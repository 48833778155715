import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import numeral from "numeral";
import {Button, Card, Col, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import InvoiceTableHeader from './InvoiceDataTableHeader';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {
    changeFilterOptionsAsync,
    loadInvoicesAsync,
    loadCountAsync, signInvoiceAsync
} from '../../../app/store/reducers/invoice/invoiceReducer';
import {selectInvoices, selectCount, selectFilterOptions} from '../../../app/store/reducers/invoice/invoiceReducer';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {selectActiveOrganization} from '../../../app/store/reducers/auth/authReducer';
import AdvanceTableCustomPagination from '../../common/advance-table/AdvanceTableCustomPagination';
import {
    getStatusColorInvoice,
    GetStatusTextInvoice,
    InvoiceCreated
} from '../../../enum/InvoiceStatus';
import EventBus from "../../../app/eventbus/EventBus";
import {DELETE_INVOICE_SUCCESS} from "../../../app/eventbus/invoiceEvents";
import PropTypes from "prop-types";
import {
    selectLang,
    Translate,
    selectDateDayJSFormat,
    selectNumberFormat
} from "../../../app/store/reducers/main/mainReducer";
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";
import CountbleProgressBar from "../../common/CountbleProgressBar";

const InvoiceDataTable = ({activeColumns, isSelectable, onSelect, filterDefaults, filterDisableds}) => {
    const dispatch = useDispatch()
    const invoices = useSelector(selectInvoices)
    const count = useSelector(selectCount)
    const filters = useSelector(selectFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const numberFormat = useSelector(selectNumberFormat);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const [hasFilter, setHasFilter] = useState(filterDefaults)
    const lang = useSelector(selectLang);
    const t = Translate;
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [signCount, setSignCount] = useState(0);
    const [countSignedInvoices, setCountSignedInvoices] = useState(0);
    const [bulkSignResult, setBulkSignResult] = useState([]);

    const totalSum = invoices.reduce((totSum, total) => totSum + total.total_info.total, 0)


    function getActiveColumns(activeColumns, columns = []) {
        return columns?.filter(column => activeColumns[column?.id])
    }

    const columns = getActiveColumns(activeColumns, [
        {
            Header: "#",
            id: "index",
            cellProps: {
                width: '40px',
            },
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'invoice_info.number',
            id: "status",
            Header: t(lang, "edi.invoice.datatable.header.row.status"),
            cellProps: {
                width: '130px',
                className: 'p-0'
            },
            totalInfo: `${t(lang, "edi.common.datatable.total")}:`,
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            Cell: rowData => {
                const {original} = rowData.row

                return <div className="d-grid gap-2">
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {t(lang, GetStatusTextInvoice(original.status))}
                            </Tooltip>
                        }
                        placement="right"
                    >
                        <div className="d-grid gap-2">
                            <Button variant="primary" style={{backgroundColor: getStatusColorInvoice(original.status)}}
                                    as={Link} to={`./invoice/${original.id}`}>
                                {original.invoice_info.number}
                            </Button>
                        </div>
                    </OverlayTrigger>
                </div>
            }
        },
        {
            accessor: 'invoice_info.date',
            id: "date",
            Header: t(lang, "edi.invoice.datatable.header.row.date"),
            cellProps: {
                width: '80px',
            },
            Cell: ({row: {original}}) => {
                return <b>{dayjs(original.invoice_info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'total_info.total',
            id: "total_sum",
            Header: t(lang, "edi.invoice.datatable.header.row.total_sum"),
            cellProps: {
                width: '50px',
                className: 'text-end'
            },
            totalInfo: numeral.formats[numberFormat].format(totalSum),
            totalInfoClassName: "fw-bold text-nowrap",
            Cell: ({row: {original}}) => {
                return numeral.formats[numberFormat].format(original.total_info.total)
            }
        },
        {
            accessor: 'shipment.order.branch.name',
            id: "branch",
            Header: t(lang, "edi.return_invoice.datatable.header.row.branch"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return <b>{original.shipment.order.branch.name}</b>
            }
        },
        {
            accessor: 'contractor',
            id: "contractor",
            Header: t(lang, "edi.invoice.datatable.header.row.contractor"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer.inn

                if (isCustomer) {
                    return <>
                        {original.executor.name}
                    </>
                }

                return <>
                    {original.customer.name}
                </>
            }
        },
        {
            accessor: 'contract_number',
            id: 'contract_num',
            Header: t(lang, 'edi.invoice.datatable.header.row.contract.number'),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return <b>{original.contract_info?.number}</b>
            }
        }
    ]);

    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}));

    useEffect(() => {
        const onInvoiceDeletedHandler = EventBus.on(DELETE_INVOICE_SUCCESS, () => {
            dispatch(loadInvoicesAsync({...filters, viewer_inn: activeOrganization.inn}))
        });

        return () => {
            EventBus.remove(DELETE_INVOICE_SUCCESS, onInvoiceDeletedHandler);
        }
    }, [])

    useEffect(() => {
        if (hasFilter) {
            let filter = {}
            Object.keys(hasFilter).forEach(key => {
                filter[key] = hasFilter[key]
            })
            dispatch(changeFilterOptionsAsync(filter))
            setHasFilter(undefined)
        } else {
            dispatch(loadInvoicesAsync({...filters, viewer_inn: activeOrganization.inn}))
            dispatch(loadCountAsync({...filters, viewer_inn: activeOrganization.inn}))
        }
    }, [filters, activeOrganization])

    const onBulkSignHandler = async (selectedInvoices) => {
        setShowProgressBar(true);
        setSignCount(selectedInvoices.length);
        for (let i = 0; i < selectedInvoices.length; i++) {
            try {
                if (selectedInvoices[i].status === InvoiceCreated) {
                    await signInvoiceAsync(selectedInvoices[i], activeCertificate);
                    setCountSignedInvoices(countSignedInvoices + 1);
                    setBulkSignResult(prev => [...prev, true]);
                }
            } catch (e) {
                setBulkSignResult(prev => [...prev, false]);
                console.log(e);
            }
        }
        await dispatch(loadInvoicesAsync({...filters, viewer_inn: activeOrganization.inn}));
        await dispatch(loadCountAsync({...filters, viewer_inn: activeOrganization.inn}));

        setBulkSignResult([]);
        setSignCount(0);
        setCountSignedInvoices(0);
        setShowProgressBar(false);
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={invoices}
            selection={!isSelectable}
            width={10}
            pagination
            perPage={1000}
        >
            <CountbleProgressBar show={showProgressBar} signed={countSignedInvoices} results={bulkSignResult}
                                 length={signCount}/>
            <Card className="mb-3">
                <Card.Header>
                    <InvoiceTableHeader table isSelectable={isSelectable} onClickBulkSign={onBulkSignHandler}
                                        filterDefaults={filterDefaults} filterDisableds={filterDisableds}/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                        rowsPerPageOptions={[10, 25, 50, 75, 100, 300]}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};


InvoiceDataTable.propTypes = {
    filterDefaults: PropTypes.shape({
        invoice_number: PropTypes.string,
        date_start: PropTypes.number,
        date_end: PropTypes.number,
        contract_number: PropTypes.string,
        contract_date: PropTypes.number,
        customer_inn: PropTypes.string,
        status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),

    filterDisableds: PropTypes.shape({
        invoice_number: PropTypes.bool,
        date_start: PropTypes.bool,
        date_end: PropTypes.bool,
        contract_number: PropTypes.bool,
        contract_date: PropTypes.bool,
        customer_inn: PropTypes.bool,
        status: PropTypes.bool
    }),

    isSelectable: PropTypes.bool,
    onSelect: PropTypes.func
}
InvoiceDataTable.defaultProps = {
    filterDefaults: {},
    filterDisableds: {},
    isSelectable: false,
    onSelect: () => {
    }
}

export default InvoiceDataTable;
