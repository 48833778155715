import React from 'react';
import MassImportAttorneyFrom from "../../../../components/hippo/attorney/mass-import-attorney/MassImportAttorneyFrom";

const MassImportAttorney = () => {
    return (
        <>
            <MassImportAttorneyFrom />
        </>
    );
};

export default MassImportAttorney;