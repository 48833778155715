import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import ItemForm from "../../../item/forms/ItemForm";
import {useHistory} from "react-router-dom";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {approveDraftItemAsync, selectOrganization} from "../../../../../app/store/reducers/draft-item/draftItemReducer";
import {selectMeasurements} from "../../../../../app/store/reducers/roaming/roamingReducer";
import {updatePriceAsync} from "../../../../../app/store/reducers/price/priceReducer";
import {toast} from "react-toastify";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    APPROVE_DRAFT_ITEM_FAILED,
    APPROVE_DRAFT_ITEM_REQUESTED,
    APPROVE_DRAFT_ITEM_SUCCESS
} from "../../../../../app/eventbus/itemEvents";
import {Piece, WithoutVat} from "../../../item/enum/itemWrapper";
import {loadCatalogByCode} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const ApproveDraftItemForm = ({draftItem}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const measurements = useSelector(selectMeasurements);
    const organization = useSelector(selectOrganization);

    // route history
    const history = useHistory();
    const form = useForm({
        defaultValues: {
            name: '',
            description: '',
            isStateControlled: false,
            sku: '',
            code: '',
            barcodes: [],
            measurement: Piece,
            categoryId: null,
            descriptionAttributes: [],
            packageMeasurements: [],
            tax: {
                measurement: null,
                rate: WithoutVat,
                catalog: null,
                package: null,
                benefit: null
            },
            images: [],
            commonPrice: {
                price: '',
                currencyId: null,
            },
            bulkPrice: {
                price: '',
                currencyId: null,
            },
        },
    });

    useEffect(async () => {
        if (draftItem) {
            form.setValue('name', draftItem.name);
            form.setValue('measurement', draftItem.measurement.toString());

            if (draftItem.barcode) {
                const barcodes = [];
                barcodes.push(draftItem.barcode);
                form.setValue('barcodes', barcodes)
            }

            if (draftItem.tax.catalog.code) {
                let catalog, taxPackage, benefit;
                catalog = await loadCatalogByCode(draftItem.tax.catalog.code);
                form.setValue('tax.catalog', catalog);

                if (draftItem.tax.catalog.package !== null) {
                    taxPackage = catalog.package_names.find(p => p.code === +draftItem.tax.catalog.package.code);
                    form.setValue('tax.package', taxPackage);
                }

                if (draftItem.tax.benefit !== null) {
                    benefit = catalog.lgotas.find(b => b.new_id === draftItem.tax.benefit.id || b.id === draftItem.tax.benefit.id);
                    form.setValue('tax.benefit', benefit);
                }
            }
        }
    }, [draftItem])

    useEffect(() => {
        const measurement = measurements.find(m => m.measureId === draftItem.tax.measurement) || null;
        form.setValue('tax.measurement', measurement);
    }, [draftItem, measurements])

    useEffect(() => {
        if (organization)
            form.setValue('tax.rate', organization.default_tax_rate === null ? WithoutVat : organization.default_tax_rate.toString());
    }, [organization])

    const [loading, setLoading] = useState(false);
    const onSubmit = formData => {
        try {
            const itemJson = {
                id: draftItem.id,
                name: formData.name,
                description: formData.description,
                is_state_controlled: formData.isStateControlled,
                sku: formData.sku || null,
                code: formData.code || null,
                barcodes: formData.barcodes.map(({barcode}) => barcode),
                measurement: formData.measurement,
                category_id: formData.categoryId,
                description_attributes: formData.descriptionAttributes.map((attr) => ({
                    key: attr.key,
                    value: attr.value
                })),
                package_measurements: formData.packageMeasurements.map((pck) => ({
                    name: pck.name,
                    quantity: +pck.quantity
                })),
                tax: {
                    tax_rate: formData.tax.rate === WithoutVat ? null : formData.tax.rate,
                    catalog: formData.tax.catalog ? {
                        code: formData.tax.catalog.class_code,
                        name: formData.tax.catalog.name,
                        package: formData.tax.package ? {
                            code: formData.tax.package.code.toString(),
                            name: formData.tax.package.name_ru,
                        } : null,
                    } : null,
                    benefit: formData.tax.benefit ? {
                        id: +formData.tax.benefit.id,
                        name: formData.tax.benefit.name,
                        type: +formData.tax.benefit.type,
                    } : null,
                    measurement: formData.tax.measurement ? formData.tax.measurement.measureId : null,
                },
                images: formData.images.map((image) => ({
                    id: null,
                    name: image.name,
                    content: image.content
                }))
            };

            setLoading(true);
            EventBus.dispatch(APPROVE_DRAFT_ITEM_REQUESTED);
            approveDraftItemAsync(itemJson)
                .then(async (item) => {
                    const priceJson = {
                        item_id: item.id,
                        common_price: {
                            currency_id: +formData.commonPrice.currencyId,
                            amount: +formData.commonPrice.price,
                        },
                        bulk_price: {
                            currency_id: +formData.bulkPrice.currencyId,
                            amount: +formData.bulkPrice.price
                        }
                    };
                    await updatePriceAsync(priceJson);

                    EventBus.dispatch(APPROVE_DRAFT_ITEM_SUCCESS, item.id);
                    setLoading(false);
                    toast.success(t(lang, 'items.common.toast.success'))
                    history.replace(`/warehouse/items/${item.id}`);
                })
                .catch((error) => {
                    EventBus.dispatch(APPROVE_DRAFT_ITEM_FAILED);
                    setLoading(false);

                    // existing name
                    if (error?.name_exists) {
                        form.setError('name', {
                            type: 'server',
                            message: t(lang, "items.common.validations.name_exists", {name: itemJson.name})
                        });
                    }

                    // existing code
                    if (error?.code_exists) {
                        form.setError('code', {
                            type: 'server',
                            message: t(lang, "items.common.validations.code_exists", {code: itemJson.code})
                        });
                    }

                    // existing barcodes
                    if (error?.existing_barcodes) {
                        for (const barcode of error.existing_barcodes) {
                            const index = itemJson.barcodes.indexOf(barcode);
                            if (index >= 0) {
                                form.setError(`barcodes.${index}.barcode`, {
                                    type: 'server',
                                    message: t(lang, "items.common.validations.existing_barcodes", {barcode: barcode})
                                });
                            }
                        }
                    }
                })
        } catch (e) {
            EventBus.dispatch(APPROVE_DRAFT_ITEM_FAILED);
            setLoading(false);
            console.log(e);
            toast.error(t(lang, 'items.common.toast.error'));
        }
    };

    return (
        <FormProvider {...form}>
            <Form>
                <ItemForm onSubmit={form.handleSubmit(onSubmit)}
                          loading={loading}
                />
            </Form>
        </FormProvider>
    );
};

export default ApproveDraftItemForm;