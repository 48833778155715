import React from 'react';
import AdditionalInformation from "./subforms/AdditionalInformation";
import MembersInfoForm from "./subforms/MembersInfoForm";
import WaybillItemsForm from "./subforms/WaybillItemsForm";
import DetailsForm from "./subforms/DetailsForm";
import DeliveryDetailsForm from "./subforms/DeliveryDetailsForm";

const WaybillForm = (props) => {
    return (
        <>
            <DetailsForm />
            <DeliveryDetailsForm />
            <MembersInfoForm />
            <AdditionalInformation />
            <WaybillItemsForm {...props} />
        </>
    );
};

export default WaybillForm;