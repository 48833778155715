import React, {Fragment} from 'react';
import {Card, Row} from "react-bootstrap";
import classNames from "classnames";
import ActivePackageCard from "../../../hippo/user/billing/ActivePackageCard";

const ActivePackages = ({hasActivePackages, organization}) => {
    return (
       <Fragment>

           <Card.Header className="text-center bg-white">
               {hasActivePackages ? <h3>Активные пакеты</h3> : <h3>Активных пакет не найдено</h3>}
           </Card.Header>
           {hasActivePackages && (
               <Card.Body>
                   <Row className={classNames('py-4 px-3', {'d-flex justify-content-center': organization.active_packages.length < 3})}>
                       {organization.active_packages.map((activePackage, index) => (
                           <ActivePackageCard key={index} activePackage={activePackage}/>
                       ))}
                   </Row>
               </Card.Body>
           )}

       </Fragment>
    );
};

export default ActivePackages;
