import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {loadItemAsync} from "../../../../app/store/reducers/item/itemReducer";
import WarehouseOperationEditItemForm from "./WarehouseOperationEditItemForm";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";

const WarehouseOperationEditItem = ({id, onItemEdited}) => {
    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate

    const [item, setItem] = useState(null);
    const [price, setPrice] = useState(null);

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
        loadItemAsync(id)
            .then(({item, price}) => {
                setItem(item);
                setPrice(price);
            })
            .catch(() => {
                setItem(null);
                setPrice(null);
            })
    }



    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-warning"
                disabled={!id}
                icon="edit"
                size="sm"
                style={{zIndex: 0}}
                className="fs-1 position-relative"
                transform="shrink-3"
            />
            <Modal show={show} onHide={handleClose} fullscreen>
                <Modal.Header>
                    <Modal.Title>{t(lang, "Редактировать")}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    {item && price &&
                        <WarehouseOperationEditItemForm item={item}
                                                        price={price}
                                                        setShow={setShow}
                                                        onItemEdited={onItemEdited}
                        />
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default WarehouseOperationEditItem;
