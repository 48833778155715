import dayjs from "dayjs";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import React, {useEffect, useState} from 'react';
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import SelectContract from "../../contract/SelectContract";
import AddContractForm from "../../contract/forms/edi/AddEdiContractForm";
import EditContract from "../../../../pages/main/edi/contract/EditEdiContract";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {ButtonGroup, Card, Col, Form, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {FromOrderDateToOrderExpires, FromOrderExpireDate} from "../../../../app/order/OrderShipmentDateMethodStrategyType";
import EventBus from "../../../../app/eventbus/EventBus";
import {EDIT_CONTRACT_SUCCEED} from "../../../../app/eventbus/contractEvents";
import {loadContractsAsync} from "../../../../app/store/reducers/contract/contractReducer";

const ReturnShipmentInfoForm = ({order}) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;


    const [shipmentDateDisabled, setShipmentDateDisabled] = useState(false);
    const [allowSelectContract, setAllowSelectContract] = useState(false)

    const shipmentDateStrategy = order?.shipment_date_method_strategy;
    const contractInfoStrategy = order?.contract_define_method_strategy;

    const {register, formState: {errors}, watch, setValue, control} = useFormContext();
    const selectedContract = watch('contract_info');
    const dispatch = useDispatch();

    useEffect(() => {
        if (shipmentDateStrategy === FromOrderExpireDate.id) {
            setShipmentDateDisabled(true);
            setValue('return_shipment_info.date', dayjs(order?.expires_on).toDate());
        }

        const onContractEditedHandler = EventBus.on(EDIT_CONTRACT_SUCCEED, () => {
            dispatch(loadContractsAsync());
        })

        switch (contractInfoStrategy) {
            case 0:
                setAllowSelectContract(true);
                dispatch(loadContractsAsync());
                break;
            case 1:
                setAllowSelectContract(false);
                setValue('contract_info.number', order?.contract?.number);
                setValue('contract_info.date', dayjs(order?.contract?.date).toDate());
                break
        }

        return () => EventBus.remove(EDIT_CONTRACT_SUCCEED, onContractEditedHandler);
    }, [])

    const addModalClose = () => setShowAddModal(false);
    const addModalShow = () => setShowAddModal(true);
    const editModalClose = () => setShowEditModal(false);
    const editModalShow = () => setShowEditModal(true);

    const onContractSelected = (contract) => {
        if (contract) {
            setValue('contract_info.number', contract.info.number, {shouldValidate: true});
            setValue('contract_info.date', dayjs(contract.info.date).toDate());
            setValue('contract_info.id', contract.id);
        } else {
            setValue('contract_info.number', '', {shouldValidate: true});
            setValue('contract_info.date', null);
            setValue('contract_info.id', null);
        }
    }

    return (
        <>
            <AddContractForm show={showAddModal} onClose={addModalClose} />
            {showEditModal && <EditContract id={selectedContract?.id} show={showEditModal} onClose={editModalClose} />}

            <Card>
                <Card.Header>
                    <Card.Title>{t(lang, 'edi.return_shipment.shipment_info.title')}</Card.Title>
                </Card.Header>
                <Card.Body className="bg-light">
                    <Row>
                        <Col xs="12" md="4" className="mb-2">
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipment_info.order_number')}</FormLabel>
                                <FormControl value={order.info.number} readOnly={true} />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4" className="mb-2">
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, 'edi.return_shipment.shipment_info.order_date')}</Form.Label>
                                <DatePicker
                                    readOnly={true}
                                    dateFormat={dateFormat}
                                    className="form-control"
                                    selected={dayjs(order.info.date).toDate()}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="12" md="4" className="mb-2">
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipment_info.final_shipment_date')}</FormLabel>
                                <DatePicker
                                    readOnly={true}
                                    dateFormat={dateFormat}
                                    className="form-control"
                                    selected={dayjs(order.expires_on).toDate()}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="6" className="mb-2">
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipment_info.shipment_number')}</FormLabel>
                                <FormControl
                                    type="text"
                                    isInvalid={errors?.return_shipment_info?.number}
                                    placeholder={t(lang, 'edi.return_shipment.shipment_info.shipment_number.placeholder')}
                                    {...register('return_shipment_info.number', {required: t(lang, 'edi.common.forms.validations.is_required')})}
                                />

                                <FormControl.Feedback type="invalid">
                                    {errors?.return_shipment_info?.number?.message}
                                </FormControl.Feedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6" className="mb-2">
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipment_info.shipment_date')}</FormLabel>
                                <Controller
                                    name='return_shipment_info.date'
                                    rules={{
                                        required: t(lang, 'edi.common.forms.validations.is_required'),
                                        validate: value => {
                                            const shipmentDate = dayjs(value).format('YYYY-MM-DD');

                                            if (shipmentDateStrategy === FromOrderDateToOrderExpires.id) {
                                                if (shipmentDate < order?.info?.date) {
                                                    return t(lang, 'edi.shipment.shipment_info.shipment_date.invalid_date')
                                                } else if (shipmentDate > order?.expires_on) {
                                                    return t(lang, 'edi.shipment.shipment_info.shipment_date.invalid_date')
                                                }
                                            }
                                        }
                                    }}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            readOnly={shipmentDateDisabled}
                                            selected={field.value}
                                            dateFormat={dateFormat}
                                            placeholderText={t(lang, 'edi.return_shipment.shipment_info.shipment_date.placeholder')}
                                            onChange={(date) => field.onChange(date)}
                                            wrapperClassName={classNames({
                                                'is-invalid': errors?.return_shipment_info?.date,
                                            })}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.return_shipment_info?.date
                                            })}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.return_shipment_info?.date?.message}
                                </Form.Control.Feedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="6" className="mb-2 mb-md-0" >
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipment_info.contract_number')}</FormLabel>
                                {allowSelectContract
                                    ?
                                    <div className="d-flex">
                                        <Controller
                                            name='contract_info.number'
                                            rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                            render={({field}) => (
                                                <SelectContract
                                                    defaultValue={field.value}
                                                    classNamePrefix="react-select rounded-end-0 border-end"
                                                    className={classNames('w-100', {
                                                        'is-invalid': !!errors?.contract_info?.number
                                                    })}
                                                    wrapperClassName={classNames({
                                                        'is-invalid': errors?.contract_info?.number
                                                    })}
                                                    onChange={contract => onContractSelected(contract)}
                                                />
                                            )}
                                        />

                                        <ButtonGroup>
                                            {selectedContract?.id && <IconButton className="rounded-start-0" onClick={editModalShow} icon="pencil" variant="outline-secondary" />}
                                            <IconButton className="rounded-start-0" onClick={addModalShow} icon="plus" variant="outline-secondary" />
                                        </ButtonGroup>
                                    </div>
                                    :
                                    <FormControl readOnly={true} {...register('contract_info.number')} />
                                }
                                <small className="text-danger">{errors?.contract_info?.number?.message}</small>
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="6" >
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipment_info.contract_date')}</FormLabel>
                                <Controller
                                    name='contract_info.date'
                                    render={({field}) => (
                                        <DatePicker
                                            readOnly={true}
                                            dateFormat={dateFormat}
                                            className="form-control"
                                            selected={field.value}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default ReturnShipmentInfoForm;