import React, {Fragment, useEffect, useRef, useState} from 'react';
import KanbanColumnHeader from "./KanbanColumnHeader";
import * as PropTypes from "prop-types";
import IconButton from "../../common/IconButton";
import AddAnotherForm from "./AddAnotherForm";
import KanbanCard from "./KanbanCard";
import classNames from "classnames";
import StrictModeDroppable from "./StrictModeDroppable";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {columnType, ContractorDebtBoardType} from "../../../enum/KanbanWrapper";

const KanbanColumn = ({kanbanColumnItem, setShowModal, showModal, setColumnId}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const { id, name, tasks, type } = kanbanColumnItem;
    const [showForm, setShowForm] = useState(false);
    const formViewRef = useRef(null);

    useEffect(() => {
        const timeout = setTimeout(() => {
            formViewRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);

        return clearTimeout(timeout);
    }, [showForm]);

    return (
        <div className={classNames('kanban-column', { 'form-added': showForm })}>
            <Fragment>
                <KanbanColumnHeader
                    setShowForm={setShowForm}
                    columnId={id}
                    title={columnType(ContractorDebtBoardType, type)?.name || name}
                    itemCount={tasks ? tasks.length : 0}
                    columnType={type}
                />
                <StrictModeDroppable droppableId={`${id}`} type="TASK">
                    {provided => (
                        <>
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                id={`container-${id}`}
                                className="kanban-items-container scrollbar"
                            >
                                {tasks && tasks.map((item, index) => (
                                    <KanbanCard
                                        index={index}
                                        key={item.id}
                                        task={item}
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                    />
                                ))}
                                {provided.placeholder}
                                <div ref={formViewRef}></div>
                            </div>

                            <div className="kanban-column-footer">
                                <IconButton
                                    size="sm"
                                    variant="link"
                                    className="d-block w-100 btn-add-card text-decoration-none text-600"
                                    icon="plus"
                                    iconClassName="me-2"
                                    onClick={() => {
                                        setColumnId(id)
                                        setShowModal(true)
                                    }}
                                >
                                    {t(lang, 'task.board.add.new-card.button.title')}
                                </IconButton>
                            </div>
                        </>
                    )}
                </StrictModeDroppable>
            </Fragment>
        </div>
    );
};



export default KanbanColumn;
