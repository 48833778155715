import React from 'react';
import ReactSelect from "react-select";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {Controller} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import {getWaybillStatuses} from "../../../../../enum/roaming/WaybillStatus";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const WaybillDataTableFilter = () => {
    const lang = useSelector(selectLang);
    const {viewer} = useParams();
    const t = Translate;

    const statusOptions = getWaybillStatuses(viewer);

    return (
        <>
            <Row>
                <h5 className="mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.waybill.datatable.header.filter.title")}</h5>
            </Row>

            <Row className="my-3">
                <Col md={6} lg={4}>
                    <Form.Label>{t(lang, 'roaming.waybill.datatable.header.filter.status_filter')}</Form.Label>
                    <Controller
                        name="status"
                        render={({field}) => (
                            <ReactSelect
                                isClearable
                                options={statusOptions}
                                hideSelectedOptions={true}
                                classNamePrefix="react-select"
                                getOptionValue={option => option.id}
                                onChange={option => field.onChange(option?.id)}
                                getOptionLabel={option => t(lang, option.name)}
                                placeholder={t(lang, 'roaming.waybill.datatable.header.filter.status_filter:placeholder')}
                            />
                        )}
                    />
                </Col>
            </Row>
            <Row className="pb-3 mb-3 text-end border-bottom">
                <Form.Group sm="auto">
                    <Button type='submit'>{t(lang, "roaming.waybill.datatable.header.filter.show_button")}</Button>
                </Form.Group>
            </Row>
        </>
    );
};

export default WaybillDataTableFilter;