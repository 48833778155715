import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {editLocationAsync} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import {toast} from "react-toastify";
import IconButton from "../../../../../common/IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../../common/FalconCloseButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {LOCATION_EDIT_SUCCESS} from "../../../../../../app/eventbus/contractor/contractorLocationEvents";
import YandexMap from "../../../../../common/map/YandexMap";

const EditContractorLocationForm = ({contractor, location}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const form = useForm({
        defaultValues: {
            location: {
                latitude: location.latitude,
                longitude: location.longitude,
                address: location.name
            },
        }
    });

    const onSubmit = async (formData) => {
        try {
            const payload = {
                contractor_id: contractor.id,
                location_id: location.id,
                location: {
                    latitude: formData.location.latitude,
                    longitude: formData.location.longitude,
                    name: formData.location.address
                },
            };

            setLoading(true);
            await editLocationAsync(payload);
            setLoading(false);
            setShow(false);

            EventBus.dispatch(LOCATION_EDIT_SUCCESS);
            toast.success(t(lang, 'common.toast.success'));
        } catch (error) {
            toast.error(t(lang, 'common.toast.error'))
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <>
            <IconButton icon={"pencil"}
                        size={"sm"}
                        iconAlign={"left"}
                        variant="falcon-default"
                        iconClassName="text-warning"
                        onClick={() => setShow(true)}
                        transform="shrink-3"
                        style={{width: '50px'}}
            />
            {show &&
                <Modal show={true}
                       centered={true}
                       size="xl"
                       onHide={() => setShow(false)}
                >
                    <FormProvider {...form}>
                        <Form>
                            <Modal.Header>
                                <Modal.Title>{t(lang, 'crm.contractor.edit_location')}</Modal.Title>
                                <FalconCloseButton onClick={() => setShow(false)}/>
                            </Modal.Header>
                            <Modal.Body>
                                <Controller name={'location'}
                                            render={({field}) => (
                                                <YandexMap defaultLocation={field.value}
                                                           onChange={field.onChange}
                                                           mapProps={{
                                                               width: '100%',
                                                               height: '500px'
                                                           }}
                                                />
                                            )}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={'secondary'}
                                        onClick={() => setShow(false)}>{t(lang, 'main.common.back')}</Button>
                                <Button variant={'primary'}
                                        disabled={loading}
                                        onClick={form.handleSubmit(onSubmit)}
                                >
                                    {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                    {t(lang, 'main.common.add')}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </FormProvider>
                </Modal>
            }
        </>
    );
};

export default EditContractorLocationForm;