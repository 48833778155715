import React, {useCallback, useMemo, useRef, useState} from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectActiveCurrencies, selectNationalCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {transliterate} from "transliteration";
import {debounce} from "underscore";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import DataTable from "../../../common/table/DataTable";
import DataTablePagination from "../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../common/table/DataTableLimiter";
import SaleItemReportModal from "./SaleItemReportModal";
import SoftBadge from "../../../common/SoftBadge";
import classNames from "classnames";
import numeral from "numeral";

const SaleItemsReportDataTable = ({report}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const activeCurrencies = useSelector(selectActiveCurrencies);
    const nationalCurrency = useSelector(selectNationalCurrency);
    const [filter, setFilter] = useState({
        name: "",
        purchasePriceExists: 0
    });
    const [sorting, setSorting] = useState({
        profit: 0
    });
    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 0
    });
    const [columnVisibility, setColumnVisibility] = useState({});
    const [rowSelection, setRowSelection] = useState({});
    const lastRowSelected = useRef(null);

    const onFilterChange = (filter) => {
        setFilter(filter);
    };
    const onDebouncedFilterChanged = useCallback(debounce(setFilter, 500), []);
    const onSortingChange = (sorting) => {
        setSorting(sorting);
    };
    const onPaginationChange = (updater) => {
        setPagination(updater)
    };
    const onColumnVisibilityChange = (columnVisibility) => {
        setColumnVisibility(columnVisibility);
    };

    const columns = useMemo(() => {
        return [
            {
                id: 'selectColumn',
                accessorKey: t(lang, "items.common.column_switch"),
                enableSorting: false,
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                            />
                        </th>
                    )
                },
                cell: ({row, table}) => {
                    const updateRowSelection = (startRowId, endRowId) => {
                        const rows = table.getCoreRowModel().rows;
                        const startIndex = rows.findIndex(row => row.id === startRowId);
                        const endIndex = rows.findIndex(row => row.id === endRowId);
                        const fromIndex = Math.min(startIndex, endIndex);
                        const toIndex = Math.max(startIndex, endIndex);
                        const newRowSelection = {};

                        for (let i = fromIndex; i <= toIndex; i++) {
                            newRowSelection[rows[i].id] = true;
                        }

                        setRowSelection(prev => ({...prev, ...newRowSelection}));
                    };
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={row.getIsSelected()}
                                        disabled={!row.getCanSelect()}
                                        onChange={row.getToggleSelectedHandler()}
                                        onClick={(e) => {
                                            if (e.shiftKey && lastRowSelected.current)
                                                updateRowSelection(lastRowSelected.current.id, row.id);
                                            lastRowSelected.current = row
                                        }}
                            />
                        </td>
                    )
                },
                footer: ({column}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllPageRowsSelected()}
                                        onChange={table.getToggleAllPageRowsSelectedHandler()}
                            />
                        </td>
                    )
                }
            },
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: () => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {row.index + 1}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td></td>
                    )
                },
            },
            {
                id: 'name',
                accessorKey: t(lang, "warehouse.operation.report.sale_items.name"),
                enableHiding: false,
                header: () => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1">
                            {t(lang, "warehouse.operation.report.sale_items.name")}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const contractorsSaleItemReport = report.contractors_period_report.map((cpr) => {
                        const saleItem = cpr.contractor_period_report.sales_items_summary.find((sis) => original.id === sis.id);
                        if (saleItem) {
                            return {
                                ...cpr,
                                contractor_period_report: {
                                    ...cpr.contractor_period_report,
                                    sale_item_summary: saleItem
                                }
                            };
                        }
                        return null;
                    })
                        .filter(cpr => cpr !== null);

                    return (
                        <td className="p-1 align-middle">
                            <SaleItemReportModal saleItem={original}
                                                 contractorsSaleItemReport={contractorsSaleItemReport}
                            />
                        </td>
                    )
                },
                footer: ({table}) => {
                    return (
                        <td></td>
                    )
                }
            },
            {
                id: 'profit_by_purchase_price',
                accessorKey: t(lang, "warehouse.operation.report.sale_items.profit_by_purchase_price"),
                enableHiding: false,
                header: () => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "210px",
                                width: "210px"
                            }}
                        >
                            {t(lang, "warehouse.operation.report.sale_items.profit_by_purchase_price")}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "210px",
                                width: "210px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {original.profit_by_purchase_price.map((profitByPurchasePrice, index) => (
                                    <SoftBadge bg='info'
                                               key={profitByPurchasePrice.currency.id}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(profitByPurchasePrice.amount)}
                                            <small
                                                className={'ms-2 fw-bold text-info'}>{profitByPurchasePrice.currency.name}</small>
                                        </h6>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const total = rows.flatMap(({original}) => original.profit_by_purchase_price).reduce((acc, item) => {
                        if (acc[item.currency.name])
                            acc[item.currency.name] += item.amount;
                        else
                            acc[item.currency.name] = item.amount;
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "210px",
                                width: "210px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {Object.keys(total).map((key, index) => (
                                    <SoftBadge bg='info'
                                               key={key}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(total[key])}
                                            <small className={'ms-2 fw-bold text-info'}>{key}</small>
                                        </h6>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                }
            },
            {
                id: 'quantity',
                accessorKey: t(lang, "warehouse.operation.report.sale_items.quantity"),
                enableHiding: false,
                header: () => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            {t(lang, "warehouse.operation.report.sale_items.quantity")}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            <h6 className="text-end">{numeral.formats[numberFormat].format(original.quantity)}</h6>
                        </td>
                    )
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const total = rows.reduce((acc, {original}) => acc + original.quantity, 0)
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            <h6 className="text-end">{numeral.formats[numberFormat].format(total)}</h6>
                        </td>
                    )
                }
            },
            {
                id: 'profit_by_purchase_price_per_quantity',
                accessorKey: t(lang, "warehouse.operation.report.sales_profit_by_purchase_price_per_item"),
                enableHiding: false,
                header: () => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "180px",
                                width: "180px"
                            }}
                        >
                            {t(lang, "warehouse.operation.report.sales_profit_by_purchase_price_per_item")}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "180px",
                                width: "180px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {original.profit_by_purchase_price.map((profitByPurchasePrice, index) => (
                                    <SoftBadge bg='info'
                                               key={profitByPurchasePrice.currency.id}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(profitByPurchasePrice.amount / original.quantity)}
                                            <small
                                                className="ms-2 fw-bold text-info">{profitByPurchasePrice.currency.name}</small>
                                        </h6>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const total = rows.reduce((acc, {original}) => {
                        original.profit_by_purchase_price.forEach((profitByPurchasePrice) => {
                            const currencyName = profitByPurchasePrice.currency.name;
                            const amount = profitByPurchasePrice.amount / original.quantity;

                            if (acc[currencyName]) {
                                acc[currencyName] += amount;
                            } else {
                                acc[currencyName] = amount;
                            }
                        });

                        return acc;
                    }, {});
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "180px",
                                width: "180px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {Object.keys(total).map((key, index) => (
                                    <SoftBadge bg='info'
                                               key={key}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(total[key])}
                                            <small className="ms-2 fw-bold text-info">{key}</small>
                                        </h6>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                }
            },
            {
                id: 'net_price',
                accessorKey: t(lang, "warehouse.operation.report.sale_items.net_price"),
                enableHiding: false,
                header: () => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            {t(lang, "warehouse.operation.report.sale_items.net_price")}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {original.net_price.map((netPrice, index) => (
                                    <SoftBadge bg='success'
                                               key={netPrice.currency.id}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(netPrice.amount)}
                                            <small
                                                className={'ms-2 fw-bold text-success'}>{netPrice.currency.name}</small>
                                        </h6>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const total = rows.flatMap(({original}) => original.net_price).reduce((acc, item) => {
                        if (acc[item.currency.name])
                            acc[item.currency.name] += item.amount;
                        else
                            acc[item.currency.name] = item.amount;

                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {Object.keys(total).map((key, index) => (
                                    <SoftBadge bg='success'
                                               key={key}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(total[key])}
                                            <small className={'ms-2 fw-bold text-success'}>{key}</small>
                                        </h6>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                }
            },
            {
                id: 'purchase_net_price',
                accessorKey: t(lang, "warehouse.operation.report.sale_items.purchase_net_price"),
                enableHiding: false,
                header: () => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            {t(lang, "warehouse.operation.report.sale_items.purchase_net_price")}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    if (!original.purchase_net_price || !original.purchase_net_price.length || !original.purchase_net_price.some((price) => price.amount > 0)) {
                        return (
                            <td className="p-1 align-middle text-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <SoftBadge bg='warning'
                                               key={nationalCurrency?.id}
                                               className={classNames("w-100")}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(0)}
                                            <small
                                                className={'ms-2 fw-bold text-warning'}>{nationalCurrency?.name}</small>
                                        </h6>
                                    </SoftBadge>
                                </div>
                            </td>
                        )
                    }

                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {original.purchase_net_price.map((purchaseNetPrice, index) => (
                                    <SoftBadge bg='primary'
                                               key={purchaseNetPrice.currency.id}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(purchaseNetPrice.amount)}
                                            <small
                                                className={'ms-2 fw-bold text-primary'}>{purchaseNetPrice.currency.name}</small>
                                        </h6>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const total = rows.flatMap(({original}) => original.purchase_net_price).reduce((acc, item) => {
                        if (acc[item.currency.name])
                            acc[item.currency.name] += item.amount;
                        else
                            acc[item.currency.name] = item.amount;

                        return acc;
                    }, {});

                    if (!Object.keys(total).length) {
                        return (
                            <td className="p-1 align-middle text-center"
                                style={{
                                    minWidth: "150px",
                                    width: "150px"
                                }}
                            >
                                <SoftBadge bg="warning"
                                           className={classNames("w-100")}
                                >
                                    <h6 className="m-0 text-end">
                                        {numeral.formats[numberFormat].format(0)}
                                        <small className={classNames("ms-2 fw-bold text-warning")}>
                                            {nationalCurrency?.name}
                                        </small>
                                    </h6>
                                </SoftBadge>
                            </td>
                        )
                    }

                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                minWidth: "150px",
                                width: "150px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {Object.keys(total).map((key, index) => (
                                    <SoftBadge bg={total[key] > 0 ? "primary" : "warning"}
                                               key={key}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(total[key])}
                                            <small className={classNames("ms-2 fw-bold", {
                                                "text-primary": total[key] > 0,
                                                "text-warning": total[key] === 0
                                            })}>{key}</small>
                                        </h6>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                }
            },
        ];
    }, [lang, lastRowSelected, nationalCurrency, numberFormat]);
    const data = useMemo(() => {
        const filteredData = report.overall_period_report.sales_items_summary.filter((saleItem) => {
            if (filter.name) {
                const searchTermLower = filter.name.trim().toLowerCase();
                const itemNameLower = saleItem.name.trim().toLowerCase();
                const transliteratedQuery = transliterate(searchTermLower);
                const transliteratedItemName = transliterate(itemNameLower);
                const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
                if (filterNameParts.length !== filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                    return false;
            }

            if (!!filter.purchasePriceExists) {
                if (filter.purchasePriceExists === 1) {
                    if (saleItem.purchase_net_price && saleItem.purchase_net_price.length && saleItem.purchase_net_price.some((price) => price.amount > 0))
                        return false;
                }

                if (filter.purchasePriceExists === 2) {
                    if (!saleItem.purchase_net_price || !saleItem.purchase_net_price.length || !saleItem.purchase_net_price.some((price) => price.amount > 0))
                        return false;
                }
            }

            return true;
        });

        let sortedData = [];

        {
            switch (true) {
                case !!sorting.profit:
                    // sort by max profit (desc)
                    if (sorting.profit === 1) {
                        filteredData.forEach((data) => {
                            data.total_profit = 0;
                            data.profit_by_purchase_price.forEach((profit) => {
                                if (profit.currency.id === nationalCurrency?.id) {
                                    data.total_profit += profit.amount
                                } else {
                                    const profitByPurchasePriceCurrency = activeCurrencies.find((currency) => currency.id === profit.currency.id);
                                    data.total_profit += profit.amount * profitByPurchasePriceCurrency.rate;
                                }
                            })
                        })

                        sortedData = filteredData.sort((a, b) => b.total_profit / b.quantity - a.total_profit / a.quantity);
                    }

                    // sort by minimum profit (asc)
                    if (sorting.profit === 2) {
                        filteredData.forEach((data) => {
                            data.total_profit = 0;
                            data.profit_by_purchase_price.forEach((profit) => {
                                if (profit.currency.id === nationalCurrency?.id) {
                                    data.total_profit += profit.amount
                                } else {
                                    const profitByPurchasePriceCurrency = activeCurrencies.find((currency) => currency.id === profit.currency.id);
                                    data.total_profit += profit.amount * profitByPurchasePriceCurrency.rate;
                                }
                            })
                        })

                        sortedData = filteredData.sort((a, b) => a.total_profit / a.quantity - b.total_profit / b.quantity);
                    }
                    break;
                default:
                    sortedData = filteredData;
                    break;
            }
        }

        return sortedData;
    }, [report, filter, sorting]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getRowId: row => row.id,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: onPaginationChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        pageCount: Math.ceil(data.length / pagination.pageSize),
        meta: {
            getHeaderRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
            getCellRowStyles: (row) => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getFooterRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            }
        },
        state: {
            pagination: pagination,
            sorting: sorting,
            rowSelection: rowSelection,
            columnVisibility: columnVisibility,
        }
    });
    const selectedItems = table.getSelectedRowModel().rows.map(({original}) => original);

    return (
        <Card>
            <Card.Header>
                <Row className="gy-3">
                    <Col xs={12}>
                        <Card.Title>{t(lang, "warehouse.operation.report.sale_items_report")}</Card.Title>
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse.operation.report.sale_items.name')}</Form.Label>
                            <Form.Control name="name"
                                          placeholder={t(lang, 'items.common.datatable.filter.deep_search.placeholder')}
                                          defaultValue={filter.name}
                                          onChange={(e) => onDebouncedFilterChanged({
                                              ...filter,
                                              name: e.target.value
                                          })}

                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse.operation.report.sale_items.sort_by_profit')}</Form.Label>
                            <Form.Select defaultValue={sorting.profit}
                                         onChange={e => onSortingChange({...sorting, profit: +e.target.value})}
                            >
                                <option
                                    value={0}>{t(lang, 'warehouse.operation.report.sale_items.sort_by_default')}</option>
                                <option
                                    value={1}>{t(lang, 'warehouse.operation.report.sale_items.sort_by_desc')}</option>
                                <option
                                    value={2}>{t(lang, 'warehouse.operation.report.sale_items.sort_by_asc')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse.operation.report.sale_items.filter_by_not_existing_purchase_price')}</Form.Label>
                            <Form.Select defaultValue={filter.purchasePriceExists}
                                         onChange={e => setFilter({
                                             ...filter,
                                             purchasePriceExists: +e.target.value
                                         })}
                            >
                                <option
                                    value={0}>{t(lang, 'warehouse.operation.report.sale_items.filter_by_default')}</option>
                                <option
                                    value={1}>{t(lang, 'warehouse.operation.report.sale_items.filter_by_yes')}</option>
                                <option
                                    value={2}>{t(lang, 'warehouse.operation.report.sale_items.filter_by_no')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {!!selectedItems.length &&
                        <Col xs={12}>
                            <h6>{t(lang, "common.table.selected_count", {length: selectedItems.length})}</h6>
                        </Col>
                    }
                </Row>
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={false}
                           columns={columns}
                           tableProps={{
                               responsive: true,
                               bordered: true,
                               style: {
                                   borderWidth: "2px 0",
                                   borderColor: "lightGrey"
                               }
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={data.length}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100, 500, 1000]}
                />
            </Card.Footer>
        </Card>
    )
};

export default SaleItemsReportDataTable;