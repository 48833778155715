import {toast} from "react-toastify";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Modal, Spinner} from "react-bootstrap";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {removeAccountSessionAsync} from "../../../app/store/reducers/auth/authReducer";

const DeleteAccountSessionModal = ({accountId, token}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const deleteSessionHandler = () => {
        setLoading(true);

        removeAccountSessionAsync({
            accountId: accountId,
            token: token,
        })
            .then(() => {
                handleClose();
                toast.success(t(lang, 'admin.organization.active_sessions.end_session.modal.toast.success_ended'))
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <FontAwesomeIcon onClick={handleShow} className="float-end text-danger cursor-pointer" icon={faBan} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'admin.organization.active_sessions.end_session.modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-1">{t(lang, 'admin.organization.active_sessions.end_session.modal.description')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, 'admin.organization.active_sessions.end_session.modal.button.back')}
                    </Button>
                    <Button variant="danger" onClick={deleteSessionHandler}>
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, 'admin.organization.active_sessions.end_session.modal.button.terminate')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteAccountSessionModal;