import {faClone, faSignature} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {Card, Col, Row, Table} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {selectDateDayJSFormat, selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import ConvertQuantityToWord from "../../attorney/ConvertQuantityToWord";
import numeral from "numeral";
import dayjs from "dayjs"
import {ContractWrapperClass} from "../../../../enum/ContractStatus";
import CancelContract from "./actions/CancelContract";
import DeclineContract from "./actions/DeclineContract";
import {cancelAsync, customerAcceptAsync, customerDeclineAsync, send} from "../../../../app/store/reducers/roaming/roamingContractReducer";
import SoftBadge from "../../../common/SoftBadge";
import {selectMeasurements} from "../../../../app/store/reducers/roaming/roamingReducer";
import {Link} from "react-router-dom";
import SignActionWithSelectCertificatWrapper from "../components/SignActionWithSelectCertificatWrapper";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingContractAccept,
    PermissionRoamingContractUpdate
} from "../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import RoamingContractSync from "./actions/RoamingContractSync";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ROAMING_CONTRACT_SYNC_FAILED,
    ROAMING_CONTRACT_SYNC_SUCCESS
} from "../../../../app/eventbus/roaming/roamingContractEvents";
import WarningAlert from "../components/WarningAlert";


const ContractView = ({contract}) => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const currentCertificate = useSelector(selectCurrentCertificate);
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const numberFormat = useSelector(selectNumberFormat);
    const measurements = useSelector(selectMeasurements);
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [showAcceptButton, setShowAcceptButton] = useState(false)
    const [showDeclineButton, setShowDeclineButton] = useState(false)
    const [showCopyButton, setShowCopyButton] = useState(false)
    const contractWrapper = new ContractWrapperClass(contract)
    const activeOrganizationPinflOrInn = activeOrganization.pinfl || activeOrganization.inn
    const showDeclineNotes = contractWrapper.enumStatuses.buyerOrAgentIsDeclined === contract.status
    const [eventMessage, setEventMessage] = useState(null);

    useEffect(() => {
        const onContractSyncSuccessHandler = EventBus.on(ROAMING_CONTRACT_SYNC_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.contract.alert.success_event_title'),
                text: t(lang, 'roaming.contract.alert.contract_sync_success')
            });
        })
        const onContractSyncFailedHandler = EventBus.on(ROAMING_CONTRACT_SYNC_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.contract.alert.failed_event_title'),
                text: error.message
            })
        })

        return () => {
            EventBus.remove(ROAMING_CONTRACT_SYNC_SUCCESS, onContractSyncSuccessHandler)
            EventBus.remove(ROAMING_CONTRACT_SYNC_FAILED, onContractSyncFailedHandler)
        }

    }, [])

    useEffect(() => {
        setShowCancelButton(contractWrapper.canTheSellerCancled(activeOrganizationPinflOrInn))
        setShowAcceptButton(contractWrapper.canTheBuyerSign(activeOrganizationPinflOrInn))
        setShowDeclineButton(contractWrapper.canTheBuyerDecline(activeOrganizationPinflOrInn))
        setShowCopyButton(contractWrapper.allowCopy(activeOrganizationPinflOrInn))
    }, [contract, activeOrganization.inn])

    const executorSignAndSend = async (data) => {
        try {
            if (currentCertificate?.keyId) {
                const convertedContract = {
                    ContractId: data.id,
                    HasVat: data.has_vat,
                    ContractDoc: {
                        ContractName: data.info.name,
                        ContractNo: data.info.number,
                        ContractDate: dayjs(data.info.date, 'YYYY-MM-DD').format("YYYY-MM-DD"),
                        ContractExpireDate: dayjs(data.info.expire_date, 'YYYY-MM-DD').format("YYYY-MM-DD"),
                        ContractPlace: data.info.place
                    },
                    Owner: {
                        Tin: data.executor.inn,
                        Name: data.executor.name,
                        Address: data.executor.address,
                        WorkPhone: data.executor.work_phone,
                        Mobile: data.executor.mobile,
                        Fax: data.executor.fax,
                        Oked: data.executor.oked,
                        Account: data.executor.account,
                        BankId: data.executor.mfo,
                        FizTin: data.executor.director_inn,
                        Fio: data.executor.director_name,
                        BranchCode: data.executor.branch.branch_code,
                        BranchName: data.executor.branch.branch_name
                    },
                    Clients: data.contractors.map(client => ({
                        Tin: client.inn,
                        Name: client.name,
                        Address: client.address,
                        WorkPhone: client.work_phone,
                        Mobile: client.mobile,
                        Fax: client.fax,
                        Oked: client.oked,
                        Account: client.account,
                        BankId: client.mfo,
                        FizTin: client.director_inn,
                        Fio: client.director_name,
                        BranchCode: client.branch.branch_code,
                        BranchName: client.branch.branch_name
                    })),
                    Products: data.items.map((product, index) => ({
                        OrdNo: product.ordering_number || index + 1,
                        CatalogCode: product.catalog.code,
                        CatalogName: product.catalog.name,
                        BarCode: product.barcode,
                        Name: product.name,
                        MeasureId: product.measure_id,
                        PackageCode: product.packageCode ? `${product.packageCode}` : null,
                        PackageName: product.packageName,
                        Count: +product.quantity,
                        Summa: +product.price,
                        DeliverySum: +product.total,
                        VatRate: +product.vat_rate,
                        VatSum: +product.vat_sum,
                        DeliverySumWithVat: +product.total_with_nds,
                        WithoutVat: +product.without_vat
                    })),
                    Parts: data.parts.map((part, index) => ({
                        OrdNo: part.ordering_number || index + 1,
                        Title: part.title,
                        Body: part.body
                    }))
                }
                await send({
                    contract: convertedContract,
                    certificate: currentCertificate,
                    lang: lang
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const totalWithNds = contract?.items.reduce((total, item) => item?.total_with_nds + total, 0)

    const getMeasure = id => measurements.find(m => m.measureId === id) || null;

  return (
    <>
        {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

        {!eventMessage && contract?.roaming_error && <WarningAlert text={contract?.roaming_error.message} title={'Ошибка от роуминга'} variant={'danger'}/>}

        <Card className="p-3">
            <Row>
                <h3 className="text-600 ms-2 col-8">{t(lang,"edi.common.created.time")}: {contract.created_at}</h3>
                <Col className="text-end">
                    {contract?.status &&
                        <>
                            <span className="me-2 fw-medium">{t(lang, 'edi.common.label.status')}: </span>
                            <SoftBadge pill className="fs--1 fw-bold" bg={contractWrapper.getRoamingStatusVariant(activeOrganizationPinflOrInn)}>
                                {t(lang, contractWrapper.getRoamingStatus(activeOrganizationPinflOrInn))}
                            </SoftBadge>
                        </>
                    }
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    {showCopyButton &&
                        <IconButton
                            variant="falcon-primary"
                            size="sm"
                            icon={faClone}
                            as={Link}
                            to={contract.status ? `/roaming/contract/actions/${contract.id}?status=${contract?.status}` : `/roaming/contract/actions/${contract.id}?status=${null}`}
                            className="mx-2 d-inline"
                        >
                            {t(lang, "roaming.common.copy")}
                        </IconButton>}
                    {!contract?.status &&
                        <>
                                <IconButton
                                    as={dispatch(checkPermission(PermissionRoamingContractUpdate))? Link : 'div'}
                                    onClick={()=>!dispatch(checkPermission(PermissionRoamingContractUpdate)) && dispatch(toastPermission())}
                                    to={`/roaming/contract/draft/edit/${contract.id}`}
                                    variant="falcon-warning"
                                    size="sm"
                                    icon={faSignature}
                                    iconClassName="me-1"
                                    className="ms-sm-2 ms-0 mb-2 mb-sm-0"
                                >{t(lang, 'roaming.common.edit')}</IconButton>
                            <SignActionWithSelectCertificatWrapper permission={PermissionRoamingContractUpdate}>
                                <IconButton
                                    variant="falcon-primary"
                                    size="sm"
                                    icon={faSignature}
                                    iconClassName="me-1"
                                    className="ms-sm-2 ms-0 mb-2 mb-sm-0"
                                    onClick={() => executorSignAndSend(contract)}
                                    disabled={!currentCertificate}
                                >{t(lang, 'edi.common.button.sign')}</IconButton>
                            </SignActionWithSelectCertificatWrapper>
                        </>
                    }
                    {showAcceptButton &&
                        <SignActionWithSelectCertificatWrapper permission={PermissionRoamingContractAccept}>
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon={faSignature}
                                iconClassName="me-1"
                                className="ms-sm-2 ms-0 mb-2 mb-sm-0"
                                onClick={() => customerAcceptAsync(currentCertificate, lang, contract)}
                                disabled={!currentCertificate}
                            >{t(lang, 'edi.common.button.sign')}</IconButton>
                        </SignActionWithSelectCertificatWrapper>
                    }

                    {showCancelButton &&
                        <CancelContract
                            className="ms-2 mb-2 mb-sm-0"
                            onCancel={() => cancelAsync(currentCertificate, lang, contract)}
                        />
                    }

                    {showDeclineButton &&
                        <DeclineContract
                            className="ms-2 mb-2 mb-sm-0"
                            disabled={!currentCertificate}
                            onDecline={comment => customerDeclineAsync(currentCertificate, lang, contract, comment)}
                        />
                    }
                    <RoamingContractSync id={contract.id} className="mx-2" />
                </Col>
            </Row>
        </Card>

        <Card className="my-3 px-3">
            <Row className="d-flex flex-lg-row flex-column mt-3">
                <Col xs={12} lg={5} className="d-flex flex-column my-2">
                    <h5 className="mb-1 fw-bold text-dark">{t(lang, "roaming.contract.company.owner")}</h5>
                    <span><b>{t(lang,"edi.common.company")}:</b> {contract?.executor?.name}</span>
                    <span><b>{t(lang, 'edi.common.label.inn')}:</b> {contract?.executor?.inn}</span>
                </Col>

                <Col xs={12} lg={5} className="d-flex flex-column">
                    {contract?.contractors?.map((contractor, index) => (
                        <Col className="d-flex flex-column my-2" key={index}>
                            <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.customer')}</h5>
                            <span><b>{t(lang,"edi.common.company")}:</b> {contractor?.name}</span>
                            <span><b>{t(lang, 'edi.common.label.inn')}:</b> {contractor?.inn}</span>
                        </Col>
                    ))

                    }
                </Col>

                {/*<Col xs={3} lg={2} className="border border-black">*/}
                {/*    QR CODE*/}
                {/*</Col>*/}
            </Row>
        </Card>

        <Card>
            <Card.Header>
                <Row>
                    <Row className="d-flex flex-column flex-lg-row align-items-md-center justify-content-lg-between">
                        <Col xs={12} md={8} lg={5} className="d-flex justify-content-center justify-content-lg-start">
                            <h5 className="p-0 ms-3"><b className="pe-1">{t(lang,"edi.common.id")}:</b>{contract.id}</h5>
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            {showDeclineNotes &&
                                <div className="alert-danger p-1 text-center">
                                    <span className="fw-bold me-2">{t(lang, "roaming.common.comment")}:</span>
                                    <strong>{contract.notes}</strong>
                                </div>
                            }
                        </Col>
                    </Row>

                    <Col className="my-3 col-12 d-flex flex-column align-items-center">
                        <h3>{t(lang, "roaming.contract.title")}</h3>
                        <div className="d-flex">
                            <b>Номер:</b><span>{contract?.info?.number}</span>
                        </div>
                        <div className="d-flex">
                            <b>Дата:</b><span>{dayjs(contract?.info?.date, "YYYY-MM-DD").format(dateFormat)}</span>
                        </div>
                        <div className="d-flex">
                            <b>Наименование:</b><span>{contract?.info?.name}</span>
                        </div>
                        <div className="d-flex">
                            <b>Действителен до:</b><span>{dayjs(contract?.info?.expire_date, "YYYY-MM-DD").format(dateFormat)}</span>
                        </div>
                        <div className="d-flex">
                            <b>Место заключения:</b><span>{contract?.info?.place}</span>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={6} className="fs--1 my-2">
                        <div className="row">
                            <span className="col-5 fs-1 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.owner")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.name} <hr className="m-0" style={{height: "2px"}} /></span>
                        </div>
                        <div className="row">
                            <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.address")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.address?.trim()}<hr className="m-0" style={{height: "2px"}} /></span>
                        </div>
                        <div className="row">
                            <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.phone")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.work_phone}{contract?.executor?.mobile && `, ${contract?.executor?.mobile}`}<hr className={contract?.executor?.work_phone || contract?.executor?.mobile ? 'm-0' : 'mb-0'} style={{height: "2px"}} /></span>
                        </div>
                        <div className="row">
                            <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.fax")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.fax}<hr className={contract?.executor?.fax ? 'm-0' : 'mb-0'} style={{height: "2px"}} /></span>
                        </div>
                        <div className="row">
                            <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.tin")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.inn}<hr className="m-0" style={{height: "2px"}} /></span>
                        </div>
                        <div className="row">
                            <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.oked")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.oked}<hr className={contract?.executor?.oked ? 'm-0' : 'mb-0'} style={{height: "2px"}} /></span>
                        </div>
                        <div className="row">
                            <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.account")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.account}<hr className="m-0" style={{height: "2px"}} /></span>
                        </div>
                        <div className="row">
                            <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.bank")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.address?.trim()}<hr className="m-0" style={{height: "2px"}} /></span>
                        </div>
                        <div className="row">
                            <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.mfo")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.mfo}<hr className="m-0" style={{height: "2px"}} /></span>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className="d-flex flex-column">
                        {contract?.contractors?.map((contractor, index) => (
                            <Col key={index} className="fs--1 my-2">
                                <div className="row">
                                    <span className="col-5 fs-1 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.contractor")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.name}<hr className="m-0" style={{height: "2px"}} /></span>
                                </div>
                                <div className="row">
                                    <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.address")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.address?.trim()}<hr className="m-0" style={{height: "2px"}} /></span>
                                </div>
                                <div className="row">
                                    <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.phone")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.work_phone}{contractor?.mobile && `, ${contractor?.mobile}`}<hr className={contractor?.work_phone || contractor?.mobile ? 'm-0' : 'mb-0'} style={{height: "2px"}} /></span>
                                </div>
                                <div className="row">
                                    <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.fax")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.fax}<hr className={contractor?.fax ? 'm-0' : 'mb-0'} style={{height: "2px"}} /></span>
                                </div>
                                <div className="row">
                                    <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.tin")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.inn}<hr className="m-0" style={{height: "2px"}} /></span>
                                </div>
                                <div className="row">
                                    <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.oked")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.oked}<hr className={contractor?.oked ? 'm-0' : 'mb-0'} style={{height: "2px"}} /></span>
                                </div>
                                <div className="row">
                                    <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.account")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.account}<hr className="m-0" style={{height: "2px"}} /></span>
                                </div>
                                <div className="row">
                                    <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.bank")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.address?.trim()}<hr className="m-0" style={{height: "2px"}} /></span>
                                </div>
                                <div className="row">
                                    <span className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.mfo")}</span>
                                    <span className="col-7 mb-1 ps-0">{contractor?.mfo}<hr className="m-0" style={{height: "2px"}} /></span>
                                </div>
                            </Col>
                        ))}
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                <Row className="justify-content-center border-top">
                    <Col xs={12}>
                        <p className="text-center mt-4 fw-medium">{t(lang, 'roaming.contract.description_1', {owner: contract?.executor?.name, owner_director: contract?.executor?.director_name})} {contract?.contractors?.map(partner => t(lang, 'roaming.contract.description_2', {partner: partner?.name, partner_director: partner?.director_name}))} {t(lang, 'roaming.contract.description_3')}</p>
                    </Col>
                </Row>

                <Table bordered responsive className="border-bottom fs--1 border-500">
                    <thead className="text-dark bg-200">
                    <tr>
                        <th className="align-middle" rowSpan="2">№</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.name")}</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.catalog")}</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.barcode")}</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.measurement")}</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.quantity")}</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.price")}</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.total")}</th>
                        <th className="align-middle text-center" colSpan="2">{t(lang, "roaming.contract.items_datatable.vat")}</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.total_with_vat")}</th>
                    </tr>
                    <tr>
                        <th className="align-middle">{t(lang, "roaming.contract.items_datatable.vat.rate")}</th>
                        <th className="align-middle">{t(lang, "roaming.contract.items_datatable.vat.sum")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {contract?.items?.map((item, index) => (
                        <tr key={index}>
                            <td className="align-middle">{item?.ordering_number}</td>
                            <td className="align-middle">{item?.name}</td>
                            <td className="align-middle">{item?.catalog.code} - {item?.catalog.name}</td>
                            <td className="align-middle">{item?.barcode}</td>
                            <td className="align-middle">{item?.measure_id ? getMeasure(`${item?.measure_id}`)?.name : item.packageName}</td>
                            <td className="align-middle text-nowrap">{item?.quantity || 0}</td>
                            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.price || 0)}</td>
                            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.total || 0)}</td>
                            <td className="align-middle text-nowrap">{item?.vat_rate}</td>
                            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.vat_sum || 0)}</td>
                            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.total_with_nds || 0)}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Row className="g-0 justify-content-end">
                    <Col xs="auto">
                        <Table borderless size="sm" className="fs--1 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900">{t(lang, 'edi.common.total')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(contract?.items?.reduce((total, item) => item?.total + total, 0))}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-900">{t(lang, 'edi.common.nds_value')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(contract.items.reduce((total, item) => item.vat_sum + total, 0))}
                                </td>
                            </tr>
                            <tr className="border-top">
                                <th className="text-900">{t(lang, 'edi.common.total_with_nds')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(totalWithNds)}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <p className="fw-bold">
                    {t(lang, "roaming.contract.items_datatable.amount_in_words_1")} {ConvertQuantityToWord.getText(totalWithNds)} ({t(lang, "roaming.contract.items_datatable.amount_in_words_2")}),
                    ({numeral.formats[numberFormat].format(totalWithNds)})
                </p>
            </Card.Body>

            <Card.Footer>
                <Col className="border-top my-4 p-2">
                    {contract?.parts?.map((part, index) => (
                        <div key={index}>
                            <h5 className="ms-4">{part?.ordering_number}. {part?.title}</h5>
                            {part?.body?.split('\n').map((text, index) => (
                                <p className="text-justify" key={index}>{text}</p>
                            ))}
                        </div>
                    ))}
                </Col>
            </Card.Footer>
        </Card>
    </>
  );
};

export default ContractView;
