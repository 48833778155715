import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {
    loadExchangeInfo,
    loadInvestmentObjectInfo
} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {toast} from "react-toastify";
import dayjs from "dayjs";

const RoamingInvoiceFormInvestmentInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const onSearch = async () => {
        try {
            const id = form.getValues('facturaInvestmentObjectDoc.objectId');
            const buyerInn = form.getValues('buyer.innOrPinfl');
            const sellerInn = form.getValues('seller.innOrPinfl');
            const investmentObjectInfo = await loadInvestmentObjectInfo({
                seller_inn: sellerInn,
                buyer_inn: buyerInn,
                object_id: id,
            });
            if (investmentObjectInfo?.lots?.length) {
                form.setValue('exchange.type', investmentObjectInfo.lots[0].lotPrefix);
                form.setValue('exchange.number', investmentObjectInfo.lots[0].lotNumber);

                const exchange = await loadExchangeInfo({
                    inn: buyerInn,
                    lot_id: `${investmentObjectInfo.lots[0].lotPrefix}${investmentObjectInfo.lots[0].lotNumber}`
                });
                onFillFromExchange(exchange);
            }
        } catch (e) {
            toast.error(t(lang,'edi.common.toast.error'))
            console.log(e);
        }
    }
    const onFillFromExchange = (exchange) => {
        if (exchange) {
            form.setValue('contractDoc.contractNo', exchange.contract_number || '');
            form.setValue('contractDoc.contractDate', exchange.contract_date ? dayjs(exchange.contract_date).toDate() : null);
            form.setValue('buyer.account', exchange.buyer_account || '');
            form.setValue(`productList.products`, exchange.products.map((product, index) => ({
                catalog: null,
                package: null,
                measurement: null,
                lgota: null,
                origin: null,
                committentName: '',
                committentTin: '',
                committentVatRegCode: '',
                committentVatRegStatus: '',
                barcode: '',
                warehouseId: null,
                mark: {
                    productType: null,
                    kiz: [
                        {code: ''}
                    ],
                    nomUpak: [
                        {code: ''}
                    ],
                    identTransUpak: [
                        {code: ''}
                    ],
                },
                exchangeInfo: {
                    productCode: product.product_code || '',
                    productProperties: product.product_properties || '',
                    planPositionId: product.plan_position_id || '',
                },
                name: product.name || '',
                serial: '',
                baseSumma: '',
                profitRate: '',
                count: product.quantity || '',
                countTon: '',
                countKm: '',
                summa: product.price || '',
                exciseRate: '',
                exciseSum: '',
                deliverySum: '',
                vatRate: '',
                vatSum: '',
                deliverySumWithVat: '',
            })));
        }
    };

    return (
        <>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.invoice.investment.object_id')}</Form.Label>
                <Col sm={8} md={12} lg={8}>
                    <InputGroup>
                        <Form.Control name={`facturaInvestmentObjectDoc.objectId`}
                                      placeholder={t(lang, 'roaming.invoice.investment.object_id.placeholder')}
                                      {...form.register(`facturaInvestmentObjectDoc.objectId`, {
                                          shouldUnregister: true
                                      })}
                        />
                        <Button onClick={onSearch}>{t(lang, 'roaming.invoice.find')}</Button>
                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.invoice.investment.object_name')}</Form.Label>
                <Col sm={8} md={12} lg={8}>
                    <InputGroup>
                        <Form.Control name={`facturaInvestmentObjectDoc.objectName`}
                                      placeholder={t(lang, 'roaming.invoice.investment.object_name.placeholder')}
                                      disabled
                                      {...form.register(`facturaInvestmentObjectDoc.objectName`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </InputGroup>
                </Col>
            </Form.Group>
        </>
    );
};

export default RoamingInvoiceFormInvestmentInfo;