import axios from "axios";

class CrmInternalOrderService {
    getInternalOrders(params) {
        return axios.get('/internal-order/order/get', {
            params: params,
        })
    }

    getInternalOrder(id) {
        return axios.get('/internal-order/order/get/' + id)
    }

    registerCrmInternalOrder(payload) {
        return axios.post('/internal-order/order/register', payload)
    }

    updateCrmInternalOrder(id, payload) {
        return axios.post(`/internal-order/order/update/${id}`, payload)
    }

    internalOrderCount() {
        return axios.get('/internal-order/order/count')
    }

    deleteInternalOrder(id) {
        return axios.post('/internal-order/order/delete/' + id)
    }


    itemRegisterUpdate(payload) {
        return axios.post('/internal-order/order/item/register', payload)
    }

    itemUpdate(payload) {
        return axios.post('/internal-order/order/item/update', payload)
    }

    itemDelete(payload) {
        return axios.post('/internal-order/order/item/delete', payload)
    }

    identifyItems({orderId}) {
        return axios.post(`/internal-order/order/set-executor-items/${orderId}`)
    }
}

export default new CrmInternalOrderService();