import React, {Fragment, useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../common/FalconCloseButton";
import IconButton from "../common/IconButton";
import Form from "react-bootstrap/Form";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {registerNotification} from "../../app/store/reducers/telegram-notification/telegramNotificationReducer";
import {NotificationEvents, NotificationForm} from "./index";

const NotificationRegister = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const t = Translate
    const lang = useSelector(selectLang)
    const handleShow = () => setShow(true);
    const handleClose = () => {
        methods.reset()
        setShow(false)
    };

    const methods = useForm({
        defaultValues: {
            phoneNumber: "",
            eventCodes: []
        }
    })

    const onSubmit = (formData) => {
        setLoading(true)
        const phoneNumber = formData.phoneNumber
        const eventCodes = formData.eventCodes.map(eventCode => eventCode.value)

        registerNotification({phoneNumber: phoneNumber, eventCodes: eventCodes})
            .then(() => handleClose())
            .catch(e => new Error(e))
            .finally(() => setLoading(false))
    }
    return (
        <Fragment>
            <div className="d-flex justify-content-end">
                <IconButton icon={"plus"} onClick={handleShow} size={"sm"} iconAlign={"left"} variant="falcon-default" className="d-flex align-items-center"
                            transform="shrink-3">
                    {t(lang, "notification.table.register")}
                </IconButton>
            </div>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
                <Modal.Header>
                    <Modal.Title>{t(lang, "notification.table.register.modal_title")}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)} id={"notification-register"}>
                            <NotificationForm/>
                            <NotificationEvents/>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "notification.close_button")}
                    </Button>
                    <Button variant="primary" disabled={loading} type={"submit"} form={"notification-register"}>
                        {loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                        />}
                        {t(lang, "notification.add_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default NotificationRegister;