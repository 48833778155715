import PropTypes from "prop-types";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {invoiceDeleteAsync} from "../../../app/store/reducers/invoice/invoiceReducer";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiInvoiceDelete} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";

const InvoiceDelete = ({id, children, ...props}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => {
        if(dispatch(checkPermission(PermissionEdiInvoiceDelete))) setShow(true)
        else dispatch(toastPermission())
    };
    const handleClose = () => {
        setLoading(false);
        setShow(false);
    };

    const deleteInvoiceHandler = () => {
        setLoading(true);

        invoiceDeleteAsync({
            id: id,
            comment: comment
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };

    return (
        <>
            {children ?
                <div onClick={() => setShow(true)}>
                    {children}
                </div> :
                <IconButton
                    variant="falcon-danger"
                    size="sm"
                    icon={faTrash}
                    iconClassName="me-1"
                    onClick={handleShow}
                    {...props}
                >{t(lang, "edi.common.delete_button")}</IconButton>
            }

            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "edi.invoices.invoice.delete_invoice_modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, "edi.common.label.comment")}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder={t(lang, "edi.common.leave_comment")}
                            onChange={(event) => setComment(event.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        {t(lang, "edi.common.cancel_button")}
                    </Button>
                    <Button
                        variant="danger"
                        className="d-flex align-items-center"
                        disabled={loading} onClick={deleteInvoiceHandler}
                    >
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, "edi.common.delete_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

InvoiceDelete.propTypes =
    {
        id: PropTypes.string,
    }
;

InvoiceDelete.defaultProps =
    {
        id: '',
    }
;


export default InvoiceDelete;
