import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {Card, Dropdown} from "react-bootstrap";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const TechSupportInformation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleToggle = () => setIsOpen(!isOpen);

    return (
        <>
            <Dropdown navbar={true} as="li" onToggle={handleToggle} show={isOpen}>
                <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!" className="px-1 nav-link">
                    <FontAwesomeIcon className="fs-3 text-facebook" icon={faPhone} />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end">
                    <Card className="dropdown-menu-end shadow-none fs-0 p-2" style={{width: "200px"}}>
                        <div>
                            <div>
                                <FontAwesomeIcon className="mx-1 text-facebook" icon={faPhone} />
                                <a href="tel:+998712006363"> +998 71 200 63 63</a>
                            </div>
                            <div>
                                <FontAwesomeIcon className="mx-1 text-facebook" icon={faTelegram} />
                                <a className="text-decoration-none" href="https://t.me/HippoEDI" target="_blank">{t(lang, 'auth.layout.link.telegram_bot.title')}</a>
                            </div>
                        </div>
                    </Card>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default TechSupportInformation;