export const AwaitExecutorApprove = 0
export const ExecutorApproved = 1
export const ExecutorDeleted = 2
export const ExecutorEdited = 3
export const InvoiceCreated = 4
export const CustomerApproved = 5
export const CustomerDeclined = 6
export const CustomerEdited = 7
export const CustomerEditApprovedByExecutor = 8

export const GetStatusTextShipment = status => {
    switch (status) {
        case AwaitExecutorApprove:
            return 'edi.shipment.status.await_executor_approve'

        case ExecutorApproved:
            return 'edi.shipment.status.executor_approved'

        case ExecutorDeleted:
            return 'edi.shipment.status.executor_deleted'

        case ExecutorEdited:
            return 'edi.shipment.status.executor_edited'

        case InvoiceCreated:
            return 'edi.shipment.status.invoice_created'

        case CustomerApproved:
            return 'edi.shipment.status.customer_approved'

        case CustomerDeclined:
            return 'edi.shipment.status.customer_declined'

        case CustomerEdited:
            return 'edi.shipment.status.customer_edited'

        case CustomerEditApprovedByExecutor:
            return 'edi.shipment.status.customer_edit_approved_by_executor'

        default:
            return 'edi.shipment.status.default'
    }
}

export const ShipmentStatuses = [
    {id: AwaitExecutorApprove, name: GetStatusTextShipment(AwaitExecutorApprove)},
    {id: ExecutorApproved, name: GetStatusTextShipment(ExecutorApproved)},
    {id: ExecutorDeleted, name: GetStatusTextShipment(ExecutorDeleted)},
    {id: ExecutorEdited, name: GetStatusTextShipment(ExecutorEdited)},
    {id: InvoiceCreated, name: GetStatusTextShipment(InvoiceCreated)},
    {id: CustomerApproved, name: GetStatusTextShipment(CustomerApproved)},
    {id: CustomerDeclined, name: GetStatusTextShipment(CustomerDeclined)},
    {id: CustomerEdited, name: GetStatusTextShipment(CustomerEdited)},
    {id: CustomerEditApprovedByExecutor, name: GetStatusTextShipment(CustomerEditApprovedByExecutor)},
]
// export const GetStatusVariant = status => {
//     switch (status) {
//         case AwaitExecutorApprove:
//             return 'dark'
//         case ExecutorApproved:
//             return 'success'
//         case ExecutorDeleted:
//             return 'danger'
//         case ExecutorEdited:
//             return 'warning'
//         case InvoiceCreated:
//             return 'primary'
//         case CustomerApproved:
//             return 'info'
//         case CustomerDeclined:
//             return 'danger'
//         case CustomerEdited:
//             return 'warning'
//         case CustomerEditApprovedByExecutor:
//             return 'primary'
//         default:
//             return 'secondary'
//     }
// }

export const getStatusColorShipment = status => {
    switch (status) {
        case AwaitExecutorApprove:
            return '#263238'
        case ExecutorApproved:
            return '#00796b'
        case ExecutorDeleted:
            return '#880e4f'
        case ExecutorEdited:
            return '#ef6c00'
        case InvoiceCreated:
            return '#01579b'
        case CustomerApproved:
            return '#4caf50'
        case CustomerDeclined:
            return '#f44336'
        case CustomerEdited:
            return '#ef6c00'
        case CustomerEditApprovedByExecutor:
            return '#00796b'
        default:
            return '#607d8b'
    }
}