import numeral from "numeral";
import ItemRow from "./ItemRow";
import React, {useEffect, useRef, useState} from 'react';
import {useFieldArray, useFormContext} from "react-hook-form";
import {Button, ButtonToolbar, Card, Col, FormControl, Row, Spinner, Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import ItemExcelImport from "../../../common/ItemExcelImport";
import {DefineByExecutor} from '../../../../app/order/OrderProductNameDefineMethodStrategyType'
import {loadProductMapNamesAsync} from "../../../../app/store/reducers/productMap/productMapReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectCompanyCatalogs} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectShipmentProductCatalogs} from "../../../../app/store/reducers/shipment/shipmentReducer";

const itemsFieldName = 'items';

const ShipmentItemsForm = ({order, shipment, isEditing, loading}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const [query, setQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const {control ,setValue, watch} = useFormContext();
    const [productMaps, setProductMaps] = useState([]);
    const {fields, update} = useFieldArray({control ,name: 'items', keyName: 'index'});
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;
    const catalogs = useSelector(selectCompanyCatalogs);
    const productCatalogsShipment = useSelector(selectShipmentProductCatalogs);

    const itemsFieldsArray = watch('items');
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...itemsFieldsArray[index]
        };
    });

    const isCustomer = activeOrganization?.inn === order?.customer?.inn;
    const allowEditProductName = DefineByExecutor.id === order.product_name_define_method_strategy;

    const quantityTotal = controlledFields.reduce((acc,item) => acc + item.quantity ,0);
    const subtotal = controlledFields.reduce((acc,item) => acc + item.total ,0);
    const tax = controlledFields.reduce((acc,item) => acc + item.nds_value ,0);
    const totalWithNds = subtotal + tax;

    const bindProductMaps = () => {
        // iterate for all fields
        for (let fieldIndex = 0; fieldIndex < fields.length; fieldIndex++) {
            let field = fields[fieldIndex];

            for (let productMapIndex = 0; productMapIndex < productMaps.length; productMapIndex++) {
                const productMap = productMaps[productMapIndex];
                let fieldProductMapFound = false;

                // find customer & executor matched product map
                if (productMap.customer.inn === order.customer.inn && productMap.executor.inn === order.executor.inn) {

                    // find product by product.customer.name
                    for (let k = 0; k < productMap.products.length; k++) {
                        const product = productMap.products[k];

                        if (product.customer_product_name === field.product) {
                            // replace form product field
                            field.product = product.executor_product_name;
                            const companyCatalog = catalogs.find(c => c.class_code === productCatalogsShipment?.[field.product]?.catalogClassCode);
                            field.catalog_class_code = companyCatalog?.class_code || field.catalog_class_code || null;
                            field.catalog_class_name = companyCatalog?.class_name || field.catalog_class_name || null;
                            field.package_code = companyCatalog?.package_names?.find(i=>i.code?.toString() === productCatalogsShipment?.[field.product]?.packageCode)?.code || field.package_code || "";
                            field.package_name = companyCatalog?.package_names?.find(i=>i.code?.toString() === productCatalogsShipment?.[field.product]?.packageCode)?.name_ru || field.package_name || "";
                            update(fieldIndex, field)
                            fieldProductMapFound = true;
                            break;
                        }
                    }
                }
                if (fieldProductMapFound) {
                    break;
                }
            }
        }

    }

    const loadProductMapNames = () => {
        loadProductMapNamesAsync({
            customer_inn: order.customer.inn,
            executor_inn: order.executor.inn
        })
            .then(response => {
                setProductMaps(response);
            })
            .catch(error => console.log(error))
    }

    const resetQuantity = () => {
        controlledFields.forEach((field, index) => {
            field.quantity = 0;
            update(index, field);
        })
    }
    const restoreQuantity = () =>   {
        if (shipment) {
            controlledFields.forEach((field, index) => {
                const shipmentItem = shipment.items.find(i => i.order_item.id === field.id)
                field.quantity = shipmentItem?.quantity || 0;
                update(index, field);
            })
        } else {
            controlledFields.forEach((field, index) => {
                field.quantity = order?.items?.[index]?.quantity;
                update(index, field);
            })
        }
    }
    const onSuccessFilled = (products) => {
        controlledFields.forEach((field, index) => {
            field.quantity = 0;

            let fieldItem = products.find(product => {
                const product_name = product.name.trim();

                return product_name === field.product.trim()
            })

            if (fieldItem)
                field.quantity = fieldItem.quantity;

            update(field.uid, field);
        })
    }

    useEffect(() => {
        const items = [...controlledFields];
        const filtered = items.filter(i => i.product.toLowerCase().includes(query.toLowerCase()));
        setFilteredItems(filtered);
    }, [query])

    useEffect(() => {
        setValue('totalWithNds', totalWithNds)
    }, [controlledFields])

    useEffect(() => {
        if (order.product_name_define_method_strategy === DefineByExecutor.id) {
            bindProductMaps();
        }
    }, [productMaps])

    useEffect(() => {
        if (order.product_name_define_method_strategy === DefineByExecutor.id) {
            loadProductMapNames();
        }
    }, [])

    const refs = useRef([]);


    useEffect(() => {
        refs.current = filteredItems.map(() => ({
            product: React.createRef(),
            catalog_class_code: React.createRef(),
            package_code: React.createRef(),
            quantity: React.createRef(),
        }));
    }, [filteredItems]);


    const handleKeyDown = (e, rowIndex, fieldName, isSelect) => {
        if (e.ctrlKey) {
            let nextRow = rowIndex;
            let nextField = fieldName;

            switch (e.key) {
                case 'ArrowUp':
                    nextRow = rowIndex > 0 ? rowIndex - 1 : rowIndex;
                    break;
                case 'ArrowDown':
                    nextRow = rowIndex < filteredItems.length - 1 ? rowIndex + 1 : rowIndex;
                    break;
                default:
                    break;
            }

            e.preventDefault();
            if (isSelect) {
                refs.current[nextRow][nextField]?.current?.focus();
            } else {
                refs.current[nextRow][nextField]?.current?.childNodes[0]?.focus();
            }
        }
    };

    return (
        <>
            <Card className="mt-3">
                <Card.Header className="bg-200">
                    <Row className="align-items-end g-2">
                        <Col className={'d-flex align-items-center gap-2'}>
                            <h5 className="mb-0 d-inline-block">{t(lang, 'edi.shipment.shipment_items.title')}</h5>
                            <FormControl className={'d-inline w-auto'} size="sm" type="text" placeholder={t(lang, 'edi.common.form.search_input.placeholder')} onChange={(e) => setQuery(e.target.value.trim())} />
                        </Col>
                        <Col xs="auto">
                            <ButtonToolbar className="d-grid d-md-block gap-2 float-end">
                                <Button size="sm" className="mx-1" onClick={() => restoreQuantity()}>{t(lang, 'edi.shipment.shipment_items.restore_all_quantities')}</Button>
                                <Button size="sm" className="mx-1" variant="danger" onClick={() => resetQuantity()}>{t(lang, 'edi.shipment.shipment_items.reset_all_quantities')}</Button>
                                <ItemExcelImport onSuccessFilled={onSuccessFilled} />
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Table  className="fs--1" responsive striped>
                        <thead>
                        <tr className="align-middle">
                            <th className="text-center">#</th>
                            <th>{t(lang, 'edi.common.product')}</th>
                            {/*<th className="p-1 pb-2">{t(lang, 'edi.common.customer_shipment_id')}</th>*/}
                            <th className="text-center" style={{width: '100px'}}>{t(lang, 'edi.common.barcode')}</th>
                            <th className="text-center" style={{width: '195px'}}>{t(lang, 'edi.common.catalog_class_code')}</th>
                            <th style={{width: '100px'}}>{t(lang, 'edi.common.measurement')}</th>
                            <th style={{width: '100px'}}>{t(lang, 'edi.common.quantity')}</th>
                            <th style={{width: '100px'}}>{t(lang, 'edi.common.price')}</th>
                            <th style={{width: '100px'}}>{t(lang, 'edi.common.total')}</th>
                            <th style={{width: '100px'}}>{t(lang, 'edi.common.nds_rate')}</th>
                            <th style={{width: '100px'}}>{t(lang, 'edi.common.nds_value')}</th>
                            <th className="text-end px-1" style={{width: '100px'}}>{t(lang, 'edi.common.total_with_nds')}</th>
                            {!isCustomer && <th className="text-center px-1"
                                 style={{width: '100px'}}>{t(lang, 'roaming.invoice.mark')}</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {filteredItems.map((item, index) => (
                            <ItemRow
                                isEditing={isEditing}
                                key={item.index}
                                index={item.uid}
                                arrayFieldName={itemsFieldName}
                                update={update}
                                isCustomer={isCustomer}
                                allowEditProductName={allowEditProductName}
                                refs={refs.current[index]}
                                handleKeyDown={handleKeyDown}
                            />
                        ))}
                        </tbody>
                    </Table>

                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900 px-1">{t(lang, 'edi.common.quantity')}:</th>
                                    <td className="fw-semi-bold px-1">
                                        {quantityTotal}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900 px-1">{t(lang, 'edi.common.total')}:</th>
                                    <td className="fw-semi-bold px-1">
                                        {numeral.formats[numberFormat].format(subtotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900 px-1">{t(lang, 'edi.common.nds_rate')}:</th>
                                    <td className="fw-semi-bold px-1">
                                        {numeral.formats[numberFormat].format(tax)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900 px-1">{t(lang, 'edi.common.total_with_nds')}:</th>
                                    <td className="fw-semi-bold px-1">
                                        {numeral.formats[numberFormat].format(subtotal + tax)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="border-top">
                    <Button tabIndex={controlledFields.length + 1} disabled={loading} className="float-end" variant="primary" type="submit">
                        <div className={'d-flex align-items-center gap-2'}>
                            {loading && <Spinner animation="border" size="sm"/>}
                            {shipment ? t(lang, 'edi.common.edit_button') : t(lang, 'edi.common.button.create')}
                        </div>
                    </Button>
                </Card.Footer>
            </Card>
        </>
    );
};

export default ShipmentItemsForm;
