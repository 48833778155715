export const BIND_DRAFT_PURCHASE_ITEM_REQUESTED = "BIND_DRAFT_PURCHASE_ITEM_REQUESTED";
export const BIND_DRAFT_PURCHASE_ITEM_SUCCEED = "BIND_DRAFT_PURCHASE_ITEM_SUCCEED";
export const BIND_DRAFT_PURCHASE_ITEM_FAILED = "BIND_DRAFT_PURCHASE_ITEM_FAILED";

export const APPROVE_DRAFT_PURCHASE_REQUESTED = "APPROVE_DRAFT_PURCHASE_REQUESTED";
export const APPROVE_DRAFT_PURCHASE_SUCCEED = "APPROVE_DRAFT_PURCHASE_SUCCEED";
export const APPROVE_DRAFT_PURCHASE_FAILED = "APPROVE_DRAFT_PURCHASE_FAILED";

export const DELETE_DRAFT_PURCHASE_REQUESTED = "DELETE_DRAFT_PURCHASE_REQUESTED";
export const DELETE_DRAFT_PURCHASE_SUCCEED = "DELETE_DRAFT_PURCHASE_SUCCEED";
export const DELETE_DRAFT_PURCHASE_FAILED = "DELETE_DRAFT_PURCHASE_FAILED";