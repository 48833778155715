import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal, Form} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import CleaveWithCommaMark from "../../../common/CleaveWithCommaMark";
import SelectContractor from "../../crm/contractor/SelectContractor";
import {Controller} from "react-hook-form";

const ContractorMassModal = ({
                                 onMassImport,
                                 show,
                                 setShow
                             }) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const handleClose = () => {
        setShow(false)
    };

    return (
        <Modal show={show} size={'md'} className={'mt-5'}>
            <Modal.Header>
                <Modal.Title>{t(lang, "items.common.bulk_change")}</Modal.Title>
                <FalconCloseButton onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>{t(lang, 'roaming.invoice.items.price')}</Form.Label>
                    <Controller name={'massValues.price'} render={({field}) => (
                        <CleaveWithCommaMark
                            placeholder={t(lang, 'roaming.invoice.items.price')}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )} />

                </Form.Group>

                <Form.Group className={'mt-3'}>
                    <Form.Label>{t(lang, 'crm.data.table.contractor')}</Form.Label>
                    <Controller name={'massValues.contractor'} render={({field}) => (
                        <SelectContractor
                            onChange={field.onChange}
                            placeholder={t(lang, "items.common.select")}
                        />
                    )} />

                </Form.Group>
                <div className={'d-flex justify-content-end gap-3 mt-3'}>
                    <Button type={'button'} variant={'falcon-primary'} onClick={onMassImport}>{t(lang, 'items.common.fill')}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ContractorMassModal;
