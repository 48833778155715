import React, {useEffect, useState} from 'react';
import ItemForm from "./ItemForm";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectMeasurements} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {editItemAsync, selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {updatePriceAsync} from "../../../../app/store/reducers/price/priceReducer";
import {toast} from "react-toastify";
import EventBus from "../../../../app/eventbus/EventBus";
import {EDIT_ITEM_FAILED, EDIT_ITEM_REQUESTED, EDIT_ITEM_SUCCESS} from "../../../../app/eventbus/itemEvents";
import {WithoutVat} from "../enum/itemWrapper";
import {loadCatalogByCode} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {selectNationalCurrency} from "../../../../app/store/reducers/currency/currencyReducer";

const EditItemForm = ({item: {item, price, warehouse_states}}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const measurements = useSelector(selectMeasurements);
    const nationalCurrency = useSelector(selectNationalCurrency);
    const items = useSelector(selectItems);

    const form = useForm({
        defaultValues: {
            name: item.name,
            description: item.description || '',
            isStateControlled: item.is_state_controlled,
            sku: item.sku,
            code: item.code,
            barcodes: item.barcodes.map((barcode) => ({barcode: barcode})),
            measurement: item.measurement.toString(),
            categoryId: item.category ? item.category.id : null,
            descriptionAttributes: item.description_attributes.map((attr) => ({
                key: attr.key,
                value: attr.value
            })),
            packageMeasurements: item.package_measurements.map((pck) => ({
                name: pck.name,
                quantity: pck.quantity
            })),
            legalType: item.legal_type,
            tax: {
                measurement: null,
                rate: WithoutVat,
                catalog: null,
                package: null,
                benefit: null,
                origin: item.tax?.origin,
            },
            images: item.images.map((image) => ({
                id: image.id,
                name: image.name,
                path: image.path
            })),
            commonPrice: {
                price: price.common_price.amount || '',
                currencyId: price.common_price.currency.global_currency_id
            },
            bulkPrice: {
                price: price.bulk_price.amount || '',
                currencyId: price.bulk_price.currency.global_currency_id
            },
            skipNameUniqeValidation: false,
            purchasePrices: warehouse_states.warehouse_items.map(warehouseItem => {
                const purchasePrice = {
                    itemId: item.id,
                    warehouseId: warehouseItem.id,
                    warehouseName: warehouseItem.name,
                    purchasePrice: {
                        price: 0,
                        currency: {
                            id: nationalCurrency?.id || null,
                            name: nationalCurrency?.name || null,
                        },
                    },
                };

                if (warehouseItem.purchase_price) {
                    purchasePrice.purchasePrice.price = warehouseItem.purchase_price.price.amount;
                    purchasePrice.purchasePrice.currency.id = warehouseItem.purchase_price.price.currency.id;
                    purchasePrice.purchasePrice.currency.name = warehouseItem.purchase_price.price.currency.name;
                }

                return purchasePrice;
            }),
            itemAlertOn: warehouse_states.warehouse_items.map(warehouseItem => {
                return {
                    itemId: item.id,
                    warehouseId: warehouseItem.id,
                    name: warehouseItem.name,
                    alertOn: warehouseItem.alert_on
                }
            })
        },
    });

    useEffect(async () => {
        if (item.tax !== null) {
            if (item.tax.catalog !== null && item.tax.catalog.code) {
                let catalog, taxPackage, benefit;

                catalog = await loadCatalogByCode(item.tax.catalog.code);
                form.setValue('tax.catalog', catalog);

                if (item.tax.catalog.package !== null) {
                    taxPackage = catalog.package_names.find(p => p.code === +item.tax.catalog.package.code);
                    form.setValue('tax.package', taxPackage);
                }

                if (item.tax.benefit !== null) {
                    benefit = catalog.lgotas.find(b => b.new_id === item.tax.benefit.id || b.id === item.tax.benefit.id);
                    form.setValue('tax.benefit', benefit);
                }
            }

            form.setValue('tax.rate', item.tax.tax_rate === null ? WithoutVat : item.tax.tax_rate.toString());
        }
    }, [item])

    useEffect(() => {
        if (item.tax !== null && item.tax.measurement !== null) {
            const measurement = measurements.find(m => m.measureId === item.tax.measurement) || null;
            form.setValue('tax.measurement', measurement);
        }
    }, [item, measurements])

    const [loading, setLoading] = useState(false);
    const onSubmit = async (formData) => {
        try {
            setLoading(true);
            const itemJson = {
                name: formData.name,
                description: formData.description,
                is_favorite: item.is_favorite,
                is_state_controlled: formData.isStateControlled,
                sku: formData.sku || null,
                code: formData.code || null,
                barcodes: formData.barcodes.map(({barcode}) => barcode),
                measurement: formData.measurement,
                category_id: formData.categoryId,
                description_attributes: formData.descriptionAttributes.map((attr) => ({
                    key: attr.key,
                    value: attr.value
                })),
                package_measurements: formData.packageMeasurements.map((pck) => ({
                    name: pck.name,
                    quantity: +pck.quantity
                })),
                legal_type: formData.legalType,
                tax: {
                    tax_rate: formData.tax.rate === "" ? null : formData.tax.rate,
                    catalog: formData.tax.catalog ? {
                        code: formData.tax.catalog.class_code,
                        name: formData.tax.catalog.name,
                        package: formData.tax.package ? {
                            code: formData.tax.package.code.toString(),
                            name: formData.tax.package.name_ru,
                        } : null,
                    } : null,
                    benefit: formData.tax.benefit ? {
                        id: +formData.tax.benefit.id,
                        name: formData.tax.benefit.name,
                        type: +formData.tax.benefit.type,
                    } : null,
                    measurement: formData.tax.measurement ? formData.tax.measurement.measureId : null,
                    origin: formData.tax.origin || null,
                },
                images: formData.images.map((image) => ({
                    id: image.id || null,
                    name: image.name,
                    content: image?.content?.split(",")?.[1]
                })),
                skip_name_unique_validation: formData.skipNameUniqeValidation,
            };

            EventBus.dispatch(EDIT_ITEM_REQUESTED, itemJson);
            const editedItem = await editItemAsync(item.id, itemJson);

            const priceJson = {
                item_id: editedItem.id,
                common_price: {
                    currency_id: +formData.commonPrice.currencyId,
                    amount: +formData.commonPrice.price,
                },
                bulk_price: {
                    currency_id: +formData.bulkPrice.currencyId,
                    amount: +formData.bulkPrice.price
                }
            };
            await updatePriceAsync(priceJson);

            setLoading(false);
            toast.success(t(lang, 'items.common.toast.success'));
            EventBus.dispatch(EDIT_ITEM_SUCCESS, {itemId: editedItem.id});
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(t(lang, 'items.common.toast.error'));

            // existing name
            if (error?.response?.data?.name_exists) {
                form.setError('name', {
                    type: 'server',
                    message: t(lang, "items.common.validations.name_exists", {name: formData.name})
                });
            }

            // existing code
            if (error?.response?.data?.code_exists) {
                form.setError('code', {
                    type: 'server',
                    message: t(lang, "items.common.validations.code_exists", {code: formData.code})
                });
            }

            // existing barcodes
            if (error?.response?.data?.existing_barcodes) {
                for (const barcode of error.existing_barcodes) {
                    const index = formData.barcodes.findIndex(c => c.code === barcode)
                    if (index >= 0) {
                        form.setError(`barcodes.${index}.barcode`, {
                            type: 'server',
                            message: t(lang, "items.common.validations.existing_barcodes", {barcode: barcode})
                        });
                    }
                }
            }

            EventBus.dispatch(EDIT_ITEM_FAILED, error);
        }
    };

    return (
        <FormProvider {...form}>
            <Form>
                <ItemForm isEditing={true}
                          onSubmit={form.handleSubmit(onSubmit)}
                          loading={loading}
                />
            </Form>
        </FormProvider>
    );
};

export default EditItemForm;
