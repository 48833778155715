import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import classNames from "classnames";

const WaybillHistoryActions = ({serialNumber, signingTime, fullName, action}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    return (
        <div className={classNames(action.className, "border-300 p-2")} style={{borderRadius: '10px', border: '10px solid', background: action.bg}}>
            <div className="d-flex justify-content-between fs--1">
                <span className="me-3 text-secondary">№ {serialNumber}</span>
                <span className="text-secondary">{signingTime}</span>
            </div>
            <p className={`fs-0 fw-semi-bold text-center text-uppercase my-3 text-nowrap text-truncate`} title={t(lang, action.label)}>{t(lang, action.label)}</p>
            <p className="m-0 fs--1 text-secondary text-center">
                {fullName}
            </p>
        </div>
    );
};

export default WaybillHistoryActions;
