import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {ActStatuses} from "../View/ActStatus";

const SelectActStatus = ({onChange, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const options = ActStatuses.map((status) => {
        return {
            value: status.id,
            label: t(lang, status.name),
        };
    })

    const onChangeHandler = (option) => {
        onChange(option ? option.value : null);
    }

    return (
        <Select {...props} classNamePrefix="react-select" onChange={onChangeHandler} options={options}/>
    );
};


SelectActStatus.propTypes = {
    onChange: PropTypes.func
}
SelectActStatus.defaultProps = {
    onChange: () => {
    }
}


export default SelectActStatus;