import React, {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import ReturnShipment from "../../../../components/hippo/return-shipment/view/ReturnShipment";
import {loadReturnShipmentAsync} from "../../../../app/store/reducers/return-shipment/returnShipmentReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CUSTOMER_APPROVE_RETURN_SHIPMENT_SUCCESS,
    DELETE_RETURN_SHIPMENT_SUCCESS,
    EXECUTOR_APPROVE_RETURN_SHIPMENT_SUCCESS
} from "../../../../app/eventbus/returnShipmentEvents";
import {setEdiReturnBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnShipmentView} from "../../../../enum/Permission/EdiPermission";

const ViewReturnShipment = () => {
    const {params: {id}} = useRouteMatch();
    const [shipment, setShipment] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization)
    const dispatch = useDispatch()
    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)


    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiReturnShipmentView))) {
            if (history.length > 1) {
                history.push('../return-shipments')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadShipment();
    }, [id, activeOrganization]);

    useEffect(() => {
        const onCustomerApprovedShipmentHandler = EventBus.on(CUSTOMER_APPROVE_RETURN_SHIPMENT_SUCCESS, () => {
            loadShipment();
        });

        const onExecutorApprovedShipmentHandler = EventBus.on(EXECUTOR_APPROVE_RETURN_SHIPMENT_SUCCESS, () => {
            loadShipment();
        });

        const onDeleteShipmentHandler = EventBus.on(DELETE_RETURN_SHIPMENT_SUCCESS, () => {
            loadShipment();
        });

        return () => {
            EventBus.remove(CUSTOMER_APPROVE_RETURN_SHIPMENT_SUCCESS, onCustomerApprovedShipmentHandler);
            EventBus.remove(EXECUTOR_APPROVE_RETURN_SHIPMENT_SUCCESS, onExecutorApprovedShipmentHandler);
            EventBus.remove(DELETE_RETURN_SHIPMENT_SUCCESS, onDeleteShipmentHandler);
        }
    }, [])

    const loadShipment = () => {
        loadReturnShipmentAsync(id)
            .then(loadedShipment => {
                dispatch(checkPermission(PermissionEdiReturnShipmentView)) && dispatch(setEdiReturnBreadcrumbData(loadedShipment))
                setShipment(loadedShipment)
            })
    };

    return (
        <>
            {shipment && <ReturnShipment returnShipment={shipment}/>}
            {!shipment && <span>loading....</span>}
        </>
    )
}


export default ViewReturnShipment;
