import React, {useState} from 'react';
import {Card} from "react-bootstrap";
import RoamingActFormMember from "./RoamingActFormMember";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";

const fieldName = "executor";
const RoamingActFormCardExecutor = ({isEditOrCopy}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const [company, setCompany] = useState(null);
    const [eventCount, setEventCount] = useState(0);

    const onChangeCompany = (company) => {
        setCompany(company);

        // if is copy or edit, prevent from conflict of values
        {
            if (isEditOrCopy && eventCount === 0) {
                setEventCount(eventCount + 1);
                return;
            }
        }

        if (company) {
            form.setValue(`description`, t(lang, "roaming.act.send_form.default_description", {executor_name: company.name, customer_name: form.getValues('customer.name')}));
            form.setValue(`${fieldName}.name`, company.name);
        } else {
            form.setValue(`description`, t(lang, "roaming.act.send_form.default_description", {executor_name: "___________", customer_name: form.getValues('customer.name')}));
            form.setValue(`${fieldName}.name`, '');
        }
    };

    return (
        <Card className="h-100">
            <Card.Body>
                <RoamingActFormMember fieldName={fieldName}
                                      isSearchable={false}
                                      company={company}
                                      onChangeCompany={onChangeCompany}
                />
            </Card.Body>
        </Card>
    );
};

export default RoamingActFormCardExecutor;