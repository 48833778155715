import React, {useEffect} from "react";
import CreatableSelect from "react-select/creatable";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {
    selectReturnInvoiceActiveAccountant,
    selectReturnInvoiceAccountants, changeActiveSigner, clearActiveSigner
} from "../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import ActionButton from "../../common/ActionButton";
import {useFormContext} from "react-hook-form";


const SelectAccountant = ({onCreate, onDelete, ...props}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const accountants = useSelector(selectReturnInvoiceAccountants);
    const activeAccountant = useSelector(selectReturnInvoiceActiveAccountant);
    const {setValue} = useFormContext()

    const formatOptionLabel = (option) => {
        const deleteOption = option => dispatch(onDelete(option));
        return (
            <div className="d-flex justify-content-between text-dark">
                <div>{option.label}</div>
                {!option.lastSelected && <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-1" onClick={(event) => {
                    event.stopPropagation();
                    deleteOption(option);
                }}
                />}
            </div>
        )
    }
    const createOption = (label) => {
        let newOption = {
            label,
            arrayFieldName: "accountants",
            lastSelected: false,
        }
        onCreate(newOption);
    };
    const formatCreateLabel = label => `${t(lang, "edi.common.add_button")} ${label}`;
    const noOptionsMessage = () => t(lang, "edi.common.accountant.no_options.message");

    useEffect(() => {
        if (activeAccountant) {
            setValue('accountant', activeAccountant.label)
        } else {
            setValue('accountant', '')
        }
    },[activeAccountant])

    return (
        <CreatableSelect options={accountants}
                         onChange={(accountant) => accountant ? dispatch(changeActiveSigner(accountant)) : dispatch(clearActiveSigner(activeAccountant))}
                         onCreateOption={createOption}
                         formatCreateLabel={formatCreateLabel}
                         formatOptionLabel={(option) => option?.__isNew__ ? option.label : formatOptionLabel(option)}
                         getOptionValue={option => option.label}
                         getOptionLabel={option => option.label}
                         noOptionsMessage={() => noOptionsMessage()}
                         value={activeAccountant}
                         placeholder={t(lang, "edi.common.button.select")}
                         isClearable
                         hideSelectedOptions
                         classNamePrefix="react-select"
                         {...props}
        />
    )
};

export default SelectAccountant;