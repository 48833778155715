import React from 'react';
import OwnerBindingsDataTable from "../../../components/hippo/organization-bindings/owner/OwnerBindingsDataTable";
import SupervisorBindingsDataTable from "../../../components/hippo/organization-bindings/supervisor/SupervisorBindingsDataTable";

const OrganizationBindings = () => {
    return (
        <React.Fragment>
            <OwnerBindingsDataTable />
            <SupervisorBindingsDataTable />
        </React.Fragment>
    );
};

export default OrganizationBindings;