import {createSlice} from "@reduxjs/toolkit";
import CrmInternalOrderService from "../../../../../services/crmInternalOrderService";
import EventBus from "../../../../eventbus/EventBus";
import {
    DELETE_CRM_INTERNAL_ORDER_FAILED,
    DELETE_CRM_INTERNAL_ORDER_REQUESTED,
    DELETE_CRM_INTERNAL_ORDER_SUCCESS,
    EDIT_CRM_INTERNAL_ORDER_FAILED,
    EDIT_CRM_INTERNAL_ORDER_REQUESTED,
    EDIT_CRM_INTERNAL_ORDER_SUCCESS,
    REGISTER_CRM_INTERNAL_ORDER_FAILED,
    REGISTER_CRM_INTERNAL_ORDER_REQUESTED,
    REGISTER_CRM_INTERNAL_ORDER_SUCCESS
} from "../../../../eventbus/crmInternalOrderEvents";

const crmInternalOrderReducer = createSlice({
    name: 'internalOrderReducer',
    initialState: {
        internalOrders: [],
        count: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        orderList: (state, action) => {
            state.internalOrders = action.payload
        },
        getCount: (state, action) => {
            state.count = action.payload
        },
        updateFilterInternalOrder: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    }
})


export const changeInternalOrderFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(crmInternalOrderReducer.actions.updateFilterInternalOrder({...params}))
}

export const loadCrmInternalOrdersListAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    CrmInternalOrderService.getInternalOrders(params).then(res => {
        dispatch(crmInternalOrderReducer.actions.orderList(res.data))
    }).catch(e => {
        console.log(e)
    })
}

export const loadCrmInternalOrderAsync = (id) => {
    return new Promise((resolve, reject) => {
        CrmInternalOrderService.getInternalOrder(id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            });
    });
}

export const registerInternalOrderAsync = (payload) => {
    EventBus.dispatch(REGISTER_CRM_INTERNAL_ORDER_REQUESTED, payload)
    return new Promise((resolve, reject) => {
        CrmInternalOrderService.registerCrmInternalOrder(payload)
            .then(response => {
                resolve(response.data)
                EventBus.dispatch(REGISTER_CRM_INTERNAL_ORDER_SUCCESS, response.data)
            })
            .catch(error => {
                reject(error)
                EventBus.dispatch(REGISTER_CRM_INTERNAL_ORDER_FAILED, error)
            })
    })

}

export const updateInternalOrderAsync = (id, payload) => {
    EventBus.dispatch(EDIT_CRM_INTERNAL_ORDER_REQUESTED, payload)
    return new Promise((resolve, reject) => {
        CrmInternalOrderService.updateCrmInternalOrder(id, payload)
            .then(response => {
                resolve(response.data)
                EventBus.dispatch(EDIT_CRM_INTERNAL_ORDER_SUCCESS, response.data)
            })
            .catch(error => {
                reject(error)
                EventBus.dispatch(EDIT_CRM_INTERNAL_ORDER_FAILED, error)
            })
    })

}

export const getCountInternalOrder = () => (dispatch) => {
    CrmInternalOrderService.internalOrderCount()
        .then(res => {
            dispatch(crmInternalOrderReducer.actions.getCount(res.data))
        })
        .catch(e => {
            console.log(e)
        })
}

export const onDeleteInternalOrder = (id) => {
    EventBus.dispatch(DELETE_CRM_INTERNAL_ORDER_REQUESTED)
    return new Promise((resolve, reject) => {
        CrmInternalOrderService.deleteInternalOrder(id)
            .then(res => {
                EventBus.dispatch(DELETE_CRM_INTERNAL_ORDER_SUCCESS)
                resolve(res.data)
            }).catch(e => {
            reject(e)
            EventBus.dispatch(DELETE_CRM_INTERNAL_ORDER_FAILED)
        })
    })
}

export const onItemDelete = (payload) => {
    return new Promise((resolve, reject) => {
        CrmInternalOrderService.itemDelete(payload)
            .then(res => resolve(res.data)).catch((e) => reject(e))
    })
}

export const internalOrdersList = (state) => state.crmInternalOrder.internalOrders

export const selectFilterOptions = state => state.crmInternalOrder.filterOptions;

export const selectCount = state => state.crmInternalOrder.count;

export const {onRequestOrderItem} = crmInternalOrderReducer.actions

export default crmInternalOrderReducer.reducer
