import dayjs from "dayjs";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import Flex from "../../../common/Flex";
import Button from "react-bootstrap/Button";
import SelectWarehouse from "./SelectWarehouse";
import IconButton from "../../../common/IconButton";
import {Form, Offcanvas, Table} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {faPenToSquare, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {revisionRegisterAsync} from "../../../../app/store/reducers/warehouse-operation/revisionReducer";

const BulkItemRevision = ({items}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const warehouses = useSelector(selectWarehouses);
    const lang = useSelector(selectLang);
    const t = Translate;

    const allowItemsRevision = warehouses.length > 0;
    const showItemWarehousesSelector = warehouses.length > 1;

    const {control, getValues, setValue, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            warehouseId: warehouses.length === 1 ? warehouses[0].id : null,
            items: [],
        },
    });

    const {fields: itemsField} = useFieldArray({
        name: 'items',
        keyName: 'index',
        control: control,
    });

    useEffect(() => {
        if (items.length > 0) {
            const selectedWarehouseId = getValues('warehouseId');

            setValue('items', items.filter(item => !!item).map(item => {
                const itemWarehouse = {
                    itemId: item.item.id,
                    itemName: item.item.name,
                    itemWarehouseCurrentState: null,
                    itemWarehouseNewState: null,
                };

                if (selectedWarehouseId) {
                    const selectedWarehouseInfo = item.warehouse_states?.warehouse_items.find(w => w.id === selectedWarehouseId);
                    itemWarehouse.itemWarehouseCurrentState = selectedWarehouseInfo.state;
                    itemWarehouse.itemWarehouseNewState = selectedWarehouseInfo.state;
                }

                if (item.warehouse_states?.warehouse_items?.length === 1) {
                    const [defaultWarehouse] = item.warehouse_states?.warehouse_items;

                    itemWarehouse.itemWarehouseCurrentState = defaultWarehouse.state;
                    itemWarehouse.itemWarehouseNewState = defaultWarehouse.state;
                    return itemWarehouse;
                }

                return itemWarehouse;
            }));
        } else {
            setValue('items', []);
        }

    }, [items])

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const findItemWarehouse = (itemId, itemWarehouseId) => {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.item.id === itemId) {
                for (let j = 0; j < item.warehouse_states.warehouse_items.length; j++) {
                    const itemWarehouse = item.warehouse_states.warehouse_items[j];
                    if (itemWarehouse.id === itemWarehouseId) {
                        return itemWarehouse;
                    }
                }
            }
        }

        return null;
    };

    const onSelectWarehouse = (warehouse, onFieldChange) => {
        const selectedWarehouseId = warehouse?.id || null;
        const fieldItems = getValues('items');

        if (selectedWarehouseId) {
            const newFieldItems = fieldItems.map(fieldItem => {
                const itemWarehouse = findItemWarehouse(fieldItem.itemId, selectedWarehouseId);

                return {
                    ...fieldItem,
                    itemWarehouseCurrentState: itemWarehouse?.state,
                    itemWarehouseNewState: itemWarehouse?.state,
                };
            });

            setValue('items', newFieldItems)
        } else {
            const newFieldItems = fieldItems.map(fieldItem => {
                return {
                    ...fieldItem,
                    itemWarehouseCurrentState: null,
                    itemWarehouseNewState: null,
                };
            });

            setValue('items', newFieldItems);
        }

        onFieldChange(selectedWarehouseId);
    };

    const onSubmit = async (formData) => {
        const {items: fieldItems, warehouseId} = formData;

        const payload = {
            date: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            approve: true,
            items: fieldItems.map(fieldItem => {
                return {
                    item_id: fieldItem.itemId,
                    warehouse_id: warehouseId,
                    quantity: Number(fieldItem.itemWarehouseNewState),
                };
            }),
        }

        setLoading(true);
        revisionRegisterAsync(payload)
            .then(() => {
                toast.success(t(lang, 'items.common.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'items.common.toast.error')))
            .finally(() => setLoading(false))

    }

    return (
        <Fragment>
            {allowItemsRevision && (
                <IconButton
                    size={'sm'}
                    icon={faPenToSquare}
                    onClick={handleShow}
                    variant={'falcon-danger'}
                    className={'m-1'}
                >
                    {t(lang, 'items.bulk_item_revision.title')}
                </IconButton>
            )}

            <Offcanvas show={show} onHide={handleClose} placement={'end'} style={{width: "600px"}}>
                <Offcanvas.Header className={'bg-light'}>
                    <Flex justifyContent={'between'} className={'w-100'}>
                        <Offcanvas.Title>{t(lang, 'items.bulk_item_revision.title')}</Offcanvas.Title>

                        <div>
                            <Button size={'sm'} className={'mx-1'} variant={'secondary'} onClick={handleClose}>{t(lang, 'items.common.back')}</Button>
                            <Button size={'sm'} className={'mx-1'} variant={'warning'} type={'submit'} form={'revision-form'} disabled={loading}>
                                {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                {t(lang, 'items.common.save')}
                            </Button>
                        </div>
                    </Flex>
                </Offcanvas.Header>
                {showItemWarehousesSelector && (
                    <Offcanvas.Header className={'py-1 bg-light'}>
                        <Form.Group className={'w-100'}>
                            <Form.Label>Склад</Form.Label>
                            <Controller
                                control={control}
                                name={'warehouseId'}
                                rules={{required: t(lang, "items.common.validations.is_required")}}
                                render={({field}) => (
                                    <SelectWarehouse
                                        defaultWarehouseId={field.value}
                                        selectProps={{
                                            isClearable: true,
                                            className: classNames('w-100', {'is-invalid border border-danger rounded-2': errors?.warehouseId}),
                                        }}
                                        onChange={warehouse => onSelectWarehouse(warehouse, field.onChange)}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type={'invalid'}>
                                {errors?.warehouseId?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Offcanvas.Header>
                )}
                <Offcanvas.Body className={'p-0'}>
                    <Form id={'revision-form'} onSubmit={handleSubmit(onSubmit)}>
                        <Table size={'sm'}>
                            <thead className={'position-sticky top-0 bg-white border-bottom'}>
                            <tr>
                                <th className={'text-start'}>{t(lang, 'items.bulk_item_revision.table.column.name')}</th>
                                <th className={'text-center'}>{t(lang, 'items.bulk_item_revision.table.column.current_state')}</th>
                                <th className={'text-end'}>{t(lang, 'items.bulk_item_revision.table.column.new_state')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {itemsField.map((itemField, index) => (
                                <tr key={itemField.index}>
                                    <td className={'text-start fs--1 text-black fw-semi-bold'} title={itemField.itemName}>
                                        {itemField.itemName}
                                    </td>
                                    <td className={'text-center'}>
                                        {itemField.itemWarehouseCurrentState !== null ? itemField.itemWarehouseCurrentState : "склад не выбран"}
                                    </td>
                                    <td className={'text-end'} style={{maxWidth: "120px", minWidth: "120px"}}>
                                        <Controller
                                            control={control}
                                            name={`items.${index}.itemWarehouseNewState`}
                                            render={({field}) => (
                                                <Cleave
                                                    value={field.value}
                                                    className={'form-control'}
                                                    onChange={event => field.onChange(event.target.rawValue)}
                                                    options={{numeral: true, delimiter: ' ', numeralDecimalScale: 3}}
                                                />
                                            )}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default BulkItemRevision;