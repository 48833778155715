import  {Fragment} from "react";
import {Tab, Tabs, Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import Permissions from "./Permissions";
import {EdiDefaultPermissions} from "../../../../enum/Permission/EdiPermission";
import {RoamingDefaultPermissions} from "../../../../enum/Permission/RoamingPermission";
import {AnotherDefaultPermission} from "../../../../enum/Permission/RolePermission";
import {changeDefaultRole} from "../../../../app/store/reducers/role/roleReducer";

const EDI = 1
const Roaming = 2
const Another  = 3
const PermissionsDatatable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <h5 className={"ps-1 text-center my-2"}>{t(lang, "edi.permission.role_permissions_title")}</h5>
            <Tabs fill defaultActiveKey={EDI}>
                <Tab eventKey={EDI} title={'EDI'}>
                    <Permissions permission={EdiDefaultPermissions} name={'edi'} />
                </Tab>
                <Tab title={t(lang, 'edi.navbar.routes.edo')} eventKey={Roaming}>
                    <Permissions permission={RoamingDefaultPermissions} name={'roaming'} />
                </Tab>
                <Tab title={t(lang, 'main.top.nav.profile_dropdown.dropdown_item.miscellaneous')}  eventKey={Another}>
                    <Permissions permission={AnotherDefaultPermission} name={'another'} />
                </Tab>
            </Tabs>
        </Fragment>
    )
}
export default PermissionsDatatable
