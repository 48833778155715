import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {returnInvoiceRegisterAsync,} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import ReturnInvoiceForm from "./ReturnInvoiceForm";
import {useHistory} from "react-router-dom";
import {saveBankInfo, saveProductCatalogs,} from "../../../../app/store/reducers/invoice/invoiceReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ReturnInvoiceRegisterForm = ({returnShipment, productMapNames}) => {
    const routerHistory = useHistory();
    const lang = useSelector(selectLang);
    const t = Translate;

    // default values of form
    const methods = useForm({
        defaultValues: {
            returnInvoiceInfo: {
                number: returnShipment.return_shipment_info.number,
                date: dayjs(returnShipment.return_shipment_info.date).toDate(),
            },
            contractInfo: {
                number: returnShipment.contract_info.number,
                date: dayjs(returnShipment.contract_info.date).toDate(),
            },
            returnShipmentInfo: {
                number: returnShipment.return_shipment_info.number,
                date: dayjs(returnShipment.return_shipment_info.date).toDate()
            },
            customer: {
                inn: returnShipment.customer_info.inn,
                name: returnShipment.customer_info.name,
                address: '',
                oked: '',
                ndsCode: '',
                bankName: '',
                account: '',
                mfo: ''
            },
            executor: {
                inn: returnShipment.executor_info.inn,
                name: returnShipment.executor_info.name,
                address: '',
                oked: '',
                ndsCode: '',
                bankName: '',
                account: '',
                mfo: '',
            },
            proxyInfo: {
                name: '',
                number: '',
                date: null,
            },
            strategies: {
                contract_define_method_strategy: returnShipment.return_order.contract_define_method_strategy,
                executor_create_invoice_strategy: returnShipment.return_order.executor_create_invoice_strategy,
                item_quantity_limit_strategy: returnShipment.return_order.item_quantity_limit_strategy,
                product_name_define_method_strategy: returnShipment.return_order.product_name_define_method_strategy,
                shipment_date_method_strategy: returnShipment.return_order.shipment_date_method_strategy
            },
            director: '',
            accountant: '',
            releaser: '',
            loadingAddress: returnShipment.loading_address,
            items: returnShipment.items.map(item => {
                return {
                    id: item.id,
                    product: item.product,
                    barcode: item.barcode,
                    measurement: item.measurement,
                    quantity: item.quantity_left,
                    price: item.price,
                    total: item.total,
                    ndsRate: item.nds_rate,
                    ndsValue: item.nds_value,
                    totalWithNds: item.total_with_nds,

                    usePackage: false,
                    packageNames: [],
                    catalogCode: null,
                    catalogName: null,
                    origin: null,
                    packageCode: null,
                    packageName: null,
                    benefitId: null,
                    benefitName: null,
                    benefitType: null,
                    benefitPrice: null,
                }
            }),
            returnInvoiceTotalSum: 0,
        },
    });

    // button loader
    const [loading, setLoading] = useState(false);

    // unique number validation
    const [existingNumber, setExistingNumber] = useState('');

    // submit
    const onSubmit = (formData) => {
        setLoading(true);
        returnInvoiceRegisterAsync({
            return_shipment_id: returnShipment.id,
            items: formData.items.filter(item => item.quantity > 0)
                .map(item => {
                    return {
                        product: item.product,
                        return_shipment_item_id: item.id,
                        quantity: +item.quantity,
                        measurement: item.measurement,
                        package_code: item.packageCode ? item.packageCode.toString() : null,
                        package_name: item.packageName || null,
                        catalog_class_code: item.catalogCode || null,
                        catalog_class_name: item.catalogName || null,
                        benefit: item.benefitId || null,
                        benefit_name: item.benefitName || null,
                        benefit_type: +item.benefitType || null,
                        benefit_vat_sum: +item.benefitPrice,
                        barcode: item.barcode,
                        origin: +item.origin
                    }
                }),
            return_invoice_info: {
                number: formData.returnInvoiceInfo.number,
                date: dayjs(formData.returnInvoiceInfo.date).format("YYYY-MM-DD"),
            },
            invoice_info: {
                id: formData.invoiceInfo.id,
                date: dayjs(formData.invoiceInfo.date).format("YYYY-MM-DD"),
                number: formData.invoiceInfo.number,
                roaming_unique_id: formData.invoiceInfo.roaming_unique_id
            },
            contract_info: {
                number: formData.contractInfo.number,
                date: dayjs(formData.contractInfo.date).format("YYYY-MM-DD")
            },
            customer: {
                inn: formData.customer.inn || null,
                name: formData.customer.name || null,
                address: formData.customer.address || null,
                oked: formData.customer.oked || null,
                nds_registration_code: formData.customer.ndsCode || null,
                bank_info: {
                    account: formData.customer.account || null,
                    mfo: formData.customer.mfo || null,
                    name: formData.customer.bankName || null
                }
            },
            executor: {
                inn: formData.executor.inn || null,
                name: formData.executor.name || null,
                address: formData.executor.address || null,
                oked: formData.executor.oked || null,
                nds_registration_code: formData.executor.ndsCode || null,
                bank_info: {
                    account: formData.executor.account || null,
                    mfo: formData.executor.mfo || null,
                    name: formData.executor.bankName || null
                },
            },
            proxy_info: {
                name: formData.proxyInfo.name,
                number: formData.proxyInfo.number,
                date: formData.proxyInfo.date ? dayjs(formData.proxyInfo.date).format("YYYY-MM-DD") : null,
            },
            director: formData.director,
            accountant: formData.accountant,
            releaser: formData.releaser,
        })
            .then((returnInvoice) => {
                setLoading(false);

                const customerBankInfo = {
                    identifier: returnInvoice.customer.inn,
                    bankInfo: {
                        name: returnInvoice.customer.bank_info.name,
                        mfo: returnInvoice.customer.bank_info.mfo,
                        account: returnInvoice.customer.bank_info.account
                    }
                };
                saveBankInfo(customerBankInfo);

                const executorBankInfo = {
                    identifier: returnInvoice.executor.inn,
                    bankInfo: {
                        name: returnInvoice.executor.bank_info.name,
                        mfo: returnInvoice.executor.bank_info.mfo,
                        account: returnInvoice.executor.bank_info.account
                    }
                };
                saveBankInfo(executorBankInfo);

                // map items array and save catalog, package
                const productKeys = returnInvoice.items.map((item) => {
                    return {
                        product: item.product,
                        catalogClassCode: item.catalog_class_code,
                        packageCode: item.package_code || "",
                        benefitId: item.benefit || "",
                        origin: item.origin
                    }
                });
                saveProductCatalogs(productKeys);

                // success event and route redirect
                toast.success(`Invoice ${returnInvoice.return_invoice_info.number} successfully added !`);
                routerHistory.push(`/edi/return-invoice/${returnInvoice.id}`);
            })
            .catch((error) => {
                setLoading(false);
                if (typeof error === 'string' && error.includes('incorrect quantity value')) {
                    toast.error(t(lang, 'edi.return_invoice.register.toast.error.existing.invoice'))
                } else {
                    toast.error(t(lang, 'edi.return_invoice.register.toast.error'))
                }

                if (error?.number_exists) {
                    setExistingNumber(formData.returnInvoiceInfo.number)
                    methods.trigger()
                }

            })
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <ReturnInvoiceForm loading={loading}
                                   productMapNames={productMapNames}
                                   existingNumber={existingNumber}
                />
            </Form>
        </FormProvider>
    )
};

ReturnInvoiceRegisterForm.propTypes = {
    returnShipment: PropTypes.object,
};

ReturnInvoiceRegisterForm.defaultProps = {
    returnShipment: {},
};

export default ReturnInvoiceRegisterForm;
