import React, {Fragment} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useFieldArray, useFormContext} from "react-hook-form";
import {Card, Col, Row, Table} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import RoamingConstructionInvoiceFormItemRow from "./RoamingConstructionInvoiceFormItemRow";

const RoamingConstructionInvoiceFormCardItems = () => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const form = useFormContext();
    const {fields, append, insert, remove,} = useFieldArray({name: 'constructionsProductList'});

    const addNewItem = () => {
        append({
            name: '',
            cipher_number: '',
            catalog_code: '',
            catalog_name: '',
            measurement: '',
            indicators: {
                totally: 0,
                current_year: 0
            },
            contract_prices: {
                totally: 0,
                current_year: 0
            },
            from_start: {
                physically: 0,
                percentage: 0,
                contract_price: 0,
            },
            current_year: {
                physically: 0,
                percentage: 0,
                contract_price: 0,
            },
            current_month: {
                physically: 0,
                percentage: 0,
                contract_price: 0,
            },
        })
    }

    const deleteItem = (index) => remove(index);
    const onBulkSetCatalog = (value) => {
        fields.map((field, index) => {
            form.setValue(`constructionsProductList.${index}.catalog_code`, value);
            form.setValue(`constructionsProductList.${index}.measurement`, null);
        });
    };

    return (
        <Fragment>
            <Card>
                <Card.Body className="">
                    <Row className="gy-2">
                        <Col xs={12} className="text-end">
                            <IconButton icon={faPlus}
                                        variant={'falcon-primary'}
                                        onClick={addNewItem}
                            >
                                {t(lang, 'roaming.common.add')}
                            </IconButton>
                        </Col>
                        <Col xs={12}>
                            <Table responsive striped bordered className="border-300">
                                <thead className="bg-200 text-dark">
                                <tr>
                                    <th rowSpan={3} style={{minWidth: '30px', width: '30px'}}
                                        className="p-0 align-middle text-center">#
                                    </th>
                                    <th rowSpan={3} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.product')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th rowSpan={3} style={{minWidth: '150px', width: '150px'}}
                                        className="p-0 align-middle text-center">
                                        <div className="d-flex flex-column">
                                            <span>
                                                {t(lang, 'roaming.common.items.catalog_class_code')}
                                                <span className="text-danger">*</span>
                                            </span>
                                            {/*<CatalogLinkSelector
                                                defaultCatalog={null}
                                                onChange={onBulkSetCatalog}
                                                linkProps={{
                                                    className: classNames('text-primary cursor-pointer fs--1')
                                                }}
                                            />*/}
                                        </div>
                                    </th>
                                    <th rowSpan={3} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.measurement_short')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th rowSpan={3} style={{minWidth: '120px', width: '120px'}}
                                        className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.cipher_number')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th colSpan={2} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.physical_indicators')}
                                    </th>
                                    <th colSpan={2} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.contract_prices')}
                                    </th>
                                    <th colSpan={9} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.completed.work')}
                                    </th>
                                    <th/>
                                </tr>
                                <tr>
                                <th rowSpan={2} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.all')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th rowSpan={2} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.current.year')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th rowSpan={2} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.all')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th rowSpan={2} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.current.year')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th colSpan={3} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.start_construction')}
                                    </th>
                                    <th colSpan={3} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.beginning_year')}
                                    </th>
                                    <th colSpan={3} className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.reporting_month')}
                                    </th>
                                    <th/>
                                </tr>
                                <tr>
                                <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.from_start')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.current_year')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.current_month')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.from_start')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.current_year')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.current_month')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.from_start')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.current_year')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.common.items.current_month')}
                                        <span className="text-danger">*</span>
                                    </th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                    {fields.map((field, index) => (
                                        <RoamingConstructionInvoiceFormItemRow
                                            key={field.id}
                                            index={index}
                                            field={field}
                                            deleteItem={deleteItem}
                                            allowDeleteItem={fields.length > 1}
                                            fieldName={`constructionsProductList.${index}`}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default RoamingConstructionInvoiceFormCardItems;
