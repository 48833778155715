import {createSlice} from "@reduxjs/toolkit";
import notificationService from "../../../../services/notificationService";
import EventBus from "../../../eventbus/EventBus";
import {
    REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_FAILED,
    REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_SUCCESS,
    UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_FAILED,
    UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_SUCCESS
} from "../../../eventbus/notificationEvents";

const notificationSlice = createSlice({
    name: "telegramNotification",
    initialState: {
        notificationData: [],
    },
    reducers: {
        getNotifications: (state, action) => {
            state.notificationData = action.payload
        }
    }

})

export const selectNotificationsData = (state) => state.telegramNotification.notificationData
export const loadNotificationsAsync = () => (dispatch) => {
    notificationService.getNotifications()
        .then((res) => dispatch(notificationSlice.actions.getNotifications(res.data)))
        .catch((e) => new Error(e))
}

export const registerNotification = ({phoneNumber, eventCodes}) => {
    return new Promise((resolve, reject) => {
        notificationService.registerNotification({phoneNumber: phoneNumber, eventCodes: eventCodes})
            .then((res) => {
                EventBus.dispatch(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_SUCCESS)
                resolve(res)
            })
            .catch((err) => {
                EventBus.dispatch(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_FAILED)
                reject(err)
            })
    })
}

export const updateNotificationAsync = ({phoneNumber, eventCodes}, id) => {
    return new Promise((resolve, reject) => {
        notificationService.updateNotification({phoneNumber: phoneNumber, eventCodes: eventCodes}, id)
            .then((res) => {
                EventBus.dispatch(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_SUCCESS)
                resolve(res)
            })
            .catch((err) => {
                EventBus.dispatch(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_FAILED)
                reject(err)
            })
    })
}

export const deleteNotificationAsync = (id) => {
    return new Promise((resolve, reject) => {
        notificationService.deleteNotification(id)
            .then((res) => resolve(res))
            .catch((e) => {
                new Error(e)
                reject(e)
            })
    })

}

export default notificationSlice.reducer