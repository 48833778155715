import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {registerCashbox, selectCashbox, updateCashbox} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import BindContractorToAccountForm from "../../crm/connecting-contractor/BindContractorToAccountForm";
import CashBoxesRegisterForm from "./CashBoxesRegisterForm";
import {toast} from "react-toastify";
import {selectDefaultSetting} from "../../../../app/store/reducers/settings/settingsReducer";

const CashBoxesRegisterModal = ({show, onHide, defaultValue}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const settings = useSelector(selectDefaultSetting)

    const [loading, setLoading] = useState(false)

    const methods = useForm({
        defaultValues: {
            name: "",
            branch_id: settings?.branch?.default_id || ""
        }
    })

    useEffect(() => {
        methods.setValue('name', defaultValue?.name)
        methods.setValue('branch_id', defaultValue?.branch?.id)
    }, [defaultValue])

    useEffect(() => {
        methods.setValue('branch_id', defaultValue?.branch?.id || settings?.branch?.default_id || "")
    }, [settings])

    const onSubmit = (data) => {
        const params = {...data, branch_id: data.branch_id?.id || data.branch_id}
        setLoading(true)
        if (defaultValue) {
            updateCashbox(params, defaultValue.id)
                .then(() => {
                    onHideModal()
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))
                .finally(() => setLoading(false))
        } else {
            registerCashbox(params)
                .then(() => {
                    onHideModal()
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))
                .finally(() => setLoading(false))
        }

    }

    const onHideModal = () => {
        onHide()
        methods.setValue('name', "")
        methods.setValue('branch_id', "")
    }

    return (
        <Modal show={show} onHide={onHideModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t(lang, defaultValue ? "cashbox.update" : "cashbox.register")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...methods}>
                    <Form id={'cashbox-form'} onSubmit={methods.handleSubmit(onSubmit)}>
                        <CashBoxesRegisterForm defaultValue={defaultValue}/>
                    </Form>
                </FormProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button type='button' variant='falcon-primary' onClick={onHide}>{t(lang, 'edi.common.button.close')}</Button>
                <Button type='submit' form={'cashbox-form'} variant='primary' disabled={loading}>
                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                    {t(lang, 'edi.common.button.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CashBoxesRegisterModal;
