import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {
    changeFilterContractorReturnOrdersAsync, loadContractorReturnOrdersAsync, loadContractorReturnOrdersCountAsync,
    selectContractorCountReturnOrders,
    selectContractorReturnOrderFilterOptions,
    selectContractorReturnOrders
} from '../../../../../../app/store/reducers/contractor/contractorReturnOrderReducer';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ContractorReturnOrderDataTableHeader from './ContractorReturnOrderDataTableHeader';
import {
    getStatusColorReturnOrder,
    GetStatusTextReturnOrder,
} from '../../../../../../enum/ReturnOrderStatus';
import {selectActiveOrganization} from '../../../../../../app/store/reducers/auth/authReducer';
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../../app/store/reducers/main/mainReducer";
import numeral from "numeral";
import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../../common/advance-table/AdvanceTableCustomPagination";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";

const ContractorReturnOrderDataTable = () => {
    const contractor = useSelector(selectCardContractor)
    const numberFormat = useSelector(selectNumberFormat);
    const returnOrders = useSelector(selectContractorReturnOrders)
    const count = useSelector(selectContractorCountReturnOrders)
    const filter = useSelector(selectContractorReturnOrderFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, "edi.return_order.datatable.header.row.status"),
            cellProps: {
                width: '80px',
                className: 'p-0'
            },
            Cell: rowData => {
                const {original} = rowData.row;

                return <div className="d-grid gap-2">
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {t(lang, GetStatusTextReturnOrder(original.status))}
                            </Tooltip>
                        }
                        placement="right"
                    >
                        <div className="d-grid gap-2">
                            <Button variant="primary"
                                    style={{backgroundColor: getStatusColorReturnOrder(original.status)}} as={Link}
                                    to={`/edi/return-order/${original.id}`}>
                                {original.info.number}
                            </Button>
                        </div>
                    </OverlayTrigger>
                </div>
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, "edi.return_order.datatable.header.row.date"),
            cellProps: {
                width: '80px',
            },
            Cell: rowData => {
                const {date} = rowData.row.original.info
                return <b>{dayjs(date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'total',
            Header: t(lang, "edi.invoice.datatable.header.row.total_sum"),
            cellProps: {
                width: '30px',
                className: 'text-end'
            },
            Cell: rowData => {
                const {items} = rowData.row.original
                let sum = 0
                for (let i = 0; i < items.length; i++)
                    sum -= items[i].total_with_nds
                return numeral.formats[numberFormat].format(sum)
            }
        },
        {
            accessor: 'branch.name',
            Header: t(lang, "edi.return_order.datatable.header.row.unloading_point"),
            cellProps: {
                // width: '120px',
                className: 'p-0 fw-bold'
            },
        },
        {
            accessor: 'expires_on',
            Header: t(lang, "edi.return_order.datatable.header.row.expire_date"),
            cellProps: {
                width: '80px',
            },
            Cell: rowData => {
                const {expires_on} = rowData.row.original
                return <b>{dayjs(expires_on, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "edi.return_order.datatable.header.row.contractor"),
            cellProps: {
                // width: '300px',
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer.inn


                if (isCustomer) {
                    return <>
                        {original.executor.name}
                    </>
                }

                return <>
                    {original.customer.name}
                </>
            }
        }
    ];

    useEffect(() => {
        dispatch(loadContractorReturnOrdersAsync({...filter, contractor_inn: contractor?.inn}))
        dispatch(loadContractorReturnOrdersCountAsync({...filter, contractor_inn: contractor?.inn}))
    }, [contractor, filter])

    const onPageChange = async (page) => {
        dispatch(changeFilterContractorReturnOrdersAsync({page: page}))
    }
    const onLimitChange = async (limit) => {
        dispatch(changeFilterContractorReturnOrdersAsync({limit: limit, page: 1}))
    }
    return (
        <AdvanceTableWrapper
            columns={columns}
            data={returnOrders}
            sortable
            pagination
            perPage={1000}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ContractorReturnOrderDataTableHeader table/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filter.limit}
                        page={filter.page}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default ContractorReturnOrderDataTable;
