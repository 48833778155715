import React, {useLayoutEffect, useState} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {UZS} from "../../item/enum/itemWrapper";

const SelectCurrency = ({defaultCurrency, onChange, ...props}) => {

    const currencies = useSelector(selectCurrency);
    const [currency, setCurrency] = useState(null);
    const activeCurrency = currencies?.filter(x => x?.is_active)

    useLayoutEffect(() => {
        if (defaultCurrency) {
            if (defaultCurrency !== currency?.id) {
                setCurrency(currencies.find(c => c.id === defaultCurrency));
            }
        } else {
            let defaultCurrency = currencies.find(c => c.code === UZS);
            setCurrency(defaultCurrency);
            onChange(defaultCurrency);
        }
    }, [currency, defaultCurrency])

    return (
        <ReactSelect
            value={currency}
            options={activeCurrency}
            onChange={(option) => onChange(option)}
            isSearchable={false}
            hideSelectedOptions
            menuPlacement="auto"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            {...props}
        />
    );
};

export default SelectCurrency;
