export const REGISTER_CONTRACT_FORMATION_REQUESTED = "REGISTER_CONTRACT_FORMATION_REQUESTED"
export const REGISTER_CONTRACT_FORMATION_SUCCESS = "REGISTER_CONTRACT_FORMATION_SUCCESS"
export const REGISTER_CONTRACT_FORMATION_FAILED = "REGISTER_CONTRACT_FORMATION_FAILED"
export const UPDATE_CONTRACT_FORMATION_REQUESTED = "UPDATE_CONTRACT_FORMATION_REQUESTED"
export const UPDATE_CONTRACT_FORMATION_SUCCESS = "UPDATE_CONTRACT_FORMATION_SUCCESS"
export const UPDATE_CONTRACT_FORMATION_FAILED = "UPDATE_CONTRACT_FORMATION_FAILED"
export const SEND_CONTRACT_FORMATION_REQUESTED = "SEND_CONTRACT_FORMATION_REQUESTED"
export const SEND_CONTRACT_FORMATION_SUCCESS = "SEND_CONTRACT_FORMATION_SUCCESS"
export const SEND_CONTRACT_FORMATION_FAILED = "SEND_CONTRACT_FORMATION_FAILED"
export const SIGN_CONTRACT_FORMATION_REQUESTED = "SIGN_CONTRACT_FORMATION_REQUESTED"
export const SIGN_CONTRACT_FORMATION_SUCCESS = "SIGN_CONTRACT_FORMATION_SUCCESS"
export const SIGN_CONTRACT_FORMATION_FAILED = "SIGN_CONTRACT_FORMATION_FAILED"
export const UPDATE_ITEM_CONTRACT_FORMATION_REQUESTED = "UPDATE_ITEM_CONTRACT_FORMATION_REQUESTED"
export const UPDATE_ITEM_CONTRACT_FORMATION_SUCCESS = "UPDATE_ITEM_CONTRACT_FORMATION_SUCCESS"
export const UPDATE_ITEM_CONTRACT_FORMATION_FAILED = "UPDATE_ITEM_CONTRACT_FORMATION_FAILED"