import classNames from "classnames";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Form, Modal} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import React, {cloneElement, Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SelectContractorLabel from "../../crm/contractor/label/SelectContractorLabel";
import {bindTagToSaleAsync} from "../../../../app/store/reducers/warehouse-operation/saleReducer";

const AddSaleTagModal = ({saleId, children, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = React.useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {control, reset, formState: {errors}, handleSubmit} = useForm({
        defaultValues: {
            tagId: null,
        },
    })

    const handleShow = () => setShow(true);
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const onSubmit = (formData) => {
        setLoading(true);
        bindTagToSaleAsync({saleId: saleId, tagId: formData.tagId})
            .then(() => {
                toast.success(t(lang, 'common.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')))
            .finally(() => setLoading(false))
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <Button variant={'primary'} onClick={handleShow} size={'sm'} {...props}>{t(lang, 'Добавть тег')}</Button>
            )}

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'Привязать тег к продаже')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Controller
                            name={'tagId'}
                            control={control}
                            rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                            render={({field, fieldState}) => (
                                <SelectContractorLabel
                                    classNamePrefix={'react-select'}
                                    defaultContractorLabelId={field.value}
                                    placeholder={t(lang, 'items.common.select')}
                                    onChange={option => field.onChange(option ? option.id : null)}
                                    className={classNames({'is-invalid border border-danger rounded-2': fieldState.error})}
                                />
                            )}
                        />
                        <Form.Control.Feedback type={'invalid'}>
                            {errors?.tagId?.message}
                        </Form.Control.Feedback>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>
                            {t(lang, 'warehouse.operation.item.common.button.add')}
                        </Button>
                        <Button variant={'primary'} type={'submit'} disabled={loading}>
                            {loading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                            {t(lang, 'warehouse.operation.item.common.button.add')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default AddSaleTagModal;