export class ContractWrapperClass {
    _contract;
    constructor(contract) {
        this._contract = contract
    }
    
    enumStatuses = {
        sendToBuyer: 15,
        sellerIsCanceled: 17,
        buyerOrAgentIsDeclined: 20,
        signedByBothParties: 30
    };

    getRoamingStatus = (activeInn) => {
        let status;
        if (this._contract?.executor?.inn === activeInn) {
            switch (this._contract?.status) {
                case this.enumStatuses.sendToBuyer: // 15 - [executor, seller] sended to [customer, buyer]
                    status = "roaming.common.statuses.seller.15"
                    break;
                case this.enumStatuses.sellerIsCanceled: // 17 - [executor, seller] sended to [customer, buyer] but the seller canceled
                    status = "roaming.common.statuses.seller.17"
                    break;
                case this.enumStatuses.buyerOrAgentIsDeclined: // 20 - [executor, seller] sended to [customer, buyer] but [customer, buyer, person, agent] declined
                    status = "roaming.common.statuses.seller.20"
                    break;
                case this.enumStatuses.signedByBothParties: // 30 - [executor, seller] sended to [customer, buyer] and [customer, buyer, person, agent]
                    status = "roaming.common.statuses.seller.30"
                    break;
                default: // no such status exists
                    status = "roaming.common.statuses.not_exist"
                    break;
            }
        } else {
            switch (this._contract?.status) {
                case this.enumStatuses.sendToBuyer: // 15 - [executor, seller] sended to [customer, buyer]
                    status = "roaming.common.statuses.buyyer.15"
                    break;
                case this.enumStatuses.sellerIsCanceled: // 17 - [executor, seller] sended to [customer, buyer] but the seller canceled
                    status = "roaming.common.statuses.buyyer.17"
                    break;
                case this.enumStatuses.buyerOrAgentIsDeclined: // 20 - [executor, seller] sended to [customer, buyer] but [customer, buyer, person, agent] declined
                    status = "roaming.common.statuses.buyyer.20"
                    break;
                case this.enumStatuses.signedByBothParties: // 30 - [executor, seller] sended to [customer, buyer] and [customer, buyer, person, agent]
                    status = "roaming.common.statuses.buyyer.30"
                    break;
                default: // no such status exists
                    status = "roaming.common.statuses.not_exist"
                    break;
            }
        }
        return status
    }
    getRoamingStatusVariant = (activeInn) => {
        let status;
        if (this._contract?.executor?.inn === activeInn) {
            switch (this._contract?.status) {
                case 15: // [executor, seller] sended to [customer, buyer]
                    status = "primary"
                    break;
                case 17: // [executor, seller] sended to [customer, buyer] but the seller canceled
                    status = "danger"
                    break;
                case 20: // [executor, seller] sended to [customer, buyer] but [customer, buyer, person, agent] declined
                    status = "danger"
                    break;
                case 30: // [executor, seller] sended to [customer, buyer] and [customer, buyer, person, agent]
                    status = "success"
                    break;
                default: // no such status exists
                    status = "dark"
                    break;
            }
        } else {
            switch (this._contract?.status) {
                case 15: // [executor, seller] sended to [customer, buyer]
                    status = "warning"
                    break;
                case 17: // [executor, seller] sended to [customer, buyer] but the seller canceled
                    status = "danger"
                    break;
                case 20: // [executor, seller] sended to [customer, buyer] but [customer, buyer, person, agent] declined
                    status = "danger"
                    break;
                case 30: // [executor, seller] sended to [customer, buyer] and [customer, buyer, person, agent]
                    status = "success"
                    break;
                default: // no such status exists
                    status = "dark"
                    break;
            }
        }
        return status
    }

    allowCopy(activeInn) {
        return activeInn === this._contract?.executor?.inn
    }
    canTheSellerCancled(activeInn) {
      return this._contract?.status === this.enumStatuses.sendToBuyer && activeInn === this._contract?.executor?.inn
    }
    canTheBuyerSign(activeInn) {
      return this._contract?.contractors?.some(contractor => contractor?.status === this.enumStatuses.sendToBuyer && activeInn === contractor?.inn)
    }
    canTheBuyerDecline(activeInn) {
      return this._contract?.contractors?.some(contractor => contractor?.status === this.enumStatuses.sendToBuyer && activeInn === contractor?.inn)
    }
}
