import React from "react";
import {useSelector} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const ContractorLabelForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {register, watch, formState: {errors}} = useFormContext();

    const labelName = watch('name');
    const labelTextColor = watch('textColor');
    const labelBackgroundColor = watch('backgroundColor');

    return (
        <Row className={'g-3 mb-3'}>
            <Form.Group>
                <Form.Label>{t(lang, 'Результат')}:</Form.Label>
                <div className={'d-inline-block px-2 py-0 rounded-2 mx-1'} style={{background: labelBackgroundColor}}>
                    <span className={'fs--1 fw-semi-bold'} style={{color: labelTextColor}}>{labelName}</span>
                </div>
            </Form.Group>

            <hr className={'my-1'}/>

            <Form.Group as={Col} xs={12} className="text-start">
                <Form.Label>{t(lang, "edi.common.name")}</Form.Label>
                <Form.Control
                    placeholder={t(lang, "edi.common.name")}
                    name="name"
                    type="text"
                    isInvalid={errors?.name?.message}
                    isValid={Object.keys(errors).length > 0 && !errors?.name?.message}
                    {...register("name", {
                        required: {
                            value: true,
                            message: t(lang, "edi.common.forms.validations.is_required")
                        }
                    })}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.name?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={6}>
                <Form.Label>{t(lang, 'Text color')}</Form.Label>
                <Form.Control
                    {...register('textColor')}
                    type={'color'}
                    id={'exampleColorInput'}
                    title={'Choose your color'}
                />
            </Form.Group>

            <Form.Group as={Col} xs={6}>
                <Form.Label>{t(lang, 'Background color')}</Form.Label>
                <Form.Control
                    {...register('backgroundColor')}
                    type={'color'}
                    value={null}
                    id={'exampleColorInput'}
                    title={'Choose your color'}
                />
            </Form.Group>
        </Row>
    );
}
export default ContractorLabelForm