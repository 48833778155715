export const Sale = 1
export const Purchase = 2
export const Refund = 3
export const Return = 4
export const Revision = -1
export const WriteOff = -2
export const TransferIn = -3
export const TransferOut = -4

export const OperationTypes = [
    [
        {
            value: Sale,
            label: 'warehouse.items.history.operation.type.sale',
            variant: 'success'
        },
        {
            value: Purchase,
            label: 'warehouse.items.history.operation.type.purchase',
            variant: 'primary'
        },
    ],
    [
        {
            value: Refund,
            label: 'warehouse.items.history.operation.type.refund',
            variant: 'secondary'
        },
        {
            value: Return,
            label: 'warehouse.items.history.operation.type.return',
            variant: 'danger'
        },
    ],
    [
        {
            value: TransferIn,
            label: 'warehouse.items.history.operation.type.transferIn',
            variant: 'dark'
        },
        {
            value: TransferOut,
            label: 'warehouse.items.history.operation.type.transferOut',
            variant: 'dark'
        }
    ],
    [
        {
            value: WriteOff,
            label: 'warehouse.items.history.operation.type.writeOff',
            variant: 'warning'
        },
        {
            value: Revision,
            label: 'warehouse.items.history.operation.type.revision',
            variant: 'info'
        },
    ]
]

export const FindOperationType = (type) => {
    return OperationTypes.flat().find(lt => lt.value === type);
}

export const GetOperationLink = (type, operationId) => {
    switch (type) {
        case Sale:
            return `/warehouse/operation/sales/view/${operationId}`
        case Purchase:
            return `/warehouse/operation/purchases/view/${operationId}`
        case Refund:
            return `/warehouse/operation/refunds/view/${operationId}`
        case Return:
            return `/warehouse/operation/return-purchases/view/${operationId}`
        case Revision:
            return `/warehouse/operation/revisions/view/${operationId}`
        case WriteOff:
            return `/warehouse/operation/write-offs/view/${operationId}`
        case TransferIn:
            return `/warehouse/operation/transfers/view/${operationId}`
        case TransferOut:
            return `/warehouse/operation/transfers/view/${operationId}`
        default:
            return "";
    }
};
