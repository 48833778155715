import ReturnInvoiceDataTable from "../../../../components/hippo/return-invoice/ReturnInvoiceDataTable.js";
import React, {useEffect} from "react";
import {
    loadCountReturnInvoiceAsync,
    loadReturnInvoicesAsync, loadReturnInvoicesDataTableColumn, selectFilterOptions, selectReturnInvoicesDataTable
} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {setEdiReturnBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnInvoiceView} from "../../../../enum/Permission/EdiPermission";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const ReturnInvoices = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const activeColumns = useSelector(selectReturnInvoicesDataTable)
    const t = Translate;
    const lang = useSelector(selectLang);
    // important
    useSelector(selectAccountRolePermissions)

    useEffect(() => {
        dispatch(loadReturnInvoicesAsync({...filters, viewer_inn: activeOrganization.inn}))
        dispatch(loadCountReturnInvoiceAsync({...filters, viewer_inn: activeOrganization.inn}))
        dispatch(loadReturnInvoicesDataTableColumn())
    }, [filters, activeOrganization])

    useEffect(() => {
        dispatch(setEdiReturnBreadcrumbData(null))
    }, [])

    if (!dispatch(checkPermission(PermissionEdiReturnInvoiceView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.edi.return-invoice.datatable.no_access.title')}</h4>
        </Card>
    }

    return <ReturnInvoiceDataTable activeColumns={activeColumns}/>

};

export default ReturnInvoices;
