import dayjs from "dayjs";
import {CloseButton, Modal, Table} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import IconButton from "../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {faClockRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {getActionText, GetReasonText} from "../../../enum/ActionStatus";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {getReturnOrderProtocolInfoAsync, selectReturnOrderProtocolActions} from "../../../app/store/reducers/protocol/protocolReducer";

const ReturnOrderProtocolInfo = ({id}) => {
    const [show, setShow] = useState(false);
    const [actions, setActions] = useState([]);

    const returnOrderProtocolActions = useSelector(selectReturnOrderProtocolActions);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    useEffect(() => {
        dispatch(getReturnOrderProtocolInfoAsync(id));
    }, [id]);

    useEffect(() => {
        const data = returnOrderProtocolActions.map(action => {
            const reasonText = GetReasonText(action.action, action.reason);

            return {
                comment: action.comment,
                reason_text: t(lang, reasonText),
                date: dayjs(action.created_at).format(dateFormat),
                action_text: t(lang, getActionText(action.action)),
                return_shipment_number: action?.return_shipment?.number,
                time: dayjs(action.created_at).format('HH:mm:ss'),
            }
        });

        setActions(data);
    }, [returnOrderProtocolActions]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <IconButton
                size="sm"
                iconClassName="me-1"
                onClick={handleShow}
                variant="falcon-info"
                icon={faClockRotateLeft}
                className="ms-2 mb-2 mb-sm-0"
            >
                {t(lang, 'edi.return_order.protocol_info.title')}
            </IconButton>

            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Header className="bg-primary">
                    <Modal.Title className="text-white">{t(lang, 'edi.return_order.protocol_info.title')}</Modal.Title>
                    <CloseButton className="btn btn-circle text-white btn-sm p-0" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Table size="sm" hover className="fs--1 text-dark">
                        <thead className="bg-300">
                        <tr>
                            <th scope="col">{t(lang, 'edi.return_order.protocol_info.table.action')}</th>
                            <th scope="col">{t(lang, 'edi.return_order.protocol_info.table.date')}</th>
                            <th scope="col">{t(lang, 'edi.return_order.protocol_info.table.time')}</th>
                            <th scope="col">{t(lang, 'edi.return_order.protocol_info.table.reason')}</th>
                            <th scope="col">{t(lang, 'edi.return_order.protocol_info.table.comment')}</th>
                            <th scope="col">{t(lang, 'edi.return_order.protocol_info.table.shipment_number')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {actions.map((action, index) => (
                            <tr key={index}>
                                <td>{action.action_text}</td>
                                <td>{action.date}</td>
                                <td>{action.time}</td>
                                <td>{action.reason_text}</td>
                                <td>{action.comment}</td>
                                <td>{action.return_shipment_number}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ReturnOrderProtocolInfo;