import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from "../../../../enum/roaming/WaybillStatus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import WaybillDataTable from "../../../../components/hippo/roaming/waybill/datatable/WaybillDataTable";
import {
    getCarrierWaybillsCount,
    getCustomerWaybillsCount, getWaybillDraftsCount,
    loadCarrierWaybillsAsync,
    loadCustomerWaybillsAsync, loadWaybillDraftsAsync,
    selectWaybillsFilterOptions,
} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingShipmentView} from "../../../../enum/Permission/RoamingPermission";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const Waybills = () => {
    const {viewer} = useParams();
    const dispatch = useDispatch();
    const filter = useSelector(selectWaybillsFilterOptions);
    const activeOrganization = (useSelector(selectActiveOrganization));
    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;
    const t = Translate;
    const lang = useSelector(selectLang);
    //important
    useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadCustomerWaybillsAsync({...filter, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getCustomerWaybillsCount({viewer_inn: viewer_inn_or_pinfl}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadCarrierWaybillsAsync({...filter, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getCarrierWaybillsCount({viewer_inn: viewer_inn_or_pinfl}));
        } else if (viewer === VIEW_DRAFTS) {
            dispatch(loadWaybillDraftsAsync({...filter, viewer_inn: viewer_inn_or_pinfl}));
            dispatch(getWaybillDraftsCount({viewer_inn: viewer_inn_or_pinfl}));
        }
    }, [activeOrganization, filter, viewer])

    if(!dispatch(checkPermission(PermissionRoamingShipmentView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.roaming.waybills.datatable.no_access.title')}</h4>
        </Card>
    }

    return <WaybillDataTable viewer={viewer} />

};

export default Waybills;
