export const WAREHOUSE_OPERATION_TRANSFER_REGISTER_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_REGISTER_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_REGISTER_FAILED = "WAREHOUSE_OPERATION_TRANSFER_REGISTER_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_FAILED = "WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_FAILED = "WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_FAILED = "WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_FAILED = "WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_DELETE_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_DELETE_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_DELETE_FAILED = "WAREHOUSE_OPERATION_TRANSFER_DELETE_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_NOTE_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_NOTE_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_NOTE_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_NOTE_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_NOTE_FAILED = "WAREHOUSE_OPERATION_TRANSFER_NOTE_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_DATE_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_DATE_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_DATE_FAILED = "WAREHOUSE_OPERATION_TRANSFER_DATE_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_NUMBER_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_NUMBER_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_NUMBER_FAILED = "WAREHOUSE_OPERATION_TRANSFER_NUMBER_FAILED"

export const WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_REQUESTED = "WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_REQUESTED"
export const WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_SUCCESS = "WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_SUCCESS"
export const WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_FAILED = "WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_FAILED"