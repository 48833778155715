import React, {useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import FindPerson from "../../../person/FindPerson";
import PropTypes from "prop-types";

const RoamingInvoiceFormCardReleaserInfo = ({isEditOrCopy}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const [eventCount, setEventCount] = useState(0);

    const onChangePerson = (personInfo) => {
        // if is copy or edit, prevent from conflict of values
        {
            if (isEditOrCopy && eventCount === 0) {
                setEventCount(eventCount + 1);
                return;
            }
        }

        if (personInfo) {
            form.setValue(`itemReleasedDoc.itemReleasedPinfl`, personInfo.pinfl || personInfo.inn);
            form.setValue(`itemReleasedDoc.itemReleasedFio`, personInfo.name);
        } else {
            form.setValue(`itemReleasedDoc.itemReleasedPinfl`, '');
            form.setValue(`itemReleasedDoc.itemReleasedFio`, '');
        }
    };

    return (
        <Card className="h-100">
            <Card.Body>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm="4" md="12" lg="4">
                        {t(lang, 'roaming.invoice.releaser.pinfl')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={8} md={12} lg={8}>
                        <Controller name={`itemReleasedDoc.itemReleasedPinfl`}
                                    render={({field}) => (
                                        <FindPerson defaultInnOrPinfl={field.value}
                                                    onChange={onChangePerson}
                                                    placeholder={t(lang, 'roaming.invoice.releaser.pinfl.placeholder')}
                                        />
                                    )}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm="4" md="12" lg="4">
                        {t(lang, 'roaming.invoice.releaser.full_name')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={8} md={12} lg={8}>
                        <Form.Control name={`itemReleasedDoc.itemReleasedFio`}
                                      placeholder={t(lang, 'roaming.invoice.releaser.full_name.placeholder')}
                                      isInvalid={form.formState.errors?.itemReleasedDoc?.itemReleasedFio}
                                      {...form.register(`itemReleasedDoc.itemReleasedFio`, {
                                          required: {
                                              value: !!form.getValues(`itemReleasedDoc.itemReleasedPinfl`),
                                              message: t(lang, 'items.common.validations.is_required')
                                          }
                                      })}
                        />
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.itemReleasedDoc?.itemReleasedFio?.message}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};

export default RoamingInvoiceFormCardReleaserInfo;

RoamingInvoiceFormCardReleaserInfo.propTypes = {
    isEditOrCopy: PropTypes.bool
}

RoamingInvoiceFormCardReleaserInfo.defaultProps = {
    isEditOrCopy: false
}