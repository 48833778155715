import React, {cloneElement, Fragment, useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import EmployeeSelect from "../../../hippo/common/EmployeeSelect";
import {editTaskInColumnAssignee, selectModalContent} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {useParams} from "react-router";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SelectEmployeeModal = ({
                                 children,
                                 title, name,
                                 isRequired,
                                 confirmationAction,
                                 defaultCreator,
                                 edit,
                                 boardId,
                                 ...props
                            }) => {
    const {id} = useParams();

    const dispatch = useDispatch();
    const modalContent = useSelector(selectModalContent)
    const lang = useSelector(selectLang);
    const t = Translate;

    const {clearErrors, setError, formState: {errors}, setValue} = useFormContext();
    // is edit
    // const assigneId = useWatch({name: name, exact: true})
    const [employeeId, setEmployeeId] = useState(null)
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChangeEmployee = (employee, field) => {
        if (employee) {
            field.onChange(employee);
            isRequired && clearErrors(name);
            confirmationAction && confirmationAction()
            setShow(false)
        } else {
            field.onChange(null);
            isRequired && setError(name, {type: 'required', message: t(lang, 'task.board.common.forms.validations.is_required')});
        }
    }

    const handleEditAssignee = () => {
        const data = {
            board_column_id: modalContent?.board_column_id,
            board_column_task_id: modalContent?.id,
            assignee_id: employeeId?.id || null
        }
       dispatch(editTaskInColumnAssignee(boardId || id, data))
           .then((res) => {
               setValue('assignee_id', res.assignee_id)
               toast.success(t(lang, 'warehouse.operation.item.common.toast.success'))
               handleClose()
           })
           .catch((e) => {
               toast.error(t(lang, 'warehouse.operation.item.common.toast.error'))
           })
           .finally(() => {
               setLoading(false)
               setEmployeeId(null)
           })

    }

    return (
        <Fragment>

            {children ? cloneElement(children, {...children.props, onClick: defaultCreator ? confirmationAction : handleShow}) : (
                <IconButton variant={'falcon-primary'} size={'sm'} icon={'plus'} onClick={handleShow} {...props}>
                    {t(lang, 'task.board.modal.employee.btn.title')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t(lang, title)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Controller
                            name={name}
                            rules={{required: isRequired ? t(lang, "crm.common.forms.validations.is_required") : false}}
                            render={({field}) => (
                                <EmployeeSelect
                                    isClearable
                                    onChange={(employee) => {
                                        console.log(employee, 'employee')
                                        if (!edit) {
                                            handleChangeEmployee(employee, field)
                                        } else {
                                            setEmployeeId(employee)
                                        }
                                    }}
                                    defaultValue={field.value?.id || field.value}
                                />
                            )} />
                        <Form.Control.Feedback type="invalid" className={'d-block'}>
                            {errors[name]?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                {edit && <Modal.Footer>
                    <Button onClick={handleClose} variant="falcon-danger" className="mx-2">
                        {t(lang, "task.board.common.close")}
                    </Button>
                    <Button onClick={() => handleEditAssignee()} variant="falcon-success" className="mx-2">
                        {
                            loading ?
                                <Spinner className="align-middle me-2" animation="border" role="switch"
                                         size="sm"/>
                                : t(lang, "task.board.common.save")
                        }
                    </Button>
                </Modal.Footer>}
            </Modal>
        </Fragment>
    );
};

export default SelectEmployeeModal;
