import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorContractSlice = createSlice({
    name: 'contractorContract',
    initialState: {
        contracts: [],
        countContracts: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateContracts: (state, action) => {
            state.contracts = action.payload
        },
        updateCountContracts: (state, action) => {
            state.countContracts = action.payload
        },
        updateFilterContract: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorContractsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorContracts(params)
        .then(response => {
            dispatch(contractorContractSlice.actions.updateContracts(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const loadContractorContractsCountAsync = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    contractorService.getContractorContractsCount(other)
        .then(response => {
            dispatch(contractorContractSlice.actions.updateCountContracts(response.data))
        })
        .catch(error => console.log(error))
}
export const changeFilterContractorContractsAsync = (params) => (dispatch) => {
    return dispatch(contractorContractSlice.actions.updateFilterContract(params))
}
export const selectContractorContracts = (state) => state.contractorContract.contracts;
export const selectContractorContractsCount = (state) => state.contractorContract.countContracts;
export const selectContractorContractFilterOptions = (state) => state.contractorContract.filterOptions;
export default contractorContractSlice.reducer