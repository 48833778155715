import React, {Fragment} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ForgotPasswordStepFour = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <Row>
                <Col className='text-center'>
                    <Button as={Link} color="primary" className="px-5 my-3" to={`/`}>
                        {t(lang, 'authentication.forgot-password.success.log_in')}
                    </Button>

                    <h4 className="mb-1">{t(lang, 'authentication.forgot-password.success.title')}</h4>
                    <p className="fs-0">{t(lang, 'authentication.forgot-password.success.subtitle')}</p>
                </Col>
            </Row>
        </Fragment>
    )
};

export default ForgotPasswordStepFour;
