import React from 'react'
import {useSelector} from 'react-redux';
import {Button, Col, Form, Row} from 'react-bootstrap'
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller} from "react-hook-form";
import SelectStatus from "../../../SelectStatus";
import {useRouteMatch} from "react-router-dom";
import {EmpowermentWrapper} from "../../../../../enum/EmpowermentStatus";

const EmpowermentDataTableFilter = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {params: {viewer}} = useRouteMatch();
    const empowermentWrapper = new EmpowermentWrapper();

    return (
        <>
            <Row className="mb-3 g-3">
                <Col xs={12}>
                    <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.attorney.datatable.header.filter.title")}</h4>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <Form.Group>
                        <Form.Label>{t(lang, "edi.order.datatable.header.filter.status")}</Form.Label>
                        <Controller name="status"
                            render={({field}) => (
                                <SelectStatus statuses={empowermentWrapper.GetStatuses(viewer)}
                                              isClearable
                                              placeholder={t(lang, "edi.order.datatable.header.filter.status:placeholder")}
                                              onChange={statusId => field.onChange(statusId || null)}
                                />
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} className="text-end">
                    <Button variant="primary" type='submit'>{t(lang, "edi.attorney.datatable.header.filter.show")}</Button>
                </Col>
            </Row>
        </>
    )
}

export default EmpowermentDataTableFilter;