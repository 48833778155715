import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import {resumeOrderAsync} from "../../../app/store/reducers/order/orderReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import IconButton from "../../common/IconButton";


const ResumeOrder = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setLoading(false);
        setShow(false);
    };

    const resumeOrderHandler = () => {
        setLoading(true);

        resumeOrderAsync({id, comment})
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };


    return (
        <>
            <IconButton
                onClick={handleShow} 
                variant="falcon-primary" 
                size="sm"
                iconClassName="me-1"
                icon={'rotate'} 
                {...props}
            >{t(lang, 'edi.orders.order.resume_order_button')}</IconButton>
            
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "edi.orders.order.resume_order_modal.title")}</Modal.Title>
                    <FalconCloseButton onClick={() => handleClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, "edi.common.label.comment")}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder={t(lang, "edi.common.leave_comment")}
                            onChange={(event) => setComment(event.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "edi.common.cancel_button")}
                    </Button>
                    <Button variant="primary" className="d-flex align-items-center" disabled={loading} onClick={resumeOrderHandler}>
                        {loading && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                        {t(lang, "edi.orders.order.resume_order_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

};


ResumeOrder.propTypes = {
    id: PropTypes.string,
};
ResumeOrder.defaultProps = {
    id: '',
};
export default ResumeOrder;