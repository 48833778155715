import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Table} from "react-bootstrap";
import dayjs from "dayjs";
import Flex from "../../../common/Flex";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    Translate, updateShowInProgressAsync,
} from '../../../../app/store/reducers/main/mainReducer';
import SoftBadge from "../../../common/SoftBadge";
import {EmpowermentWrapper, ExecutorDeclined, PersonDeclined} from "../../../../enum/EmpowermentStatus";
import CustomerCancelEmpowerment from "./CustomerCancelEmpowerment";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import PersonAcceptEmpowerment from "./PersonAcceptEmpowerment";
import ExecutorAcceptEmpowerment from "./ExecutorAcceptEmpowerment";
import PersonDeclineEmpowerment from "./PersonDeclineEmpowerment";
import ExecutorDeclineEmpowerment from "./ExecutorDeclineEmpowerment";
import SyncEmpowerment from "./SyncEmpowerment";
import WarningAlert from "../components/WarningAlert";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CUSTOMER_CANCEL_EMPOWERMENT_FAILED,
    CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, EMPOWERMENT_SEND_FAILED, EMPOWERMENT_SEND_REQUESTED, EMPOWERMENT_SEND_SUCCESS,
    EXECUTOR_ACCEPT_EMPOWERMENT_FAILED,
    EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS,
    EXECUTOR_DECLINE_EMPOWERMENT_FAILED,
    EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS,
    PERSON_ACCEPT_EMPOWERMENT_FAILED,
    PERSON_ACCEPT_EMPOWERMENT_SUCCESS,
    PERSON_DECLINE_EMPOWERMENT_FAILED,
    PERSON_DECLINE_EMPOWERMENT_SUCCESS, SYNC_EMPOWERMENT_FAILED,
    SYNC_EMPOWERMENT_SUCCESS
} from "../../../../app/eventbus/roaming/roamingEmpowermentEvents";
import {loadMeasurementsAsync, selectMeasurements} from "../../../../app/store/reducers/roaming/roamingReducer";
import IconButton from "../../../common/IconButton";
import {Link, useHistory} from "react-router-dom";
import SelectCertificateWrapperComponent from "../components/SelectCertificateWrapperComponent";
import {faClone, faSignature} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {useParams} from "react-router";
import {sendEmpowermentAsync} from "../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import PrintEmpowerment from "./PrintEmpowerment";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingEmpowermentAccept,
    PermissionRoamingEmpowermentUpdate
} from "../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const Empowerment = ({empowerment, draftEmpowermentJson, modalId}) => {
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);
    const empowermentWrapper = new EmpowermentWrapper(empowerment);
    const [eventMessage, setEventMessage] = useState(null);
    const measurements = useSelector(selectMeasurements)
    const dispatch = useDispatch()
    const currentCertificate = useSelector(selectCurrentCertificate);
    const {id} = useParams();
    const history = useHistory();


    const allowCustomerCancel = empowermentWrapper.allowCustomerCancel(activeOrganization.inn, activeOrganization.pinfl);
    const allowPersonAccept = empowermentWrapper.allowPersonAccept(activeOrganization.inn, activeOrganization.pinfl);
    const allowPersonDecline = empowermentWrapper.allowPersonDecline(activeOrganization.inn, activeOrganization.pinfl);
    const allowExecutorAccept = empowermentWrapper.allowExecutorAccept(activeOrganization.inn, activeOrganization.pinfl);
    const allowExecutorDecline = empowermentWrapper.allowExecutorDecline(activeOrganization.inn, activeOrganization.pinfl);
    const allowCopy = empowermentWrapper.allowCustomerCopy(activeOrganization.inn, activeOrganization.pinfl);
    const allowSync = empowermentWrapper.allowSync();
    const showDeclineNotes = PersonDeclined === empowerment.status || ExecutorDeclined === empowerment.status

    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang));
        //success
        const onCustomerCancelEmpowermentSuccessHandler = EventBus.on(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_cancel_success')
            });
        })

        const onPersonAcceptEmpowermentSuccessHandler = EventBus.on(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_accept_success')
            });
        })

        const onPersonDeclineEmpowermentSuccessHandler = EventBus.on(PERSON_DECLINE_EMPOWERMENT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_decline_success')
            });
        })

        const onExecutorAcceptEmpowermentSuccessHandler = EventBus.on(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_accept_success')
            });
        })

        const onExecutorDeclineEmpowermentSuccessHandler = EventBus.on(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.empowerment.alert.empowerment_decline_success')
            });
        })

        const onSyncEmpowermentSuccessHandler = EventBus.on(SYNC_EMPOWERMENT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang,'roaming.empowerment.alert.success_event_title'),
                text: t(lang,'roaming.empowerment.alert.empowerment_sync_success')
            });
        })

        //fail
        const onCustomerCancelEmpowermentFailedHandler = EventBus.on(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        const onPersonAcceptEmpowermentFailedHandler = EventBus.on(PERSON_ACCEPT_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        const onPersonDeclineEmpowermentFailedHandler = EventBus.on(PERSON_DECLINE_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            })
        })

        const onExecutorAcceptEmpowermentFailedHandler = EventBus.on(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        const onExecutorDeclineEmpowermentFailedHandler = EventBus.on(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang,'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        const onSendEmpowermentRequestedHandler = EventBus.on(EMPOWERMENT_SEND_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onSendEmpowermentSuccessHandler = EventBus.on(EMPOWERMENT_SEND_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            history.push(`/roaming/empowerment/${modalId ? modalId : id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onSendEmpowermentFailedHandler = EventBus.on(EMPOWERMENT_SEND_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })

        const onSyncEmpowermentFailedHandler = EventBus.on(SYNC_EMPOWERMENT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.empowerment.alert.failed_event_title'),
                text: error.message
            });
        })

        return () => {
            EventBus.remove(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, onCustomerCancelEmpowermentSuccessHandler);
            EventBus.remove(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, onPersonAcceptEmpowermentSuccessHandler);
            EventBus.remove(PERSON_DECLINE_EMPOWERMENT_SUCCESS, onPersonDeclineEmpowermentSuccessHandler);
            EventBus.remove(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, onExecutorAcceptEmpowermentSuccessHandler);
            EventBus.remove(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, onExecutorDeclineEmpowermentSuccessHandler);

            EventBus.remove(CUSTOMER_CANCEL_EMPOWERMENT_FAILED, onCustomerCancelEmpowermentFailedHandler);
            EventBus.remove(PERSON_ACCEPT_EMPOWERMENT_FAILED, onPersonAcceptEmpowermentFailedHandler);
            EventBus.remove(PERSON_DECLINE_EMPOWERMENT_FAILED, onPersonDeclineEmpowermentFailedHandler);
            EventBus.remove(EXECUTOR_ACCEPT_EMPOWERMENT_FAILED, onExecutorAcceptEmpowermentFailedHandler);
            EventBus.remove(EXECUTOR_DECLINE_EMPOWERMENT_FAILED, onExecutorDeclineEmpowermentFailedHandler);

            EventBus.remove(EMPOWERMENT_SEND_REQUESTED, onSendEmpowermentRequestedHandler)
            EventBus.remove(EMPOWERMENT_SEND_SUCCESS, onSendEmpowermentSuccessHandler)
            EventBus.remove(EMPOWERMENT_SEND_FAILED, onSendEmpowermentFailedHandler)

            EventBus.remove(SYNC_EMPOWERMENT_SUCCESS, onSyncEmpowermentSuccessHandler);
            EventBus.remove(SYNC_EMPOWERMENT_FAILED, onSyncEmpowermentFailedHandler)
        }
    }, [])

    const getMeasure = measurementId => {
        return measurements.find(x => x.measureId === measurementId) || null
    }

    const signEmpowerment = () => {
        sendEmpowermentAsync(currentCertificate, lang, draftEmpowermentJson)
            .then(response => {})
            .catch(error => {})
    }


    return (
        <>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

            {!eventMessage && empowerment?.roaming_error && <WarningAlert text={empowerment?.roaming_error.message} title={'Ошибка от роуминга'} variant={'danger'}/>}
            <Card className="my-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang,"edi.common.created.time")}: {empowerment.created_at}</h3>
                    {empowerment.status &&
                        <Col className="text-end">
                            <span className="me-2">{t(lang, 'roaming.empowerment.status')}:</span>
                            <SoftBadge pill className="fs--2" bg={empowermentWrapper.GetStatusBadgeVariant(activeOrganization.inn, activeOrganization.pinfl)}>
                                {t(lang, empowermentWrapper.GetStatusTranslationKey(activeOrganization.inn, activeOrganization.pinfl))}
                            </SoftBadge>

                        </Col>
                    }
                </Row>
                <Row className="mt-3 d-flex">
                    <Col xs={12} md={6}>
                        {allowCopy &&
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon={faClone}
                                as={Link}
                                to={`/roaming/empowerment/actions/${empowerment.id}?status=${empowerment?.status || null}`}
                                className="mx-2 d-inline"
                        >
                            {t(lang, "roaming.common.copy")}
                        </IconButton>}

                        {
                            empowerment.status ?
                                <>
                                    {allowSync && <SyncEmpowerment className="mx-2" id={empowerment.id}/>}
                                    {allowCustomerCancel && <CustomerCancelEmpowerment className="mx-2" id={empowerment.id}/>}
                                    {allowPersonDecline && <PersonDeclineEmpowerment className="mx-2" id={empowerment.id} />}
                                    {allowPersonAccept && <PersonAcceptEmpowerment className="mx-2" id={empowerment.id}/>}
                                    {allowExecutorDecline && <ExecutorDeclineEmpowerment  className="mx-2" id={empowerment.id}/>}
                                    {allowExecutorAccept && <ExecutorAcceptEmpowerment className="mx-2" id={empowerment.id} />}
                                    <PrintEmpowerment id={empowerment.id} />
                                </>
                                :
                                <>
                                    <IconButton
                                        variant="falcon-warning"
                                        size="sm"
                                        icon={'edit'}
                                        iconClassName="me-1"
                                        type="submit"
                                        as={dispatch(checkPermission(PermissionRoamingEmpowermentUpdate)) ? Link : 'div'}
                                        onClick={()=>!dispatch(checkPermission(PermissionRoamingEmpowermentUpdate)) && dispatch(toastPermission())}
                                        to={`/roaming/empowerment/draft-edit/${modalId ? modalId : id}`}
                                    >{t(lang,"edi.common.button.edit")}</IconButton>
                                    <SelectCertificateWrapperComponent permission={PermissionRoamingEmpowermentAccept}
                                        submitButton={
                                            <IconButton onClick={signEmpowerment} id="sendButton" size="sm" className="mx-2" transform="shrink-3" variant="falcon-primary" icon={faSignature}>
                                                {t(lang, 'roaming.common.send')}
                                            </IconButton>
                                        }
                                        openDialogButtonText={t(lang, 'roaming.act_form_send')}
                                    />
                                </>
                        }
                    </Col>
                </Row>
            </Card>


            <Card className="p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} xl={3} className="d-flex flex-column">
                        <h5 className="fw-bold mb-1">{t(lang, 'edi.common.label.organization')}</h5>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {empowerment.customer.inn}</span>
                    </Col>

                    <Col xs={12} xl={3} className="d-flex flex-column my-3 my-xl-0">
                        <h5 className="fw-bold mb-1">{t(lang, 'edi.common.contractor')}</h5>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {empowerment.executor.inn}</span>
                    </Col>

                    <Col xs={12} xl={3} className="d-flex flex-column">
                        <h5 className="fw-bold mb-1">{t(lang, 'edi.attorney.issued_by')}</h5>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {empowerment.person_info.person_inn}</span>
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card className="my-3">
                <Card.Header>
                    <Row className="d-flex flex-column flex-lg-row align-items-md-center justify-content-lg-between">
                        <Col xs={12} md={8} lg={5} className="d-flex justify-content-center justify-content-lg-start">
                            <h5 className="p-0 ms-3"><b className="pe-1">{t(lang,"edi.common.id")}:</b>{empowerment.id}</h5>
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            {showDeclineNotes &&
                                <div className="alert-danger p-1 text-center">
                                    <span className="fw-bold me-2">{t(lang, "roaming.common.comment")}:</span>
                                    <strong>{empowerment.notes}</strong>
                                </div>
                            }
                        </Col>
                    </Row>

                    <Row className="my-4">
                        <h3 className="text-center">{t(lang, 'edi.common.attorney')}: {empowerment.info.number}</h3>
                        <Col className="d-flex flex-column flex-md-row justify-content-around">
                            <div>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold mb-1">{t(lang, "edi.common.shipment.number")}: </span>
                                    <span>{empowerment.info.number}</span>
                                </p>
                                <p className="mb-1">
                                    <strong className="me-2">{t(lang, 'roaming.empowerment.start_date')}:</strong>
                                    <span>
                                    {dayjs(empowerment.info.start, 'YYYY-MM-DD').format(dateFormat)}
                                </span>
                                </p>
                                <p className="mb-1">
                                    <strong className="me-2">{t(lang, 'roaming.empowerment.end_date')}:</strong>
                                    <span>{dayjs(empowerment.info.end, 'YYYY-MM-DD').format(dateFormat)}</span>
                                </p>
                            </div>
                            <div className="mt-3 mt-md-0">
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.contract.number")}: </span>
                                    <span>{empowerment.contract.number}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.contract.date")}: </span>
                                    <span>{dayjs(empowerment.contract.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                </p>
                            </div>
                        </Col>
                    </Row>


                    <Row className="align-items-center justify-content-center mb-5 flex-column flex-lg-row">
                        <Col xs={12} lg={6} className="align-items-center fs--1">
                            <div className="d-flex justify-content-between">
                                <b className="fs-1">{t(lang, 'edi.common.label.organization')}:</b>
                                <span className="col-8">{empowerment.customer.name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <b className="col-4">{t(lang, 'roaming.common.inn')}:</b>
                                <span className="col-8">{empowerment.customer.inn} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <b className="col-4">{t(lang, 'roaming.common.director')}:</b>
                                <span className="col-8">{empowerment.customer.director} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <b className="col-4">{t(lang, 'roaming.common.accountant')}:</b>
                                <span className="col-8">{empowerment.customer.accountant} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="align-items-center fs--1 my-4 my-lg-0">
                            <div className="d-flex justify-content-between">
                                <b className="fs-1">{t(lang, 'edi.common.contractor')}:</b>
                                <span className="col-8">{empowerment.executor.name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <b className="col-4">{t(lang, 'roaming.common.inn')}:</b>
                                <span className="col-8">{empowerment.executor.inn} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <b className="col-4">{t(lang, 'roaming.common.director')}:</b>
                                <span className="col-8">{empowerment.executor.director} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <b className="col-4">{t(lang, 'roaming.common.accountant')}:</b>
                                <span className="col-8">{empowerment.executor.accountant} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                        <Col xs={12} lg={8} className="align-items-center fs--1 mt-lg-4">
                            <div className="d-flex justify-content-between">
                                <b className="col-4 fs-1">{t(lang, 'roaming.empowerment.empowerment_person')}:</b>
                                <span className="col-8">{empowerment.person_info.full_name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <b className="col-4">{t(lang, 'edi.common.label.inn')}:</b>
                                <span className="col-8">{empowerment.person_info.person_inn} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <b className="col-4">{t(lang, 'roaming.empowerment.contract_material_assets')}:</b>
                                <span className="col-8">{t(lang, 'roaming.empowerment.contact_info.from.contact_date', {contract_number: empowerment.contract.number, contract_date: dayjs(empowerment.contract.date, 'YYYY-MM-DD').format(dateFormat)})} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                    </Row>

                </Card.Header>

                <Card.Footer>
                    <Flex justifyContent="center" className="mb-2">
                        <h6 className="fs-0">{t(lang, 'roaming.empowerment.items.title')}</h6>
                    </Flex>
                    <Table bordered responsive className="border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th className="text-center" rowSpan={2}>{t(lang, 'edi.attorney.data_table.product_number')}</th>
                            <th className="text-center">{t(lang, 'roaming.common.items.product')}</th>
                            <th className="text-center">{t(lang, 'roaming.common.items.catalog_class_code')}</th>
                            <th className="text-center">{t(lang, 'roaming.common.items.quantity')}</th>
                            <th className="text-center">{t(lang, 'roaming.common.items.measurement')}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                        </tr>
                        </thead>
                        <tbody>
                        {empowerment.items.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center mb-0 text-nowrap fw-semi-bold">{item.name}</td>
                                    <td className="text-center">{item.catalog.code}</td>
                                    <td className="text-center">{item.quantity}</td>
                                    <td className="text-center">{getMeasure(item.measure_id)?.name}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Card.Footer>
            </Card>
        </>
    );
};

export default Empowerment;
