import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import ProfileBanner from "../../ProfileBanner";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {selectEdiSettings, updateEdiSettings} from "../../../../../app/store/reducers/settings/ediSettingsReducer";
import CompanyBindings from "./CompanyBindings";

const EdiSettings = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ediSettings = useSelector(selectEdiSettings);

    // form
    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            invoice: {
                shouldValidateNumber: false,
            }
        }
    });

    // button loader
    const [loading, setLoading] = useState(false);

    // submit event
    const onSubmit = (formData) => {
        setLoading(true);

        const payload = {
            edi: {
                shipment: {
                    vehicles: ediSettings.shipment.vehicles
                },
                return_shipment: {
                    vehicles: ediSettings.return_shipment.vehicles
                },
                should_validate_invoice_number: formData.invoice.shouldValidateNumber
            },
        }

        updateEdiSettings({payload})
            .then((res) => {
                setLoading(false);
                toast.success(t(lang, "edi.common.toast.success"))
            })
            .catch((error) => {
                toast.error(t(lang, "edi.common.toast.error"))
                setLoading(false);
            })
    }


    useEffect(() => {
        if (ediSettings) {
            setValue("invoice.shouldValidateNumber", ediSettings.should_validate_invoice_number);
        } else {
            setValue("invoice.shouldValidateNumber", false);
        }
    }, [ediSettings])


    return (
        <ProfileBanner>
            <Card.Header>
                <Card.Title>{t(lang, "edi.user.settings.edi")}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col className="col-auto me-auto">
                            <h5 className="mb-3">{t(lang, "edi.navbar.routes.edo.invoice")}</h5>

                            <Form.Switch label={t(lang, "edi.user.settings.edi.shouldValidateNumber")}
                                         {...register('invoice.shouldValidateNumber')}
                            />
                        </Col>
                        <Col xs={12} className="text-end">
                            <Button type="submit" disabled={loading}>
                                {loading && <Spinner animation="border" size="sm" role="switch" className="align-middle me-2" style={{width: "65px", height: "65px"}}/>}
                                {t(lang, "edi.common.button.save")}
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <hr/>

                <CompanyBindings/>
            </Card.Body>
        </ProfileBanner>
    );
};

export default EdiSettings;