import React, {useEffect} from 'react';
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Card, Col, Row} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    changeFilterOptionsAsync,
    loadRoamingVerificationActsAsync,
    loadRoamingVerificationActsCountAsync,
    selectCount,
    selectVerificationActFilterOptions,
    selectRoamingVerificationActs,
    viewPage, setId, setShowModal
} from '../../../../app/store/reducers/roaming/roamingVerificationActReducer';
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import classNames from 'classnames';
import {GetViewerIcon, GetViewerText, VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from '../../../../enum/TypeViewers';
import Flex from '../../../common/Flex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';
import SoftBadge from '../../../common/SoftBadge';
import AdvanceTableCustomPagination from '../../../common/advance-table/AdvanceTableCustomPagination';
import numeral from 'numeral';
import {VerificationActWrapper} from "../../../../enum/VerificationActStatus";
import VerificationActDataTableHeader from "./VerificationActDataTableHeader";
import ViewModalPage from "../components/ViewModalPage";
import ViewVerificationAct from "../../../../pages/main/roaming/verification-act/ViewVerificationAct";
import ViewDraftVerificationAct from "../../../../pages/main/roaming/verification-act/ViewDraftVerificationAct";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";


const VerificationActDataTable = ({typeViewer}) => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const roamingVerificationActs = useSelector(selectRoamingVerificationActs);
    const filters = useSelector(selectVerificationActFilterOptions)
    const numberFormat = useSelector(selectNumberFormat);
    const count = useSelector(selectCount)
    const lang = useSelector(selectLang);
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)
    const dispatch = useDispatch();
    const t = Translate;
    const activeOrganizationPinflOrInn = activeOrganization.pinfl || activeOrganization.inn

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (typeViewer === VIEW_DRAFT) {
            if (ctrlKey) {
                history('/#/roaming/verification-act/draft/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }
        }else {
            if (ctrlKey) {
                history('/#/roaming/verification-act/'+id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'info',
            Header: t(lang, "roaming.verification_act.datatable.header.row.verification_act"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                return <strong className={'cursor-pointer text-primary'} onClick={(e)=>openViewModalPage(e,original.id)}>
                    {t(lang, "roaming.verification_act.datatable.body.row.verification_act", {
                        name: original?.info?.number,
                        date: dayjs(original?.info?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                    })}
                </strong>
            }
        },
        {
            accessor: 'contracts',
            Header: t(lang, "roaming.verification_act.datatable.header.row.contracts"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    original?.contracts?.map((contract, index) => {
                        if(VIEW_DRAFT !== typeViewer) {
                            return (
                                <span key={index}>
                                    {!!index && <br/>}{t(lang, "roaming.verification_act.datatable.body.row.contracts", {
                                        name: contract?.contract_no,
                                        date: dayjs(contract?.contract_date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                                    })}
                                </span>
                            )
                        } else {
                            return (
                                <span key={index}>
                                    {!!index && <br/>}{t(lang, "roaming.verification_act.datatable.body.row.contracts", {
                                        name: contract?.number,
                                        date: dayjs(contract?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                                    })}
                                </span>
                            )
                        }
                    })
                )
            }
        },
        (VIEW_DRAFT !== typeViewer && {
            accessor: 'status',
            Header: t(lang, "roaming.verification_act.datatable.header.row.status"),
            Cell: ({row: {original}}) => {
                const verificationActWrapper = new VerificationActWrapper(original)
                return <h5>
                    <SoftBadge bg={verificationActWrapper.getRoamingStatusVariant(activeOrganizationPinflOrInn)} className='me-2'>
                        {t(lang, verificationActWrapper.getRoamingStatus(activeOrganizationPinflOrInn))}
                    </SoftBadge></h5>
            }
        }),
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.verification_act.datatable.header.row.created_at"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => dayjs(original?.created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
        },
        {
            accessor: 'contractor',
            Header: t(lang, "roaming.verification_act.datatable.header.row.contractor"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return typeViewer === VIEW_INBOX
                    ? `${original?.executor?.name} (${original?.executor?.inn})`
                    : `${original?.customer?.name} (${original?.customer?.inn})`
            }
        },
        {
            accessor: 'total_sum',
            Header: t(lang, "roaming.verification_act.datatable.header.row.total_sum"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                if(original?.executor?.inn === activeOrganizationPinflOrInn){
                    return (
                        <strong>
                            {   original?.close_balance?.executor_debit
                                ? `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.debit")} ${numeral.formats[numberFormat].format((original?.close_balance?.executor_debit || 0))}`
                                : `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.credit")} ${numeral.formats[numberFormat].format((original?.close_balance?.executor_credit || 0))}`
                            }
                        </strong>
                    )
                } else {
                    return (
                        <strong>
                            {   original?.close_balance?.customer_debit
                                ? `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.debit")} ${numeral.formats[numberFormat].format((original?.close_balance?.customer_debit || 0))}`
                                : `${t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.credit")} ${numeral.formats[numberFormat].format((original?.close_balance?.customer_credit || 0))}`
                            }
                        </strong>
                    )
                }
            }
        }
    ].filter(i => i);

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }

    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }

    useEffect(() => {
        let params = {...filters, type_viewer: typeViewer, viewer_inn: activeOrganizationPinflOrInn}
        dispatch(loadRoamingVerificationActsAsync(params));
        dispatch(loadRoamingVerificationActsCountAsync({...params}))
    }, [filters, activeOrganization, typeViewer]);
    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Link to={`../verification-acts/${VIEW_INBOX}`} className="text-decoration-none">
                        <Card
                            className={classNames("p-3 cursor-pointer", {'bg-primary text-white': VIEW_INBOX === typeViewer})}
                        >
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                icon={GetViewerIcon(VIEW_INBOX)}
                                className={classNames("fs-2 me-lg-2")}
                                color="primary"
                                />
                                <Card.Title className={classNames("d-none d-lg-block mb-0", {'text-white': VIEW_INBOX === typeViewer, 'text-primary': VIEW_INBOX !== typeViewer})}
                                >{t(lang, GetViewerText(VIEW_INBOX))}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={`../verification-acts/${VIEW_OUTBOX}`} className="text-decoration-none">
                        <Card
                            className={classNames("p-3 cursor-pointer", {'bg-primary text-white': VIEW_OUTBOX === typeViewer})}
                        >
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                icon={GetViewerIcon(VIEW_OUTBOX)}
                                className={classNames("fs-2 me-lg-2")}
                                color="primary"
                                />
                                <Card.Title className={classNames("d-none d-lg-block mb-0", {'text-white': VIEW_OUTBOX === typeViewer, 'text-primary': VIEW_OUTBOX !== typeViewer})}
                                >{t(lang, GetViewerText(VIEW_OUTBOX))}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={`../verification-acts/${VIEW_DRAFT}`} className="text-decoration-none">
                        <Card
                            className={classNames("p-3 cursor-pointer", {'bg-primary text-white': VIEW_DRAFT === typeViewer})}
                        >
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                icon={GetViewerIcon(VIEW_DRAFT)}
                                className={classNames("fs-2 me-lg-2")}
                                color="primary"
                                />
                                <Card.Title className={classNames("d-none d-lg-block mb-0", {'text-white': VIEW_DRAFT === typeViewer, 'text-primary': VIEW_DRAFT !== typeViewer})}
                                >{t(lang, GetViewerText(VIEW_DRAFT))}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
            </Row>
            <AdvanceTableWrapper
                columns={columns}
                data={roamingVerificationActs}
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <VerificationActDataTableHeader table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>


            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewVerificationAct modalId={id} />}
                {draftId && <ViewDraftVerificationAct modalId={draftId} />}
            </ViewModalPage>

        </>
    );
}

export default VerificationActDataTable;
