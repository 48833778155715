import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useState} from 'react';
import {Button, Form, Row, Col, InputGroup} from 'react-bootstrap';
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useSelector} from 'react-redux';
import IconButton from "../IconButton";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

export const AdvanceTableCustomPagination = ({count, limit, page, onPageChange, onLimitChange, rowsPerPageOptions}) => {
    const [query, setQuery] = useState("");

    let maxPage = Math.ceil(count / limit);
    if(count === 0)
        maxPage = Math.ceil((count + 1)/limit);
    const lang = useSelector(selectLang);
    const t = Translate;
    const pages = [];

    const range = 2
    for (let i = page - range; i <= maxPage && i <= page + range; i++) {
        if (i >= 1 && i <= maxPage)
            pages.push(i)
    }
    const pageLimitOptions = [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "75", value: 75},
        {text: "100", value: 100},
    ]

    const onGoToPageClicked = () => {
        if (query && +query > 0 && +query <= maxPage)
            onPageChange(+query)
    }

    return (
        <Row className="d-flex flex-sm-row justify-content-xxl-end">
            <Col sm={12} xxl={5} className="d-flex align-items-center justify-content-center justify-content-xxl-end col-12">
                <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={() => onPageChange(page = 1)}
                    disabled={page === 1}
                    className="mx-1"
                >
                    |<FontAwesomeIcon icon="chevron-left"/>
                </Button>

                <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={() => onPageChange(page - 1)}
                    disabled={page === 1}
                >
                    <FontAwesomeIcon icon="chevron-left"/>
                </Button>
                <ul className="pagination mb-0 mx-1">
                    {pages.map(p => {
                        return <li key={p} className={classNames({'active': p === page})}>
                            <Button
                                size="sm"
                                variant="falcon-default"
                                className={classNames('page', 'me-1')}
                                onClick={() => {
                                    if (p !== page)
                                        onPageChange(p)
                                }}
                            >
                                {p}
                            </Button>
                        </li>
                    })}
                </ul>
                <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={() => onPageChange(page + 1)}
                    disabled={page === maxPage}
                >
                    <FontAwesomeIcon icon="chevron-right"/>
                </Button>

                <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={() => onPageChange(page = maxPage)}
                    disabled={page === maxPage}
                    className="mx-1"
                >
                    <FontAwesomeIcon icon="chevron-right"/>|
                </Button>
            </Col>
            <Col xs={10} sm={6} md={6} lg={"auto"} className="d-none justify-content-center align-items-center justify-content-xxl-end my-3 my-md-0 flex-column flex-sm-row">
                <InputGroup as={Form.Group} size="sm" className="w-75">
                    <p className="mx-2 mb-0 ">{t(lang, "edi.advance_table_custom_pagination.to")}</p>
                    <Form.Control
                        type="number"
                        name="customPagination"
                        className="input-spin-none"
                        onChange={event => setQuery(event.target.value)}
                    />

                    <IconButton icon={faArrowRight} onClick={onGoToPageClicked}/>
                </InputGroup>
            </Col>
            <Col sm={6} xxl={2} className=" ms-auto d-flex justify-content-center align-items-center justify-content-xxl-end col-6 flex-column flex-sm-row">
                <p className="mx-2 mb-0">{t(lang, "edi.advance_table_custom_pagination.limit")}</p>
                <Form.Select
                    size="sm"
                    onChange={e => onLimitChange(+e.target.value)}
                    selected
                    className="w-auto"
                    defaultValue={limit}
                >
                    {rowsPerPageOptions && rowsPerPageOptions.map(option => (
                        <option key={option}
                                value={option}>{option}</option>
                    ))}
                    {!rowsPerPageOptions && pageLimitOptions.map(option => (
                        <option key={option.value}
                                value={option.value}>{option.text}</option>
                    ))}
                </Form.Select>
            </Col>
        </Row>
    );
};

export default AdvanceTableCustomPagination;
