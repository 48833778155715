import React from 'react';
import BarcodeScanner from "react-barcode-reader/src";
import EventBus from "../../../../app/eventbus/EventBus";
import {SCAN_FAILED, SCAN_SUCCESS} from "../../../../app/eventbus/itemEvents";

const BarcodeReader = () => {
    // const onScan = (value) => EventBus.dispatch(SCAN_SUCCESS, value.slice(0, 14).replace(/^0+|$/g, ''));
    const onScan = (value) => EventBus.dispatch(SCAN_SUCCESS, value);
    const onError = (error) => EventBus.dispatch(SCAN_FAILED, error);

    return (
        <BarcodeScanner onScan={onScan}
                        onError={onError}
        />
    );
};

export default BarcodeReader;