import React from 'react';
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Card, Col, ProgressBar, Row, Spinner, Table} from "react-bootstrap";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ImportAttorneyProgressModal = ({length, failedAttorneys, results, loading}) => {
    const isAllSucceed = results.filter(res => res === true).length === length;
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <Row>
                <Col xs={12} className="text-center my-3">
                    {loading && (
                        <Spinner
                            animation="border"
                            className="mb-3"
                            variant="primary"
                            style={{ width: "65px", height: "65px" }}
                        />
                    )}

                    <div className="mt-5 w-100 w-sm-75 w-xl-50 px-sm-0 px-3 mx-auto">
                        <ProgressBar animated={loading} now={(100 / length) * results.length} style={{ height: 30 }} className="border border-400 shadow" variant="primary"/>
                    </div>

                    <Row className="g-3 mt-3 w-100 justify-content-center">
                        <Col xl={1} xs={12} sm={4} className="text-center">
                            <h4 className="fs-4 fw-medium text-700">
                                <p className="text-primary">{(results.filter(res => res === true)).length}</p>
                            </h4>
                            <h6 className="text-primary">( {t(lang, 'edi.attorney.mass_import.sending_modal.success_sent_count_title')} )</h6>
                        </Col>
                        <Col xl={2} xs={12} sm={4} className="text-center">
                            <h4 className="fs-4 fw-medium text-700">
                                <p>{results.length} из {length}</p>
                            </h4>
                            <h6>( {t(lang, 'edi.attorney.mass_import.sending_modal.total_sent_count_title')} )</h6>
                        </Col>
                        <Col xl={1} xs={12} sm={4} className="text-center">
                            <h4 className="fs-4 fw-medium text-700">
                                <p className="text-danger">{(results.filter(res => res === false)).length}</p>
                            </h4>
                            <h6 className="text-danger">( {t(lang, 'edi.attorney.mass_import.sending_modal.failed_sent_count_title')} )</h6>
                        </Col>
                    </Row>
                </Col>

                {failedAttorneys.length > 0 && (
                    <Col className="mt-3" xs={12}>
                        <Card className="shadow-none border">
                            <Card.Header className="bg-light">
                                <Card.Title className="text-danger">{t(lang, 'edi.attorney.mass_import.sending_modal.failed_sends')}</Card.Title>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <Table size="sm">
                                    <thead>
                                    <tr>
                                        <th>{t(lang, 'edi.attorney.mass_import.sending_modal.failed_sends.attorney_number')}</th>
                                        <th>{t(lang, 'edi.attorney.mass_import.sending_modal.failed_sends.attorney_date')}</th>
                                        <th>{t(lang, 'edi.attorney.mass_import.sending_modal.failed_sends.person')}</th>
                                        <th>{t(lang, 'edi.attorney.mass_import.sending_modal.failed_sends.person_position')}</th>
                                        <th>{t(lang, 'edi.attorney.mass_import.sending_modal.failed_sends.contractor')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {failedAttorneys.map((attorney, index) => (
                                        <tr key={index}>
                                            <td>{attorney.attorney_info.number}</td>
                                            <td>{dayjs(attorney.attorney_info.start, "YYYY-MM-DD").format(currentDateDayJSFormat)}</td>
                                            <td>
                                                {attorney?.person_info?.full_name}
                                                <span className="fw-black"> ({attorney.person_info.person_inn})</span>
                                            </td>
                                            <td>{attorney.person_info.position}</td>
                                            <td>
                                                {attorney.contractor_info.name}
                                                <span className="fw-black"> ({attorney.contractor_info.inn})</span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                )}

                {isAllSucceed && (
                    <Col className="text-center py-3" xs="12">
                        <h4 className="text-success">
                            {t(lang, 'edi.attorney.mass_import.sending_modal.sending_succeed')}
                            <FontAwesomeIcon className="ms-1" icon={faCheckCircle} />
                        </h4>
                    </Col>
                )}
            </Row>
        </>
    );
};

export default ImportAttorneyProgressModal;