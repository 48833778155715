import React from 'react';
import numeral from "numeral";
import { useSelector } from "react-redux";
import { Button, Card, Col, Table } from "react-bootstrap";
import PackageActivationModal from "./PackageActivationModal";
import { selectLang, Translate, selectSystemMoneyFormat } from "../../../../app/store/reducers/main/mainReducer";
import {
    EDI_SERVICE_CODE, EIMZO_SIGNER_SERVICE_CODE, POS_SERVICE_CODE,
    selectBillingPackages,
    selectOrganizationActivePackages, WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from "../../../../app/store/reducers/billing/billingReducer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PricingCard = ({ pricing }) => {
    const numberFormat = useSelector(selectSystemMoneyFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const activePackages = useSelector(selectOrganizationActivePackages);
    const packages = useSelector(selectBillingPackages)

    let hasNonSoonExpiringPackages = {
        [EDI_SERVICE_CODE]: false,
        [POS_SERVICE_CODE]: false,
        [WAREHOUSE_MANAGEMENT_SERVICE_CODE]: false,
        [EIMZO_SIGNER_SERVICE_CODE]: false,
    }

    for (let i = 0; i < packages.length; i++) {
        if (activePackages && activePackages.length > 0) {
            for (let j = 0; j < activePackages.length; j++) {
                let item = activePackages[j]
                if (item.package.service.code === packages[i].service.code) {
                    const expireDate = new Date(item.expires_at)
                    const threeDaysBeforeExpire = expireDate.setDate(expireDate.getDate() - 3)
                    const today = new Date().getTime()

                    hasNonSoonExpiringPackages[item.package.service.code] = threeDaysBeforeExpire > today
                }
            }
        }
    }


    let durationTranslationKey = 'main.billing.all_packages_card.pricing_card.package_validity_period'
    if (pricing.duration_months === 0 && pricing.duration_days > 0) {
        durationTranslationKey = 'main.billing.all_packages_card.pricing_card.package_validity_period_no_months'
    } else if (pricing.duration_months > 0 && pricing.duration_days === 0) {
        durationTranslationKey = 'main.billing.all_packages_card.pricing_card.package_validity_period_no_days'
    }

    const packageItems = []
    for (let i = 0; i < pricing.items.length; i++)
        packageItems.push(pricing.items[i])

    for (let j = 0; j < pricing.on_activation_bonus_packages.length; j++)
        for (let k = 0; k < pricing.on_activation_bonus_packages[j].items.length; k++)
            packageItems.push(pricing.on_activation_bonus_packages[j].items[k])


    return (
        <>
            <Col xs={12} sm={12} md={6} className="mt-4 px-md-5 px-lg-4 ">
                <Card className="shadow">
                    <Card.Header className="border-bottom">
                        <div className="text-center">
                            <h5 className="text-primary fw-light">{pricing.name}</h5>
                            <h4 className="text-primary">
                                <span className="mx-1">{numeral.formats[numberFormat].format(pricing.price)}</span>
                                <span className="mx-1 fs-2">{t(lang, 'main.navbar.nav_item.billing.balance.currency.sum')}</span>

                                {pricing.brv_rate > 0 && <span className="mx-1 fs-2">({t(lang, 'main.billing.brv_value', {
                                    brvRate: pricing.brv_rate
                                })})</span>}
                            </h4>
                            <small>
                                <span className="ms-1 text-primary">{t(lang, durationTranslationKey, {
                                    month: pricing.duration_months,
                                    day: pricing.duration_days,
                                })}</span>
                            </small>
                        </div>
                    </Card.Header>
                    {packageItems.length > 0 && <Card.Body className="overflow-hidden">
                        <Table className="fs--1">
                            <thead>
                                <tr>
                                    <th className="px-2 text-start">{t(lang, 'main.billing.all_packages_card.pricing_card.operation')}</th>
                                    <th className="px-2 text-center">{t(lang, 'main.billing.all_packages_card.pricing_card.limit')}</th>
                                    <th className="px-2 text-end">{t(lang, 'main.billing.all_packages_card.pricing_card.over_limit')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {packageItems.map(item => (
                                    <tr key={item.code}>
                                        <td className="px-2 text-start">{t(lang, `main.billing.package.item.name.${item.name}`)}</td>
                                        <td className="px-2 text-center">
                                            {item.is_unlimited && <FontAwesomeIcon className="text-black" icon="infinity" />}
                                            {!item.is_unlimited && <span>{item.limit}</span>}
                                        </td>
                                        <td className="px-2 text-end">
                                            {!item.is_unlimited && item.is_over_limit_allowed && <span>{t(lang, 'main.billing.all_packages_card.pricing_card.over_limit_price', {value: item.over_limit_price })}</span>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>}
                    <Card.Footer className="border-top py-4">
                        <PackageActivationModal serviceCode={pricing.service} packageId={pricing.id} hasSoonExpiringPackages={hasNonSoonExpiringPackages}>
                            <Button size="sm">{t(lang, 'main.common.activate')}</Button>
                        </PackageActivationModal>
                    </Card.Footer>
                </Card>
            </Col>
        </>
    );
};

export default PricingCard;
