import React from 'react';
import numeral from "numeral";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import OrganizationDataTableFooter from "./OrganizationDataTableFooter";
import OrganizationDataTableHeader from "./OrganizationDataTableHeader";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {selectDateDayJSFormat, selectLang, selectSystemMoneyFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectSystemOrganizations, selectSystemOrganizationsFilterOptions} from "../../../../app/store/reducers/billing/billingReducer";
import dayjs from "dayjs";

const OrganizationDataTable = () => {
    const filter = useSelector(selectSystemOrganizationsFilterOptions);
    const organizations = useSelector(selectSystemOrganizations);
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const lang = useSelector(selectLang);
    const t = Translate;

    const subBalanceTotal = organizations.reduce((acc, org) => acc + org?.balance, 0)
    const subCreditTotal = organizations.reduce((acc, org) => acc + org?.credit_amount,0)

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, 'admin.organizations.datatable.item.name'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`organization/${original.id}`}>
                        <strong>{original.name}</strong>
                    </Link>
                );
            }
        },
        {
            accessor: 'organization_code',
            Header: t(lang, 'admin.organizations.datatable.item.organization_code'),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.organization_code}</span>
                )
            }
        },
        {
            accessor: 'inn',
            Header: t(lang, 'admin.organizations.datatable.item.inn'),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.inn}</span>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "edi.order.datatable.header.row.created_date"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                )
            }
        },
        {
            accessor: 'balance',
            Header: t(lang, 'admin.organizations.datatable.item.amount'),
            headerProps: {className: 'pe-2 text-end'},
            cellProps: {className: 'text-end'},
            totalInfo: numeral.formats[systemMoneyFormat].format(subBalanceTotal),
            totalInfoClassName: 'text-end fw-bold',
            Cell: ({row: {original}}) => {
                return (
                    <span>{numeral.formats[systemMoneyFormat].format(original.balance)}</span>
                )
            }
        },
        {
            accessor: 'credit_amount',
            Header: t(lang, 'admin.organizations.datatable.item.credit_amount'),
            headerProps: {className: 'pe-2 text-end'},
            cellProps: {className: 'text-end'},
            totalInfo: numeral.formats[systemMoneyFormat].format(subCreditTotal),
            totalInfoClassName: 'text-end fw-bold',
            Cell: ({row: {original}}) => {
                return (
                    <span>{numeral.formats[systemMoneyFormat].format(original.credit_amount)}</span>
                )
            }
        }
    ]

    return (
        <>
            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={organizations}
            >
                <Card className="mb-3">
                    <Card.Header className="bg-light">
                        <OrganizationDataTableHeader/>
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <OrganizationDataTableFooter/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default OrganizationDataTable;
