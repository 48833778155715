//form events
export const ROAMING_INVOICE_SEND_REQUESTED = 'ROAMING_INVOICE_SEND_REQUESTED';
export const ROAMING_INVOICE_SEND_SUCCESS = 'ROAMING_INVOICE_SEND_SUCCESS';
export const ROAMING_INVOICE_SEND_FAILED = 'ROAMING_INVOICE_SEND_FAILED';

export const ROAMING_INVOICE_SAVE_REQUESTED = 'ROAMING_INVOICE_SAVE_REQUESTED';
export const ROAMING_INVOICE_SAVE_SUCCESS = 'ROAMING_INVOICE_SAVE_SUCCESS';
export const ROAMING_INVOICE_SAVE_FAILED = 'ROAMING_INVOICE_SAVE_FAILED';

export const ROAMING_CONSTRUCTION_INVOICE_SEND_REQUESTED = 'ROAMING_CONSTRUCTION_INVOICE_SEND_REQUESTED';
export const ROAMING_CONSTRUCTION_INVOICE_SEND_SUCCESS = 'ROAMING_CONSTRUCTION_INVOICE_SEND_SUCCESS';
export const ROAMING_CONSTRUCTION_INVOICE_SEND_FAILED = 'ROAMING_CONSTRUCTION_INVOICE_SEND_FAILED';

//action events
export const ROAMING_INVOICE_EXECUTOR_CANCEL_REQUESTED = 'ROAMING_INVOICE_EXECUTOR_CANCEL_REQUESTED';
export const ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS = 'ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS';
export const ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED = 'ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED';

export const ROAMING_INVOICE_CUSTOMER_DECLINE_REQUESTED = 'ROAMING_INVOICE_CUSTOMER_DECLINE_REQUESTED';
export const ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS = 'ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS';
export const ROAMING_INVOICE_CUSTOMER_DECLINE_FAILED = 'ROAMING_INVOICE_CUSTOMER_DECLINE_FAILED';

export const ROAMING_INVOICE_CUSTOMER_ACCEPT_REQUESTED = 'ROAMING_INVOICE_CUSTOMER_ACCEPT_REQUESTED';
export const ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS = 'ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS';
export const ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED = 'ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED';

export const ROAMING_INVOICE_PERSON_DECLINE_REQUESTED = 'ROAMING_INVOICE_PERSON_DECLINE_REQUESTED';
export const ROAMING_INVOICE_PERSON_DECLINE_SUCCESS = 'ROAMING_INVOICE_PERSON_DECLINE_SUCCESS';
export const ROAMING_INVOICE_PERSON_DECLINE_FAILED = 'ROAMING_INVOICE_PERSON_DECLINE_FAILED';

export const ROAMING_INVOICE_PERSON_ACCEPT_REQUESTED = 'ROAMING_INVOICE_PERSON_ACCEPT_REQUESTED';
export const ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS = 'ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS';
export const ROAMING_INVOICE_PERSON_ACCEPT_FAILED = 'ROAMING_INVOICE_PERSON_ACCEPT_FAILED';

export const ROAMING_INVOICE_SYNC_REQUESTED = 'ROAMING_INVOICE_SYNC_REQUESTED';
export const ROAMING_INVOICE_SYNC_SUCCESS = 'ROAMING_INVOICE_SYNC_SUCCESS';
export const ROAMING_INVOICE_SYNC_FAILED = 'ROAMING_INVOICE_SYNC_FAILED';

export const ROAMING_INVOICE_DRAFT_DELETE_REQUESTED = 'ROAMING_INVOICE_DRAFT_DELETE_REQUESTED';
export const ROAMING_INVOICE_DRAFT_DELETE_SUCCESS = 'ROAMING_INVOICE_DRAFT_DELETE_SUCCESS';
export const ROAMING_INVOICE_DRAFT_DELETE_FAILED = 'ROAMING_INVOICE_DRAFT_DELETE_FAILED';

export const ROAMING_INVOICE_BULK_ACTION_STARTED = 'ROAMING_INVOICE_BULK_ACTION_STARTED';
export const ROAMING_INVOICE_BULK_ACTION_FINISHED = 'ROAMING_INVOICE_BULK_ACTION_FINISHED';

export const ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_REQUESTED = "ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_REQUESTED"
export const ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS = "ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS"
export const ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED = "ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED"

export const ROAMING_INVOICE_SYNC_HISTORIES_REQUESTED = 'ROAMING_INVOICE_SYNC_HISTORIES_REQUESTED';
export const ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS = 'ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS';
export const ROAMING_INVOICE_SYNC_HISTORIES_FAILED = 'ROAMING_INVOICE_SYNC_HISTORIES_FAILED';
