import React, {useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../common/FalconCloseButton";
import IconButton from "../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {updateNotificationAsync} from "../../app/store/reducers/telegram-notification/telegramNotificationReducer";
import {FormProvider, useForm} from "react-hook-form";
import Form from "react-bootstrap/Form";
import {NotificationEvents, NotificationForm} from "./index";

const NotificationUpdate = ({notification}) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate

    const handleShow = () => setShow(true)
    const handleClose = () => {
        methods.reset()
        setShow(false)
    }
    const methods = useForm({
        defaultValues: {
            phoneNumber: notification.phone_number,
            eventCodes: notification.events.map(item => {
                return {
                    value: item
                }
            })
        }
    })

    const onSubmit = (formData) => {
        setLoading(true)
        const id = notification.id
        const phoneNumber = formData.phoneNumber
        const eventCodes = formData.eventCodes.map(item => item.value)

        updateNotificationAsync({phoneNumber: phoneNumber, eventCodes: eventCodes}, id)
            .then(() => handleClose())
            .catch(e => new Error(e))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <IconButton icon="edit" className="mx-1" variant="falcon-warning" size="sm" transform="shrink-3"
                        onClick={handleShow}/>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
                <Modal.Header>
                    <Modal.Title>{t(lang, "notification.update.events.modal")}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)} id={"notification-update"}>
                            <NotificationForm/>
                            <NotificationEvents/>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, "notification.close_button")}</Button>
                    <Button variant="primary" disabled={loading} className="d-flex align-items-center gap-1"
                            type='submit' form={'notification-update'}>
                        {loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        {t(lang, "notification.update_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NotificationUpdate;