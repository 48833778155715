import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import ContractorPaymentView from "../../../../components/hippo/crm/contractor/payment-view/ContractorPaymentView";
import {loadOneContractorPaymentAsync} from "../../../../app/store/reducers/contractor/contractorPaymentReducer";


const ContractorPayment = ({viewId}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const {id} = useParams()
    const [payment, setPayment] = useState(null)

    const loadContractorPayment = async () => {
        await loadOneContractorPaymentAsync(viewId || id)
            .then((res) => {
                setPayment(res)
            })
            .catch(() => {
                setPayment(null)
            })
    }

    useEffect(() => {
        loadContractorPayment()
    },[id, activeOrganization, viewId])

    if (!payment)
        return (
            <>loading...</>
        )

    return(
        <>
            <ContractorPaymentView payment={payment}/>
        </>
    )
}


export default ContractorPayment;
