import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router";
import {useSelector} from "react-redux";

import {
    loadCrmInternalOrderAsync,
    updateInternalOrderAsync,
} from "../../../../../app/store/reducers/crm/internal/crmInternalOrderReducer";

import {Form} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import InternalOrderForm from "../../../../../components/hippo/crm/internal/order/forms/InternalOrderForm";
import {UZS} from "../../../../../enum/CurrencyCode";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";

const CrmInternalOrderAddForm = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const history = useHistory();

    const {status, id} = useParams()
    const currencies = useSelector(selectCurrency);
    const items = useSelector(selectItems);
    const [loading, setLoading] = useState(false);
    const [editingInternalOrder, setEditingInternalOrder] = useState(null);

    const methods = useForm({
        defaultValues: {
            branch_from: null,
            branch_to: null,
            info: {
                number: '',
                date: null
            },
            contract: {
                number: '',
                date: null
            },
            items: [
                {
                    global_item_id: '',
                    product: '',
                    barcode: '',
                    product_code: '',
                    measurement: '',
                    price: {
                        amount: '',
                        currency_id: currencies?.find(c => c?.code === UZS)?.id
                    },
                    quantity: '',
                }
            ],
            expires_on: null,
        }
    });

    useEffect(() => {
        methods.setValue('branch_from', editingInternalOrder?.branch_from);
        methods.setValue('branch_to', editingInternalOrder?.branch_to);
        methods.setValue('info.number', editingInternalOrder?.info?.number);
        methods.setValue('info.date', editingInternalOrder?.info?.date ? new Date(editingInternalOrder.info.date) : null);
        methods.setValue('contract.number', editingInternalOrder?.contract?.number);
        methods.setValue('contract.date', editingInternalOrder?.contract?.date ? new Date(editingInternalOrder.contract.date) : null);
        methods.setValue('expires_on', editingInternalOrder?.expires_on ? new Date(editingInternalOrder.expires_on) : null);

        if (items.length > 0) {
            methods.setValue('items', editingInternalOrder?.items.map((item) => {
                let foundItem = items.find(i => i.item.id === item.id);

                let price_currency_id = currencies?.find(c => c?.id === item.price.currency?.global_id)?.id ?? currencies?.find(c => c?.code === UZS)?.id;
                return {
                    product: foundItem?.item,
                    barcode: item.barcode,
                    product_code: item.product_code,
                    measurement: item.measurement,
                    price: {
                        amount: item.price.amount,
                        currency_id: price_currency_id
                    },
                    quantity: item.quantity,
                }
            }))
        }

    }, [editingInternalOrder, items])

    const onSubmit = formData => {
        setLoading(true);
        updateInternalOrderAsync(id, {
            items: formData.items.map(item => {
                return {
                    id: item.product?.id,
                    product: item.product?.name,
                    barcode: item.barcode || null,
                    product_code: item.product_code || null,
                    measurement: String(item.measurement),
                    price: {
                        amount: +item.price.amount,
                        currency_id: item.price.currency_id
                    },
                    quantity: +item.quantity,
                }
            }),
            info: {
                number: formData.info.number,
                date: formData?.info?.date ? dayjs(formData.info.date).format('YYYY-MM-DD') : null
            },
            contract: formData.contract?.number && formData.contract?.date ? {
                number: formData.contract.number,
                date: dayjs(formData.contract.date).format('YYYY-MM-DD')
            } : null,
            branch_from: formData.branch_from.id,
            branch_to: formData.branch_to.id,
            expires_on: formData?.expires_on ? dayjs(formData.expires_on).format('YYYY-MM-DD') : null,
        }).then(() => {
            toast.success(t(lang, 'crm.common.toast.success'));
            history.push('/crm/internal/order/all')
        }).catch(() => {
            toast.error(t(lang, 'crm.common.toast.error'));
        }).finally(() => {
            setLoading(false);
        })
    };

    useEffect(() => {
        loadCrmInternalOrderAsync(id).then(res => {
            setEditingInternalOrder(res);
        }).catch(err => {
        })
    }, [id])

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <InternalOrderForm loading={loading}
                                   status={status}
                                   formTitle={t(lang, "crm.internal.order.create_edit_order_form_title")}
                />
            </Form>
        </FormProvider>
    );
};

export default CrmInternalOrderAddForm;