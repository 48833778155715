import React, {useEffect} from 'react';
import dayjs from "dayjs";
import numeral from "numeral";
import classNames from "classnames";
import {Link} from "react-router-dom";
import Flex from "../../../../common/Flex";
import {useDispatch, useSelector} from "react-redux";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import SoftBadge from "../../../../common/SoftBadge";
import {faBookmark} from "@fortawesome/free-solid-svg-icons";
import WaybillDataTableHeader from "./WaybillDataTableHeader";
import WaybillDataTableFooter from "./WaybillDataTableFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {
    selectWaybills,
    selectWaybillsFilterOptions,
    selectWaybillsLoading,
    viewPage, setShowModal, setId
} from "../../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {getWaybillStatusText, getWaybillStatusVariant, VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from '../../../../../enum/roaming/WaybillStatus'
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import ViewModalPage from "../../components/ViewModalPage";
import ViewWaybill from "../../../../../pages/main/roaming/waybill/ViewWaybill";
import ViewDraftWaybill from "../../../../../pages/main/roaming/waybill/ViewDraftWaybill";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";

const WaybillDataTable = ({viewer}) => {
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const waybills = useSelector(selectWaybills);
    const loading = useSelector(selectWaybillsLoading);
    const numberFormat = useSelector(selectNumberFormat);
    const filter = useSelector(selectWaybillsFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (viewer === VIEW_DRAFTS) {
            if (ctrlKey) {
                history('/#/roaming/waybill/draft/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }
        } else {
            if (ctrlKey) {
                history('/#/roaming/waybill/'+id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, 'roaming.waybill.datatable.row.waybill_number'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                const routeTo = viewer === VIEW_DRAFTS ? `../waybill/draft/${original.id}` : `../waybill/${original.id}`;

                return (
                    <>
                        <strong onClick={(e)=>openViewModalPage(e,original.id)} className={'cursor-pointer text-primary'}>{original.info.number}</strong>
                    </>
                );
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, 'roaming.waybill.datatable.row.waybill_date'),
            headerProps: {className: 'pe-2'},
            Cell: rowData => {
                const {original} = rowData.row
                return dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'contract',
            Header: t(lang, 'roaming.waybill.datatable.row.contract'),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span>
                        {t(lang, 'roaming.waybill.datatable.row.contract_number_from_contract_date', {
                            contract_number: original.contract_info.number,
                            contract_date: dayjs(original.contract_info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                        })}
                    </span>
                )
            }
        },
        ...(viewer !== VIEW_DRAFTS ? [
            {
                accessor: 'status',
                Header: t(lang, 'roaming.waybill.datatable.row.status'),
                Cell: ({row: {original}}) => {
                    return (
                        <h5><SoftBadge className='me-2' bg={getWaybillStatusVariant(original, viewer_inn_or_pinfl)}>
                            {t(lang, getWaybillStatusText(original, viewer_inn_or_pinfl))}
                        </SoftBadge></h5>
                    )
                }
            }
        ] : []),
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.waybill.datatable.row.contractor'),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return viewer_inn_or_pinfl === original.customer.inn
                    ? `${original.carrier.name} (${original.carrier.inn})`
                    : `${original.customer.name} (${original.customer.inn})`
            }
        },
        {
            accessor: 'total',
            Header: t(lang, 'roaming.waybill.datatable.row.total'),
            Cell: ({row: {original}}) => {
                const total = original.total ? original?.total : original.items.reduce((acc, item) => acc + item.total, 0);

                return (
                    <span>{numeral.formats[numberFormat].format(total)}</span>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, 'roaming.waybill.datatable.row.created_at'),
            Cell: rowData => {
                const {original} = rowData.row
                return dayjs(original.created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
    ]

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Link to={'../waybills/inbox'} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEW_INBOX})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon="arrow-down"
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames('text-primary d-none d-lg-block',{'text-white': viewer === VIEW_INBOX, 'text-primary': viewer !== VIEW_INBOX})}>
                                    {t(lang, 'roaming.waybill.datatable.tabs.received')}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={'../waybills/outbox'} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEW_OUTBOX})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon="arrow-up"
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames('text-primary d-none d-lg-block',{'text-white': viewer === VIEW_OUTBOX, 'text-primary': viewer !== VIEW_OUTBOX})}>
                                    {t(lang, 'roaming.waybill.datatable.tabs.sent')}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={'../waybills/drafts'} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEW_DRAFTS})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon={faBookmark}
                                    className={classNames("fs-1 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames('text-primary d-none d-lg-block',{'text-white': viewer === VIEW_DRAFTS})}>
                                    {t(lang, 'roaming.waybill.datatable.tabs.drafts')}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
            </Row>


            <AdvanceTableWrapper
                selection
                pagination
                perPage={1000}
                data={waybills}
                columns={columns}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <WaybillDataTableHeader />
                    </Card.Header>
                    <Card.Body>
                        {loading
                            ?
                            <div className="w-100 h-100 text-center">
                                <Spinner className="mx-auto" variant="primary" animation="border" role="switch"/>
                            </div>
                            :
                            <AdvanceTable
                                table
                                rowClassName="align-middle white-space-nowrap"
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        }
                    </Card.Body>
                    <Card.Footer>
                        <WaybillDataTableFooter />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewWaybill modalId={id} />}
                {draftId && <ViewDraftWaybill modalId={draftId} />}
            </ViewModalPage>

        </>
    );
};

export default WaybillDataTable;
