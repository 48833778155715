import React, {forwardRef} from 'react';
import Cleave from "cleave.js/react";
import classNames from "classnames";

const commaKeyCode = 188;
const CleaveWithCommaMark = forwardRef(({value, onChange, onContextMenu, disabled}, ref) => {
    return <Cleave htmlRef={ref}
                   className={classNames("form-control", {
                       "bg-200": disabled
                   })}
                   disabled={disabled}
                   onKeyDown={(e) => {
                       if (e.keyCode === commaKeyCode)
                           e.target.value += "."
                   }}
                   options={{
                       disabled: disabled,
                       numeral: true,
                       delimiter: ' ',
                       numeralDecimalScale: 4,
                   }}
                   value={value}
                   onChange={e => onChange(e.target.rawValue)}
    />
})

export default CleaveWithCommaMark;
