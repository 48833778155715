import React from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const DeliveryDetailsForm = () => {
    const lang = useSelector(selectLang);
    const {register} = useFormContext();
    const t = Translate;

    const deliveryTypeOptions = [
        {value: 1, label: "roaming.waybill.send.delivery_details_form.delivery_type.from_warehouse_to_warehouse"},
        {value: 2, label: "roaming.waybill.send.delivery_details_form.delivery_type.from_seller_to_buyer"},
    ];

    const trailerTypeOptions = [
        {value: 1, label: "roaming.waybill.send.delivery_details_form.trailer_type.trailer"},
        {value: 2, label: "roaming.waybill.send.delivery_details_form.trailer_type.semi_trailer"},
    ]

    return (
        <Card className="mt-3">
            <Card.Header className="bg-light">
                <Card.Title>{t(lang, 'roaming.waybill.send.delivery_details_form.title')}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col className="my-1" md="6">
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type')}</Form.Label>
                            <Controller
                                name="delivery_type"
                                render={({field}) => (
                                    <ReactSelect
                                        hideSelectedOptions={true}
                                        options={deliveryTypeOptions}
                                        classNamePrefix="react-select"
                                        getOptionValue={option => option.value}
                                        getOptionLabel={option => t(lang, option.label)}
                                        onChange={option => field.onChange(option?.value)}
                                        defaultValue={deliveryTypeOptions.find(option => option.value === field.value)}
                                        placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type.placeholder')}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="my-1" md="6">
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.trailer_type')}</Form.Label>
                            <Controller
                                name="trailer.type"
                                render={({field}) => (
                                    <ReactSelect
                                        hideSelectedOptions={true}
                                        options={trailerTypeOptions}
                                        classNamePrefix="react-select"
                                        getOptionValue={option => option.value}
                                        getOptionLabel={option => t(lang, option.label)}
                                        onChange={option => field.onChange(option?.value)}
                                        defaultValue={trailerTypeOptions.find(option => option.value === field.value)}
                                        placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.trailer_type.placeholder')}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>

                    <Col className="my-1" md="6">
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.truck_register_number')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register('truck.register_number')}
                                placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.truck_register_number.placeholder')}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="my-1" md="6">
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.truck_model')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register('truck.model')}
                                placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.truck_model.placeholder')}
                            />
                        </Form.Group>
                    </Col>

                    <Col className="my-1" md="6">
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.trailer_register_number')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register('trailer.register_number')}
                                placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.trailer_register_number.placeholder')}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="my-1" md="6">
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.trailer_model')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register('trailer.model')}
                                placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.trailer_model.placeholder')}
                            />
                        </Form.Group>
                    </Col>

                    <Col className="my-1" md="6">
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.driver')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register('driver')}
                                placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.driver.placeholder')}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default DeliveryDetailsForm;