import React, {useEffect} from 'react';
import {
    getCashboxAccountAsync,
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {useDispatch, useSelector} from "react-redux";
import CashboxAccountDataTable from "./CashboxAccountDataTable";
import EventBus from "../../../../app/eventbus/EventBus";
import {CASHBOX_BINDING_SUCCESS} from "../../../../app/eventbus/cashbox/cashboxEvents";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";

const CashboxAccount = () => {

    const dispatch = useDispatch()
    const organization = useSelector(selectActiveOrganization);

    useEffect(() => {
        dispatch(getCashboxAccountAsync())
        // dispatch(getAllCashboxAsync())
    }, [organization])

    useEffect(() => {
        const cashboxBindingSuccess =  EventBus.on(CASHBOX_BINDING_SUCCESS, () => {
            dispatch(getCashboxAccountAsync())
        })

        return () => {
            EventBus.remove(CASHBOX_BINDING_SUCCESS, cashboxBindingSuccess)
        }
    }, [])

    return (
       <CashboxAccountDataTable />
    );
};

export default CashboxAccount;
