import React, {Fragment, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import EditCategoryButton from "./EditCategoryButton";
import IconButton from "../../../common/IconButton";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import {editCategoryAsync, loadCategoryAsync} from "../../../../app/store/reducers/category/categoryReducer";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import FalconCloseButton from "../../../common/FalconCloseButton";
import CategoryForm from "./forms/CategoryForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    BULK_EDIT_CATEGORY_FAILED,
    BULK_EDIT_CATEGORY_REQUESTED,
    EDIT_CATEGORY_REQUESTED
} from "../../../../app/eventbus/itemEvents";

const BulkEditCategories = ({categories, handleShowProgressBar, updateActionResult}) => {
    console.log(categories)
    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const form = useForm({
        defaultValues: {
            parentId: '',
        }
    });

    const handleShow = async () => {
        setShow(true);
    };
    const handleClose = () => setShow(false);

    const onSubmit = (formData) => {
        handleShowProgressBar();
        EventBus.dispatch(BULK_EDIT_CATEGORY_REQUESTED);
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            editCategoryAsync(category.id, {
                name: category.name,
                parent_id: formData.parentId,
                image: category?.images?.length > 0 ? category?.images[0] : null
            }).then(res => {
                updateActionResult({
                    number: category.name,
                    date: res.date,
                    contractor: {
                        name: null,
                        inn: null
                    },
                });
            }).catch(e => {
                updateActionResult({
                    number: category.name,
                    date: e.date,
                    error: e?.title,
                    contractor: {
                        name: null,
                        inn: null
                    },
                })
            })
        }
        handleClose()
    }

    return (
        <Fragment>
            <IconButton icon={faPencil}
                        variant="falcon-warning"
                        onClick={handleShow}
            >
                {t(lang, "items.common.edit")}
            </IconButton>

            <Modal show={show}
                   size="lg"
                   onHide={handleClose}
            >
                <FormProvider {...form}>
                    <Form onSubmit={form.handleSubmit(onSubmit)}>
                        <Modal.Header>
                            <Modal.Title>{t(lang, 'items.common.edit_category')}</Modal.Title>
                            <FalconCloseButton onClick={handleClose}/>
                        </Modal.Header>
                        <Modal.Body>
                            <CategoryForm showOnlyCategorySelect={true}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary"
                                    onClick={handleClose}
                            >
                                {t(lang, 'items.common.cancel')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={loading}
                            >
                                {loading &&
                                    <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                                {t(lang, 'items.common.save')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default BulkEditCategories;
