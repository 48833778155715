const NOTCONFIRMED = false
const CONFIRMED = true

export const withoutDiscount = 0
export const withDiscount = 1


export const dropDownDiscountOptions = [
    {
        value: withoutDiscount,
        label: "Без процентов"
    },
    {
        value: withDiscount,
        label: "С процентами"
    }
]

export const BulkPriceCommon = [
    {
        label: "warehouse.operation.item.common.selling_price",
        value: true
    },
    {
        label: "items.common.bulk_price",
        value: false
    },
]

export const isApprovedCheckedWarehouseOperation = (bool) => {
    switch (bool) {
        case NOTCONFIRMED:
            return "warehouse.operation.item.common.status.not_confirmed"
        case CONFIRMED:
            return "warehouse.operation.item.common.status.confirmed"
        default:
            return "not found"
    }
}

export const findCurrencyReturnRate = (currencies, id) => {
    return currencies.find(currency => currency?.id === id)?.rate
}

export const checkTypeOfReturnNumber = (amount) => {
    return typeof (amount) === 'string' ? +amount.replace(/ /g, '') : +amount
}


export const OperationTotalSumInfo = (items) => {
    var groupByCurrencyId = {}

    items?.forEach(item => {
        if (item?.price?.currency?.id in groupByCurrencyId) {
            groupByCurrencyId[item?.price?.currency?.id].totalSum += item?.price?.amount * item?.quantity
        } else {
            if (item?.price?.currency?.id) {
                groupByCurrencyId[item?.price?.currency?.id] = {
                    id: item?.price?.currency?.id,
                    name: item?.price?.currency?.name,
                    quantity: item?.quantity,
                    totalSum: item?.price?.amount * item?.quantity,
                }
            }
        }
    })
    return Object.values(groupByCurrencyId)
}

export const OperationTotalSumInfoPayment = (states) => {
    var groupByCurrencyId = {}

    if (states) {
        states.forEach(state => {
            if (state.currency.id in groupByCurrencyId) {
                groupByCurrencyId[state?.currency.id].totalSum += state.amount
            } else {
                if (state.currency.id) {
                    groupByCurrencyId[state.currency.id] = {
                        id: state.currency.id,
                        name: state.currency.name,
                        totalSum: state.amount,
                        payment_type: state.payment_type
                    }
                }
            }
        })
        return Object.values(groupByCurrencyId)
    } else {
        return 0
    }
}

export const OperationInfoPaymentType = (states) => {
    const resultObject = states?.reduce((result, currentItem) => {
        const paymentType = currentItem.payment_type;

        const key = `${paymentType}`;

        if (!result[key]) {
            result[key] = [
                    {
                        id: currentItem.currency.id,
                        name: currentItem.currency.name,
                        totalSum: 0,
                        payment_type: currentItem.payment_type
                    }
                    ]
        }

        result[key].push({
            id: currentItem.currency.id,
            name: currentItem.currency.name,
            totalSum: currentItem.amount,
            payment_type: currentItem.payment_type
        });

        return result;
    }, {});
    return resultObject ? resultObject : ""
}


export const ItemsTotalSumGroupCurrencyId = (ArraysIntoArrays) => {
    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.items?.filter(f => !f?.is_deleted)?.forEach((item) => {
            if (!groupedSums[item?.price?.currency?.id]) {
                groupedSums[item?.price?.currency?.id] = {
                    totalSum: 0,
                    currency_id: item?.price?.currency?.id,
                    name: item?.price?.currency?.name
                };
            }
            groupedSums[item?.price?.currency?.id].totalSum += item?.price?.amount * item?.quantity;
        });
    });
    return Object.values(groupedSums)
}


export const ItemsTotalSumCashBoxGroupPaymentTypeCurrencyId = (ArraysIntoArrays) => {

    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.payment?.cash_box_states?.filter(f => !f?.is_deleted)?.forEach((item) => {
            const paymentType = item.payment_type;
            const currenyId = item.currency?.id;

            const key = `${paymentType}${currenyId}`;

            if (!groupedSums[key]) {
                groupedSums[key] = {
                    totalSum: 0,
                    currency_id: item?.currency?.id,
                    name: item?.currency?.name,
                    payment_type: item?.payment_type,
                };
            }
            groupedSums[key].totalSum += item?.amount;
        });
    });
    return Object.values(groupedSums)
}


export const ItemsTotalSumDebtsGroupPaymentTypeCurrencyId = (ArraysIntoArrays) => {

    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.payment?.debt_states?.filter(f => !f?.is_deleted)?.forEach((item) => {
            const currenyId = item.currency?.id;

            const key = `${currenyId}`;

            if (!groupedSums[key]) {
                groupedSums[key] = {
                    totalSum: 0,
                    currency_id: item?.currency?.id,
                    name: item?.currency?.name,
                };
            }
            groupedSums[key].totalSum += item?.amount;
        });
    });
    return Object.values(groupedSums)
}

export const ItemsTotalSumPayoutDebtStatesGroupPaymentTypeCurrencyId = (ArraysIntoArrays) => {

    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.payout?.cash_box_states?.filter(f => !f?.is_deleted)?.forEach((item) => {
            const paymentType = item.payment_type;
            const currenyId = item.currency?.id;

            const key = `${paymentType}${currenyId}`;

            if (!groupedSums[key]) {
                groupedSums[key] = {
                    totalSum: 0,
                    currency_id: item?.currency?.id,
                    name: item?.currency?.name,
                    payment_type: item?.payment_type,
                };
            }
            groupedSums[key].totalSum += item?.amount;
        });
    });
    return Object.values(groupedSums)
}

export const ItemsTotalSumPayoutDebtGroupPaymentTypeCurrencyId = (ArraysIntoArrays) => {

    const groupedSums = {};
    ArraysIntoArrays?.forEach((array) => {
        array?.payout?.debt_states?.filter(f => !f?.is_deleted)?.forEach((item) => {
            const currenyId = item.currency?.id;

            const key = `${currenyId}`;

            if (!groupedSums[key]) {
                groupedSums[key] = {
                    totalSum: 0,
                    currency_id: item?.currency?.id,
                    name: item?.currency?.name,
                };
            }
            groupedSums[key].totalSum += item?.amount;
        });
    });
    return Object.values(groupedSums)
}