import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import ContractorAgentAdd from "./ContractorAgentAdd";


const ContractorAgentDatatableHeader = () => {
    const lang = useSelector(selectLang)
    const t = Translate



    return(
        <>
            <Row className="d-flex justify-content-between">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0 me-2">{t(lang, "crm.contractor.agent.lists")}</h5>
                </Col>
                <Col xs={8} className="d-flex justify-content-end">
                    <ContractorAgentAdd/>
                </Col>
            </Row>
        </>
    )
}

export default ContractorAgentDatatableHeader;