import axios from "axios";

class ReferenceDataAggregatorService {
    constructor() {
        this.baseURL = "/rda"
    }
    getOrganizationItems(params) {
        return axios.get(`${this.baseURL}/item/organization/get`, {params});
    }
    getOrganizationItem(id) {
        return axios.get(`${this.baseURL}/item/organization/get/${id}`);
    }
    getOrganizationBindingItems(id, params) {
        return axios.get(`${this.baseURL}/organization-binding/${id}/item/get`, {params});
    }
    getOrganizationBindingSales(id, params) {
        return axios.get(`${this.baseURL}/organization-binding/${id}/sale/get`, {params});
    }
    getOrganizationBindingSalesCount(id, params) {
        return axios.get(`${this.baseURL}/organization-binding/${id}/sale/count`, {params});
    }
    getOrganizationBindingSale(id, saleId) {
        return axios.get(`${this.baseURL}/organization-binding/${id}/sale/${saleId}`);
    }
    getOrganizationBindingCashBoxes(id, params) {
        return axios.get(`${this.baseURL}/organization-binding/${id}/cash-box/get`, {params})
    }
    getOrderSaleDifferences(orderId) {
        return axios.get(`${this.baseURL}/compare-telegram-order-with-sale/differences/get/${orderId}`);
    }
    getSaleOrderDifferences(saleId) {
        return axios.get(`${this.baseURL}/compare-sale-with-telegram-order/differences/get/${saleId}`);
    }
}

const referenceDataAggregatorService = new ReferenceDataAggregatorService();
export default referenceDataAggregatorService;