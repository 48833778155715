import React, {useEffect, useState} from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {ShipmentStatuses} from "../../../enum/ShipmentStatus";

const SelectShipmentStatus = ({onChange, defaultValue, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const options = ShipmentStatuses.map((status) => {
        return {
            value: status.id,
            label: t(lang, status.name),
        };
    })
    const [shipmentStatus, setShipmentStatus] = useState(options.find(s => s.value === defaultValue) || null)

    useEffect(() => {
        onChange(shipmentStatus ? shipmentStatus.value : null);
    }, [shipmentStatus])

    return (
        <Select {...props} classNamePrefix="react-select" onChange={option => setShipmentStatus(option)} options={options} value={shipmentStatus}/>
    );
};


SelectShipmentStatus.propTypes = {
    onChange: PropTypes.func
}
SelectShipmentStatus.defaultProps = {
    onChange: () => {
    }
}


export default SelectShipmentStatus;