import React, {Fragment} from 'react';
import {Row, Form, Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";
import DatePicker from "react-datepicker";
import CashBoxSelect from "../common/CashBoxSelect";
import AccountSelect from "../../../common/AccountSelect";
import {selectedCurrencyForCashBox, setCurrency} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import SelectCurrency from "../common/SelectCurrency";
import {useLocation} from "react-router";
import ReactSelect from "react-select";
import {CashBoxOperationTypes} from "../../../../enum/cash_box/cashBoxOperationType";

const CashBoxesHeaderForm = ({currency, transfer, cashBoxOperation}) => {

    const dispatch = useDispatch()
    const dateFormat = useSelector(selectDateFormat);
    const selectedCurrency = useSelector(selectedCurrencyForCashBox)
    const {control} = useFormContext()

    const lang = useSelector(selectLang)
    const t = Translate

    return (
        <Fragment>
            <Row>
                <Form.Group as={Col} md={3} sm={12}>
                    <Form.Label>{t(lang, "warehouse.operation.item.common.filter.date_start")}</Form.Label>
                    <Controller
                        control={control}
                        name={'date_start'}
                        render={({field}) => (
                            <DatePicker
                                className='form-control'
                                placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={field.onChange}
                                isClearable
                            />
                        )}/>
                </Form.Group>

                <Form.Group as={Col} md={3} sm={12}>
                    <Form.Label>{t(lang, "warehouse.operation.item.common.filter.date_end")}</Form.Label>
                    <Controller
                        control={control}
                        name={'date_end'}
                        render={({field}) => (
                            <DatePicker
                                className='form-control'
                                placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={field.onChange}
                                isClearable
                            />
                        )}/>
                </Form.Group>

                {!transfer && <Form.Group as={Col} md={3} sm={12}>
                    <Form.Label>{t(lang, "cashbox.bind.account.title")}</Form.Label>
                    <Controller
                        name={'cashbox_id'}
                        render={({field}) => (
                            <CashBoxSelect
                                defaultValue={field.value}
                                onChange={field.onChange}
                            />
                        )}/>
                </Form.Group>}

                {
                    transfer && <Fragment>
                        <Form.Group as={Col} md={3} xs={12}>
                            <Form.Label>{t(lang, "cash_boxes.from_cash_box")}</Form.Label>
                            <Controller
                                name={'from_cashbox_id'}
                                render={({field}) => (
                                    <CashBoxSelect
                                        defaultValue={field.value}
                                        onChange={field.onChange}
                                    />
                                )}/>
                        </Form.Group>

                        <Form.Group as={Col} md={3} xs={12}>
                            <Form.Label>{t(lang, "cash_boxes.to_cash_box")}</Form.Label>
                            <Controller
                                name={'to_cashbox_id'}
                                render={({field}) => (
                                    <CashBoxSelect
                                        defaultValue={field.value}
                                        onChange={field.onChange}
                                    />
                                )}/>
                        </Form.Group>
                    </Fragment>
                }

                <Form.Group as={Col} md={3} sm={12}>
                    <Form.Label>{t(lang, "crm.contractor.view.tab.act_report.account")}</Form.Label>
                    <Controller
                        name={'account_id'}
                        render={({field}) => (
                            <AccountSelect
                                defaultValue={field.value}
                                onChange={field.onChange}
                            />
                        )}/>
                </Form.Group>

                {cashBoxOperation && <Form.Group as={Col} md={3} sm={12}>
                    <Form.Label>{t(lang, "warehouse.items.history.datatable.column.type")}</Form.Label>
                    <Controller
                        name={'type'}
                        render={({field}) => (
                            <ReactSelect
                                options={CashBoxOperationTypes}
                                isClearable
                                hideSelectedOptions
                                classNamePrefix={'react-select'}
                                placeholder={t(lang, "warehouse.items.history.datatable.column.type")}
                                getOptionLabel={(option) => t(lang, option.label)}
                                getOptionValue={(option) => option.value}
                                onChange={field.onChange}
                            />
                        )}/>
                </Form.Group>}

                {currency && <Form.Group as={Col} md={3} sm={12}>
                    <Form.Label>{t(lang, "main.top.nav.profile_dropdown.dropdown_item.currency")}</Form.Label>
                    <SelectCurrency
                        onChange={(currency) => dispatch(setCurrency(currency))}
                        defaultCurrency={selectedCurrency?.id}
                        classNamePrefix="react-select"
                    />
                </Form.Group>}
            </Row>
        </Fragment>
    );
};

export default CashBoxesHeaderForm;
