import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import {Fragment, useState} from "react";
import IconButton from "../../common/IconButton";
import {FormProvider, useForm} from "react-hook-form";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {registerAdminOrganizationCredential} from "../../../app/store/reducers/billing/billingReducer";

const AdminOrganizationRegisterModal = ({organizationId}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const methods = useForm({
        defaultValues: {
            client_id: "",
            client_secret: ""
        }
    })

    const onSubmit = ({client_id, client_secret}) => {
        setLoading(true)
        registerAdminOrganizationCredential({
            client_id: client_id,
            client_secret: client_secret,
            organization_id: organizationId
        })
            .then(() => {
                methods.reset()
                setShow(false)
            })
            .catch(e => new Error(e))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>

            <IconButton
                onClick={handleShow}
                icon={"plus"}
                variant={"falcon-default"}
                size={"sm"}
            >
                {t(lang, "admin.organization.credentials.register")}
            </IconButton>


            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{t(lang,"admin.organization.credentials.modal.title")}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)} id={'organization-register'}>
                            <Form.Group>
                                <Form.Label>{t(lang, "admin.organization.credentials.datatable.client_secret")}</Form.Label>
                                <Form.Control
                                    {...methods.register("client_secret", {
                                        required: t(lang, 'roaming.validations.is_required')
                                    })}
                                    isInvalid={methods.formState?.errors?.client_secret}
                                    type="text"
                                    placeholder={t(lang,"admin.organization.credentials.datatable.client_secret")}/>
                                <Form.Control.Feedback
                                    type={'invalid'}>{methods.formState?.errors?.client_secret?.message}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-2">
                                <Form.Label>{t(lang,"admin.organization.credentials.datatable.client_id")}</Form.Label>
                                <Form.Control
                                    {...methods.register("client_id", {
                                        required: t(lang, "roaming.validations.is_required")
                                    })}
                                    isInvalid={methods.formState?.errors?.client_id}
                                    type="text"
                                    placeholder={t(lang,"admin.organization.credentials.datatable.client_id")}/>
                                <Form.Control.Feedback
                                    type={"invalid"}>{methods.formState?.errors?.client_id?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="mx-1" onClick={handleClose}>
                        {t(lang,"admin.organization.credentials.modal.close")}
                    </Button>
                    <Button disabled={loading} form={'organization-register'} variant="primary" type="submit">
                        {t(lang,"admin.organization.credentials.modal.submit")}
                        {loading && <Spinner animation="border" size="sm" />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default AdminOrganizationRegisterModal