import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {selectUsers} from "../../../../../../app/store/reducers/auth/authReducer";
import {
    branchAccountBindingDisable,
    branchAccountBindingEnable,
    selectAccountBranch
} from "../../../../../../app/store/reducers/branch/branchReducer";
import {Badge, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {
    cashboxAccountBindingDisable,
    cashboxAccountBindingEnable
} from "../../../../../../app/store/reducers/cashbox/CashboxReducer";
import {toast} from "react-toastify";
import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";

const AccountBranchDataTable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const users = useSelector(selectUsers)
    const accountBranch = useSelector(selectAccountBranch)

    const columns = [
        {
            Header: '#',
            Cell: ({row}) => `${row.index + 1}`,
            headerProps: {className: 'p-3'},
            cellProps: {
                width: '50px',
                className: 'p-3'
            }
        },
        {
            Header: t(lang, "Ф.И.О"),
            cellProps: {width: 300},
            Cell: ({row: {original}}) => {
                const name = users.find(i => i.id === original.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")
                return (
                    <strong>{name}</strong>
                );
            }
        },
        {
            Header: t(lang, "edi.dashboard.branches.title"),
            headerProps: {className: 'text-center'},
            cellProps: {className: 'text-center'},
            Cell: ({row: {original}}) => {
                return (
                    original.branches.map((item, index) => (
                        <Badge key={index} bg={'primary'}
                               className="me-2">{item.name}</Badge>
                    ))

                );
            }
        },
        {
            Header: t(lang, "items.common.catalog.status"),
            headerProps: {className: 'text-center'},
            cellProps: {className: 'text-center', width: 300},
            Cell: ({row: {original}}) => {
                const status = original.is_binding_enabled ? "crm.bind.contractor.account.unlock.action" : "crm.bind.contractor.account.block.action"
                return (
                    <Badge bg={original.is_binding_enabled ? "success" : "secondary"}
                           className="me-2">{t(lang, status)}</Badge>
                );
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <Fragment>
                        <OverlayTrigger placement={'top'} overlay={
                            <Tooltip>{t(lang, original.is_binding_enabled ? "crm.bind.contractor.account.change.status.to.active" : "crm.bind.contractor.account.change.status.to.disable")}</Tooltip>}>
                            <Form.Check
                                size={20}
                                checked={original.is_binding_enabled}
                                type='switch'
                                onChange={() => changeBranchAccountStatus(original)}
                            />
                        </OverlayTrigger>
                    </Fragment>
                );
            }
        }
    ]

    const changeBranchAccountStatus = (account) => {
        const params = {account_id: account.account_id}
        if (account.is_binding_enabled) {
            branchAccountBindingDisable(params)
                .then(() => {
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))

        } else {
            branchAccountBindingEnable(params)
                .then(() => {
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))
        }
    }


    return (
        <AdvanceTableWrapper
            perPage={1000}
            columns={columns}
            data={accountBranch}
            pagination
        >
            <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                }}>
            </AdvanceTable>
        </AdvanceTableWrapper>
    );
};

export default AccountBranchDataTable;
