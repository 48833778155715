import {createSlice} from "@reduxjs/toolkit";
import RoamingService from "../../../../services/roaming/roamingService";
import EventBus from "../../../eventbus/EventBus";
import {COMPANY_BINDING_FAILED, COMPANY_BINDING_STARTED, COMPANY_BINDING_SUCCESS} from "../../../eventbus/signEvents";
import {attachPkcs7WithTimestamp, createPkcs7WithTimestamp} from "../eimzo/eimzoReducer";

export const SYSTEM_PROVIDER_INN = "308011454"


export const COMPANY_CATALOGS_INITIALIZATION_STATE_NOT_STARTED = 0
export const COMPANY_CATALOGS_INITIALIZATION_STATE_LOADING = 1
export const COMPANY_CATALOGS_INITIALIZATION_STATE_COMPLETE = 2

export const MEASUREMENTS_INITIALIZATION_STATE_NOT_STARTED = 0
export const MEASUREMENTS_INITIALIZATION_STATE_LOADING = 1
export const MEASUREMENTS_INITIALIZATION_STATE_COMPLETE = 2

export const roamingSlice = createSlice({
    name: 'roaming',
    initialState: {
        companyCatalogs: [],
        countries: [],
        districts: [],
        measurements: [],
        providerBindings: [],
        branches: [],
        roamingBranches: [],
        companyCatalogsInitializationState: COMPANY_CATALOGS_INITIALIZATION_STATE_NOT_STARTED,
        measurementsInitializationState: MEASUREMENTS_INITIALIZATION_STATE_NOT_STARTED,
    },
    reducers: {
        setCompanyCatalogs: (state, action) => {
            state.companyCatalogs = action.payload;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setDistricts: (state, action) => {
            state.districts = action.payload;
        },
        setMeasurements: (state, action) => {
            state.measurements = action.payload;
        },
        setProviderBindings: (state, action) => {
            state.providerBindings = action.payload
        },
        updateRoamingAct: (state, action) => {
            state.acts = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateRoamingActsCount: (state, action) => {
            state.count = action.payload
        },
        updateRoamingBranches: (state, action) => {
            state.branches = action.payload
        },
        setCompanyCatalogsInitializationState: (state, action) => {
            state.companyCatalogsInitializationState = action.payload
        },
        setMeasurementsInitializationState: (state, action) => {
            state.measurementsInitializationState = action.payload
        },
    }
})

export const selectCountries = state => state.roaming.countries;
export const selectDistricts = state => state.roaming.districts;
export const selectMeasurements = state => state.roaming.measurements;
export const selectCompanyCatalogs = state => state.roaming.companyCatalogs;
export const selectRoamingBranches = state => state.roaming.branches;

export const selectProviderBindings = state => state.roaming.providerBindings;

export const selectCompanyCatalogsInitializationState = state => state.roaming.companyCatalogsInitializationState
export const selectMeasurementsInitializationState = state => state.roaming.measurementsInitializationState

export const selectIsSystemProviderEnabled = state => {
    const systemProvider = state.roaming.providerBindings.find(x => x.provider_inn === SYSTEM_PROVIDER_INN) || null;
    if (systemProvider)
        return systemProvider.enabled

    return false
};

export const getOrganizationInfoAsync = inn => {
    return new Promise((resolve, reject) => {
        RoamingService.getOrganizationInfo(inn).then(response => {
            resolve(response.data)
        }, reject)
    })
}

export const getOrganizationInfoSearchAsync = q => {
    return new Promise((resolve, reject) => {
        RoamingService.getOrganizationInfoSearch(q)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export const getMeasurement = (measurements, measurementId) => {
    return measurements?.find(measurement => measurement.measureId === measurementId)
}

export const getBankNameAsync = ({mfo, lang}) => {
    return new Promise((resolve, reject) => {
        RoamingService.getBankName({mfo, lang})
            .then(response => resolve(response.data))
            .catch(errors => reject(errors))
    })
};

export const loadMeasurementsAsync = (lang) => (dispatch) => {
    dispatch(roamingSlice.actions.setMeasurementsInitializationState(MEASUREMENTS_INITIALIZATION_STATE_LOADING))
    return new Promise((resolve, reject) => {
        RoamingService.loadMeasurements(lang)
            .then(response => {
                dispatch(roamingSlice.actions.setMeasurements(response.data))
                resolve(response.data);
            })
            .catch(errors => {
                reject(errors)
            })
            .finally(() => {
                dispatch(roamingSlice.actions.setMeasurementsInitializationState(MEASUREMENTS_INITIALIZATION_STATE_COMPLETE))
            })
    })
};

export const generateIdAsync = (count) => {
    return new Promise((resolve, reject) => {
        RoamingService.generateId(count)
            .then(response => resolve(response.data))
            .catch(errors => reject(errors))
    })
}

export const loadBranchesAsync = (inn) => {
    return new Promise((resolve, reject) => {
        RoamingService.getCompanyBranches(inn)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}

export const loadCompanyBranchesAsync = (inn) => (dispatch) => {
    return new Promise((resolve, reject) => {
        RoamingService.getCompanyBranches(inn)
            .then((response) => {
                dispatch(roamingSlice.actions.updateRoamingBranches(response.data))
                resolve(response.data)
            })
            .catch((e) => {
                console.log(e);
                reject(e);
            });
    })
}

export const loadRoamingBranchesAsync = (inn) => (dispatch) => {
    return new Promise((resolve, reject) => {
        RoamingService.getRoamingBranches(inn)
            .then((response) => {
                resolve(response.data)
            })
            .catch((e) => {
                console.log(e);
                reject(e);
            });
    })
}

export const loadCountriesAsync = (lang) => dispatch => {
    return new Promise((resolve, reject) => {
        RoamingService.loadCountries(lang)
            .then(response => {
                dispatch(roamingSlice.actions.setCountries(response.data));
                resolve(response.data);
            })
            .catch(error => reject(error))
    })
}

export const getOrganizationNdsInfoAsync = ({inn, date}) => {
    return new Promise((resolve, reject) => {
        RoamingService.getOrganizationNdsInfo({inn, date})
            .then(response => resolve(response.data))
            .catch(errors => reject(errors))
    })
};


export const loadDistrictsAsync = (lang) => (dispatch) => {
    return new Promise((resolve, reject) => {
        RoamingService.loadDistricts(lang)
            .then(response => {
                dispatch(roamingSlice.actions.setDistricts(response.data))
                resolve(response.data);
            })
            .catch(errors => {
                reject(errors);
            });
    })
};

export const loadCompanyProductCatalogsAsync = (inn, lang) => dispatch => {
    if (!inn) {
        dispatch(roamingSlice.actions.setCompanyCatalogs(null));
        return;
    }

    dispatch(roamingSlice.actions.setCompanyCatalogsInitializationState(COMPANY_CATALOGS_INITIALIZATION_STATE_LOADING))
    return new Promise((resolve, reject) => {
        RoamingService.getCompanyProductCatalog(inn, lang)
            .then(response => {
                dispatch(roamingSlice.actions.setCompanyCatalogs(response.data));
                resolve();
            }).catch(e => {
            reject(e)
        })
            .finally(() => {
                dispatch(roamingSlice.actions.setCompanyCatalogsInitializationState(COMPANY_CATALOGS_INITIALIZATION_STATE_COMPLETE))
            })
    })
}

export const loadCommonProductCatalogsAsync = ({q, limit}) => {
    return new Promise((resolve, reject) => {
        RoamingService.getCommonProductCatalog({q, limit})
            .then(response => {
                resolve(response.data);
            }).catch(e => reject(e))
    })
}

export const loadProviderBindings = () => async dispatch => {
    try {
        const {data: providerBindings} = await RoamingService.getProviderBindings()
        dispatch(roamingSlice.actions.setProviderBindings(providerBindings))
    } catch (error) {
        console.log(error);
    }
}

export const loadBankInfo = async (id, lang) => {
    try {
        return await RoamingService.getBankInfo(id, lang).then(response => response.data)
    } catch (error) {
        console.log(error);
        return {}
    }
}
export const getPersonInfoAsync = (innOrPinfl, lang) => {
    return new Promise((resolve, reject) => {
        RoamingService.getPersonInfo(innOrPinfl, lang)
            .then(response => resolve(response.data))
            .catch(errors => reject(errors))
    })
};

export const bindSystemAsRoamingProvider = async ({hashCode, certificate}) => {
    try {
        EventBus.dispatch(COMPANY_BINDING_STARTED);
        let {pkcs7: signature} = await createPkcs7WithTimestamp(hashCode, certificate.keyId)
        await RoamingService.bindSystemProvider({signature: signature})

        EventBus.dispatch(COMPANY_BINDING_SUCCESS);
    } catch (error) {
        let detectedError
        if (error?.canceled) {
            detectedError = {error: error, message: "edi.document.sign.notification.canceled"}
        } else if (error?.invalidPassword) {
            detectedError = {error: error, message: "edi.eimzo.notification.wrong_password"}
        } else {
            detectedError = {error: error, message: "edi.select_provider.sign.notification.failded"}
        }

        EventBus.dispatch(COMPANY_BINDING_FAILED, detectedError);
    }
}

export const signDocAndGetRoamingSignature = async (data, certificate, isAttachPkcs7 = false) => {
    try {
        let json = typeof data === "string" ? data : JSON.stringify(data)
        let {pkcs7: signature} = isAttachPkcs7
            ? await attachPkcs7WithTimestamp(json, certificate.keyId)
            : await createPkcs7WithTimestamp(json, certificate.keyId)
        return signature
    } catch (error) {
        console.log(error);
        let detectedError = {error: error, message: ""}
        if (error?.canceled) {
            detectedError.message = "edi.document.sign.notification.canceled"
            detectedError.detected = true
        } else if (error?.invalidPassword) {
            detectedError.message = "edi.eimzo.notification.wrong_password"
            detectedError.detected = true
        } else {
            detectedError.message = "edi.document.sign.notification.default"
            detectedError.detected = true
        }
        throw detectedError
    }
}

export const invalidateCompanyClassCodesAsync = (inn, lang) => {
    return new Promise((resolve, reject) => {
        RoamingService.invalidateCompanyProductCatalog(inn, lang)
            .then(() => resolve())
            .catch((error) => reject(error))
    })
}

export const exportCompanyCatalogsToExcel = (params) => {
    return new Promise((resolve, reject) => {
        RoamingService.exportCompanyCatalogs(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}

export default roamingSlice.reducer;
