import React, {useState} from 'react';
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import excelService from "../../../../services/excelService";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import IconButton from "../../../common/IconButton";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import {uploadOwnerOrganizationsPeriodReportAsync} from "../../../../app/store/reducers/excel/excelReducer";
import {downloadFile} from "../../../../helpers/excel-uploader";

const GetOwnersPeriodReportButton = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const form = useForm({
        defaultValues: {
            dateStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            dateEnd: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)
        }
    });
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = async (formData) => {
        try {
            const params = {
                date_start: dayjs(formData.dateStart).format("YYYY-MM-DD HH:mm:ss"),
                date_end: dayjs(formData.dateEnd).format("YYYY-MM-DD HH:mm:ss")
            };
            setLoading(true);


            const {content, contentType} = await uploadOwnerOrganizationsPeriodReportAsync(params);
            downloadFile({
                content: content,
                contentType: contentType,
                fileName: t(lang, 'warehouse.operation.period_report'),
            });
            setLoading(false);
            handleClose();
            toast.success(t(lang, "common.toast.success"));
            form.reset();
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(t(lang, "common.toast.error"));
        }
    };

    return (
        <React.Fragment>
            <IconButton size="sm"
                        variant="falcon-success"
                        icon={faFileExcel}
                        disabled={loading}
                        onClick={handleShow}
            >
                {t(lang, "warehouse.operation.period_report")}
                {loading && <FontAwesomeIcon className="ms-1" pulse={true} icon={faSpinner}/>}
            </IconButton>
            {show &&
                <Modal show={true}
                       size="md"
                       onHide={handleClose}
                >
                    <FormProvider {...form}>
                        <Form>
                            <Modal.Header>
                                <Modal.Title>
                                    {t(lang, "partners.common.download_period_report_to_excel")}
                                </Modal.Title>
                                <FalconCloseButton onClick={handleClose}/>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="gy-2">
                                    <Col xs={12}>
                                        <Controller name="dateStart"
                                                    rules={{
                                                        required: t(lang, "items.common.validations.is_required")
                                                    }}
                                                    render={({field, fieldState: {error}}) => (
                                                        <Form.Group>
                                                            <Form.Label>{t(lang, "partners.common.download_period_report_to_excel.date_start")}</Form.Label>
                                                            <DatePicker selected={field.value}
                                                                        onChange={field.onChange}
                                                                        isClearable
                                                                        placeholderText={t(lang, "partners.common.download_period_report_to_excel.date_start.placeholder")}
                                                                        dateFormat={`${dateFormat} HH:mm:ss`}
                                                                        showTimeSelect
                                                                        timeFormat="p"
                                                                        timeIntervals={60}
                                                                        wrapperClassName={classNames({
                                                                            'is-invalid': !!error
                                                                        })}
                                                                        className={classNames('form-control', {
                                                                            'is-invalid': !!error,
                                                                        })}
                                                            />
                                                            <Form.Control.Feedback
                                                                type="invalid">{error?.message}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Controller name="dateEnd"
                                                    rules={{
                                                        required: t(lang, "items.common.validations.is_required")
                                                    }}
                                                    render={({field, fieldState: {error}}) => (
                                                        <Form.Group>
                                                            <Form.Label>{t(lang, "partners.common.download_period_report_to_excel.date_end")}</Form.Label>
                                                            <DatePicker selected={field.value}
                                                                        onChange={field.onChange}
                                                                        isClearable
                                                                        placeholderText={t(lang, "partners.common.download_period_report_to_excel.date_end.placeholder")}
                                                                        dateFormat={`${dateFormat} HH:mm:ss`}
                                                                        showTimeSelect
                                                                        timeFormat="p"
                                                                        timeIntervals={60}
                                                                        wrapperClassName={classNames({
                                                                            'is-invalid': !!error
                                                                        })}
                                                                        className={classNames('form-control', {
                                                                            'is-invalid': !!error,
                                                                        })}
                                                            />
                                                            <Form.Control.Feedback
                                                                type="invalid">{error?.message}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    {t(lang, "common.button.cancel")}
                                </Button>
                                <Button onClick={form.handleSubmit(onSubmit)}
                                        disabled={loading}
                                        variant="success"
                                >
                                    {t(lang, "crm.common.download_excel")}
                                    {loading && <FontAwesomeIcon className="ms-1" pulse={true} icon={faSpinner}/>}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </FormProvider>
                </Modal>
            }
        </React.Fragment>
    );
};

export default GetOwnersPeriodReportButton;