export const REGISTER_LEAD_REQUESTED = "REGISTER_LEAD_REQUESTED"
export const REGISTER_LEAD_SUCCESS = "REGISTER_LEAD_SUCCESS"
export const REGISTER_LEAD_FAILED = "REGISTER_LEAD_FAILED"

export const EDIT_LEAD_REQUESTED = "EDIT_LEAD_REQUESTED"
export const EDIT_LEAD_SUCCESS = "EDIT_LEAD_SUCCESS"
export const EDIT_LEAD_FAILED = "EDIT_LEAD_FAILED"

export const DELETE_LEAD_REQUESTED = "DELETE_LEAD_REQUESTED"
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS"
export const DELETE_LEAD_FAILED = "DELETE_LEAD_FAILED"

export const CONVERT_LEAD_TO_CONTRACTOR_REQUESTED = "CONVERT_LEAD_TO_CONTRACTOR_REQUESTED"
export const CONVERT_LEAD_TO_CONTRACTOR_SUCCESS = "CONVERT_LEAD_TO_CONTRACTOR_SUCCESS"
export const CONVERT_LEAD_TO_CONTRACTOR_FAILED = "CONVERT_LEAD_TO_CONTRACTOR_FAILED"

export const ADD_LEAD_NOTE_REQUESTED = 'ADD_LEAD_NOTE_REQUESTED';
export const ADD_LEAD_NOTE_SUCCESS = 'ADD_LEAD_NOTE_SUCCESS';
export const ADD_LEAD_NOTE_FAILED = 'ADD_LEAD_NOTE_FAILED';

export const EDIT_LEAD_NOTE_REQUESTED = 'EDIT_LEAD_NOTE_REQUESTED';
export const EDIT_LEAD_NOTE_SUCCESS = 'EDIT_LEAD_NOTE_SUCCESS';
export const EDIT_LEAD_NOTE_FAILED = 'EDIT_LEAD_NOTE_FAILED';

export const DELETE_LEAD_NOTE_REQUESTED = 'DELETE_LEAD_NOTE_REQUESTED';
export const DELETE_LEAD_NOTE_SUCCESS = 'DELETE_LEAD_NOTE_SUCCESS';
export const DELETE_LEAD_NOTE_FAILED = 'DELETE_LEAD_NOTE_FAILED';
