import React, {useState} from "react";
import IconButton from "../../../../common/IconButton";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {customerDeclineAsync} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import PropTypes from "prop-types";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {useForm} from "react-hook-form";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_INVOICE_BULK_ACTION_FINISHED,
    ROAMING_INVOICE_BULK_ACTION_STARTED
} from "../../../../../app/eventbus/roaming/roamingInvoiceEvents";
import toastPermission from "../../../../../enum/Permission/ToastPermission";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingInvoiceReject} from "../../../../../enum/Permission/RoamingPermission";

const RoamingInvoiceCustomerDecline = ({id, invoices, handleShowProgressBar, updateActionResult, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch()

    // form
    const {formState: {errors}, register, handleSubmit} = useForm({
        defaultValues: {
            comment: ''
        }
    });

    // show/hide modal
    const [show, setShow] = useState(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingInvoiceReject))) setShow(true)
        else dispatch(toastPermission())
    };
    const handleClose = () => setShow(false);

    // button loader
    const [loading, setLoading] = useState(false);

    // decline invoice
    const customerDecline = (formData) => {
        if (invoices.length > 0)
            bulkDecline(formData.comment);
        else
            singleDecline(formData.comment);
    }

    // decline single document
    const singleDecline = (comment) => {
        setLoading(true);
        customerDeclineAsync(activeCertificate, lang, id, comment)
            .then(() => {
                setLoading(false);
                handleClose();
            })
            .catch((error) => {
                setLoading(false);
            })
    };

    // decline multiple documents
    const bulkDecline = async (comment) => {
        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];
            try {
                await customerDeclineAsync(activeCertificate, lang, invoices[i].id, comment)
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.executor.name,
                        inn: invoice.executor.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.executor.name,
                        inn: invoice.executor.inn
                    },
                    error: error?.message
                })
            }
        }
        handleClose();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
    };

    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-danger"
                size="sm"
                icon={faBan}
                className="m-1"
                {...props}
            >
                {t(lang, "roaming.common.decline")}
            </IconButton>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(customerDecline)}>
                    <Modal.Header>
                        <Modal.Title>
                            {t(lang, "roaming.invoice.decline_modal.title")}
                        </Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>
                            {t(lang, "roaming.invoice.decline_modal.description")}
                        </Form.Label>
                        <Form.Control as={"textarea"}
                                      type="text"
                                      placeholder={t(lang, "roaming.invoice.decline_modal.description")}
                                      isInvalid={errors?.comment}
                                      {...register('comment', {
                                          required: t(lang, "roaming.common.forms.validations.is_required")
                                      })}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.comment?.message}</Form.Control.Feedback>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "roaming.common.cancel")}
                        </Button>
                        <SelectCertificateWrapperComponent
                            openDialogButtonText={t(lang, "roaming.common.decline")}
                            submitButton={
                            <Button id="CustomerDecline"
                                    variant="danger"
                                    type="submit"
                                    disabled={loading}
                            >
                                {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                                {t(lang, "roaming.common.decline")}
                            </Button>}/>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

RoamingInvoiceCustomerDecline.propTypes = {
    id: PropTypes.string,
    invoices: PropTypes.array,
    updateActionResult: PropTypes.func,
    handleShowProgressBar: PropTypes.func
}
RoamingInvoiceCustomerDecline.defaultProps = {
    id: '',
    invoices: [],
    updateActionResult: () => {},
    handleShowProgressBar: () => {}
}

export default RoamingInvoiceCustomerDecline;
