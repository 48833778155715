import {Col, Collapse, Form, Row} from "react-bootstrap";
import {
    changeSystemOrganizationsFilterOptionsPayment, clearOrganizationsFilterOptionsPayment,
    selectOrganizationGetPaymentsCount, selectSystemOrganizationsFilterOptionsPayment
} from "../../../../app/store/reducers/billing/billingReducer";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import React, {useState} from "react";
import SystemPaymentsDatatableFilter from "./SystemPaymentsDatatableFilter";
import IconButton from "../../../common/IconButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";


const SystemPaymentsDatatableHeader = () => {
    const organizationPaymentsCount = useSelector(selectOrganizationGetPaymentsCount)
    const filters = useSelector(selectSystemOrganizationsFilterOptionsPayment)
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true)
    const methods = useForm({
        defaultValues: {
            organization_id: null,
            created_on_end: new Date(),
            created_on_start: new Date(),
        }
    })

    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    const onSubmitFilter = ({created_on_start, created_on_end, ...filters}) => {
        created_on_start = created_on_start && dayjs(created_on_start).format("YYYY-MM-DD")
        created_on_end = created_on_end && dayjs(created_on_end).format("YYYY-MM-DD")
        dispatch(changeSystemOrganizationsFilterOptionsPayment({
            created_on_start: created_on_start,
            created_on_end: created_on_end,
            page: 1,
            ...filters
        }))
    }
    const onClear = () => {
        methods.reset()
        dispatch(clearOrganizationsFilterOptionsPayment({
            page: filters.page,
            limit: filters.limit,
            created_on_start: dayjs(new Date()).format('YYYY-MM-DD'),
            created_on_end: dayjs(new Date()).format('YYYY-MM-DD'),
        }))
    }

    return(
        <Row className="d-flex justify-content-between align-items-end">
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                    <Collapse in={isOpenFilterCollapse}>
                        <Col sm={12}>
                            <SystemPaymentsDatatableFilter onClear={onClear} />
                            <hr/>
                        </Col>
                    </Collapse>
                </Form>
            </FormProvider>

            <Col xs={4} sm="auto" className="d-flex flex-column pe-0">
               <h1>{t(lang,"admin.payments.datatable.header.organization.h1")}</h1>
               <p>{t(lang,"admin.common.payments.datatable.count")}: {organizationPaymentsCount}</p>
           </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <IconButton
                    variant="falcon-default"
                    size="sm"
                    transform="shrink-3"
                    className="mx-2"
                    icon="refresh"
                    onClick={methods.handleSubmit(onSubmitFilter)}
                >
                    <span className="d-none d-sm-inline-block ms-1">{t(lang, "admin.common.payments.datatable.resume_order_button")}</span>
                </IconButton>

                <IconButton
                    onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                    variant="falcon-default"
                    size="sm"
                    icon="filter"
                    transform="shrink-3"
                >
                    <span className="d-none d-sm-inline-block ms-1">{t(lang, "admin.common.payments.datatable.navigation.filter")}</span>
                </IconButton>
            </Col>
        </Row>
    )
}


export default SystemPaymentsDatatableHeader;
