import {faCreditCard, faBuildingColumns} from "@fortawesome/free-solid-svg-icons";

const PAYMENT_BY_BANK = 0
const PAYMENT_BY_PAYME = 1

const EDI_ATTORNEY = 'edi_attorney'
const EDI_INVOICE = 'edi_invoice'
const EDI_RETURN_INVOICE = 'edi_return_invoice'
const FREE_FORM_DOCUMENT = 'free_form_document'
const ROAMING_ACT = 'roaming_act'
const ROAMING_CONTRACT = 'roaming_contract'
const ROAMING_EMPOWERMENT = 'roaming_empowerment'
const ROAMING_INVOICE = 'roaming_invoice'
const ROAMING_VERIFICATION_ACT = 'roaming_verification_act'
const ROAMING_WAYBILL = 'roaming_waybill'
const ROAMING_WAYBILL_V2 = 'roaming_waybill_v2'

export const methodPayment = (method) => {
    switch (method) {
        case PAYMENT_BY_BANK :
            return {
                name: 'Банк', icon: faBuildingColumns, bg: 'success'
            }
        case PAYMENT_BY_PAYME :
            return {
                name: 'Payme', icon: faCreditCard, bg: 'info'
            }
    }
}

const documents = [
    EDI_ATTORNEY,
    EDI_INVOICE,
    EDI_RETURN_INVOICE,
    FREE_FORM_DOCUMENT,
    ROAMING_ACT,
    ROAMING_CONTRACT,
    ROAMING_EMPOWERMENT,
    ROAMING_INVOICE,
    ROAMING_VERIFICATION_ACT,
    ROAMING_WAYBILL,
    ROAMING_WAYBILL_V2
]

export const FindDocument = (obj) => {
    const document = returnDocument(obj)
    const findDocument = {value: document.value}

    switch (document.name) {
        case EDI_ATTORNEY :
            return {...findDocument, to: '/edi/attorney/'}
        case EDI_INVOICE :
            return  {...findDocument, to: '/edi/invoice/'}
        case EDI_RETURN_INVOICE :
            return  {...findDocument, to: '/edi/return-invoice/'}
        case ROAMING_INVOICE :
            return  {...findDocument, to: '/roaming/invoice/'}
        case FREE_FORM_DOCUMENT :
            return {...findDocument, to: '/roaming/free-form/'}
        case ROAMING_ACT :
            return  {...findDocument, to: '/roaming/act/'}
        case ROAMING_CONTRACT :
            return  {...findDocument, to: '/roaming/contract/'}
        case ROAMING_EMPOWERMENT :
            return  {...findDocument, to: '/roaming/empowerment/'}
        case ROAMING_VERIFICATION_ACT :
            return {...findDocument, to: '/roaming/verification-act'}
        case ROAMING_WAYBILL :
            return  {...findDocument, to: '/roaming/waybill/'}
        case ROAMING_WAYBILL_V2:
            return  {...findDocument, to: '/roaming/waybill-v2/'}
    }
}

export const returnDocument = (obj) => {
    for (let i = 0; i < documents.length; i++) {
        for (const [key, value] of Object.entries(obj)) {
            if (documents[i] === key && value) {
                return {name: key, value}
            }
        }
    }
}
