import React, {Fragment, useEffect} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import FindCompanySearchable from "../../../../organization/FindCompanySearchable";
import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";

export const roamingInvoiceFormExecutorOrCustomer = (displayName) => {

    const RoamingInvoiceFormExecutorOrCustomer = ({fieldName}) => {

        const t = Translate;
        const lang = useSelector(selectLang);
        const activeOrganization = useSelector(selectActiveOrganization);

        const {register, setValue, formState: {errors}} = useFormContext();


        useEffect(() => {
            if (activeOrganization) {
                if (fieldName === 'executor') {
                    setValue('executor.inn', activeOrganization?.inn)
                    setValue('executor.name', activeOrganization?.name)
                }
            }
        }, [activeOrganization]);

        const onChangeCompany = (company) => {
            if (company) {
                setValue(`${fieldName}`, {
                    name: company.short_name || company.name,
                    address: company.address,
                    bankId: company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''),
                    bankAccount: company.account || company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''),
                })
            } else {
                setValue(`${fieldName}`, {
                    name: null,
                    address: null,
                    bankId: null,
                    bankAccount: null,
                })
            }
        }

        return (
            <Fragment>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>
                                {t(lang, 'roaming.common.inn_or_pinfl')}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller
                                name={`${fieldName}.inn`}
                                shouldUnregister={true}
                                rules={{
                                    required: t(lang, 'items.common.validations.is_required'),
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <FindCompanySearchable
                                        defaultInn={field.value}
                                        onChange={(company) => {
                                            onChangeCompany(company);
                                            field.onChange(company ? (company.person_num || company.inn) : null)
                                        }}
                                        disabled={fieldName === 'executor' && activeOrganization?.inn && true}
                                        inputProps={{
                                            isSearchable: !field.value,
                                            menuPortalTarget: document.body,
                                            styles: {
                                                control: base => ({
                                                    ...base,
                                                    border: error?.message && '1px solid #e63757'
                                                }),
                                                container: (provided) => ({
                                                    ...provided,
                                                    margin: '-1px'
                                                }),
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999
                                                })
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Form.Control.Feedback className={'d-block'} type="invalid">{errors?.[fieldName]?.inn?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>
                                {t(lang, 'roaming.common.organization')}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                name={`${fieldName}.name`}
                                disabled={true}
                                placeholder={t(lang, 'roaming.common.organization.placeholder')}
                                isInvalid={errors?.[fieldName]?.name}
                                {...register(`${fieldName}.name`, {
                                    required: t(lang, 'items.common.validations.is_required'),
                                    shouldUnregister: true
                                })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.[fieldName]?.name?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>
            </Fragment>
        )
    }

    RoamingInvoiceFormExecutorOrCustomer.displayName = displayName || "RoamingInvoiceFormExecutorOrCustomer";

    return RoamingInvoiceFormExecutorOrCustomer;
}
