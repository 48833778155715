import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {APPROVE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED} from "../../../../app/eventbus/contractor/contractorPaymentEvents";
import DraftPaymentDataTable from "../../../../components/hippo/crm/contractor/draft-payment/datatable/DraftPaymentDataTable";
import {loadDraftPaymentsAsync, loadDraftPaymentsCountAsync, selectDraftPaymentsFilterOptions} from "../../../../app/store/reducers/crm/draft-payment/draftPaymentReducer";

const DraftPayments = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectDraftPaymentsFilterOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        const onDraftPaymentSuccessfullyApproved = EventBus.on(APPROVE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, () => {
            loadDraftPayments();
        })

        return () => {
            EventBus.remove(APPROVE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, onDraftPaymentSuccessfullyApproved);
        };
    }, [])

    useEffect(() => {
        loadDraftPayments();
    }, [activeOrganization, filters])

    const loadDraftPayments = () => {
        dispatch(loadDraftPaymentsAsync({...filters}));
        dispatch(loadDraftPaymentsCountAsync({...filters}));
    };

    return <DraftPaymentDataTable/>;
};

export default DraftPayments;