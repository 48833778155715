import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorShipmentSlice = createSlice({
    name: 'contractorShipment',
    initialState: {
        shipments: [],
        countShipments: 0,
        filterOptions: {page: 1, limit: 10, status: 5},
    },
    reducers: {
        updateShipments: (state, action) => {
            state.shipments = action.payload
        },
        updateCountShipments: (state, action) => {
            state.countShipments = action.payload
        },
        updateFilterShipment: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorShipmentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorShipments(params)
        .then(response => {
            dispatch(contractorShipmentSlice.actions.updateShipments(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const loadContractorShipmentsCountAsync = (params) => (dispatch) => {
    let {limit, page, ...others} = params
    contractorService.getContractorShipmentsCount(others)
        .then(response => {
            dispatch(contractorShipmentSlice.actions.updateCountShipments(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const changeFilterContractorShipmentsAsync = (params) => (dispatch) => {
    return dispatch(contractorShipmentSlice.actions.updateFilterShipment(params))
}
export const selectContractorShipments = (state) => state.contractorShipment.shipments;
export const selectContractorShipmentsCount = (state) => state.contractorShipment.countShipments;
export const selectContractorShipmentFilterOptions = (state) => state.contractorShipment.filterOptions;
export default contractorShipmentSlice.reducer