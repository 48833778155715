import React, {useEffect, useState} from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import {useForm, Controller} from "react-hook-form";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import IconButton from "../../common/IconButton";
import classNames from "classnames";
import ReactSelect from "react-select";
import {OrderDeclineReasonStatuses} from "../../../enum/reasons/OrderDeclineReasonType";
import {ExecutorDeclineOrderAsync} from "../../../app/store/reducers/order/orderReducer";
import {AwaitExecutorApprove} from "../../../enum/OrderStatus";
import ActionProgressBar from "../../common/ActionProgressBar";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiOrderReject} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";

const ExecutorDecline = ({id, orders, loadOrders, ...props}) => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, handleSubmit, reset, control} = useForm();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filteredOrders, setFilteredOrders] = useState([]);

    useEffect(() => {
        setFilteredOrders(orders.filter(o => o.status === AwaitExecutorApprove));
    }, [orders])

    const handleShow = () => {
        if (dispatch(checkPermission(PermissionEdiOrderReject))) setShow(true)
        else dispatch(toastPermission())
    };
    const handleClose = () => {
        setShow(false);
        reset();
    };

    const executorDecline = (formData) => {
        if (orders.length)
            bulkDecline(formData);
        else
            singleDecline(formData);
    }

    const singleDecline = (formData) => {
        setLoading(true)
        ExecutorDeclineOrderAsync({
            id: id,
            reason: formData.reason,
            comment: formData.comment
        })
            .then(() => {
                setLoading(false)
                handleClose()
            })
            .catch(error => setLoading(false))

    };

    const bulkDecline = async (formData) => {
        handleShowProgressBar();
        for (let i = 0; i < filteredOrders.length; i++) {
            const order = filteredOrders[i];
            try {
                await ExecutorDeclineOrderAsync( {
                    id: order.id,
                    reason: formData.reason,
                    comment: formData.comment
                })
                updateActionResult({
                    number: order.info.number,
                    date: order.info.date,
                    contractor: {
                        name: order.executor.name,
                        inn: order.executor.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: order.info.number,
                    date: order.info.date,
                    contractor: {
                        name: order.executor.name,
                        inn: order.executor.inn
                    },
                    error: error
                })
            }
        }
        handleClose();
    };

    // show progress modal
    const [showProgressBar, setShowProgressBar] = useState(false);
    const handleShowProgressBar = () => setShowProgressBar(true);
    const handleCloseProgressBar = () => {
        loadOrders();
        setBulkActionResult([]);
        setShowProgressBar(false);
    };

    // bulk action result
    const [bulkActionResult, setBulkActionResult] = useState([]);
    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);

    return (
        <>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={filteredOrders.length}
                               onClose={handleCloseProgressBar}
            />
            <IconButton
                onClick={handleShow}
                variant="falcon-danger"
                size="sm"
                iconClassName="me-1"
                icon={'ban'}
                className="m-1"
                {...props}
            >{t(lang, 'edi.common.decline')}</IconButton>

            <Modal size="md" show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(executorDecline)}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, "edi.orders.order.executor.decline_order_modal.title")}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, "edi.common.modal.reason")}</Form.Label>
                            <Controller
                                name="reason"
                                control={control}
                                rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                render={({field}) => (
                                    <ReactSelect
                                        isClearable
                                        classNamePrefix="react-select"
                                        options={OrderDeclineReasonStatuses}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => t(lang, option.name)}
                                        onChange={option => field.onChange(option?.id)}
                                        className={classNames({'is-invalid': !!errors?.reason?.message})}
                                        placeholder={t(lang, "edi.common.modal.decline_reason.placeholder")}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.reason?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                {...register('comment')}
                                placeholder={t(lang, 'edi.common.leave_comment')}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "edi.common.cancel_button")}
                        </Button>
                        <Button variant="warning" className="d-flex align-items-center" disabled={loading} type="submit">
                            {loading && <Spinner className="me-1" animation="border" role="status" size="sm" />}
                            {t(lang, "edi.common.decline")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    )
};

ExecutorDecline.propTypes = {
    id: PropTypes.string,
    orders: PropTypes.array,
    loadOrders: PropTypes.func
};

ExecutorDecline.defaultProps = {
    id: '',
    orders: [],
    loadOrders: () => {}
};

export default ExecutorDecline;
