import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {getRandomNumber} from "../../helpers/utils";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import {ErrorMessage} from "@hookform/error-message";

export const deletePopover = (displayName) => {
    const DeletePopoverModal = ({deleteRequest, id, show, setShow}) => {
        const lang = useSelector(selectLang)
        const t = Translate

        const [loading, setLoading] = useState(false)
        const [code] = useState(getRandomNumber(100000, 999999))

        const methods = useForm({
            defaultValues: {
                code: null,
            }
        })

        const onApprove = () => {
            setLoading(true)
            deleteRequest(id)
                .then(() => {
                    onHide()
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))
                .finally(() => setLoading(false))
        }

        const onHide = () => {
            setShow()
            methods.setValue('code', null)
            methods.clearErrors('code')
        }

        return <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t(lang, "common.button.delete")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <FormProvider {...methods}>
                   <Form id={'delete-popover'} onSubmit={methods.handleSubmit(onApprove)}>
                       <Form.Group>
                           <Form.Label>
                               <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                               <span className="ms-1 text-primary">{code}</span>
                           </Form.Label>
                           <Controller name={'code'}
                                       control={methods.control}
                                       rules={{
                                           required: t(lang, 'items.common.validations.is_required'),
                                           validate: (value) => {
                                               if (+value === code)
                                                   return true;
                                               return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                           }
                                       }}
                                       render={({field}) => (
                                           <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                   value={field.value}
                                                   htmlRef={field.ref}
                                                   placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                   className={classNames('form-control', {
                                                       'is-invalid': methods.formState.errors?.code
                                                   })}
                                                   options={{
                                                       numeral: true,
                                                       delimiter: ' ',
                                                       numeralDecimalScale: 3
                                                   }}
                                           />
                                       )}
                           />
                           <ErrorMessage name={'code'}
                                         errors={methods.formState.errors}
                                         as={<Form.Control.Feedback type="invalid"/>}
                           />
                       </Form.Group>
                   </Form>
               </FormProvider>
            </Modal.Body>
            <Modal.Footer className={'d-flex justify-content-end'}>
                <Button onClick={onHide} variant={'falcon-primary'}>{t(lang, "crm.common.back.button")}</Button>
                <Button type={'submit'} form={'delete-popover'} variant={'primary'}>
                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                    {t(lang, "warehouse.operation.item.common.status.approve")}
                </Button>
            </Modal.Footer>
        </Modal>
    }

    DeletePopoverModal.displayName = displayName || "DeletePopoverModal"

    return DeletePopoverModal
}
