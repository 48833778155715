export const UPDATE_INVOICE_REGISTRY_SETTINGS_REQUESTED = "UPDATE_INVOICE_REGISTRY_SETTINGS_REQUESTED";
export const UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED = "UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED";
export const UPDATE_INVOICE_REGISTRY_SETTINGS_FAILED = "UPDATE_INVOICE_REGISTRY_SETTINGS_FAILED";

export const UPDATE_WAYBILL_REGISTRY_SETTINGS_REQUESTED = "UPDATE_WAYBILL_REGISTRY_SETTINGS_REQUESTED";
export const UPDATE_WAYBILL_REGISTRY_SETTINGS_SUCCEED = "UPDATE_WAYBILL_REGISTRY_SETTINGS_SUCCEED";
export const UPDATE_WAYBILL_REGISTRY_SETTINGS_FAILED = "UPDATE_WAYBILL_REGISTRY_SETTINGS_FAILED";

export const UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_REQUESTED = "UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_REQUESTED";
export const UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_SUCCEED = "UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_SUCCEED";
export const UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_FAILED = "UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_FAILED";