import {Button, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {
    selectDateDayJSFormat,
    selectLang,
        Translate
} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import ModalMediaContent from "../../modal/kanban/ModalMediaContent";
import ModalSidebar from "../../modal/kanban/ModalSidebar";
import ModalLabelContent from "../../modal/kanban/ModalLabelContent";
import ModalCommentContent from "../../modal/kanban/ModalCommentContent";
import {faFont} from "@fortawesome/free-solid-svg-icons";
import GroupMember from "../../main/GroupMember";
import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    editTaskInColumnDescription,
    editTaskInColumnTitle,

    selectModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";

import {selectEmployees} from "../../../../app/store/reducers/employee/employeeReducer";
import {selectAllContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import dayjs from "dayjs";
import IconButton from "../../../common/IconButton";
import {useParams} from "react-router";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import KanbanItem from "../../KanbanItem";

const ModalForm = ({
                       loading,
                       edit,
                       handlerClose,
                       editDate,
                       boardId
                }) => {

    const employees = useSelector(selectEmployees);
    const contractors = useSelector(selectAllContractor)
    const modalContent = useSelector(selectModalContent)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const dispatch = useDispatch();

    const [editTitle, setEditTitle] = useState(true);
    const [editDescription, setEditDescription] = useState(true);

    const t = Translate;
    const lang = useSelector(selectLang);
    const {register, formState: {errors}, watch, setValue} = useFormContext();

    const title = useWatch({name: 'title', exact: true})
    const description = useWatch({name: 'description', exact: true})
    const assignee = useWatch({name: 'assignee_id', exact: true})
    const creator = useWatch({name: 'creator_id', exact: true})
    const contractor = useWatch({name: 'contractor.id', exact: true})
    const debt_promise_date = useWatch({name: 'contractor.debt_promise_date', exact: true})

    const comments = useFieldArray({
        name: 'comments'
    })

    const handleTitleCancel = () => setValue('title', modalContent?.title)
    const handleDescriptionCancel = () => setValue('description', modalContent?.description)

    return (
        <Row>
            <Col lg={9}>
                <ModalMediaContent title={t(lang, 'task.board.modal.title.task')} icon={faFont}>
                    <InputGroup className={'flex-nowrap'}>
                        <Form.Group className={'w-100'}>
                            <Form.Control
                                type={'text'}
                                disabled={edit ? editTitle : false}
                                placeholder={t(lang, 'task.board.modal.title.task.placeholder') }
                                isInvalid={errors?.title}
                                {...register('title', {
                                    required: t(lang, "crm.common.forms.validations.is_required")
                                })}
                            />
                        </Form.Group>

                        {edit && <EditButtons
                            boardId={boardId}
                            edit={editTitle}
                            setEdit={setEditTitle}
                            action={editTaskInColumnTitle}
                            handleCancel={handleTitleCancel}
                            name={'title'} value={title}
                        />}
                    </InputGroup>

                </ModalMediaContent>

                {creator && <ModalMediaContent title={'task.board.modal.reporter'} icon="user">
                    <GroupMember
                        edit={edit}
                        name={'creator_id'}
                        value={employees.find(i => i.id === creator.id || i.id === creator)?.name}
                        avatarSize="xl"
                    />

                </ModalMediaContent>}

                {assignee && <ModalMediaContent title={t(lang, 'task.board.modal.assigner')} icon="user">
                        <GroupMember
                            name={'assignee_id'}
                            value={employees.find(i => i.id === assignee?.id || i.id === assignee)?.name}
                            avatarSize="xl"
                            edit={edit}
                        />
                </ModalMediaContent>}

                {contractor && <ModalMediaContent title={t(lang, 'crm.common.datatable.contractor')} icon="user">
                    <div className={'d-flex justify-content-between gap-2'}>
                        <GroupMember
                            className={'w-75'}
                            name={'contractor'}
                            value={contractors.find(i => i.id === contractor?.id || i.id === contractor)?.name}
                            avatarSize="xl"
                            edit={edit}
                        />
                        <strong className={'d-block text-end'}>{debt_promise_date && dayjs(debt_promise_date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</strong>
                    </div>

                </ModalMediaContent>}

                <ModalMediaContent title={t(lang, 'task.board.modal.description.task')} icon="align-left">
                    <InputGroup className={'flex-nowrap'}>
                        <Form.Group className={'w-100'}>
                            <Form.Control
                                disabled={edit ? editDescription : false}
                                as="textarea"
                                placeholder={t(lang, 'task.board.modal.description.task.placeholder')}
                                {...register('description')}
                            />
                        </Form.Group>
                        {edit && <EditButtons
                            boardId={boardId}
                            edit={editDescription}
                            setEdit={setEditDescription}
                            action={editTaskInColumnDescription}
                            handleCancel={handleDescriptionCancel}
                            name={'description'} value={description}
                        />}
                    </InputGroup>

                </ModalMediaContent>

                {/* ====== Will be needed ====== */}
                {/* <ModalMediaContent
                    title="Вложения"
                    icon="paperclip"
                    headingClass="d-flex justify-content-between"
                    headingContent={
                        <Controller name={'attachments'}
                                    render={({field}) => (
                                        <UploadAttachments files={field.value} preview />
                                    )}
                        />
                    }
                >
                    <ModalAttachmentContent attachments={attachments}/>
                </ModalMediaContent>*/}

                {edit && <ModalMediaContent
                    title={t(lang, 'task.board.modal.comments')}
                    icon={['far', 'comment']}
                    headingClass="mb-3"
                >
                    <ModalCommentContent
                        boardId={boardId}
                        comments={comments}
                    />
                </ModalMediaContent>}

                {/*{edit && <ModalMediaContent
                    title="Activity"
                    icon="list-ul"
                    headingClass="mb-3"
                    isHr={false}
                >
                    <ModalActivityContent/>
                </ModalMediaContent>}*/}

            </Col>

            <Col lg={3}>
                <ModalSidebar
                    boardId={boardId}
                    edit={edit}
                    handlerClose={handlerClose}
                />
            </Col>

            {edit &&
                <ModalMediaContent
                    title={t(lang, 'task.board.modal.actions')}
                    icon={'list'}
                    headingClass="mb-3"
                    isHr={false}
                >
                    <KanbanItem/>
                </ModalMediaContent>
            }

            {!edit && <Form.Group className={'mt-2'}>
                <Button className={'d-flex align-items-center float-end'} type="submit" variant="falcon-primary"
                        disabled={loading}>
                    {loading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    {t(lang, "task.board.common.add")}
                </Button>
            </Form.Group>}
        </Row>
    );
};


const EditButtons = ({
                         action,
                         name,
                         value,
                         edit,
                         setEdit,
                         handleCancel,
                         boardId
                    }) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const modalContent = useSelector(selectModalContent)
    const [loading, setLoading] = useState(false);

    const t = Translate;
    const lang = useSelector(selectLang);
    const {register, formState: {errors}, watch, setValue} = useFormContext();

    const handleEdit = () => {
        setLoading(true)
        const data = {
            board_column_id: modalContent?.board_column_id,
            board_column_task_id: modalContent?.id,
            [name]: value
        }
        dispatch(action(boardId || id, data))
            .then(() => {
                toast.success(t(lang, 'warehouse.operation.item.common.toast.success'))
            })
            .catch((e) => {
                handleCancel()
                toast.error(t(lang, 'warehouse.operation.item.common.toast.error'))
            })
            .finally(() => {
                setLoading(false)
                setEdit(true)
            })
    }


    return <Fragment>
            {
                edit && <IconButton
                    icon={['fas', 'edit']}
                    variant={'warning'}
                    onClick={() => {
                        setEdit(false)
                    }}
                />
            }
            {
                !edit && <Fragment>
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            handleEdit()
                        }}
                        disabled={edit}
                        className={'d-flex align-items-center gap-1'}
                    >

                        {
                            loading ?
                                <Spinner className="align-middle me-2" animation="border" role="switch"
                                         size="sm"/>
                                : <FontAwesomeIcon icon={'check'}/>
                        }
                    </Button>

                    <IconButton
                        icon={['fas', 'cancel']}
                        variant={'danger'}
                        onClick={() => {
                            handleCancel()
                            setEdit(true)
                        }}
                    />

                </Fragment>
            }
        </Fragment>
}

export default ModalForm;
