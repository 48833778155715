import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../../common/IconButton";
import {onIdentifyItems} from "../../../../../../app/store/reducers/crm/order/crmCustomerOrderReducer";

const CrmOrderIdentifyItems = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onClickProductMap = () => {
        setLoading(true);
        onIdentifyItems({id: id}).then(res => {
            toast.success(t(lang, "items.common.toast.success"))
        }).catch(err => {
            toast.error(t(lang, "items.common.toast.error"))
        }).finally(f => {
            setLoading(false)
        })
    }

    return (
        <>
            <IconButton
                variant="falcon-primary"
                size="sm"
                icon={faSync}
                iconClassName="me-1"
                className="ms-2"
                {...props}
                onClick={onClickProductMap}>
                <span className="d-none d-sm-inline-block">{t(lang, 'crm.order.identify.items')}</span>
                {loading && (
                    <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
                )}
            </IconButton>
        </>
    );
};

export default CrmOrderIdentifyItems;