import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {useHistory, useParams} from "react-router";
import {selectLang} from "../../../../app/store/reducers/main/mainReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionContractFormationCreate} from "../../../../enum/Permission/EdiPermission";
import {loadContractOneAsync} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";
import {
    COMPANY_CATALOGS_INITIALIZATION_STATE_COMPLETE,
    loadCompanyProductCatalogsAsync,
    loadMeasurementsAsync, MEASUREMENTS_INITIALIZATION_STATE_COMPLETE,
    selectCompanyCatalogsInitializationState,
    selectMeasurementsInitializationState
} from "../../../../app/store/reducers/roaming/roamingReducer";
import {useState, useEffect} from "react";
import EditContractFormationForm from "../../../../components/hippo/contract-formation/EditContractFormationForm";


const EditContractFormation = () => {
    const companyCatalogsInitializationState = useSelector(selectCompanyCatalogsInitializationState)
    const measurementsInitializationState = useSelector(selectMeasurementsInitializationState)
    const [document, setDocument] = useState(null)
    const activeOrganization = useSelector(selectActiveOrganization)
    const permission = useSelector(selectAccountRolePermissions)
    const dispatch = useDispatch()
    const history = useHistory()
    const lang = useSelector(selectLang)
    const {id} = useParams()

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionContractFormationCreate)) ) {
            if (history.length > 1) {
                history.push('/edi/contracts-formation')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    const loadContractFormation = async () => {
        const contract = await loadContractOneAsync(id)
        setDocument(contract?.data?.document)
    }

    useEffect(async () => {
        await loadContractFormation()
    }, [id, activeOrganization])

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
        dispatch(loadMeasurementsAsync(lang));
    },[lang, activeOrganization])



    if (companyCatalogsInitializationState !== COMPANY_CATALOGS_INITIALIZATION_STATE_COMPLETE)
        return <>catalogs loading...</>

    if (measurementsInitializationState !== MEASUREMENTS_INITIALIZATION_STATE_COMPLETE)
        return <>measurements loading...</>

    if (!document)
        return <>loading...</>


    if (document) {
        return(
            <>
                <EditContractFormationForm document={document}/>
            </>
        )
    }
}

export default EditContractFormation;