import React, {useEffect} from "react";
import {Col, Form, Row, Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import numeral from "numeral";
import SelectCompanyCatalog from "../../../IKPU/SelectCompanyCatalog";
import {selectProductCatalogs} from "../../../../../app/store/reducers/invoice/invoiceReducer";
import {selectCompanyCatalogs} from "../../../../../app/store/reducers/roaming/roamingReducer";
import classNames from "classnames";
import ReactSelect from "react-select";
import Cleave from "cleave.js/react";
import {ReturnInvoiceNdsZeroNoneVatPayers} from "../../../../../enum/ReturnInvoiceStatus";
import OriginLinkSelector from "../../../../common/OriginLinkSelector";


const itemRootName = "items";

const ReturnInvoiceItems = ({productMapNames}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);
    const storageCatalogs = useSelector(selectProductCatalogs);
    const catalogs = useSelector(selectCompanyCatalogs)

    const {register, setValue, formState: {errors}, getValues} = useFormContext();
    const {fields} = useFieldArray({name: itemRootName});
    const watchFieldArray = useWatch({name: itemRootName});
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const ndsOptions = [
        {value: null, label: 'No'},
        {value: 0, label: '0'},
        {value: 12, label: '12'},
        {value: 15, label: '15'}
    ];

    // calculate and set total
    useEffect(() => {
        const totalSum = controlledFields.reduce((acc, item) => acc + item.totalWithNds, 0);
        setValue('returnInvoiceTotalSum', totalSum);
    }, [controlledFields])

    // map names
    useEffect(() => {
        if (productMapNames.length) {
            // loop form fields
            for (let fieldIndex = 0; fieldIndex < controlledFields.length; fieldIndex++) {
                for (let productMapIndex = 0; productMapIndex < productMapNames.length; productMapIndex++) {
                    let fieldProductMapFound = false;
                    const productMap = productMapNames[productMapIndex];

                    for (let productIndex = 0; productIndex < productMap.products.length; productIndex++) {
                        const {customer_product_name, executor_product_name} = productMap.products[productIndex];

                        // if found set executor product name
                        if (customer_product_name.trim() === controlledFields[fieldIndex].product.trim()) {
                            setValue(`${itemRootName}.${fieldIndex}.product`, executor_product_name);
                            fieldProductMapFound = true;
                            break;
                        }
                    }
                    if (fieldProductMapFound)
                        break;
                }
            }
        }
    }, [productMapNames])

    // set default catalogs/benefits/item_packages
    useEffect(() => {
        controlledFields.map((item, index) => {
            if (item.catalogCode)
                return;

            const catalog = catalogs.find(c => c.class_code === storageCatalogs?.[item.product]?.catalogClassCode);
            if (catalog) {
                onChangeCatalog(index, catalog);
                onChangeOrigin(index, storageCatalogs[item.product].origin);

                const itemPackage = catalog.package_names.find(p => p.code.toString() === storageCatalogs?.[item.product]?.packageCode);
                const benefit = catalog.lgotas.find(b => b.new_id === storageCatalogs?.[item.product]?.benefitId || b.id === storageCatalogs?.[item.product]?.benefitId);
                if (itemPackage)
                    onChangePackage(index, itemPackage)
                if (benefit)
                    onChangeBenefit(index, benefit)
            }
        })
    }, [storageCatalogs, catalogs])

    // on change bulk catalog/privilege
    const onBulkChangeCatalog = (catalog, benefit) => {
        for (let i = 0; i < controlledFields.length; i++) {
            onChangeCatalog(i, catalog);
            onChangeBenefit(i, benefit);
        }
    };

    const onBulkChangeOrigin = (origin) => {
        for (let i = 0; i < controlledFields.length; i++) {
            onChangeOrigin(i, origin);
        }
    };

    const onChangeOrigin = (index, origin) => {
        setValue(`${itemRootName}.${index}.origin`, origin || null);
    };

    const onChangeCatalog = (index, catalog) => {
        if (catalog) {
            setValue(`${itemRootName}.${index}.catalogCode`, catalog.class_code);
            setValue(`${itemRootName}.${index}.catalogName`, catalog.name);
            setValue(`${itemRootName}.${index}.packageNames`, catalog.package_names);
            setValue(`${itemRootName}.${index}.usePackage`, catalog.use_package);
            setValue(`${itemRootName}.${index}.origin`, null);
        } else {
            setValue(`${itemRootName}.${index}.catalogCode`, null);
            setValue(`${itemRootName}.${index}.catalogName`, null);
            setValue(`${itemRootName}.${index}.packageNames`, []);
            setValue(`${itemRootName}.${index}.usePackage`, false);
            setValue(`${itemRootName}.${index}.origin`, null);
        }

        onChangeBenefit(index, null);
        onChangePackage(index, null);
    };

    const onChangeBenefit = (index, benefit) => {
        if (benefit) {
            const invoiceDate = getValues('returnInvoiceInfo.date');
            const ndsRate = invoiceDate?.getFullYear() > 2022 ? 12 : 15;
            const benefitId = invoiceDate?.getFullYear() >= 2022 ? benefit.new_id : benefit.id
            let benefitPrice = 0;

            if (benefit.type === ReturnInvoiceNdsZeroNoneVatPayers)
                benefitPrice = getValues(`${itemRootName}.${index}.total`) * ndsRate / 100;

            setValue(`${itemRootName}.${index}.benefitId`, benefitId)
            setValue(`${itemRootName}.${index}.benefitName`, benefit.name)
            setValue(`${itemRootName}.${index}.benefitType`, benefit.type)
            setValue(`${itemRootName}.${index}.benefitPrice`, benefitPrice);
        } else {
            setValue(`${itemRootName}.${index}.benefitId`, null)
            setValue(`${itemRootName}.${index}.benefitName`, null)
            setValue(`${itemRootName}.${index}.benefitType`, null)
            setValue(`${itemRootName}.${index}.benefitPrice`, null)
        }
    };

    const onChangePackage = (index, itemPackage) => {
        if (itemPackage) {
            setValue(`${itemRootName}.${index}.packageCode`, itemPackage.code)
            setValue(`${itemRootName}.${index}.packageName`, lang === "ru" ? itemPackage.name_ru : itemPackage.name_uz);
        } else {
            setValue(`${itemRootName}.${index}.packageCode`, null)
            setValue(`${itemRootName}.${index}.packageName`, null);
        }
    };

    const onChangeQuantity = (index, quantity) => {
        const itemName = `${itemRootName}.${index}`;
        const ndsRate = +getValues(`${itemName}.ndsRate`) || 0

        const total = getValues(`${itemName}.price`) * quantity;
        const ndsValue = total * ndsRate / 100;
        const totalWithNds = total + ndsValue;

        setValue(`${itemName}.quantity`, quantity);
        setValue(`${itemName}.total`, total);
        setValue(`${itemName}.ndsValue`, ndsValue);
        setValue(`${itemName}.totalWithNds`, totalWithNds);
    };

    const onChangeNdsRate = (index, nds) => {
        const itemName = `${itemRootName}.${index}`;
        const ndsRate = +nds || 0

        const total = getValues(`${itemName}.price`) * getValues(`${itemName}.quantity`);
        const ndsValue = total * ndsRate / 100;
        const totalWithNds = total + ndsValue;
        setValue(`${itemName}.ndsRate`, ndsRate)
        setValue(`${itemName}.nds`, ndsRate);
        setValue(`${itemName}.total`, total);
        setValue(`${itemName}.ndsValue`, ndsValue);
        setValue(`${itemName}.totalWithNds`, totalWithNds);
    }

    useEffect(() => {
        controlledFields.forEach((item, index) => {
            const itemName = `${itemRootName}.${index}`;
            const ndsRate = itemName.ndsRate || 0
            const total = getValues(`${itemName}.price`) * getValues(`${itemName}.quantity`);
            const ndsValue = total * ndsRate / 100
            return setValue(`${itemName}.totalWithNds`, total + ndsValue)
        })
    }, [])

    const subTotal = controlledFields.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const tax = controlledFields.reduce((acc, item) => {
        return acc + ((item.price * item.quantity) * item.ndsRate) / 100
    }, 0);

    return (
        <Row>
            <Col xs={12} className="text-end">
                <SelectCompanyCatalog variant="falcon-default"
                                      size="sm"
                                      className="w-auto"
                                      textButton={t(lang, "edi.common.select_common_catalog_class_code_button")}
                                      onChange={onBulkChangeCatalog}
                />
            </Col>
            <Col xs={12}>
                <Table responsive striped bordered hover className="mt-3 border-300 overflow-auto">
                    <thead className="bg-200 text-dark">
                    <tr className="fs--1 text-center">
                        <th>№</th>
                        <th>
                            {t(lang, "edi.common.items.product.catalog_class_code")}
                            <span className={'text-danger'}>*</span>
                        </th>
                        <th>{t(lang, "edi.common.items.product")}</th>
                        <th>{t(lang, "edi.common.items.product.benefit")}</th>
                        <th>{t(lang, "edi.common.items.product.barcode")}</th>
                        <th>{t(lang, "edi.common.items.product.measurement")}</th>
                        <th>
                            {t(lang, "edi.common.items.product.quantity")}
                            <span className={'text-danger'}>*</span>
                        </th>
                        <th>{t(lang, "edi.common.items.product.price")}</th>
                        <th>{t(lang, "edi.common.items.product.total")}</th>
                        <th>{t(lang, "edi.common.items.product.nds")}</th>
                        <th>{t(lang, "edi.common.items.product.nds_value")}</th>
                        <th>{t(lang, "edi.common.items.product.total_with_nds")}</th>
                        <th>
                            <Controller name={`common.origin`}
                                        render={() => {
                                            return <OriginLinkSelector isTitle={true} onChange={origin => onBulkChangeOrigin(origin)}/>
                                        }}/>
                        </th>
                        {/*<th>*/}
                        {/*    {t(lang, "edi.common.origin")}*/}
                        {/*    <RoamingInvoiceOriginSelector catalogCode={null}*/}
                        {/*                                  defaultOriginType={null}*/}
                        {/*                                  onChange={onBulkChangeOrigin}*/}
                        {/*    />*/}
                        {/*</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {controlledFields.map((item, index) => {
                        const itemName = `${itemRootName}.${index}`;
                        const itemErrors = errors && errors[itemRootName] && errors[itemRootName][index];

                        const benefitId = item.benefitId;
                        const usePackage = item.usePackage;
                        const packageNames = item.packageNames;
                        const activePackage = packageNames.find(p => p.code === item.packageCode) || null

                        return (
                            <tr className="fw-semi-bold text-center align-middle fs--1" key={item.id}>
                                <td className="p-0">{index + 1}</td>
                                <td className="p-0 w-25 w-lg-auto">
                                    <Controller name={`${itemName}.catalogCode`}
                                                rules={{
                                                    required: t(lang, "edi.common.forms.validations.is_required")
                                                }}
                                                render={({field}) => (
                                                    <SelectCompanyCatalog defaultCatalogCode={field.value}
                                                                          defaultLgota={benefitId}
                                                                          textButton={field.value || t(lang, "edi.common.button.select")}
                                                                          className={classNames('text-primary', {
                                                                              'text-danger': itemErrors?.catalog
                                                                          })}
                                                                          onChange={(catalog, benefit) => {
                                                                              onChangeCatalog(index, catalog);
                                                                              onChangeBenefit(index, benefit);
                                                                          }}
                                                                          variant={itemErrors?.catalogCode ? 'outline-danger' : 'outline-primary'}
                                                    />
                                                )}
                                    />
                                </td>
                                <td>
                                    {productMapNames.length &&
                                        <Form.Control name="product"
                                                      type="text"
                                                      isInvalid={itemErrors?.product}
                                                      isValid={Object.keys(errors).length > 0 && !itemErrors?.product}
                                                      {...register(`${itemName}.product`, {
                                                          required: t(lang, "edi.common.forms.validations.is_required")
                                                      })}
                                        />
                                    }
                                    {!productMapNames.length && <>{item.product}</>}
                                </td>
                                <td className="p-0">
                                    {benefitId ? <span className="text-success">{t(lang, "edi.common.items.product.selected")}</span> :
                                        <span className="text-warning">{t(lang, "edi.common.items.product.not_selected")}</span>
                                    }
                                </td>
                                <td className="p-0">{item.barcode}</td>
                                <td className="p-0">
                                    {usePackage &&
                                        <>
                                            <Controller name={`${itemName}.packageCode`}
                                                        rules={{
                                                            required: t(lang, "edi.common.forms.validations.is_required")
                                                        }}
                                                        render={({field}) => (
                                                            <ReactSelect options={packageNames}
                                                                         hideSelectedOptions
                                                                         isClearable
                                                                         placeholder={t(lang, "edi.common.button.select")}
                                                                         getOptionLabel={option => lang === "ru" ? `${option.name_ru} - ${option.code}` : `${option.name_uz} - ${option.code}`}
                                                                         getOptionValue={option => option.code}
                                                                         classNamePrefix="react-select"
                                                                         menuPortalTarget={document.body}
                                                                         className={classNames({
                                                                             'is-invalid': itemErrors?.packageCode
                                                                         })}
                                                                         onChange={(itemPackage) => onChangePackage(index, itemPackage)}
                                                                         value={activePackage}
                                                            />
                                                        )}
                                            />
                                            <Form.Control.Feedback type="invalid">{itemErrors?.packageCode?.message}</Form.Control.Feedback></>
                                    }
                                    {!usePackage && <>{item.measurement}</>}
                                </td>
                                <td className="p-0" style={{width: "40px"}}>
                                    <Controller name={`${itemName}.quantity`}
                                                rules={{
                                                    required: t(lang, "edi.common.forms.validations.is_required")
                                                }}
                                                render={({field}) => (
                                                    <Cleave
                                                        className={classNames("form-control", {
                                                            'is-invalid': itemErrors?.quantity,
                                                            'is-valid': Object.keys(errors).length > 0 && !itemErrors?.quantity
                                                        })}
                                                        options={{
                                                            numeral: true,
                                                            delimiter: '',
                                                            numeralDecimalScale: 3
                                                        }}
                                                        onChange={(event) => onChangeQuantity(index, +event.target.rawValue)}
                                                        value={field.value}
                                                    />
                                                )}
                                    />
                                </td>

                                <td className="px-1 text-nowrap">{numeral.formats[numberFormat].format(item.price)}</td>
                                <td className="px-1 text-nowrap">{numeral.formats[numberFormat].format(item.quantity * item.price)}</td>
                                <td className="p-1">
                                    <Form.Select className="p-1" {...register(`${itemName}.ndsRate`)} onChange={(event) => onChangeNdsRate(index, event.target.value)}>
                                        {ndsOptions.map(ndsOption => {
                                            return <option value={ndsOption.value} key={ndsOption.value}>{ndsOption.label}</option>
                                        })}
                                    </Form.Select>
                                </td>
                                <td className="px-1 text-nowrap">{numeral.formats[numberFormat].format(item.ndsValue)}</td>
                                <td className="px-1 text-nowrap">{numeral.formats[numberFormat].format(item.totalWithNds)}</td>
                                <td className="px-1">
                                    <Controller name={`${itemName}.origin`}
                                                rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                                render={() => {
                                                    return <OriginLinkSelector linkAppendClassName={classNames({
                                                        'text-danger': itemErrors?.origin
                                                    })}
                                                                               defaultOriginType={item.origin} onChange={origin => onChangeOrigin(index, origin)}/>
                                                }}/>
                                </td>
                                {/*<td className="p-0">*/}
                                {/*    <Controller name={`${itemName}.origin`}*/}
                                {/*                rules={{*/}
                                {/*                    required: t(lang, 'edi.common.forms.validations.is_required')*/}
                                {/*                }}*/}
                                {/*                render={({field}) => (*/}
                                {/*                    <RoamingInvoiceOriginSelector catalogCode={item.catalogCode}*/}
                                {/*                                                  defaultOriginType={field.value}*/}
                                {/*                                                  onChange={(origin) => onChangeOrigin(index, origin)}*/}
                                {/*                                                  selectProps={{*/}
                                {/*                                                      className: classNames({*/}
                                {/*                                                          'is-invalid': itemErrors?.origin*/}
                                {/*                                                      }),*/}
                                {/*                                                      menuPortalTarget: document.body*/}
                                {/*                                                  }}*/}
                                {/*                    />*/}
                                {/*                )}*/}
                                {/*    />*/}
                                {/*    <Form.Control.Feedback type="invalid">{itemErrors?.origin?.message}</Form.Control.Feedback>*/}
                                {/*</td>*/}
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </Col>
            <Row className="g-0 justify-content-end">
                <Col xs={"auto"}>
                    <Table borderless size="sm" className="fs--1 text-end">
                        <tbody>
                        <tr>
                            <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                            <td className="fw-semi-bold">
                                {numeral.formats[numberFormat].format(subTotal)}
                            </td>
                        </tr>
                        <tr>
                            <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                            <td className="fw-semi-bold">
                                {numeral.formats[numberFormat].format(tax)}
                            </td>
                        </tr>
                        <tr className="border-top">
                            <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                            <td className="fw-semi-bold">
                                {numeral.formats[numberFormat].format(subTotal + tax)}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Row>
    )
};


export default ReturnInvoiceItems;
