import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import {ReturnShipmentStatuses} from "../../../enum/ReturnShipmentStatus";


const SelectReturnShipmentStatus = ({defaultValue, onChange, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const options = ReturnShipmentStatuses.map((status) => {
        return {
            value: status.id,
            label: t(lang, status.name),
        };
    })
    const [shipmentStatus, setShipmentStatus] = useState(options.find(s => s.value === defaultValue) || null)

    useEffect(() => {
        onChange(shipmentStatus ? shipmentStatus.value : null);
    }, [shipmentStatus])

    return (
        <Select {...props} classNamePrefix="react-select" hideSelectedOptions onChange={option => setShipmentStatus(option)} options={options} value={shipmentStatus}/>
    );
};


SelectReturnShipmentStatus.propTypes = {
    onChange: PropTypes.func
}
SelectReturnShipmentStatus.defaultProps = {
    onChange: () => {
    }
}

export default SelectReturnShipmentStatus;