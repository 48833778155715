import classNames from "classnames";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import SoftBadge from "../../../common/SoftBadge";
import {Controller, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import FindCompany from "../../../hippo/organization/FindCompany";
import {Button, Card, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import FindCompanySearchable from "../../../hippo/organization/FindCompanySearchable";

const FreeFormDocumentForm = ({loading}) => {
    const {register, formState: {errors}, control, setValue, watch} = useFormContext();
    const [selectedFile, setSelectedFile] = useState(null);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const pdf = watch('file');
    const onSelectFile = event => {
        const [file] = event.target.files;
        const reader = new FileReader();
        setSelectedFile(file);

        reader.readAsDataURL(file);
        reader.onload = e => {
            setValue('file', e.target.result, {shouldValidate: true});
        }
    };

    return (
        <>
            <Card>
                <Card.Header className="bg-light">
                    <Card.Title>{t(lang, 'roaming.free_form_document.save.document_info.title')}</Card.Title>
                </Card.Header>
                <Card.Body as={Row}>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.free_form_document.save.document_info.number.title')}<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                isInvalid={errors?.freeform?.number}
                                placeholder={t(lang, 'roaming.free_form_document.save.document_info.number.placeholder')}
                                {...register('freeform.number', {required: t(lang, 'roaming.validations.is_required')})}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.freeform?.number?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Label>{t(lang, 'roaming.free_form_document.save.document_info.date.title')}<span className="text-danger">*</span></Form.Label>
                        <Controller
                            control={control}
                            name='freeform.date'
                            rules={{required: t(lang, 'roaming.validations.is_required')}}
                            render={({field}) => (
                                <DatePicker
                                    isClearable
                                    selected={field.value}
                                    dateFormat={dateFormat}
                                    onChange={(date) => field.onChange(date)}
                                    wrapperClassName={classNames({'is-invalid': errors?.freeform?.date})}
                                    className={classNames('form-control', {'is-invalid': errors?.freeform?.date})}
                                    placeholderText={t(lang, 'roaming.free_form_document.save.document_info.date.placeholder')}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.freeform?.date?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Card.Body>
            </Card>

            <Card className="mt-3">
                <Card.Header className="bg-light">
                    <Card.Title>{t(lang, 'roaming.free_form_document.save.member_info.title')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <h4>{t(lang, 'roaming.free_form_document.save.member_info.sender')}</h4>
                            <Form.Group className="mb-2 mt-3">
                                <Form.Label>{t(lang, 'roaming.free_form_document.save.member_info.receiver.inn')}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    {...register('sender.inn')}
                                    placeholder={t(lang, 'roaming.free_form_document.save.member_info.sender.inn.placeholder')}
                                />
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.free_form_document.save.member_info.receiver.name')}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    {...register('sender.name')}
                                    placeholder={t(lang, 'roaming.free_form_document.save.member_info.sender.name.placeholder')}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <h4>{t(lang, 'roaming.free_form_document.save.member_info.receiver')}</h4>
                            <Form.Group className="mb-2 mt-3">
                                <Form.Label>{t(lang, 'roaming.free_form_document.save.member_info.receiver.inn')}<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    name="receiver.inn"
                                    rules={{required: t(lang, 'roaming.validations.is_required')}}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            defaultInn={field.value}
                                            inputProps={{
                                                isInvalid: errors?.receiver?.inn,
                                                placeholder: t(lang, 'edi.toast.find_company_searchable:placeholder'),
                                            }}
                                            onChange={company => {
                                                field.onChange(company?.inn);
                                                setValue('receiver.name', company?.name || '');
                                            }}
                                            inputGroupProps={{className: classNames({'is-invalid': errors?.receiver?.inn})}}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.receiver?.inn?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.free_form_document.save.member_info.receiver.name')}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    {...register('receiver.name', {required: t(lang, 'roaming.validations.is_required')})}
                                    placeholder={t(lang, 'roaming.free_form_document.save.member_info.receiver.name.placeholder')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="mt-3">
                <Card.Header className="bg-light">
                    <Card.Title>{t(lang, 'roaming.free_form_document.save.file_upload.title')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <div className="w-100 text-end">
                                {pdf && <PdfFilePreview url={pdf}/>}
                            </div>

                            <Card className="my-3 shadow-none border-dashed bg-light">
                                <Controller
                                    name="file"
                                    rules={{required: t(lang, 'roaming.free_form_document.save.file_upload.file_uploader.validation.is_required')}}
                                    render={({}) => (
                                        <Form.Control
                                            type="file"
                                            id="fileUploader"
                                            className="d-none"
                                            onChange={onSelectFile}
                                            isInvalid={errors?.file}
                                            accept="application/pdf"
                                        />
                                    )}
                                />
                                <Card.Body as="label" htmlFor="fileUploader" className="text-center cursor-pointer py-4">
                                    <h4>
                                        <FontAwesomeIcon icon={faCloudArrowUp}/>
                                        <span className="ms-2">{t(lang, 'roaming.free_form_document.save.file_upload.file_uploader.title')}</span>
                                        <span className="text-danger">*</span>
                                    </h4>
                                    <small className="text-danger my-0">{errors?.file?.message}</small>
                                    {selectedFile && <span>{selectedFile?.name}</span>}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="text-end border-top">
                    <Button type="submit">
                        {loading && <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>}
                        {t(lang, 'roaming.free_form_document.save.button.submit.title')}
                    </Button>
                </Card.Footer>
            </Card>
        </>
    );
};


const PdfFilePreview = ({url}) => {
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <SoftBadge className="cursor-pointer">
                <FontAwesomeIcon icon="eye"/>
                <span className="ms-1" onClick={handleShow}>{t(lang, 'roaming.free_form_document.save.file_upload.preview_modal.show_button.title')}</span>
            </SoftBadge>

            <Modal fullscreen={true} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'roaming.free_form_document.save.file_upload.preview_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0" as="object" data={url} title="test" name="Test" type="application/pdf"/>
            </Modal>
        </>
    )
};

export default FreeFormDocumentForm;
