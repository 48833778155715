import React, {useCallback, useState} from 'react';
import IconButton from "../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {deleteDraftItemAsync} from "../../../../app/store/reducers/draft-item/draftItemReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {BULK_DELETE_DRAFT_ITEMS_REQUESTED, BULK_DELETE_DRAFT_ITEMS_SUCCESS} from "../../../../app/eventbus/itemEvents";

const BulkDeleteDraftItem = ({drafts, buttonProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const onDeleteDraftItems = useCallback(async () => {
        setLoading(true);

        EventBus.dispatch(BULK_DELETE_DRAFT_ITEMS_REQUESTED);
        for (const draft of drafts) {
            await deleteDraftItemAsync(draft.id)
        }
        EventBus.dispatch(BULK_DELETE_DRAFT_ITEMS_SUCCESS);

        setLoading(false);
        setShow(false);
    }, [drafts])

    return (
        <React.Fragment>
            <IconButton variant="falcon-danger"
                        icon={faTrash}
                        onClick={() => setShow(true)}
                        {...buttonProps}
            >
                {t(lang, 'items.common.delete')}
            </IconButton>

            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "items.common.draft.delete.title")}
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "items.delete_item_modal.title")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        {t(lang, "items.common.cancel")}
                    </Button>
                    <Button variant="danger" onClick={onDeleteDraftItems} disabled={loading}>
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                        }
                        {t(lang, "items.common.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>

    );
};

export default BulkDeleteDraftItem;

BulkDeleteDraftItem.propTypes = {
    drafts: PropTypes.array.isRequired
}