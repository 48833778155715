import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import ExcelRegistryDataTable from "../../../../components/hippo/excel-registry/datatable/ExcelRegistryDataTable";
import {
    loadExcelRegistriesAsync,
    loadExcelRegistriesCountAsync,
    selectExcelRegistriesFilterOptions
} from "../../../../app/store/reducers/excel-registry/excelRegistryReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {ROAMING_EXCEL_REGISTRY_SUCCESS} from "../../../../app/eventbus/excelRegistryEvents";

const ExcelRegistries = () => {
    const dispatch = useDispatch();
    const filter = useSelector(selectExcelRegistriesFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);

    useEffect(() => {
        const onRoamingExcelRegistrySuccess = EventBus.on(ROAMING_EXCEL_REGISTRY_SUCCESS, (res) => {
            loadExcelRegistries()
        })

        return () => {
            EventBus.remove(ROAMING_EXCEL_REGISTRY_SUCCESS, onRoamingExcelRegistrySuccess);
        }
    }, [])

    useEffect(() => {
        loadExcelRegistries()
    }, [activeOrganization, filter])

    const loadExcelRegistries = () => {
        dispatch(loadExcelRegistriesAsync(filter))
        dispatch(loadExcelRegistriesCountAsync(filter))
    }

    return (
        <>
            <ExcelRegistryDataTable/>
        </>
    );
};

export default ExcelRegistries;