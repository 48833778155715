import React, {useEffect, useState} from 'react';
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import dayjs from "dayjs";
import RoamingReferenceService from "../../../../../services/roamingReference/roamingReferenceService";

const RoamingInvoiceFormRentContractInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const form = useFormContext();
    const sellerInnOrPinfl = useWatch({name: `seller.innOrPinfl`, exact: true});
    const buyerInnOrPinfl = useWatch({name: `buyer.innOrPinfl`, exact: true});
    const [rentContracts, setRentContracts] = useState([]);

    const onSelectRentContract = (contract) => {
        if (contract) {
            const date = dayjs(contract.date, 'YYYY-MM-DD')
            form.setValue('contractDoc.contractNo', contract.id);
            form.setValue('contractDoc.contractDate', date.toDate());
        } else {
            form.setValue('contractDoc.contractNo', '');
            form.setValue('contractDoc.contractDate', null);
        }
    };

    useEffect(async () => {
        if (sellerInnOrPinfl && buyerInnOrPinfl) {
            try {
                const {data} = await RoamingReferenceService.getRentContracts({
                    seller: sellerInnOrPinfl,
                    buyer: buyerInnOrPinfl
                });
                setRentContracts(data)
            } catch (e) {
                console.error(e)
                setRentContracts([])
            }
        } else {
            setRentContracts([])
        }
    }, [sellerInnOrPinfl, buyerInnOrPinfl]);

    return (
        <Row className="g-2">
            <Col xs={12} md={4}>
                <Form.Label>
                    {t(lang, 'roaming.invoice.form.rent.contract_selector.title')}
                    <span className="text-danger">*</span>
                </Form.Label>
                <ReactSelect options={rentContracts}
                             getOptionLabel={option => t(lang, 'roaming.invoice.form.rent.contract_selector.option', {
                                 id: option.id,
                                 date: option.date
                             })}
                             onChange={onSelectRentContract}
                />
            </Col>
            <Col xs={12} md={4}>
                <Controller name={'facturaRentDoc.from'}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required'),
                            }}
                            render={({field, fieldState: {error}}) => (
                                <Form.Group>
                                    <Form.Label>
                                        {t(lang, 'roaming.invoice.rent.from')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <DatePicker selected={field.value}
                                                onChange={field.onChange}
                                                placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                dateFormat={dateFormat}
                                                isClearable
                                                className={classNames('form-control', {
                                                    'is-invalid': !!error,
                                                })}
                                                wrapperClassName={classNames({
                                                    'is-invalid': !!error
                                                })}
                                    />
                                    {!!error &&
                                        <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>}
                                </Form.Group>
                            )}
                />
            </Col>
            <Col xs={12} md={4}>
                <Controller name={'facturaRentDoc.to'}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required'),
                            }}
                            render={({field, fieldState: {error}}) => (
                                <Form.Group>
                                    <Form.Label>
                                        {t(lang, 'roaming.invoice.rent.to')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <DatePicker selected={field.value}
                                                onChange={field.onChange}
                                                placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                dateFormat={dateFormat}
                                                isClearable
                                                className={classNames('form-control', {
                                                    'is-invalid': !!error,
                                                })}
                                                wrapperClassName={classNames({
                                                    'is-invalid': !!error
                                                })}
                                    />
                                    {!!error &&
                                        <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>}
                                </Form.Group>
                            )}
                />
            </Col>
        </Row>
    );
};

export default RoamingInvoiceFormRentContractInfo;