import React, {useState} from 'react';
import IconButton from "../../../common/IconButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {validateItemCatalogsAsync} from "../../../../app/store/reducers/item/itemReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    VALIDATE_ITEM_CATALOGS_FAILED,
    VALIDATE_ITEM_CATALOGS_REQUESTED,
    VALIDATE_ITEM_CATALOGS_SUCCESS
} from "../../../../app/eventbus/itemEvents";
import {faRepeat} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";

const ValidateItemCatalogs = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);

    const validateItemCatalogs = () => {
        setLoading(true);
        EventBus.dispatch(VALIDATE_ITEM_CATALOGS_REQUESTED);
        validateItemCatalogsAsync()
            .then(() => {
                setLoading(false);
                EventBus.dispatch(VALIDATE_ITEM_CATALOGS_SUCCESS)
            })
            .catch(() => {
                setLoading(false);
                EventBus.dispatch(VALIDATE_ITEM_CATALOGS_FAILED)
            })
    }

    return (
        <IconButton variant="falcon-info"
                    size="sm"
                    icon={faRepeat}
                    onClick={validateItemCatalogs}
                    disabled={loading}
        >
            {t(lang, 'items.common.catalogs.sync')}
            {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
        </IconButton>
    );
};

export default ValidateItemCatalogs;