import {useEffect} from "react";
import Purchase from "../../../../components/hippo/warehouse-operation/purchase/Purchase";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useState} from "react";
import {getPurchaseItemAsync} from "../../../../app/store/reducers/warehouse-operation/purchaseReducer";
import {useParams} from "react-router";
import {
    WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/purchaseEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import {loadContractorAsync, updateCardContractor} from "../../../../app/store/reducers/contractor/contractorReducer";


const ViewPurchase = ({viewId}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [purchase, setPurchase] = useState(null)
    const dispatch = useDispatch()
    const {id} = useParams()


    const loadPurchase = () => {
        getPurchaseItemAsync({purchaseId: viewId || id})
            .then(response => {
                setPurchase(response.data, response?.data?.items?.reverse())
                loadContractorAsync(response?.data?.contractor?.id)
                    .then(cr => {
                        dispatch(updateCardContractor(cr))
                    }).catch(err => console.log(err));
            })
            .catch(error => setPurchase(null))
    }

    useEffect(() => {
        loadPurchase()
    },[activeOrganization, id, viewId])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, () => {
            loadPurchase()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, () => {
            loadPurchase()
        })

        const onRegisterPayoutHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS, () => {
            loadPurchase()
        })

        const onUpdatePayoutHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS, () => {
            loadPurchase()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS, onRegisterPayoutHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS, onUpdatePayoutHandler)
        }
    },[])


    if (!purchase) return <> loading... </>



    return(
        <>
            <Purchase purchase={purchase}/>
        </>
    )
}

export default ViewPurchase;
