import IconButton from "../../common/IconButton";
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Col, Collapse, Form, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";
import AttorneyDataTableFilter from "./AttorneyDataTableFilter";
import {faFileExcel, faSignature, faTrash} from "@fortawesome/free-solid-svg-icons";
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {
    changeAttorneyDataTableColumn,
    changeFilterOptionsAsync, loadAttorneyDataTableColumn,
    selectFilterOptions,
    STORAGE_ATTORNEY
} from "../../../app/store/reducers/attorney/attorneyReducer";
import dayjs from "dayjs";
import {useForm, FormProvider} from "react-hook-form";
import DataTableSettings from "../DataTableSettings";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiAttorneyCreate, PermissionEdiAttorneySign} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";


const AttorneyDataTableHeader = ({selectedRowIds, onClickBulkSign, page}) => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilterOptions);

    const methods = useForm({
        defaultValues: {
            attorney_number: filters.attorney_number || null,
            status: filters.status || null,
            contractor_inn: filters.contractor_inn || null,
            created_on_start: filters.created_on_start ? dayjs(filters.created_on_start).toDate() : null,
            created_on_end: filters.created_on_end ? dayjs(filters.created_on_end).toDate() : null,
        }
    });
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true)


    const onSubmitFilter = ({
                                attorney_number,
                                status,
                                contractor_inn,
                                created_on_start,
                                created_on_end
                            }) => {
        const jsonDateFormat = "YYYY-MM-DD";
        dispatch(changeFilterOptionsAsync({
            attorney_number: attorney_number || null,
            status: status,
            contractor_inn: contractor_inn || null,
            created_on_start: created_on_start ? dayjs(created_on_start).format(jsonDateFormat) : null,
            created_on_end: created_on_end ? dayjs(created_on_end).format(jsonDateFormat) : null,
        }))
    }
    const onBulkSign = async () => {
        let selectAttorney = Object.keys(selectedRowIds).map((key) => {
            let value = selectedRowIds[key]
            if (value) {
                return page[key].original
            }
            return false
        })
        onClickBulkSign(selectAttorney)
        return selectAttorney
    }


    const allColumns = JSON.parse(localStorage.getItem(STORAGE_ATTORNEY))
    if (!allColumns) {
        dispatch(loadAttorneyDataTableColumn())
        return <></>
    }

    return (
        <Row className="flex-between-center">
            <Collapse in={isOpenFilterCollapse}>
                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <AttorneyDataTableFilter/>
                        </Form>
                    </FormProvider>
                    <hr/>
                </Col>
            </Collapse>
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.attorney.datatable.header.title")}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <IconButton
                            onClick={()=> dispatch(checkPermission(PermissionEdiAttorneySign)) ? onBulkSign() : dispatch(toastPermission())}
                            disabled={!currentCertificate}
                            variant="falcon-default"
                            size="sm"
                            type="button"
                            icon={faSignature}
                            iconClassName="me-1"
                            className="ms-2"
                        >{t(lang, "edi.attorney.datatable.header.navigation.sign")}
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            type="button"
                            icon={faTrash}
                            iconClassName="me-1"
                            className="ms-2"
                        >{t(lang, "edi.attorney.datatable.header.navigation.delete")}
                        </IconButton>
                    </div>
                ) : (
                    <div id="attorney-actions">
                        <IconButton
                            as={dispatch(checkPermission(PermissionEdiAttorneyCreate)) ? Link : 'div'}
                            onClick={()=>!dispatch(checkPermission(PermissionEdiAttorneyCreate)) && dispatch(toastPermission())}
                            to="./attorney/add"
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, "edi.attorney.datatable.header.navigation.create")}
                                </span>
                        </IconButton>

                        <Link to="./attorney/mass-import">
                            <IconButton
                                variant="falcon-default"
                                className="ms-2"
                                size="sm"
                                icon={faFileExcel}
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, 'edi.attorney.datatable.header.navigation.mass_import')}
                                </span>
                            </IconButton>
                        </Link>

                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            transform="shrink-3"
                            icon="refresh"
                            className="mx-2"
                            onClick={methods.handleSubmit(onSubmitFilter)}
                        >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, "edi.orders.order.resume_order_button")}
                            </span>
                        </IconButton>

                        <IconButton
                            onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                            variant="falcon-default"
                            size="sm"
                            icon="filter"
                            transform="shrink-3"
                        >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, "edi.attorney.datatable.header.navigation.filter")}
                            </span>
                        </IconButton>
                    </div>
                )}
            </Col>
        </Row>
    );
};

AttorneyDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default AttorneyDataTableHeader;
