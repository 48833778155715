import axios from "axios";

class OrganizationBinding {
    constructor() {
        this.baseURL = "/organization-binding"
    }

    register(payload) {
        return axios.post(`${this.baseURL}/register`, payload);
    }

    edit(id, payload) {
        return axios.post(`${this.baseURL}/update/${id}`, payload);
    }

    delete(id) {
        return axios.post(`${this.baseURL}/delete/${id}`);
    }

    getOwnerBindings(params) {
        return axios.get(`${this.baseURL}/owner/organization-bindings`, {params});
    }

    getSupervisorBindings(params) {
        return axios.get(`${this.baseURL}/supervisor/organization-bindings`, {params});
    }

    getOrganizationBinding(id) {
        return axios.get(`${this.baseURL}/organization-binding/get/${id}`);
    }

    bindItem(payload) {
        return axios.post(`${this.baseURL}/add/item-binding`, payload)
    }

    bindWarehouse(payload) {
        return axios.post(`${this.baseURL}/add/warehouse-binding`, payload)
    }
}

const organizationBindingService = new OrganizationBinding();
export default organizationBindingService;
