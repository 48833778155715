import React, {useCallback, useEffect} from 'react';
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import DataTableHeader from "./DataTableHeader";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    loadCountDraftItemsAsync,
    loadDraftItemsAsync,
    selectDraftItems,
    selectFilters,
    selectPagination
} from "../../../../../app/store/reducers/draft-item/draftItemReducer";
import DataTableFooter from "./DataTableFooter";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Link} from "react-router-dom";
import DeleteDraftItem from "../DeleteDraftItem";
import {debounce} from "underscore";
import EventBus from "../../../../../app/eventbus/EventBus";
import {BULK_DELETE_DRAFT_ITEMS_SUCCESS, DELETE_DRAFT_ITEM_SUCCESS} from "../../../../../app/eventbus/itemEvents";

const DraftItemsDataTable = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const draftItems = useSelector(selectDraftItems);
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectFilters, shallowEqual);
    const pagination = useSelector(selectPagination, shallowEqual);

    const columns = [
        {
            Header: '#',
            Cell: ({row}) => `${(pagination.page - 1) * pagination.limit + row.index + 1}`,
            cellProps: {
                width: '30px',
            }

        },
        {
            accessor: 'name',
            Header: t(lang, 'items.common.name'),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`/warehouse/items/draft/${original.id}`}>
                        <h6 className="m-0 fs-0 text-truncate text-primary">{original.name}</h6>
                    </Link>
                )
            },
        },
        {
            accessor: 'barcode',
            Header: t(lang, 'items.common.barcode'),
            cellProps: {
                width: '150px',
                className: 'fs-0'
            }
        },
        {
            accessor: 'tax.measurement',
            Header: t(lang, 'items.common.measurement'),
            cellProps: {
                width: '150px',
                className: 'text-truncate fs-0'
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, 'items.common.draft.contractor'),
            Cell: ({row: {original}}) => {
                return (
                    <div style={{maxWidth: '200px'}}>
                        <h6 className="fw-semi-bold text-truncate fs-0">{original.contractor.name}</h6>
                        <p>{original.contractor.pinfl || original.contractor.inn}</p>
                    </div>
                )
            },
            cellProps: {
                width: '400px',
            }
        },
        {
            Header: t(lang, 'items.common.draft.source'),
            Cell: ({row: {original}}) => {
                if (original.edi_order) {
                    return (
                        <div>
                            <Link to={`/edi/order/${original.edi_order.id}`}>
                                <h6 className="fw-semi-bold text-primary fs-0">{original.edi_order.number}</h6>
                            </Link>
                            <p>{t(lang, 'items.common.draft.source.order')}</p>
                        </div>
                    )
                }

                return (
                    <div>
                        <Link to={`/roaming/invoice/${original.roaming_invoice.id}`}>
                            <h6 className="fw-semi-bold text-primary fs-0">{original.roaming_invoice.number}</h6>
                        </Link>
                        <p>{t(lang, 'items.common.draft.source.roaming_invoice')}</p>
                    </div>
                )
            },
            cellProps: {
                width: '200px',
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, 'items.common.draft.created_at'),
            cellProps: {
                className: 'fw-semi-bold fs-0',
                width: '150px'
            }
        },
        {
            accessor: 'none',
            Cell: ({row: {original}}) => {
                return (
                    <DeleteDraftItem id={original.id} buttonProps={{
                        size: 'sm',
                        className: 'me-2',
                        title: t(lang, 'items.common.delete')
                    }}
                    />
                )
            },
            cellProps: {
                className: 'ps-3'
            }
        }
    ];

    const load = useCallback(debounce((filters, pagination) => {
        dispatch(loadDraftItemsAsync(filters, pagination));
        dispatch(loadCountDraftItemsAsync(filters));
    }, 50), [])

    useEffect(() => {
        load(filters, pagination);

        const onDraftItemDeleteSuccessHandler = EventBus.on(DELETE_DRAFT_ITEM_SUCCESS, () => {
            load(filters, pagination)
        });
        const onBulkDraftItemsDeleteSuccessHandler = EventBus.on(BULK_DELETE_DRAFT_ITEMS_SUCCESS, () => {
            load(filters, pagination)
        });

        return () => {
            EventBus.remove(DELETE_DRAFT_ITEM_SUCCESS, onDraftItemDeleteSuccessHandler);
            EventBus.remove(BULK_DELETE_DRAFT_ITEMS_SUCCESS, onBulkDraftItemsDeleteSuccessHandler);
        }
    }, [activeOrganization, filters, pagination])


    return (
        <AdvanceTableWrapper
            columns={columns}
            data={draftItems}
            pagination
            selection
            width="50px"
        >
            <Card>
                <Card.Header>
                    <DataTableHeader table/>
                </Card.Header>
                <Card.Body>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <DataTableFooter/>
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default DraftItemsDataTable;
