import React, {useEffect} from 'react';
import ReturnOrderDataTable from "../../../../components/hippo/return-order/ReturnOrderDataTable.js";
import {useDispatch, useSelector} from "react-redux";
import {
    loadCountReturnOrdersAsync,
    loadReturnOrderDataTableColumn,
    loadReturnOrdersAsync,
    selectFilterOptions, selectReturnOrderDataTable
} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import {selectActiveOrganization} from '../../../../app/store/reducers/auth/authReducer';
import {selectIsEDIServiceActive} from "../../../../app/store/reducers/billing/billingReducer";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {setEdiReturnBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnOrderView} from "../../../../enum/Permission/EdiPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const ReturnOrders = () => {
    const filter = useSelector(selectFilterOptions)
    const isEDIServiceActive = useSelector(selectIsEDIServiceActive);
    const activeOrganization = (useSelector(selectActiveOrganization))
    const lang = useSelector(selectLang);
    const dispatch = useDispatch()
    const t = Translate;
    const activeColumns = useSelector(selectReturnOrderDataTable)
    // important
    useSelector(selectAccountRolePermissions)

    const loadDataTable = () => {
        dispatch(loadReturnOrdersAsync({...filter, viewer_inn: activeOrganization.inn}))
        dispatch(loadCountReturnOrdersAsync({...filter, viewer_inn: activeOrganization.inn}))
        dispatch(loadReturnOrderDataTableColumn())
    };

    useEffect(() => {
        isEDIServiceActive && loadDataTable()
    }, [filter, activeOrganization, isEDIServiceActive])

    useEffect(() => {
        dispatch(setEdiReturnBreadcrumbData(null))
    }, [])

    if(!isEDIServiceActive && !dispatch(checkPermission(PermissionEdiReturnOrderView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.edi.return-order.datatable.no_access.title')}</h4>
        </Card>
    }

    return <ReturnOrderDataTable activeColumns={activeColumns}/>

};

export default ReturnOrders;
