import React, {Fragment, useEffect, useState} from "react";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../../common/advance-table/AdvanceTableFooter";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {
    selectContractorLabels,
    selectContractorLabelsCount,
    selectContractorLabelFilterOptions,
    getAllContractorLabelsAsync,
    getCountContractorLabels,
    changeFilterOptionsAsync
} from "../../../../../app/store/reducers/contractor/contractorLabelReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    DELETE_CONTRACTOR_LABEL_SUCCESS,
    EDIT_CONTRACTOR_LABEL_SUCCESS,
    ADD_CONTRACTOR_LABEL_SUCCESS
} from "../../../../../app/eventbus/contractor/contractorLabelEvents";
import {toast} from "react-toastify";
import ContractorLabelDatatableHeader from "./ContractorLabelDatatableHeader";
import dayjs from "dayjs";
import IconButton from "../../../../common/IconButton";
import EditContractorLabelForm from "./EditContractorLabelForm";
import DeleteContractorLabelModal from "./DeleteContractorLabelModal";

const ContractorLabelDataTable = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()

    const [deletingContractorLabelId, setDeletingContractorLabelId] = useState(null);

    const filters = useSelector(selectContractorLabelFilterOptions)
    const contractorLabels = useSelector(selectContractorLabels)
    const count = useSelector(selectContractorLabelsCount)

    const loadContractorLabels = () => {
        dispatch(getCountContractorLabels({...filters}))
        dispatch(getAllContractorLabelsAsync({...filters}))
    }

    useEffect(() => {
        loadContractorLabels()
    }, [filters, activeOrganization])


    useEffect(() => {
        const onSuccessAddedHandler = EventBus.on(ADD_CONTRACTOR_LABEL_SUCCESS, () => {
            loadContractorLabels()
        })

        const onSuccessEditedHandler = EventBus.on(EDIT_CONTRACTOR_LABEL_SUCCESS, () => {
            loadContractorLabels()
        })

        const onSuccessDeletedHandler = EventBus.on(DELETE_CONTRACTOR_LABEL_SUCCESS, () => {
            toast.success(t(lang, 'crm.common.toast.success'));
            loadContractorLabels()
        })

        return () => {
            EventBus.remove(ADD_CONTRACTOR_LABEL_SUCCESS, onSuccessAddedHandler)
            EventBus.remove(EDIT_CONTRACTOR_LABEL_SUCCESS, onSuccessEditedHandler)
            EventBus.remove(DELETE_CONTRACTOR_LABEL_SUCCESS, onSuccessDeletedHandler)
        }

    }, [])

    const columns = [
        {
            Header: '#',
            Cell: ({row}) => `${(filters.page - 1) * filters.limit + row.index + 1}`,
            cellProps: {
                width: '50px',
            }
        },
        {
            Header: t(lang, "edi.common.label.name"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <strong>{original.name}</strong>
                );
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "crm.common.datatable.created_at"),
            Cell: ({row: {original}}) => {
                return (
                    <span
                        className="fw-medium">{dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                )
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <>
                        <EditContractorLabelForm contractorLabel={row.original}>
                            <IconButton
                                className="mx-2 py-0"
                                variant="falcon-warning"
                                size="sm"
                                icon="edit"
                                transform="shrink-3"
                            />
                        </EditContractorLabelForm>

                        <IconButton
                            className={'py-0'}
                            variant="falcon-danger"
                            onClick={() => {
                                setDeletingContractorLabelId(row.original.id);
                            }}
                            size="sm"
                            icon="trash"
                            transform="shrink-3"
                        />
                    </>
                );
            }
        }
    ].filter(i => i);

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }
    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }

    return (
        <Fragment>
            <AdvanceTableWrapper
                perPage={1000}
                columns={columns}
                data={contractorLabels}
                pagination
            >
                <Card>
                    <Card.Header>
                        <ContractorLabelDatatableHeader table/>
                    </Card.Header>

                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}>
                        </AdvanceTable>
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            table
                        />
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            {deletingContractorLabelId &&
            <DeleteContractorLabelModal id={deletingContractorLabelId} show={!!deletingContractorLabelId} onClose={() => {
                setDeletingContractorLabelId(null)
            }}/>
            }
        </Fragment>
    );
}
export default ContractorLabelDataTable