import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Select from "react-select";
import {selectContractorLabels} from "../../../../../app/store/reducers/contractor/contractorLabelReducer";
import PropTypes from "prop-types";
import SelectRoamingInvoiceType from "../../../roaming/components/SelectRoamingInvoiceType";

const SelectContractorLabel = ({onChange, defaultContractorLabelId, ...props}) => {
    const contractorLabels = useSelector(selectContractorLabels);
    const [defaultContractorLabel, setDefaultContractorLabel] = useState(null)

    useEffect(() => {
        if (contractorLabels.length > 0) {
            if (props.isMulti) {
                const labels = defaultContractorLabelId.reduce((result, label_id) => {
                    let foundLabel = contractorLabels.find(l => l.id === label_id);
                    if (foundLabel)
                        result.push(foundLabel);
                    return result;
                }, []);
                setDefaultContractorLabel(labels);
            } else {
                setDefaultContractorLabel(contractorLabels.find(c => c.id === defaultContractorLabelId))
            }
        }
    }, [defaultContractorLabelId])
    return (
        <Select
            options={contractorLabels}
            isClearable
            hideSelectedOptions
            {...props}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onChange}
            value={defaultContractorLabel}
        />
    );
};

SelectContractorLabel.propTypes = {
    onChange: PropTypes.func
}

SelectContractorLabel.defaultProps = {
    onChange: () => {}
}

export default SelectContractorLabel;