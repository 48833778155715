import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from "../../../common/FalconCardHeader";
import NumberFormatChooser from "../../navbar/top/NumberFormatChooser";
import DateFormatChooser from "../../navbar/top/DateFormatChooser";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const AccountSettings = () => {
  const lang = useSelector(selectLang);
  const t = Translate;

  return (
    <Card className="mb-3 h-100">
      <FalconCardHeader title={t(lang, 'hippo.user.settings.profile.format_title')} />
      <Card.Body className="bg-light">
        <div className="border-dashed-bottom my-3" />

        <div className="d-flex align-items-center">
          <label className="col-3 mb-0">{t(lang, 'hippo.user.settings.profile.date')}</label>
          <div className="col-7">
            <DateFormatChooser/>
          </div>
        </div>

        <div className="border-dashed-bottom my-3" />

        <div className="d-flex align-items-center">
          <label className="col-3">{t(lang, 'hippo.user.settings.profile.value')}</label>
          <div className="col-7">
            <NumberFormatChooser/>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AccountSettings;
