import React, {useEffect} from 'react';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import dayjs from 'dayjs';
import ShipmentDataTableHeader from './ShipmentDataTableHeader';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {
    changeFilterOptionsAsync,
    selectShipments,
    selectCountShipments,
    selectFilterOptions,
    loadCountShipments, selectShipmentLoading
} from '../../../app/store/reducers/shipment/shipmentReducer';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import AdvanceTableCustomPagination from '../../common/advance-table/AdvanceTableCustomPagination';
import {
    getStatusColorShipment,
    GetStatusTextShipment,
} from '../../../enum/ShipmentStatus';
import { selectActiveOrganization } from '../../../app/store/reducers/auth/authReducer';
import {
    selectLang,
    Translate,
    selectDateDayJSFormat,
    selectNumberFormat
} from "../../../app/store/reducers/main/mainReducer";
import EventBus from "../../../app/eventbus/EventBus";
import {DELETE_SHIPMENT_SUCCESS} from "../../../app/eventbus/shipmentEvents";
import {getStatusColorInvoice, GetStatusTextInvoice} from "../../../enum/InvoiceStatus";
import numeral from "numeral";
import CheckPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiInvoiceView} from "../../../enum/Permission/EdiPermission";
import {useParams} from "react-router";

const Shipments = ({orderStatus, activeColumns, loadShipments, hideViewers}) => {
    const {viewer} = useParams()
    const dispatch = useDispatch()
    const shipments = useSelector(selectShipments)
    const count = useSelector(selectCountShipments)
    const filters = useSelector(selectFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const isLoading = useSelector(selectShipmentLoading);
    const t = Translate;

    useEffect(() => {
        const onShipmentDeletedHandler = EventBus.on(DELETE_SHIPMENT_SUCCESS, () => {
            dispatch(loadShipments(viewer, {...filters, viewer_inn: activeOrganization.inn}));
            dispatch(loadCountShipments(viewer,{...filters, viewer_inn: activeOrganization.inn}));
        })

        return () => {
            EventBus.remove(DELETE_SHIPMENT_SUCCESS, onShipmentDeletedHandler);
        }
    }, [])

    const totalSum = shipments.reduce((totSum, total) => totSum + total.total_info.total, 0)


    function getActiveColumns(activeColumns, columns = []) {
        return columns?.filter(column => activeColumns[column?.id])
    }


    const columns = getActiveColumns(activeColumns,[
        {
            Header: "#",
            id: "index",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
              return `${((filters.page-1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'index',
            id: "number",
            Header: t(lang, "edi.shipment.datatable.header.row.number"),
            cellProps: {
                width: '80px',
                className: 'p-0'
            },
            totalInfo: `${t(lang,"edi.common.datatable.total")}:`,
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            Cell: rowData => {
                const {original} = rowData.row

                    return <div className="d-grid gap-2">
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    {t(lang, GetStatusTextShipment(original.status))}
                                </Tooltip>
                            }
                            placement="right"
                        >
                        <Button variant="primary"  style={{backgroundColor: getStatusColorShipment(original.status)}} as={Link} to={`/edi/shipment/${original.id}`}>
                    {original.shipment_info.number}
                </Button>
                        </OverlayTrigger>
                    </div>
            }
        },
        {
            accessor: 'shipment_info.date',
            id: "date",
            Header: t(lang, "edi.shipment.datatable.header.row.date"),
            cellProps: {
                width: '80px'
            },
            Cell: rowData => {
                const {original} = rowData.row
                return <b>{dayjs(original.shipment_info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            },
        },
        {
            accessor: 'total_info.total',
            id: "total_sum",
            Header: t(lang, "edi.shipment.datatable.header.row.total_sum"),
            cellProps: {
                width: '30px',
                className: 'text-end'
            },
            totalInfo: numeral.formats[numberFormat].format(totalSum),
            totalInfoClassName: "fw-bold text-nowrap",
            Cell: rowData => {
                const {original} = rowData.row

                return<>
                    {numeral.formats[numberFormat].format(original.total_info.total)}
                </>
            }
        },
        {
            accessor: 'order.branch.name',
            id: "branch",
            Header: t(lang, "edi.return_invoice.datatable.header.row.branch"),
            cellProps: {
                className: 'fw-bold',
            },
        },
        {
            accessor: 'invoices[0].number',
            id: "invoice",
            Header: t(lang, "edi.shipment.datatable.header.row.invoice"),
            cellProps: {
                width: '160px',
                className: 'fw-bold p-0'
            },
            Cell: rowData => {
                const {original} = rowData.row;
                if(original.invoice !== null){
                    return <div className="d-grid gap-2">
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    {t(lang, GetStatusTextInvoice(original.invoice.status))}
                                </Tooltip>
                            }
                            placement="right"
                        >
                            <Button variant="primary" style={{backgroundColor: getStatusColorInvoice(original.invoice.status)}} as={Link}
                                    to={orderStatus ? `../invoice/${original.invoice.id}` : `../invoice/${original.invoice.id}`}>
                                {original?.invoice?.invoice_info?.number}
                            </Button>
                        </OverlayTrigger>
                    </div>
                }
                    return<>
                            {t(lang, "edi.shipment.datatable.item.row.not_invoice")}
                        </>
            }
        },
        {
            accessor: 'contractor',
            id: "contractor",
            Header: t(lang, "edi.shipment.datatable.header.row.contractor"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer_info.inn

                if(isCustomer) {
                    return <>
                        {original.executor_info.name}
                    </>
                }

                return<>
                    {original.customer_info.name}
                </>
            }
        },
    ]);


    const onPageChange = async (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))
    const onLimitChange = async (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))



    return (
        <AdvanceTableWrapper
            columns={columns}
            data={shipments}
            selection
            selectionColumnWidth={'50px'}
            width={'50px'}
            pagination
            perPage={1000}
        >
            <Card className="mb-3">
                <Card.Header className={`${!hideViewers ? 'pb-0' : ""}`}>
                    <ShipmentDataTableHeader table loadShipments={loadShipments} hideViewers={hideViewers}/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                        rowsPerPageOptions={[10, 25, 50, 75, 100, 300]}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Shipments;
