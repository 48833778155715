import classNames from "classnames";
import {useSelector} from "react-redux";
import React, {Fragment, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WarehouseOperationWarehouseSelect from "./WarehouseOperationWarehouseSelect";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";

const ApproveDraftOperationModal = ({isApproveBlocked, isProcessing, onApproved}) => {
    const warehouses = useSelector(selectWarehouses);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {handleSubmit, setValue, control, formState: {errors}, reset} = useForm({
        defaultValues: {
            warehouseId: null,
        },
    });

    const handleShow = () => {
        const [defaultWarehouse] = warehouses;
        setValue('warehouseId', defaultWarehouse ? defaultWarehouse.id : null)

        setShow(true);
    };
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const onSubmit = (formData) => {
        onApproved({warehouseId: formData.warehouseId});
        handleClose();
    };

    return (
        <Fragment>
            <Button size={'lg'} onClick={handleShow} disabled={isApproveBlocked}>
                <FontAwesomeIcon className="me-2" icon="check"/>
                <span>{t(lang, 'warehouse-operation.draft_operation.view.button.approve')}</span>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'warehouse-operation.draft_operation.approve_modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse-operation.draft_operation.approve_modal.field.warehouse')}</Form.Label>
                            <Controller
                                control={control}
                                name={'warehouseId'}
                                rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                                render={({field}) => (
                                    <WarehouseOperationWarehouseSelect
                                        options={warehouses}
                                        defaultValue={field.value}
                                        classNamePrefix={'react-select'}
                                        className={classNames({'is-invalid': errors?.warehouseId})}
                                        onChange={option => field.onChange(option ? option.id : null)}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors?.warehouse_id?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'warehouse.operation.item.common.close')}</Button>
                        <Button variant={'primary'} type={'submit'} disabled={isProcessing}>
                            {isProcessing && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                            {t(lang, 'warehouse.operation.item.common.approve_button')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default ApproveDraftOperationModal;