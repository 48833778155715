import {useSelector} from "react-redux";
import {Button, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TYPE_SALE} from "../../../../../enum/ExcelTemplateTypes";
import {downloadFile} from "../../../../../helpers/excel-uploader";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import React, {cloneElement, Fragment, useEffect, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectOrganizationTemplates, uploadSaleToExcelAsync} from "../../../../../app/store/reducers/excel/excelReducer";

const UploadSaleToExcel = ({id, number, children, ...props}) => {
    const [saleTypeTemplates, setSaleTypeTemplates] = useState([]);
    const organizationTemplates = useSelector(selectOrganizationTemplates);
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        const templates = organizationTemplates.filter(template => template.type === TYPE_SALE) || [];
        setSaleTypeTemplates(templates);
    }, [organizationTemplates])

    const uploadSale = (uri) => {
        setLoading(true);
        uploadSaleToExcelAsync({uri: uri, id: id})
            .then(({content, contentType}) => {
                downloadFile({
                    content: content,
                    contentType: contentType,
                    fileName: t(lang, "warehouse.item.download_excel.sale_name", {number: number}),
                });
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            {saleTypeTemplates.length > 1
                ?
                <Dropdown {...props}>
                    <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                        {!!children
                            ?
                            <div>
                                {!!loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                {!loading && cloneElement(children, {...children.props})}
                            </div>
                            :
                            <div>
                                {loading
                                    ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                                    : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                                }
                                <span className="d-none d-sm-inline-block">
                                    {t(lang, 'warehouse.operation.item.common.download_excel')}
                                </span>
                            </div>
                        }
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='py-2'>
                        {saleTypeTemplates.map((template, index) => (
                            <Dropdown.Item key={index} onClick={() => uploadSale(template.uri)}>
                                {t(lang, 'main.common.file.upload.template_name.prefix', {template_name: t(lang, `common.excel.template.name.${template.name}`)})}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                :
                <Fragment>
                    {!!children
                        ?
                        <Button variant="falcon-default" size="sm" disabled={loading} {...props} onClick={() => {
                            const [defaultTemplate] = saleTypeTemplates;
                            uploadSale(defaultTemplate.uri);
                        }}>
                            {cloneElement(children, {...children.props})}
                        </Button>
                        :
                        <Button {...props} variant="falcon-default" size="sm" disabled={loading} onClick={() => {
                            const [defaultTemplate] = saleTypeTemplates;
                            uploadSale(defaultTemplate.uri);
                        }}>
                            {loading
                                ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                                : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                            }

                            <span className="d-none d-sm-inline-block">{t(lang, 'warehouse.operation.item.common.download_excel')}</span>
                        </Button>
                    }
                </Fragment>
            }
        </Fragment>
    );
};

export default UploadSaleToExcel;