import React, {useEffect, useState} from 'react';
import AsyncSelect from "react-select/async";
import {InputGroup} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import EditEmployeeModal from "../../employee/EditEmployeeModal";
import {selectEmployees} from "../../../app/store/reducers/employee/employeeReducer";
import AddEmployeeModal from "../../employee/AddEmployeeModal";
import {debounce} from "underscore";
import PropTypes from "prop-types";



const EmployeeSelect = ({defaultValue, onChange, allowEdit, allowAdd, ...props}) => {
    const [value, setValue] = useState(null)
    const employees = useSelector(selectEmployees);
    const lang = useSelector(selectLang)
    const t = Translate

    const filterOptions = {page: 0, limit: 10}

    useEffect(() => {
        if (defaultValue !== null) {
            setValue(employees?.find(x => x.id === defaultValue))
        } else {
            setValue(null)
        }
    }, [employees, defaultValue])

    const loadOptions = (query, callback) => {
        if (query) {
            callback(employees?.filter(i => {
                const searchLower = query?.trim()?.toLowerCase();

                if (i?.name?.toLowerCase()?.includes(searchLower))
                    return true

            }).slice(filterOptions?.page, filterOptions?.limit))
        } else {
            callback([]);
        }
    };
    const debouncedLoadOptions = debounce(loadOptions, 100);


    const onChangeEmployee = option => {
        setValue(option)
        onChange && onChange(option)
    }



    return (
        <>
            <InputGroup className={`${!allowAdd ? 'w-100' : ''}`}>
                <div className={`${!allowAdd ? 'w-100' : 'react-select form-control p-0'}`}>
                    <AsyncSelect
                        loadOptions={debouncedLoadOptions}
                        onChange={onChangeEmployee}
                        value={value}
                        hideSelectedOptions
                        getOptionValue={(option) => option?.id}
                        getOptionLabel={(option) => option?.name}
                        placeholder={t(lang, 'Введите Название')}
                        defaultOptions={employees?.slice(filterOptions?.page, filterOptions?.limit)}
                        noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                margin: '-1px'
                            })}}
                        {...props}
                    />
                </div>
                {allowAdd && !value &&
                    <AddEmployeeModal noName={true}/>
                }
                {allowEdit && value &&
                    <EditEmployeeModal className="fs-1" employee={value}/>
                }
            </InputGroup>
        </>
    );
};


EmployeeSelect.prototype = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    allowEdit: PropTypes.bool,
    allowAdd: PropTypes.bool,
    props: PropTypes.any,
}

EmployeeSelect.defaultProps = {
    defaultValue: "",
    onChange: () => {},
    allowEdit: false,
    allowAdd: false,
}

export default EmployeeSelect;
