import numeral from "numeral";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, selectSystemMoneyFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SystemPaymentsDatatableHeader from "./SystemPaymentsDatatableHeader";
import {
    changeSystemOrganizationsFilterOptionsPayment,
    selectOrganizationGetPayments,
    selectOrganizationGetPaymentsCount,
    selectSystemOrganizationsFilterOptionsPayment
} from "../../../../app/store/reducers/billing/billingReducer";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import SoftBadge from "../../../common/SoftBadge";
import {methodPayment} from "../../../../enum/BillingWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const SystemPaymentsDatatable = () => {
    const getPayments = useSelector(selectOrganizationGetPayments)
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const filters = useSelector(selectSystemOrganizationsFilterOptionsPayment)
    const count = useSelector(selectOrganizationGetPaymentsCount)
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);

    const subtotal = getPayments.reduce((acc, cur) => acc + cur.amount, 0)

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'organization.name',
            Header: t(lang, 'admin.common.payments.datatable.name'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <strong>{original.organization.name}</strong>
                    </>
                );
            }
        },
        {
            accessor: 'inn',
            Header: t(lang, 'admin.common.payments.datatable.inn'),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.organization.inn}</span>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "admin.common.payments.datatable.created_date"),
            headerProps: {className: 'pe-2'},
        },
        {
            accessor: 'account.username',
            Header: t(lang, "admin.payments.datatable.header.organization.column.username"),
            headerProps: {className: 'pe-2'},
        },
        {
            accessor: 'method',
            Header: t(lang, "admin.payments.datatable.header.organization.column.method"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <SoftBadge bg={methodPayment(original.method).bg}>
                        <span>{methodPayment(original.method).name}</span>
                        <FontAwesomeIcon icon={methodPayment(original.method).icon} className={'ms-2'}/>
                    </SoftBadge>
                )
            }
        },
        {
            accessor: 'amount',
            Header: t(lang, 'admin.common.payments.datatable.amount'),
            headerProps: {className: 'pe-4 text-end'},
            cellProps: {className: 'text-end'},
            totalInfo: numeral.formats[systemMoneyFormat].format(subtotal),
            totalInfoClassName: 'text-end fw-bold',
            Cell: ({row: {original}}) => {
                return (
                    <span>{numeral.formats[systemMoneyFormat].format(original.amount)}</span>
                )
            }
        },
    ]

    const onPageChange = (page) => dispatch(changeSystemOrganizationsFilterOptionsPayment({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeSystemOrganizationsFilterOptionsPayment({...filters, limit: limit, page: 1}));


    return (
        <>
            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={getPayments}
            >
                <Card className="mb-3">
                    <Card.Header className="bg-light">
                        <SystemPaymentsDatatableHeader table/>
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
}

export default SystemPaymentsDatatable;
