import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectFilters, updateFilters} from "../../../../../app/store/reducers/category/categoryReducer";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import DataTableFilterForm from "./DataTableFilterForm";

const DataTableFilter = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);
    const form = useForm({
        defaultValues: {
            name: filters?.name
        }
    })

    return (
        <FormProvider {...form}>
            <Form>
                <DataTableFilterForm />
            </Form>
        </FormProvider>
    );
};

export default DataTableFilter;
