import {Button, Col, Dropdown, Form, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";
import DatePicker from "react-datepicker";
import {
    uploadCustomerInvoicesToExcelAsync,
    uploadCustomerInvoicesWithItemsToExcelAsync, uploadExecutorInvoicesWithItemsToExcelAsync
} from "../../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import {excelFileUploader} from "../../../../../../helpers/excel-uploader";
import {selectContractorInvoiceFilterOptions} from "../../../../../../app/store/reducers/contractor/contractorInvoiceReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";

const ContractorInvoiceDataTableFilter = ({methods}) => {
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const filters = useSelector(selectContractorInvoiceFilterOptions);
    const [loading, setLoading] = useState(false);
    const {register} = useFormContext();
    const uploadInvoices = () => {
        uploadCustomerInvoicesToExcelAsync({...filters})
            .then(loadedContent => {
                excelFileUploader({
                    content: loadedContent,
                    fileName: "Счёт-фактуры.xlsx",
                })
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    const uploadInvoicesWithItems = () => {
        setLoading(true);
        uploadCustomerInvoicesWithItemsToExcelAsync({...filters})
            .then(loadedContent => {
                excelFileUploader({
                    content: loadedContent,
                    fileName: "Счёт-фактуры.xlsx",
                })
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };

    return (<>
        <Row>
            <Form.Group as={Col} md={4} lg={3}>
                <Form.Label>{t(lang, "roaming.invoices.table_header.date_start")}</Form.Label>
                <Controller
                    control={methods.control}
                    name="date_start"
                    render={({field}) => (
                        <DatePicker
                            isClearable
                            className='form-control'
                            placeholderText={t(lang, "roaming.invoices.table_header.date_start.placeholder")}
                            dateFormat={dateFormat}
                            selected={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group as={Col} md={4} lg={3}>
                <Form.Label>{t(lang, "roaming.invoices.table_header.date_end")}</Form.Label>
                <Controller
                    control={methods.control}
                    name="date_end"
                    render={({field}) => (
                        <DatePicker
                            isClearable
                            className='form-control'
                            placeholderText={t(lang, "roaming.invoices.table_header.date_end.placeholder")}
                            dateFormat={dateFormat}
                            selected={field.value}
                            defaultValue={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group as={Col} md={4} lg={3}>
                <Form.Label>{t(lang, "roaming.invoices.table_header.contract_number")}</Form.Label>
                <Form.Control name="contract_number"
                              type="text"
                              placeholder={t(lang, "roaming.invoices.table_header.contract_number.placeholder")}
                              {...register("contract_number")}
                />
            </Form.Group>
            <Form.Group as={Col} md={4} lg={3}>
                <Form.Label>{t(lang, "roaming.invoices.table_header.contract_date")}</Form.Label>
                <Controller
                    control={methods.control}
                    name="contract_date"
                    render={({field}) => (
                        <DatePicker
                            isClearable
                            className='form-control'
                            placeholderText={t(lang, "roaming.invoices.table_header.contract_date.placeholder")}
                            dateFormat={dateFormat}
                            selected={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </Form.Group>
        </Row>

        <Row className="mb-3 g-3 justify-content-end">
            <Form.Group as={Col} sm="auto">
                <Button variant="primary"
                        type='submit' className={"mt-2"}>{t(lang, "roaming.invoices.table_header.show")}</Button>
            </Form.Group>
        </Row>
    </>)
}
export default ContractorInvoiceDataTableFilter