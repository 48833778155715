export const ADD_BRANCH_REQUESTED = 'ADD_BRANCH_REQUESTED';
export const ADD_BRANCH_SUCCESS = 'ADD_BRANCH_SUCCESS';
export const ADD_BRANCH_FAILED = 'ADD_BRANCH_FAILED';

export const EDIT_BRANCH_REQUESTED = 'EDIT_BRANCH_REQUESTED';
export const EDIT_BRANCH_SUCCESS = 'EDIT_BRANCH_SUCCESS';
export const EDIT_BRANCH_FAILED = 'EDIT_BRANCH_FAILED';

export const DELETE_BRANCH_REQUESTED = 'DELETE_BRANCH_REQUESTED';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILED = 'DELETE_BRANCH_FAILED';

export const BIND_WAREHOUSE_SUCCESS = 'BIND_WAREHOUSE_SUCCESS'
export const BIND_WAREHOUSE_FAILED = 'BIND_WAREHOUSE_FAILED'

export const BIND_ACCOUNT_ENABLE = "BIND_ACCOUNT_ENABLE"
export const BIND_ACCOUNT_DISABLE = "BIND_ACCOUNT_DISABLE"
export const BIND_ACCOUNT_REGISTER = "BIND_ACCOUNT_REGISTER"
export const BIND_ACCOUNT_UNREGISTER = "BIND_ACCOUNT_UNREGISTER"

export const UPDATE_BRANCH_ROAMING_ADDRESS_REQUESTED = "UPDATE_BRANCH_ROAMING_ADDRESS_REQUESTED";
export const UPDATE_BRANCH_ROAMING_ADDRESS_SUCCEED = "UPDATE_BRANCH_ROAMING_ADDRESS_SUCCEED";
export const UPDATE_BRANCH_ROAMING_ADDRESS_FAILED = "UPDATE_BRANCH_ROAMING_ADDRESS_FAILED";

export const DELETE_BRANCH_ROAMING_ADDRESS_REQUESTED = "DELETE_BRANCH_ROAMING_ADDRESS_REQUESTED";
export const DELETE_BRANCH_ROAMING_ADDRESS_SUCCEED = "DELETE_BRANCH_ROAMING_ADDRESS_SUCCEED";
export const DELETE_BRANCH_ROAMING_ADDRESS_FAILED = "DELETE_BRANCH_ROAMING_ADDRESS_FAILED";