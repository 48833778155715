import React from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectUsers} from "../../app/store/reducers/auth/authReducer";

const SelectOrganizationAccount = ({defaultAccountId, onChange, props}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const users = useSelector(selectUsers);

    return (
        <ReactSelect options={users}
                     value={users.find((u) => u.id === defaultAccountId) || null}
                     onChange={onChange}
                     isSearchable={!defaultAccountId}
                     isClearable={true}
                     getOptionValue={option => option.id}
                     getOptionLabel={option => !!option.name ?  `${option.username} - ${option.name}` : `${option.username}`}
                     placeholder={t(lang, "items.common.select")}
                     {...props}
        />
    );
};

export default SelectOrganizationAccount;