import React, {Fragment} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import RoamingInvoiceInvestmentObject from "./RoamingInvoiceInvestmentObject";
import RoamingInvoiceTechnicalSupervisor from "./RoamingInvoiceTechnicalSupervisor";
import RoamingInvoiceFullPrice from "./RoamingInvoiceFullPrice";
import RoamingConstructionInvoiceFormCardItems from "./RoamingConstructionInvoiceFormCardItems";
import IconButton from "../../../../common/IconButton";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import RoamingConstructionInvoiceFormCardInfo from "./RoamingConstructionInvoiceFormCardInfo";
import RoamingConstructionInvoiceExecutorForm from "./RoamingConstructionInvoiceExecutorForm";
import RoamingConstructionInvoiceCustomerForm from "./RoamingConstructionInvoiceCustomerForm";

const RoamingConstructionInvoiceForm = ({onSend}) => {
    const t = Translate;
    const lang = useSelector(selectLang);


    return (
        <Fragment>
            <Row className="gy-3">
                <Col xs={12}>
                    <RoamingConstructionInvoiceFormCardInfo />
               </Col>
                <Col xs={12} md={6}>
                    <Card>
                        <Card.Header className={'pb-0'}>
                            <h5>{t(lang, 'roaming.common.executor')}</h5>
                        </Card.Header>
                        <Card.Body className={'pt-1'}>
                            <RoamingConstructionInvoiceExecutorForm fieldName={'executor'}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card>
                        <Card.Header className={'pb-0'}>
                            <h5>{t(lang, 'roaming.common.customer')}</h5>
                        </Card.Header>
                        <Card.Body className={'pt-1'}>
                            <RoamingConstructionInvoiceCustomerForm fieldName={'customer'}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <RoamingInvoiceTechnicalSupervisor />
                </Col>
                <Col xs={12} md={6}>
                    <RoamingInvoiceInvestmentObject/>
                </Col>
                <Col xs={12}>
                    <RoamingConstructionInvoiceFormCardItems />
                </Col>
                <Col xs={12}>
                    <RoamingInvoiceFullPrice />
                </Col>
                <Col xs={12}>
                    <Card>
                        <Card.Body className="text-end">
                            <SelectCertificateWrapperComponent size="lg" className="w-100 mt-2 mt-md-0 w-md-auto" submitButton={
                                <IconButton icon={faSignature}
                                            variant="falcon-primary"
                                            size="lg"
                                            className="w-100 mt-2 mt-md-0 w-md-auto"
                                            id="sendButton"
                                            onClick={onSend}
                                >
                                    {t(lang, "roaming.invoice.send_button")}
                                </IconButton>
                            } openDialogButtonText={t(lang, "roaming.invoice.send_button")}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RoamingConstructionInvoiceForm;
