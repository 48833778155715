function formatNumberMajorPart(value) {
    let result = ''
    value = value.split('')
        .reverse()
        .join('')
    for (let i = 0; i < value.length; i++) {
        if (i > 0 && i % 3 === 0) {
            result = `${result} `
        }
        result = `${result}${value[i]}`
    }

    if (result.length && result[result.length - 1] === ' ') {
        result = result.substr(0, result.length - 1)
    }

    return result.split('')
        .reverse()
        .join('')
}

export const decimalToString = (value) => {
    let mathRound = 4

    value = Math.round10(value, -mathRound)

    let major = value.toString()
    let commaIndex = major.replace(',', '.')
        .indexOf('.')

    // minor part
    let minor = ''
    if (commaIndex > -1) {
        minor = major.substr(commaIndex + 1)
        major = major.substr(0, commaIndex)
    }

    major = formatNumberMajorPart(major)
    while (minor.length > 0) {
        let lastChar = minor[minor.length - 1]
        if (lastChar === '.') {
            minor = ''
        } else if (lastChar === '0') {
            minor = minor.substr(0, minor.length - 1)
        } else {
            break
        }
    }

    if (minor.length === 0) {
        return major
    }

    return `${major}.${minor}`
}
