import React from 'react';
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {Card, Col, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import classNames from "classnames";
import VehiclesInfoSelect from "../../shipment/VehiclesInfoSelect";


const ReturnShipmentDetailsForm = ({order}) => {
    const {formState: {errors}, setValue, watch, control} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;



    const { fields: fieldsDriver, append: appendDriver, remove: removeDriver } = useFieldArray({
        control,
        name: "car_info.drivers"
    });
    const watchFieldArrayDrivers = watch("car_info.drivers");
    const controlledFieldsDrivers = fieldsDriver.map((field, index) => {
        return {
            ...field,
            ...watchFieldArrayDrivers[index]
        };
    });

    const { fields: fieldsNumber, append: appendNumber, remove: removeNumber } = useFieldArray({
        control,
        name: "car_info.numbers"
    });
    const watchFieldArrayNumbers = watch("car_info.numbers");
    const controlledFieldsNumbers = fieldsNumber.map((field, index) => {
        return {
            ...field,
            ...watchFieldArrayNumbers[index]
        };
    });

    const { fields: fieldsModel, append: appendModel, remove: removeModel } = useFieldArray({
        control,
        name: "car_info.models"
    });
    const watchFieldArrayModels = watch("car_info.models");
    const controlledFieldsModels = fieldsModel.map((field, index) => {
        return {
            ...field,
            ...watchFieldArrayModels[index]
        };
    });



    const onChangeDriver = (option) => {
        if (option) {
            controlledFieldsDrivers.filter(d => d.id === option.id ? d.is_active = true : d.is_active = false)
            setValue("car_info.name", controlledFieldsDrivers.find(d => d.id === option.id)?.label)
        } else {
            controlledFieldsDrivers.filter(d => d.is_active ? d.is_active = false : d.is_active)
            setValue("car_info.name", null)
        }
    }

    const onChangeNumber = (option) => {
        if (option) {
            controlledFieldsNumbers.filter(d => d.id === option.id ? d.is_active = true : d.is_active = false)
            setValue("car_info.serial_number", controlledFieldsNumbers.find(d => d.id === option.id)?.label)
        } else {
            controlledFieldsNumbers.filter(d => d.is_active ? d.is_active = false : d.is_active)
            setValue("car_info.serial_number", null)
        }
    }

    const onChangeModel = (option) => {
        if (option) {
            controlledFieldsModels.filter(d => d.id === option.id ? d.is_active = true : d.is_active = false)
            setValue("car_info.mark", controlledFieldsModels.find(d => d.id === option.id)?.label)
        } else {
            controlledFieldsModels.filter(d => d.is_active ? d.is_active = false : d.is_active)
            setValue("car_info.mark", null)
        }
    }

    return (
        <>
            <Card className="mt-3">
                <Card.Header>
                    <Card.Title>{t(lang, 'edi.return_shipment.shipment_details.title')}</Card.Title>
                </Card.Header>
                <Card.Body className="bg-light">
                    <Row>
                        <Col xs="12" lg="6" className="fs--1">
                            <div>
                                <p className="col mb-1 fw-bold fs-1 text-dark">{t(lang, 'edi.common.customer')}:</p>
                            </div>
                            <div className="row">
                                <p className="col mb-1 fw-bold text-dark">{t(lang, 'edi.common.label.name')}:</p>
                                <p className="col mb-1">{order.customer.name}</p>
                            </div>
                            <div className="row">
                                <p className="col mb-1 fw-bold text-dark">{t(lang, 'edi.common.label.inn')}:</p>
                                <p className="col mb-1">{order.customer.inn}</p>
                            </div>
                            <div className="row">
                                <p className="col mb-1 fw-bold text-dark">{t(lang, 'edi.return_shipment.shipping_details.loading_address')}:</p>
                                <p className="col mb-1">{order.branch.name}</p>
                            </div>
                        </Col>
                        <Col className="d-lg-none">
                            <hr />
                        </Col>
                        <Col xs="12" lg="6" className="fs--1">
                            <div>
                                <p className="col mb-1 fw-bold fs-1 text-dark">{t(lang, 'edi.common.executor')}:</p>
                            </div>
                            <div className="row">
                                <p className="col mb-1 fw-bold text-dark">{t(lang, 'edi.common.label.name')}:</p>
                                <p className="col mb-1">{order.executor.name}</p>
                            </div>
                            <div className="row">
                                <p className="col mb-1 fw-bold text-dark">{t(lang, 'edi.common.label.inn')}:</p>
                                <p className="col mb-1">{order.executor.inn}</p>
                            </div>
                        </Col>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="4" className="mb-2 mb-md-0" >
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipping_details.car_driver')}</FormLabel>
                                <Controller
                                    name="car_info.name"
                                    rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                    render={({field}) => (
                                        <VehiclesInfoSelect
                                            options={controlledFieldsDrivers}
                                            defaultValue={field.value}

                                            classNamePrefix="react-select"
                                            className={classNames({'is-invalid': !!errors?.car_info?.name})}
                                            remove={removeDriver}
                                            append={appendDriver}
                                            onChange={(option) => onChangeDriver(option)}
                                            noOptionsMessage={() => t(lang, 'edi.common.driver_no_option', {driver: t(lang, 'edi.common.driver_no_option.param')})}
                                        />
                                    )}
                                />

                                <FormControl.Feedback type="invalid">
                                    {errors?.car_info?.name?.message}
                                </FormControl.Feedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4" className="mb-2 mb-md-0" >
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipping_details.car_serial_number')}</FormLabel>
                                <Controller
                                    name="car_info.serial_number"
                                    rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                    render={({field}) => (
                                        <VehiclesInfoSelect
                                            options={controlledFieldsNumbers}
                                            defaultValue={field.value}

                                            classNamePrefix="react-select"
                                            className={classNames({'is-invalid': !!errors?.car_info?.serial_number})}
                                            remove={removeNumber}
                                            append={appendNumber}
                                            onChange={(option) => onChangeNumber(option)}
                                            noOptionsMessage={() => t(lang, 'edi.common.car_serial_no_option', {carSerial: t(lang, 'edi.common.car_serial_no_option.param')})}
                                        />
                                    )}
                                />

                                <FormControl.Feedback type="invalid">
                                    {errors?.car_info?.serial_number?.message}
                                </FormControl.Feedback>
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4" >
                            <FormGroup>
                                <FormLabel>{t(lang, 'edi.return_shipment.shipping_details.car_mark')}</FormLabel>
                                <Controller
                                    name="car_info.mark"
                                    rules={{required: t(lang, 'edi.return_shipment.shipping_details.car_mark.placeholder')}}
                                    render={({field}) => (
                                        <VehiclesInfoSelect
                                            options={controlledFieldsModels}
                                            defaultValue={field.value}

                                            classNamePrefix="react-select"
                                            className={classNames({'is-invalid': !!errors?.car_info?.mark})}
                                            remove={removeModel}
                                            append={appendModel}
                                            onChange={(option) => onChangeModel(option)}
                                            noOptionsMessage={() => t(lang, 'edi.common.car_no_option', {car: t(lang, 'edi.common.car_no_option.param')})}
                                        />
                                    )}
                                />

                                <FormControl.Feedback type="invalid">
                                    {errors?.car_info?.mark?.message}
                                </FormControl.Feedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default ReturnShipmentDetailsForm;