import React, {useState} from "react";
import IconButton from "../../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {deleteRoamingInvoiceDraftAsync} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_INVOICE_BULK_ACTION_FINISHED,
    ROAMING_INVOICE_BULK_ACTION_STARTED,
    ROAMING_INVOICE_DRAFT_DELETE_FAILED,
    ROAMING_INVOICE_DRAFT_DELETE_REQUESTED,
    ROAMING_INVOICE_DRAFT_DELETE_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingInvoiceEvents";

const RoamingInvoiceDraftDelete = ({id, invoices, handleShowProgressBar, updateActionResult, hasTitle, ...props}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    // show/hide confirmation modal
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // button loader
    const [loading, setLoading] = useState(false);

    // delete invoice
    const deleteDraft = () => {
        if (invoices.length > 0)
            bulkDelete();
        else
            singleDelete();
    }

    // delete single document
    const singleDelete = () => {
        setLoading(true);
        EventBus.dispatch(ROAMING_INVOICE_DRAFT_DELETE_REQUESTED, id);
        deleteRoamingInvoiceDraftAsync(id)
            .then(() => {
                setLoading(false);
                EventBus.dispatch(ROAMING_INVOICE_DRAFT_DELETE_SUCCESS, id);
                handleClose();
            })
            .catch(() => {
                EventBus.dispatch(ROAMING_INVOICE_DRAFT_DELETE_FAILED, id);
                setLoading(false);
            })
    }

    // delete multiple documents
    const bulkDelete = async () => {
        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];
            try {
                await deleteRoamingInvoiceDraftAsync(invoices[i].id);
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    },
                    error: error?.message
                })
            }
        }
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
        handleClose();
    }

    return (
        <>
            <IconButton onClick={handleShow}
                        variant="falcon-danger"
                        icon={faTrash}
                        {...props}
            >
                {hasTitle && t(lang, "roaming.invoice.delete")}
            </IconButton>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "roaming.invoice.delete_draft_modal_title")}
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "roaming.invoice.delete_draft_modal_body")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "roaming.common.cancel_button")}
                    </Button>
                    <Button variant="danger"
                            disabled={loading}
                            onClick={deleteDraft}
                    >
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, "roaming.invoice.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

RoamingInvoiceDraftDelete.propTypes = {
    id: PropTypes.string,
    invoices: PropTypes.array,
    hasTitle: PropTypes.bool,
    updateActionResult: PropTypes.func,
    handleShowProgressBar: PropTypes.func
}

RoamingInvoiceDraftDelete.defaultProps = {
    id: "",
    invoices: [],
    hasTitle: false,
    updateActionResult: () => {},
    handleShowProgressBar: () => {}
}

export default RoamingInvoiceDraftDelete;
