import React, {useEffect} from 'react';
import BoughtDatatable from "./BoughtDatatable";
import {
    changeFilterBoughtAsync,
    loadBoughtPackages,
    loadBoughtPackagesCount,
    selectBoughtFilter, selectBoughtPackages, selectBoughtPackagesCount
} from "../../../../../app/store/reducers/billing/billingReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {EXPENSES} from "./OtherTab";

const Bought = ({tab}) => {
    const dispatch = useDispatch()
    const filter = useSelector(selectBoughtFilter)
    const count = useSelector(selectBoughtPackagesCount)
    const bought = useSelector(selectBoughtPackages)
    const activeOrganization = useSelector(selectActiveOrganization)

    useEffect(() => {
       if(tab === EXPENSES) {
           dispatch(loadBoughtPackages({...filter}))
           dispatch(loadBoughtPackagesCount())
       }
    }, [filter, activeOrganization, tab])

    return <BoughtDatatable  filter={filter} count={count} bought={bought} changeFilterBoughtAsync={changeFilterBoughtAsync}/>;
};

export default Bought;
