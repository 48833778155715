import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.uz";
import {Controller, useFormContext} from "react-hook-form";
import classNames from "classnames";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";


const AddUserStepTwo = ({invalidConfirmCode}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {trigger, formState: {errors}, control} = useFormContext()


    useEffect(() => {
        if (invalidConfirmCode)
            trigger();
    }, [invalidConfirmCode]);


    return(
        <>
            <Form.Group className="text-start mb-3">
                    <Form.Label>{t(lang,"edi.common.users_organization_sms")}:</Form.Label>
                    <Controller
                        control={control}
                        name='confirmCode'
                        render={({field}) => (
                            <Cleave
                                placeholder="123456"
                                options={{numericOnly:true}}
                                onChange={(confirmCode) => field.onChange(confirmCode)}
                                className={classNames('form-control', {
                                    'is-invalid': errors?.confirmCode,
                                    'is-valid': Object.keys(errors).length > 0 && !errors?.confirmCode,
                                })}
                            />
                        )}
                        rules={{
                            required: t(lang, "edi.common.forms.validations.is_required"),
                            pattern: {
                                value: /^\d{6}$/,
                                message: t(lang, 'authentication.forgot-password.confirmation_code.format')
                            },
                            validate: value => {
                                if (invalidConfirmCode && invalidConfirmCode === value){
                                    return t(lang,"authentication.forgot-password.confirmation_code.is_invalid")
                                }
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.confirmCode?.message}
                    </Form.Control.Feedback>
                </Form.Group>

            <Form.Group>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        {t(lang,"edi.common.add_button")}
                    </Button>
                </Modal.Footer>
            </Form.Group>
        </>
    )
}


AddUserStepTwo.propTypes = {
    confirmCodeError: PropTypes.bool,
}


export default AddUserStepTwo;