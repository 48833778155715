import React from 'react';
import {ListGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import Flex from "../../../components/common/Flex";
import {useRouteMatch} from "react-router";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const Dashboard = () => {
    const {url} = useRouteMatch();
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ListGroup>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/items`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/sales`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.sale_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.sale_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/purchases`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.purchase_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.purchase_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/refunds`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.refund_from_customer_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.refund_from_customer_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/return-purchases`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.refund_to_executor_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.refund_to_executor_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/revisions`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.revision_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.revision_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/write-offs`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.write_off_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.write_off_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/transfers`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.transfer_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.transfer_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/draft-purchases`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.draft_purchase_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.draft_purchase.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/draft-refunds`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.draft_refund_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.draft_refund.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/report`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.navbar.routes.warehouse_operation_period_report")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.navbar.routes.warehouse_operation_period_report")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/items/drafts`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.non_id_product_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.non_id_product_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/items/categories`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.category_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.category_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/warehouses`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.common.item.title.warehouses")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.common.item.title.warehouses")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/items/history`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.product_history_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.product_history_title.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/operation/item-report`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.common.item.title.operation_histories")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.common.item.title.operation_histories")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/partners`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "partners.common.title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "partners.common.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/items/settings`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "warehouse.dashboard.items.settings_title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "warehouse.dashboard.items.settings_title.description")}
                </p>
            </ListGroup.Item>
        </ListGroup>
    );
};

export default Dashboard;