import React, {useEffect, useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import BindContractorToAccountForm from "./BindContractorToAccountForm";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";


const BindContractorToAccountModal = ({show, onHide, defaultValue, user}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            account_id: "",
            contractor_id: [],
            cashbox_id: [],
            branch_id: []
        }
    })

    const onHideModal = () => {
            onHide()
            methods.setValue('account_id', "")
            methods.setValue('contractor_id', [])
            methods.setValue('cashbox_id', [])
            methods.setValue('branch_id', [])
    }

    return (
        <Modal show={show} onHide={onHideModal} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>{t(lang, "crm.bind.contractor.account.navbar.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...methods}>
                    <Form id={'connecting-form'}>
                        <BindContractorToAccountForm defaultValue={defaultValue}/>
                    </Form>
                </FormProvider>
            </Modal.Body>
        </Modal>
    );
};

export default BindContractorToAccountModal;
