import {Button, Card, Col, Form, FormLabel, Row, Spinner} from "react-bootstrap";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFieldArray, useForm, useWatch} from "react-hook-form";
import classNames from "classnames";
import SelectContractor from "../SelectContractor";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {
    selectContractorFilterOptions,
    selectCardContractor, selectAllContractor
} from "../../../../../app/store/reducers/contractor/contractorReducer";
import SelectContractorPaymentType from "../SelectContractorPaymentType";
import IconButton from "../../../../common/IconButton";
import SelectCurrencyCode from "../SelectCurrencyCode";
import DatePicker from "react-datepicker";
import Cleave from "cleave.js/react";
import {useLocation} from "react-router";
import {currencyConverterConvert, selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {UZS} from "../../../../../enum/CurrencyCode";
import dayjs from "dayjs";
import CashBoxSelect from "../../../cashbox/common/CashBoxSelect";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";
import SoftBadge from "../../../../common/SoftBadge";
import numeral from "numeral";


//TODO: optimize code and render count
const ContractorPayoutForm = ({onClose, loading, onSubmit, payout, onEditNote, onEditContractor, onEditDate}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();
    const dateFormat = useSelector(selectDateFormat);
    const filters = useSelector(selectContractorFilterOptions);
    const cardContractor = useSelector(selectCardContractor);
    const numberFormat = useSelector(selectNumberFormat);
    const contractors = useSelector(selectAllContractor);
    const location = useLocation();
    const currencies = useSelector(selectCurrency);
    const settings = useSelector(selectDefaultSetting)

    const defaultDebtStates = {
        amount: '',
        currency_id: currencies.find(cur => cur.is_active)?.id || currencies.find(c => c?.code === UZS)?.id
    }
    const defaultCashBoxStates = {
        type: 1,
        currency: {
            amount: '',
            currency_id: currencies.find(cur => cur.is_active)?.id || currencies.find(c => c?.code === UZS)?.id
        }
    }

    const routeArr = location.pathname.split("/")
    const isContractorCard = routeArr.includes("contractor-card");

    const {getValues, formState: {errors}, handleSubmit, control, register, setValue} = useForm({
        defaultValues: {
            contractor: (payout && payout.contractor.id) || (isContractorCard && cardContractor?.id) || null,
            date: payout ? dayjs(payout?.date, "YYYY-MM-DD HH:mm").toDate() : dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            notes: payout ? payout.notes : '',
            debt_states: payout ? payout.debt_states.map(ds => {
                return {
                    type: ds.payment_type,
                    currency: {
                        amount: ds.amount,
                        currency_id: ds.currency?.id
                    }
                }
            }) : [defaultDebtStates],
            cash_box_states: payout ? payout.cash_box_states.map(bs => {
                return {
                    type: bs.payment_type,
                    currency: {
                        amount: bs.amount,
                        currency_id: bs.currency?.id
                    }
                }
            }) : [defaultCashBoxStates],
            cashbox_id: payout ? payout.cash_box?.id : settings?.cash_box?.default_id || ""
        }
    });

    const {
        fields: fieldsDebtStates,
        update: updateDebtStates,
        append: appendDebtStates,
        remove: removeDebtStates,
        replace: replaceDebtStates,
    } = useFieldArray({
        control,
        name: 'debt_states'
    });
    const {
        fields: fieldsCashBoxStates,
        update: updateCashBoxStates,
        append: appendCashBoxStates,
        remove: removeCashBoxStates,
        replace: replaceCashBoxStates,
    } = useFieldArray({
        control,
        name: 'cash_box_states'
    });

    const watchCashBoxStates = useWatch({name: 'cash_box_states', control});
    const watchDebtStates = useWatch({name: 'debt_states', control});

    useEffect(() => {
        let mm = watchCashBoxStates.map(item => {
            return {
                currency_id: item.currency.currency_id,
                amount: item.currency.amount,
            }
        });
        replaceDebtStates(mm)
    }, [watchCashBoxStates])

    useEffect(() => {
        setValue('cashbox_id', payout ? payout.cash_box?.id : settings?.cash_box?.default_id || null)
    }, [settings])

    const onChangeContractor = (contractor) => {
        setValue('contractor', contractor ? contractor.id : null)
        if (!payout && contractor && contractor?.debts?.length > 0) {
            let dt = contractor?.debts?.filter(c => c?.amount !== 0).map(debt => {
                return {
                    amount: Math.abs(debt?.amount),
                    currency_id: debt?.currency?.id
                }
            })
            let cc = contractor?.debts?.filter(c => c?.amount !== 0).map(debt => {
                return {
                    type: 1,
                    currency: {
                        amount: Math.abs(debt?.amount),
                        currency_id: debt?.currency?.id
                    }
                }
            })
            replaceCashBoxStates(cc)
            replaceDebtStates(dt)
        }

        if (payout && payout.id && contractor) {
            onEditContractor(contractor.id)
        }
    }

    const onChangeCashBoxStatesPaymentType = (selectedPaymentType, field, index) => {
        if (selectedPaymentType) {
            updateCashBoxStates(index, {
                type: selectedPaymentType.value,
                currency: field.currency,
            })
        }
    }
    const onChangeCashBoxStatesCurrencyCode = async (newCurrency, field, index) => {
        if (newCurrency) {
            const oldCurrencyId = getValues(`cash_box_states.${index}.currency.currency_id`);
            try {
                if (oldCurrencyId && oldCurrencyId !== newCurrency.id) {
                    const {currency_to: {value}} = await currencyConverterConvert({
                        currency_id_from: oldCurrencyId,
                        value_from: Number(field.currency.amount),
                        currency_id_to: newCurrency.id
                    });
                    updateCashBoxStates(index, {
                        type: field.type,
                        currency: {
                            currency_id: newCurrency.id,
                            amount: value,
                        }
                    })
                }
            } catch (error) {
                updateCashBoxStates(index, {
                    type: field.type,
                    currency: {
                        currency_id: newCurrency.id,
                        amount: field.currency.amount,
                    }
                })
            }
        }
    }
    const onChangeDebtStatesCurrencyId = async (newCurrency, field, index) => {
        if (newCurrency) {
            try {
                const oldCurrencyId = getValues(`debt_states.${index}.currency_id`);
                if (oldCurrencyId && oldCurrencyId !== newCurrency.id) {
                    const {currency_to: {value}} = await currencyConverterConvert({
                        currency_id_from: oldCurrencyId,
                        value_from: Number(field.amount),
                        currency_id_to: newCurrency.id
                    });
                    updateDebtStates(index, {
                        currency_id: newCurrency.id,
                        amount: value,
                    })
                }
            } catch (error) {
                updateDebtStates(index, {
                    currency_id: newCurrency.id,
                    amount: field.amount,
                })
            }
        }
    }

    const addNewPaymentRowToCashBoxStates = () => {
        appendCashBoxStates(defaultCashBoxStates)
    }
    const addNewPaymentRowToDebtStateAmounts = () => {
        appendDebtStates(defaultDebtStates)
    }
    const onTrashCashBoxStates = (index) => {
        removeCashBoxStates(index)
    }
    const onTrashDebtStateAmounts = (index) => {
        removeDebtStates(index)
    }

    const handleOnBlurNote = (val) => {
        if (payout && payout.id)
            onEditNote(val.target.value)
    }

    const setFunctions = {
        setGroupedSums: useState({})[1],
        setGroupedDebtSums: useState({})[1]
    };

    const groupCurrency = useCallback((newGroupedSums, item) => {
        const currencyId = currencies.find(cur => cur.id === item?.currency?.currency_id || cur.id === item.currency_id)?.is_active ? item?.currency?.currency_id || item.currency_id : currencies.find(cur => cur.is_active)?.id;
        const amountToString = item?.currency?.amount.toString() || item.amount?.toString() || '0'
        const amount = parseFloat(amountToString.replace(/\s/g, '') || amountToString.replace(/\s/g, '')) || 0;
        if (!newGroupedSums[currencyId]) {
            newGroupedSums[currencyId] = {
                total: amount,
                currency_id: currencyId,
                name: currencies.find(f => f.id === currencyId)?.name
            };
        } else {
            newGroupedSums[currencyId].total += amount;
        }
    }, [])

    const totalGroupCurrencyCashBoxStates = useMemo(() => {
        let newGroupedSums = {};
        watchCashBoxStates.forEach(item => {
            groupCurrency(newGroupedSums, item)
        });

        return Object.values(newGroupedSums);
    }, [watchCashBoxStates, currencies]);

    const totalGroupCurrencyDebtStates = useMemo(() => {
        let newGroupedSums = {};
        watchDebtStates.forEach(item => {
            groupCurrency(newGroupedSums, item)
        });

        return Object.values(newGroupedSums);
    }, [watchDebtStates, currencies]);

    const updateSums = () => {
        setFunctions.setGroupedSums(totalGroupCurrencyCashBoxStates);
        setFunctions.setGroupedDebtSums(totalGroupCurrencyDebtStates);
    };
    return (<>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xs={12}>
                    <Controller control={control}
                                name="contractor"
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required"),
                                }}
                                render={({field, fieldState: {error}}) => {
                                    const contractor = contractors.find(c => c.id === field.value) || null;
                                    return (
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t(lang, "edi.contractor.datatable.header.row.contractor")}</Form.Label>
                                            <SelectContractor defaultValue={contractor?.id}
                                                              onChange={onChangeContractor}
                                                              placeholder={t(lang, "edi.contractor.datatable.header.row.contractor")}
                                                              classNamePrefix="react-select"
                                                              className={classNames({
                                                                  'is-invalid': !!error,
                                                              })}
                                                              wrapperClassName={classNames({
                                                                  'is-invalid': !!error,
                                                              })}
                                            />
                                            {!!error && <Form.Control.Feedback
                                                type="invalid">{error?.message}</Form.Control.Feedback>}
                                            {contractor &&
                                                <>
                                                    {!!contractor.debts.length &&
                                                        <div className="d-flex flex-wrap gap-2 mt-2">
                                                            {contractor.debts.map((debt, index) => (
                                                                <SoftBadge bg='primary' key={index}>
                                                                    <span>{numeral.formats[numberFormat].format(debt.amount)}</span>
                                                                    <small className="ms-2 fw-bold text-info">{debt.currency.name}</small>
                                                                </SoftBadge>
                                                            ))}
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </Form.Group>
                                    )
                                }}
                    />
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label>{t(lang, "cashbox.bind.account.title")}</Form.Label>
                        <Controller
                            control={control}
                            name={'cashbox_id'}
                            render={({field}) => (
                                <CashBoxSelect
                                    defaultValue={field.value}
                                    onChange={selectedCashBox => selectedCashBox ? field.onChange(selectedCashBox.id) : field.onChange(null)}
                                />
                            )}/>
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t(lang, "edi.contractor_payment_form_date_field")}</Form.Label>
                        <Controller
                            control={control}
                            name='date'
                            render={({field}) => (
                                <DatePicker
                                    placeholderText={t(lang, "edi.common.order.date.placeholder")}
                                    dateFormat={`${dateFormat} HH:mm`}
                                    selected={field.value}
                                    onChange={(date) => {
                                        field.onChange(date)
                                        if (onEditDate)
                                            onEditDate(date)
                                    }}

                                    showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={60}
                                    className={'form-control'}
                                />
                            )}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <div className={"d-flex justify-content-between mb-1"}>
                        <FormLabel>{t(lang, "edi.contractor_payment_form_cash_label")}</FormLabel>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            onClick={() => {
                                addNewPaymentRowToCashBoxStates()
                            }}/>
                    </div>
                    {fieldsCashBoxStates.map((item, index) => (
                        <Form.Group className="text-start mb-3 d-flex" key={item.id}>
                            <Controller name={`cash_box_states.${index}.type`}
                                        control={control}
                                        render={({field}) => (
                                            <SelectContractorPaymentType
                                                defaultValue={field.value}
                                                onChange={(paymentType) => onChangeCashBoxStatesPaymentType(paymentType, item, index)}
                                            />
                                        )}
                            />
                            <Controller
                                name={`cash_box_states.${index}.currency.amount`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <Cleave options={{numeral: true, delimiter: ' ', numeralDecimalScale: 3}}
                                            value={field.value}
                                            placeholder={"Сумма"}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.cash_box_states?.[index]?.currency.amount.message,
                                                'is-valid': Object.keys(errors).length > 0 && !errors?.cash_box_states?.[index]?.currency.amount.message
                                            })}
                                            onChange={val => {
                                                let v = val.target.value ? val.target.value.replace(/-/g, '') : val.target.value
                                                field.onChange(v)
                                                updateSums()
                                            }}
                                    />
                                )}
                            />
                            <Controller name={`cash_box_states.${index}.currency.currency_id`}
                                        control={control}
                                        render={({field}) => (
                                            <SelectCurrencyCode defaultValue={field.value}
                                                                onChange={(currencyCode) => {
                                                                    onChangeCashBoxStatesCurrencyCode(currencyCode, item, index)
                                                                    updateSums()
                                                                }}/>
                                        )}
                            />
                            {fieldsCashBoxStates.length !== 1 && <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon={"trash-alt"}
                                className={"ms-2"}
                                iconClassName={"text-secondary"}
                                onClick={() => onTrashCashBoxStates(index)}
                            />}
                        </Form.Group>
                    ))}
                </Col>
                <Col xs={12} lg={6}>
                    <div className={"d-flex justify-content-between mb-1"}>
                        <FormLabel>{t(lang, "edi.contractor_payment_form_debt_label")}</FormLabel>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            onClick={() => {
                                addNewPaymentRowToDebtStateAmounts()
                            }}/>
                    </div>
                    {fieldsDebtStates.map((item, index) => (
                        <Form.Group className="text-start mb-3 d-flex" key={item.id}>
                            <Controller
                                name={`debt_states.${index}.amount`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <Cleave
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 3
                                        }}
                                        value={field.value}
                                        placeholder={"Сумма"}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.debt_states?.[index]?.amount.message,
                                            'is-valid': Object.keys(errors).length > 0 && !errors?.debt_states?.[index]?.amount.message
                                        })}
                                        onChange={val => {
                                            let v = val.target.value ? val.target.value.replace(/-/g, '') : val.target.value
                                            field.onChange(v)
                                            updateSums()
                                        }}
                                    />
                                )}
                            />
                            <Controller name={`debt_states.${index}.currency_id`}
                                        control={control}
                                        render={({field}) => (
                                            <SelectCurrencyCode defaultValue={field.value}
                                                                onChange={(currencyCode) => {
                                                                    onChangeDebtStatesCurrencyId(currencyCode, item, index)
                                                                    updateSums()
                                                                }}/>
                                        )}
                            />
                            {fieldsDebtStates.length !== 1 && <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon={"trash-alt"}
                                className={"ms-2"}
                                iconClassName={"text-secondary"}
                                onClick={() => {
                                    onTrashDebtStateAmounts(index)
                                }}
                            />}
                        </Form.Group>
                    ))}

                </Col>
            </Row>
            <Row>
                <h5>{t(lang, 'crm.common.datatable.total_amount')}</h5>
                <Col xs={12} lg={6}>
                    {totalGroupCurrencyCashBoxStates.map(item => <div className={'text-end fs-0'}>
                        <strong>{numeral.formats[numberFormat].format(item?.total)}</strong>
                        <strong className={'text-info fw-bold ms-2'}>{item.name}</strong>
                    </div>)}
                </Col>
                <Col xs={12} lg={6}>
                    {totalGroupCurrencyDebtStates.map(item => <div className={'text-end fs-0'}>
                        <strong>{numeral.formats[numberFormat].format(item?.total)}</strong>
                        <strong className={'text-info fw-bold ms-2'}>{item.name}</strong>
                    </div>)}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group className="text-start mb-3">
                        <Form.Label>{t(lang, "edi.contractor_payment_form_note_field")}</Form.Label>
                        <Form.Control
                            placeholder={"Информация"}
                            name="notes"
                            as="textarea"
                            {...register(`notes`)}
                            onBlur={handleOnBlurNote}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <div className={'text-end mt-3'}>
                <Button variant="secondary" onClick={onClose} className={'me-2'}>
                    {t(lang, "edi.common.cancel_decline")}
                </Button>
                <Button variant="primary" disabled={loading} type="submit">
                    {loading && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                    />}
                    {t(lang, "edi.common.button.save")}
                </Button>
            </div>
        </Form>
    </>)
}
export default ContractorPayoutForm
