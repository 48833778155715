import {revisionDownloadPdfAsync} from "../../../../../app/store/reducers/warehouse-operation/revisionReducer";
import IconButton from "../../../../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Spinner} from "react-bootstrap";
import {useState} from "react";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";



const RevisionDownloadPdf = ({revisionId, number, ...props}) => {
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate



    const downloadPdfRevision = () => {
        setLoading(true)
        revisionDownloadPdfAsync({revisionId})
            .then((response) => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${t(lang, "warehouse.item.download_excel.revision_name", {number: number})}.pdf`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }




    return (
        <>
            <IconButton {...props} icon="file-pdf" disabled={loading} size="sm" className="mx-1" transform="shrink-3" variant="falcon-primary" onClick={downloadPdfRevision}>
                <span className="d-none d-sm-inline-block">{t(lang, "warehouse.operation.item.common.download_pdf")}</span>
                {loading && (
                    <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
                )}
            </IconButton>
        </>
    );
};

export default RevisionDownloadPdf;