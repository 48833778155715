import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import FreeFormDocumentDataTable from "../../../../components/roaming/freeform/datatable/FreeFormDocumentDataTable";
import {
    loadFreeFormSenderDocumentsAsync,
    loadFreeFormSenderDocumentsCountAsync,
    loadFreeFormReceiverDocumentsAsync,
    loadFreeFormReceiverDocumentsCountAsync,
    selectFreeFormDocumentsFilterOptions, changeFreeFormsFilterOptions
} from "../../../../app/store/reducers/roaming/roamingFreeFormDocumentReducer";
import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import classNames from "classnames";
import Flex from "../../../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useParams} from "react-router";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const FreeFormDocuments = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const filter = useSelector(selectFreeFormDocumentsFilterOptions);
    const activeOrganization = (useSelector(selectActiveOrganization));
    const routeParams = useParams();
    const {limit} = useSelector(selectFreeFormDocumentsFilterOptions);

    const [viewType, setViewType] = useState(routeParams.viewType)

    useEffect(() => {
        loadFreeFormDocuments();
    }, [filter, activeOrganization])

    useEffect(() => {
        onSwitchViewType(routeParams.viewType)
    }, [routeParams])

    const loadFreeFormDocuments = () => {
        switch (viewType) {
            case FreeFormDocuments.ViewTypeInbox:
                dispatch(loadFreeFormReceiverDocumentsAsync({...filter}));
                dispatch(loadFreeFormReceiverDocumentsCountAsync({...filter}));
                break;

            case FreeFormDocuments.ViewTypeOutbox:
                dispatch(loadFreeFormSenderDocumentsAsync({...filter}));
                dispatch(loadFreeFormSenderDocumentsCountAsync({...filter}));
                break;
        }
    }

    const onSwitchViewType = vt => {
        if (viewType === vt) return;

        dispatch(changeFreeFormsFilterOptions({limit: limit, page: 1}));
        setViewType(vt);
    }

    const viewTypes = [
        {
            viewType: FreeFormDocuments.ViewTypeInbox,
            langKey: 'roaming.free_form_document.datatable.view_type.inbox',
            icon: 'arrow-down',
        },
        {
            viewType: FreeFormDocuments.ViewTypeOutbox,
            langKey: 'roaming.free_form_document.datatable.view_type.outbox',
            icon: 'arrow-up',
        }
    ]

    return (
        <Row>
            {viewTypes.map(vt =>
                <Col xs={6} md={6} key={vt.viewType}>
                    <Link to={`./${vt.viewType}`} className="text-decoration-none">
                        <Card className={classNames("p-3 cursor-pointer", {'bg-primary text-white': viewType === vt.viewType})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon={vt.icon}
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames("d-none d-lg-block mb-0", {
                                    'text-white': viewType === vt.viewType,
                                    'text-primary': viewType !== vt.viewType
                                })}>{t(lang, vt.langKey)}</Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>)}

            <Col xs={12} className="mt-3">
                <FreeFormDocumentDataTable/>
            </Col>
        </Row>
    );
};

FreeFormDocuments.ViewTypeInbox = 'inbox'
FreeFormDocuments.ViewTypeOutbox = 'outbox'

export default FreeFormDocuments;
