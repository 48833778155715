import React from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/IconButton";
import withDeleteItem from "./withDeleteItem";

const DeleteItemButton = ({item, handleShow}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <IconButton variant="falcon-danger"
                    size="sm"
                    icon={faTrash}
                    onClick={() => handleShow(item)}
                    className="mt-2 mt-lg-0 ms-md-2"
        >
            {t(lang, 'items.common.delete')}
        </IconButton>
    );
};

export default withDeleteItem(DeleteItemButton);