import dayjs from "dayjs";
import React, {useEffect} from 'react';
import numeral from 'numeral';
import {useDispatch, useSelector} from "react-redux";
import {Card, Col, Row, Table} from "react-bootstrap";
import {ExecutorApproved, getStatusColor, GetStatusText, InProgress} from "../../../enum/OrderStatus";
import {
    selectOrderContractDefineMethodStrategy,
    selectOrderExecutorCreateInvoiceStrategy,
    selectOrderExecutorEditStrategy,
    selectOrderItemQuantityLimitStrategy,
    selectOrderProductNameDefineMethodStrategy,
    selectOrderShipmentDateMethodStrategy,
} from "../../../app/order/GetStrategies";
import ExecutorDecline from "./ExecutorDecline";
import CustomerApprove from "./CustomerApprove";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import ExecutorApprove from "./ExecutorApprove";
import StopOrder from "./StopOrder";
import ResumeOrder from "./ResumeOrder";
import {Link, useHistory} from "react-router-dom";
import ExecutorCancelDecline from "./ExecutorCancelDecline";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../app/store/reducers/main/mainReducer";
import IconButton from "../../common/IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import OrderPrint from "./OrderPrint";
import ShipmentDataTable from "../shipment/ShipmentDataTable";
import {
    loadCountShipments, loadDataTableColumn,
    loadShipments,
    selectFilterOptions,
    selectShipmentsDataTable
} from "../../../app/store/reducers/shipment/shipmentReducer";

import OrderProtocolInfo from "./OrderProtocolInfo";
import UploadOrderToExcel from "./excel/UploadOrderToExcel";
import {NoLimit, SumLimit} from "../../../app/order/OrderItemQuantityLimitStrategyType";
import {selectItems} from "../../../app/store/reducers/item/itemReducer";
import OrderIdentifyItems from "./OrderIdentifyItems";
import OrderExecutorItem from "./OrderExecutorItem";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiOrderView, PermissionEdiShipmentCreate} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import {selectAccountRolePermissions} from "../../../app/store/reducers/role/roleReducer";
import {useParams} from "react-router";

const Order = ({order}) => {
    const {viewer} = useParams()

    const dispatch = useDispatch()
    const history = useHistory()
    const activeOrganization = useSelector(selectActiveOrganization)
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const filters = useSelector(selectFilterOptions)
    const activeColumns = useSelector(selectShipmentsDataTable)
    const items = useSelector(selectItems);

    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        permission.length && !dispatch(checkPermission(PermissionEdiOrderView)) && history.goBack()
    }, [permission])


    useEffect(() => {
        dispatch(loadCountShipments(viewer, {...filters, order_id: order.id, viewer_inn: activeOrganization.inn}))
        dispatch(loadShipments(viewer, {...filters, order_id: order.id, viewer_inn: activeOrganization.inn}))
        dispatch(loadDataTableColumn())
    }, [filters, activeOrganization])

    const subtotal = order.items.reduce((acc, item) => acc + item.total, 0)
    const tax = order.items.reduce((acc, item) => acc + item.nds_value, 0);

    const isExecutor = activeOrganization.inn === order.executor.inn;

    const allowCustomerApprove = activeOrganization.inn === order.customer.inn && !order.customer_approve.is_approved;
    const allowExecutorApprove = activeOrganization.inn === order.executor.inn && !order.executor_approve.is_approved && !order.executor_approve.is_declined;
    const allowExecutorDecline = activeOrganization.inn === order?.executor?.inn && !order?.executor_approve?.is_declined && !order?.executor_approve?.is_approved;
    const allowStopOrder = activeOrganization.inn === order?.executor?.inn && !order?.stop_info?.is_stopped && order.executor_approve.is_approved
    const allowRegisterShipment = activeOrganization.inn === order?.executor?.inn && (order.status === ExecutorApproved || order.status === InProgress)
    const allowResumeOrder = order?.executor?.inn === activeOrganization.inn && order?.stop_info?.is_stopped;

    const isOrderDeclinedToday = order?.executor_approve.is_declined && dayjs(order?.executor_approve?.declined_on, 'YYYY-MM-DD').format(dateFormat) === dayjs(new Date()).format(dateFormat);
    const allowExecutorCancelDecline = order?.executor?.inn === activeOrganization.inn && isOrderDeclinedToday;

    const getWarehouseItemsStateAmount = (itemId) => {
        if (items.length === 0)
            return 0;
        let foundItem = items.find(pr => pr.item.id === itemId)

        if (!foundItem)
            return 0
        return foundItem?.warehouse_states?.warehouse_items.reduce((total, item) => {
            return total + item.state
        }, 0);
    }
    const getWarehouseItems = (itemId) => {
        if (items.length === 0)
            return []

        let foundItem = items.find(pr => pr.item.id === itemId)
        if (!foundItem)
            return []
        return foundItem.warehouse_states.warehouse_items
    }

    return (
        <>
            <Card className="my-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang, "edi.common.created.time")}: {order.created_at}</h3>
                    <Col className="text-end">
                        <span className="me-2">{t(lang, 'edi.common.label.status')}: </span>
                        <span className={`p-1 rounded-pill text-white fs--2`}
                              style={{backgroundColor: getStatusColor(order.status)}}>{t(lang, GetStatusText(order.status))}</span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {allowRegisterShipment &&
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon={faPlus}
                                iconClassName="me-1"
                                className="ms-2"
                                onClick={() => !dispatch(checkPermission(PermissionEdiShipmentCreate)) && dispatch(toastPermission())}
                                as={dispatch(checkPermission(PermissionEdiShipmentCreate)) ? Link : 'div'}
                                to={{
                                    pathname: `/edi/shipment/add/${order.id}`,
                                    state: {id: order.id}
                                }}
                            >{t(lang, 'edi.order.new_shipment_button')}</IconButton>}
                        {allowCustomerApprove &&
                            <CustomerApprove id={order.id} className="ms-2"/>
                        }
                        {allowExecutorApprove &&
                            <ExecutorApprove id={order.id} className="ms-2"/>
                        }
                        {allowResumeOrder &&
                            <ResumeOrder id={order.id} className="ms-2"/>
                        }
                        {allowExecutorCancelDecline &&
                            <ExecutorCancelDecline id={order.id} className="ms-2"/>
                        }
                        {allowStopOrder &&
                            <StopOrder id={order.id} className="ms-2"/>
                        }
                        {allowExecutorDecline &&
                            <ExecutorDecline id={order.id} className="ms-2"/>
                        }
                        <OrderProtocolInfo id={order.id} className="me-1"/>
                        <OrderPrint size="sm" variant="falcon-default" className="mx-2" id={order.id}/>
                        <UploadOrderToExcel className="me-2 d-inline-block" size="sm" number={order.info.number}
                                            id={order.id}/>

                        {isExecutor && <OrderIdentifyItems className="me-2 d-inline-block" id={order.id}/>}
                    </Col>
                </Row>
            </Card>

            <Card className="p-3">
                <Row className="d-flex flex-lg-row flex-column mt-3">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.executor')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {order.executor.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {order.executor.inn}</span>
                    </Col>

                    <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.customer')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {order.customer.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {order.customer.inn}</span>
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card className="my-3">
                <Card.Header>
                    <Row className="my-3">
                        <h5 className="p-0 ms-3"><b className="pe-1">{t(lang, "edi.common.id")}:</b>{order.id}</h5>
                        <Col className="col-12 d-flex flex-column align-items-center justify-content-center">
                            <h3>{t(lang, 'edi.order.view.title')}</h3>
                            <b>№ {order.info.number} от {dayjs(order.info.date, "YYYY-MM-DD").format(dateFormat)}</b>
                            <b>{t(lang, "edi.common.to.contract")} № {order.contract.number} от {dayjs(order.contract.date, "YYYY-MM-DD").format(dateFormat)}</b>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={6} className="fs--1 mb-3 mb-lg-0">
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.contract_number')}:</span>
                                <span className="col mb-1 ps-0">{order.contract.number}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.contract_date')}:</span>
                                <span
                                    className="col mb-1 ps-0">{dayjs(order.contract.date, 'YYYY-MM-DD').format(dateFormat)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.delivery_deadline')}:</span>
                                <span
                                    className="col mb-1 ps-0">{dayjs(order.expires_on, 'YYYY-MM-DD').format(dateFormat)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.delivery_address')}:</span>
                                <span className="col mb-1 ps-0">{order.branch.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.customer_system_id')}:</span>
                                <span className="col mb-1 ps-0">{order.customer_system_id}
                                    <hr className={order.customer_system_id ? 'm-0' : 'mb-0'} style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.customer_shipment_id')}:</span>
                                <span className="col mb-1 ps-0">{order.customer_shipment_id}
                                    <hr className={order.customer_shipment_id ? 'm-0' : 'mb-0'}
                                        style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="fs--1 mb-3 mb-lg-0">
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.executor_edit_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderExecutorEditStrategy(order.executor_edit_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.executor_invoice_creation_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderExecutorCreateInvoiceStrategy(order.executor_create_invoice_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.shipment_date_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderShipmentDateMethodStrategy(order.shipment_date_method_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.goods_name_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderProductNameDefineMethodStrategy(order.product_name_define_method_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.contract_info_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderContractDefineMethodStrategy(order.contract_define_method_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.goods_quantity_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderItemQuantityLimitStrategy(order.item_quantity_limit_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Table bordered responsive className="border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th rowSpan={2}>№</th>
                            <th className="text-start">{t(lang, 'edi.common.product')}</th>
                            <th className="text-center">{t(lang, 'edi.common.customer_system_id')}</th>
                            <th className="text-center">{t(lang, 'edi.common.customer_shipment_id')}</th>
                            <th className="text-center">{t(lang, 'edi.common.barcode')}</th>
                            <th className="text-center">{t(lang, 'edi.common.catalog_class_code')}</th>
                            <th className="text-center">{t(lang, 'edi.common.measurement')}</th>
                            <th className="text-center">{t(lang, 'edi.common.quantity')}</th>
                            <th className="text-center">{t(lang, 'edi.common.price')}</th>
                            <th className="text-center">{t(lang, 'edi.common.total')}</th>
                            <th className="text-center">{t(lang, 'edi.common.nds_rate')}</th>
                            <th className="text-center">{t(lang, 'edi.common.nds_value')}</th>
                            <th className="text-end">{t(lang, 'edi.common.total_with_nds')}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                            <th className="p-0 text-center">5</th>
                            <th className="p-0 text-center">6</th>
                            <th className="p-0 text-center">7</th>
                            <th className="p-0 text-center">8</th>
                            <th className="p-0 text-center">9</th>
                            <th className="p-0 text-center">10</th>
                            <th className="p-0 text-center">11</th>
                            <th className="p-0 text-center">12</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order.items.map((item, index) => {
                            const showExecutorItem = isExecutor && item.executor_item

                            return <tr key={item.id} className="border-500">
                                <td>{index + 1}</td>
                                <td className="text-start text-nowrap fw-semi-bold">
                                    {showExecutorItem && <OrderExecutorItem item={item}/>}
                                    {!showExecutorItem && <span>{item.product}</span>}
                                </td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.customer_system_id}</td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.customer_shipment_id}</td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.barcode}</td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.catalog_class_code}</td>
                                <td className="text-center">{item.measurement}</td>
                                {order.item_quantity_limit_strategy === SumLimit.id &&
                                    <td className="text-center">{item.quantity_left} / {item.quantity}</td>
                                }
                                {order.item_quantity_limit_strategy === NoLimit.id &&
                                    <td className="text-center">{item.quantity}</td>
                                }
                                <td className="text-center">{numeral.formats[numberFormat].format(item.price)}</td>
                                <td className="text-center">{numeral.formats[numberFormat].format(item.total)}</td>
                                <td className="text-center">{item.nds_rate}</td>
                                <td className="text-center">{numeral.formats[numberFormat].format(item.nds_value)}</td>
                                <td className="text-end">{numeral.formats[numberFormat].format(item.total_with_nds)}</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, 'edi.common.total')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(subtotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900">{t(lang, 'edi.common.nds_value')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(tax)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, 'edi.common.total_with_nds')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(subtotal + tax)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <ShipmentDataTable hideViewers={true} orderStatus={order.status} activeColumns={activeColumns}/>
        </>
    );
};

export default Order;
