import React, {Fragment, useEffect, useRef, useState} from 'react';
import IconButton from "../../common/IconButton";
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import {Controller} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {faQrcode} from "@fortawesome/free-solid-svg-icons";

const MarksModal = ({fields, appendMark, removeMark, control, rootName}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false)
    const inputRefs = useRef([]);

    useEffect(() => {

        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                appendMark();
            }
        };

        if (show) {
            window.addEventListener('keydown', handleKeyPress);
        } else {
            window.removeEventListener('keydown', handleKeyPress);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [show]);

    useEffect(() => {
        if (inputRefs.current.length > 0) {
            inputRefs.current[fields.length - 1]?.focus();
        }
    }, [fields.length]);

    return (
        <Fragment>
            <IconButton
                variant="falcon-default"
                size="sm"
                icon={faQrcode}
                onClick={() => setShow(true)}
                transform="shrink-3"
            />

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "roaming.invoice.mark")}
                    </Modal.Title>
                    <Button variant={'falcon-primary'} className={'float-end'} onClick={appendMark}>{t(lang, "items.common.add")}</Button>
                </Modal.Header>
                <Modal.Body>
                    {fields.map((item, idx) => (
                        <InputGroup key={item.id || idx} className={fields.length > 1 ? 'mb-2' : ''}>
                            <Controller
                                name={`${rootName}.marks.${idx}.code`}
                                control={control}
                                defaultValue={item.code}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        placeholder={t(lang, 'items.common.code.placeholder')}
                                        value={field.value || ''}
                                        onChange={field.onChange}
                                        ref={el => inputRefs.current[idx] = el}
                                    />
                                )}
                            />
                            {fields.length > 1 && (
                                <IconButton
                                    icon="trash"
                                    variant="danger"
                                    title="Удалить"
                                    onClick={() => removeMark(idx)}
                                />
                            )}
                        </InputGroup>
                    ))}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default MarksModal;
