import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import telegramOrderBotService from "../../../../services/telegram-order-bot/telegramOrderBotService";
import {Card, Col, Nav, Row, Tab} from "react-bootstrap";
import TelegramOrderBotUsersDataTable
    from "../../../../components/hippo/crm/contractor/telegram-bot/TelegramOrderBotUsersDataTable";
import TelegramOrderBotConfig
    from "../../../../components/hippo/crm/contractor/telegram-bot/TelegramOrderBotConfig";
import DeleteTelegramOrderBotButton
    from "../../../../components/hippo/crm/contractor/telegram-bot/order/DeleteTelegramOrderBotButton";

const TelegramOrderBot = () => {
    const {id} = useParams();
    const t = Translate;
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);

    const [loading, setLoading] = useState(false);
    const [bot, setBot] = useState(null);
    const [initialized, setInitialized] = useState(false);

    const loadTelegramOrderBot = async (id) => {
        try {
            setLoading(true);
            const {data: bot} = await telegramOrderBotService.getTelegramOrderBot(id);
            setBot(bot);
            setLoading(false);
        } catch (error) {
            setBot(null);
            setLoading(false);
            console.log(error);
        }
        setInitialized(true);
    }

    useEffect(() => {
        id && loadTelegramOrderBot(id);
    }, [id, activeOrganization]);

    if (loading) return <>loading...</>;

    if (!initialized) return null;

    if (!bot) return null;

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <Card>
                    <Card.Header>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <Card.Title>{bot.name}</Card.Title>
                                <Card.Subtitle>{t(lang, "edi.common.created.time")}: {bot.created_at}</Card.Subtitle>
                            </div>
                            <div>
                                <DeleteTelegramOrderBotButton bot={bot}/>
                            </div>
                        </div>
                    </Card.Header>
                </Card>
            </Col>
            <Col xs={12}>
                <Card>
                    <Tab.Container defaultActiveKey="botConfig">
                        <Card.Header>
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="botConfig">
                                        {t(lang, "items.common.settings")}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="botUsers">
                                        {t(lang, "crm.bind.contractor.account.data.table.header.title")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Content>
                                <Tab.Pane eventKey="botConfig">
                                    <TelegramOrderBotConfig bot={bot}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="botUsers">
                                    <TelegramOrderBotUsersDataTable bot={bot}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Card.Body>
                    </Tab.Container>
                </Card>
            </Col>
        </Row>
    );
};

export default TelegramOrderBot;