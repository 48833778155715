import React, {Fragment, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import IconButton from "../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DeleteOrganizationDemoUses from "./DeleteOrganizationDemoUses";

const OrganizationDemoUses = ({serviceDemoUses, organizationId}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [defaultValue, setDefaultValue] = useState(null)
    const ref = useRef()
    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "admin.organizations.datatable.item.name"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original.name}
                    </>
                );
            }
        },
        {
            accessor: 'action',
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <Fragment>
                        <IconButton
                            icon={faTrash}
                            variant="falcon-danger"
                            size="sm"
                            onClick={() => {
                                ref?.current?.openModal()
                                setDefaultValue(original)
                            }}
                        />
                    </Fragment>
                )
            }
        },
    ]

    return (
        <Fragment>
            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={serviceDemoUses}
            >
                <Card className="mb-3">
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>

            <DeleteOrganizationDemoUses
                setDefaultValue={setDefaultValue}
                organizationId={organizationId}
                serviceCode={defaultValue?.code}
                ref={ref}
            />
        </Fragment>
    );
};

export default OrganizationDemoUses;