import React from 'react';
import CashIn from "../../../../components/hippo/cashbox/cash-in/CashIn";
import {getCashInAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";

const ViewCashIn = ({viewId}) => {
    return (
        <CashIn
            request={getCashInAsync}
            title={"cashboxes.cash-in.nav.label"}
            id={viewId}
        />
    );
};

export default ViewCashIn;
