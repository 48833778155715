import ReactSelect from "react-select";
import {barcodeTypes} from "../enum/itemWrapper";
import React from "react";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const SelectItemBarcodeType = ({defaultTypeValue, onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ean8Type = barcodeTypes.find(type => type.value === 'EAN8');
    return (
        <ReactSelect options={barcodeTypes}
                     classNamePrefix="select"
                     isSearchable={false}
                     hideSelectedOptions
                     getOptionLabel={option => t(lang, option.label)}
                     getOptionValue={option => option.value}
                     placeholder={t(lang, "items.common.select")}
                     onChange={onChange}
                     value={barcodeTypes.find(m => m.value === defaultTypeValue) || ean8Type}
                     {...selectProps}
        />
    )
}
export default SelectItemBarcodeType