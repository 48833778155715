export const LeadTypeBuyer = 1
export const LeadTypeProvider = 2

export const LeadTypes = [
    {
        label: 'edi.common.buyer',
        value: LeadTypeBuyer
    },
    {
        label: 'roaming.common.executor',
        value: LeadTypeProvider
    }
]

export const FindLeadType = (type) => {
    return LeadTypes.find(lt => lt.value === type);
}