import React, {useEffect, useState} from "react";
import {useFieldArray, useFormContext} from "react-hook-form";
import {Card, Table, Form, Button, Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import Flex from "../../../../common/Flex";
import IconButton from "../../../../common/IconButton";
import ActionButton from "../../../../common/ActionButton";
import PropTypes from "prop-types";
import ConvertQuantityToWord from "../../ConvertQuantityToWord";

const Item = ({root, index, allowDelete, onDeleteClicked}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {register, formState: {errors}, setValue, watch} = useFormContext();


    const itemRootName = `${root}.${index}`;
    let itemErrors = {};

    if (errors && errors[root] && errors[root][index])
        itemErrors = errors[root][index];

    const quantity = watch(`${itemRootName}.quantity`);

    useEffect(() => {
        let convertedToWordQuantity = ConvertQuantityToWord.getText(+quantity);
        setValue(`${itemRootName}.quantityInWords`, convertedToWordQuantity);
    }, [quantity])




    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                <Form.Control name={`${itemRootName}.product`}
                              placeholder={t(lang, "edi.common.items.product.placeholder")}
                              className="p-1"
                              isInvalid={itemErrors?.product}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.product}
                              {...register(`${itemRootName}.product`, {
                                  required: t(lang, "edi.common.forms.validations.is_required"),
                              })}  />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.product?.message}
                </Form.Control.Feedback>
            </td>
            <td>
                <Form.Control placeholder={t(lang, "edi.common.items.product.measurement.placeholder")}
                              className="p-1"
                              isInvalid={itemErrors?.measurement}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.measurement}
                              {...register(`${itemRootName}.measurement`, {
                                  required: t(lang, "edi.common.forms.validations.is_required")
                              })}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.measurement?.message}
                </Form.Control.Feedback>
            </td>
            <td>
                <Form.Control name={`${itemRootName}.quantity`}
                              type="text"
                              isInvalid={itemErrors?.quantity}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.quantity}
                              {...register(`${itemRootName}.quantity`, {
                                  required: t(lang, 'edi.common.forms.validations.is_required'),
                              })}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.quantity?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Form.Control placeholder={t(lang, "edi.common.items.product.quantity_in_words")}
                              className="p-1"
                              disabled
                              {...register(`${itemRootName}.quantityInWords`)}
                />
            </td>
            <td>
                <ActionButton icon="trash-alt"
                              title="Delete"
                              variant="action"
                              className="p-1 mx-auto"
                              onClick={() => onDeleteClicked(index)}
                              disabled={!allowDelete}
                />
            </td>
        </tr>
    )
};


const AttorneyItems = ({root, loading}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {append, fields, remove} = useFieldArray({name: root});
    const [allowDeleteItems, setAllowDeleteItems] = useState(false);


    const addNewItem = () => {
        const item = {
            product: "",
            measurementId: "",
            quantity: "",
            catalogClassCode: "",
            catalogClassName: "",
        };
        append(item);
    };

    const onDeleteItem = index => remove(index);

    useEffect(() => {
        setAllowDeleteItems(fields.length > 1);
    }, [fields]);

    return (
        <Card>
            <Card.Header>
                <Flex justifyContent="between" alignItems="center">
                    <h4>{t(lang, "edi.common.items")}</h4>
                    <IconButton variant="falcon-default" size="sm" icon="plus" transform="shrink-3" className="me-2"
                                onClick={() => addNewItem()}>
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, "edi.common.add_button")}
                                </span>
                    </IconButton>
                </Flex>
            </Card.Header>
            <Card.Body>
                <Table responsive striped bordered>
                    <thead>
                    <tr>
                        <th className="text-center align-middle">#</th>
                        <th className="text-center align-middle">{t(lang, "edi.common.items.product")}</th>
                        <th className="text-center align-middle">{t(lang, "edi.common.items.product.measurement")}</th>
                        <th className="text-center align-middle">{t(lang, "edi.common.items.product.quantity")}</th>
                        <th>{t(lang, "edi.common.items.product.quantity_in_words")}</th>
                        <th className="text-center align-middle"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <Item key={item.id} root={root} index={index} allowDelete={allowDeleteItems} onDeleteClicked={onDeleteItem}/>
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
                <Button type="submit" disabled={loading}>
                    {loading &&
                        <Spinner className="align-middle me-2" animation="border" role="switch"
                                 size="sm"/>}
                    {t(lang, "edi.common.button.create")}
                </Button>
            </Card.Footer>
        </Card>
    )
};

AttorneyItems.propTypes = {
    loading: PropTypes.bool
};

AttorneyItems.defaultProps = {
    loading: false,
};



export default AttorneyItems;