import SelectRole from "../../../../common/SelectRole";
import {Button, Col, Form, Modal, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, useForm} from "react-hook-form";
import {bindRoleIntoAccountAsync} from "../../../../../app/store/reducers/role/roleReducer";
import FalconCloseButton from "../../../../common/FalconCloseButton";

const BindRoleIntoAccountFormModal = ({account, defaultRole, onHide, show}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false)
    console.log(defaultRole, 'default role')
    const {handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            role: defaultRole || null,
            username: account.username
        },
    });
    const onSubmit = (formData) => {
        setLoading(true);
        bindRoleIntoAccountAsync({
            account_id: account.id,
            id: formData.role.id
        }).then(r => {
            setLoading(false)
            onHide()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (<>
        <Modal show={show}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "hippo.user.settings.bind_role_into_to_account_form.title")}</Modal.Title>
                    <FalconCloseButton onClick={() => {
                        onHide()
                    }}/>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="text-start mb-3">
                        <Form.Label>{t(lang, "edi.branch.datatable.header.row.code")}</Form.Label>
                        <Form.Control
                            value={account.username}
                            disabled/>
                    </Form.Group>
                    <Form.Group as={Col} md={12} lg={12}>
                        <Form.Label>Роль</Form.Label>
                        <Controller
                            name="role"
                            control={control}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            }}
                            render={({field}) => (
                                <SelectRole defaultRole={defaultRole}
                                            onChange={role => field.onChange(role)}/>
                            )}
                        />
                        <div className={"text-danger small"}>
                            {errors?.role?.message}
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        {t(lang, "edi.common.cancel_button")}
                    </Button>
                    <Button variant="primary" disabled={loading} type="submit">
                        {loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                        />}
                        {t(lang, "edi.common.save_button")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>)
}
export default BindRoleIntoAccountFormModal
