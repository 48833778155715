import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Spinner} from "react-bootstrap";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const LeadNoteForm = ({loading, onSubmit, note, onCancel}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {handleSubmit, register, reset, setValue, setFocus, formState: {errors}} = useForm({
        defaultValues: {
            note: '',
        }
    });
    useEffect(() => {
        if (note) {
            setValue('note', note.comment)
            setFocus('note')
        }
    }, [note])

    const onSubmitForm = (formData) => {
        onSubmit(formData)
        reset({note: ''})
    }
    const onReset = () => {
        reset({note: ''})
        onCancel()
    }
    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Form.Group className="d-flex pt-2 border-bottom pb-3">
                <FontAwesomeIcon icon={faCircleUser} className="fs-4 text-facebook mb-1 me-3"/>
                <div className={"w-100"}>
                    <Form.Control
                        placeholder="Комментирвать или запланировать дейсвие"
                        as="textarea"
                        rows={2}
                        name={"note"}
                        {...register("note", {
                            required: t(lang, "edi.common.forms.validations.is_required")
                        })}
                        isInvalid={errors?.note}
                    />
                    <div className={"d-flex mt-2 justify-content-end"}>
                        <Button variant="outline-secondary" size={"sm"} className={"border-0 me-1"} onClick={onReset}>
                            {t(lang, "edi.common.cancel_decline")}
                        </Button>
                        <Button size={"sm"} type='submit' variant='primary' disabled={loading}>
                            {loading &&
                            <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {!note && t(lang, 'edi.common.button.save')}
                            {note && t(lang, 'edi.common.button.edit')}
                        </Button>
                    </div>
                </div>
            </Form.Group>
        </Form>
    )
}
LeadNoteForm.defaultProps = {
    onCancel: () => {
    }
}
export default LeadNoteForm