import {Dropdown, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import MemberDropdownSelect from "../../form/taskCard/MemberDropdownSelect";
import SelectDropdownLabels from "../../form/taskCard/SelectDropdownLabels";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import classNames from "classnames";
import ConfirmationModal from "../ConfirmationModal";
import {Fragment, useRef} from "react";
import SelectEmployeeModal from "./SelectEmployeeModal";
import SelectContractorModal from "./SelectContractorModal";

const ModalSidebar = ({ edit, handlerClose, boardId }) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {formState: {errors}} = useFormContext();


    return (
        <Fragment>
            <h6 className="mt-5 mt-lg-0">{t(lang, 'task.board.modal.sidebar.title')}</h6>

            <Nav className="flex-lg-column fs--1">
                {/*<Nav.Item className={"me-2 me-lg-0"}>
                    <Dropdown>
                        <Dropdown.Toggle
                            as={Nav.Link}
                            className={classNames("border dropdown-caret-none nav-link-card-details p-0", {
                                'border-danger': errors?.creator_id
                            })
                        }>
                            <SelectEmployeeModal
                                name={'creator_id'}
                                title={'task.board.modal.reporter'}
                                isRequired={true}
                            >
                                <div className={'ps-3 p-2'}>
                                    <FontAwesomeIcon icon={'user'} className="me-2" />
                                    <span>{t(lang,'task.board.modal.reporter')}</span>
                                </div>
                            </SelectEmployeeModal>

                        </Dropdown.Toggle>
                    </Dropdown>
                </Nav.Item>*/}

                <Nav.Item className="me-2 me-lg-0">
                    <Dropdown>
                        <Dropdown.Toggle
                            as={Nav.Link}
                            className={"border dropdown-caret-none nav-link-card-details p-0"}
                        >
                            <SelectEmployeeModal
                                boardId={boardId}
                                edit={edit}
                                name={'assignee_id'}
                                title={'task.board.modal.assigner'}
                                isRequired={false}
                            >
                                <div className={'ps-3 p-2'}>
                                    <FontAwesomeIcon icon={'user'} className="me-2"/>
                                    <span> {t(lang, 'task.board.modal.assigner')}</span>
                                </div>
                            </SelectEmployeeModal>

                        </Dropdown.Toggle>
                    </Dropdown>
                </Nav.Item>

                <Nav.Item className="me-2 me-lg-0">
                    <Dropdown>
                        <Dropdown.Toggle
                            as={Nav.Link}
                            className={classNames("border dropdown-caret-none nav-link-card-details p-0", {
                                'border-danger': errors?.contractor?.id || errors?.contractor?.debt_promise_date
                            })
                            }
                        >
                            <SelectContractorModal edit={edit} handlerClose={handlerClose}>
                                <div className={'ps-3 p-2'}>
                                    <FontAwesomeIcon icon={'user'} className="me-2"/>
                                    <span> {t(lang, 'crm.draft_payment.datatable.header.filter.contractor')}</span>
                                </div>
                            </SelectContractorModal>
                        </Dropdown.Toggle>
                    </Dropdown>
                </Nav.Item>

                {/*<Nav.Item className={'me-2 me-lg-0'}>
                    <Dropdown ref={ref} id={'tag-id'}>
                        <Dropdown.Toggle disabled={edit} as={Nav.Link} className="border dropdown-caret-none nav-link-card-details">
                            <FontAwesomeIcon icon={'tag'} className="me-2" />
                            {t(lang, 'task.board.modal.label')}
                        </Dropdown.Toggle>
                        <SelectDropdownLabels
                            itemId={itemId}
                            addLabels={addLabels}
                            fields={labelFields}
                        />
                    </Dropdown>
                </Nav.Item>*/}
            </Nav>
        </Fragment>
    );
};

export default ModalSidebar;
