import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {toast} from "react-toastify";
import EventBus from '../../../../app/eventbus/EventBus';
import { DOCUMENT_SIGN_FAILED, DOCUMENT_SIGN_STARTED, DOCUMENT_SIGN_SUCCESS } from '../../../../app/eventbus/signEvents';
import { selectActiveOrganization } from '../../../../app/store/reducers/auth/authReducer';
import { selectLang, Translate, updateShowInProgressAsync } from '../../../../app/store/reducers/main/mainReducer';
import { loadDraftVerificationAct } from '../../../../app/store/reducers/roaming/roamingVerificationActReducer';
import VerificationActForm from '../../../../components/hippo/roaming/verification-act/VerificationActForm';
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingVerificationActUpdate, PermissionRoamingVerificationActView,
} from "../../../../enum/Permission/RoamingPermission";

const EditVerificationAct = () => {
    const [draftVerificationAct, setDraftVerificationAct] = useState({})
    const activeOrganization = useSelector(selectActiveOrganization)
    const history = useHistory()
    const { id } = useParams();
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingVerificationActUpdate))) {
            if (history.length > 1) {
                history.push('/roaming/verification-acts/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    const load = () => {
      loadDraftVerificationAct(id)
        .then(response => {
            let contentVerificationAct = JSON.parse(response.data.content)
            let verificationAct = {
                verificationActId: contentVerificationAct.VerificationActId,
                verificationActDoc: {
                    verificationActNo: contentVerificationAct.VerificationActDoc.VerificationActNo,
                    verificationActDate: dayjs(contentVerificationAct.VerificationActDoc.VerificationActDate).toDate(),
                    verificationActText: contentVerificationAct.VerificationActDoc.VerificationActText
                },
                ownerTin: contentVerificationAct.OwnerTin,
                ownerName: contentVerificationAct.OwnerName,
                ownerFizTin: contentVerificationAct.OwnerFizTin,
                ownerFizFio: contentVerificationAct.OwnerFizFio,
                partnerTin: contentVerificationAct.PartnerTin,
                partnerName: contentVerificationAct.PartnerName,
                partnerFizTin: contentVerificationAct.PartnerFizTin,
                partnerFizFio: contentVerificationAct.PartnerFizFio,
                verificationActContracts: contentVerificationAct.VerificationActContracts.map(verificationActContract => ({
                    contractNo: verificationActContract.ContractNo,
                    contractDate: dayjs(verificationActContract.ContractDate).toDate(),
                    verificationActContractItems: verificationActContract.VerificationActContractItems.map(verificationActContractItem => ({
                        ownerOperationDate: dayjs(verificationActContractItem.OwnerOperationDate).toDate(),
                        ownerOperationName: verificationActContractItem.OwnerOperationName,
                        ownerDebit: +verificationActContractItem.OwnerDebit,
                        ownerCredit: +verificationActContractItem.OwnerCredit,
                        partnerOperationDate: dayjs(verificationActContractItem.PartnerOperationDate).toDate(),
                        partnerOperationName: verificationActContractItem.PartnerOperationName,
                        partnerDebit: +verificationActContractItem.PartnerDebit,
                        partnerCredit: +verificationActContractItem.PartnerCredit
                    })),
                    openBalance: {
                        ownerDebit: +verificationActContract?.OpenBalance?.OwnerDebit || 0,
                        ownerCredit: +verificationActContract?.OpenBalance?.OwnerCredit || 0,
                        partnerDebit: +verificationActContract?.OpenBalance?.PartnerDebit || 0,
                        partnerCredit: +verificationActContract?.OpenBalance?.PartnerCredit || 0
                    },
                    closeBalance: {
                        ownerDebit: +verificationActContract?.CloseBalance?.OwnerDebit || 0,
                        ownerCredit: +verificationActContract?.CloseBalance?.OwnerCredit || 0,
                        partnerDebit: +verificationActContract?.CloseBalance?.PartnerDebit || 0,
                        partnerCredit: +verificationActContract?.CloseBalance?.PartnerCredit || 0
                    },
                    totalBalance: {
                        ownerDebit: +verificationActContract?.TotalBalance?.OwnerDebit || 0,
                        ownerCredit: +verificationActContract?.TotalBalance?.OwnerCredit || 0,
                        partnerDebit: +verificationActContract?.TotalBalance?.PartnerDebit || 0,
                        partnerCredit: +verificationActContract?.TotalBalance?.PartnerCredit || 0
                    }

                })),
                openBalance: {
                    ownerDebit: +contentVerificationAct?.OpenBalance?.OwnerDebit || 0,
                    ownerCredit: +contentVerificationAct?.OpenBalance?.OwnerCredit || 0,
                    partnerDebit: +contentVerificationAct?.OpenBalance?.PartnerDebit || 0,
                    partnerCredit: +contentVerificationAct?.OpenBalance?.PartnerCredit || 0,
                },
                turnoverBalance: {
                    ownerDebit: +contentVerificationAct?.TurnoverBalance?.OwnerDebit || 0,
                    ownerCredit: +contentVerificationAct?.TurnoverBalance?.OwnerCredit || 0,
                    partnerDebit: +contentVerificationAct?.TurnoverBalance?.PartnerDebit || 0,
                    partnerCredit: +contentVerificationAct?.TurnoverBalance?.PartnerCredit || 0,
                },
                closeBalance: {
                    ownerDebit: +contentVerificationAct?.CloseBalance?.OwnerDebit || 0,
                    ownerCredit: +contentVerificationAct?.CloseBalance?.OwnerCredit || 0,
                    partnerDebit: +contentVerificationAct?.CloseBalance?.PartnerDebit || 0,
                    partnerCredit: +contentVerificationAct?.CloseBalance?.PartnerCredit || 0,
                }
            }
            setDraftVerificationAct(verificationAct)
        })
        .catch(error => {
          console.log(error);
          history.push('/roaming/verification-acts/inbox')
        })
    }

    useEffect(() => {
      load()
    }, [id, activeOrganization.inn])
    useEffect(() => {
        const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const signSuccessHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
            toast.success(t(lang, "edi.document.sign.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
        });
        const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (data) => {
            toast.error(t(lang, data.message))
            dispatch(updateShowInProgressAsync(false))
        });

        return () => {
            EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
            EventBus.remove(DOCUMENT_SIGN_SUCCESS, signSuccessHandler)
            EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)
        }
    }, [])
    return <VerificationActForm verificationAct={draftVerificationAct}/>
};

export default EditVerificationAct;
