import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import {Button, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../../../common/advance-table/AdvanceTableFooter";
import AdvanceTableCustomPagination from "../../../../../common/advance-table/AdvanceTableCustomPagination";
import React, {useEffect} from "react";
import {getStatusColorShipment, GetStatusTextShipment} from "../../../../../../enum/ShipmentStatus";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import numeral from "numeral";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    selectContractorShipmentsCount,
    selectContractorShipmentFilterOptions,
    selectContractorShipments,
    loadContractorShipmentsAsync,
    loadContractorShipmentsCountAsync,
    changeFilterContractorShipmentsAsync
} from "../../../../../../app/store/reducers/contractor/contractorShipmentReducer";
import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import ContractorShipmentDataTableHeader from "./ContractorShipmentDataTableHeader";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";

const ContractorShipmentDataTable = () => {
    const contractor = useSelector(selectCardContractor)
    const dispatch = useDispatch()
    const shipments = useSelector(selectContractorShipments)
    const count = useSelector(selectContractorShipmentsCount)
    const filters = useSelector(selectContractorShipmentFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const t = Translate;
    const totalSum = shipments.reduce((totSum, total) => totSum + total.total_info.total, 0);

    useEffect(() => {
        dispatch(loadContractorShipmentsAsync({...filters, contractor_inn: contractor?.inn}))
        dispatch(loadContractorShipmentsCountAsync({...filters, contractor_inn: contractor?.inn}))
    }, [contractor, filters])

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'index',
            Header: t(lang, "edi.shipment.datatable.header.row.number"),
            cellProps: {
                width: '80px',
                className: 'p-0'
            },
            totalInfo: `${t(lang, "edi.common.datatable.total")}:`,
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            Cell: rowData => {
                const {original} = rowData.row

                return <div className="d-grid gap-2">
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {t(lang, GetStatusTextShipment(original.status))}
                            </Tooltip>
                        }
                        placement="right"
                    >
                        <Button variant="primary" style={{backgroundColor: getStatusColorShipment(original.status)}}
                                as={Link} to={`/edi/shipment/${original.id}`}>
                            {original.shipment_info.number}
                        </Button>
                    </OverlayTrigger>
                </div>
            }
        },
        {
            accessor: 'shipment_info.date',
            Header: t(lang, "edi.shipment.datatable.header.row.date"),
            cellProps: {
                width: '80px'
            },
            Cell: rowData => {
                const {original} = rowData.row
                return <b>{dayjs(original.shipment_info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            },
        },
        {
            accessor: 'total_info.total',
            Header: t(lang, "edi.shipment.datatable.header.row.total_sum"),
            cellProps: {
                width: '30px',
                className: 'text-end'
            },
            totalInfo: numeral.formats[numberFormat].format(totalSum),
            totalInfoClassName: "fw-bold text-nowrap",
            Cell: rowData => {
                const {original} = rowData.row

                return <>
                    {numeral.formats[numberFormat].format(original.total_info.total)}
                </>
            }
        },
        {
            accessor: 'order.branch.name',
            Header: t(lang, "edi.return_invoice.datatable.header.row.branch"),
            cellProps: {
                className: 'fw-bold',
                // width: '100px'
            },
        },
        {
            accessor: 'invoices[0].number',
            Header: t(lang, "edi.shipment.datatable.header.row.invoice"),
            cellProps: {
                width: '120px',
                className: 'fw-bold p-0'
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "edi.shipment.datatable.header.row.contractor"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer_info.inn

                if (isCustomer) {
                    return <>
                        {original.executor_info.name}
                    </>
                }

                return <>
                    {original.customer_info.name}
                </>
            }
        },
    ];

    const onPageChange = async (page) => dispatch(changeFilterContractorShipmentsAsync({...filters, page: page}))
    const onLimitChange = async (limit) => dispatch(changeFilterContractorShipmentsAsync({...filters, limit: limit, page: 1}))

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={shipments}
            sortable
            pagination
            perPage={1000}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ContractorShipmentDataTableHeader table/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
}
export default ContractorShipmentDataTable