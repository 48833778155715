import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../../app/eventbus/EventBus";
import CreateTelegramOrderSaleForm from "./CreateTelegramOrderSaleForm";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS} from "../../../../../app/eventbus/warehouse-operation/saleEvents";
import TelegramOrderBotService from "../../../../../services/telegram-order-bot/telegramOrderBotService";

const CreateTelegramOrderSale = () => {
    const [order, setOrder] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const warehouses = useSelector(selectWarehouses);
    const items = useSelector(selectItems);
    const {id: orderId} = useParams();
    const history = useHistory();

    useEffect(() => {
        const onRegisterSaleSucceed = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, (saleId) => {
            history.push(`/warehouse/operation/sales/view/${saleId}`);
        });

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, onRegisterSaleSucceed);
        };
    }, [])

    useEffect(() => {
        loadOrder(orderId);
    }, [orderId, activeOrganization])

    const loadOrder = async (id) => {
        try {
            const {data: order} = await TelegramOrderBotService.getTelegramOrderBotOrder(id)
            setOrder(order);
        } catch (error) {
            setOrder(null);
        }
    };

    if (!order || !warehouses?.length || !items?.length)
        return <span>loading....</span>;

    return <CreateTelegramOrderSaleForm order={order}/>;
};

export default CreateTelegramOrderSale;