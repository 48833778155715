export const OSTypeWindowsWin32 = 1;
export const OSTypeWindowsWin64 = 2;
export const OSTypeMacos = 3;
export const OSTypeLinux = 4;

export const GetOsLabel = type => {
    switch (type) {
        case OSTypeWindowsWin32:
            return "Windows Win32";
        case OSTypeWindowsWin64:
            return "Windows Win64";
        case OSTypeMacos:
            return "Macos";
        case OSTypeLinux:
            return "Linux";
        default:
            return "";
    }
}

export const GetOsExtension = type => {
    switch (type) {
        case OSTypeWindowsWin32:
            return ".exe";
        case OSTypeWindowsWin64:
            return ".exe";
        case OSTypeMacos:
            return ".app";
        case OSTypeLinux:
            return ".exe";
        default:
            return ".exe";
    }
}

const osType = (type, label) => {
    return {
        type,
        label
    }
}

export const OsTypes = [
    osType(OSTypeWindowsWin32, GetOsLabel(OSTypeWindowsWin32)),
    osType(OSTypeWindowsWin64, GetOsLabel(OSTypeWindowsWin64)),
    osType(OSTypeMacos, GetOsLabel(OSTypeMacos)),
    osType(OSTypeLinux, GetOsLabel(OSTypeLinux)),
];