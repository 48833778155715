import React, {Fragment, useEffect} from 'react';
import WaybillV2DataTable from "../../../../components/hippo/roaming/waybill/datatable/WaybillV2DataTable";
import {useParams} from "react-router";
import {RESPONSIBLE_PERSON, VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX} from "../../../../enum/roaming/WaybillStatus";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    changeWaybillsV2FilterOptions,
    getWaybillV2CustomerCount,
    getWaybillV2DraftsCount,
    getWaybillV2ExecutorCount, getWaybillV2ResponsiblePersonCount,
    loadWaybillV2CustomerAsync,
    loadWaybillV2DraftsAsync,
    loadWaybillV2ExecutorAsync,
    loadWaybillV2ResponsiblePersonAsync, selectWaybillsV2,
    selectWaybillsV2FilterOptions
} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {DELETE_WAYBILL_V2_SUCCEED} from "../../../../app/eventbus/roaming/roamingWaybillV2Events";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    loadSystemOrganizationAddressesAsync
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const WaybillsV2 = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const {viewer} = useParams();
    const dispatch = useDispatch();
    const filter = useSelector(selectWaybillsV2FilterOptions);
    const activeOrganization = (useSelector(selectActiveOrganization));
    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;
    const waybills = useSelector(selectWaybillsV2);


    useEffect(() => {
        dispatch(loadSystemOrganizationAddressesAsync());
    }, [activeOrganization]);

    const loadWaybills = () => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadWaybillV2CustomerAsync({...filter}));
            dispatch(getWaybillV2CustomerCount({...filter}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadWaybillV2ExecutorAsync({...filter}));
            dispatch(getWaybillV2ExecutorCount({...filter}));
        } else if (viewer === VIEW_DRAFTS) {
            dispatch(loadWaybillV2DraftsAsync({...filter}));
            dispatch(getWaybillV2DraftsCount({...filter}));
        } else if (viewer === RESPONSIBLE_PERSON) {
            dispatch(loadWaybillV2ResponsiblePersonAsync({...filter}));
            dispatch(getWaybillV2ResponsiblePersonCount({...filter}));
        }
    }

    const onLimitChange = async (limit) => {
        dispatch(changeWaybillsV2FilterOptions({...filter, limit: limit, page: 1}))
    }

    const onPageChange = async (page) => {
        dispatch(changeWaybillsV2FilterOptions({...filter, page: page}))
    }


    return (
        <WaybillV2DataTable
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
            changeFilterOptionsAsync={changeWaybillsV2FilterOptions}
            waybills={waybills}
            viewer={viewer}
            filter={filter}
            loadWaybills={loadWaybills}
        />
    );
};

export default WaybillsV2;
