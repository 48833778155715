import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {
    CalculatePaymentDebtAndCashBack,
    checkTypeOfReturnNumber, findCurrencyReturnRate,
} from "../enum/warehouseOperationWrapper";
import {UZS} from "../../item/enum/itemWrapper";
import {Col, Form, FormLabel, InputGroup, Row, Table} from "react-bootstrap";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import IconButton from "../../../common/IconButton";
import SelectCurrencyCode from "../../crm/contractor/SelectCurrencyCode";
import SelectContractorPaymentType from "../../crm/contractor/SelectContractorPaymentType";
import numeral from "numeral";



const WOWithoutDiscountPaymentForm = ({
                                          totals,

                                          controlledFieldsCashBackPay,
                                          onChangeCashBackPayPaymentType,
                                          addNewRowToCashBackPay,
                                          onTrashCashBackPay,
                                          onChangeCashBackPayCurrencyId,

                                          controlledFieldsCustomerDebtPay,
                                          addNewRowToCustomerDebtPay,
                                          onTrashCustomerDebtPay,
                                          onChangeCustomerDebtPayCurrencyId,

                                          addNewPaymentRowToCashBoxStates,
                                          onChangeCashBoxStatesPaymentType,
                                          onChangeCashBoxStatesCurrencyCode,
                                          onTrashCashBoxStates,
                                          fieldsCashBoxStates,
                                      }) => {
    const numberFormat = useSelector(selectNumberFormat)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate


    // state
    var groupByCurrencyCashBoxStates = []
    const [total, setTotal] = useState([])
    const cashBoxStates = useWatch({name: 'cash_box_states', control: form?.control});
    const cashBackPay = useWatch({name: 'cash_back_pay', control: form?.control});
    const customerDebtPay = useWatch({name: 'customer_debt_pay', control: form?.control});
    const {errors} = form.formState
    const currencies = useSelector(selectCurrency)


    for (let i = 0; i < cashBoxStates?.length; i++) {
        const cashBoxState = cashBoxStates[i];
        if (cashBoxState?.currency?.currency_id) {
            let found = false;
            for (let j = 0; j < groupByCurrencyCashBoxStates.length; j++) {
                if (groupByCurrencyCashBoxStates[j]?.currency?.id === cashBoxState?.currency?.currency_id) {
                    groupByCurrencyCashBoxStates[j].amount += checkTypeOfReturnNumber(cashBoxState?.currency?.amount) || 0
                    found = true;
                    break;
                }
            }

            if (!found) {
                groupByCurrencyCashBoxStates.push({
                    currency: currencies?.find(x => x.id === cashBoxState?.currency?.currency_id),
                    amount: checkTypeOfReturnNumber(cashBoxState?.currency?.amount) || 0,
                    payment_type: cashBoxState?.type
                })
            }
        }
    }


    const [debts, setDebts] = useState(0)
    const [cashBack, setCashBack] = useState(0)
    var cashBackTypeCash = []
    var cashBackTypeDebtState = []



    useEffect(() => {
        const totalFormat = totals.map(t => {
            return {
                amount: checkTypeOfReturnNumber(t.amount),
                currency: currencies?.find(x => x?.id === t?.currency?.id),
            };
        })
        var {cash_back, debts} = CalculatePaymentDebtAndCashBack({
            cash_box_states: groupByCurrencyCashBoxStates,
            net_price: totalFormat,
            currency: currencies
        })
        calculateDebtsAndCashBack({cash_back, debts})



        setTotal(totals)
        setCashBack(cash_back)
        setDebts(debts)
    },[cashBoxStates])




    const calculateDebtsAndCashBack = ({cash_back, debts}) => {
        var isShownCashBackFields = false
        cash_back.forEach(cashBack => {
            cashBackTypeCash.push({
                payment_type: 1,
                currency: cashBack.currency,
                amount: cashBack.amount
            })
        })

        cashBackTypeDebtState.push({
            payment_type: 1,
            currency: currencies?.find(currency => currency.is_national),
            amount: 0
        })
    }


    useEffect(() => {
        if (total) {
            const {debt_states} = calculateCashBackAndDebtState()
            form.setValue(`debt_states`, debt_states)
        }
    },[groupByCurrencyCashBoxStates, total, cashBack])



    const calculateCashBackAndDebtState = () => {
        //sotuvni obshiy summasidan qarzlarni valyuta bo'yicha ayirib chiqamiz
        let resCashBoxStates = []
        let resDebtStates = []
        //kassaga tushadigan pullarni yig'ib chiqamiz
        groupByCurrencyCashBoxStates.forEach(cashBoxState => {
            resCashBoxStates.push({
                payment_type: cashBoxState.payment_type,
                amount: +cashBoxState.amount,
                currency: cashBoxState.currency,
            })
        })

        //qarzdorlikka ketadigan tolovlarni qoshib chiqamiz
        total?.forEach(netPrice => {
            let foundDebt = debts?.find(debt => debt.currency?.id === netPrice.currency?.id)
            if (!foundDebt || (foundDebt.amount === 0 || foundDebt.amount === '0')) {
                resDebtStates.push({
                    currency: netPrice.currency,
                    amount: +netPrice.amount
                });
            } else {
                resDebtStates.push({
                    currency: netPrice.currency,
                    amount: +netPrice.amount - +foundDebt.amount
                });
            }
        });

        //agar qaytim mavjud bolsa kassaga tushayotgan puldan qaytimlarni ayiramiz yoki mijoz hisobiga o'tkazamiz
        let isExistCashBack = cashBack.length > 0 && cashBack.every(cashBackk => cashBackk.amount > 0)
        if (isExistCashBack) {
            // kassadan berilayotgan qaytim mavjud bo'lsa
            let isExistCashBackTypeCash = cashBackTypeCash.length > 0 && cashBackTypeCash.every(c => c.amount > 0)

            if (isExistCashBackTypeCash) {
                cashBackTypeCash.forEach(cashBackTypeCashh => {
                    // agar kassaga tushayotgan pul va kassadan berilayotgan qaytim valyutasi va tolov turi birxillari bo'lsa
                    let foundInCashBox = resCashBoxStates.find(cashBox => cashBox.currency?.id === cashBackTypeCashh.currency?.id && cashBox.payment_type === cashBackTypeCashh.payment_type)
                    if (foundInCashBox) {
                        foundInCashBox.amount = +foundInCashBox.amount - +cashBackTypeCashh.amount;
                    } else {
                        resCashBoxStates.push({
                            payment_type: cashBackTypeCashh.payment_type,
                            amount: 0 - +cashBackTypeCashh.amount,
                            currency: cashBackTypeCashh.currency
                        })
                    }
                })
            }

            //agar qaytimlar orasida mijozni hisobiga o'tkazish bo'lsa
            let isExistCashBackTypeDebtState = cashBackTypeDebtState.length > 0 && cashBackTypeDebtState.every(c => c.amount > 0);

            if (isExistCashBackTypeDebtState) {
                cashBackTypeDebtState.forEach(cashBackTypeDebtStatee => {
                    //agar qarz ichidan bunday valyutalik to'lov bolsa
                    let foundDebt = resDebtStates.find(debt => debt.currency?.id === cashBackTypeDebtStatee.currency?.id)
                    if (foundDebt) {
                        foundDebt.amount = +foundDebt.amount + +cashBackTypeDebtStatee.amount
                    } else {
                        resDebtStates.push({
                            amount: +cashBackTypeDebtStatee.amount,
                            currency: cashBackTypeDebtStatee.currency
                        })
                    }
                })
            }
        }

        let notNolResCashBoxStates = resCashBoxStates.filter(cashBox => cashBox.amount !== 0)
        let notNolResDebtStates = resDebtStates.filter(d => d.amount !== 0)
        return {
            cash_box_states: notNolResCashBoxStates.map(res => {
                return {
                    type: res.payment_type,
                    amount: res.amount,
                    currency_id: res.currency?.id,
                    currency: res.currency
                }
            }), debt_states: notNolResDebtStates.map(res => {
                return {
                    amount: res.amount,
                    currency_id: res.currency?.id,
                    currency: res.currency
                }
            })
        }
    }





    const [toPayShow, setToPayShow] = useState(false)
    const toPayHandlerShow = () => {
        setToPayShow(true)
        form.setValue(`cash_back_pay.${0}.currency.amount`, cashBack[0].amount.toFixed(2))
        form.setValue(`cash_back_pay.${0}.currency.currency_id`, cashBack[0]?.currency?.id)
    }

    useEffect(() => {
        if (cashBack?.length === 0) {
            if (cashBackPay[0].currency?.amount > 0 || customerDebtPay[0].amount > 0) {
                form.setValue(`cash_back_pay`, [{
                    type: 1,
                    currency: {
                        amount: '0',
                        currency_id: currencies?.filter(cur => cur.is_active)?.find(c => c?.code === UZS)?.id,
                    }
                }])
                form.setValue(`customer_debt_pay`, [{
                    amount: '0',
                    currency_id: currencies?.filter(cur => cur.is_active)?.find(c => c?.code === UZS)?.id
                }])
            }
            setToPayShow(false)
        }
    },[cashBack])

    const setCashTotalSum = (index) => {
        const indexAmount = checkTypeOfReturnNumber(form.watch(`cash_box_states.${index}.currency.amount`))
        const indexCurrency = +form.watch(`cash_box_states.${index}.currency.currency_id`)
        const cashBoxTotalSum = groupByCurrencyCashBoxStates.reduce((acc, curr) => {
            const currencyAmount = checkTypeOfReturnNumber(curr?.amount) * curr?.currency?.rate;
            return +acc + currencyAmount;
        }, 0)
        const totalSum = total?.reduce((acc, cur) => acc + (cur?.amount * findCurrencyReturnRate(currencies, cur?.currency?.id)), 0)

        form.setValue(`cash_box_states.${index}.currency.amount`, (indexAmount + ((totalSum - cashBoxTotalSum) / findCurrencyReturnRate(currencies, indexCurrency))).toFixed(2))
    }

    return (
        <>
            <Row>
                <Col xs={12} className="my-2">
                    <div className={"d-flex justify-content-between mb-1"}>
                        <FormLabel className="fs-1 fw-bold">{t(lang, "warehouse.operation.item.common.in_cash")}</FormLabel>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            onClick={() => {
                                addNewPaymentRowToCashBoxStates()
                            }}/>
                    </div>
                    {fieldsCashBoxStates.map((item, index) => (
                        <Form.Group className="text-start mb-3 d-flex" key={item?.id}>
                            <Controller name={`cash_box_states.${index}.type`}
                                        control={form.control}
                                        render={({field}) => (
                                            <SelectContractorPaymentType
                                                style={{width: "450px"}}
                                                defaultValue={field.value}
                                                onChange={(paymentType) => onChangeCashBoxStatesPaymentType(paymentType, item, index)}
                                            />
                                        )}
                            />
                            <Controller
                                name={`cash_box_states.${index}.currency.amount`}
                                control={form.control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required"),
                                }}
                                render={({field}) => (
                                    <Cleave options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 3,
                                    }}
                                            value={field.value}
                                            placeholder={"Сумма"}
                                            className={classNames('form-control', {
                                                'is-invalid': form?.formState?.errors?.cash_box_states?.[index]?.currency.amount.message,
                                                'is-valid': Object.keys(form?.formState?.errors).length > 0 && !form?.formState?.errors?.cash_box_states?.[index]?.currency.amount.message
                                            })}
                                            onChange={val => {
                                                field.onChange(val?.target?.value)
                                            }}
                                    />
                                )}
                            />
                            <Controller name={`cash_box_states.${index}.currency.currency_id`}
                                        control={form.control}
                                        render={({field}) => (
                                            <SelectCurrencyCode defaultValue={field.value}
                                                                onChange={(currencyCode) => {
                                                                    onChangeCashBoxStatesCurrencyCode(currencyCode, item, index)
                                                                }}/>
                                        )}
                            />
                            <InputGroup.Text className="cursor-pointer bg-primary text-white fw-bold" id="basic-addon2" onClick={() => setCashTotalSum(index)}>{t(lang, "warehouse.operation.item.common.total_sum_set")}</InputGroup.Text>
                            {fieldsCashBoxStates.length !== 1 && <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon={"trash-alt"}
                                className={"ms-2"}
                                iconClassName={"text-secondary"}
                                onClick={() => onTrashCashBoxStates(index)}
                            />}
                        </Form.Group>
                    ))}
                </Col>
                <Col xs={12}>
                    <Table borderless size="sm" className="fs-1 text-end">
                        <tbody>
                        <tr>
                            <th className="text-900 fw-bold w-100">{t(lang, "warehouse.operation.item.common.total_sum")}:</th>
                            {total?.length > 0 &&
                                <td className="fw-semi-bold text-nowrap">
                                    {total?.map((t, i) => {
                                        return (
                                            <div key={i}>
                                                {numeral.formats[numberFormat].format(t?.amount > 0 ? t?.amount : 0)}
                                                <span className="text-info ms-1">{t?.currency?.name}</span>
                                            </div>
                                        )
                                    })}
                                </td>
                            }
                        </tr>
                        <tr>
                            <th className="text-900 fw-bold w-100">{t(lang, "warehouse.operation.item.common.credit")}:</th>
                            {debts?.length > 0 ?
                                <td className="fw-semi-bold text-nowrap">
                                    {debts.map((t, i) => {
                                        return (
                                            <div key={i}>
                                                {numeral.formats[numberFormat].format(t?.amount || 0)}
                                                <span className="text-info ms-1">{t?.currency?.name}</span>
                                            </div>
                                        )
                                    })}
                                </td>
                                :
                                <td className="fw-semi-bold text-nowrap">
                                    {numeral.formats[numberFormat].format((0))}
                                    <span className="text-info ms-1">UZS</span>
                                </td>
                            }
                        </tr>
                        <tr>
                            <th className="text-900 fw-bold w-100">{t(lang, "warehouse.operation.item.common.back_pay")}:</th>
                            {cashBack?.length > 0 ?
                                <td className="fw-semi-bold text-nowrap">
                                    {cashBack.map((t, i) => {
                                        return (
                                            <div key={i} className="d-flex">
                                                <div>
                                                    {numeral.formats[numberFormat].format(t?.amount || 0)}
                                                    <span className="text-info ms-1">{t?.currency?.name}</span>
                                                </div>
                                                {!toPayShow &&
                                                    <IconButton
                                                        icon="pencil"
                                                        variant="falcon-warning"
                                                        size="sm"
                                                        className="ms-2"
                                                        onClick={() => toPayHandlerShow()}
                                                    />
                                                }
                                            </div>
                                        )
                                    })}
                                </td>
                                :
                                <td className="fw-semi-bold text-nowrap">
                                    {numeral.formats[numberFormat].format((0))}
                                    <span className="text-info ms-1">UZS</span>
                                </td>
                            }
                        </tr>
                        </tbody>
                    </Table>
                </Col>
                {toPayShow &&
                    <>
                        <Col xs={12} lg={6}>
                            <div className={"d-flex justify-content-between mb-1"}>
                                <FormLabel className="fs-1 fw-bold">{t(lang, "Дайте наличные")}</FormLabel>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    onClick={() => {
                                        addNewRowToCashBackPay()
                                    }}/>
                            </div>
                            {controlledFieldsCashBackPay.map((item, index) => (
                                <Form.Group className="text-start mb-3 d-flex" key={item.id}>
                                    <Controller name={`cash_back_pay.${index}.type`}
                                                control={form.control}
                                                render={({field}) => (
                                                    <SelectContractorPaymentType
                                                        style={{width: "450px"}}
                                                        defaultValue={field.value}
                                                        onChange={(paymentType) => onChangeCashBackPayPaymentType(paymentType, item, index)}
                                                    />
                                                )}
                                    />
                                    <Controller
                                        name={`cash_back_pay.${index}.currency.amount`}
                                        control={form.control}
                                        rules={{
                                            required: t(lang, "edi.common.forms.validations.is_required"),
                                        }}
                                        render={({field}) => (
                                            <InputGroup>
                                                <Cleave
                                                    options={{
                                                        numeral: true,
                                                        delimiter: ' ',
                                                        numeralDecimalScale: 3,
                                                    }}
                                                    value={field.value}
                                                    placeholder={"Сумма"}
                                                    className={classNames('form-control', {
                                                        'is-invalid': form?.formState?.errors?.cash_back_pay?.[index]?.currency?.amount.message,
                                                        'is-valid': Object.keys(form?.formState?.errors).length > 0 && !form?.formState?.errors?.cash_back_pay?.currency?.[index]?.amount.message
                                                    })}
                                                    onChange={val => {
                                                        field.onChange(val?.target?.value)
                                                    }}
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    <Controller name={`cash_back_pay.${index}.currency.currency_id`}
                                                control={form.control}
                                                render={({field}) => (
                                                    <SelectCurrencyCode
                                                        defaultValue={field.value}
                                                        onChange={(currencyCode) => {
                                                            onChangeCashBackPayCurrencyId(currencyCode, item, index)
                                                        }}/>
                                                )}
                                    />
                                    {controlledFieldsCashBackPay.length !== 1 && <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon={"trash-alt"}
                                        className={"ms-2"}
                                        iconClassName={"text-secondary"}
                                        onClick={() => onTrashCashBackPay(index)}
                                    />}
                                </Form.Group>
                            ))}
                        </Col>

                        <Col xs={12} lg={6}>
                            <div className={"d-flex justify-content-between mb-1"}>
                                <FormLabel className="fs-1 fw-bold">{t(lang, "Списание задолженности перед клиентом.")}</FormLabel>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    onClick={() => {
                                        addNewRowToCustomerDebtPay()
                                    }}/>
                            </div>
                            {controlledFieldsCustomerDebtPay.map((item, index) => (
                                <Form.Group className="text-start mb-3 d-flex" key={item.id}>
                                    <Controller
                                        name={`customer_debt_pay.${index}.amount`}
                                        control={form.control}
                                        rules={{
                                            required: t(lang, "edi.common.forms.validations.is_required"),
                                        }}
                                        render={({field}) => (
                                            <InputGroup>
                                                <Cleave
                                                    options={{
                                                        numeral: true,
                                                        delimiter: ' ',
                                                        numeralDecimalScale: 3,
                                                    }}
                                                    value={field.value}
                                                    placeholder={"Сумма"}
                                                    className={classNames('form-control', {
                                                        'is-invalid': form?.formState?.errors?.customer_debt_pay?.[index]?.amount.message,
                                                        'is-valid': Object.keys(form?.formState?.errors).length > 0 && !form?.formState?.errors?.customer_debt_pay?.[index]?.amount.message
                                                    })}
                                                    onChange={val => {
                                                        field.onChange(val?.target?.value)
                                                    }}
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    <Controller name={`customer_debt_pay.${index}.currency_id`}
                                                control={form.control}
                                                render={({field}) => (
                                                    <SelectCurrencyCode
                                                        defaultValue={field.value}
                                                        onChange={(currencyCode) => {
                                                            onChangeCustomerDebtPayCurrencyId(currencyCode, item, index)
                                                        }}/>
                                                )}
                                    />
                                    {controlledFieldsCustomerDebtPay.length !== 1 && <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon={"trash-alt"}
                                        className={"ms-2"}
                                        iconClassName={"text-secondary"}
                                        onClick={() => onTrashCustomerDebtPay(index)}
                                    />}
                                </Form.Group>
                            ))}
                        </Col>
                    </>
                }
            </Row>
        </>
    );
};

export default WOWithoutDiscountPaymentForm;