import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm, FormProvider} from 'react-hook-form';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import SelectTemplateComponent from "./SelectTemplateComponent";
import {GetTemplateTypes} from "../../../../../enum/ExcelTemplateTypes";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {
    loadOrganizationTemplatesAsync,
    loadTemplatesAsync,
    selectOrganizationTemplates,
    selectTemplates,
    updateOrganizationTemplatesAsync
} from "../../../../../app/store/reducers/excel/excelReducer";
import {toast} from "react-toastify";

const templateTypesFieldName = 'templateTypes';

const OrganizationTemplates = () => {
    const [loading, setLoading] = useState(false);
    const organizationTemplates = useSelector(selectOrganizationTemplates);
    const allTemplates = useSelector(selectTemplates);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {templateTypes: []}
    });

    const templateTypes = methods.watch(templateTypesFieldName);

    useEffect(() => {
        loadTemplates();
    }, [])

    useEffect(() => {
        if (organizationTemplates.length > 0 && allTemplates.length > 0) {
            updateTemplateTypes();
        }
    }, [organizationTemplates, allTemplates])

    const loadTemplates = () => {
        dispatch(loadTemplatesAsync());
    };
    const loadOrganizationTemplates = () => {
        dispatch(loadOrganizationTemplatesAsync());
    };

    const updateTemplateTypes = () => {
        const types = [];

        for (let i = 0; i < GetTemplateTypes.length; i++) {
            const templateType = GetTemplateTypes[i];

            const values = organizationTemplates.filter(template => template.type === templateType.id);
            const options = allTemplates.filter(template => template.type === templateType.id);

            types.push({
                values: values,
                options: options,
                label: templateType.name,
            })
        }

        methods.setValue(templateTypesFieldName, types);
    }

    const onSubmit = (formData) => {
        setLoading(true);
        let selectedTemplates = [];

        for (let i = 0; i < formData.templateTypes.length; i++) {
            const templateType = formData.templateTypes[i];

            for (let j = 0; j < templateType.values.length; j++) {
                const template = templateType.values[j];

                selectedTemplates.push({
                    id: template.id,
                    type: template.type,
                });
            }
        }

        updateOrganizationTemplatesAsync(selectedTemplates)
            .then(() => toast.success(t(lang, 'main.settings.tab.templates.toast.update_templates.success')))
            .catch(() => toast.error(t(lang, 'main.settings.tab.templates.toast.update_templates.success')))
            .finally(() => {
                loadOrganizationTemplates();
                setLoading(false);
            })
    }

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Row className="g-3">
                        <Col xs={12} className="text-center">
                            <h4 className="text-black">{t(lang, 'main.settings.tab.templates.form.title')}</h4>
                        </Col>

                        {templateTypes.map((type, index) => (
                            <SelectTemplateComponent key={index} index={index} label={type.label} options={type.options} arrayFieldName={templateTypesFieldName}/>
                        ))}

                        <Col xs={12} className="text-end border-top pt-3">
                            <Button type="submit">
                                {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                                {t(lang, 'main.settings.tab.templates.form.submit')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </>
    );
};

export default OrganizationTemplates;
