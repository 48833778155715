import React, {Fragment, useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, Col, Dropdown, Row, Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";

import numeral from "numeral";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {ContractorPaymentTypes, SeparationCurrenciesAndTotalSum} from "../../../../../enum/ContractorWrapper";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import CrmOrderIdentifyItems from "./actions/CrmOrderIdentifyItems";
import OrderExecutorItem from "../../../order/OrderExecutorItem";
import {orderMeasurementOptions} from "../orderWrapper";
import {CrmExecutorOrderStatuses, getOrderStatusText, getOrderStatusVariant} from "../../../../../enum/CrmOrderStatus";
import UploadOrderToExcel from "../excel/UploadOrderToExcel";
import {loadContractorAsync} from "../../../../../app/store/reducers/contractor/contractorReducer";

const ViewOrder = ({order, onChangeStatus}) => {

    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const currency = useSelector(selectCurrency)
    const activeOrganization = useSelector(selectActiveOrganization);
    const [contractor, setContractor] = useState(null)

    const isExecutor = activeOrganization.inn === order?.executor.inn;

    const t = Translate;
    const total = order?.items?.length && currency.length && SeparationCurrenciesAndTotalSum(order.items, currency) || 0

    const priceTotal = order?.items?.length && order.items.reduce((acc, item) => acc + item.price, 0)
    const quantityTotal = order?.items?.length && order.items.reduce((acc, item) => acc + item.quantity, 0)

    useEffect(async () => {
        if (order?.customer?.global_contractor_id) {
            const loadedContractor = await loadContractorAsync(order.customer.global_contractor_id)
            setContractor(loadedContractor)
        } else {
            setContractor(null)
        }
    }, [order]);

    const phoneNumbers = (contractor?.contacts?.filter(x => x.type === 1) || []).map(x => x.value).join('; ')

    return (
        <Fragment>
            <Card className={'p-3'}>
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang, "crm.common.created.time")}: {order?.created_at}</h3>
                </Row>
                <Row className="mt-3">
                    <Col xs={12} lg={6}>
                        {isExecutor && <CrmOrderIdentifyItems className="me-2 d-inline-block" id={order.id}/>}
                        <UploadOrderToExcel hasName className="me-2 d-inline-block" size="sm" id={order?.id} number={order?.info.number} />
                    </Col>
                    <Col xs={12} lg={6} className={'text-end'}>
                        <Dropdown as={ButtonGroup}>
                            <Button
                                variant={getOrderStatusVariant(order?.status)}>Статус: {t(lang, getOrderStatusText(order?.status))}</Button>

                            <Dropdown.Toggle split variant={getOrderStatusVariant(order?.status)}
                                             id="order-status"/>
                            <Dropdown.Menu>
                                {CrmExecutorOrderStatuses.map((status, index) => {
                                    return (
                                        status.value !== order?.status &&
                                        <Dropdown.Item key={index} onClick={() => {
                                            onChangeStatus(status)
                                        }}>{t(lang, status.label)}</Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Card>

            <Card className="mt-3 p-3">
                <Row className="d-flex flex-lg-row flex-column mt-3">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'crm.common.executor')}</h5>
                        <span><b>{t(lang, "crm.common.company")}:</b> {order?.executor?.name}</span>
                        <span><b>{t(lang, 'crm.common.label.inn')}:</b> {order?.executor?.inn}</span>
                    </Col>

                    <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'crm.common.customer')}</h5>
                        <span><b>{t(lang, "crm.common.company")}:</b> {order?.customer?.name}</span>
                        <span><b>{t(lang, 'crm.common.label.inn')}:</b> {order?.customer?.inn}</span>
                        <span><b>{t(lang, 'Номер мобильного телефона')}:</b> {phoneNumbers}</span>
                    </Col>
                </Row>
            </Card>

            <Card className="my-3">
                <Card.Header>
                    <Row className="mt-3">
                        <h5 className="p-0 ms-3"><b className="pe-1">{t(lang, "crm.common.id")}:</b>{order?.id}</h5>
                        <Col className="col-12 d-flex flex-column align-items-center justify-content-center">
                            <h3>{t(lang, 'edi.order.view.title')}</h3>
                            <b>№ {order?.info?.number} от {dayjs(order?.info?.date, "YYYY-MM-DD").format(dateFormat)}</b>
                            <b>{order?.contract?.number && t(lang, "edi.common.to.contract")} № {order?.contract?.number} {order?.contract?.date && `от ${dayjs(order?.contract?.date, "YYYY-MM-DD").format(dateFormat)}`}</b>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={6} className="fs--1 my-3 mb-lg-0">
                            {order?.contract?.number && <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'crm.order.label.contract_number')}:</span>
                                <span className="col mb-1 ps-0">{order?.contract?.number}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>}
                            {order?.contract?.date && <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'crm.order.label.contract_date')}:</span>
                                <span
                                    className="col mb-1 ps-0">{order?.contract?.date && dayjs(order?.contract?.date, 'YYYY-MM-DD').format(dateFormat)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>}
                            {order?.expires_on && <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'crm.order.label.delivery_deadline')}:</span>
                                <span
                                    className="col mb-1 ps-0">{order?.expires_on && dayjs(order?.expires_on, 'YYYY-MM-DD').format(dateFormat)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>}
                            {order?.payment_type !== null && <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'crm.common.payment_type')}:</span>
                                <span
                                    className="col mb-1 ps-0">
                                    {ContractorPaymentTypes.map((paymentType, index) => {
                                        if(paymentType.value === order?.payment_type)
                                            return (
                                                t(lang, paymentType.label)
                                            )
                                    })}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>}
                            {order?.customer?.system_id && <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'crm.order.label.customer.system_id')}:</span>
                                <span className="col mb-1 ps-0">{order?.customer?.system_id}
                                    <hr className={order?.customer?.system_id ? 'm-0' : 'mb-0'}
                                        style={{height: "2px"}}/></span>
                            </div>}
                            {order?.executor?.system_id && <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'crm.order.label.executor.system_id')}:</span>
                                <span className="col mb-1 ps-0">{order?.executor?.system_id}
                                    <hr className={order?.executor?.system_id ? 'm-0' : 'mb-0'}
                                        style={{height: "2px"}}/></span>
                            </div>}
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Table bordered responsive className="border-bottom border-500">
                        <thead className="bg-200">
                        <tr>
                            <th rowSpan={2}>№</th>
                            <th className="text-start">{t(lang, 'crm.common.product')}</th>
                            <th className="text-center">{t(lang, 'crm.common.barcode')}</th>
                            <th className="text-center">{t(lang, 'crm.common.measurement')}</th>
                            <th className="text-center">{t(lang, 'crm.common.code')}</th>
                            <th className="text-center">{t(lang, 'crm.common.quantity')}</th>
                            <th className="text-center">{t(lang, 'crm.common.price')}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                            <th className="p-0 text-center">5</th>
                            <th className="p-0 text-center">6</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order?.items.map((item, index) => {
                            const showExecutorItem = isExecutor && item.executor_item

                            return <tr key={item.id} className="border-500">
                                <td>{index + 1}</td>
                                <td className="text-start text-nowrap fw-semi-bold">
                                    {showExecutorItem && <OrderExecutorItem item={item}/>}
                                    {!showExecutorItem && <span>{item.product}</span>}
                                </td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.barcode}</td>
                                <td className="text-center">{t(lang, orderMeasurementOptions.find(measurement => measurement?.value === item.measurement)?.label) || item?.measurement}</td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.product_code}</td>
                                <td className="text-center">{item.quantity}</td>
                                <td className="text-end">
                                    {numeral.formats[numberFormat].format(item.price?.amount)}
                                    <span className={'ms-2 fw-bolder'}>{currency.find(f=>f.id === item.price?.currency?.global_id)?.name}</span>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, 'crm.common.total')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral?.formats[numberFormat]?.format(total)}
                                        <span className={'ms-2 fw-bolder'}>UZS</span>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {order?.description && <Card className="mt-3 p-3">
                <Row>
                    <Col xs={12} lg={6}>
                        <span><b>{t(lang, "crm.common.items.product.description")}:</b> {order?.description}</span>
                    </Col>
                </Row>
            </Card>}
        </Fragment>
    );
};

export default ViewOrder;
