import IconButton from "../../common/IconButton";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {editContractorAsync} from "../../../app/store/reducers/contractor/contractorReducer";
import ContractorForm from "../crm/contractor/ContractorForm";
import PropTypes from "prop-types";
import {toast} from "react-toastify";


const EditContractor = ({contractor}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;



    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }



    const handleOnSubmit = (formData) => {
        let phones = formData.phones.filter(p => p.value)
        phones = phones.map(phone => {
            if (phone.value)

                return {
                    type: 1,
                    value: '998' + phone.value?.replace(/ /g,'')
                }
        });
        let emails = formData.emails.filter(e => e.value)
        emails = emails.map(email => {
            if (email.value)
                return {
                    type: 3,
                    value: email.value
                }
        });
        const contacts = phones.concat(emails)

        setLoading(true)
        dispatch(editContractorAsync(contractor.id, {
            name: formData.name,
            inn: formData.inn,
            type: formData.type.map(t => +t),
            contacts: contacts,
            is_default: formData.is_default
        })).then(res => {
            toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            handlerClose()
        }).catch(err => {
            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
        }).finally(() => setLoading(false));
    }



    return(
        <>
            <IconButton
                variant="falcon-warning"
                size={"sm"}
                style={{zIndex: 0}}
                className="fs-1 position-relative"
                icon="edit"
                onClick={() => handlerShow()}
                transform="shrink-3">
            </IconButton>

            <Modal show={show} onHide={() => handlerClose()} size="xl">
                <Modal.Header>
                    <Modal.Title>{t(lang, "edi.contractor.edit_contractor_title")}</Modal.Title>
                    <FalconCloseButton onClick={() => handlerClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <ContractorForm contractor={contractor} onClose={() => handlerClose()} loading={loading} onSubmit={handleOnSubmit} isEditing={true}/>
                </Modal.Body>
            </Modal>
        </>
    )
}


EditContractor.propTypes = {
    contractor: PropTypes.object
}

export default EditContractor;
