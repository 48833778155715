import React from 'react';
import {flexRender} from "@tanstack/react-table"
import PropTypes from "prop-types";
import {Spinner, Table} from "react-bootstrap";
import classNames from "classnames";

const DataTable = ({
                       loading,
                       table,
                       columns,
                       tableProps,
                   }) => {
    return (
        <Table {...tableProps}>
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}
                    className={classNames(typeof table.options.meta?.getHeaderRowClassNames === 'function' ? table.options.meta.getHeaderRowClassNames() : "" )}
                    style={typeof table.options.meta?.getHeaderRowStyles === 'function' ? table.options.meta.getHeaderRowStyles() : {}}
                >
                    {headerGroup.headers.map((header) => {
                        return (
                            <React.Fragment key={header.id}>
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </React.Fragment>
                        )
                    })}
                </tr>
            ))}
            </thead>
            <tbody>
            {loading ? (<tr>
                    <td colSpan={columns.length} className="h-24 text-center">
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner size="sm"
                                     variant="primary"
                                     animation="border"
                                     role="switch"
                            />
                            <span className="ms-1">Загрузка...</span>
                        </div>
                    </td>
                </tr>) :
                (
                    <>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <tr key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                    style={typeof table.options.meta?.getCellRowStyles === 'function' ? table.options.meta.getCellRowStyles(row) : {}}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <React.Fragment key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={columns.length} className="h-24 text-center">
                                    Нет данных
                                </td>
                            </tr>
                        )}
                    </>
                )
            }
            </tbody>
            {(!loading && !!table.getRowModel().rows?.length && !!table
                    .getFooterGroups()
                    .map((group) => group.headers.map((header) => header.column.columnDef.footer))
                    .flat()
                    .filter(Boolean)
                    .length) &&
                <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}
                        style={typeof table.options.meta?.getFooterRowStyles === 'function' ? table.options.meta.getFooterRowStyles() : {}}
                    >
                        {footerGroup.headers.map(header => (
                            <React.Fragment key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                            </React.Fragment>
                        ))}
                    </tr>
                ))}
                </tfoot>
            }
        </Table>
    );
};

export default DataTable;

DataTable.propTypes = {
    loading: PropTypes.bool,
    columns: PropTypes.array.isRequired,
}

DataTable.defaultProps = {
    loading: false,
    columns: [],
}