import {useSelector} from "react-redux";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import {useHistory} from "react-router";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import React, {useEffect, useState} from "react";
import {
    getReturnPurchaseItemAsync,
    returnPurchaseApproveAsync, returnPurchasePaymentRegisterAsync, returnPurchasePaymentUpdateAsync
} from "../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/returnPurchaseEvents";
import {Button, Card, Col, Row} from "react-bootstrap";
import WarehouseOperationPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationPayment";
import WarehouseOperationWithoutPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationWithoutPayment";
import SoftBadge from "../../../../components/common/SoftBadge";
import {
    isApprovedCheckedWarehouseOperation
} from "../../../../components/hippo/warehouse-operation/enum/warehouseOperationWrapper";
import ReturnPurchaseExcelForm
    from "../../../../components/hippo/warehouse-operation/return-purchase/forms/ReturnPurchaseExcelForm";
import {Link} from "react-router-dom";
import WOWithoutDiscountPayment
    from "../../../../components/hippo/warehouse-operation/components/WOWithoutDiscountPayment";


const AddReturnPurchaseExcel = () => {
    const [returnPurchase, setReturnPurchase] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate


    const loadReturnPurchase = (id) => {
        getReturnPurchaseItemAsync({returnPurchaseId: id})
            .then((response) => setReturnPurchase(response.data))
            .catch((error) => setReturnPurchase(null))
    }

    useEffect(() => {
        const onChangeContractorHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })
        
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })
        
        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })
        
        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })
        
        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })
        
        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS, onChangeContractorHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
        }
    }, [])

    const onSubmitPay = async (pay) => {
        if (returnPurchase?.payment) {
            await returnPurchasePaymentUpdateAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {
                })
        }
        if (!returnPurchase?.payment && returnPurchase?.is_approved) {
            await returnPurchasePaymentRegisterAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {
                })
        }
        if (!returnPurchase?.is_approved) {
            await returnPurchaseApproveAsync({returnPurchaseId: pay?.id, payment: pay?.payment || null})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {
                })
        }
    }


    if (warehouses?.length > 0)
        return (
            <Card>
                <Card.Body>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <h5>{t(lang, "warehouse.operation.item.common.new_return.purchase")}</h5>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            {returnPurchase?.id &&
                                <div className="me-3">
                                    {/*<WarehouseOperationPayment onSubmitPay={onSubmitPay} data={returnPurchase} />*/}
                                    <WOWithoutDiscountPayment
                                        data={returnPurchase}
                                        isEditing={false}
                                        onSubmitPay={onSubmitPay}
                                    />
                                    {!returnPurchase?.is_approved &&
                                        <WarehouseOperationWithoutPayment onClick={onSubmitPay} id={returnPurchase?.id}/>
                                    }
                                    <Button
                                        variant="falcon-info"
                                        title={t(lang, "warehouse.operation.item.common.button.save_draft")}
                                        size="sm"
                                        className="me-1"
                                        as={Link}
                                        to={`./view/${returnPurchase?.id}`}
                                    >{t(lang, "warehouse.operation.item.common.button.save_draft")}</Button>
                                </div>
                            }
                            <div>
                                <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                <SoftBadge bg={returnPurchase?.is_approved ? 'success' : 'warning'}>
                                    {t(lang, isApprovedCheckedWarehouseOperation(!!returnPurchase?.is_approved))}
                                </SoftBadge>
                            </div>
                        </Col>
                    </Row>
                    <ReturnPurchaseExcelForm />
                </Card.Body>
            </Card>
        )

    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )
}

export default AddReturnPurchaseExcel;