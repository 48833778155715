import Select from "react-select";
import {BulkPriceCommon} from "../enum/warehouseOperationWrapper";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useEffect, useState} from "react";


const BulkPriceSelectCommon = ({onChange, defaultValue, ...selectProps}) => {
    const [value, setValue] = useState(true)
    const lang = useSelector(selectLang)
    const t = Translate;

    useEffect(() => {
        if (defaultValue !== null) {
            const val = BulkPriceCommon.find(bulkPrice => bulkPrice.value === defaultValue)
            setValue(val)
        } else {
            setValue(true)
        }
    },[defaultValue])


    const onChangeHandler = (option) => {
        onChange(option?.value)
        setValue(option?.value)
    }

    return(
        <>
            <Select
                value={value}
                options={BulkPriceCommon}
                onChange={(option) => onChangeHandler(option)}
                isSearchable={false}
                hideSelectedOptions
                menuPlacement="auto"
                getOptionLabel={option => t(lang, option.label)}
                getOptionValue={option => option.value}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: '0',
                        backgroundColor: '#0d6efd',
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: '#fff',
                        maxWidth: "130px",
                        minWidth: "60px"
                    }),
                    menu: (provided) => ({
                        ...provided,
                        marginTop: 0,
                        boxShadow: 'none',
                        borderRadius: '0',
                    }),
                }}
                {...selectProps}
            />
        </>
    )
}


export default BulkPriceSelectCommon;