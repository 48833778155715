import {Form, Modal, Row} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import {useSelector} from "react-redux";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import AddUserStepOne from "./AddUserStepOne";
import {AddUserAsync, getRegistrationVerifyConfirmationTicketAsync} from "../../../../../app/store/reducers/auth/authReducer";
import AddUserStepTwo from "./AddUserStepTwo";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const AddUser = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [isShow, setIsShow] = useState(false)
    const [step, setStep] = useState(1);
    const [ticket, setTicket] = useState(null);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState('');


    const methods = useForm({
        defaultValues: {
            inn: '',
            name: '',
            username: '',
            password: '',
            confirmCode: '',
        },
    });

    const handleShow = () => setIsShow(true);
    const handleClose = () => {
        methods.reset();
        setIsShow(false);
        setStep(1);
    };


    const onSubmit = formData => {
        const username = formData.username.split(' ').join('')

        switch (step) {
            case 1:
                getRegistrationVerifyConfirmationTicketAsync(username)
                    .then(ticket => {
                        setTicket(ticket);
                        setStep(step + 1);
                    })
                break;

            case 2:
                AddUserAsync({
                    username: username,
                    password: formData.password,
                    name: formData.name,
                    confirmCode: formData.confirmCode,
                    confirmTicket: ticket,
                })
                    .then((res) => {
                        methods.reset();
                        handleClose();
                        toast.success(t(lang, "edi.common.users_organization_success"))
                    })
                    .catch(({invalidConfirmationCodeUserAdd}) => {
                        if (invalidConfirmationCodeUserAdd) {
                            setInvalidConfirmCode(formData.confirmCode);
                            toast.error(t(lang, "authentication.forgot-password.confirmation_code.is_invalid"))
                        } else {
                            toast.error(t(lang, "edi.common.user_organization_error"))
                        }
                    })
                break;
        }
    };


    return (
        <>
            <Row className="flex-between-center">
                <div id="orders-actions" className="d-flex align-items-center justify-content-between">
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        onClick={() => handleShow()}
                    >
                        <span className="d-none d-sm-inline-block ms-1">{t(lang, "edi.branch.datatable.header.row.navigation.create")}</span>
                    </IconButton>
                    <Modal show={isShow} size="md">
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Modal.Header>
                                    <Modal.Title>{t(lang, "edi.common.user_organization_new")}</Modal.Title>
                                    <FalconCloseButton onClick={handleClose}/>
                                </Modal.Header>
                                <Modal.Body>
                                    {step === 1 && <AddUserStepOne close={setIsShow}/>}
                                    {step === 2 && <AddUserStepTwo invalidConfirmCode={invalidConfirmCode}/>}
                                </Modal.Body>
                            </Form>
                        </FormProvider>
                    </Modal>
                </div>
            </Row>
        </>
    )
}


export default AddUser;
