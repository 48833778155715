import IconButton from "../../../../../common/IconButton";
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const ContractorOrderDataTableHeader = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.order.datatable.header.title")}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <div id="orders-actions">
                    <Link to="/edi/order/add">
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >
                                <span
                                    className="d-none d-sm-inline-block ms-1">{t(lang, "edi.order.datatable.header.navigation.create")}</span>
                        </IconButton>
                    </Link>
                </div>
            </Col>
        </Row>
    );
};

ContractorOrderDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ContractorOrderDataTableHeader;
