import {Accordion, Card, Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useFormContext, Controller} from "react-hook-form";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";


const ContractFormationParts = ({ fieldsPart, update, document }) => {
    const {watch, control} = useFormContext()
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateDayJSFormat);
    const [documentParts, setDocumentParts] = useState(null)


    const specialDynamicPlaceholders = {
        contractNumber: '{{contract.number}}',
        contractDate: '{{contract.date}}',
        contractExpireDate: '{{contract.expireDate}}',
        executorCompanyName: '{{executor.companyName}}',
        executorDirector: '{{executor.director}}',
        contractTotalCost: '{{contract.totalCost}}',
        contractBasedOn: '{{contract.basedOn}}'
    }


    const contractNumber = watch(`contractInfo.number`)
    const contractDate = watch(`contractInfo.date`)
    const contractExpireDate = watch(`contractInfo.expireDate`)
    const executorCompanyName = watch(`executor.name`)
    const executorDirector = watch(`executor.fio`)
    const watchProducts = watch('products')
    const totalDeliverySumWithVat = watch('contractInfo.total_words')
    const formFields = watch('form_fields')

    const totalDeliverySum = watchProducts.reduce((total, product) => total + +product.deliverySum, 0)

    useEffect(() =>{
        ContractPart()
        setDocumentParts(document?.parts)

    },[contractNumber, contractDate, contractExpireDate, executorCompanyName, executorDirector, totalDeliverySum, watchProducts, ...formFields.map(f => f.value)])



    const regexReplaceAll = (text) => {
        return new RegExp(text, 'g')
    }


    const ContractPart = () => {
        documentParts?.map((fil, index) => {
            let item = {
                    id: fil.id,
                    order: index + 1,
                    title: fil?.title?.replace(regexReplaceAll(specialDynamicPlaceholders.contractNumber), contractNumber)
                        .replace(regexReplaceAll(specialDynamicPlaceholders.contractDate), dayjs(contractDate, 'YYYY-MM-DD').format(dateFormat))
                        .replace(regexReplaceAll(specialDynamicPlaceholders.contractExpireDate), dayjs(contractExpireDate, 'YYYY-MM-DD').format(dateFormat))
                        .replace(regexReplaceAll(specialDynamicPlaceholders.executorCompanyName), executorCompanyName)
                        .replace(regexReplaceAll(specialDynamicPlaceholders.executorDirector), executorDirector)
                        .replace(regexReplaceAll(specialDynamicPlaceholders.contractTotalCost), `${totalDeliverySum} (${totalDeliverySumWithVat})`)
                        .replace(regexReplaceAll(specialDynamicPlaceholders.contractBasedOn), 'Устава'),
                    body: fil?.body?.replace(regexReplaceAll(specialDynamicPlaceholders.contractNumber), contractNumber)
                        .replace(regexReplaceAll(specialDynamicPlaceholders.contractDate), dayjs(contractDate, 'YYYY-MM-DD').format(dateFormat))
                        .replace(regexReplaceAll(specialDynamicPlaceholders.contractExpireDate), dayjs(contractExpireDate, 'YYYY-MM-DD').format(dateFormat))
                        .replace(regexReplaceAll(specialDynamicPlaceholders.executorCompanyName), executorCompanyName)
                        .replace(regexReplaceAll(specialDynamicPlaceholders.executorDirector), executorDirector)
                        .replace(regexReplaceAll(specialDynamicPlaceholders.contractTotalCost), totalDeliverySumWithVat)
                        .replace(regexReplaceAll(specialDynamicPlaceholders.contractBasedOn), 'Устава'),
                };

            formFields.forEach(formField => {
                if(formField.value) {
                    if(formField.type === 'datalist') {
                        let value = formField.options.find(option => option.value === formField.value)
                        item.title = item.title.replace(regexReplaceAll(formField.title), value.title)
                        item.body = item.body.replace(regexReplaceAll(formField.title), value.title)
                    } else {
                        item.title = item.title.replace(regexReplaceAll(formField.title), formField.value)
                        item.body = item.body.replace(regexReplaceAll(formField.title), formField.value)
                    }
                }
            })
            update(index, item);
        })
    }



    return(
        <Card>
            <Accordion alwaysOpen={false} defaultActiveKey={fieldsPart.map(p => p.id)}>
                <Accordion.Item eventKey={fieldsPart.id} key={fieldsPart.id}>
                    <Accordion.Header>
                        <Card.Header>
                            <Row>
                                <Col><h5 className="mb-0">{t(lang, "roaming.contracts.add.parts.title")}</h5></Col>
                            </Row>
                        </Card.Header>
                    </Accordion.Header>

                    <Accordion.Body>
                        <Card.Body>
                            {fieldsPart?.map((part, index) => (
                                <Row className={`my-4 flex-column border-bottom-2 border shadow p-3 mb-5 bg-white rounded`} key={part.id}>
                                    <span className="text-primary d-flex">№ {index + 1}</span>
                                    <Col className="d-flex">
                                        <div className="w-100">

                                            <Controller
                                                name={`parts.${index}.title`}
                                                control={control}
                                                render={({field}) => (
                                                    <Form.Control
                                                        disabled={true}
                                                        value={part.title}
                                                        placeholder={t(lang, "roaming.contracts.add.parts.name:placeholder")}
                                                        type="text"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>

                                    <Col className="my-3">
                                        <Controller
                                            name={`parts.${index}.body`}
                                            control={control}
                                            render={({field}) => (
                                                <Form.Control
                                                    value={part.body}
                                                    disabled
                                                    placeholder={t(lang, "roaming.contracts.add.parts.body:placeholder")}
                                                    as="textarea"
                                                    rows={8}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Card>
    )
}

export default ContractFormationParts;