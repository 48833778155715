import React, {useEffect} from 'react';
import {Button, Card, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import dayjs from 'dayjs';
import ReturnShipmentDataTableHeader from './ReturnShipmentDataTableHeader';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {
    changeFilterOptionsAsync,
    selectReturnShipments,
    selectCountReturnShipments,
    selectFilterOptions,
    loadReturnShipments
} from '../../../app/store/reducers/return-shipment/returnShipmentReducer';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import AdvanceTableCustomPagination from '../../common/advance-table/AdvanceTableCustomPagination';
import {
    getStatusColorReturnShipment,
    GetStatusTextReturnShipment,
} from '../../../enum/ReturnShipmentStatus';
import { selectActiveOrganization } from '../../../app/store/reducers/auth/authReducer';
import {
    selectLang,
    Translate,
    selectDateDayJSFormat,
    selectNumberFormat
} from "../../../app/store/reducers/main/mainReducer"
import EventBus from "../../../app/eventbus/EventBus";
import {DELETE_RETURN_SHIPMENT_SUCCESS} from "../../../app/eventbus/returnShipmentEvents";
import numeral from "numeral";

const ReturnShipments = ({orderLinkDisabled, activeColumns}) => {
    const dispatch = useDispatch()
    const returnShipments = useSelector(selectReturnShipments)
    const count = useSelector(selectCountReturnShipments)
    const filters = useSelector(selectFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const t = Translate;

    useEffect(() => {
        const onReturnShipmentDeletedHandler = EventBus.on(DELETE_RETURN_SHIPMENT_SUCCESS, () => {
            dispatch(loadReturnShipments({...filters, viewer_inn: activeOrganization.inn}))
        })

        return () => {
            EventBus.remove(DELETE_RETURN_SHIPMENT_SUCCESS, onReturnShipmentDeletedHandler);
        }
    }, [])

    const totalSum = returnShipments.reduce((totSum, total) => totSum + total.total_info.total, 0)


    function getActiveColumns(activeColumns, columns = []) {
        return columns?.filter(column => activeColumns[column?.id])
    }

    const columns = getActiveColumns(activeColumns, [
        {
            Header: "#",
            id: "index",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
              return `${((filters.page-1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'return_shipment_info.number',
            id: "number",
            Header: t(lang, "edi.return_shipment.datatable.header.row.number"),
            cellProps: {
                width: '80px',
                className: 'p-0'
            },
            totalInfo: `${t(lang,"edi.common.datatable.total")}:`,
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            Cell: ({row: {original}}) => {

                return <div className="d-grid gap-2">
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {t(lang, GetStatusTextReturnShipment(original.status))}
                            </Tooltip>
                        }
                        placement="right"
                    >
                        <div className="d-grid gap-2">
                            <Button variant="primary"  style={{backgroundColor: getStatusColorReturnShipment(original.status)}} as={Link} to={`/edi/return-shipment/${original.id}`}>
                                {original.return_shipment_info.number}
                            </Button>
                        </div>
                    </OverlayTrigger>
                </div>
            }
        },
        {
            accessor: 'return_shipment_info.date',
            id: "date",
            Header: t(lang, "edi.return_shipment.datatable.header.row.date"),
            cellProps: {
                width: '80px',
            },
            Cell: ({row : {original}}) => {
                return <b>{dayjs(original.return_shipment_info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'total_info.total',
            id: "total_sum",
            Header: t(lang, "edi.return_shipment.datatable.header.row.total_sum"),
            cellProps: {
                width: '50px',
                className: 'text-end'
            },
            totalInfo: numeral.formats[numberFormat].format(totalSum),
            totalInfoClassName: "fw-bold text-nowrap",
            Cell: rowData => {
                const {original} = rowData.row

                return<>
                    {numeral.formats[numberFormat].format(original.total_info.total)}
                </>
            }
        },
        {
            accessor: 'return_order.branch.name',
            id: "branch",
            Header: t(lang, "edi.return_invoice.datatable.header.row.branch"),
            cellProps: {
                className: 'fw-bold',
            },
        },
        {
            accessor: 'contract_info',
            id: "contractor",
            Header: t(lang, "edi.return_shipment.datatable.header.row.contractor"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer_info.inn

                if (isCustomer) {
                    return <>
                            {original.executor_info.name}
                        </>
                }

                return <>
                        {original.customer_info.name}
                    </>
            }
        },
    ]);

    const onPageChange = async (page) => dispatch(changeFilterOptionsAsync({page: page}));
    const onLimitChange = async (limit) => dispatch(changeFilterOptionsAsync({limit: limit, page: 1}));


    return (
        <AdvanceTableWrapper
            columns={columns}
            data={returnShipments}
            pagination
            perPage={1000}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ReturnShipmentDataTableHeader table/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                        rowsPerPageOptions={[10, 25, 50, 75, 100, 300]}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default ReturnShipments;
