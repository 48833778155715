import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useFieldArray, useWatch} from "react-hook-form";
import {Card, Col, Form, Row, Table} from "react-bootstrap";
import IconButton from "../../../../../common/IconButton";
import ItemRowV2 from "./ItemRowV2";
import AddWaybillV2ItemFromExcelModal from "./AddWaybillV2ItemFromExcelModal";
import numeral from "numeral";
import {getNumberValue} from "../../../../../../helpers/utils";

const WaybillItemsV2Form = ({isEditing, content}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const products = useWatch({name: 'items', exeact: true})
    const {fields, append, remove, replace} = useFieldArray({name: 'items'});
    const allowDeleteRow = fields.length > 1;
    const [reversedCalculation, setReversedCalculation] = useState(false);

    const addNewItem = useCallback(() => {
        append({
            name: '',
            catalog_code: '',
            catalog_name: '',
            measurement: '',
            price: 0,
            quantity: 0,
            total: 0,
        });
    }, [append])

    const addItemsInBulk = useCallback((items) => {
        if (!items?.length) {
            return false;
        }

        // reset waybill items
        replace([]);

        for (let i = 0; i < items?.length; i++) {
            const item = items[i];

            const itemPrice = item?.price ? Number(item.price.replace(/\s/g, '')?.replace(/,/g, '.')) : 0;
            const itemQuantity = item?.quantity ? Number(item.quantity.replace(/\s/g, '')?.replace(/,/g, '.')) : 0;
            const itemTotal = itemPrice * itemQuantity;

            const newItem = {
                name: item?.name || '',
                catalog_code: item?.catalogCode || '',
                catalog_name: item?.catalogName || '',
                package_code: item?.packageCode || '',
                measurement: item?.measurement || '',
                price: itemPrice || 0,
                quantity: itemQuantity || 0,
                total: itemTotal || 0,
            };
            append(newItem);
        }
    },[replace, append]);

    const totalAmount = useMemo(() => {
        return numeral.formats[numberFormat].format(
            products.reduce((sum, item) => sum + getNumberValue(+item.total), 0)
        );
    }, [products]);

    const refs = useRef([]);

    useEffect(() => {
        refs.current = fields.map(() => ({
            name: React.createRef(),
            catalog_code: React.createRef(),
            package_code: React.createRef(),
            quantity: React.createRef(),
            price: React.createRef(),
            total: React.createRef(),
        }));
    }, [fields]);


    const handleKeyDown = (e, rowIndex, fieldName, isSelect) => {
        if (e.ctrlKey) {
            let nextRow = rowIndex;
            let nextField = fieldName;

            switch (e.key) {
                case 'ArrowUp':
                    nextRow = rowIndex > 0 ? rowIndex - 1 : rowIndex;
                    break;
                case 'ArrowDown':
                    nextRow = rowIndex < fields.length - 1 ? rowIndex + 1 : rowIndex;
                    break;
                default:
                    break;
            }

            e.preventDefault();
            if (isSelect) {
                refs.current[nextRow][nextField]?.current?.focus();
            } else {
                refs.current[nextRow][nextField]?.current?.childNodes[0]?.focus();

            }
        }
    };

    return (
        <Card className="mt-3">
            <Card.Header className="bg-200">
                <Row className="align-items-end g-2">
                    <Col>
                        <h5 className="mb-0">{t(lang, 'roaming.waybill.send.shipment_items_form.title')}</h5>
                    </Col>
                    <Col xs="auto">
                        <AddWaybillV2ItemFromExcelModal className={'me-2'} onSubmitItems={addItemsInBulk}/>
                        <IconButton
                            variant="falcon-default" size="sm" icon="plus" transform="shrink-3" className="me-2"
                            onClick={addNewItem}>
                            <span className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.common.add")}</span>
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <div className="d-flex flex-wrap justify-content-end pb-0">
                    <Form.Switch label={t(lang, 'roaming.invoice.reversed_calculation')}
                                 defaultChecked={reversedCalculation}
                                 onChange={(e) => setReversedCalculation(e.target.checked)}
                    />
                </div>
                <Table className="fs--1" responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.product')}<span
                            className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.catalog_code')}<span
                            className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.measurement')}<span
                            className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.quantity')}<span
                            className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.price')}<span
                            className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.total')}</th>
                        <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <ItemRowV2
                            key={item.id}
                            index={index}
                            remove={remove}
                            allowDelete={allowDeleteRow}
                            isEditing={isEditing}
                            content={content}
                            reversedCalculation={reversedCalculation}
                            refs={refs.current[index]}
                            handleKeyDown={handleKeyDown}
                        />
                    ))}
                    </tbody>
                </Table>
                <div className={'d-flex justify-content-end gap-2'}>
                    <strong className="text-900">{t(lang, 'roaming.common.items.total')}:</strong>
                    <span>{totalAmount}</span>
                </div>
            </Card.Body>
        </Card>
    );
};

export default WaybillItemsV2Form;
