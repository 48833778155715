import React, {useEffect, useState} from 'react';
import {Badge, Card, Col, Row, Table} from "react-bootstrap";
import dayjs from "dayjs";
import numeral from "numeral";
import IconButton from "../../common/IconButton";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate, updateShowInProgressAsync
} from "../../../app/store/reducers/main/mainReducer";
import invoiceWrapper, {
    getStatusColorInvoice,
    GetStatusTextInvoice,
    InvoiceCreated,
    InvoiceSend
} from "../../../enum/InvoiceStatus";
import {declineInvoiceAsync, cancelInvoiceAsync, signInvoiceAsync} from "../../../app/store/reducers/invoice/invoiceReducer";
import {faSignature} from '@fortawesome/free-solid-svg-icons'
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import CancelInvoice from './CancelInvoice';
import DeclineInvoice from './DeclineInvoice';
import InvoiceDelete from "./InvoiceDelete";
import InvoicePrint from "./InvoicePrint";
import SelectCertificateWrapperComponent from "../roaming/components/SelectCertificateWrapperComponent";
import {
    AfterConfirmByCustomer,
} from "../../../app/order/OrderExecutorCreateInvoiceStrategyType";
import WarningAlert from "../roaming/components/WarningAlert";
import EventBus from "../../../app/eventbus/EventBus";
import UploadInvoiceToExcel from "./excel/UploadInvoiceToExcel";
import {CANCEL_INVOICE_FAILED, DECLINE_INVOICE_FAILED, SIGN_INVOICE_FAILED} from "../../../app/eventbus/invoiceEvents";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {
    PermissionEdiInvoiceApprove,
    PermissionEdiInvoiceReject,
    PermissionEdiShipmentView
} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";


const Invoice = ({invoice}) => {
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const subtotal = invoice.items.reduce((acc, item) => acc + item.total, 0)
    const tax = invoice.items.reduce((acc, item) => acc + item.nds_value, 0);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);

    const [groupedPrivileges, setGroupedPrivileges] = useState([])
    const [eventMessage, setEventMessage] = useState(null);
    const [showSignButton, setShowSignButton] = useState(false)
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [showDeclineButton, setShowDeclineButton] = useState(false)

    const [allowDelete, setAllowDelete] = useState(false)

    const onSignButtonClicked = () => {
        dispatch(updateShowInProgressAsync(true));
        signInvoiceAsync(invoice, currentCertificate)
            .finally(() => {
                dispatch(updateShowInProgressAsync(false));
            })
    }

    const onCancelButtonClicked = ({comment}) => {
        dispatch(updateShowInProgressAsync(true));
        cancelInvoiceAsync(invoice, comment, currentCertificate)
            .finally(() => {
                dispatch(updateShowInProgressAsync(false));
            })
    }

    const onDeclineButtonClicked = ({comment}) => {
        dispatch(updateShowInProgressAsync(true));
        declineInvoiceAsync(invoice, comment, currentCertificate)
            .finally(() => {
                dispatch(updateShowInProgressAsync(false));
            })
    }

    useEffect(() => {
        if (activeOrganization.inn === invoice.executor.inn && invoice.status === InvoiceCreated)
            setShowSignButton(true)
        else if (activeOrganization.inn === invoice.customer.inn && invoice.status === InvoiceSend)
            setShowSignButton(true)
        else
            setShowSignButton(false)

        if (
            activeOrganization.inn === invoice.executor.inn &&
            invoice.status === InvoiceSend &&
            invoice.shipment.order.executor_create_invoice_strategy !== AfterConfirmByCustomer.id
        )
            setShowCancelButton(true)
        else
            setShowCancelButton(false)
        if (
            activeOrganization.inn === invoice.customer.inn &&
            invoice.status === InvoiceSend &&
            invoice.shipment.order.executor_create_invoice_strategy !== AfterConfirmByCustomer.id
        )
            setShowDeclineButton(true)
        else
            setShowDeclineButton(false)
    }, [activeOrganization, invoice])

    useEffect(() => {
        setAllowDelete(activeOrganization.inn === invoice.executor.inn && invoice.status === InvoiceCreated)
        setGroupedPrivileges(getTotalSumPrivileges(invoice.items))
    }, [invoice])

    useEffect(() => {
        const onSignFailedHandler = EventBus.on(SIGN_INVOICE_FAILED, (error) => {
            setEventMessage({
                variant: "danger",
                title: t(lang, "edi.alerts.return_invoice.total_sum_error_title"),
                text: t(lang, error.message)
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        })

        const onCancelFailedHandler = EventBus.on(CANCEL_INVOICE_FAILED, (error) => {
            setEventMessage({
                variant: "danger",
                title: t(lang, "edi.alerts.return_invoice.total_sum_error_title"),
                text: error.message
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        })

        const onDeclineFailedHandler = EventBus.on(DECLINE_INVOICE_FAILED, (error) => {
            setEventMessage({
                variant: "danger",
                title: t(lang, "edi.alerts.return_invoice.total_sum_error_title"),
                text: error.message
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        })

        return () => {
            EventBus.remove(SIGN_INVOICE_FAILED, onSignFailedHandler);
            EventBus.remove(CANCEL_INVOICE_FAILED, onCancelFailedHandler);
            EventBus.remove(DECLINE_INVOICE_FAILED, onDeclineFailedHandler);
        }
    }, [])


    const getTotalSumPrivileges = (invoiceItems) => {
        const groupByPrivilegesId = {}

        invoiceItems.forEach(invoiceItem => {
            if (invoiceItem?.benefit in groupByPrivilegesId) {
                groupByPrivilegesId[invoiceItem.benefit].benefit_vat_sum += invoiceItem.benefit_vat_sum
                groupByPrivilegesId[invoiceItem.benefit].benefit_vat_total_sum += invoiceItem.total
            } else {
                if (invoiceItem?.benefit) {
                    groupByPrivilegesId[invoiceItem.benefit] = {
                        id: invoiceItem.benefit,
                        benefit_name: invoiceItem.benefit_name,
                        benefit_type: invoiceItem.benefit_type,
                        benefit_vat_sum: invoiceItem.benefit_vat_sum,
                        benefit_vat_total_sum: invoiceItem.total,
                    }
                }
            }
        })

        return Object.values(groupByPrivilegesId)
    }

    return (
        <>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}


            <Card className="my-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang, "edi.common.created.time")}: {invoice.created_at}</h3>
                    <Col className="text-end">
                        <span className="me-2">{t(lang, 'edi.common.label.status')}: </span>
                        <span className={`p-1 rounded-pill text-white fs--2`}
                              style={{backgroundColor: getStatusColorInvoice(invoice.status)}}>{t(lang, GetStatusTextInvoice(invoice.status))}</span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {showSignButton &&
                            <SelectCertificateWrapperComponent
                                submitButton={
                                    <IconButton
                                        variant="falcon-primary"
                                        size="sm"
                                        icon={faSignature}
                                        iconClassName="me-1"
                                        className="ms-2 mb-2 mb-sm-0"
                                        id="signButton"
                                        onClick={onSignButtonClicked}
                                    >{t(lang, 'edi.common.button.sign')}</IconButton>
                                }
                                openDialogButtonText={t(lang, 'edi.common.button.sign')}
                                permission={PermissionEdiInvoiceApprove}
                            />
                        }

                        {!showCancelButton &&
                            <SelectCertificateWrapperComponent
                                submitButton={
                                    <CancelInvoice
                                        className="ms-2 mb-2 mb-sm-0"
                                        cancel={onCancelButtonClicked}
                                    />
                                }
                                openDialogButtonText={t(lang, 'edi.common.cancel_decline')}
                                permission={PermissionEdiInvoiceReject}
                            />

                        }

                        {showDeclineButton &&
                            <SelectCertificateWrapperComponent
                                submitButton={
                                    <DeclineInvoice
                                        className="ms-2 mb-2 mb-sm-0"
                                        cancelInvoiceHandler={onDeclineButtonClicked}
                                    />
                                }
                                openDialogButtonText={t(lang, 'edi.common.button.decline')}
                                permission={PermissionEdiInvoiceReject}
                            />
                        }

                        {allowDelete &&
                            <InvoiceDelete
                                className="ms-2 mb-2 mb-sm-0"
                                id={invoice.id}
                            />
                        }

                        <IconButton
                            as={dispatch(checkPermission(PermissionEdiShipmentView)) ? Link : 'div'}
                            to={`../shipment/${invoice.shipment.id}`}
                            onClick={()=>!dispatch(checkPermission(PermissionEdiShipmentView)) && dispatch(toastPermission())}
                            size="sm"
                            className="mx-2"
                            transform="shrink-3"
                            variant="falcon-default"
                            icon="external-link-alt">
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, 'edi.invoice.open_shipment')}</span>
                        </IconButton>

                        <InvoicePrint size="sm" variant="falcon-default" className="mx-2" id={invoice.id}/>
                        <UploadInvoiceToExcel className="mx-2" variant="falcon-default" size="sm" id={invoice.id}
                                              number={invoice.invoice_info.number}/>
                    </Col>
                </Row>
            </Card>

            <Card className="p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.executor')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {invoice.executor.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {invoice.executor.inn}</span>
                    </Col>

                    <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.customer')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {invoice.customer.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {invoice.customer.inn}</span>
                    </Col>

                    <Col xs={12} lg={2} className={'d-flex justify-content-end'}>
                        {
                            invoice?.is_customer_system_informed && <div>
                                <Badge pill bg={'info'} className={'fs--1 ms-2'}>
                                    {t(lang, 'edi.shipment.datatable.header.row.is_customer_system_informed')}
                                </Badge>
                            </div>
                        }
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card className="mt-3">
                <Card.Header>
                    <h5 className="p-0"><b className="pe-1">{t(lang, "edi.common.id")}:</b>{invoice.id}</h5>

                    <Row className="my-3">
                        <Col className="d-flex flex-column align-items-center justify-content-center">
                            <h3>{t(lang, 'edi.common.invoice')}</h3>
                            <b>№ {invoice.invoice_info.number} от {dayjs(invoice.invoice_info.date, "YYYY-MM-DD").format(dateFormat)}</b>
                            <b>{t(lang, "edi.common.to.contract")} № {invoice.contract_info.number} от {dayjs(invoice.contract_info.date, "YYYY-MM-DD").format(dateFormat)}</b>
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-5 flex-column flex-lg-row">
                        <Col xs={12} lg={6} className="align-items-center">
                            <div className="d-flex justify-content-between">
                                <b className="col-6">{t(lang, "edi.common.executor")}:</b>
                                <span className="col-6">{invoice.executor.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.executor.inn")}</span>
                                <span className="col-6">{invoice.executor.inn} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.address")}:</span>
                                <span className="col-6">{invoice.executor.address} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.label.nds_code")}:</span>
                                <span className="col-6">{invoice.executor.nds_registration_code}<hr className={invoice.executor.nds_registration_code ? 'm-0' : 'mb-0'} style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.label.account")}:</span>
                                <span className="col-6">{invoice.executor.bank_info.account} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.label.mfo")}:</span>
                                <span className="col-6">{invoice.executor.bank_info.mfo} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="align-items-center mt-3">
                            <div className="d-flex justify-content-between">
                                <b className="col-6">{t(lang,"edi.common.buyer")}:</b>
                                <span className="col-6">{invoice.customer.name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.label.address")}:</span>
                                <span className="col-6">{invoice.customer.address} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.buyer.inn")}:</span>
                                <span className="col-6">{invoice.customer.inn} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.label.nds_code")}:</span>
                                <span className="col-6">{invoice.customer.nds_registration_code} <hr className={invoice.customer.nds_registration_code ? 'm-0' : 'mb-0'} style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.label.account")}:</span>
                                <span className="col-6">{invoice.customer.bank_info.account} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang,"edi.common.label.mfo")}:</span>
                                <span className="col-6">{invoice.customer.bank_info.mfo} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                    </Row>

                    <Table bordered responsive className="mt-3 border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th rowSpan={2}>№</th>
                            <th className="text-center">{t(lang, 'edi.common.product')}</th>
                            <th className="text-center">{t(lang, 'edi.common.barcode')}</th>
                            <th className="text-center">{t(lang, 'edi.common.measurement')}</th>
                            <th className="text-center">{t(lang, 'edi.common.quantity')}</th>
                            <th className="text-center">{t(lang, 'edi.common.price')}</th>
                            <th className="text-center">{t(lang, 'edi.common.total')}</th>
                            <th className="text-center">{t(lang, 'edi.common.nds_rate')}</th>
                            <th className="text-center">{t(lang, 'edi.common.nds_value')}</th>
                            <th className="text-center">{t(lang, 'edi.common.total_with_nds')}</th>
                            <th className="text-center">{t(lang, 'edi.common.origin')}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                            <th className="p-0 text-center">5</th>
                            <th className="p-0 text-center">6</th>
                            <th className="p-0 text-center">7</th>
                            <th className="p-0 text-center">8</th>
                            <th className="p-0 text-center">9</th>
                            <th className="p-0 text-center">10</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.items.map((item, index) => (
                            <tr key={item.id} className="border-500">
                                <td>{index + 1}</td>
                                <td className="align-middle text-nowrap fw-semi-bold">{item.product}</td>
                                <td className="align-middle text-nowrap fw-semi-bold">{item.barcode}</td>
                                <td className="align-middle text-center">{item.measurement}</td>
                                <td className="align-middle text-center">{item.quantity}</td>
                                <td className="align-middle text-center">{numeral.formats[numberFormat].format(item.price)}</td>
                                <td className="align-middle text-center">{numeral.formats[numberFormat].format(item.total)}</td>
                                <td className="align-middle text-center">{item.nds_rate}</td>
                                <td className="align-middle text-center">{numeral.formats[numberFormat].format(item.nds_value)}</td>
                                <td className="align-middle text-center">{numeral.formats[numberFormat].format(item.total_with_nds)}</td>
                                <td className="align-middle text-center">{t(lang, invoiceWrapper.getItemOriginText(item.origin))}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, 'edi.common.total')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(subtotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900">{t(lang, 'edi.common.nds_value')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(tax)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, 'edi.common.total_with_nds')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(subtotal + tax)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Row className="d-flex flex-lg-row flex-column">
                        <Col lg={6} xs={12}>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.common.company_director')}: </span>
                                <span className="col-8 text-center"> {invoice.director} <hr className="d-block m-0 bg-dark" style={{height: "2px"}} /></span>
                            </div>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.common.head_accountant')}: </span>
                                <span className="col-8 text-center"> {invoice.accountant} <hr className="d-block m-0 bg-dark" style={{height: "2px"}} /> </span>
                            </div>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.common.good_releaser')}: </span>
                                <span className="col-8 text-center"> {invoice.releaser} <hr className="d-block m-0 bg-dark" style={{height: "2px"}} /> </span>
                            </div>
                        </Col>

                        <Col className="mt-3" lg={6} xs={12}>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.invoice.proxy_number')}: </span>
                                <span className="col-8">{invoice.proxy_info.number} <hr className={invoice.proxy_info.number ? 'm-0 bg-dark' : 'mb-0 bg-dark'} style={{height: "2px"}} /></span>
                            </div>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.invoice.proxy_date')}: </span>
                                {invoice.proxy_info.date && <span className="col-8 text-nowrap text-center">{dayjs(invoice.proxy_info.date, 'YYYY-MM-DD').format(dateFormat)} <hr className="d-block m-0 bg-dark" style={{height: "2px"}} /></span>}
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <span>{invoice.proxy_info.name}</span>
                                <hr className={`d-block col-12 bg-dark ${invoice.proxy_info.name ? 'my-0' : 'mb-0'}`} style={{height: "2px"}} />
                                <span className="fs-0 fw-bold mb-1">{t(lang, 'edi.invoice.proxy_name')}: </span>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>


                <Card.Footer>
                    <Row>
                        {groupedPrivileges.map((key, sum) =>
                            <div key={sum + 1}>
                                <i className="text-dark"><span className="text-danger">*</span><strong className="mx-1">{t(lang,"edi.invoice.grouped_privilege_info_id")} </strong>{key.id}
                                    <strong className="mx-1">{t(lang,"edi.invoice.grouped_privilege_info_name")} </strong><span className="text-primary">{key.benefit_name}</span>
                                    <strong className="mx-1">{t(lang,"edi.invoice.grouped_privilege_info_total")} </strong>{key.benefit_vat_total_sum} сўм
                                    <strong className="mx-1">{t(lang,"edi.invoice.grouped_privilege_info_benefit_vat_total_sum")} </strong>{key.benefit_vat_sum} сўм</i>
                            </div>
                        )}
                    </Row>
                </Card.Footer>
            </Card>
        </>
    );
};

export default Invoice;
