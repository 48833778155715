import IconButton from "../common/IconButton";
import {CloseButton, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {dataTableTranslateColumns} from "../../enum/CommonDatatableTranslateColumns";

const DataTableSettings = ({allColumns, changeDataTable, show, setShow}) => {
    const lang = useSelector(selectLang)
    const t = Translate
    const dispatch = useDispatch()

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }


    const onChangeSwitch = (event, column) => {
        let updatedSettings = {...allColumns}
        updatedSettings[column] = event.target.checked;
        dispatch(changeDataTable(updatedSettings))
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang,"edi.common.controller_datatable.modal.header_title")}
                    </Modal.Title>
                    <CloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {t(lang,"edi.common.controller_datatable.modal.body.turn_on_off")}
                    </div>
                    {allColumns && Object.keys(allColumns)?.map((column,index) => (
                        <div key={index}>
                            <label className="d-flex">
                                <Form.Check type="switch" defaultChecked={allColumns[column]} onChange={(e) => onChangeSwitch(e, column)} />{" "}
                                {t(lang, dataTableTranslateColumns(column))}
                            </label>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DataTableSettings
