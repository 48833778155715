export const ContractorSourcePayout = 0
export const ContractorSourcePurchase = 1
export const ContractorSourceRefund = 2

export const ContractorPayoutSources = [
    {
        value: ContractorSourcePayout,
        label: "crm.contractor.operation.type.payout.label",
        variant: 'info'
    },
    {
        value: ContractorSourcePurchase,
        label: "crm.contractor.operation.type.purchase.label",
        variant: 'warning'
    },
    {
        value: ContractorSourceRefund,
        label: "crm.contractor.operation.type.refund.label",
        variant: 'danger'
    },
]

export const FindContractorPayoutSource = (Source) => {
    return ContractorPayoutSources.find(lt => lt.value === Source);
}
