import axios from "axios";
import {executorCancelOldRomaingDocumentAsync} from "../app/store/reducers/shipment/shipmentReducer";

class ShipmentService {
    registerShipment(payload) {
        return axios.post(`/shipment/executor/register`, payload)
    }

    printShipment(id) {
        return axios.get(`/pdf/shipment/${id}`, {
            responseType: "blob"
        })
    }

    editShipment(id, payload) {
        return axios.post(`/shipment/executor/edit/${id}`, payload)
    }

    deleteShipment({id, reason, comment}) {
        return axios.post(`/shipment/executor/delete/${id}`, {
            reason: reason,
            comment: comment,
        })
    }

    shipmentCustomerEdit(id, payload) {
        return axios.post(`/shipment/customer/edit/${id}`, payload)
    }

    getShipment(id) {
        return axios.get(`/shipment/get/${id}`)
    }

    customerShipmentApprove({id, comment}) {
        return axios.post(`/shipment/customer/approve/${id}`, {
            comment: comment
        })
    }

    getShipments(params) {
        return axios.get('/shipment/get', {
            params: params
        })
    }

    getShipmentsCustomer(params) {
        return axios.get('/shipment/shipment/customer', {
            params: params
        })
    }

    getShipmentsExecutor(params) {
        return axios.get('/shipment/shipment/executor', {
            params: params
        })
    }

    getCountShipments(params) {
        return axios.get('/shipment/count', {
            params: params
        })
    }

    getCustomerCountShipments(params) {
        return axios.get('/shipment/shipment/customer/count', {
            params: params
        })
    }

    getExecutorCountShipments(params) {
        return axios.get('/shipment/shipment/executor/count', {
            params: params
        })
    }

    executorApproveShipment({id, ...params}) {
        return axios.post(`/shipment/executor/approve/${id}`, params)
    }

    executorApproveShipmentHashCode(id) {
        return axios.get(`/shipment/shipment/executor-approve-hash-code/${id}`, {
            transformResponse: [data => data]
        })
    }

    executorCancelOldRoamingDocumentHashCode(id) {
        return axios.get(`/shipment/shipment/executor-cancel-hash-code/${id}`, {
            transformResponse: [data => data]
        })
    }

    executorCancelOldRoamingDocument({id, signature}) {
        return axios.post(`/shipment/shipment/executor-cancel-roaming-document/${id}`, {
            signature: signature,
        })
    }

    customerDeclineShipment({id, reason, comment}) {
        return axios.post(`/shipment/customer/decline/${id}`, {
            reason: reason,
            comment: comment,
        })
    }

    uploadShipmentToExcel({uri, id}) {
        return axios.get(`${uri}/${id}`,{
            responseType: "arraybuffer",
        })
    }

    uploadShipmentsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }
    uploadExecutorShipmentsToExcel({params}) {
        return axios.get('/excel/shipments/executor/default', {
            params: params,
            responseType: "arraybuffer",
        })
    }
    uploadCustomerShipmentsToExcel({params}) {
        return axios.get('/excel/shipments/customer/default', {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadSelectedShipmentsToExcel(selectedShipmentsIDs) {
        return axios.get(`/excel/shipment/default/${selectedShipmentsIDs}`,
            {responseType: "arraybuffer"})
    }
    uploadSelectedExecutorShipmentsToExcel(selectedShipmentsIDs) {
        return axios.post(`/excel/shipments/executor/default`,
            {selectedOrdersIDs: [selectedShipmentsIDs]},
            {responseType: "arraybuffer"})
    }
    uploadSelectedCustomerShipmentsToExcel(selectedShipmentsIDs) {
        return axios.post(`/excel/shipments/customer/default`,
            {selectedOrdersIDs: [selectedShipmentsIDs]},
            {responseType: "arraybuffer"})
    }

    uploadSelectedShipmentsWithItemsToExcel(selectedShipmentsIDs) {
        return axios.post('/excel/shipments-with-items/default', {
            selectedOrdersIDs: selectedShipmentsIDs,
        }, {responseType: "arraybuffer",})
    }

    uploadSelectedExecutorShipmentsWithItemsToExcel(selectedShipmentsIDs) {
        return axios.post('/excel/shipments-with-items/executor/default', {
            selectedOrdersIDs: selectedShipmentsIDs,
        }, {responseType: "arraybuffer",})
    }

    uploadSelectedCustomerShipmentsWithItemsToExcel(selectedShipmentsIDs) {
        return axios.post('/excel/shipments-with-items/customer/default', {
            selectedOrdersIDs: selectedShipmentsIDs,
        }, {responseType: "arraybuffer",})
    }

    uploadShipmentsWithItemsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }
    uploadExecutorShipmentsWithItemsToExcel({params}) {
        return axios.get('excel/shipments-with-items/executor/default', {
            params: params,
            responseType: "arraybuffer",
        })
    }
    uploadCustomerShipmentsWithItemsToExcel({params}) {
        return axios.get('/excel/shipments-with-items/customer/default', {
            params: params,
            responseType: "arraybuffer",
        })
    }
}

const shipmentService = new ShipmentService();

export default shipmentService
