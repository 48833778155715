import RoamingInvoiceDataTable from "../../../../components/hippo/roaming/invoice/datatable/RoamingInvoiceDataTable";
import {useParams} from "react-router";
import roamingInvoiceWrapper, {
    VIEWER_PERSON
} from "../../../../enum/RoamingInvoiceWrapper";
import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import classNames from "classnames";
import Flex from "../../../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect} from "react";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadSettings} from "../../../../app/store/reducers/settings/settingsReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED,
    ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS
} from "../../../../app/eventbus/roaming/roamingInvoiceEvents";
import {toast} from "react-toastify";
import {selectSettings} from "../../../../app/store/reducers/settings/edoSettingsReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingInvoiceView} from "../../../../enum/Permission/RoamingPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import {
    changeFilterOptionsAsync, loadRoamingInvoicesAsync, loadRoamingInvoicesCountAsync, selectInvoiceFilterOptions,
    selectRoamingInvoices, selectCount
} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";

const RoamingInvoices = () => {
    const {viewer} = useParams();
    const t = Translate;
    const lang = useSelector(selectLang);
    const viewers = roamingInvoiceWrapper.GetViewers();
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch()
    const settingServiceEdo = useSelector(selectSettings)
    const roamingInvoices = useSelector(selectRoamingInvoices);
    const count = useSelector(selectCount)

    const filters = useSelector(selectInvoiceFilterOptions);

    const loadSetting = () => {
        dispatch(loadSettings())
    }

    // load invoices
    const loadInvoices = () => {
        dispatch(loadRoamingInvoicesAsync(viewer, {...filters, sort: settingServiceEdo.invoice_settings.sort, desc: settingServiceEdo.invoice_settings.desc}));
        dispatch(loadRoamingInvoicesCountAsync(viewer, {...filters}));
    }

    useEffect(() => {
        loadSetting()
    }, [activeOrganization])

    // pagination options
    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}));

    useEffect(() => {
        const roamingInvoiceSettingsDatatableColumnsSuccessHandler = EventBus.on(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS, () => {
            loadSetting()
            toast.success(t(lang, "edi.common.toast.success"))
        })
        const roamingInvoiceSettingsDatatableColumnsFailedHandler = EventBus.on(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED, () => {
            loadSetting()
            toast.error(t(lang, "edi.common.toast.error"))
        })

        return () => {
            EventBus.remove(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_SUCCESS, roamingInvoiceSettingsDatatableColumnsSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_SETTINGS_SERVICE_DATATABLE_COLUMNS_FAILED, roamingInvoiceSettingsDatatableColumnsFailedHandler)
        }
    }, [])

    //important
    useSelector(selectAccountRolePermissions)

    if (!dispatch(checkPermission(PermissionRoamingInvoiceView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.roaming.invoice.datatable.no_access.title')}</h4>
        </Card>
    }

    return (
        <>
            <Row className="mb-3">
                {viewers.map((v, index) => {
                        if (activeOrganization.pinfl && v.id === VIEWER_PERSON) {
                            return (
                                <Col key={index}>
                                    <Link to={`../invoices/${v.id}`} className="text-decoration-none">
                                        <Card
                                            className={classNames("p-3 cursor-pointer", {'bg-primary text-white': v.id === viewer})}
                                        >
                                            <Flex justifyContent="center" alignItems="center">
                                                <FontAwesomeIcon
                                                    icon={roamingInvoiceWrapper.GetViewerIcon(v.id)}
                                                    className={classNames("fs-2 me-lg-2")}
                                                    color="primary"
                                                />
                                                <Card.Title className={classNames("d-none d-lg-block mb-0", {
                                                    'text-white': v.id === viewer,
                                                    'text-primary': v.id !== viewer
                                                })}
                                                >{t(lang, v.name)}
                                                </Card.Title>
                                            </Flex>
                                        </Card>
                                    </Link>
                                </Col>
                            )
                        } else if (v.id !== VIEWER_PERSON) {
                            return (
                                <Col key={index}>
                                    <Link to={`../invoices/${v.id}`} className="text-decoration-none">
                                        <Card
                                            className={classNames("p-3 cursor-pointer", {'bg-primary text-white': v.id === viewer})}
                                        >
                                            <Flex justifyContent="center" alignItems="center">
                                                <FontAwesomeIcon
                                                    icon={roamingInvoiceWrapper.GetViewerIcon(v.id)}
                                                    className={classNames("fs-2 me-lg-2")}
                                                    color="primary"
                                                />
                                                <Card.Title className={classNames("d-none d-lg-block mb-0", {
                                                    'text-white': v.id === viewer,
                                                    'text-primary': v.id !== viewer
                                                })}
                                                >{t(lang, v.name)}
                                                </Card.Title>
                                            </Flex>
                                        </Card>
                                    </Link>
                                </Col>
                            )
                        }
                    }
                )}
            </Row>

            {settingServiceEdo &&
                <RoamingInvoiceDataTable
                    activeColumns={settingServiceEdo?.invoice_settings?.invoice_datatable_column}
                    viewer={viewer}
                    changeFilterOptionsAsync={changeFilterOptionsAsync}
                    loadInvoices={loadInvoices}
                    roamingInvoices={roamingInvoices}
                    count={count}
                    filters={filters}
                    onPageChange={onPageChange}
                    onLimitChange={onLimitChange}
                />
            }
            {!settingServiceEdo && <SpinnerComponent />}
        </>
    )
};

export default RoamingInvoices;
