import classNames from "classnames";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../../../../common/IconButton";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Form, InputGroup, Modal, Table} from "react-bootstrap";
import ExcelFileUploader from "../../../../../common/ExcelFileUploader";
import React, {cloneElement, Fragment, useEffect, useState} from 'react';
import {Controller, FormProvider, useForm, useWatch} from "react-hook-form";
import {faArrowRotateRight, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import ColumnSelector from "../../../invoice/excel/mass-add-invoice/ColumnSelector";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_SUCCEED} from "../../../../../../app/eventbus/settingsEvents";
import {loadCatalogByCode} from "../../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {loadSettings, selectWaybillItemRegistrySettings, updateWaybillRegistryItemSettingsAsync} from "../../../../../../app/store/reducers/settings/settingsReducer";

const columnUnimportant = "unimportant";
const columnProductName = "product_name";
const columnProductCatalogCode = "product_catalog_code";
const columnProductCatalogName = "product_catalog_name";
const columnProductPackageCode = "product_package_code";
const columnProductPrice = "product_price";
const columnProductQuantity = "product_quantity";
const columnProductTotal = "product_total";

const sheetColumns = [
    {key: columnUnimportant, label: 'edo.register_waybill.form.items.add_item_from_excel.column.unimportant', isRequired: false},
    {key: columnProductName, label: 'edo.register_waybill.form.items.add_item_from_excel.column.product_name', isRequired: true},
    {key: columnProductCatalogCode, label: 'edo.register_waybill.form.items.add_item_from_excel.column.product_catalog_code', isRequired: true},
    {key: columnProductCatalogName, label: 'edo.register_waybill.form.items.add_item_from_excel.column.product_catalog_name', isRequired: false},
    {key: columnProductPackageCode, label: 'edo.register_waybill.form.items.add_item_from_excel.column.product_package_code', isRequired: false},
    {key: columnProductPrice, label: 'edo.register_waybill.form.items.add_item_from_excel.column.product_price', isRequired: true},
    {key: columnProductQuantity, label: 'edo.register_waybill.form.items.add_item_from_excel.column.product_quantity', isRequired: true},
    {key: columnProductTotal, label: 'edo.register_waybill.form.items.add_item_from_excel.column.product_total', isRequired: false},
];

const fieldNameSkipRows = 'skipRows';
const fieldNameSheetData = 'sheetData';
const fieldNameColumnsKeys = 'columnKeys';

const AddWaybillV2ItemFromExcelModal = ({onSubmitItems, children, ...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const registrySettings = useSelector(selectWaybillItemRegistrySettings);
    const [show, setShow] = useState(false);
    const [limit, setLimit] = useState(20);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            skipRows: 0,
            sheetData: [],
            columnKeys: [],
            file: {
                content: null,
                name: null,
                size: null,
            },
        }
    });

    const sheetData = methods.watch(fieldNameSheetData);
    const sheetColumnKeys = useWatch({name: fieldNameColumnsKeys, exact: false, control: methods.control});
    const sheetSkipRows = methods.watch(fieldNameSkipRows);

    useEffect(() => {
        const updateWaybillItemRegistrySettingsSucceed = EventBus.on(UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_SUCCEED, () => {
            loadWaybillItemRegistrySettings();
        });

        return () => {
            EventBus.remove(UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_SUCCEED, updateWaybillItemRegistrySettingsSucceed);
        };
    }, [])

    useEffect(() => {
        initSheetColumns();
        initSheetsSkipRows();
    }, [sheetData]);

    useEffect(() => {
        validateSheetColumns();
    }, [sheetColumnKeys])

    const loadWaybillItemRegistrySettings = () => {
        dispatch(loadSettings());
    };

    const handleShow = () => setShow(true);

    const handleClose = () => {
        methods.reset();
        setShow(false);
    };

    const updateFile = (selectedFile) => {
        methods.setValue('file', {
            content: selectedFile.content,
            name: selectedFile.name,
            size: selectedFile.size,
        });
    };

    const onFileParsed = parsedData => {
        methods.setValue(fieldNameSheetData, parsedData ? parsedData : []);
    };

    const initSheetColumns = () => {
        const savedSheetColumns = registrySettings?.sheet_columns;

        // define sheet max column count
        let maxSheetRowLength = 0;
        {
            for (let rowIndex = 0; rowIndex < sheetData.length; rowIndex++) {
                const sheetRow = sheetData[rowIndex];

                if (sheetRow.length > maxSheetRowLength) {
                    maxSheetRowLength = sheetRow.length;
                }
            }
        }

        // fill sheet all column keys
        let sheetColumnKeys = [];
        for (let index = 0; index < maxSheetRowLength; index++) {
            if (!!savedSheetColumns) {
                let savedColumnFound = false;

                for (let i = 0; i < savedSheetColumns.length; i++) {
                    const savedColumn = savedSheetColumns[i];

                    if (savedColumn.column_index === index) {
                        savedColumnFound = true;
                        sheetColumnKeys.push(savedColumn.key_name);
                        break;
                    }
                }

                if (!savedColumnFound) {
                    sheetColumnKeys.push(columnUnimportant);
                }
            } else {
                sheetColumnKeys.push(columnUnimportant);
            }
        }

        methods.setValue(fieldNameColumnsKeys, sheetColumnKeys);
    };

    const initSheetsSkipRows = () => {
        const savedSheetSkipRows = registrySettings?.sheet_skip_rows;

        if (!!savedSheetSkipRows) {
            const [defaultSheetSkipRows] = savedSheetSkipRows;
            if (defaultSheetSkipRows) {
                methods.setValue('skipRows', defaultSheetSkipRows.count_row);
            }
        }
    };

    const incrementSkipRows = () => {
        const skipRowsCount = methods.getValues(fieldNameSkipRows);
        methods.setValue(fieldNameSkipRows, skipRowsCount + 1);
    };

    const decrementSkipRows = () => {
        const skipRowsCount = methods.getValues(fieldNameSkipRows);
        methods.setValue(fieldNameSkipRows, skipRowsCount - 1);
    };

    const resetColumns = () => {
        if (sheetColumnKeys) {
            methods.setValue(fieldNameColumnsKeys, sheetColumnKeys.forEach((_, index) => {
                sheetColumnKeys[index] = columnUnimportant;
            }));
        }
    };

    const validateSheetColumns = () => {
        let noErrorsFound = true;
        for (let i = 0; i < sheetColumns?.length; i++) {
            const field = sheetColumns[i];
            let found = false;

            if (field.isRequired) {
                for (let j = 0; j < sheetColumnKeys?.length; j++) {
                    if (field.key === sheetColumnKeys[j]) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    noErrorsFound = false;
                    methods.setError('columnErrors', {
                        type: 'required',
                        message: t(lang, 'roaming.invoice.mass_add.columns.error_message', {column: t(lang, field.label)})
                    });
                    break;
                }
            }
        }

        if (noErrorsFound) {
            methods.clearErrors('columnErrors');
        }
    };

    const fillFromClipboard = () => {
        navigator.clipboard.readText()
            .then(pastedContent => {
                const parsedContent = pastedContent.split(/\r?\n/).map((rowContent) => rowContent.split('\t'));

                // filter empty rows
                const filteredContent = parsedContent.filter(rowContent => !rowContent.every(cellValue => !cellValue))

                methods.setValue(fieldNameSheetData, filteredContent ? filteredContent : []);
            })
            .catch(err => {
                console.error('Failed to read clipboard contents: ', err);
            });
    };

    const onSubmit = async formData => {
        setIsLoading(true);
        try {
            const rawItems = [];
            {
                const getColumnKeyByIndex = function (index) {
                    for (let columnIndex = 0; columnIndex < formData.columnKeys.length; columnIndex++) {
                        if (index === columnIndex)
                            return formData.columnKeys[columnIndex];
                    }
                };

                for (let rowIndex = formData.skipRows; rowIndex < formData.sheetData.length; rowIndex++) {
                    const rowData = formData.sheetData[rowIndex];
                    const rawItem = {};

                    for (let cellIndex = 0; cellIndex < rowData.length; cellIndex++) {
                        const cellValue = rowData[cellIndex];

                        const cellColumnKey = getColumnKeyByIndex(cellIndex);
                        switch (cellColumnKey) {
                            case columnProductName:
                                rawItem.name = cellValue;
                                break;
                            case columnProductCatalogCode:
                                rawItem.catalogCode = cellValue;
                                break;
                            case columnProductCatalogName:
                                rawItem.catalogName = cellValue;
                                break;
                            case columnProductPackageCode:
                                rawItem.packageCode = cellValue;
                                break;
                            case columnProductPrice:
                                rawItem.price = cellValue;
                                break;
                            case columnProductQuantity:
                                rawItem.quantity = cellValue;
                                break;
                            case columnProductTotal:
                                rawItem.total = cellValue;
                                break;
                        }
                    }

                    rawItems.push(rawItem);
                }
            }

            const items = [];
            {
                const itemsCatalogsMap = {};
                for (let i = 0; i < rawItems.length; i++) {
                    const rawItem = rawItems[i];

                    const item = {
                        name: rawItem.name,
                        catalogCode: rawItem.catalogCode,
                        catalogName: rawItem.catalogName,
                        packageCode: rawItem.packageCode,
                        price: rawItem.price,
                        quantity: rawItem.quantity,
                        total: rawItem.total,
                    };

                    // load catalog code
                    {
                        let itemCatalog = null;

                        if (itemsCatalogsMap[rawItem.catalogCode]) {
                            itemCatalog = itemsCatalogsMap[rawItem.catalogCode];
                        } else {
                            const loadedCatalog = await loadCatalogByCode(rawItem.catalogCode).catch(error => console.log(error))
                            if (loadedCatalog) {
                                itemsCatalogsMap[loadedCatalog.class_code] = loadedCatalog;
                                itemCatalog = loadedCatalog;
                            }
                        }

                        if (itemCatalog) {
                            item.catalogCode = itemCatalog.class_code;
                            item.catalogName = itemCatalog.class_name;

                            if (itemCatalog.package_names?.length) {
                                const defaultItemCatalogPackage = itemCatalog.package_names[0];
                                item.packageCode = defaultItemCatalogPackage?.code;

                                for (let j = 0; j < itemCatalog.package_names?.length; j++) {
                                    const catalogPackage = itemCatalog.package_names[j];

                                    let isEqual = false;
                                    if (+catalogPackage.code === +rawItem.packageCode) {
                                        isEqual = true;
                                    } else if (catalogPackage.name_ru?.trim()?.toLowerCase() === rawItem.packageCode?.trim()?.toLowerCase()) {
                                        isEqual = true;
                                    } else if (catalogPackage.name_uz?.trim()?.toLowerCase() === rawItem.packageCode?.trim()?.toLowerCase()) {
                                        isEqual = true;
                                    } else if (catalogPackage.name_lat?.trim()?.toLowerCase() === rawItem.packageCode?.trim()?.toLowerCase()) {
                                        isEqual = true;
                                    }

                                    if (isEqual)
                                        item.packageCode = catalogPackage.code;
                                }
                            }
                        }
                    }

                    items.push(item);
                }
            }

            onSubmitItems(items);

            // save import settings
            {
                const payload = {
                    sheet_columns: formData.columnKeys.map((columnKey, index) => {
                        return {key_name: columnKey, column_index: index, sheet_index: 0};
                    }),
                    sheet_skip_rows: [{count_row: formData.skipRows, sheet_index: 0}],
                };

                updateWaybillRegistryItemSettingsAsync(payload)
            }
            handleClose();
        } catch (error) {
            toast.error(t(lang, 'common.toast.error'));
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton variant={'falcon-success'} size={'sm'} icon={faFileExcel} onClick={handleShow} iconClassName={'me-2'} {...props}>
                    {t(lang, 'edo.register_waybill.form.items.add_item_from_excel_modal.add_button')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>{t(lang, 'edo.register_waybill.form.items.add_item_from_excel_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form onSubmit={event => {
                            event.stopPropagation();
                            return methods.handleSubmit(onSubmit)(event);
                        }} id={'mass-add-waybill-item-form'}>
                            <ExcelFileUploader parseMultiSheet={false} onFileChanged={updateFile} onFileContentParsed={onFileParsed}/>

                            <div className={'text-center'}>
                                <p className={'mb-1 fs-0'}>{t(lang, 'common.or')}</p>
                                <IconButton icon={'paste'} variant={'link'} className={'mb-1'} onClick={fillFromClipboard}>
                                    {t(lang, 'edo.register_waybill.form.items.add_item_from_excel.fill_from_clipboard')}
                                </IconButton>
                            </div>

                            {!!sheetData.length && (
                                <div>
                                    <div className="d-flex flex-between-center pt-4 pb-1">
                                        <Form.Group>
                                            <Form.Label>{t(lang, 'edo.register_waybill.form.items.add_item_from_excel.ignore_columns')}</Form.Label>
                                            <InputGroup>
                                                <Button variant="outline-secondary" onClick={decrementSkipRows}>-</Button>
                                                <Controller
                                                    name={fieldNameSkipRows}
                                                    render={({field}) => (
                                                        <Form.Control
                                                            type="number"
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            className="input-spin-none"
                                                        />
                                                    )}
                                                />
                                                <Button variant="outline-secondary" onClick={incrementSkipRows}>+</Button>
                                            </InputGroup>
                                        </Form.Group>

                                        <Button onClick={resetColumns} variant="falcon-warning">
                                            <FontAwesomeIcon className="me-2" icon={faArrowRotateRight}/>
                                            {t(lang, 'edo.register_waybill.form.items.add_item_from_excel.reset_columns')}
                                        </Button>
                                    </div>

                                    <div className={'my-2'}>
                                        <h5 className="text-danger">{methods.formState.errors?.columnErrors?.message}</h5>
                                    </div>

                                    <hr/>

                                    <div className="table-responsive mt-3">
                                        <Table size="sm" bordered>
                                            <thead>
                                            <tr>
                                                <th className="text-center px-3 m-0" width={20}>№</th>
                                                {sheetColumnKeys?.map((column, index) => (
                                                    <ColumnSelector
                                                        key={index}
                                                        index={index}
                                                        columnsOptions={sheetColumns}
                                                        arrayFieldName={fieldNameColumnsKeys}
                                                        unimportantColumn={columnUnimportant}
                                                    />
                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {sheetData.slice(0, limit).map((row, rowIndex) => (
                                                <tr className={classNames('fs--1', {'bg-400 text-dark': (rowIndex + 1) <= sheetSkipRows})} key={rowIndex}>
                                                    <th className="text-center px-3 m-0">{rowIndex + 1}</th>
                                                    {row.map((cell, cellIndex) => (
                                                        <th key={cellIndex}>{cell}</th>
                                                    ))}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>

                                    {sheetData.length > limit && (
                                        <div className="w-100 text-center my-3">
                                            <Button onClick={() => setLimit(limit + 20)}>{t(lang, 'Показать больше')}</Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'secondary'} type={'button'} onClick={handleClose}>{t(lang, 'roaming.common.close')}</Button>
                    <Button variant={'primary'} type={'submit'} form={'mass-add-waybill-item-form'} disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                        {t(lang, 'roaming.common.add')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default AddWaybillV2ItemFromExcelModal;