import React, {useEffect, useState} from "react";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {Button, Card, Form, Spinner, Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import Flex from "../../../../../common/Flex";
import IconButton from "../../../../../common/IconButton";
import SelectCompanyCatalog from "../../../../IKPU/SelectCompanyCatalog";
import ActionButton from "../../../../../common/ActionButton";
import classNames from "classnames";
import PropTypes from "prop-types";
import SelectCertificateWrapperComponent from "../../../components/SelectCertificateWrapperComponent";
import {faBookmark} from "@fortawesome/free-solid-svg-icons";
import SelectMeasurement from "../../../SelectMeasurement";
import SignActionWithSelectCertificatWrapper from "../../../components/SignActionWithSelectCertificatWrapper";
import CatalogLinkSelector from "../../../../../common/CatalogLinkSelector";

const Item = ({root, index, allowDelete, onDeleteClicked}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {register, formState: {errors}, setValue, control} = useFormContext();


    const itemRootName = `${root}.${index}`;
    let itemErrors = {};

    if (errors && errors[root] && errors[root][index])
        itemErrors = errors[root][index];


    const onChangeCatalog = (catalog) => {
        setValue(`${root}.${index}.catalogClassName`, catalog?.name);
    };


    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                <Form.Control name={`${itemRootName}.product`}
                              placeholder={t(lang, "edi.common.items.product.placeholder")}
                              type="text"
                              isInvalid={itemErrors?.product}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.product}
                              {...register(`${itemRootName}.product`, {
                                  required: t(lang, 'roaming.validations.is_required'),
                              })}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.product?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Form.Control name={`${itemRootName}.quantity`}
                              placeholder={t(lang, "edi.common.items.product.quantity.placeholder")}
                              type="text"
                              isInvalid={itemErrors?.quantity}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.quantity}
                              {...register(`${itemRootName}.quantity`, {
                                  required: t(lang, 'roaming.validations.is_required'),
                              })}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.quantity?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Controller name={`${itemRootName}.measurementId`}
                            control={control}
                            rules={{
                                required: t(lang, 'roaming.validations.is_required'),
                            }}
                            render={({field}) => (
                                <SelectMeasurement menuPortalTarget={document.body}
                                                   classNamePrefix="react-select"
                                                   className={classNames({
                                                       "is-invalid": itemErrors?.measurementId,
                                                       "is-valid": Object.keys(errors).length > 0 && !itemErrors?.measurementId
                                                   })}
                                                   placeholder={t(lang, "roaming.common.select.placeholder")}
                                                   defaultMeasureId={field.value}
                                                   onChange={(measurement) => field.onChange(measurement?.measureId)}
                                                   isClearable
                                />
                            )}
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.measurementId?.message}</Form.Control.Feedback>
            </td>
            <td>
                <Controller
                    name={`items.${index}.catalogClassCode`}
                    render={
                        ({field}) => (
                            <CatalogLinkSelector defaultCatalog={field.value}
                                                 textButton={field.value || t(lang, "roaming.common.select.placeholder")}
                                                 onChange={(catalog) => {
                                                     onChangeCatalog(catalog);
                                                     field.onChange(catalog?.class_code || null)
                                                 }}
                            />
                        )
                    }
                />
            </td>
            <td>
                <Form.Control name={`${itemRootName}.catalogClassName`}
                              placeholder={t(lang,"roaming.common.items.catalog_class_name")}
                              type="text"
                              {...register(`${itemRootName}.catalogClassName`)}
                             /* className={classNames({
                                  "is-invalid": itemErrors?.catalogClassName,
                                  "is-valid": Object.keys(errors).length > 0 && !itemErrors?.catalogClassName
                              })}*/
                />
                <Form.Control.Feedback type="invalid">{itemErrors?.catalogClassName?.message}</Form.Control.Feedback>
            </td>
            <td>
                <ActionButton icon="trash-alt"
                              title="Delete"
                              variant="action"
                              className="p-1 mx-auto"
                              onClick={() => onDeleteClicked(index)}
                              disabled={!allowDelete}
                />
            </td>
        </tr>
    )
};


const EmpowermentItems = ({root}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {append, fields, remove} = useFieldArray({name: root});
    const [allowDeleteItems, setAllowDeleteItems] = useState(false);


    const addNewItem = () => {
        const item = {
            product: "",
            measurementId: "",
            quantity: "",
            catalogClassCode: "",
            catalogClassName: "",
        };
        append(item);
    };

    const onDeleteItem = index => remove(index);

    useEffect(() => {
        setAllowDeleteItems(fields.length > 1);
    }, [fields]);

    return (
        <Card>
            <Card.Header>
                <Flex justifyContent="between" alignItems="center">
                    <h4>{t(lang, "roaming.common.items")}</h4>
                    <IconButton variant="falcon-default" size="sm" icon="plus" transform="shrink-3" className="me-2"
                                onClick={() => addNewItem()}>
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, "roaming.common.add_button")}
                                </span>
                    </IconButton>
                </Flex>
            </Card.Header>
            <Card.Body>
                <Table responsive striped bordered>
                    <thead>
                    <tr>
                        <th className="text-center align-middle">#</th>
                        <th rowSpan={2} className="text-center align-middle">{t(lang, "roaming.common.items.product")}</th>
                        <th rowSpan={2} className="text-center align-middle">{t(lang, "roaming.common.items.quantity")}</th>
                        <th rowSpan={2} className="text-center align-middle">{t(lang, "roaming.common.items.measurement")}</th>
                        <th rowSpan={2} className="text-center align-middle">{t(lang, "roaming.common.items.catalog_class_code")}</th>
                        <th rowSpan={2} className="text-center align-middle">{t(lang, "roaming.common.items.catalog_class_name")}</th>
                        <th rowSpan={2} className="text-center align-middle"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <Item key={item.id} root={root} index={index} allowDelete={allowDeleteItems} onDeleteClicked={onDeleteItem}/>
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
                <IconButton
                    type="submit"
                    variant="warning"
                    name="draft"
                    icon={faBookmark}
                >{t(lang,"roaming.act_form_save")}
                </IconButton>

                <SignActionWithSelectCertificatWrapper isForm>
                    <Button type="submit" name="send" className="ms-2" form="EmpowermentForm">
                        {t(lang, "roaming.common.send_button")}
                    </Button>
                </SignActionWithSelectCertificatWrapper>
            </Card.Footer>
        </Card>
    )
};


export default EmpowermentItems;
