import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, Form} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {excelFileUploader} from "../../../../../helpers/excel-uploader";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectOrganizationTemplates} from "../../../../../app/store/reducers/excel/excelReducer";
import {DRAFT, VIEWER_CUSTOMER, VIEWER_EXECUTOR} from "../../../../../enum/RoamingInvoiceWrapper";
import {TYPE_ROAMING_INVOICES, TYPE_ROAMING_INVOICES_WITH_ITEMS} from "../../../../../enum/ExcelTemplateTypes";
import {
    selectInvoiceFilterOptions,
    uploadCustomerInvoicesToExcelAsync,
    uploadCustomerInvoicesWithItemsToExcelAsync,
    uploadExecutorInvoicesToExcelAsync, uploadExecutorInvoicesWithItemsToExcelAsync
} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingInvoiceDownloadExcel} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";

const UploadRoamingInvoiceListToExcel = ({...props}) => {
    const [roamingInvoicesWithItemsTypeTemplates, setRoamingInvoicesWithItemsTypeTemplates] = useState([]);
    const [roamingInvoicesTypeTemplates, setRoamingInvoicesTypeTemplates] = useState([]);
    const organizationTemplates = useSelector(selectOrganizationTemplates);

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [shouldLoadAll, setShouldLoadAll] = useState(false);
    const filter = {...useSelector(selectInvoiceFilterOptions)};
    const lang = useSelector(selectLang);
    const {viewer} = useParams();
    const t = Translate;
    useEffect(() => {
        const roamingInvoicesTemplates = organizationTemplates.filter(template => template.type === TYPE_ROAMING_INVOICES) || [];
        const roamingInvoicesWithItemsTemplates = organizationTemplates.filter(template => template.type === TYPE_ROAMING_INVOICES_WITH_ITEMS) || [];

        setRoamingInvoicesTypeTemplates(roamingInvoicesTemplates);
        setRoamingInvoicesWithItemsTypeTemplates(roamingInvoicesWithItemsTemplates);
    }, [organizationTemplates])

    const uploadExcel = (uri) => {
        setLoading(true);

        if (shouldLoadAll)
            filter.limit = null;

        if (viewer === VIEWER_CUSTOMER) {
            uploadCustomerInvoicesToExcelAsync({...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.invoices.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else if (viewer === VIEWER_EXECUTOR) {
            uploadExecutorInvoicesToExcelAsync({...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.return_invoices.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }
    const uploadExcelWithItems = (uri) => {
        setLoading(true);

        if (shouldLoadAll)
            filter.limit = null;

        if (viewer === VIEWER_CUSTOMER) {
            uploadCustomerInvoicesWithItemsToExcelAsync({...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.invoices_with_items.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else if (viewer === VIEWER_EXECUTOR) {
            uploadExecutorInvoicesWithItemsToExcelAsync({...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.return_invoices_with_items.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }

    const uploadInvoices = (uri) => {
        if (dispatch(checkPermission(PermissionRoamingInvoiceDownloadExcel))) uploadExcel(uri)
        else dispatch(toastPermission())
    };

    const uploadInvoicesWithItems = (uri) => {
        if (dispatch(checkPermission(PermissionRoamingInvoiceDownloadExcel))) uploadExcelWithItems(uri)
        else dispatch(toastPermission())
    };

    if (viewer === DRAFT) {
        return false
    }

    return (
        <>
            <Dropdown {...props}>
                <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                    {loading
                        ?
                        <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                        :
                        <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                    }
                    <span className="d-none d-sm-inline-block">
                        {t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.export_to_excel')}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-2'>
                    <Form.Switch label={t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.download_all')}
                                 onChange={(e) => setShouldLoadAll(e.target.checked)}
                                 className="ps-5 text-black"
                    />
                    <Dropdown.Header className="text-black">{t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.item.export_orders_to_excel')}</Dropdown.Header>
                    {roamingInvoicesTypeTemplates.map((template, index) => (
                        <Dropdown.Item className="ps-4" key={index} onClick={() => uploadInvoices(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                    ))}

                    <Dropdown.Header className="text-black">{t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.item.export_orders_with_items_to_excel')}</Dropdown.Header>
                    {roamingInvoicesWithItemsTypeTemplates.map((template, index) => (
                        <Dropdown.Item className="ps-4" key={index} onClick={() => uploadInvoicesWithItems(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default UploadRoamingInvoiceListToExcel;
