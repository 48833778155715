import React, {useState} from "react";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import IconButton from "../../../../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {customerAcceptAsync,} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import PropTypes from "prop-types";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {Spinner} from "react-bootstrap";
import EventBus from "../../../../../app/eventbus/EventBus";
import {ROAMING_INVOICE_BULK_ACTION_FINISHED, ROAMING_INVOICE_BULK_ACTION_STARTED} from "../../../../../app/eventbus/roaming/roamingInvoiceEvents";
import {PermissionRoamingInvoiceAccept} from "../../../../../enum/Permission/RoamingPermission";

const RoamingInvoiceCustomerAccept = ({id, invoices, handleShowProgressBar, updateActionResult, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    // accept invoice
    const customerAccept = () => {
        if (invoices.length > 0)
            bulkAccept();
        else
            singleAccept();
    }

    // accept single document
    const singleAccept = () => {
        setLoading(true);
        customerAcceptAsync(activeCertificate, lang, id)
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    // accept multiple documents
    const bulkAccept = async () => {
        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];
            try {
                await customerAcceptAsync(activeCertificate, lang, invoice.id)
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.executor.name,
                        inn: invoice.executor.inn
                    }
                })
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.executor.name,
                        inn: invoice.executor.inn
                    },
                    error: error?.message
                })
            }
        }
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
    }

    return (
        <SelectCertificateWrapperComponent
            permission={PermissionRoamingInvoiceAccept}
            openDialogButtonText={t(lang, "roaming.common.accept")}
            className="mx-2"
            submitButton={
                <IconButton
                    onClick={customerAccept}
                    variant="falcon-primary"
                    size="sm"
                    className="m-2"
                    id="PersonAccept"
                    icon={'check'}
                    disabled={loading}
                    {...props}
                >
                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                    {t(lang, 'roaming.common.accept')}
                </IconButton>}
        />
    )
};

RoamingInvoiceCustomerAccept.propTypes = {
    id: PropTypes.string,
    invoices: PropTypes.array,
    updateActionResult: PropTypes.func,
    handleShowProgressBar: PropTypes.func
}

RoamingInvoiceCustomerAccept.defaultProps = {
    id: '',
    invoices: [],
    updateActionResult: () => {},
    handleShowProgressBar: () => {}
}

export default RoamingInvoiceCustomerAccept;
