import ContractFormationView from "./ContractFormationView";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    loadContractOneAsync,
    updateItemContractFormationAsync
} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    SEND_CONTRACT_FORMATION_FAILED,
    SEND_CONTRACT_FORMATION_REQUESTED,
    SEND_CONTRACT_FORMATION_SUCCESS,
    SIGN_CONTRACT_FORMATION_FAILED,
    SIGN_CONTRACT_FORMATION_REQUESTED,
    SIGN_CONTRACT_FORMATION_SUCCESS, UPDATE_ITEM_CONTRACT_FORMATION_FAILED, UPDATE_ITEM_CONTRACT_FORMATION_SUCCESS
} from "../../../../app/eventbus/contractFormationEvents";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../app/store/reducers/main/mainReducer";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {
    loadCompanyProductCatalogsAsync,
    loadMeasurementsAsync
} from "../../../../app/store/reducers/roaming/roamingReducer";
import {toast} from "react-toastify";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionContractFormationView} from "../../../../enum/Permission/EdiPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {useHistory} from "react-router-dom";


const ViewContractFormation = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const { id } = useParams()
    const [contract, setContract] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate
    const permission = useSelector(selectAccountRolePermissions)

    const methods = useForm({
        defaultValues: {
            products: [
                {
                    item_id: null,
                    catalog_code: null,
                    catalog_name: null,
                    measurement_id: '',
                    measurement_name: '',
                    package_code: null,
                    package_name: null,
                    packages: null,
                    catalogs: null,
                }
            ],
        }
    })

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionContractFormationView))) {
            if (history.length > 1) {
                history.push('/edi/contracts-formation')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
        dispatch(loadMeasurementsAsync(lang));
    },[activeOrganization, lang])


    useEffect(() => {
        loadContractFormation()
    },[id, activeOrganization])


    useEffect(() => {
        const onContractFormationSendRequestedHandler = EventBus.on(SEND_CONTRACT_FORMATION_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onContractFormationSendSuccessHandler = EventBus.on(SEND_CONTRACT_FORMATION_SUCCESS, () => {
            loadContractFormation()
            dispatch(updateShowInProgressAsync(false))
        })

        const onContractFormationSendFailedHandler = EventBus.on(SEND_CONTRACT_FORMATION_FAILED, () => {
            dispatch(updateShowInProgressAsync(false))
        })


        const onContractFormationSignRequestedHandler = EventBus.on(SIGN_CONTRACT_FORMATION_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onContractFormationSignSuccessHandler = EventBus.on(SIGN_CONTRACT_FORMATION_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            loadContractFormation()
            dispatch(updateShowInProgressAsync(false))
        })

        const onContractFormationSignFailedHandler = EventBus.on(SIGN_CONTRACT_FORMATION_FAILED, () => {
            dispatch(updateShowInProgressAsync(false))
        })


        const onContractFormationUpdateItemSuccessHandler = EventBus.on(UPDATE_ITEM_CONTRACT_FORMATION_SUCCESS, () => {
            loadContractFormation()
            toast.success(t(lang,"edi.common.toast.success"))
        })

        const onContractFormationUpdateItemFailedHandler = EventBus.on(UPDATE_ITEM_CONTRACT_FORMATION_FAILED, () => {
            toast.error(t(lang,"edi.common.toast.error"))
        })



        return () => {
            EventBus.remove(SEND_CONTRACT_FORMATION_REQUESTED, onContractFormationSendRequestedHandler);
            EventBus.remove(SEND_CONTRACT_FORMATION_SUCCESS, onContractFormationSendSuccessHandler);
            EventBus.remove(SEND_CONTRACT_FORMATION_FAILED, onContractFormationSendFailedHandler);
            EventBus.remove(SIGN_CONTRACT_FORMATION_REQUESTED, onContractFormationSignRequestedHandler);
            EventBus.remove(SIGN_CONTRACT_FORMATION_SUCCESS, onContractFormationSignSuccessHandler);
            EventBus.remove(SIGN_CONTRACT_FORMATION_FAILED, onContractFormationSignFailedHandler);
            EventBus.remove(UPDATE_ITEM_CONTRACT_FORMATION_SUCCESS, onContractFormationUpdateItemSuccessHandler)
            EventBus.remove(UPDATE_ITEM_CONTRACT_FORMATION_FAILED, onContractFormationUpdateItemFailedHandler)
        }
    },[])

    const loadContractFormation = async () => {
        const getContract = await loadContractOneAsync(id)
        setContract(getContract?.data?.document)
    }

    if (!contract) {
        return <>loading...</>
    }

    const onFormSubmit = async (formData) => {

        const json = formData.products.map((product) => ({
                    item_id: product.item_id,
                    catalog_code: product.catalog_code,
                    catalog_name: product.catalog_name,
                    measurement_id: product.measurement_id,
                    measurement_name: product.measurement_name,
                    package_code: product?.package_code ? `${product?.package_code}` : null,
                    package_name: product?.package_name || null,
                }
            ))


        for (var i = 0; i < json.length; i++) {
            await updateItemContractFormationAsync({id: id, updateItem: json[i]})
        }

    }

    return(
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onFormSubmit)}>
                <ContractFormationView contractFormation={contract?.contract} contract={contract}/>
            </Form>
        </FormProvider>
    )
}


export default ViewContractFormation;
