export const REGISTER_COMPANY_ADDRESS_REQUESTED = "REGISTER_COMPANY_ADDRESS_REQUESTED";
export const REGISTER_COMPANY_ADDRESS_SUCCEED = "REGISTER_COMPANY_ADDRESS_SUCCEED";
export const REGISTER_COMPANY_ADDRESS_FAILED = "REGISTER_COMPANY_ADDRESS_FAILED";

export const UPDATE_COMPANY_ADDRESS_REQUESTED = "UPDATE_COMPANY_ADDRESS_REQUESTED";
export const UPDATE_COMPANY_ADDRESS_SUCCEED = "UPDATE_COMPANY_ADDRESS_SUCCEED";
export const UPDATE_COMPANY_ADDRESS_FAILED = "UPDATE_COMPANY_ADDRESS_FAILED";

export const DELETE_COMPANY_ADDRESS_REQUESTED = "DELETE_COMPANY_ADDRESS_REQUESTED";
export const DELETE_COMPANY_ADDRESS_SUCCEED = "DELETE_COMPANY_ADDRESS_SUCCEED";
export const DELETE_COMPANY_ADDRESS_FAILED = "DELETE_COMPANY_ADDRESS_FAILED";

export const UPDATE_COMPANY_BINDINGS_REQUESTED = "UPDATE_COMPANY_BINDINGS_REQUESTED";
export const UPDATE_COMPANY_BINDINGS_SUCCEED = "UPDATE_COMPANY_BINDINGS_SUCCEED";
export const UPDATE_COMPANY_BINDINGS_FAILED = "UPDATE_COMPANY_BINDINGS_FAILED";

// system organization
export const REGISTER_SYSTEM_COMPANY_ADDRESS_REQUESTED = "REGISTER_SYSTEM_COMPANY_ADDRESS_REQUESTED";
export const REGISTER_SYSTEM_COMPANY_ADDRESS_SUCCEED = "REGISTER_SYSTEM_COMPANY_ADDRESS_SUCCEED";
export const REGISTER_SYSTEM_COMPANY_ADDRESS_FAILED = "REGISTER_SYSTEM_COMPANY_ADDRESS_FAILED";

export const DELETE_SYSTEM_COMPANY_ADDRESS_REQUESTED = "DELETE_SYSTEM_COMPANY_ADDRESS_REQUESTED";
export const DELETE_SYSTEM_COMPANY_ADDRESS_SUCCEED = "DELETE_SYSTEM_COMPANY_ADDRESS_SUCCEED";
export const DELETE_SYSTEM_COMPANY_ADDRESS_FAILED = "DELETE_SYSTEM_COMPANY_ADDRESS_FAILED";
