import classNames from "classnames";
import Cleave from "cleave.js/react";
import {useSelector} from "react-redux";
import {Button, Form, Modal} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {getRandomNumber} from "../../helpers/utils";
import React, {cloneElement, Fragment, useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const CommonDeleteModal = ({
                               deleteButtonLabel,
                               modalTitle,
                               modalDescription,
                               modalCancelButtonLabel,
                               modalDeleteButtonLabel,
                               withConfirmation,
                               onDelete,
                               centered,
                               children,
                               ...props
                           }) => {
    const [confirmCode] = useState(getRandomNumber(100000000, 999999999));
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {control, formState: {errors}, handleSubmit, reset} = useForm({defaultValues: {code: null}});

    const handleShow = () => setShow(true);
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const onSubmit = formData => {
        if (Number(confirmCode) === Number(formData.code)) {
            onDelete();
            handleClose();
        }

        return false;
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <Button variant={'danger'} onClick={handleShow} {...props}>{deleteButtonLabel ? deleteButtonLabel : t(lang,'common.button.delete')}</Button>
            )}

            <Modal show={show} size={'md'} onHide={handleClose} centered={centered}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{modalTitle ? modalTitle : t(lang, "common.common_delete_modal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!withConfirmation && <h5>{modalDescription ? modalDescription : t(lang, 'common.common_delete_modal.description')}</h5>}
                        {!!withConfirmation && (
                            <Form.Group>
                                <Form.Label>
                                    <span>{t(lang, 'common.common_delete_modal.confirm_code.title')}:</span>
                                    <span className="ms-1 text-primary" onCopy={(e) => e.preventDefault()}>{confirmCode}</span>
                                </Form.Label>
                                <Controller
                                    name={'code'}
                                    control={control}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                        validate: (value) => {
                                            if (+value === confirmCode)
                                                return true;
                                            return t(lang, 'common.common_delete_modal.invalid_confirm_code', {code: confirmCode});
                                        }
                                    }}
                                    render={({field}) => (
                                        <Cleave
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.target.rawValue)}
                                            options={{numeral: true, delimiter: ' ', numeralDecimalScale: 3}}
                                            className={classNames('form-control', {'is-invalid': errors?.code})}
                                            placeholder={t(lang, 'common.common_delete_modal.confirm_code.placeholder')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors?.code?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>{modalCancelButtonLabel ? modalCancelButtonLabel : t(lang, 'common.button.cancel')}</Button>
                        {!!withConfirmation && <Button variant={'danger'} type={'submit'}>{!!modalDeleteButtonLabel ? modalDeleteButtonLabel : t(lang,'common.button.delete')}</Button>}
                        {!withConfirmation && <Button variant={'danger'} onClick={onDelete}>{!!modalDeleteButtonLabel ? modalDeleteButtonLabel : t(lang,'common.button.delete')}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default CommonDeleteModal;