import React from 'react'
import {deleteContractorLabelAsync} from '../../../../../app/store/reducers/contractor/contractorLabelReducer';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";

const DeleteContractorLabelModal = ({id, onClose}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const onConfirm = () => {
        deleteContractorLabelAsync({
            id: id
        }).then(res => {
            onClose();
        }).catch(error => console.log(error))
    }
    return (
        <Modal
            show={true}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>{t(lang, 'edi.common.delete_button')}</Modal.Title>
                <FalconCloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>{t(lang, 'edi.common.delete_button')}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t(lang, 'edi.common.button.close')}
                </Button>
                <Button onClick={onConfirm}>{t(lang, 'edi.common.delete_button')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteContractorLabelModal