import React, {useState} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import {useForm} from "react-hook-form";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../common/IconButton";
import { selectLang, Translate } from "../../../app/store/reducers/main/mainReducer";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";


const CancelInvoice = ({cancel, disabled, ...props}) => {
    const {register, formState: {errors}, handleSubmit, reset} = useForm();
    const lang = useSelector(selectLang);
    const t = Translate;

    const [show, setShow] = useState(false);
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        cancel({
            comment: comment
        })
        reset();
        setShow(false);
    };

    return (
        <>
        <IconButton
            variant="falcon-danger"
            size="sm"
            icon={faBan}
            disabled={disabled}
            iconClassName="me-1"
            onClick={() => setShow(true)}
            {...props}
        >{t(lang, "edi.common.cancel_decline")}</IconButton>
            <Modal size="md" show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Header>
                        <Modal.Title>Invoice cancel</Modal.Title>
                        <FalconCloseButton onClick={() => {handleClose()}}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                name="Name"
                                as="textarea" rows={3}
                                placeholder="Please enter a comment to cancel"
                                isInvalid={errors?.comment?.message}
                                {...register("comment", {
                                    required: {
                                        value: true,
                                        message: "This field is required!"
                                    }
                                })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.comment?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {handleClose()}}>{t(lang, "edi.common.button.close")}</Button>
                        <Button 
                            variant="danger" 
                            className="d-flex align-items-center" 
                            disabled={disabled}
                            type="submit"
                        >{t(lang, "edi.common.button.yes")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

CancelInvoice.propTypes = {
    cancel: PropTypes.func
}

CancelInvoice.defaultProps = {
    cancel: () => {}
}

export default CancelInvoice;
