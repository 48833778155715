import React, {Fragment, useEffect, useState} from 'react';
import {Controller, FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {selectAllContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import IconButton from "../../../common/IconButton";
import ReactSelect from "react-select";
import CleaveWithCommaMark from "../../../common/CleaveWithCommaMark";
import numeral from "numeral";
import {loadPriceAsync, updateItemPriceContractor} from "../../../../app/store/reducers/price/priceReducer";
import SpinnerComponent from "../../spinner/SpinnerComponent";
import {toast} from "react-toastify";
import ContractorMassModal from "./ContractorMassModal";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";

const ContractorPriceItem = ({item, index, onChange}) => {
    const contractors = useSelector(selectAllContractor)
    const name = `items.${index}`

    const defaultPriceValue = item.price.common_price.amount
    const t = Translate;
    const lang = useSelector(selectLang);
    const [commonPriceCurrencyGlobalId, setCommonPriceCurrencyGlobalId] = useState(null)
    const [bulkPriceCurrencyGlobalId, setBulkPriceCurrencyGlobalId] = useState(null)
    const [loading, setLoading] = useState(false)
    const {setValue, watch, formState: {errors}} = useFormContext()

    const massPrice = watch('massValues.price')
    const price = watch(`${name}.price.common_price.amount`)
    const contractor = watch(`${name}.contractor`)

    useEffect(() => {
        if (contractor?.id && !massPrice) {
            setLoading(true)
            loadPriceAsync(item.item_id, {contractorId: contractor.id})
                .then(res => {
                    setValue(`${name}.price.common_price.amount`, res.common_price.amount)
                    setCommonPriceCurrencyGlobalId(res.common_price?.currency?.global_currency_id)
                    setBulkPriceCurrencyGlobalId(res.bulk_price?.currency?.global_currency_id)
                })
                .finally(() => setLoading(false))
        }
    }, [contractor])

    const setItemContractorPrice = () => {
        if (contractor) {
            const data = {
                ...item,
                name: item.name,
                contractor: contractor,
                price: {
                    common_price: {
                        currency_id: commonPriceCurrencyGlobalId,
                        amount: +price
                    },
                    bulk_price: {
                        currency_id: bulkPriceCurrencyGlobalId,
                        amount: item.price.bulk_price.amount
                    }
                }
            }
            onChange(data)
            setValue(`${name}.price.common_price.amount`, defaultPriceValue)
            setValue(`${name}.contractor`, null)
            setCommonPriceCurrencyGlobalId(null)
            setBulkPriceCurrencyGlobalId(null)
        }
    }

    return (
        <Fragment>
            <Row className={'mb-2'}>
                <Col lg={5} className={'d-flex gap-2'}>
                    <span>{index + 1}.</span>
                    <strong>{item.name}</strong>
                </Col>
                <Col lg={7}>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Controller
                                    name={`${name}.price.common_price.amount`}
                                    render={({field}) => (
                                        <CleaveWithCommaMark value={field.value}
                                                             onChange={field.onChange}
                                                             ref={field.ref}
                                                             disabled={loading}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Controller
                                    name={`${name}.contractor`}
                                    rules={{
                                        required: t(lang, "items.common.validations.is_required")
                                    }}
                                    render={({field}) => (
                                        <Fragment>
                                            <ReactSelect
                                                hideSelectedOptions
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    setValue('massValues.price', 0)
                                                }}
                                                placeholder={t(lang, "items.common.select")}
                                                style={{width: '100%'}}
                                                classNamePrefix="react-select"
                                                options={contractors}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.id}
                                                value={contractors.find(s => s.id === field.value?.id) || null}
                                                isClearable
                                            />
                                        </Fragment>
                                    )}/>
                                <Form.Control.Feedback type={'invalid'}
                                                       className={'d-block'}>{errors?.items?.[index]?.contractor?.message}</Form.Control.Feedback>
                            </Form.Group>

                        </Col>
                        <Col md={3}>
                            <Button className={'float-end'} variant={'falcon-primary'} onClick={setItemContractorPrice}>
                                {t(lang, "items.common.add")}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}

const ContractorPriceForm = ({items}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const contractors = useSelector(selectAllContractor)
    const numberFormat = useSelector(selectNumberFormat);

    const [productList, setProductList] = useState([])
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(null)

    const methods = useForm({
        defaultValues: {
            items: items.map(item => {
                return {
                    item_id: item.item.id,
                    name: item.item.name,
                    price: {
                        bulk_price: {
                            amount: item.price.bulk_price.amount,
                            currency_id: item.price.bulk_price.currency.id
                        },
                        common_price: {
                            amount: item.price.common_price.amount,
                            currency_id: item.price.common_price.currency.id
                        }
                    },
                    contractor: ""
                }
            }),
            itemContractorList: [],
            massValues: {
                price: 0,
                contractor: null
            }
        }
    })

    const {fields, append, remove, update} = useFieldArray({
        control: methods.control,
        name: 'itemContractorList'
    })

    const {fields: productItems} = useFieldArray({
        control: methods.control,
        name: 'items'
    })

    useEffect(() => {
        fields.map((product) => {
            if (productList.length) {
                let isTrue = false
                productList.map((arrList) => {
                    if (arrList.productList.some(item => item.contractor?.id === product.contractor?.id)) {
                        if (!arrList.productList.some(item => item.item_id === product.item_id)) {
                            arrList.productList.push(product)
                            isTrue = true
                        } else {
                            arrList.productList.forEach((list, index) => {
                                if (list.item_id === product.item_id) {
                                    arrList.productList.splice(index, 1, product)
                                }
                            })
                            isTrue = true
                        }
                    }
                })
                if (!isTrue) {
                    productList.push({
                        name: contractors.find(s => s.id === product?.contractor?.id)?.name,
                        productList: new Array(product)
                    })
                }
            } else {
                productList.push({
                    name: contractors.find(s => s.id === product?.contractor?.id)?.name,
                    productList: new Array(product)
                })
            }
        })
        setProductList([...productList])
    }, [fields])

    const onSubmit = (formData) => {
        formData.items.map((item, i) => {
            let index = fields.findIndex(i => i.item_id === item.item_id && i.contractor?.id === item.contractor?.id)
            if (index !== -1) update(index, item)
            else append(item)
            methods.setValue(`items.${i}.contractor`, null)
            methods.setValue(`items.${i}.price.common_price.amount`, productItems[i]?.price?.common_price.amount)
        })
    }

    const onSave = () => {
        const data = fields.map(((item) => {
            return {
                correlation_id: item.id,
                item_id: item.item_id,
                contractor_id: item.contractor.id,
                common_price: {
                    currency_id: item.price.common_price.currency_id,
                    amount: item.price.common_price.amount
                },
                bulk_price: {
                    currency_id: item.price.bulk_price.currency_id,
                    amount: item.price.bulk_price.amount
                }
            }
        }))
        setLoading(true)
        updateItemPriceContractor(data)
            .then((res) => {
                setResponse(res)
            })
            .catch(e => {
                toast.error('Error')
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (response) {
            let arrIndex = response?.records.filter((item) => item.success).map((_, index) => index)
            remove(arrIndex)
            setProductList([])
        }
    }, [response])

    const onChangeItem = (item) => {
        let index = fields.findIndex(i => i.item_id === item.item_id && i.contractor?.id === item.contractor?.id)
        if (index !== -1) update(index, item)
        else append(item)
    }

    const deleteProduct = (item) => {
        let index = fields.findIndex(i => i.item_id === item.item_id && i.contractor === item.contractor)
        if (index !== -1) {
            remove(index)
            productList.map((i, iIndex) => {
                if (i.name === item.contractor.name) {
                    i.productList.map((product, productIndex) => {
                        if (product.item_id === item.item_id) {
                            if (i.productList.length === 1) {
                                return productList.splice(iIndex, 1)
                            }
                            return i.productList.splice(productIndex, 1)
                        }
                    })
                }
            })
            setProductList([...productList])
        }
    }

    const handleShow = () => {
        setShow(true)
    };

    const onMassImport = () => {
        const price = methods.getValues('massValues.price')
        const contractor = methods.getValues('massValues.contractor')
        if (price) {
            productItems.forEach((_, index) => {
                methods.setValue(`items.${index}.price.common_price.amount`, +price)
            })
        }
        if (contractor) {
            productItems.forEach((_, index) => {
                methods.setValue(`items.${index}.contractor`, contractor)
            })
        }
        setShow(false)
    }

    return (
        <Fragment>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>

                    {productItems.map((i, index) => <ContractorPriceItem key={index} item={i} index={index}
                                                                         onChange={onChangeItem}/>)}

                    <Row className={'justify-content-between align-items-end'}>
                        <Col lg={12} className={'d-flex justify-content-end mt-3 gap-3'}>
                            <Button type={'button'} variant={'falcon-default'}
                                    onClick={handleShow}>{t(lang, "items.common.bulk_change")}</Button>
                            <Button variant={'falcon-default'}
                                    type={'submit'}>{t(lang, 'items.common.all.add')}</Button>
                        </Col>
                    </Row>

                    <hr/>

                    <Row>
                        <Col lg={6} className={'d-flex gap-4'}>
                            {response ? <Fragment>
                                <h4 className={'m-0'}>{t(lang, 'items.common.toast.success')}: <span
                                    className={'text-success ms-2'}>{response?.succeed_count}</span></h4>
                                <h4 className={'m-0'}>{t(lang, 'items.common.toast.error')}: <span
                                    className={'text-danger ms-2'}>{response?.failed_count}</span></h4>
                            </Fragment> : null}
                        </Col>
                        <Col lg={6}>
                            <Button className={'float-end'} disabled={!fields.length} name={'save'} type={'button'}
                                    onClick={onSave}>
                                {t(lang, 'items.common.save')}
                            </Button>
                        </Col>
                    </Row>

                    <hr/>

                    {loading ? <SpinnerComponent/> : productList.map((item, index) => <div key={index}>
                            <h5 className={'text-center text-decoration-underline'}>{item.name}</h5>
                            {
                                item.productList.map((product, index) => (
                                    <Row className={`mb-2 fs-0`} key={product.item_id}>
                                        <Col lg={8} className={'d-flex gap-2'}>
                                            <span>{index + 1}.</span>
                                            <strong>{product?.name}</strong>
                                        </Col>
                                        <Col lg={2} className={'d-flex'}>
                                            <span>{numeral.formats[numberFormat]?.format(+product?.price?.common_price?.amount)}<strong
                                                className={'text-info ms-2'}>UZS</strong></span>
                                        </Col>
                                        <Col lg={2} className={'d-flex justify-content-end gap-3'}>
                                            <IconButton icon={'trash'} variant={'falcon-danger'} size={'sm'}
                                                        onClick={() => deleteProduct(product)}/>
                                        </Col>
                                    </Row>
                                ))
                            }
                            <hr/>
                        </div>
                    )}

                    <ContractorMassModal
                        show={show}
                        setShow={setShow}
                        onMassImport={onMassImport}
                    />

                </Form>
            </FormProvider>
        </Fragment>
    );
};

export default ContractorPriceForm;
