import { Card, Col, Form, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useFormContext, Controller } from "react-hook-form"
import { Translate, selectLang } from "../../../../app/store/reducers/main/mainReducer"
import { selectActiveOrganization } from "../../../../app/store/reducers/auth/authReducer"
import FindCompany from "../../organization/FindCompany"
import { useState } from "react"
import { loadBankInfo } from "../../../../app/store/reducers/roaming/roamingReducer"
import FindCompanySearchable from "../../organization/FindCompanySearchable";

const OwnerInfo = () => {
    const { register, setValue, formState: { errors }, control } = useFormContext()
    const [companyDetails, setCompanyDetails] = useState({})
    const [bankInfo, setBankInfo] = useState({})
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang)
    const t = Translate
    const onChangeHandler = async data => {
        if(data) {
            setCompanyDetails(data)
            setValue('owner.name', data.name)
            setValue('owner.tin', data.person_num || data.inn)
            setValue('owner.fizTin', data.director_pinfl || data.director_inn)
            setValue('owner.fio', data.director)
            setValue('owner.address', data.address)
            setValue('owner.bankId', data.mfo)
            setValue('owner.account', data.account)
            if(data.mfo) {
                setBankInfo(await loadBankInfo(data.mfo, lang))
            }
        }
    }
    return (
        <Card>
            <Card.Header>
                <h5 className="mb-0">{t(lang, "roaming.contracts.add.owner_info.title")}</h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    {companyDetails?.branch &&
                        <Col sm="12" className="mb-3">
                            <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.branch")}</Form.Label>
                            <Form.Control placeholder="Филиал" />
                        </Col>
                    }
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.name")}</Form.Label>
                        <Form.Control
                            {...register("owner.name", {
                                // required: "edi.common.forms.validations.is_required"
                            })}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.name")}
                            className={errors?.owner?.name?.message && "is-invalid"}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.owner?.name?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl")}</Form.Label>
                        <Controller
                            name="owner.tin"
                            control={control}
                            // rules={{ required: true }}
                            render={() => (
                                <FindCompanySearchable
                                    defaultInn={activeOrganization.pinfl || activeOrganization.inn}
                                    onChange={onChangeHandler}
                                    disabled={true}
                                    inputProps={{
                                        placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                    }}
                                />
                            )}
                        />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.full_name_person")}</Form.Label>
                        <Form.Control
                            {...register("owner.fio")}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.full_name_person")}
                        />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl_person")}</Form.Label>
                        <Form.Control
                            {...register("owner.fizTin")}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl_person")}
                        />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.work_phone_number")}</Form.Label>
                        <Form.Control placeholder="Телефон" />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.mobile_number")}</Form.Label>
                        <Form.Control placeholder={t(lang, "roaming.contracts.add.owner_info.mobile_number")} />
                    </Col>
                    <Col sm="12" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.address")}</Form.Label>
                        <Form.Control {...register("owner.address")} placeholder={t(lang, "roaming.contracts.add.owner_info.address")} />
                    </Col>
                    <Col sm="4" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.bank_account")}</Form.Label>
                        <Form.Control {...register("owner.account")} placeholder={t(lang, "roaming.contracts.add.owner_info.bank_account")} />
                    </Col>
                    <Col sm="5" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.bank_address")}</Form.Label>
                        <Form.Control defaultValue={bankInfo?.name} placeholder={t(lang, "roaming.contracts.add.owner_info.bank_address")} />
                    </Col>
                    <Col sm="3" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.mfo")}</Form.Label>
                        <Form.Control {...register("owner.bankId")} placeholder={t(lang, "roaming.contracts.add.owner_info.mfo")} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default OwnerInfo
