import React, {cloneElement, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {
    addBoardColumn, editBoardColumn, selectBoards,
    selectKanbanItems, selectWithTasks,
    statusUpdate
} from "../../../app/store/reducers/kanban/kanbanReducer";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import ReactSelect from "react-select";
import IconButton from "../../common/IconButton";
import {ContractorDebtBoardType} from "../../../enum/KanbanWrapper";

const AddAnotherForm = ({children, defaultValue, columnId}) => {

    const {id} = useParams();
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false);
    const withTasks = useSelector(selectWithTasks);


    const methods = useForm({
        defaultValues: {
            name: defaultValue || null,
        }
    })

    const handleClose = () => {
        setShowForm(false)
    }

    const onSubmit = ({name}) => {
        setLoading(true)

        const addPayload = {
            id,
            name
        };

        const editPayload = {
            id: columnId,
            name,
        }

        if (columnId) {
            dispatch(editBoardColumn(id, editPayload))
                .then(()=> {
                    setShowForm(false)
                    methods.reset()
                })
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        } else  {
            dispatch(addBoardColumn(addPayload))
                .then(()=> {
                    setShowForm(false)
                    methods.reset()
                })
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        }

    }

    return (
        <>

            {!!children ? cloneElement(children, {...children.props, onClick: () => setShowForm(true)}) : (
                <IconButton
                    disabled={withTasks?.type === ContractorDebtBoardType}
                    variant="primary"
                    className="d-block w-100"
                    icon="plus"
                    iconClassName="me-1"
                    onClick={() => setShowForm(true)}
                >
                    {t(lang, 'task.board.add.new-status.button.title')}
                </IconButton>
            )}

            <Modal show={showForm} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t(lang,  defaultValue ?  "task.board.common.edit" : 'task.board.common.add')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form>
                            <Form.Group>
                                <Form.Label>{t(lang, 'task.board.form.title')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    className="mb-2"
                                    autoFocus={true}
                                    defaultValue={defaultValue}
                                    {...methods.register('name', {
                                        required: t(lang, 'task.board.common.forms.validations.is_required'),
                                    })}
                                    placeholder={t(lang, 'task.board.form.title.placeholder')}
                                />
                                <Form.Control.Feedback type={'invalid'}>{methods?.formState?.errors?.name?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-dark"
                        type="button"
                        onClick={() => setShowForm(false)}
                    >
                        {t(lang, 'task.board.common.cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        form={'form-column'}
                        type="submit"
                        disabled={loading}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        {t(lang, 'task.board.common.add')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};


export default AddAnotherForm;
