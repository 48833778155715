import React, {cloneElement, Fragment, useEffect, useState} from 'react';
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import SelectContractor from "../../../hippo/crm/contractor/SelectContractor";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import {
    editTaskInColumnContractorDebtDate, selectBoards,
    selectModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {toast} from "react-toastify";
import dayjs from "dayjs";

const SelectContractorModal = ({children, edit, handlerClose, ...props}) => {
    const dispatch = useDispatch();
    const dateFormat = useSelector(selectDateFormat);
    const modalContent = useSelector(selectModalContent);
    const boards = useSelector(selectBoards);

    const lang = useSelector(selectLang);
    const t = Translate;

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const {control, clearErrors, setError, formState: {errors}, setValue} = useFormContext();

    const contractor = useWatch({name: 'contractor.id', exact: true})
    const debt_promise_date = useWatch({name: 'contractor.debt_promise_date', exact: true})

    const handleClose = () => {
        setShow(false)

    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (contractor && !debt_promise_date) {
            setError('contractor.debt_promise_date', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})
        } else if (debt_promise_date && !contractor) {
            setError('contractor.id', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})
        } else {
            clearErrors('contractor.debt_promise_date')
            clearErrors('contractor.id')
        }
    }, [contractor, debt_promise_date]);

    const handleEditDebtDate = () => {
        setLoading(true)

        const board_id = boards.find(board => board.id === modalContent?.board_id)?.id

        const data = {
            board_column_id: modalContent?.board_column_id,
            board_column_task_id: modalContent?.id,
            contractor: {
                id: modalContent.contractor?.id,
                debt_promise_date: dayjs(debt_promise_date).format('YYYY-MM-DD')
            }
        }

        dispatch(editTaskInColumnContractorDebtDate(board_id, data))
            .then(res => {
                setValue('contractor.debt_promise_date', new Date(res.contractor.debt_promise_date))
                toast.success(t(lang, 'warehouse.operation.item.common.toast.success'))
                handleClose()
                handlerClose && handlerClose()
            })
            .catch((e) => {
                toast.error(t(lang, 'warehouse.operation.item.common.toast.error'))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Fragment>

            {children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton variant={'falcon-primary'} size={'sm'} icon={'plus'} onClick={handleShow} {...props}>
                    {t(lang, 'crm.draft_payment.datatable.header.filter.contractor')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t(lang, 'crm.common.datatable.contractor')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'crm.common.datatable.contractor')}</Form.Label>
                        <Controller
                            name={'contractor.id'}
                            rules={{required: debt_promise_date ? t(lang, "crm.common.forms.validations.is_required") : false}}
                            render={({field}) => (
                                <SelectContractor
                                    isDisabled={edit}
                                    defaultValue={field.value}
                                    onChange={(contractor) => {
                                        if (contractor) {
                                            field.onChange(contractor?.id);
                                            // clearErrors('contractor');
                                        } else {
                                            field.onChange(null);
                                            // setError('contractor', {type: 'required', message: t(lang, 'task.board.common.forms.validations.is_required')});
                                        }
                                    }}
                                    placeholder={t(lang, 'task.board.common.select.placeholder')}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid" className={'d-block'}>
                            {errors.contractor?.id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t(lang, 'warehouse.operation.item.common.date')}</Form.Label>
                        <Controller
                            name={'contractor.debt_promise_date'}
                            control={control}
                            rules={{required: contractor ? t(lang, "crm.common.forms.validations.is_required") : false}}
                            render={({field}) => (
                                <DatePicker
                                    isClearable
                                    className={classNames(`form-control`, {
                                        'is-invalid': errors.contractor?.debt_promise_date
                                    })}
                                    placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                    dateFormat={dateFormat}
                                    selected={field.value}
                                    onChange={date => field.onChange(date)}
                                />
                            )} />
                        <Form.Control.Feedback type="invalid" className={'d-block'}>
                            {errors.contractor?.debt_promise_date?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                {edit && <Modal.Footer>
                    <Button onClick={handleClose} variant="falcon-danger" className="mx-2">
                        {t(lang, "task.board.common.close")}
                    </Button>
                    <Button onClick={handleEditDebtDate} variant="falcon-success" className="mx-2">
                        {
                            loading ?
                                <Spinner className="align-middle me-2" animation="border" role="switch"
                                         size="sm"/>
                                : t(lang, "task.board.common.save")
                        }
                    </Button>
                </Modal.Footer>}
            </Modal>

        </Fragment>
    );
};

export default SelectContractorModal;
