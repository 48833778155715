import {useDispatch, useSelector} from "react-redux";
import {Button, Dropdown} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {excelFileUploader} from "../../../../helpers/excel-uploader";
import {TYPE_RETURN_INVOICE} from "../../../../enum/ExcelTemplateTypes";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectOrganizationTemplates} from "../../../../app/store/reducers/excel/excelReducer";
import {uploadReturnInvoiceToExcelAsync} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnInvoiceDownloadExcel} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const UploadReturnInvoiceToExcel = ({id, number, ...props}) => {
    const dispatch = useDispatch()
    const [returnInvoiceTypeTemplates, setReturnInvoiceTypeTemplates] = useState([]);
    const organizationTemplates = useSelector(selectOrganizationTemplates);
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        const templates = organizationTemplates.filter(template => template.type === TYPE_RETURN_INVOICE) || [];
        setReturnInvoiceTypeTemplates(templates);
    }, [organizationTemplates])

    const uploadReturnInvoice = (uri) => {
       if (dispatch(checkPermission(PermissionEdiReturnInvoiceDownloadExcel))) {
           setLoading(true);

           uploadReturnInvoiceToExcelAsync({uri: uri, id: id})
               .then(loadedContent => {
                   excelFileUploader({
                       content: loadedContent,
                       fileName: `Возвратная счёт-фактура_${number}`
                   })
               })
               .catch(error => console.log(error))
               .finally(() => setLoading(false))
       }
       else dispatch(toastPermission())

    }

    return (
        <>
            {returnInvoiceTypeTemplates.length > 1
                ?
                <Dropdown {...props}>
                    <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                        {loading
                            ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                            : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                        }
                        <span className="d-none d-sm-inline-block">
                        {t(lang, 'edi.common.export_to_excel')}
                    </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='py-2'>
                        {returnInvoiceTypeTemplates.map((template, index) => (
                            <Dropdown.Item key={index} onClick={() => uploadReturnInvoice(template.uri)}>
                                {t(lang, 'main.common.file.upload.template_name.prefix', {template_name: t(lang, `common.excel.template.name.${template.name}`)})}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                :
                <Button {...props} variant="falcon-default" size="sm" onClick={() => {
                    const [defaultTemplate] = returnInvoiceTypeTemplates;
                    uploadReturnInvoice(defaultTemplate.uri);
                }}>
                    {loading
                        ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                        : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                    }

                    <span className="d-none d-sm-inline-block">{t(lang, 'edi.common.export_to_excel')}</span>
                </Button>
            }
        </>
    );
};

export default UploadReturnInvoiceToExcel;
