import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {waybillV2SyncAsync} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import IconButton from "../../../../../common/IconButton";
import PropTypes from "prop-types";

const WaybillV2Sync = ({id, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false);

    const syncWaybill = () => {
        setLoading(true);
        waybillV2SyncAsync(id)
            .then()
            .catch()
            .finally(() => setLoading(false))
    };

    return (
        <IconButton
            icon={'fa-refresh'}
            variant="falcon-primary"
            onClick={syncWaybill}
            size="sm"
            iconClassName="me-1"
            disabled={loading}
            {...props}>
            {t(lang, "roaming.empowerment.sync")}
        </IconButton>
    );
};


WaybillV2Sync.propTypes = {
    id: PropTypes.string
}

WaybillV2Sync.defaulProps = {
    id: ""
}


export default WaybillV2Sync;
