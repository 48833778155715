import React, {Fragment, useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import AdditionalInformationRailwayV2Point from "./AdditionalInformationRailwayV2Point";
import {useDispatch, useSelector} from "react-redux";
import {
    getWaybillRegionAsync,
    getWaybillStationsAsync, selectRegion
} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";

const AdditionalInformationRailwayV2 = () => {

    const regions = useSelector(selectRegion);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!regions.length) {
            dispatch(getWaybillRegionAsync())
        }
        dispatch(getWaybillStationsAsync())

    }, []);


    return (
        <Fragment>
            <Row className={'my-3'}>
                <Col sm={12} md={6}>
                    <AdditionalInformationRailwayV2Point
                        title={'roaming.waybill.v2.send.freight_forwarder.railway.loading.point'}
                        titleLoadingPoint={'roaming.waybill.v2.send.railway.loading_point.title'}
                        railwayName={'railway_loading_point'}
                        trusteeName={'loading_trustee'}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <AdditionalInformationRailwayV2Point
                        title={'roaming.waybill.v2.send.freight_forwarder.railway.unloading.point'}
                        titleLoadingPoint={'roaming.waybill.v2.send.railway.unloading_point.title'}
                        railwayName={'railway_unloading_point'}
                        trusteeName={'unloading_trustee'}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default AdditionalInformationRailwayV2;
