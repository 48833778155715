import React, {Fragment, useEffect, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {Controller, FormProvider, useForm} from "react-hook-form";
import SelectCertificate from "../../eimzo/SelectCertificate";
import {getRegistrationVerifyHashCodeAsync, setOrganizationInn} from "../../../../app/store/reducers/auth/authReducer";
import {CertificatePfx} from "../../../../app/plugins/eimzo";
import {activateCertificateAsync, createPkcs7WithTimestamp} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {toast} from "react-toastify";

const RegisterOrganizationInn = () => {

        const lang = useSelector(selectLang);
        const t = Translate;

        const methods = useForm({
            defaultValues: {
                certificate: null,
                inn: null
            }
        })

        const [show, setShow] = useState(false);
        const [loading, setLoading] = useState(false);
        const [signatureHashCode, setSignatureHashCode] = useState(null);
        const [activeCertificate, setActiveCertificate] = useState(null);


        const handleClose = () => {
            setShow(false)
            setLoading(false)
            methods.reset()
        };
        const handleShow = () => setShow(true);

        useEffect(() => {
            const formData = methods.getValues();

            if (signatureHashCode) {
                activateCertificateAsync(formData.certificate)
                    .then(activatedCertificate => {
                        setActiveCertificate(activatedCertificate);
                    })
                    .catch(({invalidPassword}) => {
                        if (invalidPassword) {
                            toast.error(t(lang, 'edi.eimzo.notification.wrong_password'))
                        } else {
                            toast.error(t(lang, 'crm.common.toast.error'))
                        }
                    })
                    .finally(() => setLoading(false))
            }
        }, [signatureHashCode])

        useEffect(() => {
            if (activeCertificate) {
                createPkcs7WithTimestamp(signatureHashCode, activeCertificate.keyId)
                    .then(({pkcs7}) => {
                        const formData = methods.getValues();

                        setOrganizationInn({
                            inn: formData.inn,
                            signature: pkcs7
                        })
                            .then(() => {
                                methods.reset();
                            })
                            .catch(() => {
                                toast.error(t(lang, 'crm.common.toast.error'))
                                methods.trigger();
                            })
                            .finally(() => setLoading(false))
                    })
                    .catch(() => {
                        toast.error(t(lang, 'crm.common.toast.error'))
                    })
                    .finally(() => setLoading(false))
            }
        }, [activeCertificate])

        const onCertificateChanged = (certificate) => {
            if (certificate) {
                const pfxCertificate = new CertificatePfx(certificate)

                methods.setValue('certificate', certificate)
                methods.setValue('inn', pfxCertificate?.innNumber || pfxCertificate?.pinfl)
            }
        }

        const onSubmit = (formData) => {
            setLoading(true)
            setSignatureHashCode(null)
            getRegistrationVerifyHashCodeAsync(formData.inn)
                .then(hashCode => setSignatureHashCode(hashCode))
                .catch(() => toast.error(t(lang, 'crm.common.toast.error')))
        }


        return (
            <Fragment>
                <b className={'cursor-pointer text-decoration-underline text-primary ms-2'} onClick={handleShow}>{t(lang, 'hippo.user.set.inn')}</b>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'hippo.user.set.inn')}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <FormProvider {...methods}>
                            <Form id={'set-inn'} onSubmit={methods.handleSubmit(onSubmit)}>
                                <Form.Group>
                                    <Form.Label>{t(lang, 'hippo.user.choose_certificate')}</Form.Label>
                                    <Controller
                                        name={'certificate'}
                                        rules={{
                                            required: t(lang, "edi.common.forms.validations.is_required"),
                                        }}
                                        render={() => (
                                            <SelectCertificate
                                                placeholder={t(lang, "edi.common.select_button")}
                                                onChange={onCertificateChanged}
                                                hideSelectedOptions
                                                classNamePrefix={'react-select'}
                                            />
                                        )}/>
                                    <Form.Control.Feedback className={'d-block'} type={'invalid'}>{methods.formState.errors?.certificate?.message}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t(lang, 'hippo.user.settings.profile.inn')}</Form.Label>
                                    <Form.Control
                                        readOnly
                                        {...methods.register('inn')}
                                        placeholder={t(lang, 'hippo.user.settings.profile.inn')}
                                    />
                                </Form.Group>
                            </Form>
                        </FormProvider>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={loading} type={'submit'} form={'set-inn'} variant="primary">
                            {t(lang, 'edi.shipments.shipment.navigation.button_customer_approve')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
;

export default RegisterOrganizationInn;
