import React from 'react';
import {useSelector} from "react-redux";
import {selectEdiReturnBreadcrumbData} from "../../../app/store/reducers/order/orderReducer";
import {useLocation} from "react-router";
import {Breadcrumb, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const EdiReturnBreadcrumb = () => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const selectEdiReturnBreadcrumb = useSelector(selectEdiReturnBreadcrumbData)
    const {pathname} = useLocation()
    const order =  pathname.includes('return-order')
    const shipment =  pathname.includes('return-shipment')
    const invoice =  pathname.includes('return-invoice')

    return (
        <div className={'position-sticky'} style={{zIndex: 999, top: '4.3125rem'}}>
            {
                order && <Card className={'mb-3'}>
                    <Card.Header>
                        <Breadcrumb>
                            <li className={'breadcrumb-item'}>
                                <Link
                                    to={'/edi/return-orders'}
                                    className={classNames('text-decoration-none', {
                                        'text-secondary': pathname === '/edi/return-orders' && true
                                    })}>
                                    {t(lang, 'edi.dashboard.return_orders.title')}
                                </Link>
                            </li>
                            {
                                selectEdiReturnBreadcrumb?.info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <span>{t(lang, 'edi.return_order.title')} №: {selectEdiReturnBreadcrumb?.info?.number}</span>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link
                                    className={'text-decoration-none'}
                                    to={'/edi/return-shipments'}>
                                    {t(lang, 'edi.return_shipment.datatable.header.title')}
                                </Link></li>
                            <li className={'breadcrumb-item'}>
                                <Link
                                    className={'text-decoration-none'}
                                    to={'/edi/return-invoices'}>
                                    {t(lang, 'edi.return_invoice.datatable.header.title')}
                                </Link>
                            </li>
                        </Breadcrumb>
                    </Card.Header>
                </Card>
            }

            {
                shipment && <Card className={'mb-3'}>
                    <Card.Header>
                        <Breadcrumb className={'d-flex align-items-center'}>
                            <li className={'breadcrumb-item'}>
                                <Link
                                    className={'text-decoration-none'}
                                    to={'/edi/return-orders'}>
                                    {t(lang, 'edi.dashboard.return_orders.title')}
                                </Link>
                            </li>
                            {
                                selectEdiReturnBreadcrumb?.return_order?.info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <Link
                                        className={'text-decoration-none'}
                                        to={'/edi/return-order/' + selectEdiReturnBreadcrumb?.return_order?.id}>
                                        {t(lang, 'edi.return_order.title')} №: {selectEdiReturnBreadcrumb?.return_order?.info?.number}
                                    </Link>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link
                                    to={'/edi/return-shipments'}
                                    className={classNames('text-decoration-none', {
                                        'text-secondary': pathname === '/edi/return-shipments' && true
                                    })}>
                                    {t(lang, 'edi.return_shipment.datatable.header.title')}
                                </Link>
                            </li>
                            {
                                selectEdiReturnBreadcrumb?.return_shipment_info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <span>{t(lang,'edi.common.return_shipment')} №: {selectEdiReturnBreadcrumb?.return_shipment_info?.number}
                                    </span>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link to={'/edi/return-invoices'} className={'text-decoration-none'}>
                                    {t(lang, 'edi.dashboard.return_invoices.title')}
                                </Link>
                            </li>
                        </Breadcrumb>
                    </Card.Header>
                </Card>
            }

            {
                invoice && <Card className={'mb-3'}>
                    <Card.Header>
                        <Breadcrumb>
                            <li className={'breadcrumb-item'}><Link to={'/edi/return-orders'}>{t(lang, 'edi.dashboard.return_orders.title')}</Link></li>
                            {
                                selectEdiReturnBreadcrumb?.return_shipment?.return_order?.info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <Link
                                        to={'/edi/return-order/'+ selectEdiReturnBreadcrumb?.return_shipment?.return_order?.id}
                                        className={'text-decoration-none'}>
                                        {t(lang, 'edi.return_order.title')} №: {selectEdiReturnBreadcrumb?.return_shipment?.return_order?.info?.number}
                                    </Link>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link
                                    className={'text-decoration-none'}
                                    to={'/edi/return-shipments'}>
                                    {t(lang, 'edi.return_shipment.datatable.header.title')}
                                </Link>
                            </li>
                            {
                                selectEdiReturnBreadcrumb?.return_shipment?.return_shipment_info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <Link
                                        className={'text-decoration-none'}
                                        to={'/edi/return-shipment/' + selectEdiReturnBreadcrumb?.return_shipment?.id}>
                                        {t(lang,'edi.common.return_shipment')} №: {selectEdiReturnBreadcrumb?.return_shipment?.return_shipment_info?.number}
                                    </Link>
                                </li>
                            }
                            <li className={'breadcrumb-item'}>
                                <Link
                                    to={'/edi/return-invoices'}
                                    className={classNames('p-0 text-decoration-none', {
                                        'text-secondary': pathname === '/edi/return-invoices' && true
                                    })}>
                                    {t(lang, 'edi.dashboard.return_invoices.title')}
                                </Link>
                            </li>
                            {
                                selectEdiReturnBreadcrumb?.return_invoice_info?.number &&
                                <li className={'breadcrumb-item'}>
                                    <span>{t(lang, 'main.templates.type.return_invoice')}: {selectEdiReturnBreadcrumb?.return_invoice_info?.number}</span>
                                </li>
                            }
                        </Breadcrumb>
                    </Card.Header>
                </Card>
            }


        </div>
    );
};

export default EdiReturnBreadcrumb;
