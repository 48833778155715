import React, {Fragment, useEffect, useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import FalconCloseButton from "./FalconCloseButton";
import SelectOrganizationCatalog from "../hippo/item/components/SelectOrganizationCatalog";
import SelectTasnifCatalog from "../hippo/item/components/SelectTasnifCatalog";
import IconButton from "./IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import SelectExactCatalog from "../hippo/item/components/SelectExactCatalog";
import Divider from "./Divider";
import classNames from "classnames";
import {selectCompanyCatalogs} from "../../app/store/reducers/roaming/roamingReducer";

const CatalogLinkSelector = ({defaultCatalog, onChange, className, linkProps, textButton, onContextMenu, ...props}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const companyCatalogs = useSelector(selectCompanyCatalogs);
    const [show, setShow] = useState(false);
    const [catalog, setCatalog] = useState(null);

    useEffect(() => {
        if (textButton) {
            setCatalog(companyCatalogs.find(i => i.class_code === defaultCatalog));
        } else {
            if (catalog?.class_code !== defaultCatalog?.class_code)
                setCatalog(defaultCatalog);
        }
    }, [defaultCatalog, companyCatalogs])

    const link = <span onContextMenu={onContextMenu} onClick={() => setShow(true)}
                       className={className ? className : 'text-dark cursor-pointer fs--1'}
                       {...linkProps}
    >
        {catalog ? `${catalog.class_code}` : t(lang, 'items.common.click_to_select_catalog_code')}
    </span>

    const onChangeHandler = (option) => {
        setCatalog(option);
        onChange(option);
        option && setShow(false);
    }

    return (
        <>
        {!textButton ? <Fragment>
            {catalog && <OverlayTrigger overlay={catalog?.name && <Tooltip>{catalog.name}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!catalog && link}
        </Fragment> : <Button variant="outline-primary"
                              className={classNames('w-100', className)} {...props}
                              onClick={() => setShow(true)}>
            {catalog ? catalog?.class_code : textButton || t(lang, 'edi.common.button.select')}
        </Button>
}

            {show && <Modal show={true}
                            onHide={() => setShow(false)}
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
            >
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.common.catalog_link_selector_modal.title')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-2">
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.catalog_link_selector.organization_catalogs')}</Form.Label>
                                <SelectOrganizationCatalog onChange={onChangeHandler}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.catalog_link_selector.tasnif_catalogs')}</Form.Label>
                                <SelectTasnifCatalog onChange={onChangeHandler}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.catalog_link_selector.exact_catalog')}</Form.Label>
                                <SelectExactCatalog onChange={onChangeHandler}/>
                            </Form.Group>
                        </Col>
                    </Row>


                    {catalog &&
                        <>
                            <Divider/>
                            <Row>
                                <Col xs={12}>
                                    <h5 className="text-info">{t(lang, 'items.common.catalog_link_selector.chosen_catalog')}</h5>
                                    <div className="mt-3 fw-bold fs--1">
                                        <span className="text-primary">{catalog.class_code} - </span>
                                        <span className="text-dark">{catalog.name}</span>
                                        <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon={faTrash}
                                            iconClassName={"text-danger"}
                                            className="ms-2"
                                            title={t(lang, 'items.common.delete')}
                                            onClick={() => onChangeHandler(null)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShow(false)}>
                        {t(lang, 'items.common.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>}
        </>
    )
};

export default CatalogLinkSelector;
