import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterAdminFilterAsync,
    loadAdminOrganizationPaymentsAsync,
    loadAdminOrganizationPaymentsCountAsync,
    selectAdminPayments,
    selectAdminPaymentsCount,
    selectAdminPaymentsFilter
} from "../../../../app/store/reducers/billing/billingReducer";
import numeral from "numeral";
import {selectLang, selectSystemMoneyFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import SoftBadge from "../../../common/SoftBadge";
import {methodPayment} from "../../../../enum/BillingWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";

const OrganizationPayments = ({id}) => {

    const dispatch = useDispatch()

    const payments = useSelector(selectAdminPayments)
    const filter = useSelector(selectAdminPaymentsFilter)
    const count = useSelector(selectAdminPaymentsCount)
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);

    const lang = useSelector(selectLang);
    const t = Translate;

    const subtotal = payments.reduce((acc, cur) => acc + cur.amount, 0)

    const onPageChange = (page) => dispatch(changeFilterAdminFilterAsync({...filter, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterAdminFilterAsync({...filter, limit: limit, page: 1}));


    useEffect(() => {
        dispatch(loadAdminOrganizationPaymentsAsync({...filter, organization_id: id}))
        dispatch(loadAdminOrganizationPaymentsCountAsync({...filter, organization_id: id}))
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "admin.common.payments.datatable.created_date"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {dayjs(original.created_at).format("YYYY-MM-DD")}
                    </>
                );
            }
        },
        {
            accessor: 'method',
            Header: t(lang, "admin.payments.datatable.header.organization.column.method"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <SoftBadge bg={methodPayment(original.method).bg}>
                        <span>{methodPayment(original.method).name}</span>
                        <FontAwesomeIcon icon={methodPayment(original.method).icon} className={'ms-2'}/>
                    </SoftBadge>
                )
            }
        },
        {
            accessor: 'amount',
            Header: t(lang, 'admin.common.payments.datatable.amount'),
            headerProps: {className: 'pe-2 text-end'},
            cellProps: {className: 'text-end'},
            totalInfo: numeral.formats[systemMoneyFormat].format(subtotal),
            totalInfoClassName: 'text-end fw-bold',
            Cell: ({row: {original}}) => {
                return (
                    <span>{numeral.formats[systemMoneyFormat].format(original.amount)}</span>
                )
            }
        },
    ]

    return (
        <>

            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={payments}
            >
                <Card className="mb-3">
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filter.limit}
                            page={filter.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default OrganizationPayments;
