import React, {useState} from 'react';
import IconButton from "../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import CountableProgressBar from "../../common/CountbleProgressBar";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {AwaitCustomerApprove, AwaitExecutorApprove} from "../../../enum/OrderStatus";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {
    CustomerApproveAsync,
    ExecutorApproveAsync, loadCountOrdersAsync,
    loadOrdersAsync, selectFilterOptions
} from "../../../app/store/reducers/order/orderReducer";
import {useParams} from "react-router";

const MassOrderAccept = ({rowValues}) => {
    const {viewer} = useParams()
    const [showCountableProgressBar, setShowCountableProgressBar] = useState(false);
    const [selectedRowsCount, setSelectedRowsCount] = useState(0);
    const [massAcceptResults, setMassAcceptResults] = useState([]);
    const activeOrganization = useSelector(selectActiveOrganization);
    const filter = useSelector(selectFilterOptions);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const massAcceptOrderHandler = async () => {
        const selectedRowValues = rowValues.filter(value => value.isSelected);
        setSelectedRowsCount(selectedRowValues.length);
        setShowCountableProgressBar(true);

        for (let i = 0; i < selectedRowValues.length; i++) {
            const order = selectedRowValues[i].original;

            const allowCustomerApprove = activeOrganization.inn === order.customer.inn && order.status === AwaitCustomerApprove;
            const allowExecutorApprove = activeOrganization.inn === order.executor.inn && order.status === AwaitExecutorApprove;

            if (allowCustomerApprove) {
                await CustomerApproveAsync({id: order.id, comment: ""})
                    .then(() => setMassAcceptResults(prev => [...prev, true]))
                    .catch(() => setMassAcceptResults(prev => [...prev, false]))
            } else if (allowExecutorApprove) {
                await ExecutorApproveAsync({id: order.id, comment: ""})
                    .then(() => setMassAcceptResults(prev => [...prev, true]))
                    .catch(() => setMassAcceptResults(prev => [...prev, false]))
            } else {
                setMassAcceptResults(prev => [...prev, false]);
            }
        }
    }

    const finishMassAccept = () => {
        dispatch(loadOrdersAsync(viewer,{...filter, viewer_inn: activeOrganization.inn}));
        dispatch(loadCountOrdersAsync(viewer,{...filter, viewer_inn: activeOrganization.inn}));

        setSelectedRowsCount(0);
        setMassAcceptResults([]);
        setShowCountableProgressBar(false);
    }

    return (
        <>
            <CountableProgressBar show={showCountableProgressBar} length={selectedRowsCount} results={massAcceptResults} onClose={finishMassAccept} />

            <IconButton icon="check" variant="falcon-primary" size="sm" className="m-1" onClick={massAcceptOrderHandler}>
                {t(lang, 'edi.common.button.approve')}
            </IconButton>
        </>
    );
};

export default MassOrderAccept;
