import React, {Fragment} from 'react';
import {Card, Col, Dropdown, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCashbox, selectedCurrencyForCashBox,
    setCurrency
} from "../../../app/store/reducers/cashbox/CashboxReducer";
import {
    totalAmountCardInCashBox,
    totalAmountCashInCashBox,
    totalAmountInCashBox
} from "../../../enum/cash_box/cashBoxWrapper";
import numeral from "numeral";
import {selectLang, selectNumberFormat, Translate} from "../../../app/store/reducers/main/mainReducer";
import {ContractorPaymentTypes, PaymentTypeCashCode} from "../../../enum/ContractorWrapper";
import Background from "../../common/Background";
import cardImage from "../../../assets/img/icons/spot-illustrations/corner-5.png";
import cardImage2 from "../../../assets/img/icons/spot-illustrations/corner-2.png";
import {selectCurrency} from "../../../app/store/reducers/currency/currencyReducer";


const CashBoxAmountStatistics = () => {
    const dispatch = useDispatch()

    const t = Translate;
    const lang = useSelector(selectLang);
    const cashbox = useSelector(selectCashbox)
    const numberFormat = useSelector(selectNumberFormat);
    const currency = useSelector(selectCurrency)
    const selectedCurrency = useSelector(selectedCurrencyForCashBox)

    return (
        <Fragment>
            <Row className={'my-3 align-content-stretch px-3 mb-2'}>
                <Col xs={12} md={6} lg={8} className={'px-1'}>
                    <Card className={'p-2 h-100 p-1'}>
                        {/*<Background image={cardImage2} className="bg-card"/>*/}
                        <div className={'d-flex flex-wrap justify-content-between align-content-between h-100'}>
                            <h2>{t(lang, 'cash_boxes.total.amount.title')}</h2>
                            <div className={'d-flex gap-1 align-items-center'}>
                                <h2> {currency.length ? numeral['formats'][numberFormat].format(totalAmountInCashBox(cashbox, currency) / selectedCurrency?.rate) : 0}</h2>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant={'link'}
                                        className="p-0 pb-2 "
                                    >
                                        <strong className={'text-info ms-2 fs-3'}>{selectedCurrency?.name}</strong>
                                    </Dropdown.Toggle>
                                    {currency.filter(i => i.is_active).length > 1 && <Dropdown.Menu
                                        style={{minWidth: '5rem'}}
                                        className="py-1">
                                        {currency
                                            .filter(i => i.is_active)
                                            .filter(i => i.id !== selectedCurrency?.id)
                                            .map(i => (
                                                <Dropdown.Item key={i.id}
                                                               onClick={() => dispatch(setCurrency(i))}
                                                               as="div"
                                                               className="px-3 py-2 cursor-pointer">
                                                    <strong className={'fs-0'}>{i.name}</strong>
                                                </Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>}
                                </Dropdown>
                            </div>
                            <div className={'w-100 d-flex gap-2 flex-wrap'}>
                                {
                                    ContractorPaymentTypes.filter(i => i.value !== PaymentTypeCashCode).map((i) => (
                                        <div className={'px-2'} style={{backgroundColor: i.bg}} key={i.value}>{t(lang, i.label)}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </Card>
                </Col>

                <Col xs={12} md={6} lg={4} className={'px-1'}>
                    <Card className={'p-2 h-100 p-1'}>
                        <Background image={cardImage2} className="bg-card"/>
                        <p className={'text-center fw-bold mb-1'}>{t(lang, ContractorPaymentTypes[0].label)}</p>
                        {totalAmountCashInCashBox(cashbox)
                            .map((i, index) => <div key={index} className='d-block text-end fs-0 fw-bold'>
                                <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                <strong className={'ms-2 fw-bold text-info'}>{i?.name}</strong>
                            </div>)}
                    </Card>
                </Col>
            </Row>

            <Row className={'justify-content-between mb-2 align-content-stretch px-3'}>
                {
                    ContractorPaymentTypes
                        .filter(i => i.value !== PaymentTypeCashCode)
                        .map((i, index) => <Col xs={6} md={4} lg={4} xl={2} key={index} className={'mb-xl-0 mb-2 px-1'}>
                                <Card className={'h-100 p-1'}>
                                    <Background image={cardImage} className="bg-card"/>
                                    <p className={'text-center fw-bold mb-1'}>{t(lang, i.label)}</p>
                                    {
                                        totalAmountCardInCashBox(cashbox)
                                            .filter(item => item.money_type === i.value)
                                            .map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className='d-block text-end fs-0 fw-bold'
                                                    >
                                                        <strong className={'text-700'}>
                                                            {numeral.formats[numberFormat].format(item?.total)}
                                                        </strong>
                                                        <strong className={'ms-2 fw-bold text-info'}>{item?.name}</strong>
                                                    </div>
                                                )
                                            )
                                    }
                                </Card>
                            </Col>
                        )
                }
            </Row>
        </Fragment>
    );
};

export default CashBoxAmountStatistics;
