import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorReturnShipmentSlice = createSlice({
    name: 'contractorReturnShipment',
    initialState: {
        returnShipments: [],
        countReturnShipments: 0,
        filterOptions: {page: 1, limit: 10, status: 4},
    },
    reducers: {
        updateReturnShipments: (state, action) => {
            state.returnShipments = action.payload
        },
        updateCountReturnShipments: (state, action) => {
            state.countReturnShipments = action.payload
        },
        updateFilterReturnShipment: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorReturnShipmentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorReturnShipments(params)
        .then(response => {
            dispatch(contractorReturnShipmentSlice.actions.updateReturnShipments(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const loadContractorReturnShipmentsCountAsync = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    contractorService.getContractorReturnShipmentsCount(other)
        .then(response => {
            dispatch(contractorReturnShipmentSlice.actions.updateCountReturnShipments(response.data))
        })
        .catch(error => console.log(error))
}
export const changeFilterContractorReturnShipmentsAsync = (params) => (dispatch) => {
    return dispatch(contractorReturnShipmentSlice.actions.updateFilterReturnShipment(params))
}
export const selectContractorReturnShipments = (state) => state.contractorReturnShipment.returnShipments;
export const selectContractorCountReturnShipments = (state) => state.contractorReturnShipment.countReturnShipments;
export const selectContractorReturnShipmentFilterOptions = (state) => state.contractorReturnShipment.filterOptions;

export default contractorReturnShipmentSlice.reducer