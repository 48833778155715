import AddAttorneyForm from "../../../../components/hippo/attorney/forms/AddAttorneyForm";
import React, {useEffect} from "react";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_ATTORNEY_SUCCESS} from "../../../../app/eventbus/attorneyEvents";
import {useHistory} from "react-router-dom";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiAttorneyCreate} from "../../../../enum/Permission/EdiPermission";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const AddAttorney = () => {
    const routerHistory = useHistory();
    const permission = useSelector(selectAccountRolePermissions)
    const dispatch = useDispatch()

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiAttorneyCreate))) {
            if (routerHistory.length > 1) {
                routerHistory.push('../attorneys')
            } else {
                routerHistory.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        const onAttorneyAddedHandler = EventBus.on(ADD_ATTORNEY_SUCCESS, attorney => {
            routerHistory.push(`/edi/attorney/${attorney.id}`)
        });

        return () => {
            EventBus.remove(ADD_ATTORNEY_SUCCESS, onAttorneyAddedHandler)
        }
    }, []);

    return (
        <>
            <AddAttorneyForm />
        </>
    )
};

export default AddAttorney;
