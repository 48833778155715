import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getTransferItemAsync} from "../../../../app/store/reducers/warehouse-operation/transferReducer";
import {
    WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/transferEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import Transfer from "../../../../components/hippo/warehouse-operation/transfer/Transfer";


const ViewTransfer = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [transfer, setTransfer] = useState(null)
    const {id} = useParams()


    const loadTransfer = () => {
        getTransferItemAsync({transferId: id})
            .then(response => setTransfer(response.data))
            .catch(error => setTransfer(null))
    }

    useEffect(() => {
        loadTransfer()
    },[activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, () => {
            loadTransfer()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
        }
    },[])


    if (!transfer) return <> loading... </>



    return(
        <>
            <Transfer transfer={transfer}/>
        </>
    )
}


export default ViewTransfer