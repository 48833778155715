import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import RoamingInvoiceFormExchangeTypeSelector from "./RoamingInvoiceFormExchangeTypeSelector";
import {Controller, useFormContext} from "react-hook-form";
import {toast} from "react-toastify";
import {
    loadExchangeInfo,
    loadExchangeTypeWithExchangeNumber
} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import dayjs from "dayjs";

const RoamingInvoiceFormExchangeInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const onSearchExchange = async () => {
        try {
            const id = form.getValues('exchange.number');
            const buyerInn = form.getValues('buyer.innOrPinfl');

            const exchangeInfo = await loadExchangeTypeWithExchangeNumber({
                inn: buyerInn,
                lot_number: id
            });
            if (exchangeInfo && exchangeInfo.length) {
                const exchange = await loadExchangeInfo({
                    inn: buyerInn,
                    lot_id: `${exchangeInfo[0].prefix}${exchangeInfo[0].lot_id}`
                });
                onFillFromExchange(exchangeInfo, exchange);
            }
        } catch (e) {
            toast.error(t(lang,'edi.common.toast.error'))
            console.log(e);
        }
    };

    const onFillFromExchange = (exchangeInfo, exchange) => {
        if (exchangeInfo) {
            form.setValue('exchange.type', exchangeInfo[0].prefix);
            form.setValue('exchange.number', exchangeInfo[0].lot_id);
        }

        if (exchange) {
            form.setValue('contractDoc.contractNo', exchange.contract_number || '');
            form.setValue('contractDoc.contractDate', exchange.contract_date ? dayjs(exchange.contract_date).toDate() : null);
            form.setValue('buyer.account', exchange.buyer_account || '');
            form.setValue(`productList.products`, exchange.products.map((product, index) => ({
                catalog: null,
                package: null,
                measurement: null,
                lgota: null,
                origin: null,
                committentName: '',
                committentTin: '',
                committentVatRegCode: '',
                committentVatRegStatus: '',
                barcode: '',
                warehouseId: null,
                mark: {
                    productType: null,
                    kiz: [
                        {code: ''}
                    ],
                    nomUpak: [
                        {code: ''}
                    ],
                    identTransUpak: [
                        {code: ''}
                    ],
                },
                exchangeInfo: {
                    productCode: product.product_code || '',
                    productProperties: product.product_properties || '',
                    planPositionId: product.plan_position_id || '',
                },
                name: product.name || '',
                serial: '',
                baseSumma: '',
                profitRate: '',
                count: product.quantity || '',
                countTon: '',
                countKm: '',
                summa: product.price || '',
                exciseRate: '',
                exciseSum: '',
                deliverySum: '',
                vatRate: '',
                vatSum: '',
                deliverySumWithVat: '',
            })));
        }
    };

    return (
        <>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.invoice.exchange.number')}</Form.Label>
                <Col sm={8} md={12} lg={8}>
                    <InputGroup>
                        <Form.Control name={`exchange.number`}
                                      placeholder={t(lang, 'roaming.invoice.exchange.number.placeholder')}
                                      {...form.register(`exchange.number`, {
                                          shouldUnregister: true
                                      })}
                        />
                        <Button onClick={onSearchExchange}>{t(lang, 'roaming.invoice.find')}</Button>
                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.invoice.exchange.type')}</Form.Label>
                <Col sm={8} md={12} lg={8}>
                    <Controller name={`exchange.type`}
                                shouldUnregister={true}
                                render={({field}) => (
                                    <RoamingInvoiceFormExchangeTypeSelector defaultExchangeType={field.value}
                                                                            onChange={field.onChange}
                                    />
                                )}
                    />
                </Col>
            </Form.Group>
        </>
    );
};

export default RoamingInvoiceFormExchangeInfo;