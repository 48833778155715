import axios from "axios";

class ContractService {
    getContracts() {
        return axios.get('/contract/get')
    }

    getContract(id) {
        return axios.get(`/contract/get/${id}`)
    }

    register(payload) {
        return axios.post('/contract/executor/register', payload)
    }

    edit(id, payload) {
        return axios.post(`/contract/executor/edit/${id}`, payload)
    }

    delete(id) {
        return axios.post(`/contract/contract/delete/${id}`)
    }
}

const contractService = new ContractService();

export default contractService;