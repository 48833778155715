import PropTypes from "prop-types";
import {toast} from "react-toastify";
import IconButton from "../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import CommonDeleteModal from "../../common/CommonDeleteModal";
import {Button, Col, Collapse, Form, Row} from "react-bootstrap";
import SelectRoamingBranch from "../../common/SelectRoamingBranch";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import OrganizationAddressForm from "../roaming/my-address/form/OrganizationAddressForm";
import {loadCompanyBranchesAsync} from "../../../app/store/reducers/roaming/roamingReducer";
import {deleteBranchRoamingAddressAsync} from "../../../app/store/reducers/branch/branchReducer";

const BranchForm = ({branchId, existingName, existingCode, roamingAddressExist, isEditing}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const [collapsed, setCollapsed] = useState(false);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const {register, formState: {errors}, setValue, trigger, resetField} = useFormContext();

    // not reviewed

    useEffect(() => {
        const inn = activeOrganization?.inn || '';
        setValue('inn', inn)
    }, [activeOrganization]);

    useEffect(() => {
        if (existingName)
            trigger();
    }, [existingName]);

    useEffect(() => {
        if (existingCode)
            trigger();
    }, [existingCode]);

    useEffect(() => {
        setCollapsed(roamingAddressExist);
    }, [])

    const onSelectedRoamingBranch = (branch) => {
        setValue('roaming_branch.branch_name', branch?.branch_name);
        setValue('roaming_branch.branch_number', branch?.branch_number);
    }

    const deleteRoamingAddress = () => {
        deleteBranchRoamingAddressAsync(branchId)
            .then(() => {
                toast.success(t(lang, 'common.toast.success'));
                resetRoamingBranchInfoFields();
                setCollapsed(false);
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')))
    };

    const resetRoamingBranchInfoFields = () => {
        resetField('regionCode');
        resetField('regionName');
        resetField('districtCode');
        resetField('districtName');
        resetField('villageCode');
        resetField('villageName');
        resetField('street');
        resetField('other');
        resetField('longitude');
        resetField('latitude');
    };

    return (
        <Fragment>
            <Row>
                <Form.Group as={Col} xs={6} className={'my-1'}>
                    <Form.Label>{t(lang, "edi.common.name")}</Form.Label>
                    <Form.Control
                        type="text"
                        isInvalid={errors?.name?.message}
                        placeholder={t(lang, "edi.common.name.placeholder")}
                        isValid={Object.keys(errors).length > 0 && !errors?.name?.message}
                        {...register("name", {
                            required: {
                                value: true,
                                message: t(lang, "edi.common.forms.validations.is_required")
                            },
                            validate: (value) => {
                                if (existingName) {
                                    if (value === existingName)
                                        return t(lang, "edi.common.forms.validations.is_exists", {name: t(lang, "edi.common.forms.keywords.name")});
                                }
                                return true;
                            }
                        })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.name?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={6} className={'my-1'}>
                    <Form.Label>{t(lang, "edi.common.code")}</Form.Label>
                    <Form.Control
                        type="text"
                        isInvalid={errors?.code?.message}
                        placeholder={t(lang, "edi.common.code.placeholder")}
                        isValid={Object.keys(errors).length > 0 && !errors?.code?.message}
                        {...register("code", {
                            required: {
                                value: true,
                                message: t(lang, "edi.common.forms.validations.is_required")
                            },
                            validate: (value) => {
                                if (existingCode) {
                                    if (value === existingCode)
                                        return t(lang, "edi.common.forms.validations.is_exists", {name: t(lang, "edi.common.forms.keywords.code")});
                                }
                                return true;
                            }
                        })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.code?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={6} className="text-start mb-3">
                    <Form.Label>{t(lang, "edi.order.datatable.header.filter.branch")}</Form.Label>
                    <Controller
                        name="roaming_branch.branch_number"
                        render={({field}) => (
                            <SelectRoamingBranch
                                branchNumber={field?.value}
                                onChange={onSelectedRoamingBranch}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} xs={6} className="text-start mb-3">
                    <Form.Label>{t(lang, "edi.common.inn")}</Form.Label>
                    <Form.Control
                        className="input-spin-none"
                        name="Inn"
                        type="number"
                        readOnly={true}
                        {...register('inn')}
                    />
                </Form.Group>
            </Row>

            {roamingAddressExist && <hr/>}

            <div className={'d-flex flex-between-center'}>
                <Button variant="primary" size='sm' onClick={() => setCollapsed(!collapsed)}>
                    <span>{collapsed ? t(lang, 'edi.common.hide') : t(lang, 'edi.common.enter_address')}</span>
                </Button>

                {roamingAddressExist && (
                    <CommonDeleteModal centered={true} onDelete={deleteRoamingAddress}>
                        <IconButton size={'sm'} icon={'trash'} variant={'falcon-danger'} className={'link-danger'}>
                            <span>{t(lang, 'edi.common.delete_address')}</span>
                        </IconButton>
                    </CommonDeleteModal>
                )}
            </div>

            <Collapse in={collapsed}>
                <div>
                    {collapsed && <OrganizationAddressForm isEditing={isEditing}/>}
                </div>
            </Collapse>
        </Fragment>
    )
};

BranchForm.propTypes = {
    existingName: PropTypes.string,
    existingCode: PropTypes.string,
};

BranchForm.defaultProps = {
    existingName: "",
    existingCode: "",
    branch: {
        name: '',
        code: '',
        roaming_branch: {
            branch_name: '',
            branch_number: ''
        }
    }
};

export default BranchForm;
