import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {selectRoamingBranches} from '../../app/store/reducers/roaming/roamingReducer';
import {useEffect, useState} from "react";

const SelectRoamingBranch = ({onChange, branchNumber, isDisabled, ...props}) => {
    const roamingBranches = useSelector(selectRoamingBranches)
    const lang = useSelector(selectLang);
    const t = Translate;

    const [value, setValue] = useState(null)

    useEffect(() => {
        if (branchNumber) {
            setValue(roamingBranches.find(branch => branch.branch_num === branchNumber))
        } else {
            setValue(null)
        }
    }, [branchNumber]);

    const onChangeBranch = (selectedBranch) => {
        setValue(selectedBranch)
        onChange({
            branch_name: selectedBranch?.branch_name,
            branch_number: selectedBranch?.branch_num,
        })
    }
    return (
        <ReactSelect options={roamingBranches}
                     onChange={onChangeBranch}
                     getOptionLabel={option => t(lang, option.branch_name)}
                     getOptionValue={option => option.branch_num}
                     placeholder={t(lang, "roaming.common.select.placeholder")}
                     classNamePrefix={!isDisabled && "react-select"}
                     hideSelectedOptions
                     isClearable
                     value={value}
                     isDisabled={isDisabled}
                     {...props}
        />
    )
}
export default SelectRoamingBranch;
