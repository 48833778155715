import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, CloseButton, Col, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import CleaveWithCommaMark from "../../../common/CleaveWithCommaMark";
import SelectCurrency from "./SelectCurrency";
import {currencyConverterConvert} from "../../../../app/store/reducers/currency/currencyReducer";
import RangeSlider from "react-bootstrap-range-slider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import printJS from "print-js";
import Barcode from "react-jsbarcode";
import numeral from "numeral";
import dayjs from "dayjs";
import ReactSelect from "react-select";

const printSizes = [
    {
        label: '58x40', value: '5840',
    },
    {
        label: '58x30', value: '5830',
    },
    {
        label: '40x30', value: '4030',
    },
    {
        label: '30x20', value: '3020',
    }
]

const BulkPrintItems = ({selectedItems}) => {

    const lang = useSelector(selectLang)
    const t = Translate

    const [show, setShow] = useState(false)

    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }

    return (
        <Fragment>
            <Button onClick={handlerShow} variant={'outline-primary'}
                    size={'sm'}>{t(lang, 'items.print.barcode.modal.title')}</Button>
            <Modal show={show} onHide={handlerClose} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "items.print.barcode.modal.title")}
                    </Modal.Title>
                    <CloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <BulkPrintItem selectedItems={selectedItems}/>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};


const BulkPrintItem = ({selectedItems}) => {

    const {defaultCurrency, sku} = selectedItems

    const t = Translate;
    const lang = useSelector(selectLang);
    const viewTypes = [
        {
            value: 1,
            label: t(lang, "items.print.barcode.select_option_type_1")
        },
        {
            value: 2,
            label: t(lang, "items.print.barcode.select_option_type_2")
        }
    ]

    const [barcodes, setBarcodes] = useState([]);

    const [priceAmount, setPriceAmount] = useState(0);
    const [itemCurrency, setItemCurrency] = useState(0);
    const [barcode, setBarcode] = useState(null);
    const [barcodeType, setBarcodeType] = useState(null);
    const [barcodeWidth, setBarcodeWidth] = useState(2);
    const [barcodeHeight, setBarcodeHeight] = useState(70);
    const [barcodeMarginBottom, setBarcodeMarginBottom] = useState(10);
    const [barcodeFontSize, setBarcodeFontSize] = useState(16);
    const [viewItemName, setViewItemName] = useState(true);
    const [viewItemPrice, setViewItemPrice] = useState(true);
    const [itemNameFontSize, setItemNameFontSize] = useState(16);
    const [itemPriceFontSize, setItemPriceFontSize] = useState(30);
    const [itemSmallPriceFontSize, setItemSmallPriceFontSize] = useState(30);
    const [itemNameAndPriceWidth, setItemNameAndPriceWidth] = useState(180);
    const [viewNowDateTime, setViewNowDateTime] = useState(false);
    const [viewItemSku, setViewItemSku] = useState(false);
    const [viewBarcode, setViewBarcode] = useState(true);
    const [itemSkuFontSize, setItemSkuFontSize] = useState(true);
    const [selectedViewType, setSelectedViewType] = useState(viewTypes[0]);
    const [printData, setPrintData] = useState([]);
    const [width, setWidth] = useState(printSizes[0]?.value?.slice(0, 2) || 58);
    const [height, setHeight] = useState(printSizes[0]?.value?.slice(2) || 40);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setPrintData(selectedItems);
    }, []);

    useEffect(() => {
        if (localStorage.getItem('barcodeSettings')) {
            let barcodeSettings = JSON.parse(localStorage.getItem('barcodeSettings'));

            if (barcodeSettings.barcodeType)
                setBarcodeType(barcodeSettings.barcodeType)
            if (barcodeSettings.barcodeWidth)
                setBarcodeWidth(barcodeSettings.barcodeWidth)
            if (barcodeSettings.barcodeHeight)
                setBarcodeHeight(barcodeSettings.barcodeHeight)
            if (barcodeSettings.barcodeMarginBottom)
                setBarcodeMarginBottom(barcodeSettings.barcodeMarginBottom)
            if (barcodeSettings.itemNameFontSize)
                setItemNameFontSize(barcodeSettings.itemNameFontSize)
            if (barcodeSettings.barcodeFontSize)
                setBarcodeFontSize(barcodeSettings.barcodeFontSize)
            if (barcodeSettings.viewItemSku)
                setViewItemSku(barcodeSettings.viewItemSku)
            if (barcodeSettings.viewBarcode)
                setViewBarcode(barcodeSettings.viewBarcode)
            if (barcodeSettings.itemSkuFontSize)
                setItemSkuFontSize(barcodeSettings.itemSkuFontSize)

            if (typeof barcodeSettings.viewItemName !== "undefined")
                setViewItemName(barcodeSettings.viewItemName)

            if (typeof barcodeSettings.viewItemPrice !== "undefined")
                setViewItemPrice(barcodeSettings.viewItemPrice)

            if (barcodeSettings.itemNameFontSize)
                setItemNameFontSize(barcodeSettings.itemNameFontSize)

            if (barcodeSettings.itemPriceFontSize)
                setItemPriceFontSize(barcodeSettings.itemPriceFontSize)

            if (barcodeSettings.itemSmallPriceFontSize)
                setItemSmallPriceFontSize(barcodeSettings.itemSmallPriceFontSize)


            if (barcodeSettings.itemNameAndPriceWidth)
                setItemNameAndPriceWidth(barcodeSettings.itemNameAndPriceWidth)

            if (typeof barcodeSettings.viewNowDateTime !== "undefined")
                setViewNowDateTime(barcodeSettings.viewNowDateTime)

            if (typeof barcodeSettings.viewItemSku !== "undefined")
                setViewItemSku(barcodeSettings.viewItemSku)

            if (typeof barcodeSettings.viewBarcode !== "undefined")
                setViewBarcode(barcodeSettings.viewBarcode)

            if (barcodeSettings.itemSkuFontSize)
                setItemSkuFontSize(barcodeSettings.itemSkuFontSize)

            if (barcodeSettings.selectedViewType)
                setSelectedViewType(barcodeSettings.selectedViewType)
        }
    }, [])

    useEffect(() => {
        setItemCurrency(defaultCurrency);
    }, [defaultCurrency]);

    useEffect(() => {
        const allBarcodes = selectedItems.flatMap((item) => item.barcodes);
        const formattedBarcodes = allBarcodes.map((barcode) => ({
            label: barcode,
            value: barcode,
        }));
        setBarcodes(formattedBarcodes);
    }, []);

    const formatCreateLabel = (label) => `${t(lang, "crm.common.add")} ${label}`;

    const onItemPriceCurrencyChanged = async (oldCurrency, newCurrency) => {
        try {
            if (oldCurrency && oldCurrency.id !== newCurrency.id) {
                const {currency_to: {value}} = await currencyConverterConvert({
                    currency_id_from: oldCurrency.id,
                    value_from: Number(priceAmount),
                    currency_id_to: newCurrency.id
                });
                setPriceAmount(value ? Number(value.toFixed(2)) : 0);
                setItemCurrency(newCurrency);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const onPrint = () => {
        setLoading(true);
        setTimeout(() => {
            printJS({
                printable: 'printBarcode',
                type: 'html',
                targetStyles: ['*'],
                font_size: ''
            });

            localStorage.setItem('barcodeSettings', JSON.stringify({
                barcodeType: barcodeType,
                barcodeWidth: barcodeWidth,
                barcodeHeight: barcodeHeight,
                barcodeMarginBottom: barcodeMarginBottom,
                barcodeFontSize: barcodeFontSize,
                viewItemName: viewItemName,
                viewItemPrice: viewItemPrice,
                itemNameFontSize: itemNameFontSize,
                itemPriceFontSize: itemPriceFontSize,
                itemSmallPriceFontSize: itemSmallPriceFontSize,
                itemNameAndPriceWidth: itemNameAndPriceWidth,
                viewNowDateTime: viewNowDateTime,
                viewItemSku: viewItemSku,
                viewBarcode: viewBarcode,
                itemSkuFontSize: itemSkuFontSize,
                selectedViewType: selectedViewType,
            }));

            setLoading(false);
        }, 0);
    }

    const handleSizeChange = (selectedOption) => {
        const value = selectedOption.value;
        const selectedWidth = value.slice(0, 2);
        const selectedHeight = value.slice(2);

        setWidth(selectedWidth);
        setHeight(selectedHeight);
    };

    return (
        <Fragment>
            <Row className={'align-items-end'}>
                {/*<Form.Group as={Col} xs={12} lg={6}>
                    <Form.Label>{t(lang, 'items.common.barcode')}</Form.Label>
                    <InputGroup className={'flex-nowrap'}>
                        <CreatableSelect
                            className={'w-100'}
                            options={barcodes}
                            value={barcodes.find((b) => b.value === barcode)}
                            classNamePrefix={'react-select'}
                            hideSelectedOptions
                            defaultValue={barcodes[0]}
                            formatCreateLabel={formatCreateLabel}
                            onChange={(option) => setBarcode(option?.value || null)}
                            placeholder={t(lang, 'items.common.barcode')}
                            isClearable
                        />
                        <SelectItemBarcodeType defaultTypeValue={barcodeType ? barcodeType.value : null}
                                               onChange={(type) => {
                                                   setBarcodeType(type)
                                               }}
                        />
                    </InputGroup>
                </Form.Group>*/}

                {/*<Form.Group as={Col} xs={12} lg={6}>
                    <Form.Label>{t(lang, "items.print.barcode.select_view_types_label")}</Form.Label>
                    <Select classNamePrefix="react-select" options={viewTypes}
                            hideSelectedOptions
                            onChange={(opt) => {
                                setSelectedViewType(opt)
                            }}
                            value={selectedViewType}/>
                </Form.Group>*/}

                <Form.Group as={Col} xs={12} lg={6}>
                    <Form.Label>{t(lang, 'items.print.barcode.price.value')}</Form.Label>
                    <InputGroup>
                        <CleaveWithCommaMark value={priceAmount} onChange={value => setPriceAmount(Number(value))}
                                             ref={null}/>
                        <SelectCurrency
                            defaultCurrencyId={itemCurrency?.id}
                            onChange={(oldCurrency, newCurrency) => onItemPriceCurrencyChanged(oldCurrency, newCurrency)}
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group as={Col} xs={12} lg={3}>
                    <Form.Label column={true}>{t(lang, 'items.print.barcode.paper.size')}</Form.Label>
                    <ReactSelect
                        defaultValue={printSizes[0]}
                        options={printSizes}
                        onChange={handleSizeChange}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={3}>
                    <div className={'d-flex gap-2 align-items-end justify-content-end h-100'}>
                        <Button disabled={loading} className={'d-flex align-items-center gap-1'} variant={'success'} onClick={onPrint}>
                            {loading ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon className={'me-2'} icon={faPrint}/>}
                            {t(lang, "edi.common.button.print")}
                            {printData.length > 1 ? ` (${printData.length})` : ''}
                        </Button>
                    </div>
                </Form.Group>
                <hr className={'my-2'}/>
            </Row>
            <Row>
                <Col sm={12} lg={6}>
                    <Row>
                        <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, 'items.print.barcode.width')}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={barcodeWidth}
                                size={"lg"}
                                min={1}
                                step={0.1}
                                max={10}
                                onChange={changeEvent => setBarcodeWidth(+changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, 'items.print.barcode.height')}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={barcodeHeight}
                                size={"lg"}
                                step={0.1}
                                min={10}
                                max={150}
                                onChange={changeEvent => setBarcodeHeight(changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, 'items.print.barcode.barcodeMarginBottom')}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={barcodeMarginBottom}
                                size={"lg"}
                                step={0.1}
                                min={1}
                                max={150}
                                onChange={changeEvent => setBarcodeMarginBottom(changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, 'items.print.barcode.fontsize')}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={barcodeFontSize}
                                size={"lg"}
                                step={0.1}
                                min={2}
                                max={50}
                                onChange={changeEvent => setBarcodeFontSize(changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, 'items.print.barcode.name.fontsize')}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={itemNameFontSize}
                                size={"lg"}
                                step={0.1}
                                min={2}
                                max={50}
                                onChange={changeEvent => setItemNameFontSize(changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, 'items.print.barcode.price.fontsize')}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={itemPriceFontSize}
                                size={"lg"}
                                step={1}
                                min={5}
                                max={100}
                                onChange={changeEvent => setItemPriceFontSize(changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>

                        {selectedViewType && selectedViewType.value === 2 && <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, "items.print.barcode.small_price.fontsize")}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={itemSmallPriceFontSize}
                                size={"lg"}
                                step={1}
                                min={5}
                                max={100}
                                onChange={changeEvent => setItemSmallPriceFontSize(changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>}

                        <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, 'items.print.barcode.textWidth')}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={itemNameAndPriceWidth}
                                size={"lg"}
                                step={0.1}
                                min={20}
                                max={500}
                                onChange={changeEvent => setItemNameAndPriceWidth(changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} lg={6}>
                            <Form.Label>{t(lang, 'Размер артикул')}</Form.Label>
                            <RangeSlider
                                style={{width: '100%'}}
                                value={itemSkuFontSize}
                                size={"lg"}
                                step={0.1}
                                min={2}
                                max={50}
                                onChange={changeEvent => setItemSkuFontSize(changeEvent.target.value)}
                            />
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12}>
                            <Form.Label column={true} className={'d-flex justify-content-between align-items-center gap-2'}>
                                <span>{t(lang, "items.print.barcode.viewName")}</span>
                                <Form.Switch checked={viewItemName} onChange={(e) => {
                                    setViewItemName(e.target.checked)
                                }}/>
                            </Form.Label>
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12}>
                            <Form.Label column={true} className={'d-flex justify-content-between align-items-center gap-2'}>
                                <span>{t(lang, "items.print.barcode.viewPrice")}</span>
                                <Form.Switch checked={viewItemPrice} onChange={(e) => {
                                    setViewItemPrice(e.target.checked)
                                }}/>
                            </Form.Label>
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12}>
                            <Form.Label column={true}
                                        className={'d-flex justify-content-between align-items-center gap-2'}>
                                <span>{t(lang, "items.print.barcode.viewDateTime")}</span>
                                <Form.Switch checked={viewNowDateTime} onChange={(e) => {
                                    setViewNowDateTime(e.target.checked)
                                }}/>
                            </Form.Label>
                            <hr className={'my-1'}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12}>
                            <Form.Label column={true}
                                        className={'d-flex justify-content-between align-items-center gap-2'}>
                                <span>{t(lang, "items.print.barcode.viewBarcode")}</span>
                                <Form.Switch checked={viewBarcode} onChange={(e) => {
                                    setViewBarcode(e.target.checked)
                                }}/>
                            </Form.Label>
                            <hr className={'my-1'}/>
                        </Form.Group>
                    </Row>
                </Col>
                <Col sm={12} lg={6}>
                    <div style={{minHeight: '400px', maxHeight: '600px', overflowY: 'auto', overflowX: "hidden"}} className={'scrollbar'}>
                        <div className={'m-auto'} style={{margin: 0, display: 'grid', justifyContent: 'center'}}>
                            {
                                selectedItems.map((item, index) => {
                                    return (
                                        <div className={'text-center w-100 h-100 overflow-hidden'} key={index}>
                                            <CardBarcodeItems
                                                index={index} item={item}
                                                barcode={barcode}
                                                barcodeType={barcodeType}
                                                priceAmount={priceAmount}
                                                itemCurrency={itemCurrency}
                                                viewItemName={viewItemName}
                                                viewItemPrice={viewItemPrice}
                                                itemNameFontSize={itemNameFontSize}
                                                itemPriceFontSize={itemPriceFontSize}
                                                itemSmallPriceFontSize={itemSmallPriceFontSize}
                                                itemNameAndPriceWidth={itemNameAndPriceWidth}
                                                viewNowDateTime={viewNowDateTime}
                                                viewItemSku={viewItemSku}
                                                viewBarcode={viewBarcode}
                                                itemSkuFontSize={itemSkuFontSize}
                                                selectedViewType={selectedViewType}
                                                barcodeWidth={barcodeWidth}
                                                barcodeHeight={barcodeHeight}
                                                barcodeMarginBottom={barcodeMarginBottom}
                                                barcodeFontSize={barcodeFontSize}
                                                setPrintData={setPrintData}
                                                width={width}
                                                height={height}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={`scrollable-container position-absolute scrollbar`} style={{minHeight: '400px', maxHeight: '600px', overflowY: 'auto', overflowX: "hidden", zIndex: '-99999', right: '-100000vh', top: '-100000vh'}}>
                        <div id={'printBarcode'} className="sticker-layout" style={{width: `${width}mm`, height: `${height}mm`, textAlign: 'center'}}>
                            <style>
                                {`
                                  @media print {
                                   @supports (size: ${width}mm ${height}mm) {
                                    @page {
                                      size: ${width}mm ${height}mm;
                                      margin: 0;
                                    }
                                  }
                        
                                    .scrollable-container {
                                        overflow: visible;
                                        height: auto;
                                    }
                                    .print-counter{
                                        display: none;
                                    }
                                    .sticker {
                                        page-break-inside: avoid;
                                        page-break-after: always;
                                    }
                                  }
                            `}
                            </style>
                            {
                                printData.map((item, index) => {
                                    return (
                                        <div className={`overflow-hidden`} key={index}>
                                            <CardBarcodeItems
                                                index={index} item={item} barcode={barcode}
                                                barcodeType={barcodeType}
                                                priceAmount={priceAmount}
                                                itemCurrency={itemCurrency}
                                                viewItemName={viewItemName} viewItemPrice={viewItemPrice}
                                                itemNameFontSize={itemNameFontSize}
                                                itemPriceFontSize={itemPriceFontSize}
                                                itemSmallPriceFontSize={itemSmallPriceFontSize}
                                                itemNameAndPriceWidth={itemNameAndPriceWidth}
                                                viewNowDateTime={viewNowDateTime}
                                                viewItemSku={viewItemSku}
                                                viewBarcode={viewBarcode}
                                                itemSkuFontSize={itemSkuFontSize}
                                                selectedViewType={selectedViewType}
                                                barcodeWidth={barcodeWidth}
                                                barcodeHeight={barcodeHeight}
                                                barcodeMarginBottom={barcodeMarginBottom}
                                                barcodeFontSize={barcodeFontSize}
                                                setPrintData={setPrintData}
                                                hideButtonCounter={true}
                                                width={width}
                                                height={height}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )

}

const CardBarcodeItems = ({
                              item,
                              barcode,
                              viewBarcode,
                              viewItemPrice,
                              priceAmount,
                              itemCurrency,
                              viewItemName,
                              itemNameFontSize,
                              itemNameAndPriceWidth,
                              itemPriceFontSize,
                              viewNowDateTime,
                              barcodeWidth,
                              barcodeHeight,
                              barcodeMarginBottom,
                              barcodeFontSize,
                              setPrintData,
                              hideButtonCounter,
                              width, height
                          }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const [isValid, setIsValid] = useState(false);
    const [option, setOption] = useState({
        label: 'CODE128',
        format: 'CODE128',
        width: barcodeWidth,
        height: barcodeHeight,
        marginBottom: barcodeMarginBottom,
        fontSize: barcodeFontSize,
        valid: (val) => {
            setIsValid(val);
        },
    });

    const updateBarcodeOptions = (currentBarcode, isValid, dimensions) => {
        if (!currentBarcode || !isValid) return;

        const barcodeLength = currentBarcode.length;
        let newFormat = 'CODE128';

        if (barcodeLength === 8) {
            newFormat = 'EAN8';
        } else if (barcodeLength === 13) {
            newFormat = 'EAN13';
        } else if (barcodeLength === 12) {
            newFormat = 'UPC';
        }

        if (isValid && newFormat === 'EAN13') {
            newFormat = 'CODE128';
        }

        setOption((prevOption) => ({
            ...prevOption,
            format: newFormat,
            ...dimensions,
        }));
    };

    useEffect(() => {
        const currentBarcode = barcode || item.barcodes[0];
        updateBarcodeOptions(currentBarcode, isValid, {
            width: barcodeWidth,
            height: barcodeHeight,
            marginBottom: barcodeMarginBottom,
            fontSize: barcodeFontSize,
        });
    }, [barcode, item.barcodes, isValid, barcodeWidth, barcodeHeight, barcodeFontSize, barcodeMarginBottom]);

    const [count, setCount] = useState(1)

    const handleIncrease = useCallback(() => {
        setCount((prevCount) => prevCount + 1);
        setPrintData((prevData) => [...prevData, item]);
    }, [item]);

    const handleDecrease = useCallback(() => {
        setCount((prevCount) => {
            if (prevCount > 1) {
                return prevCount - 1;
            }
            return prevCount;
        });

        setPrintData((prevData) => {
            if (count > 1) {
                const index = prevData.indexOf(item);
                if (index > -1) {
                    const newData = [...prevData];
                    newData.splice(index, 1);
                    return newData;
                }
            }
            return prevData;
        });
    }, [item, count]);

    return <Fragment>
        {!hideButtonCounter &&
            <ButtonGroup size={"sm"} className="me-2 mb-1 d-block print-counter" aria-label="First group">
                <Button variant="secondary" onClick={handleDecrease}>-</Button>
                <Button variant="secondary">{count}</Button>
                <Button variant="secondary" onClick={handleIncrease}>+</Button>
            </ButtonGroup>}

        <div className={`${!hideButtonCounter ? 'mb-2 border' : ''} m-auto `}
             style={{width: `${width}mm`, height: `${height}mm`}}>

            <div className={'w-100'}>
                {!isValid &&
                    <div className={'text-danger'}>{t(lang, "edi.common.forms.validations.invalid_format")}</div>}
                {viewBarcode && <div><Barcode value={barcode || item.barcodes[0]} options={option}/></div>}
                <div className={'m-auto'} style={{width: itemNameAndPriceWidth + 'px'}}>
                    {viewItemName && item.warehouse_item.name &&
                        <div className='fw-bold' style={{lineHeight: 1, fontSize: itemNameFontSize + 'px'}}>
                            {item.warehouse_item.name}
                        </div>
                    }
                    {viewItemPrice &&
                        <div className='fw-bold' style={{lineHeight: 1, fontSize: itemPriceFontSize + 'px'}}>
                            {numeral.formats[numberFormat].format(priceAmount || item.price.amount)}
                            &nbsp;
                            <small>{(itemCurrency?.name === 'UZS' || item.price.currency.name === 'UZS') ? "SO'M" : itemCurrency?.name || item.price.currency.name}</small>
                        </div>
                    }

                    {viewNowDateTime &&
                        <div className={'fw-bold small'}
                             style={{lineHeight: 1, minWidth: 80}}>{dayjs(new Date()).format("YYYY-MM-DD")}</div>
                    }
                </div>
            </div>
        </div>
    </Fragment>
}

export default BulkPrintItems;