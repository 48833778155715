import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {
    loadCatalogsByCode,
    loadMySoliqUzContract
} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {useHistory} from "react-router";
import {uniq} from "underscore";

const RoamingInvoiceFormCardOrigin = () => {
    const history = useHistory();
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const onSearchContract = () => {
        const id = form.getValues('contractDoc.contractId');
        id && loadMySoliqUzContract(id)
            .then((contract) => {
                onFillFromMySoliqUzContract(contract);
                toast.success(t(lang, 'edi.common.toast.success'))
            })
            .catch((error) => {
                console.log(error);
                toast.error(t(lang, 'edi.common.toast.error'));
            })
    };
    const onFillFromMySoliqUzContract = async (contract) => {
        if (contract) {
            form.setValue(`contractDoc.contractId`, contract.contract_id);
            form.setValue(`contractDoc.contractNo`, contract.contract_no);
            form.setValue(`contractDoc.contractDate`, contract.contract_date ? dayjs(contract.contract_date).toDate() : null);

            if (contract.contragents.length)
                form.setValue(`buyer.innOrPinfl`,  contract.contragents[0].tin);


            const unknownCatalogCodes = uniq(contract.products.map((item) => item.product_code || ""));
            const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
            const products = contract.products.map((product) => {
                const json = {
                    catalog: null,
                    package: null,
                    measurement: null,
                    lgota: null,
                    origin: null,
                    committentName: '',
                    committentTin: '',
                    committentVatRegCode: '',
                    committentVatRegStatus: '',
                    barcode: '',
                    warehouseId: null,
                    mark: {
                        productType: null,
                        kiz: [
                            {code: ''}
                        ],
                        nomUpak: [
                            {code: ''}
                        ],
                        identTransUpak: [
                            {code: ''}
                        ],
                    },
                    exchangeInfo: {
                        productCode: '',
                        productProperties: '',
                        planPositionId: '',
                    },
                    name: '',
                    serial: '',
                    baseSumma: '',
                    profitRate: '',
                    count: '',
                    countTon: '',
                    countKm: '',
                    summa: '',
                    exciseRate: '',
                    exciseSum: '',
                    deliverySum: '',
                    vatRate: '',
                    vatSum: '',
                    deliverySumWithVat: '',
                };
                json.name = product.product_name || '';
                json.barcode = product.product_bar_code || '';
                json.count = product.count || '';
                json.summa = product.item_sum || '';

                // set item tax info
                {
                    let catalog = null;
                    let catalogPackage = null;
                    let vatRate = '';

                    if (product.product_code) {
                        catalog = catalogs.find(c => c.class_code === product.product_code);

                        if (product.package_code && catalog && catalog.use_package)
                            catalogPackage = catalog.package_names.find(p => p.code === parseInt(product.package_code))

                        if (product.vat_rate !== null)
                            vatRate = product.vat_rate.toString();
                    }

                    json.catalog = catalog;
                    json.package = catalogPackage;
                    json.vatRate = vatRate;
                }

                return json;
            });
            form.setValue(`productList.products`, products);
        }
    };
    const onSearchWaybill = () => {
        const id = form.getValues('waybillId');
        id && history.push({search: `?waybill_id=${id}`});
    };
    const onSearchV2Waybill = () => {
        const value = form.getValues('v2WaybillIds');
        value && history.push({search: `?waybill_v2_id=${value}`});
    };

    return (
        <Card>
            <Card.Body>
                <Row className="g-2">
                    <Col xs={12} md={6} lg={5} xl={4}>
                        <Form.Group>
                            <Form.Label>
                                <span className="me-2">{t(lang, 'roaming.contract.id')}</span>
                                (<a href="https://my.soliq.uz">my.soliq.uz</a>)
                            </Form.Label>
                            <InputGroup>
                                <Form.Control name={`contractDoc.contractId`}
                                              placeholder={t(lang, 'roaming.contract.id.placeholder')}
                                              {...form.register(`contractDoc.contractId`)}
                                />
                                <Button onClick={onSearchContract}>{t(lang, 'roaming.invoice.find')}</Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={5} xl={4}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.invoice.waybill.id')}</Form.Label>
                            <InputGroup>
                                <Form.Control name={`waybillId`}
                                              placeholder={t(lang, 'roaming.invoice.waybill.id.placeholder')}
                                              {...form.register(`waybillId`)}
                                />
                                <Button onClick={onSearchWaybill}>{t(lang, 'roaming.invoice.find')}</Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={5} xl={4}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.invoice.waybill_v2.id')}</Form.Label>
                            <InputGroup>
                                <Form.Control name={`v2WaybillIds`}
                                              placeholder={t(lang, 'roaming.invoice.waybill_v2.id.placeholder')}
                                              {...form.register(`v2WaybillIds`)}
                                />
                                <Button onClick={onSearchV2Waybill}>{t(lang, 'roaming.invoice.find')}</Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default RoamingInvoiceFormCardOrigin;