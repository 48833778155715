import {Button, Card, Col, Form, Nav, Row, Spinner, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../app/store/reducers/main/mainReducer";
import numeral from "numeral";
import dayjs from "dayjs";
import {loadMeasurementsAsync, selectCompanyCatalogs} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {Link} from "react-router-dom";
import {
    ContractFormationFileDownload, ContractFormationPdfDownload,
    sendContractFormationAsync, signContractFormationAsync
} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";
import SoftBadge from "../../../common/SoftBadge";
import {
    GetAllowedTaxLinkStatus,
    GetAllowedSignStatus,
    GetAllowedStatus,
    GetStatusText,
    GetStatusVariant,
    ShowEditButtonIKPU, InRework,
} from "./ContractFormationStatus";
import Divider from "../../../common/Divider";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import IconButton from "../../../common/IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent
    from "../../roaming/components/SelectCertificateWrapperComponent";
import EventBus from "../../../../app/eventbus/EventBus";
import {SEND_CONTRACT_FORMATION_FAILED, SIGN_CONTRACT_FORMATION_FAILED} from "../../../../app/eventbus/contractFormationEvents";
import WarningAlert from "../../roaming/components/WarningAlert";
import ContractFormationViewItemUpdate from "../contract-formation-form/ContractFormationViewItemUpdate";
import {Controller, useFormContext} from "react-hook-form";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionContractFormationSign,
    PermissionContractFormationUpdate
} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import classNames from "classnames";


const ContractFormationView = ({contractFormation, contract}) => {
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization)
    const numberFormat = useSelector(selectNumberFormat);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [pdfloading, setPdfLoading] = useState(false)
    const [showTag, setShowTag] = useState(true)
    const {setValue} = useFormContext()
    const catalogs = useSelector(selectCompanyCatalogs)



    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang))
    },[contractFormation, activeOrganization.inn])


    const subtotal = contractFormation?.items.reduce((acc, item) => acc + item?.total, 0)
    const subtotalNdsValue = contractFormation?.items.reduce((acc, item) => acc + item?.nds_value, 0)
    const subtotalWithNds = contractFormation?.items.reduce((acc, item) => acc + item?.total_with_nds, 0)

    const allowEdit = GetAllowedStatus(contract.status)
    const allowSend = GetAllowedStatus(contract.status)
    const allowSign = GetAllowedSignStatus(contract.status)
    const showTaxLink = GetAllowedTaxLinkStatus(contract.status)
    const showEditButtonIKPU = ShowEditButtonIKPU(contract.status)
    const warningInRework = InRework === contract.status

    const ContractFormationDownloadFile = async (e, filename) => {
        ContractFormationFileDownload({file_id: e.target.id, doc_id: contract?.id})
            .then(response => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);


                link.href = blobUrl;
                link.download = `${filename}`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const [eventMessage, setEventMessage] = useState(null);


    useEffect(() => {
        const onContractFormationSendFailedHandler = EventBus.on(SEND_CONTRACT_FORMATION_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, "edi.contract_formation.alert.failed_event_title"),
                text: error
            })
        })

        const onContractFormationSignFailedHandler = EventBus.on(SIGN_CONTRACT_FORMATION_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, "edi.contract_formation.alert.failed_event_title"),
                text: error?.content || t(lang,"edi.contract_formation.common_error")
            })
        })


        return () => {
            EventBus.remove(SEND_CONTRACT_FORMATION_FAILED, onContractFormationSendFailedHandler);
            EventBus.remove(SIGN_CONTRACT_FORMATION_FAILED, onContractFormationSignFailedHandler);
        }
    },[])


    const sendContractFormationHandler = async () => {
        await sendContractFormationAsync({id: contract?.id, contractdata: contract})
    }


    const signContractFormation = async () => {
        await signContractFormationAsync({certificate: currentCertificate, lang: lang, contract: contract})
    }


    const pdfFileDownload = async () => {
        setPdfLoading(true)
        await ContractFormationPdfDownload(contract.uid)
            .then(response => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);


                link.href = blobUrl;
                link.download = `${contractFormation.number}.pdf`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setPdfLoading(false)
            })

    }

    const updateItemShowButton = () => {
        setShowTag(!showTag)
        if (showTag) {
            setValue(`products`, contract?.contract?.items?.map((item) => {
                const catalog = catalogs?.find(c => c?.class_code === item?.catalog_code)
                const packages = catalog?.package_names?.find(p => p?.code === +item?.package_code)

                return {
                    item_id: item?.id,
                    catalog_code: item.catalog_code,
                    catalog_name: item.catalog_name,
                    measurement_id: item?.measurement,
                    measurement_name: item?.measurement_name,
                    package_code: +item?.package_code,
                    package_name: item?.package_name,
                    packages: packages,
                    catalogs: catalog,
                }
            }))
        }
    }

    return(
        <>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

            <Card>
                <Card.Header className="border-bottom">
                    <Row className="mb-2" >
                        <Col xs={9} >
                            <h4>{t(lang, "edi.contract.datatable.header.row.number")}: № <strong>{contractFormation?.number}</strong></h4>
                            <div className="fs--1">
                                <p className="mb-1">{t(lang, "edi.contract.datatable.header.row.date")}: <strong>{dayjs(contractFormation?.date, 'YYYY-MM-DD').format(dateFormat)}</strong></p>
                                <p className="mb-1">{t(lang, "edi.common.label.name")}: <strong>{contractFormation?.title}</strong></p>
                                <p className="mb-1">{t(lang, "main.billing.active_packages_card.package_expires_on")}: <strong>{dayjs(contractFormation?.expires_on, 'YYYY-MM-DD').format(dateFormat)}</strong></p>
                                <p className="mb-1">{t(lang, "roaming.contracts.add.contract_info.place_conclusion")}: <strong>{contractFormation?.place}</strong></p>
                            </div>
                            {warningInRework &&
                                <p className={"text-danger fs-1 d-inline"}><b>{t(lang, "edi.common.label.comment")}:</b> {contract?.content?.note}</p>

                            }
                        </Col>
                        <Col xs={3} className="text-end">
                            <Col className="d-inline-flex flex-column text-center">
                                <h5>
                                    <SoftBadge bg={GetStatusVariant(contract?.status)} className='me-2'>
                                        {GetStatusText(contract?.status)}
                                    </SoftBadge>
                                </h5>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <p><span className="text-capitalize fw-bold">{t(lang,"edi.common.forms.keywords.id")}: </span>{contract.uid}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex align-items-center justify-content-end">
                            {allowEdit &&
                                <Nav.Link as={dispatch(checkPermission(PermissionContractFormationUpdate)) ? Link : 'div'}
                                          to={`../update/${contract.id}`}
                                            onClick={()=>!dispatch(checkPermission(PermissionContractFormationUpdate)) && dispatch(toastPermission())}>
                                    <Button variant="falcon-warning" size="sm" >
                                        {t(lang,"edi.common.edit_button")}
                                    </Button>
                                </Nav.Link>
                            }
                            {allowSend &&
                                <Button  variant="falcon-success" size="sm" className="mx-2" onClick={sendContractFormationHandler}>
                                    {t(lang,"roaming.common.send")}
                                </Button>
                            }
                            {allowSign &&
                                <SelectCertificateWrapperComponent
                                    submitButton={
                                        <IconButton onClick={signContractFormation} id="sendButton" size="sm" className="mx-2" transform="shrink-3" variant="falcon-primary" icon={faSignature}>
                                            {t(lang, 'edi.common.button.sign')}
                                        </IconButton>
                                    }
                                    openDialogButtonText={t(lang, 'edi.common.button.sign')}
                                    permission={PermissionContractFormationSign}
                                />
                            }

                            <Button variant="falcon-primary" size="sm" className="mx-2" onClick={pdfFileDownload}>
                                {pdfloading && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                                {t(lang,"edi.contract_formation.view.download_pdf")}
                            </Button>

                            {showEditButtonIKPU &&
                            <IconButton variant={showTag ? "falcon-info" : "falcon-danger"} size="sm" className="mx-2"
                                         onClick={()=>dispatch(checkPermission(PermissionContractFormationUpdate)) ? updateItemShowButton() : dispatch(toastPermission())}
                                         icon={showTag ? "pencil" : "arrow-down"}>{t(lang, "edi.common.edit_button")} ИКПУ
                                код</IconButton>
                            }
                        </Col>
                    </Row>
                    {showTaxLink &&
                        <Row>
                            <Col>
                                <a href={`https://my.soliq.uz/faktura/ru/contractDoc/view/${contractFormation.roaming_operator_uid}`}>{t(lang,"edi.contract_formation.view.roaming_operator_uid")}</a>
                            </Col>
                        </Row>
                    }
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-between">
                        {contractFormation?.header &&
                            <Col>
                                <h5 className="mb-3 text-center">{t(lang, "edi.contract_formation.info_header.title")}</h5>
                                <p>{contractFormation?.header}</p>
                            </Col>
                        }
                        <Col xs={12}>
                            <h5 className="text-center mb-3">{t(lang, "roaming.contract.part.last")}</h5>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Table bordered responsive size="sm">
                                <thead>
                                <tr><th colSpan={2}><h5>{t(lang, "roaming.contract.company.contractor")}</h5></th></tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.tin")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.company_inn}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.name")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.company_name}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang,"edi.contract_formation.customer.director.inn")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.physic_inn}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang,"edi.common.director")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.physic_name}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.address")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.address}</td>
                                </tr>
                                <tr className={contractFormation?.customer?.phone === "" ? 'd-none' : ''}>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang,"roaming.contracts.add.clients_info.work_phone_number")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.phone}</td>
                                </tr>
                                <tr className={contractFormation?.customer?.mobile === "" ? 'd-none' : ''}>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang,"roaming.contracts.add.clients_info.mobile_number")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.mobile}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.account")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.bank_account}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.bank")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.bank_name}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.mfo")}</td>
                                    <td className="align-middle">{contractFormation?.customer?.bank_mfo}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Table bordered responsive size="sm">
                                <thead>
                                <tr>
                                    <th colSpan={2}>
                                        <h5>{t(lang, "edi.common.executor")}</h5>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.tin")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.company_inn}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.name")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.company_name}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang,"edi.contract_formation.customer.director.inn")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.physic_inn}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang,"edi.common.director")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.physic_name}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.address")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.address}</td>
                                </tr>
                                <tr className={contractFormation?.executor?.phone === "" ? 'd-none' : ''}>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang,"roaming.contracts.add.clients_info.work_phone_number")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.phone}</td>
                                </tr>
                                <tr className={contractFormation?.executor?.mobile === "" ? 'd-none' : ''}>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang,"roaming.contracts.add.clients_info.mobile_number")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.mobile}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.account")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.bank_account}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.bank")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.bank_name}</td>
                                </tr>
                                <tr>
                                    <td className="align-middle col-lg-5 col-md-3 col-sm-4">{t(lang, "roaming.contract.company.info.mfo")}</td>
                                    <td className="align-middle">{contractFormation?.executor?.bank_mfo}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <div className="table-responsive fs--1 mt-3">
                        <Table striped className="border-bottom">
                            <thead className="table-primary">
                            <tr>
                                <th className="align-middle" rowSpan="2">№</th>
                                <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.name")}</th>
                                <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.catalog")}</th>
                                <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.barcode")}</th>
                                <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.measurement")}</th>
                                <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.quantity")}</th>
                                <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.price")}</th>
                                <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.total")}</th>
                                <th className="align-middle text-center" colSpan="2">{t(lang, "edi.common.items.product.excise")}</th>
                                <th className="align-middle text-center" colSpan="2">{t(lang, "roaming.contract.items_datatable.vat")}</th>
                                <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.total_with_vat")}</th>
                                <th className="align-middle" rowSpan="2"></th>
                            </tr>
                            <tr>
                                <th className="align-middle">{t(lang, "edi.common.items.product.excise")}</th>
                                <th className="align-middle">{t(lang, "edi.common.items.product.excise_value")}</th>
                                <th className="align-middle">{t(lang, "roaming.contract.items_datatable.vat.rate")}</th>
                                <th className="align-middle">{t(lang, "roaming.contract.items_datatable.vat.sum")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {contractFormation?.items?.map((item, index) => {

                                return (
                                    <tr key={item?.id}>
                                        <ContractFormationViewItemUpdate item={item} index={index} showTag={showTag} contractFormation={contractFormation}/>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                        <Row className="g-0 d-flex flex-column align-items-end">
                            <Col xs="auto">
                                <Table borderless size="sm" className="fs--1 text-end">
                                    <tbody>
                                    <tr>
                                        <th className="text-900">{t(lang, 'edi.common.total')}:</th>
                                        <td className="fw-semi-bold">{numeral.formats[numberFormat].format(subtotal)}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-900">{t(lang, 'edi.common.nds_value')}:</th>
                                        <td className="fw-semi-bold">{numeral.formats[numberFormat].format(subtotalNdsValue)}</td>
                                    </tr>
                                    <tr className="border-top">
                                        <th className="text-900">{t(lang, 'edi.common.total_with_nds')}:</th>
                                        <td className="fw-semi-bold">{numeral.formats[numberFormat].format(subtotalWithNds)}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                {!showTag &&
                                    <Button type="submit" variant="falcon-info">
                                        {t(lang,"edi.common.save_button")} все
                                    </Button>
                                }
                            </Col>
                        </Row>
                        <p className="fw-bold">
                            {t(lang, "roaming.contract.items_datatable.amount_in_words_1")}: {contractFormation?.total_in_words}
                        </p>
                    </div>

                    <Col className="border-top my-4 p-2">
                        {contractFormation?.parts?.map(item => (
                            <div key={item?.id}>
                                <h5 className="ms-4">{item?.title}</h5>
                                <p>{item?.body}</p>
                            </div>
                        ))}
                    </Col>

                    <Divider/>


                    {contractFormation?.files.length > 0 &&
                        <Row>
                            <Col xs={12} lg={10}>
                                <Table bordered responsive size="sm">
                                    <thead>
                                    <tr>
                                        <th colSpan={4}>
                                            <h4>{t(lang,"edi.contract_formation.modal.file_view")}</h4>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {contractFormation?.files?.map((file, index) => (
                                        <tr key={file?.id} className={'my-2'}>
                                            <td className="align-middle">{index + 1})</td>
                                            <td className="align-middle">{file?.filename}</td>
                                            <td className="align-middle">{file?.expires_on && dayjs(file?.expires_on, 'YYYY-MM-DD').format(dateFormat)}</td>
                                            <td className="align-middle">
                                                <Button id={file?.id} variant="falcon-warning" size="sm" className="mx-2" onClick={(e) => ContractFormationDownloadFile(e, file?.filename)}>
                                                    {t(lang,"edi.contract_formation.attachemnt.file_download")}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    }

                </Card.Body>
            </Card>
        </>
    )
}

export default ContractFormationView;
