import {toast} from "react-toastify";
import {Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import IconButton from "../../../common/IconButton";
import CommonDeleteModal from "../../../common/CommonDeleteModal";
import {PDFTemplates} from "../../../../enum/OrganizationTemplate";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import AddOrganizationPDFTemplateModal from "../modal/AddOrganizationPDFTemplateModal";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import UpdateOrganizationPDFTemplateModal from "../modal/UpdateOrganizationPDFTemplateModal";
import {deleteOrganizationTemplateAsync, downloadOrganizationTemplateAsync, loadOrganizationTemplatesAsync} from "../../../../app/store/reducers/print/printReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_ORGANIZATION_TEMPLATE_SUCCEED, DELETE_ORGANIZATION_TEMPLATE_SUCCEED, UPDATE_ORGANIZATION_TEMPLATE_SUCCEED} from "../../../../app/eventbus/printEvents";

const OrganizationPdfTemplates = ({organizationId}) => {
    const [organizationTemplates, setOrganizationTemplates] = useState([]);
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        const onOrganizationPDFTemplateSuccessfullyAdded = EventBus.on(ADD_ORGANIZATION_TEMPLATE_SUCCEED, () => {
            loadOrganizationTemplates();
        });

        const onOrganizationPDFTemplateSuccessfullyUpdated = EventBus.on(UPDATE_ORGANIZATION_TEMPLATE_SUCCEED, () => {
            loadOrganizationTemplates();
        });

        const onOrganizationPDFTemplateSuccessfullyDeleted = EventBus.on(DELETE_ORGANIZATION_TEMPLATE_SUCCEED, () => {
            loadOrganizationTemplates();
        });

        return () => {
            EventBus.remove(ADD_ORGANIZATION_TEMPLATE_SUCCEED, onOrganizationPDFTemplateSuccessfullyAdded);
            EventBus.remove(UPDATE_ORGANIZATION_TEMPLATE_SUCCEED, onOrganizationPDFTemplateSuccessfullyUpdated);
            EventBus.remove(DELETE_ORGANIZATION_TEMPLATE_SUCCEED, onOrganizationPDFTemplateSuccessfullyDeleted);
        };
    }, [])

    useEffect(() => {
        loadOrganizationTemplates();
    }, [organizationId, activeOrganization])

    const loadOrganizationTemplates = () => {
        loadOrganizationTemplatesAsync(organizationId)
            .then(loadedTemplates => setOrganizationTemplates(loadedTemplates))
            .catch(() => setOrganizationTemplates([]))
    };

    const downloadTemplate = (templateId, templateCode) => {
        downloadOrganizationTemplateAsync(organizationId, templateId)
            .then((fileContent) => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([fileContent], {type: 'text/plain'});
                const blobUrl = window.URL.createObjectURL(blobFile);
                const templateLabel = t(lang, PDFTemplates[templateCode]?.label);

                link.href = blobUrl;
                link.download = `${organizationId}-${templateLabel}.gohtml`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')));
    };

    const deleteTemplate = (templateId) => {
        deleteOrganizationTemplateAsync(organizationId, templateId)
            .then(() => toast.success(t(lang, 'common.toast.success')))
            .catch(() => toast.toast.error(t(lang, 'common.toast.error')));
    };

    return (
        <div>
            <div className="card-header text-end">
                <AddOrganizationPDFTemplateModal organizationId={organizationId}/>
            </div>

            <Table size={'lg'} hover={true}>
                <thead className={'bg-300'}>
                <tr>
                    <th>№</th>
                    <th>{t(lang, 'admin.organization_templates.datatable.column.template_type')}</th>
                    <th className={'text-end'}/>
                </tr>
                </thead>
                <tbody>
                {organizationTemplates.map((template, index) => (
                    <tr key={template.id}>
                        <td>{index + 1}</td>
                        <td className={'fw-semi-bold text-black'}>{t(lang, PDFTemplates[template.template_code]?.label)}</td>
                        <td className={'text-end'}>
                            <IconButton className={'mx-1'} size={'md'} icon={'cloud-arrow-up'} variant={'primary'} onClick={() => downloadTemplate(template.id, template.template_code)}/>
                            <UpdateOrganizationPDFTemplateModal organizationId={organizationId} template={template}>
                                <IconButton className={'mx-1'} size={'md'} icon={'pencil'} variant={'warning'}/>
                            </UpdateOrganizationPDFTemplateModal>
                            <CommonDeleteModal onDelete={() => deleteTemplate(template.id)}>
                                <IconButton className={'mx-1'} size={'md'} icon={'trash'} variant={'danger'}/>
                            </CommonDeleteModal>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};

export default OrganizationPdfTemplates;