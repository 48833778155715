import React, {Fragment, useState} from 'react';
import ShipmentInfoForm from "./ShipmentInfoForm";
import ShipmentItemsForm from "./ShipmentItemsForm";
import ShippingDetailsForm from "./ShippingDetailsForm";
import MembersInfoFormV2 from "../../roaming/waybill/forms/subformV2/MembersInfoFormV2";
import DetailsFormV2 from "../../roaming/waybill/forms/subformV2/DetailsFormV2";
import AdditionalInformationV2 from "../../roaming/waybill/forms/subformV2/AdditionalInformationV2";
import WaybillItemsV2Form from "../../roaming/waybill/forms/subformV2/WaybillItemsV2Form";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import DeliveryForm from "../../roaming/waybill/forms/subformV2/DeliveryForm";
import {useWatch} from "react-hook-form";

const ShipmentForm = ({order, shipment, isEditing, loading}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const isCustomer = activeOrganization?.inn === order?.customer?.inn;

    const is_hide_additional_information = useWatch({name: 'is_hide_additional_information', exact: true})
    return (
        <>
            <ShipmentInfoForm order={order} />
            {/*<ShippingDetailsForm order={order} />*/}
            {!isCustomer && <Fragment>
                <MembersInfoFormV2
                    ediShipment={true}
                    isEditing={isEditing}
                />
                <DetailsFormV2
                    ediShipment={true}
                />
                {
                    !is_hide_additional_information && <AdditionalInformationV2
                        ediShipment={true}
                        isEditing={isEditing}
                    />
                }
                <DeliveryForm/>
            </Fragment>
            }

            <ShipmentItemsForm isEditing={isEditing} shipment={shipment} order={order} loading={loading} />
        </>
    );
};

export default ShipmentForm;
