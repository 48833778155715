import React, {useEffect} from "react";
import BranchDataTable from "../../../../components/hippo/branch/BranchDataTable";
import {
    loadBranchDataTableColumn,
    loadBranchesAsync,
    selectBranchDataTable
} from "../../../../app/store/reducers/branch/branchReducer";
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ADD_BRANCH_SUCCESS, BIND_WAREHOUSE_SUCCESS, DELETE_BRANCH_ROAMING_ADDRESS_SUCCEED,
    DELETE_BRANCH_SUCCESS,
    EDIT_BRANCH_SUCCESS, UPDATE_BRANCH_ROAMING_ADDRESS_SUCCEED
} from "../../../../app/eventbus/branchEvents";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {Card} from "react-bootstrap";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {PermissionBranchView} from "../../../../enum/Permission/RolePermission";
import {loadCompanyBranchesAsync} from "../../../../app/store/reducers/roaming/roamingReducer";

const Branches = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization)
    const activeColumns = useSelector(selectBranchDataTable)
    // important
    useSelector(selectAccountRolePermissions)

    useEffect(() => {
        dispatch(loadCompanyBranchesAsync(activeOrganization?.inn));
    }, [activeOrganization]);

    useEffect(() => {
        const onBranchAddedHandler = EventBus.on(ADD_BRANCH_SUCCESS, () => {
            dispatch(loadBranchesAsync())
            dispatch(loadBranchDataTableColumn())
        });

        const onBranchEditedHandler = EventBus.on(EDIT_BRANCH_SUCCESS, () => {
            toast.success(t(lang, 'edi.toast.edit_branch_succeed'));
            dispatch(loadBranchesAsync())
            dispatch(loadBranchDataTableColumn())
        });

        const onBranchDeletedHandler = EventBus.on(DELETE_BRANCH_SUCCESS, () => {
            toast.success(t(lang, 'edi.toast.delete_branch_succeed'));
            dispatch(loadBranchesAsync())
            dispatch(loadBranchDataTableColumn())
        });

        const onBranchWarehouseBindHandler = EventBus.on(BIND_WAREHOUSE_SUCCESS, () => {
            toast.success(t(lang, 'edi.common.toast.success'))
            dispatch(loadBranchesAsync())
            dispatch(loadBranchDataTableColumn())
        })

        const onBranchRoamingAddressUpdated = EventBus.on(UPDATE_BRANCH_ROAMING_ADDRESS_SUCCEED, () => {
            dispatch(loadBranchesAsync());
            dispatch(loadBranchDataTableColumn());
        });

        const onBranchRoamingAddressDeleted = EventBus.on(DELETE_BRANCH_ROAMING_ADDRESS_SUCCEED, () => {
            dispatch(loadBranchesAsync());
            dispatch(loadBranchDataTableColumn());
        });

        return () => {
            EventBus.remove(ADD_BRANCH_SUCCESS, onBranchAddedHandler)
            EventBus.remove(EDIT_BRANCH_SUCCESS, onBranchEditedHandler);
            EventBus.remove(DELETE_BRANCH_SUCCESS, onBranchDeletedHandler);
            EventBus.remove(BIND_WAREHOUSE_SUCCESS, onBranchWarehouseBindHandler);
            EventBus.remove(UPDATE_BRANCH_ROAMING_ADDRESS_SUCCEED, onBranchRoamingAddressUpdated);
            EventBus.remove(DELETE_BRANCH_ROAMING_ADDRESS_SUCCEED, onBranchRoamingAddressDeleted);
        }
    }, []);

    useEffect(() => {
        dispatch(loadBranchDataTableColumn())
    }, [activeOrganization])

    if (!dispatch(checkPermission(PermissionBranchView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.edi.branch.datatable.no_access.title')}</h4>
        </Card>
    }

    return <BranchDataTable activeColumns={activeColumns}/>
};

export default Branches;
