import dayjs from "dayjs";
import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Card, Spinner} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import FreeFormDocumentDataTableHeader from "./FreeFormDocumentDataTableHeader";
import FreeFormDocumentDataTableFooter from "./FreeFormDocumentDataTableFooter";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {GetStatusText, GetStatusVariant} from '../../../../enum/roaming/FreeFormDocumentStatus';
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectFreeFormDocuments, selectFreeFormDocumentsFilterOptions, selectFreeFormDocumentsLoading, setId, setShowModal, viewPage} from "../../../../app/store/reducers/roaming/roamingFreeFormDocumentReducer";
import ViewFreeFormDocument from "../../../../pages/main/roaming/freeform/ViewFreeFormDocument";
import ViewModalPage from "../../../hippo/roaming/components/ViewModalPage";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";

const FreeFormDocumentDataTable = () => {
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const loading = useSelector(selectFreeFormDocumentsLoading);
    const freeFormDocuments = useSelector(selectFreeFormDocuments);
    const filter = useSelector(selectFreeFormDocumentsFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);

    const {id, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (ctrlKey) {
            history('/#/roaming/free-form/'+id, '_blank')
        } else {
            dispatch(setId(id))
            dispatch(setShowModal(true))
        }

    }

    const onHideModalPage = () => {
        if (showModal) {
            dispatch(setShowModal(false))
        }
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_number'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                return (
                    <strong onClick={(e)=>openViewModalPage(e,original.id)} className={'cursor-pointer text-primary'}>{original.info.number}</strong>
                );
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_date'),
            headerProps: {className: 'pe-2'},
            Cell: rowData => {
                const {original} = rowData.row
                return dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'status',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_status'),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <h5><SoftBadge bg={GetStatusVariant(original.status)} className='me-2'>{t(lang, GetStatusText(original.status))}</SoftBadge></h5>
                )
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_counterparty'),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return activeOrganization.inn === original.sender.inn
                    ? `${original.receiver.name} (${original.receiver.inn})`
                    : `${original.sender.name} (${original.sender.inn})`
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, 'roaming.free_form_document.datatable.row.document_created_at'),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return dayjs(original.created_at).format(currentDateDayJSFormat)
            }
        },
    ];

    return (
        <>
            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={freeFormDocuments}
            >
                <Card>
                    <Card.Header>
                        <FreeFormDocumentDataTableHeader/>
                    </Card.Header>
                    <Card.Body>
                        {loading
                            ?
                            <div className="w-100 h-100 text-center">
                                <Spinner className="mx-auto" variant="primary" animation="border" role="switch"/>
                            </div>
                            :
                            <AdvanceTable
                                table
                                rowClassName="align-middle white-space-nowrap"
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        }
                    </Card.Body>
                    <Card.Footer>
                        <FreeFormDocumentDataTableFooter/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewFreeFormDocument modalId={id} />}
            </ViewModalPage>

        </>
    );
};

export default FreeFormDocumentDataTable;
