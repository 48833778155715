import {createSlice} from "@reduxjs/toolkit";
import contractorPaymentService from "../../../../services/contractorPaymentService";
import EventBus from "../../../eventbus/EventBus";
import dayjs from "dayjs";
import queryString from "query-string";
import {
    ADD_CONTRACTOR_PAYOUT_REQUESTED,
    ADD_CONTRACTOR_PAYOUT_FAILED,
    ADD_CONTRACTOR_PAYOUT_SUCCESS,
    DELETE_CONTRACTOR_PAYOUT_REQUESTED,
    DELETE_CONTRACTOR_PAYOUT_SUCCESS,
    DELETE_CONTRACTOR_PAYOUT_FAILED,
    EDIT_CONTRACTOR_PAYOUT_REQUESTED,
    EDIT_CONTRACTOR_PAYOUT_SUCCESS,
    EDIT_CONTRACTOR_PAYOUT_FAILED,
    EDIT_CONTRACTOR_PAYOUT_NOTE_REQUESTED,
    EDIT_CONTRACTOR_PAYOUT_NOTE_SUCCESS,
    EDIT_CONTRACTOR_PAYOUT_NOTE_FAILED,
    EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_REQUESTED,
    EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_SUCCESS,
    EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_FAILED,
    EDIT_CONTRACTOR_PAYOUT_DATE_REQUESTED,
    EDIT_CONTRACTOR_PAYOUT_DATE_SUCCESS, EDIT_CONTRACTOR_PAYOUT_DATE_FAILED,
} from "../../../eventbus/contractor/contractorPaymentEvents";

export const contractorPayoutSlice = createSlice({
    name: 'contractorPayout',
    initialState: {
        filterOptions: {page: 1, limit: 10},
        contractorPayouts: [],

        cardFilterOptions: {
            date_start: dayjs(new Date()).format('YYYY-MM-DD'),
            date_end: dayjs(new Date()).format('YYYY-MM-DD'),
        },
        contractorCardPayouts: [],

        isLoading: false,
        count: 0,
        contractor: null
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateContractorsCount: (state, action) => {
            state.count = action.payload
        },
        updatePayoutContractors: (state, action) => {
            state.contractorPayouts = action.payload
        },
        updateContractor: (state, action) => {
            state.contractor = action.payload || null
        },
        updateContractorPaymentsCount: (state, action) => {
            state.count = action.payload
        },
        updateCardFilterOptions: (state, action) => {
            state.cardFilterOptions = {...state.cardFilterOptions, ...action.payload}
        },
        updatePayoutCardContractors: (state, action) => {
            state.contractorCardPayouts = action.payload
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
})
export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorPayoutSlice.actions.updateFilterOptions({...params}))
}
export const updateContractor = (contractor) => dispatch => {
    dispatch(contractorPayoutSlice.actions.updateContractor(contractor))
}
export const changeCardFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorPayoutSlice.actions.updateCardFilterOptions({...params}))
}

export const registerContractorPayoutAsync = (payload) => () => {
    EventBus.dispatch(ADD_CONTRACTOR_PAYOUT_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.registerContractorPayout(payload)
            .then(response => {
                EventBus.dispatch(ADD_CONTRACTOR_PAYOUT_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_CONTRACTOR_PAYOUT_FAILED, error)
                reject(error);
            })
    });
}

export const loadContractorPayoutsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    dispatch(contractorPayoutSlice.actions.setLoading(true));
    contractorPaymentService.getContractorPayouts(queryString.stringify(params))
        .then((response) => {
            dispatch(contractorPayoutSlice.actions.updatePayoutContractors(response.data));
        })
        .catch(error => {
            console.log(error);
        }).finally(() => {
        dispatch(contractorPayoutSlice.actions.setLoading(false));
    });
}

export const loadContractorPayoutCountAsync = (params) => dispatch => {
    let {limit, page, ...other} = params

    contractorPaymentService.getContractorPayoutsCount(queryString.stringify(other))
        .then((response) => {
            dispatch(contractorPayoutSlice.actions.updateContractorPaymentsCount(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const loadCardContractorPayoutsAsync = ({...filters}) => dispatch => {
    const params = {...filters}
    dispatch(contractorPayoutSlice.actions.setLoading(true));
    contractorPaymentService.getContractorPayouts(queryString.stringify(params))
        .then((response) => {
            dispatch(contractorPayoutSlice.actions.updatePayoutCardContractors(response.data));
        })
        .catch(error => {
            console.log(error);
        }).finally(() => {
        dispatch(contractorPayoutSlice.actions.setLoading(false));
    });
}

export const loadOneContractorPayoutsAsync = (id) => {
    return new Promise((resolve, reject) => {
        contractorPaymentService.getContractorPayout(id)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const deleteContractorPayoutAsync = ({id}) => {
    EventBus.dispatch(DELETE_CONTRACTOR_PAYOUT_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        contractorPaymentService.deleteContractorPayout({id})
            .then((response) => {
                EventBus.dispatch(DELETE_CONTRACTOR_PAYOUT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_CONTRACTOR_PAYOUT_FAILED, error.message);
                reject(error)
            })
    });
};

export const editContractorPayoutAsync = ({payout_id, payload}) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.editContractorPayout({payout_id, payload})
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_FAILED, error)
                reject(error);
            })
    });
}

export const editContractorPayoutNoteAsync = ({payout_id, payload}) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_NOTE_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.editContractorPayoutNote({payout_id, payload})
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_NOTE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_NOTE_FAILED, error)
                reject(error);
            })
    });
}

export const editContractorPayoutContractorAsync = ({payout_id, payload}) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.editContractorPayoutContractor({payout_id, payload})
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_FAILED, error)
                reject(error);
            })
    });
}

export const editContractorPayoutDateAsync = ({payout_id, payload}) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_DATE_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.editContractorPayoutDate({payout_id, payload})
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_DATE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYOUT_DATE_FAILED, error)
                reject(error);
            })
    });
}

export const selectContractorPayouts = (state) => state.contractorPayout.contractorPayouts;
export const selectFilterOptionsContractorPayout = (state) => state.contractorPayout.filterOptions;

export const selectFilterOptionsCardContractorPayout = (state) => state.contractorPayout.cardFilterOptions;
export const selectCardContractorPayouts = (state) => state.contractorPayout.contractorCardPayouts;

export const selectPayoutContractorCount = (state) => state.contractorPayout.count;

export const selectLoading = state => state.contractorPayout.isLoading;
export const selectPaymentContractorCount = (state) => state.contractorPayout.count;
export const selectContractor = (state) => state.contractorPayment.contractor;

export default contractorPayoutSlice.reducer;