import React, { useEffect, useState } from "react"
import {Card, Col, Row, Table} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import { selectDateDayJSFormat, selectLang, selectNumberFormat, Translate } from "../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import { selectCurrentCertificate } from "../../../../app/store/reducers/eimzo/eimzoReducer";
import { selectActiveOrganization } from "../../../../app/store/reducers/auth/authReducer";
import {faClone, faSignature} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/IconButton";
import ConvertQuantityToWord from "../../../../app/filter/ConvertQuantityToWord";
import numeral from "numeral";
import { VerificationActWrapper } from "../../../../enum/VerificationActStatus";
import DeclineContract from "./Actions/DeclineVerificationAct";
import CancelContract from "./Actions/CancelVerificationAct";
import { cancelAsync, customerAcceptAsync, customerDeclineAsync, verificationActSignAndRegister } from "../../../../app/store/reducers/roaming/roamingVerificationActReducer";
import SoftBadge from "../../../common/SoftBadge";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import SignActionWithSelectCertificatWrapper from "../components/SignActionWithSelectCertificatWrapper";
import {
    PermissionRoamingVerificationActAccept,
    PermissionRoamingVerificationActUpdate
} from "../../../../enum/Permission/RoamingPermission";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ROAMING_VERIFICATION_ACT_SYNC_FAILED,
    ROAMING_VERIFICATION_ACT_SYNC_SUCCESS
} from "../../../../app/eventbus/roaming/roamingVerificationActEvents";
import WarningAlert from "../components/WarningAlert";
import RoamingVerificationActSync from "./RoamingVerificationActSync";


const VerificationAct = ({verificationAct}) => {
    const dispatch= useDispatch()
    const lang = useSelector(selectLang);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const numberFormat = useSelector(selectNumberFormat)
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [showAcceptButton, setShowAcceptButton] = useState(false)
    const [showDeclineButton, setShowDeclineButton] = useState(false)
    const [showCopyButton, setShowCopyButton] = useState(false)
    const verificationActWrapper = new VerificationActWrapper(verificationAct)
    const t = Translate;
    const showDeclineNotes = verificationActWrapper.enumStatuses.buyerOrAgentIsDeclined === verificationAct.status;
    const [eventMessage, setEventMessage] = useState(null);

    useEffect(() => {
        setShowCancelButton(verificationActWrapper.canTheSellerCancled(activeOrganization.inn))
        setShowAcceptButton(verificationActWrapper.canTheBuyerSign(activeOrganization.inn))
        setShowDeclineButton(verificationActWrapper.canTheBuyerDecline(activeOrganization.inn))
        setShowCopyButton(verificationActWrapper.allowCopy(activeOrganization.inn))
    }, [verificationAct, activeOrganization.inn])

    useEffect(() => {
        //  success
        const onVerificationActSyncSuccessHandler = EventBus.on(ROAMING_VERIFICATION_ACT_SYNC_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.verification_act.alert.success_event_title'),
                text: t(lang, 'roaming.verification_act.alert.verification_act_sync_success')
            });
        })
        // fail
        const onVerificationActSyncFailHandler = EventBus.on(ROAMING_VERIFICATION_ACT_SYNC_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.verification_act.alert.failed_event_title'),
                text: error.message
            })
        })

        return () => {
            EventBus.remove(ROAMING_VERIFICATION_ACT_SYNC_SUCCESS, onVerificationActSyncSuccessHandler)
            EventBus.remove(ROAMING_VERIFICATION_ACT_SYNC_FAILED, onVerificationActSyncFailHandler)
        }
    }, []);

    const executorSignAndSend = async (data) => {
        try {
            if(currentCertificate?.keyId) {
              let verificationAct = {
                VerificationActId: data.id,
                VerificationActDoc: {
                  VerificationActNo: data.info.number,
                  VerificationActDate: dayjs(data.info.date).format("YYYY-MM-DD"),
                  VerificationActText: data.info.text
                },
                OwnerTin: data.executor.inn,
                OwnerName: data.executor.name,
                OwnerFizTin: data.executor.director_inn,
                OwnerFizFio: data.executor.director_name,
                PartnerTin: data.customer.inn,
                PartnerName: data.customer.name,
                PartnerFizTin: data.customer.director_inn,
                PartnerFizFio: data.customer.director_name,
                VerificationActContracts: data.contracts.map(contract => ({
                  ContractNo: contract.contract_no,
                  ContractDate: dayjs(contract.contract_date).format("YYYY-MM-DD"),
                  VerificationActContractItems: contract.items.map(item => ({
                    OwnerOperationDate: dayjs(item.executor_operation_date).format("YYYY-MM-DD"),
                    OwnerOperationName: item.executor_operation_name,
                    OwnerDebit: item?.executor_debit?.toFixed(2),
                    OwnerCredit: item?.executor_credit?.toFixed(2),
                    PartnerOperationDate: dayjs(item.customer_operation_date).format("YYYY-MM-DD"),
                    PartnerOperationName: item.customer_operation_name,
                    PartnerDebit: item?.customer_debit?.toFixed(2),
                    PartnerCredit: item?.customer_credit?.toFixed(2)
                  })),
                  OpenBalance: {
                    OwnerDebit: contract?.open_balance?.executor_debit?.toFixed(2),
                    OwnerCredit: contract?.open_balance?.executor_credit?.toFixed(2),
                    PartnerDebit: contract?.open_balance?.customer_debit?.toFixed(2),
                    PartnerCredit: contract?.open_balance?.customer_credit?.toFixed(2)
                  },
                  CloseBalance: {
                    OwnerDebit: contract?.close_balance?.executor_debit?.toFixed(2),
                    OwnerCredit: contract?.close_balance?.executor_credit?.toFixed(2),
                    PartnerDebit: contract?.close_balance?.customer_debit?.toFixed(2),
                    PartnerCredit: contract?.close_balance?.customer_credit?.toFixed(2)
                  },
                  TotalBalance: {
                    OwnerDebit: contract?.total_balance?.executor_debit?.toFixed(2),
                    OwnerCredit: contract?.total_balance?.executor_credit?.toFixed(2),
                    PartnerDebit: contract?.total_balance?.customer_debit?.toFixed(2),
                    PartnerCredit: contract?.total_balance?.customer_credit?.toFixed(2)
                  }

                })),
                OpenBalance: {
                  OwnerDebit: data?.open_balance?.executor_debit?.toFixed(2),
                  OwnerCredit: data?.open_balance?.executor_credit?.toFixed(2),
                  PartnerDebit: data?.open_balance?.customer_debit?.toFixed(2),
                  PartnerCredit: data?.open_balance?.customer_credit?.toFixed(2),
                },
                TurnoverBalance: {
                  OwnerDebit: data?.turnover_balance?.executor_debit?.toFixed(2),
                  OwnerCredit: data?.turnover_balance?.executor_credit?.toFixed(2),
                  PartnerDebit: data?.turnover_balance?.customer_debit?.toFixed(2),
                  PartnerCredit: data?.turnover_balance?.customer_credit?.toFixed(2),
                },
                CloseBalance: {
                  OwnerDebit: data?.close_balance?.executor_debit?.toFixed(2),
                  OwnerCredit: data?.close_balance?.executor_credit?.toFixed(2),
                  PartnerDebit: data?.close_balance?.customer_debit?.toFixed(2),
                  PartnerCredit: data?.close_balance?.customer_credit?.toFixed(2),
                }
              }
              await verificationActSignAndRegister({
                verificationAct: verificationAct,
                certificate: currentCertificate,
                lang: lang
              })
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

            {!eventMessage && verificationAct?.roaming_error && <WarningAlert text={verificationAct?.roaming_error.message} title={'Ошибка от роуминга'} variant={'danger'}/>}

            <Card className="mb-3">
                <Card.Header className="border-bottom pb-4">
                    <Row className="justify-content-between">
                        <Col>
                            <p className="fs-1 fw-bold mb-2 mb-md-0">{t(lang, "roaming.verification_act.view.title")}</p>
                            <ul className="fs-1 mt-1 list-unstyled">
                                <li className="mb-1 fw-bold">{t(lang, "roaming.verification_act.view.info", {number: verificationAct?.info?.number, date: dayjs(verificationAct?.info?.date, 'YYYY-MM-DD').format(dateFormat)})}</li>
                                <li className="mb-1">{t(lang, "roaming.verification_act.view.subInfo-1", {owner: verificationAct?.executor?.name, partner: verificationAct?.customer?.name})}<br /> {t(lang, "roaming.verification_act.view.subInfo-2", {owner: verificationAct?.executor?.name, partner: verificationAct?.customer?.name})}</li>
                            </ul>
                            {verificationAct?.status &&
                                <>
                                    <Col className="d-flex flex-column">
                                        <div>
                                            <span className="me-2 fw-medium">{t(lang, 'edi.common.label.status')}: </span>
                                            <SoftBadge pill className="fs--1 fw-bold" bg={verificationActWrapper.getRoamingStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}>
                                                {t(lang, verificationActWrapper.getRoamingStatus(activeOrganization.pinfl || activeOrganization.inn))}
                                            </SoftBadge>
                                        </div>
                                        {showDeclineNotes &&
                                            <div className="alert-danger fs--1 p-1 text-center col-8 mt-3">
                                                <span className="fw-bold fs-0 me-2">{t(lang, "roaming.common.comment")}:</span>
                                                <strong>{verificationAct.notes}</strong>
                                            </div>
                                        }
                                    </Col>
                                </>
                            }
                        </Col>
                        <Col xs="12" md={6} className="text-end">
                            {showCopyButton &&
                                <IconButton
                                    variant="falcon-primary"
                                    size="sm"
                                    icon={faClone}
                                    as={Link}
                                    to={verificationAct?.status ? `/roaming/verification-act/actions/${verificationAct.id}?status=${verificationAct?.status}` : `/roaming/verification-act/actions/${verificationAct.id}?status=${null}`}
                                    className="mx-2 d-inline"
                                >
                                    {t(lang, "roaming.common.copy")}
                                </IconButton>
                            }
                             <RoamingVerificationActSync id={verificationAct.id} className="me-2" />
                            {verificationAct?.status ?
                                <>
                                    {showAcceptButton &&
                                        <SignActionWithSelectCertificatWrapper permission={PermissionRoamingVerificationActAccept}>
                                            <IconButton
                                                variant="falcon-primary"
                                                size="sm"
                                                icon={faSignature}
                                                iconClassName="me-1"
                                                className="ms-sm-2 ms-0 mb-2 mb-sm-0"
                                                onClick={() => customerAcceptAsync(currentCertificate, lang, verificationAct)}
                                                disabled={!currentCertificate}
                                            >{t(lang, 'edi.common.button.sign')}</IconButton>
                                        </SignActionWithSelectCertificatWrapper>
                                    }

                                    {showCancelButton &&
                                        <CancelContract
                                            className="ms-2 mb-2 mb-sm-0"
                                            cancelVerificationActHandler={({comment}) => cancelAsync(currentCertificate, lang, verificationAct, comment)}
                                        />
                                    }

                                    {showDeclineButton &&
                                        <DeclineContract
                                            className="ms-2 mb-2 mb-sm-0"
                                            declineVerificationActHandler={({comment}) => customerDeclineAsync(currentCertificate, lang, verificationAct, comment)}
                                        />
                                    }
                                </>
                            :
                                <>
                                        <IconButton
                                            to={`/roaming/verification-act/draft/edit/${verificationAct.id}`}
                                            as={dispatch(checkPermission(PermissionRoamingVerificationActUpdate)) ? Link : 'div'}
                                            onClick={()=>!dispatch(checkPermission(PermissionRoamingVerificationActUpdate)) && dispatch(toastPermission())}
                                            variant="falcon-warning"
                                            size="sm"
                                            icon={faSignature}
                                            iconClassName="me-1"
                                            className="ms-sm-2 ms-0 mb-2 mb-sm-0"
                                        >{t(lang, 'roaming.common.edit')}</IconButton>
                                    <SignActionWithSelectCertificatWrapper permission={PermissionRoamingVerificationActAccept}>
                                        <IconButton
                                            variant="falcon-primary"
                                            size="sm"
                                            icon={faSignature}
                                            iconClassName="me-1"
                                            className="ms-sm-2 ms-0 mb-2 mb-sm-0"
                                            onClick={() => executorSignAndSend(verificationAct)}
                                            disabled={!currentCertificate}
                                        >{t(lang, 'edi.common.button.sign')}</IconButton>
                                    </SignActionWithSelectCertificatWrapper>
                                </>
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <p className="text-center">{verificationAct?.info?.text}</p>
                        </Col>
                    </Row>
                    <div className="mt-3 fs--1">
                        <Table bordered responsive>
                            <tbody>
                                <tr className="table-primary">
                                    <td colSpan="4" className="fw-bold text-center">{t(lang, "roaming.verification_act.view.table.according_to", {company: verificationAct?.executor?.name})}</td>
                                    <td colSpan="4" className="fw-bold text-center">{t(lang, "roaming.verification_act.view.table.according_to", {company: verificationAct?.customer?.name})}</td>
                                </tr>
                                <tr className="table-primary">
                                    <td className="fw-bold">{t(lang, "roaming.verification_act.view.table.document_date")}</td>
                                    <td className="fw-bold">{t(lang, "roaming.verification_act.view.table.document_number")}</td>
                                    <td className="fw-bold">{t(lang, "roaming.verification_act.view.table.debit")}</td>
                                    <td className="fw-bold">{t(lang, "roaming.verification_act.view.table.credit")}</td>
                                    <td className="fw-bold">{t(lang, "roaming.verification_act.view.table.document_date")}</td>
                                    <td className="fw-bold">{t(lang, "roaming.verification_act.view.table.document_number")}</td>
                                    <td className="fw-bold">{t(lang, "roaming.verification_act.view.table.debit")}</td>
                                    <td className="fw-bold">{t(lang, "roaming.verification_act.view.table.credit")}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="fw-bold table-primary text-end">{t(lang, "roaming.verification_act.view.table.open_balance")}</td>
                                    <td>{numeral.formats[numberFormat].format(verificationAct?.open_balance?.executor_debit || 0)}</td>
                                    <td>{numeral.formats[numberFormat].format(verificationAct?.open_balance?.executor_credit || 0)}</td>
                                    <td colSpan="2" className="fw-bold table-primary text-end">{t(lang, "roaming.verification_act.view.table.open_balance")}</td>
                                    <td>{numeral.formats[numberFormat].format(verificationAct?.open_balance?.customer_debit || 0)}</td>
                                    <td>{numeral.formats[numberFormat].format(verificationAct?.open_balance?.customer_credit || 0)}</td>
                                </tr>
                                {verificationAct?.contracts?.map((contract, index) => {
                                    return [
                                        <tr key={`hr-${index}`}>
                                            <td colSpan="8"><hr className="mb-1 mt-0"/></td>
                                        </tr>,
                                        <tr key={`company-${index}`}>
                                            <td className="fw-bold">{dayjs(contract?.contract_date, 'YYYY-MM-DD').format(dateFormat)}</td>
                                            <td className="fw-bold">{contract?.contract_no}</td>
                                            <td colSpan="6"></td>
                                        </tr>,
                                        <tr key={`open-balance-${index}`}>
                                            <td colSpan="2" className="fw-bold table-primary text-end">{t(lang, "roaming.verification_act.view.table.open_balance_by_contract")}</td>
                                            <td>{numeral.formats[numberFormat].format(contract?.open_balance?.executor_debit || 0)}</td>
                                            <td>{numeral.formats[numberFormat].format(contract?.open_balance?.executor_credit || 0)}</td>
                                            <td colSpan="2" className="fw-bold table-primary text-end">{t(lang, "roaming.verification_act.view.table.open_balance_by_contract")}</td>
                                            <td>{numeral.formats[numberFormat].format(contract?.open_balance?.customer_debit || 0)}</td>
                                            <td>{numeral.formats[numberFormat].format(contract?.open_balance?.customer_credit || 0)}</td>
                                        </tr>,
                                        contract?.items?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{dayjs(item?.executor_operation_date, 'YYYY-MM-DD').format(dateFormat)}</td>
                                                <td>{item?.executor_operation_name}</td>
                                                <td>{numeral.formats[numberFormat].format(item?.executor_debit || 0)}</td>
                                                <td>{numeral.formats[numberFormat].format(item?.executor_credit || 0)}</td>
                                                <td>{dayjs(item?.customer_operation_date, 'YYYY-MM-DD').format(dateFormat)}</td>
                                                <td>{item?.customer_operation_name}</td>
                                                <td>{numeral.formats[numberFormat].format(item?.customer_debit || 0)}</td>
                                                <td>{numeral.formats[numberFormat].format(item?.customer_credit || 0)}</td>
                                            </tr>
                                        )),
                                        <tr key={`total-balance-${index}`}>
                                            <td colSpan="2" className="table-primary fw-bold text-end">{t(lang, "roaming.verification_act.view.table.close_balance_by_contract")}</td>
                                            <td className="bold">{numeral.formats[numberFormat].format(contract?.total_balance?.executor_debit || 0)}</td>
                                            <td className="bold">{numeral.formats[numberFormat].format(contract?.total_balance?.executor_credit || 0)}</td>
                                            <td colSpan="2" className="table-primary fw-bold text-end">{t(lang, "roaming.verification_act.view.table.close_balance_by_contract")}</td>
                                            <td className="bold">{numeral.formats[numberFormat].format(contract?.total_balance?.customer_debit || 0)}</td>
                                            <td className="bold">{numeral.formats[numberFormat].format(contract?.total_balance?.customer_credit || 0)}</td>
                                        </tr>,
                                        <tr key={`close-balance-${index}`}>
                                            <td colSpan="2" className="table-primary fw-bold text-end">{t(lang, "roaming.verification_act.view.table.total_balance_by_contract")}</td>
                                            <td className="bold">{numeral.formats[numberFormat].format(contract?.close_balance?.executor_debit || 0)}</td>
                                            <td className="bold">{numeral.formats[numberFormat].format(contract?.close_balance?.executor_credit || 0)}</td>
                                            <td colSpan="2" className="table-primary fw-bold text-end">{t(lang, "roaming.verification_act.view.table.total_balance_by_contract")}</td>
                                            <td className="bold">{numeral.formats[numberFormat].format(contract?.close_balance?.customer_debit || 0)}</td>
                                            <td className="bold">{numeral.formats[numberFormat].format(contract?.close_balance?.customer_credit || 0)}</td>
                                        </tr>
                                    ]
                                })}
                                <tr>
                                    <td colSpan="8"><hr className="mb-1 mt-0"/></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="table-primary fw-bold text-end">{t(lang, "roaming.verification_act.view.table.turnover_balance")}</td>
                                    <td className="bold">{numeral.formats[numberFormat].format(verificationAct?.turnover_balance?.executor_debit || 0)}</td>
                                    <td className="bold">{numeral.formats[numberFormat].format(verificationAct?.turnover_balance?.executor_credit || 0)}</td>
                                    <td colSpan="2" className="table-primary fw-bold text-end">{t(lang, "roaming.verification_act.view.table.turnover_balance")}</td>
                                    <td className="bold">{numeral.formats[numberFormat].format(verificationAct?.turnover_balance?.customer_debit || 0)}</td>
                                    <td className="bold">{numeral.formats[numberFormat].format(verificationAct?.turnover_balance?.customer_credit || 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="table-primary fw-bold text-end">{t(lang, "roaming.verification_act.view.table.close_balance")}</td>
                                    <td className="bold">{numeral.formats[numberFormat].format(verificationAct?.close_balance?.executor_debit || 0)}</td>
                                    <td className="bold">{numeral.formats[numberFormat].format(verificationAct?.close_balance?.executor_credit || 0)}</td>
                                    <td colSpan="2" className="table-primary fw-bold text-end">{t(lang, "roaming.verification_act.view.table.close_balance")}</td>
                                    <td className="bold">{numeral.formats[numberFormat].format(verificationAct?.close_balance?.customer_debit || 0)}</td>
                                    <td className="bold">{numeral.formats[numberFormat].format(verificationAct?.close_balance?.customer_credit || 0)}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table bordered responsive>
                            <tbody>
                                <tr>
                                    <td colSpan="4">{t(lang, "roaming.verification_act.view.according_to", {company: verificationAct?.executor?.name})}</td>
                                    <td colSpan="4">{t(lang, "roaming.verification_act.view.according_to", {company: verificationAct?.customer?.name})}</td>
                                </tr>
                                <tr>
                                    {
                                        (!+verificationAct?.close_balance?.executor_debit && !+verificationAct?.close_balance?.customer_debit) &&
                                        <>
                                            <td colSpan="4" className="fw-bold">{t(lang, "roaming.verification_act.view.conclusion.not_company", {
                                                verification_act_date: verificationAct?.info?.date
                                            })}</td>
                                            <td colSpan="4" className="fw-bold">{t(lang, "roaming.verification_act.view.conclusion.not_company", {
                                                verification_act_date: verificationAct?.info?.date
                                            })}</td>
                                        </>
                                    }
                                    {
                                        (!!+verificationAct?.close_balance?.executor_debit) &&
                                        <>
                                            <td colSpan="4" className="fw-bold">{t(lang, "roaming.verification_act.view.conclusion", {
                                                verification_act_date: verificationAct?.info?.date,
                                                company: verificationAct?.executor?.name,
                                                close_balance: numeral.formats[numberFormat].format(verificationAct?.close_balance?.executor_debit || 0),
                                                close_balance_in_words: ConvertQuantityToWord.getText(+verificationAct?.close_balance?.executor_debit || 0)
                                            })}</td>
                                            <td colSpan="4" className="fw-bold">{t(lang, "roaming.verification_act.view.conclusion", {
                                                verification_act_date: verificationAct?.info?.date,
                                                company: verificationAct?.executor?.name,
                                                close_balance: numeral.formats[numberFormat].format(verificationAct?.close_balance?.executor_debit || 0),
                                                close_balance_in_words: ConvertQuantityToWord.getText(+verificationAct?.close_balance?.executor_debit || 0)
                                            })}</td>
                                        </>
                                    }
                                    {
                                        (!!+verificationAct?.close_balance?.customer_debit) &&
                                        <>
                                            <td colSpan="4" className="fw-bold">{t(lang, "roaming.verification_act.view.conclusion", {
                                                verification_act_date: verificationAct?.info?.date,
                                                company: verificationAct?.customer?.name,
                                                close_balance: numeral.formats[numberFormat].format(verificationAct?.close_balance?.customer_debit || 0),
                                                close_balance_in_words: ConvertQuantityToWord.getText(+verificationAct?.close_balance?.customer_debit || 0)
                                            })}</td>
                                            <td colSpan="4" className="fw-bold">{t(lang, "roaming.verification_act.view.conclusion", {
                                                verification_act_date: verificationAct?.info?.date,
                                                company: verificationAct?.customer?.name,
                                                close_balance: numeral.formats[numberFormat].format(verificationAct?.close_balance?.customer_debit || 0),
                                                close_balance_in_words: ConvertQuantityToWord.getText(+verificationAct?.close_balance?.customer_debit || 0)
                                            })}</td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    <td colSpan="4">{t(lang, "roaming.verification_act.view.from", {company: verificationAct?.executor?.name})}</td>
                                    <td colSpan="4">{t(lang, "roaming.verification_act.view.from", {company: verificationAct?.customer?.name})}</td>
                                </tr>
                                <tr>
                                    <td colSpan="4" className="fw-bold">{t(lang, "roaming.verification_act.view.responsible_person", {responsible_person: verificationAct?.executor?.director_name})}</td>
                                    <td colSpan="4" className="fw-bold">{t(lang, "roaming.verification_act.view.responsible_person", {responsible_person: verificationAct?.customer?.director_name})}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default VerificationAct
