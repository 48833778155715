import TransferDatatable from "../../../../components/hippo/warehouse-operation/transfer/datatable/TransferDatatable";


const Transfers = () => {



    return(
        <>
            <TransferDatatable />
        </>
    )
}

export default Transfers;