import React from 'react';
import {Form} from "react-bootstrap";
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const ColumnSelector = ({index, columnsOptions, unimportantColumn, arrayFieldName}) => {
    const columns = useWatch({name: arrayFieldName});
    const rootName = `${arrayFieldName}.${index}`;
    const {setValue, control} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    const onSelectColumn = (selectedOption, onFieldChange) => {
        const alreadySelectedColumnIndex = columns.findIndex(columnKey => columnKey === selectedOption);

        if (alreadySelectedColumnIndex !== -1) {
            const alreadySelectedColumnFieldName = `${arrayFieldName}.${alreadySelectedColumnIndex}`;

            setValue(alreadySelectedColumnFieldName, unimportantColumn);
            onFieldChange(selectedOption);
        } else {
            onFieldChange(selectedOption);
        }
    }

    return (
        <>
            <th className="px-1" key={index}>
                <Form.Group style={{minWidth: "200px"}}>
                    <Controller
                        name={rootName}
                        control={control}
                        render={({field}) => (
                            <ReactSelect
                                className="fs--1"
                                hideSelectedOptions
                                options={columnsOptions}
                                defaultValue={field.value}
                                getOptionValue={option => option.key}
                                getOptionLabel={option => t(lang, option.label)}
                                onChange={option => onSelectColumn(option?.key, field.onChange)}
                                value={columnsOptions.find(column => column.key === field.value)}
                            />
                        )}
                    />
                </Form.Group>
            </th>
        </>
    );
};

export default ColumnSelector;