import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorOrderSlice = createSlice({
    name: 'contractorOrder',
    initialState: {
        orders: [],
        countOrders: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateOrders: (state, action) => {
            state.orders = action.payload
        },
        updateCountOrders: (state, action) => {
            state.countOrders = action.payload
        },
        updateFilterOrder: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorOrdersAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorOrders(params)
        .then(response => {
            dispatch(contractorOrderSlice.actions.updateOrders(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const loadContractorOrdersCountAsync = (params) => (dispatch) => {
    let {limit, page, ...others} = params
    contractorService.getContractorOrdersCount(others)
        .then(response => {
            dispatch(contractorOrderSlice.actions.updateCountOrders(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const changeFilterContractorOrdersAsync = (params) => (dispatch) => {
    return dispatch(contractorOrderSlice.actions.updateFilterOrder(params))
}
export const selectContractorOrders = (state) => state.contractorOrder.orders;
export const selectContractorCountOrders = (state) => state.contractorOrder.countOrders;
export const selectContractorOrderFilterOptions = (state) => state.contractorOrder.filterOptions;
export default contractorOrderSlice.reducer