import React from 'react';
import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import OwnerOrganizationItemsDataTableFilter from "./OwnerOrganizationItemsDataTableFilter";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import Divider from "../../../common/Divider";
import SoftBadge from "../../../common/SoftBadge";
import OwnerOrganizationsItemsTotalInfo from "./OwnerOrganizationsItemsTotalInfo";


const OwnerOrganizationsDataTableHeader = ({
                                               table,
                                               load,
                                               loading,
                                               categories,
                                               itemPriceAccessTypeEnabled,
                                               filter,
                                               onFilterChange,
                                               onDebouncedFilterChange,
                                           }) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Row className="g-2">
            <Col xs={12}>
                <OwnerOrganizationItemsDataTableFilter load={load}
                                                       loading={loading}
                                                       categories={categories}
                                                       filter={filter}
                                                       onFilterChange={onFilterChange}
                                                       onDebouncedFilterChange={onDebouncedFilterChange}
                />
            </Col>
            <Divider/>
            <Col xs={12}>
                <div className="d-flex flex-wrap align-items-center gap-2">
                    <h5>{t(lang, 'items.table.title')}</h5>
                    <div className="d-flex gap-2 flex-wrap">
                        <SoftBadge bg='info'>{t(lang, 'items.common.category')}</SoftBadge>
                        <SoftBadge bg='secondary'>{t(lang, 'items.common.barcode')}</SoftBadge>
                        <SoftBadge bg='dark'>{t(lang, 'items.common.sku')}</SoftBadge>
                    </div>
                </div>
            </Col>
            {itemPriceAccessTypeEnabled &&
                <Col xs={12} className="text-end">
                    <OwnerOrganizationsItemsTotalInfo table={table}/>
                </Col>
            }
        </Row>
    );
};

export default OwnerOrganizationsDataTableHeader;

OwnerOrganizationsDataTableHeader.propTypes = {
    load: PropTypes.func,
    loading: PropTypes.bool,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
    categories: PropTypes.array
};

OwnerOrganizationsDataTableHeader.defaultProps = {
    load: () => {
    },
    loading: false,
    filters: {},
    onFilterChange: () => {
    },
    categories: []
};
