import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import AttorneyTableHeader from './AttorneyDataTableHeader';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {
    changePaginationOptionsAsync,
    loadAttorneysAsync,
    loadCountAttorneysAsync,
    selectAttorneys,
    selectCountAttorneys,
    selectFilterOptions, selectLoading, selectPaginationOptions,
    signAttorneyAsync
} from '../../../app/store/reducers/attorney/attorneyReducer';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {selectActiveOrganization} from '../../../app/store/reducers/auth/authReducer';
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";
import AdvanceTableCustomPagination from '../../common/advance-table/AdvanceTableCustomPagination';
import CountbleProgressBar from '../../common/CountbleProgressBar';
import ActionButton from '../../common/ActionButton';
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {GetStatusText, GetStatusVariant, AttorneyRegistered} from "../../../enum/AttorneyStatus";
import {debounce} from "underscore";


const Attorneys = ({activeColumns}) => {
    const dispatch = useDispatch();
    const attorneys = useSelector(selectAttorneys);
    const count = useSelector(selectCountAttorneys);
    const pagination = useSelector(selectPaginationOptions);
    const filters = useSelector(selectFilterOptions);
    const loading = useSelector(selectLoading);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang);
    const t = Translate;

    const load = useCallback(debounce((activeOrganization, filters, pagination) => {
        dispatch(loadAttorneysAsync({...filters, viewer_inn: activeOrganization.inn}, pagination))
        dispatch(loadCountAttorneysAsync({...filters, viewer_inn: activeOrganization.inn}))
    },  50), [dispatch])

    const [ signResults, setSignResults ] = useState([])
    const [ lengthSignAttorney, setLengthSignAttorney ] = useState(0)
    const [ showProgressBar, setShowProgressBar ] = useState(false)
    const [ countSelectedAndSignedAttorney, setCountSelectedAndSignedAttorney ] = useState(false)


    function getActiveColumns(activeColumns, columns = []) {
        return columns?.filter(column => activeColumns[column?.id])
    }

    const columns = getActiveColumns(activeColumns, [
        {
            Header: "#",
            id: "index",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
              return `${((pagination.page-1) * pagination.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'index',
            id: "number",
            Header: t(lang, "edi.attorney.datatable.header.row.number"),
            cellProps: {
                width: '100px',
                className: 'p-0'
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-grid gap-2">
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    {t(lang, GetStatusText(original.status))}
                                </Tooltip>
                            }
                            placement="right"
                        >
                            <Button style={{backgroundColor: GetStatusVariant(original.status)}} as={Link} to={`./attorney/${original.id}`}>
                                {original?.attorney_info.number}
                            </Button>
                        </OverlayTrigger>
                    </div>
                )
            }
        },
        {
            accessor: 'person_info.full_name',
            id: "full_name",
            Header: t(lang, "edi.attorney.datatable.header.row.full_name"),
            cellProps: {
                width: '250px',
                className: 'fw-bold'
            },
        },
        {
            accessor: 'contractor',
            id: "contractor",
            Header: t(lang, "edi.attorney.datatable.header.row.contractor"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isOwner = activeOrganization.inn === original.owner_info.inn
                if (isOwner) {
                    return<>
                        {original.contractor_info.name}
                        </>
                }
                return <>
                    {original.owner_info.name}
                    </>
            }
        },
        {
            accessor: 'person_info.position',
            id: "position",
            Header: t(lang, "edi.attorney.datatable.header.row.position"),
        },
        {
            accessor: 'person_info.person_inn',
            id: "employees_inn",
            Header: t(lang, "edi.attorney.datatable.header.row.employees_inn"),
        },
        {
            accessor: 'none',
            id: "button_action",
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '60px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                const allowEdit = original.status === AttorneyRegistered;

                if (allowEdit) {
                    return (
                        <Link to={`./attorney/update/${original.id}`}>
                            <ActionButton icon="edit" title="Изменить" variant="action" className="p-0 me-2"/>
                        </Link>
                    )
                }

                return false
            }
        }
    ]);

    const onPageChange = async (page) => dispatch(changePaginationOptionsAsync({...pagination, page: page}));
    const onLimitChange = async (limit) => dispatch(changePaginationOptionsAsync({...pagination, limit: limit, page: 1}));

    const onBulkSignHandler = async (attorneys) => {
        let notSignedAttorneys = attorneys.filter(attorney => attorney.status === AttorneyRegistered);
        setCountSelectedAndSignedAttorney(attorneys.length - notSignedAttorneys.length)
        setLengthSignAttorney(notSignedAttorneys.length)
        setShowProgressBar(true)
        for await (let attorney of notSignedAttorneys) {
            let result = await signAttorneyAsync({id: attorney.id, certificate: currentCertificate});
            setSignResults(old => [...old, result])
        }
        dispatch(loadAttorneysAsync({...filters, viewer_inn: activeOrganization.inn}))
        dispatch(loadCountAttorneysAsync({...filters, viewer_inn: activeOrganization.inn}))
        setTimeout(() => {
            setSignResults([])
            setLengthSignAttorney(0)
            setShowProgressBar(false)
        }, 1000)
    }

    useEffect(() => {
        dispatch(changePaginationOptionsAsync({...pagination, page: 1}));
    }, [filters])

    useEffect(() => {
        load(activeOrganization, filters, pagination);
    }, [activeOrganization, filters, pagination])

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={attorneys}
            selection
            width={10}
            pagination
            perPage={1000}
        >
            <CountbleProgressBar show={showProgressBar} results={signResults} signed={countSelectedAndSignedAttorney} length={lengthSignAttorney} />
            <Card className="mb-3">
                <Card.Header>
                    <AttorneyTableHeader table onClickBulkSign={onBulkSignHandler}/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        isLoading={loading}
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={pagination.limit}
                        page={pagination.page}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                        rowsPerPageOptions={[10, 25, 50, 75, 100, 300]}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Attorneys;
