import {faWallet} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/IconButton";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import Button from "react-bootstrap/Button";


const WarehouseOperationWithoutPayment = ({onClick, id, contractorId, operation, ...props}) => {
    const [show, setShow] = useState(false)

    const lang = useSelector(selectLang)
    const t = Translate;

    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }


    const onConfirmed = () => {
        onClick({id})
        setShow(false)
    }
    return(
        <>
            <IconButton
                icon={faWallet}
                // variant="falcon-primary"
                variant="primary"
                className="mx-2"
                title={t(lang, "warehouse.operation.item.common.without_payment")}
                size="sm"
                onClick={() => handlerShow()}
                {...props}
            >{t(lang, "warehouse.operation.item.common.without_payment")}</IconButton>

            <Modal show={show} onHide={handlerClose}>
                <Modal.Header>
                    <FalconCloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title className="text-center">{t(lang, "warehouse.operation.item.common.confirmed_item_modal.title")}</Modal.Title>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerClose}>
                        {t(lang, "warehouse.operation.item.common.without_payment_no")}
                    </Button>
                    <Button variant="primary" onClick={() => onConfirmed()}>
                        {t(lang, "warehouse.operation.item.common.without_payment_yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default WarehouseOperationWithoutPayment
