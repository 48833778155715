export const dataTableTranslateColumns = column => {
    switch (column) {
        case "index":
            return "common.datatable.setting.index"
        case "number":
            return "common.datatable.setting.number"
        case "date":
            return "common.datatable.setting.date"
        case "unloading_point":
            return "common.datatable.setting.unloading_point"
        case "expire_date":
            return "common.datatable.setting.expire_date"
        case "created_date":
            return "common.datatable.setting.created_date"
        case "status":
            return "common.datatable.setting.status"
        case "contractor":
            return "common.datatable.setting.contractor"
        case "contract_num":
            return "common.datatable.setting.contract_num"
        case "invoice":
            return "common.datatable.setting.invoice"
        case "branch":
            return "common.datatable.setting.branch"
        case "total_sum":
            return "common.datatable.setting.total_sum"
        case "employees_inn":
            return "common.datatable.setting.employess_inn"
        case "full_name":
            return "common.datatable.setting.full_name"
        case "position":
            return "common.datatable.setting.position"
        case "button_action":
            return "common.datatable.setting.button_action"
        case "branch_code":
            return "common.datatable.setting.branch_code"
        case "branch_name":
            return "common.datatable.setting.branch_name"
        case "customer_employee_code":
            return "edi.order.datatable.header.row.customer_employee_code"
        case "code":
            return "common.datatable.setting.code"
        case "name":
            return "common.datatable.setting.name"
        case "uid":
            return "common.datatable.setting.uid"
        default:
            return "common.datatable.setting.default"
    }
}