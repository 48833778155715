export const OperationTypePayment = 1 //oldim
export const OperationTypePayout = 2 //berdim
export const OperationTypeSale = 3 //berdim
export const OperationTypePurchase = 4 //oldim
export const OperationTypeRefund = 5 //oldim
export const OperationTypeReturn = 6 //berdim

export const GetContractorOperationTypeLabel = type => {
    switch (type) {
        case OperationTypePayment:
            return "crm.contractor.operation.type.payment.label";
        case OperationTypePayout:
            return "crm.contractor.operation.type.payout.label";
        case OperationTypeSale:
            return "crm.contractor.operation.type.sale.label";
        case OperationTypePurchase:
            return "crm.contractor.operation.type.purchase.label";
        case OperationTypeRefund:
            return "crm.contractor.operation.type.refund.label";
        case OperationTypeReturn:
            return "crm.contractor.operation.type.return.label";
        default:
            return "";
    }
};

export const GetContractorOperationTypeBgVariant = type => {
    switch (type) {
        case OperationTypePayment:
            return "primary";
        case OperationTypePayout:
            return "info";
        case OperationTypeSale:
            return "success";
        case OperationTypePurchase:
            return "warning";
        case OperationTypeRefund:
            return "danger";
        case OperationTypeReturn:
            return "danger";
        default:
            return "secondary";
    }
}

export const ContractorOperationTypes = [
    {
        value: OperationTypePayment,
        label: "crm.contractor.operation.type.payment.label",
        variant: 'primary'
    },
    {
        value: OperationTypePayout,
        label: "crm.contractor.operation.type.payout.label",
        variant: 'info'
    },
    {
        value: OperationTypeSale,
        label: "crm.contractor.operation.type.sale.label",
        variant: 'success'
    },
    {
        value: OperationTypePurchase,
        label: "crm.contractor.operation.type.purchase.label",
        variant: 'warning'
    },
    {
        value: OperationTypeRefund,
        label: "crm.contractor.operation.type.refund.label",
        variant: 'danger'
    },
    {
        value: OperationTypeReturn,
        label: "crm.contractor.operation.type.return.label",
        variant: 'danger'
    }
]

export const FindContractorOperationType = (type) => {
    return ContractorOperationTypes.find(lt => lt.value === type);
}
