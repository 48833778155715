import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import {Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import RevisionDefaultSettingsForm from "./RevisionDefaultSettingsForm";
import {
    selectRevisionDefaultsSettings,
    setRevisionDefaultsSettings
} from "../../../../../app/store/reducers/warehouse-operation/revisionReducer";


const RevisionSettings = () => {
    const revisionDefaultsSettings = useSelector(selectRevisionDefaultsSettings)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const form = useForm({
        defaultValues: {
            defaultEmployeeId: null,
            priceTypeOfInputOption: null,
            showItemSearchOptions: 20,
            isCheckedViewDateTime: false,
            isCheckedViewNumber: false,
            isCheckedViewCategories: false,
            isCheckedViewWarehouses: false,
            defaultWarehouseId: null,
            operationItemExtraInfo: null,
            showOperationItemPurchasePrice: false,
        }
    })


    const handleShow = () => {
        setShow(true)
        form.setValue("defaultEmployeeId", revisionDefaultsSettings?.defaultEmployeeId)
        form.setValue("isCheckedViewDateTime", revisionDefaultsSettings?.isCheckedViewDateTime)
        form.setValue("isCheckedViewNumber", revisionDefaultsSettings?.isCheckedViewNumber)
        form.setValue("isCheckedViewCategories", revisionDefaultsSettings?.isCheckedViewCategories)
        form.setValue("isCheckedViewWarehouses", revisionDefaultsSettings?.isCheckedViewWarehouses)
        form.setValue("defaultWarehouseId", revisionDefaultsSettings?.defaultWarehouseId)
        form.setValue("priceTypeOfInputOption", revisionDefaultsSettings?.priceTypeOfInputOption)
        form.setValue("showItemSearchOptions", revisionDefaultsSettings?.showItemSearchOptions)
        form.setValue("operationItemExtraInfo", revisionDefaultsSettings?.operationItemExtraInfo)
        form.setValue("showOperationItemPurchasePrice", revisionDefaultsSettings?.showOperationItemPurchasePrice)
    }


    const handleClose = () => {
        setShow(false)
    }


    const onSubmit = (formData) => {
        dispatch(setRevisionDefaultsSettings(JSON.stringify(formData)))
        handleClose()
    }



    return(
        <>
            <IconButton
                icon="gear"
                onClick={handleShow}
                variant="falcon-primary"
                size="sm"
                className="mx-2"
            />
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        {/*Сотув созламалари*/}
                        Настройки
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...form}>
                        <Form onSubmit={event => {
                            event.stopPropagation();
                            return form.handleSubmit(onSubmit)(event);
                        }}>
                            <RevisionDefaultSettingsForm/>
                        </Form>
                    </FormProvider>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RevisionSettings;
