import React, {useContext} from 'react';
import NavbarDropdown from './NavbarDropdown';
import routes, {
    dashboardRoutes,
} from "../../../../routes/routes";
import {Col, Nav, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import AppContext from "../../../../context/Context";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import NavbarNavLink from "./NavbarNavLink";
import {selectActiveOrganization, selectIsAdmin} from "../../../../app/store/reducers/auth/authReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const HideNavbarTopDropdown = ({routeTitle}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{t(lang, 'common.notification.without.inn')}</Tooltip>}>
            <span className={'mx-2 d-flex align-items-center gap-2 cursor-default'}>
                <span>{routeTitle}</span>
                <FontAwesomeIcon size={'sm'} icon={'lock'}/>
            </span>
        </OverlayTrigger>
    )

}

const NavbarTopDropdown = ({item, routeTitle}) => {
    return <Nav>
        <Row style={{minWidth: '250px'}}>
            {Array.isArray(item) && item && item.map(route => <Col md={12} key={route.name}>
                <NavbarNavLink routeTitle={routeTitle} route={route}/>
            </Col>)}
            {!Array.isArray(item) && item && <Col md={12} key={item.name}>
                <NavbarNavLink route={item}/>
            </Col>}
        </Row>
    </Nav>
}


const NavbarTopDropDownMenus = () => {

    const {
        config: {navbarCollapsed, showBurgerMenu},
        setConfig
    } = useContext(AppContext);

    const lang = useSelector(selectLang);
    const t = Translate;
    const isAdmin = useSelector(selectIsAdmin);
    const activeOrganization = useSelector(selectActiveOrganization)

    const EDI = 'EDI'
    const EDO = 'ЭДО'

    const handleDropdownItemClick = () => {
        if (navbarCollapsed) {
            setConfig('navbarCollapsed', !navbarCollapsed);
        }
        if (showBurgerMenu) {
            setConfig('showBurgerMenu', !showBurgerMenu);
        }
    };

    const additionalSetting = [
        {name: 'main.top.nav.profile_dropdown.dropdown_item.settings', to: '/user/settings'},
        {name: 'main.top.nav.profile_dropdown.dropdown_item.billing', to: '/user/billing'},
        {name: 'main.top.nav.profile_dropdown.dropdown_item.currency', to: '/user/currency'},
        {name: 'main.top.nav.profile_dropdown.dropdown_item.notification', to: '/user/notification'},
        {name: 'main.top.nav.profile_dropdown.dropdown_item.employees', to: '/user/employee'},
    ]

    return (
        <>
            {isAdmin && dashboardRoutes.children.map((route, index) => {
                return route.onlyAdmin && <NavbarDropdown to={route.to} key={route.name}  title={t(lang, route.name)}>
                    <NavbarTopDropdown item={routes[index].children}/>
                </NavbarDropdown>
            })}

            {dashboardRoutes.children.map((route, index) => {
                return index > 0 && !route.onlyAdmin ?
                    route.title === EDI || route.title === EDO ?
                        !activeOrganization.inn ?
                            <HideNavbarTopDropdown key={index} routeTitle={route.title}/> :
                            <NavbarDropdown to={route.to} key={route.name} title={t(lang, route.name)}>
                                <NavbarTopDropdown routeTitle={route.title} item={routes[index].children}/>
                            </NavbarDropdown> :
                            <NavbarDropdown to={route.to} key={route.name} title={t(lang, route.name)}>
                                <NavbarTopDropdown routeTitle={route.title} item={routes[index].children}/>
                            </NavbarDropdown> : null
            })}

            <NavbarDropdown to={'/user'} title={t(lang, 'main.top.nav.profile_dropdown.dropdown_item.miscellaneous')}>
                {additionalSetting.map(route => <NavbarTopDropdown  key={route.name} item={route}/>)}
            </NavbarDropdown>

        </>
    );
};

export default NavbarTopDropDownMenus;
