import React from "react";
import {Form} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ForgotPasswordStepThree = ({footer}) => {
    const {register, formState: {errors}, watch} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Control
                    type='password'
                    name='password'
                    autoComplete='off'
                    isInvalid={errors.password}
                    className='form-control'
                    placeholder={t(lang, 'authentication.forgot-password.new_password.placeholder')}
                    {...register('password', {
                        required: t(lang, 'edi.common.forms.validations.is_required'),
                    })}
                />
                <Form.Control.Feedback type='invalid'>{errors.password && errors.password?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Control
                    type='password'
                    autoComplete='off'
                    name='confirmPassword'
                    isInvalid={errors.confirmPassword}
                    className='form-control'
                    placeholder={t(lang, 'authentication.forgot-password.confirm_password.placeholder')}
                    {...register('confirmPassword', {
                        validate: value => value === watch('password') || t(lang, 'authentication.forgot-password.confirm_password.not_match')
                    })}
                />
                <Form.Control.Feedback type='invalid'>{errors.confirmPassword && errors.confirmPassword?.message}</Form.Control.Feedback>
            </Form.Group>

            {footer}
        </>
    );
};

ForgotPasswordStepThree.propTypes = {
    footer: PropTypes.element
}

export default ForgotPasswordStepThree;