import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form} from 'react-bootstrap';
import {FormProvider, useForm} from "react-hook-form";
import RegistrationFromStepOne from "./register/RegistrationFromStepOne";
import RegistrationFromStepTwo from "./register/RegistrationFromStepTwo";
import {getRegistrationVerifyConfirmationTicketAsync, getRegistrationVerifyHashCodeAsync, registerAsync} from "../../../app/store/reducers/auth/authReducer";
import {activateCertificateAsync, createPkcs7WithTimestamp} from "../../../app/store/reducers/eimzo/eimzoReducer";
import IconAlert from "../../common/IconAlert";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

const RegistrationForm = ({hasLabel}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            company: {
                name: '',
                inn: '',
                certificate: null,
                referral_agent_code: null
            },
            account: {
                name: '',
                username: '',
                password: '',
                confirmPassword: '',
                confirmCode: ''
            },
            terms: null
        }
    });

    const [step, setStep] = useState(1);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState(null);
    const [confirmationTicket, setConfirmationTicket] = useState(null);
    const [signatureHashCode, setSignatureHashCode] = useState(null);
    const [invalidCertificatePassword, setInvalidCertificatePassword] = useState(false);
    const [certificateEnterPasswordCanceled, setCertificateEnterPasswordCanceled] = useState(false);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [activeCertificate, setActiveCertificate] = useState(null);
    const [showAgentCode, setShowAgentCode] = useState(false);

    const referralAgentCode = methods.watch('company.referral_agent_code')
    const inputRef = useRef(null)

    useEffect(() => {
        if (showAgentCode) {
            inputRef.current?.focus()
        }
    }, [showAgentCode])

    useEffect(() => {
        if (activeCertificate) {
            createPkcs7WithTimestamp(signatureHashCode, activeCertificate.keyId)
                .then(({pkcs7}) => {
                    const formData = methods.getValues();
                    let username = formData.account?.username?.replace(/ /g, '')
                    username = '998' + username;
                    registerAsync({
                        inn: formData.company.inn,
                        name: formData.company.name,
                        signature: pkcs7,
                        username: username,
                        fullName: formData.account.name,
                        password: formData.account.password,
                        confirmCode: formData.account.confirmCode,
                        confirmTicket: confirmationTicket,
                        referral_agent_code: referralAgentCode
                    })
                        .then(() => {
                            methods.reset();
                        }, ({invalidConfirmationCode, referralAgentNotFound}) => {
                            if (invalidConfirmationCode) {
                                setButtonDisabled(false);
                                setInvalidConfirmCode(formData.account.confirmCode);
                                methods.trigger();
                            }
                            if (referralAgentNotFound) {
                                toast.error(t(lang, 'authentication.register.agent.code.error'))
                            } else {
                                setSomethingWentWrong(true);
                            }
                        })
                }, () => {
                    setSomethingWentWrong(true);
                })
        }
    }, [activeCertificate]);

    useEffect(() => {
        const formData = methods.getValues();
        if (signatureHashCode) {
            setButtonDisabled(true);

            activateCertificateAsync(formData.company.certificate)
                .then(activatedCertificate => {
                    setActiveCertificate(activatedCertificate);
                }, ({invalidPassword, canceled}) => {
                    if (invalidPassword) {
                        setInvalidCertificatePassword(true)
                    } else if (canceled) {
                        setCertificateEnterPasswordCanceled(canceled);
                    } else {
                        setSomethingWentWrong(true);
                    }
                });
        }
    }, [signatureHashCode]);

    const onSubmit = async formData => {

        let username = formData.account?.username?.replace(/ /g, '')
        username = '998' + username;

        switch (step) {
            case 1:
                getRegistrationVerifyConfirmationTicketAsync(username)
                    .then(ticket => {
                        setConfirmationTicket(ticket);
                        setStep(step + 1);
                    }, () => {
                        setSomethingWentWrong(true);
                    })
                break;

            case 2:
                getRegistrationVerifyHashCodeAsync(formData.company.inn)
                    .then(hashCode => {
                        setSignatureHashCode(hashCode);
                    }, () => {
                        setSomethingWentWrong(true);
                    });
                break;
        }
    };

    const onRetryClicked = () => {
        setSomethingWentWrong(false);
        setCertificateEnterPasswordCanceled(false);
        setInvalidCertificatePassword(false);
        setButtonDisabled(false);
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                {somethingWentWrong && <IconAlert variant="danger" dismissible onClose={onRetryClicked}>
                    <p className="mb-0">{t(lang, 'authorization.register.something_went_wrong')}</p>
                </IconAlert>}

                {certificateEnterPasswordCanceled && <IconAlert variant="warning" dismissible onClose={onRetryClicked}>
                    <p className="mb-0">{t(lang, 'authorization.register.eimzo_enter_password_canceled')}</p>
                </IconAlert>}

                {invalidCertificatePassword && <IconAlert variant="warning" dismissible onClose={onRetryClicked}>
                    <p className="mb-0">{t(lang, 'authorization.register.entered_wrong_password')}</p>
                </IconAlert>}

                {step === 1 && <RegistrationFromStepOne hasLabel={hasLabel}/>}
                {step === 2 && <RegistrationFromStepTwo hasLabel={hasLabel} invalidConfirmCode={invalidConfirmCode}/>}

                <Form.Group className="mb-4">
                    <Button className="w-100" type="submit" disabled={buttonDisabled}> {step === 1 ? t(lang, 'authorization.register.button.next') : t(lang, 'authorization.register.button.register')} </Button>
                </Form.Group>
            </Form>
            <p className="fs-0 text-center text-600 my-0">
                {t(lang, 'authorization.register.already_has_account')} ? <Link
                to="./login">{t(lang, 'authorization.login.title')}</Link>
            </p>
            {step === 1 && <div className={'d-flex justify-content-center'}>
                {
                    !showAgentCode ? <p onClick={() => setShowAgentCode(true)}
                                        className={'d-inline-block cursor-pointer text-center text-primary mt-1 fs--2'}>
                            {referralAgentCode ?
                                <span className={'text-facebook'}>
                                    {t(lang, 'authentication.register.agent.code')}:
                                    <span className={'ms-2 text-primary'}>{referralAgentCode}</span>
                                </span> :
                                t(lang, 'authentication.register.add.agent.code')}
                        </p> :
                        <Form.Group as={Col} sm={12}>
                            <Form.Control
                                ref={inputRef}
                                className={'w-50 d-block m-auto mt-1'}
                                size={'sm'}
                                value={referralAgentCode}
                                onChange={(e) => methods.setValue('company.referral_agent_code', e.target.value)}
                                onBlur={() => setShowAgentCode(false)}
                                placeholder={!hasLabel ? t(lang, 'authentication.register.agent.code') : ''}
                                type="text"
                            />
                        </Form.Group>}
            </div>}
        </FormProvider>
    );
};

RegistrationForm.propTypes = {
    hasLabel: PropTypes.bool
};

RegistrationForm.defaultValue = {
    hasLabel: false
};

export default RegistrationForm;
