import dayjs from "dayjs";
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    selectTelegramOrderBotOrderColumnVisibility,
    selectTelegramOrderBotOrderFilters,
    selectTelegramOrderBotOrderPagination,
    selectTelegramOrderBotOrders,
    selectTelegramOrderBotOrdersCount,
    updateColumnVisibility,
    updateFilters,
    updateOrders,
    updateOrdersCount,
    updatePagination
} from "../../../../../../../app/store/reducers/crm/bot/telegramOrderBotOrderReducer";
import {debounce} from "underscore";
import {selectActiveOrganization} from "../../../../../../../app/store/reducers/auth/authReducer";
import TelegramOrderBotService from "../../../../../../../services/telegram-order-bot/telegramOrderBotService";
import EventBus from "../../../../../../../app/eventbus/EventBus";
import {
    BULK_DELETE_TELEGRAM_ORDER_BOT_ORDERS_FINISHED,
    DELETE_TELEGRAM_ORDER_BOT_ORDER_SUCCEED
} from "../../../../../../../app/eventbus/telegram-bot/telegramOrderBotEvents";
import TelegramOrderBotOrderDataTable from "./TelegramOrderBotOrderDataTable";

const TelegramOrderBotOrdersDatatable = () => {
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectTelegramOrderBotOrderFilters);
    const pagination = useSelector(selectTelegramOrderBotOrderPagination);
    const columnVisibility = useSelector(selectTelegramOrderBotOrderColumnVisibility);
    const orders = useSelector(selectTelegramOrderBotOrders);
    const count = useSelector(selectTelegramOrderBotOrdersCount);
    const [loading, setLoading] = useState(false);

    const onFilterChange = (filter) => {
        dispatch(updateFilters(filter));
        dispatch(updatePagination({...pagination, pageIndex: 0}));
        load(filter, {...pagination, pageIndex: 0});
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        dispatch(updateFilters(filter));
        dispatch(updatePagination({...pagination, pageIndex: 0}));
        load(filter, {...pagination, pageIndex: 0});
    }, 500), [pagination.pageSize]);
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        dispatch(updatePagination(nextState));
        load(filters, nextState);
    };
    const onColumnVisibilityChange = (updater) => {
        const nextState = updater(columnVisibility);
        dispatch(updateColumnVisibility(nextState));
    };
    const load = async (filters, pagination) => {
        try {
            let filterParams = {};
            let paginationParams = {};

            // build filters
            {
                if (filters.dateStart)
                    filterParams["date_start"] = dayjs(filters.dateStart).format("YYYY-MM-DD");

                if (filters.dateEnd)
                    filterParams["date_end"] = dayjs(filters.dateEnd).format("YYYY-MM-DD");

                if (filters.contractorId)
                    filterParams["contractor_id"] = filters.contractorId;

                if (filters.isApproved !== null)
                    filterParams["is_approved"] = filters.isApproved;

                if (filters.isSaleCreated !== null)
                    filterParams["is_sale_created"] = filters.isSaleCreated;

                if (filters.botId !== null)
                    filterParams["bot_id"] = filters.botId;
            }

            // build pagination
            {
                paginationParams["skip"] = pagination.pageIndex * pagination.pageSize;
                paginationParams["limit"] = pagination.pageSize
            }

            setLoading(true);
            const getTelegramOrderBotOrdersPromise = TelegramOrderBotService.getTelegramOrderBotOrders({...filterParams, ...paginationParams});
            const getTelegramOrderBotOrdersCountPromise = TelegramOrderBotService.getTelegramOrderBotOrdersCount({...filterParams});
            const {data: orders} = await getTelegramOrderBotOrdersPromise;
            const {data: count} = await getTelegramOrderBotOrdersCountPromise;
            dispatch(updateOrders(orders));
            dispatch(updateOrdersCount(count));
            setLoading(false);
        } catch (error) {
            dispatch(updateOrders([]));
            dispatch(updateOrdersCount(0));
            setLoading(false);
        }
    };

    useEffect(() => {
        load(filters, pagination);
    }, [activeOrganization]);

    useEffect(() => {
        const onDeleteTelegramOrderBotOrderSucceedHandler = EventBus.on(DELETE_TELEGRAM_ORDER_BOT_ORDER_SUCCEED, () => {
            load(filters, pagination);
        });
        const onBulkDeleteTelegramOrderBotOrdersFinishedHandler = EventBus.on(BULK_DELETE_TELEGRAM_ORDER_BOT_ORDERS_FINISHED, () => {
            load(filters, pagination);
        });

        return () => {
            EventBus.remove(DELETE_TELEGRAM_ORDER_BOT_ORDER_SUCCEED, onDeleteTelegramOrderBotOrderSucceedHandler);
            EventBus.remove(BULK_DELETE_TELEGRAM_ORDER_BOT_ORDERS_FINISHED, onBulkDeleteTelegramOrderBotOrdersFinishedHandler);
        }
    }, [activeOrganization, filters, pagination]);

    return (
        <TelegramOrderBotOrderDataTable filters={filters}
                                        pagination={pagination}
                                        columnVisibility={columnVisibility}
                                        loading={loading}
                                        orders={orders}
                                        count={count}
                                        onFilterChange={onFilterChange}
                                        onDebouncedFilterChange={onDebouncedFilterChange}
                                        onPaginationChange={onPaginationChange}
                                        onColumnVisibilityChange={onColumnVisibilityChange}
                                        onReloadData={() => load(filters, pagination)}
        />
    )
};

export default TelegramOrderBotOrdersDatatable;