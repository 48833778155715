import React, {useEffect} from 'react';
import ChargeDatatable from "./ChargeDatatable";
import {useDispatch, useSelector} from "react-redux";
import {
    loadChargesAsync, loadChargesCount,
    selectCharges,
    selectChargesCount,
    selectChargesFilter, changeFilterChargesAsync
} from "../../../../../app/store/reducers/billing/billingReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {CHARGES} from "./OtherTab";

const Charge = ({tab}) => {
    const dispatch = useDispatch()

    const charges = useSelector(selectCharges)
    const count = useSelector(selectChargesCount)
    const filters = useSelector(selectChargesFilter)
    const activeOrganization = useSelector(selectActiveOrganization)

    useEffect(() => {
        if(tab === CHARGES) {
            dispatch(loadChargesAsync({...filters}))
            dispatch(loadChargesCount({...filters}))
        }
    }, [filters, activeOrganization, tab])

    return <ChargeDatatable charges={charges} filters={filters} count={count}
                            changeFilterChargesAsync={changeFilterChargesAsync}/>
};

export default Charge;
