import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    changeSystemOrganizationsFilterOptions,
    selectSystemOrganizationsCount,
    selectSystemOrganizationsFilterOptions
} from "../../../../app/store/reducers/billing/billingReducer";
import classNames from "classnames";

const pageLimitOptions = [
    {text: "10", value: 10},
    {text: "25", value: 25},
    {text: "50", value: 50},
    {text: "75", value: 75},
    {text: "100", value: 100},
]


const OrganizationDataTableFooter = () => {
    const {limit, page} = useSelector(selectSystemOrganizationsFilterOptions);
    const count = useSelector(selectSystemOrganizationsCount);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const maxPage = Math.ceil(count / limit);
    const pages = [];
    const range = 2;

    for (let i = page - range; i <= maxPage && i <= page + range; i++) {
        if (i >= 1 && i <= maxPage) {
            pages.push(i)
        }
    }

    const onLimitChange = selectedOption => {
        dispatch(changeSystemOrganizationsFilterOptions({limit: selectedOption, page: 1}))
    }

    const onPageChange = selectedPage => {
        dispatch(changeSystemOrganizationsFilterOptions({limit: limit, page: selectedPage}))
    }


    return (
        <>
            <Row className="d-flex flex-sm-row justify-content-xxl-end">
                <Col sm={12} xxl={4} className="d-flex align-items-center justify-content-center justify-content-xxl-end col-12">
                    <Button
                        size="sm"
                        disabled={page === 1}
                        variant="falcon-default"
                        onClick={() => onPageChange(page - 1)}
                    >
                        <FontAwesomeIcon icon="chevron-left"/>
                    </Button>
                    <ul className="pagination mb-0 mx-1">
                        {pages.map(p => {
                            return (
                                <li key={p} className={classNames({'active': p === page})}>
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className={classNames('page', 'me-1')}
                                        onClick={() => p !== page && onPageChange(p)}
                                    >{p}</Button>
                                </li>
                            )
                        })}
                    </ul>
                    <Button
                        size="sm"
                        variant="falcon-default"
                        disabled={page === maxPage}
                        onClick={() => onPageChange(page + 1)}
                    >
                        <FontAwesomeIcon icon="chevron-right"/>
                    </Button>
                </Col>
                <Col sm={6} xxl={2} className="mt-3 mt-sm-0 d-flex ms-auto justify-content-end align-items-center justify-content-xxl-end flex-sm-row">
                    <p className="mx-2 mb-0">{t(lang, "admin.organizations.datatable.footer.limit.title")}</p>
                    <Form.Select
                        size="sm"
                        selected
                        className="w-auto"
                        defaultValue={limit}
                        onChange={event => onLimitChange(event.target.value)}
                    >
                        {pageLimitOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.text}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
        </>
    );
};

export default OrganizationDataTableFooter;