export const OrderDraftLabel = "crm.order.status.draft"
export const OrderCanceledLabel = "crm.order.status.canceled"
export const OrderDeclinedLabel = "crm.order.status.declined"
export const OrderNewLabel = "crm.order.status.new"
export const OrderAcceptedLabel = "crm.order.status.accepted"
export const OrderProgressLabel = "crm.order.status.progress"
export const OrderCompletedLabel = "crm.order.status.completed"

export const OrderDraft = -10
export const OrderCanceled = -2
export const OrderDeclined = -1
export const OrderNew = 0
export const OrderAccepted = 1
export const OrderProgress = 2
export const OrderCompleted = 3

export const OrderDraftVariant = 'secondary'
export const OrderCanceledVariant = 'danger'
export const OrderDeclinedVariant = 'danger'
export const OrderNewVariant = 'success'
export const OrderAcceptedVariant = 'primary'
export const OrderProgressVariant = 'warning'
export const OrderCompletedVariant = 'info'

export const getOrderStatusText = status => {
    switch (status) {
        case OrderDraft:
            return OrderDraftLabel
        case OrderCanceled:
            return OrderCanceledLabel
        case OrderDeclined:
            return OrderDeclinedLabel
        case OrderNew:
            return OrderNewLabel
        case OrderAccepted:
            return OrderAcceptedLabel
        case OrderProgress:
            return OrderProgressLabel
        case OrderCompleted:
            return OrderCompletedLabel
        default:
            return OrderNewLabel
    }
}

export const getOrderStatusVariant = status => {
    switch (status) {
        case OrderDraft:
            return OrderDraftVariant
        case OrderCanceled:
            return OrderCanceledVariant
        case OrderDeclined:
            return OrderDeclinedVariant
        case OrderNew:
            return OrderNewVariant
        case OrderAccepted:
            return OrderAcceptedVariant
        case OrderProgress:
            return OrderProgressVariant
        case OrderCompleted:
            return OrderCompletedVariant
        default:
            return OrderNewVariant
    }
}

export const OrderStatuses = [
    {
        label: OrderDraftLabel,
        value: OrderDraft,
        variant: OrderDraftVariant
    },
    {
        label: OrderCanceledLabel,
        value: OrderCanceled,
        variant: OrderCanceledVariant
    },
    {
        label: OrderDeclinedLabel,
        value: OrderDeclined,
        variant: OrderDeclinedVariant
    },
    {
        label: OrderNewLabel,
        value: OrderNew,
        variant: OrderNewVariant
    },
    {
        label: OrderAcceptedLabel,
        value: OrderAccepted,
        variant: OrderAcceptedVariant
    },
    {
        label: OrderProgressLabel,
        value: OrderProgress,
        variant: OrderProgressVariant
    },
    {
        label: OrderCompletedLabel,
        value: OrderCompleted,
        variant: OrderCompletedVariant
    },
]

export const CrmExecutorOrderStatuses = [
    {
        label: OrderDeclinedLabel,
        value: OrderDeclined,
        variant: OrderDeclinedVariant
    },
    {
        label: OrderNewLabel,
        value: OrderNew,
        variant: OrderNewVariant
    },
    {
        label: OrderAcceptedLabel,
        value: OrderAccepted,
        variant: OrderAcceptedVariant
    },
    {
        label: OrderProgressLabel,
        value: OrderProgress,
        variant: OrderProgressVariant
    },
    {
        label: OrderCompletedLabel,
        value: OrderCompleted,
        variant: OrderCompletedVariant
    },
]

export const CrmCustomerOrderStatuses = [
    {
        label: OrderCanceledLabel,
        value: OrderCanceled,
        variant: OrderCanceledVariant
    }
]
