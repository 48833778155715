import React, {useState} from 'react';
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {changeSystemOrganizationsFilterOptions, selectSystemOrganizationsCount, selectSystemOrganizationsFilterOptions} from "../../../../app/store/reducers/billing/billingReducer";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

const OrganizationDataTableHeader = () => {
    const filterOptions = useSelector(selectSystemOrganizationsFilterOptions)
    const dateFormat = useSelector(selectDateFormat);

    const [organizationInn, setOrganizationInn] = useState(filterOptions.inn || '');
    const [organizationName, setOrganizationName] = useState(filterOptions.name || '');
    const [organizationCode, setOrganizationCode] = useState(filterOptions.code || '');
    const [createdAtStart, setCreatedAtStart] = useState(filterOptions.created_at_start || null);
    const [createdAtEnd, setCreatedAtEnd] = useState(filterOptions.created_at_end || null);
    const organizationsCount = useSelector(selectSystemOrganizationsCount);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const onSearch = () => {
        dispatch(changeSystemOrganizationsFilterOptions({
            inn: organizationInn,
            name: organizationName,
            code: organizationCode,
            created_at_start: createdAtStart ? dayjs(createdAtStart).format("YYYY-MM-DD") : null ,
            created_at_end: createdAtEnd ? dayjs(createdAtEnd).format("YYYY-MM-DD") : null ,
            page: 1,
        }));
    }

    return (
        <Row className="flex-between-center align-items-center">
            <Col xs={12} sm="auto" className="pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, 'admin.organizations.datatable.header.title')}</h5>
                <small>{t(lang, 'admin.organizations.datatable.organizations_count')}:
                    <span className="fw-black text-black mx-1">{organizationsCount}</span>
                </small>
            </Col>
            <Col xs={12} sm={"auto"} className="mt-3 mt-sm-0 d-flex gap-2" onKeyUp={event => event.key === 'Enter' && onSearch()}>
                    <DatePicker
                        selected={createdAtStart ? new Date(createdAtStart) : null}
                        isClearable
                        onChange={setCreatedAtStart}
                        selectsStart
                        className="form-control"
                        placeholderText={t(lang, 'roaming.waybill.v2.datatable.header.filter.created_at_start')}
                        dateFormat={`${dateFormat}`}
                    />
                    <DatePicker
                        selected={createdAtEnd ? new Date(createdAtEnd) : null}
                        isClearable
                        onChange={setCreatedAtEnd}
                        selectsStart
                        className="form-control"
                        placeholderText={t(lang, 'roaming.waybill.v2.datatable.header.filter.created_at_end')}
                        dateFormat={`${dateFormat}`}
                    />

                <InputGroup>
                    <Form.Control
                        size="sm"
                        type="search"
                        value={organizationCode}
                        className="search-input"
                        onKeyUp={event => event.key === 'Enter' && onSearch()}
                        onChange={event => setOrganizationCode(event.target.value)}
                        placeholder="Поиск по коду"
                    />

                </InputGroup>
                <InputGroup>
                    <Form.Control
                        size="sm"
                        type="search"
                        value={organizationName}
                        className="search-input"
                        onKeyUp={event => event.key === 'Enter' && onSearch()}
                        onChange={event => setOrganizationName(event.target.value)}
                        placeholder="Поиск название"
                    />

                </InputGroup>
                <InputGroup>
                    <Form.Control
                        size="sm"
                        type="search"
                        value={organizationInn}
                        className="search-input"
                        onKeyUp={event => event.key === 'Enter' && onSearch()}
                        onChange={event => setOrganizationInn(event.target.value)}
                        placeholder={t(lang, 'admin.organizations.header.filter.search.input.placeholder')}
                    />

                </InputGroup>
                <p className="p-0 m-0 me-2">Hайти:</p>
                <IconButton icon="search" size="sm" onClick={onSearch}/>
            </Col>

        </Row>
    );
};

export default OrganizationDataTableHeader;