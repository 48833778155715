export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_RESTORED = 'FORGOT_PASSWORD_RESTORED';
export const FORGOT_PASSWORD_RESTORE_FAILED = 'FORGOT_PASSWORD_RESTORE_FAILED';
export const REGISTRATION_REQUESTED = 'REGISTRATION_REQUESTED';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const REMOVE_ACCOUNT_SESSION_REQUESTED = 'REMOVE_ACCOUNT_SESSION_REQUESTED';
export const REMOVE_ACCOUNT_SESSION_SUCCEED = 'REMOVE_ACCOUNT_SESSION_SUCCEED';
export const REMOVE_ACCOUNT_SESSION_FAILED = 'REMOVE_ACCOUNT_SESSION_FAILED';
export const LOGOUT = "LOGOUT";
export const ADD_USER_ORGANIZATION_SUCCESS = 'ADD_USER_ORGANIZATION_SUCCESS';

export const ACCOUNT_REMOVE_FROM_ORGANIZATION_SUCCEED = "ACCOUNT_REMOVE_FROM_ORGANIZATION_SUCCEED";
