import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {roamingInvoiceTypes} from "../../../../../enum/RoamingInvoiceWrapper";

const RoamingInvoiceFormTypeSelector = ({defaultType, onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ReactSelect options={roamingInvoiceTypes}
                     getOptionValue={option => option.value}
                     getOptionLabel={option => t(lang, option.label)}
                     value={roamingInvoiceTypes.find(o => o.value === defaultType)}
                     onChange={(option) => onChange(option.value)}
                     isClearable={false}
                     isSearchable={false}
                     {...selectProps}
        />
    );
};

export default RoamingInvoiceFormTypeSelector;