import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import IconButton from "../../../../../common/IconButton";
import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import {faArrowLeftLong} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectBranch from "../../../../../common/SelectBranch";
import ItemRowAdd from "./ItemRowAdd";
import {UZS} from "../../../../../../enum/CurrencyCode";
import {selectCurrency} from "../../../../../../app/store/reducers/currency/currencyReducer";
import numeral from "numeral";


const itemsFieldName = 'items';

const InternalOrderForm = ({loading, formTitle}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const currencies = useSelector(selectCurrency);

    const dispatch = useDispatch();
    const history = useHistory()

    const {register, formState: {errors}, control} = useFormContext();
    const {append, fields, remove} = useFieldArray({name: itemsFieldName});

    const watchFieldArray = useWatch({name: itemsFieldName});
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateFormat);

    const activeOrganization = useSelector(selectActiveOrganization)
    const [totalSum, setTotalSum] = useState(0);

    useEffect(() => {
        const totalAmount = controlledFields.reduce((acc, item) => {
            return acc + item?.quantity * item.price?.amount
        }, 0);

        setTotalSum(totalAmount);
    }, [controlledFields]);

    const addNewItem = () => {
        append({
            product: '',
            barcode: '',
            product_code: '',
            measurement: '',
            price: {
                amount: '',
                currency_id: currencies?.find(c => c?.code === UZS)?.id
            },
            quantity: ''
        });
    };

    const onDeleteItem = item => {
        const index = fields.indexOf(item);
        remove(index);
    };

    return (
        <Fragment>
            <Row className="g-3 mb-3">
                <Col xs={12} className="pe-lg-2 mb-3">
                    <Card className="h-100">
                        <Card.Header className={'d-flex justify-content-between'}>
                            <h5 className="mb-0">{formTitle}</h5>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "crm.internal.order.from.branch")}</Form.Label>
                                        <Controller control={control} render={({field}) => (
                                            <SelectBranch
                                                defaultBranchId={field.value?.id}
                                                placeholder={t(lang, "edi.common.shipping_address.placeholder")}
                                                classNamePrefix="react-select"
                                                className={classNames({
                                                    'is-invalid': errors?.branch_from,
                                                })}
                                                wrapperClassName={classNames({
                                                    'is-invalid': errors?.branch_from,
                                                })}
                                                onChange={branch => {
                                                    field.onChange(branch);
                                                }}/>
                                        )}
                                                    name="branch_from"
                                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.branch_from?.message}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "crm.internal.order.to.branch")}</Form.Label>
                                        <Controller control={control} render={({field}) => (
                                            <SelectBranch
                                                defaultBranchId={field.value?.id}
                                                placeholder={t(lang, "edi.common.shipping_address.placeholder")}
                                                classNamePrefix="react-select"
                                                className={classNames({
                                                    'is-invalid': errors?.branch_to,
                                                })}
                                                wrapperClassName={classNames({
                                                    'is-invalid': errors?.branch_to,
                                                })}
                                                onChange={branch => {
                                                    field.onChange(branch);
                                                }}/>
                                        )}
                                                    name="branch_to"
                                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.branch_to?.message}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "crm.common.order.number")}</Form.Label>
                                        <Form.Control type="text"
                                                      placeholder={t(lang, "crm.common.order.number.placeholder")}
                                                      isInvalid={errors?.info?.number}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.info?.number}
                                                      {...register('info.number', {
                                                          required: t(lang, "crm.common.forms.validations.is_required"),
                                                      })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.info?.number?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "crm.common.order.date")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name='info.date'
                                            render={({field}) => (
                                                <DatePicker
                                                    isClearable
                                                    placeholderText={t(lang, "crm.common.order.date.placeholder")}
                                                    wrapperClassName={classNames({
                                                        'is-invalid': errors?.info?.date,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.info?.date
                                                    })}
                                                    dateFormat={dateFormat}
                                                    selected={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors?.info?.date,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.info?.date
                                                    })}
                                                />
                                            )}
                                            rules={{
                                                required: t(lang, "crm.common.forms.validations.is_required")
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.info?.date?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "crm.common.contract.number")}</Form.Label>
                                        <Form.Control type="text"
                                                      placeholder={t(lang, "crm.common.contract.number.placeholder")}
                                                      {...register('contract.number')}
                                        />

                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t(lang, "crm.common.contract.date")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name='contract.date'

                                            render={({field}) => (
                                                <DatePicker
                                                    isClearable
                                                    placeholderText={t(lang, "crm.common.contract.date.placeholder")}
                                                    dateFormat={dateFormat}
                                                    selected={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    className={'form-control'}
                                                />
                                            )}
                                        />

                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>{t(lang, "crm.orders.add_order.order_details.final_shipment_date")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name='expires_on'

                                            render={({field}) => (
                                                <DatePicker
                                                    isClearable
                                                    placeholderText={t(lang, "crm.orders.add_order.order_details.final_shipment_date.placeholder")}
                                                    dateFormat={dateFormat}
                                                    selected={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    className={'form-control'}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col xs={12} className="pe-lg-2 mb-3">
                    <Card className="h-100">
                        <Card.Header>
                            <Row className="align-items-end g-2">
                                <Col>
                                    <h5 className="mb-0">{t(lang, "crm.common.items")}</h5>
                                </Col>
                                <Col xs="auto">
                                    <IconButton variant="falcon-default" size="sm" icon="plus" transform="shrink-3"
                                                className="me-2" onClick={addNewItem}>
                                        <span className="d-none d-sm-inline-block ms-1">
                                            {t(lang, "crm.common.add_button")}
                                        </span>
                                    </IconButton>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Table responsive striped hover>
                                <thead>
                                <tr>
                                    <th scope="col">{t(lang, "crm.common.items.product")}</th>
                                    <th scope="col">{t(lang, "crm.common.items.product.barcode")}</th>
                                    <th scope="col">{t(lang, "crm.common.items.product.product_code")}</th>
                                    <th scope="col"
                                        style={{width: 150}}>{t(lang, "crm.common.items.product.measurement")}</th>
                                    <th scope="col"
                                        style={{width: 100}}>{t(lang, "crm.common.items.product.quantity")}</th>
                                    <th scope="col">{t(lang, "crm.common.items.product.price")}</th>
                                    <th scope="col">{t(lang, "crm.common.items.product.total")}</th>
                                    <th scope="col"/>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((item, index) => (
                                    <ItemRowAdd item={item}
                                                key={item.id}
                                                onDelete={onDeleteItem} arrayFieldName={itemsFieldName} index={index}
                                    />
                                ))}
                                </tbody>
                            </Table>

                            <div className={"text-end"}>
                                <h5>{t(lang, "edi.contract_formation.datatable.header.row.total_sum")}: {numeral.formats[numberFormat].format(totalSum)}</h5>
                            </div>

                            <div className="border-dashed-bottom my-3"/>

                            <Row className={'justify-content-end'}>
                                <Col md={'auto'} className="text-end">
                                    <Button onClick={() => history.goBack()} variant={'falcon-primary'}>
                                        <FontAwesomeIcon icon={faArrowLeftLong} className={'me-2'}/>
                                        {t(lang, "crm.orders.item.row.button.back")}
                                    </Button>
                                </Col>
                                <Col md={'auto'} className="text-end">
                                    <Button type="submit" disabled={loading}>
                                        {loading && <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />}
                                        {t(lang, "crm.common.save_button")}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>)
};

export default InternalOrderForm;
