import {Doughnut} from 'react-chartjs-2';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const getThemeColor = (colorName) => {
    const themeColors = {
        primary: '#1f78b4',
        success: '#4caf50',
        info: '#33a02c',
        'info-light': '#91c7ae',
        'info-dark': '#2f4554',
        'danger-lighter': '#ff7f50',
        'danger-darker': '#c23531',
        'warning-light': '#ffb980',
        'warning-dark': '#d48265',
        'body-bg': '#ffffff',
    };
    return themeColors[colorName] || '#000000';
};


const MostLeadsChart = ({ style, isDarkMode = false, data, viewer }) => {

    const viewerData = (data && viewer) ? [...data?.find(item => item.name === viewer)?.packages, ...data?.find(item => item.name === viewer)?.demo_packages] : []


    const chartData = {
        labels: viewer
            ? viewerData?.map((item) => item.name)
            : data?.map((item) => item.name),
        datasets: [
            {
                data: viewer
                    ? viewerData?.map((item) => item.users_count)
                    : data?.map((item) => item.active_users_count),

                backgroundColor: viewer
                    ? viewerData?.map((item, index) => [
                        getThemeColor('primary'),
                        getThemeColor('success'),
                        getThemeColor('info'),
                        getThemeColor('info-light'),
                        getThemeColor('info-dark'),
                        getThemeColor('danger-lighter'),
                        getThemeColor('danger-darker'),
                        getThemeColor('warning-light'),
                        getThemeColor('warning-dark'),
                    ][index % 9])
                    : data?.map((item, index) => [
                        getThemeColor('primary'),
                        getThemeColor('success'),
                        getThemeColor('info'),
                        getThemeColor('info-light'),
                        getThemeColor('info-dark'),
                        getThemeColor('danger-lighter'),
                        getThemeColor('danger-darker'),
                        getThemeColor('warning-light'),
                        getThemeColor('warning-dark'),
                    ][index % 9]),
                borderColor: getThemeColor('body-bg'),
                borderWidth: 3,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        return ` ${value}`; // Tooltipda faqat qiymat ko'rsatiladi
                    },
                },
            },
            legend: {
                display: true,
                labels: {
                    filter: function (legendItem, chartData) {
                        const dataset = chartData.datasets[0]; // 0-indeksdagi datasetni olish
                        const value = dataset.data[legendItem.index]; // Mos qiymatni olish
                        return value !== 0;
                    },
                },
            },
            datalabels: {
                color: '#fff',
                font: {
                    weight: 'bold',
                    size: 12,
                },
                formatter: (value, context) => {
                    if (value === 0) return null;
                    const dataset = context.chart.data.datasets[0];
                    const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
                    const percentage = ((value / total) * 100).toFixed(1);
                    return `${percentage}%`;
                },
                offset: -25,
            },
        },
        cutout: '55%',
        rotation: 90,
    };


    return <div
        style={{
            width: '100%',
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'
        }}
    >
        <Doughnut data={chartData} options={options} width={300} height={300} style={{width: 600, height: 200}}/>
    </div>
};

export default MostLeadsChart;