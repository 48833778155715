import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Col, Form, Row, Table} from "react-bootstrap";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import numeral from "numeral";
import ReactSelect from "react-select";
import classNames from "classnames";
import SelectCompanyCatalog from "../../../IKPU/SelectCompanyCatalog";
import {selectProductCatalogs} from "../../../../../app/store/reducers/invoice/invoiceReducer";
import {selectCompanyCatalogs} from "../../../../../app/store/reducers/roaming/roamingReducer";
import {InvoiceNdsZeroNoneVatPayers} from "../../../../../enum/InvoiceStatus";
import OriginLinkSelector from "../../../../common/OriginLinkSelector";
import {loadCatalogByCode} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import CatalogPackageLinkSelector from "../../../../common/CatalogPackageLinkSelector";
import {debounce} from "underscore";


const itemRootName = "items";

const InvoiceItems = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const storageCatalogs = useSelector(selectProductCatalogs);
    const catalogs = useSelector(selectCompanyCatalogs);

    const {setValue, formState: {errors}, getValues} = useFormContext();
    const {fields} = useFieldArray({name: itemRootName});
    const watchFieldArray = useWatch({name: itemRootName});

    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        }
    })

    // set default catalogs/benefits/item_packages
    useEffect(() => {
        controlledFields.map((item, index) => {
            if (item.catalogCode)
                return;

            const catalog = catalogs.find(c => c.class_code === storageCatalogs?.[item.product]?.catalogClassCode);
            if (catalog) {
                onChangeCatalog(index, catalog)
                onChangeOrigin(index, storageCatalogs[item.product].origin);
                const itemPackage = catalog.package_names.find(p => p.code.toString() === storageCatalogs?.[item.product]?.packageCode);
                const benefit = catalog.lgotas.find(b => b.new_id === storageCatalogs?.[item.product]?.benefitId || b.id === storageCatalogs?.[item.product]?.benefitId);
                if (itemPackage)
                    onChangePackage(index, itemPackage)
                if (benefit)
                    onChangeBenefit(index, benefit)
            }
        })
    }, [storageCatalogs, catalogs])

    // on change bulk catalog/privilege
    const onBulkChangeCatalog = (catalog, benefit) => {
        for (let i = 0; i < controlledFields.length; i++) {
            onChangeCatalog(i, catalog);
            onChangeBenefit(i, benefit);
        }
    };

    const onBulkChangeOrigin = (origin) => {
        for (let i = 0; i < controlledFields.length; i++) {
            onChangeOrigin(i, origin);
        }
    };

    const onChangeOrigin = (index, origin) => {
        setValue(`${itemRootName}.${index}.origin`, origin || null);
    };

    const onChangeCatalog = (index, catalog) => {
        if (catalog) {
            setValue(`${itemRootName}.${index}.catalogCode`, catalog.class_code);
            setValue(`${itemRootName}.${index}.catalogName`, catalog.name);
            setValue(`${itemRootName}.${index}.packageNames`, catalog.package_names);
            setValue(`${itemRootName}.${index}.usePackage`, catalog.use_package);
            setValue(`${itemRootName}.${index}.origin`, null);
        } else {
            setValue(`${itemRootName}.${index}.catalogCode`, null);
            setValue(`${itemRootName}.${index}.catalogName`, null);
            setValue(`${itemRootName}.${index}.packageNames`, []);
            setValue(`${itemRootName}.${index}.usePackage`, false);
            setValue(`${itemRootName}.${index}.origin`, null);
        }
        onChangeBenefit(index, null);
        onChangePackage(index, null);
    };

    const onChangeBenefit = (index, benefit) => {
        if (benefit) {
            const invoiceDate = getValues('invoiceInfo.date');
            const ndsRate = invoiceDate?.getFullYear() > 2022 ? 12 : 15;
            const benefitId = invoiceDate?.getFullYear() >= 2022 ? benefit.new_id : benefit.id

            let benefitPrice = 0;

            if (benefit.type === InvoiceNdsZeroNoneVatPayers)
                benefitPrice = getValues(`${itemRootName}.${index}.total`) * ndsRate / 100;

            setValue(`${itemRootName}.${index}.benefitId`, benefitId)
            setValue(`${itemRootName}.${index}.benefitName`, benefit.name)
            setValue(`${itemRootName}.${index}.benefitType`, benefit.type)
            setValue(`${itemRootName}.${index}.benefitPrice`, benefitPrice)
        } else {
            setValue(`${itemRootName}.${index}.benefitId`, null)
            setValue(`${itemRootName}.${index}.benefitName`, null)
            setValue(`${itemRootName}.${index}.benefitType`, null)
            setValue(`${itemRootName}.${index}.benefitPrice`, null)
        }
    };

    const onChangePackage = (index, itemPackage) => {
        if (itemPackage) {
            setValue(`${itemRootName}.${index}.packageCode`, itemPackage.code)
            setValue(`${itemRootName}.${index}.packageName`, lang === "ru" ? itemPackage.name_ru : itemPackage.name_uz);
        } else {
            setValue(`${itemRootName}.${index}.packageCode`, null)
            setValue(`${itemRootName}.${index}.packageName`, null);
        }
    };

    const subtotal = controlledFields.reduce((acc, item) => acc + item.total, 0);
    const tax = controlledFields.reduce((acc, item) => acc + item.nds_value, 0);


    return (
        <Fragment>
            <div className="text-end position-absolute" style={{top: 15, right: 15}}>
                <SelectCompanyCatalog
                    variant="falcon-default"
                    size="sm"
                    className="w-auto"
                    textButton={t(lang, "edi.common.select_common_catalog_class_code_button")}
                    onChange={onBulkChangeCatalog}
                />
            </div>
            <Row>
                <Col xs={12}>
                    <Table responsive striped bordered hover className="mt-3 border-300 overflow-auto">
                        <thead className="bg-200 text-dark">
                        <tr className="fs--1 text-center">
                            <th>№</th>
                            <th>
                                {t(lang, "edi.common.items.product.catalog_class_code")}
                                <span className={'text-danger'}>*</span>
                            </th>
                            <th>{t(lang, "edi.common.items.product")}</th>
                            <th>{t(lang, "edi.common.items.product.benefit")}</th>
                            <th>{t(lang, "edi.common.items.product.barcode")}</th>
                            <th>{t(lang, "edi.common.items.product.measurement")}</th>
                            <th>{t(lang, "edi.common.items.product.quantity")}</th>
                            <th>{t(lang, "edi.common.items.product.price")}</th>
                            <th>{t(lang, "edi.common.items.product.total")}</th>
                            <th>{t(lang, "edi.common.items.product.nds")}</th>
                            <th>{t(lang, "edi.common.items.product.nds_value")}</th>
                            <th>{t(lang, "edi.common.items.product.total_with_nds")}</th>
                            <th>
                                {/*{t(lang, "edi.common.origin")}*/}
                                {/*<br/>*/}
                                <Controller name={`common.origin`}
                                            render={() => {
                                                return <OriginLinkSelector isTitle={true} onChange={origin => onBulkChangeOrigin(origin)}/>
                                            }}/>
                            </th>
                            {/*<th>*/}
                            {/*    {t(lang, "edi.common.origin")}*/}
                            {/*    <RoamingInvoiceOriginSelect catalogCode={null}*/}
                            {/*                                  defaultOriginType={null}*/}
                            {/*                                  onChange={onBulkChangeOrigin}*/}
                            {/*    />*/}
                            {/*</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {controlledFields.map((item, index) => <InvoiceItemRow
                            key={item.id}
                            item={item}
                            index={index}
                            onChangeBenefit={onChangeBenefit}
                            onChangeCatalog={onChangeCatalog}
                            onChangeOrigin={onChangeOrigin}
                            onChangePackage={onChangePackage}
                        />)}
                        </tbody>
                    </Table>
                </Col>
                <Row className="g-0 justify-content-end">
                    <Col xs={"auto"}>
                        <Table borderless size="sm" className="fs--1 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(subtotal)}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(tax)}
                                </td>
                            </tr>
                            <tr className="border-top">
                                <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(subtotal + tax)}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Row>
        </Fragment>
    )
};
export default InvoiceItems;

const InvoiceItemRow = ({index, item, onChangeCatalog, onChangeBenefit, onChangePackage, onChangeOrigin}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const itemName = `${itemRootName}.${index}`;
    const {setValue, formState: {errors}, getValues} = useFormContext();
    const itemErrors = errors && errors[itemRootName] && errors[itemRootName][index];
    const catalogCode = useWatch({name: `${itemName}.catalogCode`, exact:true});
    const packageCode = useWatch({name: `${itemName}.packageCode`, exact: true})
    const benefitId = useWatch({name: `${itemName}.benefitId`, exact: true})
    const companyCatalogs = useSelector(selectCompanyCatalogs);
    const [catalogPackages, setCatalogPackages] = useState(null)


    useEffect(() => {
        const handleCatalogChange = async () => {
            if (companyCatalogs.length > 0 && catalogCode) {
                let code = onCatalogChanged(companyCatalogs.find(i => i.class_code === catalogCode));
                if (!code) {
                    let catalog = await loadCatalogByCode(catalogCode);
                    setCatalogPackages(catalog);
                }
            }
        };
        handleCatalogChange();
    }, [companyCatalogs, catalogCode]);

    const onCatalogChanged = useCallback((option) => {
        if (option) {
            setCatalogPackages(option);
        } else {
            setCatalogPackages(null);
        }
        setValue(`${itemName}.measurement`, null);
        return option;
    }, [setValue, itemName]);

    return <tr className="fw-semi-bold text-center align-middle fs--1" key={item.id}>
        <td className="p-0">{index + 1}</td>
        <td className="px-0 py-1">
            <Controller name={`${itemName}.catalogCode`}
                        rules={{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        }}
                        render={({field}) => (
                            <SelectCompanyCatalog
                                textButton={field.value || t(lang, "edi.common.button.select")}
                                defaultCatalogCode={field.value}
                                defaultLgota={benefitId}
                                onChange={(catalog, benefit) => {
                                    onChangeCatalog(index, catalog)
                                    onChangeBenefit(index, benefit)
                                }}
                                variant={itemErrors?.catalogCode ? 'outline-danger' : 'outline-primary'}
                            />
                        )}
            />
        </td>
        <td className="p-0">{item.product}</td>
        <td className="p-0">
            {benefitId ?
                <span
                    className="text-success">{t(lang, "edi.common.items.product.selected")}</span>
                :
                <span
                    className="text-warning">{t(lang, "edi.common.items.product.not_selected")}</span>
            }
        </td>
        <td className="p-0">{item.barcode}</td>
        <td className="p-0">
            {catalogPackages &&
                <>
                    <Controller name={`${itemName}.packageCode`}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => {
                                    return (
                                        <CatalogPackageLinkSelector
                                            textButton={field.value || t(lang, "roaming.common.select.placeholder")}
                                            defaultCatalogPackage={packageCode?.code ? packageCode.code : packageCode}
                                            catalogPackages={catalogPackages?.package_names}
                                            onChange={field.onChange}
                                        />
                                    )
                                }
                                }
                    />

                    <Form.Control.Feedback
                        type="invalid">{itemErrors?.packageCode?.message}</Form.Control.Feedback></>
            }
            {!catalogPackages && <>{item.measurement}</>}
        </td>
        <td className="p-0">{item.quantity}</td>
        <td className="p-0">{item.price}</td>
        <td className="p-0">{item.total}</td>
        <td className="p-0">{item.nds_rate}</td>
        <td className="p-0">{item.nds_value}</td>
        <td className="p-0">{item.total_with_nds}</td>
        <td className="p-0">
            <Controller name={`${itemName}.origin`}
                        rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                        render={() => {
                            return <OriginLinkSelector linkAppendClassName={classNames({
                                'text-danger': itemErrors?.origin
                            })}
                                                       defaultOriginType={item.origin}
                                                       onChange={origin => onChangeOrigin(index, origin)}/>
                        }}

            />
        </td>
    </tr>

}
