import PropTypes from "prop-types";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../common/IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {returnShipmentExecutorApproveAsync} from "../../../app/store/reducers/return-shipment/returnShipmentReducer";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnShipmentApprove} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";

const ReturnShipmentApproveExecutor = ({id, ...props}) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => {
        if(dispatch(checkPermission(PermissionEdiReturnShipmentApprove))) setShow(true)
        else dispatch(toastPermission())
    };
    const handleClose = () => {
        setLoading(false);
        setShow(false);
    };

    const returnShipmentExecutorApproveHandler = () => {
        setLoading(true);

        returnShipmentExecutorApproveAsync({
            id: id,
            comment: comment
        })
            .then(() => handleClose())
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-primary"
                size="sm"
                icon="check"
                transform="shrink-3"
                {...props}
            >
                <span className="d-none d-sm-inline-block">{t(lang, "edi.common.approve")}</span>
            </IconButton>

            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "edi.return_shipments.return_shipment.approve_modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder={t(lang, 'edi.common.leave_comment')}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        {t(lang, "edi.common.cancel_button")}
                    </Button>
                    <Button variant="primary" className="d-flex align-items-center" disabled={loading} onClick={returnShipmentExecutorApproveHandler}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, "edi.common.approve")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

ReturnShipmentApproveExecutor.propTypes = {
    id: PropTypes.string,
};

ReturnShipmentApproveExecutor.defaultProps = {
    id: '',
};

export default ReturnShipmentApproveExecutor;
