import React, {useEffect, useState} from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectBranchUses } from "../../app/store/reducers/branch/branchReducer";

const SelectBranchUse = ({ onChange, defaultValue, ...props }) => {
  const branchUses = useSelector(selectBranchUses);
  const [branch, setBranch] = useState(null);

  const onChangeHandler = option => {
    onChange(option);
    setBranch(option);
  }

  useEffect(() => {
    if (defaultValue) {
      const defaultBranch = branchUses.find(b => b.branch.id === defaultValue);
      setBranch(defaultBranch);
    }
  }, [defaultValue])

  const getOptionValue = option => option.branch.id;
  const getOptionLabel = option => `${option.branch.name} ${option.using_member.name}`;

  return (
    <Select
        {...props}
        value={branch}
        hideSelectedOptions
        options={branchUses}
        onChange={onChangeHandler}
        classNamePrefix="react-select"
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
    />
  );
};

SelectBranchUse.propTypes = {
  onChange: PropTypes.func
}
SelectBranchUse.defaultProps  = {
  onChange: () => {}
}

export default SelectBranchUse;
