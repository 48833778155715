import Select from "react-select";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const options = [
    {value: null, label: 'common.selector.option.all'},
    {value: true, label: 'common.selector.option.approved'},
    {value: false, label: 'common.selector.option.not_approved'},
];

const SelectApprovedOrNo = ({onChange, defaultValue, ...props}) => {
    const [value, setValue] = useState(null);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (defaultValue !== null) {
            const val = options.find(v => v.value === defaultValue);
            setValue(val);
        } else {
            setValue(options.find(v => v.value === null));
        }
    }, [defaultValue])

    const onSelect = (option) => {
        setValue(option);
        onChange(option);
    };

    return (
        <Select
            {...props}
            value={value}
            options={options}
            hideSelectedOptions
            onChange={onSelect}
            getOptionValue={option => option.value}
            getOptionLabel={option => t(lang, option.label)}
        />
    );
};

export default SelectApprovedOrNo;