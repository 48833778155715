import React, {useState} from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {loginAsync} from "../../../../app/store/reducers/auth/authReducer";
import Cleave from "cleave.js/react";
import classNames from "classnames";

const LoginWithUsername = () => {
    const dispatch = useDispatch();
    const {register, formState: {errors}, control, handleSubmit} = useForm();
    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false);

    const onSubmit = formData => {
        let username = formData.username.replace(/ /g, '')
        username = '998' + username;
        
        setLoading(true);
        dispatch(loginAsync(username, formData.password))
            .then(() => {
            })
            .catch(() => {
            })
            .finally(() => setLoading(false))
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
                <div className="input-group">
                    <div className="input-group-prepend"><span className="input-group-text">+998</span></div>
                    <Controller control={control} name={'username'} rules={{
                        required: t(lang, "edi.common.forms.validations.is_required")
                    }} render={({field}) => (
                        <Cleave
                            placeholder={"-- --- -- --"}
                            options={{
                                delimiters: [' ', ' ', ' ', ' '],
                                blocks: [2, 3, 2, 2],
                                numericOnly: true
                            }}
                            onChange={(username) => field.onChange(username)}
                            className={classNames('form-control', {
                                'is-invalid': errors?.username,
                            })}
                        />
                    )}/>
                    <Form.Control.Feedback type="invalid">{errors.username?.message}</Form.Control.Feedback>
                </div>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Control
                    placeholder={t(lang, "edi.login.form.password")}
                    className={errors.password && 'is-invalid'}
                    {
                        ...register("password", {
                            required: t(lang, "edi.common.forms.validations.is_required"),
                        })
                    }
                    type="password"
                />
                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
            </Form.Group>

            <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                    <Form.Check type="checkbox" id="rememberMe">
                        <Form.Check.Input
                            type="checkbox"
                            {...register("remember")}
                        />
                        <Form.Check.Label className="mb-0">{t(lang, "edi.login.form.remember_me")}</Form.Check.Label>
                    </Form.Check>
                </Col>

                <Col xs="auto">
                    <Link className="fs--1 mb-0"
                          to="./forgot-password">{t(lang, "edi.login.form.forgot_password")}</Link>
                </Col>
            </Row>

            <Form.Group>
                <Button type="submit" color="primary" className="mt-3 w-100" disabled={loading}>
                    {loading &&
                    <Spinner className="align-middle me-2" animation="border" role="switch"
                             size="sm"/>}
                    {t(lang, "edi.login.form.button.login")}
                </Button>
            </Form.Group>
        </Form>
    );
};

export default LoginWithUsername;
