import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import ContractForm from "./EdiContractForm";
import {editContractAsync} from "../../../../../app/store/reducers/contract/contractReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const EditEdiContractForm = ({show, onClose, contract}) => {
    const [loading, setLoading] = useState(false);
    const [existNumber, setExistNumber] = useState(null);
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            info: {
                number: contract.info.number,
                date: dayjs(contract.info.date, 'YYYY-MM-DD').toDate()
            },
            customer: {
                inn: contract.customer.inn,
                name: contract.customer.name
            },
            executor: {
                inn: contract.executor.inn,
                name: contract.executor.name
            }
        }
    });

    const onSubmit = (form) => {
        setLoading(true)
        editContractAsync(contract.id, {
            info: {
                name: "",
                number: form.info.number,
                date: dayjs(form.info.date).format('YYYY-MM-DD'),
                expire_date: null,
                place: ""
            },
            executor: {
                inn: form.executor.inn,
                name: form.executor.name,
                address: "",
                work_phone: "",
                mobile: "",
                fax: "",
                oked: "",
                account: "",
                mfo: "",
                director_inn: "",
                director_name: "",
                branch_code: "",
                branch_name: "",
                role: ""
            },
            customer: {
                inn: form.customer.inn,
                name: form.customer.name,
                address: "",
                work_phone: "",
                mobile: "",
                fax: "",
                oked: "",
                account: "",
                mfo: "",
                director_inn: "",
                director_name: "",
                branch_code: "",
                branch_name: "",
                role: ""
            },
            items: [],
            parts: [],
            notes: ""
        })
            .then(() => {
                methods.reset();
                onClose();
            }, ({number_exists}) => {
                if (number_exists) {
                    setExistNumber(form.info.number);
                    methods.trigger('info.number')
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Modal
            centered
            size="lg"
            show={show}
            onHide={onClose}
        >
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">{t(lang, 'edi.contract.edit-form.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContractForm existNumber={existNumber}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='button' variant='default' onClick={onClose}>{t(lang, 'edi.common.button.close')}</Button>
                        <Button type='submit' variant='primary' disabled={loading}>
                            {loading &&
                            <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {t(lang, 'edi.common.button.save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
};

export default EditEdiContractForm;
