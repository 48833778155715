import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {loadDraftItemAsync} from "../../../app/store/reducers/draft-item/draftItemReducer";
import DraftItem from "../../../components/hippo/draft-item/components/view/DraftItem";

const ViewDraftItem = () => {
    const {id} = useParams();
    const activeOrganization = useSelector(selectActiveOrganization);
    const [draftItem, setDraftItem] = useState(null);

    const loadDraftItem = (id) => {
        loadDraftItemAsync(id)
            .then((data) => {
                setDraftItem(data);
            })
            .catch(() => {
                setDraftItem(null);
            })
    }

    useEffect(() => {
        loadDraftItem(id)
    }, [id, activeOrganization])

    if (!draftItem) return null;

    return <DraftItem draftItem={draftItem}/>
};

export default ViewDraftItem;