import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import withEditCategory from "./withEditCategory";

const EditCategoryDropdownItem = ({category, handleShow}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Dropdown.Item onClick={() => handleShow(category)}>
            {t(lang, 'items.common.edit')}
        </Dropdown.Item>
    );
};

export default withEditCategory(EditCategoryDropdownItem);