import React, {Fragment, useState} from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";
import {Modal, OverlayTrigger, Popover, Table} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import numeral from "numeral";
import {getNumberFixedValue, getNumberValue} from "../../../../helpers/utils";

const roundingValue = 2;
const OwnerOrganizationsItemsTotalInfo = ({table}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const rows = table.getCoreRowModel().rows;
    const commonPrice = rows.reduce((acc, {original}) => {
        const itemTotalWarehouseState = original.warehouse_states.warehouse_items.reduce((acc, itemWarehouse) => acc + itemWarehouse.state, 0);
        const priceAmount = itemTotalWarehouseState > 0 ? original.price.common_price.amount * itemTotalWarehouseState : 0;
        acc[original.price.common_price.currency.name] = (acc[original.price.common_price.currency.name] || 0) + priceAmount;
        return acc;
    }, {});
    const bulkPrice = rows.reduce((acc, {original}) => {
        const itemTotalWarehouseState = original.warehouse_states.warehouse_items.reduce((acc, itemWarehouse) => acc + itemWarehouse.state, 0);
        const priceAmount = itemTotalWarehouseState > 0 ? original.price.bulk_price.amount * itemTotalWarehouseState : 0;
        acc[original.price.bulk_price.currency.name] = (acc[original.price.bulk_price.currency.name] || 0) + priceAmount;
        return acc;
    }, {});
    const purchasePrice = rows.reduce((acc, {original: {warehouse_states: {warehouse_items}}}) => {
        warehouse_items.forEach(item => {
            if (item.state > 0) {
                if (item.purchase_price) {
                    const {amount, currency: {name}} = item.purchase_price.price;
                    if (!acc[item.id]) acc[item.id] = {};
                    if (!acc[item.id][name]) acc[item.id][name] = 0;
                    acc[item.id][name] += amount * item.state;
                }
            } else {
                if (item.purchase_price) {
                    const {currency: {name}} = item.purchase_price.price;
                    if (!acc[item.id]) acc[item.id] = {};
                    if (!acc[item.id][name]) acc[item.id][name] = 0;
                    acc[item.id][name] += 0;
                }
            }
        });
        return acc;
    }, {});
    const totalItemsWarehousesState = rows.reduce((acc, {original: {warehouse_states: {warehouse_items}}}) => {
        warehouse_items.forEach((warehouseItem) => {
            if (acc[warehouseItem.id])
                acc[warehouseItem.id] = acc[warehouseItem.id] + (warehouseItem.state > 0 ? warehouseItem.state : 0)
            else
                acc[warehouseItem.id] = warehouseItem.state > 0 ? warehouseItem.state : 0
        })
        return acc;
    }, {});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <IconButton variant={'falcon-primary'}
                        icon={faCalculator}
                        size="sm"
                        className="mx-1"
                        onClick={handleShow}
            >
                {t(lang, 'items.common.total_amount_information')}
            </IconButton>

            <Modal size={'lg'} show={show} onHide={handleClose}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>{t(lang, 'items.common.total_amount_information')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                        <tr>
                            <th className={'align-top'}>{t(lang, 'items.common.total_common_price_amount')}:</th>
                            <th>
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'primary'}>{t(lang, 'items.common.price')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge className={'d-block text-end'} bg={'primary'}>
                                        {Object.keys(commonPrice).map((key) => (
                                            <div
                                                key={key}>{`${numeral.formats[numberFormat].format(getNumberValue(getNumberFixedValue(commonPrice[key], roundingValue)))} ${key}`}</div>
                                        ))}
                                    </SoftBadge>
                                </OverlayTrigger>
                            </th>
                        </tr>
                        <tr>
                            <th className={'align-top'}>{t(lang, 'items.common.total_bulk_price_amount')}:</th>
                            <th>
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <SoftBadge
                                                                bg={'secondary'}>{t(lang, 'items.common.bulk_price')}</SoftBadge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <SoftBadge className={'d-block d-block text-end mt-1'} bg={'secondary'}>
                                        {Object.keys(bulkPrice).map((key) => (
                                            <div
                                                key={key}>{`${numeral.formats[numberFormat].format(getNumberValue(getNumberFixedValue(bulkPrice[key], roundingValue)))} ${key}`}</div>
                                        ))}
                                    </SoftBadge>
                                </OverlayTrigger>
                            </th>
                        </tr>
                        <tr>
                            <th className={'align-top'}>{t(lang, 'items.common.total_purchase_price_amount')}:</th>
                            <th>
                                {Object.keys(purchasePrice).map((warehouseId, index) => {
                                    let warehouse = null;
                                    rows.forEach(({original: {warehouse_states: {warehouse_items}}}) => {
                                        const foundWarehouse = warehouse_items.find(w => w.id === parseInt(warehouseId));
                                        if (foundWarehouse)
                                            warehouse = foundWarehouse;
                                    });
                                    const colors = ['primary', 'info', 'success', 'dark', 'secondary'];
                                    const color = colors[index % colors.length];

                                    if (!warehouse) return null;

                                    return (
                                        <OverlayTrigger key={warehouseId}
                                                        placement={'bottom'}
                                                        trigger={['hover', 'focus']}
                                                        overlay={
                                                            <Popover id="popover-basic" className='mt-0'>
                                                                <Popover.Header>
                                                                    <SoftBadge className={'d-block text-center fs--1'}
                                                                               bg={color}>
                                                                        {warehouse.name}
                                                                    </SoftBadge>
                                                                </Popover.Header>
                                                            </Popover>
                                                        }
                                        >
                                            <SoftBadge className={'d-block text-end my-1'} bg={color}>
                                                {Object.entries(purchasePrice[warehouseId]).map(([currency, amount]) => (
                                                    <div key={currency}>
                                                        {`${numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(amount, roundingValue)))} ${currency}`}
                                                    </div>
                                                ))}
                                            </SoftBadge>
                                        </OverlayTrigger>
                                    )
                                })}
                            </th>
                        </tr>
                        <tr>
                            <th className={'align-top'}>{t(lang, 'items.common.total_item_warehouse_state_amount')}:</th>
                            <th>
                                {Object.keys(totalItemsWarehousesState).map((warehouseItemId, index) => {
                                    const colors = ['primary', 'info', 'success', 'dark'];
                                    const color = colors[index % colors.length]
                                    let warehouse = null;
                                    rows.forEach(({original: {warehouse_states: {warehouse_items}}}) => {
                                        const foundWarehouse = warehouse_items.find(w => w.id === parseInt(warehouseItemId));
                                        if (foundWarehouse)
                                            warehouse = foundWarehouse;
                                    });

                                    return (
                                        <SoftBadge bg={color} className='d-block text-end mb-1 flex-fill me-1'
                                                   key={warehouseItemId}>
                                            {numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(totalItemsWarehousesState[warehouseItemId], roundingValue)))}
                                            <br/>
                                            {warehouse.name}
                                        </SoftBadge>
                                    )
                                })}

                                <hr className={'mt-2 mb-1'}/>
                                <p className={'mb-1 fw-bold text-end'}>
                                    {numeral['formats'][numberFormat].format(getNumberValue(getNumberFixedValue(Object.values(totalItemsWarehousesState).reduce((acc, value) => acc + value, 0), roundingValue)))}
                                </p>
                            </th>
                        </tr>
                        </thead>
                    </Table>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default OwnerOrganizationsItemsTotalInfo;