import {Card, Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";
import {loadBankInfo} from "../../../../app/store/reducers/roaming/roamingReducer";
import React from "react";
import {GetAllowedStatus} from "../contract-formation-view/ContractFormationStatus";
import FindPerson from "../../person/FindPerson";
import FindCompanySearchable from "../../organization/FindCompanySearchable";


const ContractFormationCustomerInfo = ({editDocumentStatus}) => {
    const { register, formState: {errors}, control, setValue } = useFormContext()
    const lang = useSelector(selectLang);
    const t = Translate

    const isEditableStatus  = GetAllowedStatus(editDocumentStatus)

    const onCompanyChange = async customer => {

        if(customer && !isEditableStatus) {
            setValue(`customer.name`, customer?.name)
            setValue(`customer.pinflTin`, (customer?.director_pinfl || customer?.director_inn) || customer?.person_num)
            setValue(`customer.fio`, customer?.director_pinfl ? customer?.director : customer?.name)
            setValue(`customer.address`, customer?.address)
            setValue(`customer.bankId`, customer?.mfo)
            setValue(`customer.account`, customer?.account)
        }


        if (customer?.mfo && !isEditableStatus){
            const bankInfo = await loadBankInfo(customer.mfo, lang)
            setValue('customer.bank', bankInfo?.name || "")
        }
        else if (!isEditableStatus) {
            setValue('customer.bank', '')
        }
    }


    const onCompanyCustomerChange = async director => {
        if (director) {
            setValue(`customer.pinflTin`, director?.pinfl || director?.inn)
            setValue(`customer.fio`, director?.name)
        } else {
            setValue(`customer.pinflTin`, '')
            setValue(`customer.fio`, '')
        }
    }

    return(
        <Card>
            <Card.Header>
                <h5 className="mb-2 pb-1">{t(lang, "roaming.contract.company.contractor")}</h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.name")}</Form.Label>
                        <Form.Control
                            name="customer.name"
                            {...register(`customer.name`,{
                                required: t(lang,"edi.common.forms.validations.is_required")
                            })}
                            className={errors?.customer?.name && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.name")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.customer?.name?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}</Form.Label>
                        <Controller
                            name={`customer.tin`}
                            control={control}
                            rules={{
                                required: t(lang,"edi.common.forms.validations.is_required")
                            }}
                            render={({field}) => (
                                <FindCompanySearchable
                                    onChange={(customer) => {
                                        field.onChange(customer?.inn)
                                        onCompanyChange(customer)
                                    }}
                                    defaultInn={field.value}
                                    disabled={true}
                                    inputProps={{
                                        className: errors?.customer?.tin && 'is-invalid',
                                        placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.customer?.tin?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.full_name_person")}</Form.Label>
                        <Form.Control
                            {...register(`customer.fio`, {
                                required: t(lang,"edi.common.forms.validations.is_required")
                            })}
                            className={errors?.customer?.fio && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.full_name_person")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.customer?.fio?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl_person")}</Form.Label>
                        <Controller
                            name={`customer.pinflTin`}
                            control={control}
                            rules={{
                                required: t(lang,"edi.common.forms.validations.is_required")
                            }}
                            render={({field}) => (
                                <FindPerson
                                    onChange={onCompanyCustomerChange}
                                    defaultInnOrPinfl={field.value}
                                    inputProps={{
                                        className: errors?.customer?.pinflTin && 'is-invalid',
                                        placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl_person")
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.customer?.pinflTin?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.work_phone_number")}</Form.Label>
                        <Form.Control
                            {...register("customer.phoneWork")}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.work_phone_number")}
                        />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.mobile_number")}</Form.Label>
                        <Form.Control
                            {...register("customer.mobile")}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.mobile_number")}
                        />
                    </Col>
                    <Col sm="12" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.address")}</Form.Label>
                        <Form.Control
                            {...register("customer.address",{
                                required: t(lang,"edi.common.forms.validations.is_required")
                            })}
                            className={errors?.customer?.address && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.address")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.customer?.address?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="4" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.bank_account")}</Form.Label>
                        <Form.Control
                            {...register("customer.account",{
                                required: t(lang,"edi.common.forms.validations.is_required")
                            })}
                            className={errors?.customer?.account && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.bank_account")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.customer?.account?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="5" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.bank_address")}</Form.Label>
                        <Form.Control
                            {...register("customer.bank",{
                                required: t(lang,"edi.common.forms.validations.is_required")
                            })}
                            className={errors?.customer?.bank && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.bank_address")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.customer?.bank?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="3" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.mfo")}</Form.Label>
                        <Form.Control
                            {...register("customer.bankId",{
                                required: t(lang,"edi.common.forms.validations.is_required")
                            })}
                            className={errors?.customer?.bankId && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.mfo")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.customer?.bankId?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ContractFormationCustomerInfo;
