import PropTypes from "prop-types";
import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import ExecutorCancelDeclineForm from "../../order/ExecutorCancelDecline";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {returnOrderExecutorCancelDeclineAsync} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import {PermissionEdiReturnOrderDownloadExcel} from "../../../../enum/Permission/EdiPermission";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const ReturnOrderExecutorCancelDecline = ({id, ...props}) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => {
        if(dispatch(checkPermission(PermissionEdiReturnOrderDownloadExcel))) setShow(true);
        else dispatch(toastPermission())
    };
    const handleClose = () => setShow(false);

    const onDeclineCancelled = () => {
        setLoading(true);

        returnOrderExecutorCancelDeclineAsync({
            id: id,
            comment: comment
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };

    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-primary"
                size="sm"
                iconClassName="me-1"
                icon={'rotate-left'}
                {...props}
            >{t(lang, 'edi.common.button.cancel_decline')}</IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'edi.return_order.cancel_decline_return_order.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder={t(lang, 'edi.common.leave_comment')}
                            onChange={(event) => setComment(event.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
                    <Button variant="primary" className="d-flex align-items-center" disabled={loading} onClick={onDeclineCancelled}>
                        {loading && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                        {t(lang, 'edi.common.button.cancel_decline')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

ExecutorCancelDeclineForm.propTypes = {
    id: PropTypes.string,
};
ExecutorCancelDeclineForm.defaultProps = {
    id: "",
};

export default ReturnOrderExecutorCancelDecline;
