import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import {Button, Modal, Spinner} from "react-bootstrap";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {customerAcceptWaybillAsync} from "../../../../../app/store/reducers/roaming/roamingWaybillReducer";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingShipmentAccept,
} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";

const CustomerAcceptWaybill = ({id, ...props}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingShipmentAccept))) setShow(true)
        else dispatch(toastPermission())
    };

    const acceptWaybillHandler = () => {
        setLoading(true);

        customerAcceptWaybillAsync({
            id: id,
            certificate: currentCertificate,
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <SelectCertificateWrapperComponent permission={PermissionRoamingShipmentAccept}
                submitButton={
                    <IconButton
                        size="sm"
                        {...props}
                        icon={faCheck}
                        id="acceptWaybill"
                        iconClassName="me-1"
                        onClick={handleShow}
                        variant="falcon-primary"
                    >{t(lang, 'roaming.common.accept')}</IconButton>
                }
                openDialogButtonText={t(lang, 'roaming.common.accept')}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'roaming.waybill.accept_waybill.modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        {t(lang, 'roaming.waybill.accept_waybill.modal.description')}
                    </h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'roaming.common.close')}</Button>

                    <Button variant="primary" onClick={acceptWaybillHandler}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, 'roaming.common.accept')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CustomerAcceptWaybill;
