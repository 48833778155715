import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {singleSidedInvoiceTypes} from "../../../../../enum/RoamingInvoiceWrapper";

const RoamingInvoiceFormSingleSideTypeSelector = ({defaultType, onChange, options}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ReactSelect options={options}
                     getOptionValue={option => option.value}
                     getOptionLabel={option => t(lang, option.label)}
                     value={options.find(o => o.value === defaultType)}
                     onChange={(option) => onChange(option.value)}
                     isOptionDisabled={option => option.disabled}
                     isClearable={false}
                     isSearchable={false}
        />
    );
};

export default RoamingInvoiceFormSingleSideTypeSelector;
