import {Form} from "react-bootstrap";
import IconButton from "../../../../../common/IconButton";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import React, {useState} from "react";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import dayjs from "dayjs";
import {changeFilterOptionsAsync, selectReturnPurchaseFilterOptions} from "../../../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import DatePicker from "react-datepicker";


const ContractorReturnPurchaseDatatableHeader = () => {
    const lang = useSelector(selectLang)
    const t = Translate
    const cardContractor = useSelector(selectCardContractor)
    const dateFormat = useSelector(selectDateFormat);
    const filters = useSelector(selectReturnPurchaseFilterOptions)
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(filters.date_time_start ? dayjs(filters.date_time_start).toDate() : null);
    const [endDate, setEndDate] = useState(filters.date_time_end ? dayjs(filters.date_time_end).toDate() : null);

    const onChangeStartDate = (selectedDate) => {
        let date_start = selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD HH:mm:ss") : null;
        dispatch(changeFilterOptionsAsync({...filters, page: 1, date_time_start: date_start}))
        setStartDate(selectedDate);
    }

    const onChangeEndDate = (selectedDate) => {
        let date_end = selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD HH:mm:ss") : null;
        dispatch(changeFilterOptionsAsync({...filters, page: 1, date_time_end: date_end}))
        setEndDate(selectedDate);
    }

    return (
        <>
            <div className="d-flex align-content-center justify-content-between">
                <div className={"d-flex"}>
                    <Form.Group>
                        <Form.Label>Дата возврата (начало)</Form.Label>
                        <DatePicker
                            isClearable
                            className='form-control'
                            placeholderText={"Дата возврата"}
                            dateFormat={dateFormat}
                            selected={startDate}
                            onChange={onChangeStartDate}
                        />
                    </Form.Group>
                    <Form.Group className={'ps-3'}>
                        <Form.Label>Дата возврата (конец)</Form.Label>
                        <DatePicker
                            isClearable
                            className='form-control'
                            placeholderText={"Дата возврата"}
                            dateFormat={dateFormat}
                            selected={endDate}
                            onChange={onChangeEndDate}
                        />
                    </Form.Group>
                </div>
                <div className={"pt-4"}>
                    <Link
                        to={{
                            pathname: "/warehouse/operation/return-purchases/register",
                            search: `?client_id=${cardContractor?.id}`,
                        }}>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            className="mx-2"
                            transform="shrink-3"
                        >
                        <span
                            className="d-none d-sm-inline-block ms-1">{t(lang, "warehouse.operation.item.common.new_return.purchase")}</span>
                        </IconButton>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ContractorReturnPurchaseDatatableHeader;