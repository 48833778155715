import {Accordion, Button, Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import React from "react";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
    ContractFormationFileDownload
} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";


const ContractFormationFile = ({fieldsFile, addFieldFile, removeFieldFile, documentContract}) => {
    const dateFormat = useSelector(selectDateFormat)
    const { formState: {errors: partErrors}, control, setValue} = useFormContext()
    const errors = partErrors.attachments || []
    const lang = useSelector(selectLang);
    const t = Translate;


    const onSelectFile = (event, index) => {
        const file = event.target.files[0];
        setValue(`attachments.${index}.filename`, file?.name)

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = e => {
                const base64 = e.target.result.split(',')[1];
                setValue(`attachments.${index}.file`, base64)
            }
        }

    }

    const ContractFormationDownloadFile = async (e, filename) => {
        ContractFormationFileDownload({file_id: e.target.id, doc_id: documentContract?.id})
            .then(response => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);


                link.href = blobUrl;
                link.download = `${filename}`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
    }


    return(
        <Card>
            <Accordion alwaysOpen={false} defaultActiveKey={fieldsFile.map(p => p.id)}>
                <Accordion.Item eventKey={fieldsFile.id} key={fieldsFile.id}>
                    <Accordion.Header>
                        <Card.Header>
                            <Row>
                                <Col>
                                    <h5>{t(lang,"edi.contract_formation.attachemnt.file_header")}</h5>
                                </Col>
                            </Row>
                        </Card.Header>
                    </Accordion.Header>

                    <Accordion.Body>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col className="text-end">
                                    <IconButton
                                        onClick={addFieldFile}
                                        variant="falcon-primary"
                                        icon="plus"
                                        size="sm"
                                        transform="shrink-3"
                                    >{t(lang,"edi.contract_formation.attachemnt.file_add")}
                                    </IconButton>
                                </Col>
                            </Row>
                            {fieldsFile.map((file, index) => (
                                <Row key={file?.index}  className="my-2 d-flex flex-lg-row flex-column">
                                    <Col className="d-flex justify-content-start">
                                        {index + 1})
                                    </Col>
                                    {file?.id ?
                                        <Col xs={12} lg={4}>
                                            <Form.Label>{t(lang,"edi.contract_formation.attachemnt.file_name")} <p className="mt-3">{file?.filename}</p></Form.Label>
                                        </Col>
                                        :
                                        <Col xs={12} lg={5}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t(lang,"edi.contract_formation.attachemnt.choose_file_name")}</Form.Label>
                                                <Controller
                                                    name={`attachments.${index}.file`}
                                                    control={control}
                                                    rules={{
                                                        required: t(lang,"edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <Form.Control
                                                            type="file"
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors[index]?.file,
                                                                'is-valid': Object.keys(errors).length > 0 && !errors[index]?.file
                                                            })}
                                                            onChange={event =>  onSelectFile(event, index)}
                                                        />
                                                    )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[index]?.file?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    }

                                    <Col xs={12} lg={5} >
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t(lang,"edi.contract_formation.attachemnt.file_date")}</Form.Label>
                                            <Controller
                                                control={control}
                                                name={`attachments.${index}.date`}
                                                render={({field}) => (
                                                    <DatePicker
                                                        onChange={field.onChange}
                                                        dateFormat={dateFormat}
                                                        className="form-control"
                                                        selected={field.value}
                                                        placeholderText={t(lang, "edi.common.contract.date.placeholder")}
                                                        minDate={new Date()}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col className="d-flex align-items-center justify-content-start">
                                        <Button id={documentContract?.contract?.files[index]?.id} variant="falcon-warning" size="sm" className={file?.id ? "d-block me-2 col-3 col-lg-10" : 'd-none'} onClick={(e) => ContractFormationDownloadFile(e, file?.filename)}>
                                            {t(lang,"edi.contract_formation.attachemnt.file_download")}
                                        </Button>
                                        <span className={`cursor-pointer text-danger `}
                                              onClick={() => removeFieldFile(index)}><FontAwesomeIcon icon="trash-alt"/></span>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Card>
    )
}


export default ContractFormationFile