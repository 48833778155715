import InvoiceDataTable from "../../../../components/hippo/invoice/InvoiceDataTable";
import React, {useEffect} from "react";
import {
    loadInvoicesDataTableColumn,
    selectInvoicesDataTable
} from "../../../../app/store/reducers/invoice/invoiceReducer";
import {useDispatch, useSelector} from "react-redux";
import {setEdiBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiInvoiceView} from "../../../../enum/Permission/EdiPermission";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";


const Invoices = () => {
    const activeColumns = useSelector(selectInvoicesDataTable)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    // important
    useSelector(selectAccountRolePermissions)

    useEffect(() => {
        dispatch(loadInvoicesDataTableColumn())
        dispatch(setEdiBreadcrumbData(null))
    },[])

    if(!dispatch(checkPermission(PermissionEdiInvoiceView))) {
        return  <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.edi.invoice.datatable.no_access.title')}</h4>
        </Card>
    }

    return  <InvoiceDataTable activeColumns={activeColumns} />

};

export default Invoices;
