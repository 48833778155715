import React, {useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import ReactSelect, {components} from "react-select";

const SelectBankAccount = ({bankAccounts, onChange}) => {
    const t = Translate;
    const lang  = useSelector(selectLang);
    const [maxSize, setMaxSize] = useState(5);
    const [show, setShow] = useState(false);
    let options = bankAccounts;

    const onChangeHandler = (option) => {
        onChange(option);
        setShow(false);
    };
    const onInputChange = (value) => {
        options = bankAccounts.filter((o) => {
            return !!(o.account.includes(value) || o.bank_id.includes(value));
        })
    };
    const MenuList = ({children, ...props}) => {
        return (
            <components.MenuList {...props}>
                {Array.isArray(children) ? children.slice(0, maxSize) : children}
                {maxSize < options.length &&
                    <div className="text-primary text-center cursor-pointer"
                         onClick={() => setMaxSize(prevState => prevState + 5)}>
                        {t(lang, 'roaming.common.bank_account_selector.click_to_see_more')}
                    </div>
                }
            </components.MenuList>
        )
    };
    const formatOptionLabel = (option) => {
        return (
            <div className="d-flex flex-column">
                <div>
                    <span className="fw-bold me-2">{t(lang, 'roaming.common.bank_account')}:</span>
                    <span>{option.account}</span>
                </div>
                <div>
                    <span className="fw-bold me-2">{t(lang, 'roaming.common.bank_mfo')}:</span>
                    <span>{option.bank_id}</span>
                </div>
            </div>
        )
    };

    return (
        <>
            <Button onClick={() => setShow(true)}>
                {t(lang, 'roaming.common.select.placeholder')}
            </Button>
            {show &&
                <Modal show={true}
                       onHide={() => setShow(false)}
                       size="xl"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Body>
                        <ReactSelect options={options}
                                     closeMenuOnSelect={true}
                                     defaultMenuIsOpen={true}
                                     autoFocus={true}
                                     placeholder={t(lang, 'roaming.common.select.placeholder')}
                                     classNamePrefix="react-select"
                                     components={{MenuList}}
                                     formatOptionLabel={formatOptionLabel}
                                     getOptionLabel={(option) => `${option.account} ${option.bank_id}`}
                                     getOptionValue={(option) => option.account}
                                     value={null}
                                     onChange={onChangeHandler}
                                     onInputChange={onInputChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShow(false)}>{t(lang, 'roaming.common.close')}</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
};

export default SelectBankAccount;