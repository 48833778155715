import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {toast} from "react-toastify";
import EventBus from '../../../../app/eventbus/EventBus';
import {selectLang, Translate} from '../../../../app/store/reducers/main/mainReducer';
import ContractFormPage from "./ContractFormPage";
import {registerContractAsync} from "../../../../app/store/reducers/contract/contractReducer";
import dayjs from "dayjs";
import {REGISTER_CONTRACT_FAILED, REGISTER_CONTRACT_SUCCEED} from "../../../../app/eventbus/contractEvents";
import {useHistory} from "react-router-dom";

const SendContractFormPage = () => {
    const lang = useSelector(selectLang)
    const t = Translate
    const [loading, setLoading] = useState(false);
    const routerHistory = useHistory();

    const onSubmit = (data) => {
        setLoading(true)
        let notEmptyProducts = data.products.filter(product => {
            return product.name
        })
        let notEmptyParts = data.parts.filter(part => {
            return part.title
        })
        registerContractAsync({
            info: {
                name: data.contractDoc.contractName,
                number: data.contractDoc.contractNo,
                date: dayjs(data.contractDoc.contractDate).format("YYYY-MM-DD") ?? null,
                expire_date: data.contractDoc.contractExpireDate ? dayjs(data.contractDoc.contractExpireDate).format("YYYY-MM-DD") : null,
                place: data.contractDoc.contractPlace
            },
            executor: {
                inn: data.owner.tin,
                name: data.owner.name,
                address: data.owner.address,
                work_phone: data.owner.workPhone,
                mobile: data.owner.mobile,
                fax: data.owner.fax,
                oked: data.owner.oked,
                account: data.owner.account,
                mfo: data.owner.bankId,
                director_inn: data.owner.fizTin,
                director_name: data.owner.fio,
                branch_code: data.owner.branchCode,
                branch_name: data.owner.branchName,
                role: data.owner.role
            },
            customer: {
                inn: data.client.tin,
                name: data.client.name,
                address: data.client.address,
                work_phone: data.client.workPhone,
                mobile: data.client.mobile,
                fax: data.client.fax,
                oked: data.client.oked,
                account: data.client.account,
                mfo: data.client.bankId,
                director_inn: data.client.fizTin,
                director_name: data.client.fio,
                branch_code: data.client.branchCode,
                branch_name: data.client.branchName,
                role: data.client.role
            },
            items: notEmptyProducts.map((product, index) => ({
                name: product.name,
                barcode: product.barCode,
                measurement_id: product.measureId,
                quantity: +product.count,
                price: +product.summa,
                total: +product.deliverySum,
                nds_rate: +product.vatRate,
                nds_value: +product.vatSum,
                total_with_nds: +product.deliverySumWithVat,
                catalog_code: product.catalogCode,
                catalog_name: product.catalogName,
                without_vat: product.withoutVat
            })),
            parts: notEmptyParts.map((part, index) => ({
                title: part.title,
                body: part.body
            })),
            notes: data.notes
        }).then((res) => {
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }
    useEffect(() => {
        const contractRegisterSuccess = EventBus.on(REGISTER_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            routerHistory.push("/crm/contracts")
        });
        const contractRegisterError = EventBus.on(REGISTER_CONTRACT_FAILED, (data) => {
            toast.error(t(lang, data.message))
        });

        return () => {
            EventBus.remove(REGISTER_CONTRACT_SUCCEED, contractRegisterSuccess)
            EventBus.remove(REGISTER_CONTRACT_FAILED, contractRegisterError)
        }
    }, [])
    return (
        <ContractFormPage onSubmit={onSubmit} loading={loading}/>
    );
};

export default SendContractFormPage;