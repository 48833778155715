import {toast} from "react-toastify";
import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import SelectCertificate from "../../eimzo/SelectCertificate";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    activateCertificateAsync,
    selectCurrentCertificate,
    setCurrentCertificate
} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/IconButton";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const ConfirmCertificate = ({submitButton, openDialogButtonText, permission, ...props}) => {
    const submitButtonId = submitButton?.props?.id;
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;
    const handleClose = () => setShow(false);

    const handleShow = () => {
        if (permission) {
            if (dispatch(checkPermission(permission))) setShow(true)
            else dispatch(toastPermission())
        } else {
            setShow(true)
        }
    };

    const onSelectCertificate = certificate => {
        activateCertificateAsync(certificate)
            .then(activatedCertificate => {
                dispatch(setCurrentCertificate(activatedCertificate));
                handleClose();
                submitButtonId && document.getElementById(submitButtonId).click();
                toast.success('Certificate success activated');
            })
            .catch(e => {
                toast.error('Error')
                console.log(e)
            });
    }

    return (
        <>
            {currentCertificate
                ? <>{submitButton}</>
                : <IconButton
                    size="sm"
                    icon={faSignature}
                    iconClassName="me-1"
                    variant={"falcon-primary"}
                    // className="ms-2 mb-2 mb-sm-0"
                    onClick={handleShow}
                    {...props}
                >
                    {openDialogButtonText}
                </IconButton>
            }

            <Modal backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'roaming.components.select_certificate.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectCertificate
                        onChange={onSelectCertificate}
                        placeholder={t(lang, 'roaming.components.select_certificate.title')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ConfirmCertificate;
