import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    EDIT_CATEGORY_FAILED,
    EDIT_CATEGORY_REQUESTED,
    EDIT_CATEGORY_SUCCESS
} from "../../../../app/eventbus/itemEvents";
import {editCategoryAsync, loadCategoryAsync} from "../../../../app/store/reducers/category/categoryReducer";
import {toast} from "react-toastify";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import CategoryForm from "./forms/CategoryForm";

const withEditCategory = (WrappedComponent) => {
    return ({categoryId, ...props}) => {
        const t = Translate;
        const lang = useSelector(selectLang);
        const form = useForm({
            defaultValues: {
                name: '',
                parentId: '',
                images: []
            }
        });

        const [category, setCategory] = useState(null);
        const [show, setShow] = useState(false);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            form.setValue('name', category?.name || null);
            form.setValue('parentId', category?.parent_id || null);
            if (category?.image) {
                const images = [];
                images.push({
                    id: category.image.id,
                    name: category.image.name,
                    path: category.image.path
                })
                form.setValue('images', images)
            }
        }, [category])

        const handleShow = async () => {
            setCategory(await loadCategoryAsync(categoryId));
            setShow(true);
        };
        const handleClose = () => setShow(false);
        const onSubmit = (formData) => {
            EventBus.dispatch(EDIT_CATEGORY_REQUESTED);
            const payload = {
                name: formData.name,
                parent_id: formData.parentId,
                image: formData.images?.length > 0 ? {
                    id: formData.images[0]?.id || null,
                    name: formData.images[0].name,
                    content: formData.images[0].content?.split(",")?.[1]
                } : null
            };
            setLoading(true);
            editCategoryAsync(category.id, payload)
                .then((category) => {
                    EventBus.dispatch(EDIT_CATEGORY_SUCCESS, category.id);
                    setLoading(false);
                    setShow(false);
                    form.reset();
                    toast.success(t(lang, 'items.common.toast.success'));
                })
                .catch((error) => {
                    EventBus.dispatch(EDIT_CATEGORY_FAILED);
                    setLoading(false);
                    toast.error(t(lang, 'items.common.toast.error'));
                    if (error.category_exists) {
                        form.setError('name', {
                            type: 'server',
                            message: t(lang, 'items.common.validations.category_name_exists', {name: payload.name})
                        })
                    }
                })
        }

        return (
            <React.Fragment>
                <WrappedComponent handleShow={handleShow} {...props}/>
                {show &&
                    <Modal show={show}
                           size="lg"
                           onHide={handleClose}
                    >
                        <FormProvider {...form}>
                            <Form onSubmit={form.handleSubmit(onSubmit)}>
                                <Modal.Header>
                                    <Modal.Title>{t(lang, 'items.common.edit_category')}</Modal.Title>
                                    <FalconCloseButton onClick={handleClose}/>
                                </Modal.Header>
                                <Modal.Body>
                                    <CategoryForm />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary"
                                            onClick={handleClose}
                                    >
                                        {t(lang, 'items.common.cancel')}
                                    </Button>
                                    <Button variant="primary"
                                            type="submit"
                                            disabled={loading}
                                    >
                                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                                        {t(lang, 'items.common.save')}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </FormProvider>
                    </Modal>}
            </React.Fragment>
        )
    }
};

export default withEditCategory;
