export const REGISTER_INVOICE_REQUESTED = 'REGISTER_INVOICE_REQUESTED';
export const REGISTER_INVOICE_SUCCESS = 'REGISTER_INVOICE_SUCCESS';
export const REGISTER_INVOICE_FAILED = 'REGISTER_INVOICE_FAILED';
export const DELETE_INVOICE_REQUESTED = 'DELETE_INVOICE_REQUESTED';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILED = 'DELETE_INVOICE_FAILED';
export const CANCEL_INVOICE_REQUESTED = 'CANCEL_INVOICE_REQUESTED';
export const CANCEL_INVOICE_SUCCESS = 'CANCEL_INVOICE_SUCCESS';
export const CANCEL_INVOICE_FAILED = 'CANCEL_INVOICE_FAILED';
export const DECLINE_INVOICE_REQUESTED = 'DECLINE_INVOICE_REQUESTED';
export const DECLINE_INVOICE_SUCCESS = 'DECLINE_INVOICE_SUCCESS';
export const DECLINE_INVOICE_FAILED = 'DECLINE_INVOICE_FAILED';
export const SIGN_INVOICE_REQUESTED = 'SIGN_INVOICE_REQUESTED';
export const SIGN_INVOICE_SUCCESS = 'SIGN_INVOICE_SUCCESS';
export const SIGN_INVOICE_FAILED = 'SIGN_INVOICE_FAILED';

