import React, {useEffect} from "react";
import {Card} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    loadNotificationsAsync,
    selectNotificationsData,
} from "../../../app/store/reducers/telegram-notification/telegramNotificationReducer";
import EventBus from "../../../app/eventbus/EventBus";
import {
    REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_FAILED,
    REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_SUCCESS,
    UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_FAILED,
    UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_SUCCESS
} from "../../../app/eventbus/notificationEvents";
import {toast} from "react-toastify";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import {
    NotificationRegister,
    NotificationDelete,
    NotificationUpdate,
    NotificationTelegramModal
} from "../../../components/notification";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";


const Notification = () => {
    const lang = useSelector(selectLang)
    const t = Translate
    const dispatch = useDispatch()

    const data = useSelector(selectNotificationsData)

    useEffect(() => {
        const onRegisterHandlerSuccess = EventBus.on(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_SUCCESS, () => {
            toast.success('Success')
            dispatch(loadNotificationsAsync())
        })

        const onRegisterHandlerFailed = EventBus.on(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_FAILED, () => {
            toast.error("Error")
        })

        const onUpdateHandlerSuccess = EventBus.on(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_SUCCESS, () => {
            toast.success('Success')
            dispatch(loadNotificationsAsync())
        })
        const onUpdateHandlerFailed = EventBus.on(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_FAILED, () => {
            toast.error('Error')
        })

        return () => {
            EventBus.remove(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_SUCCESS, onRegisterHandlerSuccess)
            EventBus.remove(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_FAILED, onRegisterHandlerFailed)
            EventBus.remove(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_SUCCESS, onUpdateHandlerSuccess)
            EventBus.remove(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_FAILED, onUpdateHandlerFailed)
        }
    }, []);

    useEffect(() => {
        dispatch(loadNotificationsAsync())
    }, []);

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 10,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'phoneNumber',
            Header: t(lang, "notification.table.phone_number"),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.phone_number}</span>
                );
            }
        },
        {
            accessor: 'actions',
            Header: t(lang, "notification.table.actions"),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                return (
                    <div>
                        <NotificationUpdate notification={original}/>
                        <NotificationDelete notification={original}/>
                    </div>
                );
            }
        }
    ]

    return (
        <>
            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={data}
            >
                <Card className="mb-3">
                    <Card.Header className="d-flex align-items-start justify-content-between">
                       <NotificationTelegramModal />
                        <NotificationRegister/>
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default Notification;