import React, {Fragment, useState} from 'react';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import {
    changeBoardFilter,
    deleteBoard,
    selectBoards,
    selectBoardsCount,
    selectBoardsFilter
} from "../../../app/store/reducers/kanban/kanbanReducer";
import AdvanceTablePagination from "../../common/advance-table/AdvanceTablePagination";
import KanbanDatatableHeader from "./KanbanDatatableHeader";
import BoardModal from "../modal/board/BoardModal";
import DeleteBoard from "../modal/board/DeleteBoard";
import IconButton from "../../common/IconButton";
import AdvanceTableCustomPagination from "../../common/advance-table/AdvanceTableCustomPagination";
import {boardTypeName} from "../../../enum/KanbanWrapper";

const KanbanDatatable = () => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const dispatch = useDispatch();

    const boards = useSelector(selectBoards);
    const count = useSelector(selectBoardsCount);
    const filter = useSelector(selectBoardsFilter);
    const lang = useSelector(selectLang);
    const t = Translate;

    const [editShowModal, setEditShowModal] = useState(false);
    const [editId, setEditId] = useState(null);

    const onHideEditModal = () => {
        setEditShowModal(false);
        setEditId(null);
    }

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "task.board.datatable.title"),
            headerProps: {className: 'pe-7'},
            cellProps: {
                className: 'fw-bold fs-0 text-uppercase',
            },
            Cell: ({row: {original}}) => {
                return (
                    <Link className={'text-decoration-none'} to={`/kanban/board/${original?.id}`}>
                        <strong>{t(lang, boardTypeName(original.type) || original?.name)}</strong>
                    </Link>
                );
            }
        },
        {
            accessor: 'created_at',
            id: "created_at",
            Header: t(lang, "admin.organization.view.active_sessions.session.created_at"),
            cellProps: {
                minwidth: '100px',
                className: 'fw-bold fs-0',
            },
            Cell: rowData => {
                const {created_at} = rowData?.row?.original
                return created_at && dayjs(created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'none',
            cellProps: {
                className: 'text-end',
            },
            Cell: ({row: {original}})=> (
                <div className={'d-flex align-items-center gap-2'}>
                    <BoardModal
                        variant={'falcon-warning'}
                        icon={'edit'}
                        title={'task.board.common.edit'}
                        data={original}
                        id={original?.id}
                    />
                    <IconButton
                        variant="falcon-danger"
                        size="sm"
                        icon="trash"
                        onClick={() => {
                            setEditShowModal(true);
                            setEditId(original?.id);
                        }}
                        transform="shrink-3"
                    />
                </div>
            )
        }
    ];

    const onPageChange = (page) => dispatch(changeBoardFilter({...filter, page: page}))
    const onLimitChange = (limit) => dispatch(changeBoardFilter({...filter, limit: limit, page: 1}))

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={boards}
                pagination
                perPage={10}
            >
                <Card>
                    <Card.Header className={'pb-0'}>
                        <KanbanDatatableHeader />
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filter.limit}
                            page={filter.page}
                            onPageChange={onPageChange}
                            onLimitChange={onLimitChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>


            <DeleteBoard
                show={editShowModal}
                setShow={onHideEditModal}
                id={editId}
                deleteRequest={deleteBoard}
            />
        </Fragment>
    );
};

export default KanbanDatatable;
