export const REGISTER_RETURN_ORDER_REQUESTED = "REGISTER_RETURN_ORDER_REQUESTED";
export const REGISTER_RETURN_ORDER_SUCCESS = "REGISTER_RETURN_ORDER_SUCCESS";
export const REGISTER_RETURN_ORDER_FAILED = "REGISTER_RETURN_ORDER_FAILED";
export const CUSTOMER_APPROVE_RETURN_ORDER_REQUESTED = 'CUSTOMER_APPROVE_RETURN_ORDER_REQUESTED';
export const CUSTOMER_APPROVE_RETURN_ORDER_SUCCEED = 'CUSTOMER_APPROVE_RETURN_ORDER_SUCCEED';
export const CUSTOMER_APPROVE_RETURN_ORDER_FAILED = 'CUSTOMER_APPROVE_RETURN_ORDER_FAILED';
export const EXECUTOR_APPROVE_RETURN_ORDER_REQUESTED = 'EXECUTOR_APPROVE_RETURN_ORDER_REQUESTED';
export const EXECUTOR_APPROVE_RETURN_ORDER_SUCCEED = 'EXECUTOR_APPROVE_RETURN_ORDER_SUCCEED';
export const EXECUTOR_APPROVE_RETURN_ORDER_FAILED = 'EXECUTOR_APPROVE_RETURN_ORDER_FAILED';
export const EXECUTOR_DECLINE_RETURN_ORDER_REQUESTED = 'EXECUTOR_DECLINE_RETURN_ORDER_REQUESTED';
export const EXECUTOR_DECLINE_RETURN_ORDER_SUCCEED = 'EXECUTOR_DECLINE_RETURN_ORDER_SUCCEED';
export const EXECUTOR_DECLINE_RETURN_ORDER_FAILED = 'EXECUTOR_DECLINE_RETURN_ORDER_FAILED';
export const EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_REQUESTED = 'EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_REQUESTED';
export const EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_SUCCEED = 'EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_SUCCEED';
export const EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_FAILED = 'EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_FAILED';
export const RETURN_ORDER_STOP_REQUESTED = 'RETURN_ORDER_STOP_REQUESTED';
export const RETURN_ORDER_STOP_SUCCEED = 'RETURN_ORDER_STOP_SUCCEED';
export const RETURN_ORDER_STOP_FAILED = 'RETURN_ORDER_STOP_FAILED';
export const RETURN_ORDER_RESUMED = 'RETURN_ORDER_RESUMED'

export const RETURN_ORDER_IDENTIFY_ITEMS_REQUESTED = 'RETURN_ORDER_IDENTIFY_ITEMS_REQUESTED'
export const RETURN_ORDER_IDENTIFY_ITEMS_SUCCEED = 'RETURN_ORDER_IDENTIFY_ITEMS_SUCCEED';
export const RETURN_ORDER_IDENTIFY_ITEMS_FAILED = 'RETURN_ORDER_IDENTIFY_ITEMS_FAILED';