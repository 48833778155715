import {PaymentTypeCashCode} from "../ContractorWrapper";

export const totalGroupAmounts = (amounts = []) => {
    const groupedSum = {}
    const groupItems = []
    amounts.map(item => {
        if (!groupedSum[item?.money_type]) {
            groupedSum[item.money_type] = {
                [item.currency.id]: {
                    total: item.amount,
                    money_type: item.money_type,
                    currency_id: item.currency.id,
                    name: item.currency.name
                }
            }
        } else if (groupedSum[item?.money_type]) {
            if ([item.currency.id] in groupedSum[item?.money_type]) {
                groupedSum[item?.money_type][item.currency.id].total += item.amount
            } else {
                groupedSum[item.money_type][item.currency.id] = {
                    total: item.amount,
                    money_type: item.money_type,
                    currency_id: item.currency.id,
                    name: item.currency.name
                }
            }
        }
    })
    Object.values(groupedSum).map(i => {
        for (const iElement in i) {
            groupItems.push(i[iElement])
        }
    })
    return groupItems
}

export const totalGroupCashAmount = (amounts = []) => {
    const groupedSums = {}
    amounts.forEach(debt => {
        if (!groupedSums[debt?.currency?.id]) {
            groupedSums[debt?.currency?.id] = {
                total: 0,
                currency_id: debt?.currency?.id,
                name: debt.currency?.name
            }
        }
        groupedSums[debt?.currency?.id].total += debt?.amount
    })
    return Object.values(groupedSums) || []
}
export const totalAmountInCashBox = (data, currency, bool) => {
    let amount = 0
    if (currency.length) {
        if (!bool) {
            data.map((item) => {
                item.amounts.forEach(debt => {
                    amount += debt.amount * currency.find(f => f.id === debt.currency.id)?.rate
                })
            })
        } else {
            data.forEach(debt => {
                amount += debt.amount * currency.find(f => f.id === debt.currency.id)?.rate
            })
        }
    }
    return isNaN(amount) ? 0 : amount
}


export const totalAmountCashInCashBox = (data) => {
    const arr = []
    if (Array.isArray(data)) {
        data.forEach((item) => {
            item.amounts.filter(i => i.money_type === PaymentTypeCashCode).forEach(amount => arr.push(amount))
        })
    } else {
        data?.amounts.filter(i => i.money_type === PaymentTypeCashCode).forEach(amount => arr.push(amount))
    }

   return  totalGroupCashAmount(arr)

}

export const totalAmountCardInCashBox = (data, filter) => {
    const arr = []
    if (Array.isArray(data)) {
        data.forEach((item) => {
            item.amounts.filter(i => (i.money_type !== PaymentTypeCashCode && i.money_type) && (i.money_type !== null)).forEach(amount => arr.push(amount))
        })
    } else {
        data?.amounts?.filter(i => (i.money_type === filter && i.money_type) && (i.money_type !== null)).forEach(amount => arr.push(amount))
    }

    return totalGroupAmounts(arr)
}


export const totalAmountCash = (data, currency) => {
    let amount = 0
    if (currency.length) {
        data.map((item) => {
            item.amounts.filter(i => i.money_type === PaymentTypeCashCode).forEach(debt => {
                amount += debt.amount * currency.find(f => f.id === debt.currency.id)?.rate
            })
        })

    }

    return isNaN(amount) ? 0 : amount

}

export const totalAmountCard = (data, currency) => {
    let amount = 0
    if (currency.length) {
        data.map((item) => {
            item.amounts.filter(i => i.money_type !== PaymentTypeCashCode).forEach(debt => {
                amount += debt.amount * currency.find(f => f.id === debt.currency.id)?.rate
            })
        })
    }

    return isNaN(amount) ? 0 : amount
}

export const commonTotalAmount = (data, currency) => {
    let amount = 0
    if (currency.length) {
        data.amounts.forEach(debt => {
            amount += debt.amount * currency.find(f => f.id === debt.currency.id)?.rate
        })
    }

    return isNaN(amount) ? 0 : amount
}
