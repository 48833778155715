import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {selectTelegramOrderBotScripts} from "../../../../../../app/store/reducers/crm/bot/telegramOrderBotReducer";
import ReactSelect from "react-select";

const SelectBotScript = ({onChange, botScripts, defaultValue, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const options = botScripts.map((option) => ({
        label: option,
        value: option,
    }));
    const [script, setScript] = useState(null);

    const onChangeHandler = (option) => {
        setScript(option);
        onChange(option ? option.value : null);
    };

    useEffect(() => {
        if (defaultValue) {
            if (defaultValue !== script?.value) {
                const newValue = options.find((option) => option.value === defaultValue);
                setScript(newValue);
            }
        } else {
            const [defaultScript] = botScripts;
            const newValue = options.find((option) => option.value === defaultScript);
            onChangeHandler(newValue);
        }
    }, [defaultValue, botScripts]);

    return (
        <ReactSelect options={options}
                     placeholder={t(lang, "items.common.select")}
                     getOptionLabel={(option) => option.label}
                     getOptionValue={(option) => option.value}
                     value={script}
                     onChange={onChangeHandler}
                     {...selectProps}
        />
    );
};

export default SelectBotScript;