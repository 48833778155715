import PropTypes from "prop-types";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../common/IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {executorApproveShipmentAsync} from "../../../app/store/reducers/shipment/shipmentReducer";
import checkPermission from "../../../enum/Permission/CheckPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import {PermissionEdiShipmentApprove} from "../../../enum/Permission/EdiPermission";
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";
import SelectCertificateWrapperComponent from "../roaming/components/SelectCertificateWrapperComponent";

const ExecutorApproveShipment = ({id}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeCertificate = useSelector(selectCurrentCertificate);

    const handleShow = () => {
        if(dispatch(checkPermission(PermissionEdiShipmentApprove))) setShow(true);
        else dispatch(toastPermission())
    }
    const handleClose = () => {
        setLoading(false);
        setShow(false);
    };

    const executorApprovedShipment = () => {
        setLoading(true);

        executorApproveShipmentAsync(activeCertificate, lang, {
            id: id,
            comment: comment
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };


    return (
        <>
            <SelectCertificateWrapperComponent
                submitButton={
                    <IconButton
                        id={'approve-shipment'}
                        onClick={handleShow}
                        variant="falcon-primary"
                        size="sm"
                        icon="check"
                        transform="shrink-3"
                    >
                        <span className="d-none d-sm-inline-block">{t(lang, "edi.common.approve")}</span>
                    </IconButton>
                }
                openDialogButtonText={t(lang, 'edi.common.approve')}
            />

            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "edi.shipments.shipment.approve_shipment_modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                        <Form.Control
                            name="Name"
                            as="textarea" rows={3}
                            placeholder={t(lang, 'edi.common.leave_comment')}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        {t(lang, "edi.common.cancel_button")}
                    </Button>

                    <Button variant="primary" className="d-flex align-items-center" disabled={loading} onClick={executorApprovedShipment}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, "edi.common.approve")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

ExecutorApproveShipment.propTypes = {
    id: PropTypes.string,
};

ExecutorApproveShipment.defaultProps = {
    id: '',
};

export default ExecutorApproveShipment;
