import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Col, Form, InputGroup, Modal, Row, Table} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import {faPencil, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import MarkProductTypeSelector from "./MarkProductTypeSelector";
import {ForGroup, ForTransport, ForUsage, markPackageOptions} from "../../../../../enum/RoamingInvoiceWrapper";
import MarkPackageTypeSelector from "./MarkPackageTypeSelector";
import classNames from "classnames";

const RoamingInvoiceFormItemMarkLinkSelector = ({
                                                    itemFieldName,
                                                    index,
                                                    item
                                                }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const [show, setShow] = useState(false);

    const {
        fields: kiz,
        append: addKiz,
        remove: removeKiz
    } = useFieldArray({name: `${itemFieldName}.mark.kiz`});
    const {
        fields: nomUpak,
        append: addNomUpak,
        remove: removeNomUpak
    } = useFieldArray({name: `${itemFieldName}.mark.nomUpak`});
    const {
        fields: identTransUpak,
        append: addIdentTransUpak,
        remove: removeIdentTransUpak
    } = useFieldArray({name: `${itemFieldName}.mark.identTransUpak`});

    const addKizItem = () => addKiz({code: ''});
    const addNomUpakItem = () => addNomUpak({code: ''});
    const addIdentTransUpakItem = () => addIdentTransUpak({code: ''});

    const removeKizItem = (index) => removeKiz(index);
    const removeNomUpakItem = (index) => removeNomUpak(index);
    const removeIdentTransUpakItem = (index) => removeIdentTransUpak(index);

    const validateIfIsMarked = () => {
        const values = form.getValues(`${itemFieldName}.mark`);
        if (values.productType) {
            const kizHasAnyMarkCode = values.kiz?.some((mark) => !!mark.code);
            const nomUpakHasAnyMarkCode = values.nomUpak?.some((mark) => !!mark.code);
            const identTransUpakHasAnyMarkCode = values.identTransUpak?.some((mark) => !!mark.code);
            if (kizHasAnyMarkCode || nomUpakHasAnyMarkCode || identTransUpakHasAnyMarkCode) return true;
        }
        return false;
    };
    const isMarked = validateIfIsMarked();

    return (
        <>
            <IconButton icon={faPencil}
                        variant={isMarked ? 'falcon-success' : 'falcon-primary'}
                        style={{height: '40px'}}
                        onClick={() => setShow(true)}
            >
                {isMarked ? t(lang, 'roaming.invoice.items.hasMark') : t(lang, 'roaming.common.select.placeholder')}
            </IconButton>
            {show &&
                <Modal show={true}
                       centered={true}
                       size="xl"
                       onHide={() => setShow(false)}
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'roaming.invoice.mark')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Table responsive striped bordered className="border-300">
                                    <thead className="bg-200 text-dark">
                                    <tr>
                                        <th className="p-0 align-middle text-center">№</th>
                                        <th className="p-0 align-middle text-center">{t(lang, 'roaming.common.items.mark_type')}</th>
                                        <th className="p-0 align-middle text-center">{t(lang, 'roaming.common.items.mark_package_type')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="p-0 align-middle text-center">{index}</td>
                                        <td className="align-middle text-center">
                                            <Controller name={`${itemFieldName}.mark.productType`}
                                                        render={({field}) => (
                                                            <MarkProductTypeSelector defaultProductType={item?.mark?.productType}
                                                                                     onChange={field.onChange}
                                                                                     selectProps={{
                                                                                         menuPortalTarget: document.body,
                                                                                         styles: {
                                                                                             menuPortal: (provided) => ({
                                                                                                 ...provided,
                                                                                                 zIndex: 9999
                                                                                             })
                                                                                         }
                                                                                     }}
                                                            />
                                                        )}
                                            />
                                        </td>
                                        <td>
                                            <Table responsive>
                                                <tbody>
                                                {markPackageOptions.map((option, index) => (
                                                    <tr key={option.value}>
                                                        <td className="p-0 align-middle">
                                                            <MarkPackageTypeSelector defaultPackageType={option.value}
                                                                                     selectProps={{
                                                                                         isDisabled: true
                                                                                     }}
                                                            />
                                                        </td>
                                                        {option.value === ForUsage &&
                                                            <td>
                                                                {kiz?.map((field, index) => (
                                                                    <InputGroup key={field.id} className={classNames({'mb-2': kiz.length > 1})}>
                                                                        <Controller name={`${itemFieldName}.mark.kiz.${index}.code`}
                                                                                    render={({field}) => (
                                                                                        <Form.Control name={`${itemFieldName}.mark.kiz.${index}.code`}
                                                                                                      value={item?.mark?.kiz?.[index]?.code}
                                                                                                      onChange={(e) => field.onChange(e.target.value)}
                                                                                        />
                                                                                    )}
                                                                        />
                                                                        {kiz.length > 1 &&
                                                                            <IconButton icon={faTrash}
                                                                                        variant={'danger'}
                                                                                        title={t(lang, 'roaming.invoice.delete')}
                                                                                        onClick={() => removeKizItem(index)}
                                                                            />
                                                                        }
                                                                        <IconButton icon={faPlus}
                                                                                    variant={'success'}
                                                                                    title={t(lang, 'roaming.common.add')}
                                                                                    onClick={addKizItem}
                                                                        />
                                                                    </InputGroup>
                                                                ))}
                                                            </td>
                                                        }
                                                        {option.value === ForGroup &&
                                                            <td>
                                                                {nomUpak?.map((field, index) => (
                                                                    <InputGroup key={field.id} className={classNames({'mb-2': nomUpak.length > 1})}>
                                                                        <Controller name={`${itemFieldName}.mark.nomUpak.${index}.code`}
                                                                                    render={({field}) => (
                                                                                        <Form.Control name={`${itemFieldName}.mark.nomUpak.${index}.code`}
                                                                                                      value={item?.mark?.nomUpak?.[index]?.code}
                                                                                                      onChange={(e) => field.onChange(e.target.value)}
                                                                                        />
                                                                                    )}
                                                                        />
                                                                        {nomUpak.length > 1 &&
                                                                            <IconButton icon={faTrash}
                                                                                        variant={'danger'}
                                                                                        title={t(lang, 'roaming.invoice.delete')}
                                                                                        onClick={() => removeNomUpakItem(index)}
                                                                            />
                                                                        }
                                                                        <IconButton icon={faPlus}
                                                                                    variant={'success'}
                                                                                    title={t(lang, 'roaming.common.add')}
                                                                                    onClick={addNomUpakItem}
                                                                        />
                                                                    </InputGroup>
                                                                ))}
                                                            </td>
                                                        }
                                                        {option.value === ForTransport &&
                                                            <td>
                                                                {identTransUpak?.map((field, index) => (
                                                                    <InputGroup key={field.id} className={classNames({'mb-2': identTransUpak.length > 1})}>
                                                                        <Controller name={`${itemFieldName}.mark.identTransUpak.${index}.code`}
                                                                                    render={({field}) => (
                                                                                        <Form.Control name={`${itemFieldName}.mark.identTransUpak.${index}.code`}
                                                                                                      value={item?.mark?.identTransUpak?.[index]?.code}
                                                                                                      onChange={(e) => field.onChange(e.target.value)}
                                                                                        />
                                                                                    )}
                                                                        />
                                                                        {identTransUpak.length > 1 &&
                                                                            <IconButton icon={faTrash}
                                                                                        variant={'danger'}
                                                                                        title={t(lang, 'roaming.invoice.delete')}
                                                                                        onClick={() => removeIdentTransUpakItem(index)}
                                                                            />
                                                                        }
                                                                        <IconButton icon={faPlus}
                                                                                    variant={'success'}
                                                                                    title={t(lang, 'roaming.common.add')}
                                                                                    onClick={addIdentTransUpakItem}
                                                                        />
                                                                    </InputGroup>
                                                                ))}
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShow(false)}>
                            {t(lang, 'roaming.common.cancel_button')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
};

export default RoamingInvoiceFormItemMarkLinkSelector;