import React, {useState} from "react";
import {Col, Form, InputGroup} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";
import classNames from "classnames";
import {getBankNameAsync} from "../../../../../app/store/reducers/roaming/roamingReducer";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import SelectBankAccount from "../../../../common/SelectBankAccount";
import {selectBankInfo} from "../../../../../app/store/reducers/invoice/invoiceReducer";

const ReturnInvoiceMember = ({rootName}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const storageBankInfo = useSelector(selectBankInfo);
    const {register, formState: {errors}, setValue} = useFormContext();
    const [company, setCompany] = useState(null);

    const onChangeCompany = async (company) => {
        setCompany(company);
        if(company) {
            setValue(`${rootName}.address`, company.address);
            setValue(`${rootName}.oked`, company.oked);
            setValue(`${rootName}.ndsCode`, company.vat_reg_code);

            if (!company.bank_accounts || !company.bank_accounts?.length) {
                if (storageBankInfo && storageBankInfo[company.inn]) {
                    const info = storageBankInfo[company.inn];
                    setValue(`${rootName}.bankName`, info.name);
                    setValue(`${rootName}.mfo`, info.mfo);
                    setValue(`${rootName}.account`, info.account);
                }
            } else {
                setValue(`${rootName}.account`, company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''))
                setValue(`${rootName}.mfo`, company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''))
            }

            try {
                if (company.mfo) {
                    const bankInfo = await getBankNameAsync({mfo: company.mfo, lang});
                    setValue(`${rootName}.bankName`, bankInfo.name);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };
    const onChangeBankAccount = (bankAccount) => {
        if (bankAccount) {
            setValue(`${rootName}.account`, bankAccount.account);
            setValue(`${rootName}.mfo`, bankAccount.bank_id);
        } else {
            setValue(`${rootName}.account`, '');
            setValue(`${rootName}.mfo`, '');
        }
    };

    return (
        <>
            <Col xs={12} sm={6} className="mb-2">
                <Form.Label>
                    {t(lang, "edi.common.inn")}
                    <span className={'text-danger'}>*</span>
                </Form.Label>
                <Controller name={`${rootName}.inn`}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            }}
                            render={({field}) => (
                                <FindCompanySearchable defaultInn={field.value}
                                                       onChange={company => {
                                                           onChangeCompany(company);
                                                           field.onChange(company ? company.inn : null);
                                                       }}
                                                       disabled={true}
                                                       inputGroupProps={{
                                                           className: classNames({
                                                               'is-invalid': errors?.[rootName]?.inn,
                                                               'is-valid': Object.keys(errors).length > 0 && !errors?.[rootName]?.inn
                                                           })
                                                       }}
                                />
                            )}
                />
            </Col>

            <Col xs={12} sm={6} className="mb-2">
                <Form.Label>
                    {t(lang, "edi.common.organization")}
                    <span className={'text-danger'}>*</span>
                </Form.Label>
                <Form.Control name={`${rootName}.name`}
                              type="text"
                              disabled
                              isInvalid={errors?.[rootName]?.name}
                              isValid={Object.keys(errors).length > 0 && !errors?.[rootName]?.name}
                              {...register(`${rootName}.name`, {
                                  required: t(lang, "edi.common.forms.validations.is_required")
                              })}
                />
                <Form.Control.Feedback type="invalid">{errors?.[rootName]?.name?.message}</Form.Control.Feedback>
            </Col>

            <Col xs={12} sm={6} className="mb-2">
                <Form.Label>{t(lang, "edi.common.address")}</Form.Label>
                <Form.Control name={`${rootName}.address`}
                              type="text"
                              placeholder={t(lang, "edi.common.address.placeholder")}
                              isValid={Object.keys(errors).length > 0 && !errors?.[rootName]?.address}
                              {...register(`${rootName}.address`)}
                />
            </Col>
            <Col xs={12} sm={6} className="mb-2">
                <Form.Label>{t(lang, "edi.common.bank_info.name")}</Form.Label>
                <Form.Control name={`${rootName}.bankName`}
                              type="text"
                              placeholder={t(lang, "edi.common.bank_info.name.placeholder")}
                              isValid={Object.keys(errors).length > 0 && !errors?.[rootName]?.bankName}
                              {...register(`${rootName}.bankName`)}
                />
            </Col>

            <Col xs={12} sm={6} className="mb-2">
                <Form.Label>
                    {t(lang, "edi.common.bank_info.checking_account")}
                    <span className={'text-danger'}>*</span>
                </Form.Label>
                <InputGroup className={classNames({'is-invalid': errors?.[rootName]?.account})}>
                    <Form.Control name={`${rootName}.account`}
                                  type="text"
                                  placeholder={t(lang, "edi.common.bank_info.checking_account.placeholder")}
                                  isValid={Object.keys(errors).length > 0 && !errors?.[rootName]?.account}
                                  isInvalid={errors?.[rootName]?.account}
                                  {...register(`${rootName}.account`, {
                                      required: t(lang, 'edi.common.forms.validations.is_required')
                                  })}                    />
                    <SelectBankAccount bankAccounts={company?.bank_accounts || []}
                                       onChange={onChangeBankAccount}
                    />
                </InputGroup>
                <Form.Control.Feedback type="invalid">{errors?.[rootName]?.account?.message}</Form.Control.Feedback>
            </Col>
            <Col xs={12} sm={6} className="mb-2">
                <Form.Label>
                    {t(lang, "edi.common.bank_info.mfo")}
                    <span className={'text-danger'}>*</span>
                </Form.Label>
                <Form.Control name={`${rootName}.mfo`}
                              type="text"
                              placeholder={t(lang, "edi.common.bank_info.mfo.placeholder")}
                              isValid={Object.keys(errors).length > 0 && !errors?.[rootName]?.mfo}
                              isInvalid={errors?.[rootName]?.account}
                              {...register(`${rootName}.mfo`, {
                                  required: t(lang, 'edi.common.forms.validations.is_required')
                              })}
                />
                <Form.Control.Feedback type="invalid">{errors?.[rootName]?.mfo?.message}</Form.Control.Feedback>
            </Col>

            <Col xs={12} sm={6} className="mb-2">
                <Form.Label>{t(lang, "edi.common.bank_info.oked")}</Form.Label>
                <Form.Control name={`${rootName}.oked`}
                              type="text"
                              placeholder={t(lang, "edi.common.bank_info.oked.placeholder")}
                              isValid={Object.keys(errors).length > 0 && !errors?.[rootName]?.oked}
                              {...register(`${rootName}.oked`)}
                />
            </Col>
            <Col xs={12} sm={6}>
                <Form.Label>{t(lang, "edi.common.bank_info.nds_code")}</Form.Label>
                <Form.Control name={`${rootName}.ndsCode`}
                              type="text"
                              placeholder={t(lang, "edi.common.bank_info.nds_code.placeholder")}
                              isValid={Object.keys(errors).length > 0 && !errors?.[rootName]?.ndsCode}
                              {...register(`${rootName}.ndsCode`)}
                />
            </Col>
        </>
    )
};

export default ReturnInvoiceMember;
