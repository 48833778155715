import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectExchangeTypes} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const RoamingInvoiceFormExchangeTypeSelector = ({defaultExchangeType, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const exchangeTypes = useSelector(selectExchangeTypes);

    return (
        <ReactSelect options={exchangeTypes}
                     placeholder={t(lang, "roaming.common.select.placeholder")}
                     getOptionLabel={option => `${option.name} (${option.prefix})`}
                     getOptionValue={option => option.prefix}
                     isClearable
                     onChange={option => onChange(option ? option.prefix : null)}
                     value={exchangeTypes.find(o => o.prefix === defaultExchangeType)}
        />
    );
};

export default RoamingInvoiceFormExchangeTypeSelector;