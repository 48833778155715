import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import ReturnOrder from "../../../../components/hippo/return-order/ReturnOrder";
import {loadReturnOrderAsync} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    CUSTOMER_APPROVE_RETURN_ORDER_SUCCEED,
    EXECUTOR_APPROVE_RETURN_ORDER_SUCCEED,
    EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_SUCCEED,
    EXECUTOR_DECLINE_RETURN_ORDER_SUCCEED, RETURN_ORDER_IDENTIFY_ITEMS_SUCCEED, RETURN_ORDER_STOP_SUCCEED
} from "../../../../app/eventbus/returnOrderEvents";
import {RETURN_ORDER_RESUMED} from "../../../../app/eventbus/returnOrderEvents";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {getReturnOrderProtocolInfoAsync} from "../../../../app/store/reducers/protocol/protocolReducer";
import {setEdiReturnBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {useHistory} from "react-router-dom";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnOrderView} from "../../../../enum/Permission/EdiPermission";

const ViewReturnOrder = () => {
    const {params: {id}} = useRouteMatch();
    const [order, setOrder] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const history = useHistory()
    const t = Translate;
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiReturnOrderView))) {
            if (history.length > 1) {
                history.push('../return-orders')
            } else {
                history.push('/')
            }
        }
    }, [permission])


    useEffect(() => {
        const onCustomerApprovedReturnedOrderHandler = EventBus.on(CUSTOMER_APPROVE_RETURN_ORDER_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.return_order_customer_approve_succeed'));
            dispatch(getReturnOrderProtocolInfoAsync(id));
            loadReturnOrder();
        });

        const onExecutorApprovedReturnedOrderHandler = EventBus.on(EXECUTOR_APPROVE_RETURN_ORDER_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.return_order_executor_approve_succeed'));
            dispatch(getReturnOrderProtocolInfoAsync(id));
            loadReturnOrder();
        });

        const onExecutorDeclinedReturnOrderHandler = EventBus.on(EXECUTOR_DECLINE_RETURN_ORDER_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.return_order_decline_succeed'));
            dispatch(getReturnOrderProtocolInfoAsync(id));
            loadReturnOrder();
        });

        const onExecutorCanceledDeclineReturnOrderHandler = EventBus.on(EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.return_order_cancel_decline_succeed'));
            dispatch(getReturnOrderProtocolInfoAsync(id));
            loadReturnOrder();
        });

        const onReturnOrderStoppedHandler = EventBus.on(RETURN_ORDER_STOP_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.return_order_stop_succeed'));
            dispatch(getReturnOrderProtocolInfoAsync(id));
            loadReturnOrder();
        });

        const onReturnOrderResumedHandler = EventBus.on(RETURN_ORDER_RESUMED, () => {
            toast.success(t(lang, 'edi.toast.return_order_resume_succeed'));
            dispatch(getReturnOrderProtocolInfoAsync(id));
            loadReturnOrder();
        });

        const onReturnOrderIdentifyItemsHandler = EventBus.on(RETURN_ORDER_IDENTIFY_ITEMS_SUCCEED, () => {
            toast.success(t(lang, 'edi.common.toast.success'));
            dispatch(getReturnOrderProtocolInfoAsync(id));
            loadReturnOrder();
        });


        return () => {
            EventBus.remove(CUSTOMER_APPROVE_RETURN_ORDER_SUCCEED, onCustomerApprovedReturnedOrderHandler);
            EventBus.remove(EXECUTOR_APPROVE_RETURN_ORDER_SUCCEED, onExecutorApprovedReturnedOrderHandler);
            EventBus.remove(EXECUTOR_DECLINE_RETURN_ORDER_SUCCEED, onExecutorDeclinedReturnOrderHandler);
            EventBus.remove(EXECUTOR_CANCELED_DECLINE_RETURN_ORDER_SUCCEED, onExecutorCanceledDeclineReturnOrderHandler);
            EventBus.remove(RETURN_ORDER_STOP_SUCCEED, onReturnOrderStoppedHandler);
            EventBus.remove(RETURN_ORDER_RESUMED, onReturnOrderResumedHandler);
            EventBus.remove(RETURN_ORDER_IDENTIFY_ITEMS_SUCCEED, onReturnOrderIdentifyItemsHandler);
        }
    }, [])

    useEffect(() => {
        loadReturnOrder()
    }, [id, activeOrganization])

    const loadReturnOrder = () => {
        loadReturnOrderAsync(id).then(response => {
            dispatch(checkPermission(PermissionEdiReturnOrderView)) && dispatch(setEdiReturnBreadcrumbData(response))
            setOrder(response);
        }).catch(error => console.log(error))
    }

    return (
        <>
            {order && <ReturnOrder returnOrder={order}/>}
            {!order && <span>loading....</span>}
        </>
    );
};

export default ViewReturnOrder;
