import React, {Fragment} from 'react';
import IconButton from "../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {invoiceRegisterAsync, selectProductCatalogs} from "../../../app/store/reducers/invoice/invoiceReducer";
import dayjs from "dayjs";
import {getOrganizationInfoAsync} from "../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {
    AfterConfirmByCustomer,
    OnShipmentWaybillCreation
} from "../../../app/order/OrderExecutorCreateInvoiceStrategyType";
import {CustomerApproved, CustomerEditApprovedByExecutor, ExecutorApproved} from "../../../enum/ShipmentStatus";
import {roamingInvoiceItemOriginTypes} from "../../../enum/RoamingInvoiceWrapper";
import {TYPE_CREATE_BULK_INVOICE} from "../../../app/eventbus/shipmentEvents";

const CreateBulkInvoice = ({
                               shipments,
                               handleShowProgressBar,
                               updateActionResult,
                               ...props
                           }) => {

    const lang = useSelector(selectLang)
    const t = Translate
    const activeOrganization = useSelector(selectActiveOrganization);
    const storageCatalogs = useSelector(selectProductCatalogs);


    const allowCreateInvoice = (shipment) => {
        return activeOrganization.inn === shipment.executor_info.inn &&
            (
                (
                    shipment.order.executor_create_invoice_strategy === OnShipmentWaybillCreation.id && (
                        // shipment.status === AwaitExecutorApprove ||
                        shipment.status === ExecutorApproved ||
                        // shipment.status === ExecutorEdited ||
                        shipment.status === CustomerApproved ||
                        shipment.status === CustomerEditApprovedByExecutor
                        // shipment.status === CustomerEdited
                    )
                ) ||
                (
                    shipment.order.executor_create_invoice_strategy === AfterConfirmByCustomer.id && (
                        shipment.status === CustomerApproved ||
                        shipment.status === CustomerEditApprovedByExecutor
                    )
                )
            )

    }

    const actionResult = (payload) => {
        updateActionResult(payload);
    }

    const invoicePayload = async (shipment) => {
        const executorCompany = await getOrganizationInfoAsync(shipment?.executor_info?.inn)
        const customerCompany = await getOrganizationInfoAsync(shipment?.customer_info?.inn)

        return  {
            shipment_id: shipment.id,
            invoice_info: {
                number: shipment.shipment_info.number,
                date: dayjs(shipment.shipment_info.date).format("YYYY-MM-DD"),
            },
            customer: {
                inn: shipment.customer_info.inn,
                name: shipment.customer_info.name,
                address: customerCompany?.address || null,
                bank_info: {
                    account: '',
                    mfo: customerCompany?.mfo || null,
                    oked: customerCompany?.oked || null,
                }
            },
            executor: {
                inn: shipment.executor_info.inn,
                name: shipment.executor_info.name,
                address: executorCompany?.address || null,
                bank_info: {
                    account: '',
                    oked: executorCompany?.oked || null,
                    mfo: executorCompany?.mfo || null,
                },
            },
            items: shipment.items.map(item => {
                return {
                    shipment_item_id: item.id,
                    measurement: item.measurement || null,
                    barcode: item.barcode || null,
                    catalog_class_code: item.catalog_class_code || null,
                    catalog_class_name: item.catalog_class_name || null,
                    package_code: item.package_code ? item.package_code.toString() : null,
                    package_name: item.package_name || null,
                    benefit: item.benefitId || null,
                    benefit_name: item.benefitName || null,
                    benefit_type: +item.benefitType || null,
                    benefit_vat_sum: +item.benefitPrice || 0,
                    origin: storageCatalogs[item.product]?.origin || roamingInvoiceItemOriginTypes[0].value
                }
            }),
        }
    }

    const updateActionResultPayload = (shipment) => {
        return {
            number: shipment?.contract_info?.number,
            date: shipment?.contract_info?.date,
            contractor: {
                name: shipment?.customer_info?.name,
                inn: shipment?.customer_info?.inn
            }
        }
    }

    const createInvoices = async () => {
        handleShowProgressBar(TYPE_CREATE_BULK_INVOICE);
        let data = []

        for (let i = 0; i < shipments.length; i++) {
            const shipment = shipments[i];
            const createInvoice = allowCreateInvoice(shipment)

            if (createInvoice) {
                const payload = await invoicePayload(shipment)

                data.push(payload)
            }
        }

        const taskSize = 10;
        const tasks = []

        const requestInCreateInvoice = async (payload) => {
            try {
                await invoiceRegisterAsync(payload)
                    .then((res) => actionResult(updateActionResultPayload(res.shipment)))
                    .catch((error) => actionResult({...updateActionResultPayload(payload),  error: error?.message}))
            } catch (error) {
                actionResult({...updateActionResultPayload(payload),  error: error?.message})
            }
        }

        for (let i = 0; i < data.length; i++) {
            tasks.push(requestInCreateInvoice(data[i]))

            if (tasks.length >= taskSize) {
                await Promise.all(tasks)
                tasks.splice(0, tasks.length)
            }
        }

        if (tasks.length > 0) {
            await Promise.all(tasks)
            tasks.splice(0, tasks.length)
        }

    }

    return (
        <Fragment>
            <IconButton
                onClick={createInvoices}
                variant="falcon-success"
                size="sm"
                icon="plus"
                transform="shrink-3"
            >
                <span className="d-none d-sm-inline-block">{t(lang, "edi.common..bulk,new_invoice_button")}</span>
            </IconButton>
        </Fragment>
    );
};

export default CreateBulkInvoice;
