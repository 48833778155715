import React from 'react';
import AsyncSelect from 'react-select/async';
import {useSelector} from "react-redux";
import {selectCompanyCatalogs} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const formatOptionLabel = (option) => {
    return (
        <div className="fs--1">
            <span className="text-black fw-bold me-1">{option.class_code}</span>-
            <span className="ms-1 text-facebook">{option.name}</span>
        </div>
    )
}

const SelectOrganizationCatalog = ({onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const catalogs = useSelector(selectCompanyCatalogs);

    const loadOptions = (inputValue, callback) => {
        const cleanInputValue = `${inputValue}`.trim()
        const options = (catalogs || []).filter(option => {
            if (cleanInputValue.length === 0) return true;

            const lowerInputValue = `${cleanInputValue}`.toLowerCase()
            const lowerName = `${option.name}`.toLowerCase()
            const lowerClassCode = `${option.class_code}`.toLowerCase()

            const parts = lowerInputValue.split(' ').filter(x => x.length > 0)
            return parts.length === parts.filter(x => {
                if (lowerClassCode === lowerInputValue) return true;
                if (lowerName.includes(x)) return true;
                if (lowerClassCode.includes(lowerInputValue)) return true;

                return false;
            }).length
        }).slice(0, 10)
        callback(options);
    };

    return (
        <AsyncSelect isClearable
                     defaultOptions={(catalogs || []).slice(0, 10)}
                     loadOptions={loadOptions}
                     hideSelectedOptions
                     getOptionLabel={option => option.class_code}
                     getOptionValue={option => `${option.class_code} ${option.name}`}
                     formatOptionLabel={formatOptionLabel}
                     classNamePrefix="select"
                     placeholder={t(lang, 'items.common.select')}
                     onChange={onChange}
                     value={null}
                     {...selectProps}
        />
    );
};

export default SelectOrganizationCatalog;