import { Card, Col, Form, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useFormContext, Controller } from "react-hook-form"
import { Translate, selectLang } from "../../../../../app/store/reducers/main/mainReducer"
import { selectActiveOrganization } from "../../../../../app/store/reducers/auth/authReducer"
import FindCompany from "../../../organization/FindCompany"
import FindPerson from "../../../person/FindPerson";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";


const OwnerInfo = () => {
    const { register, setValue, formState: { errors }, control } = useFormContext()
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang)
    const t = Translate
    const onChangeHandler = async data => {
        if(data) {
            setValue('ownerTin', data.person_num || data.inn)
            setValue('ownerName', data.name)
            setValue('ownerFizTin', data.director_pinfl || data.director_inn)
            setValue('ownerFizFio', data.director)
        }
    }

    const onChangeDirectorHandler = (director) => {
        if(director) {
            setValue('ownerFizTin', director.pinfl || director.inn, {shouldValidate: true})
            setValue('ownerFizFio', director.name, {shouldValidate: true})
        } else {
            setValue('ownerFizTin', '')
            setValue('ownerFizFio', '')
        }
    }
    return (
        <Card>
            <Card.Header>
                <h5 className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.owner_info.title")}</h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.owner_info.inn_or_pinfl")}</Form.Label>
                        <Controller
                            name="ownerTin"
                            control={control}
                            render={() => (
                                <FindCompanySearchable
                                    defaultInn={activeOrganization.pinfl || activeOrganization.inn}
                                    onChange={onChangeHandler}
                                    disabled={true}
                                    inputProps={{
                                        placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.ownerTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.owner_info.name")}</Form.Label>
                        <Form.Control
                            {...register("ownerName", {
                                required: "edi.common.forms.validations.is_required"
                            })}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.owner_info.name")}
                            className={errors?.ownerName?.message && "is-invalid"}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.ownerName?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.owner_info.person_inn_or_pinfl")}</Form.Label>
                        <Controller
                            name="ownerFizTin"
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindPerson
                                    onChange={onChangeDirectorHandler}
                                    defaultInnOrPinfl={field.value}
                                    inputProps={{
                                        className: errors?.ownerFizTin?.message && 'is-invalid',
                                        placeholder: t(lang, "roaming.verification_act.add.verification_act.owner_info.person_inn_or_pinfl")
                                    }}
                                    inputGroupProps={{
                                        className: errors?.ownerFizTin?.message && 'is-invalid'
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.ownerFizTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.owner_info.person_name")}</Form.Label>
                        <Form.Control
                            {...register("ownerFizFio", {
                                required: "edi.common.forms.validations.is_required"
                            })}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.owner_info.person_name")}
                            className={errors?.ownerFizFio?.message && "is-invalid"}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.ownerFizFio?.message)}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default OwnerInfo
