import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, ListGroup, ProgressBar, Row, Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { selectLang, Translate } from "../../../../app/store/reducers/main/mainReducer";
import { useSelector } from "react-redux";
import {
    clearCacheAsync, ikpuCodeAsync,
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import { toast } from "react-toastify";
import SoftBadge from "../../../../components/common/SoftBadge";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { importRoamingInvoiceAsync } from '../../../../app/store/reducers/roaming/roamingInvoiceReducer';

const ControlCompanyRoaming = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loadingClearCache, setLoadingClearCache] = useState(false)
    const [loadingIkpu, setLoadingIkpu] = useState(false)
    const [loading, setLoading] = useState(false)
    const [countId, setCountId] = useState(0)
    const [countSuccessId, setCountSuccessId] = useState(0)
    const [countFailureId, setCountFailureId] = useState(0)
    const [failedIdArray, setFailedIdArray] = useState([])
    const [insertArray, setInsertArray] = useState([])
    const [progressPercentage, setProgressPercentage] = useState(0)

    const methodsImportRoaming = useForm({
        defaultValues: {
            invoiceIdText: "",
            executorInn: "",
            customerInn: ""
        }
    })
    const methodsClearCache = useForm({
        defaultValues: {
            companyRoamingInn: "",
        }
    })

    const methodsIkpuCache = useForm({
        defaultValues: {
            ikpuCode: ""
        }
    })

    const onSubmitRoamingImport = async ({ invoiceIdText, executorInn, customerInn }) => {
        invoiceIdText = invoiceIdText.trim()
        if (!invoiceIdText) return

        const insertIdArr = invoiceIdText.split('\n')
        setInsertArray(insertIdArr)
        setLoading(true)

        let tempArray = []
        for (let i = 0; i < insertIdArr.length; i++) {
            await importRoamingInvoiceAsync(insertIdArr[i].trim(), executorInn, customerInn)
                .then(() => setCountSuccessId((prevState) => prevState + 1))
                .catch((error) => {
                    new Error(error)
                    setCountFailureId((prevState) => prevState + 1)
                    tempArray.unshift(insertIdArr[i])
                })
        }
        setLoading(false)
        setFailedIdArray(tempArray)
    }

    const onSubmitClearCache = ({ companyRoamingInn }) => {
        setLoadingClearCache(true)
        clearCacheAsync(companyRoamingInn)
            .then((response) => {
                methodsClearCache.reset()
                toast.success(t(lang, "admin.control_company_roaming.send.inn.success"))
            })
            .catch((err) => {
                new Error(err)
                toast.error(t(lang, "admin.control_company_roaming.send.inn.error"))
            })
            .finally(() => setLoadingClearCache(false))
    }
    const onSubmitIkpuCache = ({ ikpuCode }) => {
        setLoadingIkpu(true)
        ikpuCodeAsync(ikpuCode)
            .then((response) => {
                methodsIkpuCache.reset()
                toast.success(t(lang, "admin.control_company_roaming.send.ikpu_code.success"))
            })
            .catch((err) => {
                new Error(err)
                toast.error(t(lang, "admin.control_company_roaming.send.ikpu_code.error"))
            })
            .finally(() => setLoadingIkpu(false))
    }

    const clearAll = (e) => {
        e.preventDefault()
        methodsImportRoaming.reset()
        setCountId(0)
        setCountSuccessId(0)
        setCountFailureId(0)
        setProgressPercentage(0)
        setFailedIdArray([])
        setInsertArray([])
    }

    useEffect(() => {
        const percentage = (((countSuccessId + countFailureId) / insertArray.length) * 100).toFixed(0)
        setProgressPercentage(percentage)
    }, [countId, countSuccessId, countFailureId, insertArray, failedIdArray]);

    const copyFailedIdList = async (e) => {
        e.stopPropagation()
        e.preventDefault()
        const newFailedIdArray = failedIdArray.join('\n')
        try {
            if (newFailedIdArray) {
                await navigator.clipboard.writeText(newFailedIdArray)
                toast.success("Text copied to Clipboard Successfully")
            } else {
                toast.error("Empty")
            }
        } catch (e) {
            toast.error("Error, Text did not copied")
        }
    }

    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} className={"mb-3"}>
                <Card>
                    <Card.Body>
                        <Card.Title>{t(lang, "admin.control_company_roaming.roaming_import.title")}</Card.Title>
                        <FormProvider {...methodsImportRoaming}>
                            <Form onSubmit={methodsImportRoaming.handleSubmit(onSubmitRoamingImport)}>
                                <Row>
                                    <Col xs={12} md={12} lg={7} xl={8}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t(lang, "admin.control_company_roaming.roaming_import.textarea_label")}</Form.Label>
                                            <Form.Control as="textarea" rows={6} type="text"
                                                placeholder={t(lang, "admin.control_company_roaming.roaming_import.textarea_placeholder")}
                                                {...methodsImportRoaming.register("invoiceIdText")}
                                            />
                                        </Form.Group>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <div className={"w-100 d-flex justify-content-between"}>
                                                        <SoftBadge bg='danger' className='me-2'>
                                                            {t(lang, "admin.control_company_roaming.roaming_import.failed_id_list")}
                                                            : {countFailureId}
                                                        </SoftBadge>
                                                        <Button onClick={copyFailedIdList}
                                                            className="mx-2 p-0 px-1"
                                                            variant="falcon-default"
                                                            icon={faCopy}
                                                            size="sm" transform="shrink-3"
                                                        />
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ListGroup style={{ maxHeight: '25vh', overflowY: "scroll" }}>
                                                        {failedIdArray.length ? failedIdArray.map((failedIdItem, index) => (
                                                            <ListGroup.Item key={index}>
                                                                id: {failedIdItem}
                                                            </ListGroup.Item>)) :
                                                            <h6 className={"text-center m-0"}>
                                                                {t(lang, "admin.control_company_roaming.roaming_import.failed_id_no_data")}
                                                            </h6>}
                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                    <Col xs={12} md={12} lg={5} xl={4}>
                                        <Form.Group>
                                            <Form.Label>{t(lang, "admin.control_company_roaming.roaming_import.executor_inn_label")}</Form.Label>
                                            <Form.Control
                                                {...methodsImportRoaming.register("executorInn")}
                                                type="text"
                                                placeholder={t(lang, "admin.control_company_roaming.roaming_import.executor_inn_placeholder")}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t(lang, "admin.control_company_roaming.roaming_import.customer_inn_label")}</Form.Label>
                                            <Form.Control
                                                {...methodsImportRoaming.register("customerInn")}
                                                type="text"
                                                placeholder={t(lang, "admin.control_company_roaming.roaming_import.customer_inn_placeholder")}
                                            />
                                        </Form.Group>
                                        <ProgressBar variant={"success"} animated now={progressPercentage}
                                            label={progressPercentage + '%'}
                                            className={"mt-3 mb-2"} />
                                        <div>
                                            <SoftBadge bg='success' className='me-1'>
                                                {t(lang, "admin.control_company_roaming.roaming_import.count_id_success")}: {countSuccessId}
                                            </SoftBadge>
                                            <SoftBadge bg='info' className='me-1'>
                                                {t(lang, "admin.control_company_roaming.roaming_import.count_id")}: {countSuccessId + countFailureId}/{insertArray.length}
                                            </SoftBadge>
                                            <SoftBadge bg='danger' className='me-1'>
                                                {t(lang, "admin.control_company_roaming.roaming_import.count_id_failure")}: {countFailureId}
                                            </SoftBadge>
                                        </div>
                                        <div
                                            className="d-flex gap-2 justify-content-end">
                                            <Button variant="danger" className="my-2 d-flex align-items-center gap-1"
                                                onClick={clearAll} size="sm">
                                                {t(lang, "admin.control_company_roaming.roaming_import.btn.clear_all")}
                                            </Button>
                                            <Button disabled={loading} variant="primary"
                                                type="submit" size="sm"
                                                className="my-2 d-flex align-items-center gap-1">
                                                {t(lang, "admin.control_company_roaming.button_submit")}
                                                {loading &&
                                                    <Spinner animation="border" size="sm"
                                                        className="align-middle" />}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </FormProvider>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4}>
                <Card>
                    <Card.Body>
                        <Card.Title>{t(lang, "admin.control_company_roaming.main_title")}</Card.Title>
                        <FormProvider {...methodsClearCache}>
                            <Form onSubmit={methodsClearCache.handleSubmit(onSubmitClearCache)}>
                                <Form.Group>
                                    <Form.Label>{t(lang, "admin.control_company_roaming.label")}</Form.Label>
                                    <Form.Control
                                        {...methodsClearCache.register("companyRoamingInn", {
                                            required: t(lang, 'roaming.validations.is_required')
                                        })}
                                        isInvalid={methodsClearCache.formState?.errors?.companyRoamingInn}
                                        type="text"
                                        placeholder={t(lang, "admin.control_company_roaming.input_placeholder")} />
                                    <Form.Control.Feedback type={'invalid'}>
                                        {methodsClearCache.formState?.errors?.companyRoamingInn?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button disabled={loadingClearCache} variant="primary" type="submit"
                                    className={"my-2"}>
                                    {t(lang, "admin.control_company_roaming.button_submit")}
                                    {loadingClearCache &&
                                        <Spinner animation="border" size="sm" className={"align-middle"} />}
                                </Button>
                            </Form>
                        </FormProvider>
                    </Card.Body>
                </Card>
                <Card className="my-3">
                    <Card.Body>
                        <Card.Title>{t(lang,"admin.control_company_roaming.ikpu_code_title")}</Card.Title>
                        <FormProvider {...methodsIkpuCache}>
                            <Form onSubmit={methodsIkpuCache.handleSubmit(onSubmitIkpuCache)}>
                                <Form.Group>
                                    <Form.Label>{t(lang, "admin.control_company_roaming.ikpu_code_title")}</Form.Label>
                                    <Form.Control
                                        {...methodsIkpuCache.register("ikpuCode", {
                                            required: t(lang, 'roaming.validations.is_required')
                                        })}
                                        isInvalid={methodsIkpuCache.formState?.errors?.ikpuCode}
                                        type="text"
                                        placeholder={t(lang, "admin.control_company_roaming.ikpu_code_placeholder")} />
                                    <Form.Control.Feedback type={'invalid'}>
                                        {methodsIkpuCache.formState?.errors?.ikpuCode?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button disabled={loadingIkpu} variant="primary" type="submit"
                                        className={"my-2"}>
                                    {t(lang, "admin.control_company_roaming.button_submit")}
                                    {loadingIkpu &&
                                        <Spinner animation="border" size="sm" className={"align-middle"} />}
                                </Button>
                            </Form>
                        </FormProvider>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default ControlCompanyRoaming;