import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../../common/advance-table/AdvanceTableFooter";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import EmpowermentDataTableHeader from "./EmpowermentDataTableHeader";
import {
    selectCountEmpowerments,
    selectEmpowerments,
    changeFilterOptionsAsync,
    selectFilterOptions, loadEmpowermentsAsync, selectIsLoading, setId, setShowModal, viewPage
} from "../../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import classNames from "classnames";
import Flex from "../../../../common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import SoftBadge from "../../../../common/SoftBadge";
import {
    EmpowermentWrapper,
    VIEWER_CUSTOMER, VIEWER_DRAFT,
    VIEWER_EXECUTOR,
    VIEWER_PERSON,
} from "../../../../../enum/EmpowermentStatus";
import PropTypes from "prop-types";
import {VIEW_DRAFT} from "../../../../../enum/TypeViewers";
import ViewModalPage from "../../components/ViewModalPage";
import ViewEmpowerment from "../../../../../pages/main/roaming/empowerment/ViewEmpowerment";
import DraftEmpowerment from "../draftEmpowerment/DraftEmpowerment";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";

const EmpowermentDataTable = ({viewer}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const empowerments = useSelector(selectEmpowerments);
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch();
    const filters = useSelector(selectFilterOptions);
    const count = useSelector(selectCountEmpowerments);
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const lang = useSelector(selectLang);
    const t = Translate;

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (viewer === VIEW_DRAFT) {
            if (ctrlKey) {
                history('/#/roaming/draft-empowerment/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }
        } else {
            if (ctrlKey) {
                history('/#/roaming/empowerment/'+id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'index',
            Header: t(lang, "roaming.dashboard.empowerments.number"),
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            Cell: ({row: {original}}) => {
                return (
                    <strong className={'cursor-pointer text-primary'} onClick={(e)=>openViewModalPage(e,original.id)}>
                        {original.info.number}
                    </strong>
                );
            }
        },
        {
            accessor: 'fullName',
            Header: viewer === VIEWER_PERSON ? t(lang, "roaming.common.customer") : t(lang, "roaming.empowerment.empowerment_person"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                const isPerson = empowermentWrapper.isPerson(activeOrganization.inn, activeOrganization.pinfl);
                return (
                    <>
                        {isPerson && <>{original.customer.name}</>}
                        {!isPerson && <>{original.person_info.full_name}</>}
                    </>
                )
            }
        },
        {
            accessor: 'inn',
            Header: t(lang, "roaming.common.inn_or_pinfl"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                const isPerson = empowermentWrapper.isPerson(activeOrganization.inn, activeOrganization.pinfl);
                return (
                    <>
                        {isPerson && <>{original.customer.pinfl || original.customer.inn}</>}
                        {!isPerson && <>{original.person_info.person_inn}</>}
                    </>
                )
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "roaming.dashboard.empowerments.contractor"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                const isPerson = empowermentWrapper.isPerson(activeOrganization.inn, activeOrganization.pinfl);
                const isCustomer = empowermentWrapper.isCustomer(activeOrganization.inn, activeOrganization.pinfl);
                const isExecutor = empowermentWrapper.isExecutor(activeOrganization.inn, activeOrganization.pinfl);

                return (
                    <>
                        {(isPerson || isCustomer) && <>{original.executor.name}</>}
                        {isExecutor && <>{original.customer.name}</>}
                    </>
                )
            }
        },
        {
            accessor: 'contractor.inn',
            Header: t(lang, "roaming.common.inn_or_pinfl"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                const isPerson = empowermentWrapper.isPerson(activeOrganization.inn, activeOrganization.pinfl);
                const isCustomer = empowermentWrapper.isCustomer(activeOrganization.inn, activeOrganization.pinfl);
                const isExecutor = empowermentWrapper.isExecutor(activeOrganization.inn, activeOrganization.pinfl);

                return (
                    <>
                        {(isPerson || isCustomer) && <>{original.executor.pinfl || original.executor.inn}</>}
                        {isExecutor && <>{original.customer.pinfl || original.customer.inn}</>}
                    </>
                )
            }
        },
        viewer !== VIEWER_DRAFT && {
            accessor: 'status',
            Header: t(lang, "roaming.dashboard.empowerments.status"),
            Cell: ({row: {original}}) => {
                const empowermentWrapper = new EmpowermentWrapper(original);
                return (
                    <h5>
                        <SoftBadge bg={empowermentWrapper.GetStatusBadgeVariant(activeOrganization.inn, activeOrganization.pinfl)} className='me-2'>
                            {t(lang, empowermentWrapper.GetStatusTranslationKey(activeOrganization.inn, activeOrganization.pinfl))}
                        </SoftBadge>
                    </h5>
                )
            }
        },
    ].filter(column => typeof column === 'object' && column !== null)

    useEffect(() => {
        dispatch(loadEmpowermentsAsync(viewer, {viewer_inn: activeOrganization.pinfl || activeOrganization.inn, ...filters}));
    }, [viewer, activeOrganization, filters]);

    const onPageChange = (page) => {
        dispatch(changeFilterOptionsAsync({page: page}))
    }
    const onLimitChange = (limit) => {
        dispatch(changeFilterOptionsAsync({limit: limit, page: 1}))
    }


    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Link to={`../empowerments/${VIEWER_EXECUTOR}`} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEWER_EXECUTOR})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon="arrow-down"
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames('text-primary d-none d-lg-block', {'text-white': viewer === VIEWER_EXECUTOR})}>
                                    {t(lang, "roaming.dashboard.empowerments.incomings")}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={`../empowerments/${VIEWER_CUSTOMER}`} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEWER_CUSTOMER})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon="arrow-up"
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames('text-primary d-none d-lg-block',{'text-white': viewer === VIEWER_CUSTOMER})}>
                                    {t(lang, "roaming.dashboard.empowerments.sent")}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                {activeOrganization.pinfl &&
                    <Col>
                        <Link to={`../empowerments/${VIEWER_PERSON}`} className="text-decoration-none">
                            <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEWER_PERSON})}>
                                <Flex justifyContent="center" alignItems="center">
                                    <FontAwesomeIcon
                                        icon="user-circle"
                                        className={classNames("fs-2 me-lg-2")}
                                        color="primary"
                                    />
                                    <Card.Title className={classNames('text-primary d-none d-lg-block',{'text-white': viewer === VIEWER_PERSON})}>
                                        {t(lang, "roaming.dashboard.empowerments.contragent")}
                                    </Card.Title>
                                </Flex>
                            </Card>
                        </Link>
                    </Col>
                }
                <Col>
                    <Link to={`../empowerments/${VIEWER_DRAFT}`} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEWER_DRAFT})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon="file-archive"
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames('text-primary d-none d-lg-block', {'text-white': viewer === VIEWER_DRAFT})}>
                                    {t(lang, "roaming.act.datatable.viewer.draft")}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
            </Row>
           <AdvanceTableWrapper
                columns={columns}
                data={empowerments}
                selectionColumnWidth={40}
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <EmpowermentDataTableHeader viewer={viewer} table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            isLoading={isLoading}
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter table/>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}

                            onPageChange={onPageChange}
                            onLimitChange={onLimitChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewEmpowerment modalId={id} />}
                {draftId && <DraftEmpowerment modalId={draftId} />}
            </ViewModalPage>

        </>
    );
};

EmpowermentDataTable.propTypes = {
    viewer: PropTypes.string
}

EmpowermentDataTable.defaultProps = {
    viewer: ''
}

export default EmpowermentDataTable;
