import ContractorNoteForm from "./ContractorNoteForm";
import React, {useState} from "react";
import {registerContractorNoteAsync} from "../../../../../../app/store/reducers/contractor/contractorNoteReducer";
import {useSelector} from "react-redux";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";

const ContractorAddNoteForm = () => {
    const contractor = useSelector(selectCardContractor)
    const [loading, setLoading] = useState(false)
    const handleOnSubmit = (formData) => {
        setLoading(true)
        registerContractorNoteAsync({
            contractor_id: contractor?.id,
            comment: formData.note
        }).then(res => {
            setLoading(false)
        }).catch(errors => {
            setLoading(false)
        })
    }
    return (<>
        <ContractorNoteForm onSubmit={handleOnSubmit} loading={loading}/>
    </>)
}
export default ContractorAddNoteForm