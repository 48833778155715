// EDI ORDER
export const PermissionEdiOrderView = 300
export const PermissionEdiOrderCreate = 301
export const PermissionEdiOrderApprove = 302
export const PermissionEdiOrderReject = 303
export const PermissionEdiOrderDownloadExcel = 304

// EDI SHIPMENT
export const PermissionEdiShipmentView = 400
export const PermissionEdiShipmentCreate = 401
export const PermissionEdiShipmentUpdate = 402
export const PermissionEdiShipmentApprove = 403
export const PermissionEdiShipmentReject = 404
export const PermissionEdiShipmentDelete = 405
export const PermissionEdiShipmentDownloadExcel = 406

// EDI INVOICE
export const PermissionEdiInvoiceView = 500
export const PermissionEdiInvoiceCreate = 501
export const PermissionEdiInvoiceApprove = 503
export const PermissionEdiInvoiceReject = 504
export const PermissionEdiInvoiceDelete = 505
export const PermissionEdiInvoiceDownloadExcel = 506

// EDI RETURN ORDER
export const PermissionEdiReturnOrderView = 600
export const PermissionEdiReturnOrderCreate = 601
export const PermissionEdiReturnOrderApprove = 602
export const PermissionEdiReturnOrderDownloadExcel = 603
// EDI RETURN SHIPMENT
export const PermissionEdiReturnShipmentView = 700
export const PermissionEdiReturnShipmentCreate = 701
export const PermissionEdiReturnShipmentUpdate = 702
export const PermissionEdiReturnShipmentApprove = 703
export const PermissionEdiReturnShipmentReject = 704
export const PermissionEdiReturnShipmentDelete = 705
export const PermissionEdiReturnShipmentDownloadExcel = 706
// EDI RETURN INVOICE
export const PermissionEdiReturnInvoiceView = 800
export const PermissionEdiReturnInvoiceCreate = 801
export const PermissionEdiReturnInvoiceApprove = 803
export const PermissionEdiReturnInvoiceReject = 804
export const PermissionEdiReturnInvoiceDelete = 805
export const PermissionEdiReturnInvoiceDownloadExcel = 806
// EDI ATTORNEY
export const PermissionEdiAttorneyView = 900
export const PermissionEdiAttorneyCreate = 901
export const PermissionEdiAttorneySign = 902
export const PermissionEdiAttorneyDelete = 903


// CONTRACT FORMATION
export const PermissionContractFormationView = 200;
export const PermissionContractFormationCreate = 201
export const PermissionContractFormationSign = 203
export const PermissionContractFormationUpdate = 202

export const EdiDefaultPermissions = [
    {
        text: 'edi.permission.orders',
        permissions: [
            {
                text: 'system.permission.edi.order.view',
                value: PermissionEdiOrderView
            },
            {
                text: 'system.permission.edi.order.create',
                value: PermissionEdiOrderCreate
            },
            {
                text: 'system.permission.edi.order.approve',
                value: PermissionEdiOrderApprove
            },
            {
                text: 'system.permission.edi.order.reject',
                value: PermissionEdiOrderReject
            },
            {
                text: 'system.permission.edi.order.download_excel',
                value: PermissionEdiOrderDownloadExcel
            },
        ]
    },
    {
        text: 'edi.permission.shipments',
        permissions: [
            {
                text: 'system.permission.edi.shipment.view',
                value: PermissionEdiShipmentView
            },
            {
                text: 'system.permission.edi.shipment.create',
                value: PermissionEdiShipmentCreate
            },
            {
                text: 'system.permission.edi.shipment.update',
                value: PermissionEdiShipmentUpdate
            },
            {
                text: 'system.permission.edi.shipment.approve',
                value: PermissionEdiShipmentApprove
            },
            {
                text: 'system.permission.edi.shipment.reject',
                value: PermissionEdiShipmentReject
            },
            {
                text: 'system.permission.edi.shipment.delete',
                value: PermissionEdiShipmentDelete
            },
            {
                text: 'system.permission.edi.shipment.download_excel',
                value: PermissionEdiShipmentDownloadExcel
            },
        ]
    },
    {
        text: 'edi.permission.invoices',
        permissions: [
            {
                text: 'system.permission.edi.invoice.view',
                value: PermissionEdiInvoiceView
            },
            {
                text: 'system.permission.edi.invoice.create',
                value: PermissionEdiInvoiceCreate
            },
            {
                text: 'system.permission.edi.invoice.approve',
                value: PermissionEdiInvoiceApprove
            },
            {
                text: 'system.permission.edi.invoice.reject',
                value: PermissionEdiInvoiceReject
            },
            {
                text: 'system.permission.edi.invoice.delete',
                value: PermissionEdiInvoiceDelete
            },
            {
                text: 'system.permission.edi.invoice.download_excel',
                value: PermissionEdiInvoiceDownloadExcel
            },
        ]
    },
    {
        text: 'edi.permission.return_orders',
        permissions: [
            {
                text: 'system.permission.edi.return_order.view',
                value: PermissionEdiReturnOrderView
            },
            {
                text: 'system.permission.edi.return_order.create',
                value: PermissionEdiReturnOrderCreate
            },
            {
                text: 'system.permission.edi.return_order.approve',
                value: PermissionEdiReturnOrderApprove
            },
            {
                text: 'system.permission.edi.return_order.download_excel',
                value: PermissionEdiReturnOrderDownloadExcel
            }
        ]
    },
    {
        text: 'edi.permission.return_shipments',
        permissions: [
            {
                text: 'system.permission.edi.return_shipment.view',
                value: PermissionEdiReturnShipmentView
            },
            {
                text: 'system.permission.edi.return_shipment.create',
                value: PermissionEdiReturnShipmentCreate
            },
            {
                text: 'system.permission.edi.return_shipment.update',
                value: PermissionEdiReturnShipmentUpdate
            },
            {
                text: 'system.permission.edi.return_shipment.approve',
                value: PermissionEdiReturnShipmentApprove
            },
            {
                text: 'system.permission.edi.return_shipment.reject',
                value: PermissionEdiReturnShipmentReject
            },
            {
                text: 'system.permission.edi.return_shipment.delete',
                value: PermissionEdiReturnShipmentDelete
            },
            {
                text: 'system.permission.edi.return_shipment.download_excel',
                value: PermissionEdiReturnShipmentDownloadExcel
            },
        ]
    },
    {
        text: 'edi.permission.return_invoices',
        permissions: [
            {
                text: 'system.permission.edi.return_invoice.view',
                value: PermissionEdiReturnInvoiceView
            },
            {
                text: 'system.permission.edi.return_invoice.create',
                value: PermissionEdiReturnInvoiceCreate
            },
            {
                text: 'system.permission.edi.return_invoice.approve',
                value: PermissionEdiReturnInvoiceApprove
            },
            {
                text: 'system.permission.edi.return_invoice.reject',
                value: PermissionEdiReturnInvoiceReject
            },
            {
                text: 'system.permission.edi.return_invoice.delete',
                value: PermissionEdiReturnInvoiceDelete
            },
            {
                text: 'system.permission.edi.return_invoice.download_excel',
                value: PermissionEdiReturnInvoiceDownloadExcel
            },
        ]
    },
    {
        text: 'edi.permission.attorneys',
        permissions: [
            {
                text: 'system.permission.edi.attorney.view',
                value: PermissionEdiAttorneyView
            },
            {
                text: 'system.permission.edi.attorney.create',
                value: PermissionEdiAttorneyCreate
            },
            {
                text: 'system.permission.edi.attorney.sign',
                value: PermissionEdiAttorneySign
            },
            {
                text: 'system.permission.edi.attorney.delete',
                value: PermissionEdiAttorneyDelete
            }
        ]
    },
    {
        text: 'edi.permission.contracts_formation',
        permissions: [
            {
                text: 'system.permission.contract_formation.view',
                value: PermissionContractFormationView
            },
            {
                text: 'system.permission.contract_formation.create',
                value: PermissionContractFormationCreate
            },
            {
                text: 'system.permission.contract_formation.sign',
                value: PermissionContractFormationSign
            },
            {
                text: 'system.permission.contract_formation.update',
                value: PermissionContractFormationUpdate
            }
        ]
    },
];
