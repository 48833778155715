import React from 'react';
import organizationBindingWrapper from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import Error403 from "../../../errors/Error403";
import OwnerOrganizationSalesDataTable from "../sales/OwnerOrganizationSalesDataTable";

const ViewSupervisorSalesDataTable = ({binding}) => {
    const isViewSalesDisabled = organizationBindingWrapper.isAllSalesDisabled(binding.sales_access_type);
    const isViewSalePaymentsEnabled = organizationBindingWrapper.isSalePaymentAccessEnabled(binding.sales_payment_access_type);

    if (isViewSalesDisabled)
        return <Error403/>;

    return (
        <OwnerOrganizationSalesDataTable organizationBinding={binding}
                                         isViewSalePaymentsEnabled={isViewSalePaymentsEnabled}
        />
    )
};

export default ViewSupervisorSalesDataTable;