import {Button, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.uz";
import {Controller, useFormContext} from "react-hook-form";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";



const AddUserStepOne = ({close}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {formState: {errors}, control, register} = useFormContext()
    const [openClose] = useState(false)

    const handleClose = () => {
        close(openClose)
    };

    return (
        <>
            <Form.Group>
                <Form.Label>{t(lang,"users.modal.placeholder_with_title.full_name")}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t(lang,"users.modal.placeholder_with_title.full_name")}
                    {...register('name', {
                        required: t(lang, 'edi.common.forms.validations.is_required')
                    })}
                    isInvalid={errors.name}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.name && errors.name.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="text-start mb-3">
                <Form.Label>{t(lang,"edi.attorney.datatable.header.row.number")}:</Form.Label>
                <Controller
                    control={control}
                    name='username'
                    render={({field}) => (
                        <Cleave
                            placeholder="998 XX XXX XX XX"
                            options={{delimiters: [' ', ' ', ' ', ' ', ' '], blocks: [3, 2, 3, 2, 2], numericOnly:true}}
                            onChange={(username) => field.onChange(username)}
                            className={classNames('form-control', {
                                'is-invalid': errors?.username,
                                'is-valid': Object.keys(errors).length > 0 && !errors?.username
                            })}
                        />
                    )}
                    rules={{
                        required: t(lang,"edi.common.forms.validations.is_required"),
                        minLength: {value: 16, message: t(lang, "edi.common.forms.validations.is_required")},
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.username?.message}
                </Form.Control.Feedback>
            </Form.Group>


            <Form.Group>
                <Form.Label>{t(lang,"authentication.forgot-password.new_password.placeholder")}</Form.Label>
                <Controller
                    control={control}
                    name='password'
                    render={({field}) => (
                        <input
                            type="password"
                            placeholder={t(lang,"authentication.forgot-password.new_password.placeholder")}
                            onChange={(password) => field.onChange(password)}
                            className={classNames('form-control', {
                                'is-invalid': errors?.password,
                                'is-valid': Object.keys(errors).length > 0 && !errors?.password,
                            })}
                        />
                    )}
                    rules={{
                        required: t(lang,"edi.common.forms.validations.is_required"),
                        minLength: 1
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.password?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang,"authentication.forgot-password.prev_button")}
                    </Button>
                    <Button variant="primary" type="submit">
                        {t(lang,"authentication.forgot-password.send_confirmation_code")}
                    </Button>
                </Modal.Footer>
            </Form.Group>

        </>
    )
}



export default AddUserStepOne;