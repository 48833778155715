import React, {useEffect, useState} from "react";
import {loadActAsync} from "../../../../app/store/reducers/roaming/roamingActReducer";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import Act from "../../../../components/hippo/roaming/act/View/Act";
import {
    ROAMING_ACT_ACCEPT_FAILED, ROAMING_ACT_ACCEPT_REQUESTED,
    ROAMING_ACT_ACCEPT_SUCCESS, ROAMING_ACT_CANCEL_FAILED, ROAMING_ACT_CANCEL_REQUESTED,
    ROAMING_ACT_CANCEL_SUCCESS, ROAMING_ACT_DECLINE_FAILED, ROAMING_ACT_DECLINE_REQUESTED,
    ROAMING_ACT_DECLINE_SUCCESS, ROAMING_ACT_SYNC_SUCCESS,
} from "../../../../app/eventbus/roaming/roamingActEvents";
import EventBus from "../../../../app/eventbus/EventBus"
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../app/store/reducers/main/mainReducer";
import {toast} from "react-toastify";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActView} from "../../../../enum/Permission/RoamingPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";


const ViewAct = ({modalId}) => {
    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const [act, setAct] = useState(null);
    const {params: {id}} = useRouteMatch();
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingActView))) {
            if (history.length > 1) {
                history.push('/roaming/acts/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadAct()
    }, [modalId, id, activeOrganization]);


    useEffect(() => {
        const onActAcceptRequestedHandler = EventBus.on(ROAMING_ACT_ACCEPT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
            loadAct();
        });

        const onActAcceptSucceessHandler = EventBus.on(ROAMING_ACT_ACCEPT_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"));
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
            loadAct();
        });

        const onActAcceptFailedHandler = EventBus.on(ROAMING_ACT_ACCEPT_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
            loadAct();
        });

        const onActDeclineRequestedHandler = EventBus.on(ROAMING_ACT_DECLINE_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
            loadAct()
        })

        const onActDeclineSuccessHandler = EventBus.on(ROAMING_ACT_DECLINE_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(updateShowInProgressAsync(false))
            loadAct()
        })

        const onActDeclineFailedHandler = EventBus.on(ROAMING_ACT_DECLINE_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
            loadAct()
        })

        const onActCancelRequestedHandler = EventBus.on(ROAMING_ACT_CANCEL_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
            loadAct()
        })

        const onActCancelSuccessHandler = EventBus.on(ROAMING_ACT_CANCEL_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(updateShowInProgressAsync(false))
            loadAct()
        })

        const onActCancelFailedHandler = EventBus.on(ROAMING_ACT_CANCEL_FAILED, () => {
            toast.error(t(lang, "roaming.act_form_save_error"))
            dispatch(updateShowInProgressAsync(false))
            loadAct()
        })

        const onActSyncSuccessHandler = EventBus.on(ROAMING_ACT_SYNC_SUCCESS,()=> {
            loadAct()
        })

        return () => {
            EventBus.remove(ROAMING_ACT_ACCEPT_REQUESTED, onActAcceptRequestedHandler);
            EventBus.remove(ROAMING_ACT_ACCEPT_SUCCESS, onActAcceptSucceessHandler);
            EventBus.remove(ROAMING_ACT_ACCEPT_FAILED, onActAcceptFailedHandler);
            EventBus.remove(ROAMING_ACT_DECLINE_REQUESTED, onActDeclineRequestedHandler);
            EventBus.remove(ROAMING_ACT_DECLINE_SUCCESS, onActDeclineSuccessHandler);
            EventBus.remove(ROAMING_ACT_DECLINE_FAILED, onActDeclineFailedHandler);
            EventBus.remove(ROAMING_ACT_CANCEL_REQUESTED, onActCancelRequestedHandler);
            EventBus.remove(ROAMING_ACT_CANCEL_SUCCESS, onActCancelSuccessHandler);
            EventBus.remove(ROAMING_ACT_CANCEL_FAILED, onActCancelFailedHandler);
            EventBus.remove(ROAMING_ACT_SYNC_SUCCESS, onActSyncSuccessHandler);
        }
    },[])

    const loadAct = async () => {
        const getAct = await loadActAsync(modalId ? modalId : id)
        setAct(getAct?.data)
    };


    return (
        <>
            {act && <Act modalId={modalId} act={act}/>}
            {!act && <SpinnerComponent />}
        </>
    )
}

export default ViewAct;
