import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadContractsAsync, selectContractDataTable} from "../../../../app/store/reducers/contract/contractReducer";
import ContractDataTable from "../../../../components/hippo/contract/ContractDataTable";
import EventBus from "../../../../app/eventbus/EventBus";
import {DELETE_CONTRACT_SUCCEED, EDIT_CONTRACT_SUCCEED, REGISTER_CONTRACT_SUCCEED} from "../../../../app/eventbus/contractEvents";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const Contracts = () => {
    const activeColumns = useSelector(selectContractDataTable)
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    const loadContracts = () => {
        dispatch(loadContractsAsync())
    };

    useEffect(() => {
        loadContracts();

        const onContactAddedHandler = EventBus.on(REGISTER_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.register_contract_succeed'));
            loadContracts();
        });

        const onContractEditedHandler = EventBus.on(EDIT_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.edit_contract_succeed'));
            loadContracts();
        });

        const onContractDeletedHandler = EventBus.on(DELETE_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.edit_contract_succeed'));
            loadContracts();
        });

        return () => {
            EventBus.remove(REGISTER_CONTRACT_SUCCEED, onContactAddedHandler);
            EventBus.remove(EDIT_CONTRACT_SUCCEED, onContractEditedHandler);
            EventBus.remove(DELETE_CONTRACT_SUCCEED, onContractDeletedHandler);
        }
    }, []);

    return (
        <>
            <ContractDataTable activeColumns={activeColumns}/>
        </>
    )
};

export default Contracts;
