import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";


const SelectRoamingInvoiceType = React.forwardRef(({onChange,defaultType, options, ...props}, ref) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const getOptionLabel = option => t(lang, option.label);
    const getOptionValue = option => option.value;

    const onChangeHandler = option => onChange(option ? option.value : null);

    return (
        <ReactSelect getOptionLabel={getOptionLabel}
                     getOptionValue={getOptionValue}
                     hideSelectedOptions
                     options={options}
                     defaultValue={options.find(t => t.value === defaultType)}
                     onChange={onChangeHandler}
                     ref={ref}
                     {...props}
        />
    )
});

SelectRoamingInvoiceType.propTypes = {
    onChange: PropTypes.func
}

SelectRoamingInvoiceType.defaultProps = {
    onChange: () => {}
}

export default SelectRoamingInvoiceType;