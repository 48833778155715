import React from 'react';
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ImportAttorneyDetailsForm = ({tableRef}) => {
    const {setValue, watch, formState: {errors}} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    const skipRows = watch('skipRows');

    const incrementSkipRows = () => {
        setValue('skipRows', skipRows + 1)
    };
    const decrementSkipRows = () => {
        setValue('skipRows', skipRows - 1)
    };

    const scrollToLeft = () => tableRef.current.scrollLeft += -200;
    const scrollToRight = () => tableRef.current.scrollLeft += 200;

    return (
        <Row>
            <Col xs="12" sm="6" md="auto">
                <Form.Group>
                    <Form.Label>{t(lang, 'edi.shipment.fill_from_excel.ignore_top_lines')}</Form.Label>
                    <InputGroup>
                        <Button variant="outline-secondary" onClick={decrementSkipRows}>-</Button>
                        <Controller
                            name="skipRows"
                            render={({field}) => (
                                <Form.Control
                                    type="number"
                                    value={field.value}
                                    onChange={field.onChange}
                                    className="input-spin-none"
                                />
                            )}
                        />
                        <Button variant="outline-secondary" onClick={incrementSkipRows}>+</Button>
                    </InputGroup>
                </Form.Group>
            </Col>

            <Col xs="auto" className="ms-auto align-self-end">
                <Button type="submit">{t(lang, 'edi.attorney.mass_import.import_details.synchronize')}</Button>
            </Col>

            <div className="w-100 d-flex flex-between-center my-3">
                <span className="text-danger">{errors?.columns?.message}</span>

                <div>
                    <Button variant="falcon-default" className="mx-1" onClick={scrollToLeft}>
                        <FontAwesomeIcon icon="chevron-left"/>
                        <FontAwesomeIcon icon="chevron-left"/>
                    </Button>
                    <Button variant="falcon-default" className="mx-1" onClick={scrollToRight}>
                        <FontAwesomeIcon icon="chevron-right"/>
                        <FontAwesomeIcon icon="chevron-right"/>
                    </Button>
                </div>
            </div>
        </Row>
    );
};

export default ImportAttorneyDetailsForm;