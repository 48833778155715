import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PurchaseDatatable from "../../../../warehouse-operation/purchase/datatable/PurchaseDatatable";
import {getPurchasesAsync, getPurchasesCountAsync, selectPurchaseFilterOptions} from "../../../../../../app/store/reducers/warehouse-operation/purchaseReducer";

const ContractorPurchases = ({contractorId}) => {
    const filters = useSelector(selectPurchaseFilterOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        loadContractorPurchases();
    }, [contractorId, filters])

    const predefinedFilters = useMemo(() => {
        return {
            contractor_id: contractorId,
            is_approved: null,
        };
    }, [contractorId])

    const loadContractorPurchases = () => {
        dispatch(getPurchasesAsync({...filters, ...predefinedFilters}));
        dispatch(getPurchasesCountAsync({...filters, ...predefinedFilters}));
    }

    return <PurchaseDatatable predefinedFilters={predefinedFilters}/>;
};

export default ContractorPurchases;