export const AwaitCustomerApprove = 0
export const AwaitExecutorApprove = 1
export const ExecutorEdited = 2
export const ExecutorDeclined = 3
export const ExecutorApproved = 4
export const Stopped = 5
export const Finished = 6
export const Closed = 7
export const Expired = 8
export const InProgress = 9

export const GetStatusText = status => {
    switch (status) {
        case AwaitExecutorApprove:
            return 'edi.order.status.await_customer_approve' // 'Подтверждён заказчиком'
        case ExecutorEdited:
            return 'edi.order.status.executor_edited' // 'Изменён поставщиком'
        case ExecutorDeclined:
            return 'edi.order.status.executor_declined' // 'Отклонён поставщиком'
        case ExecutorApproved:
            return 'edi.order.status.executor_approved' // 'Подтверждён поставщиком'
        case Stopped:
            return 'edi.order.status.stopped' // 'Приостановлен поставщиком'
        case Finished:
            return 'edi.order.status.finished' // 'Выполнен'
        case Closed:
            return 'edi.order.status.closed' // 'Закрыт'
        case Expired:
            return 'edi.order.status.expired' // 'Просрочен'
        case InProgress:
            return 'edi.order.status.in_progress' // 'Поставка'
        default:
            return 'edi.order.status.default' // 'Не подтверждён'
    }
}

// export const GetStatusVariant = status =>{
//     switch (status) {
//         case AwaitExecutorApprove:
//             return 'info'
//         case ExecutorEdited:
//             return 'warning'
//         case ExecutorDeclined:
//             return 'danger'
//         case ExecutorApproved:
//             return 'success'
//         case Stopped:
//             return 'secondary'
//         case Finished:
//             return 'success'
//         case Closed:
//             return 'secondary'
//         case Expired:
//             return 'warning'
//         case InProgress:
//             return 'primary'
//         default:
//             return 'dark'
//     }
// }

export const getStatusColor = status => {
    switch (status) {
        case AwaitExecutorApprove:
            return '#263238'
        case ExecutorEdited:
            return '#fb8c00'
        case ExecutorDeclined:
            return '#f44336'
        case ExecutorApproved:
            return '#4caf50'
        case Stopped:
            return '#8b8b8b'
        case Finished:
            return '#827717'
        case Closed:
            return '#1b5e20'
        case Expired:
            return '#ef6c00'
        case InProgress:
            return '#2e7d32'
        default:
            return '#607d8b'
    }
}

const OrderStatus = (id, name) => {
    return {
        id,
        name
    }
}

export const OrderStatuses = [
    OrderStatus(AwaitCustomerApprove, GetStatusText(AwaitCustomerApprove)),
    OrderStatus(AwaitExecutorApprove, GetStatusText(AwaitExecutorApprove)),
    OrderStatus(ExecutorEdited, GetStatusText(ExecutorEdited)),
    OrderStatus(ExecutorDeclined, GetStatusText(ExecutorDeclined)),
    OrderStatus(ExecutorApproved, GetStatusText(ExecutorApproved)),
    OrderStatus(Stopped, GetStatusText(Stopped)),
    OrderStatus(Finished, GetStatusText(Finished)),
    OrderStatus(Closed, GetStatusText(Closed)),
    OrderStatus(Expired, GetStatusText(Expired)),
    OrderStatus(InProgress, GetStatusText(InProgress)),
]

class OrderWrapper {
   isStopOrderAllowed(status) {
       switch (status) {
           case ExecutorApproved:
           case InProgress:
               return true;
           default:
               return false;
       }
   }
};

const orderWrapper = new OrderWrapper();
export default orderWrapper;