import React from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import SelectCategory from "../../../item/components/SelectCategory";
import {selectAllCategories} from "../../../../../app/store/reducers/category/categoryReducer";
import UploadImages from "../../../item/components/UploadImages";

const CategoryForm = ({showOnlyCategorySelect}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const categories = useSelector(selectAllCategories);
    const form = useFormContext();

    return (
        <Row className="gy-2">
            {!showOnlyCategorySelect && <Col xs={12}>
                <Form.Group>
                    <Form.Label>
                        {t(lang, 'items.common.name')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control name="name"
                                  placeholder={t(lang, 'items.common.name.placeholder')}
                                  isInvalid={form.formState.errors?.name}
                                  {...form.register('name', {
                                      required: t(lang, 'items.common.validations.is_required'),
                                  })}
                    />
                    <ErrorMessage name='name'
                                  errors={form.formState.errors}
                                  as={<Form.Control.Feedback type="invalid"/>}
                    />
                </Form.Group>
            </Col>}
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>
                        {t(lang, 'items.common.subcategory')}
                    </Form.Label>
                    <Controller name={'parentId'}
                                render={({field}) => (
                                    <SelectCategory defaultCategoryId={field.value}
                                                    categories={categories}
                                                    onChange={(option) => {
                                                        field.onChange(option ? option.id : null)
                                                    }}
                                    />
                                )}
                    />
                </Form.Group>
            </Col>
            {!showOnlyCategorySelect && <Col xs={12}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.images')}</Form.Label>
                    <Controller name={'images'}
                                render={({field}) => (
                                    <UploadImages files={field.value}
                                                  onChange={(images) => {
                                                      if (images.length > 0) {
                                                          const lastImage = [images[images.length - 1]];
                                                          field.onChange(lastImage);
                                                      } else {
                                                          field.onChange([]);
                                                      }
                                                  }}
                                                  preview
                                    />
                                )}
                    />
                </Form.Group>
            </Col>}
        </Row>
    );
};

export default CategoryForm;
