import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {FormProvider, useForm} from "react-hook-form";
import {TypeConstruction} from "../../../../../enum/RoamingInvoiceWrapper";
import {
    generateRoamingConstructionInvoiceSendHashCode,
    sendConstructionInvoiceAsync
} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import WarningAlert from "../../components/WarningAlert";
import RoamingConstructionInvoiceForm from "./RoamingConstructionInvoiceForm";
import {roamingDateDayJsFormat} from "./SendRoamingInvoiceForm";
import InProgress from "../../../../common/InProgress";

const SendRoamingConstructionInvoiceForm = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const t = Translate;
    const lang = useSelector(selectLang);
    const activeCertificate = useSelector(selectCurrentCertificate);

    const [errorMessage, setErrorMessage] = useState(null);

    const methods = useForm({
        defaultValues: {
            type: TypeConstruction,
            lotId: null,
            facturaDoc: {
                facturaNo: '',
                facturaDate: null
            },
            contractDoc: {
                contractId: null,
                contractNo: '',
                contractDate: null
            },
            investmentObject: {
                id: '',
                name: ''
            },
            executor: {
                inn: null,
                name: null,
                bankAccount: null,
                bankID: null,
                address: null,
            },
            customer: {
                inn: null,
                name: null,
                bankAccount: null,
                bankID: null,
                address: null,
            },
            technicalSupervisor: {
                inn: null,
                name: null,
                bankAccount: null,
                bankID: null,
                address: null,
            },
            priceTotal: {
                contractPricesTotally: 0,
                contractPricesCurrentYear: 0,
                completedWorksFromStart: 0,
                completedWorksCurrentYear: 0,
                completedWorksCurrentMonth: 0,
            },
            vat: {
                contractPricesTotally: 0,
                contractPricesCurrentYear: 0,
                completedWorksFromStart: 0,
                completedWorksCurrentYear: 0,
                completedWorksCurrentMonth: 0,
            },
            priceWithVat: {
                contractPricesTotally: 0,
                contractPricesCurrentYear: 0,
                completedWorksFromStart: 0,
                completedWorksCurrentYear: 0,
                completedWorksCurrentMonth: 0,
            },
            constructionsProductList: [
                {
                    name: '',
                    cipher_number: '',
                    catalog_code: '',
                    catalog_name: '',
                    measurement: '',
                    indicators: {
                        totally: 0,
                        current_year: 0
                    },
                    contract_prices: {
                        totally: 0,
                        current_year: 0
                    },
                    from_start: {
                        physically: 0,
                        percentage: 0,
                        contract_price: 0,
                    },
                    current_year: {
                        physically: 0,
                        percentage: 0,
                        contract_price: 0,
                    },
                    current_month: {
                        physically: 0,
                        percentage: 0,
                        contract_price: 0,
                    },
                }
            ]
        }
    });

    const onSend = async (formData) => {
        try {
            const roamingInvoiceHashCode = await getDocumentGeneratorConstructionInvoiceHashcode(formData)
            dispatch(updateShowInProgressAsync(true));

            sendConstructionInvoiceAsync(activeCertificate, roamingInvoiceHashCode, lang)
                .then((invoice) => {
                    dispatch(updateShowInProgressAsync(false));
                    toast.success(t(lang, "roaming.invoice.alert.success"));
                    history.push(`/roaming/invoice/${invoice.id}`)
                })
                .catch((error) => {
                    dispatch(updateShowInProgressAsync(false));
                    setErrorMessage(error.message)
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                })
        } catch (error) {
            console.log(error);
        }
    }

    const getDocumentGeneratorConstructionInvoiceHashcode = async (formData) => {
        try {
            const documentGeneratorConstructionInvoiceJson = {
                type: formData.facturaType,
                lot_id: formData.lotId,
                info: {
                    number: formData.facturaDoc.facturaNo,
                    date: dayjs(formData.facturaDoc.facturaDate).format(roamingDateDayJsFormat),
                },
                contract: {
                    number: formData.contractDoc.contractNo,
                    date: dayjs(formData.contractDoc.contractDate).format(roamingDateDayJsFormat),
                },
                investment_object: {
                    id: formData.investmentObject?.id,
                    name: formData.investmentObject?.name,
                },
                customer: {
                    inn: formData?.customer?.inn,
                    name: formData?.customer?.name,
                    bank_account: formData?.customer?.account,
                    bank_id: formData?.customer?.bankId,
                    address: formData?.customer?.address,
                },
                technical_supervisor: {
                    inn: formData?.technicalSupervisor?.inn,
                    name: formData?.technicalSupervisor?.name,
                    bank_account: formData?.technicalSupervisor?.bankAccount,
                    bank_id: formData?.technicalSupervisor?.bankId,
                    address: formData?.technicalSupervisor?.address,
                },
                price_total: {
                    contract_prices_totally: +formData?.priceTotal?.contractPricesTotally,
                    contract_prices_current_year: +formData?.priceTotal?.contractPricesCurrentYear,
                    completed_works_from_start: +formData?.priceTotal?.completedWorksFromStart,
                    completed_works_current_year: +formData?.priceTotal?.completedWorksCurrentYear,
                    completed_works_current_month: +formData?.priceTotal?.completedWorksCurrentMonth,
                },
                vat: {
                    contract_prices_totally: +formData?.vat?.contractPricesTotally,
                    contract_prices_current_year: +formData?.vat?.contractPricesCurrentYear,
                    completed_works_from_start: +formData?.vat?.completedWorksFromStart,
                    completed_works_current_year: +formData?.vat?.completedWorksCurrentYear,
                    completed_works_current_month: +formData?.vat?.completedWorksCurrentMonth,
                },
                price_with_vat: {
                    contract_prices_totally: +formData?.priceWithVat?.contractPricesTotally,
                    contract_prices_current_year: +formData?.priceWithVat?.contractPricesCurrentYear,
                    completed_works_from_start: +formData?.priceWithVat?.completedWorksFromStart,
                    completed_works_current_year: +formData?.priceWithVat?.completedWorksCurrentYear,
                    completed_works_current_month: +formData?.priceWithVat?.completedWorksCurrentMonth,
                },
                items: formData.constructionsProductList?.map((item) => (
                    {
                        name: item.name,
                        catalog_code: item.catalog_code?.class_code || null,
                        catalog_name: item.catalog_code?.class_name || null,
                        cipher_number: item.cipher_number,
                        measurement: item.measurement?.code?.toString() || item.measurement?.toString(),
                        indicators: {
                            totally: +item.indicators?.totally,
                            current_year: +item.indicators?.current_year,
                        },
                        contract_prices: {
                            totally: +item.contract_prices?.totally,
                            current_year: +item.contract_prices?.current_year,
                        },
                        from_start: {
                            physically: +item.from_start?.physically,
                            percentage: +item.from_start?.percentage,
                            contract_price: +item.from_start?.contract_price
                        },
                        current_year: {
                            physically: +item.current_year?.physically,
                            percentage: +item.current_year?.percentage,
                            contract_price: +item.current_year?.contract_price
                        },
                        current_month: {
                            physically: +item.current_month?.physically,
                            percentage: +item.current_month?.percentage,
                            contract_price: +item.current_month?.contract_price
                        },
                    }
                ))
            }

            const {hashcode} = await generateRoamingConstructionInvoiceSendHashCode(documentGeneratorConstructionInvoiceJson)
            return hashcode
        } catch (error) {
            setErrorMessage('Error while generating hashcode')
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            throw error;
        }
    }


    return (
        <Fragment>
            <InProgress/>
            {errorMessage && <WarningAlert text={errorMessage} title={'Error'} variant={'danger'}/>}
            <FormProvider {...methods}>
                <RoamingConstructionInvoiceForm onSend={methods.handleSubmit(onSend)} />
            </FormProvider>
        </Fragment>
    );
};

export default SendRoamingConstructionInvoiceForm;
