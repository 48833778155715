import React, {Fragment} from 'react';
import KanbanHeader from "./KanbanHeader";
import KanbanContainer from "./KanbanContainer";

const KanbanProvider = () => {


    return (
        <Fragment>
            <KanbanHeader/>
            <KanbanContainer loading={false}/>
        </Fragment>
    );
};

export default KanbanProvider;
