import axios from "axios";

class ExcelService {
    getTemplates() {
        return axios.get('/excel/template/get')
    }

    getOrganizationTemplates() {
        return axios.get('/excel/organization/template/get')
    }

    updateOrganizationTemplates(templates) {
        return axios.post('/excel/organization/template/update', {
            templates: templates
        });
    }
    parse(base64Content) {
        return axios.post('/excel/parse', {
            base64Content: base64Content,
        })
    }
    parseMultiSheet(base64Content) {
        return axios.post('/excel/parse-multi-sheet', {
            base64Content: base64Content,
        })
    }

    getSaleTemplate({uri, id}) {
        return axios.get(`${uri}/${id}`, {
            responseType: "blob",
        })
    }

    getPurchaseTemplate({uri, id}) {
        return axios.get(`${uri}/${id}`, {
            responseType: "blob",
        })
    }

    getRefundTemplate({uri, id}) {
        return axios.get(`${uri}/${id}`, {
            responseType: "blob",
        })
    }

    getReturnPurchaseTemplate({uri, id}) {
        return axios.get(`${uri}/${id}`, {
            responseType: "blob",
        })
    }

    getRevisionTemplate({uri, id}) {
        return axios.get(`${uri}/${id}`, {
            responseType: "blob",
        })
    }

    getWriteOffTemplate({uri, id}) {
        return axios.get(`${uri}/${id}`, {
            responseType: "blob",
        })
    }

    getTransferTemplate({uri, id}) {
        return axios.get(`${uri}/${id}`, {
            responseType: "blob",
        })
    }

    getOrderTemplate(id) {
        return axios.get(`/excel/order/crm/${id}`, {
            responseType: "blob",
        })
    }

    getTelegramOrderTemplate(id) {
        return axios.get(`/excel/telegram/order/default/${id}`, {
            responseType: "blob",
        })
    }

    getOwnerOrganizationsPeriodReport(params) {
        return axios.get('/excel/report/get-owner-organizations-period-report', {
            params: params,
            responseType: "blob",
        })
    }

    getOperationItemsPeriodReport(payload) {
        return axios.post('/excel/operation-items-report', payload, {
            responseType: "blob",
        });
    }
}

const excelService = new ExcelService();
export default excelService;
