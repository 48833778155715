import React from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useController} from "react-hook-form";
import CatalogPackageLinkSelector from "../../../common/CatalogPackageLinkSelector";
import CatalogLinkSelector from "../../../common/CatalogLinkSelector";
import BenefitLinkSelector from "../../../common/BenefitLinkSelector";
import MeasurementLinkSelector from "../../../common/MeasurementLinkSelector";
import VatRateLinkSelector from "../../../common/VatRateLinkSelector";
import ItemLegalTypeSelector from "../components/ItemLegalTypeSelector";
import classNames from "classnames";
import OriginLinkSelector from "../../../common/OriginLinkSelector";


const fieldName = "tax";
const ItemFormCardTaxIdentifications = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const {field: catalogField} = useController({name: `${fieldName}.catalog`});
    const {field: catalogPackageField} = useController({name: `${fieldName}.package`});
    const {field: measurementField} = useController({name: `${fieldName}.measurement`});
    const {field: taxRateField} = useController({name: `${fieldName}.rate`});
    const {field: benefitField} = useController({name: `${fieldName}.benefit`});
    const {field: originField}= useController({name: `${fieldName}.origin`})
    return (
        <Card className="h-100">
            <Card.Header className="border-bottom">
                <Card.Title>{t(lang, 'items.common.tax')}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row className="gy-2">
                    <Col xs={12}>
                        <Form.Group>
                            <CatalogLinkSelector defaultCatalog={catalogField.value}
                                                 onChange={(catalog) => {
                                                     catalogField.onChange(catalog);
                                                     catalogPackageField.onChange((catalog && catalog.package_names.length === 1) ? catalog.package_names[0] : null);
                                                     measurementField.onChange(null);
                                                     benefitField.onChange(null);
                                                 }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group>
                            {catalogField?.value?.use_package ?
                                <CatalogPackageLinkSelector catalogPackages={catalogField?.value?.package_names}
                                                            defaultCatalogPackage={catalogPackageField.value}
                                                            onChange={catalogPackageField.onChange}
                                />
                                :
                                <MeasurementLinkSelector defaultMeasurement={measurementField.value}
                                                         onChange={measurementField.onChange}

                                />
                            }
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group>
                            <VatRateLinkSelector defaultVatRate={taxRateField.value}
                                                 onChange={taxRateField.onChange}

                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <BenefitLinkSelector benefits={catalogField?.value?.lgotas}
                                             defaultBenefit={benefitField.value}
                                             onChange={benefitField.onChange}
                        />
                    </Col>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.item_legal_type_selector.title')}</Form.Label>
                            <Controller
                                name={'legalType'}
                                render={({field}) => (
                                    <ItemLegalTypeSelector legalType={field.value} onChange={field.onChange} />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <OriginLinkSelector defaultOriginType={originField.value}
                                            onChange={originField.onChange}
                                            linkProps={{
                                                className: classNames('text-primary cursor-pointer fs--1')
                                            }}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ItemFormCardTaxIdentifications;