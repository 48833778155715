import React, {Fragment, useState} from 'react';
import {Button, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {
    activateDesktopAppVersionAsync,
    deactivateDesktopAppVersionAsync
} from "../../../../app/store/reducers/desktop-app/desktopAppReducer";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const DesktopAppVersionActivationSwitcher = ({version, ...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onCheckedHandler = () => {
        handleShow();
    }

    const onClickHandler = () => {
        setIsLoading(true);

        if (!!version.is_active) {
            deactivateDesktopAppVersionAsync(version.id)
                .then(() => toast.success(t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.deactivate.toast.success')))
                .catch(() => toast.error(t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.deactivate.toast.failed')))
                .finally(() => setIsLoading(false))
        } else if (!version.is_active) {
            activateDesktopAppVersionAsync(version.id)
                .then(() => toast.success(t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.activate.toast.success')))
                .catch(() => toast.error(t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.activate.toast.failed')))
                .finally(() => setIsLoading(false))
        }
    }

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <Fragment>
            <OverlayTrigger overlay={
                <Tooltip>{version.is_active
                    ? t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.tooltip.deactivate')
                    : t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.tooltip.activate')
                }</Tooltip>} placement="left">

                <Form.Switch
                    {...props}
                    checked={version.is_active}
                    onChange={onCheckedHandler}
                />
            </OverlayTrigger>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{version.is_active
                        ? t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.deactivate.title')
                        : t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.activate.title')
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!version.is_active
                        ? t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.deactivate.description')
                        : t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.activate.description')
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'main.common.back')}</Button>
                    <Button variant={!!version.is_active ? 'warning' : 'success'} onClick={onClickHandler} disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                        {!!version.is_active
                            ? t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.button.deactivate')
                            : t(lang, 'admin.desktop_app_versions.switch_desktop_app_version_activation.button.activate')
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default DesktopAppVersionActivationSwitcher;