import Select from "react-select"
import React from "react";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {InputGroup} from "react-bootstrap";

const SelectCurrencyCode = ({defaultValue, onChange, ...props}) => {
    const currencies = useSelector(selectCurrency);
    const activeCurrencies = currencies.filter(cur => cur.is_active)


    return (
        <div style={{width: activeCurrencies?.length > 1 && '220px'}} {...props}>
            {activeCurrencies?.length <= 1 ?
                <InputGroup.Text className="cursor-pointer" id="basic-addon2">
                    {activeCurrencies[0]?.name}
                </InputGroup.Text>
                :
                <Select
                    value={activeCurrencies.find(c => c.id === defaultValue) || activeCurrencies[0]}
                    options={activeCurrencies}
                    isDisabled={activeCurrencies?.length <= 1}
                    hideSelectedOptions
                    onChange={(val) => onChange(val)}
                    classNamePrefix="react-select w-25"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                />
            }
        </div>
    )
}
export default SelectCurrencyCode