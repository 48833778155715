import dayjs from "dayjs";
import React from 'react';
import numeral from "numeral";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SoftBadge from "../../../../common/SoftBadge";
import {Card, OverlayTrigger, Popover} from "react-bootstrap";
import DraftRefundDataTableHeader from "./DraftRefundDataTableHeader";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {isApprovedCheckedWarehouseOperation} from "../../enum/warehouseOperationWrapper";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {
    changeDraftRefundFilterOptionsAsync,
    selectDraftRefunds,
    selectDraftRefundsCount,
    selectDraftRefundsFilterOptions
} from "../../../../../app/store/reducers/warehouse-operation/draftRefundReducer";

const DraftRefundDataTable = () => {
    const filters = useSelector(selectDraftRefundsFilterOptions);
    const draftRefunds = useSelector(selectDraftRefunds);
    const numberFormat = useSelector(selectNumberFormat);
    const count = useSelector(selectDraftRefundsCount);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const columns = [
        {
            Header: "#",
            id: "row",
            cellProps: {width: '35px'},
            Cell: ({row}) => {
                return (
                    <OverlayTrigger
                        placement={'bottom'}
                        trigger={['hover', 'focus']}
                        overlay={
                            <Popover id="popover-basic" className='mt-0'>
                                <Popover.Header>
                                    <SoftBadge className={'d-block text-center fs--1'} bg={row?.original?.is_approved ? 'success' : 'warning'}>
                                        {t(lang, isApprovedCheckedWarehouseOperation(row?.original?.is_approved))}
                                    </SoftBadge>
                                </Popover.Header>
                            </Popover>
                        }
                    >
                        <SoftBadge className={'d-block text-center fs--1 cursor-pointer'} bg={row?.original?.is_approved ? 'success' : 'warning'}>
                            {(filters.page - 1) * filters.limit + row.index + 1}
                        </SoftBadge>
                    </OverlayTrigger>
                )
            },
        },
        {
            accessor: 'number',
            Header: t(lang, "warehouse-operation.draft_refund.datatable.column.number"),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`/warehouse/operation/draft-refunds/view/${original?.id}`} className="d-flex text-wrap">
                        {original?.number}
                    </Link>
                )
            }
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "warehouse-operation.draft_refund.datatable.column.contractor"),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`/crm/contractor-card/${original?.contractor?.id}`} className="d-flex text-wrap">
                        {original?.contractor?.name}
                    </Link>
                )
            }
        },
        {
            accessor: 'net_price',
            Header: t(lang, "warehouse-operation.draft_refund.datatable.column.total"),
            Cell: ({row: {original}}) => {
                return original.net_price.map((netPrice, index) => (
                    <div className={'fs-0'} key={index}>
                        <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"info"}>
                            {numeral['formats'][numberFormat].format(netPrice.amount)}&nbsp;{netPrice.currency.name}
                        </SoftBadge>
                    </div>
                ))
            },
        },
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse-operation.draft_refund.datatable.column.created_at"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <span>{dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                );
            }
        },
    ];

    const onPageChange = (page) => dispatch(changeDraftRefundFilterOptionsAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeDraftRefundFilterOptionsAsync({...filters, limit: limit, page: 1}));

    return (
        <AdvanceTableWrapper
            perPage={1000}
            sortable={true}
            columns={columns}
            pagination={true}
            data={draftRefunds}
        >
            <Card className="mb-3">
                <Card.Header className={'bg-light'}>
                    <Card.Title>{t(lang, 'warehouse-operation.draft_refund.datatable.title')}</Card.Title>
                </Card.Header>
                <Card.Header>
                    <DraftRefundDataTableHeader/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName={'bg-200 text-900 text-wrap align-middle'}
                        rowClassName={'align-middle white-space-nowrap border-bottom border-2'}
                        tableProps={{
                            size: 'md',
                            striped: false,
                            className: 'fs--1 mb-2 overflow-hidden',
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableCustomPagination
                        count={count}
                        page={filters.page}
                        limit={filters.limit}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default DraftRefundDataTable;