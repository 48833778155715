import React from 'react';
import {Col, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import AddOrganizationBindingModal from "./AddOrganizationBindingModal";

const DataTableHeader = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Row>
            <Col xs={12}>
                <div className="d-flex justify-content-between flex-wrap">
                    <h5>{t(lang, 'partners.common.my_partners')}</h5>
                    <AddOrganizationBindingModal />
                </div>
            </Col>
        </Row>
    );
};

export default DataTableHeader;