import {toast} from "react-toastify";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {activatePackageAsync, EDI_SERVICE_CODE} from "../../../../app/store/reducers/billing/billingReducer";

const PackageActivationModal = ({children, packageId, hasSoonExpiringPackages, serviceCode}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;

    const noInnOrganization = !activeOrganization.pinfl && !activeOrganization.inn;
    const ediPackageNotAllowed = noInnOrganization && serviceCode.code === EDI_SERVICE_CODE;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const activatePackage = () => {
        setLoading(true);

        activatePackageAsync({
            packageId: packageId,
        })
            .then(() => {
                handleClose();
                toast.success(t(lang, 'main.billing.all_packages_card.activation.modal.success_activated'))
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <div className="d-flex justify-content-center" onClick={handleShow}>
                {children}
            </div>

            <Modal show={show} onHide={handleClose} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'main.billing.all_packages_card.activation.modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'text-center'}>
                    {ediPackageNotAllowed && <p className="text-danger">{t(lang, 'main.billing.all_packages_card.activation.modal.warning.not_registered_with_inn')}</p>}
                    {hasSoonExpiringPackages[serviceCode.code] && <p className="text-danger">{t(lang, 'main.billing.all_packages_card.activation.modal.warning.has_soon_expiring_package')}</p>}
                    {!hasSoonExpiringPackages[serviceCode.code] && !ediPackageNotAllowed && <p>{t(lang, 'main.billing.all_packages_card.activation.modal.warning.message')}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} variant="secondary">{t(lang, 'main.common.back')}</Button>
                    <Button onClick={activatePackage} disabled={hasSoonExpiringPackages[serviceCode.code] || ediPackageNotAllowed}>
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, 'main.common.activate')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PackageActivationModal;
