export const EMPOWERMENT_SEND_REQUESTED = "EMPOWERMENT_SEND_REQUESTED";
export const EMPOWERMENT_SEND_SUCCESS = "EMPOWERMENT_SEND_SUCCESS";
export const EMPOWERMENT_SEND_FAILED = "EMPOWERMENT_SEND_FAILED";
export const CUSTOMER_CANCEL_EMPOWERMENT_REQUESTED = "CUSTOMER_CANCEL_EMPOWERMENT_REQUESTED";
export const CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS = "CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS";
export const CUSTOMER_CANCEL_EMPOWERMENT_FAILED = "CUSTOMER_CANCEL_EMPOWERMENT_FAILED";
export const PERSON_ACCEPT_EMPOWERMENT_REQUESTED = "PERSON_ACCEPT_EMPOWERMENT_REQUESTED";
export const PERSON_ACCEPT_EMPOWERMENT_SUCCESS = "PERSON_ACCEPT_EMPOWERMENT_SUCCESS";
export const PERSON_ACCEPT_EMPOWERMENT_FAILED = "PERSON_ACCEPT_EMPOWERMENT_FAILED";
export const PERSON_DECLINE_EMPOWERMENT_REQUESTED = "PERSON_DECLINE_EMPOWERMENT_REQUESTED";
export const PERSON_DECLINE_EMPOWERMENT_SUCCESS = "PERSON_DECLINE_EMPOWERMENT_SUCCESS";
export const PERSON_DECLINE_EMPOWERMENT_FAILED = "PERSON_DECLINE_EMPOWERMENT_FAILED";
export const EXECUTOR_ACCEPT_EMPOWERMENT_REQUESTED = "EXECUTOR_ACCEPT_EMPOWERMENT_REQUESTED";
export const EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS = "EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS";
export const EXECUTOR_ACCEPT_EMPOWERMENT_FAILED = "EXECUTOR_ACCEPT_EMPOWERMENT_FAILED";
export const EXECUTOR_DECLINE_EMPOWERMENT_REQUESTED = "EXECUTOR_DECLINE_EMPOWERMENT_REQUESTED";
export const EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS = "EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS";
export const EXECUTOR_DECLINE_EMPOWERMENT_FAILED = "EXECUTOR_DECLINE_EMPOWERMENT_FAILED";
export const SYNC_EMPOWERMENT_REQUESTED = "SYNC_EMPOWERMENT_REQUESTED";
export const SYNC_EMPOWERMENT_SUCCESS = "SYNC_EMPOWERMENT_SUCCESS";
export const SYNC_EMPOWERMENT_FAILED = "SYNC_EMPOWERMENT_FAILED";
export const EMPOWERMENT_DRAFT_REQUESTED = "EMPOWERMENT_DRAFT_REQUESTED"
export const EMPOWERMENT_DRAFT_SUCCESS = "EMPOWERMENT_DRAFT_SUCCESS"
export const EMPOWERMENT_DRAFT_FAILED = "EMPOWERMENT_DRAFT_FAILED"