import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller} from "react-hook-form";
import {Form} from "react-bootstrap";

const RoamingInvoiceFormInternalActDescription = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Controller name="act.description"
                    rules={{
                        required: t(lang, 'items.common.validations.is_required'),
                    }}
                    render={({field, fieldState: {error}}) => (
                        <Form.Group>
                            <Form.Label>
                                {t(lang, "roaming.act_form_act_comment")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control as="textarea"
                                          name="act.description"
                                          placeholder={t(lang, "roaming.act_form_act_comment")}
                                          isInvalid={!!error}
                                          value={field.value}
                                          onChange={field.onChange}
                            />
                            {!!error && <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>}
                        </Form.Group>
                    )}
        />
    );
};

export default RoamingInvoiceFormInternalActDescription;