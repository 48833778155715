import React, {useEffect, useState} from 'react';
import OrderDataTable from "../../../../components/hippo/order/OrderDataTable";
import {useDispatch, useSelector} from "react-redux";
import {
    loadCountOrdersAsync,
    loadOrderDataTableColumn,
    loadOrdersAsync,
    selectFilterOptions, selectOrdersDataTable, setEdiBreadcrumbData
} from "../../../../app/store/reducers/order/orderReducer";
import {selectActiveOrganization} from '../../../../app/store/reducers/auth/authReducer';
import {selectIsEDIServiceActive} from "../../../../app/store/reducers/billing/billingReducer";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiOrderView} from "../../../../enum/Permission/EdiPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {useParams} from "react-router";
import {useViewer} from "../ViewerProvider";

const Orders = () => {
    const {viewer} = useParams()
    const filter = useSelector(selectFilterOptions)
    const isEDIServiceActive = useSelector(selectIsEDIServiceActive);
    const activeOrganization = (useSelector(selectActiveOrganization))
    const lang = useSelector(selectLang);
    const dispatch = useDispatch()
    const t = Translate;
    const activeColumns = useSelector(selectOrdersDataTable)
    // important
    useSelector(selectAccountRolePermissions)

    const loadDataTable = () => {
        dispatch(loadOrdersAsync(viewer,{...filter, viewer_inn: activeOrganization.inn}))
        dispatch(loadCountOrdersAsync(viewer,{...filter, viewer_inn: activeOrganization.inn}))
        dispatch(loadOrderDataTableColumn())
    };

    useEffect(() => {
        dispatch(setEdiBreadcrumbData(null))
    }, [])

    useEffect(() => {
        isEDIServiceActive && loadDataTable()
    }, [filter, activeOrganization, isEDIServiceActive, viewer])



    if (!isEDIServiceActive && !dispatch(checkPermission(PermissionEdiOrderView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'edi.orders.datatable.no_access.title')}</h4>
        </Card>
    }

    return <OrderDataTable activeColumns={activeColumns} loadOrders={loadDataTable}/>

};

export default Orders;
