import React from 'react';
import TelegramOrderBotOrdersDataTableFilter from "./TelegramOrderBotOrdersDataTableFilter";
import {Col, Row} from "react-bootstrap";
import Divider from "../../../../../../common/Divider";
import {selectLang, Translate} from "../../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import BulkDeleteTelegramOrderBotOrders from "../../BulkDeleteTelegramOrderBotOrders";

const TelegramOrderBotOrdersDatatableHeader = ({
                                                   table,
                                                   filters,
                                                   onFilterChange,
                                                   onDebouncedFilterChange,
                                                   onReloadData,
                                                   loading
                                               }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const selectedTelegramOrderBotOrders = table.getSelectedRowModel().rows.map(({original}) => original);

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <TelegramOrderBotOrdersDataTableFilter filters={filters}
                                                       onFilterChange={onFilterChange}
                                                       onDebouncedFilterChange={onDebouncedFilterChange}
                                                       loading={loading}
                />
            </Col>
            <Divider/>
            <Col xs={12}>
                <div className="d-flex flex-column justify-content-center flex-md-row justify-content-md-end gap-2">
                    {!!selectedTelegramOrderBotOrders.length &&
                        <BulkDeleteTelegramOrderBotOrders orders={selectedTelegramOrderBotOrders}/>}
                </div>
            </Col>
        </Row>
    )
};

export default TelegramOrderBotOrdersDatatableHeader;