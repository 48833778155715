import {Card} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import ChargeDatatableHeader from "./ChargeDatatableHeader";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";

import {Link} from "react-router-dom";
import {FindDocument} from "../../../../../enum/BillingWrapper";
import dayjs from "dayjs";

const ChargeDatatable = ({isAdmin, charges, filters, count, changeFilterChargesAsync}) => {

    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const onLimitChange = (limit) => dispatch(changeFilterChargesAsync({...filters, limit: limit, page: 1}))
    const onPageChange = (page) => dispatch(changeFilterChargesAsync({...filters, page}))

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`
            }
        },
        {
            accessor: 'type_operations',
            Header: t(lang, "main.billing.charge.datatable.type.of.transaction"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {t(lang, `main.billing.package.item.name.${original?.item.name}`)}
                    </>
                );
            }
        },
        {
            accessor: 'price',
            Header: t(lang, "main.billing.charge.datatable.amount"),
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <span className={`${original.price > 0 && 'text-danger'} fw-bold`}>
                        {original.price > 0 && '-'}
                        {original?.price}
                    </span>
                );
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "main.billing.charge.datatable.created_at"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.created_at}
                    </>
                );
            }
        },
        {
            accessor: 'package',
            Header: t(lang, "main.billing.charge.datatable.package"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.package?.name}
                    </>
                );
            }
        },
        {
            accessor: 'document',
            Header: t(lang, "main.billing.charge.datatable.document"),
            Cell: ({row: {original}}) => {
                const document = FindDocument(original)
                return (
                    <>
                        <Link to={document.to + document.value.id}>
                            № {document.value.number} от {dayjs(document.value.date).format('YYYY-MM-DD')}
                        </Link>
                    </>
                );
            }
        },

    ]
    return (

        <AdvanceTableWrapper
            columns={columns}
            data={charges}
            pagination
            perPage={1000}
        >
            <Card className={'mt-1'}>
                <Card.Header className={'py-0 bg-light'}>
                    <ChargeDatatableHeader isAdmin={isAdmin}/>
                </Card.Header>
                <Card.Body>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}/>

                </Card.Body>
                <Card.Footer>
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default ChargeDatatable;
