import IconButton from "../../common/IconButton";
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Col, Form, Row, Button, Collapse} from 'react-bootstrap';
import {Link} from "react-router-dom";
import OrderDataTableFilter from "./ReturnOrderDataTableFilter";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {
    changeFilterReturnOrdersAsync, changeReturnOrderDataTableColumn, loadReturnOrderDataTableColumn,
    selectFilterOptions, STORAGE_RETURN_ORDER
} from "../../../app/store/reducers/return-order/returnOrderReducer";
import {useForm, FormProvider} from "react-hook-form";
import UploadReturnOrderListToExcel from "./excel/UploadReturnOrderListToExcel";
import DataTableSettings from "../DataTableSettings";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnOrderCreate} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";


const OrderDataTableHeader = ({selectedRowIds}) => {
    const dispatch = useDispatch();
    const filter = useSelector(selectFilterOptions);


    const methods = useForm({
        defaultValues: {
            branch_id: filter?.branch_id || null,
            date_end: filter?.date_end && dayjs(filter?.date_end).toDate() || null,
            date_start: filter?.date_start && dayjs(filter?.date_start).toDate() || null,
            is_executor_approved: typeof filter?.is_executor_approved === 'boolean' ? filter?.is_executor_approved : null,
            return_order_number: filter?.return_order_number || null,
            status: filter?.status || null,
            executor_inn: filter?.executor_inn || null,
        }
    });
    const lang = useSelector(selectLang);
    const t = Translate;

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true);

    const onSubmitFilter = ({date_start, date_end, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD")
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD")
        dispatch(changeFilterReturnOrdersAsync({
            page: 1,
            date_start,
            date_end,
            ...filters
        }))
    }

    const allColumns = JSON.parse(localStorage.getItem(STORAGE_RETURN_ORDER))
    if (!allColumns) {
        dispatch(loadReturnOrderDataTableColumn())
        return <></>
    }

    return (
        <Row className="flex-between-center">
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                    <Collapse in={isOpenFilterCollapse}>
                        <Col sm={12}>
                            <OrderDataTableFilter/>
                            <hr/>
                        </Col>
                    </Collapse>
                </Form>
            </FormProvider>

            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.return_order.datatable.header.title")}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <Form.Select size="sm" aria-label="Bulk actions">
                            <option>Bulk Actions</option>
                            <option value="refund">Refund</option>
                            <option value="delete">Delete</option>
                            <option value="archive">Archive</option>
                        </Form.Select>
                        <Button
                            type="button"
                            variant="falcon-default"
                            size="sm"
                            className="ms-2"
                        >
                            Apply
                        </Button>
                    </div>
                ) : (
                    <div id="orders-actions">
                        <IconButton
                            as={dispatch(checkPermission(PermissionEdiReturnOrderCreate)) ? Link : 'div'}
                            onClick={()=>!dispatch(checkPermission(PermissionEdiReturnOrderCreate)) && dispatch(toastPermission())}
                            to="./return-order/register"
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >
                                <span
                                    className="d-none d-sm-inline-block ms-1">{t(lang, "edi.return_order.datatable.header.navigation.create")}</span>
                        </IconButton>

                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            transform="shrink-3"
                            className="mx-2"
                            icon="refresh"
                            onClick={methods.handleSubmit(onSubmitFilter)}
                        >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "edi.orders.order.resume_order_button")}</span>
                        </IconButton>

                        <UploadReturnOrderListToExcel className="d-inline-block me-2"/>

                        <IconButton
                            onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                            variant="falcon-default"
                            size="sm"
                            icon="filter"
                            transform="shrink-3"
                        >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "edi.return_order.datatable.header.navigation.filter")}</span>
                        </IconButton>
                    </div>
                )}
            </Col>
        </Row>
    );
};

OrderDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default OrderDataTableHeader;
