import axios from "axios";

class TelegramOrderBotService {
    constructor() {
        this.baseURL = "/telegram-order-bot";
    }

    registerTelegramOrderBot(payload) {
        return axios.post(`${this.baseURL}/bot/register`, payload)
    }

    deleteTelegramOrderBot(id) {
        return axios.post(`${this.baseURL}/bot/delete/${id}`)
    }

    getTelegramOrderBots() {
        return axios.get(`${this.baseURL}/bot/get`)
    }

    getTelegramOrderBot(id) {
        return axios.get(`${this.baseURL}/bot/get/${id}`)
    }

    getTelegramOrderBotUsers(id, params) {
        return axios.get(`${this.baseURL}/bot/get-bot-users/${id}`, {
            params: params
        })
    }

    getTelegramOrderBotUsersCount(id, params) {
        return axios.get(`${this.baseURL}/bot/get-bot-users-count/${id}`, {
            params: params
        })
    }

    sendMessageToTelegramOrderBotUsers(id, payload) {
        return axios.post(`${this.baseURL}/bot/send-message-to-bot-users/${id}`, payload);
    }

    getTelegramOrderBotScripts() {
        return axios.get(`${this.baseURL}/bot/script/get`)
    }

    getTelegramOrderBotConfigs(botId) {
        return axios.get(`${this.baseURL}/bot/get-config/${botId}`)
    }

    configureTelegramOrderBotConfigs(botId, payload) {
        return axios.post(`${this.baseURL}/bot/configure/${botId}`, payload)
    }

    getTelegramOrderBotOrder(id) {
        return axios.get(`${this.baseURL}/order/get/${id}`)
    }

    getTelegramOrderBotOrders(params) {
        return axios.get(`${this.baseURL}/order/get`, {params: params})
    }

    getTelegramOrderBotOrdersCount(params) {
        return axios.get(`${this.baseURL}/order/get/count`, {params: params})
    }

    deleteTelegramOrderBotOrder(id) {
        return axios.post(`${this.baseURL}/order/delete/${id}`)
    }
}

const telegramOrderBotService = new TelegramOrderBotService();
export default telegramOrderBotService;