import React, {useEffect} from 'react';
import {Button, CloseButton, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";

const ViewModalPage = ({show, children, onHide}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Modal show={show} onHide={onHide} fullscreen style={{padding: '15px'}}>
            <Modal.Body>
                <div className="d-flex justify-content-end mb-1">
                    <IconButton
                        className={'d-flex align-items-center'}
                        onClick={onHide}
                        variant={'outline-primary'}
                        icon={'remove'}>
                        {t(lang, 'main.common.modal.close')}
                    </IconButton>
                </div>

                {children}
            </Modal.Body>
        </Modal>
    );
};

export default ViewModalPage;
