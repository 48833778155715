import {createSlice} from "@reduxjs/toolkit";
import {
    AccountCashBoxBindingDisabled,
    AccountCashBoxBindingEnabled,
    AccountCashBoxBindRegistered, AccountCashBoxBindUnregistered,
    AccountContractorBindingDisable,
    AccountContractorBindingEnable,
    AccountContractorBindRegister,
    AccountContractorBindUnregister, CashBoxAmountsUpdated, CashBoxDeleted,
    CashBoxRegistered,
    CashBoxUpdated,
    ContractorDebtUpdated,
    WarehouseItemUpdated
} from "../../../../enum/PushUpdateNotification";

import sound from '../../../../assets/hippo/notification_sound.mp3'
import moneySound from '../../../../assets/hippo/money_sound.mp3'
const pushUpdateNotificationSlice = createSlice({
    name: "pushUpdateNotification",
    initialState: {
        pushUpdateNotificationData: [],
        soundDisabled: false,
    },
    reducers: {
        addPushUpdateNotification: (state, action) => {
            let account = action.payload.payload

            if (!state.soundDisabled) {
                if (action.payload.type === CashBoxAmountsUpdated) {
                    new Audio(moneySound).play().then(() => {})
                } else {
                    new Audio(sound).play().then(() => {})
                }
                state.soundDisabled = true
            }
            let pushUpdateNotifications = state.pushUpdateNotificationData
            if (action.payload.type === WarehouseItemUpdated && pushUpdateNotifications.length) {
                const temp = pushUpdateNotifications.filter(item => item.payload.item_id !== action.payload.payload.item_id)
                temp.unshift(action.payload)
                state.pushUpdateNotificationData = temp
            }
            else if (action.payload.type === ContractorDebtUpdated && pushUpdateNotifications.length) {
                const temp = pushUpdateNotifications.filter(item => item.payload.id !== action.payload.payload.id)
                temp.unshift(action.payload)
                state.pushUpdateNotificationData = temp
            }
            else if ((
                action.payload.type === AccountContractorBindingEnable ||
                action.payload.type === AccountContractorBindingDisable ||
                action.payload.type === AccountCashBoxBindingEnabled ||
                action.payload.type === AccountCashBoxBindingDisabled
            ) && pushUpdateNotifications.length) {
                if (!account.account_id) {
                    return
                }
                pushUpdateNotifications.forEach((item,index) => {
                    item = item.payload
                    if (item.type === account.type && item.account_id === account.account_id && item.is_binding_enabled === account.is_binding_enabled) {
                        pushUpdateNotifications.splice(index, 1)
                    }
                })
               pushUpdateNotifications.unshift(action.payload)
            }
            else if ((
                action.payload.type === AccountContractorBindRegister ||
                action.payload.type === AccountContractorBindUnregister ||
                action.payload.type === AccountCashBoxBindRegistered ||
                action.payload.type === AccountCashBoxBindUnregistered
            ) && pushUpdateNotifications.length) {
                pushUpdateNotifications.forEach((item,index) => {
                    item = item.payload
                    if (item.type === account.type && item.account_id === account.account_id && item.contractor_id === account.contractor_id) {
                        pushUpdateNotifications.splice(index, 1)
                    }
                })
                pushUpdateNotifications.unshift(action.payload)
            }
            else if ((action.payload.type === CashBoxRegistered || action.payload.type === CashBoxUpdated || action.payload.type === CashBoxDeleted) && pushUpdateNotifications.length) {
                pushUpdateNotifications.forEach((item, index) => {
                    item = item.payload
                    if (item.id === account.id) {
                        pushUpdateNotifications.splice(index, 1)
                    }
                })
                pushUpdateNotifications.unshift(action.payload)
            }
            else if (action.payload.type === CashBoxAmountsUpdated && pushUpdateNotifications.length) {
                pushUpdateNotifications.forEach((item, index) => {
                    item = item.payload
                    if (item.name === account.name) {
                        pushUpdateNotifications.splice(index, 1)
                    }
                })
                pushUpdateNotifications.unshift(action.payload)
            }
            else {
                pushUpdateNotifications.unshift(action.payload)
                state.pushUpdateNotificationData = pushUpdateNotifications
            }
        },
        deleteOneNotification: (state, action) => {
            state.pushUpdateNotificationData = state.pushUpdateNotificationData.filter((item, ind) => ind !== action.payload)
        },
        clearAllNotification: (state) => {
            state.pushUpdateNotificationData = []
            state.soundDisabled = false
        },
        soundNotification(state) {
           state.soundDisabled = false
        }
    }
})

export const selectPushUpdateNotification = (state) => state.pushUpdateNotification.pushUpdateNotificationData

export const pushUpdateNotification = (payload) => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.addPushUpdateNotification(payload));
};

export const pushUpdateNotificationContractorDebt = (payload) => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.addPushUpdateNotification(payload));
}

export const deletePushUpdateNotification = (payload) => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.deleteOneNotification(payload))
}

export const clearAllPushUpdateNotifications = () => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.clearAllNotification())
}

export const pushUpdateNotificationDisabled = () => (dispatch) => {
    dispatch(pushUpdateNotificationSlice.actions.soundNotification())
}
export default pushUpdateNotificationSlice.reducer
