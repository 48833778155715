import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import EmployeeForm from "./EmployeeForm";
import IconButton from "../common/IconButton";
import React, {Fragment, useEffect, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {editEmployeeAsync} from "../../app/store/reducers/employee/employeeReducer";

const EditEmployeeModal = ({employee, className}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            name: employee.name,
        }
    });

    useEffect(() => {
        methods.setValue('name', employee?.name)
    },[employee])

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false)
        methods.reset()
    };

    const onSubmit = formData => {
        setLoading(true);

        editEmployeeAsync(employee.id, {name: formData.name})
            .then(() => {
                toast.success(t(lang, 'employee.edit_employee_modal.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'employee.edit_employee_modal.toast.error')))
            .finally(() => setLoading(false))
    };

    return (
        <Fragment>
            <IconButton icon="edit" className={className} variant="falcon-warning" size="sm" transform="shrink-3" onClick={handleShow}/>

            <Modal size={'md'} show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{t(lang, 'employee.edit_employee_modal.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EmployeeForm/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'main.common.back')}</Button>
                            <Button variant={'primary'} type={'submit'} disabled={loading}>
                                {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                {t(lang, 'main.common.edit')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default EditEmployeeModal;