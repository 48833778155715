import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {Dropdown, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {downloadFile} from "../../../../../../../helpers/excel-uploader";
import ExcelService from "../../../../../../../services/excelService";
import {toast} from "react-toastify";

const UploadTelegramOrderToExcel = ({id}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);

    const uploadOrder = async (id) => {
        try {
            setLoading(true)
            const {
                data: content,
                headers: {'content-type': contentType}
            } = await ExcelService.getTelegramOrderTemplate(id);
            downloadFile({
                content: content,
                contentType: contentType,
                fileName: `telegram_order_bot_${id}`,
            });
            setLoading(false);
            toast.success(t(lang, "common.toast.success"));
        } catch (error) {
            setLoading(false);
            toast.error(t(lang, "common.toast.error"));
        }
    }

    return (
        <Dropdown.Item className="text-success align-center"
                       disabled={loading}
                       onClick={() => uploadOrder(id)}
        >
            <FontAwesomeIcon className="me-1"
                             icon={faFileExcel}
            />
            <span>{t(lang, 'crm.common.download_excel')}</span>
            {loading && <Spinner className="align-middle ms-2" animation="border" role="switch" size="sm"/>}
        </Dropdown.Item>
    );
};

export default UploadTelegramOrderToExcel;
