import {toast} from "react-toastify";
import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Card, Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import MassRegisterRoamingWaybillSheetForm from "./MassRegisterRoamingWaybillSheetForm";
import MassRegisterRoamingWaybillDetailsForm from "./MassRegisterRoamingWaybillDetailsForm";
import MassRegisterRoamingWaybillFileUploader from "./MassRegisterRoamingWaybillFileUploader";
import {registerMassWaybillAsync} from "../../../../../app/store/reducers/excel-registry/excelRegistryReducer";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";
import {
    ExcelRegistryWaybillColumnUnimportant,
    ExcelRegistryWaybillDeliveryTypeFromSellerToBuyer,
    ExcelRegistryWaybillTransportTypeRoad,
} from "../../../../../enum/excel-registry/ExcelRegistryWaybillWrapper";
import {selectWaybillRegistrySettings, updateWaybillRegistrySettingsAsync} from "../../../../../app/store/reducers/settings/settingsReducer";

const sheetsFieldName = 'sheets';
const sheetsSkipRowsFieldName = 'sheetsSkipRows';
const sheetsColumnKeysFieldName = 'sheetsColumnKeys';

const MassRegisterRoamingWaybillForm = () => {
    const registrySettings = useSelector(selectWaybillRegistrySettings);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            sheets: [],
            sheetsSkipRows: [],
            sheetsColumnKeys: [],
            deliveryType: ExcelRegistryWaybillDeliveryTypeFromSellerToBuyer,
            transportType: ExcelRegistryWaybillTransportTypeRoad,
            customerIsFreightForwarder: false,
            executorIsFreightForwarder: false,
            freightForwarderIsCarrier: false,
            responsiblePersonIsDriver: false,
            executorIsClientEmployer: false,
            customerIsClientEmployer: false,
            file: {
                name: null,
                size: null,
                content: null,
            },
        }
    });

    const sheets = methods.watch('sheets');

    useEffect(() => {
        if (sheets.length > 0) {
            initWaybillImportDetails();
            initSheetsColumns();
            initSheetsSkipRows();
        }
    }, [sheets])

    const initWaybillImportDetails = () => {
        methods.setValue('deliveryType', registrySettings.delivery_type);
        methods.setValue('transportType', registrySettings.transport_type);
        methods.setValue('customerIsFreightForwarder', registrySettings.customer_is_freight_forwarder);
        methods.setValue('executorIsFreightForwarder', registrySettings.executor_is_freight_forwarder);
        methods.setValue('freightForwarderIsCarrier', registrySettings.freight_forwarder_is_carrier);
        methods.setValue('responsiblePersonIsDriver', registrySettings.responsible_person_is_driver);
    };

    const initSheetsColumns = () => {
        let sheetsColumns = [];

        const savedSheetColumns = registrySettings?.sheet_columns;

        for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex++) {
            const sheet = sheets[sheetIndex];

            let maxSheetRowLength = 0;
            let sheetColumnKeys = [];

            // define sheet max column count
            for (let rowIndex = 0; rowIndex < sheet.data.length; rowIndex++) {
                const sheetRow = sheet.data[rowIndex];

                if (sheetRow.length > maxSheetRowLength) {
                    maxSheetRowLength = sheetRow.length;
                }
            }

            // fill sheet all column keys
            for (let index = 0; index < maxSheetRowLength; index++) {

                if (!!savedSheetColumns) {
                    let savedColumnFound = false;

                    for (let i = 0; i < savedSheetColumns.length; i++) {
                        const savedColumn = savedSheetColumns[i];

                        if (savedColumn.column_index === index && savedColumn.sheet_index === sheetIndex) {
                            savedColumnFound = true;
                            sheetColumnKeys.push(savedColumn.key_name);
                            break;
                        }
                    }

                    if (!savedColumnFound) {
                        sheetColumnKeys.push(ExcelRegistryWaybillColumnUnimportant);
                    }
                } else {
                    sheetColumnKeys.push(ExcelRegistryWaybillColumnUnimportant);
                }
            }

            sheetsColumns[sheetIndex] = sheetColumnKeys;
        }

        methods.setValue(sheetsColumnKeysFieldName, sheetsColumns);
    }

    const initSheetsSkipRows = () => {
        let sheetsSkipRows = [];

        const savedSheetSkipRows = registrySettings?.sheet_skip_rows;

        for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex++) {
            if (savedSheetSkipRows) {
                const savedSkipRows = savedSheetSkipRows.find(sheetSkipRows => sheetSkipRows.sheet_index === sheetIndex);

                if (savedSkipRows) {
                    sheetsSkipRows[sheetIndex] = {countRow: +savedSkipRows.count_row};
                } else {
                    sheetsSkipRows[sheetIndex] = {countRow: 0};
                }
            } else {
                sheetsSkipRows[sheetIndex] = {countRow: 0};
            }
        }

        methods.setValue(sheetsSkipRowsFieldName, sheetsSkipRows);
    }

    const onSubmit = formData => {

        // append sheets column keys
        let sheetsColumnKeys = [];
        {
            for (let sheetIndex = 0; sheetIndex < formData.sheetsColumnKeys.length; sheetIndex++) {
                const columnKeys = formData.sheetsColumnKeys[sheetIndex];

                for (let columnIndex = 0; columnIndex < columnKeys.length; columnIndex++) {
                    const columnKey = columnKeys[columnIndex];

                    if (columnKey === ExcelRegistryWaybillColumnUnimportant) {
                        continue;
                    }

                    sheetsColumnKeys.push({
                        key_name: columnKey,
                        column_index: columnIndex,
                        sheet_index: sheetIndex,
                    });
                }
            }
        }

        let sheetsSkipRows = [];
        {
            for (let sheetIndex = 0; sheetIndex < formData.sheetsSkipRows.length; sheetIndex++) {
                const sheetSkpRows = formData.sheetsSkipRows[sheetIndex];

                sheetsSkipRows.push({
                    count_row: +sheetSkpRows.countRow,
                    sheet_index: sheetIndex,
                })
            }
        }

        const payload = {
            sheets_columns: sheetsColumnKeys,
            sheets_skip_rows: sheetsSkipRows,
            file: {
                name: formData.file.name,
                content: formData.file.content.split(',')[1],
            },
            delivery_type: formData.deliveryType,
            transport_type: formData.transportType,
            customer_is_freight_forwarder: formData.customerIsFreightForwarder,
            executor_is_freight_forwarder: formData.executorIsFreightForwarder,
            freight_forwarder_is_carrier: formData.freightForwarderIsCarrier,
            responsible_person_is_driver: formData.responsiblePersonIsDriver,
            executor_is_client_employer: formData.executorIsClientEmployer,
            customer_is_client_employer: formData.customerIsClientEmployer,
        };

        dispatch(updateShowInProgressAsync(false));
        registerMassWaybillAsync(payload)
            .catch(() => toast.error(t(lang, 'common.toast.success')))
            .finally(() => dispatch(updateShowInProgressAsync(false)))

        // save import details to settings
        const waybillRegistrySettings = {
            sheet_columns: sheetsColumnKeys,
            sheet_skip_rows: sheetsSkipRows,
            delivery_type: formData.deliveryType,
            transport_type: formData.transportType,
            customer_is_freight_forwarder: formData.customerIsFreightForwarder,
            executor_is_freight_forwarder: formData.executorIsFreightForwarder,
            freight_forwarder_is_carrier: formData.freightForwarderIsCarrier,
            responsible_person_is_driver: formData.responsiblePersonIsDriver,
        };
        updateWaybillRegistrySettingsAsync(waybillRegistrySettings);
    };

    return (
        <Fragment>
            <Card className={'w-100'}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Card.Header className={'bg-light'}>
                            <Row>
                                <Col xs={'auto'} className={'align-content-center'}>
                                    <h4>{t(lang, 'edo.excel_registry.mass_register_waybill.form.title')}</h4>
                                </Col>
                                <Col xs={'auto'} className={'ms-auto'}>
                                    <Button size={'lg'} variant={'primary'} type={'submit'}>
                                        {t(lang, 'edo.excel_registry.mass_register_waybill.form.submit')}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <MassRegisterRoamingWaybillFileUploader/>
                        </Card.Body>
                        {!!sheets.length && <Card.Body className={'border-top border-bottom'}>
                            <MassRegisterRoamingWaybillDetailsForm/>
                        </Card.Body>}
                        {!!sheets.length && <Card.Body>
                            <Tabs fill={true} className={'border-bottom p-3 pb-0'}>
                                {sheets.map((sheet, sheetIndex) => (
                                    <Tab key={sheetIndex} eventKey={sheetIndex} title={sheet.name} className={'bg-light'}>
                                        <MassRegisterRoamingWaybillSheetForm
                                            sheet={sheet}
                                            sheetIndex={sheetIndex}
                                            sheetsArrayName={sheetsFieldName}
                                            sheetsSkipRowsArrayName={sheetsSkipRowsFieldName}
                                            sheetsColumnKeysArrayName={sheetsColumnKeysFieldName}
                                        />
                                    </Tab>
                                ))}
                            </Tabs>
                        </Card.Body>}
                    </Form>
                </FormProvider>
            </Card>
        </Fragment>
    );
};

export default MassRegisterRoamingWaybillForm;