import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {selectOrganizationTemplates} from "../../../../../app/store/reducers/excel/excelReducer";
import {
    exportSalesAsync,
    selectSaleFilterOptions
} from "../../../../../app/store/reducers/warehouse-operation/saleReducer";
import {TYPE_SALES_LIST_REPORT} from "../../../../../enum/ExcelTemplateTypes";
import {downloadFile} from "../../../../../helpers/excel-uploader";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const UploadSalesToExcel = () => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const organizationTemplates = useSelector(selectOrganizationTemplates);
    const filters = useSelector(selectSaleFilterOptions)
    const [itemTypeTemplates, setItemTypeTemplates] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const templates = organizationTemplates.filter(template => template.type === TYPE_SALES_LIST_REPORT) || [];
        setItemTypeTemplates(templates);
    }, [organizationTemplates])

    const exportSales = (uri) => {
        setLoading(true);
        exportSalesAsync(uri, {...filters})
            .then(({content, contentType}) => {
                downloadFile({
                    content: content,
                    contentType: contentType,
                    fileName: t(lang, 'warehouse.operation.item.common.list_sales'),
                });
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => setLoading(false))
    }
    return (
        <Dropdown className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0 m-lg-1">
            <Dropdown.Toggle bsPrefix="toggle"
                             variant="falcon-default"
                             size="sm"
                             className={'me-1'}
                             disabled={loading}
            >
                {loading
                    ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                    : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                }
                {t(lang, 'warehouse.operation.item.common.sales.download')}

            </Dropdown.Toggle>
            <Dropdown.Menu className='py-2'>
                {itemTypeTemplates.map((template, index) => {
                    return (
                        <Dropdown.Item as="button" key={index} onClick={() => exportSales(template.uri)}>
                            {t(lang, 'main.common.file.upload.template_name.prefix', {template_name: t(lang, `common.excel.template.name.${template.name}`)})}
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UploadSalesToExcel;
