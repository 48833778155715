import React from "react";
import { useSelector } from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import SignActionWithSelectCertificatWrapper from "../../components/SignActionWithSelectCertificatWrapper";
import {PermissionRoamingActAccept} from "../../../../../enum/Permission/RoamingPermission";



const CustomerAccept = ({ActAcceptHandler, disabled, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <>
            <SignActionWithSelectCertificatWrapper permission={PermissionRoamingActAccept}>
                <IconButton
                    onClick={ActAcceptHandler}
                    variant="falcon-success"
                    size="sm"
                    icon={faSignature}
                    disabled={disabled}
                    iconClassName="me-1"
                    {...props}
                >{t(lang,"edi.attorney.datatable.header.navigation.sign")}</IconButton>
            </SignActionWithSelectCertificatWrapper>
    </>
)
}

export default CustomerAccept;
