import {useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {UZS} from "../../../../../enum/CurrencyCode";
import {toast} from "react-toastify";
import {Form} from "react-bootstrap";
import {
    selectTransferDefaultsSettings,
    transferAddItemAsync, transferChangeEmployeeAsync,
    transferUpdateNoteAsync, transferUpdateNumberAsync
} from "../../../../../app/store/reducers/warehouse-operation/transferReducer";
import TransferForm from "./TransferForm";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";


const EditTransferForm = ({transfer}) => {
    const transferDefaultsSettings = useSelector(selectTransferDefaultsSettings)
    const warehouses = useSelector(selectWarehouses)
    const [loading, setLoading] = useState(false)
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate
    const settings = useSelector(selectDefaultSetting)


    const form = useForm({
        defaultValues: {
            employee_id: transfer?.employee?.id,
            date: dayjs(transfer?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: transfer?.note,
            number: transfer?.number,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse_from_id: settings?.warehouse?.default_id || null,
            warehouse_to_id: null,
            quantity: null,
            marks_from: [],
            marks_to: [],
            commonPrice: {
                amount: null,
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        }
    })


    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse_from_id: settings?.warehouse?.default_id || null,
            warehouse_to_id: null,
            warehouse: null,
            package_measurements: null,
            marks_from: [],
            marks_to: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        })
    }




    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name
        const quantity = +formData?.quantity * +formData?.package_measurements?.quantity

        const item = {
            item_id: formData?.item_id,
            quantity: quantity,
            marks_from: formData?.marks_from || [],
            marks_to: formData?.marks_to || [],
            price: {
                amount: +formData?.commonPrice?.amount,
                currency_id: formData?.commonPrice?.currency_id,
            },
            warehouse_from_id: formData?.warehouse_from_id,
            warehouse_to_id: formData?.warehouse_to_id,
        }

        if (buttonType === "addOrEditTransfer" || event === "addOrEditTransfer") {
            setLoading(true)
            await transferAddItemAsync({
                transferId: transfer?.id,
                operation: item,
            })
                .then(({data}) => {
                    updateNote(formData)
                    // updateNumber(formData)
                    resetForm(formData)
                    updateEmployee(formData)
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch((error) => {
                    if (error.response.data.error.includes("warehouse")) {
                        toast.error(t(lang, "Склад не выбран"))
                    } else {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || transfer?.employee) {
            if (formData?.employee_id !== transfer?.employee?.id) {
                transferChangeEmployeeAsync({transferId: transfer?.id, employeeId: formData?.employee_id})
                    .then(() => { })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== transfer?.note) {
            transferUpdateNoteAsync({transferId: transfer?.id, note: formData?.note})
                .then(({data}) => {})
                .catch(() => {})
        }
    }

    // const updateNumber = (formData) => {
    //     if (formData?.number?.trim() && formData?.number !== transfer?.number) {
    //         transferUpdateNumberAsync({transferId: transfer?.id, number: formData?.number})
    //             .then(({data}) => {})
    //             .catch(() => {})
    //     }
    // }


    return(
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <TransferForm transfer={transfer} loading={loading} addItem={form.handleSubmit(onSubmit)}/>
                </Form>
            </FormProvider>
        </>
    )
}

export default EditTransferForm;
