import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {toast} from "react-toastify";
import {Dropdown, Form, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import Button from "react-bootstrap/Button";
import {writeOffDeleteAsync} from "../../../../../app/store/reducers/warehouse-operation/writeOffReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_REQUESTED, WAREHOUSE_OPERATION_WRITE_OFF_DELETE_SUCCESS
} from "../../../../../app/eventbus/warehouse-operation/writeOffEvents";
import {Controller, FormProvider, useForm} from "react-hook-form";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import {ErrorMessage} from "@hookform/error-message";
import {getRandomNumber} from "../../../../../helpers/utils";


const WriteOffDelete = ({id}) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang);
    const t = Translate;
    const [code] = useState(getRandomNumber(100000, 999999))

    const methods = useForm({
        defaultValues: {
            code: null,
        }
    })

    const handleShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }



    const onDeleteItem = async () => {
        setLoading(true)
        EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_REQUESTED)

        await writeOffDeleteAsync(id)
            .then(() => {
                handlerClose()
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_SUCCESS)
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_FAILED)
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <>
            <Dropdown.Item className="text-danger align-center"
                           onClick={() => handleShow()}
            >
                <FontAwesomeIcon className="me-1" size="sm" icon={faTrash} />
                <span>{t(lang, 'items.common.delete')}</span>
            </Dropdown.Item>

            <Modal show={show} onHide={handlerClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">{t(lang, "warehouse.operation.item.common.delete_item_modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form id={'delete-popover'} onSubmit={methods.handleSubmit(onDeleteItem)}>
                            <Form.Group>
                                <Form.Label>
                                    <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                    <span className="ms-1 text-primary">{code}</span>
                                </Form.Label>
                                <Controller name={'code'}
                                            control={methods.control}
                                            rules={{
                                                required: t(lang, 'items.common.validations.is_required'),
                                                validate: (value) => {
                                                    if (+value === code)
                                                        return true;
                                                    return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                                }
                                            }}
                                            render={({field}) => (
                                                <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                        value={field.value}
                                                        htmlRef={field.ref}
                                                        placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                        className={classNames('form-control', {
                                                            'is-invalid': methods.formState.errors?.code
                                                        })}
                                                        options={{
                                                            numeral: true,
                                                            delimiter: ' ',
                                                            numeralDecimalScale: 3
                                                        }}
                                                />
                                            )}
                                />
                                <ErrorMessage name={'code'}
                                              errors={methods.formState.errors}
                                              as={<Form.Control.Feedback type="invalid"/>}
                                />
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerClose}>
                        {t(lang, "warehouse.operation.item.common.close")}
                    </Button>
                    <Button type={'submit'} form={'delete-popover'} variant="danger">
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' size="sm"
                                     role='switch'/>
                        }
                        {t(lang, "warehouse.operation.item.common.delete_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WriteOffDelete;
