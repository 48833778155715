import React, {memo, useCallback} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Form, InputGroup} from "react-bootstrap";
import {getNumberFixedValue} from "../../../../../helpers/utils";
import {ROUND_SCALE_TWO} from "./RoamingInvoiceFormItemRow";
import CatalogLinkSelector from "../../../../common/CatalogLinkSelector";
import classNames from "classnames";
import CatalogPackageLinkSelector from "../../../../common/CatalogPackageLinkSelector";
import MeasurementLinkSelector from "../../../../common/MeasurementLinkSelector";
import Cleave from "cleave.js/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const RoamingConstructionInvoiceFormItemRow = ({
                                                   index,
                                                   field,
                                                   deleteItem,
                                                   allowDeleteItem,
                                                   fieldName
                                               }) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const item = useWatch({name: `${fieldName}`});
    const {control, getFieldState, formState, setValue} = useFormContext();
    const {error} = getFieldState(fieldName, formState)
    const indicatorsTotally = useWatch({name: `${fieldName}.indicators.totally`, control});
    const fromStartPhysically = useWatch({name: `${fieldName}.from_start.physically`, control});
    const currentYearPhysically = useWatch({name: `${fieldName}.current_year.physically`, control});
    const currentMonthPhysically = useWatch({name: `${fieldName}.current_month.physically`, control});

    const onChangeCatalog = useCallback(() => {
        setValue(`${fieldName}.measurement`, null);
    }, []);
    const calculateTotallyFromStart = useCallback((totally, physically) => {
        const value = (physically / totally) * 100;
        setValue(`${fieldName}.from_start.percentage`, getNumberFixedValue(value, ROUND_SCALE_TWO));
    }, [fromStartPhysically, indicatorsTotally])
    const calculateTotallyCurrentYear = useCallback((totally, physically) => {
        const value = (physically / totally) * 100;
        setValue(`${fieldName}.current_year.percentage`, getNumberFixedValue(value, ROUND_SCALE_TWO));
    }, [currentYearPhysically, indicatorsTotally])
    const calculateTotallyCurrentMonth = useCallback((totally, physically) => {
        const value = (physically / totally) * 100;
        setValue(`${fieldName}.current_month.percentage`, getNumberFixedValue(value, ROUND_SCALE_TWO));
    }, [currentMonthPhysically, indicatorsTotally])

    return (
        <tr className="position-relative">
            <td className="p-0 align-middle text-center" style={{minWidth: '30px'}}>
                <span style={{height: '40px'}}>{index + 1}</span>
            </td>
            <td style={{minWidth: '250px'}} className="p-0">
                <Controller
                    name={`${fieldName}.name`}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required')
                    }}
                    render={({field, fieldState: {error}}) => (
                        <Form.Control
                            name={`${fieldName}.name`}
                            className="px-1"
                            style={{height: '40px', border: !error && 'none'}}
                            isInvalid={!!error}
                            value={item.name}
                            onChange={(e) => field.onChange(e.target.value)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '150px', width: '150px'}} className="p-0 align-middle text-center">
                <Controller
                    name={`${fieldName}.catalog_code`}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required')
                    }}
                    render={({field}) => (
                        <CatalogLinkSelector
                            defaultCatalog={item.catalog}
                            onChange={(option) => {
                                field.onChange(option);
                                onChangeCatalog(option);
                            }}
                            linkProps={{
                                className: classNames({
                                    'text-primary cursor-pointer fs--1': !error?.catalog_code,
                                    'text-danger cursor-pointer fs--1': error?.catalog_code
                                })
                            }}
                        />
                    )}
                />
            </td>
            <td className="p-0 align-middle text-center">
                {item?.catalog_code?.use_package &&
                    <Controller
                        name={`${fieldName}.measurement`}
                        rules={{
                            required: t(lang, 'items.common.validations.is_required')
                        }}
                        render={({field}) => (
                            <CatalogPackageLinkSelector
                                catalogPackages={item?.catalog_code?.package_names}
                                defaultCatalogPackage={item.measurement}
                                onChange={field.onChange}
                                linkProps={{
                                    className: classNames({
                                        'text-primary cursor-pointer fs--1': !error?.measurement,
                                        'text-danger cursor-pointer fs--1': error?.measuremen
                                    })
                                }}
                            />
                        )}
                    />
                }
                {!item?.catalog_code?.use_package &&
                    <Controller
                        name={`${fieldName}.measurement`}
                        rules={{
                            required: t(lang, 'items.common.validations.is_required')
                        }}
                        render={({field}) => (
                            <MeasurementLinkSelector
                                onChange={field.onChange}
                                defaultMeasurement={item.measurement}
                                linkProps={{
                                    className: classNames({
                                        'text-primary cursor-pointer fs--1': !error?.measurement,
                                        'text-danger cursor-pointer fs--1': error?.measurement
                                    })
                                }}
                            />
                        )}
                    />
                }
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.cipher_number`}
                    rules={{
                        required: t(lang, 'items.common.validations.is_required')
                    }}
                    render={({field}) => (
                        <Form.Control
                            name={`${fieldName}.cipher_number`}
                            className="px-1"
                            style={{height: '40px', border: !error?.cipher_number && 'none'}}
                            isInvalid={!!error?.cipher_number}
                            value={item.cipher_number}
                            onChange={(e) => field.onChange(e.target.value)}
                        />
                    )}
                />
            </td>

            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.indicators.totally`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.indicators?.totally,
                                'border-danger': error?.indicators?.totally
                            })}
                            onKeyDown={(e) => {
                                if (e.keyCode === 188)
                                    e.target.value += "."
                            }}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3,
                            }}
                            value={field.value}
                            onChange={e => {
                                field.onChange(e.target.rawValue)
                                const updatedTotally = e.target.rawValue
                                calculateTotallyFromStart(updatedTotally, fromStartPhysically)
                                calculateTotallyCurrentYear(updatedTotally, currentYearPhysically)
                                calculateTotallyCurrentMonth(updatedTotally, currentMonthPhysically)
                            }}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.indicators.current_year`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.indicators?.current_year,
                                'border-danger': error?.indicators?.current_year
                            })}
                            options={{
                                numeral: true,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>

            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.contract_prices.totally`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.contract_prices?.totally,
                                'border-danger': error?.contract_prices?.totally
                            })}
                            onKeyDown={(e) => {
                                if (e.keyCode === 188)
                                    e.target.value += "."
                            }}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.contract_prices.current_year`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.contract_prices?.current_year,
                                'border-danger': error?.contract_prices?.current_year
                            })}
                            options={{
                                numeral: true,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>

            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.from_start.physically`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.from_start?.physically,
                                'border-danger': error?.from_start?.physically
                            })}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3,
                            }}
                            value={field.value}
                            onChange={e => {
                                const updatedPhysically = e.target.rawValue
                                calculateTotallyFromStart(indicatorsTotally, updatedPhysically)
                                field.onChange(e.target.rawValue)
                            }}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.from_start.percentage`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            disabled={true}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.from_start?.percentage,
                                'border-danger': error?.from_start?.percentage
                            })}
                            options={{
                                numeral: true,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.from_start.contract_price`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.from_start?.contract_price,
                                'border-danger': error?.from_start?.contract_price
                            })}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>

            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.current_year.physically`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.current_year?.physically,
                                'border-danger': error?.current_year?.physically
                            })}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3,
                            }}
                            value={field.value}
                            onChange={e => {
                                const updatedCurrentYear = e.target.rawValue
                                calculateTotallyCurrentYear(indicatorsTotally, updatedCurrentYear)
                                field.onChange(e.target.rawValue)
                            }}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.current_year.percentage`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            disabled={true}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.current_year?.percentage,
                                'border-danger': error?.current_year?.percentage
                            })}
                            options={{
                                numeral: true,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    style={{height: 40}}
                    name={`${fieldName}.current_year.contract_price`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.current_year?.contract_price,
                                'border-danger': error?.current_year?.contract_price
                            })}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>

            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.current_month.physically`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.current_month?.physically,
                                'border-danger': error?.current_month?.physically
                            })}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3,
                            }}
                            value={field.value}
                            onChange={e => {
                                const updatedCurrentMonth = e.target.rawValue
                                calculateTotallyCurrentMonth(indicatorsTotally, updatedCurrentMonth)
                                field.onChange(e.target.rawValue)
                            }}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.current_month.percentage`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            disabled={true}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.current_month?.percentage,
                                'border-danger': error?.current_month?.percentage
                            })}
                            options={{
                                numeral: true,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '120px', width: '120px'}} className="p-0">
                <Controller
                    name={`${fieldName}.current_month.contract_price`}
                    control={control}
                    rules={{
                        required: "items.common.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            style={{height: 40}}
                            htmlRef={field.ref}
                            className={classNames('form-control', {
                                'border-0': !error?.current_month?.contract_price,
                                'border-danger': error?.current_month?.contract_price
                            })}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3,
                            }}
                            value={field.value}
                            onChange={e => field.onChange(e.target.rawValue)}
                        />
                    )}
                />
            </td>
            <td style={{minWidth: '30px', width: '30px'}} className="p-0 flex-wrap">
                <div className="d-flex flex-column justify-content-center align-items-center"
                     style={{height: '40px', minHeight: '40px'}}
                >
                  {/*  <FontAwesomeIcon icon="plus"
                                     className="text-success cursor-pointer"
                                     title={t(lang, 'roaming.common.add')}
                                     onClick={() => insertNewItem(index)}
                    />*/}
                    {allowDeleteItem &&
                        <FontAwesomeIcon icon="trash-alt"
                                         className="text-danger cursor-pointer mt-2"
                                         title={t(lang, 'roaming.invoice.delete')}
                                         onClick={() => deleteItem(index)}
                        />
                    }
                </div>
            </td>
        </tr>
    );
};

export default memo(RoamingConstructionInvoiceFormItemRow);
