import axios from 'axios'


class OrderService {
    addOrder(payload) {
        return axios.post('/order/customer/register', payload)
    }

    getOrders (params) {
        return axios.get('/order/get', {
            params
        })
    }

    getCustomerOrders(params) {
        return axios.get('/order/order/customer', {
            params: params
        })
    }
    getExecutorOrders(params) {
        return axios.get('/order/order/executor', {
            params: params
        })
    }

    getCountOrders (params) {
        return axios.get('/order/count', {
            params
        })
    }
    getExecutorCountOrders (params) {
        return axios.get('/order/order/executor/count', {
            params
        })
    }

    getCustomerCountOrders (params) {
        return axios.get('/order/order/customer/count', {
            params
        })
    }

    executorApprove({id, comment}) {
        return axios.post(`/order/executor/approve/${id}`, {
            comment: comment
        })
    }

    customerApprove({id, comment}) {
        return axios.post(`/order/customer/approve/${id}`, {
            comment: comment
        })
    }

    stopOrder ({id, comment}) {
        return axios.post(`/order/executor/stop/${id}`, {
            comment: comment,
        });
    }

    getOrder(id) {
        return axios.get(`/order/get/${id}`)
    }

    executorDeclineOrder ({id, reason, comment}) {
        return axios.post(`/order/executor/decline/${id}`, {
            reason: reason,
            comment: comment,
        });
    }

    executorCancelDecline ({id, comment}) {
        return axios.post(`/order/executor/cancel-declined/${id}`, {
            comment: comment,
        });
    }

    resumeOrder ({id, comment}) {
        return axios.post(`/order/executor/resume/${id}`, {
            comment: comment,
        });
    }

    printOrder(id) {
        return axios.get(`/pdf/order/${id}`, {
            responseType: "blob"
        })
    }

    uploadOrderListToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadOrdersWithItemsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadOrderToExcel({uri, id}) {
        return axios.get(`${uri}/${id}`, {
            responseType: "arraybuffer",
        })
    }

    uploadBeltonOrderToExcel(id) {
        return axios.get(`/excel/order/belton/${id}`, {
            responseType: "arraybuffer",
        })
    }

    uploadSelectedOrdersToExcel(selectedOrdersIDs) {
        return axios.post('/excel/orders/default', {
            selectedOrdersIDs: selectedOrdersIDs,
        }, {responseType: "arraybuffer",})
    }

    uploadSelectedOrdersWithItemsToExcel(selectedOrdersIDs) {
        return axios.post('/excel/orders-with-items/default', {
            selectedOrdersIDs: selectedOrdersIDs,
        }, {responseType: "arraybuffer",})
    }

    getSystemOrderCustomer() {
        return axios.get('/system-order-customer/get');
    }

    orderIdentifyItems({orderId}){
        return axios.post(`/order/order/set-executor-items/${orderId}`);
    }
}

const orderService = new OrderService()

export default orderService
