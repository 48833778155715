import classNames from "classnames";
import {Form} from "react-bootstrap";
import React, {Fragment} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import FileUploader from "../../../common/FileUploader";
import {Controller, useFormContext} from "react-hook-form";
import {PDFTemplates} from "../../../../enum/OrganizationTemplate";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const OrganizationPdfTemplateForm = ({isEditing}) => {
    const {formState: {errors}} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <Form.Group>
                <Form.Label>{t(lang, 'admin.organization_templates.template_form.field.template_code')}</Form.Label>
                <Controller
                    name={'templateCode'}
                    rules={{required: t(lang, 'main.validation.is_required')}}
                    render={({field, fieldState}) => (
                        <ReactSelect
                            isClearable={true}
                            isDisabled={isEditing}
                            placeholder={'Выбрать'}
                            hideSelectedOptions={true}
                            classNamePrefix={'react-select'}
                            value={PDFTemplates[field.value]}
                            getOptionValue={(option) => option.code}
                            getOptionLabel={(option) => t(lang, option.label)}
                            options={Object.keys(PDFTemplates).map(key => PDFTemplates[key])}
                            className={classNames({'is-invalid border border-danger rounded-2': fieldState.error})}
                            onChange={(option) => field.onChange(option ? option?.code : null)}
                        />
                    )}
                />

                <Form.Control.Feedback type="invalid">
                    {errors?.templateCode?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
                <Controller
                    name={'file'}
                    rules={{required: t(lang, 'main.validation.is_required')}}
                    render={({field}) => (
                        <FileUploader
                            acceptedFiles={'.gohtml'}
                            errorMessage={errors?.file?.message}
                            onFileChanged={file => field.onChange({name: file.name, content: file.content})}
                        />
                    )}
                />
            </Form.Group>
        </Fragment>
    );
};

export default OrganizationPdfTemplateForm;