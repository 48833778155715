import classNames from "classnames";
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import React, {Fragment, useEffect} from 'react';
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import excelRegistryWaybillWrapper, {ExcelRegistryWaybillDeliveryTypes, ExcelRegistryWaybillTransportTypes} from "../../../../../enum/excel-registry/ExcelRegistryWaybillWrapper";

const MassRegisterRoamingWaybillDetailsForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {setValue, formState: {errors}} = useFormContext();

    const deliveryType = useWatch({name: 'deliveryType', exact: true});
    const customerIsFreightForwarder = useWatch({name: 'customerIsFreightForwarder', exact: true});
    const executorIsFreightForwarder = useWatch({name: 'executorIsFreightForwarder', exact: true});
    const freightForwarderIsCarrier = useWatch({name: 'freightForwarderIsCarrier', exact: true});
    const responsiblePersonIsDriver = useWatch({name: 'responsiblePersonIsDriver', exact: true});
    const executorIsClientEmployer = useWatch({name: 'executorIsClientEmployer', exact: true});
    const customerIsClientEmployer = useWatch({name: 'customerIsClientEmployer', exact: true});
    const isFreightForwarderDefined = customerIsFreightForwarder || executorIsFreightForwarder;

    useEffect(() => excelRegistryWaybillWrapper.setDeliveryType(deliveryType), [deliveryType])
    useEffect(() => excelRegistryWaybillWrapper.setCustomerIsFreightForwarder(customerIsFreightForwarder), [customerIsFreightForwarder])
    useEffect(() => excelRegistryWaybillWrapper.setExecutorIsFreightForwarder(executorIsFreightForwarder), [executorIsFreightForwarder])
    useEffect(() => excelRegistryWaybillWrapper.setFreightForwarderIsCarrier(freightForwarderIsCarrier), [freightForwarderIsCarrier])
    useEffect(() => excelRegistryWaybillWrapper.setResponsiblePersonIsDriver(responsiblePersonIsDriver), [responsiblePersonIsDriver])
    useEffect(() => excelRegistryWaybillWrapper.setExecutorIsClientEmployer(executorIsClientEmployer), [executorIsClientEmployer])
    useEffect(() => excelRegistryWaybillWrapper.setCustomerIsClientEmployer(customerIsClientEmployer), [customerIsClientEmployer])


    const onCustomerIsFreightForwarderChecked = value => {
        if (!!value) {
            setValue('executorIsFreightForwarder', !value);
        }
        setValue('customerIsFreightForwarder', value);
        resetCheckedClientEmployers();
    };

    const onExecutorIsFreightForwarderChecked = value => {
        if (!!value) {
            setValue('customerIsFreightForwarder', !value);
        }
        setValue('executorIsFreightForwarder', value);
        resetCheckedClientEmployers();
    };

    const onFreightForwarderIsCarrierChecked = value => {
        setValue('freightForwarderIsCarrier', value);
    };

    const onResponsiblePersonIsDriverChecked = value => {
        setValue('responsiblePersonIsDriver', value);
    };

    const onExecutorIsClientEmployerChecked = value => {
        if (!!value) {
            setValue('customerIsClientEmployer', false);
        }
        setValue('executorIsClientEmployer', value);
    };

    const onCustomerIsClientEmployerChecked = value => {
        if (!!value) {
            setValue('executorIsClientEmployer', false);
        }
        setValue('customerIsClientEmployer', value);
    };

    const resetCheckedClientEmployers = () => {
        setValue('executorIsClientEmployer', false);
        setValue('customerIsClientEmployer', false);
    };

    return (
        <Fragment>
            <Row>
                <Form.Group as={Col} xs={4}>
                    <Form.Label>{t(lang, 'edo.excel_registry.mass_register_waybill.form.field.delivery_type')}</Form.Label>
                    <Controller
                        name={'deliveryType'}
                        rules={{required: t(lang, 'roaming.validations.is_required')}}
                        render={({field}) => (
                            <ReactSelect
                                hideSelectedOptions={true}
                                classNamePrefix="react-select"
                                getOptionValue={option => option.value}
                                options={ExcelRegistryWaybillDeliveryTypes}
                                getOptionLabel={option => t(lang, option.label)}
                                placeholder={t(lang, "roaming.common.select.placeholder")}
                                onChange={option => field.onChange(option ? option?.value : null)}
                                value={ExcelRegistryWaybillDeliveryTypes.find(o => o.value === field.value)}
                                wrapperClassName={classNames({'is-invalid': errors?.deliveryType})}
                                className={classNames({'is-invalid border border-danger rounded-2': errors?.deliveryType})}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.deliveryType?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={4}>
                    <Form.Label>{t(lang, 'edo.excel_registry.mass_register_waybill.form.field.transport_type')}</Form.Label>
                    <Controller
                        name={'transportType'}
                        rules={{required: t(lang, 'roaming.validations.is_required')}}
                        render={({field}) => (
                            <ReactSelect
                                hideSelectedOptions={true}
                                classNamePrefix={'react-select'}
                                getOptionValue={option => option.value}
                                options={ExcelRegistryWaybillTransportTypes}
                                getOptionLabel={option => t(lang, option.label)}
                                placeholder={t(lang, "roaming.common.select.placeholder")}
                                onChange={option => field.onChange(option ? option?.value : null)}
                                value={ExcelRegistryWaybillTransportTypes.find(o => o.value === field.value)}
                                wrapperClassName={classNames({'is-invalid': errors?.transportType})}
                                className={classNames({'is-invalid border border-danger rounded-2': errors?.transportType})}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.transportType?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className={'py-3 mt-3'}>
                <Form.Group as={Col} xs={3}>
                    <Controller name={'customerIsFreightForwarder'} render={({field}) => (
                        <Form.Check type={'checkbox'} id={'customerIsFreightForwarder'}>
                            <Form.Check.Input type={'checkbox'} checked={field.value} onChange={event => onCustomerIsFreightForwarderChecked(event.target.checked)}/>
                            <Form.Check.Label className={'cursor-pointer'}>
                                {t(lang, "edo.excel_registry.mass_register_waybill.form.field.customer_is_freight_forwarder")}
                            </Form.Check.Label>
                        </Form.Check>
                    )}/>
                </Form.Group>

                <Form.Group as={Col} xs={3}>
                    <Controller name={'executorIsFreightForwarder'} render={({field}) => (
                        <Form.Check type={'checkbox'} id={'executorIsFreightForwarder'}>
                            <Form.Check.Input type={'checkbox'} checked={field.value} onChange={event => onExecutorIsFreightForwarderChecked(event.target.checked)}/>
                            <Form.Check.Label className={'cursor-pointer'}>
                                {t(lang, "edo.excel_registry.mass_register_waybill.form.field.executor_is_freight_forwarder")}
                            </Form.Check.Label>
                        </Form.Check>
                    )}/>
                </Form.Group>

                <Form.Group as={Col} xs={3}>
                    <Controller name={'freightForwarderIsCarrier'} render={({field}) => (
                        <Form.Check type={'checkbox'} id={'freightForwarderIsCarrier'}>
                            <Form.Check.Input type={'checkbox'} checked={field.value} onChange={event => onFreightForwarderIsCarrierChecked(event.target.checked)}/>
                            <Form.Check.Label className={'cursor-pointer'}>
                                {t(lang, "edo.excel_registry.mass_register_waybill.form.field.freight_forwarder_is_carrier")}
                            </Form.Check.Label>
                        </Form.Check>
                    )}/>
                </Form.Group>

                <Form.Group as={Col} xs={3}>
                    <Controller
                        name={'responsiblePersonIsDriver'}
                        render={({field}) => (
                            <Form.Check type={'checkbox'} id={'responsiblePersonIsDriver'}>
                                <Form.Check.Input type={'checkbox'} checked={field.value} onChange={event => onResponsiblePersonIsDriverChecked(event.target.checked)}/>
                                <Form.Check.Label className={'cursor-pointer'}>
                                    {t(lang, "edo.excel_registry.mass_register_waybill.form.field.responsible_person_is_driver")}
                                </Form.Check.Label>
                            </Form.Check>
                        )}
                    />
                </Form.Group>

                {!isFreightForwarderDefined && <Form.Group as={Col} xs={3}>
                    <Controller
                        name={'executorIsClientEmployer'}
                        render={({field}) => (
                            <Form.Check type={'checkbox'} id={'executorIsClientEmployer'}>
                                <Form.Check.Input type={'checkbox'} checked={field.value} onChange={event => onExecutorIsClientEmployerChecked(event.target.checked)}/>
                                <Form.Check.Label className={'cursor-pointer'}>
                                    {t(lang, "edo.excel_registry.mass_register_waybill.form.field.executor_is_client_employer")}
                                </Form.Check.Label>
                            </Form.Check>
                        )}
                    />
                </Form.Group>}

                {!isFreightForwarderDefined && <Form.Group as={Col} xs={3}>
                    <Controller
                        name={'customerIsClientEmployer'}
                        render={({field}) => (
                            <Form.Check type={'checkbox'} id={'customerIsClientEmployer'}>
                                <Form.Check.Input type={'checkbox'} checked={field.value} onChange={event => onCustomerIsClientEmployerChecked(event.target.checked)}/>
                                <Form.Check.Label className={'cursor-pointer'}>
                                    {t(lang, "edo.excel_registry.mass_register_waybill.form.field.customer_is_client_employer")}
                                </Form.Check.Label>
                            </Form.Check>
                        )}
                    />
                </Form.Group>}
            </Row>
        </Fragment>
    );
};

export default MassRegisterRoamingWaybillDetailsForm;