import React from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import InputGroupWithCurrency from "../components/InputGroupWithCurrency";
import ItemPurchasePricesInput from "../components/ItemPurchasePricesInput";

const ItemFormCardPrice = ({isEditing}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Card className="h-100">
            <Card.Header className="border-bottom">
                <Card.Title>{t(lang, 'items.common.price')}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.price')}</Form.Label>
                            <InputGroupWithCurrency currencyFieldName={'commonPrice.currencyId'}
                                                    priceFieldName={'commonPrice.price'}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.bulk_price')}</Form.Label>
                            <InputGroupWithCurrency currencyFieldName={'bulkPrice.currencyId'}
                                                    priceFieldName={'bulkPrice.price'}
                            />
                        </Form.Group>
                    </Col>
                    {!isEditing && (
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.purchase_price')}</Form.Label>
                                <InputGroupWithCurrency currencyFieldName={'purchasePrice.currencyId'}
                                                        priceFieldName={'purchasePrice.price'}
                                />
                            </Form.Group>
                        </Col>
                    )}

                    {!!isEditing && (
                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.purchase_price')}</Form.Label>
                            <ItemPurchasePricesInput purchasePricesArrayName={'purchasePrices'}/>
                        </Form.Group>
                    )}
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ItemFormCardPrice;
