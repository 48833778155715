import {
    BelowCustomsPrice,
    BelowMarketPrice,
    ExciseSubmissionForOwnNeeds,
    ExciseUsageForOwnNeeds,
    ForExportService,
    ForFinancialService,
    ForForeignExportService,
    ForGovernmentSecret,
    ForPerson,
    IndividualManufacture,
    LossAndDamageOfGoods,
    NotIncluded,
    OtherIncome,
    PriceAndPrimeCostDifference,
    Resale,
    Service,
    StatusAcceptedByCustomerOrPerson,
    StatusCanceledByExecutor,
    StatusDeclinedByCustomerOrPerson,
    StatusRevoked,
    StatusSentToCustomer,
    TypeAdditional,
    TypeAdditionalAmendOfExpenses,
    TypeAmendOfExpenses,
    TypeCorrective,
    TypeCorrectiveAmendOfExpenses
} from "../RoamingInvoiceWrapper";


const WARNING = "warning";
const DANGER = "danger";
const SUCCESS = "success";
const INFO = "info";
const PRIMARY = "primary";
const SECONDARY = "secondary";
const DARK = "dark";

export class RoamingInvoiceViewPageWrapper {
    constructor(invoice) {
        this.invoice = invoice;
    }

    isExecutor(identifier) {
        return this.invoice.executor.inn === identifier;
    }

    isCustomer(identifier) {
        return this.invoice.customer.inn === identifier;
    }

    isPerson(identifier) {
        return this.invoice.empowerment.inn === identifier || this.invoice.empowerment.pinfl === identifier;
    }

    isDraft() {
        return !this.invoice.status;
    }


    canCopy(identifier) {
        return this.isExecutor(identifier);
    }

    canEditDraft(identifier) {
        return this.isExecutor(identifier) && this.isDraft();
    }

    canCreateAdditionalInvoice(identifier) {
        return this.isExecutor(identifier) && !this.isTypeAmendOfExpenses() && this.isStatusAcceptedByCustomer()
    }

    canCreateCorrectiveInvoice(identifier) {
        return this.isExecutor(identifier) && !this.isTypeAmendOfExpenses() && this.isStatusAcceptedByCustomer()
    }

    canCreateAdditionalAmendOfExpensesInvoice(identifier) {
        return this.isExecutor(identifier) && this.isTypeAmendOfExpenses() && this.isStatusAcceptedByCustomer();
    }

    canCreateCorrectiveAmendOfExpensesInvoice(identifier) {
        return this.isExecutor(identifier) && this.isTypeAmendOfExpenses() && this.isStatusAcceptedByCustomer();
    }

    canExecutorCancel(identifier) {
        return this.isExecutor(identifier) && this.isStatusSentToCustomer();
    }

    canCustomerAccept(identifier) {
        return this.isCustomer(identifier) && this.isStatusSentToCustomer() && !this.isEmpowered();
    }

    canCustomerDecline(identifier) {
        return this.isCustomer(identifier) && this.isStatusSentToCustomer() && !this.isEmpowered();
    }

    canPersonAccept(identifier) {
        return this.isPerson(identifier) && this.isStatusSentToCustomer() && this.isEmpowered();
    }

    canPersonDecline(identifier) {
        return this.isPerson(identifier) && this.isStatusSentToCustomer() && this.isEmpowered();
    }

    canSync() {
        switch (this.invoice.status) {
            case StatusCanceledByExecutor:
            case StatusDeclinedByCustomerOrPerson:
            case StatusRevoked:
                return false;
            default:
                return true;
        }
    }

    isAdditionalOrCorrective() {
        switch (this.invoice.factura_type) {
            case TypeAdditional:
            case TypeCorrective:
            case TypeCorrectiveAmendOfExpenses:
            case TypeAdditionalAmendOfExpenses:
                return true;
            default:
                return false;
        }
    }

    isSingleSide() {
        switch (this.invoice.single_sided_type) {
            case ForPerson:
            case ForForeignExportService:
            case ForGovernmentSecret:
            case ForFinancialService:
            case BelowMarketPrice:
            case BelowCustomsPrice:
            case ForExportService:
            case OtherIncome:
            case ExciseSubmissionForOwnNeeds:
            case ExciseUsageForOwnNeeds:
            case PriceAndPrimeCostDifference:
            case LossAndDamageOfGoods:
                return true;
            default:
                return false;
        }
    }

    hasMark() {
        return this.invoice.has_marking;
    }

    hasExcise() {
        return this.invoice.has_excise;
    }

    hasMedical() {
        return this.invoice.has_medical;
    }

    hasExchange() {
        return !!this.invoice.lot_id;
    }

    hasCommittent() {
        return this.invoice.has_committent;
    }

    isReportHiddenForCommittent() {
        return this.invoice.hide_report_committent;
    }

    isWaybilled() {
        return this.invoice.waybill_ids && !!this.invoice.waybill_ids.length;
    }

    isTypeAmendOfExpenses() {
        return this.invoice.factura_type === TypeAmendOfExpenses;
    }


    isStatusSentToCustomer() {
        return this.invoice.status === StatusSentToCustomer;
    }

    isStatusCanceledByExecutor() {
        return this.invoice.status === StatusCanceledByExecutor;
    }

    isStatusDeclinedByCustomerOrPerson() {
        return this.invoice.status === StatusDeclinedByCustomerOrPerson;
    }

    isStatusAcceptedByCustomer() {
        return this.invoice.status === StatusAcceptedByCustomerOrPerson;
    }

    isStatusRevoked() {
        return this.invoice.status === StatusRevoked;
    }

    isExecutorSent(status) {
        return status === StatusSentToCustomer;
    }

    isExecutorCanceled(status) {
        return status === StatusCanceledByExecutor;
    }

    isCustomerOrPersonDeclined(status) {
        return status === StatusDeclinedByCustomerOrPerson;
    }

    isCustomerOrPersonAccepted(status) {
        return status === StatusAcceptedByCustomerOrPerson;
    }

    isEmpowered() {
        return this.invoice.is_empowered
    }

    getStatusVariant(identifier) {
        if (this.isExecutor(identifier)) {
            switch (this.invoice.status) {
                case StatusSentToCustomer:
                    return INFO;
                case StatusCanceledByExecutor:
                    return WARNING;
                case StatusDeclinedByCustomerOrPerson:
                    return DANGER;
                case StatusAcceptedByCustomerOrPerson:
                    return SUCCESS;
                case StatusRevoked:
                    return SECONDARY;
                default:
                    return DARK;
            }
        } else if (this.isCustomer(identifier)) {
            switch (this.invoice.status) {
                case StatusSentToCustomer:
                    return WARNING;
                case StatusCanceledByExecutor:
                    return DANGER;
                case StatusDeclinedByCustomerOrPerson:
                    return DANGER;
                case StatusAcceptedByCustomerOrPerson:
                    return SUCCESS;
                case StatusRevoked:
                    return SECONDARY
                default:
                    return DARK;
            }
        } else if (this.isPerson(identifier)) {
            switch (this.invoice.status) {
                case StatusSentToCustomer:
                    return INFO;
                case StatusCanceledByExecutor:
                    return DANGER;
                case StatusDeclinedByCustomerOrPerson:
                    return DANGER;
                case StatusAcceptedByCustomerOrPerson:
                    return SUCCESS;
                case StatusRevoked:
                    return SECONDARY;
                default:
                    return DARK;
            }
        }
    }

    getStatusText(identifier) {
        if (this.isDraft())
            return "roaming.act.datatable.viewer.draft"

        if (this.isExecutor(identifier)) {
            switch (this.invoice.status) {
                case StatusSentToCustomer:
                    return "roaming.invoices.invoice.status.executor.15"
                case StatusCanceledByExecutor:
                    return "roaming.invoices.invoice.status.executor.17"
                case StatusDeclinedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.executor.20"
                case StatusAcceptedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.executor.30"
                case StatusRevoked:
                    return "roaming.invoices.invoice.status.executor.40"
                default:
                    return "roaming.invoices.roaming_statuses.not_exist"
            }
        } else if (this.isCustomer(identifier)) {
            switch (this.invoice.status) {
                case StatusSentToCustomer:
                    return "roaming.invoices.invoice.status.customer.15"
                case StatusCanceledByExecutor:
                    return "roaming.invoices.invoice.status.customer.17"
                case StatusDeclinedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.customer.20"
                case StatusAcceptedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.customer.30"
                case StatusRevoked:
                    return "roaming.invoices.invoice.status.customer.40"
                default:
                    return "roaming.invoices.roaming_statuses.not_exist"
            }
        } else if (this.isPerson(identifier)) {
            switch (this.invoice.status) {
                case StatusSentToCustomer:
                    return "roaming.invoices.invoice.status.person.15"
                case StatusCanceledByExecutor:
                    return "roaming.invoices.invoice.status.person.17"
                case StatusDeclinedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.person.20"
                case StatusAcceptedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.person.30"
                case StatusRevoked:
                    return "roaming.invoices.invoice.status.person.40"
                default:
                    return "roaming.invoices.roaming_statuses.not_exist"
            }
        }
    }

    getItemOriginText(type) {
        switch (type) {
            case IndividualManufacture:
                return "roaming.invoice.items.origin_types.1";
            case Resale:
                return "roaming.invoice.items.origin_types.2";
            case Service:
                return "roaming.invoice.items.origin_types.3";
            case NotIncluded:
                return "roaming.invoice.items.origin_types.4";
            default:
                return;
        }
    }
}

const roamingInvoiceViewPageWrapper = new RoamingInvoiceViewPageWrapper();

export default roamingInvoiceViewPageWrapper;