import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadDraftPurchasesAsync, loadDraftPurchasesCountAsync, selectDraftPurchasesFilterOptions} from "../../../../app/store/reducers/warehouse-operation/draftPurchaseReducer";
import DraftPurchaseDataTable from "../../../../components/hippo/warehouse-operation/draft-purchase/datatable/DraftPurchaseDataTable";

const DraftPurchases = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectDraftPurchasesFilterOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        loadDraftPurchases();
    }, [activeOrganization, filters])

    const loadDraftPurchases = () => {
        dispatch(loadDraftPurchasesAsync({...filters}));
        dispatch(loadDraftPurchasesCountAsync({...filters}));
    };

    return <DraftPurchaseDataTable/>;
};

export default DraftPurchases;