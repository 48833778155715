import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {Tab, Tabs} from "react-bootstrap";
import {EdiDefaultNotification} from "../../enum/Notification/EdiNotification";
import {NotificationEventsTable} from "./index";

const Event = 1

const NotificationEvents = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <hr/>
            <h4 className={"my-2"}>{t(lang, "notification.table.events.add_event")}</h4>
            <Tabs fill defaultActiveKey={Event} className="my-2">
                <Tab eventKey={Event} title={t(lang,"notification.table.events.tab_title")}>
                    <NotificationEventsTable notification={EdiDefaultNotification}/>
                </Tab>
            </Tabs>
        </Fragment>
    )
};

export default NotificationEvents;