import React, {Fragment, useEffect, useState} from 'react';
import ProfileBanner from '../ProfileBanner';
import {Card, Col, Row, Tab, Tabs} from 'react-bootstrap';
import AccountSettings from './AccountSettings';
import ChangePassword from './ChangePassword';
import ProfileSettings from "./ProfileSettings";
import AddUser from "../Users/Forms/AddUser";
import UsersDatatable from "../Users/UsersDatatable";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate, changeDashboardPath} from "../../../../app/store/reducers/main/mainReducer";
import OrganizationTemplates from "./templates/OrganizationTemplates";
import EDISettings from "./EDI/EDISettings";
import {loadSettings} from "../../../../app/store/reducers/settings/settingsReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import ChangeLanguage from "./ChangeLanguage";
import AddRole from "../role/forms/AddRole";
import RolesDatatable from "../role/RolesDatatable";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionSystemRoleView} from "../../../../enum/Permission/RolePermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import BindContractorToAccountDataTable from "../../crm/connecting-contractor/BindContractorToAccountDataTable";
import BindContractorToAccountDataTableHeader
    from "../../crm/connecting-contractor/BindContractorToAccountDataTableHeader";
import CashboxAccount from "../../../../pages/main/cashbox/account/CashboxAccount";
import {useHistory, useLocation} from "react-router";
import AccountBranch from "./accounts/branch/AccountBranch";

const PERMISSION = "1"
export const CONTRACTOR = "2"
export const CASHBOX = "3"
export const BRANCH = "4"

const Settings = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const activeOrganization = useSelector(selectActiveOrganization);
    const permission = useSelector(selectAccountRolePermissions)
    const lang = useSelector(selectLang);
    const t = Translate;
    const [key, setKey] = useState(PERMISSION);

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    let activeTabFromRoute = queryParameters.get("activeTab");

    useEffect(() => {
        dispatch(loadSettings())
        dispatch(changeDashboardPath())
    }, [activeOrganization])

    useEffect(() => {
        setKey(activeTabFromRoute || '1')
    }, [activeTabFromRoute])

    return (
        <>
            <ProfileBanner>
                <Tabs fill defaultActiveKey="ProfileAndUsers" id="uncontrolled-tab-example">
                    <Tab eventKey="ProfileAndUsers" title={t(lang, 'hippo.user.settings.profile.title')} className='border-bottom px-3 py-4' tabClassName="p-3">
                        <Card>
                            <ProfileSettings/>
                        </Card>

                        <Card className="my-3 p-3">
                            <Row className={'align-items-center mb-3'}>
                                <Col col={'auto'}>
                                    <h5>{t(lang, "edi.common.user_organization")}</h5>
                                </Col>
                                <Col md={8} className={'d-flex justify-content-end gap-2 align-items-center'}>
                                    <AddUser/>
                                    <BindContractorToAccountDataTableHeader/>
                                </Col>
                            </Row>
                            <Tabs fill defaultActiveKey={key} onSelect={(key) => {
                                history.push(`../user/settings?activeTab=${key}`)
                                setKey(key)
                            }}>
                                <Tab eventKey={PERMISSION} title={t(lang, "Роль")}>
                                    <UsersDatatable/>
                                </Tab>
                                <Tab eventKey={CONTRACTOR} title={t(lang, "crm.data.table.contractor")}>
                                    <BindContractorToAccountDataTable />
                                </Tab>
                                <Tab eventKey={CASHBOX} title={t(lang, "cashbox.bind.account.title")}>
                                    <CashboxAccount />
                                </Tab>
                                <Tab eventKey={BRANCH} title={t(lang, "edi.invoice.datatable.header.row.branch")}>
                                    <AccountBranch />
                                </Tab>
                            </Tabs>
                        </Card>
                    </Tab>
                    <Tab eventKey="FormatAndLanguage" title={t(lang,"main.top.nav.profile_dropdown.dropdown_item.system")} className='border-bottom px-3 py-4' tabClassName="p-3">
                        <Row className="sticky-sidebar">
                            <Col md={6} lg={4} className="col-12">
                                <AccountSettings/>
                            </Col>
                            <Col md={6} lg={4} className="col-12 my-2 my-md-0">
                                <ChangeLanguage/>
                            </Col>
                            <Col lg={4} className="col-12 mt-md-2 mt-lg-0">
                                <ChangePassword/>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="OrganizationTemplates" title={t(lang, 'main.settings.tab.templates.title')} className='border-bottom px-3 py-4' tabClassName="p-3">
                        <OrganizationTemplates />
                    </Tab>
                    <Tab eventKey="Roles" title={t(lang,"hippo.user.settings.roles")} className='border-bottom px-3 py-4' tabClassName="p-3">
                        {
                            dispatch(checkPermission(PermissionSystemRoleView)) ?
                                <Fragment>
                                    <AddRole/>
                                    <RolesDatatable/>
                                </Fragment> :
                                <Card className="text-center p-3">
                                    <h4>{t(lang, 'system.permission.role.user.datatable.no_access.title')}</h4>
                                </Card>
                        }
                    </Tab>
                </Tabs>
            </ProfileBanner>
            <EDISettings />
        </>
    );
};

export default Settings;

