import {Alert} from "react-bootstrap";
import React from "react";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import SyncCatalogCodeButton from "./SyncCatalogCodeButton";

const TasnifAlert = () => {
    const t = Translate
    const lang = useSelector(selectLang);

    return (
        <>
            <Alert variant="info">
                <p className="mb-0 text-600">
                    {t(lang, 'roaming.common.tasnif_desciption')}
                    <a className="ms-2" href="https://tasnif.soliq.uz" target="_blank">{t(lang, 'roaming.common.tasnif_follow_link')}</a>

                    <span className="float-end">
                        <SyncCatalogCodeButton/>
                    </span>
                </p>
            </Alert>
        </>
    )
};

export default TasnifAlert;