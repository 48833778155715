import React, {useLayoutEffect, useState} from 'react';
import Select from "react-select";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {RUB, USD, UZS} from "../enum/itemWrapper";

const SelectCurrency = ({defaultCurrencyId, onChange, selectProps}) => {
    const currencies = useSelector(selectCurrency);
    const [currency, setCurrency] = useState(null);
    const activeCurrency = currencies?.filter(x => x?.is_active)

    useLayoutEffect(() => {
        if (defaultCurrencyId) {
            if (defaultCurrencyId !== currency?.id) {
                setCurrency(currencies.find(c => c.id === defaultCurrencyId));
            }
        } else {
            let defaultCurrency =
                activeCurrency.find(c => c.code === UZS) ||
                activeCurrency.find(c => c.code === USD) ||
                activeCurrency.find(c => c.code === RUB) ||
                currencies.find(c => c.code === UZS);
            if (defaultCurrency) {
                setCurrency(defaultCurrency);
                onChange(currency, defaultCurrency);
            }
        }
    }, [defaultCurrencyId, currencies])

    const onChangeHandler = (oldCurrency, newCurrency) => {
        setCurrency(newCurrency);
        onChange(oldCurrency, newCurrency);
    }

    return (
        <>
            {activeCurrency?.length <= 1 ?
                <span className="input-group-text">
                    {currency?.name}
                </span>
                :
                <Select
                    value={currency}
                    options={activeCurrency}
                    onChange={(option) => onChangeHandler(currency, option)}
                    isSearchable={false}
                    hideSelectedOptions
                    menuPlacement="auto"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            border: 'none',
                            boxShadow: 'none',
                            borderRadius: '0',
                            backgroundColor: '#0d6efd',
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: '#fff'
                        }),
                        menu: (provided) => ({
                            ...provided,
                            marginTop: 0,
                            boxShadow: 'none',
                            borderRadius: '0',
                        }),
                    }}
                    {...selectProps}
                />
            }
        </>
    );
};

export default SelectCurrency;
