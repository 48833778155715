import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    deleteCashbox,
    selectCashbox, selectedCurrencyForCashBox
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Badge, Card, Col, Dropdown, OverlayTrigger, Popover, Row,} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import CashBoxesDatatableHeader from "./CashBoxesDatatableHeader";
import CashBoxesRegisterModal from "./CashBoxesRegisterModal";
import {ContractorPaymentTypes, PaymentTypeCashCode} from "../../../../enum/ContractorWrapper";
import numeral from "numeral";
import CashBoxAmountStatistics from "../CashBoxAmountStatistics";
import {
    totalAmountCard,
    totalAmountCash,
    totalAmountInCashBox, totalGroupAmounts, totalGroupCashAmount,
} from "../../../../enum/cash_box/cashBoxWrapper";
import CardDropdown from "../../../common/CardDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import CashBoxCashInDeleteModal from "../cash-in/CashBoxCashInDeleteModal";
import {Link} from "react-router-dom";
import classNames from "classnames";
import SoftBadge from "../../../common/SoftBadge";
import {isApprovedCheckedWarehouseOperation} from "../../warehouse-operation/enum/warehouseOperationWrapper";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import CashBoxDatatableBody from "./CashBoxDatatableBody";

const CashBoxesDatatable = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const cashbox = useSelector(selectCashbox)
    const numberFormat = useSelector(selectNumberFormat);
    const currency = useSelector(selectCurrency)

    const [show, setShow] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [defaultValue, setDefaultValue] = useState(null)
    const selectedCurrency = useSelector(selectedCurrencyForCashBox)

    const totalAmountDataTableCash = useMemo(() => {
            return  totalAmountCash(cashbox, currency)
    }, [cashbox, currency])

    const totalAmountDataTableCard = useMemo(() => {
            return  totalAmountCard(cashbox, currency)
    }, [cashbox, currency])


    const columns = [
        {
            Header: '#',
            Cell: ({row}) => `${row.index + 1}`,
            cellProps: {
                width: '50px',
            }
        },
        {
            Header: t(lang, "edi.common.name"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <Link to={{
                        pathname: `/cash-box/operation`,
                        state: original
                    }}>
                        <strong>{original.name}</strong>
                    </Link>
                );
            }
        },
        {
            Header: t(lang, "edi.contract_formation.datatable.header.row.created_at"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.created_at}</span>
                );
            }
        },
        {
            Header: t(lang, "edi.common.branch"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.branch?.name}</span>
                );
            }
        },
        {
            Header: t(lang, "crm.data.table.total.sum"),
            headerProps: {className: "text-center"},
            cellProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                const total = totalAmountInCashBox(original.amounts, currency, true)
                return (
                    <span className={'fw-bold fs-0'}>
                        <span className={'text-700'}>
                            {numeral['formats'][numberFormat].format(total / selectedCurrency?.rate)}
                        </span>
                        <strong className={'text-info ms-2 fw-bold'}>{selectedCurrency?.name}</strong>
                    </span>
                );
            }
        },
        {
            Header: t(lang, "crm.payment_type.cash"),
            headerProps: {className: "text-end pe-4"},
            cellProps: {className: 'pe-2 text-end'},
            Cell: ({row: {original}}) => {
                const cash = totalGroupCashAmount(original.amounts.filter(i => i.money_type === PaymentTypeCashCode))
                return (
                    <Fragment>
                        {cash.map((i, index) => <Badge key={index} bg='success' className='me-2 py-2'
                                                       style={{fontSize: 12}}>
                            <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                            <strong className={'ms-2 fw-bold'}>{i?.name}</strong>
                        </Badge>)}
                    </Fragment>
                );
            }
        },
        {
            Header: t(lang, "Карта"),
            headerProps: {className: "text-end pe-4"},
            cellProps: {width: 250, className: 'pe-2 text-end'},
            Cell: ({row: {original}}) => {
                let plasticCard = totalGroupAmounts(original.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type))) || []
                return (
                    <Fragment>
                        {plasticCard.map((item, index) => <Badge key={index} bg='info' className='me-2'
                                                                 style={{fontSize: 12}}>
                                <span>{numeral.formats[numberFormat].format(item?.total)}</span>
                                <strong className={'ms-2 fw-bold'}>{item?.name}</strong>
                                <i className={'d-block fs--2 fw-bold text-center m-0 mt-1 text-dark'}>{t(lang, ContractorPaymentTypes.find(b => b.value === item?.money_type)?.label)}</i>
                            </Badge>
                        )}
                    </Fragment>
                );
            }
        },
        {
            Header: "",
            accessor: 'none',
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <CardDropdown drop={'start'} btnRevealClass="btn-reveal-sm">
                        <Dropdown.Item
                            onClick={() => {
                                setDefaultValue(original)
                                setShow(true)
                            }}
                            className="text-warning">
                            <FontAwesomeIcon className="me-1" icon={"edit"} color={'warning'}/>
                            <span>{t(lang, "roaming.common.edit")}</span>
                        </Dropdown.Item>

                        <Dropdown.Item
                            onClick={() => {
                                setShowConfirmationModal(true)
                                setDefaultValue(original)
                            }}
                            className="text-danger">
                            <FontAwesomeIcon className="me-1" icon={"trash"}/>
                            <span>{t(lang, "roaming.invoice.delete")}</span>
                        </Dropdown.Item>
                    </CardDropdown>
                );
            }
        },
    ]

    const onHide = () => {
        setShow(false)
        setDefaultValue(null)
    }

    const onHideConfirmationModal = () => {
        setShowConfirmationModal(false)
        setDefaultValue(null)
    }


    return (
        <Fragment>
            <CashBoxAmountStatistics />

                <CashBoxesDatatableHeader />
                <Card.Body className={'py-1 px-0'}>
                    <Row className={'g-2'}>
                        {
                            cashbox.map((item, index) => <CashBoxDatatableBody
                                item={item}
                                index={index}
                                key={index}
                                setShow={setShow}
                                setDefaultValue={setDefaultValue}
                                setShowConfirmationModal={setShowConfirmationModal}
                            />)
                        }
                    </Row>
                </Card.Body>
            {/*<AdvanceTableWrapper
                data={cashbox}
                columns={columns}
                perPage={1000}
                pagination
            >
                <Card>
                    <Card.Header className={'pb-0'}>
                        <CashBoxesDatatableHeader />
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}/>
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>*/}

            <CashBoxesRegisterModal show={show} onHide={onHide} defaultValue={defaultValue}/>
            <CashBoxCashInDeleteModal show={showConfirmationModal} setShow={onHideConfirmationModal}
                                      id={defaultValue?.id}
                                      deleteRequest={deleteCashbox}/>
        </Fragment>
    );
};

export default CashBoxesDatatable;
