import {createSlice} from "@reduxjs/toolkit";
import PrintService from "../../../../services/printService";
import EventBus from "../../../eventbus/EventBus";
import {
    ADD_ORGANIZATION_TEMPLATE_FAILED,
    ADD_ORGANIZATION_TEMPLATE_REQUESTED,
    ADD_ORGANIZATION_TEMPLATE_SUCCEED, DELETE_ORGANIZATION_TEMPLATE_FAILED, DELETE_ORGANIZATION_TEMPLATE_REQUESTED, DELETE_ORGANIZATION_TEMPLATE_SUCCEED, UPDATE_ORGANIZATION_TEMPLATE_FAILED,
    UPDATE_ORGANIZATION_TEMPLATE_REQUESTED,
    UPDATE_ORGANIZATION_TEMPLATE_SUCCEED
} from "../../../eventbus/printEvents";

const printSlice = createSlice({
    name: 'print',
    initialState: {},
    reducers: {},
});

export const addOrganizationTemplateAsync = (organizationId, payload) => {
    EventBus.dispatch(ADD_ORGANIZATION_TEMPLATE_REQUESTED, organizationId);

    return new Promise((resolve, reject) => {
        PrintService.addOrganizationTemplate(organizationId, payload)
            .then(response => {
                EventBus.dispatch(ADD_ORGANIZATION_TEMPLATE_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_ORGANIZATION_TEMPLATE_FAILED, error);
                reject(error);
            });
    })
};

export const updateOrganizationTemplateAsync = (organizationId, payload) => {
    EventBus.dispatch(UPDATE_ORGANIZATION_TEMPLATE_REQUESTED, organizationId);

    return new Promise((resolve, reject) => {
        PrintService.updateOrganizationTemplate(organizationId, payload)
            .then(response => {
                EventBus.dispatch(UPDATE_ORGANIZATION_TEMPLATE_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(UPDATE_ORGANIZATION_TEMPLATE_FAILED, error);
                reject(error);
            });
    })
};

export const deleteOrganizationTemplateAsync = (organizationId, templateId) => {
    EventBus.dispatch(DELETE_ORGANIZATION_TEMPLATE_REQUESTED, organizationId);

    return new Promise((resolve, reject) => {
        PrintService.deleteOrganizationTemplate(organizationId, templateId)
            .then(response => {
                EventBus.dispatch(DELETE_ORGANIZATION_TEMPLATE_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(DELETE_ORGANIZATION_TEMPLATE_FAILED, error);
                reject(error);
            });
    })
};

export const loadOrganizationTemplatesAsync = (organizationId) => {
    return new Promise((resolve, reject) => {
        PrintService.getOrganizationTemplates(organizationId)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};

export const downloadOrganizationTemplateAsync = (organizationId, templateId) => {
    return new Promise((resolve, reject) => {
        PrintService.downloadOrganizationTemplate(organizationId, templateId)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};

export const getDriverSalePdfAsync = ({employeeId, dateStart, dateEnd}) => {
    return new Promise((resolve, reject) => {
        PrintService.getDriverSalePdf({employeeId, dateStart, dateEnd})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export default printSlice.reducer;