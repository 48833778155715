import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SoftBadge from "../../../common/SoftBadge";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";


const WarehouseOperationWarehouseSelect = ({options, defaultValue, onChange, disabled, onFocus, autoFocus, onBlur, ...props}) => {
    const [value, setValue] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const lang = useSelector(selectLang);
    const t = Translate;

    const ref = useRef(null)
    useEffect(() => {
        if (defaultValue !== null && !options) {
            const val = warehouses.find(ware => ware?.id === defaultValue)
            setValue(val)
        } else if(defaultValue && options) {
            const val = options?.find(ware => ware?.id === defaultValue)
            setValue(val)
        } else {
            setValue(null)
        }
    },[options, defaultValue])

    const onChangeWarehouse = option => {
        setValue(option)
        onChange(option)
    }

    const getOptionValue = option => option?.id

    const getOptionLabel = option => option?.name
    const formatOptionLabel = option => {
        return(
            <div className="d-flex justify-content-between">
                {option?.name}
                <SoftBadge bg={option?.state >= 0 ? "success" : "danger"}>
                    {option?.state}
                </SoftBadge>
            </div>
        )
    }

    useEffect(() => {
        onFocus && ref?.current?.focus()
    }, [onFocus])

    return (
        <ReactSelect
            ref={ref}
            options={options || warehouses || []}
            onBlur={() => onBlur && onBlur(value)}
            value={value}
            autoFocus={autoFocus}
            onChange={onChangeWarehouse}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            formatOptionLabel={formatOptionLabel}
            placeholder={t(lang, 'items.common.select')}
            hideSelectedOptions={true}
            noOptionsMessage={() => t(lang, "no options")}
            isDisabled={disabled}
            {...props}
            // menuPortalTarget={document.body}
        />
    );
};


WarehouseOperationWarehouseSelect.propTypes = {
    onChange: PropTypes.func
}

WarehouseOperationWarehouseSelect.defaultProps = {
    onChange: () => {
    }
}


export default WarehouseOperationWarehouseSelect;
