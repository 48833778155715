import React from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import {
    VALIDATE_ITEM_CATALOG_FAILED,
    VALIDATE_ITEM_CATALOG_REQUESTED,
    VALIDATE_ITEM_CATALOG_SUCCESS
} from "../../../../app/eventbus/itemEvents";
import {validateItemCatalogAsync} from "../../../../app/store/reducers/item/itemReducer";

const withValidateCatalog = (WrappedComponent) => {
    return (props) => {
        const validateCatalog = (id) => {
            EventBus.dispatch(VALIDATE_ITEM_CATALOG_REQUESTED, id)
            validateItemCatalogAsync(id)
                .then(() => {
                    EventBus.dispatch(VALIDATE_ITEM_CATALOG_SUCCESS, id)
                })
                .catch(() => {
                    EventBus.dispatch(VALIDATE_ITEM_CATALOG_FAILED, id)
                })
        }

        return <WrappedComponent {...props} validateCatalog={validateCatalog}/>
    }
};

export default withValidateCatalog;