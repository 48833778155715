export const WAREHOUSE_OPERATION_REVISION_ADD_CLIENT_REQUESTED = "WAREHOUSE_OPERATION_REVISION_ADD_CLIENT_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_ADD_CLIENT_SUCCESS = "WAREHOUSE_OPERATION_REVISION_ADD_CLIENT_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_ADD_CLIENT_FAILED = "WAREHOUSE_OPERATION_REVISION_ADD_CLIENT_FAILED"

export const WAREHOUSE_OPERATION_REVISION_REGISTER_REQUESTED = "WAREHOUSE_OPERATION_REVISION_REGISTER_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS = "WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_REGISTER_FAILED = "WAREHOUSE_OPERATION_REVISION_REGISTER_FAILED"

export const WAREHOUSE_OPERATION_REVISION_ADD_ITEM_REQUESTED = "WAREHOUSE_OPERATION_REVISION_ADD_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS = "WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_ADD_ITEM_FAILED = "WAREHOUSE_OPERATION_REVISION_ADD_ITEM_FAILED"

export const WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_FAILED = "WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_FAILED = "WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_REQUESTED = "WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS = "WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_FAILED = "WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_FAILED"

export const WAREHOUSE_OPERATION_REVISION_DELETE_REQUESTED = "WAREHOUSE_OPERATION_REVISION_DELETE_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS = "WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_DELETE_FAILED = "WAREHOUSE_OPERATION_REVISION_DELETE_FAILED"

export const WAREHOUSE_OPERATION_REVISION_NOTE_REQUESTED = "WAREHOUSE_OPERATION_REVISION_NOTE_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_NOTE_SUCCESS = "WAREHOUSE_OPERATION_REVISION_NOTE_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_NOTE_FAILED = "WAREHOUSE_OPERATION_REVISION_NOTE_FAILED"

export const WAREHOUSE_OPERATION_REVISION_DATE_REQUESTED = "WAREHOUSE_OPERATION_REVISION_DATE_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_DATE_SUCCESS = "WAREHOUSE_OPERATION_REVISION_DATE_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_DATE_FAILED = "WAREHOUSE_OPERATION_REVISION_DATE_FAILED"

export const WAREHOUSE_OPERATION_REVISION_NUMBER_REQUESTED = "WAREHOUSE_OPERATION_REVISION_NUMBER_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_NUMBER_SUCCESS = "WAREHOUSE_OPERATION_REVISION_NUMBER_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_NUMBER_FAILED = "WAREHOUSE_OPERATION_REVISION_NUMBER_FAILED"

export const WAREHOUSE_OPERATION_REVISION_EMPLOYEE_REQUESTED = "WAREHOUSE_OPERATION_REVISION_EMPLOYEE_REQUESTED"
export const WAREHOUSE_OPERATION_REVISION_EMPLOYEE_SUCCESS = "WAREHOUSE_OPERATION_REVISION_EMPLOYEE_SUCCESS"
export const WAREHOUSE_OPERATION_REVISION_EMPLOYEE_FAILED = "WAREHOUSE_OPERATION_REVISION_EMPLOYEE_FAILED"