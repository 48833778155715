import {faPrint} from "@fortawesome/free-solid-svg-icons";
import printJS from "print-js";
import React, {useState} from "react";
import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {printAttorneyAsync} from "../../../app/store/reducers/attorney/attorneyReducer";
import {
    selectLang,
    Translate,
} from "../../../app/store/reducers/main/mainReducer";
import IconButton from "../../common/IconButton";

const PrintAttorney = ({id}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onPrint = () => {
        setLoading(true);
        printAttorneyAsync(id)
            .then((response) => {
                setLoading(false);
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    return (
        <IconButton
            variant="falcon-default"
            size="sm"
            icon={faPrint}
            onClick={onPrint}
            iconClassName="me-1"
            className="me-1 mb-2 mb-sm-0"
        >
            <span>{t(lang, "edi.common.button.print")}</span>
            {loading &&
                <Spinner
                    className="align-middle ms-2"
                    size="sm"
                    animation="border"
                    role="switch"
                />
            }
        </IconButton>
    );
};

export default PrintAttorney;
