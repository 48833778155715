import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectDistricts} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const RoamingInvoiceFormDistrictSelector = ({defaultDistrictId, onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const districts = useSelector(selectDistricts);

    return (
        <ReactSelect options={districts}
                     isClearable={true}
                     isSearchable={!defaultDistrictId}
                     placeholder={t(lang, 'roaming.common.select.placeholder')}
                     getOptionLabel={option => option.name}
                     getOptionValue={option => option.districtId}
                     value={districts.find(d => d.districtId === defaultDistrictId)}
                     onChange={option => onChange(option ? option.districtId : null)}
                     {...selectProps}
        />
    );
};

export default RoamingInvoiceFormDistrictSelector;