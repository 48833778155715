import {Form} from "react-bootstrap";
import classNames from "classnames";
import {Controller, useFormContext} from "react-hook-form";
import Cleave from "cleave.js/react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import React from "react";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";


const ContractFormationDynamicFields = ({index, item}) => {
    const { register, formState: {errors}, control } = useFormContext()
    const dateFormat = useSelector(selectDateFormat)
    const lang = useSelector(selectLang)
    const t = Translate

    return(
        <>
            {item.type === 'text' && item.kind === 'email' &&
                <>
                    <Form.Label className="mt-2">
                        {t(lang, "edi.contract_formation.info.email")}
                    </Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="example@gmail.com"
                        defaultValue={item.value}
                        {...register(`form_fields.${index}.value`, {
                                required: t(lang, "edi.common.forms.validations.is_required"),
                                pattern: {
                                    value: /^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,3}$/,
                                    message: t(lang, "edi.common.forms.validations.is_required")
                                }
                            }
                        )}
                        className={classNames('form-control', {
                            'is-invalid': errors?.form_fields?.[index]?.value?.message,
                            'is-valid': Object.keys(errors).length > 0 && !errors?.form_fields?.[index]?.value?.message
                        })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.form_fields?.[index]?.value?.message}
                    </Form.Control.Feedback>
                </>
            }

            {item.type === 'number' &&
                <>
                    <Form.Label className="mt-2">
                        {t(lang, "edi.contract_formation.info.residual_amount")}
                    </Form.Label>
                    <Controller
                        name={`form_fields.${index}.value`}
                        control={control}
                        rules={{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        }}
                        render={({field}) => (
                            <Cleave
                                options={{
                                    numeral: true,
                                    delimiter: ''
                                }}
                                value={field.value}
                                placeholder={t(lang, "edi.common.items.product.total")}
                                className={classNames('form-control', {
                                    'is-invalid': errors?.form_fields?.[index]?.value?.message,
                                    'is-valid': Object.keys(errors).length > 0 && !errors?.form_fields?.[index]?.value?.message
                                })}
                                onChange={val => field.onChange(+val.target.value)}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.form_fields?.[index]?.value?.message}
                    </Form.Control.Feedback>
                </>
            }

            {item.type === 'datalist' &&
                <>
                    <Form.Label className="mt-2">
                        {t(lang, "edi.contract_formation.info.residual")}
                    </Form.Label>
                    <Controller
                        name={`form_fields.${index}.value`}
                        rules={{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        }}
                        render={({field}) => {
                            return (
                                <ReactSelect
                                    options={item?.options}
                                    onChange={selected => field.onChange(selected.value)}
                                    getOptionLabel={option => option.title}
                                    defaultValue={item?.options.find(p => p.value === item.value)}
                                    getOptionValue={option => option.value}
                                    noOptionsMessage={() => t(lang, "edi.contract_formation.common_no_certificate")}
                                    hideSelectedOptions
                                    placeholder={t(lang, "roaming.common.select.placeholder")}
                                    classNamePrefix="react-select"
                                    className={classNames({
                                        "is-invalid": errors?.form_fields?.[index]?.value?.message,
                                        "is-valid": Object.keys(errors).length > 0 && !errors?.form_fields?.[index]?.value?.message
                                    })}
                                    menuPortalTarget={document.body}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.form_fields?.[index]?.value?.message}
                    </Form.Control.Feedback>
                </>
            }

            {item.type === 'date' &&
                <>
                    <Form.Label>
                        {t(lang,"edi.contract_formation.info.date")}
                    </Form.Label>
                    <Controller
                        control={control}
                        name={`form_fields.${index}.value`}
                        rules={{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        }}
                        render={({field}) => (
                            <DatePicker
                                onChange={field.onChange}
                                dateFormat={dateFormat}
                                defaultValue={new Date(item.value).getTime()}
                                wrapperClassName={classNames({
                                    'is-invalid': errors?.form_fields?.[index]?.value?.message,
                                    'is-valid': Object.keys(errors).length > 0 && !errors?.form_fields?.[index]?.value?.message
                                })}
                                className={classNames('form-control', {
                                    'is-invalid': errors?.form_fields?.[index]?.value?.message,
                                    'is-valid': Object.keys(errors).length > 0 && !errors?.form_fields?.[index]?.value?.message
                                })}
                                selected={field.value}
                                placeholderText={t(lang, "roaming.contracts.add.contract_info.expiring_on")}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.form_fields?.[index]?.value?.message}
                    </Form.Control.Feedback>
                </>
            }

            {item.type === 'text' && item.kind !== 'email' &&
                <>
                    <Form.Label className="mt-2">
                        New Field text
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="new field"
                        {...register(`form_fields.${index}.value`,{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        })}
                        defaultValue={item.value}
                        className={classNames('form-control', {
                            'is-invalid': errors?.form_fields?.[index]?.value?.message,
                            'is-valid': Object.keys(errors).length > 0 && !errors?.form_fields?.[index]?.value?.message
                        })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.form_fields?.[index]?.value?.message}
                    </Form.Control.Feedback>
                </>
            }

            {item.type === 'textarea' &&
                <>
                    <Form.Label className="mt-2">
                        New Field textarea
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="new field"
                        defaultValue={item.value}
                        {...register(`form_fields.${index}.value`,{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        })}
                        className={classNames('form-control', {
                            'is-invalid': errors?.form_fields?.[index]?.value?.message,
                            'is-valid': Object.keys(errors).length > 0 && !errors?.form_fields?.[index]?.value?.message
                        })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.form_fields?.[index]?.value?.message}
                    </Form.Control.Feedback>
                </>
            }

            {item.type === 'checkbox' || item.type === 'radio' &&
                <>
                    <Form.Label className="mt-2">
                        New Field checkbox
                    </Form.Label>
                    <Form.Check
                        type={item.type}
                        defaultChecked={item.value}
                        {...register(`form_fields.${index}.value`,{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        })}
                        className={classNames('form-control', {
                            'is-invalid': errors?.form_fields?.[index]?.value?.message,
                            'is-valid': Object.keys(errors).length > 0 && !errors?.form_fields?.[index]?.value?.message
                        })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.form_fields?.[index]?.value?.message}
                    </Form.Control.Feedback>
                </>
            }
        </>
    )
}

export default ContractFormationDynamicFields;