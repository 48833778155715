import React, {useState} from 'react';
import IconButton from "../../../../common/IconButton";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import ImportRevisionItemsFromExcelForm from "./ImportRevisionItemsFromExcelForm";

const ImportRevisionItemsFromExcel = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <React.Fragment>
            <IconButton icon={faFileExcel}
                        variant={'falcon-success'}
                        size={'sm'}
                        className="m-1"
                        onClick={handleShow}
            >
                {t(lang, 'warehouse.operation.item.common.register_revision_from_excel')}
            </IconButton>
            {show && <Modal show={true} fullscreen>
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.table.title')}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <ImportRevisionItemsFromExcelForm handleClose={handleClose}/>
                </Modal.Body>
            </Modal>}
        </React.Fragment>
    );
};

export default ImportRevisionItemsFromExcel;