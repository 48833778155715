import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectFilters, setFilter} from "../../../../app/store/reducers/report/reportReducer";
import reportService from "../../../../services/report/reportService";
import Report from "../../../../components/hippo/warehouse-operation/report/Report";
import dayjs from "dayjs";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import DatePicker from "react-datepicker";
import {Controller, FormProvider, useForm} from "react-hook-form";
import classNames from "classnames";
import EmployeeSelect from "../../../../components/hippo/common/EmployeeSelect";
import SelectOrganizationAccount from "../../../../components/common/SelectOrganizationAccount";
import ContractorSelect from "../../../../components/hippo/common/ContractorSelect";

const ViewReport = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateFormat);
    const filters = useSelector(selectFilters);
    const form = useForm({
        defaultValues: {
            dateStart: filters.date_start,
            dateEnd: filters.date_end,
            contractorId: filters.contractor_id,
            employeeId: filters.employee_id,
            operationAccountId: filters.operation_account_id
        }
    });

    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(null);

    const loadReport = (filters) => {
        if (report)
            setReport(null);

        const params = {
            date_start: null,
            date_end: null
        };

        if (filters.date_start)
            params.date_start = dayjs(filters.date_start).format("YYYY-MM-DD");

        if (filters.date_end)
            params.date_end = dayjs(filters.date_end).format("YYYY-MM-DD");

        setLoading(true);
        reportService.getPeriodReport(filters)
            .then((response) => {
                setReport(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setReport(null);
                setLoading(false);
            })
    };
    const onSubmit = (formData) => {
        const payload = {
            date_start: formData.dateStart ? dayjs(formData.dateStart).format("YYYY-MM-DD") : null,
            date_end: formData.dateEnd ? dayjs(formData.dateEnd).format("YYYY-MM-DD") : null
        };

        if (formData.contractorId)
            payload["contractor_id"] = formData.contractorId;

        if (formData.employeeId)
            payload["employee_id"] = formData.employeeId;

        if (formData.operationAccountId)
            payload["operation_account_id"] = formData.operationAccountId;

        dispatch(setFilter(payload));
    };

    useEffect(() => {
        loadReport(filters);
    }, [filters, activeOrganization]);

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <FormProvider {...form}>
                    <Form>
                        <Card>
                            <Card.Header className="pb-0">
                                <Card.Title className="mb-0">{t(lang, "warehouse.operation.period_report")}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="g-2">
                                    <Col xs={12} sm={4} xl={3} xxl={2}>
                                        <Controller name="dateStart"
                                                    rules={{
                                                        required: t(lang, "main.validation.is_required")
                                                    }}
                                                    render={({field, fieldState: {error}}) => (
                                                        <Form.Group>
                                                            <Form.Label>{t(lang, "warehouse.operation.period_report.date_start")}</Form.Label>
                                                            <DatePicker dateFormat={dateFormat}
                                                                        isClearable
                                                                        selected={field.value ? new Date(field.value) : null}
                                                                        onChange={field.onChange}
                                                                        placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                                                        wrapperClassName={classNames({
                                                                            'is-invalid': !!error
                                                                        })}
                                                                        className={classNames('form-control', {
                                                                            'is-invalid': !!error,
                                                                        })}
                                                            />
                                                            <Form.Control.Feedback
                                                                type="invalid">{error?.message}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} xl={3} xxl={2}>
                                        <Controller name="dateEnd"
                                                    rules={{
                                                        required: t(lang, "main.validation.is_required")
                                                    }}
                                                    render={({field, fieldState: {error}}) => (
                                                        <Form.Group>
                                                            <Form.Label>{t(lang, "warehouse.operation.period_report.date_end")}</Form.Label>
                                                            <DatePicker dateFormat={dateFormat}
                                                                        isClearable
                                                                        selected={field.value ? new Date(field.value) : null}
                                                                        onChange={field.onChange}
                                                                        placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                                                        wrapperClassName={classNames({
                                                                            'is-invalid': !!error
                                                                        })}
                                                                        className={classNames('form-control', {
                                                                            'is-invalid': !!error,
                                                                        })}
                                                            />
                                                            <Form.Control.Feedback
                                                                type="invalid">{error?.message}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} xl={3} xxl={2}>
                                        <Controller name="contractorId"
                                                    render={({field, fieldState: {error}}) => (
                                                        <Form.Group>
                                                            <Form.Label>{t(lang, "crm.common.datatable.contractor")}</Form.Label>
                                                            <ContractorSelect defaultValue={field.value}
                                                                              onChange={(option) => field.onChange(option ? option.id : null)}
                                                                              isClearable={true}
                                                            />
                                                        </Form.Group>
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} xl={3} xxl={2}>
                                        <Controller name="employeeId"
                                                    render={({field}) => (
                                                        <Form.Group>
                                                            <Form.Label>{t(lang, "warehouse.operation.item.common.employee")}</Form.Label>
                                                            <EmployeeSelect defaultValue={field.value}
                                                                            onChange={(option) => field.onChange(option ? option.id : null)}
                                                                            isClearable={true}
                                                            />
                                                        </Form.Group>
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} xl={3} xxl={2}>
                                        <Controller name="operationAccountId"
                                                    render={({field}) => (
                                                        <Form.Group>
                                                            <Form.Label>{t(lang, "crm.common.datatable.username")}</Form.Label>
                                                            <SelectOrganizationAccount defaultAccountId={field.value}
                                                                                       onChange={(option) => field.onChange(option ? option.id : null)}
                                                            />
                                                        </Form.Group>
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12} sm={4} md={3} xl={2}>
                                        <div className="d-grid h-100 w-100 d-sm-flex align-items-end">
                                            <Button variant="primary"
                                                    disabled={loading}
                                                    onClick={form.handleSubmit(onSubmit)}
                                            >
                                                {loading && <Spinner className="align-middle me-2" animation="border"
                                                                     role="switch" size="sm"/>}
                                                {t(lang, "crm.common.show.button")}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Form>
                </FormProvider>
            </Col>
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        {!!(loading && !report) &&
                            <h3 className="text-center">{t(lang, "warehouse.operation.period_report_loading")}</h3>}
                        {!!(!loading && !report) &&
                            <h3 className="text-center">{t(lang, "warehouse.operation.period_report_no_data")}</h3>}
                        {!!(!loading && report) && <Report report={report}/>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
};

export default ViewReport;