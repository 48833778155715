import React, {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {loadEmpowermentAsync} from "../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import Empowerment from "../../../../components/hippo/roaming/empowerement/Empowerment";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS,
    EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS,
    EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS,
    PERSON_ACCEPT_EMPOWERMENT_SUCCESS,
    PERSON_DECLINE_EMPOWERMENT_SUCCESS,
    SYNC_EMPOWERMENT_SUCCESS
} from "../../../../app/eventbus/roaming/roamingEmpowermentEvents";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingEmpowermentView} from "../../../../enum/Permission/RoamingPermission";
import {useHistory} from "react-router";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";

const ViewEmpowerment = ({modalId}) => {
    const {params: {id}} = useRouteMatch();
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch()
    const history = useHistory();
    const permission = useSelector(selectAccountRolePermissions)

    const [empowerment, setEmpowerment] = useState(null);


    useEffect(() => {
        if ( permission.length && !dispatch(checkPermission(PermissionRoamingEmpowermentView))) {
            if (history.length > 1) {
                history.push('/roaming/empowerments/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])
    const loadEmpowerment = () => {
        loadEmpowermentAsync(modalId ? modalId : id)
            .then(loadedEmpowerment => {
                setEmpowerment(loadedEmpowerment)
            })
            .catch(errors => console.log(errors))
    };

    useEffect(() => {
        loadEmpowerment()
    }, [modalId, id, activeOrganization]);

    useEffect(() => {
        const onCustomerCancelEmpowermentSuccessHandler = EventBus.on(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, () => {
            loadEmpowerment();
        })

        const onPersonAcceptEmpowermentSuccessHandler = EventBus.on(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            loadEmpowerment();
        })

        const onPersonDeclineEmpowermentSuccessHandler = EventBus.on(PERSON_DECLINE_EMPOWERMENT_SUCCESS, () => {
            loadEmpowerment();
        })

        const onExecutorAcceptEmpowermentSuccessHandler = EventBus.on(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            loadEmpowerment();
        })

        const onExecutorDeclineEmpowermentSuccessHandler = EventBus.on(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, () => {
            loadEmpowerment();
        })

        const onSyncEmpowermentSuccessHandler = EventBus.on(SYNC_EMPOWERMENT_SUCCESS, () => {
            loadEmpowerment();
        })

        return () => {
            EventBus.remove(CUSTOMER_CANCEL_EMPOWERMENT_SUCCESS, onCustomerCancelEmpowermentSuccessHandler);
            EventBus.remove(PERSON_ACCEPT_EMPOWERMENT_SUCCESS, onPersonAcceptEmpowermentSuccessHandler);
            EventBus.remove(PERSON_DECLINE_EMPOWERMENT_SUCCESS, onPersonDeclineEmpowermentSuccessHandler);
            EventBus.remove(EXECUTOR_ACCEPT_EMPOWERMENT_SUCCESS, onExecutorAcceptEmpowermentSuccessHandler);
            EventBus.remove(EXECUTOR_DECLINE_EMPOWERMENT_SUCCESS, onExecutorDeclineEmpowermentSuccessHandler);
            EventBus.remove(SYNC_EMPOWERMENT_SUCCESS, onSyncEmpowermentSuccessHandler);
        }
    }, [])

    return (
        <>
            {empowerment && <Empowerment modalId={modalId} empowerment={empowerment}/>}
            {!empowerment && <SpinnerComponent />}
        </>
    )
};

export default ViewEmpowerment;
