import IconButton from "../../common/IconButton";
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Button, Col, Collapse, Form, Row} from 'react-bootstrap';
import ReturnShipmentDataTableFilter from "./ReturnShipmentDataTableFilter";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer"
import {useDispatch, useSelector} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import dayjs from "dayjs";
import {
    changeFilterOptionsAsync,
    changeReturnShipmentDataTableColumn, loadReturnShipmentDataTableColumn,
    selectFilterOptions, STORAGE_RETURN_SHIPMENT
} from "../../../app/store/reducers/return-shipment/returnShipmentReducer";
import UploadReturnShipmentListToExcel from "./excel/UploadReturnShipmentListToExcel";
import DataTableSettings from "../DataTableSettings";


const ReturnShipmentDataTableHeader = ({selectedRowIds}) => {
    const dispatch = useDispatch();
    const filter = useSelector(selectFilterOptions);

    const methods = useForm({
        defaultValues: {
            date_end: filter?.date_end && dayjs(filter?.date_end).toDate() || null,
            date_start: filter?.date_start && dayjs(filter?.date_start).toDate() || null,
            number: filter?.number || null,
            branch_id: filter?.branch_id || null,
            status: typeof (filter?.status) === 'undefined' ? null : filter.status,
        }
    });
    const lang = useSelector(selectLang);
    const t = Translate;

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true);

    const onSubmitFilter = async ({date_start, date_end, updated_on_start, updated_on_end, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD")
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD")
        dispatch(changeFilterOptionsAsync({
            page: 1,
            date_start: date_start,
            date_end: date_end,
            ...filters
        }))
    }


    const allColumns = JSON.parse(localStorage.getItem(STORAGE_RETURN_SHIPMENT))
    if (!allColumns) {
        dispatch(loadReturnShipmentDataTableColumn())
        return <></>
    }

    return (
        <Row className="flex-between-center">
            <Collapse in={isOpenFilterCollapse}>
                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <ReturnShipmentDataTableFilter/>
                        </Form>
                    </FormProvider>
                    <hr/>
                </Col>
            </Collapse>
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.return_shipment.datatable.header.title")}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <Form.Select size="sm" aria-label="Bulk actions">
                            <option>Bulk Actions</option>
                            <option value="refund">Refund</option>
                            <option value="delete">Delete</option>
                            <option value="archive">Archive</option>
                        </Form.Select>
                        <Button
                            type="button"
                            variant="falcon-default"
                            size="sm"
                            className="ms-2"
                        >
                            Apply
                        </Button>
                    </div>
                ) : (
                    <div id="returnReturnShipment-actions">

                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            transform="shrink-3"
                            icon="refresh"
                            className="mx-2"
                            onClick={methods.handleSubmit(onSubmitFilter)}
                        >
                            <span className="d-none d-sm-inline-block ms-1">{t(lang, "edi.orders.order.resume_order_button")}</span>
                        </IconButton>

                        <UploadReturnShipmentListToExcel className="d-inline-block me-2" />

                        <IconButton
                            onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                            variant="falcon-default"
                            size="sm"
                            icon="filter"
                            transform="shrink-3"
                        >
                            <span className="d-none d-sm-inline-block ms-1">{t(lang, "edi.return_shipment.datatable.header.navigation.filter")}</span>
                        </IconButton>
                    </div>
                )}
            </Col>
        </Row>
    );
};

ReturnShipmentDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ReturnShipmentDataTableHeader;
