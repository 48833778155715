import {useFormContext} from "react-hook-form";
import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowDown} from "@fortawesome/free-solid-svg-icons";
import {parseMultiSheetExcelFileAsync} from "../../../../../app/store/reducers/excel/excelReducer";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";

const MassRegisterRoamingWaybillFileUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const lang = useSelector(selectLang);
    const {setValue} = useFormContext();
    const dispatch = useDispatch();
    const t = Translate;

    useEffect(() => {
        if (selectedFile) {
            parseFile();
        }
    }, [selectedFile])

    const onSelectFile = event => {
        const [file] = event.target.files;
        setSelectedFile(file);
    };

    const parseFile = () => {
        dispatch(updateShowInProgressAsync(true));
        readFileAsync().then(parsedContent => {
            setValue('sheets', parsedContent);
        }).catch(() => {
            setValue('sheets', []);
        }).finally(() => {
            dispatch(updateShowInProgressAsync(false));
        })
    }

    const readFileAsync = () => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);

            reader.onload = e => {
                const base64Content = e.target.result;
                setValue('file', {
                    content: base64Content,
                    name: selectedFile.name,
                    size: selectedFile.size,
                });

                parseMultiSheetExcelFileAsync(base64Content)
                    .then(parsedContent => resolve(parsedContent))
                    .catch(error => reject(error))
            }

            reader.onerror = err => {
                reject(err);
            }
        })
    }

    return (
        <Row>
            <Col xs={12}>
                <Card className={'my-3 shadow-none border-dashed bg-light'}>
                    <Form.Control
                        type={'file'}
                        id={'fileUploader'}
                        className={'d-none'}
                        onChange={onSelectFile}
                        accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                    />

                    <Card.Body as={'label'} htmlFor={'fileUploader'} className={'text-center cursor-pointer py-4'}>
                        <h4>
                            <FontAwesomeIcon icon={faCloudArrowDown}/>
                            <span className={'ms-2'}>{t(lang, 'edo.excel_registry.mass_register_waybill.file_uploader.title')}</span>
                        </h4>
                        {selectedFile && <span>{selectedFile?.name}</span>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default MassRegisterRoamingWaybillFileUploader;