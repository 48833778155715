import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getReturnPurchaseItemAsync} from "../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import {
    WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/returnPurchaseEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import ReturnPurchase from "../../../../components/hippo/warehouse-operation/return-purchase/ReturnPurchase";
import {loadContractorAsync, updateCardContractor} from "../../../../app/store/reducers/contractor/contractorReducer";


const ViewReturnPurchase = ({viewId}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [returnPurchase, setReturnPurchase] = useState(null)
    const dispatch = useDispatch()
    const {id} = useParams()


    const loadReturnPurchase = () => {
        getReturnPurchaseItemAsync({returnPurchaseId: viewId || id})
            .then(response => {
                setReturnPurchase(response.data, response?.data?.items?.reverse())
                loadContractorAsync(response?.data?.contractor?.id)
                    .then(cr => {
                        dispatch(updateCardContractor(cr))
                    }).catch(err => console.log(err));
            })
            .catch(error => setReturnPurchase(null))
    }

    useEffect(() => {
        loadReturnPurchase()
    },[activeOrganization, id, viewId])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS, () => {
            loadReturnPurchase()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS, onUpdatePaymentHandler)
        }
    },[])


    if (!returnPurchase) return <> loading... </>



    return(
        <>
            <ReturnPurchase returnPurchase={returnPurchase}/>
        </>
    )
}

export default ViewReturnPurchase;
