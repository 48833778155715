import React from "react";
import {Form} from "react-bootstrap";
import {
    changeNumberFormat,
    NumberFormatOptions,
    selectNumberFormat
} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";


const NumberFormatChooser = () => {
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);

    const onChange = (type) => {
        dispatch(changeNumberFormat(type));
    }

    return (
        <>
            <li className="me-2 d-md-block list-inline">
                <Form.Select size="sm"
                             value={numberFormat}
                             onChange={(event) => onChange(event.target.value)}
                >
                    {NumberFormatOptions.map(format => {
                        return (
                            <option value={format.type} key={format.type}>
                                {format.value}
                            </option>
                        )
                    })}
                </Form.Select>
            </li>
        </>
    )
}

export default NumberFormatChooser