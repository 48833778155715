import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const SelectOrderStrategy = React.forwardRef(({onChange, strategies, ...props}, ref) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [options, setOptions] = useState(null);

    useEffect(() => {
        const translatedStrategies = strategies.map(strategy => {
            return {
                label: t(lang, strategy.name),
                value: strategy.id
            }
        })
        setOptions(translatedStrategies);
    }, [lang])

    const onChangeHandler = option => {
        const strategy = strategies.find(s => {
            return s.id === option.value
        }) || null;

        onChange(strategy);
    };

    return <ReactSelect hideSelectedOptions={true} classNamePrefix="react-select" onChange={onChangeHandler} options={options}  {...props} ref={ref}/>
});

SelectOrderStrategy.propTypes = {
    onChange: PropTypes.func,
    strategies: PropTypes.array
}

export default SelectOrderStrategy;