import React, {useState} from 'react';
import {Col, Form, Row, Spinner} from "react-bootstrap";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import ContractorSelect from "../../../../../common/ContractorSelect";
import SelectApprovedOrNo from "../../../../../../common/SelectApprovedOrNo";
import SelectYesOrNo from "../../../../../SelectYesOrNo";
import IconButton from "../../../../../../common/IconButton";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {selectDateFormat, selectLang, Translate} from "../../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectTelegramOrderBots} from "../../../../../../../app/store/reducers/crm/bot/telegramOrderBotReducer";

const TelegramOrderBotOrdersDataTableFilter = ({
                                                   filters,
                                                   onFilterChange,
                                                   onDebouncedFilterChange,
                                                   loading
                                               }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const telegramOrderBots = useSelector(selectTelegramOrderBots);
    const [filter, setFilter] = useState({
        dateStart: filters.dateStart,
        dateEnd: filters.dateEnd,
        contractorId: filters.contractorId,
        isApproved: filters.isApproved,
        isSaleCreated: filters.isSaleCreated,
        botId: filters.botId,
    });

    const onSubmitFilter = (data) => {
        onFilterChange(data);
    };

    return (
        <Row className="g-2">
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'crm.common.datatable.filter.date_start')}</Form.Label>
                    <DatePicker selected={filter.dateStart ? dayjs(filter.dateStart).toDate() : null}
                                dateFormat={dateFormat}
                                className="form-control"
                                isClearable={true}
                                placeholderText={t(lang, 'crm.common.select')}
                                onChange={(value) => setFilter({
                                    ...filter,
                                    dateStart: value ? dayjs(value).format("YYYY-MM-DD") : null
                                })}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'crm.common.datatable.filter.date_end')}</Form.Label>
                    <DatePicker selected={filter.dateEnd ? dayjs(filter.dateEnd).toDate() : null}
                                dateFormat={dateFormat}
                                className="form-control"
                                isClearable={true}
                                placeholderText={t(lang, 'crm.common.select')}
                                onChange={(value) => setFilter({
                                    ...filter,
                                    dateEnd: value ? dayjs(value).format("YYYY-MM-DD") : null
                                })}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Label>{t(lang, 'crm.common.datatable.filter.contractor')}</Form.Label>
                <ContractorSelect defaultValue={filter.contractorId}
                                  isClearable={true}
                                  onChange={(option) => setFilter({
                                      ...filter,
                                      contractorId: option ? option.id : null
                                  })}
                />
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Label>{t(lang, 'crm.common.datatable.filter.approved_status')}</Form.Label>
                <SelectApprovedOrNo defaultValue={filter.isApproved}
                                    placeholder={t(lang, 'crm.common.select')}
                                    onChange={(option) => setFilter({...filter, isApproved: option.value})}
                />
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Label>{t(lang, 'crm.common.datatable.filter.is_sale_created')}</Form.Label>
                <SelectYesOrNo defaultValue={filter.isSaleCreated}
                               placeholder={t(lang, 'crm.common.select')}
                               onChange={(option) => setFilter({...filter, isSaleCreated: option.value})}
                />
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Label>{t(lang, 'crm.common.datatable.filter.bot_id')}</Form.Label>
                <ReactSelect
                    isClearable={true}
                    options={telegramOrderBots}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    placeholder={t(lang, 'crm.common.select')}
                    onChange={(option) => setFilter({...filter, botId: option?.id})}
                />
            </Col>
            <Col xs={12} className="text-end">
                <IconButton size="md"
                            icon={faEye}
                            disabled={loading}
                            onClick={() => onSubmitFilter(filter)}
                >
                    {t(lang, 'crm.common.show.button')}
                    {loading && <Spinner className="align-middle ms-2" animation="border" role="switch" size="sm"/>}
                </IconButton>
            </Col>
        </Row>
    );
};

export default TelegramOrderBotOrdersDataTableFilter;