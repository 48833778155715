import React from "react";
import PropTypes from "prop-types";
import {Badge, Card, Col, Row, Table} from "react-bootstrap";
import IconButton from "../../common/IconButton";
import {Link} from "react-router-dom";
import {
    AwaitExecutorApprove,
    CustomerEditApprovedByExecutor,
    CustomerEdited,
    ExecutorApproved,
    ExecutorEdited,
    CustomerApproved,
    CustomerDeclined, getStatusColorShipment, GetStatusTextShipment
} from "../../../enum/ShipmentStatus";
import numeral from "numeral";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import ExecutorApproveShipment from "./ExecutorApproveShipment";
import CustomerDeclineShipment from "./CustomerDeclineShipment";
import ShipmentCustomerApprove from "./ShipmentCustomerApprove";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../app/store/reducers/main/mainReducer";
import {
    OnShipmentWaybillCreation,
    AfterConfirmByCustomer
} from "../../../app/order/OrderExecutorCreateInvoiceStrategyType";
import ShipmentPrint from "./ShipmentPrint";
import ShipmentDelete from "./ShipmentDelete";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import UploadShipmentToExcel from "./excel/UploadShipmentToExcel";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {
    PermissionEdiInvoiceCreate, PermissionEdiInvoiceView,
    PermissionEdiOrderView,
    PermissionEdiShipmentUpdate
} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ExecutorCancelRoamingDocument from "./ExecutorCancelRoamingDocument";
import {RoamingWaybillV2StatusExecutorSent} from "../../../enum/roaming/WaybillV2Status";

const Shipment = ({shipment}) => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const subtotal = shipment.items.reduce((acc, item) => acc + item.total, 0);
    const tax = shipment.items.reduce((acc, item) => acc + item.nds_value, 0);
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);

    const activeOrganization = useSelector(selectActiveOrganization);
    const isExecutor = activeOrganization.inn === shipment.executor_info.inn;
    const isCustomer = activeOrganization.inn === shipment.customer_info.inn;
    const executorCancelOldDocument = isExecutor && ((shipment.status === CustomerEdited || shipment.status === CustomerDeclined) && shipment.old_roaming_document?.status === RoamingWaybillV2StatusExecutorSent) || (shipment.status ===  ExecutorApproved && shipment.roaming_document?.status === RoamingWaybillV2StatusExecutorSent);
    const allowCustomerDecline = isCustomer && shipment.status === ExecutorApproved //ExecutorApproved
    let allowExecutorApprove = false

    if (isExecutor) {
        if (!shipment.old_roaming_document || shipment.old_roaming_document.status !== RoamingWaybillV2StatusExecutorSent) {
            if (shipment.status === AwaitExecutorApprove || shipment.status === ExecutorEdited || shipment.status === CustomerEdited) {
                allowExecutorApprove = true
            }
        }

    }

    const allowCustomerApprove = isCustomer &&
        (
            //ExecutorApproved
            shipment.status === ExecutorApproved ||

            //ExecutorEdited
            shipment.status === ExecutorEdited ||

            //CustomerEditApprovedByExecutor
            shipment.status === CustomerEditApprovedByExecutor
        )

    const allowCreateInvoice = isExecutor &&
        (
            (
                shipment.order.executor_create_invoice_strategy === OnShipmentWaybillCreation.id && (
                    // shipment.status === AwaitExecutorApprove ||
                    shipment.status === ExecutorApproved ||
                    // shipment.status === ExecutorEdited ||
                    shipment.status === CustomerApproved ||
                    shipment.status === CustomerEditApprovedByExecutor
                    // shipment.status === CustomerEdited
                )
            ) ||
            (
                shipment.order.executor_create_invoice_strategy === AfterConfirmByCustomer.id && (
                    shipment.status === CustomerApproved ||
                    shipment.status === CustomerEditApprovedByExecutor
                )
            )
        )

    const allowEditShipment =
        (isExecutor && shipment.invoice === null && (shipment.status === AwaitExecutorApprove || shipment.status === ExecutorEdited)) ||
        (isCustomer && (shipment.status === ExecutorApproved));

    let allowDelete = isExecutor && (
        (shipment.status === ExecutorApproved && shipment.order.executor_create_invoice_strategy !== AfterConfirmByCustomer.id) ||
        shipment.status === AwaitExecutorApprove ||
        shipment.status === ExecutorEdited ||
        shipment.status === CustomerDeclined
    )

    return (
        <>
            <Card className="my-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang, "edi.common.created.time")}: {shipment.created_at}</h3>
                    <Col className="text-end">
                        <span className="me-2">{t(lang, "edi.common.status")}: </span>
                        <span className={`p-1 rounded-pill text-white fs--2`}
                              style={{backgroundColor: getStatusColorShipment(shipment.status)}}>{t(lang, GetStatusTextShipment(shipment.status))}</span>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-between">
                    <Col sm={9} className={'d-flex gap-2'}>
                        {allowCreateInvoice &&
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => !dispatch(checkPermission(PermissionEdiInvoiceCreate)) && dispatch(toastPermission())}
                                as={dispatch(checkPermission(PermissionEdiInvoiceCreate)) ? Link : 'div'}
                                to={`../invoice/register/${shipment.id}`}
                            >
                                <span
                                    className="d-none d-sm-inline-block">{t(lang, "edi.common.new_invoice_button")}</span>
                            </IconButton>
                        }
                        {allowEditShipment &&
                            <IconButton
                                as={dispatch(checkPermission(PermissionEdiShipmentUpdate)) ? Link : 'div'}
                                onClick={() => !dispatch(checkPermission(PermissionEdiShipmentUpdate)) && dispatch(toastPermission())}
                                size="sm"
                                icon={faPen}
                                transform="shrink-3"
                                variant="falcon-warning"
                                to={
                                    isExecutor && `../shipment/executor-edit/${shipment.id}` ||
                                    isCustomer && `../shipment/customer-edit/${shipment.id}`
                                }
                            >
                                <span>{t(lang, 'edi.common.button.edit')}</span>
                            </IconButton>
                        }
                        {allowExecutorApprove && <ExecutorApproveShipment id={shipment.id} />}
                        {allowCustomerApprove && <ShipmentCustomerApprove id={shipment.id} isCustomer={isCustomer} />}
                        {allowCustomerDecline && <CustomerDeclineShipment id={shipment.id} />}
                        {executorCancelOldDocument && <ExecutorCancelRoamingDocument id={shipment.id}/>}
                        {allowDelete && <ShipmentDelete id={shipment.id} />}

                        <IconButton
                            onClick={() => !dispatch(checkPermission(PermissionEdiOrderView)) && dispatch(toastPermission())}
                            as={dispatch(checkPermission(PermissionEdiOrderView)) ? Link : 'div'}
                            variant="falcon-default"
                            to={`../order/${shipment.order.id}`}
                            size="sm"
                            icon="eye"
                            transform="shrink-3"
                        >
                            <span className="d-none d-sm-inline-block">{t(lang, "edi.common.order")}</span>
                        </IconButton>

                        {shipment.invoice &&
                            <IconButton
                                onClick={() => !dispatch(checkPermission(PermissionEdiInvoiceView)) && dispatch(toastPermission())}
                                as={dispatch(checkPermission(PermissionEdiInvoiceView)) ? Link : 'div'}
                                to={`../invoice/${shipment.invoice.id}`}
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block">{t(lang, "edi.common.invoice")}</span>
                            </IconButton>
                        }

                        {<ShipmentPrint size="sm" variant="falcon-default" id={shipment.id}/>}
                        <UploadShipmentToExcel id={shipment.id}
                                               number={shipment.shipment_info.number}/>
                    </Col>
                </Row>
            </Card>

            <Card className="p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="fw-bold mb-1">{t(lang, "edi.common.executor")}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {shipment.executor_info.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {shipment.executor_info.inn}</span>
                    </Col>

                    <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="fw-bold mb-1">{t(lang, "edi.common.customer")}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {shipment.customer_info.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {shipment.customer_info.inn}</span>
                    </Col>

                    <Col xs={12} lg={2} className={'d-flex justify-content-end'}>
                        {
                            shipment?.is_customer_system_informed && <div>
                                <Badge pill bg={'info'} className={'fs--1 ms-2'}>
                                    {t(lang, 'edi.shipment.datatable.header.row.is_customer_system_informed')}
                                </Badge>
                            </div>
                        }
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card className="my-3">
                <Card.Header>
                    <h5 className="p-0"><b className="pe-1">{t(lang, "edi.common.id")}:</b>{shipment.id}</h5>

                    <Row className="my-3">
                        <h3 className="text-center">{t(lang, "edi.common.shipment")}</h3>
                        <Col className="d-flex align-items-center justify-content-around">
                            <div>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold mb-1">{t(lang, "edi.common.shipment.number")}: </span>
                                    <span>{shipment.shipment_info.number}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.shipment.date")}: </span>
                                    <span>{dayjs(shipment.shipment_info.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                </p>
                                <p className="mb-1">
                                    <span
                                        className="fs-0 fw-bold">{t(lang, "edi.shipment.shipment_info.customer_shipment_id")}: </span>
                                    <span>{shipment.order.customer_shipment_id}</span>
                                </p>
                            </div>
                            <div>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.contract.number")}: </span>
                                    <span>{shipment.contract_info.number}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.contract.date")}: </span>
                                    <span>{dayjs(shipment.contract_info.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.shipping_address")}: </span>
                                    <span>{shipment.delivery_address}</span>
                                </p>
                            </div>
                        </Col>
                    </Row>

                </Card.Header>
                <Card.Footer>
                    <Table bordered responsive className="border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th rowSpan={2}>№</th>
                            <th className="text-start">{t(lang, "edi.common.items.product")}</th>
                            <th className="text-center">{t(lang, "edi.common.customer_shipment_id")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.catalog_class_code")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.product_code")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.barcode")}</th>
                            <th className="text-center">{t(lang, "roaming.invoice.mark")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.measurement")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.quantity")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.price")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.total")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.nds")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.nds_value")}</th>
                            <th className="text-end">{t(lang, "edi.common.items.product.total_with_nds")}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                            <th className="p-0 text-center">5</th>
                            <th className="p-0 text-center">6</th>
                            <th className="p-0 text-center">7</th>
                            <th className="p-0 text-center">8</th>
                            <th className="p-0 text-center">9</th>
                            <th className="p-0 text-center">10</th>
                            <th className="p-0 text-center">11</th>
                            <th className="p-0 text-center">12</th>
                            <th className="p-0 text-center">13</th>
                        </tr>
                        </thead>
                        <tbody>
                        {shipment.items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-start">{item.product}</td>
                                    <td className="text-center">{item.order_item.customer_shipment_id}</td>
                                    <td className="text-center">{item.catalog_class_code || item.order_item.catalog_class_code}</td>
                                    <td className="text-center">{item.product_code || item.order_item.product_code}</td>
                                    <td className="text-center">{item.barcode || item.order_item.barcode}</td>
                                    <td className="text-center">
                                        {
                                            <ol className={'mb-0'}>
                                                {item.marks?.map(mark => (
                                                    <li className={'text-start'} key={mark}>{mark}</li>
                                                ))}
                                            </ol>
                                        }
                                    </td>
                                    <td className="text-center">{item.package_code || item.measurement}</td>
                                    <td className="text-center">{item.quantity}</td>
                                    <td className="text-center">{numeral.formats[numberFormat].format(item.price)}</td>
                                    <td className="text-center">{numeral.formats[numberFormat].format(item.total)}</td>
                                    <td className="text-center">{item.nds_rate}</td>
                                    <td className="text-center">{numeral.formats[numberFormat].format(item.nds_value)}</td>
                                    <td className="text-end">{numeral.formats[numberFormat].format(item.total_with_nds)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, "edi.common.subtotal")}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(subtotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900">{t(lang, "edi.common.tax")}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(tax)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, "edi.common.total")}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(subtotal + tax)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </>
    )
};

Shipment.propTypes = {
    shipment: PropTypes.object,
};

Shipment.defaultProps = {
    shipment: {},
};
export default Shipment;
