import React from 'react';
import {Card, Col, Row, Table} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useFieldArray, useFormContext} from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CatalogLinkSelector from "../../../../common/CatalogLinkSelector";
import classNames from "classnames";
import VatRateLinkSelector from "../../../../common/VatRateLinkSelector";
import RoamingActFormItemRow from "./RoamingActFormItemRow";

const RoamingActFormCardItems = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const {fields, append, insert, remove,} = useFieldArray({name: 'product_list.items'});
    const addNewItem = () => {
        append({
            catalog: null,
            package: null,
            measurement: null,
            name: '',
            quantity: '',
            price: '',
            total: '',
            vatRate: '',
            vatSum: '',
            total_with_vat_sum: '',
        });
    };
    const insertNewItem = (index) => {
        insert(index, {
            catalog: null,
            package: null,
            measurement: null,
            name: '',
            quantity: '',
            price: '',
            total: '',
            vatRate: '',
            vatSum: '',
            total_with_vat_sum: '',
        });
    };
    const deleteItem = (index) => {
        remove(index);
    };

    const onBulkSetCatalog = (value) => {
        fields.map((field, index) => {
            form.setValue(`product_list.items.${index}.catalog`, value);
            form.setValue(`product_list.items.${index}.package`, null);
            form.setValue(`product_list.items.${index}.measurement`, null);
        });
    };
    const onBulkSetVatRate = (value) => {
        fields.map((field, index) => {
            form.setValue(`product_list.items.${index}.vat_rate`, value);
        });
    };

    return (
        <Card className="h-100">
            <Card.Body>
                <Row className="gy-2">
                    <Col xs={12} className="text-end">
                        <IconButton icon={faPlus}
                                    variant={'falcon-primary'}
                                    onClick={addNewItem}
                        >
                            {t(lang, 'roaming.common.add')}
                        </IconButton>
                    </Col>
                    <Col xs={12}>
                        <Table responsive striped bordered className="border-300">
                            <thead className="bg-200 text-dark">
                            <tr className="position-relative">
                                <th style={{minWidth: '30px', width: '30px'}}
                                    className="p-0 align-middle text-center">#
                                </th>
                                <th className="p-0 align-middle text-center">
                                    {t(lang, 'roaming.common.items.product')}
                                    <span className="text-danger">*</span>
                                </th>
                                <th style={{minWidth: '150px', width: '150px'}}
                                    className="p-0 align-middle text-center">
                                    <div className="d-flex flex-column">
                                            <span>
                                                {t(lang, 'roaming.common.items.catalog_class_code')}
                                                <span className="text-danger">*</span>
                                            </span>
                                        <CatalogLinkSelector defaultCatalog={null}
                                                             onChange={onBulkSetCatalog}
                                                             linkProps={{
                                                                 className: classNames('text-primary cursor-pointer fs--1')
                                                             }}
                                        />
                                    </div>
                                </th>
                                <th style={{minWidth: '150px', width: '150px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, 'roaming.common.items.measurement')}
                                    <span className="text-danger">*</span>
                                </th>
                                <th style={{minWidth: '120px', width: '120px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, "roaming.common.items.quantity")}
                                    <span className="text-danger">*</span>
                                </th>
                                <th style={{minWidth: '140px', width: '140px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, "roaming.common.items.price")}
                                    <span className="text-danger">*</span>
                                </th>
                                <th style={{minWidth: '150px', width: '150px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, "roaming.invoice.items.total")}
                                </th>
                                <th style={{minWidth: '60px', width: '60px'}} className="p-0 align-middle text-center">
                                    <div className="d-flex flex-column">
                                        <span>{t(lang, "roaming.common.items.nds")}</span>
                                        <VatRateLinkSelector defaultVatRate={''}
                                                             onChange={onBulkSetVatRate}
                                                             linkProps={{
                                                                 className: classNames('text-primary cursor-pointer fs--1')
                                                             }}
                                        />
                                    </div>
                                </th>
                                <th style={{minWidth: '130px', width: '130px'}}
                                    className="p-0 align-middle text-center">{t(lang, "roaming.common.items.nds_value")}</th>
                                <th style={{minWidth: '160px', width: '160px'}}
                                    className="p-0 align-middle text-center">{t(lang, "roaming.common.items.total_with_nds")}</th>
                                <th style={{minWidth: '30px', width: '30px'}}
                                    className="p-0 align-middle text-center"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {fields.map((field, index) => (
                                <RoamingActFormItemRow key={field.id}
                                                       fieldName={`product_list.items.${index}`}
                                                       index={index}
                                                       insertNewItem={insertNewItem}
                                                       deleteItem={deleteItem}
                                                       allowDeleteItem={fields.length > 1}
                                />
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default RoamingActFormCardItems;