import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {loadDraftItemAsync} from "../../../app/store/reducers/draft-item/draftItemReducer";
import ApproveDraftItemForm from "../../../components/hippo/draft-item/components/form/ApproveDraftItemForm";

const ApproveDraftItem = () => {
    const {id} = useParams();
    const activeOrganization = useSelector(selectActiveOrganization);
    const [draftItem, setDraftItem] = useState(null);

    useEffect(() => {
        loadDraftItemAsync(id)
            .then((data) => {
                setDraftItem(data);
            })
            .catch(() => {
                setDraftItem(null);
            })
    }, [id, activeOrganization])

    if (!draftItem) return null;

    return <ApproveDraftItemForm draftItem={draftItem}/>
};

export default ApproveDraftItem;