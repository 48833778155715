import React from 'react';
import {useFieldArray, useFormContext} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import IconButton from "../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import classNames from "classnames";

const DescriptionAttributesRow = ({fieldName}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const {fields, append, remove} = useFieldArray({name: fieldName})
    const addNewDescriptionAttribute = () => append({key: "", value: ""});
    const deleteDescriptionAttribute = (index) => remove(index);


    return (
        <React.Fragment>
            <h6 className="text-info cursor-pointer d-inline-block" onClick={addNewDescriptionAttribute}>{t(lang, 'items.common.add_characteristics')}</h6>
            <Row className="gy-3">
                {fields.map((field, index) => {
                    const {error} = form.getFieldState(`${fieldName}.${index}`);
                    return (
                        <React.Fragment key={field.id}>
                            <Col xs={12} sm={5} md={12} lg={5}>
                                <Form.Group>
                                    {index === 0 && <Form.Label>
                                        {t(lang, 'items.common.characteristics.key')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>}
                                    <Form.Control
                                        name={`${fieldName}.${index}.key`}
                                        isInvalid={error?.key}
                                        placeholder={t(lang, 'items.common.characteristics.key.placeholder')}
                                        {...form.register(`${fieldName}.${index}.key`, {
                                            required: t(lang, 'items.common.validations.is_required')
                                        })}
                                    />
                                    <ErrorMessage name='key'
                                                  errors={error}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={5} md={12} lg={5}>
                                <Form.Group>
                                    {index === 0 && <Form.Label>
                                        {t(lang, 'items.common.characteristics.value')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>}
                                    <Form.Control
                                        name={`${fieldName}.${index}.key`}
                                        isInvalid={error?.value}
                                        placeholder={t(lang, 'items.common.characteristics.value.placeholder')}
                                        {...form.register(`${fieldName}.${index}.value`, {
                                            required: t(lang, 'items.common.validations.is_required')
                                        })}
                                    />
                                    <ErrorMessage name='value'
                                                  errors={error}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={2} md={12} lg={2}>
                                <IconButton
                                    variant="falcon-default"
                                    icon={faTrash}
                                    iconClassName={"text-danger"}
                                    className={classNames({'mt-4': index === 0})}
                                    onClick={() => deleteDescriptionAttribute(index)}
                                />
                            </Col>
                        </React.Fragment>
                    )
                })}
            </Row>

        </React.Fragment>
    );
};

export default DescriptionAttributesRow;