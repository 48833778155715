import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import {Button, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import {bindItem} from "../../../../app/store/reducers/organization-binding/organizationBindingReducer";
import SearchItem from "../../draft-item/components/SearchItem";
import SelectOwnerBindings from "./SelectOwnerBindings";
import IconButton from "../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const BindItemsToOrganizationsForm = ({items = [], handleClose}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useForm({
        defaultValues: {
            organizations: [
                {id: null}
            ],
            items: items.map(({item}) => ({id: item.id})) || [
                {id: null}
            ]
        }
    });
    const {
        fields: organizationsFields,
        append: addOrganization,
        remove: removeOrganization
    } = useFieldArray({control: form.control, name: 'organizations'});
    const {
        fields: itemsFields,
        append: addItem,
        remove: removeItem
    } = useFieldArray({control: form.control, name: 'items'});


    const onSubmit = (formData) => {
        formData.organizations.forEach((org) => {
            formData.items.forEach(async (item) => {
                const payload = {
                    organization_binding_id: org.id,
                    item_id: item.id
                }

                await bindItem(payload)
            })
        });
        toast.success(t(lang, 'edi.common.toast.success'))
        handleClose();
    };

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <FormProvider {...form}>
                <Row>
                    <Col xs={12} lg={6}>
                        <div className="d-flex flex-column">
                            <IconButton size="sm"
                                        variant="falcon-primary"
                                        icon="fa-plus"
                                        onClick={() => addOrganization({id: null})}
                            >
                                {t(lang, "items.common.add")}
                            </IconButton>
                            <Form.Group className="mt-3">
                                <Form.Label>{t(lang, 'admin.dashboard.organization.title')}</Form.Label>
                                <Row className="gy-2">
                                    {organizationsFields.map((field, index) => (
                                        <Col xs={12} key={field.id}>
                                            <Controller name={`organizations.${index}.id`}
                                                        rules={{
                                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                                        }}
                                                        render={({field, fieldState: {error}}) => (
                                                            <>
                                                                <InputGroup>
                                                                    <div className="react-select form-control p-0">
                                                                        <SelectOwnerBindings defaultBindingId={field.value}
                                                                                             onChange={(option) => field.onChange(option ? option.id : null)}
                                                                                             selectProps={{
                                                                                                 styles: {
                                                                                                     control: base => ({
                                                                                                         ...base,
                                                                                                         border: error?.message && '1px solid #e63757'
                                                                                                     }),
                                                                                                     container: (provided) => ({
                                                                                                         ...provided,
                                                                                                         margin: '-1px'
                                                                                                     })
                                                                                                 }
                                                                                             }}
                                                                        />
                                                                    </div>
                                                                    {organizationsFields.length > 1 &&
                                                                        <IconButton variant="falcon-danger"
                                                                                    icon={faTrash}
                                                                                    onClick={() => removeOrganization(index)}
                                                                        />
                                                                    }
                                                                </InputGroup>
                                                                {error?.message && <span className="text-danger fs--1">{error.message}</span>}
                                                            </>

                                                        )}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Form.Group>
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className="d-flex flex-column">
                            <IconButton size="sm"
                                        variant="falcon-primary"
                                        icon="fa-plus"
                                        onClick={() => addItem({id: null})}
                            >
                                {t(lang, "items.common.add")}
                            </IconButton>
                            <Form.Group className="mt-3">
                                <Form.Label>{t(lang, 'items.table.title')}</Form.Label>
                                <Row className="gy-2">
                                    {itemsFields.map((field, index) => (
                                        <Col xs={12} key={field.id}>
                                            <Controller name={`items.${index}.id`}
                                                        rules={{
                                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                                        }}
                                                        render={({field, fieldState: {error}}) => (
                                                            <>
                                                                <InputGroup>
                                                                    <div className="react-select form-control p-0">
                                                                        <SearchItem defaultItemId={field.value}
                                                                                    onChange={(option) => field.onChange(option ? option.item.id : null)}
                                                                                    selectProps={{
                                                                                        styles: {
                                                                                            control: base => ({
                                                                                                ...base,
                                                                                                border: error?.message && '1px solid #e63757'
                                                                                            }),
                                                                                            container: (provided) => ({
                                                                                                ...provided,
                                                                                                margin: '-1px'
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                        />
                                                                    </div>
                                                                    {itemsFields.length > 1 &&
                                                                        <IconButton variant="falcon-danger"
                                                                                    icon={faTrash}
                                                                                    onClick={() => removeItem(index)}
                                                                        />
                                                                    }
                                                                </InputGroup>
                                                                {error?.message && <span className="text-danger fs--1">{error.message}</span>}
                                                            </>
                                                        )}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Form.Group>
                        </div>
                    </Col>
                    <Col xs={12} className="text-end mt-3">
                        <Button variant="secondary"
                                onClick={handleClose}
                                className="mx-2"
                        >
                            {t(lang, 'items.common.cancel')}
                        </Button>
                        <Button variant="primary"
                                disabled={form.formState.isSubmitting}
                                type="submit"
                        >
                            {form.formState.isSubmitting && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {t(lang, 'edi.common.approve')}
                        </Button>
                    </Col>
                </Row>
            </FormProvider>
        </Form>
    );
};

export default BindItemsToOrganizationsForm;