import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import {DRAFT} from "../../../../enum/RoamingInvoiceWrapper";
import {formatBytes} from "../../../../helpers/byte-formatter";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import {excelFileUploader} from "../../../../helpers/excel-uploader";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import RoamingInvoiceDataTable from "../../roaming/invoice/datatable/RoamingInvoiceDataTable";
import {GetStatusColor, GetStatusText, StatusFinished} from "../../../../enum/ExcelRegistryStatus";
import {
    uploadFailedDocumentsToExcelAsync,
    uploadSuccessDocumentsToExcelAsync
} from "../../../../app/store/reducers/excel-registry/excelRegistryReducer";
import {loadInvoicesAsync, loadInvoicesCountAsync} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {ExcelRegistryTypeInvoice, ExcelRegistryTypeWaybill} from "../../../../enum/ExcelRegistry";
import WaybillV2DataTable from "../../roaming/waybill/datatable/WaybillV2DataTable";
import {VIEW_DRAFTS} from "../../../../enum/roaming/WaybillStatus";
import roamingWaybillV2Service from "../../../../services/roaming/roamingWaybillV2Service";

const ExcelRegistry = ({registry}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);

    const [data, setData] = useState([])
    const [filterOptions, setFilterOptions] = useState({page: 1, limit: 10})
    const [count, setCount] = useState(0)

    const predefinedFilters = useMemo(() => {
        return {
            registry_id: registry.id
        }
    }, [registry.id])

    const showResults = registry.status === StatusFinished;
    const allowUploadFailedDocuments = registry.status === StatusFinished && registry.result.failures > 0;
    const allowUploadSuccessDocuments = registry.status === StatusFinished && registry.result.success > 0;

    const registryTypeInvoice = registry.type === ExcelRegistryTypeInvoice;
    const registryTypeWaybill = registry.type === ExcelRegistryTypeWaybill;

    useEffect(() => {
        setData([])
        setCount(0)
        setFilterOptions({page: 1, limit: 10})
    }, []);

    const loadDraftInvoice = () => {
        loadInvoicesAsync(DRAFT, {...filterOptions, ...predefinedFilters, viewer_inn: activeOrganization.pinfl || activeOrganization.inn})
            .then(response => setData(response))
            .catch(() => setData([]))
    }

    const loadDraftInvoiceCount = () => {
        loadInvoicesCountAsync(DRAFT, {...filterOptions, ...predefinedFilters, viewer_inn: activeOrganization.pinfl || activeOrganization.inn})
            .then(response => setCount(response))
            .catch(() => setCount(0))
    }

    // load invoice
    const loadInvoices = () => {
        loadDraftInvoice()
        loadDraftInvoiceCount()
    }

    // load waybills
    const loadWaybillsAsync = () => {
        const skip = (filterOptions.page - 1) * filterOptions.limit;
        const params = {skip, limit: filterOptions.limit, ...filterOptions};
        roamingWaybillV2Service.getWaybillV2Drafts({...params, ...predefinedFilters})
            .then(res => setData(res.data))
            .catch(() => setData([]))
        roamingWaybillV2Service.getWaybillV2DraftsCount({...filterOptions})
            .then(res => setCount(res.data))
            .catch(() => setCount(0))
    }

    // filter option
    const changeFilterOptionsAsync = (filters)  => {
        setFilterOptions({...filterOptions, ...filters})
    }

    // pagination option
    const onPageChange = (page) => changeFilterOptionsAsync({...filterOptions, page: page});
    const onLimitChange = (limit) => changeFilterOptionsAsync({...filterOptions, limit: limit, page: 1});

    return (
        <>
            <Card className="mb-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang, 'roaming.excel_registry.view.created_time')}: {registry.created_at}</h3>
                    <Col className="text-end">
                        <span className="me-2">{t(lang, 'roaming.excel_registry.view.status')}: </span>
                        <span className={`p-1 rounded-pill text-white fs--2`} style={{backgroundColor: GetStatusColor(registry.status)}}>
                            {t(lang, GetStatusText(registry.status))}
                        </span>
                    </Col>
                </Row>
            </Card>

            <Card className="my-3 p-3">
                <Row>
                    <Col className="mb-3 mb-md-0" xs={12} md={6}>
                        <h4>{t(lang, 'roaming.excel_registry.view.file_name')}: <span className="fw-semi-bold">{registry.file.name}</span></h4>
                        <h6 className="mb-1">{t(lang, 'roaming.excel_registry.view.file_size')}: <span className="fw-semi-bold">{formatBytes(registry.file.size)}</span></h6>
                    </Col>
                    {showResults && (
                        <Col xs={12} md={6}>
                            <div className="d-flex align-items-center">
                                <span className="dot bg-success"/>
                                <p className="mb-0 fw-semi-bold d-inline-block border-bottom border-success border-2">{t(lang, 'roaming.excel_registry.view.success_count')}:
                                    <span className="fw-bolder ms-1">{registry.result.success}</span>
                                    {allowUploadSuccessDocuments && <UploadSuccessDocumentsToExcel id={registry.id} />}
                                </p>
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="dot bg-danger"/>
                                <p className="mb-0 fw-semi-bold d-inline-block border-bottom border-danger border-2">{t(lang, 'roaming.excel_registry.view.failures_count')}:
                                    <span className="fw-bolder ms-1">{registry.result.failures}</span>
                                    {allowUploadFailedDocuments && <UploadFailedDocumentsToExcel id={registry.id}/>}
                                </p>
                            </div>
                        </Col>
                    )}
                </Row>
            </Card>

            {registryTypeInvoice && <RoamingInvoiceDataTable
                viewer={DRAFT}
                predefinedFilters={predefinedFilters}
                roamingInvoices={data}
                count={count}
                filters={filterOptions}
                loadInvoices={loadInvoices}
                changeFilterOptionsAsync={changeFilterOptionsAsync}
                onPageChange={onPageChange}
                onLimitChange={onLimitChange}
                excelRegistry
            />}

            {registryTypeWaybill && <WaybillV2DataTable
                changeFilterOptionsAsync={changeFilterOptionsAsync}
                viewer={VIEW_DRAFTS}
                waybills={data}
                predefinedFilters={predefinedFilters}
                loadWaybills={loadWaybillsAsync}
                onPageChange={onPageChange}
                filter={filterOptions}
                onLimitChange={onLimitChange}
                excelRegistry
            />}
        </>
    );
};

const UploadFailedDocumentsToExcel = ({id}) => {
    const uploadHandler = () => {
        uploadFailedDocumentsToExcelAsync(id)
            .then(loadedContent => {
                excelFileUploader({
                    content: loadedContent,
                    fileName: "FailedDocuments"
                })
            })
            .catch()
    }

    return <FontAwesomeIcon onClick={uploadHandler} className="ms-1 cursor-pointer" icon={faCloudArrowUp}/>
}

const UploadSuccessDocumentsToExcel = ({id}) => {
    const uploadHandler = () => {
        uploadSuccessDocumentsToExcelAsync(id)
            .then(loadedContent => {
                excelFileUploader({
                    content: loadedContent,
                    fileName: "SuccessDocuments"
                })
            })
            .catch()
    }

    return <FontAwesomeIcon onClick={uploadHandler} className="ms-1 cursor-pointer" icon={faCloudArrowUp}/>
}

export default ExcelRegistry;
