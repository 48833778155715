import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import {Spinner, Table} from 'react-bootstrap';

const AdvanceTable = ({
                          getTableProps,
                          headers,
                          page,
                          prepareRow,
                          headerClassName,
                          isLoading,
                          rowClassName,
                          tableProps,
                      }) => {

    const getRowClass = (row) => {
        return row.original.highlight ? row.original.highlight : '';
    };

    return (
        <SimpleBarReact>
            {isLoading && <div className="text-center">
                <Spinner className="mx-auto" variant="primary" animation="border" role="switch"/>
            </div>}
            {!isLoading && <Table {...getTableProps(tableProps)}>
                <thead className={headerClassName}>
                <tr>
                    {headers.map((column, index) => (
                        <th
                            key={index}
                            {...column.getHeaderProps(
                                column.getSortByToggleProps(column.headerProps)
                            )}
                            // colSpan={index === 5 && 2}
                        >
                            {column.render('Header')}
                            {column.canSort ? (
                                column.isSorted ? (
                                    column.isSortedDesc ? (
                                        <span className="sort desc"/>
                                    ) : (
                                        <span className="sort asc"/>
                                    )
                                ) : (
                                    <span className="sort"/>
                                )
                            ) : (
                                ''
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr key={i} className={`hover-actions-trigger ${rowClassName}`} {...row.getRowProps()} style={{backgroundColor: getRowClass(row)}}>
                            {row.cells.map((cell, index) => {
                                return (
                                    <td
                                        key={index}
                                        {...cell.getCellProps(cell.column.cellProps)}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                {!!headers.find((col) => col?.totalInfo) &&
                    <tr>
                        {headers.map((col, index) => {
                            return (
                                <td key={index} className={col.totalInfoClassName}>
                                    {col.totalInfo}
                                </td>
                            )
                        })}
                    </tr>
                }
                </tbody>
            </Table>}
        </SimpleBarReact>
    );
};
AdvanceTable.propTypes = {
    getTableProps: PropTypes.func,
    headers: PropTypes.array,
    page: PropTypes.array,
    prepareRow: PropTypes.func,
    headerClassName: PropTypes.string,
    rowClassName: PropTypes.string,
    tableProps: PropTypes.object,
    isLoading: PropTypes.bool
};

AdvanceTable.defaultProps = {
    isLoading: false
}

export default AdvanceTable;
