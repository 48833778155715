import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useParams} from "react-router";
import {VIEW_INBOX, VIEW_OUTBOX} from "../../../../../../enum/roaming/WaybillStatus";
import {excelFileUploader} from "../../../../../../helpers/excel-uploader";
import {
    selectWaybillsV2FilterOptions,
    uploadCustomerWaybillV2ToExcelAsync,
    uploadCustomerWaybillV2WithItemsToExcelAsync,
    uploadExecutorWaybillV2ToExcelAsync, uploadExecutorWaybillV2WithItemsToExcelAsync
} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import {Dropdown, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";

const UploadWaybillV2ListToExcel = ({...props}) => {


    const [loading, setLoading] = useState(false);
    const [shouldLoadAll, setShouldLoadAll] = useState(false);
    const filter = {...useSelector(selectWaybillsV2FilterOptions)};
    const {viewer} = useParams();
    const lang = useSelector(selectLang);
    const t = Translate;

    const uploadExcel = () => {
        setLoading(true);

        if (shouldLoadAll)
            filter.limit = null;

        if (viewer === VIEW_INBOX) {
            uploadCustomerWaybillV2ToExcelAsync({...filter})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.waybill.v2.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else if (viewer === VIEW_OUTBOX) {
            uploadExecutorWaybillV2ToExcelAsync({...filter})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.waybill.v2_invoices.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }


    const uploadExcelWithItems = () => {
        setLoading(true);

        if (shouldLoadAll)
            filter.limit = null;

        if (viewer === VIEW_INBOX) {
            uploadCustomerWaybillV2WithItemsToExcelAsync({...filter})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.waybill.v2_with_items.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else if (viewer === VIEW_OUTBOX) {
            uploadExecutorWaybillV2WithItemsToExcelAsync({...filter})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'roaming.waybill.v2_invoices_with_items.excel_upload.file_name'),
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }

    return (
        <Fragment>
            <Dropdown {...props}>
                <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                    {loading
                        ?
                        <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                        :
                        <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                    }
                    <span className="d-none d-sm-inline-block">
                        {t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.export_to_excel')}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-2'>
                    <Form.Switch label={t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.download_all')}
                                 onChange={(e) => setShouldLoadAll(e.target.checked)}
                                 className="ps-5 text-black"
                    />
                    <Dropdown.Header className="text-black">{t(lang, 'roaming.waybill.v2.datatable.header.navigation.dropdown.item.export_orders_to_excel')}</Dropdown.Header>
                    <Dropdown.Item className="ps-4" onClick={uploadExcel}>{t(lang, 'common.excel.template.name.default')}</Dropdown.Item>
                    <Dropdown.Header className="text-black">{t(lang, 'roaming.waybill.v2.datatable.header.navigation.dropdown.item.export_orders_with_items_to_excel')}</Dropdown.Header>
                    <Dropdown.Item className="ps-4" onClick={uploadExcelWithItems}>{t(lang, 'common.excel.template.name.default')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    );
};

export default UploadWaybillV2ListToExcel;
