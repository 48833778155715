import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Form, Modal} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import ContractorLabelForm from "./ContractorLabelForm";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import React, {cloneElement, Fragment, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {editContractorLabelAsync} from "../../../../../app/store/reducers/contractor/contractorLabelReducer";

const defaultTextColor = '#1c4f93';
const defaultBackgroundColor = '#d5e5fa';

const EditContractorLabelForm = ({contractorLabel, children, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const form = useForm({
        defaultValues: {
            name: "",
            textColor: defaultTextColor,
            backgroundColor: defaultBackgroundColor,
        },
    });

    const handleShow = () => {
        initDefaultValues();
        setShow(true);
    };

    const handleClose = () => {
        form.reset();
        setShow(false);
    };

    const initDefaultValues = () => {
        form.setValue('name', contractorLabel.name);

        if (contractorLabel.text_color) {
            form.setValue('textColor', contractorLabel.text_color);
        }

        if (contractorLabel.background_color) {
            form.setValue('backgroundColor', contractorLabel.background_color);
        }
    };

    const onSubmit = (formData) => {
        const payload = {
            name: formData.name,
            text_color: formData.textColor === defaultTextColor ? null : formData.textColor,
            background_color: formData.backgroundColor === defaultBackgroundColor ? null : formData.backgroundColor,
        };

        setLoading(true);
        editContractorLabelAsync(contractorLabel.id, payload)
            .then(() => {
                toast.success(t(lang, 'common.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')))
            .finally(() => setLoading(false))
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton icon={'plus'} variant={'falcon-default'} size={"sm"} onClick={handleShow} {...props}>{t(lang, "edi.common.edit_button")}</IconButton>
            )}

            <Modal show={show} onHide={handleClose} size={'md'}>
                <FormProvider {...form}>
                    <Form onSubmit={event => {
                        event.stopPropagation();
                        return form.handleSubmit(onSubmit)(event);
                    }}>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{t(lang, "crm.contractor.label.edit_form_title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ContractorLabelForm/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'secondary'} onClick={handleClose}>{t(lang, "edi.common.cancel_button")}</Button>
                            <Button variant={'primary'} type={'submit'} disabled={loading}>
                                {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                {t(lang, "edi.common.button.save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    )
}
export default EditContractorLabelForm