import React, {Fragment, useEffect, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {loadWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {loadWaybillV2Async} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import Waybill from "../../../../components/roaming/waybill/view/Waybill";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import WaybillV2 from "../../../../components/roaming/waybill/view/WaybillV2";

const ViewWaybillV2 = ({modalId}) => {

    const {params: {id}} = useRouteMatch();
    const [waybill, setWaybill] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        loadWaybill();
    }, [modalId, id, activeOrganization])

    const loadWaybill = () => {
        loadWaybillV2Async(modalId ? modalId : id).then(loadedWaybill => {
            setWaybill(loadedWaybill)
        }).catch(error => console.log(error))
    }

    return (
        <Fragment>
            {!waybill && <SpinnerComponent />}
            {waybill && <WaybillV2 waybill={waybill} loadWaybill={loadWaybill} />}
        </Fragment>
    );
};

export default ViewWaybillV2;
