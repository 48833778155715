import axios from "axios";

class BillingService {
    getOrganizationBillingInfo() {
        return axios.get(`/billing/organization/get`)
    }

    getBillingPackages() {
        return axios.get('/billing/package/get')
    }

    activatePackage({packageId}) {
        return axios.post('/billing/package/buy', {
            package_id: packageId,
        })
    }

    activateDemoPeriod({serviceCode}) {
        return axios.post('/billing/demo/activate', {
            service_code: serviceCode,
        })
    }

    getEImzoSigners() {
        return axios.get('/roaming/admin/eimzo-signers/get')
    }

    registerEImzoSigner(params) {
        return axios.post(`/roaming/admin/eimzo-signers/register`, params)
    }

    getSystemOrganizations(params) {
        return axios.get('/billing/admin/organization', {
            params: params
        })
    }

    getSystemOrganizationsCount(params) {
        return axios.get('/billing/admin/organization/count', {
            params: params,
        })
    }

    addOrganizationBalance({id, balance}) {
        return axios.post(`/billing/admin/payment/add-balance/${id}`, {
            balance: balance,
        })
    }

    applyCreditIntoOrganization({id, amount}) {
        return axios.post(`/billing/admin/organization/update-credit-amount/${id}`, {
            credit_amount: +amount,
        })
    }

    getSystemOrganization(id) {
        return axios.get(`/billing/admin/organization/${id}`)
    }

    getPayments(params) {
        return axios.get('/billing/admin/payment', {
            params
        });
    }

    getPaymentsCount(params) {
        return axios.get('/billing/admin/payment/count', {
            params: params,
        })
    }

    getBankPaymentsError(params) {
        return axios.get('/billing/admin/bank-auto-payment-error', {
            params
        });
    }

    getBankPaymentsErrorCount(params) {
        return axios.get('/billing/admin/bank-auto-payment-error/count', {
            params: params,
        })
    }
    resolveBankPaymentsError(id) {
        return axios.post(`/billing/admin/bank-auto-payment-error/resolve/${id}`)
    }


    getOwnPayments(params) {
        return axios.get('/billing/organization/payment', {
            params
        })
    }
    getOwnPaymentsCount() {
        return axios.get('/billing/organization/payment/count')
    }

    getBought(params) {
        return axios.get('/billing/organization/bought-packages', {
            params
        })
    }

    getBoughtCount() {
        return axios.get('/billing/organization/bought-packages/count')
    }

    getCharges(params) {
        return axios.get('/billing/organization/charge', {
            params
        })
    }

    getChargesCount(params) {
        return axios.get('/billing/organization/charge/count', {
            params
        })
    }

    // admin
    getChargesAdmin(params) {
        return axios.get('/billing/admin/organization/charge', {
            params
        })
    }

    getChargesAdminCount(params) {
        return axios.get('/billing/admin/organization/charge/count', {
            params
        })
    }

    getBoughtAdmin(params) {
        return axios.get('/billing/admin/organization/bought-packages', {
            params
        })
    }

    getBoughtAdminCount(params) {
        return axios.get('/billing/admin/organization/bought-packages/count', {
            params
        })
    }


    setAutoExtendPackage(params) {
        return axios.post('/billing/active-package/set-auto-extend',{
            active_package_id : params.active_package_id,
            is_auto_extend: params.is_auto_extend
        })
    }

    // credentials
    getCredentials(params) {
        return axios.get('/auth/admin/client-credentials/get', {params})
    }

    getCredentialsCount(params) {
        return axios.get('/auth/admin/client-credentials/count', {params})
    }

    registerCredential(payload) {
        return axios.post('/auth/admin/client-credentials/register', payload)
    }

    removeServiceDemoUses(payload) {
        return axios.post('/billing/admin/organization/remove-service-demo-use', payload)
    }

    // report
    getReport(params) {
        return axios.get('/billing/admin/report/services-usage-period-report', {params})
    }
}

const billingService = new BillingService();

export default billingService;
