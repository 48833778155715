import React, {Fragment, memo, useState} from 'react';
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {updateWarehouseItemAlertOn} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {toast} from "react-toastify";
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import Cleave from "cleave.js/react";
import IconButton from "../../../common/IconButton";
import SelectWarehouse from "./SelectWarehouse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const ItemsAlertOn = () => {
    const {fields,replace} = useFieldArray({name: 'itemAlertOn'});

    const onSuccessUpdated = (index, item) => {
        const updatedFields = [...fields]
        updatedFields[index] = item
        replace(updatedFields)
    }

    return (
        <Fragment>
            {fields.map((item, index) => <ItemAlertOnState
                key={item.id}
                index={index}
                item={item}
                onSuccessUpdated={onSuccessUpdated}
            />)}
        </Fragment>
    );
};

const ItemAlertOnState = ({item, index, onSuccessUpdated}) => {

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {reset,handleSubmit, setValue, control} = useForm({
        defaultValues: {
            warehouseId: item.warehouseId || null,
            name: item.name || null,
            alertOn: item.alertOn || 0
        }
    })

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        reset();
    };

    const onSubmit = (formData) => {
        setLoading(true)

        const payload = {
            id: item.itemId,
            warehouse_id: formData.warehouseId,
            alert_on: +formData.alertOn
        }

        updateWarehouseItemAlertOn(payload)
            .then(() => {
                onSuccessUpdated(index, {...payload, alertOn: +formData.alertOn, name: item.name, itemId: item.itemId})
                toast.success(t(lang, 'items.common.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'items.common.toast.error')))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>{item.name}</InputGroup.Text>
                <Cleave
                    disabled={true}
                    className={'form-control'}
                    value={item.alertOn || 0}
                    options={{numeral: true, delimiter: ' ', numeralDecimalScale: 3}}
                />
                <IconButton variant={'warning'} icon={'pen-to-square'} onClick={handleShow}/>
            </InputGroup>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'items.common.edit_alert_on_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id={'update-alert-on'} onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className={'my-3'}>
                            <Form.Label>{t(lang, 'items.common.warehouse')}</Form.Label>
                            <SelectWarehouse selectProps={{isDisabled: true}} defaultWarehouseId={item.warehouseId}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.alert_on.state')}</Form.Label>
                            <Controller
                                control={control}
                                name={'alertOn'}
                                render={({field}) => (
                                    <Cleave htmlRef={field.ref}
                                            className="form-control"
                                            placeholder={t(lang, 'items.common.state.placeholder')}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 188)
                                                    e.target.value += "."
                                            }}
                                            options={{
                                                numeral: true,
                                                delimiter: ' ',
                                                numeralDecimalScale: 3,
                                            }}
                                            value={field.value}
                                            onChange={e => field.onChange(e.target.rawValue)}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'items.common.cancel')}</Button>
                    <Button form={'update-alert-on'} variant={'primary'} type={'submit'} disabled={loading}>
                        {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                        {t(lang, 'items.common.save')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}

export default memo(ItemsAlertOn);
