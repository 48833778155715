import {useDispatch, useSelector} from "react-redux";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../app/store/reducers/currency/currencyReducer";
import {
    selectWarehouseInitializationState,
    selectWarehouses, WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../app/store/reducers/item/itemReducer";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import ItemNo from "../../../../components/hippo/warehouse-operation/components/ItemNo";
import EditSaleForm from "../../../../components/hippo/warehouse-operation/sale/forms/EditSaleForm";
import {useEffect, useState} from "react";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_SALE_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS, WAREHOUSE_OPERATION_SALE_EMPLOYEE_SUCCESS, WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/saleEvents";
import {getSaleItemAsync} from "../../../../app/store/reducers/warehouse-operation/saleReducer";
import {useHistory, useParams} from "react-router";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import {
    loadContractorAsync,
    updateContractorDebtPromiseDate
} from "../../../../app/store/reducers/contractor/contractorReducer";
import {ITEM_REGISTER_SUCCESS} from "../../../../app/eventbus/kanban/KanbanEvents";


const EditSale = () => {
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [sale, setSale] = useState(null)
    const history = useHistory()
    const items = useSelector(selectItems)
    const {id} = useParams()



    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, (id) => {
            loadSale()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, (id) => {
            loadSale()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, (id) => {
            loadSale()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, (id) => {
            loadSale()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, (id) => {
            history.push(`../view/${id}`)
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onDeleteSalePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_SUCCESS, () => {
            history.push(`../view/${id}`)
        });

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_NOTE_SUCCESS, (id) => {
            loadSale()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DATE_SUCCESS, (id) => {
            loadSale()
        })

        const onChangeExactDiscountsHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_SUCCESS, (id) => {
            loadSale()
        })

        const onChangePercentDiscountHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_SUCCESS, (id) => {
            loadSale()
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS, (id) => {
            loadSale()
        })

        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_EMPLOYEE_SUCCESS, (response) => {
            setSale(response.data, response.data.items.reverse())
        })



        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS, onUpdatePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_SUCCESS, onDeleteSalePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_SUCCESS, onChangeExactDiscountsHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_SUCCESS, onChangePercentDiscountHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS, onChangedNumberHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
        }
    }, [])

    useEffect(() => {
        loadSale()
    },[activeOrganization, id])



    const loadSale = async () => {
        await getSaleItemAsync({saleId: id})
            .then((response) => {
                setSale(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setSale(null)
            })
    }



    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    if(!sale)
        return (
            <SpinnerComponent />
        )


    return(
        <>
            <EditSaleForm sale={sale}/>
        </>
    )
}

export default EditSale;
