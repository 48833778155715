import axios from "axios";

class DesktopAppManagerService {
    getDesktopAppVersions(params) {
        return axios.get('/desktop-app-manager/admin/versions/get', {
            params: params
        })
    }

    getDesktopAppVersionsCount(params) {
        return axios.get('/desktop-app-manager/admin/versions/count', {
            params: params
        })
    }

    addNewDesktopAppVersion(payload) {
        console.log(payload)

        return axios.post("/desktop-app-manager/admin/version/add", payload);
    }

    deleteDesktopAppVersion(id) {
        return axios.post(`/desktop-app-manager/admin/version/delete/${id}`);
    }

    activateDesktopAppVersion(id) {
        return axios.post(`/desktop-app-manager/admin/version/activate/${id}`);
    }

    deactivateDesktopAppVersion(id) {
        return axios.post(`/desktop-app-manager/admin/version/deactivate/${id}`);
    }
}

const desktopAppManagerService = new DesktopAppManagerService();
export default desktopAppManagerService;