import React from 'react';
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Card, Col, Row, Table} from "react-bootstrap";
import SignAttorneyComponent from "./actions/SignAttorneyComponent";
import CancelAttorneyComponent from "./actions/CancelAttorneyComponent";
import AcceptAttorneyComponent from "./actions/AcceptAttorneyComponent";
import DeclineAttorneyComponent from "./actions/DeclineAttorneyComponent";
import {GetStatusVariant, GetStatusText} from "../../../enum/AttorneyStatus";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {AttorneyRegistered, OwnerSigned} from "../../../enum/AttorneyStatus";
import {selectDateDayJSFormat, selectLang, Translate,} from '../../../app/store/reducers/main/mainReducer';
import PrintAttorney from './PrintAttorney';

const Attorney = ({attorney}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const allowSign = attorney.status === AttorneyRegistered && attorney.owner_info.inn === activeOrganization.inn;
    const allowCancel = attorney.status === OwnerSigned && attorney.owner_info.inn === activeOrganization.inn;
    const allowAccept = attorney.status === OwnerSigned && attorney.contractor_info.inn === activeOrganization.inn;
    const allowDecline = attorney.status === OwnerSigned && attorney.contractor_info.inn === activeOrganization.inn;

    return (
        <>
            <Card className="my-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang,"edi.common.created.time")}: {attorney.created_at}</h3>
                    <Col className="text-end">
                        <span className="me-2">{t(lang, 'edi.common.label.status')}: </span>
                        <span className={`p-1 rounded-pill text-white fs--2`} style={{backgroundColor: GetStatusVariant(attorney.status)}}>
                            {t(lang, GetStatusText(attorney.status))}
                        </span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {allowSign && <SignAttorneyComponent className="me-2" id={attorney.id}/>}
                        {allowCancel && <CancelAttorneyComponent className="me-2" id={attorney.id}/>}
                        {allowAccept && <AcceptAttorneyComponent className="me-2" id={attorney.id} />}
                        {allowDecline && <DeclineAttorneyComponent className="me-2" id={attorney.id} />}
                        <PrintAttorney id={attorney.id} />
                    </Col>
                </Row>
            </Card>

            <Card className="p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} lg={3} className="d-flex flex-column">
                        <h5 className="fw-bold mb-1">{t(lang, 'edi.common.label.organization')}</h5>
                        <span><b>{t(lang,"edi.common.company")}:</b> {attorney.owner_info.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {attorney.owner_info.inn}</span>
                    </Col>

                    <Col xs={12} lg={3} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="fw-bold mb-1">{t(lang, 'edi.common.contractor')}</h5>
                        <span><b>{t(lang,"edi.common.company")}:</b> {attorney.contractor_info.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {attorney.contractor_info.inn}</span>
                    </Col>

                    <Col xs={12} lg={3} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="fw-bold mb-1">{t(lang, 'edi.attorney.issued_by')}</h5>
                        <span><b>{t(lang,"edi.common.company")}:</b> {attorney.person_info.full_name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {attorney.person_info.person_inn}</span>
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card className="my-3">
                <Card.Header>
                    <h5 className="p-0"><b className="pe-1">{t(lang,"edi.common.id")}:</b>{attorney.id}</h5>

                    <h3 className="text-center">{t(lang, 'edi.common.attorney')}: {attorney.attorney_info.number}</h3>

                    <Row className="align-items-center justify-content-center mb-5 flex-column flex-lg-row">
                        <Col xs={12} lg={6} className="align-items-center">
                            <div className="d-flex justify-content-between">
                                <b className="col-3">{t(lang, 'edi.common.label.organization')}:</b>
                                <span className="col-9">{attorney.owner_info.name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.inn')}:</span>
                                <span className="col-9">{attorney.owner_info.inn} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.oked')}:</span>
                                <span className="col-9">{attorney.owner_info.oked} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.address')}:</span>
                                <span className="col-9">{attorney.owner_info.address}<hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.bank')}:</span>
                                <span className="col-9">{attorney.owner_bank_info.name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.account')}:</span>
                                <span className="col-9">{attorney.owner_bank_info.account} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.mfo')}:</span>
                                <span className="col-9">{attorney.owner_bank_info.mfo} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="align-items-center mt-3">
                            <div className="d-flex justify-content-between">
                                <b className="col-3">{t(lang, 'edi.common.contractor')}:</b>
                                <span className="col-9">{attorney.contractor_info.name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.inn')}:</span>
                                <span className="col-9">{attorney.contractor_info.inn} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.oked')}:</span>
                                <span className="col-9">{attorney.contractor_info.oked} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.address')}:</span>
                                <span className="col-9">{attorney.contractor_info.address} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.bank')}:</span>
                                <span className="col-9">{attorney.contractor_bank_info.name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.account')}:</span>
                                <span className="col-9">{attorney.contractor_bank_info.account} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-3">{t(lang, 'edi.common.label.mfo')}:</span>
                                <span className="col-9">{attorney.contractor_bank_info.mfo} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="align-items-center mt-3">
                            <div className="d-flex justify-content-between">
                                <b className="col-5">{t(lang, 'edi.attorney.issued_by')}:</b>
                                <span className="col-7">{attorney.person_info.position} {attorney.person_info.full_name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-5">{t(lang, 'edi.attorney.passport_serial_number')}:</span>
                                <span className="col-7">{attorney.passport_info.serial} | № {attorney.passport_info.number} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-5">{t(lang, 'edi.attorney.passport_issued_by')}:</span>
                                <span className="col-7">{attorney.passport_info.issuer} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-5">{t(lang, 'edi.attorney.passport_issue_date')}:</span>
                                <span className="col-7">{dayjs(attorney.passport_info.issued_on, 'YYYY-MM-DD').format(dateFormat)} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-5">{t(lang, 'edi.attorney.receive_from')}:</span>
                                <span className="col-7">{attorney.contractor_info.name} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-5">{t(lang, 'edi.attorney.material_assets_under_the_contract')}:</span>
                                <span className="col-7">{t(lang, 'edi.attorney.contract_number.from.contract_date', {contract_number: attorney.contract_info.number, contract_date: dayjs(attorney.contract_info.date, 'YYYY-MM-DD').format(dateFormat)})} <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Footer>
                    <Table bordered responsive className="border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th className="text-center" rowSpan={2}>{t(lang, 'edi.attorney.data_table.product_number')}</th>
                            <th className="text-center">{t(lang, 'edi.attorney.data_table.product_name')}</th>
                            <th className="text-center">{t(lang, 'edi.attorney.data_table.product_measurement')}</th>
                            <th className="text-center">{t(lang, 'edi.attorney.data_table.product_quantity')}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                        </tr>
                        </thead>
                        <tbody>
                        {attorney.items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center mb-0 text-nowrap fw-semi-bold">{item.product}</td>
                                    <td className="text-center">{item.measurement}</td>
                                    <td className="text-center">{item.quantity}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>

                    <Col className="mt-3" lg={6} xs={12}>
                        <div className="d-flex">
                            <span className="col-6 col-lg-7 fs-0 fw-bold mb-1">{t(lang, 'edi.common.company_director')}: </span>
                            <span className="col-6 col-lg-5">{attorney.director} <hr className="d-block m-0 bg-dark" style={{height: "2px"}} /></span>
                        </div>
                        <div className="d-flex">
                            <span className="col-6 col-lg-7 fs-0 fw-bold mb-1">{t(lang, 'edi.common.head_accountant')}: </span>
                            <span className="col-6 col-lg-5">{attorney.accountant} <hr className="d-block m-0 bg-dark" style={{height: "2px"}} /></span>
                        </div>
                        <div className="d-flex">
                            <i>{t(lang, 'edi.attorney.signed_by_certificate')}: <span className="col-8 text-nowrap text-center">{attorney.owner_info.certificate_serial}</span></i>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>
        </>
    );
};

Attorney.propTypes = {
    attorney: PropTypes.shape({
        id: PropTypes.string,
        updated_at: PropTypes.string,
        created_at: PropTypes.string,
        deleted_at: PropTypes.string,
        is_deleted: PropTypes.bool,
        items: PropTypes.array,
        owner_info: PropTypes.shape({
            inn: PropTypes.string,
            name: PropTypes.string,
            oked: PropTypes.string,
            address: PropTypes.string,
        }),
        attorney_info: PropTypes.shape({
            number: PropTypes.string,
            start: PropTypes.string,
            end: PropTypes.string,
        }),
        person_info: PropTypes.shape({
            position: PropTypes.string,
            full_name: PropTypes.string,
            person_inn: PropTypes.string,
        }),
        passport_info: PropTypes.shape({
            serial: PropTypes.string,
            number: PropTypes.string,
            issuer: PropTypes.string,
            issued_on: PropTypes.string,
        }),
        contractor_info: PropTypes.shape({
            inn: PropTypes.string,
            name: PropTypes.string,
            oked: PropTypes.string,
            address: PropTypes.string,
        }),
        contract_info: PropTypes.shape({
            number: PropTypes.string,
            date: PropTypes.string,
        }),
        owner_bank_info: PropTypes.shape({
            account: PropTypes.string,
            mfo: PropTypes.string,
            name: PropTypes.string,
        }),
        contractor_bank_info: PropTypes.shape({
            account: PropTypes.string,
            mfo: PropTypes.string,
            name: PropTypes.string,
        }),
        accountant: PropTypes.string,
        director: PropTypes.string,
        signed_certificate_serial: PropTypes.string,
    })
};

Attorney.defaultProps = {
    attorney: {
        id: '',
        updated_at: '',
        created_at: '',
        deleted_at: '',
        is_deleted: false,
        items: [],
        owner_info: {
            inn: '',
            name: '',
            oked: '',
            address: '',
        },
        attorney_info: {
            number: '',
            start: '',
            end: '',
        },
        person_info: {
            position: '',
            full_name: '',
            person_inn: '',
        },
        passport_info: {
            serial: '',
            number: '',
            issuer: '',
            issued_on: '',
        },
        contractor_info: {
            inn: '',
            name: '',
            oked: '',
            address: ''
        },
        contract_info: {
            number: '',
            date: '',
        },
        owner_bank_info: {
            account: '',
            mfo: '',
            name: '',
        },
        contractor_bank_info: {
            account: '',
            mfo: '',
            name: '',
        },
        accountant: '',
        director: '',
        signed_certificate_serial: '',
    }
};

export default Attorney;