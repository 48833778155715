export const ADD_MASS_ROAMING_INVOICE_REQUESTED = "ADD_MASS_ROAMING_INVOICE_REQUESTED"
export const ADD_MASS_ROAMING_INVOICE_SUCCEED = "ADD_MASS_ROAMING_INVOICE_SUCCEED"
export const ADD_MASS_ROAMING_INVOICE_FAILED = "ADD_MASS_ROAMING_INVOICE_FAILED"

export const MASS_REGISTER_ROAMING_WAYBILL_REQUESTED = "MASS_REGISTER_ROAMING_WAYBILL_REQUESTED";
export const MASS_REGISTER_ROAMING_WAYBILL_SUCCEED = "MASS_REGISTER_ROAMING_WAYBILL_SUCCEED";
export const MASS_REGISTER_ROAMING_WAYBILL_FAILED = "MASS_REGISTER_ROAMING_WAYBILL_FAILED";

export const ROAMING_EXCEL_REGISTRY_REQUESTED = "ROAMING_EXCEL_REGISTRY_REQUESTED";
export const ROAMING_EXCEL_REGISTRY_SUCCESS = "ROAMING_EXCEL_REGISTRY_SUCCESS";
export const ROAMING_EXCEL_REGISTRY_FAILED = "ROAMING_EXCEL_REGISTRY_FAILED";
