import React, {Fragment, useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import {Controller} from "react-hook-form";
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {
    selectBindContractor
} from "../../app/store/reducers/crm/bind-contractor-to-account/bindContractorToAccountReducer";
import {selectUsers} from "../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const AccountSelect = ({defaultValue, onChange, account}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const bindContractorData = useSelector(selectBindContractor)
    const users = useSelector(selectUsers)

    const [value, setValue] = useState(null)

    useEffect(() => {

        setValue(defaultValue && bindContractorData.find(item => item.id === defaultValue?.[0]?.account_organization_id) || value || null)
    }, [defaultValue])

    return (
        <ReactSelect
            // isDisabled={!!defaultValue}
            value={value}
            isClearable
            hideSelectedOptions
            options={bindContractorData}
            classNamePrefix="react-select"
            onChange={(e) => {
                onChange(e)
                setValue(e)
            }}
            getOptionLabel={option => users.find(i => i.id === option.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
            getOptionValue={option => option.account_id}
            placeholder={t(lang, "edi.common.button.select")}
        />
    );
};

export default AccountSelect;
