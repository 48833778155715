import {createSlice} from "@reduxjs/toolkit";
import roamingContractService from '../../../../services/roaming/roamingContractService'
import EventBus from "../../../eventbus/EventBus";
import {signDocAndGetRoamingSignature} from "./roamingReducer";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../../../enum/TypeViewers";
import { DOCUMENT_CANCEL_FAILED, DOCUMENT_CANCEL_STARTED, DOCUMENT_CANCEL_SUCCESS, DOCUMENT_DECLINE_FAILED, DOCUMENT_DECLINE_STARTED, DOCUMENT_DECLINE_SUCCESS, DOCUMENT_SIGN_FAILED, DOCUMENT_SIGN_STARTED, DOCUMENT_SIGN_SUCCESS } from "../../../eventbus/signEvents";
import {
    ROAMING_CONTRACT_SYNC_FAILED,
    ROAMING_CONTRACT_SYNC_REQUESTED,
    ROAMING_CONTRACT_SYNC_SUCCESS
} from "../../../eventbus/roaming/roamingContractEvents";

export const roamingContractSlice = createSlice({
  name: 'roamingContract',
  initialState: {
    filterOptions: {page: 1, limit: 10},
    contracts: [],
    count: 0,
    viewPage: {
        id: null,
        draftId: null,
        showModal: false
    }
  },
  reducers: {
    setShowModal: (state, action) => {
        if (!action.payload) {
            state.viewPage = {id: null, draftId: null, showModal: false}
        }else {
            state.viewPage.showModal = action.payload
        }
    },
    setId: (state, {payload}) => {
        const {draftId, id} = payload
        if (draftId) {
            state.viewPage.draftId = draftId
        } else if (id) {
            state.viewPage.id = id
        }
    },
    updateFilterOptions: (state, action) => {
      state.filterOptions = {...state.filterOptions, ...action.payload}
    },
    updateRoamingContracts: (state, action) => {
      state.contracts = action.payload
    },
    updateRoamingContractsCount: (state, action) => {
      state.count = action.payload
    }
  }
});

export default roamingContractSlice.reducer;
export const {setShowModal, setId} = roamingContractSlice.actions

export const selectRoamingContracts = state => state.roamingContract.contracts;
export const selectContractFilterOptions = state => state.roamingContract.filterOptions;
export const selectCount = state => state.roamingContract.count;

export const viewPage = state => state.roamingContract.viewPage

export const loadContract = id => {
  return roamingContractService.get(id)
}

export const loadDraftContract = id => {
  return roamingContractService.getDraft(id)
}

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
  dispatch(roamingContractSlice.actions.updateFilterOptions({...params}))
}

export const loadRoamingContractsAsync = ({type_viewer, page, limit, ...filters}) => async (dispatch) => {
    try {
        const skip = (page - 1) * limit
        const params = {skip, limit, ...filters}
        let invoices = []
        if (type_viewer === VIEW_INBOX) {
            invoices = (await roamingContractService.getCustomerContracts(params)).data
        } else if (type_viewer === VIEW_OUTBOX) {
            invoices = (await roamingContractService.getExecutorContracts(params)).data
        } else if (type_viewer === VIEW_DRAFT) {
          invoices = (await roamingContractService.getDraftContracts(params)).data
        }
        dispatch(roamingContractSlice.actions.updateRoamingContracts(invoices))
    } catch (error) {
        console.log(error)
    }
}
export const loadRoamingContractsCountAsync = ({type_viewer, page, limit, ...filters}) => async (dispatch) => {
    try {
        let count = 0
        if (type_viewer === VIEW_INBOX) {
            count = (await roamingContractService.getCustomerContractsCount(filters)).data
        } else if (type_viewer === VIEW_OUTBOX) {
            count = (await roamingContractService.getExecutorContractsCount(filters)).data
        } else if (type_viewer === VIEW_DRAFT) {
          count = (await roamingContractService.getDraftContractsCount(filters)).data
        }
        dispatch(roamingContractSlice.actions.updateRoamingContractsCount(count))
    } catch (error) {
        console.log(error)
    }
}

export const send = async ({contract, certificate, lang}) => {
  try {
      EventBus.dispatch(DOCUMENT_SIGN_STARTED);
      let pkcs7_64 = await signDocAndGetRoamingSignature(contract, certificate)
      const response = await roamingContractService.send({
          id: contract.ContractId,
          signature: pkcs7_64,
          executorInn: contract.Owner.Tin,
          lang: lang
      })
      EventBus.dispatch(DOCUMENT_SIGN_SUCCESS);
      return response
  } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
      }
  }
}
export const save = async ({contract}) => {
  try {
      EventBus.dispatch(DOCUMENT_SIGN_STARTED);
      const response = await roamingContractService.save({
        content: JSON.stringify(contract)
      })
      EventBus.dispatch(DOCUMENT_SIGN_SUCCESS);
      return response
  } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
      }
  }
}

export const create = async ({contract,  lang}) => {
  try {
      EventBus.dispatch(DOCUMENT_SIGN_STARTED);
      const response = await roamingContractService.send({
      })
      EventBus.dispatch(DOCUMENT_SIGN_SUCCESS);
      return response
  } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
      }
  }
}

export const cancelAsync = async (certificate, lang, contract) => {
    try {
      EventBus.dispatch(DOCUMENT_CANCEL_STARTED, contract.id);
      const {data: hashCode} = await roamingContractService.getCancelHashCode(contract.id)
      let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate)
      const { data: canceledContract } = await roamingContractService.cancel(contract.id, roamingSignature)
      EventBus.dispatch(DOCUMENT_CANCEL_SUCCESS, canceledContract)
    } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_CANCEL_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_CANCEL_FAILED, "edi.document.sign.notification.default");
      }
    }
}

export const customerAcceptAsync = async (certificate, lang, contract) => {
    try {
      EventBus.dispatch(DOCUMENT_SIGN_STARTED, contract.id);
      const {data: hashCode} = await roamingContractService.getAcceptHashCode(contract.id)
      let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate, true)
      const { data: acceptedContract } = await roamingContractService.customerAccept(contract.id, roamingSignature)
      EventBus.dispatch(DOCUMENT_SIGN_SUCCESS, acceptedContract)
    } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
      }
    }
}

export const customerDeclineAsync = async (certificate, lang, contract, comment) => {
    try {
      EventBus.dispatch(DOCUMENT_DECLINE_STARTED, contract.id);
      const {data: hashCode} = await roamingContractService.getDeclineHashCode(contract.id, comment)
      let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate)
      const { data: declineContract } = await roamingContractService.customerDecline(contract.id, {signature: roamingSignature, comment: comment})
      EventBus.dispatch(DOCUMENT_DECLINE_SUCCESS, declineContract)
    } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_DECLINE_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_DECLINE_FAILED, "edi.document.sign.notification.default");
      }
    }
}

export const contractSyncAsync = async (id) => {
    EventBus.dispatch(ROAMING_CONTRACT_SYNC_REQUESTED, id);
    try {
        const response = await roamingContractService.sync(id);
        EventBus.dispatch(ROAMING_CONTRACT_SYNC_SUCCESS, id);
        return response.data;
    } catch (error) {
        let errorMessage = {message: error.response.data};
        EventBus.dispatch(ROAMING_CONTRACT_SYNC_FAILED, errorMessage);
        console.log(error);
    }
};