import React, {cloneElement, Fragment, useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import WarehouseOperationAddItemForm from "./WarehouseOperationAddItemForm";

const WarehouseOperationAddItem = ({onItemCreated, defaultName, children}) => {
    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate


    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }



    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton
                    onClick={handleShow}
                    variant="falcon-primary"
                    icon="plus"
                    size="sm"
                    style={{zIndex: 0}}
                    className="fs-1 position-relative"
                    transform="shrink-3"
                />
            )}
            <Modal show={show} onHide={handleClose} fullscreen>
                <Modal.Header>
                    <Modal.Title>{t(lang, "Добавить товар")}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <WarehouseOperationAddItemForm setShow={setShow} defaultName={defaultName} onItemCreated={onItemCreated} />
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default WarehouseOperationAddItem;
