import {Button, Dropdown, Form, Modal, Spinner} from "react-bootstrap";
import React, {cloneElement, Fragment, useRef, useState} from "react";
import IconButton from "../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {getRandomNumber} from "../../helpers/utils";
import {Controller, FormProvider, useForm} from "react-hook-form";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import {ErrorMessage} from "@hookform/error-message";
import ResultCounter from "../hippo/item/forms/bulk-item/ResultCounter";

export const commonConfirmationBulkDelete = (displayName) => {

    const BulkDeleteConfirmationModal = ({
                                             children,
                                             actionRequest,
                                             selectedItems,
                                             loadItems,
                                             ...props
                                        }) => {
        const lang = useSelector(selectLang);
        const t = Translate;
        const [show, setShow] = useState(false);
        const [loading, setLoading] = useState(false);
        const [code] = useState(getRandomNumber(100000, 999999));
        const ref = useRef(null);

        const methods = useForm({
            defaultValues: {
                code: null,
            }
        });

        const handleShow = () => setShow(true);

        const onApprove = async () => {
            ref.current.updateLoadedCount(selectedItems.length);
            setLoading(true);
            setShow(false);

            const data = [];
            const tasks = [];
            const taskSize = 10;

            for (let i = 0; i < selectedItems.length; i++) {
                const item = selectedItems[i];
                data.push(item.id);
            }

           const requestDeleteAsync = async (itemId) => {
                try {
                    await actionRequest(itemId);
                    ref?.current?.incrementSucceedCount();
                } catch (e) {
                    ref?.current?.incrementFailedCount();
                }
           }

            for (let i = 0; i < data.length; i++) {
                tasks.push(requestDeleteAsync(data[i]));
                if (tasks.length >= taskSize) {
                    await Promise.all(tasks);
                    tasks.length = 0;
                }
            }
            setLoading(false);
        }

        const onHide = () => {
            setShow(false);
            methods.setValue('code', null);
            methods.clearErrors('code');
        }

        return (
            <Fragment>
                <ResultCounter ref={ref} loadItems={loadItems}/>
                {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                    <IconButton
                        size="sm"
                        variant="falcon-danger"
                        icon="fa-trash"
                        className={'me-2'}
                        onClick={handleShow}
                        {...props}
                    >
                        {t(lang,"items.common.delete")}
                    </IconButton>
                )}

                <Modal show={show} onHide={onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-center">{t(lang, "warehouse.operation.item.common.delete_item_modal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormProvider {...methods}>
                            <Form id={'delete-popover'} onSubmit={methods.handleSubmit(onApprove)}>
                                <Form.Group>
                                    <Form.Label>
                                        <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                        <span className="ms-1 text-primary">{code}</span>
                                    </Form.Label>
                                    <Controller name={'code'}
                                                control={methods.control}
                                                rules={{
                                                    required: t(lang, 'items.common.validations.is_required'),
                                                    validate: (value) => {
                                                        if (+value === code)
                                                            return true;
                                                        return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                                    }
                                                }}
                                                render={({field}) => (
                                                    <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                            value={field.value}
                                                            htmlRef={field.ref}
                                                            placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                            className={classNames('form-control', {
                                                                'is-invalid': methods.formState.errors?.code
                                                            })}
                                                            options={{
                                                                numeral: true,
                                                                delimiter: ' ',
                                                                numeralDecimalScale: 3
                                                            }}
                                                    />
                                                )}
                                    />
                                    <ErrorMessage name={'code'}
                                                  errors={methods.formState.errors}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </Form.Group>
                            </Form>
                        </FormProvider>
                    </Modal.Body>
                    <Modal.Footer className={'d-flex justify-content-end'}>
                        <Button onClick={onHide} variant={'falcon-primary'}>{t(lang, "crm.common.back.button")}</Button>
                        <Button type={'submit'} form={'delete-popover'} variant="danger">
                            {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {t(lang, "warehouse.operation.item.common.status.approve")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }

    BulkDeleteConfirmationModal.displayName = displayName || "BulkDeleteConfirmationModal";

    return BulkDeleteConfirmationModal
}
