import Barcode from 'react-jsbarcode';
import {useSelector} from "react-redux";
import numeral from "numeral";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import SelectItemBarcodeType from "./SelectItemBarcodeType";
import RangeSlider from 'react-bootstrap-range-slider';
import dayjs from "dayjs";
import printJS from "print-js";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CleaveWithCommaMark from "../../../common/CleaveWithCommaMark";
import SelectCurrency from "./SelectCurrency";
import {currencyConverterConvert} from "../../../../app/store/reducers/currency/currencyReducer";
import Select from "react-select";
import {decimal} from "../../../../helpers/utils";

const PrintItemBarcode = ({itemName, defaultBarcode, defaultPrice, defaultCurrency, sku}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);

    useEffect(() => {
        if (localStorage.getItem('barcodeSettings')) {
            let barcodeSettings = JSON.parse(localStorage.getItem('barcodeSettings'));

            if (barcodeSettings.barcodeType)
                setBarcodeType(barcodeSettings.barcodeType)
            if (barcodeSettings.barcodeWidth)
                setBarcodeWidth(barcodeSettings.barcodeWidth)
            if (barcodeSettings.barcodeHeight)
                setBarcodeHeight(barcodeSettings.barcodeHeight)
            if (barcodeSettings.barcodeMarginBottom)
                setBarcodeMarginBottom(barcodeSettings.barcodeMarginBottom)
            if (barcodeSettings.itemNameFontSize)
                setItemNameFontSize(barcodeSettings.itemNameFontSize)
            if (barcodeSettings.barcodeFontSize)
                setBarcodeFontSize(barcodeSettings.barcodeFontSize)
            if (barcodeSettings.viewItemSku)
                setViewItemSku(barcodeSettings.viewItemSku)
            if (barcodeSettings.viewBarcode)
                setViewBarcode(barcodeSettings.viewBarcode)
            if (barcodeSettings.itemSkuFontSize)
                setItemSkuFontSize(barcodeSettings.itemSkuFontSize)

            if (typeof barcodeSettings.viewItemName !== "undefined")
                setViewItemName(barcodeSettings.viewItemName)

            if (typeof barcodeSettings.viewItemPrice !== "undefined")
                setViewItemPrice(barcodeSettings.viewItemPrice)

            if (barcodeSettings.itemNameFontSize)
                setItemNameFontSize(barcodeSettings.itemNameFontSize)

            if (barcodeSettings.itemPriceFontSize)
                setItemPriceFontSize(barcodeSettings.itemPriceFontSize)

            if (barcodeSettings.itemSmallPriceFontSize)
                setItemSmallPriceFontSize(barcodeSettings.itemSmallPriceFontSize)


            if (barcodeSettings.itemNameAndPriceWidth)
                setItemNameAndPriceWidth(barcodeSettings.itemNameAndPriceWidth)

            if (typeof barcodeSettings.viewNowDateTime !== "undefined")
                setViewNowDateTime(barcodeSettings.viewNowDateTime)

            if (typeof barcodeSettings.viewItemSku !== "undefined")
                setViewItemSku(barcodeSettings.viewItemSku)

            if (typeof barcodeSettings.viewBarcode !== "undefined")
                setViewBarcode(barcodeSettings.viewBarcode)

            if (barcodeSettings.itemSkuFontSize)
                setItemSkuFontSize(barcodeSettings.itemSkuFontSize)

            if (barcodeSettings.selectedViewType)
                setSelectedViewType(barcodeSettings.selectedViewType)
        }
    }, [])

    useEffect(() => {
        if (defaultBarcode)
            setBarcode(defaultBarcode);
    }, [defaultBarcode])

    useEffect(() => {
        setPriceAmount(defaultPrice);
    }, [defaultPrice])

    useEffect(() => {
        setItemCurrency(defaultCurrency);
    }, [defaultCurrency])

    const viewTypes = [
        {
            value: 1,
            label: t(lang, "items.print.barcode.select_option_type_1")
        },
        {
            value: 2,
            label: t(lang, "items.print.barcode.select_option_type_2")
        }
    ]

    const [isValid, setIsValid] = useState(false);
    const [priceAmount, setPriceAmount] = useState(0);
    const [itemCurrency, setItemCurrency] = useState(0);
    const [barcode, setBarcode] = useState('12345670');
    const [barcodeType, setBarcodeType] = useState(null);
    const [barcodeWidth, setBarcodeWidth] = useState(2);
    const [barcodeHeight, setBarcodeHeight] = useState(70);
    const [barcodeMarginBottom, setBarcodeMarginBottom] = useState(10);
    const [barcodeFontSize, setBarcodeFontSize] = useState(16);
    const [viewItemName, setViewItemName] = useState(true);
    const [viewItemPrice, setViewItemPrice] = useState(true);
    const [itemNameFontSize, setItemNameFontSize] = useState(16);
    const [itemPriceFontSize, setItemPriceFontSize] = useState(30);
    const [itemSmallPriceFontSize, setItemSmallPriceFontSize] = useState(30);
    const [itemNameAndPriceWidth, setItemNameAndPriceWidth] = useState(180);
    const [viewNowDateTime, setViewNowDateTime] = useState(false);
    const [viewItemSku, setViewItemSku] = useState(false);
    const [viewBarcode, setViewBarcode] = useState(true);
    const [itemSkuFontSize, setItemSkuFontSize] = useState(true);
    const [selectedViewType, setSelectedViewType] = useState(viewTypes[0]);

    const options = {
        format: barcodeType ? barcodeType.value : 'EAN8',
        width: barcodeWidth,
        height: barcodeHeight,
        marginBottom: barcodeMarginBottom,
        fontSize: barcodeFontSize,
        valid: function (val) {
            setIsValid(val)
        }
    }


    const onItemPriceCurrencyChanged = async (oldCurrency, newCurrency) => {
        try {
            if (oldCurrency && oldCurrency.id !== newCurrency.id) {
                const {currency_to: {value}} = await currencyConverterConvert({
                    currency_id_from: oldCurrency.id,
                    value_from: Number(priceAmount),
                    currency_id_to: newCurrency.id
                });
                setPriceAmount(value ? Number(value.toFixed(2)) : 0);
                setItemCurrency(newCurrency);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onPrint = () => {
        printJS({printable: 'printBarcode', type: 'html', targetStyles: ['*'], font_size: ''});
        localStorage.setItem('barcodeSettings', JSON.stringify({
            barcodeType: barcodeType,
            barcodeWidth: barcodeWidth,
            barcodeHeight: barcodeHeight,
            barcodeMarginBottom: barcodeMarginBottom,
            barcodeFontSize: barcodeFontSize,
            viewItemName: viewItemName,
            viewItemPrice: viewItemPrice,
            itemNameFontSize: itemNameFontSize,
            itemPriceFontSize: itemPriceFontSize,
            itemSmallPriceFontSize: itemSmallPriceFontSize,
            itemNameAndPriceWidth: itemNameAndPriceWidth,
            viewNowDateTime: viewNowDateTime,
            viewItemSku: viewItemSku,
            viewBarcode: viewBarcode,
            itemSkuFontSize: itemSkuFontSize,
            selectedViewType: selectedViewType,
        }));
    }

    const splitPriceToArray = (price) => {
        return price.split(" ");
    }

    return <>
        <Row>
            <Col xs={12} lg={8}>
                <Row>
                    <Col xs={12}>
                        <InputGroup>
                            <Form.Control
                                value={barcode}
                                onChange={(e) => setBarcode(e.target.value)}
                                placeholder="Штрих код"
                            />
                            <SelectItemBarcodeType defaultTypeValue={barcodeType ? barcodeType.value : null}
                                                   onChange={(type) => {
                                                       setBarcodeType(type)
                                                   }}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row className={'my-4'}>
                    <Col xs={12}>
                        <div>{t(lang, "items.print.barcode.select_view_types_label")}</div>
                        <Select classNamePrefix="react-select" options={viewTypes}
                                hideSelectedOptions
                                onChange={(opt) => {
                                    setSelectedViewType(opt)
                                }}
                                value={selectedViewType}/>
                    </Col>
                </Row>

                <Row className={'my-4'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.price.value")}</Col>
                    <Col xs={9}>
                        <InputGroup>
                            <CleaveWithCommaMark value={priceAmount} onChange={value => setPriceAmount(Number(value))}
                                                 ref={null}/>
                            <SelectCurrency
                                defaultCurrencyId={itemCurrency?.id}
                                onChange={(oldCurrency, newCurrency) => onItemPriceCurrencyChanged(oldCurrency, newCurrency)}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row className={'mb-4 mt-5'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.width")}</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={barcodeWidth}
                            size={"lg"}
                            min={1}
                            step={0.1}
                            max={10}
                            onChange={changeEvent => setBarcodeWidth(+changeEvent.target.value)}
                        />
                    </Col>
                </Row>
                <Row className={'my-4'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.height")}</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={barcodeHeight}
                            size={"lg"}
                            step={0.1}
                            min={10}
                            max={150}
                            onChange={changeEvent => setBarcodeHeight(changeEvent.target.value)}
                        />
                    </Col>
                </Row>
                <Row className={'my-4'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.barcodeMarginBottom")}</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={barcodeMarginBottom}
                            size={"lg"}
                            step={0.1}
                            min={1}
                            max={150}
                            onChange={changeEvent => setBarcodeMarginBottom(changeEvent.target.value)}
                        />
                    </Col>
                </Row>
                <Row className={'my-4'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.fontsize")}</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={barcodeFontSize}
                            size={"lg"}
                            step={0.1}
                            min={2}
                            max={50}
                            onChange={changeEvent => setBarcodeFontSize(changeEvent.target.value)}
                        />
                    </Col>
                </Row>
                <Row className={'my-4'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.name.fontsize")}</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={itemNameFontSize}
                            size={"lg"}
                            step={0.1}
                            min={2}
                            max={50}
                            onChange={changeEvent => setItemNameFontSize(changeEvent.target.value)}
                        />
                    </Col>
                </Row>
                <Row className={'my-4'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.price.fontsize")}</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={itemPriceFontSize}
                            size={"lg"}
                            step={1}
                            min={5}
                            max={100}
                            onChange={changeEvent => setItemPriceFontSize(changeEvent.target.value)}
                        />
                    </Col>
                </Row>

                {selectedViewType && selectedViewType.value === 2 && <Row className={'my-4'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.small_price.fontsize")}</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={itemSmallPriceFontSize}
                            size={"lg"}
                            step={1}
                            min={5}
                            max={100}
                            onChange={changeEvent => setItemSmallPriceFontSize(changeEvent.target.value)}
                        />
                    </Col>
                </Row>}

                <Row className={'my-4'}>
                    <Col xs={3}>{t(lang, "items.print.barcode.textWidth")}</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={itemNameAndPriceWidth}
                            size={"lg"}
                            step={0.1}
                            min={20}
                            max={500}
                            onChange={changeEvent => setItemNameAndPriceWidth(changeEvent.target.value)}
                        />
                    </Col>
                </Row>
                <Row className={'my-4'}>
                    <Col xs={3}>Размер артикул</Col>
                    <Col xs={9}>
                        <RangeSlider
                            style={{width: '100%'}}
                            value={itemSkuFontSize}
                            size={"lg"}
                            step={0.1}
                            min={2}
                            max={50}
                            onChange={changeEvent => setItemSkuFontSize(changeEvent.target.value)}
                        />
                    </Col>
                </Row>
                <div className={'d-flex justify-content-between align-content-center my-5'}>
                    <div className={'d-flex'}>
                        <div className={'me-3'}>{t(lang, "items.print.barcode.viewName")}</div>
                        <Form.Switch checked={viewItemName} onChange={(e) => {
                            setViewItemName(e.target.checked)
                        }}/>
                    </div>
                    <div className={'d-flex'}>
                        <div className={'me-3'}>{t(lang, "items.print.barcode.viewPrice")}</div>
                        <Form.Switch checked={viewItemPrice} onChange={(e) => {
                            setViewItemPrice(e.target.checked)
                        }}/>
                    </div>
                    <div className={'d-flex'}>
                        <div className={'me-3'}>{t(lang, "items.print.barcode.viewDateTime")}</div>
                        <Form.Switch checked={viewNowDateTime} onChange={(e) => {
                            setViewNowDateTime(e.target.checked)
                        }}/>
                    </div>
                </div>
                <div className={'d-flex align-content-center my-5'}>
                    <div className={'d-flex me-5'}>
                        <div className={'me-3'}>{t(lang, "items.print.barcode.viewItemSku")}</div>
                        <Form.Switch checked={viewItemSku} onChange={(e) => {
                            setViewItemSku(e.target.checked)
                        }}/>
                    </div>
                    <div className={'d-flex'}>
                        <div className={'me-3'}>{t(lang, "items.print.barcode.viewBarcode")}</div>
                        <Form.Switch checked={viewBarcode} onChange={(e) => {
                            setViewBarcode(e.target.checked)
                        }}/>
                    </div>
                </div>
            </Col>
            <Col xs={12} lg={4}>
                <div className={'text-end'}>
                    <Button onClick={onPrint} size={"lg"}>
                        <FontAwesomeIcon className="me-1" icon={faPrint}/>
                        {t(lang, "edi.common.button.print")}
                    </Button>
                </div>

                <div className={'text-center mt-6 border py-3'}>
                    <div id={'printBarcode'} className={'d-inline-block'}>
                        {!isValid &&
                        <div className={'text-danger'}>{t(lang, "edi.common.forms.validations.invalid_format")}</div>}

                        {selectedViewType && selectedViewType.value === 1 && <div>

                            {viewBarcode && <div><Barcode value={barcode} options={options}/></div>}

                            <div className={'m-auto'} style={{width: itemNameAndPriceWidth + 'px'}}>
                                {viewItemName && itemName &&
                                <div className='fw-bold'
                                     style={{lineHeight: 1, fontSize: itemNameFontSize + 'px'}}>{itemName}</div>
                                }
                                {viewItemPrice && priceAmount &&
                                <div className='fw-bold' style={{lineHeight: 1, fontSize: itemPriceFontSize + 'px'}}>
                                    {numeral.formats[numberFormat].format(priceAmount)}
                                    &nbsp;
                                    <small>{itemCurrency?.name === 'UZS' ? "SO'M" : itemCurrency?.name}</small>
                                </div>
                                }
                            </div>

                            {viewNowDateTime &&
                            <div className={'fw-bold small'}
                                 style={{lineHeight: 1, minWidth: 80}}>{dayjs(new Date()).format("YYYY-MM-DD")}</div>
                            }
                        </div>}

                        {selectedViewType && selectedViewType.value === 2 && <div>
                            <div className={'m-auto'} style={{width: itemNameAndPriceWidth + 'px'}}>
                                {viewItemPrice && priceAmount &&
                                <div className='fw-bold d-flex w-100 justify-content-center'>
                                    {splitPriceToArray(decimal(priceAmount)).map((price, index) => {
                                        return (
                                            index === 0 ?
                                                <div style={{fontSize: itemPriceFontSize + 'px'}}>{price}</div> :
                                                <div className={'ms-2 text-start'} style={{marginTop: "13px"}}>
                                                    <div style={{fontSize: itemSmallPriceFontSize + 'px'}}>{price}</div>
                                                    {splitPriceToArray(decimal(priceAmount)).length - 1 === index &&
                                                    <div>{itemCurrency?.name === 'UZS' ? "SO'M" : itemCurrency?.name}</div>}
                                                </div>
                                        )
                                    })}
                                </div>
                                }
                                {viewItemName && itemName &&
                                <div className='fw-bold text-start mt-2'
                                     style={{lineHeight: 1, fontSize: itemNameFontSize + 'px'}}>{itemName}</div>
                                }
                            </div>

                            <div className={'d-flex align-items-end'}>
                                {viewBarcode &&
                                <div className={'text-start'}><Barcode value={barcode} options={options}/></div>}
                                {viewNowDateTime &&
                                <div className={'fw-bold small mb-2 w-100'}
                                     style={{
                                         lineHeight: 1,
                                         minWidth: 80
                                     }}>{dayjs(new Date()).format("YYYY-MM-DD")}</div>
                                }
                            </div>
                        </div>}

                        {viewItemSku &&
                        <div className={'fw-bold'} style={{lineHeight: 1, fontSize: itemSkuFontSize + 'px'}}>{sku}</div>
                        }
                    </div>
                </div>
            </Col>
        </Row>
    </>;
}
export default PrintItemBarcode
