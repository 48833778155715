import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router";
import {useSelector} from "react-redux";

import {
    acceptOrder, completeOrder, declineOrder,
    getOrder, newOrder, progressOrder, registerOrderItem,
    updateOrder
} from "../../../../../../app/store/reducers/crm/order/crmExecutorOrderReducer";

import {Form} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import {UZS} from "../../../../../../enum/CurrencyCode";
import {selectCurrency} from "../../../../../../app/store/reducers/currency/currencyReducer";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {selectItems} from "../../../../../../app/store/reducers/item/itemReducer";
import CrmExecutorOrderForm from "./CrmExecutorOrderForm";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {selectAllContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import {updateOrderItem} from "../../../../../../app/store/reducers/crm/order/crmExecutorOrderReducer";

const CrmInternalOrderEditForm = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const history = useHistory();

    const {id} = useParams()
    const currencies = useSelector(selectCurrency);
    const items = useSelector(selectItems);
    const contractors = useSelector(selectAllContractor);
    const [loading, setLoading] = useState(false);
    const [editingExecutorOrder, setEditingExecutorOrder] = useState(null);

    const methods = useForm({
        defaultValues: {
            customer: null,
            branch: null,
            info: {
                number: '',
                date: null
            },
            contract: {
                number: '',
                date: null
            },
            items: [
                {
                    global_item_id: null,
                    product: '',
                    barcode: '',
                    product_code: '',
                    measurement: '',
                    price: {
                        amount: '',
                        currency_id: currencies?.find(c => c?.code === UZS)?.id
                    },
                    quantity: '',
                }
            ],
            expires_on: null,
            description: null
        }
    });

    useEffect(() => {
        const contractor = contractors.find(contractor => contractor.id === editingExecutorOrder?.customer?.global_contractor_id);
        if (contractor) {
            methods.setValue('customer', contractor);
        }

        methods.setValue('info.number', editingExecutorOrder?.info?.number);
        methods.setValue('info.date', editingExecutorOrder?.info?.date ? new Date(editingExecutorOrder.info.date) : null);
        methods.setValue('contract.number', editingExecutorOrder?.contract?.number);
        methods.setValue('contract.date', editingExecutorOrder?.contract?.date ? new Date(editingExecutorOrder.contract.date) : null);
        methods.setValue('expires_on', editingExecutorOrder?.expires_on ? new Date(editingExecutorOrder.expires_on) : null);
        methods.setValue('description', editingExecutorOrder?.description);
        methods.setValue('branch', editingExecutorOrder?.branch);

        if (items.length > 0) {
            methods.setValue('items', editingExecutorOrder?.items.map((item) => {
                let foundItem = items.find(i => i.item.id === item.global_item_id);
                let price_currency_id = currencies?.find(c => c?.id === item.price?.currency?.global_id)?.id ?? currencies?.find(c => c?.code === UZS)?.id;
                return {
                    global_item_id: item.id,
                    product: foundItem?.item,
                    barcode: item.barcode,
                    product_code: item.product_code,
                    measurement: item.measurement,
                    price: {
                        amount: item.price ? item.price.amount : '',
                        currency_id: price_currency_id
                    },
                    quantity: item.quantity,
                }
            }))
        }
    }, [editingExecutorOrder, items])

    const onSubmit = formData => {
        setLoading(true);
        updateOrder(id, {
            items: formData.items.map(item => {
                return {
                    global_item_id: item.product?.id,
                    product: item.product?.name,
                    barcode: item.barcode || null,
                    product_code: item.product_code || null,
                    measurement: String(item.measurement),
                    price: item.price.amount === '' || item.price.amount === null ? null : {
                        amount: +item.price.amount,
                        currency_id: item.price.currency_id
                    },
                    quantity: +item.quantity,
                }
            }),
            customer: {
                inn: formData.customer.inn || null,
                name: formData.customer.name,
                global_contractor_id: formData.customer.id,
            },
            info: {
                number: formData.info?.number,
                date: formData?.info?.date ? dayjs(formData.info.date).format('YYYY-MM-DD') : null
            },
            contract: formData.contract ? {
                number: formData.contract.number || '',
                date: formData?.contract.date ? dayjs(formData.contract.date).format('YYYY-MM-DD') : null
            } : null,
            branch: formData.branch ? {
                id: formData.branch.id || null,
                code: formData.branch.code || null,
                name: formData.branch.name || ''
            } : null,
            expires_on: formData?.expires_on ? dayjs(formData.expires_on).format('YYYY-MM-DD') : null,
            description: formData?.description
        }).then(() => {
            toast.success(t(lang, 'crm.common.toast.success'));
            history.goBack()
        }).catch(() => {
            toast.error(t(lang, 'crm.common.toast.error'));
        }).finally(() => {
            setLoading(false);
        })
    };

    const loadOrder = () => {
        getOrder(id).then(res => {
            setEditingExecutorOrder(res);
        }).catch(err => {
        })
    }

    useEffect(() => {
        loadOrder()
    }, [id])

    const onChangeStatus = (status) => {
        if (!status)
            return;

        switch (status.value) {
            case -1:
                declineOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
            case 0:
                newOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
            case 1:
                acceptOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
            case 2:
                progressOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
            case 3:
                completeOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
        }
    }
    const onSaveEditItem = (editingItem) => {
        setLoading(true);
        if (editingItem.item_id) {
            const payload = {
                order_id: id,
                order_item_id: editingItem.item_id,
                item: {
                    global_item_id: editingItem.product.id,
                    product: editingItem.product?.name,
                    barcode: editingItem.barcode || null,
                    product_code: editingItem.product_code || null,
                    measurement: String(editingItem.measurement),
                    price: editingItem.price.amount === '' || editingItem.price.amount === null ? null : {
                        amount: +editingItem.price?.amount,
                        currency_id: editingItem.price?.currency_id
                    },
                    quantity: +editingItem.quantity,
                }
            }
            updateOrderItem(payload)
                .then(() => {
                    toast.success(t(lang, 'crm.common.toast.success'));
                    loadOrder()
                })
                .catch(() => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            const payload = {
                order_id: id,
                item: {
                    global_item_id: editingItem.product.id,
                    product: editingItem.product?.name,
                    barcode: editingItem.barcode || null,
                    product_code: editingItem.product_code || null,
                    measurement: String(editingItem.measurement),
                    price: editingItem.price.amount === '' || editingItem.price.amount === null ? null : {
                        amount: +editingItem.price?.amount,
                        currency_id: editingItem.price?.currency_id
                    },
                    quantity: +editingItem.quantity,
                }
            }
            registerOrderItem(payload)
                .then(() => {
                    toast.success(t(lang, 'crm.common.toast.success'));
                    loadOrder()
                })
                .catch(() => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <CrmExecutorOrderForm loading={loading}
                                      order={editingExecutorOrder}
                                      onChangeStatus={onChangeStatus}
                                      onSaveEditItem={onSaveEditItem}
                                      formTitle={'Редактировать заказ'}/>
            </Form>
        </FormProvider>
    );
};

export default CrmInternalOrderEditForm;