import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Dropdown, Modal, Spinner} from "react-bootstrap";
import TelegramOrderBotService from "../../../../../services/telegram-order-bot/telegramOrderBotService";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import TelegramOrderBotOrder from "./orders/view/TelegramOrderBotOrder";
import {
    loadOrderSaleDifferencesAsync
} from "../../../../../app/store/reducers/organization-binding/organizationBindingReducer";
import {toast} from "react-toastify";

const ViewTelegramOrderBotOrderDropdownItem = ({id}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [saleDifferences, setSaleDifferences] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const loadOrder = async (id) => {
        try {
            setLoading(true);
            const {data: order} = await TelegramOrderBotService.getTelegramOrderBotOrder(id);

            if (order.is_sale_created) {
                const {data: saleDif} = await loadOrderSaleDifferencesAsync(order.id);
                setSaleDifferences(saleDif);
            } else {
                setSaleDifferences([]);
            }

            setOrder(order);
            setLoading(false);
            handleShow();
        } catch (error) {
            setOrder(null);
            setSaleDifferences([]);
            setLoading(false);
            toast.error(t(lang, 'common.toast.error'));
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <Dropdown.Item className="text-primary align-center"
                           disabled={loading}
                           onClick={() => loadOrder(id)}
            >
                <FontAwesomeIcon className="me-1"
                                 icon={faEye}
                />
                <span>{t(lang, 'items.common.draft.show')}</span>
                {loading && <Spinner className="align-middle ms-2" animation="border" role="switch" size="sm"/>}
            </Dropdown.Item>
            {show &&
                <Modal show={true}
                       fullscreen={true}
                       centered={true}
                       onHide={handleClose}
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, "edi.common.order")}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <TelegramOrderBotOrder order={order}
                                               itemsDiffs={saleDifferences}
                        />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
            }
        </React.Fragment>
    );
};

export default ViewTelegramOrderBotOrderDropdownItem;