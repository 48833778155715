import {createSlice} from "@reduxjs/toolkit";
import {crmOrderService} from "../../../../../services/crmOrderService";
import EventBus from "../../../../eventbus/EventBus";
import {
    ADD_CRM_ORDER_FAILED,
    ADD_CRM_ORDER_SUCCESS,
    CRM_ORDER_IDENTIFY_ITEMS_FAILED,
    CRM_ORDER_IDENTIFY_ITEMS_REQUEST,
    CRM_ORDER_IDENTIFY_ITEMS_SUCCESS,
    DELETE_CRM_ORDER_FAILED,
    DELETE_CRM_ORDER_SUCCESS,
    EDIT_CRM_CUSTOMER_ORDER_REQUESTED,
    EDIT_CRM_CUSTOMER_ORDER_REQUESTED_FAILED,
    EDIT_CRM_CUSTOMER_ORDER_REQUESTED_SUCCESS,
    ITEM_UPDATE_REGISTER_CRM_ORDER_FAILED,
    ITEM_UPDATE_REGISTER_CRM_ORDER_REQUEST,
    ITEM_UPDATE_REGISTER_CRM_ORDER_SUCCESS
} from "../../../../eventbus/crmOrderEvents";
import ExcelService from "../../../../../services/excelService";

export const crmCustomerOrderSlice = createSlice({
    name: 'crmCustomerOrder',
    initialState: {
        orders: [],
        filterOptions: {page: 1, limit: 10},
        count: 0,
        isLoading: false,
    },
    reducers: {
        updateOrders: (state, action) => {
            state.orders = action.payload
        },
        updateOrdersCount: (state, action) => {
            state.count = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
})

export const changeFilterOrdersAsync = (params) => (dispatch) => {
    return dispatch(crmCustomerOrderSlice.actions.updateFilterOptions(params))
}

//order actions
export const getOrders = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    dispatch(crmCustomerOrderSlice.actions.setLoading(true));
    crmOrderService.getCustomerOrders(params).then(res => {
        dispatch(crmCustomerOrderSlice.actions.updateOrders(res.data))
    }).catch(e => {
        console.log(e)
    }).finally(() => {
        dispatch(crmCustomerOrderSlice.actions.setLoading(false));
    })

}
export const getOrdersCount = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    crmOrderService.getCustomerOrdersCount(other)
        .then(res => {
            dispatch(crmCustomerOrderSlice.actions.updateOrdersCount(res.data))
        })
        .catch(e => {
            console.log(e)
        })
}
export const registerOrder = (payload) => {
    return new Promise((resolve, reject) => {
        crmOrderService.registerCustomerOrder(payload)
            .then(response => {
                resolve(response.data)
                EventBus.dispatch(ADD_CRM_ORDER_SUCCESS, response.data)
            })
            .catch(error => {
                reject(error)
                EventBus.dispatch(ADD_CRM_ORDER_FAILED, error)
            })
    })
}
export const getOrder = (id) => {
    return new Promise((resolve, reject) => {
        crmOrderService.getOrder(id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            });
    });
}
export const updateOrder = (id, payload) => {
    EventBus.dispatch(EDIT_CRM_CUSTOMER_ORDER_REQUESTED, payload)
    return new Promise((resolve, reject) => {
        crmOrderService.updateCustomerOrder(id, payload)
            .then(response => {
                resolve(response.data)
                EventBus.dispatch(EDIT_CRM_CUSTOMER_ORDER_REQUESTED_SUCCESS, response.data)
            })
            .catch(error => {
                reject(error)
                EventBus.dispatch(EDIT_CRM_CUSTOMER_ORDER_REQUESTED_FAILED, error)
            })
    })

}
export const deleteOrder = (id) => {
    return new Promise((resolve, reject) => {
        crmOrderService.deleteOrder(id)
            .then(res => {
                EventBus.dispatch(DELETE_CRM_ORDER_SUCCESS)
                resolve(res.data)
            }).catch(e => {
            reject(e)
            EventBus.dispatch(DELETE_CRM_ORDER_FAILED)
        })
    })
}

export const uploadOrderToExcelAsync = (id) => {
    return new Promise((resolve, reject) => {
        ExcelService.getOrderTemplate(id)
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const onIdentifyItems = ({id}) => {
    EventBus.dispatch(CRM_ORDER_IDENTIFY_ITEMS_REQUEST)
    return new Promise((resolve, reject) => {
        crmOrderService.identifyItems({orderId: id})
            .then(res => {
                EventBus.dispatch(CRM_ORDER_IDENTIFY_ITEMS_SUCCESS)
                resolve(res.data)
            }).catch(e => {
            reject(e)
            EventBus.dispatch(CRM_ORDER_IDENTIFY_ITEMS_FAILED)
        })
    })
}

//order item actions
export const deleteOrderItem = (payload) => {
    return new Promise((resolve, reject) => {
        crmOrderService.itemDelete(payload)
            .then(res => resolve(res.data)).catch((e) => reject(e))
    })
}
export const updateOrderItem = (payload) => {
    EventBus.dispatch(ITEM_UPDATE_REGISTER_CRM_ORDER_REQUEST)
    return new Promise((resolve, reject) => {
        crmOrderService.orderItemUpdate(payload)
            .then(res => {
                EventBus.dispatch(ITEM_UPDATE_REGISTER_CRM_ORDER_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_UPDATE_REGISTER_CRM_ORDER_FAILED)
                reject(e)
            })
    })
}
export const registerOrderItem = (payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(ITEM_UPDATE_REGISTER_CRM_ORDER_REQUEST)
        crmOrderService.orderItemRegister(payload)
            .then(res => {
                EventBus.dispatch(ITEM_UPDATE_REGISTER_CRM_ORDER_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_UPDATE_REGISTER_CRM_ORDER_FAILED)
                reject(e)
            })
    })
}

// order status actions
export const cancelOrder = (id, payload) => {
    return new Promise((resolve, reject) => {
        crmOrderService.cancelOrder(id, payload)
            .then(res => resolve(res.data)).catch((e) => reject(e))
    })
}
export const declineOrder = (id, payload) => {
    return new Promise((resolve, reject) => {
        crmOrderService.declineOrder(id, payload)
            .then(res => resolve(res.data)).catch((e) => reject(e))
    })
}
export const newOrder = (id, payload) => {
    return new Promise((resolve, reject) => {
        crmOrderService.newOrder(id, payload)
            .then(res => resolve(res.data)).catch((e) => reject(e))
    })
}
export const acceptOrder = (id, payload) => {
    return new Promise((resolve, reject) => {
        crmOrderService.acceptOrder(id, payload)
            .then(res => resolve(res.data)).catch((e) => reject(e))
    })
}
export const progressOrder = (id, payload) => {
    return new Promise((resolve, reject) => {
        crmOrderService.progressOrder(id, payload)
            .then(res => resolve(res.data)).catch((e) => reject(e))
    })
}
export const completeOrder = (id, payload) => {
    return new Promise((resolve, reject) => {
        crmOrderService.completeOrder(id, payload)
            .then(res => resolve(res.data)).catch((e) => reject(e))
    })
}

//selectors
export const selectOrders = (state) => state.crmCustomerOrder.orders
export const selectFilterOptions = state => state.crmCustomerOrder.filterOptions;
export const selectCount = state => state.crmCustomerOrder.count;
export const selectLoading = state => state.contractorPayment.isLoading;

export default crmCustomerOrderSlice.reducer
