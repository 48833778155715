import {Card, Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {
    selectDateFormat,
    selectLang,
    Translate,
} from "../../../../app/store/reducers/main/mainReducer";
import {useFormContext, Controller} from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import WarningAlert from "../../roaming/components/WarningAlert";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    REGISTER_CONTRACT_FORMATION_FAILED,
    SEND_CONTRACT_FORMATION_FAILED,
    UPDATE_CONTRACT_FORMATION_FAILED,
} from "../../../../app/eventbus/contractFormationEvents";
import ContractFormationDynamicFields from "./ContractFormationDynamicFields";
import {InRework} from "../contract-formation-view/ContractFormationStatus";


const ContractFormationInfo = ({document}) => {
    const { register, formState: {errors}, control, getValues} = useFormContext()
    const warningInRework = document?.status === InRework
    const dateFormat = useSelector(selectDateFormat)
    const capContract = getValues('contractInfo.header');
    const lang = useSelector(selectLang);
    const t = Translate;


    const [eventMessage, setEventMessage] = useState(null);


    useEffect(() => {

        const onContractFormationRegisterFailedHandler = EventBus.on(REGISTER_CONTRACT_FORMATION_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, "edi.contract_formation.alert.failed_event_title"),
                text: error
            })
        })

        const onContractFormationUpdateFailedHandler = EventBus.on(UPDATE_CONTRACT_FORMATION_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, "edi.contract_formation.alert.failed_event_title"),
                text: error
            })
        })

        const onContractFormationSendFailedHandler = EventBus.on(SEND_CONTRACT_FORMATION_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, "edi.contract_formation.alert.failed_event_title"),
                text: error
            })
        })


        return () => {
            EventBus.remove(REGISTER_CONTRACT_FORMATION_FAILED, onContractFormationRegisterFailedHandler);
            EventBus.remove(UPDATE_CONTRACT_FORMATION_FAILED, onContractFormationUpdateFailedHandler);
            EventBus.remove(SEND_CONTRACT_FORMATION_FAILED, onContractFormationSendFailedHandler);
        }
    },[])


    return(
        <>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}


            <Card className="mb-3 p-3">
                <Col xs={6} className="d-flex flex-column">
                    <p><b>{t(lang, "edi.contract_formation.info.contract_initiator")}:</b> {document?.content?.contract_initiator}</p>
                    <p><b>{t(lang, "edi.contract_formation.info.contract_product_group")}:</b> {document?.content?.contract_product_group}</p>
                    <p className={warningInRework ? "text-danger fs-1" : ""}><b>{t(lang, "edi.common.label.comment")}:</b> {document?.content?.note}</p>
                </Col>
            </Card>
            <Card>
                <Card.Header>
                    <h5 className="mb-0">{t(lang, "roaming.contracts.add.contract_info.title")}</h5>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col lg="4" sm="12" md="6">
                            <Form.Label>{t(lang, document?.content?.parent_uid ? "roaming.contracts.add.contract_info.contract_number_additional" : "roaming.contracts.add.contract_info.contract_number")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="contractInfo.number"
                                {...register('contractInfo.number', {
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                })}
                                className={errors?.contractInfo?.number?.message && "is-invalid"}
                                placeholder={t(lang, "edi.contract.datatable.header.row.number")}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.contractInfo?.number?.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col lg="4" sm="12" md="6">
                            <Form.Label>{t(lang,  document?.content?.parent_uid ? "roaming.contracts.add.contract_info.date_conclusion_additional" : "roaming.contracts.add.contract_info.date_conclusion")}</Form.Label>
                            <Controller
                                control={control}
                                name="contractInfo.date"
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <DatePicker
                                        onChange={field.onChange}
                                        dateFormat={dateFormat}
                                        selected={field.value}
                                        className={`${errors?.contractInfo?.date?.message && "is-invalid"} form-control`}
                                        placeholderText={t(lang,"edi.contract.datatable.header.row.date")}
                                        maxDate={new Date()}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.contractInfo?.date?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" sm="12" md="6">
                            <Form.Label>{t(lang, "roaming.contracts.add.contract_info.name")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="contractInfo.name"
                                disabled={true}
                                placeholder={t(lang, "roaming.common.label.name")}
                                {...register('contractInfo.name', {
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                })}
                                className={errors?.contractInfo?.name?.message && "is-invalid"}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.contractInfo?.name?.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col lg="4" sm="12" md="6">
                            <Form.Label>{t(lang, "roaming.contracts.add.contract_info.expiring_on")}</Form.Label>
                            <Controller
                                control={control}
                                name="contractInfo.expireDate"
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <DatePicker
                                        onChange={field.onChange}
                                        dateFormat={dateFormat}
                                        wrapperClassName={classNames({
                                            'is-invalid': errors?.contractInfo?.expireDate,
                                            'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.expireDate
                                        })}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.contractInfo?.expireDate,
                                            'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.expireDate
                                        })}
                                        selected={field.value}
                                        placeholderText={t(lang, "roaming.contracts.add.contract_info.expiring_on")}
                                        minDate={new Date()}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.contractInfo?.expireDate?.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col lg="4" sm="12" md="12">
                            <Form.Label>{t(lang, "roaming.contracts.add.contract_info.place_conclusion")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="contractInfo.place"
                                placeholder={t(lang, "roaming.contracts.add.contract_info.place_conclusion")}
                                {...register('contractInfo.place', {
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                })}
                                className={errors?.contractInfo?.place?.message && "is-invalid"}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.contractInfo?.place?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>

                    {document?.form_fields.map((item, index) => (
                        <Col lg="4" sm="12" md="6" key={index}>
                            <ContractFormationDynamicFields index={index} item={item} />
                        </Col>
                    ))}
                    </Row>
                </Card.Body>
            </Card>
            {capContract &&
                <Card className="mt-3">
                    <Card.Header>
                        <h5 className="mb-0">{t(lang, "edi.contract_formation.info_header.title")}</h5>
                    </Card.Header>
                    <Card.Body>
                        <Controller
                            name={`contractInfo.header`}
                            control={control}
                            render={({field}) => (
                                <Form.Control
                                    value={field?.value}
                                    disabled
                                    placeholder={t(lang, "roaming.contracts.add.parts.body:placeholder")}
                                    as="textarea"
                                    rows={3}
                                />
                            )}
                        />
                    </Card.Body>
                </Card>
            }
        </>
    )
}


export default ContractFormationInfo;