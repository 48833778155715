import Select from "react-select";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React, {useEffect, useState} from "react";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const SelectStatus = ({onChange, statuses, defaultStatus, ...props}) => {
    const [status, setStatus] = useState(null);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (defaultStatus) {
            const defaultValue = statuses.find(s => s.id === defaultStatus);
            setStatus(defaultValue);
        }
    }, [defaultStatus])

    const onChangeHandler = (option) => {
        onChange(option?.id);
        setStatus(option);
    }

    const getOptionValue = option => option.id;
    const getOptionLabel = option => t(lang, option.name);

    return (
        <Select
            {...props}
            value={status}
            options={statuses}
            hideSelectedOptions
            onChange={onChangeHandler}
            classNamePrefix="react-select"
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
        />
    );
};

SelectStatus.propTypes = {
    statuses: PropTypes.array,
    onChange: PropTypes.func
}
SelectStatus.defaultProps = {
    statuses: [],
    onChange: () => {
    }
}

export default SelectStatus;