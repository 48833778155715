import {useSelector} from "react-redux";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import {useHistory} from "react-router";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useEffect, useState} from "react";
import {
    getWriteOffItemAsync,
    writeOffApproveAsync
} from "../../../../app/store/reducers/warehouse-operation/writeOffReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {Button, Card, Col, Row} from "react-bootstrap";
import IconButton from "../../../../components/common/IconButton";
import SoftBadge from "../../../../components/common/SoftBadge";
import {
    isApprovedCheckedWarehouseOperation
} from "../../../../components/hippo/warehouse-operation/enum/warehouseOperationWrapper";
import WriteOffExcelForm from "../../../../components/hippo/warehouse-operation/write-off/forms/WriteOffExcelForm";
import {
    WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS, WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/revisionEvents";
import {Link} from "react-router-dom";


const AddWriteOffExcel = () => {
    const [writeOff, setWriteOff] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate



    const loadWriteOff = (id) => {
        getWriteOffItemAsync({writeOffId: id})
            .then(response => setWriteOff(response.data))
            .catch(error => setWriteOff(null))
    }


    useEffect(() => {
        const onRegisterHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, (id) => {
            loadWriteOff(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS, (id) => {
            loadWriteOff(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS, (id) => {
            loadWriteOff(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS, (id) => {
            loadWriteOff(id)
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, onRegisterHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
        }
    }, [])


    const onApproved = async () => {
        await writeOffApproveAsync({writeOffId: writeOff?.id})
            .then(() => {
                history.push(`./view/${writeOff?.id}`)
            })
            .catch(() => {})
    }


    if (items?.length === 0)
        return <>items loading...</>


    if (warehouses?.length > 0)
        return (
            <Card>
                <Card.Body>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <h5>{t(lang, "warehouse.operation.item.common.new_write_off")}</h5>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            {writeOff?.id && !writeOff?.is_approved &&
                                <div>
                                    <IconButton
                                        variant="falcon-primary"
                                        icon="layer-group"
                                        className="mx-2"
                                        size="sm"
                                        onClick={() => onApproved()}
                                    >{t(lang, "warehouse.operation.item.common.status.approve")}</IconButton>
                                    <Button
                                        variant="falcon-info"
                                        title={t(lang, "warehouse.operation.item.common.button.save_draft")}
                                        size="sm"
                                        className="me-1"
                                        as={Link}
                                        to={`./view/${writeOff?.id}`}
                                    >{t(lang, "warehouse.operation.item.common.button.save_draft")}</Button>
                                </div>
                            }
                            <div>
                                <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                <SoftBadge bg={writeOff?.is_approved ? 'success' : 'warning'}>
                                    {t(lang, isApprovedCheckedWarehouseOperation(!!writeOff?.is_approved))}
                                </SoftBadge>
                            </div>
                        </Col>
                    </Row>
                    <WriteOffExcelForm />
                </Card.Body>
            </Card>
        )


    if (warehouses?.length === 0)
        return(
            <>
                <WarehouseNo/>
            </>
        )
}

export default AddWriteOffExcel;