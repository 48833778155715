import React, {Fragment} from 'react';
import {Card} from "react-bootstrap";
import numeral from "numeral";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, selectSystemMoneyFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import AdminBoughtDatatableHeader from "../../../../../pages/main/admin/component/AdminBoughtDatatableHeader";


const BoughtDatatable = ({isAdmin=false, filter, bought, count, changeFilterBoughtAsync}) => {
    const dispatch = useDispatch()
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);

    const onPageChange = (page) => dispatch(changeFilterBoughtAsync({...filter, page}))
    const onLimitChange = (limit) => dispatch(changeFilterBoughtAsync({...filter, limit, page: 1}))

    const lang = useSelector(selectLang);
    const t = Translate;

    const subBoughtPackageTotal = bought.reduce((acc, pack) => acc + pack.package?.price, 0)

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`
            }
        },
        {
            accessor: 'organization.name',
            isAdmin: true,
            Header: t(lang, "main.billing.bought.datatable.organization.name"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original.organization?.name}
                    </>
                );
            }
        },
        {
            accessor: 'package.name',
            Header: t(lang, "main.billing.bought.datatable.name"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original.package?.name}
                    </>
                );
            }
        },
        {
            accessor: 'bought_at',
            Header: t(lang, "main.billing.bought.datatable.bought_at"),
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {dayjs(original.bought_at).format("YYYY-MM-DD")}
                    </>
                );
            }
        },
        {
            accessor: 'expires_at',
            Header: t(lang, "main.billing.bought.datatable.expires_at"),
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {dayjs(original.expires_at).format("YYYY-MM-DD")}
                    </>
                );
            }
        },
        {
            accessor: 'amount',
            Header: t(lang, "main.billing.bought.datatable.amount"),
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            totalInfo: numeral.formats[systemMoneyFormat].format(subBoughtPackageTotal),
            totalInfoClassName: 'text-end fw-bold',
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {numeral.formats[systemMoneyFormat].format(original.package?.price)}
                    </>
                );
            }
        },

    ].filter(i => isAdmin ? i : !i.isAdmin)

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={bought}
                pagination
                perPage={1000}
            >
                <Card>
                    <Fragment>
                        {isAdmin && <Card.Header className={'bg-light'}>
                            <AdminBoughtDatatableHeader changeFilterBoughtAsync={changeFilterBoughtAsync}/>
                        </Card.Header>}
                    </Fragment>
                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}/>

                        <Card.Footer>
                            <AdvanceTableCustomPagination
                                   count={count}
                                   limit={filter.limit}
                                   page={filter.page}
                                   onLimitChange={onLimitChange}
                                   onPageChange={onPageChange}
                            />
                        </Card.Footer>
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default BoughtDatatable;
