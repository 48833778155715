import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import settingsService from "../../../../services/settingsService";
import {
    UPDATE_INVOICE_REGISTRY_SETTINGS_FAILED,
    UPDATE_INVOICE_REGISTRY_SETTINGS_REQUESTED,
    UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED,
    UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_FAILED,
    UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_REQUESTED,
    UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_SUCCEED,
    UPDATE_WAYBILL_REGISTRY_SETTINGS_FAILED,
    UPDATE_WAYBILL_REGISTRY_SETTINGS_REQUESTED,
    UPDATE_WAYBILL_REGISTRY_SETTINGS_SUCCEED
} from "../../../eventbus/settingsEvents";

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        settings: null,
        defaultSettings: null
    },
    reducers: {
        updateSettings: (state, action) => {
            state.settings = action.payload;
        },
        updateDefaultSettings: (state, action) => {
            state.defaultSettings = action.payload
        }
    }
})

export const selectInvoiceRegistrySettings = state => state.settings.settings?.invoice_registry;
export const selectWaybillRegistrySettings = state => state.settings.settings?.waybill_registry;
export const selectWaybillItemRegistrySettings = state => state.settings.settings?.waybill_item_registry;
export const selectDefaultSetting = state => state.settings.defaultSettings;

export const loadSettings = () => (dispatch) => {
    settingsService.getSettings()
        .then((response) => {
            dispatch(settingsSlice.actions.updateSettings(response.data))
        })
        .catch((error) => {
            console.log(error)
        })
}

export const loadDefaultSettings = () => (dispatch) => {
    settingsService.getDefaultSettings()
        .then((response) => {
            dispatch(settingsSlice.actions.updateDefaultSettings(response.data))
        })
        .catch((error) => {
            console.log(error)
        })
}

export const updateLangSettingsAsync = ({payload}) => dispatch => {

    return new Promise((resolve, reject) => {
        settingsService.updateLangSettings({payload})
            .then((response) => {
                resolve(response)
                dispatch(settingsSlice.actions.updateSettings(response.data))
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const updateInvoiceRegistrySettingsAsync = (payload) => {
    EventBus.dispatch(UPDATE_INVOICE_REGISTRY_SETTINGS_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        settingsService.updateInvoiceRegistrySettings(payload)
            .then(settings => {
                EventBus.dispatch(UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED, settings);
                resolve();
            })
            .catch(error => {
                EventBus.dispatch(UPDATE_INVOICE_REGISTRY_SETTINGS_FAILED, error);
                reject();
            })
    })
}

export const updateWaybillRegistrySettingsAsync = (payload) => {
    EventBus.dispatch(UPDATE_WAYBILL_REGISTRY_SETTINGS_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        settingsService.updateWaybillRegistrySettings(payload)
            .then(settings => {
                EventBus.dispatch(UPDATE_WAYBILL_REGISTRY_SETTINGS_SUCCEED, settings);
                resolve();
            })
            .catch(error => {
                EventBus.dispatch(UPDATE_WAYBILL_REGISTRY_SETTINGS_FAILED, error);
                reject();
            })
    })
}

export const updateWaybillRegistryItemSettingsAsync = (payload) => {
    EventBus.dispatch(UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        settingsService.updateWaybillItemRegistrySettings(payload)
            .then(settings => {
                EventBus.dispatch(UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_SUCCEED, settings);
                resolve();
            })
            .catch(error => {
                EventBus.dispatch(UPDATE_WAYBILL_ITEM_REGISTRY_SETTINGS_FAILED, error);
                reject();
            })
    })
}

export default settingsSlice.reducer;
