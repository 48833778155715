import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import BindWarehousesToOrganizationsForm from "../../organization-bindings/owner/BindWarehousesToOrganizationsForm";

const BindWarehousesToOrganizationsModal = ({warehouses}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <IconButton icon={faLock}
                        onClick={handleShow}
                        variant="falcon-default"
                        size="sm"
                        className="ms-2"
            >
                {t(lang, 'partners.common.warehouses_access')}
            </IconButton>
            {show &&
                <Modal show={true} size="xl">
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'partners.common.warehouses_access')}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <BindWarehousesToOrganizationsForm warehouses={warehouses}
                                                           handleClose={handleClose}
                        />
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default BindWarehousesToOrganizationsModal;