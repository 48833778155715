import { Card, Col, Row, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Translate, selectLang } from "../../../../../app/store/reducers/main/mainReducer"
import Cleave from 'cleave.js/react';
import { useFormContext } from "react-hook-form"
import IconButton from "../../../../common/IconButton"
import Product from "./Product"
import { useState } from "react"

const Products = ({ fieldsProduct, addFieldProduct, removeFieldProduct }) => {
    const [totalAmounts, setTotalAmounts] = useState({
        deliverySum: null,
        vatSum: null,
        deliverySumWithVat: null
    })
    const { watch } = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate
    const watchProducts = watch('products') 

    const calcAmounts = () => {
        let totalDeliverySum = watchProducts.reduce((total, product) => total + +product.deliverySum, 0)
        let totalVatSum = watchProducts.reduce((total, product) => total + +product.vatSum, 0)
        let totalDeliverySumWithVat = watchProducts.reduce((total, product) => total + +product.deliverySumWithVat, 0)
        totalDeliverySum = totalDeliverySum.toFixed(2)
        totalVatSum = totalVatSum.toFixed(2)
        totalDeliverySumWithVat = totalDeliverySumWithVat.toFixed(2)
        setTotalAmounts({
            deliverySum: totalDeliverySum,
            vatSum: totalVatSum,
            deliverySumWithVat: totalDeliverySumWithVat
        })
    }

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col><h5 className="mb-0">{t(lang, "roaming.contracts.add.products.title")}</h5></Col>
                    <Col className="text-end">
                        <IconButton
                            onClick={addFieldProduct}
                            variant="falcon-primary" 
                            icon="plus"
                            size="sm"
                            transform="shrink-3"
                        >{t(lang, "roaming.contracts.add.products.add_product")}
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.sequence")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.name")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.catalog")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.barcode")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.measure")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.quantity")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.price")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.sum")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.vat_rate")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.vat_sum")}</th>
                            <th scope="col" className="fs--2 fw-bold">{t(lang, "roaming.contracts.add.products.sum_with_vat_sum")}</th>
                            <th scope="col"/>
                        </tr>
                    </thead>
                    <tbody>
                        {fieldsProduct.map((product, index) => (
                            <Product key={product.id} product={product} index={index} removeFieldProduct={removeFieldProduct} onChangeAmounts={calcAmounts}/>
                        ))}
                    <tr>
                        <td colSpan={7} className="px-1 fs--2 fw-bold text-end">{t(lang, "roaming.contracts.add.products.total_info")}</td>
                        <td className="p-1">
                            <Cleave
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: 2
                                }}
                                value={totalAmounts.deliverySum}
                                className={`form-control p-1`}
                            />
                        </td>
                        <td></td>
                        <td className="p-1">
                            <Cleave
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: 2
                                }}
                                value={totalAmounts.vatSum}
                                className={`form-control p-1`}
                            />
                        </td>
                        <td className="p-1">
                            <Cleave
                                options={{
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: 2
                                }}
                                value={totalAmounts.deliverySumWithVat}
                                className={`form-control p-1`}
                            />
                        </td>
                        <td></td>
                    </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default Products