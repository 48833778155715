import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import DraftRefund from "../../../../components/hippo/warehouse-operation/draft-refund/view/DraftRefund";
import {loadDraftRefundAsync} from "../../../../app/store/reducers/warehouse-operation/draftRefundReducer";
import {APPROVE_DRAFT_REFUND_SUCCEED, BIND_DRAFT_REFUND_ITEM_SUCCEED, DELETE_DRAFT_REFUND_SUCCEED} from "../../../../app/eventbus/warehouse-operation/draftRefundEvents";

const ViewDraftRefund = () => {
    const [draftRefund, setDraftRefund] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        const onBindDraftRefundItemSucceed = EventBus.on(BIND_DRAFT_REFUND_ITEM_SUCCEED, () => {
            loadDraftRefund();
        });

        const onApproveDraftRefundSucceed = EventBus.on(APPROVE_DRAFT_REFUND_SUCCEED, (draftRefund) => {
            history.replace(`/warehouse/operation/refunds/view/${draftRefund.id}`);
        });

        const onDeleteDraftRefundSucceed = EventBus.on(DELETE_DRAFT_REFUND_SUCCEED, (draftRefund) => {
            history.replace('/warehouse/operation/draft-refunds');
        });

        return () => {
            EventBus.remove(APPROVE_DRAFT_REFUND_SUCCEED, onApproveDraftRefundSucceed);
            EventBus.remove(BIND_DRAFT_REFUND_ITEM_SUCCEED, onBindDraftRefundItemSucceed);
            EventBus.remove(DELETE_DRAFT_REFUND_SUCCEED, onDeleteDraftRefundSucceed);
        };
    }, [])

    useEffect(() => {
        loadDraftRefund();
    }, [activeOrganization, id])

    const loadDraftRefund = () => {
        loadDraftRefundAsync(id)
            .then(loadedDraftRefund => setDraftRefund(loadedDraftRefund))
            .catch(() => setDraftRefund(null))
    };

    if (!draftRefund)
        return <span>loading...</span>

    return <DraftRefund draftRefund={draftRefund}/>;
};

export default ViewDraftRefund;