import React, {useState} from "react";
import LeadNoteList from "./LeadNoteList";
import LeadAddNoteForm from "./LeadAddNoteForm";
import LeadEditNoteForm from "./LeadEditNoteForm";

const LeadNote = () => {
    const [editingNote, setEditingNote] = useState(null);
    const onEditNote = (note) => {
        setEditingNote(note)
    }
    const onCancel = () => {
        setEditingNote(null)
    }
    const onSuccessEdited = () => {
        setEditingNote(null)
    }
    return (<>
        {editingNote && <LeadEditNoteForm editingNote={editingNote} onCancel={onCancel} onSuccess={onSuccessEdited}/>}
        {!editingNote && <LeadAddNoteForm/>}
        <LeadNoteList onEdit={onEditNote}/>
    </>)
}
export default LeadNote