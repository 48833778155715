import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {OperationItemSelectOptionExtraInfos, UnmarkedPriceWholesalePrice} from "../../enum/warehouseOperationWrapper";
import React from "react";
import WarehouseOperationWarehouseSelect from "../../components/WarehouseOperationWarehouseSelect";
import EmployeeSelect from "../../../common/EmployeeSelect";


const RevisionDefaultSettingsForm = () => {
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate




    return(
        <>
            <Card>
                <Row>
                    <Col xs={12} xl={6}>
                        <Card.Header className="d-flex">
                            <Form.Switch
                                {...form.register('isCheckedViewDateTime')}
                            />
                            <span>{t(lang, "Выключить / включить дата и время")}</span>
                        </Card.Header>
                    </Col>

                    <Col xs={12} xl={6}>
                        <Card.Header className="d-flex">
                            <Form.Switch
                                {...form.register('isCheckedViewNumber')}
                            />
                            <span>{t(lang, "Выключить / включить номер")}</span>
                        </Card.Header>
                    </Col>

                    <Col xs={12} xl={6}>
                        <Card.Header className="d-flex">
                            <Form.Switch
                                {...form.register('isCheckedViewCategories')}
                            />
                            <span>{t(lang, "Выключить / включить категории")}</span>
                        </Card.Header>
                    </Col>

                    <Col xs={12} xl={6}>
                        <Card.Header className="d-flex">
                            <Form.Switch
                                {...form.register('isCheckedViewWarehouses')}
                            />
                            <span>{t(lang, "Выключить / включить склад")}</span>
                        </Card.Header>
                    </Col>

                    <Col xs={12} xl={6}>
                        <Card.Header>
                            <Card.Text>{t(lang, "Выбор сотрудника по умолчанию")}</Card.Text>
                            <Controller
                                name="defaultEmployeeId"
                                render={({field}) => {
                                    return (
                                        <EmployeeSelect
                                            defaultValue={field?.value}
                                            onChange={(option) => {
                                                field.onChange(option ? option?.id : null)
                                            }}
                                            isClearable={true}
                                            allowAdd={true}
                                            allowEdit={true}
                                        />
                                    )
                                }}
                            />
                        </Card.Header>
                    </Col>

                    <Col xs={12} xl={6}>
                        <Card.Header>
                            <Card.Text>{t(lang, "При поиске товара показывать цену")}</Card.Text>
                            <Controller
                                name="priceTypeOfInputOption"
                                render={({field}) => {
                                    return(
                                        <Select
                                            options={UnmarkedPriceWholesalePrice}
                                            defaultValue={UnmarkedPriceWholesalePrice?.find(f => f.value === field.value)}
                                            hideSelectedOptions
                                            onChange={(option) => field.onChange(option.value)}
                                            getOptionLabel={(option) => t(lang, `${option.label}`)}
                                            getOptionValue={(option) => option.value}
                                        />
                                    )
                                }}
                            />
                        </Card.Header>
                    </Col>

                    <Col xs={12} xl={6}>
                        <Card.Header>
                            <Card.Text>{t(lang, "При поиске товара показывать доп. информацию")}</Card.Text>
                            <Controller
                                name="operationItemExtraInfo"
                                render={({field}) => {
                                    return(
                                        <Select
                                            options={OperationItemSelectOptionExtraInfos}
                                            defaultValue={OperationItemSelectOptionExtraInfos?.find(f => f.value === field.value)}
                                            hideSelectedOptions
                                            onChange={(option) => field.onChange(option.value)}
                                            getOptionLabel={(option) => t(lang, option.label)}
                                            getOptionValue={(option) => option.value}
                                            placeholder={t(lang, 'items.common.select')}
                                        />
                                    )
                                }}
                            />
                        </Card.Header>
                    </Col>

                    <Col xs={12} lg={6}>
                        <Card.Header>
                            <Card.Text>{t(lang, "Количество отображаемых товаров при поиске")}</Card.Text>
                            <Form.Control
                                name='showItemSearchOptions'
                                type="number"
                                isInvalid={form?.formState?.errors?.showItemSearchOptions}
                                isValid={Object.keys(form?.formState?.errors).length > 0 && !form?.formState?.errors?.showItemSearchOptions}
                                {...form.register(`showItemSearchOptions`, {
                                    validate: value => +value <= 100 || "0 ... 100"
                                })}
                            />
                            <Form.Control.Feedback
                                type="invalid">{form?.formState?.errors?.showItemSearchOptions?.message}</Form.Control.Feedback>
                        </Card.Header>
                    </Col>

                    <Col xs={12} xl={6}>
                        <Card.Header className="d-flex">
                            <Form.Switch {...form.register('showOperationItemPurchasePrice')}/>
                            <span>{t(lang, 'warehouse.common.settings.show_operation_item.purchase_price')}</span>
                        </Card.Header>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="d-flex justify-content-end">
                        <Card.Footer>
                            <Button
                                variant="falcon-primary"
                                type="submit"
                            >
                                {t(lang, "items.common.save")}
                            </Button>
                        </Card.Footer>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default RevisionDefaultSettingsForm;
