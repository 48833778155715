import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import EmpowermentForm from "./EmpowermentForm";
import dayjs from "dayjs";
import React, {useEffect} from "react";
import {
    generateIdAsync,
    loadCompanyProductCatalogsAsync,
    loadDistrictsAsync, loadMeasurementsAsync
} from "../../../../../app/store/reducers/roaming/roamingReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {
    draftEmpowermentAsync,
    sendEmpowermentAsync
} from "../../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";

const roamingDayJSDateFormat = 'YYYY-MM-DD'

const EmpowermentFillForm = ({empowerment}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const activeCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)


    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
    }, [activeOrganization])

    useEffect(() => {
        dispatch(loadDistrictsAsync(lang));
        dispatch(loadMeasurementsAsync(lang));
    },[])

    const methods = useForm({
        defaultValues: {
            empowermentInfo: {
                number: empowerment?.info?.number,
                start: dayjs(empowerment?.info?.start, roamingDayJSDateFormat).toDate() || null,
                end: dayjs(empowerment?.info?.end, roamingDayJSDateFormat).toDate() || null
            },
            contractInfo: {
                number: empowerment?.contract?.number,
                date: dayjs(empowerment?.contract?.date, roamingDayJSDateFormat).toDate() || null
            },
            personInfo: {
                inn: empowerment?.person_info?.person_inn,
                fullName: empowerment?.person_info?.full_name,
            },
            customerInfo: {
                inn: empowerment?.customer?.inn,
                name: empowerment?.customer?.name,
                pinflOrInn: empowerment?.customer?.inn,
                address: empowerment?.customer?.Address || "",
                cellPhone: empowerment?.customer?.Mobile || "",
                housePhone: empowerment?.customer?.WorkPhone || "",
                oked: empowerment?.customer?.Oked || "",
                director: empowerment?.customer?.director,
                accountant: empowerment?.customer?.accountant,
                districtId: empowerment?.customer?.DistrictId || null,
            },
            executorInfo: {
                inn: empowerment?.executor?.inn,
                name: empowerment?.executor?.name,
                pinflOrInn: empowerment?.executor?.inn,
                address: empowerment?.executor?.Address || "",
                cellPhone: empowerment?.executor?.Mobile || "",
                housePhone: empowerment?.executor?.WorkPhone || "",
                oked: empowerment?.executor?.Oked || "",
                director: empowerment?.executor?.director,
                accountant: empowerment?.executor?.accountant,
                districtId: empowerment?.executor?.DistrictId || null,
            },
            items: empowerment?.items?.map(item => {
                return  {
                    product: item.name,
                    measurementId: item.measure_id,
                    quantity: item.quantity,
                    catalogClassCode: item.catalog.code,
                    catalogClassName: item.catalog.name,
                }
            }),
        }
    });

    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name
        const [EmpowermentId, AgentEmpowermentId, EmpowermentProductId] = await generateIdAsync(3)


        const EmpowermentForm = JSON.stringify({
            EmpowermentId,
            EmpowermentDoc: {
                EmpowermentNo: formData.empowermentInfo.number,
                EmpowermentDateOfIssue: formData.empowermentInfo.start && dayjs(formData.empowermentInfo.start).format(roamingDayJSDateFormat) || null,
                EmpowermentDateOfExpire: formData.empowermentInfo.end && dayjs(formData.empowermentInfo.end).format(roamingDayJSDateFormat) || null,
            },
            ContractDoc: {
                ContractNo: formData.contractInfo.number,
                ContractDate: formData.contractInfo.date && dayjs(formData.contractInfo.date).format(roamingDayJSDateFormat) || null
            },
            Agent: {
                AgentEmpowermentId,
                AgentTin: formData.personInfo.pinfl || formData.personInfo.inn,
                JobTitle: "",
                Fio: formData.personInfo.fullName || "",
                Passport: {
                    Number: "",
                    DateOfIssue: null,
                    IssuedBy: ""
                }
            },
            SellerTin: formData.executorInfo.pinflOrInn,
            BuyerTin: formData.customerInfo.pinflOrInn,
            Seller: {
                Name: formData.executorInfo.name || "",
                Address: formData.executorInfo.address || "",
                Mobile: formData.executorInfo.cellPhone || "",
                WorkPhone: formData.executorInfo.housePhone || "",
                Oked: formData.executorInfo.oked || "",
                DistrictId: formData.executorInfo.districtId,
                Director: formData.executorInfo.director || "",
                Accountant: formData.executorInfo.accountant || "",
            },
            Buyer: {
                Name: formData.customerInfo.name || "",
                Address: formData.customerInfo.address || "",
                Mobile: formData.customerInfo.cellPhone || "",
                WorkPhone: formData.customerInfo.housePhone || "",
                Oked: formData.customerInfo.oked || "",
                DistrictId: formData.customerInfo.districtId,
                Director: formData.customerInfo.director || "",
                Accountant: formData.customerInfo.accountant || "",
            },
            ProductList: {
                EmpowermentProductId,
                Tin: formData.customerInfo.pinflOrInn,
                Products: formData.items.map((item, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: item.product,
                        MeasureId: item.measurementId,
                        Count: +item.quantity,
                        CatalogCode: item.catalogClassCode || "",
                        CatalogName: item.catalogClassName || ""
                    }
                })
            },
        })



        if(buttonType === "send") {
            if(activeCertificate?.keyId) {
                await sendEmpowermentAsync(activeCertificate, lang, EmpowermentForm)
            }
        } else if (buttonType === "draft") {
            await draftEmpowermentAsync(EmpowermentForm)
        }
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} id="EmpowermentForm">
                <EmpowermentForm draftEmpowerment={empowerment} />
            </Form>
        </FormProvider>
    )
}


export default EmpowermentFillForm;