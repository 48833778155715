import {createSlice} from "@reduxjs/toolkit";
import ExcelService from "../../../../services/excelService";

export const excelSlice = createSlice({
    name: 'excel',
    initialState: {
        templates: [],
        organizationTemplates: [],
    },
    reducers: {
        updateTemplates: (state, action) => {
            state.templates = action.payload
        },
        updateOrganizationTemplates: (state, action) => {
            state.organizationTemplates = action.payload
        },
    }
});

export const selectTemplates = state => state.excel.templates;
export const selectOrganizationTemplates = state => state.excel.organizationTemplates;

export const loadTemplatesAsync = () => dispatch => {
    return new Promise((resolve, reject) => {
        ExcelService.getTemplates()
            .then(response => {
                dispatch(excelSlice.actions.updateTemplates(response.data));
                resolve();
            })
            .catch(error => {
                reject(error);
            })
    })
};
export const loadOrganizationTemplatesAsync = () => dispatch => {
    return new Promise((resolve, reject) => {
        ExcelService.getOrganizationTemplates()
            .then(response => {
                dispatch(excelSlice.actions.updateOrganizationTemplates(response.data));
                resolve();
            })
            .catch(error => {
                reject(error);
            })
    })
};
export const updateOrganizationTemplatesAsync = (templates) => {
    return new Promise((resolve, reject) => {
        ExcelService.updateOrganizationTemplates(templates)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    });
}

export const parseExcelFileAsync = (base64Content) => {
    return new Promise((resolve, reject) => {
        ExcelService.parse(base64Content)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const parseMultiSheetExcelFileAsync = (base64Content) => {
    return new Promise((resolve, reject) => {
        ExcelService.parseMultiSheet(base64Content)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const uploadSaleToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        ExcelService.getSaleTemplate({uri, id})
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const uploadPurchaseToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        ExcelService.getPurchaseTemplate({uri, id})
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const uploadRefundToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        ExcelService.getRefundTemplate({uri, id})
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const uploadReturnPurchaseToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        ExcelService.getReturnPurchaseTemplate({uri, id})
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const uploadRevisionToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        ExcelService.getRevisionTemplate({uri, id})
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const uploadWriteOffToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        ExcelService.getWriteOffTemplate({uri, id})
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const uploadTransferToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        ExcelService.getTransferTemplate({uri, id})
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const uploadOwnerOrganizationsPeriodReportAsync = (params) => {
    return new Promise((resolve, reject) => {
        ExcelService.getOwnerOrganizationsPeriodReport(params)
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((error) => reject(error))
    })
}

export const uploadOperationItemsReportAsync = (payload) => {
    return new Promise((resolve, reject) => {
        ExcelService.getOperationItemsPeriodReport(payload)
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((error) => reject(error))
    })
};


export default excelSlice.reducer;