export const WarehouseItemUpdated = 1
export const ContractorDebtUpdated = 2
export const AccountContractorBindingEnable = 3
export const AccountContractorBindingDisable = 4
export const AccountContractorBindRegister = 5
export const AccountContractorBindUnregister = 6
export const CashBoxRegistered = 7
export const CashBoxUpdated = 8
export const CashBoxDeleted = 9
export const CashBoxAmountsUpdated = 10
export const AccountCashBoxBindingEnabled = 11
export const AccountCashBoxBindingDisabled = 12
export const AccountCashBoxBindRegistered = 13
export const AccountCashBoxBindUnregistered = 14

export const pushUpdateFunc = (type, payload) => {
    switch (type) {
        case WarehouseItemUpdated:
            return {type: WarehouseItemUpdated, payload: payload};
        case ContractorDebtUpdated:
            return {type: ContractorDebtUpdated, payload: payload};
        case AccountContractorBindingEnable:
            return {type: AccountContractorBindingEnable, payload: payload};
        case AccountContractorBindingDisable:
            return {type: AccountContractorBindingDisable, payload: payload};
        case AccountContractorBindRegister:
            return {type: AccountContractorBindRegister, payload: payload};
        case AccountContractorBindUnregister:
            return {type: AccountContractorBindUnregister, payload: payload};
        case CashBoxRegistered:
            return {type: CashBoxRegistered, payload: payload};
        case CashBoxUpdated:
            return {type: CashBoxUpdated, payload: payload};
        case CashBoxDeleted:
            return {type: CashBoxDeleted, payload: payload};
        case CashBoxAmountsUpdated:
            return {type: CashBoxAmountsUpdated, payload: payload};
        case AccountCashBoxBindingEnabled:
            return {type: AccountCashBoxBindingEnabled, payload: payload};
        case AccountCashBoxBindingDisabled:
            return {type: AccountCashBoxBindingDisabled, payload: payload};
        case AccountCashBoxBindRegistered:
            return {type: AccountCashBoxBindRegistered, payload: payload};
        case AccountCashBoxBindUnregistered:
            return {type: AccountCashBoxBindUnregistered, payload: payload};
        default:
            return null
    }
}
