import React, {useEffect, useState} from 'react';
import {Badge, Card, Dropdown, OverlayTrigger, Popover} from 'react-bootstrap';
import BranchTableHeader from './BranchDataTableHeader';
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import {selectBranches} from '../../../app/store/reducers/branch/branchReducer';
import {useDispatch, useSelector} from 'react-redux';
import AdvanceTablePagination from '../../common/advance-table/AdvanceTablePagination';
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import DeleteBranchModal from "./DeleteBranchModal";
import EditBranchModal from "./EditBranchModal";
import checkPermission from "../../../enum/Permission/CheckPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import {getAllWarehousesAsync} from "../../../app/store/reducers/warehouse/warehouseReducer";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import CardDropdown from "../../common/CardDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PermissionBranchDelete} from "../../../enum/Permission/RolePermission";

const BranchDataTable = ({activeColumns}) => {
  const dispatch = useDispatch()
  const [deletingBranchId, setDeletingBranchId] = useState(null)
  const activeOrganization = useSelector(selectActiveOrganization)
  const branches = useSelector(selectBranches)
  const lang = useSelector(selectLang);
  const t = Translate;

  useEffect(() => {
    dispatch(getAllWarehousesAsync({id: activeOrganization.id}))
  }, [activeOrganization])

  function getActiveColumns(activeColumns, columns = []) {
    return columns?.filter(column => activeColumns[column?.id])
  }

  const columns = getActiveColumns(activeColumns, [
    {
      Header: "#",
      id: "index",
      filterable: false,
      cellProps: {
        width: '40px',
      },
      Cell: ({row}) => {
        return row.index + 1;
      }
    },
    {
      accessor: 'name',
      id: "name",
      Header: t(lang, "edi.branch.datatable.header.row.name"),
      cellProps: {
        className: 'fw-bold'
      },
      Cell: ({row: {original}}) => {
        return (
          <>
            <span>{original.name}</span>
            {original.roaming_address &&
              <Badge pill bg="primary" className="ms-2">
                Адресс указан
              </Badge>
            }
          </>
        )
      }
    },
    {
      accessor: 'code',
      id: "code",
      Header: t(lang, "edi.branch.datatable.header.row.code"),
      cellProps: {
        className: 'fw-bold'
      },
    },
    {
      accessor: 'roaming_branch.branch_name',
      id: "branch_name",
      Header: t(lang, "edi.branch.datatable.header.row.branch_name"),
      cellProps: {
        className: 'fw-bold'
      },
    },
    {
      accessor: 'roaming_branch.branch_number',
      id: "branch_code",
      Header: t(lang, "edi.branch.datatable.header.row.branch_code"),
      cellProps: {
        className: 'fw-bold'
      },
    },
    {
      accessor: 'warehouse',
      id: "bind_warehouse",
      Header: t(lang, "edi.branch.datatable.header.bind.warehouses"),
      cellProps: {
        className: 'fw-bold'
      },
      Cell: ({row: {original}}) => {
        const warehouseNames = original?.warehouses.map(x => x.name) ?? [];

        return <div>
          <OverlayTrigger placement={'left'} trigger={['hover', 'focus']} overlay={
            <Popover>
              <Popover.Body>
                {original?.warehouses.map(item => <strong key={item.id} className={'d-block'}>{item.name}</strong>)}
              </Popover.Body>
            </Popover>
          }>
            <strong className={'cursor-default'}>
              {warehouseNames.join('; ')}
            </strong>
          </OverlayTrigger>
        </div>
      }
    },
    {
      accessor: 'none',
      id: "button_action",
      Header: '',
      disableSortBy: true,
      cellProps: {
        width: '80px',
        className: 'text-end'
      },
      Cell: ({row}) => {
        return (
          <CardDropdown drop={'start'} btnRevealClass="btn-reveal-sm">
            <EditBranchModal branch={row.original}>
              <Dropdown.Item className="text-warning">
                <FontAwesomeIcon className="me-1" icon={"edit"} color={'warning'}/>
                <span>{t(lang, "roaming.common.edit")}</span>
              </Dropdown.Item>
            </EditBranchModal>
            <Dropdown.Item
              onClick={() => {
                dispatch(checkPermission(PermissionBranchDelete)) ? setDeletingBranchId(row.original.id) : dispatch(toastPermission())
              }}
              className="text-danger">
              <FontAwesomeIcon className="me-1" icon={"trash"}/>
              <span>{t(lang, "roaming.invoice.delete")}</span>
            </Dropdown.Item>
          </CardDropdown>
        );
      }
    }
  ]);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={branches}
        pagination
        perPage={1000}
      >
        <Card className="mb-3">
          <Card.Header>
            <BranchTableHeader table/>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              table
              rowsPerPageSelection
              rowsPerPageOptions={[10, 25, 50]}
            />
            <AdvanceTablePagination table/>
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>

      {deletingBranchId && <DeleteBranchModal id={deletingBranchId} show={!!deletingBranchId} onClose={() => {
        setDeletingBranchId(null)
      }}/>}
    </>
  );
};

export default BranchDataTable;
