import React from 'react';
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import ShipmentForm from "./forms/ShipmentForm";
import {FormProvider, useForm} from "react-hook-form";
import ConvertQuantityToWord from "../../../app/filter/ConvertQuantityToWord";
import {SumLimit} from "../../../app/order/OrderItemQuantityLimitStrategyType";
import {shipmentCustomerEditAsync} from "../../../app/store/reducers/shipment/shipmentReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {selectEdiSettings} from "../../../app/store/reducers/settings/ediSettingsReducer";
import {Roadway} from "../roaming/waybill/forms/SendWaybillFormV2";

const ShipmentCustomerEditForm = ({shipment, order}) => {
    const isSumLimit = order?.item_quantity_limit_strategy === SumLimit.id;
    const settings = useSelector(selectEdiSettings)
    const lang = useSelector(selectLang);
    const t = Translate;

    const shipmentItemQuantityLeft = (shipmentItem) => {
        const orderItem = order.items.find(orderItem => orderItem.id === shipmentItem.order_item.id);
        return isSumLimit ? shipmentItem.quantity + orderItem?.quantity_left : orderItem.quantity_left;
    }

    const methods = useForm({
        defaultValues: {
            order_info: {
                number: shipment?.order?.info?.number,
                date: dayjs(shipment?.order?.info?.date, 'YYYY-MM-DD').toDate(),
                expires_on: dayjs(shipment?.order?.expires_on, 'YYYY-MM-DD').toDate() ,
            },
            shipment_info: {
                number: shipment?.shipment_info?.number,
                date: dayjs(shipment?.shipment_info?.date, 'YYYY-MM-DD').toDate(),
            },
            waybill_info: {
                number: shipment?.waybill_info?.number,
                date: dayjs(shipment?.waybill_info?.date, 'YYYY-MM-DD').toDate(),
            },
            contract_info: {
                number: shipment?.contract_info?.number,
                date: dayjs(shipment?.contract_info?.date, 'YYYY-MM-DD').toDate(),
            },
            car_info: {
                drivers: settings?.shipment?.vehicles?.drivers,
                numbers: settings?.shipment?.vehicles?.numbers,
                models: settings?.shipment?.vehicles?.models,
                name: shipment?.car_info?.name,
                serial_number: shipment?.car_info?.serial_number,
                mark: shipment?.car_info?.mark,
            },
            customer_shipment_id: shipment.order.customer_shipment_id,
            items: shipment.items.map((item, index) => {
                return {
                    uid: index,
                    id: item.order_item.id,
                    product: item.product,
                    barcode: item.barcode,
                    catalog_class_code: item.catalog_class_code,
                    customer_shipment_id: item.customer_shipment_id,
                    measurement: item.package_code || item.measurement,
                    package_code: item.package_code,
                    quantity: item.quantity,
                    price: item.price,
                    total: item.total,
                    nds_rate: item.nds_rate,
                    nds_value: item.nds_value,
                    quantity_left: shipmentItemQuantityLeft(item),
                    total_with_nds: item.total_with_nds,
                }
            }),
            totalWithNds: null,
        }
    });

    const onSubmit = (form) => {
        shipmentCustomerEditAsync(shipment.id, {
            total_in_words: ConvertQuantityToWord.getText(parseInt(form.totalWithNds)),
            items: form.items
                .filter(item => item.quantity > 0)
                .map(item => {
                    return {
                        order_item_id: item.id,
                        quantity: +item.quantity,
                        package_code:  item.package_code?.code?.toString() || item.package_code?.toString() || item.package_code,
                    }
                })
        })
            .then(() => toast.success(t(lang, 'edi.toast.edit_shipment_succeed')))
            .catch(() => toast.error(t(lang, 'edi.toast.edit_shipment_failed')))
    }

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <ShipmentForm isEditing={true} shipment={shipment} order={order} />
                </Form>
            </FormProvider>

        </>
    );
};

export default ShipmentCustomerEditForm;
