import React, {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {loadShipmentAsync} from "../../../../app/store/reducers/shipment/shipmentReducer";
import InvoiceRegisterForm from "./InvoiceRegisterForm";
import {useDispatch, useSelector} from "react-redux";
import {loadCompanyProductCatalogsAsync} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang} from "../../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiInvoiceCreate} from "../../../../enum/Permission/EdiPermission";

const InvoiceRegister = () => {
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const {params: {id}} = useRouteMatch();

    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiInvoiceCreate))) {
            if (history.length > 1) {
                history.push('/edi/shipments')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    const [shipment, setShipment] = useState(null);

    // load shipment and product map
    useEffect(() => {
        loadShipmentAsync(id)
            .then(setShipment)
    }, [id, activeOrganization]);

    // load company product catalogs
    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
    }, [activeOrganization, lang])


    if (!shipment)
        return <span>loading shipment...</span>

    return (
        <InvoiceRegisterForm shipment={shipment}/>
    )
}

export default InvoiceRegister;
