import React, {useState} from "react";
import {Form} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import AttorneyForm from "./AttorneyForm";
import {editAttorneyAsync} from "../../../../app/store/reducers/attorney/attorneyReducer";

const EditAttorneyForm = ({attorney}) => {
    const methods = useForm({
        defaultValues: {
            items: attorney.items.map(item => {
                return {
                    product: item.product,
                    measurement: item.measurement,
                    quantity: +item.quantity,
                    quantityInWords: item.quantity_in_words
                }
            }),
            ownerInfo: {
                inn: attorney.owner_info.inn,
                name: attorney.owner_info.name,
                oked: attorney.owner_info.oked,
                address: attorney.owner_info.address,
                bankInfo: {
                    account: attorney.owner_bank_info.account,
                    mfo: attorney.owner_bank_info.mfo,
                    name: attorney.owner_bank_info.name,
                }
            },
            attorneyInfo: {
                number: attorney.attorney_info.number,
                start: dayjs(attorney.attorney_info.start).toDate(),
                end: dayjs(attorney.attorney_info.end).toDate(),
            },
            personInfo: {
                inn: attorney.person_info.person_inn,
                position: attorney.person_info.position,
                fullName: attorney.person_info.full_name,
                passportInfo: {
                    number: attorney.passport_info.number,
                    serial: attorney.passport_info.serial,
                    issuer: attorney.passport_info.issuer,
                    issuedOn: dayjs(attorney.passport_info.issued_on).toDate()
                },
            },
            contractInfo: {
                number: attorney.contract_info.number,
                date: dayjs(attorney.contract_info.date).toDate()
            },
            contractorInfo: {
                inn: attorney.contractor_info.inn,
                name: attorney.contractor_info.name,
                oked: attorney.contractor_info.oked,
                address: attorney.contractor_info.address,
                bankInfo: {
                    account: attorney.contractor_bank_info.account,
                    mfo: attorney.contractor_bank_info.mfo,
                    name: attorney.contractor_bank_info.name,
                }
            },
            accountant: attorney.accountant,
            director: attorney.director,
        },
    });

    const [loading, setLoading] = useState(false);

    const onSubmit = (formData) => {
        setLoading(true);
        editAttorneyAsync(attorney.id, {
            items: formData.items.map(item => {
                return {
                    product: item.product,
                    measurement: item.measurement,
                    quantity: +item.quantity,
                    quantity_in_words: item.quantityInWords
                }
            }),
            owner_info: {
                inn: formData.ownerInfo.inn,
                name: formData.ownerInfo.name,
                oked: formData.ownerInfo.oked,
                address: formData.ownerInfo.address,
            },
            owner_bank_info: {
                account: formData.ownerInfo.bankInfo.account,
                mfo: formData.ownerInfo.bankInfo.mfo,
                name: formData.ownerInfo.bankInfo.name,
            },
            attorney_info: {
                number: formData.attorneyInfo.number,
                start: dayjs(formData.attorneyInfo.start).format("YYYY-MM-DD"),
                end: dayjs(formData.attorneyInfo.end).format("YYYY-MM-DD")
            },
            person_info: {
                position: formData.personInfo.position,
                full_name: formData.personInfo.fullName,
                person_inn: formData.personInfo.inn,
            },
            passport_info: {
                number: formData.personInfo.passportInfo.number,
                serial: formData.personInfo.passportInfo.serial,
                issuer: formData.personInfo.passportInfo.issuer,
                issued_on: dayjs(formData.personInfo.passportInfo.issuedOn).format("YYYY-MM-DD")
            },
            contract_info: {
                number: formData.contractInfo.number,
                date: dayjs(formData.contractInfo.date).format("YYYY-MM-DD")
            },
            contractor_info: {
                inn: formData.contractorInfo.inn,
                name: formData.contractorInfo.name,
                oked: formData.contractorInfo.oked,
                address: formData.contractorInfo.address,
            },
            contractor_bank_info: {
                account: formData.contractorInfo.bankInfo.account,
                mfo: formData.contractorInfo.bankInfo.mfo,
                name: formData.contractorInfo.bankInfo.name,
            },
            accountant: formData.accountant,
            director: formData.director
        })
            .then(response => {})
            .catch(error => {console.log(error)})
            .finally(() => {
                setLoading(false);
            });
    };


    return (

        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <AttorneyForm loading={loading}/>
            </Form>
        </FormProvider>
    )
};

EditAttorneyForm.propTypes = {
    attorney: PropTypes.object
};

EditAttorneyForm.defaultProps = {
    attorney: {},
};


export default EditAttorneyForm;