import React from 'react';
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import UploadImages from "../components/UploadImages";
import {Controller} from "react-hook-form";

const fieldName = "images";
const ItemFormCardImages = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Card className="h-100">
            <Card.Header className="border-bottom">
                <Card.Title>{t(lang, 'items.common.images')}</Card.Title>
            </Card.Header>
            <Card.Body>
                <div>
                    <Controller name={fieldName}
                                render={({field}) => (
                                    <UploadImages files={field.value}
                                                  onChange={field.onChange}
                                                  preview
                                    />
                                )}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

export default ItemFormCardImages;