import classNames from "classnames";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Modal} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import React, {cloneElement, Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MultiEmployeeSelect from "../../../common/MultiEmployeeSelect";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {bindEmployeeToContractorAsync} from "../../../../../app/store/reducers/contractor/contractorReducer";

const MassBindContractorsToEmployeeModal = ({selectedContractors, children, ...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = React.useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            employeeId: null,
        },
    });

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = (formData) => {
        setIsLoading(true);
        if (selectedContractors?.length > 0) {
            for (let i = 0; i < selectedContractors.length; i++) {
                bindEmployeeToContractorAsync(selectedContractors[i].id, {employeesIds: formData.employeeIds})
                    .catch(() => toast.error("No contractor found for employee"));
            }
        }
        setIsLoading(false);
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton variant={'falcon-primary'} size={'sm'} icon={'plus'} onClick={handleShow} {...props}>
                    {t(lang, 'Привязка контрагента с сотрудником')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose} size={'lg'}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'Привязать контагент(ов) к сотруднику')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'Сотрудник')}</Form.Label>
                            <Controller
                                control={control}
                                name={'employeeIds'}
                                rules={{required: t(lang, "crm.common.forms.validations.is_required")}}
                                render={({field, fieldState}) => (
                                    <MultiEmployeeSelect
                                        employeeIds={field.value}
                                        classNamePrefix={'react-select'}
                                        className={classNames({'is-invalid border border-danger rounded-2': fieldState.error})}
                                        onChange={(selectedEmployees) => field.onChange(selectedEmployees.map(e => e.id))}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                                {errors?.employeeIds?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {selectedContractors.length > 0 && <div>
                            <hr/>
                            <h5>{t(lang, 'Выбранные контрагенты')}:</h5>

                            <div className={'ms-2'}>
                                {selectedContractors.map((contractor, index) => (
                                    <div key={index}>
                                        <span className={'me-1'}>{index + 1}.</span>
                                        <b className={'text-black'}>{contractor.name}</b>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'Закрыть')}</Button>
                        <Button variant={'primary'} type={'submit'} disabled={isLoading}>
                            {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                            {t(lang, 'Привязать')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default MassBindContractorsToEmployeeModal;