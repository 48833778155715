import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {useHistory, useRouteMatch} from "react-router-dom";
import {loadDraftEmpowerment} from "../../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import DraftEmpowermentForm from "./DraftEmpowermentForm";
import {selectAccountRolePermissions} from "../../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingEmpowermentUpdate} from "../../../../../enum/Permission/RoamingPermission";


const DraftEmpowermentEdit = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [draftEmpowerment, setDraftEmpowerment] = useState(null)
    const {params: {id}} = useRouteMatch();
    const history = useHistory()
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingEmpowermentUpdate))) {
            if (history.length > 1) {
                history.push('/roaming/empowerments/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadEmpowerment()
    }, [id, activeOrganization])


    const loadEmpowerment = async () => {
        try {
            const getDraft = await loadDraftEmpowerment(id)
            setDraftEmpowerment(JSON.parse(getDraft?.data?.content))
        }catch (error) {
            history.push(`../send`)
        }
    }



    return(
        <>
            {draftEmpowerment && <DraftEmpowermentForm draftEmpowerment={draftEmpowerment} />}
            {!draftEmpowerment && <span>loading...</span>}
        </>
    )
}


export default DraftEmpowermentEdit;
