import React, {useState} from 'react';
import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {declineFreeFormDocumentAsync} from "../../../../app/store/reducers/roaming/roamingFreeFormDocumentReducer";
import SignActionWithSelectCertificatWrapper from "../../../hippo/roaming/components/SignActionWithSelectCertificatWrapper";

const DeclineFreeFormDocument = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const declineFreeFormDocument = () => {
        setLoading(true);
        declineFreeFormDocumentAsync({
            id: id,
            certificate: currentCertificate,
        }).finally(() => setLoading(false))
    }

    return (
        <>
            <SignActionWithSelectCertificatWrapper>
                <IconButton
                    size="sm"
                    {...props}
                    icon={faBan}
                    iconClassName="me-1"
                    variant="falcon-danger"
                    onClick={declineFreeFormDocument}
                >
                    {t(lang, 'roaming.free_form_document.view.action.cancel')}
                    {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
                </IconButton>
            </SignActionWithSelectCertificatWrapper>
        </>
    );
};

export default DeclineFreeFormDocument;