import axios from "axios";

class ExcelRegistryService {
    registerMassInvoice(payload) {
        return axios.post('/excel-registry/registry/mass-register/invoice', payload)
    }

    registerMassWaybill(payload) {
        return axios.post('/excel-registry/registry/mass-register/waybill', payload)
    }

    getExcelRegistry(id) {
        return axios.get(`/excel-registry/registry/${id}`)
    }

    getExcelRegistries(params) {
        return axios.get('/excel-registry/registry', {
            params: params,
        })
    }

    getExcelRegistriesCount(params) {
        return axios.get('/excel-registry/registry/count', {
            params: params,
        })
    }

    uploadFailedDocumentsToExcel(id) {
        return axios.get(`/excel-registry/registry/failed-document/${id}`, {
            responseType: "arraybuffer"
        })
    }

    uploadSuccessDocumentsToExcel(id) {
        return axios.get(`/excel-registry/registry/success-document/${id}`, {
            responseType: "arraybuffer"
        })
    }

    deleteExcelRegistry(id) {
        return axios.post(`/excel-registry/registry/delete//${id}`)
    }
}

const excelRegistryService = new ExcelRegistryService();
export default excelRegistryService;
