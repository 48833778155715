import React, {useEffect, useState} from 'react';
import {Col, Form, Row, Spinner} from "react-bootstrap";
import SelectCategory from "../../item/components/SelectCategory";
import AlertOnSelector from "../../item/datatable/AlertOnSelector";
import IconButton from "../../../common/IconButton";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {CheckScanBarcodeOrMarking} from "../../warehouse-operation/enum/warehouseOperationWrapper";
import EventBus from "../../../../app/eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../app/eventbus/itemEvents";

const OwnerOrganizationItemsDataTableFilter = ({
                                                   load,
                                                   loading,
                                                   filter,
                                                   onFilterChange,
                                                   onDebouncedFilterChange,
                                                   categories,
                                               }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [filters, setFilters] = useState({
        searchTerm: filter.searchTerm,
        sku: filter.sku,
        categoryId: filter.categoryId,
        alertOn: filter.alertOn,
    });

    useEffect(() => {
        const onScan = (value) => {
            const barcode = CheckScanBarcodeOrMarking(value);
            setFilters({...filters, searchTerm: barcode});
            onFilterChange({...filter, searchTerm: barcode});
        };

        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);
        return () => EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
    }, [filter, filters])

    return (
        <Row className="g-2">
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.datatable.filter.deep_search')}</Form.Label>
                    <Form.Control name="searchTerm"
                                  placeholder={t(lang, 'items.common.datatable.filter.deep_search.placeholder')}
                                  value={filters.searchTerm}
                                  onChange={(e) => {
                                      setFilters({...filters, searchTerm: e.target.value});
                                      onDebouncedFilterChange({...filters, searchTerm: e.target.value});
                                  }}

                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.alert_on.state')}</Form.Label>
                    <AlertOnSelector defaultValue={filters.alertOn}
                                     onChange={(option) => {
                                         setFilters({...filters, alertOn: option.value})
                                         onFilterChange({...filters, alertOn: option.value});
                                     }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.category')}</Form.Label>
                    <SelectCategory defaultCategoryId={filters.categoryId}
                                    categories={categories}
                                    onChange={(category) => {
                                        setFilters({...filters, categoryId: category ? category.id : null})
                                        onFilterChange({
                                            ...filters,
                                            categoryId: category ? category.id : null
                                        });
                                    }}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} className="text-end">
                <div className="d-grid d-sm-inline-block">
                    <IconButton icon={faRefresh}
                                variant={'falcon-warning'}
                                onClick={load}
                                disabled={loading}
                    >
                        {t(lang, 'items.common.reload')}
                        {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
                    </IconButton>
                </div>
            </Col>
        </Row>
    );
};

export default OwnerOrganizationItemsDataTableFilter;