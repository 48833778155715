import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import AppContext from "../../../../context/Context";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useLocation} from "react-router";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";

const NavbarNavLink = ({title, route, routeTitle}) => {
    const dispatch = useDispatch()
    const hideModal = useSelector(roamingHideModal)
    const lang = useSelector(selectLang);
    const t = Translate;
    const {pathname} = useLocation()
    const crm = routeTitle === "CRM" || routeTitle === "Warehouse"
    const {
        config: {navbarCollapsed},
        setConfig
    } = useContext(AppContext);


    const handleClick = () => {
        if (!hideModal) {
            dispatch(setRoamingCommonHideModal(true))
        }

        if (navbarCollapsed) {
            setConfig('navbarCollapsed', !navbarCollapsed);
        }
    };
    return (
        <Nav.Link
            as={title ? 'p' : Link}
            className={classNames('text-decoration-none', {
                'text-primary': crm ? pathname === route.to :  pathname.includes(route.to),
                'fw-bold': pathname.includes(route.to),
                'text-700 mb-0 fw-bold': title,
                'py-1': !title,
                'link-600': crm ? pathname !== route.to : !pathname.includes(route.to),
            })}
            to={route?.to}
            onClick={handleClick}
        >
            {title ? title : t(lang, route.name)}
        </Nav.Link>
    );
};

NavbarNavLink.propTypes = {
    title: PropTypes.string,
    route: PropTypes.shape({
        name: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        active: PropTypes.bool
    })
};

export default NavbarNavLink;
