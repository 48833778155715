import {useSelector} from "react-redux";
import React, {useEffect, useRef} from "react";
import {
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import {currencyConverterConvert, selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import numeral from "numeral";
import dayjs from "dayjs";
import {isNaN} from "underscore";
import {selectAccount} from "../../../../../app/store/reducers/auth/authReducer";
import {autocompleteValidator} from "handsontable/validators";
import Handsontable from "handsontable";
import {
    transferAddItemAsync, transferDeleteItemAsync, transferUpdateNoteAsync,
    transferRegisterAsync, transferUpdateDateAsync,
    transferUpdateItemAsync, transferUpdateNumberAsync
} from "../../../../../app/store/reducers/warehouse-operation/transferReducer";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../../app/eventbus/itemEvents";
import {CheckScanBarcodeOrMarking} from "../../enum/warehouseOperationWrapper";


const propPositionNumber = "positionNumber"
const propWarehouseNameFrom = "warehouseNameFrom"
const propWarehouseItemStateFrom = "warehouseItemStateFrom"
const propItemName = "itemName"
const propQuantity = 'quantity'
const propWarehouseNameTo = "warehouseNameTo"
const propWarehouseItemStateTo = "warehouseItemStateTo"
const propPriceAmount = 'price'
const propPriceCurrency = 'priceCurrency'
const propPriceType = 'priceType'
const propTotal = 'total'
const propActions = "actions"

const colIndexPositionNumber = 0
const colIndexWarehouseNameFrom = 1
const colIndexWarehouseItemStateFrom = 2
const colIndexItemName = 3
const colIndexQuantity = 7
const colIndexPriceAmount = 8
const colIndexPriceType = 9
const colIndexTotal = 10
const colIndexWarehouseNameTo = 11
const colIndexWarehouseItemStateTo = 12
const colIndexPriceCurrency = 13
const colIndexActions = 14

const priceTypeCommon = "Розничная"
const priceTypeBulk = "Оптовая"
const priceTypes = [priceTypeCommon, priceTypeBulk]

const updateSourceTypeComputed = 'computed'

// const dateFormat = 'DD-MM-YYYY'
const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'

const numericValidator = (value, callback) => {
    callback(typeof (value) === 'number' && !isNaN(value));
}

const dateValidator = (value, callback) => {
    value = value || ''
    callback(dayjs(value, 'YYYY-MM-DD').isValid())
}

const timeValidator = (value, callback) => {
    const now = dayjs(new Date())

    value = `${now.format('YYYY-MM-DD')} ${value}`
    callback(dayjs(value, `${'YYYY-MM-DD'} ${timeFormat}`).isValid())
}

function dropdownValidator(value, callback) {
    if (this) {
        if (this.source.indexOf(value) > -1) {
            callback(true)
            return
        }
    }
    callback(false)
}

const createPositionObject = rowIndex => {
    return {
        id: null,
        item: null,
        quantity: 0,
        price: {
            amount: 0,
            currency: null
        },
        warehouseItemFrom: null,
        warehouseItemTo: null,
        marks_from: [],
        marks_to: [],
        rowIndex: rowIndex
    }
}

const isOkTransfer = transfer => {
    return transfer.date && transfer.date.isValid()
}

const isOkPosition = position => {
    return typeof (position.quantity) === 'number' && !isNaN(position.quantity) // position.quantity
        && typeof (position.price.amount) === 'number' && !isNaN(position.price.amount) // position.price.amount
        && position.price.currency // position.currency
        && position.warehouseItemFrom // position.warehouseItemFrom
        && position.warehouseItemTo // position.warehouseItemTo
        && (position.warehouseItemFrom.id !== position.warehouseItemTo.id) // duplicate warehouse select
}

const TransferExcelForm = ({transfer}) => {
    const items = useSelector(selectItems)
    const currencies = useSelector(selectCurrency)
    const systemMoneyFormat = useSelector(selectNumberFormat);
    const warehouses = useSelector(selectWarehouses)
    const account = useSelector(selectAccount)
    const lang = useSelector(selectLang)
    const t = Translate;

    const itemsRef = useRef(items)
    const currenciesRef = useRef(currencies)
    const hotContainerRef = useRef(null)
    const hotRef = useRef(null)
    const positionRowsStartRef = useRef(3)
    const positionRowsEndRef = useRef(3)

    const transferRef = useRef({
        id: null,
        date: dayjs(new Date()),
        number: null,
        note: null,
        assigner: null,
        items: [
            createPositionObject(positionRowsEndRef.current)
        ],
        net_price: []
    })

    const upsert = () => {
        // register transfer

        const positions = transferRef.current.items.filter(isOkPosition)

        transferRegisterAsync({
            date: transferRef.current.date.format("YYYY-MM-DD HH:mm:ss"),
            items: positions.map(position => {
                return {
                    item_id: position.item.item.id,
                    quantity: position.quantity,
                    marks_from: position.marks_from || [],
                    marks_to: position.marks_to || [],
                    price: {
                        amount: position.price.amount,
                        currency_id: position.price.currency.id,
                    },
                    warehouse_from_id: position?.warehouseItemFrom.id,
                    warehouse_to_id: position?.warehouseItemTo.id,
                }
            }),
            note: transferRef.current.note,
            approve: false,
        }).then(({data}) => {
            transferRef.current.id = data.id
            for (let i = 0; i < data.items.length; i++) {
                positions[i].id = data.items[i].id
            }
        })
    }

    const upsertPosition = position => {
        if (!isOkPosition(position)) return;

        if (transferRef.current.id) {
            if (position.id) {
                transferUpdateItemAsync({
                    transferId: transferRef.current.id,
                    operation: {
                        operation_item_id: position.id,
                        warehouse_from_id: position.warehouseItemFrom.id,
                        warehouse_to_id: position.warehouseItemTo.id,
                        item_id: position.item.item.id,
                        quantity: position.quantity,
                        marks_from: position.marks_from || [],
                        marks_to: position.marks_to || [],
                        price: {
                            amount: position.price.amount,
                            currency_id: position.price.currency.id
                        }
                    }
                })
            } else {
                // add
                transferAddItemAsync({
                    transferId: transferRef.current.id,
                    operation: {
                        item_id: position.item.item.id,
                        warehouse_from_id: position.warehouseItemFrom.id,
                        warehouse_to_id: position.warehouseItemTo.id,
                        quantity: position.quantity,
                        marks_from: position.marks_from || [],
                        marks_to: position.marks_to || [],
                        price: {
                            amount: position.price.amount,
                            currency_id: position.price.currency.id
                        }
                    }
                })
                    .then(({data}) => {
                        position.id = data.transfer_item.id
                    })
                    .catch()
            }
        } else if (isOkTransfer(transferRef.current) && transferRef.current.items.filter(isOkPosition).length > 0) {
            upsert()
        }
    }

    const deletePosition = position => {
        if (transferRef.current.id) {
            if (position.id) {
                transferDeleteItemAsync({transferId: transferRef.current.id, operationId: position.id})
                    .then()
                    .catch()
                calculateNetPrice()
                renderNetPrice()
            }
        }
    }

    const enablePosition = position => {
        const hot = hotRef.current;

        hot.setCellMeta(position.rowIndex, colIndexWarehouseNameFrom, "readOnly", false)
        hot.setCellMeta(position.rowIndex, colIndexWarehouseNameTo, "readOnly", false)
        hot.setCellMeta(position.rowIndex, colIndexPriceType, "readOnly", false)
        hot.setCellMeta(position.rowIndex, colIndexPriceCurrency, "readOnly", false)
        hot.setCellMeta(position.rowIndex, colIndexItemName, "readOnly", false)
        hot.setCellMeta(position.rowIndex, colIndexQuantity, "readOnly", false)
        hot.setCellMeta(position.rowIndex, colIndexPriceAmount, "readOnly", false)

        hot.render()
    }

    const enablePositions = () => {
        for (let i = 0; i < transferRef.current.items.filter(isOkPosition).length; i++) {
            enablePosition(transferRef.current.items[i])
        }
    }

    const disablePosition = position => {
        const hot = hotRef.current;

        hot.setCellMeta(position.rowIndex, colIndexItemName, "readOnly", true)
        hot.setCellMeta(position.rowIndex, colIndexQuantity, "readOnly", true)
        hot.setCellMeta(position.rowIndex, colIndexPriceAmount, "readOnly", true)
    }

    const disablePositions = () => {
        for (let i = 0; i < transferRef.current.items.length; i++) {
            disablePosition(transferRef.current.items[0])
        }
    }

    const createPositionRow = () => {
        const hot = hotRef.current;
        hot.alter('insert_row_below', positionRowsEndRef.current)

        positionRowsEndRef.current = positionRowsEndRef.current + 1;
        transferRef.current.items.push(createPositionObject(positionRowsEndRef.current))
        const position = transferRef.current.items.find(x => x.rowIndex === positionRowsEndRef.current)
        constructPositionRow(position)
    }

    const constructPositionRow = rowPosition => {
        const hot = hotRef.current;

        const positionNumber = rowPosition.rowIndex - positionRowsStartRef.current + 1
        hot.setDataAtCell(rowPosition.rowIndex, colIndexPositionNumber, positionNumber, updateSourceTypeComputed)

        hot.getPlugin('mergeCells').merge(rowPosition.rowIndex, colIndexItemName, rowPosition.rowIndex, colIndexItemName + 3)
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexPositionNumber, {
            data: propPositionNumber,
            className: 'fw-bold bg-soft-dark text-dark text-center'
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexWarehouseNameFrom, {
            data: propWarehouseNameFrom,
            allowInvalid: false,
            strict: true,
            allowEmpty: false,
            type: 'dropdown',
            source: [],
            className: 'fw-semi-bold',
            validator: dropdownValidator,
            readOnly: true
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexWarehouseItemStateFrom, {
            data: propWarehouseItemStateFrom,
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
            },
            readOnly: true,
            className: 'fw-semi-bold',
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexItemName, {
            data: propItemName,
            source: (itemsRef?.current ?? items).map(x => x.item.name),
            type: "autocomplete",
            readOnly: false,
            validator: autocompleteValidator,
            allowInvalid: false,
            strict: true,
            allowEmpty: false,
            className: 'fw-semi-bold bg-soft-warning'
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexQuantity, {
            data: propQuantity,
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
            },
            allowEmpty: false,
            validator: numericValidator,
            className: 'fw-semi-bold bg-soft-success',
            readOnly: false,
            allowInvalid: false,
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexPriceAmount, {
            data: propPriceAmount,
            type: 'numeric',
            numericFormat: {
                pattern: '0,0',
            },
            readOnly: false,
            allowEmpty: false,
            validator: numericValidator,
            className: 'fw-semi-bold bg-soft-info',
            allowInvalid: false
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexTotal, {
            data: propTotal,
            type: 'numeric',
            numericFormat: {
                pattern: '0,0',
            },
            readOnly: true,
            className: 'fw-semi-bold bg-soft-primary text-black',
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexWarehouseNameTo, {
            data: propWarehouseNameTo,
            allowInvalid: false,
            strict: true,
            allowEmpty: false,
            type: 'dropdown',
            source: [],
            className: 'fw-semi-bold bg-soft-warning',
            validator: dropdownValidator,
            readOnly: true
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexWarehouseItemStateTo, {
            data: propWarehouseItemStateTo,
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
            },
            readOnly: true,
            className: 'fw-semi-bold',
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexPriceCurrency, {
            data: propPriceCurrency,
            type: 'dropdown',
            allowInvalid: false,
            strict: true,
            allowEmpty: false,
            source: (currenciesRef?.current || []).filter(x => x.is_active).map(x => x.name),
            validator: dropdownValidator,
            className: 'fw-semi-bold',
            readOnly: true
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexPriceType, {
            data: propPriceType,
            type: 'dropdown',
            allowInvalid: false,
            strict: true,
            allowEmpty: false,
            source: priceTypes,
            validator: dropdownValidator,
            className: 'fw-semi-bold',
            readOnly: true
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexActions, {
            data: propActions,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                td.innerHTML = null

                const deleteBtn = document.createElement('button')
                deleteBtn.innerText = "-"
                deleteBtn.classList.add('btn', 'btn-danger', 'btn-sm', 'float-end')
                deleteBtn.addEventListener('click', ev => {
                    ev.preventDefault();
                    if (window.confirm(t(lang, "warehouse.operation.item.common.delete_item_modal.title"))) {
                        onDeletePosition(row)
                    }
                })
                td.appendChild(deleteBtn)
            },
            readOnly: true,
        })

        if (!isOkTransfer(transferRef.current)) {
            const position = transferRef.current.items.find(x => x.rowIndex === rowPosition.rowIndex)
            disablePosition(position)
        }

        hot.render()
    }

    const calculateNetPrice = () => {
        const net_price = []
        for (let i = 0; i < transferRef.current.items.length; i++) {
            const transferItem = transferRef.current.items[i];
            if (transferItem.price?.currency) {
                let found = false;
                for (let j = 0; j < net_price.length; j++) {
                    if (net_price[j].id === transferItem.price.currency.id) {
                        net_price[j].amount += transferItem.price.amount * (transferItem.quantity || 0)
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    net_price.push({
                        id: transferItem.price.currency.id,
                        name: transferItem.price.currency.name,
                        amount: transferItem.price.amount * (transferItem.quantity || 0)
                    })
                }
            }
        }

        transferRef.current.net_price = net_price
    }

    const renderNetPrice = () => {
        const hot = hotRef.current

        for (let i = 8; i < 13; i++) {
            hot.setDataAtCell(positionRowsEndRef.current + 2, i, null)
            hot.setDataAtCell(positionRowsEndRef.current + 4, i, null)
        }

        for (let i = 0; i < transferRef.current.net_price.length; i++) {
            hot.setDataAtCell(positionRowsEndRef.current + 2, 8 + i, `${numeral.formats[systemMoneyFormat].format(transferRef.current.net_price[i].amount)} ${transferRef.current.net_price[i].name}`)
        }
    }

    const onDateChanged = (value, rowIndex, source) => {
        const now = dayjs(new Date())

        let time = now.format(timeFormat)
        if (transferRef.current.date)
            time = transferRef.current.date.format(timeFormat)

        transferRef.current.date = dayjs(`${value} ${time}`, `${dateFormat} ${timeFormat}`)

        // enable / disable positions
        if (isOkTransfer(transferRef.current)) {
            enablePositions()
        } else {
            disablePositions()
        }

        if (transferRef.current.id && transferRef.current.date.isValid()) {
            transferUpdateDateAsync({transferId: transferRef.current.id, date: transferRef.current.date.format("YYYY-MM-DD HH:mm:ss")})
                .then(() => {
                })
                .catch()
        }
    }

    const onTimeChanged = (value, rowIndex, source) => {
        const now = dayjs(new Date())

        let date = now.format(dateFormat)
        if (transferRef.current.date)
            date = transferRef.current.date.format(dateFormat)

        transferRef.current.date = dayjs(`${date} ${value}`, `${dateFormat} ${timeFormat}`)

        // enable / disable positions
        if (isOkTransfer(transferRef.current)) {
            enablePositions()
        } else {
            disablePositions()
        }

        if (transferRef.current.id && transferRef.current.date.isValid()) {
            transferUpdateDateAsync({transferId: transferRef.current.id, date: transferRef.current.date.format("YYYY-MM-DD HH:mm:ss")})
                .then(() => {
                })
                .catch()
        }
    }

    const onNumberChanged = (value, rowIndex, source) => {
        transferRef.current.number = value

        const hot = hotRef.current;
        hot.selectCell(positionRowsStartRef.current, colIndexItemName)

        if (transferRef.current.id) {
            transferUpdateNumberAsync({transferId: transferRef.current.id, number: transferRef.current.number})
                .then(() => {})
                .catch(() => {})
        }
    }

    const onNoteChanged = (value, rowIndex, source) => {
        transferRef.current.note = value

        if (transferRef.current.id) {
            transferUpdateNoteAsync({transferId: transferRef.current.id, note: transferRef.current.note})
                .then(() => {})
                .catch()
        }
    }

    const onAssignerChanged = (value, rowIndex, source) => {
        transferRef.current.assigner = value
    }

    const onItemChanged = (oldValue, value, rowIndex, source) => {
        if (oldValue === value) return;

        const hot = hotRef.current;

        const item = itemsRef.current.find(x => x.item.name?.trimStart() === value) || null
        const position = transferRef.current.items.find(x => x.rowIndex === rowIndex)

        // set item
        position.item = item

        // set price
        position.price = {
            amount: item.price.common_price.amount,
            currency: {
                id: item.price.common_price.currency.global_currency_id,
                name: item.price.common_price.currency.name
            }
        }
        hot.setDataAtCell(position.rowIndex, colIndexPriceAmount, position.price.amount, updateSourceTypeComputed)

        // set price type
        hot.setDataAtCell(position.rowIndex, colIndexPriceType, priceTypeCommon, updateSourceTypeComputed)

        // compute total
        const total = (position.quantity || 0) * position.price.amount
        hot.setDataAtCell(position.rowIndex, colIndexTotal, total, updateSourceTypeComputed)

        // set warehouse
        // update warehouse name source
        const warehouseNames = position.item.warehouse_states.warehouse_items.map(x => x.name)
        hot.setCellMeta(position.rowIndex, colIndexWarehouseNameFrom, "source", warehouseNames)
        hot.setCellMeta(position.rowIndex, colIndexWarehouseNameTo, "source", warehouseNames)
        let warehouseNameFrom = position.warehouseItemFrom?.name || null
        // let warehouseNameTo = position.warehouseItemTo?.name || null
        // select default warehouse name
        if (!warehouseNameFrom && warehouseNames.length > 0)
            warehouseNameFrom = warehouseNames[0]

        if (warehouseNameFrom) {
            const warehouseItemFrom = position.item.warehouse_states.warehouse_items.find(x => x.name?.trimStart() === warehouseNameFrom)
            hot.setDataAtCell(position.rowIndex, colIndexWarehouseNameFrom, warehouseItemFrom.name, updateSourceTypeComputed)
            position.warehouseItemFrom = warehouseItemFrom
        }

        // if (!warehouseNameTo && warehouseNames.length > 0)
        //     warehouseNameTo = warehouseNames[1]
        //
        // if (warehouseNameTo) {
        //     const warehouseItemTo = position.item.warehouse_states.warehouse_items.find(x => x.name === warehouseNameTo)
        //     hot.setDataAtCell(position.rowIndex, colIndexWarehouseNameTo, warehouseItemTo.name, updateSourceTypeComputed)
        //     position.warehouseItemTo = warehouseItemTo
        // }

        // set price currency
        hot.setDataAtCell(position.rowIndex, colIndexPriceCurrency, position.price.currency.name, updateSourceTypeComputed)

        // add new positions row
        if (position.rowIndex === positionRowsEndRef.current)
            createPositionRow()

        calculateNetPrice()
        renderNetPrice()

        // save position
        upsertPosition(position)

        // enable position
        enablePosition(position)

        hot.selectCell(position.rowIndex, colIndexQuantity)
    }

    const onQuantityChanged = (value, rowIndex, source) => {
        const hot = hotRef.current;

        const position = transferRef.current.items.find(x => x.rowIndex === rowIndex)
        position.quantity = value || 0

        // compute total
        const total = position.quantity * position.price.amount
        hot.setDataAtCell(position.rowIndex, colIndexTotal, total, updateSourceTypeComputed)

        calculateNetPrice()
        renderNetPrice()

        // save position
        upsertPosition(position)

        if (position.price.amount === 0) {
            hot.selectCell(position.rowIndex, colIndexPriceAmount)
        } else {
            hot.selectCell(position.rowIndex, colIndexWarehouseNameTo)
        }
    }

    const onPriceAmountChanged = (value, rowIndex, source) => {
        const hot = hotRef.current;

        const position = transferRef.current.items.find(x => x.rowIndex === rowIndex)
        position.price.amount = value || 0

        // compute total
        const total = (position.quantity || 0) * position.price.amount
        hot.setDataAtCell(position.rowIndex, colIndexTotal, total, updateSourceTypeComputed)

        if (source !== updateSourceTypeComputed) {
            calculateNetPrice()
            renderNetPrice()

            // save position
            upsertPosition(position)

            if (position.quantity) {
                hot.selectCell(position.rowIndex, colIndexWarehouseNameTo)
            } else {
                hot.selectCell(position.rowIndex, colIndexQuantity)
            }
        }
    }

    const onPriceTypeChanged = (value, rowIndex, source) => {
        const hot = hotRef.current;

        const position = transferRef.current.items.find(x => x.rowIndex === rowIndex)
        if (position.item) {
            if (source !== updateSourceTypeComputed) {
                let price;
                switch (value) {
                    case priceTypeBulk:
                        price = {
                            amount: position.item.price.bulk_price.amount,
                            currency: {
                                id: position.item.price.bulk_price.currency.global_currency_id,
                                name: position.item.price.bulk_price.currency.name
                            }
                        };
                        break;

                    default:
                        price = price = {
                            amount: position.item.price.common_price.amount,
                            currency: {
                                id: position.item.price.common_price.currency.global_currency_id,
                                name: position.item.price.common_price.currency.name
                            }
                        };
                        break;
                }

                position.price = JSON.parse(JSON.stringify(price))

                hot.setDataAtCell(position.rowIndex, colIndexPriceAmount, price.amount, updateSourceTypeComputed)
                hot.setDataAtCell(position.rowIndex, colIndexPriceCurrency, price.currency.name, updateSourceTypeComputed)

                calculateNetPrice()
                renderNetPrice()

                // save position
                upsertPosition(position)
            }
        }
    }

    const onWarehouseFromChanged = (value, rowIndex, source) => {
        const hot = hotRef.current;

        const position = transferRef.current.items.find(x => x.rowIndex === rowIndex)

        if (source !== updateSourceTypeComputed) {
            position.warehouseItemFrom = position.item.warehouse_states.warehouse_items.find(x => x.name?.trimStart() === value)

            // save position
            upsertPosition(position)
        }


        const state = position.warehouseItemFrom?.state || 0;
        hot.setDataAtCell(position.rowIndex, colIndexWarehouseItemStateFrom, state, updateSourceTypeComputed)

        // update className
        if (state <= 0) {
            hot.setCellMeta(position.rowIndex, colIndexWarehouseItemStateFrom, "className", 'fw-semi-bold bg-warning bg-300 text-light')
        } else {
            hot.setCellMeta(position.rowIndex, colIndexWarehouseItemStateFrom, "className", 'fw-semi-bold bg-facebook text-light')
        }
    }

    const onWarehouseToChanged = (value, rowIndex, source) => {
        const hot = hotRef.current;

        const position = transferRef.current.items.find(x => x.rowIndex === rowIndex)

        if (source !== updateSourceTypeComputed) {
            position.warehouseItemTo = position.item.warehouse_states.warehouse_items.find(x => x.name?.trimStart() === value)

            // save position
            upsertPosition(position)
        }


        const state = position.warehouseItemTo?.state || 0;
        hot.setDataAtCell(position.rowIndex, colIndexWarehouseItemStateTo, state, updateSourceTypeComputed)

        // update className
        if (state <= 0) {
            hot.setCellMeta(position.rowIndex, colIndexWarehouseItemStateTo, "className", 'fw-semi-bold bg-warning bg-300 text-light')
        } else {
            hot.setCellMeta(position.rowIndex, colIndexWarehouseItemStateTo, "className", 'fw-semi-bold bg-facebook text-light')
        }
    }

    const onCurrencyChanged = (value, rowIndex, source) => {
        const hot = hotRef.current;

        const position = transferRef.current.items.find(x => x.rowIndex === rowIndex)
        const currency = currenciesRef.current.find(x => x.name === value) || null

        if (source !== updateSourceTypeComputed) {
            let oldCurrency = null
            if (position.item && position.price) {
                oldCurrency = position.price.currency
                position.price.currency = currency
            }

            if (currency && oldCurrency && currency.id !== oldCurrency.id) {
                currencyConverterConvert({
                    currency_id_from: oldCurrency.id,
                    value_from: position.price.amount,
                    currency_id_to: currency.id
                }).then(response => {
                    position.price.amount = response.currency_to.value
                    hot.setDataAtCell(rowIndex, colIndexPriceAmount, position.price.amount, updateSourceTypeComputed)

                    calculateNetPrice()
                    renderNetPrice()

                    // save position
                    upsertPosition(position)
                })
            }
        }
    }

    const onDeletePosition = rowIndex => {
        const hot = hotRef.current;

        const position = transferRef.current.items.find(x => x.rowIndex === rowIndex)
        hot.alter('remove_row', position.rowIndex)

        transferRef.current.items = transferRef.current.items.filter(x => x.rowIndex !== position.rowIndex)

        for (let i = 0; i < transferRef.current.items.length; i++) {
            if (transferRef.current.items[i].rowIndex > position.rowIndex) {
                transferRef.current.items[i].rowIndex -= 1
            }
        }
        positionRowsEndRef.current = positionRowsEndRef.current - 1;
        if (positionRowsEndRef.current < positionRowsStartRef.current) {
            createPositionRow();
        }

        let counter = 1;
        for (let i = positionRowsStartRef.current; i <= positionRowsEndRef.current; i++) {
            hot.setDataAtCell(i, colIndexPositionNumber, counter, updateSourceTypeComputed)

            counter++
        }

        deletePosition(position)
    }

    const afterChange = (changes, source) => {
        const hot = hotRef.current;

        if (!changes) return;

        changes.forEach(([rowIndex, prop, oldValue, newValue]) => {
            hot.validateCell(newValue, {
                row: rowIndex, prop: prop
            }, valid => {
                if (!valid) return;


                if (rowIndex === 0) {
                    switch (prop) {
                        case propWarehouseItemStateFrom:
                            onDateChanged(newValue, rowIndex, source)
                            break;

                        case `${propItemName}-3`:
                            onTimeChanged(newValue, rowIndex, source)
                            break;
                        case `${propItemName}-5`:
                            onNumberChanged(newValue, rowIndex, source)
                            break;
                    }
                } else if (rowIndex === 1) {
                    // if (prop === propWarehouseItemStateTo)
                    //     onNumberChanged(newValue, rowIndex, source)
                } else if (positionRowsEndRef.current + 2 === rowIndex) {
                    if (prop === propWarehouseItemStateFrom) {
                        onNoteChanged(newValue, rowIndex, source)
                    }
                } else if (positionRowsEndRef.current + 7 === rowIndex) {
                    if (prop === propWarehouseItemStateFrom) {
                        onAssignerChanged(newValue, rowIndex, source)
                    }
                } else if (rowIndex >= positionRowsStartRef.current && rowIndex <= positionRowsEndRef.current) {
                    switch (prop) {
                        case propItemName:
                            onItemChanged(oldValue, newValue, rowIndex, source)
                            break;

                        case propQuantity:
                            onQuantityChanged(newValue, rowIndex, source)
                            break;

                        case propPriceAmount:
                            onPriceAmountChanged(newValue, rowIndex, source)
                            break;

                        case propPriceType:
                            onPriceTypeChanged(newValue, rowIndex, source)
                            break;

                        case propWarehouseNameFrom:
                            onWarehouseFromChanged(newValue, rowIndex, source)
                            break;

                        case propWarehouseNameTo:
                            onWarehouseToChanged(newValue, rowIndex, source)
                            break;

                        case propPriceCurrency:
                            onCurrencyChanged(newValue, rowIndex, source)
                            break;
                    }
                }
            });
        });
    }

    const initialConstruct = hot => {
        // date / time / number
        hot.setCellMeta(0, 0, "className", 'fw-bold bg-soft-dark text-dark')
        hot.setCellMeta(0, 3, "className", 'fw-bold bg-soft-dark text-dark')
        hot.setCellMetaObject(0, 2, {
            className: 'fw-bold text-dark bg-soft-warning',
            readOnly: false,
            allowEmpty: false,
            allowInvalid: false,
            validator: dateValidator,
        })
        hot.setCellMeta(0, 4, "className", 'fw-bold bg-soft-dark text-dark')
        hot.setCellMetaObject(0, 4, {
            className: 'fw-bold text-dark bg-soft-warning',
            readOnly: false,
            allowEmpty: false,
            allowInvalid: false,
            validator: timeValidator,
        })
        hot.setCellMeta(0, 5, "className", 'fw-bold bg-soft-dark text-dark')
        hot.setCellMetaObject(0, 6, {
            className: 'fw-bold text-dark bg-soft-warning',
            readOnly: false,
        })
        hot.getPlugin('mergeCells').merge(0, 0, 0, 1)
        hot.getPlugin('mergeCells').merge(0, 7, 0, 14)



        // empty row
        hot.getPlugin('mergeCells').merge(1, 0, 1, 14)

        // empty row
        for (let i = 0; i < hot.countCols(); i++) {
            hot.setCellMeta(1, i, "className", 'bg-100')
        }

        // positions header
        hot.setCellMeta(2, colIndexPositionNumber, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexWarehouseNameFrom, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexWarehouseItemStateFrom, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexItemName, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexQuantity, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexPriceAmount, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexTotal, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexWarehouseNameTo, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexWarehouseItemStateTo, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexPriceCurrency, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexPriceType, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexActions, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.getPlugin('mergeCells').merge(2, colIndexItemName, 2, colIndexItemName + 3)

        // positions row
        for (let i = positionRowsStartRef.current; i <= positionRowsEndRef.current; i++)
            constructPositionRow(transferRef.current.items.find(x => x.rowIndex === i))

        // empty row
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 1, 0, positionRowsEndRef.current + 1, 14)
        for (let i = 0; i < hot.countCols(); i++) {
            hot.setCellMeta(positionRowsEndRef.current + 1, i, "className", 'bg-100')
        }

        // note / net price
        hot.setCellMeta(positionRowsEndRef.current + 2, 0, "className", 'fw-bold bg-soft-dark text-dark align-middle text-center')
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 2, 0, positionRowsEndRef.current + 3, 1)
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 2, 2, positionRowsEndRef.current + 3, 6)
        hot.setCellMetaObject(positionRowsEndRef.current + 2, 2, {
            type: "text",
            readOnly: false,
            className: 'bg-soft-warning'
        })

        hot.setCellMeta(positionRowsEndRef.current + 2, 7, "className", 'fw-bold bg-soft-dark text-dark align-middle text-center')
        hot.setCellMeta(positionRowsEndRef.current + 2, 8, "className", 'fw-bold text-dark align-middle')
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 2, 7, positionRowsEndRef.current + 3, 7)
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 2, 8, positionRowsEndRef.current + 3, 13)


        // creator
        hot.setCellMeta(positionRowsEndRef.current + 4, 0, "className", 'fw-bold bg-soft-dark text-dark')
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 4, 0, positionRowsEndRef.current + 4, 1)
        hot.setCellMeta(positionRowsEndRef.current + 4, 2, "className", 'fw-bold bg-soft-dark text-dark')
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 4, 2, positionRowsEndRef.current + 4, 4)
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 4, 5, positionRowsEndRef.current + 4, 14)
        for (let i = 5; i < hot.countCols(); i++) {
            hot.setCellMeta(positionRowsEndRef.current + 4, i, "className", 'bg-100')
        }

        // assigner
        hot.setCellMeta(positionRowsEndRef.current + 5, 0, "className", 'fw-bold bg-soft-dark text-dark')
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 5, 0, positionRowsEndRef.current + 5, 1)
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 5, 2, positionRowsEndRef.current + 5, 4)
        hot.setCellMetaObject(positionRowsEndRef.current + 5, 2, {
            type: "text",
            readOnly: false,
            className: 'fw-bold text-dark bg-soft-warning'
        })
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 5, 5, positionRowsEndRef.current + 5, 14)
        for (let i = 4; i < hot.countCols(); i++) {
            hot.setCellMeta(positionRowsEndRef.current + 5, i, "className", 'bg-100')
        }

        calculateNetPrice()
        renderNetPrice()
        hot.render()
    }

    const onScan = (value) => {
        var barcode
        if (value.length > 14){
            barcode = itemsRef?.current?.find(item => item?.item?.barcodes?.find(x => x === CheckScanBarcodeOrMarking(value)))
            if (barcode) {
                hotRef?.current.setDataAtCell(positionRowsEndRef?.current, colIndexItemName, barcode?.item?.name, updateSourceTypeComputed)
                hotRef?.current.setDataAtCell(positionRowsEndRef?.current, colIndexQuantity, 1, updateSourceTypeComputed)
                transferRef.current.items[transferRef.current.items.length - 1].marks_from = [value]
                transferRef.current.items[transferRef.current.items.length - 1].marks_to = [value]
            }
        } else {
            barcode = itemsRef?.current?.find(item => item?.item?.barcodes?.find(x => x === CheckScanBarcodeOrMarking(value)))
            if (barcode) {
                hotRef?.current.setDataAtCell(positionRowsEndRef?.current, colIndexItemName, barcode?.item?.name, updateSourceTypeComputed)
                hotRef?.current.setDataAtCell(positionRowsEndRef?.current, colIndexQuantity, 1, updateSourceTypeComputed)
            }
        }
    }

    useEffect(() => {
        const data = []

        // 1-row
        const row1 = {}
        row1[propPositionNumber] = t(lang, "warehouse.operation.item.common.date")
        row1[propItemName] = t(lang, "warehouse.operation.item.common.time")
        row1[`${propItemName}-4`] = t(lang, "warehouse.operation.item.common.number")
        if (transfer) {
            transferRef.current.id = transfer.id
            transferRef.current.date = dayjs(transfer.date, `YYYY-MM-DD HH:mm:ss`)
            transferRef.current.number = transfer.number || null

            row1[propWarehouseItemStateFrom] = transferRef.current.date.format(dateFormat)
            row1[`${propItemName}-3`] = transferRef.current.date.format(timeFormat)
            row1[`${propItemName}-5`] = transferRef.current.number
        }

        if (!transfer) {
            transferRef.current.date = dayjs(new Date(), `YYYY-MM-DD HH:mm:ss`)
            row1[propWarehouseItemStateFrom] = transferRef.current.date.format(dateFormat)
            row1[`${propItemName}-3`] = transferRef.current.date.format(timeFormat)
            row1[`${propItemName}-5`] = null
        }
        data.push(row1)


        // 2-row
        data.push({})

        // 3-row
        const row3 = {}
        row3[propPositionNumber] = '№'
        row3[propWarehouseNameFrom] = t(lang, "warehouse.operation.item.common.warehouse_from")
        row3[propWarehouseItemStateFrom] = t(lang, "warehouse.operation.item.common.warehouse_state")
        row3[propItemName] = t(lang, "warehouse.operation.item.common.product_name")
        row3[propQuantity] = t(lang, "warehouse.operation.item.common.quantity")
        row3[propPriceAmount] = t(lang, "warehouse.operation.item.common.sum")
        row3[propTotal] = t(lang, "warehouse.operation.item.common.total")
        row3[propWarehouseNameTo] = t(lang, "warehouse.operation.item.common.warehouse_to")
        row3[propWarehouseItemStateTo] = t(lang, "warehouse.operation.item.common.warehouse_state")
        row3[propPriceCurrency] = t(lang, "warehouse.operation.item.common.currency")
        row3[propPriceType] = t(lang, "warehouse.operation.item.common.price_type")
        row3[propActions] = t(lang, "warehouse.operation.item.common.action")
        data.push(row3)

        // positions rows
        const refPositions = []
        if (transfer) {
            const positions = transfer.items.filter(x => !x.is_deleted)

            for (let i = 0; i < positions.length; i++) {
                const position = positions[i]
                const item = itemsRef.current.find(x => x.item.id === position.warehouse_item_from.id)
                const warehouseItemFrom = item?.warehouse_states.warehouse_items.find(x => x.id === position.warehouse_item_from.warehouse.id)
                const warehouseItemTo = item?.warehouse_states.warehouse_items.find(x => x.id === position.warehouse_item_to.warehouse.id)
                const priceCurrency = currenciesRef.current.find(x => x.id === position.price.currency.id)

                data.push({
                    positionNumber: i + 1,
                    warehouseNameFrom: warehouseItemFrom?.name,
                    warehouseItemStateFrom: warehouseItemFrom?.state,
                    itemName: item?.item.name || null,
                    quantity: position.quantity,
                    price: position.price.amount,
                    priceType: priceTypeCommon,
                    total: position.quantity * position.price.amount,
                    warehouseNameTo: warehouseItemTo?.name,
                    warehouseItemStateTo: warehouseItemTo?.state,
                    marks_from: position.warehouse_item_from.marks || [],
                    marks_to: position.warehouse_item_to.marks || [],
                    priceCurrency: priceCurrency?.name || position.price.currency.name,
                })

                refPositions.push({
                    id: position.id,
                    warehouseItemFrom: warehouseItemFrom,
                    item: item,
                    quantity: position.quantity,
                    price: {
                        amount: position.price.amount,
                        currency: {
                            id:  priceCurrency?.id || position.price.currency.id,
                            name:  priceCurrency?.name || position.price.currency.name,
                        }
                    },
                    warehouseItemTo: warehouseItemTo,
                    marks_from: position.warehouse_item_from.marks || [],
                    marks_to: position.warehouse_item_to.marks || [],
                    rowIndex: positionRowsStartRef.current + i
                })

                positionRowsEndRef.current = positionRowsEndRef.current + 1
            }
        }
        data.push({positionNumber: positionRowsEndRef.current - positionRowsStartRef.current + 1})
        refPositions.push({rowIndex: positionRowsEndRef.current})
        transferRef.current.items = refPositions

        // test w from !== w to

        // positions end row + 1    row 4
        data.push({})

        // positions end row + 2
        let row5 = {}
        transferRef.current.note = transfer?.note || null

        row5[propPositionNumber] = t(lang, "warehouse.operation.item.common.note")
        row5[propWarehouseItemStateFrom] = transferRef.current.note
        row5[propQuantity] = t(lang, "warehouse.operation.item.common.total_summa")
        data.push(row5)


        // row 6
        data.push({})

        // positions end row + 5       row 7
        let row7 = {}
        row7[propPositionNumber] = t(lang, "warehouse.operation.item.common.creator")
        row7[propWarehouseItemStateFrom] = transfer?.account?.name || account.name
        data.push(row7)

        // positions end row + 6      row 8
        let row8 = {}
        row8[propPositionNumber] = t(lang, "warehouse.operation.item.common.assigner")
        row8[propWarehouseItemStateFrom] = transfer?.assigner || null
        data.push(row8)


        const hot = new Handsontable(hotContainerRef.current, {
            licenseKey: "non-commercial-and-evaluation",
            mergeCells: true,
            filters: true,
            stretchH: "all",
            minSpareRows: 0,
            startCols: 14,
            columns: [
                {data: propPositionNumber, readOnly: true},
                {data: propWarehouseNameFrom, readOnly: true},
                {data: propWarehouseItemStateFrom, readOnly: true},
                {data: propItemName, readOnly: true},
                {data: `${propItemName}-3`, readOnly: true},
                {data: `${propItemName}-4`, readOnly: true},
                {data: `${propItemName}-5`, readOnly: true},
                {data: propQuantity, readOnly: true},
                {data: propPriceAmount, readOnly: true},
                {data: propPriceType, readOnly: true},
                {data: propTotal, readOnly: true},
                {data: propWarehouseNameTo, readOnly: true},
                {data: propWarehouseItemStateTo, readOnly: true},
                {data: propPriceCurrency, readOnly: true},
                {data: propActions, readOnly: true},
            ],
            data: data,
            colWidths: ["5", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20"],
        })
        hotRef.current = hot

        initialConstruct(hot)

        hot.addHook('afterChange', afterChange)

        calculateNetPrice()

        // enable positions
        enablePositions()

        return () => {
            hot.removeHook('afterChange', afterChange)
            hot.destroy()
        }
    }, [])

    useEffect(() => {
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);
        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    },[])

    useEffect(() => {
        itemsRef.current = items
        const hot = hotRef.current;

        const itemNames = items.map(x => x.item.name.trimStart())
        for (let rowIndex = positionRowsStartRef.current; rowIndex <= positionRowsEndRef.current; rowIndex++)
            hot.setCellMeta(rowIndex, colIndexItemName, "source", itemNames)
    }, [items])

    useEffect(() => {
        const hot = hotRef.current

        const warehouseNames = warehouses.map(x => x.name?.trimStart())
        for (let rowIndex = positionRowsStartRef.current; rowIndex <= positionRowsEndRef.current; rowIndex++) {
            hot.setCellMeta(rowIndex, colIndexWarehouseNameFrom, "source", warehouseNames)
            hot.setCellMeta(rowIndex, colIndexWarehouseNameTo, "source", warehouseNames)
        }
    },[warehouses])

    useEffect(() => {
        const hot = hotRef.current;
        currenciesRef.current = currencies

        const activeCurrenciesNames = currencies.filter(x => x.is_active).map(x => x.name)
        for (let rowIndex = positionRowsStartRef.current; rowIndex <= positionRowsEndRef.current; rowIndex++)
            hot.setCellMeta(rowIndex, colIndexPriceCurrency, "source", activeCurrenciesNames)

        hot.render()
    }, [currencies])

    return (
        <div ref={hotContainerRef}></div>
    )
};

export default TransferExcelForm;