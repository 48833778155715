import React, {useEffect, useMemo, useState} from 'react';
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {Card, Col, Form, Row} from "react-bootstrap";
import telegramOrderBotService from "../../../../../services/telegram-order-bot/telegramOrderBotService";
import DataTable from "../../../../common/table/DataTable";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import DataTableLimiter from "../../../../common/table/DataTableLimiter";
import DataTablePagination from "../../../../common/table/DataTablePagination";
import SendMessageToBotUsersModal from "./SendMessageToBotUsersModal";
import {transliterate} from "transliteration";

const TelegramOrderBotUsersDataTable = ({bot}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [filter, setFilter] = useState({
        isContractor: 1,
        query: "",
        phoneNumber: "",
    });
    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 0,
    });
    const [sorting, setSorting] = useState([]);
    const [users, setUsers] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(false);

    const loadUsers = async (bot) => {
        try {
            setLoading(true);
            const {data: users} = await telegramOrderBotService.getTelegramOrderBotUsers(bot.id);
            setUsers(users);
            setLoading(false);
        } catch (error) {
            setUsers([]);
            setLoading(false);
        }
    };
    const onFilterChange = (filter) => {
        setPagination(prev => ({...prev, pageIndex: 0}));
        setFilter(filter);
    };

    useEffect(() => {
        loadUsers(bot);
    }, [bot]);

    const FormatPhoneNumber = (number) => {
        const match = number.match(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/);
        if (match) {
            return `${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
        }
        return number;
    };
    const columns = useMemo(() => {
        return [
            {
                id: 'select-column',
                enableSorting: false,
                header: ({table}) => {
                    return (
                        <th className="px-0 text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                            />
                        </th>
                    )
                },
                cell: ({row}) => {
                    return (
                        <td className="px-0 text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={row.getIsSelected()}
                                        disabled={!row.getCanSelect()}
                                        onChange={row.getToggleSelectedHandler()}
                            />
                        </td>
                    )
                },
            },
            {
                accessorKey: "#",
                header: ({column}) => {
                    return (
                        <th className="px-0 text-center text-dark"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="px-0 text-center text-dark"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {pagination.pageIndex * pagination.pageSize + row.index + 1}
                        </td>
                    );
                },
                enableSorting: false,
            },
            {
                accessorKey: "name",
                header: ({column}) => {
                    return (
                        <th className="text-dark"
                            style={{
                                minWidth: '300px',
                            }}
                        >
                            {t(lang, "task.board.member.name.placeholder")}
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="text-dark">{row.original.user.first_name}</td>
                    );
                },
                enableSorting: false,
            },
            {
                accessorKey: "phone_number",
                header: ({column}) => {
                    return (
                        <th className="text-dark text-center"
                            style={{
                                width: '250px',
                                minWidth: '250px',
                            }}
                        >
                            {t(lang, "edi.common.cellPhone")}
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="text-dark text-center">{row.original.user.phone_number ? `${FormatPhoneNumber(row.original.user.phone_number)}` : ''}</td>
                    );
                },
                enableSorting: false,
            },
        ]
    }, [lang, pagination]);
    const data = useMemo(() => {
        const filteredUsers = users.filter((botUser) => {
            // filter contractor
            {
                switch (filter.isContractor) {
                    case 1:
                        break;
                    case 2:
                        if (!botUser.contractor_id) return false;
                        break
                    case 3:
                        if (!!botUser.contractor_id) return false;
                        break
                    default:
                        break;
                }
            }

            // filter by first_name
            {
                if (filter.query) {
                    const searchTermLower = filter.query.trim().toLowerCase();
                    const nameLower = botUser.user.first_name.trim().toLowerCase();
                    const transliteratedQuery = transliterate(searchTermLower);
                    const transliteratedItemName = transliterate(nameLower);
                    const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');

                    if (!(filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length))
                        return false;
                }
            }

            // filter by phone_number
            {
                if (filter.phoneNumber) {
                    const searchTermLower = filter.phoneNumber.trim().toLowerCase();
                    if (botUser.user.phone_number && (botUser.user.phone_number === searchTermLower || FormatPhoneNumber(botUser.user.phone_number) === FormatPhoneNumber(searchTermLower)))
                        return true;

                    return false;
                }
            }

            return true;
        });

        return filteredUsers;
    }, [users, filter, sorting]);
    const table = useReactTable({
        data: data.slice(pagination.pageIndex * pagination.pageSize, pagination.pageIndex * pagination.pageSize + pagination.pageSize),
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        manualPagination: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        enableMultiSort: true,
        pageCount: Math.ceil(data.length / pagination.pageSize),
        state: {
            pagination: pagination,
            sorting: sorting,
            rowSelection: rowSelection
        }
    });

    return (
        <Card>
            <Card.Header>
                <Row className="gy-2">
                    <Col xs={12}>
                        <Row className="gy-3">
                            <Col xs={12} md={6} lg={4} xl={3} xxl={2}>
                                <Form.Group>
                                    <Form.Label>{t(lang, "items.common.datatable.filter.deep_search")}</Form.Label>
                                    <Form.Control type="text"
                                                  placeholder={t(lang, "items.common.datatable.filter.deep_search.placeholder")}
                                                  defaultValue={filter.query}
                                                  onChange={(e) => onFilterChange({...filter, query: e.target.value})}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4} xl={3} xxl={2}>
                                <Form.Group>
                                    <Form.Label>{t(lang, "edi.common.cellPhone")}</Form.Label>
                                    <Form.Control type="text"
                                                  placeholder={t(lang, "items.common.datatable.filter.deep_search.placeholder")}
                                                  defaultValue={filter.phoneNumber}
                                                  onChange={(e) => onFilterChange({
                                                      ...filter,
                                                      phoneNumber: e.target.value
                                                  })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4} xl={3} xxl={2}>
                                <Form.Group>
                                    <Form.Label>{t(lang, "crm.telegram_order_bot.show_contractors_only")}</Form.Label>
                                    <Form.Select defaultValue={filter.isContractor}
                                                 onChange={(e) => onFilterChange({
                                                     ...filter,
                                                     isContractor: +e.target.value
                                                 })}
                                    >
                                        <option key={1}
                                                value={1}
                                        >
                                            {t(lang, "warehouse.operation.report.sale_items.filter_by_default")}
                                        </option>
                                        <option key={2}
                                                value={2}
                                        >
                                            {t(lang, "warehouse.operation.report.sale_items.filter_by_yes")}
                                        </option>
                                        <option key={3}
                                                value={3}
                                        >
                                            {t(lang, "warehouse.operation.report.sale_items.filter_by_no")}
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4} xl={3} xxl={2} className="mt-md-auto mt-lg-3 mt-xl-auto">
                                <div className="d-grid d-lg-inline-block">
                                    <SendMessageToBotUsersModal botId={bot.id}
                                                                botUserIds={Object.keys(rowSelection).map((id) => parseInt(id))}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <h5 className="mb-2">{t(lang, "crm.bind.contractor.account.data.table.header.title")}</h5>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true,
                               bordered: true,
                               striped: true
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <div className="d-flex flex-column">
                    <DataTablePagination table={table} pagination={pagination} count={data.length}/>
                    <DataTableLimiter table={table} pagination={pagination}/>
                </div>
            </Card.Footer>
        </Card>
    );
};

export default TelegramOrderBotUsersDataTable;