import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import ContractorForm from "./ContractorForm";
import {editContractorAsync, loadAllContractorAsync, setDefaultContractor, unSetDefaultContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import {toast} from "react-toastify";

const EditContractor = ({contractor, onClose}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);
    const handleOnSubmit = (formData) => {
        let phones = formData.phones.filter(p => p.value)
        phones = phones.map(phone => {
            if (phone.value) {
                let ph_value = phone.value.replace(/ /g, '')
                ph_value = '998' + ph_value;
                return {
                    type: 1,
                    value: ph_value
                }
            }
        });
        let emails = formData.emails.filter(e => e.value)
        emails = emails.map(email => {
            if (email.value)
                return {
                    type: 3,
                    value: email.value
                }
        });
        const contacts = phones.concat(emails)

        setLoading(true)

        if (contractor.is_default !== formData.is_default)
            if (formData.is_default) {
                dispatch(setDefaultContractor(contractor.id)).then(res => {
                    toast.success(t(lang, "edi.common.toast.success"));
                }).catch(err => {
                    toast.error(t(lang, "edi.common.toast.error"));
                });
            } else {
                dispatch(unSetDefaultContractor()).then(res => {
                }).catch(err => {
                    toast.error(t(lang, "edi.common.toast.error"));
                });
            }


        dispatch(editContractorAsync(contractor.id, {
            name: formData.name,
            inn: formData.inn,
            is_default: formData.is_default,
            organization_code: formData.organization_code || null,
            type: formData.type.map(t => +t),
            contacts: contacts
        })).then(res => {
            dispatch(loadAllContractorAsync());
            toast.success(t(lang, "edi.common.toast.success"));
            onClose()
        }).catch(err => {
            toast.error(t(lang, "edi.common.toast.error"));
        }).finally(() => {
            setLoading(false)
        });


    }
    return (<>
        <Modal show={true} onHide={onClose} size="xl">
            <Modal.Header>
                <Modal.Title>{t(lang, "edi.contractor.edit_contractor_title")}</Modal.Title>
                <FalconCloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <ContractorForm contractor={contractor} onClose={onClose} loading={loading} onSubmit={handleOnSubmit} isEditing={true}/>
            </Modal.Body>
        </Modal>
    </>)
}
export default EditContractor