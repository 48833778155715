import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ContractorAttorneyDataTableHeader from './ContractorAttorneyDataTableHeader';
import {
    loadContractorAttorneysAsync,
    loadContractorAttorneysCountAsync,
    selectContractorAttorneys,
    selectContractorCountAttorneys,
    selectContractorAttorneyFilterOptions,
    changeFilterContractorAttorneysAsync
} from '../../../../../../app/store/reducers/contractor/contractorAttorneyReducer';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {selectActiveOrganization} from '../../../../../../app/store/reducers/auth/authReducer';
import AdvanceTableCustomPagination from '../../../../../common/advance-table/AdvanceTableCustomPagination';
import ActionButton from '../../../../../common/ActionButton';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {GetStatusText, GetStatusVariant, AttorneyRegistered} from "../../../../../../enum/AttorneyStatus";
import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../../../common/advance-table/AdvanceTableFooter";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";


const ContractorAttorneys = () => {
    const contractor = useSelector(selectCardContractor)
    const dispatch = useDispatch();
    const attorneys = useSelector(selectContractorAttorneys);
    const count = useSelector(selectContractorCountAttorneys);
    const filters = useSelector(selectContractorAttorneyFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang);
    const t = Translate;

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return `${((filters.page-1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'index',
            Header: t(lang, "edi.attorney.datatable.header.row.number"),
            cellProps: {
                width: '100px',
                className: 'p-0'
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-grid gap-2">
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    {t(lang, GetStatusText(original.status))}
                                </Tooltip>
                            }
                            placement="right"
                        >
                            <Button style={{backgroundColor: GetStatusVariant(original.status)}} as={Link}
                                    to={`/edi/attorney/${original.id}`}>
                                {original?.attorney_info.number}
                            </Button>
                        </OverlayTrigger>
                    </div>
                )
            }
        },
        {
            accessor: 'person_info.full_name',
            Header: t(lang, "edi.attorney.datatable.header.row.full_name"),
            cellProps: {
                width: '250px',
                className: 'fw-bold'
            },
        },
        {
            accessor: 'contractor',
            Header: t(lang, "edi.attorney.datatable.header.row.contractor"),
            cellProps: {
                // width: '250px',
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isOwner = activeOrganization.inn === original.owner_info.inn
                if (isOwner) {
                    return <>
                        {original.contractor_info.name}
                    </>
                }
                return <>
                    {original.owner_info.name}
                </>
            }
        },
        {
            accessor: 'person_info.position',
            Header: t(lang, "edi.attorney.datatable.header.row.position"),
        },
        {
            accessor: 'person_info.person_inn',
            Header: t(lang, "edi.attorney.datatable.header.row.employees_inn"),
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '60px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                const allowEdit = original.status === AttorneyRegistered;

                if (allowEdit) {
                    return (
                        <Link to={`/edi/attorney/update/${original.id}`}>
                            <ActionButton icon="edit" title="Изменить" variant="action" className="p-0 me-2"/>
                        </Link>
                    )
                }

                return false;
            }
        }
    ];

    const onPageChange = (page) => dispatch(changeFilterContractorAttorneysAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterContractorAttorneysAsync({...filters, limit: limit, page: 1}));

    useEffect(()=>{
        dispatch(loadContractorAttorneysAsync({...filters, contractor_inn: contractor?.inn}))
        dispatch(loadContractorAttorneysCountAsync({...filters, contractor_inn: contractor?.inn}))
    }, [contractor, filters])

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={attorneys}
            selection
            sortable
            width={10}
            pagination
            perPage={1000}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ContractorAttorneyDataTableHeader table/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default ContractorAttorneys;
