import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import {useHistory, useRouteMatch} from "react-router-dom";
import EditWaybillForm from "../../../../components/roaming/waybill/forms/EditWaybillForm";
import {loadDraftWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {SAVE_DRAFT_WAYBILL_SUCCEED, SEND_WAYBILL_SUCCEED} from "../../../../app/eventbus/roaming/roamingWaybillEvents";
import {useDispatch, useSelector} from "react-redux";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingShipmentUpdate} from "../../../../enum/Permission/RoamingPermission";

const EditWaybill = () => {
    const routerHistory = useHistory();
    const {params: {id}} = useRouteMatch();
    const [draftWaybill, setDraftWaybill] = useState(null);
    const dispatch = useDispatch()

    const history = useHistory();
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingShipmentUpdate))) {
            if (history.length > 1) {
                history.push('/roaming/waybills/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadDraftWaybill();
    }, [id])

    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_SUCCEED, waybill => {
            routerHistory.push(`/roaming/waybill/${waybill.id}`)
            dispatch(getOrganizationBillingInfoAsync());
        });

        const onSaveDraftWaybillSucceedHandler = EventBus.on(SAVE_DRAFT_WAYBILL_SUCCEED, draftWaybill => {
            routerHistory.push(`/roaming/waybill/draft/${draftWaybill.id}`)
        })

        return () => {
            EventBus.remove(SEND_WAYBILL_SUCCEED, onSendWaybillSucceedHandler);
            EventBus.remove(SAVE_DRAFT_WAYBILL_SUCCEED, onSaveDraftWaybillSucceedHandler);
        }
    }, [])

    const loadDraftWaybill = () => {
        loadDraftWaybillAsync(id)
            .then(loadedDraftWaybill => {
                const parsedContent = JSON.parse(loadedDraftWaybill.content);
                setDraftWaybill(parsedContent);
            })
            .catch(error => console.log(error))
    }

    return (
        <>
            {!draftWaybill && <span>loading....</span>}
            {draftWaybill && <EditWaybillForm id={id} waybill={draftWaybill} />}
        </>
    );
};

export default EditWaybill;
