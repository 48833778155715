import {createSlice} from "@reduxjs/toolkit";
import contractorService from "../../../../services/contractorService";
import EventBus from "../../../eventbus/EventBus";
import {
    CONVERT_LEAD_TO_CONTRACTOR_FAILED,
    CONVERT_LEAD_TO_CONTRACTOR_REQUESTED, CONVERT_LEAD_TO_CONTRACTOR_SUCCESS,
    DELETE_LEAD_FAILED,
    DELETE_LEAD_REQUESTED, DELETE_LEAD_SUCCESS,
    EDIT_LEAD_FAILED,
    EDIT_LEAD_REQUESTED, EDIT_LEAD_SUCCESS,
    REGISTER_LEAD_FAILED,
    REGISTER_LEAD_REQUESTED,
    REGISTER_LEAD_SUCCESS
} from "../../../eventbus/contractor/contractorLeadEvents";

export const contractorLeadReducer = createSlice({
    name: 'leadReducer',
    initialState: {
        leads: [],
        count: 0,
        cardLead: null,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateLeads: (state, action) => {
            state.leads = action.payload || []
        },
        updateLeadsCount: (state, action) => {
            state.count = action.payload
        },
        updateCardLead: (state, action) => {
            state.cardLead = action.payload || null
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
    },
});

export const loadLeadsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getLeads(params)
        .then((response) => {
            dispatch(contractorLeadReducer.actions.updateLeads(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const loadLeadsCountAsync = (filters) => dispatch => {
    return new Promise((resolve, reject) => {
        let {limit, page, ...other} = filters
        contractorService.getLeadsCount(other)
            .then((response) => {
                dispatch(contractorLeadReducer.actions.updateLeadsCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const registerLeadAsync = (payload) => {
    EventBus.dispatch(REGISTER_LEAD_REQUESTED)
    return new Promise((resolve, reject) => {
        contractorService.registerLead(payload)
            .then((response) => {
                EventBus.dispatch(REGISTER_LEAD_SUCCESS)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(REGISTER_LEAD_FAILED)
                reject(error)
            })
    })
}

export const editLeadAsync = (id, payload) => () => {
    EventBus.dispatch(EDIT_LEAD_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorService.editLead(id, payload)
            .then(response => {
                EventBus.dispatch(EDIT_LEAD_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_LEAD_FAILED, error)
                reject(error);
            })
    });
}

export const deleteLeadAsync = ({id}) => {
    EventBus.dispatch(DELETE_LEAD_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        contractorService.deleteLead({id})
            .then((response) => {
                EventBus.dispatch(DELETE_LEAD_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_LEAD_FAILED, error.message);
                reject(error)
            })
    });
};

export const convertLeadToContractorAsync = ({id}) => {
    EventBus.dispatch(CONVERT_LEAD_TO_CONTRACTOR_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        contractorService.convertLeadToContractor({id})
            .then((response) => {
                EventBus.dispatch(CONVERT_LEAD_TO_CONTRACTOR_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(CONVERT_LEAD_TO_CONTRACTOR_FAILED, error.message);
                reject(error)
            })
    });
};

export const loadLeadAsync = id => {
    return new Promise((resolve, reject) => {
        contractorService.getLead(id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    })
}

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorLeadReducer.actions.updateFilterOptions({...params}))
}

export const updateCardLead = (lead) => dispatch => {
    dispatch(contractorLeadReducer.actions.updateCardLead(lead))
}


export const selectLeads = state => state.lead.leads
export const selectLeadsCount = state => state.lead.count
export const selectLeadsFilterOptions = state => state.lead.filterOptions
export const selectCardLead = (state) => state.lead.cardLead;

export default contractorLeadReducer.reducer