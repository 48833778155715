import React, {useEffect} from 'react';
import {useRouteMatch} from "react-router-dom";
import {Card, Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import ChangeContractorStartingDebtComponent from "../../starting-debt/ChangeContractorStartingDebtComponent";
import {loadContractorAsync, selectCardContractor, updateCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";

const ContractorOtherInfo = () => {
    const contractor = useSelector(selectCardContractor);
    const {params: {id}} = useRouteMatch();
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    useEffect(() => {
        loadContractor();
    }, [id]);

    const loadContractor = () => {
        loadContractorAsync(id).then(cr => {
            dispatch(updateCardContractor(cr))
        }).catch(err => console.log(err));
    }

    return (
        <div>
            <Card className={'my-3 shadow-none border border-danger'} as={Card.Body}>
                <Card.Title>{t(lang, 'crm.contractor.view.tab.other.change_client_starting_debt.confirm_title')}</Card.Title>

                <Row>
                    <Col xs={12} md={'auto'} className={''}>
                        <p className={'text-warning'}>{t(lang, 'crm.contractor.view.tab.other.change_client_starting_debt.confirm_message')}</p>
                        <ChangeContractorStartingDebtComponent contractor={contractor}/>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default ContractorOtherInfo;