import React from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectRailwayStation} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";

const SelectStations = ({defaultId, regionId, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const railwayStations = useSelector(selectRailwayStation)

    return (
        <ReactSelect
            isClearable
            options={regionId ? railwayStations.filter(x => x.region_code === regionId) : railwayStations}
            classNamePrefix={'react-select'}
            placeholder={t(lang, "roaming.common.select.placeholder")}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
        />
    );
};

export default SelectStations;
