import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {downloadFile} from "../../../../../helpers/excel-uploader";
import {uploadOrderToExcelAsync} from "../../../../../app/store/reducers/crm/order/crmExecutorOrderReducer";

const UploadOrderToExcel = ({id, hasName, number}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const uploadOrder = (id, number) => {
        setLoading(true)
        uploadOrderToExcelAsync(id)
            .then(({content, contentType}) => {
                downloadFile({
                    content: content,
                    contentType: contentType,
                    fileName: t(lang, "crm.order.download_excel.order_name", {number: number}),
                });
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false))
    }

    return (
        <Button variant="falcon-success" size="sm" onClick={() => uploadOrder(id, number)}>
            {loading
                ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
            }
            {hasName &&
                <span className="d-none d-sm-inline-block">{t(lang, 'crm.common.download_excel')}</span>
            }
        </Button>
    );
};

export default UploadOrderToExcel;
