import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import ExcelRegistry from "../../../../components/hippo/excel-registry/view/ExcelRegistry";
import {loadExcelRegistryAsync} from "../../../../app/store/reducers/excel-registry/excelRegistryReducer";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";

const ViewExcelRegistry = ({modalId}) => {
    const {params: {id}} = useRouteMatch();
    const activeOrganization = useSelector(selectActiveOrganization);
    const [excelRegistry, setExcelRegistry] = useState(null);

    useEffect(() => {
        loadExcelRegistry();
    }, [modalId, id, activeOrganization])

    const loadExcelRegistry = () => {
        loadExcelRegistryAsync(modalId ? modalId : id)
            .then(loadedData => setExcelRegistry(loadedData))
            .catch(error => console.log(error))
    }

    if (!excelRegistry)
        return <SpinnerComponent />

    return (
        <>
            <ExcelRegistry registry={excelRegistry}/>
        </>
    );
};

export default ViewExcelRegistry;
