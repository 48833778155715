import {Card, Col, Row, Table} from "react-bootstrap";
import {GetPaymentTypeText} from "../../../../enum/ContractorWrapper";
import numeral from "numeral";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {OperationTotalSumInfoWithoutPaymentType} from "../enum/warehouseOperationWrapper";


const WarehouseOperationCashBoxCard = ({cashBox}) => {
    const numberFormat = useSelector(selectNumberFormat)
    const lang = useSelector(selectLang)
    const t = Translate




    const totalSum = OperationTotalSumInfoWithoutPaymentType(cashBox)




    return (
        <Card className="h-100">
            {/*<h5 className="pt-3 ps-3">{t(lang, "warehouse.operation.item.common.cash_box_state")}</h5>*/}
            <h5 className="pt-3 fw-bold ps-3">{t(lang, "Оплата к кассе")}</h5>
            <div className="table-responsive fs--1 mt-3">
                <Table striped className="border-bottom">
                    <thead className="table-info">
                    <tr>
                        <th className="align-middle">№</th>
                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.cash_box_state.type")}</th>
                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.total")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cashBox?.map((cash, index) => {
                        return(
                            <tr key={index} className="fw-bold">
                                <td className="align-middle">{index + 1}</td>
                                <td className="align-middle">{t(lang, GetPaymentTypeText(cash?.payment_type))}</td>
                                <td className="text-start">{numeral.formats[numberFormat].format(cash?.amount || 0)} <span className="text-info ms-1">{cash?.currency?.name}</span></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <Row className="g-0 d-flex flex-column align-items-end">
                    <Col xs="auto" className="d-flex fs-1">
                        <p className="text-900 fw-bold text-nowrap m-0">{t(lang, "warehouse.operation.item.common.total_sum")}:</p>
                        <Table borderless size="sm" className="text-end">
                            <tbody>
                            {totalSum?.length > 0 &&
                                totalSum?.map((item, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="fw-semi-bold">{numeral.formats[numberFormat].format(item?.totalSum || 0)} <span className="text-info">{item?.name}</span></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </Card>
    );
};

export default WarehouseOperationCashBoxCard;
