import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import Waybill from "../../../../components/roaming/waybill/view/Waybill";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {loadWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    CARRIER_CANCEL_WAYBILL_SUCCEED,
    CUSTOMER_ACCEPT_WAYBILL_SUCCEED,
    CUSTOMER_DECLINE_WAYBILL_SUCCEED,
    ROAMING_WAYBILL_SYNC_SUCCESS
} from "../../../../app/eventbus/roaming/roamingWaybillEvents";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {useHistory} from "react-router";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingShipmentView} from "../../../../enum/Permission/RoamingPermission";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";

const ViewWaybill = ({modalId}) => {
    const {params: {id}} = useRouteMatch();
    const [waybill, setWaybill] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    const history = useHistory();
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingShipmentView))) {
            if (history.length > 1) {
                history.push('/roaming/waybills/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadWaybill();
    }, [modalId, id, activeOrganization])

    useEffect(() => {
        const onCarrierCancelWaybillSucceedHandler = EventBus.on(CARRIER_CANCEL_WAYBILL_SUCCEED, () => {
            toast.success(t(lang, 'roaming.waybill.cancel_waybill.success.toast'));
            loadWaybill();
        });

        const onCustomerDeclineWaybillSucceedHandler = EventBus.on(CUSTOMER_DECLINE_WAYBILL_SUCCEED, () => {
            toast.success(t(lang, 'roaming.waybill.decline_waybill.success.toast'));
            loadWaybill();
        });

        const onCustomerAcceptWaybillSucceedHandler = EventBus.on(CUSTOMER_ACCEPT_WAYBILL_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
            toast.success(t(lang, 'roaming.waybill.accept_waybill.success.toast'));
            loadWaybill();
        });

        const onSyncWaybillSuccessHandler = EventBus.on(ROAMING_WAYBILL_SYNC_SUCCESS, () => {
            loadWaybill();
        })

        return () => {
            EventBus.remove(CARRIER_CANCEL_WAYBILL_SUCCEED, onCarrierCancelWaybillSucceedHandler);
            EventBus.remove(CUSTOMER_DECLINE_WAYBILL_SUCCEED, onCustomerDeclineWaybillSucceedHandler);
            EventBus.remove(CUSTOMER_ACCEPT_WAYBILL_SUCCEED, onCustomerAcceptWaybillSucceedHandler);
            EventBus.remove(ROAMING_WAYBILL_SYNC_SUCCESS, onSyncWaybillSuccessHandler);
        }
    }, [])

    const loadWaybill = () => {
        loadWaybillAsync(modalId ? modalId : id).then(loadedWaybill => {
            setWaybill(loadedWaybill)
        }).catch(error => console.log(error))
    }

    return (
        <>
            {waybill && <Waybill waybill={waybill} />}
            {!waybill && <SpinnerComponent />}
        </>
    );
};

export default ViewWaybill;
