import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import React, {useEffect, useState} from "react";
import {loadDraftEmpowerment} from "../../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import Empowerment from "../Empowerment";
import {useHistory, useRouteMatch} from "react-router-dom";
import {VIEWER_CUSTOMER} from "../../../../../enum/EmpowermentStatus";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingEmpowermentView} from "../../../../../enum/Permission/RoamingPermission";
import {selectAccountRolePermissions} from "../../../../../app/store/reducers/role/roleReducer";
import SpinnerComponent from "../../../spinner/SpinnerComponent";


const DraftEmpowerment = ({modalId}) => {
    const [draftEmpowerment, setDraftEmpowerment] = useState(null)
    const [draftEmpowermentJson, setDraftEmpowermentJson] = useState(null)
    const activeOrganization = useSelector(selectActiveOrganization)
    const {params: {id}} = useRouteMatch();
    const history = useHistory()
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingEmpowermentView))) {
            if (history.length > 1) {
                history.push('/roaming/empowerments/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadEmpowerment()
    }, [modalId, id, activeOrganization])


    const loadEmpowerment = async () => {
        try {
            const getDraft = await loadDraftEmpowerment(modalId ? modalId : id)
            setDraftEmpowerment(JSON.parse(getDraft?.data?.content))
            setDraftEmpowermentJson(getDraft?.data?.content)
        }catch (error) {
            history.push(`/roaming/empowerments/${VIEWER_CUSTOMER}`)
        }
    }

    const empowerment = {
        id: draftEmpowerment?.EmpowermentId,
        product_id: draftEmpowerment?.ProductList?.EmpowermentProductId,
        agent_empowerment_id: draftEmpowerment?.Agent?.AgentEmpowermentId,
        info: {
            number: draftEmpowerment?.EmpowermentDoc?.EmpowermentNo,
            start: draftEmpowerment?.EmpowermentDoc?.EmpowermentDateOfIssue,
            end: draftEmpowerment?.EmpowermentDoc?.EmpowermentDateOfExpire,
        },
        customer: {
            inn: draftEmpowerment?.BuyerTin,
            name: draftEmpowerment?.Buyer?.Name,
            director: draftEmpowerment?.Buyer?.Director,
            accountant: draftEmpowerment?.Buyer?.Accountant,
        },
        executor: {
            inn: draftEmpowerment?.SellerTin,
            name: draftEmpowerment?.Seller?.Name,
            director: draftEmpowerment?.Seller?.Director,
            accountant: draftEmpowerment?.Seller?.Accountant,
        },
        person_info: {
            full_name: draftEmpowerment?.Agent?.Fio,
            person_inn: draftEmpowerment?.Agent?.AgentTin,
        },
        contract: {
            date: draftEmpowerment?.ContractDoc?.ContractDate,
            number: draftEmpowerment?.ContractDoc?.ContractNo,
        },
        items: draftEmpowerment?.ProductList?.Products.map(item => {
            return {
                ordering_number: item?.OrdNo,
                name: item?.Name,
                quantity: item?.Count,
                measure_id: item?.MeasureId,
                catalog: {
                    code: item?.CatalogCode,
                    name: item?.CatalogName,
                }
            }
        })
    }


    return (
        <>
            {draftEmpowerment && <Empowerment modalId={modalId} empowerment={empowerment} draftEmpowermentJson={draftEmpowermentJson}/>}
            {!draftEmpowerment && <SpinnerComponent />}
        </>
    )
}

export default DraftEmpowerment;
