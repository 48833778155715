import React from "react";
import {useFormContext} from "react-hook-form";
import {Form} from "react-bootstrap";
import PropTypes from 'prop-types';
import {getUsernameExistsAsync} from "../../app/store/reducers/auth/authReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const UsernameInputForm = ({name, shouldExist, shouldNotExist, hasLabel, ...props}) => {
    const {register, getFieldState} = useFormContext();
    const {error} = getFieldState(name);
    const lang = useSelector(selectLang);
    const t = Translate;

    const validateShouldExist = async value => {
        if (shouldExist)
            return await getUsernameExistsAsync(value)
        return true;
    }

    const validateShouldNotExist = async value => {
        if (shouldNotExist)
            return !await getUsernameExistsAsync(value);
        return true;
    }

    return <Form.Group className='text-start' {...props}>
        {hasLabel && <Form.Label>Phone number</Form.Label>}
        <Form.Control
            type='number'
            placeholder="998ххххххххх"
            isInvalid={error}
            className='input-spin-none'
            {...register(name, {
                required: t(lang, 'edi.common.forms.validations.is_required'),
                validate: {
                    format: value => /^998(\d{9})$/.test(value) || t(lang, 'authentication.forgot-password.phoneNumber.validations.format'),
                    exist: async value => {
                        if (!await validateShouldExist(value))
                            return t(lang, 'authentication.forgot-password.phoneNumber.validations.not_exist');

                        if (!await validateShouldNotExist(value))
                            return 'Username exists'

                        return true;
                    }
                }
            })}
        />
        <Form.Control.Feedback type='invalid'>{error?.message}</Form.Control.Feedback>
    </Form.Group>;
};

UsernameInputForm.propTypes = {
    name: PropTypes.string,
    shouldExist: PropTypes.bool,
    shouldNotExist: PropTypes.bool,
    hasLabel: PropTypes.bool
};

UsernameInputForm.defaultProps = {
    name: 'username',
    shouldExist: false,
    shouldNotExist: false,
    hasLabel: false
};

export default UsernameInputForm;
