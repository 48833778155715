import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {Col, Collapse, Form, Row,} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import IconButton from "../../../../common/IconButton";
import ChargeFilter from "./ChargeFilter";
import {
    changeFilterChargesAdminAsync,
    changeFilterChargesAsync
} from "../../../../../app/store/reducers/billing/billingReducer";

const ChargeDatatableHeader = ({isAdmin}) => {

    const dispatch = useDispatch()

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false)


    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        created_on_end: null,
        created_on_start: null,
    })

    const onSubmitFilter = ({created_on_start, created_on_end}) => {

        created_on_start = created_on_start && dayjs(created_on_start).format("YYYY-MM-DD")
        created_on_end = created_on_end && dayjs(created_on_end).format("YYYY-MM-DD")

        {
            isAdmin && dispatch(changeFilterChargesAdminAsync({
                created_on_start,
                created_on_end
            }))
        }

        {
            !isAdmin &&   dispatch(changeFilterChargesAsync({
                created_on_start,
                created_on_end
            }))
        }

    }

    return (
        <Row className="d-flex justify-content-between align-items-end">

            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                    <Collapse in={isOpenFilterCollapse}>
                        <Col sm={12}>
                            <ChargeFilter/>
                            <hr/>
                        </Col>
                    </Collapse>
                </Form>
            </FormProvider>

            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <IconButton
                    onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                    variant="falcon-default"
                    size="sm"
                    icon="filter"
                    transform="shrink-3"
                >
                    <span
                        className="d-none d-sm-inline-block ms-1">{t(lang, "admin.common.payments.datatable.navigation.filter")}</span>
                </IconButton>
            </Col>


        </Row>
    );
};

export default ChargeDatatableHeader;
