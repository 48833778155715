import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    deletePushUpdateNotification
} from "../../app/store/reducers/push-update-notification/pushUpdateNotificationReducer";
import {selectWarehouses} from "../../app/store/reducers/warehouse/warehouseReducer";
import {
    AccountCashBoxBindingDisabled,
    AccountCashBoxBindingEnabled, AccountCashBoxBindRegistered, AccountCashBoxBindUnregistered,
    AccountContractorBindingDisable,
    AccountContractorBindingEnable,
    AccountContractorBindRegister,
    AccountContractorBindUnregister, CashBoxAmountsUpdated, CashBoxDeleted,
    CashBoxRegistered,
    CashBoxUpdated,
    ContractorDebtUpdated,
    WarehouseItemUpdated
} from "../../enum/PushUpdateNotification";
import {Translate, selectLang, selectNumberFormat} from "../../app/store/reducers/main/mainReducer";
import SoftBadge from "../common/SoftBadge";
import numeral from "numeral";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {selectUsers} from "../../app/store/reducers/auth/authReducer";
import {selectAllContractor} from "../../app/store/reducers/contractor/contractorReducer";
import {Badge} from "react-bootstrap";
import {selectCashbox} from "../../app/store/reducers/cashbox/CashboxReducer";

const Notification = ({index, notification}) => {
    const t = Translate
    const lang = useSelector(selectLang)
    const [currencyArr, setCurrencyArr] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const numberFormat = useSelector(selectNumberFormat);
    const dispatch = useDispatch()
    const users = useSelector(selectUsers)
    const contractors = useSelector(selectAllContractor)
    const cashbox = useSelector(selectCashbox)
    const deleteOne = (index) => {
        dispatch(deletePushUpdateNotification(index))
        // toast.error(t(lang,"notification.toast.notification_deleted"))
    }
    useEffect(() => {
        if (notification.type === ContractorDebtUpdated) {
            const debtsArray = notification?.payload?.debts?.map((item) => ({
                id: item.currency.id,
                name: item.currency.name,
                amount: item.amount
            }))
            setCurrencyArr(debtsArray)
        }
    }, [notification]);


    const warehouseItemUpdateText = t(lang, 'notification.warehouse_item.message', {
        item_name: '{item_name}',
        warehouse_id: '{warehouse_id}',
        state: '{state}'
    })
    const contractorDebtUpdateText = t(lang, 'notification.contractor_debt.client_name', {
        contractor_name: '{contractor_name}',
        debt: '{debt}',
    })
    const accountContractorBindingText = t(lang, 'notification.toast.account.register.text', {
        contractor_name: '{contractor_name}',
        account_name: '{account_name}'
    })
    const accountContractorUnbindingText = t(lang, 'notification.toast.account.unregister.text', {
        contractor_name: '{contractor_name}',
        account_name: '{account_name}'
    })
    const cashBoxRegisterText = t(lang, 'notification.toast.cash_box.registered.text', {
        branch_name: '{branch_name}',
        cash_box_name: '{cash_box_name}'
    })
    const cashBoxUpdateText = t(lang, 'notification.toast.cash_box.updated.text', {
        branch_name: '{branch_name}',
        cash_box_name: '{cash_box_name}'
    })
    const cashBoxDeleteText = t(lang, 'notification.toast.cash_box.deleted.text', {
        branch_name: '{branch_name}',
        cash_box_name: '{cash_box_name}'
    })

    const accountCashBoxBindRegisteredText = t(lang, 'notification.toast.cash_box.account.contractor.register.text', {
        account_name: '{account_name}',
        cash_box_name: '{cash_box_name}'
    })

    const accountCashBoxUnbindingRegisteredText = t(lang, 'notification.toast.cash_box.account.contractor.unregister.text', {
        account_name: '{account_name}',
        cash_box_name: '{cash_box_name}'
    })

    const cashBoxAmountsUpdated = t(lang, 'notification.toast.cash_box.amount.update', {
        cash_box_name: '{cash_box_name}'
    })

    return (
        <div className={"notification-body"}>
            <div className={'position-relative'} style={{overflowWrap: 'break-word', hyphens: 'auto', whiteSpace: 'normal'}}>
                <Fragment>
                    {notification.type === WarehouseItemUpdated &&
                        <div>
                            {warehouseItemUpdateText.split(/({item_name}|{warehouse_id}|{state})/g).map((part, index) =>
                                part === '{item_name}' ? <SoftBadge key={index} bg='info' className='me-1 text-wrap'>{notification?.payload?.item_name ? notification?.payload?.item_name : null}</SoftBadge> :
                                part === '{warehouse_id}' ? <SoftBadge key={index} bg='info' className='mx-1 text-wrap'>{(notification?.payload?.warehouse_id && warehouses.find(i => i.id === notification?.payload?.warehouse_id).name)}</SoftBadge> :
                                part === '{state}' ? notification?.payload?.state > 0 ? <SoftBadge key={index} bg='success' className='mx-1 text-wrap'>{notification?.payload?.state}</SoftBadge> : <SoftBadge key={index} bg='danger' className='mx-1 text-wrap'>{notification?.payload?.state}</SoftBadge> :
                                part
                            )}
                        </div>
                    }

                    {notification.type === ContractorDebtUpdated &&
                        <div>
                            {
                                contractorDebtUpdateText.split(/({contractor_name}|{debt})/g).map((part, index) =>
                                    part === '{contractor_name}' ? <SoftBadge key={index} bg='info' className='me-1'>{notification?.payload?.name}</SoftBadge> :
                                    part === '{debt}' ? currencyArr && <div key={index} className={'d-inline-flex gap-2'}>
                                        {currencyArr.map(item => <SoftBadge key={item.id}
                                                                            bg='primary'>
                                            <span>{numeral.formats[numberFormat].format(item.amount)}</span>
                                            <small className={'ms-2 fw-bold text-info'}>{item.name}</small>
                                        </SoftBadge>)}
                                    </div> : part
                                )
                            }
                        </div>
                    }

                    {
                    (notification.type === AccountContractorBindingDisable || notification.type === AccountCashBoxBindingDisabled) &&
                        <div className={'text-center'}>
                            <span
                                className={'text-start d-block text-700 fw-bold'}>{t(lang, notification.type === AccountContractorBindingDisable ? "crm.data.table.contractor" : "cashbox.bind.account.title")}</span>
                            <SoftBadge bg={'primary'}>
                                {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                            </SoftBadge>
                            <span className={'text-wrap'}>{t(lang, "notification.toast.account.blocked")}</span>
                            <Badge bg={"secondary"}
                                   className="ms-2">{t(lang, "crm.bind.contractor.account.block.action")}</Badge>
                        </div>
                    }

                    {
                        (notification.type === AccountContractorBindingEnable || notification.type === AccountCashBoxBindingEnabled) &&
                        <div className={'text-center'}>
                            <span
                                className={'text-start d-block text-700 fw-bold text-wrap'}>{t(lang, notification.type === AccountContractorBindingEnable ? "crm.data.table.contractor" : "cashbox.bind.account.title")}</span>
                            <SoftBadge bg={'secondary'} className={'text-wrap'}>
                                {users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}
                            </SoftBadge>
                            <span className={'text-wrap'}>{t(lang, "notification.toast.account.blocked")}</span>
                            <Badge bg={"success"}
                                   className="ms-2 text-wrap">{t(lang, "crm.bind.contractor.account.unlock.action")}</Badge>
                        </div>
                    }

                    {
                        (notification.type === AccountContractorBindRegister) &&
                        <div className={'text-center'}>
                              <span className={'text-start d-block text-700 fw-bold text-wrap'}>{t(lang, "crm.data.table.contractor")}</span>
                            {
                                accountContractorBindingText.split(/({contractor_name}|{account_name})/g).map((part, index) =>
                                    part === '{contractor_name}' ? <SoftBadge key={index} bg='success' className='me-1'>{contractors.find(i => i.id === notification.payload.contractor_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}</SoftBadge> :
                                    part === '{account_name}' ? <SoftBadge key={index} bg='primary'>{users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}</SoftBadge> :
                                    part
                                )
                            }
                        </div>
                    }

                    {
                        (notification.type === AccountContractorBindUnregister) &&
                        <div className={'text-center'}>
                            <span className={'text-start d-block text-700 fw-bold'}>{t(lang, "crm.data.table.contractor")}</span>
                            {
                                accountContractorUnbindingText.split(/({contractor_name}|{account_name})/g).map((part, index) =>
                                    part === '{contractor_name}' ? <SoftBadge key={index} bg='danger' className='me-1 text-wrap'>{contractors.find(i => i.id === notification.payload.contractor_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}</SoftBadge> :
                                    part === '{account_name}' ? <SoftBadge key={index} bg='primary' className={'text-wrap'}>{users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}</SoftBadge> :
                                    part
                            )}
                        </div>
                    }

                    {
                        (notification.type === CashBoxRegistered) &&
                        <div className={'text-center'}>
                            {
                                cashBoxRegisterText.split(/({branch_name}|{cash_box_name})/g).map((part, index) =>
                                    part === '{branch_name}' ? <SoftBadge key={index} bg='primary' className='me-1 text-wrap'>{notification.payload.branch?.name}</SoftBadge> :
                                    part === '{cash_box_name}' ? <SoftBadge key={index} bg='success' className='me-1 text-wrap'>{notification.payload.name}</SoftBadge> :
                                    part
                                )
                            }
                        </div>
                    }

                    {
                        (notification.type === CashBoxUpdated) &&
                        <div className={'text-center'}>
                            {
                                cashBoxUpdateText.split(/({branch_name}|{cash_box_name})/g).map((part, index) =>
                                    part === '{branch_name}' ? <SoftBadge key={index} bg='primary' className='me-1 text-wrap'>{notification.payload.branch?.name}</SoftBadge> :
                                    part === '{cash_box_name}' ? <SoftBadge key={index} bg='warning' className='me-1 text-wrap'>{notification.payload.name}</SoftBadge> :
                                    part
                                )
                            }

                        </div>
                    }

                    {
                        (notification.type === CashBoxDeleted) &&
                        <div className={'text-center'}>
                            {
                                cashBoxDeleteText.split(/({branch_name}|{cash_box_name})/g).map((part, index) =>
                                    part === '{branch_name}' ? <SoftBadge key={index} bg='primary' className='me-1 text-wrap'>{notification.payload.branch?.name}</SoftBadge> :
                                    part === '{cash_box_name}' ? <SoftBadge key={index} bg='danger' className='me-1 text-wrap'>{notification.payload.name}</SoftBadge> :
                                    part
                                )
                            }
                        </div>
                    }

                    {
                        (notification.type === AccountCashBoxBindRegistered) &&
                        <div className={'text-center'}>
                             <span className={'text-start d-block text-700 fw-bold'}>{t(lang, "cashbox.bind.account.title")}</span>
                            {
                                accountCashBoxBindRegisteredText.split(/({account_name}|{cash_box_name})/g).map((part, index) =>
                                    part === '{account_name}' ? <SoftBadge key={index} bg='primary' className='me-1 text-wrap'>{users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}</SoftBadge> :
                                    part === '{cash_box_name}' ? <SoftBadge key={index} bg='success' className='me-1 text-wrap'>{cashbox.find(i => i.id === notification.payload.cash_box_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}</SoftBadge> :
                                    part
                                )
                            }
                        </div>
                    }
                    {
                        (notification.type === AccountCashBoxBindUnregistered) &&
                        <div className={'text-center'}>
                             <span className={'text-start d-block text-700 fw-bold'}>{t(lang, "cashbox.bind.account.title")}</span>
                            {
                                accountCashBoxUnbindingRegisteredText.split(/({account_name}|{cash_box_name})/g).map((part, index) =>
                                    part === '{account_name}' ? <SoftBadge key={index} bg='primary' className='me-1 text-wrap'>{users.find(i => i.id === notification.payload.account_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}</SoftBadge> :
                                    part === '{cash_box_name}' ? <SoftBadge key={index} bg='danger' className='me-1 text-wrap'>{cashbox.find(i => i.id === notification.payload.cash_box_id)?.name || t(lang, "crm.bind.contractor.account.unknown.account")}</SoftBadge> :
                                    part
                                )
                            }
                        </div>
                    }
                    {
                        (notification.type === CashBoxAmountsUpdated) &&
                        <div className={'text-center'}>
                            {
                                cashBoxAmountsUpdated.split(/({cash_box_name})/g).map((part, index) =>
                                    part === '{cash_box_name}' ? <SoftBadge key={index} bg='success' className='me-1 text-wrap'>{notification.payload.name}</SoftBadge> :
                                    part
                                )
                            }
                        </div>
                    }
                </Fragment>
                <FontAwesomeIcon icon="fa-solid fa-xmark" style={{right: '-10px'}} transform="shrink-3"
                                 onClick={() => deleteOne(index)} size={"2x"} className="text-danger position-absolute top-0 cursor-pointer"/>
            </div>
        </div>
    )
};
export default Notification;
