import React, {useCallback, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {loadOrganizationBinding} from "../../../../app/store/reducers/organization-binding/organizationBindingReducer";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/IconButton";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import OrganizationBindingForm from "./OrganizationBindingForm";

const EditOrganizationBindingModal = ({id}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const [organizationBinding, setOrganizationBinding] = useState(null);

    const load = useCallback(() => {
        setLoading(true);
        loadOrganizationBinding(id)
            .then((response) => {
                setOrganizationBinding(response);
                setLoading(false);
            })
            .catch((error) => {
                setOrganizationBinding(null);
                setLoading(false)
            })
    }, [id])

    return (
        <React.Fragment>
            <IconButton size="sm"
                        variant="falcon-warning"
                        icon={faPencil}
                        onClick={load}
                        disabled={loading}
            />
            {organizationBinding !== null &&
                <Modal show={true} onHide={() => setOrganizationBinding(null)}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'partners.common.edit_partner')}</Modal.Title>
                        <FalconCloseButton onClick={() => setOrganizationBinding(null)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <OrganizationBindingForm organizationBinding={organizationBinding}
                                                 handleClose={() => setOrganizationBinding(null)}
                        />
                    </Modal.Body>
                </Modal>
            }
        </React.Fragment>
    );
};

export default EditOrganizationBindingModal;

EditOrganizationBindingModal.propTypes = {
    id: PropTypes.string,
};

EditOrganizationBindingModal.defaultProps = {
    id: ''
};