import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SelectCertificate from "../../eimzo/SelectCertificate";
import {Button, Form, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useForm, Controller} from "react-hook-form";
import classNames from "classnames";
import {loginWithCertificateAsync} from "../../../../app/store/reducers/auth/authReducer";

const LoginWithCertificate = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const {formState: {errors}, handleSubmit, control} = useForm();

    const [loading, setLoading] = useState(false);

    const onSubmit = (formData) => {
        setLoading(true);
        dispatch(loginWithCertificateAsync(formData.certificate))
            .then(() => {})
            .catch(() => {})
            .finally(() => setLoading(false))
    };


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Label>Сертификат</Form.Label>
            <Controller name="certificate"
                        rules={{
                            required: t(lang, "edi.common.forms.validations.is_required"),
                        }}
                        control={control}
                        render={({field}) => (
                            <SelectCertificate placeholder={t(lang, "edi.common.select_button")}
                                               className={classNames({'is-invalid': errors?.certificate})}
                                               isClearable
                                               onChange={field.onChange}
                            />
                        )}
            />
            <Form.Control.Feedback type="invalid">{errors?.certificate?.message}</Form.Control.Feedback>
            <Button type="submit" color="primary" className="mt-3 w-100">
                {loading &&
                    <Spinner className="align-middle me-2" animation="border" role="switch"
                             size="sm"/>}
                {t(lang, "edi.login.form.button.login")}
            </Button>
        </Form>
    )
};

export default LoginWithCertificate;