import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faRepeat} from "@fortawesome/free-solid-svg-icons";
import withValidateCatalog from "./withValidateCatalog";

const ValidateCatalogButton = ({id, validateCatalog}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <IconButton variant="falcon-info"
                    size="sm"
                    icon={faRepeat}
                    onClick={() => validateCatalog(id)}
                    className="mt-2 mt-lg-0 ms-lg-2"
        >
            {t(lang, 'items.common.catalog.sync')}
        </IconButton>
    );
};

export default withValidateCatalog(ValidateCatalogButton);