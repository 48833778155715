import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    getWaybillRegionAsync,
    selectRegion
} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";

const SelectRegion = ({onChange, defaultRegionId, isDisabled, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const regions = useSelector(selectRegion);
    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(regions.find(i => i.code === defaultRegionId) || null)
    }, [regions, defaultRegionId]);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
    }

    return (
        <ReactSelect
            isDisabled={isDisabled}
            value={value}
            options={regions}
            classNamePrefix={!isDisabled && 'react-select'}
            placeholder={t(lang, "roaming.common.select.placeholder")}
            onChange={(option) => handleChange(option)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            hideSelectedOptions
            {...props}
        />

    );
};

export default SelectRegion;
