const TemplateCodeWMSSalePdfDefault = 1;
const TemplateCodeWMSSaleSkuPackage = 2;
const TemplateCodeWMSSaleMultiWarehouse = 3;
const TemplateCodeWMSSaleItemImage = 4;
const TemplateCodeWMSSaleContractorLastPayment = 5;
const TemplateCodeWMSSaleReceipt80Default = 6;
const TemplateCodeWMSSaleReceipt58Default = 7;
const TemplateCodeWMSSaleWarehouseman = 8;

const TemplateCodeWMSRefundPdfDefault = 100;
const TemplateCodeWMSRefundReceipt80Default = 101;
const TemplateCodeWMSRefundReceipt58Default = 102;

const TemplateCodeWMSPurchasePdfDefault       = 201;
const TemplateCodeWMSPurchaseReceipt80Default = 202;
const TemplateCodeWMSPurchaseReceipt58Default = 203;

const TemplateCodeWMSReturnPdfDefault       = 301;
const TemplateCodeWMSReturnReceipt80Default = 302;
const TemplateCodeWMSReturnReceipt58Default = 303;

const TemplateCodeWMSRevisionPdfDefault       = 400;
const TemplateCodeWMSRevisionReceipt80Default = 401;
const TemplateCodeWMSRevisionReceipt58Default = 402;

const TemplateCodeWMSWriteOffPdfDefault       = 500;
const TemplateCodeWMSWriteOffReceipt80Default = 501;
const TemplateCodeWMSWriteOffReceipt58Default = 502;

const TemplateCodeWMSTransferPdfDefault       = 600;
const TemplateCodeWMSTransferReceipt80Default = 601;
const TemplateCodeWMSTransferReceipt58Default = 602;

export const PDFTemplates = {
    [TemplateCodeWMSSalePdfDefault]: {code: TemplateCodeWMSSalePdfDefault, label: "main.common.templates.sale_pdf_default"},
    [TemplateCodeWMSSaleSkuPackage]: {code: TemplateCodeWMSSaleSkuPackage, label: "main.common.templates.sale_sku_package"},
    [TemplateCodeWMSSaleMultiWarehouse]: {code: TemplateCodeWMSSaleMultiWarehouse, label: "main.common.templates.sale_multi_warehouse"},
    [TemplateCodeWMSSaleItemImage]: {code: TemplateCodeWMSSaleItemImage, label: "main.common.templates.sale_item_image"},
    [TemplateCodeWMSSaleContractorLastPayment]: {code: TemplateCodeWMSSaleContractorLastPayment, label: "main.common.templates.sale_contractor_last_payment"},
    [TemplateCodeWMSSaleWarehouseman]: {code: TemplateCodeWMSSaleWarehouseman, label: "main.common.templates.sale_for_warehouseman"},
    [TemplateCodeWMSSaleReceipt80Default]: {code: TemplateCodeWMSSaleReceipt80Default, label: "main.common.templates.sale_receipt_80_default"},
    [TemplateCodeWMSSaleReceipt58Default]: {code: TemplateCodeWMSSaleReceipt58Default, label: "main.common.templates.sale_receipt_58_default"},
    [TemplateCodeWMSRefundPdfDefault]: {code: TemplateCodeWMSRefundPdfDefault, label: "main.common.templates.refund_pdf_default"},
    [TemplateCodeWMSRefundReceipt80Default]: {code: TemplateCodeWMSRefundReceipt80Default, label: "main.common.templates.refund_receipt_80_default"},
    [TemplateCodeWMSRefundReceipt58Default]: {code: TemplateCodeWMSRefundReceipt58Default, label: "main.common.templates.refund_receipt_58_default"},
    [TemplateCodeWMSPurchasePdfDefault]: {code: TemplateCodeWMSPurchasePdfDefault, label: "main.common.templates.purchase_pdf_default"},
    [TemplateCodeWMSPurchaseReceipt80Default]: {code: TemplateCodeWMSPurchaseReceipt80Default, label: "main.common.templates.purchase_receipt_80_default"},
    [TemplateCodeWMSPurchaseReceipt58Default]: {code: TemplateCodeWMSPurchaseReceipt58Default, label: "main.common.templates.purchase_receipt_58_default"},
    [TemplateCodeWMSReturnPdfDefault]: {code: TemplateCodeWMSReturnPdfDefault, label: "main.common.templates.return_pdf_default"},
    [TemplateCodeWMSReturnReceipt80Default]: {code: TemplateCodeWMSReturnReceipt80Default, label: "main.common.templates.return_receipt_80_default"},
    [TemplateCodeWMSReturnReceipt58Default]: {code: TemplateCodeWMSReturnReceipt58Default, label: "main.common.templates.return_receipt_58_default"},
    [TemplateCodeWMSRevisionPdfDefault]: {code: TemplateCodeWMSRevisionPdfDefault, label: "main.common.templates.revision_pdf_default"},
    [TemplateCodeWMSRevisionReceipt80Default]: {code: TemplateCodeWMSRevisionReceipt80Default, label: "main.common.templates.revision_receipt_80_default"},
    [TemplateCodeWMSRevisionReceipt58Default]: {code: TemplateCodeWMSRevisionReceipt58Default, label: "main.common.templates.revision_receipt_58_default"},
    [TemplateCodeWMSWriteOffPdfDefault]: {code: TemplateCodeWMSWriteOffPdfDefault, label: "main.common.templates.write_off_pdf_default"},
    [TemplateCodeWMSWriteOffReceipt80Default]: {code: TemplateCodeWMSWriteOffReceipt80Default, label: "main.common.templates.write_off_receipt_80_default"},
    [TemplateCodeWMSWriteOffReceipt58Default]: {code: TemplateCodeWMSWriteOffReceipt58Default, label: "main.common.templates.write_off_receipt_58_default"},
    [TemplateCodeWMSTransferPdfDefault]: {code: TemplateCodeWMSTransferPdfDefault, label: "main.common.templates.transfer_pdf_default"},
    [TemplateCodeWMSTransferReceipt80Default]: {code: TemplateCodeWMSTransferReceipt80Default, label: "main.common.templates.transfer_receipt_80_default"},
    [TemplateCodeWMSTransferReceipt58Default]: {code: TemplateCodeWMSTransferReceipt58Default, label: "main.common.templates.transfer_receipt_58_default"},
};