import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {loadDraftPDFAsync} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import printJS from "print-js";
import {toast} from "react-toastify";
import IconButton from "../../../../common/IconButton";
import {Spinner} from "react-bootstrap";

const RoamingDraftInvoicePrint = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onPrint = () => {
        setLoading(true);
        loadDraftPDFAsync(id)
            .then(response => {
                setLoading(false);
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                setLoading(false);
                toast.error(t(lang, "roaming.invoice.print_failed_error"))
            })
    }

    return (
        <IconButton {...props} icon="print" transform="shrink-3" onClick={onPrint}>
            <span className="d-none d-sm-inline-block">{t(lang, 'roaming.invoice.print')}</span>
            {loading && (
                <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
            )}
        </IconButton>
    );
};

export default RoamingDraftInvoicePrint;
