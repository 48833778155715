import dayjs from "dayjs";
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import {Form, Row, Collapse, Col} from "react-bootstrap";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import ExcelRegistryDataTableFilter from "./ExcelRegistryDataTableFilter";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    changeExcelRegistriesFilterOptions,
    clearExcelRegistriesFilterOptions,
    selectExcelRegistriesFilterOptions
} from "../../../../app/store/reducers/excel-registry/excelRegistryReducer";

const ExcelRegistryDataTableHeader = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const filter = useSelector(selectExcelRegistriesFilterOptions);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            file_name: filter?.file_name || null,
            status: filter?.status || null,
            created_on_start: filter?.created_on_start && dayjs(filter?.created_on_start).toDate() || null,
            created_on_end: filter?.created_on_end && dayjs(filter?.created_on_end).toDate() || null,
        }
    });

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    const onSubmitFilter = ({created_on_start, created_on_end, ...filters}) => {
        created_on_start = created_on_start && dayjs(created_on_start).format("YYYY-MM-DD")
        created_on_end = created_on_end && dayjs(created_on_end).format("YYYY-MM-DD")
        dispatch(changeExcelRegistriesFilterOptions({
            created_on_start: created_on_start,
            created_on_end: created_on_end,
            page: 1,
            ...filters
        }))
    }

    const onResetFilter = () => {
        methods.reset()
        dispatch(clearExcelRegistriesFilterOptions({
            page: filter.page,
            limit: filter.limit,
        }))
    }

    return (
        <>
            <Row className="flex-between-center">
                <Collapse in={isCollapsed}>
                    <Col sm={12}>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                                <ExcelRegistryDataTableFilter onResetFilter={onResetFilter}/>
                                <hr/>
                            </Form>
                        </FormProvider>
                    </Col>
                </Collapse>

                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.excel_registry.datatable.title")}</h5>
                </Col>

                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    <IconButton
                        size="sm"
                        as={Link}
                        className="mx-1"
                        icon={faFileExcel}
                        transform="shrink-3"
                        variant="falcon-default"
                        to="/roaming/invoice/mass-add"
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "roaming.excel_registry.datatable.header.navigation.mass_add_invoice")}
                        </span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        as={Link}
                        className="mx-1"
                        icon={faFileExcel}
                        transform="shrink-3"
                        variant="falcon-default"
                        to="/roaming/waybill/mass-register"
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "roaming.excel_registry.datatable.header.navigation.mass_add_waybill")}
                        </span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        icon="refresh"
                        className="mx-2"
                        transform="shrink-3"
                        variant="falcon-default"
                        onClick={methods.handleSubmit(onSubmitFilter)}
                    >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, "roaming.excel_registry.datatable.header.navigation.refresh")}
                            </span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        icon="filter"
                        transform="shrink-3"
                        variant="falcon-default"
                        onClick={toggleCollapse}
                    >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, "roaming.excel_registry.datatable.header.navigation.filter")}
                            </span>
                    </IconButton>
                </Col>
            </Row>
        </>
    );
};

export default ExcelRegistryDataTableHeader;
