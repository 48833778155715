import EventBus from "../../../eventbus/EventBus";
import {
    ADD_BRANCH_FAILED,
    ADD_BRANCH_REQUESTED,
    ADD_BRANCH_SUCCESS,
    EDIT_BRANCH_FAILED,
    EDIT_BRANCH_SUCCESS,
    EDIT_BRANCH_REQUESTED,
    DELETE_BRANCH_FAILED,
    DELETE_BRANCH_REQUESTED,
    DELETE_BRANCH_SUCCESS,
    BIND_WAREHOUSE_SUCCESS,
    BIND_WAREHOUSE_FAILED,
    BIND_ACCOUNT_ENABLE,
    BIND_ACCOUNT_DISABLE,
    BIND_ACCOUNT_REGISTER,
    BIND_ACCOUNT_UNREGISTER,
    UPDATE_BRANCH_ROAMING_ADDRESS_REQUESTED,
    UPDATE_BRANCH_ROAMING_ADDRESS_SUCCEED,
    UPDATE_BRANCH_ROAMING_ADDRESS_FAILED, DELETE_BRANCH_ROAMING_ADDRESS_REQUESTED, DELETE_BRANCH_ROAMING_ADDRESS_SUCCEED, DELETE_BRANCH_ROAMING_ADDRESS_FAILED
} from "../../../eventbus/branchEvents";
import branchService from "../../../../services/branchService";
import {createSlice} from "@reduxjs/toolkit";
import settingsService from "../../../../services/settingsService";

export const STORAGE_BRANCH = "BranchesDataTableSettings"

export const branchSlice = createSlice({
    name: 'branch',
    initialState: {
        branches: [],
        branchUses: [],
        branchesDataTableSettings: {},
        accounts: []
    },
    reducers: {
        updateBranches: (state, action) => {
            state.branches = action.payload;
        },
        updateBranchUses: (state, action) => {
            state.branchUses = action.payload;
        },

        loadBranchDataTableColumn: (state) => {
            const defaultColumns = {
                index: true,
                name: true,
                code: true,
                branch_name: true,
                branch_code: true,
                bind_warehouse: true,
                button_action: true,
            }
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_BRANCH))
            if(!storageColumn) {
                storageColumn = defaultColumns
                localStorage.setItem(STORAGE_BRANCH, JSON.stringify(storageColumn));
            } else {
                storageColumn = {...defaultColumns, ...storageColumn}
            }
            state.branchesDataTableSettings = storageColumn
        },

        changeBranchDataTableColumn: (state, action) => {
            state.branchesDataTableSettings = action.payload
            localStorage.setItem(STORAGE_BRANCH, JSON.stringify(state.branchesDataTableSettings))
        },

        updateAccounts: (state, action) => {
            state.accounts = action.payload
        }
    },
});

export const loadBranchesAsync = () => dispatch => {
    branchService.getBranches()
        .then((response) => {
            dispatch(branchSlice.actions.updateBranches(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const addBranchAsync = ({name, code, inn, roaming_branch}) => {
    EventBus.dispatch(ADD_BRANCH_REQUESTED, {name, code, inn, roaming_branch});

    return new Promise((resolve, reject) => {
        branchService.addBranch({name, code, inn, roaming_branch})
            .then(response => {
                const branch = response.data;
                EventBus.dispatch(ADD_BRANCH_SUCCESS, branch);
                resolve(branch);
            })
            .catch(error => {
                reject({
                    nameExists: error?.response?.data?.name_exist || false,
                    codeExists: error?.response?.data?.code_exist || false
                })
                EventBus.dispatch(ADD_BRANCH_FAILED, error);
            });
    });
}

export const loadBranchUsesAsync = () => dispatch => {
    branchService.getBranchUse()
        .then(response => {
            dispatch(branchSlice.actions.updateBranchUses(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const loadBranchAsync = (id) => {
    return new Promise((resolve, reject) => {
        branchService.getBranch(id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    })
}

export const editBranchAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(EDIT_BRANCH_REQUESTED, payload);

        branchService.edit(id, payload)
            .then((response) => {
                EventBus.dispatch(EDIT_BRANCH_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch(error => {
                reject({
                    nameExists: error?.response?.data?.name_exists || false,
                    codeExists: error?.response?.data?.code_exists || false
                })
                EventBus.dispatch(EDIT_BRANCH_FAILED, error);
            })
    })
}

export const deleteBranchAsync = ({id}) => {
    EventBus.dispatch(DELETE_BRANCH_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        branchService.deleteBranch({id})
            .then((response) => {
                EventBus.dispatch(DELETE_BRANCH_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_BRANCH_FAILED, error.message);
                reject(error)
            })
    });
};

export const bindBranchWarehouseAsync = (data) => {
   return new Promise((resolve, reject) => {
       branchService.bindWarehouse(data)
           .then((response) => {
               EventBus.dispatch(BIND_WAREHOUSE_SUCCESS, response.data)
               resolve(response.data)
           })
           .catch((error) => {
               EventBus.dispatch(BIND_WAREHOUSE_FAILED, error.message)
               reject(error.message)
           })
   })
}

export const updateBranchRoamingAddressAsync = (branchId, payload) => {
    EventBus.dispatch(UPDATE_BRANCH_ROAMING_ADDRESS_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        branchService.updateRoamingAddress(branchId, payload)
            .then((response) => {
                EventBus.dispatch(UPDATE_BRANCH_ROAMING_ADDRESS_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(UPDATE_BRANCH_ROAMING_ADDRESS_FAILED, error);
                reject(error);
            })
    })
}

export const deleteBranchRoamingAddressAsync = (branchId) => {
    EventBus.dispatch(DELETE_BRANCH_ROAMING_ADDRESS_REQUESTED, branchId);

    return new Promise((resolve, reject) => {
        branchService.deleteRoamingAddress(branchId)
            .then((response) => {
                EventBus.dispatch(DELETE_BRANCH_ROAMING_ADDRESS_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_BRANCH_ROAMING_ADDRESS_FAILED, error);
                reject(error);
            })
    })
}

// Account

export const getBranchAccountsAsync = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        branchService.getAccounts()
            .then(res => {
                resolve(res.data)
                dispatch(branchSlice.actions.updateAccounts(res.data))
            })
            .catch(e => console.log(e))
    })
}

export const getBranchAccount = (id) => {
    return new Promise((resolve, reject) => {
        branchService.getAccount(id)
            .then(res => {
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

export const branchAccountBindingEnable = (params) => {
    return new Promise((resolve, reject) => {
        return new Promise(resolve => {
            branchService.accountBindingEnable(params)
                .then(res => {
                    EventBus.dispatch(BIND_ACCOUNT_ENABLE)
                    resolve(res.data)
                })
                .catch(e => console.log(e))
        })
    })
}

export const branchAccountBindingDisable = (params) => {
    return new Promise((resolve, reject) => {
        branchService.accountBindingDisable(params)
            .then(res => {
                EventBus.dispatch(BIND_ACCOUNT_DISABLE)
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

export const branchAccountBindingRegister = (params) => {
    return new Promise(resolve => {
        branchService.accountBindingRegister(params)
            .then(res => {
                EventBus.dispatch(BIND_ACCOUNT_REGISTER)
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

export const branchAccountBindingUnregister = (params) => {
    return new Promise(resolve => {
        branchService.accountBindingUnregister(params)
            .then(res => {
                EventBus.dispatch(BIND_ACCOUNT_UNREGISTER)
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

// settings

export const updateBranchDefaultSetting = (payload) => {
    return new Promise((resolve, reject) => {
        settingsService.updateBranchSettings(payload)
            .then((res) => resolve(res.data))
            .catch(() => reject())
    })
}

export const selectBranches = state => state.branch.branches;

export const selectBranchUses = (state) => state.branch.branchUses

export const selectBranchDataTable = (state) => state.branch.branchesDataTableSettings
export const selectAccountBranch = (state) => state.branch.accounts

export const {loadBranchDataTableColumn} = branchSlice.actions
export const {changeBranchDataTableColumn} = branchSlice.actions

export default branchSlice.reducer;
