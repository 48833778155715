import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_ACT_ACCEPT_FAILED,
    ROAMING_ACT_ACCEPT_REQUESTED,
    ROAMING_ACT_ACCEPT_SUCCESS,
    ROAMING_ACT_CANCEL_FAILED,
    ROAMING_ACT_CANCEL_REQUESTED,
    ROAMING_ACT_CANCEL_SUCCESS,
    ROAMING_ACT_DECLINE_FAILED,
    ROAMING_ACT_DECLINE_REQUESTED,
    ROAMING_ACT_DECLINE_SUCCESS,
    ROAMING_ACT_DRAFT_FAILED,
    ROAMING_ACT_DRAFT_REQUESTED,
    ROAMING_ACT_DRAFT_SUCCESS,
    ROAMING_ACT_REGISTER_FAILED,
    ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS,
    ROAMING_ACT_SYNC_FAILED,
    ROAMING_ACT_SYNC_REQUESTED,
    ROAMING_ACT_SYNC_SUCCESS
} from "../../../eventbus/roaming/roamingActEvents";
import RoamingService from "../../../../services/roaming/roamingService";
import roamingService from "../../../../services/roaming/roamingService";
import {VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX} from "../../../../components/hippo/roaming/act/View/TypeView";
import {attachPkcs7WithTimestamp, createPkcs7WithTimestamp} from "../eimzo/eimzoReducer";
import documentGeneratorService from "../../../../services/documentGenerator/documentGeneratorService";
import {Translate} from "../main/mainReducer";

export const roamingActSlice = createSlice({
    name: "roamingAct",
    initialState: {
        acts: [],
        filterOptions: {page: 1, limit: 10},
        count: 0,
        viewPage: {
            id: null,
            draftId: null,
            showModal: false
        }
    },
    reducers: {
        setShowModal: (state, action) => {
            if (!action.payload) {
                state.viewPage = {id: null, draftId: null, showModal: false}
            } else {
                state.viewPage.showModal = action.payload
            }
        },
        setId: (state, {payload}) => {
            const {draftId, id} = payload
            if (draftId) {
                state.viewPage.draftId = draftId
            } else if (id) {
                state.viewPage.id = id
            }
        },
        updateRoamingAct: (state, action) => {
            state.acts = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateRoamingActsCount: (state, action) => {
            state.count = action.payload
        },
    }
})

export const selectRoamingActs = state => state.roamingActSlice.acts;
export const selectActsFilterOptions = state => state.roamingActSlice.filterOptions;
export const selectCount = state => state.roamingActSlice.count;
export const viewPage = state => state.roamingActSlice.viewPage
const t = Translate;

export const ActFormSendAsync = async (certificate, lang, hashCode) => {
    try {
        EventBus.dispatch(ROAMING_ACT_REGISTER_REQUESTED, hashCode);
        const {pkcs7: signature} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const {data} = await RoamingService.registerActFormSend({signature: signature, lang: lang});
        EventBus.dispatch(ROAMING_ACT_REGISTER_SUCCESS, data)
    } catch (error) {
        let errorMessage;
        const errorResponse = error?.response?.data;
        if (errorResponse.content) {
            const parsedError = JSON.parse(errorResponse.content);
            errorMessage = {message: parsedError?.ErrorMessage}
        } else if (errorResponse?.not_allowed_inn) {
            errorMessage = {error: error, message: t(lang, 'roaming.waybill.error.message.not_allowed_inn')}
        } else if (errorResponse?.content_parse_failed) {
            errorMessage = {error: error, message: t(lang, 'roaming.waybill.error.message.content_parse_failed')}
        } else if (errorResponse.roaming_error_message) {
            errorMessage = {
                error: error,
                message: JSON.parse(errorResponse.roaming_error_message)?.ErrorMessage || errorResponse.roaming_error_message
            }
        } else {
            errorMessage = {message: t(lang, 'roaming.waybill.error.message.default_error')}
        }
        EventBus.dispatch(ROAMING_ACT_REGISTER_FAILED, errorMessage);
        throw errorMessage
    }
}


export const ActFormSendDraft = async (data) => {
    EventBus.dispatch(ROAMING_ACT_DRAFT_REQUESTED);

    return new Promise((resolve, reject) => {
        RoamingService.actFormSendDraft(data)
            .then(response => {
                EventBus.dispatch(ROAMING_ACT_DRAFT_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch(errors => {
                EventBus.dispatch(ROAMING_ACT_DRAFT_FAILED, errors)
                reject(errors)
            })
    })
}

export const loadActDraftAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingService.getActDraft(id)
            .then(response => resolve(response))
            .catch(errors => reject(errors))
    })
}

export const loadActAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingService.getAct(id)
            .then(response => resolve(response))
            .catch(errors => reject(errors))
    })
}

export const loadRoamingActAsync = ({type_viewer, page, limit, ...filters}) => async (dispatch) => {
    try {
        const skip = (page - 1) * limit
        const params = {skip, limit, ...filters}
        let acts = []
        if (type_viewer === VIEW_INBOX) {
            acts = (await roamingService.getCustomerAct(params)).data
        } else if (type_viewer === VIEW_OUTBOX) {
            acts = (await roamingService.getExecutorAct(params)).data
        } else if (type_viewer === VIEW_DRAFT) {
            acts = (await roamingService.getExecutorActDraft(params)).data
        }
        dispatch(roamingActSlice.actions.updateRoamingAct(acts))
    } catch (error) {
        console.log(error)
    }
}

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(roamingActSlice.actions.updateFilterOptions({...params}))
}

export const loadRoamingActsCountAsync = ({type_viewer, page, limit, ...filters}) => async (dispatch) => {
    try {
        let count
        if (type_viewer === VIEW_INBOX) {
            count = (await roamingService.getCustomerActCount(filters)).data
        } else if (type_viewer === VIEW_OUTBOX) {
            count = (await roamingService.getExecutorActCount(filters)).data
        } else if (type_viewer === VIEW_DRAFT) {
            count = (await roamingService.getExecutorActDraftCount(filters)).data
        }
        dispatch(roamingActSlice.actions.updateRoamingActsCount(count))
    } catch (error) {
        console.log(error)
    }
}

export const ActCustomerAcceptHashCodeAsync = async (certificate, lang, act) => {
    try {
        const {data: hashCode} = await roamingService.getActAcceptHashCode(act.id)

        EventBus.dispatch(ROAMING_ACT_ACCEPT_REQUESTED, act.id);
        const {pkcs7: signature} = await attachPkcs7WithTimestamp(hashCode, certificate.keyId);
        await roamingService.ActCustomerAccept(act.id, signature).then(response => EventBus.dispatch(ROAMING_ACT_ACCEPT_SUCCESS, response.data))
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_ACCEPT_FAILED, error);
    }
}

export const ActCustomerDeclineHashCodeAsync = async (certificate, lang, act, comment) => {
    try {
        const {data: hashCode} = await roamingService.getActDeclineHashCode(act.id, comment)

        EventBus.dispatch(ROAMING_ACT_DECLINE_REQUESTED, act.id);
        const {pkcs7: signature} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        await roamingService.ActCustomerDecline(act.id, signature, comment).then(response => EventBus.dispatch(ROAMING_ACT_DECLINE_SUCCESS, response.data))
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_DECLINE_FAILED, error);
    }
}

export const ActCancelHashCodeAsync = async (certificate, lang, act) => {
    try {
        const {data: hashCode} = await roamingService.getActCancelHashCode(act.id)

        EventBus.dispatch(ROAMING_ACT_CANCEL_REQUESTED, act.id);
        const {pkcs7: signature} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        await roamingService.ActCancel(act.id, signature).then(response => EventBus.dispatch(ROAMING_ACT_CANCEL_SUCCESS, response.data))
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_CANCEL_FAILED, error);
    }
}

export const syncActAsync = (id) => {
    EventBus.dispatch(ROAMING_ACT_SYNC_REQUESTED, id);
    return new Promise((resolve, reject) => {
        roamingService.syncAct(id)
            .then((response) => {
                EventBus.dispatch(ROAMING_ACT_SYNC_SUCCESS, id);
                resolve(response.data)
            })
            .catch((error) => {
                let errorMessage = {message: error.response.data}
                EventBus.dispatch(ROAMING_ACT_SYNC_FAILED, errorMessage)
                reject(error)
            })
    })
}

export const generateRoamingActSendHashCode = async (payload) => {
    return new Promise((resolve, reject) => {
        documentGeneratorService.generateRoamingActSendHashCode(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}

export const deleteActDraftAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingService.deleteActDraft(id)
            .then(response => resolve(response.data))
            .catch(errors => reject(errors))
    })
}

export default roamingActSlice.reducer
export const {setId, setShowModal} = roamingActSlice.actions
