import React from 'react';
import {Link} from "react-router-dom";
import {Alert} from "react-bootstrap";
import {useSelector} from "react-redux";
import IconAlert from "../common/IconAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {
    EDI_SERVICE_CODE,
    POS_SERVICE_CODE,
    WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from "../../app/store/reducers/billing/billingReducer";

const ActivateServicePackageAlert = ({serviceCode}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <IconAlert variant="warning">
                <Alert.Heading className="mb-2 mb-sm-0">{t(lang, 'edi.alert.activate_edi_service_package.title')}</Alert.Heading>
                {serviceCode === EDI_SERVICE_CODE &&
                    <p className="mb-0 fw-medium">
                        {t(lang, 'edi.alert.activate_edi_service_package.description')}
                        <Alert.Link as={Link} className="ms-2 fw-bold text-primary" to={'/user/billing'}>
                            {t(lang, 'edi.alert.activate_edi_service_package.description.link_to_all_billing_packages')}
                            <FontAwesomeIcon className='ms-1' icon={faArrowUpRightFromSquare}/>
                        </Alert.Link>
                    </p>
                }
                {serviceCode === WAREHOUSE_MANAGEMENT_SERVICE_CODE &&
                    <p className="mb-0 fw-medium">
                        {t(lang, 'warehouse.alert.activate_warehouse_service_package.description')}
                        <Alert.Link as={Link} className="ms-2 fw-bold text-primary" to={'/user/billing'}>
                            {t(lang, 'warehouse.alert.activate_warehouse_service_package.description.link_to_all_billing_packages')}
                            <FontAwesomeIcon className='ms-1' icon={faArrowUpRightFromSquare}/>
                        </Alert.Link>
                    </p>
                }
                {serviceCode === POS_SERVICE_CODE &&
                    <p className="mb-0 fw-medium">
                        {t(lang, 'pos.alert.activate_pos_service_package.description')}
                        <Alert.Link as={Link} className="ms-2 fw-bold text-primary" to={'/user/billing'}>
                            {t(lang, 'pos.alert.activate_pos_service_package.description.link_to_all_billing_packages')}
                            <FontAwesomeIcon className='ms-1' icon={faArrowUpRightFromSquare}/>
                        </Alert.Link>
                    </p>
                }
            </IconAlert>
        </>
    );
};

export default ActivateServicePackageAlert;
