import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {ItemsPriceAccessTypes} from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import ReactSelect from "react-select";

const SelectItemsPriceAccessType = ({onChange, defaultItemsPriceAccessType}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const options = ItemsPriceAccessTypes;

    return (
        <ReactSelect options={options}
                     isSearchable={false}
                     placeholder={t(lang, 'items.common.select')}
                     getOptionLabel={option => t(lang, option.label)}
                     getOptionValue={(option) => option.value}
                     onChange={(option) => onChange(option.value)}
                     value={options.find(o => o.value === defaultItemsPriceAccessType) || null}
        />
    );
};

export default SelectItemsPriceAccessType;