import React, {Fragment,  useState} from 'react';
import IconButton from "../../common/IconButton";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import PropTypes from "prop-types";
import {selectMembers} from "../../../app/store/reducers/kanban/kanbanReducer";
import {Form} from "react-bootstrap";
import SelectMember from "../../common/SelectMember";
import {Controller, FormProvider, useForm} from "react-hook-form";

const ConfirmationModal = ({
                               title,
                               confirmationAction,
                               loading: loadingContent,
                               titleLabel,
                               update,
                               register,
                               comment,
                               hideModalBody,
                               commentId,
                               ...props
                           }) => {

    const defaultReporterId = JSON.parse(localStorage.getItem('reporterId'))

    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const methods = useForm({
        defaultValues: {
            reporter_id: defaultReporterId || null,
            assigner_id: null,
            remember_reporter_id: [].some(i => i.id === defaultReporterId) || false
        }
    })

    const reporter_id = methods.watch('reporter_id')
    const remember_reporter_id = methods.watch('remember_reporter_id')

    const rememberReporter = (e) => {
        if (reporter_id) {
            methods.setValue('remember_reporter_id', e)
            if (e) {
                localStorage.setItem('reporterId', reporter_id)
            } else {
                localStorage.removeItem('reporterId')
            }
        }
    }

    const handleClose = () => {
        !remember_reporter_id && methods.setValue('reporter_id', null)
        methods.setValue('assigner_id', null)
        setShow(false)
    };
    const handleShow = () => {
        if (comment || comment === undefined) {
            setShow(true)
        }
    };

    const onSubmit = ({reporter_id, assigner_id}) => {
        setLoading(true)
        confirmationAction(reporter_id, assigner_id)
            .then(() => {
                setShow(false)
                methods.setValue('assigner_id', null)
                !remember_reporter_id && methods.setValue('reporter_id', null)
            })
            .catch((e) => new Error(e))
            .finally(() => {
                !update && setShow(false)
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                size="sm"
                disabled={loadingContent}
                iconClassName={'fs-0'}
                transform="shrink-3"
                {...props}
            >
                {loadingContent && <Spinner animation="border" role="switch" size="sm"/>}
                {register && <span className={'fs--1 font-weight-normal'}>{t(lang, register)}</span>}
            </IconButton>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{t(lang, title)}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                {!hideModalBody && <Modal.Body>
                    <FormProvider {...methods}>
                        <Form id={'confirmation-form'} onSubmit={methods.handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>{t(lang, "Автор")}</Form.Label>
                                <Controller name={'reporter_id'} rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }} render={({field}) => (

                                    <SelectMember
                                        value={field.value}
                                        onChange={field.onChange}
                                        classNamePrefix={'react-select'}
                                        placeholder={t(lang, "task.board.common.select.placeholder")}
                                        hideSelectedOptions
                                        isClearable
                                    />
                                )}/>
                                <Form.Control.Feedback type={'invalid'}
                                                       className={'d-block'}>{methods.formState?.errors?.reporter_id?.message}</Form.Control.Feedback>
                            </Form.Group>
                            {
                                update && <Form.Group>
                                    <Form.Label>{t(lang, "task.board.modal.assigner")}</Form.Label>
                                    <Controller name={'assigner_id'} rules={{
                                        required: t(lang, "task.board.common.forms.validations.is_required")
                                    }} render={({field}) => (
                                        <SelectMember
                                            value={field.value}
                                            onChange={field.onChange}
                                            classNamePrefix={'react-select'}
                                            placeholder={t(lang, "task.board.common.select.placeholder")}
                                            hideSelectedOptions
                                            isClearable
                                        />
                                    )}/>
                                    <Form.Control.Feedback type={'invalid'}
                                                           className={'d-block'}>{methods.formState?.errors?.assigner_id?.message}</Form.Control.Feedback>
                                </Form.Group>
                            }

                            <Form.Group>
                                <Form.Check
                                    {...methods.register('remember_reporter_id')}
                                    checked={remember_reporter_id}
                                    type='checkbox'
                                    id='defaultCheckbox'
                                    label={t(lang, 'task.board.modal.remember.reporter')}
                                    className={'mt-2'}
                                    onChange={(e) => rememberReporter(e.target.checked)}
                                />
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>}
                <Modal.Footer>
                    <Button variant="falcon-danger" onClick={handleClose}>{t(lang, 'task.board.common.cancel')}</Button>
                    <Button className={'d-flex align-items-center gap-1'} form={'confirmation-form'} disabled={loading} onClick={hideModalBody ? onSubmit : () => null}
                            type={hideModalBody ? 'button' : 'submit'} variant="falcon-primary">
                        {
                            loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                        {t(lang, title)}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

ConfirmationModal.propTypes = {
    title: PropTypes.string.isRequired,
    confirmationAction: PropTypes.func.isRequired,
    titleLabel: PropTypes.string.isRequired,
    update: PropTypes.bool,
    register: PropTypes.string,
    loading: PropTypes.bool,
    comment: PropTypes.string,
    hideRequester: PropTypes.bool
}

export default ConfirmationModal;
