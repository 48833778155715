import React from 'react';
import {Card, Col, Table} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../app/store/reducers/main/mainReducer";
import {totalAmountCardInCashBox, totalAmountCashInCashBox} from "../../../enum/cash_box/cashBoxWrapper";
import numeral from "numeral";
import {
    PaymentTypeBankTransferCode,
    PaymentTypeBankTransferText,
    PaymentTypeCashText, PaymentTypeClickCode,
    PaymentTypeClickText,
    PaymentTypeHumoCode,
    PaymentTypeHumoText, PaymentTypePaymeCode,
    PaymentTypePaymeText,
    PaymentTypeUzCardCode,
    PaymentTypeUzCardText, PaymentTypeVisaCode,
    PaymentTypeVisaText
} from "../../../enum/ContractorWrapper";

const CashBoxDataTable = ({data}) => {
    const lang = useSelector(selectLang)
    const t = Translate
    const numberFormat = useSelector(selectNumberFormat);


    return (
        <div className="table-responsive fs--1" style={{maxHeight: "430px"}}>
            <Table className="border-bottom">
                <thead style={{position: "sticky", top: '-1px'}} className="table-primary">
                <tr>
                    <th className="align-middle text-center">{t(lang, PaymentTypeCashText)}</th>
                    <th className="align-middle text-center">{t(lang, PaymentTypeUzCardText)}</th>
                    <th className="align-middle text-center">{t(lang, PaymentTypeHumoText)}</th>
                    <th className="align-middle text-center">{t(lang, PaymentTypeBankTransferText)}</th>
                    <th className="align-middle text-center">{t(lang, PaymentTypeClickText)}</th>
                    <th className="align-middle text-center">{t(lang, PaymentTypePaymeText)}</th>
                    <th className="align-middle text-center">{t(lang, PaymentTypeVisaText)}</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={'border-end border-2'}>
                            {totalAmountCashInCashBox(data)
                                .map((item, index) => (
                                        <div key={index} className='d-block text-end fs-0 fw-bold'>
                                            <strong className={'text-700'}>
                                                {numeral['formats'][numberFormat].format(item?.total)}
                                            </strong>
                                            <strong className={'ms-2 fw-bold text-info'}>{item?.name}</strong>
                                        </div>
                                    )
                                )}
                        </td>
                        <td className={'border-end border-2'}>
                            {totalAmountCardInCashBox(data, PaymentTypeUzCardCode)
                                .map((item, index) => (
                                        <div key={index} className='d-block text-end fs-0 fw-bold'>
                                            <strong className={'text-700'}>
                                                {numeral['formats'][numberFormat].format(item?.total)}
                                            </strong>
                                            <strong className={'ms-2 fw-bold text-info'}>{item?.name}</strong>
                                        </div>
                                    )
                                )}
                        </td>
                        <td className={'border-end border-2'}>
                            {totalAmountCardInCashBox(data, PaymentTypeHumoCode)
                                .map((item, index) => (
                                        <div key={index} className='d-block text-end fs-0 fw-bold'>
                                            <strong className={'text-700'}>
                                                {numeral['formats'][numberFormat].format(item?.total)}
                                            </strong>
                                            <strong className={'ms-2 fw-bold text-info'}>{item?.name}</strong>
                                        </div>
                                    )
                                )}
                        </td>
                        <td className={'border-end border-2'}>
                            {totalAmountCardInCashBox(data, PaymentTypeBankTransferCode)
                                .map((item, index) => (
                                        <div key={index} className='d-block text-end fs-0 fw-bold'>
                                            <strong className={'text-700'}>
                                                {numeral['formats'][numberFormat].format(item?.total)}
                                            </strong>
                                            <strong className={'ms-2 fw-bold text-info'}>{item?.name}</strong>
                                        </div>
                                    )
                                )}
                        </td>
                        <td className={'border-end border-2'}>
                            {totalAmountCardInCashBox(data, PaymentTypeClickCode)
                                .map((item, index) => (
                                        <div key={index} className='d-block text-end fs-0 fw-bold'>
                                            <strong className={'text-700'}>
                                                {numeral['formats'][numberFormat].format(item?.total)}
                                            </strong>
                                            <strong className={'ms-2 fw-bold text-info'}>{item?.name}</strong>
                                        </div>
                                    )
                                )}
                        </td>
                        <td className={'border-end border-2'}>
                            {totalAmountCardInCashBox(data, PaymentTypePaymeCode)
                                .map((item, index) => (
                                        <div key={index} className='d-block text-end fs-0 fw-bold'>
                                            <strong className={'text-700'}>
                                                {numeral['formats'][numberFormat].format(item?.total)}
                                            </strong>
                                            <strong className={'ms-2 fw-bold text-info'}>{item?.name}</strong>
                                        </div>
                                    )
                                )}
                        </td>
                        <td className={'border-end border-2'}>
                            {totalAmountCardInCashBox(data, PaymentTypeVisaCode)
                                .map((item, index) => (
                                        <div key={index} className='d-block text-end fs-0 fw-bold'>
                                            <strong className={'text-700'}>
                                                {numeral['formats'][numberFormat].format(item?.total)}
                                            </strong>
                                            <strong className={'ms-2 fw-bold text-info'}>{item?.name}</strong>
                                        </div>
                                    )
                                )}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default CashBoxDataTable;
