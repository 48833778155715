export const Piece = "0";
export const Kilogram = "1";
export const Meter = "2";
export const orderMeasurementOptions = [
    {
        label: 'crm.common.measurement_options.0',
        value: Piece,
    },
    {
        label: 'crm.common.measurement_options.1',
        value: Kilogram
    },
    {
        label: 'items.common.measurement_options.2',
        value: Meter
    }
];


export const filterContractorsOption = [
    {
        label: 'crm.data.table.filter.contractor.select.all',
        value: null
    },
    {
        label: 'crm.data.table.filter.contractor.select.customer',
        value: 'customer'

    },
    {
        label: 'crm.data.table.filter.contractor.select.executor',
        value: 'executor'
    },
]



export const setVehiclesInfoLocalStorage = (localStorageOptions, vehicleInto) => {
    localStorageOptions.filter(driver => {
        const duplicateIndex = vehicleInto?.filter(d => d?.label || d?.is_active).findIndex(d => d.label === driver.label);
        if (duplicateIndex === -1) {
            vehicleInto.push({
                label: driver.label,
                is_active: driver.lastSelected
            });
            return true;
        }
        return false;
    });

    return  vehicleInto
}