import React from 'react';
import IconButton from "../../../common/IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import withAddCategory from "./withAddCategory";

const AddCategoryButton = ({handleShow, buttonProps, children}) => {
    return (
        <IconButton icon={faPlus}
                    variant="falcon-primary"
                    onClick={handleShow}
                    {...buttonProps}
        >
            {children}
        </IconButton>
    );
};

export default withAddCategory(AddCategoryButton);