import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
   editTaskInColumn,
    selectModalContent,
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import ModalForm from "./ModalForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ITEM_DELETE_COMMENT_FAIL,
    ITEM_DELETE_COMMENT_SUCCESS,
    ITEM_REGISTER_COMMENT_FAIL,
    ITEM_REGISTER_COMMENT_SUCCESS, ITEM_UPDATE_COMMENT_FAIL,
    ITEM_UPDATE_COMMENT_SUCCESS
} from "../../../../app/eventbus/kanban/KanbanEvents";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {useParams} from "react-router";
import dayjs from "dayjs";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const EditFormTask = ({setShow, handlerClose, boardColumnTaskId, boardColumnId, boardId}) => {
    const {id} = useParams();

    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const modalContent = useSelector(selectModalContent)
    const [loading, setLoading] = useState(false);

    const defaultCreator = +localStorage.getItem('defaultCreator');

    useEffect(() => {

        // comment success
        const onItemRegisterCommentHandlerSuccess = EventBus.on(ITEM_REGISTER_COMMENT_SUCCESS, () => {
            toast.success(t(lang,"common.toast.success"))
        })
        const onItemUpdateCommentHandlerSuccess = EventBus.on(ITEM_UPDATE_COMMENT_SUCCESS, () => {
            toast.success(t(lang,"common.toast.success"))
        })
        const onItemDeleteCommentHandlerSuccess = EventBus.on(ITEM_DELETE_COMMENT_SUCCESS, () => {
            toast.success(t(lang,"common.toast.success"))
        })


        // comment fail
        const onItemRegisterCommentHandlerFailed = EventBus.on(ITEM_REGISTER_COMMENT_FAIL, () => {
            toast.error("Error")
        })
        const onItemUpdateCommentHandlerFailed = EventBus.on(ITEM_UPDATE_COMMENT_FAIL, () => {
            toast.error("Error")
        })
        const onItemDeleteCommentHandlerFailed = EventBus.on(ITEM_DELETE_COMMENT_FAIL, () => {
            toast.error("Error")
        })

        return () => {
            // comment
            EventBus.remove(ITEM_REGISTER_COMMENT_SUCCESS, onItemRegisterCommentHandlerSuccess)
            EventBus.remove(ITEM_REGISTER_COMMENT_FAIL, onItemRegisterCommentHandlerFailed)

            EventBus.remove(ITEM_UPDATE_COMMENT_SUCCESS, onItemUpdateCommentHandlerSuccess)
            EventBus.remove(ITEM_UPDATE_COMMENT_FAIL, onItemUpdateCommentHandlerFailed)

            EventBus.remove(ITEM_DELETE_COMMENT_SUCCESS, onItemDeleteCommentHandlerSuccess)
            EventBus.remove(ITEM_DELETE_COMMENT_FAIL, onItemDeleteCommentHandlerFailed)
        }

    }, [])

    const methods = useForm({
        defaultValues: {
            title: modalContent?.title,
            description: modalContent?.description,
            assignee_id: modalContent?.assignee_id || null,
            creator_id: modalContent?.creator_id || defaultCreator || null,
            contractor: {
                id: modalContent?.contractor?.id || null,
                debt_promise_date: modalContent?.contractor?.debt_promise_date ? new Date(modalContent?.contractor?.debt_promise_date) : null
            },
            comments: modalContent?.comments?.map(i => {
                return {...i, comment_id: i.id}
            })
        }
    })

    const onSubmit = (formData) => {

        // if (!formData.creator_id) return methods.setError('creator_id', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})
        if (formData.contractor.debt_promise_date && !formData.contractor?.id) return methods.setError('contractor.id', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})
        if (formData.contractor.id && !formData.contractor?.debt_promise_date) return methods.setError('contractor.debt_promise_date', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})

        setLoading(true)

        const data = {
            board_column_id: modalContent?.board_column_id,
            title: formData.title,
            description: formData.description,
            // creator_id: formData.creator_id?.id || formData.creator_id,
            assignee_id: formData.assignee_id?.id || formData.assignee_id,
            contractor: formData.contractor?.id ? {
                id: formData.contractor?.id || null,
                debt_promise_date: dayjs(formData.contractor?.debt_promise_date).format('YYYY-MM-DD HH:mm:ss') || null
            } : null,
        }
        dispatch(editTaskInColumn(id, data))
            .then(() => setShow(false))
            .catch(e => new Error(e))
            .finally(() => setLoading(false))
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <ModalForm
                    loading={loading}
                    edit
                    handlerClose={handlerClose}
                    boardId={boardId}
                />
            </Form>
        </FormProvider>
    );
};

EditFormTask.propTypes = {
    setShow: PropTypes.func
}
export default EditFormTask;
