import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import {Link} from "react-router-dom";
import IconButton from "../../../common/IconButton";
import {
    revisionApproveAsync,
} from "../../../../app/store/reducers/warehouse-operation/revisionReducer";
import {
    isApprovedCheckedWarehouseOperation,
} from "../enum/warehouseOperationWrapper";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import RevisionDatatableTab from "./components/RevisionDatatableTab";
import UploadRevisionToExcel from "./excel/UploadRevisionToExcel";
import RevisionPrint from "./components/RevisionPrint";
import RevisionDownloadPdf from "./components/RevisionDownloadPdf";


const Revision = ({revision}) => {
    const [activeTab, setActiveTab] = useState("all")
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate



    useEffect(() => {
        if (revision?.items?.filter(f => !f?.is_deleted).length > 0) {
            setActiveTab("all")
        } else {
            setActiveTab("deleted")
        }
    },[currencies])

    // approve
    const onApprovedHandler = async () => {
        await revisionApproveAsync({revisionId: revision?.id})
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }


    return(
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}><h3 className="text-600">{t(lang, "warehouse.operation.item.common.time_revision")} {revision?.date}</h3></Col>
                        <Col xs={6} className="text-end">
                            <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                            <SoftBadge bg={revision?.is_approved ? 'success' : 'warning'}>
                                {t(lang, isApprovedCheckedWarehouseOperation(revision?.is_approved))}
                            </SoftBadge>
                        </Col>
                        <Col xs={12}>
                            {revision?.items.length > 0 && !revision?.is_approved &&
                                <IconButton
                                    variant="falcon-primary"
                                    icon="layer-group"
                                    size="sm"
                                    onClick={() => onApprovedHandler()}
                                >{t(lang, "warehouse.operation.item.common.status.approve")}</IconButton>
                            }
                            <Link to={`../update/${revision?.id}`}>
                                <IconButton
                                    variant="falcon-warning"
                                    icon="edit"
                                    title={t(lang,"warehouse.operation.item.common.edit")}
                                    className="ms-3"
                                    size="sm"
                                >{t(lang,"warehouse.operation.item.common.edit")}</IconButton>
                            </Link>
                            <Link to={`../update-excel/${revision?.id}`}>
                                <IconButton
                                    variant="falcon-success"
                                    icon="edit"
                                    title={t(lang,"warehouse.operation.item.common.edit")}
                                    className="mx-2"
                                    size="sm"
                                >{t(lang,"Редактировать с Excel")}</IconButton>
                            </Link>
                            <UploadRevisionToExcel className="me-2 d-inline-block" size="sm" id={revision?.id} number={revision?.number} />
                            <Link to={`../register`}>
                                <IconButton
                                    variant="falcon-primary"
                                    icon="plus"
                                    title={t(lang,"Новая ревизия")}
                                    className="ms-2"
                                    size="sm"
                                >{t(lang,"Новая ревизия")}</IconButton>
                            </Link>
                            <RevisionPrint
                                revisionId={revision?.id}
                            />
                            <RevisionDownloadPdf
                                revisionId={revision?.id}
                                number={revision?.number}
                            />
                        </Col>
                    </Row>
                </Card.Header>
            </Card>


            <Row>
                <Col xs={12} className="mt-3">
                    <Card>
                        <Card.Body>
                            <h4 className="text-info mb-2">{t(lang, "warehouse.operation.item.common.revision")}</h4>

                            {revision?.note &&
                                <Col xs={12}>
                                    <p className="fw-bold m-0">Описание: <span className="fw-medium">{revision?.note}</span> </p>
                                </Col>
                            }
                            <Tabs activeKey={activeTab} id="controlled-tab-example" className="d-flex justify-content-center fs-1" onSelect={(tab) => {
                                setActiveTab(tab)
                            }}>
                                {revision?.items?.filter(f => !f?.is_deleted).length > 0 &&
                                    <Tab eventKey="all" tabClassName="fs-1" title={t(lang, 'Списки')}>
                                        {activeTab === 'all' &&
                                            <RevisionDatatableTab
                                                dataItems={revision?.items?.filter(f => !f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                                {revision?.items?.filter(f => f.is_deleted).length > 0 &&
                                    <Tab eventKey="deleted" tabClassName="fs-1" title={t(lang, 'Удалённые из списка')}>
                                        {activeTab === 'deleted' &&
                                            <RevisionDatatableTab
                                                dataItems={revision?.items?.filter(f => f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default Revision;