import React from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {Controller} from "react-hook-form";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const ChargeFilter = () => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat)

    return (
        <div>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "crm.data.table.filter.h1")}</h4>
            </Row>

            <Row>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.date_start")}</Form.Label>
                    <Controller
                        name="created_on_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.date_end")}</Form.Label>
                    <Controller
                        name="created_on_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} className="d-flex align-items-end justify-content-end justify-content-sm-start">
                    <Button variant="primary" type='submit'>{t(lang, "admin.common.payments.datatable.navigation.filter.show_button")}</Button>
                </Form.Group>

            </Row>
        </div>
    );
};

export default ChargeFilter;
