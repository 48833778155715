import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Col, Modal, Row} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import SaleItemWarehouseByReportDataTable from "./SaleItemWarehouseByReportDataTable";
import SaleItemContractorByReportDataTable from "./SaleItemContractorByReportDataTable";

const SaleItemReportModal = ({saleItem, contractorsSaleItemReport}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <React.Fragment>
            <div onClick={handleShow}
                 className="fs--1 fw-semi-bold font-sans-serif text-black text-decoration-underline cursor-pointer text-800"
            >
                {saleItem.name}
            </div>
            {show &&
                <Modal show={true}
                       fullscreen={true}
                       centered={true}
                       onHide={handleClose}
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, "warehouse.operation.report.sale_item_report", {name: saleItem.name})}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-3">
                            <Col xs={12}>
                                <SaleItemWarehouseByReportDataTable warehouseItems={saleItem.warehouse_items}/>
                            </Col>
                            {(contractorsSaleItemReport && contractorsSaleItemReport) &&
                                <Col xs={12}>
                                    <SaleItemContractorByReportDataTable contractorReports={contractorsSaleItemReport}/>
                                </Col>
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="lg"
                                variant="secondary"
                                onClick={handleClose}
                        >
                            {t(lang, "main.common.back")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </React.Fragment>
    );
};

export default SaleItemReportModal;