import { useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { useFormContext, Controller } from "react-hook-form"
import { useSelector } from "react-redux"
import { Translate, selectLang } from "../../../../app/store/reducers/main/mainReducer"
import { loadBankInfo } from "../../../../app/store/reducers/roaming/roamingReducer"
import FindCompany from "../../organization/FindCompany"
import FindCompanySearchable from "../../organization/FindCompanySearchable";
import classNames from "classnames";

const ClientInfo = () => {
    const { register, setValue, formState: { errors: clientError }, control } = useFormContext()
    const [companyDetails, setCompanyDetails] = useState({})
    const [bankInfo, setBankInfo] = useState({})
    const errors = clientError.client || []
    const lang = useSelector(selectLang)
    const t = Translate

    const onCompanyChange = async company => {
        setCompanyDetails(company)
        setValue(`client.name`, company?.name)
        setValue(`client.fizTin`, (company?.director_pinfl || company?.director_inn) || company?.person_num)
        setValue(`client.fio`, company?.director_pinfl ? company?.director : company?.name)
        setValue(`client.address`, company?.address || "")
        setValue(`client.bankId`, company?.mfo || "")
        setValue(`client.account`, company?.account || "")

        if (company?.mfo)
            setBankInfo(await loadBankInfo(company.mfo, lang))
        else
            setBankInfo({})
    }
    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col><h5 className="mb-0">{t(lang, "roaming.contracts.add.clients_info.title")}</h5></Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    {companyDetails?.branch &&
                        <Col sm="12" className="mb-3">
                            <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.branch")}</Form.Label>
                            <Form.Control placeholder={t(lang, "roaming.contracts.add.clients_info.branch")} />
                        </Col>
                    }
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.name")}</Form.Label>
                        <Form.Control
                            {...register(`client.name`, {
                                // required: "edi.common.forms.validations.is_required"
                            })}
                            className={errors?.name && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.name")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.name?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}</Form.Label>
                        <Controller
                            name={`client.tin`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindCompanySearchable
                                    onChange={(company) => {
                                        field.onChange(company?.person_num || company?.inn)
                                        onCompanyChange(company)
                                    }}
                                    defaultInn={field.value}
                                    inputProps={{
                                        'is-invalid': errors?.tin,
                                        placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                    }}
                                    inputGroupProps={{
                                        className: classNames({
                                            'is-invalid': errors?.tin
                                        })
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.tin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.full_name_person")}</Form.Label>
                        <Form.Control
                            {...register(`client.fio`, {
                                required: "",
                            })}
                            className={errors?.fio && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.full_name_person")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.fio?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl_person")}</Form.Label>
                        <Form.Control
                            {...register(`client.fizTin`, {
                                required: "",
                            })}
                            className={errors?.fizTin && 'is-invalid'}
                            placeholder={t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl_person")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.fizTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.work_phone_number")}</Form.Label>
                        <Form.Control placeholder={t(lang, "roaming.contracts.add.clients_info.work_phone_number")} />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.mobile_number")}</Form.Label>
                        <Form.Control placeholder={t(lang, "roaming.contracts.add.clients_info.mobile_number")} />
                    </Col>
                    <Col sm="12" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.address")}</Form.Label>
                        <Form.Control {...register(`client.address`)} placeholder={t(lang, "roaming.contracts.add.clients_info.address")} />
                    </Col>
                    <Col sm="4" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.bank_account")}</Form.Label>
                        <Form.Control {...register(`client.account`)} placeholder={t(lang, "roaming.contracts.add.clients_info.bank_account")} />
                    </Col>
                    <Col sm="5" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.bank_address")}</Form.Label>
                        <Form.Control defaultValue={bankInfo?.name} placeholder={t(lang, "roaming.contracts.add.clients_info.bank_address")} />
                    </Col>
                    <Col sm="3" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.clients_info.mfo")}</Form.Label>
                        <Form.Control {...register(`client.bankId`)} placeholder={t(lang, "roaming.contracts.add.clients_info.mfo")} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ClientInfo
