import React, {Fragment, useEffect} from 'react';
import {Badge, Card, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat, selectLang, selectNumberFormat, Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {
    loadCrmInternalOrdersListAsync,
    internalOrdersList,
    selectFilterOptions,
    getCountInternalOrder,
    selectCount,
    changeInternalOrderFilterOptionsAsync
} from "../../../../../app/store/reducers/crm/internal/crmInternalOrderReducer";
import dayjs from "dayjs";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import IconButton from "../../../../common/IconButton";
import InternalOrderDeleteButton from "./InternalOrderDeleteButton";
import EventBus from "../../../../../app/eventbus/EventBus";
import {DELETE_CRM_INTERNAL_ORDER_SUCCESS} from "../../../../../app/eventbus/crmInternalOrderEvents";
import InternalOrderDataTableHeader from "./InternalOrderDataTableHeader";
import SoftBadge from "../../../../common/SoftBadge";
import numeral from "numeral";
import {sourceName, sourceVariant} from "../../../../../enum/ContractorWrapper";


const InternalOrderDataTable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const orders = useSelector(internalOrdersList)
    const count = useSelector(selectCount)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const filters = useSelector(selectFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const numberFormat = useSelector(selectNumberFormat);

    useEffect(() => {
        dispatch(loadCrmInternalOrdersListAsync({...filters}))
        dispatch(getCountInternalOrder())
    }, [filters, activeOrganization])


    useEffect(() => {
        const onDeleteOrderHandler = EventBus.on(DELETE_CRM_INTERNAL_ORDER_SUCCESS, () => {
            dispatch(loadCrmInternalOrdersListAsync({...filters}))
        });

        return () => {
            EventBus.remove(DELETE_CRM_INTERNAL_ORDER_SUCCESS, onDeleteOrderHandler)
        }
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, "crm.data.table.number"),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`/crm/order/${original.id}`}>
                        <strong>{original.info.number}</strong>
                    </Link>
                );
            }
        },
        {
            accessor: 'branch_from',
            Header: t(lang, "crm.internal.order.from.branch"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.branch_from?.name}</span>
                )
            }
        },
        {
            accessor: 'branch_to',
            Header: t(lang, "crm.internal.order.to.branch"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.branch_to?.name}</span>
                )
            }
        },
        {
            accessor: 'total.sum',
            id: "total-sum",
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            Header: t(lang, "edi.contractor_payment.datatable.payment_amount"),
            Cell: ({row: {original}}) => {
                let total = original.items.length && original.items.reduce((acc, item) => (item.price.amount * item.quantity) + acc, 0)
                return <>
                    <div className={'fs-0'}>
                        <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                            {numeral.formats[numberFormat].format(total)}
                        </SoftBadge>
                    </div>
                </>
            }
        },
        {
            accessor: 'source',
            id: "source",
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Header: t(lang, "crm.data.table.source"),
            Cell: ({row: {original}}) => {
                return <Badge bg={sourceVariant(original.source)}
                              className='me-2'>{t(lang, sourceName(original.source))}</Badge>
            }
        },
        {
            accessor: 'info.date',
            id: "date",
            Header: t(lang, "crm.data.table.date"),
            cellProps: {
                width: '100px',
            },
            Cell: rowData => {
                const {date} = rowData.row.original.info.date
                return <b>{dayjs(date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'none',
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original: {id}}}) => {
                return (
                    <>
                        <Link to={`/crm/internal/order/edit/${id}`}>
                            <IconButton
                                variant="falcon-warning"
                                icon="edit"
                                className="mx-2"
                                size="sm"
                            />
                        </Link>
                        <InternalOrderDeleteButton id={id}/>
                    </>
                )
            },

        }
    ].filter(i => i);

    const onPageChange = (page) => dispatch(changeInternalOrderFilterOptionsAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeInternalOrderFilterOptionsAsync({
        ...filters,
        limit: limit,
        page: 1
    }));

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={orders}
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <InternalOrderDataTableHeader/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onPageChange={onPageChange}
                            onLimitChange={onLimitChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default InternalOrderDataTable;
