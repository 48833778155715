import React from 'react';
import {Card} from 'react-bootstrap';
import FalconCardHeader from "../../../common/FalconCardHeader";
import PasswordChangeForm from "../../authentication/PasswordChangeForm";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ChangePassword = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Card className="mb-3 h-100">
            <FalconCardHeader title={t(lang, 'edi.user.settings.change_password.title')}/>
            <Card.Body className="bg-light">
                <PasswordChangeForm/>
            </Card.Body>
        </Card>
    );
};

export default ChangePassword;
