import dayjs from "dayjs";
import numeral from "numeral";
import {toast} from "react-toastify";
import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import IconButton from "../../../../../common/IconButton";
import {Badge, Card, Col, Row, Table} from "react-bootstrap";
import ApproveDraftPaymentModal from "../ApproveDraftPaymentModal";
import CommonDeleteModal from "../../../../../common/CommonDeleteModal";
import {GetPaymentTypeText} from "../../../../../../enum/ContractorWrapper";
import {selectLang, selectNumberFormat, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {deleteDraftPaymentAsync} from "../../../../../../app/store/reducers/crm/draft-payment/draftPaymentReducer";

const separationCurrenciesAndTotalSum = (state) => {
    let groupByCurrencyId = {};

    state.forEach(sum => {
        if (sum?.currency?.id in groupByCurrencyId) {
            groupByCurrencyId[sum?.currency?.id].totalSum += sum?.amount
        } else {
            if (sum?.currency?.id) {
                groupByCurrencyId[sum?.currency?.id] = {
                    id: sum?.currency?.id,
                    name: sum?.currency?.name,
                    totalSum: sum?.amount,
                }
            }
        }
    });
    return Object.values(groupByCurrencyId);
}

const DraftPaymentView = ({draftPayment}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const separationCurrencyCalcCashBoxStates = separationCurrenciesAndTotalSum(draftPayment?.cash_box_states);
    const separationCurrencyCalcDebtStates = separationCurrenciesAndTotalSum(draftPayment?.debt_states);

    const deleteHandler = () => {
        deleteDraftPaymentAsync(draftPayment.id)
            .then(() => toast.success(t(lang, 'common.toast.success')))
            .catch(() => toast.error(t(lang, 'common.toast.error')))
    };

    return (
        <Fragment>
            <Card className="mb-3">
                <Card.Header className={'d-flex justify-content-between'}>
                    <div>
                        <h4 className={'mb-1'}>
                            <span className={'mx-1'}>{t(lang, 'crm.draft_payment.view.field.contractor')}:</span>
                            <span className={'mx-1 fw-semi-bold text-black'}>
                                <Link to={`/crm/contractor-card/${draftPayment.contractor.id}`} className={'text-decoration-none'}>{draftPayment.contractor.name}</Link>
                            </span>
                        </h4>
                        <p className={'mb-1'}>
                            <span className={'mx-1'}>{t(lang, 'crm.draft_payment.view.field.created_at')}:</span>
                            <span className={'mx-1 fw-semi-bold text-black'}>{dayjs(draftPayment.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                        </p>
                        <p className={'mb-1'}>
                            <span className={'mx-1'}>{t(lang, 'crm.draft_payment.view.field.is_approved')}:</span>
                            <span className={'mx-1 fw-semi-bold text-black'}>
                                <Badge bg={draftPayment.is_approved ? 'success' : 'secondary'}>
                                {draftPayment.is_approved
                                    ? t(lang, 'crm.draft_payment.view.field.is_approved.approved')
                                    : t(lang, 'crm.draft_payment.view.field.is_approved.not_approved')
                                }
                            </Badge>
                            </span>
                        </p>
                        {draftPayment.approved_at && <p className={'mb-1'}>
                            <span className={'mx-1'}>{t(lang, 'crm.draft_payment.view.field.approved_at')}:</span>
                            <span className={'mx-1 fw-semi-bold text-black'}>{dayjs(draftPayment.approved_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                        </p>}
                    </div>

                    <div>
                        {draftPayment.payment && <IconButton as={Link} to={`/crm/contractor-payment/view/${draftPayment.payment.id}`} size={'lg'} icon={'eye'} className={'mx-1'} variant={'info'}>
                            {t(lang, 'crm.draft_payment.view.link_button.view_payment')}
                        </IconButton>}
                        <CommonDeleteModal onDelete={deleteHandler} disabled={draftPayment.is_approved} size={'lg'} className={'mx-1'}/>
                        <ApproveDraftPaymentModal draftPaymentId={draftPayment.id} size={'lg'} icon={'check'} disabled={draftPayment.is_approved} className={'mx-1'}/>
                    </div>
                </Card.Header>
            </Card>

            <Row className="d-flex justify-content-between mb-3">
                <Col xs={12} lg={6}>
                    <Card className="h-100">
                    <h5 className="pt-3 ps-3">{t(lang, 'crm.draft_payment.view.datatable.cash_box_states')}</h5>
                        <div className="table-responsive fs--1 mt-3">
                            <Table striped className="border-bottom">
                                <thead className="table-success">
                                <tr>
                                    <th className="align-middle">№</th>
                                    <th className="align-middle">{t(lang, "crm.draft_payment.view.datatable.payment_type")}</th>
                                    <th className="align-middle">{t(lang, "crm.draft_payment.view.datatable.payment_amount")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {draftPayment.cash_box_states.map((cashBoxState, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="align-middle">{index + 1}</td>
                                            <td className="align-middle">{t(lang, GetPaymentTypeText(cashBoxState.payment_type))}</td>
                                            <td className="text-start">{numeral['formats'][numberFormat].format(cashBoxState.amount)} <span className="text-info ms-1">{cashBoxState.currency.name}</span></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                <Col xs="auto" className="d-flex">
                                    <p className="text-900 fw-bold text-nowrap m-0 fs-1">{t(lang, "crm.draft_payment.view.datatable.total_amount")}:</p>
                                    <Table borderless size="sm" className="fs-1 text-end">
                                        <tbody>
                                        {separationCurrencyCalcCashBoxStates.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td className="fw-semi-bold">{numeral['formats'][numberFormat].format(item?.totalSum)} <span className="text-info">{item?.name}</span></td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} lg={6}>
                    <Card className="h-100">
                        <h5 className="pt-3 ps-3">{t(lang, 'crm.draft_payment.view.datatable.debt_states')}</h5>
                        <div className="table-responsive fs--1 mt-3">
                            <Table striped className="border-bottom">
                                <thead className="table-danger">
                                <tr>
                                    <th className="align-middle">№</th>
                                    <th className="align-middle">{t(lang, "crm.draft_payment.view.datatable.debt_amount")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {draftPayment.debt_states.map((debtState, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="align-middle">{index + 1}</td>
                                            <td className="text-start">{numeral['formats'][numberFormat].format(debtState.amount)}<span className="text-info ms-1">{debtState.currency.name}</span></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                <Col xs="auto" className="d-flex">
                                    <p className="text-900 fw-bold text-nowrap m-0 fs-1">{t(lang, "crm.draft_payment.view.datatable.total_amount")}:</p>
                                    <Table borderless size="sm" className="fs-1 text-end">
                                        <tbody>
                                        {separationCurrencyCalcDebtStates.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td className="fw-semi-bold">{numeral['formats'][numberFormat].format(item?.totalSum || 0)} <span className="text-info">{item?.name}</span></td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default DraftPaymentView;