import React from 'react';
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {itemLegalTypes} from "../enum/itemWrapper";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ItemLegalTypeSelector = React.forwardRef(({onChange, legalType, selectorProps}, ref) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <ReactSelect
            ref={ref}
            hideSelectedOptions
            isSearchable={false}
            classNamePrefix="select"
            options={itemLegalTypes}
            getOptionValue={option => option.value}
            onChange={(option) => onChange(option?.value)}
            getOptionLabel={option => t(lang, option.label)}
            value={itemLegalTypes.find(m => m.value === legalType) || null}
            {...selectorProps}
        />
    );
});

export default ItemLegalTypeSelector;