import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller} from "react-hook-form";
import ReactSelect from "react-select";
import {excelImportColumnOptions} from "../enum/revisionWrapper";

const ImportRevisionItemsFromExcelColumnSelectors = ({items, control}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const options = excelImportColumnOptions;
    const maxRowLength = Math.max(...items.map(row => row.length));

    return (
        <React.Fragment>
            <th>№</th>
            {Array(maxRowLength).fill().map((column, index) => {
                return (
                    <th key={index} style={{minWidth: '350px'}}>
                        <Controller name={`columns.${index}`}
                                    control={control}
                                    render={({field}) => (
                                        <ReactSelect options={options}
                                                     getOptionLabel={option => t(lang, option.label)}
                                                     getOptionValue={option => option.key}
                                                     isClearable
                                                     hideSelectedOptions
                                                     placeholder={t(lang, 'items.common.select')}
                                                     onChange={(option) => field.onChange(option ? option.key : null)}
                                                     value={options.find(o => o.key === field.value) || null}
                                                     menuPortalTarget={document.body}
                                                     styles={{
                                                         container: (provided) => ({
                                                             ...provided,
                                                             width: '100%',
                                                         }),
                                                         menuPortal: (provided) => ({...provided, zIndex: 9999})
                                                     }}
                                        />
                                    )}
                        />
                    </th>
                )
            })}
        </React.Fragment>
    )
};

export default ImportRevisionItemsFromExcelColumnSelectors;