import React from 'react';
import EdiBreadcrumb from "./EdiBreadcrumb";
import EdiReturnBreadcrumb from "./EdiReturnBreadcrumb";

const Breadcrumb = () => {
    return (
        <>
            <EdiBreadcrumb />
            <EdiReturnBreadcrumb />
        </>
    );
};

export default Breadcrumb;
