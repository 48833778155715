import React from 'react';
import {Card, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {deleteTaskInColumn, removeTaskCard, selectCurrentUser} from "../../../app/store/reducers/kanban/kanbanReducer";
import {selectEmployees} from "../../../app/store/reducers/employee/employeeReducer";
import {useParams} from "react-router";
import {selectAllContractor} from "../../../app/store/reducers/contractor/contractorReducer";
import dayjs from "dayjs";


const TaskDropMenu = ({board_column_task_id, board_column_id}) => {
    const dispatch = useDispatch();
    const {id} = useParams();

    const handleRemoveTaskCard = () => {
        dispatch(deleteTaskInColumn(id, {board_column_id, board_column_task_id}))
    };

    return (
        <Dropdown
            onClick={e => e.stopPropagation()}
            align="end"
            className="font-sans-serif"
        >
            <Dropdown.Toggle
                variant="falcon-default"
                size="sm"
                className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
            >
                <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2"/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0" align={'start'}>
                <Dropdown.Item as={'li'} onClick={handleRemoveTaskCard} className="text-danger">
                    Удалить
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const TaskCardItem = ({
                          snapshot,
                          getItemStyle,
                          handleModalOpen,
                          task: {id, board_column_id, title, assignee_id, attachments, created_at, contractor}
                      }) => {


    const employees = useSelector(selectEmployees);

    const image = attachments?.length ? attachments[0] : null;
    return (
        <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
            onClick={handleModalOpen}
        >
            {image && (
                <div className={`position-relative rounded-top-lg overflow-hidden ${image.className}`}>
                    <img className={'w-100'}
                         src={image?.image.includes('/static') ? image?.image : `data:image/jpeg;base64,${image?.image}`}
                         alt=""/>
                </div>
            )}
            <Card.Body className={'position-relative'}>
                <div className="position-relative">
                    <TaskDropMenu
                        board_column_task_id={id}
                        board_column_id={board_column_id}
                    />
                </div>
                <div>
                    <p
                        className="mb-0 fw-medium font-sans-serif stretched-link d-flex justify-content-between"
                        title={title}
                    >
                        <span className={'d-block text-truncate'}>{title}</span>
                    </p>
                    <span className={'d-block text-end fs--2'}>{created_at}</span>
                </div>
                <hr className={'my-1'}/>
                {assignee_id && <div className="kanban-item-footer cursor-default d-flex justify-content-end gap-2">
                    <span className={'text-primary fw-bold'}>{employees.find(x => x.id === assignee_id)?.name}</span>
                </div>}
                {contractor && <div>
                    <span>{contractor?.name}</span>
                    <span className={'d-block text-end text-danger fw-bold'}>{dayjs(contractor?.debt_promise_date).format("DD-MM-YYYY")}</span>
                </div>}
            </Card.Body>
        </Card>
    );
};

export default TaskCardItem;
