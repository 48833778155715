import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import OrganizationDataTable from "../../../../components/admin/organization/datatable/OrganizationDataTable";
import {getSystemOrganizationsCount, loadSystemOrganizationsAsync, selectSystemOrganizationsFilterOptions} from "../../../../app/store/reducers/billing/billingReducer";

const Organizations = () => {
    const dispatch = useDispatch();
    const filter = useSelector(selectSystemOrganizationsFilterOptions);

    useEffect(() => {
        loadOrganizations();
    }, [filter])

    const loadOrganizations = () => {
        dispatch(loadSystemOrganizationsAsync(filter));
        dispatch(getSystemOrganizationsCount(filter));
    }

    return (
        <>
            <OrganizationDataTable/>
        </>
    );
};

export default Organizations;