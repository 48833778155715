import React, {useEffect, useState} from 'react';
import ReturnOrderForm from "./ReturnOrderForm";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {loadContractsAsync} from "../../../../app/store/reducers/contract/contractReducer";
import {registerReturnOrderAsync} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import dayjs from 'dayjs';
import {toast} from "react-toastify";
import {loadCompanyProductCatalogsAsync} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang} from "../../../../app/store/reducers/main/mainReducer";

const RegisterReturnOrderForm = () => {
    const lang = useSelector(selectLang);
    const methods = useForm({
        defaultValues: {
            executor: {
                inn: '',
                name: '',
            },
            info: {
                number: '',
                date: null
            },
            contract: {
                number: '',
                date: null
            },
            deliveryAddress: null,
            expiresOn: null,
            customerShipmentId: '',
            customerSystemId: '',
            executorEditStrategy: null,
            executorCreateInvoiceStrategy: null,
            shipmentDeliveryDateStrategy: null,
            shipmentDateStrategy: null,
            productNameDefineStrategy: null,
            contractDefineStrategy: null,
            itemQuantityLimitStrategy: null,
            items: [
                {
                    name: '',
                    customerSystemId: '',
                    customerShipmentId: '',
                    barcode: '',
                    productCode: '',
                    catalogClassCode: '',
                    catalogClassName: '',
                    measurement: '',
                    quantity: '',
                    price: '',
                    total: '',
                    nds: 'No',
                    ndsValue: '',
                    totalWithNds: '0',
                }
            ]
        }
    });
    const dispatch = useDispatch();
    const [existingErrors, setExistingErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const activeOrganization = useSelector(selectActiveOrganization)

    useEffect(() => {
        dispatch(loadContractsAsync())
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
    }, [activeOrganization])

    const onSubmit = formData => {
        setLoading(true);
        registerReturnOrderAsync({
            items: formData.items.map(item => {
                return {
                    product: item.name,
                    customer_system_id: item.customerSystemId || null,
                    customer_shipment_id: item.customerShipmentId || null,
                    barcode: item.barcode || null,
                    product_code: item.productCode || null,
                    catalog_class_code: item.catalogClassCode || null,
                    catalog_class_name: item.catalogClassName || null,
                    measurement: item.measurement,
                    price: +item.price,
                    quantity: +item.quantity,
                    nds_rate: +(item.nds || 0)
                }
            }),
            branch: {
                id: formData.deliveryAddress.id,
                code: formData.deliveryAddress.code
            },
            info: {number: formData.info.number, date: dayjs(formData.info.date).format('YYYY-MM-DD')},
            contract: {number: formData.contract.number, date: dayjs(formData.contract.date).format('YYYY-MM-DD')},
            customer: {inn: activeOrganization.inn, name: activeOrganization.name},
            executor: {inn: formData.executor.inn, name: formData.executor.name},
            expires_on: dayjs(formData.expiresOn).format('YYYY-MM-DD'),
            contract_define_method_strategy: formData.contractDefineStrategy.id,
            executor_create_invoice_strategy: formData.executorCreateInvoiceStrategy.id,
            executor_edit_strategy: formData.executorEditStrategy.id,
            item_quantity_limit_strategy: formData.itemQuantityLimitStrategy.id,
            product_name_define_method_strategy: formData.productNameDefineStrategy.id,
            shipment_date_method_strategy: formData.shipmentDateStrategy.id,
            customerSystemId: formData.customerSystemId || null,
            customerShipmentId: formData.customerShipmentId || null
        })
            .then(order => {
                toast.success(`Order ${order.info.number} success added !`);
            })
            .catch(({creatorIsNotCustomer, branchNotFound, numberExists, customerSystemIdExists}) => {
                setExistingErrors({
                    creatorIsNotCustomer: creatorIsNotCustomer && formData.customer.inn,
                    branchNotFound: branchNotFound && formData.branch.id,
                    numberExists: numberExists && formData.info.number,
                    customerSystemIdExists: customerSystemIdExists && formData.customerSystemId
                });
                methods.trigger();
                toast.error(`Failed to add order`);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <ReturnOrderForm loading={loading} existingErrors={existingErrors}/>
                </Form>
            </FormProvider>
        </>
    )
}
export default RegisterReturnOrderForm;
