import IconButton from "../../../common/IconButton";
import React from "react";
import {invalidateCompanyClassCodesAsync, loadCompanyProductCatalogsAsync} from "../../../../app/store/reducers/roaming/roamingReducer";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const SyncCatalogCodeButton = ({props}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const syncCompanyClassCodes = () => {
        invalidateCompanyClassCodesAsync(activeOrganization.inn, lang)
            .then(() => {
                dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
                toast.success(t(lang, 'hippo.user.settings.organization_class_codes.sync.success_toast'));
            })
            .catch(() => toast.error(t(lang, 'hippo.user.settings.organization_class_codes.sync.error_toast')))
    }

    return <IconButton icon="fa-refresh" size="sm" className="mb-2" {...props}
                       onClick={syncCompanyClassCodes}>{t(lang, 'hippo.user.settings.organization_class_codes.sync.button')}</IconButton>
};

export default SyncCatalogCodeButton;