import { useSelector } from "react-redux";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Translate, selectLang, selectDateFormat } from "../../../../../app/store/reducers/main/mainReducer";
import { useFormContext, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

const VerificationActInfo = () => {
  const {register, getFieldState, control} = useFormContext();
  const errors = getFieldState('verificationActDoc').error || [];
  const dateFormat = useSelector(selectDateFormat);
  const lang = useSelector(selectLang);
  const t = Translate;
  return (
    <Card>
      <Card.Header>
        <h5 className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.info.title")}</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="6" className="mb-3">
                <Form.Label>{t(lang, "roaming.verification_act.add.verification_act.info.number")}</Form.Label>
                <Form.Control
                  {...register("verificationActDoc.verificationActNo", {
                    required: "edi.common.forms.validations.is_required"
                  })}
                  className={errors?.verificationActNo?.message && "is-invalid"}
                  placeholder={t(lang, "roaming.verification_act.add.verification_act.info.number")}
                />
                <Form.Control.Feedback type="invalid">
                  {t(lang, errors?.verificationActNo?.message)}
                </Form.Control.Feedback>
              </Col>
              <Col sm="6" className="mb-3">
                <Form.Label>{t(lang, "roaming.verification_act.add.verification_act.info.date")}</Form.Label>
                <Controller
                  control={control}
                  name="verificationActDoc.verificationActDate"
                  rules={{
                    required: "edi.common.forms.validations.is_required"
                  }}
                  render={({ field }) => (
                    <DatePicker
                      onChange={field.onChange}
                      dateFormat={dateFormat}
                      selected={field.value}
                      wrapperClassName={errors?.verificationActDate?.message && "is-invalid"}
                      className={`${errors?.verificationActDate?.message && "is-invalid"} form-control`}
                      placeholderText={t(lang, "roaming.verification_act.add.verification_act.info.date")}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {t(lang, errors?.verificationActDate?.message)}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default VerificationActInfo;
