import React, {useEffect, useState} from 'react';
import CreatableSelect from "react-select/creatable";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const SelectBarcode = ({barcodes,onChange, defaultValue, ...props}) => {
    const [options, setOptions] = useState([])
    const lang = useSelector(selectLang);
    const t = Translate;


    useEffect(() => {
        if (barcodes?.length > 0) {
            let os = barcodes.map(barcode => {
                return {
                    label: barcode,
                    value: barcode,
                }
            });
            setOptions(os);
        }
    }, [barcodes])

    const formatCreateLabel = (label) => `${t(lang, "crm.common.add")} ${label}`;

    function onChangeBarcode(value) {
        return value?.value
    }

    const formatCatalogLabel = (option) => {
        return (
            <div className="fs--1">
                <span className={`ms-1 ${props?.isDisabled ? 'text-black' : 'text-facebook'}`}>{option.value}</span>
            </div>
        )
    }

    return (
        <CreatableSelect
            placeholder={t(lang, "crm.common.select")}
            options={options}
            noOptionsMessage={() => t(lang, "crm.common.select.barcode.no_options_message")}
            formatCreateLabel={formatCreateLabel}
            onChange={(value) => onChange(value.__isNew__ ? value?.label : onChangeBarcode(value))}
            getOptionValue={(option) => option?.value}
            formatOptionLabel={(option) => option?.__isNew__ ? option.label : formatCatalogLabel(option)}
            getOptionLabel={(option) => option.value}
            value={options.find(op => op.value === defaultValue) || {value: defaultValue} }
            menuPortalTarget={document.body}
            {...props}
        />
    );
};

export default SelectBarcode;
