import React, {useState} from 'react';
import {useFieldArray, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {RoleDefaultPermission} from "../../../../enum/Permission/RolePermission";
import {Form, Table} from "react-bootstrap";

const Permissions = ({permission=[], name}) => {
    const {control} = useFormContext();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {fields, replace, append} = useFieldArray({
        control,
        name: `Permissions.${name}`
    })

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
        const arr = [];
        permission.forEach(permissionValues => {
            permissionValues.permissions.forEach(val => {
                arr.push({value: val.value});
            })
        })
        replace(arr);

        if (isCheckAll) {
            replace([]);
        }
    }

    const handleClick = (el, permission) => {
        if (el.target.checked) {
            append({value: permission})
        } else {
            replace(fields.filter(field => field.value !== permission))
        }
    }

    const isChecked = (val) => {
        let checked = fields.find(item => {
            return item.value === val;
        });
        return !!checked
    }

    return (
        <Table hover responsive className="border-bottom">
            <thead>
            <tr>
                <th className={'d-flex border-white border-0 border-bottom table-primary align-items-center'}>
                    <h6 className={"me-3"}>{t(lang, "edi.permission.admin_access")}</h6>
                </th>
                <th>
                    <Form.Check type="checkbox">
                        <Form.Check.Label className={'text-dark'}>
                            <Form.Check.Input type="checkbox" onClick={handleSelectAll} defaultChecked={isCheckAll}/>
                            {t(lang, "edi.permission.check_all")}
                        </Form.Check.Label>
                    </Form.Check>
                </th>
            </tr>
            </thead>
            <tbody>
            {permission.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className={`text-dark table-primary border-white border-0 border-bottom`}>{t(lang, item.text)}</td>
                        {item.permissions.map((val, valIndex) => {
                            return (
                                <td key={valIndex}>
                                    <Form.Check
                                        type="checkbox"
                                        className="form-check">
                                        <Form.Check.Label className="form-label text-dark">
                                            <Form.Check.Input id={val.value}
                                                              type="checkbox"
                                                              onChange={e => {
                                                                  handleClick(e, val.value);
                                                              }}
                                                              name={val.text}
                                                              checked={isChecked(val.value)}/>
                                            {t(lang, val.text)}
                                        </Form.Check.Label>
                                    </Form.Check>
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </Table>
    );
};

export default Permissions;
