import React, {useEffect} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    selectHistories,
    selectFilters,
    selectCountHistories,
    loadCountHistoriesAsync,
    loadItemHistoriesAsync, changeFiltersAsync
} from "../../../../app/store/reducers/item-history/itemHistoryReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import ItemHistoryDataTableHeader from "./ItemHistoryDataTableHeader";
import {getAllWarehousesAsync, selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {FindOperationType} from "../../../../enum/OperationTypes";
import SoftBadge from "../../../common/SoftBadge";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";

const ItemsHistoryDataTable = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const items = useSelector(selectItems);
    const warehouses = useSelector(selectWarehouses)
    const count = useSelector(selectCountHistories)

    const histories = useSelector(selectHistories);
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectFilters)

    useEffect(() => {
        dispatch(loadCountHistoriesAsync({...filters}))
        dispatch(loadItemHistoriesAsync({...filters}))
    }, [filters, activeOrganization])

    useEffect(() => {
        dispatch(getAllWarehousesAsync({id: activeOrganization.id}))
    }, [activeOrganization])

    const onPageChange = (page) => dispatch(changeFiltersAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFiltersAsync({...filters, limit: limit, page: 1}));

    const findItem = (item_id) => {
        return items.find(item => item.item.id === item_id);
    }
    const findWarehouse = (warehouse_id) => {
        return warehouses.find(warehouse => warehouse.id === warehouse_id);
    }

    const columns = [
        {
            Header: "#",
            id: "row",
            cellProps: {
                width: '50px',
                className: 'p-0'
            },
            filterable: false,
            Cell: ({row}) => {
                return (<>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {t(lang, FindOperationType(row.original.type)?.label)}
                            </Tooltip>
                        }
                        placement="right"
                    >
                        <SoftBadge bg={FindOperationType(row.original.type)?.variant} className='fs--1 text-start'>
                            {`${((filters.page - 1) * filters.limit) + row.index + 1}`}
                        </SoftBadge>
                    </OverlayTrigger>
                </>)
            }
        },
        {
            accessor: 'type',
            Header: t(lang, "warehouse.items.history.datatable.column.type"),
            headerProps: {className: 'text-start'},
            Cell: ({row: {original}}) => {
                return (
                    <SoftBadge bg={FindOperationType(original.type)?.variant}>{t(lang, FindOperationType(original.type)?.label)}</SoftBadge>
                )
            }
        },
        {
            accessor: 'item',
            Header: t(lang, "crm.common.items.product"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{findItem(original.item_id)?.item?.name}</span>
                )
            }
        },
        {
            Header: t(lang, "warehouse.items.history.datatable.column.before_quantity"),
            headerProps: {className: 'text-center'},
            cellProps: {
                className: 'text-center'
            },
            accessor: 'before_quantity'
        },
        {
            Header: t(lang, "warehouse.items.history.datatable.column.quantity"),
            headerProps: {className: 'text-center'},
            cellProps: {
                className: 'text-center'
            },
            accessor: 'quantity'
        },
        {
            Header: t(lang, "warehouse.items.history.datatable.column.after_quantity"),
            headerProps: {className: 'text-center'},
            cellProps: {
                className: 'text-center'
            },
            accessor: 'after_quantity'
        },
        {
            accessor: 'warehouse',
            Header: t(lang, "warehouse.items.history.datatable.column.warehouse"),
            headerProps: {className: 'text-center'},
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{findWarehouse(original.warehouse_id)?.name}</span>
                )
            }
        },
        {
            accessor: 'operation_date_time',
            Header: t(lang, "warehouse.items.history.datatable.column.operation_date_time"),
            headerProps: {className: 'text-end'},
            cellProps: {
                width: '200px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.operation_date_time}</span>
                )
            }
        },
    ]

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={histories}
            pagination
            perPage={1000}
        >
            <Card>
                <Card.Header className={'pb-0'}>
                    <ItemHistoryDataTableHeader/>
                </Card.Header>
                <Card.Body>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default ItemsHistoryDataTable;
