import {ListGroup} from "react-bootstrap";
import {Link, useRouteMatch} from "react-router-dom";
import Flex from "../../../components/common/Flex";
import React from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const Dashboard = () => {
    const {url} = useRouteMatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <ListGroup>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contractor`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.contractor.datatable.header.row.contractor")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.contractor.datatable.header.row.contractor")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contractor-payments`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.contractor.datatable.header.row.contractor_payments")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.contractor.datatable.header.row.contractor_payments")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contractor-payouts`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.contractor.datatable.header.row.contractor_payouts")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.contractor.datatable.header.row.contractor_payouts")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contractor-draft-payments`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.contractor.datatable.header.row.contractor_draft_payments")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.contractor.datatable.header.row.contractor_draft_payments")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contractor-operation`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.contractor.datatable.header.row.contractor_operations")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.contractor.datatable.header.row.contractor_operations")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contracts`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.contracts.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.contracts.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/order`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.orders.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.orders.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contractor-agents`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.dashboard.contractor_agents.title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.dashboard.contractor_agents.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contractor-agent-bots`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.dashboard.contractor_agent_bots.title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.dashboard.contractor_agent_bots.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/lead`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.dashboard.lead.title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.dashboard.lead.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/internal/order/all`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "crm.internal.order")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "crm.internal.order")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contractor-labels`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "edi.dashboard.contractor_labels.title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, "edi.dashboard.contractor_labels.description")}
                </p>
            </ListGroup.Item>
        </ListGroup>
    )
}
export default Dashboard