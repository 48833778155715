import React, { cloneElement, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectLang, Translate } from "../../../../app/store/reducers/main/mainReducer";
import { activateCertificateAsync, selectCurrentCertificate, setCurrentCertificate } from "../../../../app/store/reducers/eimzo/eimzoReducer";
import { useFormContext } from "react-hook-form";
import SelectCertificate from "../../eimzo/SelectCertificate";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiAttorneySign} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const SignActionWithSelectCertificatWrapper = ({children, isForm, permission}) => {
  const currentCertificate = useSelector(selectCurrentCertificate);
  const methodsContext = useFormContext();
  const [show, setShow] = useState(false);
  const { props, ...element} = children
  const lang = useSelector(selectLang);
  const dispatch = useDispatch();
  const t = Translate;
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!isForm) {
        if(permission) {
            if(dispatch(checkPermission(permission))) setShow(true)
            else dispatch(toastPermission())
        }
       else {
            setShow(true)
        }
    }
  };

  useEffect(() => {
    if (isForm && !Object.keys(methodsContext?.formState?.errors).length && methodsContext?.formState?.submitCount && !currentCertificate?.keyId) {
      setShow(true)
    }
  }, [methodsContext?.formState?.submitCount]);

  const onSelectCertificate = certificate => {
    activateCertificateAsync(certificate)
      .then(activatedCertificate => {
        dispatch(setCurrentCertificate(activatedCertificate));
        setShow(false);
        toast.success('Certificate success activated');
      })
      .catch(e => {
        toast.error('Error')
        console.log(e)
      });
  }

  return (
    <>
      { currentCertificate ?
        children :
        cloneElement(element,
          {...props, onClick: handleShow, disabled: false},
        )
      }
      <Modal backdrop="static" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t(lang, 'roaming.components.select_certificate.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <SelectCertificate
                onChange={onSelectCertificate}
                placeholder={t(lang, 'roaming.components.select_certificate.title')}
              />
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
            {children}
          </Modal.Footer>
      </Modal>

    </>
  )
}


SignActionWithSelectCertificatWrapper.propTypes = {
  wrapper: PropTypes.element,
};

SignActionWithSelectCertificatWrapper.defaultProps = {
  wrapper: <div/>
};

export default SignActionWithSelectCertificatWrapper
