import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import React, {cloneElement, Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrganizationPDFTemplateForm from "../form/OrganizationPDFTemplateForm";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {addOrganizationTemplateAsync} from "../../../../app/store/reducers/print/printReducer";

const AddOrganizationPDFTemplateModal = ({organizationId, children, ...props}) => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            file: null,
            templateCode: null,
        },
    });

    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        methods.reset();
        setShow(false);
    };

    const onSubmit = (formData) => {
        const payload = {
            template_code: formData.templateCode,
            file: {
                name: formData.file.name,
                content: formData.file.content.split(',')[1],
            },
        };

        setIsLoading(true);
        addOrganizationTemplateAsync(organizationId, payload)
            .then(() => {
                toast.success(t(lang, 'common.toast.success'));
                handleClose();
            })
            .catch(() => toast.error("common.toast.error"))
            .finally(() => setIsLoading(false))
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton variant={'primary'} onClick={handleShow} icon={'plus'} {...props}>
                    {t(lang, 'admin.organization_templates.add_template_modal.show_button')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose} size={'lg'}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{t(lang, 'admin.organization_templates.add_template_modal.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <OrganizationPDFTemplateForm isEditing={false}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'secondary'} type={'button'} onClick={handleClose}>
                                {t(lang, 'admin.organization_templates.add_template_modal.button.close')}
                            </Button>
                            <Button variant={'primary'} type={'submit'} disabled={isLoading}>
                                {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                                {t(lang, 'admin.organization_templates.add_template_modal.button.add')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default AddOrganizationPDFTemplateModal;