import React, { useEffect, useState } from "react"
import IconAlert from "../../common/IconAlert"
import { Alert } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { selectLang, Translate, updateShowInProgressAsync } from '../../../app/store/reducers/main/mainReducer';
import Button from "react-bootstrap/Button"
import { selectCurrentCertificate } from "../../../app/store/reducers/eimzo/eimzoReducer";
import { bindSystemAsRoamingProvider, loadProviderBindings, selectIsSystemProviderEnabled, selectProviderBindings, SYSTEM_PROVIDER_INN } from "../../../app/store/reducers/roaming/roamingReducer";
import { selectActiveOrganization } from "../../../app/store/reducers/auth/authReducer";
import { COMPANY_BINDING_FAILED, COMPANY_BINDING_STARTED, COMPANY_BINDING_SUCCESS } from "../../../app/eventbus/signEvents";
import EventBus from "../../../app/eventbus/EventBus";
import { toast } from "react-toastify";
import SelectCertificateWrapperComponent from "./components/SelectCertificateWrapperComponent";

const RoamingProviderBinding = () => {
    const lang = useSelector(selectLang)
    const currentCertificate = useSelector(selectCurrentCertificate);
    const providerBindings = useSelector(selectProviderBindings);
    const activeOrganization = useSelector(selectActiveOrganization)
    const dispatch = useDispatch()
    const t = Translate
    const isSystemProviderEnabled = useSelector(selectIsSystemProviderEnabled);

    useEffect(() => {
        if (activeOrganization.inn) {
            dispatch(loadProviderBindings());
        }
    }, [activeOrganization])

    useEffect(() => {
        const signStartedHandler = EventBus.on(COMPANY_BINDING_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const signSuccessHandler = EventBus.on(COMPANY_BINDING_SUCCESS, () => {
            toast.success(t(lang, "edi.select_provider.sign.notification.success"))
            dispatch(updateShowInProgressAsync(false))
            dispatch(loadProviderBindings());
        });
        const signFailedHandler = EventBus.on(COMPANY_BINDING_FAILED, (data) => {
            toast.error(t(lang, data.message))
            dispatch(updateShowInProgressAsync(false))
        });

        return () => {
            EventBus.remove(COMPANY_BINDING_STARTED, signStartedHandler)
            EventBus.remove(COMPANY_BINDING_SUCCESS, signSuccessHandler)
            EventBus.remove(COMPANY_BINDING_FAILED, signFailedHandler)
        }
    }, [])

    const onBindSystemAsRoamingProviderClicked = async () => {
        let enabledProviderBindings = await providerBindings.filter(provider => {
            return provider.enabled || provider.provider_inn === SYSTEM_PROVIDER_INN
        })
        enabledProviderBindings = enabledProviderBindings.map(provider => provider.provider_inn)
        let hashCode = JSON.stringify({
            "ClientTin": activeOrganization.inn,
            "ProviderTins": enabledProviderBindings
        })
        await bindSystemAsRoamingProvider({
            hashCode: hashCode,
            certificate: currentCertificate
        })
    }

    if (isSystemProviderEnabled || providerBindings.length === 0)
        return null

    if (activeOrganization && (activeOrganization.inn || activeOrganization.pinfl))
        return (
            <IconAlert variant="warning">
                <Alert.Heading className="mb-0">{t(lang, "edi.alert.warning.is_system_provider_enabled.title")}</Alert.Heading>
                <p className="mb-0 fw-medium mb-2">{t(lang, "edi.alert.warning.is_system_provider_enabled.description")}</p>
                <SelectCertificateWrapperComponent submitButton={<Button
                    onClick={onBindSystemAsRoamingProviderClicked}
                    variant="falcon-success"
                    size="lg"
                >{t(lang, "edi.alert.warning.is_system_provider_enabled.activation_button")}
                </Button>} openDialogButtonText={t(lang, "edi.alert.warning.is_system_provider_enabled.activation_button")} />
            </IconAlert>
        )

    return null

}

export default RoamingProviderBinding
