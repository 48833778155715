import React, {Fragment} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {changeFilterOrdersAsync, selectFilterOptions} from "../../../../../app/store/reducers/crm/order/crmCustomerOrderReducer";
import CrmCustomerOrderDataTableFilter from "./CrmCustomerOrderDataTableFilter";
import {selectAllContractor} from "../../../../../app/store/reducers/contractor/contractorReducer";

const CrmCustomerOrderDataTableHeader = () => {
    const dispatch = useDispatch()
    const contractors = useSelector(selectAllContractor)
    const filterOptions = useSelector(selectFilterOptions)
    const methods = useForm({
        defaultValues: {
            executor: contractors.find(contractor=>contractor.id === filterOptions?.executor_contractor_id) || null,
            number: filterOptions?.number || '',
        }
    })

    const onSubmitFilter = ({number, executor}) => {
        dispatch(changeFilterOrdersAsync({
            number: number || null,
            executor_contractor_id: executor?.id
        }))
    }

    return (
        <Fragment>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                    <Row className={'mt-3'}>
                        <Col sm={12}>
                            <CrmCustomerOrderDataTableFilter />
                            <hr/>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </Fragment>
    );
};

export default CrmCustomerOrderDataTableHeader;
