import React, {useState} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import {useForm} from "react-hook-form";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../common/IconButton";
import {useDispatch} from "react-redux";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnInvoiceReject} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";


const DeclineReturnInvoice = ({decline, disabled, ...props}) => {
    const {register, formState: {errors}, handleSubmit, reset} = useForm();
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleShow = () => {
        if (dispatch(checkPermission(PermissionEdiReturnInvoiceReject))) setShow(true)
        else dispatch(toastPermission())
    }

    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        decline({
            comment: comment
        })
        reset();
        setShow(false);
    };

    return (
        <>
            <IconButton
                variant="falcon-danger"
                size="sm"
                icon={faXmarkCircle}
                disabled={disabled}
                iconClassName="me-1"
                className="mx-1 mb-2 mb-sm-0"
                onClick={handleShow}
                {...props}
            >Decline</IconButton>
            <Modal size="md" show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Header>
                        <Modal.Title>Return Invoice decline</Modal.Title>
                        <FalconCloseButton onClick={() => {handleClose()}}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                name="Name"
                                as="textarea" rows={3}
                                placeholder="Please enter a comment to decline"
                                isInvalid={errors?.comment?.message}
                                {...register("comment", {
                                    required: {
                                        value: true,
                                        message: "This field is required!"
                                    }
                                })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.comment?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {handleClose()}}>Close</Button>
                        <Button
                            variant="danger"
                            className="d-flex align-items-center"
                            disabled={disabled}
                            type="submit"
                        >decline
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default DeclineReturnInvoice;
