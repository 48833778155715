import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {syncAsync, syncHistoriesAsync} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import IconButton from "../../../../common/IconButton";

const RoamingInvoiceSyncHistories = ({id, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false);

    const syncHistories = () => {
        setLoading(true);
        syncHistoriesAsync(id)
            .then()
            .catch()
            .finally(() => setLoading(false))
    };

    return (
        <IconButton
            icon={'fa-refresh'}
            variant="falcon-default"
            onClick={syncHistories}
            size="sm"
            iconClassName="me-1"
            disabled={loading}
            {...props}>
            {t(lang, "roaming.empowerment.sync-histories")}
        </IconButton>
    );
};

export default RoamingInvoiceSyncHistories;
