import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import ExcelFileImportDropzone from "../../../item/excel/ExcelFileImportDropzone";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import {faArrowLeft, faDownload, faFileImport} from "@fortawesome/free-solid-svg-icons";
import {Controller, useForm, useWatch} from "react-hook-form";
import classNames from "classnames";
import ImportRevisionItemsFromExcelColumnSelectors from "./ImportRevisionItemsFromExcelColumnSelectors";
import SelectWarehouse from "../../../item/components/SelectWarehouse";
import {ErrorMessage} from "@hookform/error-message";
import {utils, writeFile} from "xlsx";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {excelImportColumnOptions, Name, State} from "../enum/revisionWrapper";
import dayjs from "dayjs";
import {revisionRegisterAsync} from "../../../../../app/store/reducers/warehouse-operation/revisionReducer";
import InProgress from "../../../../common/InProgress";
import {toast} from "react-toastify";
import SoftBadge from "../../../../common/SoftBadge";
import SkipRowsInputGroup from "../../../item/excel/SkipRowsInputGroup";

const columnsFieldName = "columns";
const itemsFieldName = "items";
const skipRowsFieldName = "skipRows";
const ImportRevisionItemsFromExcelForm = ({handleClose}) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useForm({
        defaultValues: {
            items: [],
            columns: [],
            skipRows: 1,
            globalWarehouseId: null,
        },
    });
    const existingItems = useSelector(selectItems);
    const selectedColumns = useWatch({name: columnsFieldName, control: form.control});
    const items = useWatch({name: itemsFieldName, control: form.control});
    const skipRows = useWatch({name: skipRowsFieldName, control: form.control});
    const [limit, setLimit] = useState(50);
    const [failedCount, setFailedCount] = useState(false);

    const onChangeFile = (parsedContent) => {
        setFailedCount(0);
        form.setValue('items', parsedContent);
    };
    const incrementLimitTo50 = () => {
        setLimit(prev => prev + 50);
    };
    const downloadFailedItemsToExcel = (selectedColumns, items) => {
        let headers = selectedColumns.map(c => t(lang, excelImportColumnOptions.find(x => x.key === c)?.label));
        const workbook = utils.book_new();
        const worksheet = utils.aoa_to_sheet([headers, ...items]);
        utils.book_append_sheet(workbook, worksheet, t(lang, 'items.table.title'));
        writeFile(workbook, `${t(lang, 'items.common.import_from_excel.failed')}.xlsx`);
    };
    const onSubmit = async (formData) => {
        try {
            setFailedCount(0);
            const items = [];
            const failed = [];

            // get cell data and transform to revision item json
            {
                for (let i = formData.skipRows; i < formData.items.length; i++) {
                    const itemRow = formData.items[i];
                    const item = {
                        name: '',
                        state: '',
                        warehouseId: formData.globalWarehouseId
                    };

                    // bind cell data to item object
                    {
                        for (let j = 0; j < itemRow.length; j++) {
                            if (itemRow[j]) {
                                switch (formData.columns[j]) {
                                    case Name:
                                        item.name = itemRow[j];
                                        break;
                                    case State:
                                        item.state = +itemRow[j];
                                        break;
                                }
                            }
                        }
                    }

                    // validate existing items
                    {
                        const existingItem = existingItems.find(i => i.item.name.trim().toLowerCase() === item.name.trim().toLowerCase());
                        if (!existingItem) {
                            failed.push([...itemRow, t(lang, 'items.common.not_found')]);
                            continue;
                        }
                        items.push({
                            item_id: existingItem.item.id,
                            quantity: item.state,
                            warehouse_id: item.warehouseId
                        });
                    }
                }
            }

            // register approved revision
            {
                if (items.length > 0) {
                    dispatch(updateShowInProgressAsync(true));
                    const revisionJson = {
                        approve: true,
                        date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        items: items,
                    };
                    await revisionRegisterAsync(revisionJson);
                    dispatch(updateShowInProgressAsync(false));
                }
            }

            // on any failed handler
            {
                if (failed.length > 0) {
                    setFailedCount(failed.length);
                    form.setValue('items', failed);
                    toast.error(t(lang, 'items.common.toast.error'));
                    return;
                }
            }

            toast.success(t(lang, 'items.common.toast.success'));
            handleClose();
        } catch (error) {
            toast.error(t(lang, 'items.common.toast.error'));
            console.log(error);
        }
    };
    const validateColumns = () => {
        let noErrorsFound = true;
        for (let i = 0; i < excelImportColumnOptions.length; i++) {
            let found = false;
            const field = excelImportColumnOptions[i];

            if (field.isRequired) {
                for (let j = 0; j < selectedColumns.length; j++) {
                    if (field.key === selectedColumns[j]) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    noErrorsFound = false;
                    form.setError(columnsFieldName, {
                        type: 'required',
                        message: t(lang, 'items.common.import_from_excel.column_selector.required_option', {column: t(lang, field.label)})
                    })
                }
            }
        }

        if (noErrorsFound)
            form.clearErrors(columnsFieldName);
    }

    useEffect(() => {
        validateColumns();
    }, [selectedColumns])

    return (
        <>
            <InProgress/>
            <Form>
                <Row className="gy-2">
                    <Col xs={12}>
                        <ExcelFileImportDropzone onChangeFile={onChangeFile}/>
                    </Col>
                    {items.length > 0 &&
                        <>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} md={6} lg={4} xl={3}>
                                        <Form.Group>
                                            <Form.Label>{t(lang, 'items.common.warehouse')}</Form.Label>
                                            <Controller name={`globalWarehouseId`}
                                                        control={form.control}
                                                        rules={{
                                                            required: t(lang, 'items.common.validations.is_required')
                                                        }}
                                                        render={({field, fieldState: {error}}) => (
                                                            <SelectWarehouse defaultWarehouseId={field.value}
                                                                             onChange={option => field.onChange(option ? option.id : null)}
                                                                             selectProps={{
                                                                                 isSearchable: !field.value,
                                                                                 isClearable: true,
                                                                                 className: classNames({
                                                                                     'is-invalid': !!error
                                                                                 }),
                                                                                 menuPortalTarget: document.body,
                                                                                 styles: {
                                                                                     container: (provided) => ({
                                                                                         ...provided,
                                                                                         margin: '-1px'
                                                                                     }),
                                                                                     menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                                                                                 }
                                                                             }}
                                                            />
                                                        )}
                                            />
                                            <ErrorMessage name='globalWarehouse'
                                                          errors={form.formState.errors}
                                                          as={<Form.Control.Feedback type="invalid" />}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} xl={3}>
                                        <Form.Group>
                                            <Form.Label>{t(lang, 'items.common.import_from_excel.skip_rows')}</Form.Label>
                                            <Controller name="skipRows"
                                                        control={form.control}
                                                        render={({field}) => (
                                                            <SkipRowsInputGroup onChangeSkipCount={field.onChange}
                                                                                defaultSkipCount={field.value}
                                                                                maxSkipCount={items.length}
                                                            />
                                                        )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} xl={6} className="d-grid d-md-block mt-md-2 mt-xl-auto text-md-end">
                                        <IconButton icon={faArrowLeft}
                                                    variant="falcon-default"
                                                    className="my-2 my-md-0"
                                                    onClick={handleClose}
                                        >
                                            {t(lang, "items.common.cancel")}
                                        </IconButton>
                                        <IconButton icon={faFileImport}
                                                    variant="falcon-primary"
                                                    type="submit"
                                                    className="mx-md-2"
                                                    onClick={form.handleSubmit(onSubmit)}
                                        >
                                            <span className="me-1">{t(lang, 'items.common.import')}</span>
                                            <SoftBadge>{items.length - skipRows}</SoftBadge>
                                        </IconButton>
                                        {failedCount > 0 &&
                                            <IconButton icon={faDownload}
                                                        variant="falcon-success"
                                                        className="my-2 my-md-0"
                                                        onClick={() => downloadFailedItemsToExcel(selectedColumns, items)}
                                            >
                                                <span className="me-1">{t(lang, 'items.common.import_from_excel.download')}</span>
                                                <SoftBadge bg="success">{failedCount}</SoftBadge>
                                            </IconButton>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12}>
                                {form.formState.errors?.columns && <span className="text-danger d-block">{form.formState.errors.columns.message}</span>}
                                <div className="table-responsive overflow-auto" style={{maxHeight: '500px'}}>
                                    <Table bordered>
                                        <thead style={{position: 'sticky', top: '-1px', zIndex: '3', backgroundColor: 'aliceblue'}}>
                                        <tr>
                                            <ImportRevisionItemsFromExcelColumnSelectors items={items}
                                                                                         control={form.control}
                                            />
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items.slice(0, limit).map((item, itemIndex) => (
                                            <tr className={classNames('fs--1', {'bg-400 text-dark': (itemIndex + 1) <= skipRows})}
                                                key={itemIndex}>
                                                <th className="text-center px-3 m-0">{itemIndex + 1}</th>
                                                {item.map((product, productIndex) => (
                                                    <th key={productIndex}>{product}</th>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {items.length > limit && (
                                    <div className="w-100 text-center my-3">
                                        <Button onClick={incrementLimitTo50}>{t(lang, 'items.common.import_from_excel.show_more')}</Button>
                                    </div>
                                )}
                            </Col>
                        </>
                    }
                </Row>
            </Form>
        </>
    );
};

export default ImportRevisionItemsFromExcelForm;