import {Row, Col} from "react-bootstrap";
import EImzoSignerModal from "./EImzoSignerModal";

const EImzoSignerDataTableHeader = ({setDataFunc}) => {
    return (
        <Row className="flex-between-center align-items-center">
            <Col className="d-flex justify-content-end">
                <EImzoSignerModal setDataFunc={setDataFunc}/>
            </Col>
        </Row>
    );
};
export default EImzoSignerDataTableHeader;