export const WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_REQUESTED = "WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS = "WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_FAILED = "WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_FAILED"

export const WAREHOUSE_OPERATION_REFUND_ADD_ITEM_REQUESTED = "WAREHOUSE_OPERATION_REFUND_ADD_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS = "WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_ADD_ITEM_FAILED = "WAREHOUSE_OPERATION_REFUND_ADD_ITEM_FAILED"

export const WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_FAILED = "WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_FAILED = "WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_REQUESTED = "WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS = "WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_FAILED = "WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_FAILED"

export const WAREHOUSE_OPERATION_REFUND_DELETE_REQUESTED = "WAREHOUSE_OPERATION_REFUND_DELETE_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_DELETE_SUCCESS = "WAREHOUSE_OPERATION_REFUND_DELETE_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_DELETE_FAILED = "WAREHOUSE_OPERATION_REFUND_DELETE_FAILED"

export const WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_REQUESTED = "WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_SUCCESS = "WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_FAILED = "WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_FAILED"

export const WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_REQUESTED = "WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS = "WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_FAILED = "WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_FAILED"

export const WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_REQUESTED = "WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS = "WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_FAILED = "WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_FAILED"

export const WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_REQUESTED = "WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_REQUESTED";
export const WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS = "WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS";
export const WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_FAILED = "WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_FAILED";

export const WAREHOUSE_OPERATION_REFUND_NOTE_REQUESTED = "WAREHOUSE_OPERATION_REFUND_NOTE_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS = "WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_NOTE_FAILED = "WAREHOUSE_OPERATION_REFUND_NOTE_FAILED"

export const WAREHOUSE_OPERATION_REFUND_DATE_REQUESTED = "WAREHOUSE_OPERATION_REFUND_DATE_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS = "WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_DATE_FAILED = "WAREHOUSE_OPERATION_REFUND_DATE_FAILED"

export const WAREHOUSE_OPERATION_REFUND_NUMBER_REQUESTED = "WAREHOUSE_OPERATION_REFUND_NUMBER_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS = "WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_NUMBER_FAILED = "WAREHOUSE_OPERATION_REFUND_NUMBER_FAILED"

export const WAREHOUSE_OPERATION_REFUND_EMPLOYEE_REQUESTED = "WAREHOUSE_OPERATION_REFUND_EMPLOYEE_REQUESTED"
export const WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS = "WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS"
export const WAREHOUSE_OPERATION_REFUND_EMPLOYEE_FAILED = "WAREHOUSE_OPERATION_REFUND_EMPLOYEE_FAILED"