import SaleExcelForm from "../../../../components/hippo/warehouse-operation/sale/forms/SaleExcelForm";
import {Button, Card, Col, Row} from "react-bootstrap";
import WarehouseOperationPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationPayment";
import WarehouseOperationWithoutPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationWithoutPayment";
import SoftBadge from "../../../../components/common/SoftBadge";
import {
    isApprovedCheckedWarehouseOperation
} from "../../../../components/hippo/warehouse-operation/enum/warehouseOperationWrapper";
import {
    getSaleItemAsync, saleApproveAsync, saleChangeExactDiscountsAsync, saleChangePercentDiscountAsync,
    salePaymentRegisterAsync,
    salePaymentUpdateAsync
} from "../../../../app/store/reducers/warehouse-operation/saleReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import React, {useEffect, useState} from "react";
import {
    WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_SUCCESS, WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/saleEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import {useHistory} from "react-router";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {Link} from "react-router-dom";
import WOWIthDiscountPayment from "../../../../components/hippo/warehouse-operation/components/WOWIthDiscountPayment";

const AddSaleExcel = () => {
    const [sale, setSale] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate;

    const totalAmounts = sale?.totals?.map(saleTotal => {
        return {amount: saleTotal?.amount, currencyId: saleTotal?.currency?.id};
    });

    const exactDiscounts = sale?.exact_discounts?.map(exactDiscount => {
        return {amount: exactDiscount?.amount, currencyId: exactDiscount?.currency?.id};
    });

    const salePayment = {
        debtStates: sale?.payment?.debt_states?.map(debtState => {
            return {amount: debtState?.amount, currencyId: debtState?.currency?.id};
        }),
        cashBoxStates: sale?.payment?.cash_box_states?.map(cashBoxState => {
            return {amount: cashBoxState?.amount, currencyId: cashBoxState?.currency?.id};
        }),
        note: sale?.payment?.note,
    };

    const loadSale = (id) => {
        getSaleItemAsync({saleId: id})
            .then(response => setSale(response.data))
            .catch(error => setSale(null))
    }


    useEffect(() => {
        const onChangeContractorHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_SUCCESS, (id) => {
            loadSale(id)
        })

        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, (id) => {
            loadSale(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, (id) => {
            loadSale(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, (id) => {
            loadSale(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, (id) => {
            loadSale(id)
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_SALE_CHANGE_CONTRACTOR_SUCCESS, onChangeContractorHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
        }
    }, [])


    const onSubmitPay = async (pay) => {
        const areLengthsEqual = pay?.exactDiscounts?.length !== sale?.exact_discounts?.length;

        const areEqual = pay?.exactDiscounts?.every((p, i) =>
            p?.amount !== sale?.exact_discounts[i]?.amount &&
            p?.currency_id === sale?.exact_discounts[i]?.currency?.id
        );



        if (pay?.percentDiscount !== sale?.percent_discount) {
            await saleChangePercentDiscountAsync({saleId: sale?.id, percentDiscount: pay?.percentDiscount})
                .then(() => {})
                .catch(() => {})
        }
        if (areLengthsEqual || areEqual) {
            await saleChangeExactDiscountsAsync({saleId: sale?.id, exactDiscounts: pay?.exactDiscounts})
                .then(() => {})
                .catch(() => {})
        }


        if (sale?.payment) {
            await salePaymentUpdateAsync({saleId: pay?.id, payment: pay?.payment})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})
        }

        if (!sale?.payment && sale?.is_approved) {
            await salePaymentRegisterAsync({saleId: pay?.id, payment: pay?.payment})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})

        }

        if (!sale?.is_approved) {
            await saleApproveAsync({saleId: pay?.id, payment: pay?.payment || null})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})
        }
    }

    if (warehouses?.length === 0)
        return(
            <>
                <WarehouseNo/>
            </>
        )


    if (warehouses?.length > 0)
        return (
            <Card>
                <Card.Body>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <h5>Новая продажа</h5>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            {sale?.id &&
                                <div className="me-3">
                                    {/*<WarehouseOperationPayment onSubmitPay={onSubmitPay} data={sale} />*/}
                                    <WOWIthDiscountPayment
                                        data={sale}
                                        saleId={sale?.id}
                                        isEditing={false}
                                        payment={salePayment}
                                        onSubmitPay={onSubmitPay}
                                        totalAmounts={totalAmounts}
                                        exactDiscounts={exactDiscounts}
                                        contractorId={sale?.contractor?.id}
                                        percentDiscount={sale?.percent_discount}
                                    />
                                    {!sale?.is_approved &&
                                        <WarehouseOperationWithoutPayment onClick={onSubmitPay} id={sale?.id}/>
                                    }
                                    <Button
                                        variant="falcon-info"
                                        title={t(lang, "warehouse.operation.item.common.button.save_draft")}
                                        size="sm"
                                        className="me-1"
                                        as={Link}
                                        to={`./view/${sale?.id}`}
                                    >{t(lang, "warehouse.operation.item.common.button.save_draft")}</Button>
                                </div>
                            }
                            <div>
                                <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                <SoftBadge bg={sale?.is_approved ? 'success' : 'warning'}>
                                    {t(lang, isApprovedCheckedWarehouseOperation(!!sale?.is_approved))}
                                </SoftBadge>
                            </div>
                        </Col>
                    </Row>
                    <SaleExcelForm />
                </Card.Body>
            </Card>
        )
}


export default AddSaleExcel;
