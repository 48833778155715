import React from 'react'
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux';
import {Button, Col, Form, Row} from 'react-bootstrap'
import DatePicker from "react-datepicker";
import {selectDateFormat} from "../../../app/store/reducers/main/mainReducer";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import SelectYesOrNo from "../SelectYesOrNo";
import SelectStatus from "../SelectStatus";
import {AttorneyStatuses} from "../../../enum/AttorneyStatus";

function AttorneyDataTableFilter() {
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, control} = useFormContext();

    return (
        <>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.attorney.datatable.header.filter.title")}</h4>
            </Row>
            <Row className="mb-3 g-3">
                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, "edi.attorney.datatable.header.filter.number")}</Form.Label>
                    <Form.Control {...register("attorney_number")}
                                  placeholder={t(lang, "edi.attorney.datatable.header.filter.number:placeholder")}/>
                </Form.Group>

                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, "edi.attorney.datatable.header.filter.date_start")}</Form.Label>
                    <Controller
                        control={control}
                        name="created_on_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "edi.attorney.datatable.header.filter.date_start:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, "edi.attorney.datatable.header.filter.date_end")}</Form.Label>
                    <Controller
                        control={control}
                        name="created_on_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "edi.attorney.datatable.header.filter.date_end:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => {
                                    field.onChange(date)
                                }}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, "edi.attorney.datatable.header.filter.status")}</Form.Label>
                    <Controller
                        name="status"
                        render={({field}) => (
                            <SelectStatus statuses={AttorneyStatuses}
                                          defaultStatus={field.value}
                                          isClearable
                                          placeholder={t(lang, "edi.common.button.select")}
                                          onChange={field.onChange}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, "edi.attorney.datatable.header.filter.contractor_inn")}</Form.Label>
                    <Form.Control name="contractor_inn"
                                  type="text"
                                  placeholder={t(lang, "edi.attorney.datatable.header.filter.contractor_inn:placeholder")}
                                  {...register('contractor_inn')}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3 g-3 justify-content-end">
                <Form.Group as={Col} sm="auto">
                    <Button variant="primary"
                            type='submit'>{t(lang, "edi.attorney.datatable.header.filter.show")}</Button>
                </Form.Group>
            </Row>
        </>
    )
}

export default AttorneyDataTableFilter;