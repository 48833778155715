import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import SelectContractor from "../../contractor/SelectContractor";
import IconButton from "../../../../common/IconButton";
import {Link} from "react-router-dom";

const CrmCustomerOrderDataTableFilter = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, control} = useFormContext();
    return (
        <Fragment>
            <Row className={'mb-3 g-3 justify-content-between align-items-end'}>
                <Form.Group as={Col} sm={6} xl={3}>
                    <Form.Label>Поставщик</Form.Label>
                    <Controller
                        control={control}
                        name="executor"
                        render={({field}) => (
                            <SelectContractor
                                placeholder={t(lang, "edi.common.button.select")}
                                classNamePrefix="react-select"
                                onChange={field.onChange}
                                defaultValue={field?.value?.id}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} xl={3}>
                    <Form.Label>{t(lang, "crm.common.order.number")}</Form.Label>
                    <Form.Control type="text"
                                  placeholder={t(lang, "crm.common.order.number.placeholder")}
                                  {...register('number')}
                    />
                </Form.Group>
                <Col as={Col} className={'d-flex justify-content-end'}>
                    <Form.Group>
                        <Button variant="primary"
                                type='submit'>{t(lang, "crm.common.datatable.filter.show_button")}</Button>
                    </Form.Group>
                    <Form.Group>
                        <Link to="/crm/order/customer/register">
                            <IconButton variant="falcon-primary"
                                        icon="fa-plus"
                                        className={'ms-2'}
                            >Создать новый</IconButton>
                        </Link>
                    </Form.Group>
                </Col>
            </Row>
        </Fragment>
    );
};

export default CrmCustomerOrderDataTableFilter;