import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import IconButton from "../../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";

const PackageMeasurementRow = ({fieldName, onRightClickHandler}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const {error} = form.getFieldState(fieldName, form.formState);

    const {
        fields: packageMeasurements,
        append: appendPackageMeasurement,
        remove: removePackageMeasurement,
    } = useFieldArray({name: `${fieldName}`});

    return (
        <React.Fragment>
            <h6 className="text-info cursor-pointer d-inline-block" onClick={() => appendPackageMeasurement({
                name: '',
                quantity: ''
            })}>{t(lang, 'items.common.add_package_measurement')}</h6>
            <Row className="gy-3">
                {packageMeasurements.map((pck, index) => (
                    <React.Fragment key={pck.id}>
                        <Col lg={5}>
                            <Form.Group>
                                {index === 0 && <Form.Label>
                                    {t(lang, 'items.common.package_measurement.name')}
                                    <span className="text-danger">*</span>
                                </Form.Label>}
                                <Controller name={`${fieldName}.${index}.name`}
                                            rules={{
                                                required: t(lang, 'items.common.validations.is_required')
                                            }}
                                            render={({field}) => (
                                                <>
                                                    <Form.Control name={field.name}
                                                                  placeholder={t(lang, 'items.common.package_measurement.name.placeholder')}
                                                                  isInvalid={error?.name}
                                                                  defaultValue={field.value}
                                                                  onChange={field.onChange}
                                                                  onContextMenu={onRightClickHandler}
                                                    />
                                                    <ErrorMessage name='name'
                                                                  errors={error}
                                                                  as={<Form.Control.Feedback type="invalid"/>}
                                                    />
                                                </>
                                            )}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group>
                                {index === 0 && <Form.Label>
                                    {t(lang, 'items.common.package_measurement.quantity')}
                                    <span className="text-danger">*</span>
                                </Form.Label>}
                                <Controller name={`${fieldName}.${index}.quantity`}
                                            rules={{
                                                required: t(lang, 'items.common.validations.is_required')
                                            }}
                                            render={({field}) => (
                                                <>
                                                    <Form.Control name={field.name}
                                                                  isInvalid={error?.quantity}
                                                                  placeholder={t(lang, 'items.common.package_measurement.quantity.placeholder')}
                                                                  value={field.value}
                                                                  onChange={field.onChange}
                                                                  onContextMenu={onRightClickHandler}
                                                    />
                                                    <ErrorMessage name='quantity'
                                                                  errors={error}
                                                                  as={<Form.Control.Feedback type="invalid"/>}
                                                    />
                                                </>
                                            )}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={2}>
                            <Form.Group>
                                <IconButton variant="falcon-default"
                                            icon={faTrash}
                                            iconClassName={"text-danger"}
                                            className={classNames({'mt-4': index === 0})}
                                            onClick={() => removePackageMeasurement(index)}
                                />
                            </Form.Group>
                        </Col>
                    </React.Fragment>

                ))}
            </Row>
        </React.Fragment>
    );
};

export default PackageMeasurementRow;