import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    loadDesktopAppVersionsAsync,
    loadDesktopAppVersionsCountAsync,
    selectDesktopAppVersionsFilterOptions
} from "../../../../app/store/reducers/desktop-app/desktopAppReducer";
import {
    ACTIVATE_DESKTOP_APP_VERSION_SUCCEED,
    ADD_NEW_DESKTOP_APP_VERSION_SUCCEED,
    DEACTIVATE_DESKTOP_APP_VERSION_SUCCEED,
    DELETE_DESKTOP_APP_VERSION_SUCCEED
} from "../../../../app/eventbus/desktopAppEvents";
import DesktopAppVersionsDatatable from "../../../../components/admin/desktop-app/datatable/DesktopAppVersionsDatatable";

const DesktopAppVersions = () => {
    const filter = useSelector(selectDesktopAppVersionsFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch();

    useEffect(() => {
        const onDesktopAppNewVersionSuccessfullyAdded = EventBus.on(ADD_NEW_DESKTOP_APP_VERSION_SUCCEED, () => {
            loadDesktopAppVersions();
        });

        const onDesktopAppNewVersionSuccessfullyDeleted = EventBus.on(DELETE_DESKTOP_APP_VERSION_SUCCEED, () => {
            loadDesktopAppVersions();
        });

        const onDesktopAppNewVersionSuccessfullyActivated = EventBus.on(ACTIVATE_DESKTOP_APP_VERSION_SUCCEED, () => {
            loadDesktopAppVersions();
        });

        const onDesktopAppNewVersionSuccessfullyDeactivated = EventBus.on(DEACTIVATE_DESKTOP_APP_VERSION_SUCCEED, () => {
            loadDesktopAppVersions();
        });

        return () => {
            EventBus.remove(ADD_NEW_DESKTOP_APP_VERSION_SUCCEED, onDesktopAppNewVersionSuccessfullyAdded);
            EventBus.remove(DELETE_DESKTOP_APP_VERSION_SUCCEED, onDesktopAppNewVersionSuccessfullyDeleted);
            EventBus.remove(ACTIVATE_DESKTOP_APP_VERSION_SUCCEED, onDesktopAppNewVersionSuccessfullyActivated);
            EventBus.remove(DEACTIVATE_DESKTOP_APP_VERSION_SUCCEED, onDesktopAppNewVersionSuccessfullyDeactivated);
        }
    }, [])

    useEffect(() => {
        loadDesktopAppVersions();
    }, [filter, activeOrganization])

    const loadDesktopAppVersions = () => {
        dispatch(loadDesktopAppVersionsAsync(filter));
        dispatch(loadDesktopAppVersionsCountAsync(filter));
    }

    return <DesktopAppVersionsDatatable/>;
};

export default DesktopAppVersions;