import IconButton from "../../../common/IconButton";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import ContractorForm from "./ContractorForm";
import {loadAllContractorAsync, registerContractorAsync, setDefaultContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import {toast} from "react-toastify";

const AddContractor = ({sizeAndNameClose, disabled, changeNewContractorValue}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOnSubmit = (formData) => {
        let phones = formData.phones.filter(p => p.value)
        phones = phones.map(phone => {
            if (phone.value) {
                let ph_value = phone.value.replace(/ /g, '')
                ph_value = '998' + ph_value;
                return {
                    type: 1,
                    value: ph_value
                }
            }
        });
        let emails = formData.emails.filter(e => e.value)
        emails = emails.map(email => {
            if (email.value)
                return {
                    type: 3,
                    value: email.value
                }
        });
        const contacts = phones.concat(emails)

        sizeAndNameClose && changeNewContractorValue({name: formData.name, inn: formData.inn})

        const startingDebts = formData.starting_debt_states.map(startingDebt => {
            return {
                amount: Number(startingDebt.amount),
                currency_id: startingDebt.currency_id,
            }
        });

        setLoading(true)

        dispatch(registerContractorAsync({
            name: formData.name,
            inn: formData.inn,
            is_default: formData.is_default,
            organization_code: formData.organization_code || null,
            phone: formData.phone,
            type: formData.type.map(t => +t),
            contacts: contacts,
            starting_debts: startingDebts,
        })).then(res => {
            if (formData.is_default) {
                dispatch(setDefaultContractor(res.id)).then(res => {
                }).catch(err => {
                    toast.error(t(lang, "edi.common.toast.error"));
                });
            }
            dispatch(loadAllContractorAsync());
            toast.success(t(lang, "edi.common.toast.success"));
            setShow(false)
        }).catch(err => {
            toast.error(t(lang, "edi.common.toast.error"));
        }).finally(() => {
            setLoading(false)
        });
    }
    return (<>
        <IconButton
            variant="falcon-default"
            disabled={disabled}
            size={!sizeAndNameClose && "sm"}
            icon="plus"
            onClick={() => setShow(true)}
            transform="shrink-3">
            {!sizeAndNameClose &&
            <span className="d-none d-sm-inline-block ms-1">
                    {t(lang, "edi.common.add_button")}
                </span>
            }
        </IconButton>
        <Modal show={show} onHide={() => {
            setShow(false)
        }} size="xl">
            <Modal.Header>
                <Modal.Title>{t(lang, "edi.contractor.add_contractor_title")}</Modal.Title>
                <FalconCloseButton onClick={() => {
                    setShow(false)
                }}/>
            </Modal.Header>
            <Modal.Body>
                <ContractorForm onClose={() => setShow(false)} loading={loading} onSubmit={handleOnSubmit} isEditing={false}/>
            </Modal.Body>
        </Modal>
    </>)
}
export default AddContractor