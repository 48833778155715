import React, {useCallback, useEffect, useMemo} from 'react';
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    categorySlice,
    loadCategoriesAsync,
    loadCountCategoriesAsync,
    selectCategories,
    selectFilters,
    selectPagination, updateCategories, updateCount
} from "../../../../../app/store/reducers/category/categoryReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {debounce} from "underscore";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {Card, Dropdown} from "react-bootstrap";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import DataTableFooter from "./DataTableFooter";
import DataTableHeader from "./DataTableHeader";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ADD_CATEGORY_SUCCESS,
    BULK_DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_SUCCESS,
    EDIT_CATEGORY_SUCCESS
} from "../../../../../app/eventbus/itemEvents";
import dayjs from "dayjs";
import CardDropdown from "../../../../common/CardDropdown";
import DeleteCategory from "../DeleteCategory";
import EditCategoryDropdownItem from "../EditCategoryDropdownItem";

const CategoriesDataTable = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const categories = useSelector(selectCategories);
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectFilters, shallowEqual);
    const pagination = useSelector(selectPagination, shallowEqual);
    const dateDayJsFormat = useSelector(selectDateDayJSFormat);

    const columns = useMemo(() => {
        return [
            {
                Header: '#',
                Cell: ({row}) => `${(pagination.page - 1) * pagination.limit + row.index + 1}`,
                cellProps: {
                    width: '30px'
                }
            },
            {
                Header: t(lang, 'items.common.images'),
                accessor: 'image',
                Cell: ({row: {original}}) => {
                    if (original.image === null) return null;
                    return <img src={`${process.env.REACT_APP_HOST}/cdn/${original.image.path}`} alt={original.image.name} style={{width: '60px', height: '60px'}}/>
                }
            },
            {
                Header: t(lang, 'items.common.name'),
                accessor: 'name'
            },
            {
                Header: t(lang,'items.common.category.created_at'),
                accessor: 'created_at',
                Cell: ({row: {original}}) => dayjs(original.created_at).format(dateDayJsFormat)
            },
            {
                accessor: 'none',
                Cell: ({row: {original}}) => {
                    return (
                        <CardDropdown>
                            <div className="py-2">
                                <EditCategoryDropdownItem categoryId={original?.id}/>
                                <Dropdown.Divider as="div" />
                                <DeleteCategory category={original}/>
                            </div>
                        </CardDropdown>
                    )
                }
            }
        ];
    }, [categories, lang])

    const load = useCallback(debounce((filters, pagination) => {
        loadCategoriesAsync(filters, pagination)
            .then((categories) => dispatch(updateCategories(categories)))
            .catch(() => dispatch(updateCategories([])))

        loadCountCategoriesAsync(filters)
            .then((count) => dispatch(updateCount(count)))
            .catch((error) => dispatch(updateCount(0)))
    }, 50), [dispatch])

    useEffect(() => {
        load(filters, pagination);

        const onCategoryAddSuccessHandler = EventBus.on(ADD_CATEGORY_SUCCESS, () => {
            load(filters, pagination)
        });
        const onCategoryEditSuccessHandler = EventBus.on(EDIT_CATEGORY_SUCCESS, () => {
            load(filters, pagination)
        });
        const onCategoryDeleteSuccessHandler = EventBus.on(DELETE_CATEGORY_SUCCESS, () => {
            load(filters, pagination)
        });
        const onCategoryBulkDeleteSuccessHandler = EventBus.on(BULK_DELETE_CATEGORY_SUCCESS, () => {
            load(filters, pagination)
        });

        return () => {
            EventBus.remove(ADD_CATEGORY_SUCCESS, onCategoryAddSuccessHandler);
            EventBus.remove(EDIT_CATEGORY_SUCCESS, onCategoryEditSuccessHandler);
            EventBus.remove(DELETE_CATEGORY_SUCCESS, onCategoryDeleteSuccessHandler);
            EventBus.remove(BULK_DELETE_CATEGORY_SUCCESS, onCategoryBulkDeleteSuccessHandler);
        }
    }, [activeOrganization, filters, pagination])

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={categories}
            pagination
            selection
            width="50px"
            perPage={1000}
        >
            <Card>
                <Card.Header>
                    <DataTableHeader load={load} table/>
                </Card.Header>
                <Card.Body>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden',
                            style: {
                                minHeight: '250px'
                            }
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <DataTableFooter />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default CategoriesDataTable;
