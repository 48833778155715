import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Col, Collapse, Form, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {faSignature, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import {
    changeFilterOptionsAsync,
    selectCountEmpowerments
} from "../../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import EmpowermentDataTableFilter from "./EmpowermentDataTableFilter";
import {VIEWER_DRAFT} from "../../../../../enum/EmpowermentStatus";
import SoftBadge from "../../../../common/SoftBadge";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingEmpowermentCreate} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";


const EmpowermentDataTableHeader = ({selectedRowIds, onClickBulkSign, page, viewer}) => {
    const dispatch = useDispatch();
    const methods = useForm();
    const currentCertificate = useSelector(selectCurrentCertificate);
    const empowermentCount = useSelector(selectCountEmpowerments)
    const lang = useSelector(selectLang);
    const t = Translate;

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false)


    const onSubmitFilter = ({...filters}) => {
        dispatch(changeFilterOptionsAsync({
            page: 1,
            ...filters
        }))
    }
    const onBulkSign = async () => {
        let selectEmpowerment = Object.keys(selectedRowIds).map((key) => {
            let value = selectedRowIds[key]
            if (value) {
                return page[key].original
            }
            return false
        })
        onClickBulkSign(selectEmpowerment)
        return selectEmpowerment
    }

    return (
        <Row className="flex-between-center">
            <Collapse in={isOpenFilterCollapse}>
                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <EmpowermentDataTableFilter/>
                        </Form>
                    </FormProvider>
                    <hr/>
                </Col>
            </Collapse>
            <Col xs={4} sm="auto" className="d-flex flex-column pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.attorney.datatable.header.title")}</h5>
                <div className="mt-1">
                    {t(lang,"roaming.common.count")}:
                    <SoftBadge bg='success' className='ms-2'>
                        {empowermentCount}
                    </SoftBadge>
                </div>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <IconButton
                            onClick={onBulkSign}
                            disabled={!currentCertificate}
                            variant="falcon-default"
                            size="sm"
                            type="button"
                            icon={faSignature}
                            iconClassName="me-1"
                            className="ms-2"
                        >{t(lang, "edi.attorney.datatable.header.navigation.sign")}
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            type="button"
                            icon={faTrash}
                            iconClassName="me-1"
                            className="ms-2"
                        >{t(lang, "edi.attorney.datatable.header.navigation.delete")}
                        </IconButton>
                    </div>
                ) : (
                    <div id="attorney-actions">
                            <IconButton
                                as={dispatch(checkPermission(PermissionRoamingEmpowermentCreate)) ? Link : 'div'}
                                onClick={()=>!dispatch(checkPermission(PermissionRoamingEmpowermentCreate)) && dispatch(toastPermission())}
                                to="../empowerment/send"
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, "edi.attorney.datatable.header.navigation.create")}
                                </span>
                            </IconButton>
                        {
                            viewer === VIEWER_DRAFT
                            ?
                            ''
                            :
                            <>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    transform="shrink-3"
                                    icon="refresh"
                                    className="mx-2"
                                    onClick={methods.handleSubmit(onSubmitFilter)}
                                >
                                    <span className="d-none d-sm-inline-block ms-1">
                                        {t(lang, "edi.orders.order.resume_order_button")}
                                    </span>
                                </IconButton>
                                <IconButton
                                    onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="filter"
                                    transform="shrink-3"
                                >
                                    <span className="d-none d-sm-inline-block ms-1">
                                        {t(lang, "edi.attorney.datatable.header.navigation.filter")}
                                    </span>
                                </IconButton>
                            </>
                        }
                    </div>
                )}
            </Col>
        </Row>
    );
};

EmpowermentDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default EmpowermentDataTableHeader;
