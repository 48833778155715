import React from "react";
import {ListGroup} from "react-bootstrap";
import {Link, useRouteMatch} from "react-router-dom";
import Flex from "../../../components/common/Flex";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const Dashboard = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {url} = useRouteMatch()

    return (
        <ListGroup>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/invoices/inbox`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "roaming.dashboard.invoices.title")}</h5>
                </Flex>
                <p className="mb-1">{t(lang, "roaming.dashboard.invoices.description")}</p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/v2-waybills`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'roaming.dashboard.waybill.v2.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'roaming.dashboard.waybill.subtitle')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contracts/inbox`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'roaming.dashboard.contracts.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'roaming.dashboard.contracts.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/empowerments`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "roaming.dashboard.empowerments")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'roaming.dashboard.empowerments_title')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/verification-acts/inbox`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'roaming.dashboard.verification_act.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'roaming.dashboard.verification_act.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/acts/inbox`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, "roaming.act.dashboard.title")}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang,"roaming.act.dashboard.description")}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/free-forms`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'roaming.dashboard.free_form_document.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'roaming.dashboard.free_form_document.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/excel-registries`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'roaming.dashboard.excel_registry.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'roaming.dashboard.excel_registry.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/waybills`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'roaming.dashboard.waybill.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'roaming.dashboard.waybill.subtitle')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/organization-addresses`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.navbar.routes.edo.company_address')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, '')}
                </p>
            </ListGroup.Item>
        </ListGroup>
    )
};

export default Dashboard;
