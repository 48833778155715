import React, {useRef} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {OverlayTrigger, Popover} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import {
    CatalogCodeAndPackageOk,
    CatalogCodeDeleted,
    CatalogCodeInvalidPackage,
    CatalogCodeMoved,
    ServerError
} from "../enum/itemWrapper";
import dayjs from 'dayjs';

const CatalogStatusBadge = ({catalogCode, tasnif_info, badgeProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);

    const getBadgeVariant = (tasnif_info) => {
        if (tasnif_info === null)
            return "primary";

        switch (tasnif_info.status) {
            case CatalogCodeAndPackageOk:
                return "success";
            case CatalogCodeMoved:
            case CatalogCodeInvalidPackage:
                return "warning";
            case CatalogCodeDeleted:
                return "danger";
            case ServerError:
                return "secondary";
            default:
                return "secondary";
        }
    };
    const getBadgeText = (tasnif_info) => {
        if (tasnif_info === null)
            return t(lang, "items.common.catalog.unknown");

        switch (tasnif_info.status) {
            case CatalogCodeAndPackageOk:
                return t(lang, "items.common.catalog.is_active");
            case CatalogCodeMoved:
                return t(lang, "items.common.catalog.moved_to", {code: tasnif_info.moved});
            case CatalogCodeInvalidPackage:
                return t(lang, "items.common.catalog.package_edited");
            case CatalogCodeDeleted:
                return t(lang, "items.common.catalog.deleted");
            case ServerError:
                return t(lang, "items.common.catalog.server_error");
            default:
                return t(lang, "items.common.catalog.unknown");
        }
    };
    const getPopoverBodyText = (tasnif_info) => {
        if (tasnif_info === null)
            return t(lang, "items.common.catalog.popover_text");
        return t(lang, "items.common.catalog.last_sync_time", {time: dayjs(tasnif_info.check_date).format("YYYY-MM-DD HH:mm:ss")})
    };
    const badgeVariant = getBadgeVariant(tasnif_info);
    const badgeText = getBadgeText(tasnif_info);
    const popoverBodyText = getPopoverBodyText(tasnif_info);

    return (
        <OverlayTrigger trigger={['hover', 'focus']}
                        placement="right"
                        overlay={
                            <Popover id="popover-basic" className='ms-0'>
                                <Popover.Header>
                                    <SoftBadge bg={badgeVariant}>
                                        <span className="fs--1 fw-bold">{t(lang, 'items.common.catalog')}</span>
                                    </SoftBadge>
                                </Popover.Header>
                                <Popover.Body>
                                    <h5>
                                        <span className='me-2'>{t(lang, 'items.common.catalog.status')}:</span>
                                        <SoftBadge bg={badgeVariant}>{badgeText}</SoftBadge>
                                    </h5>
                                    {popoverBodyText}
                                </Popover.Body>
                            </Popover>
                        }
                        target={ref.current}
        >
            <SoftBadge bg={badgeVariant} ref={ref} {...badgeProps}>{catalogCode}</SoftBadge>
        </OverlayTrigger>
    )
}

export default CatalogStatusBadge;