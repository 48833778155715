import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorReturnInvoiceSlice = createSlice({
    name: 'contractorReturnInvoice',
    initialState: {
        returnInvoices: [],
        countReturnInvoices: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateReturnInvoices: (state, action) => {
            state.returnInvoices = action.payload
        },
        updateCountReturnInvoices: (state, action) => {
            state.countReturnInvoices = action.payload
        },
        updateFilterReturnInvoice: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorReturnInvoicesAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorReturnInvoices(params)
        .then(response => {
            dispatch(contractorReturnInvoiceSlice.actions.updateReturnInvoices(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const loadContractorReturnInvoicesCountAsync = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    contractorService.getContractorReturnInvoiceCount(other)
        .then(response => {
            dispatch(contractorReturnInvoiceSlice.actions.updateCountReturnInvoices(response.data))
        })
        .catch(error => console.log(error))
}
export const changeFilterContractorReturnInvoicesAsync = (params) => (dispatch) => {
    return dispatch(contractorReturnInvoiceSlice.actions.updateFilterReturnInvoice(params))
}
export const selectContractorReturnInvoices = (state) => state.contractorReturnInvoice.returnInvoices;
export const selectContractorCountReturnInvoices = (state) => state.contractorReturnInvoice.countReturnInvoices;
export const selectContractorReturnInvoiceFilterOptions = (state) => state.contractorReturnInvoice.filterOptions;

export default contractorReturnInvoiceSlice.reducer