import React, {useState} from 'react';
import {Card, Col, Nav, Row, Tab} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ViewSupervisorItemsDataTable from "./ViewSupervisorItemsDataTable";
import ViewSupervisorSalesDataTable from "./ViewSupervisorSalesDataTable";
import ViewSupervisorCashBoxesDataTable from "./ViewSupervisorCashBoxesDataTable";

const SupervisorBinding = ({binding}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [eventKey, setEventKey] = useState('1');

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <Tab.Container defaultActiveKey={eventKey}
                               onSelect={setEventKey}
                >
                    <Card>
                        <Card.Header>
                            <Card.Title>{binding.owner_organization_name}</Card.Title>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey={'1'} className="cursor-pointer">
                                        {t(lang, 'items.table.title')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={'2'} className="cursor-pointer">
                                        {t(lang, 'partners.common.sales')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={'3'} className="cursor-pointer">
                                        {t(lang, 'warehouse.operation.item.common.cash_box')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Content>
                                <Tab.Pane eventKey={'1'}>
                                    <ViewSupervisorItemsDataTable binding={binding}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey={'2'}>
                                    <ViewSupervisorSalesDataTable binding={binding}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey={'3'}>
                                    <ViewSupervisorCashBoxesDataTable binding={binding}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Card.Body>
                    </Card>
                </Tab.Container>
            </Col>
        </Row>
    )
};

export default SupervisorBinding;