import {createSlice} from "@reduxjs/toolkit";
import currencyService from "../../../../services/currencyService";
import EventBus from "../../../eventbus/EventBus";
import {
    CURRENCY_ACTIVATE_FAILED,
    CURRENCY_ACTIVATE_REQUESTED,
    CURRENCY_ACTIVATE_SUCCESS,
    CURRENCY_CONVERTER_CONVERT_FAILED,
    CURRENCY_CONVERTER_CONVERT_REQUESTED,
    CURRENCY_CONVERTER_CONVERT_SUCCESS,
    CURRENCY_DEACTIVATE_FAILED,
    CURRENCY_DEACTIVATE_REQUESTED,
    CURRENCY_DEACTIVATE_SUCCESS,
    CURRENCY_GET_FAILED,
    CURRENCY_GET_REQUESTED,
    CURRENCY_GET_SUCCESS,
    CURRENCY_UPDATE_RATE_FAILED,
    CURRENCY_UPDATE_RATE_REQUESTED,
    CURRENCY_UPDATE_RATE_SUCCESS,
    SET_DEFAULT_CURRENCY_FAILED,
    SET_DEFAULT_CURRENCY_REQUESTED,
    SET_DEFAULT_CURRENCY_SUCCESS,
    UNSET_DEFAULT_CURRENCY_FAILED,
    UNSET_DEFAULT_CURRENCY_REQUESTED,
    UNSET_DEFAULT_CURRENCY_SUCCESS
} from "../../../eventbus/currencyEvents";


export const CURRENCY_INITIALIZATION_STATE_NOT_STARTED = 0
export const CURRENCY_INITIALIZATION_STATE_LOADING = 1
export const CURRENCY_INITIALIZATION_STATE_COMPLETE = 2

const currencySlice = createSlice({
    name: 'currency',
    initialState: {
        currencies: [],
        currencyInitializationState: CURRENCY_INITIALIZATION_STATE_NOT_STARTED,
    },
    reducers: {
        setCurrencies: (state, action) => {
            state.currencies = action.payload
        },
        setCurrencyInitializationState: (state, action) => {
            state.currencyInitializationState = action.payload
        },
    }
})


export const currencyGetAsync = () => (dispatch) => {
    const params = {}

    EventBus.dispatch(CURRENCY_GET_REQUESTED)
    dispatch(currencySlice.actions.setCurrencyInitializationState(CURRENCY_INITIALIZATION_STATE_LOADING));
    return new Promise(() => {
        currencyService.currencyGet(params)
            .then(response => {
                dispatch(currencySlice.actions.setCurrencies(response.data))
                EventBus.dispatch(CURRENCY_GET_SUCCESS, response.data)
            })
            .catch(error => {
                EventBus.dispatch(CURRENCY_GET_FAILED, error.response)
            })
            .finally(() => {
                dispatch(currencySlice.actions.setCurrencyInitializationState(CURRENCY_INITIALIZATION_STATE_COMPLETE));
            })
    })
}

export const currencyUpdateRate = ({id, rate}) => {
    EventBus.dispatch(CURRENCY_UPDATE_RATE_REQUESTED)
    return new Promise(() => {
        currencyService.currencyUpdateRate({id, rate})
            .then(response => {
                EventBus.dispatch(CURRENCY_UPDATE_RATE_SUCCESS, response.data)
            })
            .catch(error => {
                EventBus.dispatch(CURRENCY_UPDATE_RATE_FAILED, error.response)
            })
    })
}

export const currencyActivateAsync = ({id}) => {
    EventBus.dispatch(CURRENCY_ACTIVATE_REQUESTED)
    return new Promise(() => {
        currencyService.currencyActivate({id})
            .then(response => {
                EventBus.dispatch(CURRENCY_ACTIVATE_SUCCESS, response.data)
            })
            .catch(error => {
                EventBus.dispatch(CURRENCY_ACTIVATE_FAILED, error.response)
            })
    })
}

export const currencyDeactivate = ({id}) => {
    EventBus.dispatch(CURRENCY_DEACTIVATE_REQUESTED)
    return new Promise(() => {
        currencyService.currencyDeactivate({id})
            .then(response => {
                EventBus.dispatch(CURRENCY_DEACTIVATE_SUCCESS, response)
            })
            .catch(error => {
                EventBus.dispatch(CURRENCY_DEACTIVATE_FAILED, error.response)
            })
    })
}

export const setDefaultCurrencyAsync = ({id}) => {
    EventBus.dispatch(SET_DEFAULT_CURRENCY_REQUESTED)
    return new Promise(() => {
        currencyService.setDefaultCurrency({id})
            .then(response => {
                EventBus.dispatch(SET_DEFAULT_CURRENCY_SUCCESS, response)
            })
            .catch(error => {
                EventBus.dispatch(SET_DEFAULT_CURRENCY_FAILED, error.response)
            })
    })
};

export const unsetDefaultCurrencyAsync = () => {
    EventBus.dispatch(UNSET_DEFAULT_CURRENCY_REQUESTED)
    return new Promise(() => {
        currencyService.unsetDefaultCurrency()
            .then(response => {
                EventBus.dispatch(UNSET_DEFAULT_CURRENCY_SUCCESS, response)
            })
            .catch(error => {
                EventBus.dispatch(UNSET_DEFAULT_CURRENCY_FAILED, error.response)
            })
    })
};

export const currencyConverterConvert = (payload) => {
    EventBus.dispatch(CURRENCY_CONVERTER_CONVERT_REQUESTED)

    return new Promise((resolve, reject) => {
        currencyService.currencyConverter(payload)
            .then(response => {
                resolve(response.data)
                EventBus.dispatch(CURRENCY_CONVERTER_CONVERT_SUCCESS, response.data)
            })
            .catch(error => {
                reject(error.response)
                EventBus.dispatch(CURRENCY_CONVERTER_CONVERT_FAILED, error.response)
            })
    })
}

export const selectCurrency = state => state.currency.currencies;
export const selectNationalCurrency = state => state.currency.currencies.find(c => c.is_national);
export const selectActiveCurrencies = state => state.currency.currencies.filter(c => c.is_active);
export const selectCurrencyInitializationState = state => state.currency.currencyInitializationState;

export default currencySlice.reducer
