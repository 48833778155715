import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {Button, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TYPE_INVOICE} from "../../../../enum/ExcelTemplateTypes";
import {excelFileUploader} from "../../../../helpers/excel-uploader";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {uploadInvoiceToExcelAsync} from "../../../../app/store/reducers/invoice/invoiceReducer";
import {selectOrganizationTemplates} from "../../../../app/store/reducers/excel/excelReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiInvoiceDownloadExcel} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const UploadInvoiceToExcel = ({id, number, ...props}) => {
    const dispatch = useDispatch()
    const [invoiceTypeTemplates, setInvoiceTypeTemplates] = useState([]);
    const organizationTemplates = useSelector(selectOrganizationTemplates);
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        const templates = organizationTemplates.filter(template => template.type === TYPE_INVOICE);
        setInvoiceTypeTemplates(templates);
    }, [organizationTemplates])

    const uploadInvoice = (uri) => {
        if(dispatch(checkPermission(PermissionEdiInvoiceDownloadExcel))) {
            setLoading(true);

            uploadInvoiceToExcelAsync({uri: uri, id: id})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'edi.invoice.excel_upload.file_name', {number: number}),
                    });
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else {
            dispatch(toastPermission())
        }

    }

    return (
        <>
            {invoiceTypeTemplates > 1
                ?
                <Dropdown {...props}>
                    <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                        {loading
                            ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                            : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                        }
                        <span className="d-none d-sm-inline-block">
                        {t(lang, 'edi.common.export_to_excel')}
                    </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='py-2'>
                        {invoiceTypeTemplates.map((template, index) => (
                            <Dropdown.Item key={index} onClick={() => uploadInvoice(template.uri)}>
                                {t(lang, 'main.common.file.upload.template_name.prefix', {template_name: t(lang, `common.excel.template.name.${template.name}`)})}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                :
                <Button {...props} variant="falcon-default" size="sm" onClick={() => {
                    const [defaultTemplate] = invoiceTypeTemplates;
                    uploadInvoice(defaultTemplate.uri);
                }}>
                    {loading
                        ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                        : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                    }

                    <span className="d-none d-sm-inline-block">{t(lang, 'edi.common.export_to_excel')}</span>
                </Button>
            }
        </>
    );
};

export default UploadInvoiceToExcel;
