import React, {useEffect} from 'react';
import {useFieldArray, useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Form, InputGroup} from "react-bootstrap";
import IconButton from "../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {ErrorMessage} from "@hookform/error-message";
import classNames from "classnames";
import itemWrapper from "../enum/itemWrapper";
import EventBus from "../../../../app/eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../app/eventbus/itemEvents";
import {getProductGuideAsync} from "../../../../app/store/reducers/item/itemReducer";
import {CheckScanBarcodeOrMarking} from "../../warehouse-operation/enum/warehouseOperationWrapper";


const barcodeRegExp = /^[0-9]{0,13}$/;
const fieldName = "barcodes";
const BarcodeRow = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const {fields, append, replace, remove} = useFieldArray({name: `${fieldName}`});
    const addNewBarcode = () => append({barcode: itemWrapper.generateGtinBarcode(new Date().getTime().toString().slice(5, 13))});
    const deleteBarcode = (index) => remove(index);

    const onScan = async (value) => {
        const barcodes = [];
        const cuteBarcode = CheckScanBarcodeOrMarking(value)
        barcodes.push({barcode: cuteBarcode});

        try {
            replace(barcodes);
            const {product_guide, tasnif_product_guide: catalog} = await getProductGuideAsync(cuteBarcode);

            if (product_guide?.name)
                form.setValue('name', product_guide.name);

            if (catalog) {
                if (!product_guide?.name)
                    form.setValue('name', catalog.name);

                form.setValue('tax.catalog', catalog);
                form.setValue('tax.package', (catalog?.use_package && catalog?.package_names?.length === 1) ? catalog.package_names[0] : null);
                form.setValue('tax.measurement', null);
                form.setValue('tax.benefit', null);
            }

            form.setFocus(!!form.getValues('name') ? 'commonPrice.price' : 'name');
        } catch (e) {
            replace(barcodes);
            form.setFocus('name');
            console.log(e);
        }
    }

    useEffect(() => {
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    }, [])

    return (
        <React.Fragment>
            <h6 className="text-info cursor-pointer d-inline-block" onClick={addNewBarcode}>{t(lang, 'items.common.add_barcode')}</h6>
            {fields.map((field, index) => {
                const {error} = form.getFieldState(`${fieldName}.${index}`);
                return (
                    <Form.Group key={field.id} className="mb-2">
                        {index === 0 && <Form.Label>
                            {t(lang, 'items.common.barcode')}
                            <span className="text-danger">*</span>
                        </Form.Label>}
                        <InputGroup className={classNames({'is-invalid': error?.barcode})}>
                            <Form.Control placeholder={t(lang, 'items.common.barcode.placeholder')}
                                          name={`${fieldName}.${index}.barcode`}
                                          isInvalid={error?.barcode}
                                          {...form.register(`${fieldName}.${index}.barcode`, {
                                              required: t(lang, 'items.common.validations.is_required'),
                                              pattern: {
                                                  value: barcodeRegExp,
                                                  message: t(lang, "items.common.validations.invalid_barcode")
                                              }
                                          })}
                            />
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon={faTrash}
                                iconClassName={"text-danger"}
                                onClick={() => deleteBarcode(index)}
                            />
                        </InputGroup>
                        <ErrorMessage name='barcode'
                                      errors={error}
                                      as={<Form.Control.Feedback type="invalid"/>}
                        />
                    </Form.Group>
                )
            })}
        </React.Fragment>
    );
};

export default BarcodeRow;