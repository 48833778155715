import PropTypes from "prop-types";
import classNames from "classnames";
import React, {useState} from "react";
import ReactSelect from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {useForm, Controller} from "react-hook-form";
import IconButton from "../../../common/IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {OrderDeclineReasonStatuses} from "../../../../enum/reasons/OrderDeclineReasonType";
import {returnOrderExecutorDeclineAsync} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnOrderDownloadExcel} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const ReturnOrderExecutorDecline = ({id, ...props}) => {
    const dispatch = useDispatch()

    const {register, formState: {errors}, handleSubmit, reset, control} = useForm();
    const lang = useSelector(selectLang);
    const t = Translate;

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShow = () => {
        if(dispatch(checkPermission(PermissionEdiReturnOrderDownloadExcel))) setShow(true);
        else dispatch(toastPermission())
    };
    const handleClose = () => {
        setLoading(false);
        reset();
        setShow(false);
    };

    const onExecutorDeclined = (formData) => {
        setLoading(true);
        returnOrderExecutorDeclineAsync({
            id: id,
            reason: formData.reason,
            comment: formData.comment,
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };


    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-danger"
                size="sm"
                iconClassName="me-1"
                icon={'ban'}
                {...props}
            >{t(lang, 'edi.common.button.decline')}</IconButton>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onExecutorDeclined)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t(lang, 'edi.return_order.decline_return_order.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                            <Controller
                                name="reason"
                                control={control}
                                rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                render={({field}) => (
                                    <ReactSelect
                                        isClearable
                                        classNamePrefix="react-select"
                                        options={OrderDeclineReasonStatuses}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => t(lang, option.name)}
                                        onChange={option => field.onChange(option?.id)}
                                        className={classNames({'is-invalid': !!errors?.reason?.message})}
                                        placeholder={t(lang, "edi.common.modal.decline_reason.placeholder")}
                                    />
                                )}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors?.reason?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                {...register('comment')}
                                placeholder={t(lang, 'edi.common.leave_comment')}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {handleClose()}}>{t(lang, 'edi.common.button.close')}</Button>
                        <Button variant="warning" className="d-flex align-items-center" disabled={loading} type="submit">
                            {loading && <Spinner className="me-1" animation="border" role="status" size="sm" />}
                            {t(lang, 'edi.common.button.decline')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    )
};

ReturnOrderExecutorDecline.propTypes = {
    id: PropTypes.string,
};

ReturnOrderExecutorDecline.defaultProps = {
    id: '',
};

export default ReturnOrderExecutorDecline;
