import React from 'react';
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import ShipmentForm from "./forms/ShipmentForm";
import {FormProvider, useForm} from "react-hook-form";
import ConvertQuantityToWord from "../../../app/filter/ConvertQuantityToWord";
import {editShipmentAsync} from "../../../app/store/reducers/shipment/shipmentReducer";
import {SumLimit} from '../../../app/order/OrderItemQuantityLimitStrategyType'
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {selectEdiSettings, updateEdiSettings} from "../../../app/store/reducers/settings/ediSettingsReducer";
import {Roadway} from "../roaming/waybill/forms/SendWaybillFormV2";

const ShipmentExecutorEditForm = ({shipment, order}) => {
    const settings = useSelector(selectEdiSettings)
    const lang = useSelector(selectLang);
    const t = Translate;

    const isSumLimit = order?.item_quantity_limit_strategy === SumLimit.id;
    const checkItems = (id) => shipment.items.find(item => item?.order_item?.id === id);

    const items = () => {
        let items = [];
        for (let i = 0; i < order.items.length; i++) {
            let orderItem = order.items[i];
            let shipmentItem = checkItems(orderItem?.id)

            if (shipmentItem) {
                items.push({
                    uid: i,
                    id: shipmentItem.order_item.id,
                    product: shipmentItem.product,
                    barcode: shipmentItem.barcode,
                    catalog_class_code: shipmentItem.catalog_class_code,
                    catalog_class_name: shipmentItem.catalog_class_name,
                    customer_shipment_id: shipmentItem.customer_shipment_id,
                    measurement: shipmentItem.package_code || shipmentItem.measurement,
                    package_code: shipmentItem.package_code,
                    package_name: shipmentItem.package_name,
                    quantity: shipmentItem.quantity,
                    price: shipmentItem.price,
                    total: shipmentItem.total,
                    nds_rate: shipmentItem.nds_rate,
                    nds_value: shipmentItem.nds_value,
                    total_with_nds: shipmentItem.total_with_nds,
                    quantity_left: isSumLimit ? shipmentItem.quantity + orderItem.quantity_left : orderItem.quantity_left,
                    marks: shipmentItem?.marks?.length ? shipmentItem?.marks?.map(i => {
                        return {code: i}
                    }) : [],
                })
            } else {
                items.push({
                    uid: i,
                    id: orderItem.id,
                    product: orderItem.product,
                    barcode: orderItem.barcode,
                    catalog_class_code: orderItem.catalog_class_code,
                    catalog_class_name: orderItem.catalog_class_name,
                    customer_shipment_id: orderItem.customer_shipment_id,
                    measurement: orderItem.package_code || orderItem.measurement,
                    package_code: orderItem.package_code,
                    package_name: orderItem.package_name,
                    quantity: 0,
                    price: orderItem.price,
                    total: orderItem.total,
                    nds_rate: orderItem.nds_rate,
                    nds_value: orderItem.nds_value,
                    quantity_left: orderItem.quantity_left,
                    total_with_nds: orderItem.total_with_nds,
                    marks: orderItem?.marks?.length ? orderItem?.marks?.map(i => {
                        return {code: i}
                    }): [],
                })
            }
        }
        return items
    }

    const methods = useForm({
        defaultValues: {
            executor: {
                inn_or_pinfl: shipment?.executor_info?.inn,
                name: shipment?.executor_info?.name,
            },
            customer: {
                inn_or_pinfl: shipment?.customer_info?.inn,
                name: shipment?.customer_info?.name,
            },
            freight_forwarder: {
                inn_or_pinfl: shipment?.freight_forwarder?.inn,
                name: shipment?.freight_forwarder?.name,
            },
            carrier: {
                inn_or_pinfl: shipment?.carrier?.inn,
                name: shipment?.carrier?.name,
            },
            responsible_person: {
                inn_or_pinfl: shipment?.responsible_person?.inn,
                name: shipment?.responsible_person?.name,
            },
            driver: {
                inn_or_pinfl: shipment?.driver?.inn,
                name: shipment?.driver?.name,
            },
            order_info: {
                number: shipment?.order?.info?.number,
                date: dayjs(shipment?.order?.info?.date, 'YYYY-MM-DD').toDate(),
                expires_on: dayjs(shipment?.order?.expires_on, 'YYYY-MM-DD').toDate(),
            },
            shipment_info: {
                number: shipment?.shipment_info?.number,
                date: dayjs(shipment?.shipment_info?.date, 'YYYY-MM-DD').toDate(),
            },
            contract_info: {
                number: shipment?.contract_info?.number,
                date: dayjs(shipment?.contract_info?.date, 'YYYY-MM-DD').toDate(),
            },
            transport: {
                registration_number: shipment?.transport?.registration_number,
            },
            total_distance: +shipment?.total_distance || 1,
            delivery_cost: +shipment?.delivery_cost || 1,
            total_delivery_cost: +shipment?.total_delivery_cost || 1,
            transport_type: Roadway,
            loading_point: {
                street: shipment.loading_point?.address || null,
                region_code: shipment.loading_point?.region_code || null,
                region_name: shipment.loading_point?.region_name || null,
                district_code: shipment.loading_point?.district_code || null,
                district_name: shipment.loading_point?.district_name || null,
                village_code: shipment.loading_point?.village_code || null,
                village_name: shipment.loading_point?.village_name || null,
                longitude: +shipment.loading_point?.longitude || null,
                latitude: +shipment.loading_point?.latitude || null,
            },
            unloading_point: {
                street: shipment.unloading_point?.address || null,
                region_code: shipment.unloading_point?.region_code || null,
                region_name: shipment.unloading_point?.region_name || null,
                district_code: shipment.unloading_point?.district_code || null,
                district_name: shipment.unloading_point?.district_name || null,
                village_code: shipment.unloading_point?.village_code || null,
                village_name: shipment.unloading_point?.village_name || null,
                longitude: +shipment.unloading_point?.longitude || null,
                latitude: +shipment.unloading_point?.latitude || null,
            },
            customer_shipment_id: shipment.order.customer_shipment_id,
            items: items(),
            totalWithNds: null,
            payer_contract_info: {
                number: shipment.payer_contract_info?.number || null,
                date: dayjs(shipment.payer_contract_info?.date, 'YYYY-MM-DD').toDate() || null,
            }
        }
    });

    const onSubmit = (form) => {
        const activeDriver = form?.car_info?.drivers?.find(d => d?.label === form?.car_info?.name)
        const activeNumber = form?.car_info?.numbers?.find(d => d?.label === form?.car_info?.serial_number)
        const activeModel = form?.car_info?.models?.find(d => d?.label === form?.car_info?.mark)

        const total_distance = typeof form.total_distance === 'string' ? form.total_distance.replace(/ /g, '') : form.total_distance
        const delivery_cost = typeof form.delivery_cost === 'string' ? form.delivery_cost.replace(/ /g, '') : form.delivery_cost
        const total_delivery_cost = typeof form.total_delivery_cost === 'string' ? form.total_delivery_cost.replace(/ /g, '') : form.total_delivery_cost

        const shipmentSettingsJson = {
            edi: {
                shipment: {
                    vehicles: {
                        drivers: form?.car_info?.drivers.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeDriver?.label === driver?.label
                            }
                        }),
                        numbers: form?.car_info?.numbers.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeNumber?.label === driver?.label
                            }
                        }),
                        models: form?.car_info?.models.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeModel?.label === driver?.label
                            }
                        })
                    }
                },
                return_shipment: {
                    vehicles: settings?.return_shipment?.vehicles
                },
                should_validate_invoice_number: settings?.should_validate_invoice_number
            },
        }

        const payload= {
            order_id: order.id,
            customer_info: {
                inn: order.customer.inn,
                name: order.customer.name
            },
            executor_info: {
                inn: order.executor.inn,
                name: order.executor.name,
            },
            car_info: {
                serial_number: form.transport?.registration_number?.registration_number || null,
                mark: form.transport?.registration_number?.model || null,
            },
            shipment_info: {
                number: form.shipment_info.number,
                date: dayjs(form.shipment_info.date).format('YYYY-MM-DD'),
            },
            contract_info: {
                number: form.contract_info.number,
                date: dayjs(form.contract_info.date).format('YYYY-MM-DD'),
            },
            total_in_words: ConvertQuantityToWord.getText(parseInt(form.totalWithNds)),
            total_distance: +total_distance,
            delivery_cost: +delivery_cost,
            total_delivery_cost: +total_delivery_cost,
            loading_point: {
                address: form.loading_point.street, // required when company_address_id = null and branch_id = null
                region_code: form.loading_point.region_code, // required when company_address_id = null and branch_id = null
                region_name: form.loading_point.region_name, // required when company_address_id = null and branch_id = null
                district_code: form.loading_point.district_code, // required when company_address_id = null and branch_id = null
                district_name: form.loading_point.district_name, // required when company_address_id = null and branch_id = null
                village_code: form.loading_point.village_code, // required when company_address_id = null and branch_id = null
                village_name: form.loading_point.village_name, // required when company_address_id = null and branch_id = null
                longitude: +form.loading_point.longitude, // required when company_address_id = null and branch_id = null
                latitude: +form.loading_point.latitude,// required when company_address_id = null and branch_id = null
            },
            unloading_point: {
                address: form.unloading_point.street, // required when company_address_id = null and branch_id = null
                region_code: form.unloading_point.region_code, // required when company_address_id = null and branch_id = null
                region_name: form.unloading_point.region_name, // required when company_address_id = null and branch_id = null
                district_code: form.unloading_point.district_code, // required when company_address_id = null and branch_id = null
                district_name: form.unloading_point.district_name, // required when company_address_id = null and branch_id = null
                village_code: form.unloading_point.village_code, // required when company_address_id = null and branch_id = null
                village_name: form.unloading_point.village_name, // required when company_address_id = null and branch_id = null
                longitude: +form.unloading_point.longitude, // required when company_address_id = null and branch_id = null
                latitude: +form.unloading_point.latitude,// required when company_address_id = null and branch_id = null
            },
            items: form.items
                .filter(item => item.quantity > 0)
                .map(item => {
                    return {
                        order_item_id: item.id,
                        product: item.product,
                        quantity: +item.quantity,
                        barcode: item.barcode,
                        catalog_name: item.catalog_class_name || null,
                        catalog_code: item.catalog_class_code || null,
                        package_code: item.package_code?.code?.toString() || item.package_code?.toString() || item.package_code,
                        package_name: item.package_code?.code && (lang === 'ru' ? item.package_code?.name_ru : item.package_code?.name_uz) || item.package_name,
                        marks: item.marks.filter(i => i.code).map(i => i.code)
                    }
                })
        }

        if (form.freight_forwarder.inn_or_pinfl) {
            payload['freight_forwarder'] = {
                inn: form.freight_forwarder.inn_or_pinfl || null,
                name: form.freight_forwarder.name || null
            }
        }

        if (form.carrier.inn_or_pinfl) {
            payload['carrier'] = {
                inn: form.carrier.inn_or_pinfl || null,
                name: form.carrier.name || null
            }
        }

        if (form.responsible_person.inn_or_pinfl) {
            payload['responsible_person'] = {
                inn: form.responsible_person.inn_or_pinfl || null,
                name: form.responsible_person.name || null
            }
        }

        if (form.driver.inn_or_pinfl) {
            payload['driver'] = {
                inn: form.driver.inn_or_pinfl || null,
                name: form.driver.name || null
            }
        }

        if (form.freight_forwarder?.inn_or_pinfl !== form.carrier?.inn_or_pinfl) {
            payload['payer_contract_info'] = {
                number: form.payer_contract_info?.number,
                date: dayjs(form.payer_contract_info?.date).format('YYYY-MM-DD'),
            }
        }

        if (form.freight_forwarder?.inn_or_pinfl !== form.carrier?.inn_or_pinfl) {
            payload['payer_contract_info'] = {
                number: form.payer_contract_info?.number,
                date: dayjs(form.payer_contract_info?.date).format('YYYY-MM-DD'),
            }
        }

        editShipmentAsync(shipment.id, payload)
            .then( async () => {
                toast.success(t(lang, 'edi.toast.edit_shipment_succeed'))
                await updateEdiSettings({payload: shipmentSettingsJson})
                    .then(() => {})
                    .catch(() => {})
            })
            .catch(() => toast.error(t(lang, 'edi.toast.edit_shipment_failed')))
    }

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <ShipmentForm isEditing={true} shipment={shipment} order={order}/>
                </Form>
            </FormProvider>
        </>
    );
};

export default ShipmentExecutorEditForm;
