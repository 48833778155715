import React, {useEffect, useState} from 'react';
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Card, Col, Row} from "react-bootstrap";
import ContractDataTableHeader from "./ContractDataTableHeader";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import {useDispatch, useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    changeFilterOptionsAsync,
    loadRoamingContractsAsync,
    loadRoamingContractsCountAsync,
    selectCount,
    selectContractFilterOptions,
    selectRoamingContracts,
    setId, viewPage, setShowModal
} from '../../../../app/store/reducers/roaming/roamingContractReducer';
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import classNames from 'classnames';
import {GetViewerIcon, VIEW_INBOX, VIEW_OUTBOX, VIEW_DRAFT, GetViewerText} from '../../../../enum/TypeViewers';
import Flex from '../../../common/Flex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';
import SoftBadge from '../../../common/SoftBadge';
import AdvanceTableCustomPagination from '../../../common/advance-table/AdvanceTableCustomPagination';
import numeral from 'numeral';
import {ContractWrapperClass} from '../../../../enum/ContractStatus';
import ViewModalPage from "../components/ViewModalPage";
import ViewContract from "../../../../pages/main/roaming/contract/ViewContract";
import ViewDraftContract from "../../../../pages/main/roaming/contract/ViewDraftContract";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";


const ContractDataTable = ({filterDefaults, typeViewer}) => {
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const roamingInvoices = useSelector(selectRoamingContracts);
    const filters = useSelector(selectContractFilterOptions)
    const numberFormat = useSelector(selectNumberFormat);
    const count = useSelector(selectCount)
    const {id, draftId, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (typeViewer === VIEW_DRAFT) {
            if (ctrlKey) {
                history('/#/roaming/contract/draft/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }
        } else {
            if (ctrlKey) {
                history('/#/roaming/contract/'+id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const activeOrganizationPinflOrInn = activeOrganization.pinfl || activeOrganization.inn
    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'info',
            Header: t(lang, "roaming.contracts.datatable.header.row.contract"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                    return (
                        <strong className={'cursor-pointer text-primary'} onClick={(e) => openViewModalPage(e,original.id)}>
                            {original.info.name}, {original.info.number} от {dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}
                        </strong>
                    )
                }
        },
        {
            accessor: 'expire_date',
            Header: t(lang, "roaming.contracts.datatable.header.row.expire_date"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return `до ${dayjs(original.info.expire_date, "YYYY-MM-DD").format(currentDateDayJSFormat)}`
            }
        },
        (VIEW_DRAFT !== typeViewer && {
            accessor: 'status',
            Header: t(lang, "roaming.contracts.datatable.header.row.status"),
            Cell: ({row: {original}}) => {
                const contractWrapper = new ContractWrapperClass(original)
                return <h5>
                    <SoftBadge bg={contractWrapper.getRoamingStatusVariant(activeOrganizationPinflOrInn)} className='me-2'>
                        {t(lang, contractWrapper.getRoamingStatus(activeOrganizationPinflOrInn))}
                    </SoftBadge></h5>
            }
        }),
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.contracts.datatable.header.row.created_at"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => dayjs(original.created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
        },
        {
            accessor: 'contractor',
            Header: t(lang, "roaming.contracts.datatable.header.row.contractor"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return typeViewer === VIEW_INBOX
                    ? `${original.executor.name} (${original.executor.inn})`
                    : `${original.contractors[0].name} (${original.contractors[0].inn})`
            }
        },
        {
            accessor: 'total_sum',
            Header: t(lang, "roaming.contracts.datatable.header.row.total_sum"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                if(typeViewer === VIEW_DRAFT) {
                    return (
                        <strong>
                            {numeral.formats[numberFormat].format((original?.total_info?.total_with_nds_value) || 0)}
                        </strong>
                    )
                } else {
                    return (
                        <strong>
                            {numeral.formats[numberFormat].format((original?.items?.reduce((total, item) => total + item?.total_with_nds, 0) || 0))}
                        </strong>
                    )
                }
            }
        }
    ].filter(i => i);;

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }

    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }

    useEffect(() => {
        let params = {...filters, type_viewer: typeViewer, viewer_inn: activeOrganizationPinflOrInn}
        dispatch(loadRoamingContractsAsync(params));
        dispatch(loadRoamingContractsCountAsync({...params}))
    }, [filters, activeOrganization, typeViewer]);
    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Link to={`../contracts/${VIEW_INBOX}`} className="text-decoration-none">
                        <Card
                        className={classNames("p-3 cursor-pointer", {'bg-primary text-white': VIEW_INBOX === typeViewer})}
                        >
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon={GetViewerIcon(VIEW_INBOX)}
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames("d-none d-lg-block mb-0", {'text-white': VIEW_INBOX === typeViewer, 'text-primary': VIEW_INBOX !== typeViewer})}
                                >{t(lang, GetViewerText(VIEW_INBOX))}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={`../contracts/${VIEW_OUTBOX}`} className="text-decoration-none">
                        <Card
                        className={classNames("p-3 cursor-pointer", {'bg-primary text-white': VIEW_OUTBOX === typeViewer})}
                        >
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon={GetViewerIcon(VIEW_OUTBOX)}
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames("d-none d-lg-block mb-0", {'text-white': VIEW_OUTBOX === typeViewer, 'text-primary': VIEW_OUTBOX !== typeViewer})}
                                >{t(lang, GetViewerText(VIEW_OUTBOX))}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={`../contracts/${VIEW_DRAFT}`} className="text-decoration-none">
                        <Card
                        className={classNames("p-3 cursor-pointer", {'bg-primary text-white': VIEW_DRAFT === typeViewer})}
                        >
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon={GetViewerIcon(VIEW_DRAFT)}
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames("d-none d-lg-block mb-0", {'text-white': VIEW_DRAFT === typeViewer, 'text-primary': VIEW_DRAFT !== typeViewer})}
                                >{t(lang, GetViewerText(VIEW_DRAFT))}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
            </Row>
            <AdvanceTableWrapper
                columns={columns}
                data={roamingInvoices}
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ContractDataTableHeader table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                    {id && <ViewContract modalId={id} />}
                    {draftId && <ViewDraftContract modalId={draftId} />}
                </ViewModalPage>

        </>
    );
}

export default ContractDataTable;
