import {useSelector} from "react-redux";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {useHistory} from "react-router";
import React, {useEffect, useState} from "react";
import {
    getPurchaseItemAsync, purchaseApproveAsync, purchasePayoutRegisterAsync,
    purchasePayoutUpdateAsync
} from "../../../../app/store/reducers/warehouse-operation/purchaseReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/purchaseEvents";
import {Button, Card, Col, Row} from "react-bootstrap";
import WarehouseOperationPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationPayment";
import WarehouseOperationWithoutPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationWithoutPayment";
import SoftBadge from "../../../../components/common/SoftBadge";
import {
    isApprovedCheckedWarehouseOperation
} from "../../../../components/hippo/warehouse-operation/enum/warehouseOperationWrapper";
import PurchaseExcelForm from "../../../../components/hippo/warehouse-operation/purchase/forms/PurchaseExcelForm";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Link} from "react-router-dom";
import WOWithoutDiscountPayment
    from "../../../../components/hippo/warehouse-operation/components/WOWithoutDiscountPayment";

const AddPurchaseExcel = () => {
    const [purchase, setPurchase] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate


    const loadPurchase = (id) => {
        getPurchaseItemAsync({purchaseId: id})
            .then((response) => setPurchase(response.data))
            .catch((error) => setPurchase(null))
    }

    useEffect(() => {
        const onChangeContractorHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_SUCCESS, (id) => {
            loadPurchase(id)
        })

        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS, (id) => {
            loadPurchase(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS, (id) => {
            loadPurchase(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS, (id) => {
            loadPurchase(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, (id) => {
            loadPurchase(id)
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_CHANGE_CONTRACTOR_SUCCESS, onChangeContractorHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
        }
    }, [])


    const onSubmitPay = async (pay) => {
        if (purchase?.payout) {
            await purchasePayoutUpdateAsync({purchaseId: pay?.id, payout: pay?.payment})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})
        }
        if (!purchase?.payout && purchase?.is_approved) {
            await purchasePayoutRegisterAsync({purchaseId: pay?.id, payout: pay?.payment})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})
        }
        if (!purchase?.is_approved) {
            await purchaseApproveAsync({purchaseId: pay?.id, payout: pay?.payment || null})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})
        }
    }

    if (warehouses?.length === 0)
        return(
            <>
                <WarehouseNo/>
            </>
        )


    if (warehouses?.length > 0)
        return (
            <Card>
                <Card.Body>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <h5>{t(lang, "warehouse.operation.item.common.new_coming")}</h5>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            {purchase?.id &&
                                <div className="me-3">
                                    {/*<WarehouseOperationPayment onSubmitPay={onSubmitPay} data={purchase} />*/}
                                    <WOWithoutDiscountPayment
                                        data={purchase}
                                        isEditing={false}
                                        onSubmitPay={onSubmitPay}
                                    />
                                    {!purchase?.is_approved &&
                                        <WarehouseOperationWithoutPayment onClick={onSubmitPay} id={purchase?.id}/>
                                    }
                                    <Button
                                        variant="falcon-info"
                                        title={t(lang, "warehouse.operation.item.common.button.save_draft")}
                                        size="sm"
                                        className="me-1"
                                        as={Link}
                                        to={`./view/${purchase?.id}`}
                                    >{t(lang, "warehouse.operation.item.common.button.save_draft")}</Button>
                                </div>
                            }
                            <div>
                                <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                <SoftBadge bg={purchase?.is_approved ? 'success' : 'warning'}>
                                    {t(lang, isApprovedCheckedWarehouseOperation(!!purchase?.is_approved))}
                                </SoftBadge>
                            </div>
                        </Col>
                    </Row>
                    <PurchaseExcelForm />
                </Card.Body>
            </Card>
        )
}

export default AddPurchaseExcel;