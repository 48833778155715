import React from 'react';
import {CloseButton, Modal, Spinner} from "react-bootstrap";
import FormTask from "../../form/taskCard/FormTask";
import EditFormTask from "../../form/taskCard/EditFormTask";
import {useDispatch, useSelector} from "react-redux";
import {
    selectModalContent, taskBoardLoading,
    updateModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import PropTypes from "prop-types";

const KanbanModal = ({
                         show,
                         setShow,
                         columnId,
                         boardId,
                         edit,
                         contractorId,
                         handlerClose,
                         fastAddTask,
                         boardType,
                         loading,

                    }) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const modalContent = useSelector(selectModalContent)
    const taskLoading = useSelector(taskBoardLoading)
    const handleClose = () => {
        setShow(false)
        dispatch(updateModalContent({}))
        !edit && handlerClose && handlerClose()
    };

    const styleInProgress = {
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        background: "white",
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={handleClose}
            contentClassName="border-0"
            dialogClassName="mt-6"
            backdrop="static"
        >
            {edit && modalContent?.attachments?.length > 0 && (
                <div className="position-relative overflow-hidden">
                    <img className={'w-50 d-block m-auto'} src={modalContent?.attachments[0]?.image?.includes('/static') ? modalContent?.attachments[0]?.image : `data:image/jpeg;base64,${modalContent?.attachments[0]?.image}`} alt=""/>
                </div>
            )}
            <div className="position-absolute top-0 end-0 mt-1 me-1 z-index-1">
                <CloseButton
                    onClick={handleClose}
                    className="btn btn-sm btn-circle d-flex flex-center transition-base"
                />
            </div>
            <Modal.Body className="p-0 position-relative">
                {!edit && <div className="bg-light rounded-top-lg px-4 py-3">
                    <h4 className="mb-1">{t(lang, 'task.board.modal.header.title')}</h4>
                </div>}
                {(loading || taskLoading) ? <div className='position-absolute d-flex justify-content-center align-items-center'
                                style={styleInProgress}>
                    <Spinner animation="border" variant="primary" style={{width: "50px", height: "50px"}}/>
                </div> : <div className="p-4">
                    {
                        edit ?
                            <EditFormTask
                                setShow={setShow}
                                handlerClose={handlerClose}
                                boardId={boardId}
                            /> :
                            <FormTask
                                columnId={columnId}
                                boardId={boardId}
                                setShow={setShow}
                                contractorId={contractorId}
                                handlerClose={handlerClose}
                                fastAddTask={fastAddTask}
                                boardType={boardType}
                            />
                    }
                </div>}
            </Modal.Body>
        </Modal>
    );
};
KanbanModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    // id: PropTypes.number.isRequired | PropTypes.string.isRequired,
    edit: PropTypes.bool

}
export default KanbanModal;
