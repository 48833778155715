import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Kanban from "./layout/Kanban";
import KanbanView from "./layout/KanbanView";
import KanbanItem from "../../../components/kanban/KanbanItem";
import {loadEmployeesAsync} from "../../../app/store/reducers/employee/employeeReducer";
import {useDispatch} from "react-redux";
import EventBus from "../../../app/eventbus/EventBus";
import {
    DRAG_AND_DROP_TASK_SUCCESS,
    EDIT_CONTRACOR_DATE_SUCCESS,
    ITEM_REGISTER_SUCCESS
} from "../../../app/eventbus/kanban/KanbanEvents";
import {
    loadContractorAsync,
    updateContractorDebtPromiseDate
} from "../../../app/store/reducers/contractor/contractorReducer";

const KanbanLayout = () => {
    const {url} = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadEmployeesAsync());
    }, []);

    const loadContractor = (id) => {
        loadContractorAsync(id)
            .then(cr => {
                dispatch(updateContractorDebtPromiseDate(cr))
            })
    }

    useEffect(() => {
        const onAddedNewTaskInContractor = EventBus.on(ITEM_REGISTER_SUCCESS, (id)=> {
            id && loadContractor(id)
        })
        const onDragAndDropTaskInContractor = EventBus.on(DRAG_AND_DROP_TASK_SUCCESS, (id)=> {
            id && loadContractor(id)
        })
        const onEditContractorDateTaskInContractor = EventBus.on(EDIT_CONTRACOR_DATE_SUCCESS, (id)=> {
            id && loadContractor(id)
        })

        return () => {
            EventBus.remove(ITEM_REGISTER_SUCCESS, onAddedNewTaskInContractor)
            EventBus.remove(DRAG_AND_DROP_TASK_SUCCESS, onDragAndDropTaskInContractor)
            EventBus.remove(EDIT_CONTRACOR_DATE_SUCCESS, onEditContractorDateTaskInContractor)
        }
    }, []);

    return (
        <Switch>
            <Route path={`${url}`} exact component={Kanban} />
            <Route path={`${url}/board/:id`} component={KanbanView} />
        </Switch>
    );
};

export default KanbanLayout;
