import {useDispatch, useSelector} from "react-redux";
import {
    changeSystemOrganizationsFilterOptionsBankPaymentsError, selectSystemOrganizationBankPaymentsErrorCount,
    selectSystemOrganizationsBankPaymentsError,
    selectSystemOrganizationsFilterOptionsBankPaymentsError
} from "../../../../app/store/reducers/billing/billingReducer";
import {Button, Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import React, {Fragment, useState} from "react";
import numeral from "numeral";
import {selectLang, selectSystemMoneyFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import BankPaymentErrorDataTableHeader from "./BankPaymentErrorDataTableHeader";
import SeenBankPaymentsErrorModal from "../modal/SeenBankPaymentsErrorModal";
import SoftBadge from "../../../common/SoftBadge";


const BankPaymentErrorDataTable = () => {
    const filters = useSelector(selectSystemOrganizationsFilterOptionsBankPaymentsError)
    const bankPaymentsError = useSelector(selectSystemOrganizationsBankPaymentsError)
    const count = useSelector(selectSystemOrganizationBankPaymentsErrorCount)
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    const [paymentId, setPaymentId] = useState(null)
    const [show, setShow] = useState(false)

    const subtotal = bankPaymentsError?.reduce((acc, cur) => acc + cur.amount, 0)

    const showModal = (id) => {
        setShow(true)
        setPaymentId(id)
    }

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'payer_name',
            Header: t(lang, 'admin.common.payments.datatable.name'),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <strong>{original.payer_name}</strong>
                    </>
                );
            }
        },
        {
            accessor: 'payer_inn',
            Header: t(lang, 'admin.common.payments.datatable.inn'),
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.payer_inn}</span>
                )
            }
        },
        {
            accessor: 'error_message',
            Header: t(lang, "admin.common.payments.datatable.error_message"),
            Cell: ({row: {original}})=> {
                return (
                   <Fragment>
                       {original.resolved ? <SoftBadge pill bg='primary' className='me-2'>{t(lang, "admin.common.payments.datatable.inn.checked")}</SoftBadge>:
                           <span>{original?.error_message}</span>}
                   </Fragment>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "admin.common.payments.datatable.created_date"),
        },
        {
            accessor: 'amount',
            Header: t(lang, 'admin.common.payments.datatable.amount'),
            headerProps: {className: 'text-end'},
            cellProps: {className: 'text-end'},
            totalInfo: numeral.formats[systemMoneyFormat].format(subtotal),
            totalInfoClassName: 'text-end fw-bold',
            Cell: ({row: {original}}) => {
                return (
                    <span>{numeral.formats[systemMoneyFormat].format(original.amount)}</span>
                )
            }
        },
        {
            accessor: "actions",
            cellProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                return (
                    <Button disabled={original.resolved} onClick={() => showModal(original.id)} variant={"falcon-primary"} size={"sm"}>
                        {t(lang, "admin.common.payments.datatable.check")}
                    </Button>
                )
            }
        }
    ]


    const onPageChange = (page) => dispatch(changeSystemOrganizationsFilterOptionsBankPaymentsError({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeSystemOrganizationsFilterOptionsBankPaymentsError({...filters, limit: limit, page: 1}));

    return(
        <>
            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={bankPaymentsError}
            >
                <Card className="mb-3">
                    <Card.Header className="bg-light">
                        <BankPaymentErrorDataTableHeader table/>
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            {show && <SeenBankPaymentsErrorModal show={show} setShow={setShow} id={paymentId}/>}

        </>
    )
}


export default BankPaymentErrorDataTable;