import dayjs from "dayjs";
import numeral from "numeral";
import classNames from "classnames";
import {Form} from "react-bootstrap";
import Cleave from "cleave.js/react";
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SelectCompanyCatalog from "../../IKPU/SelectCompanyCatalog";
import {loadCatalogByCode} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {selectCompanyCatalogs} from "../../../../app/store/reducers/roaming/roamingReducer";
import CatalogPackageLinkSelector from "../../../common/CatalogPackageLinkSelector";
import MarksModal from "../MarksModal";

const itemQuantityFormatRegEx = /^[0-9]\d*(\.\d+)?$/

const ItemRow = ({index, arrayFieldName, update, allowEditProductName, isCustomer, isEditing, refs, handleKeyDown}) => {
    const rootName = `${arrayFieldName}.${index}`;
    const item = useWatch({name: rootName})
    const {watch, control, formState: {errors}, register, setValue} = useFormContext();
    const itemQuantity = useWatch({name: `${rootName}.quantity`});
    const catalogCode = useWatch({name: `${rootName}.catalog_class_code`});
    const packageCode = useWatch({name: `${rootName}.package_code`, exact: true})
    const numberFormat = useSelector(selectNumberFormat);
    const itemProduct = item.product;
    const privilege = item.benefit;
    const {fields, replace,append,remove} = useFieldArray({
        control,
        name: `${rootName}.marks`
    })

    const shipmentDate = watch('shipment_info.date');
    const lang = useSelector(selectLang);
    const t = Translate;
    const companyCatalogs = useSelector(selectCompanyCatalogs);

    const [catalogPackages, setCatalogPackages] = useState(null)

    useEffect( () => {
        const fetchCatalog = async () => {
            if (!isCustomer) {
                if (isEditing && companyCatalogs.length > 0) {
                    if (catalogCode) {
                        let catalog  =  await loadCatalogByCode(catalogCode)
                        setCatalogPackages(catalog)
                    }
                }
            }
        };
        fetchCatalog();
    }, [isEditing, companyCatalogs.length, catalogCode]);

    useEffect( () => {
        const fetchCatalog = async () => {
            if (!isCustomer) {
                if (companyCatalogs.length > 0) {
                    if (catalogCode) {
                        const code = onCatalogChanged(companyCatalogs.find(i => i.class_code === catalogCode));
                        if (!code) {
                            const catalog = await loadCatalogByCode(catalogCode);
                            setCatalogPackages(catalog);
                        }
                    }
                }
            }
        };
        fetchCatalog();
    }, [companyCatalogs, catalogCode])

    useEffect(() => {
        if (packageCode?.code) {
            setValue(`${rootName}.package_name`, lang === 'ru' ? packageCode.name_ru : packageCode.name_uz);
        }
    }, [packageCode]);

    const onCatalogChanged = useCallback((option) => {
        if (option) {
            setCatalogPackages(option)
        } else {
            setCatalogPackages(null)
        }
        setValue(`${rootName}.measurement`, null);
        return option
    }, [setValue, rootName]);

    useEffect(() => {
        updateRow();
    }, [itemQuantity])

    const updateRow = useCallback(() => {
        item.quantity = +itemQuantity;
        item.total = item.quantity * item.price;
        item.nds_value = (item.total / 100) * item.nds_rate;
        item.total_with_nds = item.total + item.nds_value;

        update(index, item);
    }, [itemQuantity, item.quantity, item.price, item.nds_rate, item.nds_value])

    const onChangeCatalog = (catalog, privilege) => {
        setValue(`${rootName}.catalog_class_name`, catalog ? catalog.name : null);
        if(privilege) {
            const isCurrentYear = shipmentDate && dayjs().year() === shipmentDate.getFullYear();
            setValue(`${rootName}.benefit`, isCurrentYear ? privilege.newLgotaId : privilege.lgotaId);
        } else {
            setValue(`${rootName}.benefit`, null);
        }
    };

    let itemErrors = {};
    if (errors && errors[arrayFieldName] && errors[arrayFieldName][index])
        itemErrors = errors[arrayFieldName][index];

    const appendMark = () => {
        append({code: "", id: `${rootName}-mark-${fields.length}`})
    }
    const removeMark = (idx) => {
        remove(idx);
    };
    return (
        <>
            <tr className="align-middle">
                <td className="px-0 text-center">{index + 1}</td>
                <td className="fw-semi-bold text-black" ref={refs?.product}>
                    {allowEditProductName ? (
                           <Fragment>
                               <Form.Control
                                   style={{
                                       minWidth: "250px",
                                   }}
                                   disabled={isCustomer}
                                   type="text"
                                   value={itemProduct}
                                   isInvalid={itemErrors.product}
                                   onKeyDown={(e) => handleKeyDown(e, index, 'product')}
                                   {...register(`${rootName}.product`, {required: t(lang, 'edi.common.forms.validations.is_required')})}
                               />

                            <Form.Control.Feedback type="invalid">
                                {itemErrors?.product?.message}
                            </Form.Control.Feedback>
                           </Fragment>
                    ) : item.product}
                </td>
                {/*<td className="p-1">{item.customer_shipment_id}</th>*/}
                <td className="p-1 text-center">{item.barcode}</td>
                <td className="p-1 text-center" ref={refs?.catalog_class_code}>
                    {!isCustomer ? <Controller
                        control={control}
                        name={`${rootName}.catalog_class_code`}
                        rules={{
                            required: t(lang, 'edi.common.forms.validations.is_required')
                        }}
                        render={({field}) => (
                            <SelectCompanyCatalog className="m-0"
                                                  disabled={isCustomer}
                                                  textButton={catalogCode || field.value || t(lang, "edi.common.button.select")}
                                                  defaultCatalogCode={catalogCode || field.value}
                                                  defaultLgota={privilege}
                                                  onChange={(catalog, privilege) => {
                                                      field.onChange(catalog ? catalog.class_code : null);
                                                      setValue(`${rootName}.package_code`, null);
                                                      onChangeCatalog(catalog, privilege);
                                                  }}
                                                  onKeyDown={(e) => handleKeyDown(e, index, 'catalog_class_code')}
                                                  onFocus={(e) => e.target.classList.add('btn-primary')}
                                                  onBlur={(e) => e.target.classList.remove('btn-primary')}

                            />
                        )}
                    /> : item.catalog_class_code}
                    <Form.Control.Feedback className={'d-block'} type="invalid">
                        {itemErrors?.catalog_class_code?.message}
                    </Form.Control.Feedback>
                </td>
                <td ref={refs?.package_code}>
                    {catalogPackages ? <Fragment>
                        <Controller name={`${rootName}.package_code`}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required')
                                    }}
                                    render={({field}) => (
                                        <CatalogPackageLinkSelector
                                            disabled={isCustomer}
                                            textButton={field.value || t(lang, "roaming.common.select.placeholder")}
                                            defaultCatalogPackage={packageCode?.code ? packageCode.code : packageCode}
                                            catalogPackages={catalogPackages?.package_names}
                                            onChange={field.onChange}
                                            onKeyDown={(e) => handleKeyDown(e, index, 'package_code')}
                                            onFocus={(e) => e.target.classList.add('btn-primary')}
                                            onBlur={(e) => e.target.classList.remove('btn-primary')}
                                        />
                                    )}

                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {itemErrors?.package_code?.message}
                        </Form.Control.Feedback>
                    </Fragment> : item.measurement}
                </td>
                <td className="text-end py-0" ref={refs?.quantity}>
                    <Controller
                        name={`${rootName}.quantity`}
                        rules={{
                            required: t(lang, 'edi.common.forms.validations.is_required'),
                            min: {value: 0, message: t(lang, 'edi.shipment.shipment_items.item.quantity.validation.less_than')},
                            max: {value: item.quantity_left, message: t(lang, 'edi.shipment.shipment_items.item.quantity.validation.more_than')}
                        }}
                        render={({field}) => (
                            <Cleave
                                value={+item.quantity}
                                className={classNames('form-control p-1 small text-end', {
                                    'is-invalid': itemErrors.quantity
                                })}
                                options={{numeral: true, delimiter: '', numeralDecimalScale: 3}}
                                onChange={event => {
                                    if (itemQuantityFormatRegEx.test(event.target.value)) {
                                        field.onChange(event.target.value)
                                    }

                                }}
                                onKeyDown={(e) => handleKeyDown(e, index, 'quantity')}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.quantity?.message}
                    </Form.Control.Feedback>
                </td>
                <td className="px-1 fw-semi-bold text-end">{numeral.formats[numberFormat].format(item.price)}</td>
                <td className="px-1 fw-semi-bold text-end">{numeral.formats[numberFormat].format(item.total)}</td>
                <td className="px-1 fw-semi-bold text-end">{item.nds_rate}</td>
                <td className="px-1 fw-semi-bold text-end">{numeral.formats[numberFormat].format(item.nds_value)}</td>
                <td className="px-1 fw-semi-bold text-end">{numeral.formats[numberFormat].format(item.total_with_nds)}</td>
                {!isCustomer && <td className="px-1 text-center">
                    <MarksModal control={control} fields={fields} appendMark={appendMark} rootName={rootName}
                                removeMark={removeMark} index={index}/>
                </td>}
            </tr>
        </>
    );
};

export default ItemRow;
