import React, {useState} from 'react';
import {useFieldArray, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {Form, Table} from "react-bootstrap";

const NotificationEventsTable = ({notification = []}) => {
    const {control} = useFormContext();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {fields, replace, append} = useFieldArray({
        control,
        name: 'eventCodes'
    })
    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);

        const arr = [];
        notification.forEach(notificationValues => {
            notificationValues.notifications.forEach(val => {
                arr.push({value: val.value});
            })
        })
        replace(arr);

        if (isCheckAll) {
            replace([]);
        }
    }

    const handleClick = (el, notification) => {
        if (el.target.checked) {
            append({value: notification})
        } else {
            replace(fields.filter(field => field.value !== notification))
        }
    }

    const isChecked = (val) => {
        let checked = fields.find(item => {
            return item.value === val;
        });
        return !!checked
    }

    return (
        <Table hover responsive className="border-bottom">
            <thead>
            <tr>
                <th className={'d-flex border-white border-0 border-bottom table-primary align-items-center'} />
                <th className="p-0">
                    <Form.Check type="checkbox" className="m-0">
                        <Form.Check.Label className={'text-dark m-0'}>
                            <Form.Check.Input type="checkbox" onClick={handleSelectAll}  className={"ps-2"}  defaultChecked={isCheckAll}/>
                            {t(lang, "notification.check_all")}
                        </Form.Check.Label>
                    </Form.Check>
                </th>
            </tr>
            </thead>
            <tbody>
            {notification.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className={`text-dark table-primary border-white border-0 border-bottom p-1 fs--1 fw-medium`}>{t(lang, item.text)}</td>
                        {item.notifications.map((val, valIndex) => {
                            return (
                                <td key={valIndex} className="px-0 py-1">
                                    <Form.Check
                                        type="checkbox"
                                        className="form-check">
                                        <Form.Check.Label className="form-label text-dark">
                                            <Form.Check.Input id={val.value}
                                                              type="checkbox"
                                                              defaultValue={isChecked(val.value)}
                                                              onChange={e => {
                                                                  handleClick(e, val.value);
                                                              }}
                                                              name={val.text}
                                                              checked={isChecked(val.value)}/>
                                            {t(lang, val.text)}
                                        </Form.Check.Label>
                                    </Form.Check>
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </Table>
    );
};

export default NotificationEventsTable;