import React from 'react';
import {Card, Col, Row, Table} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import BindDraftItemModal from "../BindDraftItemModal";
import {Link} from "react-router-dom";
import IconButton from "../../../../common/IconButton";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import ImageSlider from "../../../item/components/ImageSlider";
import itemWrapper from "../../../item/enum/itemWrapper";
import DeleteDraftItem from "../DeleteDraftItem";

const DraftItem = ({draftItem}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <h3 className="text-600 col-md-8">{draftItem.name}</h3>
                                <h5>{t(lang, 'items.common.draft.created_at')}: {draftItem.created_at}</h5>
                            </Col>
                            <Col xs={12} className="mt-3">
                                <BindDraftItemModal id={draftItem.id}/>
                                <Link to={`./approve/${draftItem.id}`} className="mx-2">
                                    <IconButton icon={faPencil}
                                                variant="falcon-warning"
                                                title={t(lang, 'items.common.draft.add_as_new_item')}
                                    >
                                        {t(lang, 'items.common.draft.new')}
                                    </IconButton>
                                </Link>
                                <DeleteDraftItem id={draftItem.id} buttonText={t(lang, 'items.common.delete')}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} className="mt-3">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col lg={6}>
                                <ImageSlider product={{images: []}}/>
                            </Col>
                            <Col lg={6}>
                                <Table>
                                    <tbody>
                                    <tr>
                                        <th className="bg-100 w-25">{t(lang, 'items.common.draft.source')}</th>
                                        {draftItem.edi_order && <td>
                                            <Link to={`../../../edi/order/${draftItem.edi_order.id}`}>
                                                <h6 className="text-primary fs-0">{draftItem.edi_order.number}</h6>
                                            </Link>
                                        </td>}
                                        {draftItem.roaming_invoice && <td>
                                            <Link to={`../../../roaming/invoice/${draftItem.roaming_invoice.id}`}>
                                                <h6 className="text-primary fs-0">{draftItem.roaming_invoice.number}</h6>
                                            </Link>
                                        </td>}
                                    </tr>
                                    <tr>
                                        <th className="bg-100 w-25">{t(lang, 'items.common.barcode')}</th>
                                        <td>{draftItem.barcode}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-100 w-25">{t(lang, 'items.common.product_code')}</th>
                                        <td>{draftItem.product_code}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-100 w-25">{t(lang, "items.common.measurement")}</th>
                                        <td>{t(lang, itemWrapper.getMeasurement(draftItem.measurement))}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-100 w-25">{t(lang, "items.common.roaming_measurement")}</th>
                                        <td>{draftItem.tax.measurement || draftItem.tax.catalog.package.name}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-100 w-25">{t(lang, 'items.common.catalog')}</th>
                                        <td>{draftItem.tax.catalog.code}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-100 w-25">{t(lang, 'items.common.tax_rate')}</th>
                                        <td>{draftItem.tax.tax_rate}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} className="mt-6">
                                {draftItem.tax.benefit &&
                                    <i className="text-dark">
                                        <span className="text-danger">*</span>
                                        <strong
                                            className="mx-1">{t(lang, "items.common.grouped_privilege_info_id")}</strong>
                                        {draftItem.tax.benefit.id}
                                        <strong
                                            className="mx-1">{t(lang, "items.common.grouped_privilege_info_name")} </strong>
                                        <span className="text-primary">{draftItem.tax.benefit.name}</span>
                                    </i>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default DraftItem;