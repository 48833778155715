import React, {Fragment, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WarehouseAccountSettings from "../../warehouse-operation/sale/components/WarehouseAccountSettings";
import {useLocation} from "react-router-dom";
import DataTableSettings from "../../DataTableSettings";
import {changeOrderDataTableColumn, selectOrdersDataTable} from "../../../../app/store/reducers/order/orderReducer";
import {changeAttorneyDataTableColumn, STORAGE_ATTORNEY} from "../../../../app/store/reducers/attorney/attorneyReducer";
import {
    changeContractFormationDataTableColumn, selectContractFormationDataTable
} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";
import {useSelector} from "react-redux";
import {changeDataTableColumn, STORAGE_SHIPMENT} from "../../../../app/store/reducers/shipment/shipmentReducer";
import {
    changeInvoicesDataTableColumn,
    selectInvoicesDataTable
} from "../../../../app/store/reducers/invoice/invoiceReducer";
import {
    changeReturnOrderDataTableColumn,
    STORAGE_RETURN_ORDER
} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import {
    changeReturnShipmentDataTableColumn,
    STORAGE_RETURN_SHIPMENT
} from "../../../../app/store/reducers/return-shipment/returnShipmentReducer";
import {
    changeReturnInvoicesDataTableColumn,
    selectReturnInvoicesDataTable
} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import {changeBranchDataTableColumn, STORAGE_BRANCH} from "../../../../app/store/reducers/branch/branchReducer";
import DatatableSettingsControl from "../../../common/datatable-settings/DatatableSettingsControl";

const OrganizationSettings = () => {

    const [show, setShow] = useState(false)

    const {pathname} = useLocation()

    const orderColumns = useSelector(selectOrdersDataTable)
    const contractFormationColumns = useSelector(selectContractFormationDataTable)
    const invoiceColumns = useSelector(selectInvoicesDataTable)
    const returnInvoiceColumns = useSelector(selectReturnInvoicesDataTable)
    const attorneyColumns = JSON.parse(localStorage.getItem(STORAGE_ATTORNEY))
    const shipmentColumns = JSON.parse(localStorage.getItem(STORAGE_SHIPMENT))
    const returnOrderColumns = JSON.parse(localStorage.getItem(STORAGE_RETURN_ORDER))
    const returnShipmentColumns = JSON.parse(localStorage.getItem(STORAGE_RETURN_SHIPMENT))
    const branchColumns = JSON.parse(localStorage.getItem(STORAGE_BRANCH))

    useEffect(() => {
        setShow(false)
    }, [pathname])


    return (
        <Fragment>

            {!(
                pathname === '/' ||
                pathname === '/edi' ||
                pathname === '/roaming' ||
                pathname === '/crm' ||
                pathname === '/warehouse' ||
                pathname === '/cash-box' ||
                pathname.startsWith('/admin') ||
                pathname.startsWith('/kanban') ||
                pathname.startsWith('/roaming/contracts') ||
                pathname.startsWith('/roaming/empowerments') ||
                pathname.startsWith('/roaming/free-forms') ||
                pathname.startsWith('/roaming/excel-registries') ||
                pathname.startsWith('/roaming/organization-address') ||
                pathname.startsWith('/user')
            ) && <Fragment>
                <FontAwesomeIcon
                    onClick={() => setShow(true)}
                    icon="gear"
                    transform="shrink-6"
                    className="fs-5 text-facebook cursor-pointer"
                />

                {
                    (pathname.includes('cash-box') || pathname.includes('warehouse') || pathname.includes('crm')) ?
                        <WarehouseAccountSettings show={show} setShow={setShow}/> :
                        pathname.startsWith('/roaming/invoices') ?
                            <DatatableSettingsControl show={show} setShow={setShow}/> :
                            <DataTableSettings
                                show={show}
                                setShow={setShow}
                                allColumns={
                                    pathname.startsWith('/edi/order') ? orderColumns :
                                        pathname.startsWith('/edi/attorney') ? attorneyColumns :
                                            pathname.startsWith('/edi/contracts-formation') ? contractFormationColumns :
                                                pathname.startsWith('/edi/shipments') ? shipmentColumns :
                                                    pathname.startsWith('/edi/invoice') ? invoiceColumns :
                                                        pathname.startsWith('/edi/return-order') ? returnOrderColumns :
                                                            pathname.startsWith('/edi/return-shipment') ? returnShipmentColumns :
                                                                pathname.startsWith('/edi/return-invoice') ? returnInvoiceColumns :
                                                                    pathname.startsWith('/edi/branches') ? branchColumns : {}
                                }
                                changeDataTable={
                                    pathname.startsWith('/edi/order') ? changeOrderDataTableColumn :
                                        pathname.startsWith('/edi/attorney') ? changeAttorneyDataTableColumn :
                                            pathname.startsWith('/edi/contracts-formation') ? changeContractFormationDataTableColumn :
                                                pathname.startsWith('/edi/shipment') ? changeDataTableColumn :
                                                    pathname.startsWith('/edi/invoice') ? changeInvoicesDataTableColumn :
                                                        pathname.startsWith('/edi/return-order') ? changeReturnOrderDataTableColumn :
                                                            pathname.startsWith('/edi/return-shipment') ? changeReturnShipmentDataTableColumn :
                                                                pathname.startsWith('/edi/return-invoice') ? changeReturnInvoicesDataTableColumn :
                                                                    pathname.startsWith('/edi/branches') ? changeBranchDataTableColumn : () => {
                                                                    }
                                }
                               />
                }
            </Fragment>}

        </Fragment>
    );
};

export default OrganizationSettings;
