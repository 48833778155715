import {createSlice} from "@reduxjs/toolkit";
import contractorService from "../../../../services/contractorService";
import EventBus from "../../../eventbus/EventBus";
import {
    DELETE_CONTRACTOR_AGENT_FAILED,
    DELETE_CONTRACTOR_AGENT_REQUESTED, DELETE_CONTRACTOR_AGENT_SUCCESS,
    REGISTER_CONTRACTOR_AGENT_FAILED,
    REGISTER_CONTRACTOR_AGENT_REQUESTED,
    REGISTER_CONTRACTOR_AGENT_SUCCESS
} from "../../../eventbus/contractor/contractorAgentEvents";


export const contractorAgentSlice = createSlice({
    name: "contractorAgent",
    initialState: {
        agents: [],
        count: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateAgent: (state, action) => {
            state.agents = action.payload || []
        },
        updateAgentCount: (state, action) => {
            state.count = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
    }
})

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorAgentSlice.actions.updateFilterOptions({...params}))
}

export const loadContractorAgentsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorAgents(params)
        .then((response) => {
            dispatch(contractorAgentSlice.actions.updateAgent(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}


export const loadContractorAgentsCountAsync = (filters) => dispatch => {
    return new Promise((resolve, reject) => {
        let {limit, page, ...other} = filters

        contractorService.getContractorAgentsCount(other)
            .then((response) => {
                dispatch(contractorAgentSlice.actions.updateAgentCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const laodContractorAgentAsync = (id) => {

    return new Promise((resolve, reject) => {
        contractorService.getContractorAgent(id)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    })
}

export const registerContractorAgentAsync = (payload) => {
    EventBus.dispatch(REGISTER_CONTRACTOR_AGENT_REQUESTED)
    return new Promise((resolve, reject) => {
        contractorService.registerContractorAgent(payload)
            .then((response) => {
                EventBus.dispatch(REGISTER_CONTRACTOR_AGENT_SUCCESS)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(REGISTER_CONTRACTOR_AGENT_FAILED)
                reject(error)
            })
    })
}

export const deleteContractorAgentAsync = (id) => {
    EventBus.dispatch(DELETE_CONTRACTOR_AGENT_REQUESTED)
    return new Promise((resolve, reject) => {
        contractorService.deleteContractorAgent(id)
            .then((response) => {
                EventBus.dispatch(DELETE_CONTRACTOR_AGENT_SUCCESS)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_CONTRACTOR_AGENT_FAILED)
                reject(error)
            })
    })
}

export const selectContractorAgents = state => state.contractorAgent.agents

export const selectContractorAgentsCount = state => state.contractorAgent.count

export const selectContractorAgentsFilterOptions = state => state.contractorAgent.filterOptions


export default contractorAgentSlice.reducer