import React from "react";
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const Select = ({options, field, label, value, placeholder, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const getOptionLabel = option => t(lang, option[label]);
    const getOptionValue = option => option[value];
    const onChangeHandler = option => field.onChange(option ? option[value] : null);

    return (
        <ReactSelect getOptionLabel={getOptionLabel}
                     getOptionValue={getOptionValue}
                     onChange={onChangeHandler}
                     options={options}
                     placeholder={t(lang,placeholder)}
                     defaultValue={options?.find(item => item[value] ===field.value)}
                     {...props}
        />
    )
};

export default Select;