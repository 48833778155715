import React from 'react';
import {Dropdown} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import withDeleteItem from "./withDeleteItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const DeleteItemDropdownItem = ({item, handleShow}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Dropdown.Item className="text-danger align-center"
                       onClick={() => handleShow(item)}
        >
            <FontAwesomeIcon className="me-1" size="sm" icon={faTrash}/>
            <span>{t(lang, 'items.common.delete')}</span>
        </Dropdown.Item>
    );
};

export default withDeleteItem(DeleteItemDropdownItem);