import {FormProvider, useForm} from "react-hook-form";
import {Button, Form, Spinner} from "react-bootstrap";
import CashBoxCommonRegisterForm from "../hippo/cashbox/common/CashBoxCommonRegisterForm";
import {UZS} from "../../enum/CurrencyCode";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../app/store/reducers/currency/currencyReducer";
import React, {Fragment, useEffect, useState} from "react";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {selectDefaultSetting} from "../../app/store/reducers/settings/settingsReducer";

export const cashBoxRegister = (displayName) => {

    const CashBoxRegister = ({registerRequest, setShow, defaultValue, transfer}) => {
        const currencies = useSelector(selectCurrency)
        const lang = useSelector(selectLang)
        const t = Translate
        const settings = useSelector(selectDefaultSetting)

        const [loading, setLoading] = useState(false)
        const methods = useForm({
            defaultValues: {
                amounts: defaultValue?.amounts.map(i => {
                        return {
                            amount: i.amount,
                            currency_id: i.currency.id,
                            money_type: i.money_type
                        }
                    }) || [
                        {
                            amount: null,
                            currency_id: currencies?.find(c => c?.code === UZS)?.id,
                            money_type: 1
                        }
                    ],
                cash_box_id: defaultValue?.cash_box?.id || settings?.cash_box?.default_id || "",
                from_cashbox_id: defaultValue?.from_cash_box?.id || settings?.cash_box?.default_id || "",
                to_cashbox_id: defaultValue?.to_cash_box?.id || "",
                notes: defaultValue?.notes || "",
                date: defaultValue ? new Date(defaultValue?.date) : new Date()
            }
        })
        const onHideModal = () => {
            setShow(false)
            methods.setValue("account", null)
            methods.setValue("amounts", {
                amount: null,
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
                money_type: 1
            })
            methods.setValue("cash_box_id", null)
            methods.setValue("from_cashbox_id", null)
            methods.setValue("from_cashbox_id", null)
            methods.setValue("notes", null)
            methods.setValue("date", new Date())
        }

        const onSubmit = (formData) => {
            const data = {
                amounts: formData.amounts.filter(item => +item.amount > 0).map(item => {
                    return {
                        amount: +item.amount,
                        currency_id: typeof item.currency_id !== "number" ? item.currency_id?.id : item.currency_id,
                        money_type: typeof item.money_type !== "number" ? item.money_type?.value : item.money_type
                    }
                }),
                date: formData.date && dayjs(formData.date).format("YYYY-MM-DD HH:mm:ss"),
                cashbox_id: formData.cash_box_id?.id || formData.cash_box_id || null,
                from_cashbox_id: formData.from_cashbox_id?.id || formData.from_cashbox_id || null,
                to_cashbox_id: formData.to_cashbox_id?.id || formData.to_cashbox_id || null,
                notes: formData.notes
            }

            for (const dataKey in data) {
                if (data[dataKey] === null) {
                    delete data[dataKey]
                }
            }

            setLoading(true)
            registerRequest(data, defaultValue?.id)
                .then(() => {
                    onHideModal()
                    toast.success(t(lang, "crm.common.toast.success"))
                })
                .catch(() => toast.error(t(lang, "crm.common.toast.error")))
                .finally(() => setLoading(false))
        }

        useEffect(() => {
            methods.setValue('cashbox_id',  defaultValue?.cash_box?.id || settings?.cash_box?.default_id || null)
            methods.setValue('from_cashbox_id',  defaultValue?.from_cash_box?.id  || settings?.cash_box?.default_id || null)
        }, [settings])

        return <Fragment>
            <FormProvider {...methods}>
                <Form id={'cash-box-form-register'} onSubmit={methods.handleSubmit(onSubmit)}>
                    <CashBoxCommonRegisterForm transfer={transfer}/>
                </Form>
            </FormProvider>

            <div className={'d-flex justify-content-end gap-3 mt-3'}>
                <Button onClick={onHideModal} variant={'falcon-primary'}>{t(lang, "main.common.modal.close")}</Button>
                <Button form={'cash-box-form-register'} type={'submit'}>
                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                    {t(lang, "task.board.common.save")}
                </Button>
            </div>
        </Fragment>

    }

    CashBoxRegister.displayName = displayName || "CashBoxRegister"

    return CashBoxRegister
}
