import React, {useEffect} from "react";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import {Controller, useFormContext} from "react-hook-form";
import InvoiceMember from "./form-blocks/InvoiceMember";
import InvoiceItems from "./form-blocks/InvoiceItems";
import {
    createSigner,
    deleteSigner,
    loadSigners
} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import SelectDirector from "../../signers/SelectDirector";
import SelectAccountant from "../../signers/SelectAccountant";
import SelectReleaser from "../../signers/SelectReleaser";
import {selectCompanyCatalogs} from "../../../../app/store/reducers/roaming/roamingReducer";


const InvoiceForm = ({loading, existingNumber}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat);
    const {register, formState: {errors}} = useFormContext();

    useEffect(() => {
        dispatch(loadSigners());
    }, []);

    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <h5>{t(lang, "edi.common.invoice")}</h5>
                            </Col>
                            <Col xs={'auto'} className={'text-right'}>
                                <Button type="submit" disabled={loading}>
                                    {loading && <Spinner className="align-middle me-2" animation="border" role="switch"
                                                         size="sm"/>}
                                    {t(lang, "edi.common.save_button")}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row className="d-flex flex-column flex-lg-row">
                            <Col xs={12} lg={6}>
                                <Row>
                                    <Col xs={12} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.invoice.number")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Form.Control name="invoiceInfo.number"
                                                      type="text"
                                                      placeholder={t(lang, "edi.common.invoice.number.placeholder")}
                                                      isInvalid={errors?.invoiceInfo?.number}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.invoiceInfo?.number}
                                                      {...register('invoiceInfo.number', {
                                                          required: t(lang, "edi.common.forms.validations.is_required"),
                                                          validate: (value) => {
                                                              if (existingNumber) {
                                                                  if (value === existingNumber)
                                                                      return t(lang, "edi.common.forms.validations.is_exists", {name: t(lang, "edi.common.invoice.number")});
                                                              }
                                                              return true;
                                                          }
                                                      })}

                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.invoiceInfo?.number?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.invoice.date")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Controller name={'invoiceInfo.date'}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <DatePicker
                                                            placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            dateFormat={dateFormat}
                                                            disabled
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.invoiceInfo?.date,
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.invoiceInfo?.date,
                                                                'is-valid': Object.keys(errors).length > 0 && !errors?.invoiceInfo?.date
                                                            })}
                                                        />
                                                    )}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.invoiceInfo?.date?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.shipment.number")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Form.Control name="shipmentInfo.number"
                                                      type="text"
                                                      placeholder={t(lang, "edi.common.shipment.number.placeholder")}
                                                      disabled
                                                      isInvalid={errors?.shipmentInfo?.number}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.shipmentInfo?.number}
                                                      {...register('shipmentInfo.number', {
                                                          required: t(lang, "edi.common.forms.validations.is_required")
                                                      })}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.shipmentInfo?.number?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.shipment.date")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Controller name={'shipmentInfo.date'}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <DatePicker
                                                            placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            dateFormat={dateFormat}
                                                            disabled
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.shipmentInfo?.date,
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.shipmentInfo?.date,
                                                                'is-valid': Object.keys(errors).length > 0 && !errors?.shipmentInfo?.date
                                                            })}
                                                        />
                                                    )}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.shipmentInfo?.date?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.contract.number")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Form.Control name="contractInfo.number"
                                                      placeholder={t(lang, "edi.common.contract.number.placeholder")}
                                                      type="text"
                                                      isInvalid={errors?.contractInfo?.number}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.contractInfo?.number}
                                                      disabled
                                                      {...register('contractInfo.number', {
                                                          required: t(lang, "edi.common.forms.validations.is_required")
                                                      })}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.contract.date")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Controller name={'contractInfo.date'}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <DatePicker
                                                            placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            dateFormat={dateFormat}
                                                            disabled
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.contractInfo?.date,
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.contractInfo?.date,
                                                                'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.date
                                                            })}
                                                        />
                                                    )}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.contractInfo?.date?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.shipping_address")}
                                        </Form.Label>
                                        <Form.Control name="deliveryAddress"
                                                      type="text"
                                                      placeholder={t(lang, "edi.common.shipping_address.placeholder")}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.deliveryAddress}
                                                      {...register('deliveryAddress')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} lg={6} className="mt-4">
                <Card className="h-100">
                    <Card.Header>
                        <h5>{t(lang, "edi.common.customer")}</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <InvoiceMember rootName={'customer'}/>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} lg={6} className="mt-4">
                <Card className="h-100">
                    <Card.Header>
                        <h5>{t(lang, "edi.common.executor")}</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <InvoiceMember rootName={'executor'}/>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} className="mt-4">
                <Card className={'position-relative'}>
                    <Card.Header className={'pb-1'}>
                        <h5 className={'mb-0'}>{t(lang, "edi.common.items")}</h5>
                    </Card.Header>
                    <Card.Body className={'pt-0'}>
                        <InvoiceItems/>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} className="mt-4">
                <Card>
                    <Card.Header>
                        <h5>{t(lang, "edi.common.signers")}</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6}>
                                <Row>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>{t(lang, "edi.common.director")}</Form.Label>
                                        <Controller name={"director"}
                                                    render={({field}) => (
                                                        <SelectDirector
                                                            onCreate={(director) => {
                                                                console.log(director)
                                                                dispatch(createSigner(director));
                                                                field.onChange(director.label);
                                                            }}
                                                            onDelete={deleteSigner}
                                                        />
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>{t(lang, "edi.common.accountant")}</Form.Label>
                                        <Controller name={"accountant"}
                                                    render={({field}) => (
                                                        <SelectAccountant
                                                            onCreate={(accountant) => {
                                                                dispatch(createSigner(accountant));
                                                                field.onChange(accountant.label);
                                                            }}
                                                            onDelete={deleteSigner}
                                                        />
                                                    )}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Label>{t(lang, "edi.common.releaser")}</Form.Label>
                                        <Controller name={"releaser"}
                                                    render={({field}) => (
                                                        <SelectReleaser
                                                            onCreate={(releaser) => {
                                                                dispatch(createSigner(releaser));
                                                                field.onChange(releaser.label);
                                                            }}
                                                            onDelete={deleteSigner}
                                                        />
                                                    )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Row>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>{t(lang, "edi.common.shipper")}</Form.Label>
                                        <Form.Control
                                            placeholder={t(lang, "edi.common.shipper.placeholder")}
                                            type="text"
                                            isValid={Object.keys(errors).length > 0 && !errors?.proxyInfo?.name}
                                            {...register('proxyInfo.name')}
                                        />
                                    </Col>
                                    <Col xs={12} className="mb-2">
                                        <Form.Label>{t(lang, "edi.common.attorney.number")}</Form.Label>
                                        <Form.Control
                                            placeholder={t(lang, "edi.common.attorney.number.placeholder")}
                                            type="text"
                                            isValid={Object.keys(errors).length > 0 && !errors?.proxyInfo?.number}
                                            {...register('proxyInfo.number')}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Label>{t(lang, "edi.common.attorney.date")}</Form.Label>
                                        <Controller name="proxyInfo.date"
                                                    render={
                                                        ({field}) => (
                                                            <DatePicker
                                                                placeholderText={t(lang, "edi.common.attorney.date.placeholder")}
                                                                selected={field.value}
                                                                onChange={field.onChange}
                                                                dateFormat="dd.MM.yyyy"
                                                                className={classNames('form-control', {
                                                                    'is-valid': Object.keys(errors).length > 0
                                                                })}
                                                            />
                                                        )
                                                    }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col className="text-end">
                                <Button type="submit" disabled={loading}>
                                    {loading && <Spinner className="align-middle me-2" animation="border" role="switch"
                                                         size="sm"/>}
                                    {t(lang, "edi.common.save_button")}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>

                </Card>
            </Col>
        </Row>
    )
};

InvoiceForm.propTypes = {
    loading: PropTypes.bool,
    existingNumber: PropTypes.string
};

InvoiceForm.defaultProps = {
    loading: false,
    existingNumber: ''
};

export default InvoiceForm;
