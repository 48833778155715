import {useState} from "react";
import {useSelector} from "react-redux";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import IconButton from "../../../../common/IconButton";
import {Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import Button from "react-bootstrap/Button";
import {purchaseUpdateItemAsync} from "../../../../../app/store/reducers/warehouse-operation/purchaseReducer";
import PurchaseUpdateItemModalForm from "./PurchaseUpdateItemModalForm";


const PurchaseUpdateItemModal = ({purchase, item, itemIndex, ...props}) => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate;


    const form = useForm({
        defaultValues: {
            contractor_id: purchase?.contractor?.id,
            commonPriceUpdate: {
                amount: item?.price?.amount,
                currency_id: item?.price?.currency?.id,
            },
            item_id: null,
            quantity: item?.quantity,
            warehouse_id: null,
            warehouse: items?.find(it => it?.warehouse_states?.id === item?.warehouse_item?.id)?.warehouse_states?.warehouse_items,
            package_measurements: null,
        }
    })


    const handlerShow = () => {
        setShow(true)
        form.setValue("item_id", item?.warehouse_item?.id)
        form.setValue("commonPriceUpdate.amount", item?.price?.amount)
        form.setValue("commonPriceUpdate.currency_id", item?.price?.currency?.id)
        form.setValue("quantity", item?.quantity)
        form.setValue("warehouse_id", item?.warehouse_item?.warehouse?.id)
    }

    const handlerClose = () => {
        setShow(false)
        form.reset()
    }


    const onSubmit = async (formData) => {
        const packageQuantity = +formData?.quantity * +formData?.package_measurements?.quantity
        setLoading(true)

        const operationItem = {
            operation_item_id: item?.id,
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: packageQuantity,
            price: {
                amount: +formData?.commonPriceUpdate?.amount,
                currency_id: formData?.commonPriceUpdate?.currency_id,
            },
        }

        await purchaseUpdateItemAsync({
            purchaseId: purchase?.id,
            operation: operationItem
        })
            .then((res) => {
                toast.success(t(lang, "warehouse.operation.item.common.update.toast.success"))
                handlerClose()
            })
            .catch((err) => {
                toast.error(t(lang, "warehouse.operation.item.common.update.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <>
            <IconButton
                icon="pencil"
                variant="falcon-warning"
                size="sm"
                onClick={() => handlerShow()}
                {...props}
            />

            <Modal show={show} onHide={handlerClose} size="lg">
                <Modal.Header>
                    <FalconCloseButton onClick={handlerClose}/>
                </Modal.Header>
                <FormProvider {...form}>
                    <Form>
                        <Modal.Body>
                            <PurchaseUpdateItemModalForm/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handlerClose}>
                                {t(lang, "warehouse.operation.item.common.close")}
                            </Button>
                            <Button variant="falcon-info" type="button" onClick={form.handleSubmit(onSubmit)} disabled={loading}>
                                {loading &&
                                    <Spinner className='align-middle me-2' animation='border' size="sm"
                                             role='switch'/>
                                }
                                {t(lang, "items.common.save")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    )
}


export default PurchaseUpdateItemModal;