import React from 'react';
import {Col, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import GetOwnersPeriodReportButton from "./GetOwnersPeriodReportButton";

const DataTableHeader = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Row>
            <Col xs={12}>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <h5>{t(lang, 'partners.common.i_am_as_partner')}</h5>
                    <div>
                        <GetOwnersPeriodReportButton />
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default DataTableHeader;