import React from "react";
import UsernameInputForm from "../../UsernameInputForm";
import {Button, Form} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ForgotPasswordStepOne = () => {
    const {formState: {errors}} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <UsernameInputForm shouldExist={true} name="username" className="mb-3" isInvalid={errors?.username} />
            <Form.Group className="mb-3">
                <Button className="w-100" type="submit">
                    {t(lang, 'authentication.forgot-password.send_confirmation_code')}
                </Button>
            </Form.Group>
        </>
    );
};

export default ForgotPasswordStepOne;