export const ADD_ORGANIZATION_TEMPLATE_REQUESTED = "ADD_ORGANIZATION_TEMPLATE_REQUESTED";
export const ADD_ORGANIZATION_TEMPLATE_SUCCEED = "ADD_ORGANIZATION_TEMPLATE_SUCCEED";
export const ADD_ORGANIZATION_TEMPLATE_FAILED = "ADD_ORGANIZATION_TEMPLATE_FAILED";

export const UPDATE_ORGANIZATION_TEMPLATE_REQUESTED = "UPDATE_ORGANIZATION_TEMPLATE_REQUESTED";
export const UPDATE_ORGANIZATION_TEMPLATE_SUCCEED = "UPDATE_ORGANIZATION_TEMPLATE_SUCCEED";
export const UPDATE_ORGANIZATION_TEMPLATE_FAILED = "UPDATE_ORGANIZATION_TEMPLATE_FAILED";

export const DELETE_ORGANIZATION_TEMPLATE_REQUESTED = "DELETE_ORGANIZATION_TEMPLATE_REQUESTED";
export const DELETE_ORGANIZATION_TEMPLATE_SUCCEED = "DELETE_ORGANIZATION_TEMPLATE_SUCCEED";
export const DELETE_ORGANIZATION_TEMPLATE_FAILED = "DELETE_ORGANIZATION_TEMPLATE_FAILED";