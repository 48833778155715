import React from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {Controller, useFormContext} from "react-hook-form";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import WarehouseOperationItemSelect from "../../components/WarehouseOperationItemSelect";
import classNames from "classnames";
import WarehouseOperationWarehouseSelect from "../../components/WarehouseOperationWarehouseSelect";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";


const RevisionUpdateItemModalForm = () => {
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate



    // state
    const {errors} = form.formState

    // watch
    const warehouse = form.watch('warehouse')
    const warehouseId = form.watch('warehouse_id');


    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
    }





    return(
        <>
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.product_name")}</Form.Label>
                        <Controller
                            name="item_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationItemSelect
                                        options={items}
                                        allowImage={true}
                                        warehouseId={warehouseId}
                                        defaultValue={field?.value}
                                        isClearable
                                        onChange={option => {
                                            field?.onChange(option ? option?.item?.id : null)
                                            onChangeItem(option)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.item_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.item_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                {warehouses?.length > 1 &&
                    <Col xs={12}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.warehouse_name")}</Form.Label>
                        <Controller
                            name="warehouse_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationWarehouseSelect
                                        options={warehouse}
                                        defaultValue={field?.value}
                                        onChange={option => {
                                            field?.onChange(option ? option?.id : null)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.warehouse_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.warehouse_id?.message}
                        </Form.Control.Feedback>
                    </Col>
                }

                <Col xs={12}>
                    <Form.Group className="d-flex flex-column">
                        <Form.Label>{t(lang, "warehouse.operation.item.common.quantity")}</Form.Label>
                        <InputGroup>
                            <div className="d-flex w-100">
                                <Form.Control
                                    type="text"
                                    id="quantity"
                                    {...form.register("quantity", {
                                        required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                        pattern: {
                                            value: /^[0-9]*[\.,]?[0-9]*$/,
                                            message: "100 | 1.2 | 1,5"
                                        }
                                    })}
                                    placeholder={t(lang, "warehouse.operation.item.common.quantity:placeholder")}
                                    // isInvalid={errors?.quantity}
                                    className={classNames("form-control w-100")}
                                />
                            </div>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.quantity?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}


export default RevisionUpdateItemModalForm;