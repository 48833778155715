import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {
    SAVE_DRAFT_WAYBILL_v2_SUCCEED,
    SEND_WAYBILL_V2_SUCCEED
} from "../../../../app/eventbus/roaming/roamingWaybillV2Events";
import {loadDraftWaybillV2Async, setShowModal} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import EditWaybillV2Form from "../../../../components/roaming/waybill/forms/EditWaybillV2Form";

const EditWaybillV2 = () => {

    const routerHistory = useHistory();
    const {params: {id}} = useRouteMatch();
    const [draftWaybill, setDraftWaybill] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setShowModal(false))
        loadDraftWaybill();
    }, [id])

    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_V2_SUCCEED, waybill => {
            routerHistory.push(`/roaming/waybill-v2/${waybill.id}`)
            dispatch(getOrganizationBillingInfoAsync());
        });

        const onSaveDraftWaybillSucceedHandler = EventBus.on(SAVE_DRAFT_WAYBILL_v2_SUCCEED, draftWaybill => {
            routerHistory.push(`/roaming/waybill-v2/draft/${draftWaybill.id}`)
        })

        return () => {
            EventBus.remove(SEND_WAYBILL_V2_SUCCEED, onSendWaybillSucceedHandler);
            EventBus.remove(SAVE_DRAFT_WAYBILL_v2_SUCCEED, onSaveDraftWaybillSucceedHandler);
        }
    }, [])

    const loadDraftWaybill = () => {
        loadDraftWaybillV2Async(id)
            .then(loadedDraftWaybill => {
                const parsedContent = JSON.parse(loadedDraftWaybill.content);
                setDraftWaybill({...parsedContent, id: loadedDraftWaybill.id});
            })
            .catch(error => console.log(error))
    }

    return (
        <>
            {!draftWaybill && <SpinnerComponent />}
            {draftWaybill && <EditWaybillV2Form id={id} waybill={draftWaybill} />}
        </>
    );
};

export default EditWaybillV2;
