import React from 'react';
import {Form} from "react-bootstrap";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const DataTableLimiter = ({table, pagination, limitOptions = [10, 50, 100, 200, 1000]}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <div className="d-flex flex-column justify-content-center flex-sm-row justify-content-md-end">
            <p className="mb-0 me-sm-2">{t(lang, "edi.advance_table_custom_pagination.limit")}</p>
            <Form.Select size="sm"
                         className="w-auto"
                         defaultValue={pagination.pageSize}
                         onChange={e => table.setPageSize(+e.target.value)}
            >
                {limitOptions.map(option => (
                    <option key={option}
                            value={option}
                    >
                        {option}
                    </option>
                ))}
            </Form.Select>
        </div>
    );
};

export default DataTableLimiter;