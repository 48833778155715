import React, {useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import FalconCloseButton from "./FalconCloseButton";
import SearchItem from "../hippo/draft-item/components/SearchItem";
import IconButton from "./IconButton";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const SelectItemModal = ({onChange, buttonProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <React.Fragment>
            <IconButton onClick={handleShow} icon={faSearch} {...buttonProps}></IconButton>
            {show && <Modal show={true} onHide={handleClose} size="lg">
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.common.draft.select_item')}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <SearchItem defaultItemId={null}
                                onChange={(item) => {
                                    onChange(item);
                                    handleClose();
                                }}
                                selectProps={{
                                    defaultMenuIsOpen: true
                                }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "items.common.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>}
        </React.Fragment>
    );
};

export default SelectItemModal;
