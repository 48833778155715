import {Route, useRouteMatch, Switch} from "react-router-dom";
import React, {useEffect} from "react";
import Contractors from "./contractor/Contractors";
import ContractorPayments from "./contractor/ContractorPayments";
import ContractorPayouts from "./contractor/ContractorPayouts";
import Dashboard from "./Dashboard";
import ContractorOperations from "./contractor/ContractorOperations";
import ContractorCard from "./contractor/ContractorCard";
import SendContractFormPage from "../../../components/hippo/contract/forms/SendContractFormPage";
import EditContractFormPage from "../../../components/hippo/contract/forms/EditContractFormPage";
import ViewContract from "../../../components/hippo/contract/ViewContract";
import {loadMeasurementsAsync} from "../../../app/store/reducers/roaming/roamingReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../app/store/reducers/main/mainReducer";
import Contracts from "./contract/Contracts";
import {loadContractDataTableColumn} from "../../../app/store/reducers/contract/contractReducer";
import ContractorAgents from "./contractor/ContractorAgents";
import ContractorPayment from "./contractor/ContractorPayment";
import ContractorPayout from "./contractor/ContractorPayout";
import {currencyGetAsync} from "../../../app/store/reducers/currency/currencyReducer";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import CrmOrders from "./order/CrmOrders";

import CrmViewOrder from "./order/CrmViewOrder";
import TelegramBots from "./telegram-order-bot/TelegramBots";

import EventBus from "../../../app/eventbus/EventBus";
import Lead from "./lead/Lead";
import LeadCard from "./lead/LeadCard";
import CrmInternalOrders from "./internal/order/CrmInternalOrders";
import CrmInternalOrderAddForm from "./internal/order/CrmInternalOrderAddForm";
import CrmInternalOrderEditForm from "./internal/order/CrmInternalOrderEditForm";
import ContractorLabel from "../../../components/hippo/crm/contractor/label/ContractorLabel";
import {
    getAllContractorLabelsAsync,
    loadAllContractorLabelsAsync, selectContractorLabelFilterOptions
} from "../../../app/store/reducers/contractor/contractorLabelReducer";
import {
    ADD_CONTRACTOR_LABEL_SUCCESS,
} from "../../../app/eventbus/contractor/contractorLabelEvents";
import CrmExecutorOrderAddForm from "../../../components/hippo/crm/order/executor-orders/forms/CrmExecutorOrderAddForm";
import CrmExecutorOrderEditForm
    from "../../../components/hippo/crm/order/executor-orders/forms/CrmExecutorOrderEditForm";
import CrmCustomerOrderAddForm from "../../../components/hippo/crm/order/customer-orders/forms/CrmCustomerOrderAddForm";
import CrmCustomerOrderEditForm
    from "../../../components/hippo/crm/order/customer-orders/forms/CrmCustomerOrderEditForm";
import {Redirect} from "react-router";
import {VIEWER_TELEGRAM_ORDER_BOT} from "../../../enum/crm/TelegramBotWrapper";
import TelegramOrderBotOrders from "./order/TelegramOrderBotOrders";
import TelegramOrderBotOrder from "../../../components/hippo/crm/contractor/telegram-bot/orders/view/TelegramOrderBotOrder";
import TelegramOrderBot from "./telegram-order-bot/TelegramOrderBot";
import DraftPayments from "./contractor/DraftPayments";
import DraftPayment from "./contractor/DraftPayment";
import {loadEmployeesAsync} from "../../../app/store/reducers/employee/employeeReducer";
import {
    getBoardOrganizationAsync,
    getWithTasksAsync,
} from "../../../app/store/reducers/kanban/kanbanReducer";
import {ContractorDebtBoardType} from "../../../enum/KanbanWrapper";

import TelegramOrderBotService from "../../../services/telegram-order-bot/telegramOrderBotService";
import {updateBots} from "../../../app/store/reducers/crm/bot/telegramOrderBotReducer";

const CrmLayout = () => {
    const {url} = useRouteMatch();
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization)
    const labelFilters = useSelector(selectContractorLabelFilterOptions)

    useEffect(async () => {
        dispatch(loadMeasurementsAsync(lang))
        dispatch(loadContractDataTableColumn())
        dispatch(currencyGetAsync())
        dispatch(loadAllContractorLabelsAsync())
        dispatch(getAllContractorLabelsAsync({...labelFilters}))
        dispatch(loadEmployeesAsync());

        const {data: bots} = await TelegramOrderBotService.getTelegramOrderBots();
        dispatch(updateBots(bots));
        loadBoards()
    }, [lang, activeOrganization]);


    const loadBoards = async () => {
        const boards = await dispatch(getBoardOrganizationAsync({}))
        const board_id = boards?.find(board => board.type === ContractorDebtBoardType)?.id
        board_id && dispatch(getWithTasksAsync({board_id}))
    }


    useEffect(() => {
        const onContractorLabelAdd = EventBus.on(ADD_CONTRACTOR_LABEL_SUCCESS, () => {
            dispatch(loadAllContractorLabelsAsync())
        })

        return () => {
            EventBus.remove(ADD_CONTRACTOR_LABEL_SUCCESS, onContractorLabelAdd)
        }
    }, [])

    return (<>
        <Switch>
            <Route path={`${url}`} exact component={Dashboard}/>
            <Route path={`${url}/contractor`} component={Contractors}/>
            <Route path={`${url}/contractor-payments`} component={ContractorPayments}/>
            <Route path={`${url}/contractor-payouts`} component={ContractorPayouts}/>
            <Route path={`${url}/contractor-operation`} component={ContractorOperations}/>
            <Route path={`${url}/contractor-card/:id`} component={ContractorCard}/>

            <Route path={`${url}/contractor-payment/view/:id`} component={ContractorPayment}/>
            <Route path={`${url}/contractor-payout/view/:id`} component={ContractorPayout}/>

            <Route path={`${url}/contractor-contract/send`} component={SendContractFormPage}/>
            <Route path={`${url}/contractor-contract/edit/:id`} component={EditContractFormPage}/>
            <Route path={`${url}/contractor-contract/:id`} component={ViewContract}/>

            <Route path={`${url}/order/executor/register`} component={CrmExecutorOrderAddForm}/>
            <Route path={`${url}/order/executor/edit/:id`} component={CrmExecutorOrderEditForm}/>
            <Route path={`${url}/order/customer/register`} component={CrmCustomerOrderAddForm}/>
            <Route path={`${url}/order/customer/edit/:id`} component={CrmCustomerOrderEditForm}/>

            <Route path={`${url}/order/:id`} component={CrmViewOrder}/>
            <Route path={`${url}/order`} component={CrmOrders}/>

            <Route path={`${url}/contracts`} component={Contracts}/>
            <Route path={`${url}/contractor-agents`} component={ContractorAgents}/>

            {/*<Route path={`${url}/contractor-agent-bots`} component={TelegramBots}/>*/}

            <Route path={`${url}/telegram-bots/:viewer`} component={TelegramBots}/>
            <Route path={`${url}/telegram-order-bot/:id`} component={TelegramOrderBot}/>
            <Redirect from={`${url}/telegram-bots`} to={`${url}/telegram-bots/${VIEWER_TELEGRAM_ORDER_BOT}`}/>

            <Route path={`${url}/telegram-order/:id`} component={TelegramOrderBotOrder}/>
            <Route path={`${url}/telegram-orders`} component={TelegramOrderBotOrders}/>

            <Route path={`${url}/lead`} component={Lead}/>
            <Route path={`${url}/lead-card/:id`} component={LeadCard}/>
            <Route path={`${url}/internal/order/all`} component={CrmInternalOrders} />

            <Route path={`${url}/internal/order/create`} component={CrmInternalOrderAddForm} />
            <Route path={`${url}/internal/order/edit/:id`} component={CrmInternalOrderEditForm} />

            <Route path={`${url}/contractor-labels`} component={ContractorLabel}/>

            <Route path={`${url}/contractor-draft-payments`} exact component={DraftPayments}/>
            <Route path={`${url}/contractor-draft-payment/view/:id`} exact component={DraftPayment}/>
        </Switch>
    </>)
}
export default CrmLayout
