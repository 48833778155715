import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import logoutImg from '../../../assets/img/icons/spot-illustrations/45.png';
import {logoutAsync} from "../../../app/store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {changeDashboardPath, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const LogoutContent = ({titleTag: TitleTag}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        dispatch(logoutAsync())
        dispatch(changeDashboardPath())
    }, []);

    return (
        <>
            <img className="d-block mx-auto mb-4" src={logoutImg} alt="shield" width={100}/>
            <TitleTag>{t(lang, "edi.logout.title")}</TitleTag>
            <p>
                {t(lang, "edi.logout.description_1")}<br className="d-none d-sm-block"/>
                {t(lang, "edi.logout.description_2")}
            </p>
            <Button as={Link} color="primary" size="sm" className="mt-3" to="./login">
                <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="me-1"/>
                {t(lang, "edi.logout.button.go_to_login")}
            </Button>
        </>
    );
};

LogoutContent.propTypes = {
    layout: PropTypes.string,
    titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
    layout: 'simple',
    titleTag: 'h4'
};

export default LogoutContent;
