import React, {useRef, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faKey, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Controller, useForm, useWatch} from "react-hook-form";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import SelectOrganizationCatalog from "./SelectOrganizationCatalog";
import SelectTasnifCatalog from "./SelectTasnifCatalog";
import SelectExactCatalog from "./SelectExactCatalog";
import Divider from "../../../common/Divider";
import {editItemAsync} from "../../../../app/store/reducers/item/itemReducer";
import ReactSelect from "react-select";
import ResultCounter from "./ResultCounter";
import EventBus from "../../../../app/eventbus/EventBus";
import {BULK_EDIT_ITEMS_FINISHED, BULK_EDIT_ITEMS_STARTED} from "../../../../app/eventbus/itemEvents";
import {selectOrganization} from "../../../../app/store/reducers/draft-item/draftItemReducer";

const SelectBulkCatalogButton = ({items}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);
    const organization = useSelector(selectOrganization);
    const form = useForm({
        defaultValues: {
            catalog: null,
            catalogPackage: null
        }
    });
    const catalog = useWatch({name: 'catalog', control: form.control});

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = async (formData) => {
        handleClose();
        const payload = {
            code: formData.catalog.class_code,
            name: formData.catalog.name,
            package: formData.catalogPackage ? {
                name: formData.catalogPackage.name_ru,
                code: formData.catalogPackage.code.toString()
            } : null
        }
        ref.current.updateLoadedCount(items.length);
        EventBus.dispatch(BULK_EDIT_ITEMS_STARTED);
        for (let i = 0; i < items.length; i++) {
            try {
                const {item} = items[i];
                const itemJson = {
                    ...item,
                    measurement: item.measurement.toString(),
                    tax: {
                        catalog: payload,
                        benefit: null,
                        measurement: null,
                        tax_rate: item?.tax?.tax_rate || organization?.default_tax_rate || null
                    },
                }
                await editItemAsync(item.id, itemJson);
                ref.current.incrementSucceedCount();
            } catch (e) {
                console.log(e);
                ref.current.incrementFailedCount();
            }
        }
        EventBus.dispatch(BULK_EDIT_ITEMS_FINISHED);
    }

    const onChangeCatalog = (option) => {
        form.setValue('catalog', option);
        form.setValue('catalogPackage', (option.use_package && option.package_names.length === 1) ? option.package_names[0] : null);
    }

    return (
        <>
            <ResultCounter ref={ref}/>
            <IconButton variant={'falcon-primary'}
                        icon={faKey}
                        size="sm"
                        className="m-1"
                        onClick={handleShow}
            >
                {t(lang, 'items.common.add_bulk_catalog')}
            </IconButton>
            {show &&
                <Modal show={true}
                       onHide={handleClose}
                       size="xl"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'items.common.catalog_link_selector_modal.title')}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-2">
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{t(lang, 'items.common.catalog_link_selector.organization_catalogs')}</Form.Label>
                                    <SelectOrganizationCatalog onChange={onChangeCatalog}/>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{t(lang, 'items.common.catalog_link_selector.tasnif_catalogs')}</Form.Label>
                                    <SelectTasnifCatalog onChange={onChangeCatalog}/>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{t(lang, 'items.common.catalog_link_selector.exact_catalog')}</Form.Label>
                                    <SelectExactCatalog onChange={onChangeCatalog}/>
                                </Form.Group>
                            </Col>
                        </Row>


                        {catalog &&
                            <>
                                <Divider/>
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="text-info">{t(lang, 'items.common.catalog_link_selector.chosen_catalog')}</h5>
                                        <div className="mt-3 fw-bold fs--1">
                                            <span className="text-primary">{catalog.class_code} - </span>
                                            <span className="text-dark">{catalog.name}</span>
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon={faTrash}
                                                iconClassName={"text-danger"}
                                                className="ms-2"
                                                title={t(lang, 'items.common.delete')}
                                                onClick={() => form.reset()}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>{t(lang, 'items.common.package_measurement')}</Form.Label>
                                            <Controller name={'catalogPackage'}
                                                        control={form.control}
                                                        render={({field}) => (
                                                            <ReactSelect options={catalog.package_names}
                                                                         classNamePrefix="react-select"
                                                                         isClearable
                                                                         hideSelectedOptions
                                                                         getOptionLabel={option =>  option.name_ru}
                                                                         getOptionValue={option => option.code}
                                                                         placeholder={t(lang, "items.common.select")}
                                                                         value={field.value}
                                                                         onChange={field.onChange}
                                                            />
                                                        )}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={form.handleSubmit(onSubmit)} disabled={!catalog}>
                            {t(lang, 'items.common.save')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>

    );
};

export default SelectBulkCatalogButton;