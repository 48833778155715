import React, {useEffect} from 'react';
import KanbanProvider from "../../../../components/kanban/main/KanbanProvider";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useParams} from "react-router";
import {getWithTasksAsync,} from "../../../../app/store/reducers/kanban/kanbanReducer";

import EventBus from "../../../../app/eventbus/EventBus";
import {
    DRAG_AND_DROP_FAIL,
    DRAG_AND_DROP_TASK_FAIL,
    STATUS_DELETE_FAIL,
    STATUS_DELETE_SUCCESS,
    STATUS_REGISTER_FAIL,
    STATUS_REGISTER_SUCCESS,
    STATUS_UPDATE_FAIL,
    STATUS_UPDATE_SUCCESS
} from "../../../../app/eventbus/kanban/KanbanEvents";
import {toast} from "react-toastify";

import {useHistory} from "react-router-dom";


const KanbanView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const activeOrganization = useSelector(selectActiveOrganization)

    const lang = useSelector(selectLang);
    const t = Translate;

    const {id} = useParams();

    useEffect(async () => {
        getBoardAll()
    }, [activeOrganization])

    const getBoardAll = async () => {
        try {
            await dispatch(getWithTasksAsync({board_id: id}))
        } catch (e) {
            history.push('/kanban')
        }
    }

    useEffect(() => {
        //status success
        const onStatusRegisterHandlerSuccess = EventBus.on(STATUS_REGISTER_SUCCESS, () => {
            toastSuccess()
        })
        const onStatusUpdateHandlerSuccess = EventBus.on(STATUS_UPDATE_SUCCESS, () => {
            toastSuccess()
        })
        const onStatusDeleteHandlerSuccess = EventBus.on(STATUS_DELETE_SUCCESS, () => {
            toastSuccess()
        })

        // columns fail
        const onColumnDragAndDropHandlerFailed = EventBus.on(DRAG_AND_DROP_FAIL, () => {
            dispatch(getWithTasksAsync({board_id: id}))
            toastError()
        })

        const onStatusRegisterHandlerFailed = EventBus.on(STATUS_REGISTER_FAIL, () => {
            dispatch(getWithTasksAsync({board_id: id}))
            toastError()
        })

        const onStatusUpdateHandlerFailed = EventBus.on(STATUS_UPDATE_FAIL, () => {
            dispatch(getWithTasksAsync({board_id: id}))
            toastError()
        })

        const onStatusDeleteHandlerFailed = EventBus.on(STATUS_DELETE_FAIL, () => {
            dispatch(getWithTasksAsync({board_id: id}))
            toastError()
        })


        // tasks fail
        const onTaskDragAndDropHandlerFailed = EventBus.on(DRAG_AND_DROP_TASK_FAIL, () => {
            dispatch(getWithTasksAsync({board_id: id}))
            toastError()
        })


        return () => {
            EventBus.remove(STATUS_REGISTER_SUCCESS, onStatusRegisterHandlerSuccess)
            EventBus.remove(STATUS_UPDATE_SUCCESS, onStatusUpdateHandlerSuccess)
            EventBus.remove(STATUS_REGISTER_FAIL, onStatusRegisterHandlerFailed)

            EventBus.remove(STATUS_DELETE_SUCCESS, onStatusDeleteHandlerSuccess)
            EventBus.remove(STATUS_UPDATE_FAIL, onStatusUpdateHandlerFailed)
            EventBus.remove(STATUS_DELETE_FAIL, onStatusDeleteHandlerFailed)

            EventBus.remove(DRAG_AND_DROP_FAIL, onColumnDragAndDropHandlerFailed)
            EventBus.remove(DRAG_AND_DROP_TASK_FAIL, onTaskDragAndDropHandlerFailed)
        }
    }, [])

    function toastSuccess() {
        return toast.success(t(lang, "Success"))
    }

    function toastError() {
        return toast.error(t(lang, "Error"))
    }

    return (
        <KanbanProvider/>
    );
};


export default KanbanView;
