import {useDispatch, useSelector} from "react-redux";
import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import {Card, Dropdown, OverlayTrigger, Popover} from "react-bootstrap";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../../common/advance-table/AdvanceTableCustomPagination";
import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    changeFilterOptionsAsync, getRefundsAsync, getRefundsCountAsync,
    selectRefundCount,
    selectRefundFilterOptions,
    selectRefunds
} from "../../../../../../app/store/reducers/warehouse-operation/refundReducer";
import SoftBadge from "../../../../../common/SoftBadge";
import numeral from "numeral";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import IconButton from "../../../../../common/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect} from "react";
import ContractorRefundDatatableHeader from "./ContractorRefundDatatableHeader";
import {
    GetPaymentTypeText,
    PaymentTypeBankTransferCode,
    PaymentTypeCashCode, PaymentTypeClickCode,
    PaymentTypeHumoCode, PaymentTypePaymeCode,
    PaymentTypeUzCardCode, PaymentTypeVisaCode
} from "../../../../../../enum/ContractorWrapper";
import {
    OperationTotalSumInfoPayment, isApprovedCheckedWarehouseOperation,
    ItemsTotalSumGroupCurrencyId, ItemsTotalSumPayoutDebtGroupPaymentTypeCurrencyId,
    ItemsTotalSumPayoutDebtStatesGroupPaymentTypeCurrencyId, OperationInfoPaymentType,
    OperationTotalSumInfo,
} from "../../../../warehouse-operation/refund/enum/warehouseOperationWrapper";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import CardDropdown from "../../../../../common/CardDropdown";
import SaleDelete from "../../../../warehouse-operation/sale/components/SaleDelete";


const ContractorRefunds = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const numberFormat = useSelector(selectNumberFormat)
    const refunds = useSelector(selectRefunds)
    const dispatch = useDispatch()
    const filters = useSelector(selectRefundFilterOptions)
    const count = useSelector(selectRefundCount)
    const lang = useSelector(selectLang)
    const t = Translate
    const contractor = useSelector(selectCardContractor)

    const totalSumArray = ItemsTotalSumGroupCurrencyId(refunds)
    const totalSumCashBoxPaymentType = ItemsTotalSumPayoutDebtStatesGroupPaymentTypeCurrencyId(refunds)
    const totalSumDebtsPaymentType = ItemsTotalSumPayoutDebtGroupPaymentTypeCurrencyId(refunds)


    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return <>
                    <OverlayTrigger trigger={['hover', 'focus']}
                                    placement={'bottom'}
                                    overlay={
                                        <Popover id="popover-basic" className='mt-0'>
                                            <Popover.Header>
                                                <SoftBadge className={'d-block text-center fs--1'}
                                                           bg={row?.original?.is_approved ? 'success' : 'warning'}>{t(lang, isApprovedCheckedWarehouseOperation(row?.original?.is_approved))}</SoftBadge>
                                            </Popover.Header>
                                        </Popover>
                                    }>
                        <SoftBadge className={'d-block text-center fs--1 cursor-pointer'}
                                   bg={row?.original?.is_approved ? 'success' : 'warning'}>
                            {(filters.page - 1) * filters.limit + row.index + 1}
                        </SoftBadge>
                    </OverlayTrigger>
                </>
            }
        },
        {
            Header: t(lang, "warehouse.operation.item.common.total_sum"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumArray?.map((totalSum, index) => {
                return (
                    <div key={index}>
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <SoftBadge className={'d-block text-center fs--1'}
                                                               bg="info">{t(lang, "warehouse.operation.item.common.total_sum")}</SoftBadge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <SoftBadge bg="info" className="cursor-pointer">
                                {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                &nbsp;
                                <span className="fw-bold">{totalSum?.name}</span>
                            </SoftBadge>
                        </OverlayTrigger>
                    </div>
                )
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationTotalSumInfo(original?.items?.filter(f => !f?.is_deleted))


                return separationCurrencyCalc?.map((item, index) => {
                    return (
                        <div className={'fs-0'} key={index}>
                            <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"info"}>
                                {numeral.formats[numberFormat].format(item?.totalSum)}
                                &nbsp;
                                {item?.name}
                            </SoftBadge>
                        </div>
                    )
                })
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeCashCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeCashCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeCashCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationTotalSumInfoPayment(original?.payout?.cash_box_states)


                if (separationCurrencyCalc) {
                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeCashCode === item?.payment_type) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeUzCardCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeUzCardCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeUzCardCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeUzCardCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeUzCardCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeHumoCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeHumoCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeHumoCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeHumoCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeHumoCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeBankTransferCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeBankTransferCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeBankTransferCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeBankTransferCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeBankTransferCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeClickCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeClickCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeClickCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeClickCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeClickCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypePaymeCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypePaymeCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypePaymeCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypePaymeCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypePaymeCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeVisaCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeVisaCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeVisaCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payout?.cash_box_states)[PaymentTypeVisaCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeVisaCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, "warehouse.operation.item.common.debt_state"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },

            totalInfo: totalSumDebtsPaymentType.map((totalSum, index) => {

                if (totalSum?.totalSum) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="danger">{t(lang, "warehouse.operation.item.common.debt_state")}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="danger" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationTotalSumInfoPayment(original?.payout?.debt_states)

                if (separationCurrencyCalc) {
                    return separationCurrencyCalc?.map((item, index) => {
                        if (item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"danger"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ''
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.date_refund"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-column">
                        <span>{dayjs(original?.date).format("YYYY-MM-DD")}</span>
                        <span>{dayjs(original?.date).format("HH:mm:ss")}</span>
                    </div>
                )
            }
        },
        {
            Header: '',
            id: "none",
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => (
                <div className="d-inline-flex">
                    <Link to={`/warehouse/operation/refunds/view/${original?.id}`}>
                        <IconButton
                            variant="falcon-info"
                            icon="eye"
                            title={t(lang, "warehouse.operation.item.common.show")}
                            className="mx-2"
                            size="sm"
                        />
                    </Link>
                    <CardDropdown btnRevealClass="btn-reveal-sm">
                        <div className="py-2">
                            <Dropdown.Item as={Link} to={`/warehouse/operation/refunds/update/${original?.id}`}
                                           className="text-warning">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "warehouse.operation.item.common.edit")}</span>
                            </Dropdown.Item>
                            <SaleDelete id={original?.id}/>
                            <Dropdown.Item as={Link} to={`/warehouse/operation/refunds/view/${original?.id}`} className="text-info">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"eye"}/>
                                <span>{t(lang, "warehouse.operation.item.common.show")}</span>
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                </div>
            ),
        }
    ]


    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))


    useEffect(() => {
        dispatch(getRefundsAsync({...filters, contractor_id: contractor?.id}))
        dispatch(getRefundsCountAsync({...filters, contractor_id: contractor?.id}))
    }, [activeOrganization, filters, contractor])


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={refunds}
                pagination
                sortable
                perPage={1000}
                // selection
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ContractorRefundDatatableHeader/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}


export default ContractorRefunds;