import {useParams} from "react-router"
import VerificationActDataTable from "../../../../components/hippo/roaming/verification-act/VerificationActDataTable"
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingVerificationActView
} from "../../../../enum/Permission/RoamingPermission";
import {Card} from "react-bootstrap";
import React from "react";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const VerificationActs = () => {
    const { type_viewer } = useParams()
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    //important
    useSelector(selectAccountRolePermissions)


    if (!dispatch(checkPermission(PermissionRoamingVerificationActView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.roaming.verification-act.datatable.no_access.title')}</h4>
        </Card>
    }

    return <VerificationActDataTable typeViewer={type_viewer}/>

}

export default VerificationActs
