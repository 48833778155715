import axios from "axios";

class ItemHistoryService {
    getAllItemHistories(payload) {
        return axios.post(`/warehouse/WarehouseItemUse/get`, payload)
    }

    getAllItemHistoriesCount(payload) {
        return axios.post(`/warehouse/WarehouseItemUse/count`, payload)
    }
}

export default new ItemHistoryService();