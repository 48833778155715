import {useFormContext} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";


const ContractorAgentForm = ({fieldsPhone, appendPhone, removePhone}) => {
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate


    return(
        <>
            <Row>
                <Col xs={12}>
                    <Form.Group className="text-start">
                        <Form.Label>{t(lang, "crm.contractor.datatable.header.row.full_name")}</Form.Label>
                        <Form.Control
                            placeholder={t(lang, "crm.contractor.datatable.header.row.full_name")}
                            name="name"
                            type="text"
                            isInvalid={form.formState.errors?.name?.message}
                            isValid={Object.keys(form.formState.errors).length > 0 && !form.formState.errors?.name?.message}
                            {...form.register("name", {
                                required: t(lang, "edi.common.forms.validations.is_required")
                            })}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.formState.errors?.name?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={12} className="mt-2">
                    <Form.Group>
                        <Form.Label>
                            {t(lang, "edi.common.cellPhone")}
                        </Form.Label>
                        {fieldsPhone.map((item, index) => {
                            return (
                                <div key={index} className={"mb-2 d-flex align-items-baseline"}>
                                    <div className={"w-100"}>
                                        <Form.Control
                                            type='number'
                                            placeholder="998ххххххххх"
                                            isInvalid={form.formState.errors?.phones?.[index]?.value.message}
                                            isValid={Object.keys(form.formState.errors).length > 0 && !form.formState.errors?.phones?.[index]?.value.message}
                                            className='input-spin-none'
                                            {...form.register(`phones.${index}.value`, {
                                                validate: {
                                                    format: value => !value || /^998(\d{9})$/.test(value) || t(lang, 'authentication.forgot-password.phoneNumber.validations.format'),
                                                }
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {form.formState.errors?.phones?.[index]?.value.message}
                                        </Form.Control.Feedback>
                                    </div>
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon={"trash-alt"}
                                        className={"ms-2"}
                                        iconClassName={"text-secondary"}
                                        onClick={() => {
                                            removePhone(index)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </Form.Group>
                    <h6 className={"text-info cursor-pointer mt-2"} onClick={() => {
                        appendPhone({value: ''})
                    }}>{t(lang, 'crm.contractor.agent.modal.add.phone')}</h6>
                </Col>
                <Col xs={12} className="d-flex justify-content-end">
                    <Button type="submit" variant="falcon-primary">
                        {t(lang, "crm.common.add.button")}
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default ContractorAgentForm;