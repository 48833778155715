import React, {Fragment, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import AddContractorLabelForm from "../../contractor/label/AddContractorLabelForm";
import BindContractorsToLabelModal from "./BindContractorsToLabelModal";
import IconButton from "../../../../common/IconButton";

const ContractorLabelDatatableHeader = () => {
    const lang = useSelector(selectLang)
    const t = Translate
    const [showBindLabelContractorsModal, setShowBindLabelContractorsModal] = useState(false)

    return (
        <Fragment>
            <Row className="d-flex justify-content-between">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0 me-2">{t(lang, "crm.contractor.label")}</h5>
                </Col>
                <Col xs={8} className="d-flex justify-content-end">
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="right-left"
                        transform="shrink-3"
                        className={'me-2'}
                        onClick={() => setShowBindLabelContractorsModal(true)}
                    ><span className="d-none d-sm-inline-block">{t(lang, "crm.contractor.bind.label.button")}</span>
                    </IconButton>

                    <AddContractorLabelForm
                        icon="fa-plus"
                        title={t(lang, "crm.lead.add")}
                        variant="falcon-primary"
                    />
                </Col>
            </Row>

            <BindContractorsToLabelModal show={showBindLabelContractorsModal}
                                         setShow={setShowBindLabelContractorsModal}/>
        </Fragment>
    );
};

export default ContractorLabelDatatableHeader;