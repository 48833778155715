import {Button, Col, Form, Row} from "react-bootstrap";
import React from "react";
import {Controller} from "react-hook-form";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import Select from "react-select"
import {bankPaymentFilterCheckResolvedOptions} from "../../../../enum/AdminWrapper";

const BankPaymentErrorDataTableFilter = ({onClear}) => {
    const dateFormat = useSelector(selectDateFormat)
    const lang = useSelector(selectLang)
    const t = Translate



    return(
        <>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "admin.common.payments.datatable.navigation.filter.h1")}</h4>
            </Row>

            <Row className="mb-3 g-3">
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.date_start")}</Form.Label>
                    <Controller
                        name="created_on_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.date_end")}</Form.Label>
                    <Controller
                        name="created_on_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.select.label")}</Form.Label>
                    <Controller
                        name="resolved"
                        render={({field}) => (
                            <Select
                                options={bankPaymentFilterCheckResolvedOptions}
                                value={bankPaymentFilterCheckResolvedOptions.find(option => option.value === field.value) || null}
                                onChange={(option) => field.onChange(option?.value)}
                                getOptionLabel={(option) => t(lang, option.label)}
                                hideSelectedOptions
                                isClearable
                                className="basic-single"
                                classNamePrefix="react-select"
                                placeholder={t(lang, 'admin.common.payments.datatable.navigation.filter.select:placeholder')}
                            />
                        )}
                    />
                </Form.Group>
                <Col sm={12} md={4} lg={3} className="d-flex align-items-end justify-content-end justify-content-lg-start gap-3">
                    <Button variant="falcon-primary" onClick={onClear} type='button'>{t(lang, "items.common.clear_imported_file")}</Button>
                    <Button variant="primary" type='submit'>{t(lang, "admin.common.payments.datatable.navigation.filter.show_button")}</Button>
                </Col>
            </Row>
        </>
    )
}


export default BankPaymentErrorDataTableFilter;
