import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {useHistory} from "react-router";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {Button, Card, Col, Form, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import SoftBadge from "../../../../common/SoftBadge";
import {isApprovedCheckedWarehouseOperation} from "../../enum/warehouseOperationWrapper";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import IconButton from "../../../../common/IconButton";
import WarehouseOperationCategorySelect from "../../components/WarehouseOperationCategorySelect";
import WarehouseOperationItemSelect from "../../components/WarehouseOperationItemSelect";
import WarehouseOperationWarehouseSelect from "../../components/WarehouseOperationWarehouseSelect";
import {faArrowLeftLong} from "@fortawesome/free-solid-svg-icons/faArrowLeftLong";
import WarehouseOperationImageModal from "../../components/WarehouseOperationImageModal";
import WarehouseOperationDeleteItem from "../../components/WarehouseOperationDeleteItem";
import WriteOffUpdateItemModal from "../components/WriteOffUpdateItemModal";
import {
    selectWriteOffDefaultsSettings,
    writeOffApproveAsync,
    writeOffDeleteItemAsync, writeOffUpdateDateAsync,
    writeOffUpdateItemAsync, writeOffUpdateNumberAsync
} from "../../../../../app/store/reducers/warehouse-operation/writeOffReducer";
import WriteOffSettings from "../components/WriteOffSettings";
import EventBus from "../../../../../app/eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../../app/eventbus/itemEvents";
import {ADD_EMPLOYEE_SUCCEED} from "../../../../../app/eventbus/employeeEvents";
import EmployeeSelect from "../../../common/EmployeeSelect";


const colIndexItem = 0
const colIndexQuantity = 2
const colIndexWarehouse = 6


const colCoordinateZero = 0
const rowCoordinateOne = 1
const subRowoordinateTwo = 2




const WriteOffForm = ({writeOff, loading, addItem}) => {
    const writeOffDefaultsSettings = useSelector(selectWriteOffDefaultsSettings)
    const dateFormat = useSelector(selectDateFormat)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate


    // state
    const [categoryItems, setCategoryItems] = useState(null)
    const [isHover, setIsHover] = useState([])
    const [filter, setFilter] = useState('')
    const {errors} = form?.formState


    // watch
    const warehouseId = useWatch({name: 'warehouse_id', exact: true})
    const numberWatch = useWatch({name: 'number', exact: true})
    const warehouse = form.watch('warehouse')
    const quantity = form.watch('quantity')
    const itemId = useWatch({name: 'item_id', exact: true})

    useEffect(() => {
        if (!warehouseId) {
            form?.setValue("warehouse_id", writeOffDefaultsSettings?.defaultWarehouseId)
        }
        const onEmployeeAddedHandler = EventBus.on(ADD_EMPLOYEE_SUCCEED, (res) => {
            form?.setValue("employee_id", res?.data?.id)
        })
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(ADD_EMPLOYEE_SUCCEED, onEmployeeAddedHandler)
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    },[writeOffDefaultsSettings])

    useEffect(() => {
        if (warehouses?.length === 1 && warehouse) {
            form?.setValue("warehouse_id", warehouse[0]?.id)
        }
    },[warehouse])

    useEffect(() => {
        form?.setValue("employee_id", writeOffDefaultsSettings?.defaultEmployeeId)
    }, [writeOffDefaultsSettings?.defaultEmployeeId]);


    const onScan = (value) => {
        if (value.length > 14){
            const quantityGet = form?.getValues('quantity')

            form?.setValue('marks', [value])
            form?.setValue('quantity', +quantityGet + 1)

            addItem("addOrEditWriteOff")
        }
    }


    const onChangeCategory = (option) => {
        setCategoryItems(items.filter(i => i?.item?.category?.id === option?.id))
    }

    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
    }




    // focus start

    const onFocusItem = () => {
        if (!itemId) {
            return true
        }
    }

    const onFocusWarehouse = () => {
        if (itemId && !warehouseId) {
            return true
        }
    }

    const onFocusQuantity = () => {
        if (warehouseId && itemId && !quantity) {
            document.getElementById('quantity')?.focus()
            return true
        }
    }

    useEffect(() => {
        onFocusQuantity()
    }, [warehouseId, itemId])
    // focus end




    const data = useMemo(() => {
        return writeOff?.items?.filter(i => {
            const searchLower = filter.trim().toLowerCase();

            // search by barcode
            {
                if (searchLower.length >= 8 && searchLower.length <= 13 && !isNaN(searchLower)) {
                    const searchBarcode = items.filter(it => it.item?.barcodes?.some((b) => b?.includes(searchLower)))

                    if (searchBarcode.some(bar => bar?.item?.name.trim().toLowerCase() === i?.warehouse_item?.name.trim().toLowerCase()))
                        return true;
                }
            }

            // search by name
            {
                const filterNameParts = searchLower.replaceAll('  ', ' ').split(' ');
                const itemLowerName = i.warehouse_item?.name.toLowerCase();
                if (filterNameParts?.length === filterNameParts?.filter(fnp => itemLowerName?.indexOf(fnp) > -1)?.length)
                    return true;
            }
        })
    }, [writeOff, filter])

    const groupByItemIdAndPrice = (filterItems) => {
        var groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;


            if (itemID in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[itemID].sub.push(item)
            } else {
                if (itemID) {
                    groupByItemIdPriceIdCurrencyId[itemID] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(data?.filter(f => !f?.is_deleted))



    // hover mouser over
    const handleMouseOver = ({col, row, subRow}) => {
        setIsHover([col, row, subRow])
    }


    const updateItem = async ({itemOne, quantity, warehouseId, item}) => {
        const operationItem = {
            operation_item_id: item?.id,
            item_id: item?.warehouse_item?.id,
            warehouse_id: item?.warehouse_item?.warehouse?.id,
            quantity: item?.quantity,
        }


        if (itemOne) {
            operationItem.item_id = itemOne?.item?.id
        }
        if(quantity) {
            operationItem.quantity = +quantity
        }
        if(warehouseId) {
            operationItem.warehouse_id = warehouseId
        }

        await writeOffUpdateItemAsync({
            writeOffId: writeOff?.id,
            operation: operationItem
        })
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.update.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.update.toast.error"))
            })
            .finally(() => {
                setIsHover([])

            })
    }

    // delete item
    const onDeleteItem = async ({id, itemId}) => {
        await writeOffDeleteItemAsync({writeOffId: id, operationId: itemId})
            .then((response) => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch((error) => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }

    // change date
    const onDateChanged = async (e, date) => {
        if (e === "Save") {
            await writeOffUpdateDateAsync({
                writeOffId: writeOff?.id,
                date: date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : dayjs(writeOff?.date).format("YYYY-MM-DD HH:mm:ss")
            })
                .then(() => {
                    form.setValue('date', date ? dayjs(date, "YYYY-MM-DD HH:mm").toDate() : dayjs(writeOff?.date, "YYYY-MM-DD HH:mm").toDate())
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
        if (e === "Cancel") {
            form.setValue('date', dayjs(writeOff?.date, "YYYY-MM-DD HH:mm").toDate())
        }
    }

    const onNumberChanged = (e, number) => {
        if (e === "Save") {
            if (number?.trim() && number !== writeOff?.number) {
                writeOffUpdateNumberAsync({writeOffId: writeOff?.id, number: number})
                    .then(() => {
                        form.setValue('number', number ? number : writeOff?.number)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch(() => {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    })
            }
        }
        if (e === "Cancel") {
            form.setValue('number', writeOff?.number)
        }
    }


    // approve
    const onApprovedHandler = async () => {
        await writeOffApproveAsync({writeOffId: writeOff?.id})
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }


    return(
        <>
            <Card className={'mb-2'}>
                <Card.Body>
                    <IconButton
                        size={'lg'}
                        variant="falcon-warning"
                        icon={faArrowLeftLong}
                        className="me-2"
                        onClick={() => history.goBack()}
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "warehouse.operation.item.common.button.back")}
                        </span>
                    </IconButton>
                </Card.Body>
            </Card>

            <Card className="bg-light">
                <Card.Header>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between">
                            <h4 className="text-dark">
                                {t(lang, "warehouse.operation.item.common.new_write_off")}
                            </h4>
                            <div>
                                {writeOff &&
                                    <>
                                        <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                        <SoftBadge bg={writeOff?.is_approved ? 'success' : 'warning'}>
                                            {t(lang, isApprovedCheckedWarehouseOperation(!!writeOff?.is_approved))}
                                        </SoftBadge>
                                    </>
                                }
                                <WriteOffSettings/>
                            </div>
                        </Col>
                        {writeOffDefaultsSettings?.isCheckedViewDateTime &&
                            <Col xs={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>{t(lang, 'Дата')}</Form.Label>
                                    <Controller
                                        name={`date`}
                                        rules={{
                                            required: t(lang, 'warehouse.operation.item.common.validation.is_required'),
                                        }}
                                        render={({field}) => {
                                            return (
                                                <InputGroup>
                                                    <div className="form-control p-0">
                                                        <DatePicker
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            placeholderText={t(lang, "date")}
                                                            dateFormat={`${dateFormat} HH:mm`}
                                                            showTimeSelect
                                                            timeFormat="p"
                                                            timeIntervals={60}
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.date
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.date,
                                                            })}
                                                        />
                                                    </div>
                                                    {dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== writeOff?.date && writeOff &&
                                                        <>
                                                            <IconButton
                                                                variant={"falcon-info"}
                                                                title={"Save"}
                                                                size="md"
                                                                onClick={(e) => onDateChanged("Save", field?.value)}
                                                                icon={"check"}
                                                            />
                                                            <IconButton
                                                                variant="danger"
                                                                title={"Cancel"}
                                                                size="md"
                                                                onClick={(e) => onDateChanged("Cancel")}
                                                                icon="cancel"
                                                            />
                                                        </>
                                                    }
                                                </InputGroup>
                                            )
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.date?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "Сотрудника")}</Form.Label>
                                <Controller
                                    name="employee_id"
                                    render={({field}) => {
                                        return (
                                            <EmployeeSelect
                                                classNamePrefix="react-select"
                                                defaultValue={field?.value}
                                                onChange={(option) => {
                                                    field.onChange(option ? option?.id : null)
                                                }}
                                                isClearable={true}
                                                allowAdd={true}
                                                allowEdit={true}
                                            />
                                        )
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} xl={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "warehouse.navbar.routes.warehouse")}</Form.Label>
                                <Controller
                                    name="warehouse_id"
                                    rules={{
                                        required: t(lang, "warehouse.operation.item.common.validation.is_required")
                                    }}
                                    render={({field}) => {
                                        return (
                                            <WarehouseOperationWarehouseSelect
                                                classNamePrefix="react-select"
                                                defaultValue={field?.value}
                                                onChange={(option) => {
                                                    field.onChange(option ? option?.id : null)
                                                }}
                                            />
                                        )
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors?.warehouse_id?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {writeOffDefaultsSettings?.isCheckedViewNumber &&
                            <Col xs={12} lg={6}>
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label>{t(lang, "№")}</Form.Label>
                                    <InputGroup>
                                        <div className="form-control p-0">
                                            <Form.Control
                                                type="text"
                                                {...form.register("number")}
                                                placeholder={t(lang, "Номер")}
                                                className={classNames("form-control w-100")}
                                            />
                                        </div>
                                        {numberWatch !== writeOff?.number && writeOff &&
                                            <>
                                                <IconButton
                                                    variant={"falcon-info"}
                                                    title={"Save"}
                                                    size="md"
                                                    onClick={(e) => onNumberChanged("Save", numberWatch)}
                                                    icon={"check"}
                                                />
                                                <IconButton
                                                    variant="danger"
                                                    title={"Cancel"}
                                                    size="md"
                                                    onClick={(e) => onNumberChanged("Cancel")}
                                                    icon="cancel"
                                                />
                                            </>
                                        }
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        }
                        {writeOffDefaultsSettings?.isCheckedViewCategories &&
                            <Col xs={12} lg={6}>
                                <Form.Label>{t(lang, "Категории")}</Form.Label>
                                <Controller
                                    name="category_id"
                                    render={({field}) => {
                                        return (
                                            <WarehouseOperationCategorySelect
                                                classNamePrefix="react-select"
                                                defaultValue={field?.value}
                                                onChange={option => {
                                                    field?.onChange(option ? option?.id : null)
                                                    onChangeCategory(option)
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </Col>
                        }
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "warehouse.operation.item.common.product_name")}</Form.Label>
                                <Controller
                                    name="item_id"
                                    rules={{
                                        required: t(lang, "warehouse.operation.item.common.validation.is_required")
                                    }}
                                    render={({field}) => {
                                        return (
                                            <WarehouseOperationItemSelect
                                                classNamePrefix="react-select"
                                                onFocus={onFocusItem()}
                                                warehouseId={warehouseId}
                                                options={categoryItems || items}
                                                allowEdit={true}
                                                allowAdd={true}
                                                allowImage={true}
                                                defaultValue={field?.value}
                                                defaultsSettings={writeOffDefaultsSettings}
                                                isClearable
                                                onChange={option => {
                                                    field?.onChange(option ? option?.item?.id : null)
                                                    onChangeItem(option)
                                                }}
                                                className={classNames({
                                                    'is-invalid': errors?.item_id
                                                })}
                                            />
                                        )
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors?.item_id?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {/*{warehouses?.length > 1 &&
                            <>
                                {writeOffDefaultsSettings?.isCheckedViewWarehouses &&
                                    <Col xs={12} lg={6}>
                                        <Form.Label>{t(lang, "warehouse.operation.item.common.warehouse_name")}</Form.Label>
                                        <Controller
                                            name="warehouse_id"
                                            rules={{
                                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                                            }}
                                            render={({field}) => {
                                                return (
                                                    <WarehouseOperationWarehouseSelect
                                                        onFocus={onFocusWarehouse()}
                                                        options={warehouse}
                                                        defaultValue={field?.value}
                                                        onChange={option => {
                                                            field?.onChange(option ? option?.id : null)
                                                        }}
                                                        className={classNames({
                                                            'is-invalid': errors?.warehouse_id
                                                        })}
                                                    />
                                                )
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.warehouse_id?.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                }
                            </>
                        }*/}
                        <Col xs={12} lg={6}>
                            <Form.Group className="d-flex flex-column">
                                <Form.Label>{t(lang, "warehouse.operation.item.common.quantity")}</Form.Label>
                                <InputGroup>
                                    <div className="d-flex w-100">
                                        <Form.Control
                                            type="text"
                                            id="quantity"
                                            {...form.register("quantity", {
                                                required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                                pattern: {
                                                    value: /^[0-9]*[\.,]?[0-9]*$/,
                                                    message: "100 | 1.2 | 1,5"
                                                }
                                            })}
                                            placeholder={t(lang, "warehouse.operation.item.common.quantity:placeholder")}
                                            // isInvalid={errors?.quantity}
                                            className={classNames("form-control w-100")}
                                        />
                                    </div>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors?.quantity?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{t(lang, "Описание")}</Form.Label>
                                <Form.Control
                                    placeholder="Описание"
                                    as="textarea"
                                    rows={4}
                                    name="note"
                                    {...form?.register("note")}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Row>
                        <Col xs={12} className="d-flex flex-wrap justify-content-between">
                            <div>
                                <Button type="submit" disabled={loading} variant="falcon-primary" name="addOrEditWriteOff" size={'lg'}>
                                    {loading &&
                                        <Spinner className='align-middle me-2' animation='border' size="sm" role='switch'/>
                                    }
                                    {t(lang, "warehouse.operation.item.common.button.add")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>



            <Card className="bg-light mt-3 gap-2">
                <Card.Footer>
                    <Row>
                        <Col className="d-flex justify-content-between flex-wrap">
                            <div className="d-flex">
                                {writeOff?.items?.length &&
                                    <>
                                        <Form.Control
                                            name="searchNameAndBarcode"
                                            placeholder={t(lang, "warehouse.operation.item.common.filter.search")}
                                            value={filter}
                                            onChange={(e) => setFilter(e.target.value)}
                                        />
                                    </>
                                }
                            </div>
                            <div>
                                <h4 className="text-center">{t(lang, "warehouse.operation.item.common.list_sold")}</h4>
                            </div>
                            <div className="d-flex">
                                {writeOff?.items.length > 0 && !writeOff?.is_approved &&
                                    <IconButton
                                        variant="falcon-primary"
                                        icon="layer-group"
                                        className="mx-2"
                                        size="md"
                                        onClick={() => onApprovedHandler()}
                                    >{t(lang, "warehouse.operation.item.common.status.approve")}</IconButton>
                                }
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="table-responsive fs--1 mt-3" style={{maxHeight: "560px"}}>
                                <Table className="border-bottom">
                                    <thead className="table-primary" style={{position: "sticky", top: '-1px'}}>
                                    <tr>
                                        <th className="align-middle">№</th>
                                        <th className="align-middle"
                                            style={{width: "300px"}}>{t(lang, "warehouse.operation.item.common.name")}</th>
                                        <th className="text-center">{t(lang, "warehouse.operation.item.common.image")}</th>
                                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                                        {writeOff?.items?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                                            <th className="text-center">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                                        }
                                        {warehouses?.length > 1 &&
                                            <th className="text-start"
                                                style={{width: "230px"}}>{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                                        }
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                                        return (
                                            <React.Fragment key={rowIndex}>
                                                {item?.sub?.map((subItem, subRowIndex) => {
                                                    return (
                                                        <tr key={subItem.id} className="fw-bold" style={{height: "62px"}}>
                                                            <td key={rowIndex}
                                                                className={subRowIndex === 0 ? "text-start border-end border-2 ps-3" : "d-none"}
                                                                rowSpan={item?.sub?.length}
                                                                style={{width: "50px"}}
                                                            >
                                                                {groupByItemPriceCurrencyId?.length - rowIndex}
                                                            </td>
                                                            <td className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                                                rowSpan={item?.sub?.length}
                                                                style={{minWidth: "300px"}}
                                                                onDoubleClick={() => handleMouseOver({
                                                                    col: colIndexItem,
                                                                    row: rowIndex,
                                                                    subRow: subRowIndex
                                                                })}
                                                            >
                                                                {isHover[colCoordinateZero] === colIndexItem && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                                    <WarehouseOperationItemSelect
                                                                        options={items}
                                                                        defaultValue={subItem?.warehouse_item?.id}
                                                                        menuPortalTarget={document.body}
                                                                        onChange={option => {
                                                                            updateItem({
                                                                                itemOne: option,
                                                                                item: subItem
                                                                            })
                                                                        }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        {subItem?.warehouse_item?.name}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className={subRowIndex === 0 ? "text-center border-end border-2" : "d-none"}
                                                                style={{width: "50px"}}
                                                                rowSpan={item?.sub?.length}
                                                            >
                                                                {items.find(i => i?.item?.id === subItem?.warehouse_item?.id)?.item?.images?.length > 0 &&
                                                                    <WarehouseOperationImageModal
                                                                        image={items.find(i => i?.item?.id === item?.item?.id)?.item?.images}/>
                                                                }
                                                            </td>
                                                            <td className="text-start border-end border-2"
                                                                style={{maxWidth: "100px"}}
                                                                onDoubleClick={() => handleMouseOver({
                                                                    col: colIndexQuantity,
                                                                    row: rowIndex,
                                                                    subRow: subRowIndex
                                                                })}>
                                                                {isHover[colCoordinateZero] === colIndexQuantity && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                                    <Form.Control
                                                                        placeholder={t(lang, "Quantity")}
                                                                        defaultValue={subItem?.quantity}
                                                                        onFocus={(e) => e.target.select()}
                                                                        autoFocus
                                                                        onBlur={(e) => {
                                                                            updateItem({
                                                                                quantity: e.target.value,
                                                                                item: subItem,
                                                                            })
                                                                        }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        {subItem?.quantity}
                                                                    </>
                                                                }
                                                            </td>
                                                            {groupByItemPriceCurrencyId[rowIndex]?.sub?.some(s => s?.warehouse_item?.warehouse_item_use) &&
                                                                <td className="text-center border-end border-2 text-nowrap">
                                                                    {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                                    <span> => </span>
                                                                    {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                                                </td>
                                                            }
                                                            {warehouses?.length > 1 &&
                                                                <td className="text-start border-end border-2"
                                                                    style={{width: "240px"}}
                                                                    onDoubleClick={() => handleMouseOver({
                                                                        col: colIndexWarehouse,
                                                                        row: rowIndex,
                                                                        subRow: subRowIndex
                                                                    })}>
                                                                    {isHover[colCoordinateZero] === colIndexWarehouse && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex && warehouses?.length > 1 ?
                                                                        <WarehouseOperationWarehouseSelect
                                                                            options={items?.find(f => f?.warehouse_states?.id === subItem?.warehouse_item?.id)?.warehouse_states?.warehouse_items}
                                                                            defaultValue={subItem?.warehouse_item?.warehouse?.id}
                                                                            onFocus={() => true}
                                                                            autoFocus={true}
                                                                            onBlur={(warehouse) => {
                                                                                updateItem({
                                                                                    warehouseId: warehouse?.id,
                                                                                    item: subItem,
                                                                                })
                                                                            }}
                                                                            onChange={warehouse => {
                                                                                updateItem({
                                                                                    warehouseId: warehouse?.id,
                                                                                    item: subItem,
                                                                                })
                                                                            }}
                                                                        />
                                                                        :
                                                                        <>
                                                                            {subItem?.warehouse_item?.warehouse?.name}
                                                                        </>
                                                                    }
                                                                </td>
                                                            }


                                                            <td className="text-nowrap text-center border-end border-2"
                                                                style={{width: "40px"}}>
                                                                {subItem?.is_deleted &&
                                                                    <span className="text-end text-nowrap text-danger">
                                                                        {t(lang, "warehouse.operation.item.common.removed")}
                                                                    </span>
                                                                }
                                                                <WriteOffUpdateItemModal writeOff={writeOff} item={subItem}
                                                                                         itemIndex={rowIndex}/>

                                                                <WarehouseOperationDeleteItem onClick={onDeleteItem}
                                                                                              itemId={subItem?.id}
                                                                                              id={writeOff?.id}/>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </>
    )
}


export default WriteOffForm;
