import axios from 'axios';

class AuthService {
    login(username, password) {
        return axios.post('/auth/login', {
            username: username,
            password: password,
            signature: null
        });
    }

    loginWithCertificate(signature) {
        return axios.post('/auth/login', {
            username: null,
            password: null,
            signature: signature
        })
    }

    logout() {
        return axios.post('/auth/logout');
    }

    getMe() {
        return axios.get('/auth/get-me')
    }

    switchOrganization(id) {
        return axios.post('/auth/switch-organization', {
            id: id
        });
    }


    getUsernameExist(username) {
        return axios.get(`/auth/username-exists/${username}`)
    }

    getCompanyExist(inn) {
        return axios.get(`/auth/company-exists/${inn}`)
    }

    changePassword({oldPassword, newPassword}) {
        return axios.post('/auth/change-password', {
            old_password: oldPassword,
            new_password: newPassword
        })
    }

    getRegistrationVerifyConfirmationTicket(inn) {
        return axios.get(`/auth/get-register-verify-confirmation/${inn}`)
    }

    getVerifyHashcode(inn) {
        return axios.get(`/auth/get-verify-hashcode/${inn}`)
    }

    register({inn, name, fullName, signature, username, password, confirmCode, confirmTicket, referral_agent_code}) {
        return axios.post('/auth/register', {
            inn: inn,
            name: name,
            signature: signature,
            referral_agent_code,
            account: {
                name: fullName,
                username: username,
                password: password,
                confirm_code: confirmCode,
                confirm_ticket: confirmTicket,
            }
        });
    }

    getOrganizationUsers(){
        return axios.get(`/auth/get-organization-accounts`)
    }

    deleteUser({username}){
        return axios.post(`/auth/remove-account-from-organization`,{
            username: username,
        })
    }

    addUser({username, password, name, confirmCode, confirmTicket}) {
        return axios.post('/auth/add-account-to-organization', {
            username: username,
            password: password,
            name: name,
            confirm_code: confirmCode,
            confirm_ticket: confirmTicket,
        })
    }

    getForgotPasswordVerifyConfirmation(username) {
        return axios.get(`/auth/get-forgot-password-verify-confirmation/${username}`)
    }

    resetPassword({username, new_password, confirm_ticket, confirm_code}) {
        return axios.post('/auth/reset-password', {
            username: username,
            new_password: new_password,
            confirm_code: confirm_code,
            confirm_ticket: confirm_ticket,
        })
    }


    getAdminOrganizationAccounts(id) {
        return axios.get(`/auth/admin/organization/accounts/get/${id}`)
    }

    getAdminAccountSessions(id) {
        return axios.get(`/auth/admin/session/${id}`)
    }

    removeAdminAccountSession({accountId, token}) {
        return axios.post('/auth/admin/session/remove', {
            account_id: accountId,
            token: token,
        })
    }

    getAdminClientCredentials() {
        return axios.get('/auth/admin/client-credentials/get?skip=0&limit=10')
    }

    registerAdminClientCredentials({organization_id, client_id, client_secret}) {
        return axios.post('/auth/admin/client-credentials/register', {
            organization_id: organization_id,
            client_id: client_id,
            client_secret: client_secret
        })
    }

    setOrganizationInn(data) {
        return axios.post(`/auth/set-organization-inn`, data)
    }

}

const authService = new AuthService();

export default authService;
