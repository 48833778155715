import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectTransports} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const SelectTransport = ({onChange, defaultTransportNumber, ...props}) => {

    const transports = useSelector(selectTransports)
    const lang = useSelector(selectLang);
    const t = Translate;

    const [value, setValue] = useState(null)
    useEffect(() => {
        if (transports.length === 1) {
            if (transports[0] !== value) {
                setValue(transports[0]);
                if (onChange) onChange(transports[0]);
            }
        } else {
            const defaultTransport = transports.find(i => i.registration_number === defaultTransportNumber?.registration_number || i.registration_number === defaultTransportNumber);
            if (defaultTransport && defaultTransport !== value) {
                setValue(defaultTransport);
            } else if (defaultTransport === undefined && value !== null) {
                setValue(null);
            }
        }
    }, [transports, defaultTransportNumber, value, onChange]);

    const handleChange = (option) => {
        onChange(option)
        setValue(option)
    }

    return (
        <ReactSelect
            value={value}
            onChange={handleChange}
            options={transports}
            classNamePrefix={'react-select'}
            placeholder={t(lang, 'roaming.common.select.placeholder')}
            getOptionLabel={(option) => `${option.model} - ${option.registration_number}`}
            getOptionValue={(option) => option.registration_number}
            hideSelectedOptions
            isClearable
            {...props}
        />
    );
};

export default SelectTransport;
