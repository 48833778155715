import RefundDatatable from "../../../../components/hippo/warehouse-operation/refund/datatable/RefundDatatable";


const Refunds = () => {




    return(
        <>
            <RefundDatatable/>
        </>
    )
}

export default Refunds;