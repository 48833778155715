import React from 'react';
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import SelectStatus from "../../../hippo/SelectStatus";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {FreeFormDocumentStatuses} from "../../../../enum/roaming/FreeFormDocumentStatus";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const FreeFormDocumentDataTableFilter = () => {
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const {register} = useFormContext();
    const t = Translate;

    return (
        <>
            <Row>
                <h5 className="mb-0 text-nowrap py-2 py-xl-0">{t(lang, 'roaming.free_form_document.datatable.header.filter.title')}</h5>
            </Row>

            <Row className="mt-3 mb-4 g-3">
                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, 'roaming.free_form_document.datatable.header.filter.document_number.label')}</Form.Label>
                    <Form.Control
                        {...register('document_number')}
                        placeholder={t(lang, 'roaming.free_form_document.datatable.header.filter.document_number.placeholder')}
                    />
                </Form.Group>
                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, 'roaming.free_form_document.datatable.header.filter.created_on_start.label')}</Form.Label>
                    <Controller
                        name="created_on_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                selected={field.value}
                                dateFormat={dateFormat}
                                className='form-control'
                                onChange={field.onChange}
                                placeholderText={t(lang, 'roaming.free_form_document.datatable.header.filter.created_on_start.placeholder')}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, 'roaming.free_form_document.datatable.header.filter.created_on_end.label')}</Form.Label>
                    <Controller
                        name="created_on_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                selected={field.value}
                                dateFormat={dateFormat}
                                className='form-control'
                                onChange={field.onChange}
                                placeholderText={t(lang, 'roaming.free_form_document.datatable.header.filter.created_on_end.placeholder')}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, 'roaming.free_form_document.datatable.header.filter.status.label')}</Form.Label>
                    <Controller
                        name="status"
                        render={({field}) => (
                            <SelectStatus
                                isClearable
                                defaultStatus={field.value}
                                statuses={FreeFormDocumentStatuses}
                                onChange={statusId => field.onChange(typeof (statusId) !== 'undefined' ? statusId : null)}
                                placeholder={t(lang, 'roaming.free_form_document.datatable.header.filter.status.placeholder')}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, 'roaming.free_form_document.datatable.header.filter.updated_on_start.label')}</Form.Label>
                    <Controller
                        name="updated_on_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                selected={field.value}
                                dateFormat={dateFormat}
                                className='form-control'
                                onChange={field.onChange}
                                placeholderText={t(lang, 'roaming.free_form_document.datatable.header.filter.updated_on_start.placeholder')}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, 'roaming.free_form_document.datatable.header.filter.updated_on_end.label')}</Form.Label>
                    <Controller
                        name="updated_on_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                selected={field.value}
                                dateFormat={dateFormat}
                                className='form-control'
                                onChange={field.onChange}
                                placeholderText={t(lang, 'roaming.free_form_document.datatable.header.filter.updated_on_end.placeholder')}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>{t(lang, 'roaming.free_form_document.datatable.header.filter.contractor_inn.label')}</Form.Label>
                    <Form.Control
                        type="search"
                        className="search-input"
                        {...register("contractor_inn")}
                        placeholder={t(lang, 'roaming.free_form_document.datatable.header.filter.contractor_inn.placeholder')}
                    />
                </Form.Group>
            </Row>

            <Row className="mb-3 g-3 justify-content-end">
                <Form.Group as={Col} sm="auto" controlId="showChange">
                    <Button variant="primary" type='submit'>{t(lang, "roaming.free_form_document.datatable.header.filter.submit.label")}</Button>
                </Form.Group>
            </Row>
        </>
    );
};

export default FreeFormDocumentDataTableFilter;