import React from 'react';
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import {useForm, FormProvider} from "react-hook-form";
import ReturnShipmentForm from "./ReturnShipmentForm";
import ConvertQuantityToWord from "../../../../app/filter/ConvertQuantityToWord";
import {registerReturnShipmentAsync} from "../../../../app/store/reducers/return-shipment/returnShipmentReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectEdiSettings, updateEdiSettings} from "../../../../app/store/reducers/settings/ediSettingsReducer";

const RegisterReturnShipmentForm = ({order}) => {
    const settings = useSelector(selectEdiSettings)
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            order_info: {
                number: order?.info?.number,
                date: order?.info?.date,
                expires_on: order?.expires_on,
            },
            return_shipment_info: {
                number: '',
                date: new Date(),
            },
            contract_info: {
                number: null,
                date: null,
            },
            car_info: {
                drivers: settings?.return_shipment?.vehicles?.drivers,
                numbers: settings?.return_shipment?.vehicles?.numbers,
                models: settings?.return_shipment?.vehicles?.models,
                name: settings?.return_shipment?.vehicles?.drivers?.find(d => d?.is_active)?.label || "",
                serial_number: settings?.return_shipment?.vehicles?.numbers?.find(d => d?.is_active)?.label || "",
                mark: settings?.return_shipment?.vehicles?.models?.find(d => d?.is_active)?.label || "",
            },
            items: order.items.map(item => {
                return {
                    id: item.id,
                    product: item.product,
                    customer_shipment_id: item.customer_shipment_id,
                    barcode: item.barcode,
                    catalog_class_code: item.catalog_class_code,
                    catalog_class_name: item.catalog_class_name,
                    measurement: item.measurement,
                    quantity: item.quantity,
                    price: item.price,
                    total: item.total,
                    nds_rate: item.nds_rate,
                    nds_value: item.nds_value,
                    quantity_left: item.quantity_left,
                    total_with_nds: item.total_with_nds,
                }
            }),
            totalWithNds: null,
        }
    });

    const onSubmit = (form) => {
        const activeDriver = form?.car_info?.drivers?.find(d => d?.label === form?.car_info?.name)
        const activeNumber = form?.car_info?.numbers?.find(d => d?.label === form?.car_info?.serial_number)
        const activeModel = form?.car_info?.models?.find(d => d?.label === form?.car_info?.mark)


        const returnShipmentSettingsJson = {
            edi: {
                shipment: {
                    vehicles: settings?.shipment?.vehicles
                },
                return_shipment: {
                    vehicles: {
                        drivers: form?.car_info?.drivers.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeDriver?.label === driver?.label
                            }
                        }),
                        numbers: form?.car_info?.numbers.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeNumber?.label === driver?.label
                            }
                        }),
                        models: form?.car_info?.models.map((driver) => {
                            return{
                                label: driver.label,
                                is_active: activeModel?.label === driver?.label
                            }
                        })
                    }
                },
                should_validate_invoice_number: settings?.should_validate_invoice_number
            },
        }



        registerReturnShipmentAsync({
            return_order_id: order.id,
            return_shipment_info: {
                number: form.return_shipment_info.number,
                date: dayjs(form.return_shipment_info.date).format('YYYY-MM-DD'),
            },
            contract_info: {
                number: form.contract_info.number,
                date: dayjs(form.contract_info.date).format('YYYY-MM-DD'),
            },
            customer_info: {
                inn: order.customer.inn,
                name: order.customer.name
            },
            executor_info: {
                inn: order.executor.inn,
                name: order.executor.name,
            },
            car_info: {
                name: form.car_info.name,
                serial_number: form.car_info.serial_number,
                mark: form.car_info.mark,
            },
            loading_address: order?.branch?.name,
            loading_date_time: dayjs(form.return_shipment_info.date).format('YYYY-MM-DD HH:mm:ss'),
            total_in_words: ConvertQuantityToWord.getText(parseInt(form.totalWithNds)),
            items: form.items
                .filter(item => item.quantity > 0)
                .map(item => {
                    return {
                        return_order_item_id: item.id,
                        product: item.product,
                        barcode: item.barcode || null,
                        measurement: item.measurement,
                        quantity: +item.quantity,
                        price: item.price,
                        nds_rate: item.nds_rate,
                        catalog_name: item.catalog_class_name || null,
                        catalog_code: item.catalog_class_code || null
                    }
                })
        })
            .then( async () => {
                toast.success(t(lang, 'edi.toast.register_return_shipment_succeed'))
                await updateEdiSettings({payload: returnShipmentSettingsJson})
                    .then(() => {})
                    .catch(() => {})
            })
            .catch(() => toast.error(t(lang, 'edi.toast.register_return_shipment_failed')))
    }

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <ReturnShipmentForm order={order} />
                </Form>
            </FormProvider>
        </>
    );
};

export default RegisterReturnShipmentForm;