import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_MASS_ROAMING_INVOICE_SUCCEED} from "../../../../app/eventbus/excelRegistryEvents";
import MassAddRoamingInvoiceForm from "../../../../components/hippo/roaming/invoice/excel/mass-add-invoice/MassAddRoamingInvoiceForm";
import {UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED} from "../../../../app/eventbus/settingsEvents";
import {useDispatch} from "react-redux";
import {loadSettings} from "../../../../app/store/reducers/settings/settingsReducer";

const MassAddRoamingInvoice = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const addMassRoamingInvoiceSucceed = EventBus.on(ADD_MASS_ROAMING_INVOICE_SUCCEED, registry => {
            history.push(`/roaming/excel-registry/${registry.id}`);
        })

        const updateInvoiceRegistrySettingsSucceed = EventBus.on(UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED, () => {
            dispatch(loadSettings());
        })

        return () => {
            EventBus.remove(ADD_MASS_ROAMING_INVOICE_SUCCEED, addMassRoamingInvoiceSucceed);
            EventBus.remove(UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED, updateInvoiceRegistrySettingsSucceed);
        }
    }, [])

    useEffect(() => {
        dispatch(loadSettings());
    }, [])

    return (
        <>
            <MassAddRoamingInvoiceForm/>
        </>
    );
};

export default MassAddRoamingInvoice;