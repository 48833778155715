import {useDispatch, useSelector} from "react-redux";
import {
    loadContractorNotesAsync,
    selectContractorNoteFilterOptions,
    selectContractorNotes,
    changeFilterContractorNotesAsync
} from "../../../../../../app/store/reducers/contractor/contractorNoteReducer";
import React, {useEffect, useState} from "react";
import Flex from "../../../../../common/Flex";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import EventBus from "../../../../../../app/eventbus/EventBus";
import {
    EDIT_CONTRACTOR_NOTE_SUCCESS, ADD_CONTRACTOR_NOTE_SUCCESS, DELETE_CONTRACTOR_NOTE_SUCCESS
} from "../../../../../../app/eventbus/contractor/contractorEvents";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import DeleteContractorNoteModal from "./DeleteContractorNoteModal";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";

const ContractorNoteList = ({onEdit}) => {
    const contractor = useSelector(selectCardContractor)

    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const [deletingNoteId, setDeletingNoteId] = useState(null)
    const contractorNotes = useSelector(selectContractorNotes)
    const noteFilter = useSelector(selectContractorNoteFilterOptions)
    const onLimitChange = () => dispatch(changeFilterContractorNotesAsync({
        ...noteFilter,
        limit: noteFilter.limit + 10,
        page: 1
    }))

    const loadContractorNotes = () => {
        dispatch(loadContractorNotesAsync({...noteFilter, id: contractor?.id}));
    }

    useEffect(() => {
        if (contractor)
            loadContractorNotes()
    }, [contractor, noteFilter])

    useEffect(() => {
        const onNoteAddedHandler = EventBus.on(ADD_CONTRACTOR_NOTE_SUCCESS, () => {
            loadContractorNotes();
        });
        const onNoteEditedHandler = EventBus.on(EDIT_CONTRACTOR_NOTE_SUCCESS, () => {
            toast.success(t(lang, 'edi.common.toast.success'));
            loadContractorNotes();
        });
        const onNoteDeletedHandler = EventBus.on(DELETE_CONTRACTOR_NOTE_SUCCESS, () => {
            toast.success(t(lang, 'edi.common.toast.success'));
            loadContractorNotes();
        });
        return () => {
            EventBus.remove(ADD_CONTRACTOR_NOTE_SUCCESS, onNoteAddedHandler)
            EventBus.remove(EDIT_CONTRACTOR_NOTE_SUCCESS, onNoteEditedHandler);
            EventBus.remove(DELETE_CONTRACTOR_NOTE_SUCCESS, onNoteDeletedHandler);
        }
    }, [contractor])

    return (<>
        <div className={"mb-3"}>{contractorNotes.map((note, index) => {
            return (<Flex key={index} className={"d-block p-3 border-bottom"}>
                <FontAwesomeIcon icon={faCircleUser} className="fs-4 text-facebook mb-1 me-3"/>
                <div className={"flex-1"}>
                    <div className={"w-xxl-75"}>
                        <div className={"h6 text-secondary mb-0"}>{note.account.name}</div>
                        <div className={"text-400 fs--2 mb-1"}>{note.created_at}</div>
                        <Flex
                            alignItems="center"
                            className={"hover-actions-trigger align-items-center"}
                        >
                            <div className={"p-2 rounded-2 chat-message bg-200"}>{note.comment}</div>
                            <ul className="hover-actions position-relative list-inline mb-0 text-400 mx-2">
                                <li className="list-inline-item cursor-pointer chat-option-hover" onClick={() => {
                                    onEdit(note)
                                }}>
                                    <OverlayTrigger overlay={<Tooltip>{t(lang, "roaming.common.edit")}</Tooltip>}>
                                        <div>
                                            <FontAwesomeIcon icon={'edit'} className="d-inline-block"/>
                                        </div>
                                    </OverlayTrigger>
                                </li>
                                <li className="list-inline-item cursor-pointer chat-option-hover" onClick={() => {
                                    setDeletingNoteId(note?.id)
                                }}>
                                    <OverlayTrigger overlay={<Tooltip>{t(lang, "edi.common.delete_button")}</Tooltip>}>
                                        <div>
                                            <FontAwesomeIcon icon={'trash-alt'} className="d-inline-block"/>
                                        </div>
                                    </OverlayTrigger>
                                </li>
                            </ul>
                        </Flex>
                    </div>
                </div>
            </Flex>)
        })}</div>
        {!(contractorNotes.length < 10) && <div className={"text-center"}>
            <a className="fs--1 text-700 d-inline-block mt-2 cursor-pointer" onClick={onLimitChange}>
                {t(lang, "crm.contractor_card.general.note_list.loader.text")}
            </a>
        </div>}
        {deletingNoteId && <DeleteContractorNoteModal id={deletingNoteId} show={!!deletingNoteId} onClose={() => {
            setDeletingNoteId(null)
        }}/>}
    </>)
}
export default ContractorNoteList
