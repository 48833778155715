import React, {useEffect, useState} from "react";
import AttorneyForm from "./AttorneyForm";
import {Form} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {addAttorneyAsync} from "../../../../app/store/reducers/attorney/attorneyReducer";
import {getBankNameAsync, getOrganizationInfoAsync} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang} from "../../../../app/store/reducers/main/mainReducer";

const AddAttorneyForm = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);

    const methods = useForm({
        defaultValues: {
            items: [
                {
                    product: '',
                    measurement: '',
                    quantity: '',
                    quantityInWords: ''
                }
            ],
            ownerInfo: {
                inn: '',
                name: '',
                oked: '',
                address: '',
                bankInfo: {
                    account: '',
                    mfo: '',
                    name: '',
                }
            },
            attorneyInfo: {
                number: '',
                start: null,
                end: null,
            },
            personInfo: {
                position: '',
                fullName: '',
                inn: '',
                passportInfo: {
                    number: '',
                    serial: '',
                    issuer: '',
                    issuedOn: null
                },
            },
            contractInfo: {
                number: '',
                date: null
            },
            contractorInfo: {
                inn: '',
                name: '',
                oked: '',
                address: '',
                bankInfo: {
                    account: '',
                    mfo: '',
                    name: '',
                }
            },
            accountant: '',
            director: '',
        },
    });

    useEffect(() => {
        (async () => {
            const ownerInfo = await getOrganizationInfoAsync(activeOrganization.inn)
            const {name: ownerBankName} = await getBankNameAsync({mfo: ownerInfo.mfo, lang})
            methods.setValue('ownerInfo', {
                inn: ownerInfo.inn,
                name: ownerInfo.name,
                oked: ownerInfo.oked,
                address: ownerInfo.address,
                bankInfo: {
                    account: ownerInfo.account,
                    mfo: ownerInfo.mfo,
                    name: ownerBankName,
                }
            })
        })()
    }, [activeOrganization])

    const [loading, setLoading] = useState(false);
    const onSubmit = formData => {
        setLoading(true);
        addAttorneyAsync({
            items: formData.items.map(item => {
                return {
                    product: item.product,
                    measurement: item.measurement,
                    quantity: +item.quantity,
                    quantity_in_words: item.quantityInWords
                }
            }),
            owner_info: {
                inn: formData.ownerInfo.inn,
                name: formData.ownerInfo.name,
                oked: formData.ownerInfo.oked,
                address: formData.ownerInfo.address,
            },
            owner_bank_info: {
                account: formData.ownerInfo.bankInfo.account,
                mfo: formData.ownerInfo.bankInfo.mfo,
                name: formData.ownerInfo.bankInfo.name,
            },
            attorney_info: {
                number: formData.attorneyInfo.number,
                start: dayjs(formData.attorneyInfo.start).format("YYYY-MM-DD"),
                end: dayjs(formData.attorneyInfo.end).format("YYYY-MM-DD")
            },
            person_info: {
                position: formData.personInfo.position,
                full_name: formData.personInfo.fullName,
                person_inn: formData.personInfo.inn,
            },
            passport_info: {
                number: formData.personInfo.passportInfo.number,
                serial: formData.personInfo.passportInfo.serial,
                issuer: formData.personInfo.passportInfo.issuer,
                issued_on: dayjs(formData.personInfo.passportInfo.issuedOn).format("YYYY-MM-DD")
            },
            contract_info: {
                number: formData.contractInfo.number,
                date: dayjs(formData.contractInfo.date).format("YYYY-MM-DD")
            },
            contractor_info: {
                inn: formData.contractorInfo.inn,
                name: formData.contractorInfo.name,
                oked: formData.contractorInfo.oked,
                address: formData.contractorInfo.address,
            },
            contractor_bank_info: {
                account: formData.contractorInfo.bankInfo.account,
                mfo: formData.contractorInfo.bankInfo.mfo,
                name: formData.contractorInfo.bankInfo.name,
            },
            accountant: formData.accountant,
            director: formData.director
        })
            .then((response) => {
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <AttorneyForm loading={loading}/>
                </Form>
            </FormProvider>
        </>
    )
};

export default AddAttorneyForm;