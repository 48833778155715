import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectCountries} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const RoamingInvoiceFormCountrySelector = ({defaultCountryId, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const countries = useSelector(selectCountries);

    return (
        <ReactSelect options={countries}
                     placeholder={t(lang, 'roaming.common.select.placeholder')}
                     getOptionLabel={option => option.name}
                     getOptionValue={option => option.countryId}
                     isClearable
                     onChange={option => onChange(option ? option.countryId : null)}
                     value={countries.find(c => c.countryId === defaultCountryId) || null}
        />
    );
};

export default RoamingInvoiceFormCountrySelector;