import React from 'react';
import ItemRow from "./ItemRow";
import {useSelector} from "react-redux";
import {useFieldArray} from "react-hook-form";
import IconButton from "../../../../../common/IconButton";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {faBookmark, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import SelectCertificateWrapperComponent from "../../../components/SelectCertificateWrapperComponent";

const itemsFieldName = 'products';

const WaybillItemsForm = ({isSaving, isSending}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {fields, append, remove} = useFieldArray({name: itemsFieldName});
    const allowDeleteRow = fields.length > 1;

    const addNewItem = () => {
        append({
            product: '',
            catalog_code: '',
            catalog_name: '',
            price: '',
            measureId: '',
            packageCode: null,
            packageName: null,
            packages: null,
            quantity: '',
            total: '',
            road_haulage_cost: '',
            follow_documents: '',
            weight_define_method: '',
            load_class: '',
            weight_brutto: '',
            weight_netto: '',
        });
    }

    return (
        <Card className="mt-3">
            <Card.Header className="bg-light">
                <Row className="align-items-end g-2">
                    <Col>
                        <h5 className="mb-0">{t(lang, 'roaming.waybill.send.shipment_items_form.title')}</h5>
                    </Col>
                    <Col xs="auto">
                        <IconButton
                            variant="falcon-default" size="sm" icon="plus" transform="shrink-3" className="me-2" onClick={addNewItem}>
                            <span className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.common.add")}</span>
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Table className="fs--1" responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.product')}<span className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.catalog_code')}</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.price')}<span className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.measurement')}<span className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.quantity')}<span className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.total')}</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.road_haulage_cost')}<span className="text-danger">*</span></th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.follow_documents')}</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_define_method')}</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.load_class')}</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_brutto')}</th>
                        <th scope="col">{t(lang, 'roaming.waybill.send.shipment_items_form.table.weight_netto')}</th>
                        <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <ItemRow
                            key={item.id}
                            index={index}
                            remove={remove}
                            allowDelete={allowDeleteRow}
                            arrayFieldName={itemsFieldName}
                        />
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
            <Card.Footer className="text-end">
                <IconButton
                    name="save"
                    className="mx-2"
                    type="submit" icon={faBookmark}
                    variant="warning" transform="shrink-3"
                >
                    {isSaving && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                    {t(lang, 'roaming.common.save')}
                </IconButton>

                <SelectCertificateWrapperComponent
                    submitButton={<IconButton icon={faPaperPlane} className="float-end" variant="primary" id="submitButton" name="send" type="submit">
                        {isSending && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                        {t(lang, 'roaming.common.send')}
                    </IconButton>}
                    openDialogButtonText={t(lang, 'roaming.common.send')}
                />
            </Card.Footer>
        </Card>
    );
};

export default WaybillItemsForm;