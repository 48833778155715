import {useSelector} from "react-redux";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../app/store/reducers/currency/currencyReducer";
import {
    selectWarehouseInitializationState,
    selectWarehouses, WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../app/store/reducers/item/itemReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, WAREHOUSE_OPERATION_PURCHASE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_NOTE_SUCCESS, WAREHOUSE_OPERATION_PURCHASE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS, WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/purchaseEvents";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import ItemNo from "../../../../components/hippo/warehouse-operation/components/ItemNo";
import {getPurchaseItemAsync} from "../../../../app/store/reducers/warehouse-operation/purchaseReducer";
import EditPurchaseForm from "../../../../components/hippo/warehouse-operation/purchase/forms/EditPurchaseForm";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";


const EditPurchase = () => {
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [purchase, setPurchase] = useState(null)
    const history = useHistory()
    const items = useSelector(selectItems)
    const {id} = useParams()



    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS, (id) => {
            loadPurchase()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS, (id) => {
            loadPurchase()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS, (id) => {
            loadPurchase()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, (id) => {
            loadPurchase()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, (id) => {
            history.push(`../view/${id}`)
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS,  (id) => {
            history.push(`../view/${id}`)
        })

        const onDeletePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_SUCCESS, () => {
            history.push(`../view/${id}`)
        })

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_NOTE_SUCCESS, (id) => {
            loadPurchase()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DATE_SUCCESS, (id) => {
            loadPurchase()
        })
        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_SUCCESS, (response) => {
            setPurchase(response.data, response.data.items.reverse())
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_NUMBER_SUCCESS, (id) => {
            loadPurchase()
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS, onUpdatePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_SUCCESS, onDeletePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        loadPurchase()
    },[activeOrganization, id])



    const loadPurchase = async () => {
        await getPurchaseItemAsync({purchaseId: id})
            .then((response) => {
                setPurchase(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setPurchase(null)
            })
    }



    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    if(!purchase)
        return (
            <SpinnerComponent />
        )


    return(
        <>
            <EditPurchaseForm purchase={purchase}/>
        </>
    )
}

export default EditPurchase;
