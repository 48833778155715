import React, {Fragment, useState} from 'react';
import IconButton from "../../../common/IconButton";
import {faClipboard} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import ContractorPriceForm from "./ContractorPriceForm";

const ContractorPriceModal = ({items}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <Fragment>

            <IconButton icon={faClipboard}
                        onClick={handleShow}
                        variant="falcon-info"
                        size="sm"
                        className="m-1"
            >
                {t(lang, 'items.edit.item.price.contractors')}
            </IconButton>


            <Modal show={show} size="xl">
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.edit.item.price.title')}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <ContractorPriceForm items={items} />
                </Modal.Body>
            </Modal>

        </Fragment>
    );
};

export default ContractorPriceModal;
