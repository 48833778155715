import {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {Form} from "react-bootstrap";
import {
    revisionAddItemAsync, revisionChangeEmployeeAsync,
    revisionUpdateNoteAsync, revisionUpdateNumberAsync, selectRevisionDefaultsSettings
} from "../../../../../app/store/reducers/warehouse-operation/revisionReducer";
import RevisionForm from "./RevisionForm";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";


const EditRevisionForm = ({revision}) => {
    const revisionDefaultsSettings = useSelector(selectRevisionDefaultsSettings)
    const [loading, setLoading] = useState(false)
    const warehouses = useSelector(selectWarehouses)
    const lang = useSelector(selectLang)
    const t = Translate
    const settings = useSelector(selectDefaultSetting)


    const form = useForm({
        defaultValues: {
            employee_id: revision?.employee?.id,
            date: dayjs(revision?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: revision?.note,
            number: revision?.number,
            item_id: null,
            warehouse_id:settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
        }
    })


    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            marks: [],
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
        })
    }




    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: +formData?.quantity,
        }


        if (buttonType === "addOrEditRevision" || event || "addOrEditRevision") {
            setLoading(true)
            await revisionAddItemAsync({
                revisionId: revision?.id,
                operation: item
            })
                .then(({data}) => {
                    updateNote(formData)
                    // updateNumber(formData)
                    resetForm(formData)
                    updateEmployee(formData)
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch((error) => {
                    if (error?.response?.data?.includes("warehouse")) {
                        toast.error(t(lang, "Склад не выбран"))
                    } else {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || revision?.employee) {
            if (formData?.employee_id !== revision?.employee?.id) {
                revisionChangeEmployeeAsync({revisionId: revision?.id, employeeId: formData?.employee_id})
                    .then(() => { })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== revision?.note) {
            revisionUpdateNoteAsync({revisionId: revision?.id, note: formData?.note})
                .then(({data}) => {})
                .catch(() => {})
        }
    }

    // const updateNumber = (formData) => {
    //     if (formData?.number?.trim() && formData?.number !== revision?.number) {
    //         revisionUpdateNumberAsync({revisionId: revision?.id, number: formData?.number})
    //             .then(({data}) => {})
    //             .catch(() => {})
    //     }
    // }


    return(
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <RevisionForm revision={revision} loading={loading} addItem={form.handleSubmit(onSubmit)}/>
                </Form>
            </FormProvider>
        </>
    )
}

export default EditRevisionForm;
