export const ADD_CRM_ORDER_SUCCESS = 'ADD_CRM_ORDER_SUCCESS';
export const ADD_CRM_ORDER_FAILED = 'ADD_CRM_ORDER_FAILED';

export const DELETE_CRM_ORDER_SUCCESS = 'DELETE_CRM_ORDER_SUCCESS'
export const DELETE_CRM_ORDER_FAILED = 'DELETE_CRM_ORDER_FAILED'

export const ITEM_UPDATE_REGISTER_CRM_ORDER_REQUEST = 'ITEM_UPDATE_REGISTER_CRM_ORDER_START'
export const ITEM_UPDATE_REGISTER_CRM_ORDER_SUCCESS = 'ITEM_UPDATE_REGISTER_CRM_ORDER_SUCCESS'
export const ITEM_UPDATE_REGISTER_CRM_ORDER_FAILED = 'ITEM_UPDATE_REGISTER_CRM_ORDER_FAILED'

export const CRM_ORDER_IDENTIFY_ITEMS_REQUEST = 'CRM_ORDER_IDENTIFY_ITEMS_REQUEST'
export const CRM_ORDER_IDENTIFY_ITEMS_SUCCESS = 'CRM_ORDER_IDENTIFY_ITEMS_SUCCESS'
export const CRM_ORDER_IDENTIFY_ITEMS_FAILED = 'CRM_ORDER_IDENTIFY_ITEMS_FAILED'

export const EDIT_CRM_EXECUTOR_ORDER_REQUESTED = 'EDIT_CRM_EXECUTOR_ORDER_REQUESTED'
export const EDIT_CRM_EXECUTOR_ORDER_REQUESTED_SUCCESS = 'EDIT_CRM_EXECUTOR_ORDER_REQUESTED_SUCCESS'
export const EDIT_CRM_EXECUTOR_ORDER_REQUESTED_FAILED = 'EDIT_CRM_EXECUTOR_ORDER_REQUESTED_FAILED'

export const EDIT_CRM_CUSTOMER_ORDER_REQUESTED = 'EDIT_CRM_CUSTOMER_ORDER_REQUESTED'
export const EDIT_CRM_CUSTOMER_ORDER_REQUESTED_SUCCESS = 'EDIT_CRM_CUSTOMER_ORDER_REQUESTED_SUCCESS'
export const EDIT_CRM_CUSTOMER_ORDER_REQUESTED_FAILED = 'EDIT_CRM_CUSTOMER_ORDER_REQUESTED_FAILED'
