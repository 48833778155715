export const CASHBOX_REGISTER_SUCCESS = "CASHBOX_REGISTER_SUCCESS"
export const CASHBOX_UPDATE_SUCCESS = "CASHBOX_UPDATE_SUCCESS"
export const CASHBOX_DELETE_SUCCESS = "CASHBOX_DELETE_SUCCESS"
export const CASHBOX_BINDING_SUCCESS = "CASHBOX_BINDING_SUCCESS"

export const CASHBOX_GET_EXPENSE_SUCCESS = "CASHBOX_GET_EXPENSE_SUCCESS"
export const CASHBOX_EXPENSE_REGISTER_SUCCESS = "CASHBOX_EXPENSE_REGISTER_SUCCESS"
export const CASHBOX_EXPENSE_UPDATE_SUCCESS = "CASHBOX_EXPENSE_UPDATE_SUCCESS"
export const CASHBOX_EXPENSE_DELETE_SUCCESS = "CASHBOX_EXPENSE_DELETE_SUCCESS"

export const CASHBOX_GET_CASH_IN_SUCCESS = "CASHBOX_GET_CASH_IN_SUCCESS"
export const CASHBOX_CASH_IN_REGISTER_SUCCESS = "CASHBOX_CASH_IN_REGISTER_SUCCESS"
export const CASHBOX_CASH_IN_UPDATE_SUCCESS = "CASHBOX_CASH_IN_UPDATE_SUCCESS"
export const CASHBOX_CASH_IN_DELETE_SUCCESS = "CASHBOX_CASH_IN_DELETE_SUCCESS"

export const CASHBOX_GET_CASH_OUT_SUCCESS = "CASHBOX_GET_CASH_OUT_SUCCESS"
export const CASHBOX_CASH_OUT_REGISTER_SUCCESS = "CASHBOX_CASH_OUT_REGISTER_SUCCESS"
export const CASHBOX_CASH_OUT_UPDATE_SUCCESS = "CASHBOX_CASH_OUT_UPDATE_SUCCESS"
export const CASHBOX_CASH_OUT_DELETE_SUCCESS = "CASHBOX_CASH_OUT_DELETE_SUCCESS"


export const CASHBOX_GET_TRANSFER_SUCCESS = "CASHBOX_GET_TRANSFER_SUCCESS"
export const CASHBOX_TRANSFER_REGISTER_SUCCESS = "CASHBOX_TRANSFER_REGISTER_SUCCESS"
export const CASHBOX_TRANSFER_UPDATE_SUCCESS = "CASHBOX_TRANSFER_UPDATE_SUCCESS"
export const CASHBOX_TRANSFER_DELETE_SUCCESS = "CASHBOX_TRANSFER_DELETE_SUCCESS"
// PUSH UPDATE
export const CASH_BOX_REGISTERED = "CASH_BOX_REGISTERED"
export const CASH_BOX_UPDATED = "CASH_BOX_UPDATED"
export const CASH_BOX_DELETED = "CASH_BOX_DELETED"
export const CASH_BOX_AMOUNTS_UPDATED = "CASH_BOX_AMOUNTS_UPDATED"
export const ACCOUNT_CASH_BOX_BINDING_ENABLED = "ACCOUNT_CASH_BOX_BINDING_ENABLED"
export const ACCOUNT_CASH_BOX_BINDING_DISABLED = "ACCOUNT_CASH_BOX_BINDING_DISABLED"
export const ACCOUNT_CASH_BOX_BINDING_REGISTERED = "ACCOUNT_CASH_BOX_BINDING_REGISTERED"
export const ACCOUNT_CASH_BOX_BINDING_UNREGISTERED = "ACCOUNT_CASH_BOX_BINDING_UNREGISTERED"
