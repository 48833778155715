import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useFormContext, useWatch} from "react-hook-form";
import {Form} from "react-bootstrap";

const RoamingInvoiceFormHideReportCommittentSwitcher = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const hasCommittent = useWatch({name: `productList.hasCommittent`, exact: true});

    if (!hasCommittent) return null;

    return (
        <Form.Switch label={t(lang, 'roaming.invoice.hideReportCommittent')}
                     {...form.register(`productList.hideReportCommittent`)}
        />
    );
};

export default RoamingInvoiceFormHideReportCommittentSwitcher;