import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    dragAndDropBoardColumn, dragAndDropTaskInColumn,
    selectModalContent, selectWithTasks, seletcFilterWithTasks, setDragAndDropColumn, updateFilterWithTasks
} from "../../../app/store/reducers/kanban/kanbanReducer";
import KanbanColumn from "./KanbanColumn";
import KanbanModal from "../modal/kanban/KanbanModal";
import is from 'is_js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {useParams} from "react-router";
import useQuery from "../../hippo/roaming/invoice/form/useQuery";
import {Card, Col, Form, Row} from "react-bootstrap";
import SelectContractor from "../../hippo/crm/contractor/SelectContractor";
import {selectDateFormat, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import EmployeeSelect from "../../hippo/common/EmployeeSelect";

const KanbanContainer = () => {
    const {id} = useParams();
    const {contractor_id} = useQuery();
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const t = Translate;
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [columnId, setColumnId] = useState(null);
    const containerRef = useRef(null);
    const kanbanItems = useSelector(selectWithTasks);
    const filterWithTasks = useSelector(seletcFilterWithTasks);
    const modalContent = useSelector(selectModalContent);

    useEffect(() => {
        if (contractor_id && kanbanItems?.columns) {
            kanbanItems.columns.forEach(column => {
                if (column.type === 1) {
                    setColumnId(column.id);
                }
            });
            setShowModal(true);
        }
    }, [contractor_id, kanbanItems]);

    useEffect(() => {
        if (is.ipad()) {
            containerRef.current.classList.add('ipad');
        }

        if (is.mobile()) {
            containerRef.current.classList.add('mobile');
            if (is.safari()) {
                containerRef.current.classList.add('safari');
            }
            if (is.chrome()) {
                containerRef.current.classList.add('chrome');
            }
        }
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);

        return clearTimeout(timeout);
    }, [showForm]);

    const onDragEndColumn = (result) => {

        const { destination, source, draggableId } = result;

        if (destination.index === source.index) return;

        const newColumns = JSON.parse(JSON.stringify(kanbanItems?.columns));

        const [removed] = newColumns.splice(source?.index, 1);

        newColumns.splice(destination?.index, 0, removed)
        const newPosition = kanbanItems?.columns[destination?.index]?.position

        dispatch(setDragAndDropColumn(newColumns));
        dragAndDropBoardColumn(id, {board_column_id: draggableId, position: newPosition})
    }

    const onDragEndTask = (result) => {
        const { destination, source } = result;
        // If the task returns to its original position (within the same column, at the same index), do nothing.
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;

        const sourceColumn = kanbanItems.columns.find(col => col.id === source.droppableId);
        const destinationColumn = kanbanItems.columns.find(col => col.id === destination.droppableId);

        const sourceTasks = Array.from(sourceColumn.tasks);
        const [removedTask] = sourceTasks.splice(source.index, 1);

        if (source.droppableId === destination.droppableId) {
            // When a task changes position within the same column.
            sourceTasks.splice(destination.index, 0, removedTask);

            const updatedTasks = sourceTasks.map((task, index) => ({
                ...task,
                position: index + 1
            }));

            const newColumns = kanbanItems.columns.map(col => {
                if (col.id === source.droppableId) {
                    return { ...col, tasks: updatedTasks };
                }
                return col;
            });

            dispatch(setDragAndDropColumn(newColumns));
            dragAndDropTaskInColumn({
                board_id: id,
                board_column_id: source.droppableId,
                board_column_task_id: removedTask?.id,
                position: updatedTasks.find(i => i.id === removedTask.id)?.position
            });

        } else {

            // When a task is moved to another column.
            const destinationTasks = Array.from(destinationColumn.tasks);
            destinationTasks.splice(destination.index, 0, removedTask);

            const updatedSourceTasks = sourceTasks.map((task, index) => ({
                ...task,
                position: index + 1
            }));

            const updatedDestinationTasks = destinationTasks.map((task, index) => ({
                ...task,
                position: index + 1
            }));

            const newColumns = kanbanItems.columns.map(col => {
                if (col.id === source.droppableId) {
                    return { ...col, tasks: updatedSourceTasks };
                }
                if (col.id === destination.droppableId) {
                    return { ...col, tasks: updatedDestinationTasks };
                }
                return col;
            });

            dispatch(setDragAndDropColumn(newColumns));
            dragAndDropTaskInColumn({
                board_id: id,
                board_column_id: destination.droppableId,
                board_column_task_id: removedTask?.id,
                position: updatedDestinationTasks.find(i => i.id === removedTask.id)?.position
            });
        }
    }

    const onDragEnd = (result) => {
        const { destination, type} = result;

        if (!destination) return;

        if (type === 'COLUMN') onDragEndColumn(result);
        else if (type === 'TASK') onDragEndTask(result);
    };

    const data = useMemo(() => {
        return {
            ...kanbanItems,
            columns: kanbanItems?.columns?.map((kanbanColumnItem) => {
                const filteredTasks = kanbanColumnItem.tasks.filter(task => {
                    const taskDate = task.contractor?.debt_promise_date
                        ? dayjs(task.contractor.debt_promise_date).format('YYYY-MM-DD')
                        : null;

                    // contractor
                    if (filterWithTasks.contractor_id && filterWithTasks.contractor_id !== task.contractor?.id) {
                        return false;
                    }

                    // employee
                    if (filterWithTasks.employee_id && filterWithTasks.employee_id !== task.assignee_id) {
                        return false;
                    }

                    // date
                    if (filterWithTasks.debt_promise_date_start || filterWithTasks.debt_promise_date_end) {
                        const startDate = filterWithTasks.debt_promise_date_start
                            ? dayjs(filterWithTasks.debt_promise_date_start).format('YYYY-MM-DD')
                            : null;

                        const endDate = filterWithTasks.debt_promise_date_end
                            ? dayjs(filterWithTasks.debt_promise_date_end).format('YYYY-MM-DD')
                            : null;

                        if (taskDate) {

                            if (startDate && endDate) {
                                if (!(taskDate >= startDate && taskDate <= endDate)) return false;
                            }
                            // startDate
                            else if (startDate && !(taskDate >= startDate)) {
                                return false;
                            }
                            // endDate
                            else if (endDate && !(taskDate <= endDate)) {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }

                    return true;
                });

                return { ...kanbanColumnItem, tasks: filteredTasks };
            }) || []
        }
    }, [kanbanItems, filterWithTasks]);

    return (
        <Fragment>
            <Card className={'mb-2'}>
                <Card.Body>
                    <Form>
                        <Row>
                            <Form.Group as={Col} sm={12} md={3}>
                                <SelectContractor
                                    onChange={(contractor) => {
                                        if (contractor) dispatch(updateFilterWithTasks({contractor_id: contractor?.id}))
                                        else dispatch(updateFilterWithTasks({contractor_id: null}))
                                    }}
                                    defaultValue={filterWithTasks.contractor_id}
                                    placeholder={t(lang, 'task.board.contractor.filter')}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }) // Increase z-index of the menu
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} md={3}>
                                <EmployeeSelect
                                    onChange={(employee) => {
                                        if (employee) dispatch(updateFilterWithTasks({employee_id: employee?.id}))
                                        else dispatch(updateFilterWithTasks({employee_id: null}))
                                    }}
                                    isClearable
                                    classNamePrefix={'react-select'}
                                    defaultValue={filterWithTasks.employee_id}
                                    placeholder={t(lang, 'main.top.nav.profile_dropdown.dropdown_item.employees')}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }) // Increase z-index of the menu
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} md={3}>
                                <DatePicker
                                    isClearable
                                    className='form-control'
                                    placeholderText={t(lang, "crm.contractor.view.tab.act_report.filter.date_start.title")}
                                    dateFormat={dateFormat}
                                    selected={filterWithTasks.debt_promise_date_start ? new Date(filterWithTasks.debt_promise_date_start) : null}
                                    onChange={date => dispatch(updateFilterWithTasks({
                                        debt_promise_date_start: date ? dayjs(date).format('YYYY-MM-DD') : null
                                    }))}/>
                            </Form.Group>
                            <Form.Group as={Col} sm={12} md={3}>
                                <DatePicker
                                    isClearable
                                    className='form-control'
                                    placeholderText={t(lang, "crm.contractor.view.tab.act_report.filter.date_end.title")}
                                    dateFormat={dateFormat}
                                    selected={filterWithTasks.debt_promise_date_end ? new Date(filterWithTasks.debt_promise_date_end) : null}
                                    onChange={date => dispatch(updateFilterWithTasks({
                                        debt_promise_date_end: date ? dayjs(date).format('YYYY-MM-DD') : null
                                    }))}/>
                            </Form.Group>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
             {!!data.columns?.length > 0 && <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="kanban-columns" direction="horizontal" type="COLUMN">
                    {
                        provided => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="d-flex kanban-board kanban-container me-n3 scrollbar"
                                // style={{ height: '100vh', opacity: loading && 0.2}}
                            >
                                {data?.columns?.map((kanbanColumnItem, index) => (
                                    <Draggable key={kanbanColumnItem.id} draggableId={`${kanbanColumnItem.id}`}
                                               index={index}>
                                        {provided => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <KanbanColumn
                                                    key={kanbanColumnItem.id}
                                                    kanbanColumnItem={kanbanColumnItem}
                                                    showModal={showModal}
                                                    setShowModal={setShowModal}
                                                    setShowForm={setShowForm}
                                                    showForm={showForm}
                                                    setColumnId={setColumnId}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}


                                <KanbanModal show={showModal} setShow={setShowModal} columnId={columnId} boardType={kanbanItems.type}
                                             edit={!!Object.keys(modalContent).length}/>

                                {provided.placeholder}
                            </div>
                        )
                    }
                </Droppable>
            </DragDropContext>}
        </Fragment>
    );
};

export default KanbanContainer;
