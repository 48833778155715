import { createSlice } from "@reduxjs/toolkit";
import { VIEW_DRAFT, VIEW_INBOX, VIEW_OUTBOX } from "../../../../enum/TypeViewers";
import roamingVerificationActService from "../../../../services/roaming/roamingVerificationActService";
import EventBus from "../../../eventbus/EventBus";
import { DOCUMENT_CANCEL_FAILED, DOCUMENT_CANCEL_STARTED, DOCUMENT_CANCEL_SUCCESS, DOCUMENT_DECLINE_FAILED, DOCUMENT_DECLINE_STARTED, DOCUMENT_DECLINE_SUCCESS, DOCUMENT_SIGN_FAILED, DOCUMENT_SIGN_STARTED, DOCUMENT_SIGN_SUCCESS } from "../../../eventbus/signEvents";
import { signDocAndGetRoamingSignature } from "./roamingReducer";
import {
    ROAMING_VERIFICATION_ACT_SYNC_FAILED, ROAMING_VERIFICATION_ACT_SYNC_REQUESTED,
    ROAMING_VERIFICATION_ACT_SYNC_SUCCESS
} from "../../../eventbus/roaming/roamingVerificationActEvents";

export const roamingVerificationActSlice = createSlice({
  name: "roamingVerificationAct",
  initialState: {
    filterOptions: {page: 1, limit: 10},
    verificationActs: [],
    count: 0,
    viewPage: {
        id: null,
        draftId: null,
        showModal: false
    }
  },
  reducers: {
    setShowModal: (state, action) => {
        if (!action.payload) {
            state.viewPage = {id: null, draftId: null, showModal: false}
        }else {
            state.viewPage.showModal = action.payload
        }
    },
    setId: (state, {payload}) => {
        const {draftId, id} = payload
        if (draftId) {
            state.viewPage.draftId = draftId
        } else if (id) {
            state.viewPage.id = id
        }
    },
    updateFilterOptions: (state, action) => {
      state.filterOptions = {...state.filterOptions, ...action.payload}
    },
    updateRoamingVerificationActs: (state, action) => {
      state.verificationActs = action.payload
    },
    updateRoamingVerificationActsCount: (state, action) => {
      state.count = action.payload
    }
  }
})

export default roamingVerificationActSlice.reducer;
export const {setShowModal, setId} = roamingVerificationActSlice.actions

export const selectRoamingVerificationActs = state => state.roamingVerificationAct.verificationActs;
export const selectVerificationActFilterOptions = state => state.roamingVerificationAct.filterOptions;
export const selectCount = state => state.roamingVerificationAct.count;
export const viewPage = state => state.roamingVerificationAct.viewPage

export const loadVerificationAct = id => {
    return roamingVerificationActService.get(id)
}

export const syncVerificationActAsync = (id) => {
    EventBus.dispatch(ROAMING_VERIFICATION_ACT_SYNC_REQUESTED, id)
    return new Promise((resolve, reject) => {
        roamingVerificationActService.sync(id)
            .then((response) => {
                EventBus.dispatch(ROAMING_VERIFICATION_ACT_SYNC_SUCCESS, id);
                resolve(response.data);
            })
            .catch((error) => {
                let errorMessage = {message: error.response.data}
                EventBus.dispatch(ROAMING_VERIFICATION_ACT_SYNC_FAILED, errorMessage);
                reject(error);
            })
    })
}
export const loadDraftVerificationAct = id => {
    return roamingVerificationActService.getDraft(id)
}

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
  dispatch(roamingVerificationActSlice.actions.updateFilterOptions({...params}))
}

export const loadRoamingVerificationActsAsync = ({type_viewer, page, limit, ...filters}) => async (dispatch) => {
    try {
        const skip = (page - 1) * limit
        const params = {skip, limit, ...filters}
        let invoices = []
        if (type_viewer === VIEW_INBOX) {
            invoices = (await roamingVerificationActService.getCustomerVerificationActs(params)).data
        } else if (type_viewer === VIEW_OUTBOX) {
            invoices = (await roamingVerificationActService.getExecutorVerificationActs(params)).data
        } else if (type_viewer === VIEW_DRAFT) {
            invoices = (await roamingVerificationActService.getDraftVerificationActs(params)).data
        }
        dispatch(roamingVerificationActSlice.actions.updateRoamingVerificationActs(invoices))
    } catch (error) {
        console.log(error)
    }
}
export const loadRoamingVerificationActsCountAsync = ({type_viewer, page, limit, ...filters}) => async (dispatch) => {
    try {
        let count = 0
        if (type_viewer === VIEW_INBOX) {
            count = (await roamingVerificationActService.getCustomerVerificationActsCount(filters)).data
        } else if (type_viewer === VIEW_OUTBOX) {
            count = (await roamingVerificationActService.getExecutorVerificationActsCount(filters)).data
        } else if (type_viewer === VIEW_DRAFT) {
            count = (await roamingVerificationActService.getDraftVerificationActsCount(filters)).data
        }
        dispatch(roamingVerificationActSlice.actions.updateRoamingVerificationActsCount(count))
    } catch (error) {
        console.log(error)
    }
}

export const verificationActSignAndRegister = async ({verificationAct, certificate, lang}) => {
  try {
      EventBus.dispatch(DOCUMENT_SIGN_STARTED);
      let pkcs7_64 = await signDocAndGetRoamingSignature(verificationAct, certificate)
      const response = await roamingVerificationActService.registerRoamingVerificationAct({
          id: verificationAct.VerificationActId,
          signature: pkcs7_64,
          ownerInn: verificationAct.OwnerTin,
          lang: lang
      })
      EventBus.dispatch(DOCUMENT_SIGN_SUCCESS);
      return response
  } catch (error) {
    console.log(error);
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
      }
  }
}

export const verificationActSaveDraft = async ({verificationAct}) => {
  try {
      EventBus.dispatch(DOCUMENT_SIGN_STARTED);
      const response = await roamingVerificationActService.saveDraftRoamingVerificationAct({
        content: JSON.stringify(verificationAct)
      })
      EventBus.dispatch(DOCUMENT_SIGN_SUCCESS);
      return response
  } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
      }
  }
}

export const cancelAsync = async (certificate, lang, contract, comment) => {
    try {
      EventBus.dispatch(DOCUMENT_CANCEL_STARTED, contract.id);
      const {data: hashCode} = await roamingVerificationActService.getCancelHashCode(contract.id, comment)
      let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate)
      const { data: canceledContract } = await roamingVerificationActService.cancel(contract.id, {signature: roamingSignature, lang: lang})
      EventBus.dispatch(DOCUMENT_CANCEL_SUCCESS, canceledContract)
    } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_CANCEL_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_CANCEL_FAILED, "edi.document.sign.notification.default");
      }
    }
}

export const customerAcceptAsync = async (certificate, lang, contract) => {
    try {
      EventBus.dispatch(DOCUMENT_SIGN_STARTED, contract.id);
      const {data: hashCode} = await roamingVerificationActService.getAcceptHashCode(contract.id)
      let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate, true)
      const { data: acceptedContract } = await roamingVerificationActService.customerAccept(contract.id, {signature: roamingSignature, lang: lang})
      EventBus.dispatch(DOCUMENT_SIGN_SUCCESS, acceptedContract)
    } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
      }
    }
}

export const customerDeclineAsync = async (certificate, lang, contract, comment) => {
    try {
      EventBus.dispatch(DOCUMENT_DECLINE_STARTED, contract.id);
      const {data: hashCode} = await roamingVerificationActService.getDeclineHashCode(contract.id, comment)
      let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate)
      const { data: declineContract } = await roamingVerificationActService.customerDecline(contract.id, {signature: roamingSignature, comment: comment, lang: lang})
      EventBus.dispatch(DOCUMENT_DECLINE_SUCCESS, declineContract)
    } catch (error) {
      if(error.detected) {
          EventBus.dispatch(DOCUMENT_DECLINE_FAILED, error.message);
      } else {
          EventBus.dispatch(DOCUMENT_DECLINE_FAILED, "edi.document.sign.notification.default");
      }
    }
}
