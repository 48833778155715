import React, {useContext, useState, createContext} from 'react';

const ViewerContext = createContext();

export const ViewerProvider = ({ children }) => {

    const [viewer, setViewer] = useState('inbox');

    return (
        <ViewerContext.Provider value={{ viewer, setViewer }}>
            {children}
        </ViewerContext.Provider>
    );
};

export const useViewer = () => useContext(ViewerContext);
