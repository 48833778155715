import React, {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import ReturnInvoiceRegisterForm from "./ReturnInvoiceRegisterForm";
import {useDispatch, useSelector} from "react-redux";
import {loadReturnShipmentAsync} from "../../../../app/store/reducers/return-shipment/returnShipmentReducer";
import {loadContractsAsync} from "../../../../app/store/reducers/contract/contractReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadCompanyProductCatalogsAsync,} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang} from "../../../../app/store/reducers/main/mainReducer";
import {loadProductMapNamesAsync} from "../../../../app/store/reducers/productMap/productMapReducer";
import {DefineByExecutor} from "../../../../app/order/OrderProductNameDefineMethodStrategyType";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionEdiReturnInvoiceCreate,
} from "../../../../enum/Permission/EdiPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const ReturnInvoiceRegister = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);
    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)

    const {params: {id}} = useRouteMatch();

    const [returnShipment, setReturnShipment] = useState(null);
    const [productMapNames, setProductMapNames] = useState([]);




    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiReturnInvoiceCreate))) {
            if (history.length > 1) {
                history.push('/edi/return-shipments')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    const loadReturnShipment = async () => {
        const loadedReturnShipment = await loadReturnShipmentAsync(id);
        setReturnShipment(loadedReturnShipment)
    }

    // load return shipment
    useEffect(() => {
        dispatch(loadContractsAsync());
        loadReturnShipment();
    }, [id, activeOrganization]);

    // load product catalog company
    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
    }, [lang, activeOrganization])

    // load and set product map names
    useEffect(() => {
        if (returnShipment && returnShipment.return_order.product_name_define_method_strategy === DefineByExecutor.id) {
            loadProductMapNamesAsync({
                customer_inn: returnShipment.customer_info.inn,
                executor_inn: returnShipment.executor_info.inn
            })
                .then(setProductMapNames)
        } else {
            setProductMapNames([]);
        }
    }, [returnShipment])


    if (!returnShipment)
        return <span>loading return invoice data...</span>


    return (
        <ReturnInvoiceRegisterForm returnShipment={returnShipment}
                                   productMapNames={productMapNames}
        />
    )
}

export default ReturnInvoiceRegister;
