import React, {useMemo, useRef, useState} from 'react';
import {Card, Form, OverlayTrigger, Popover} from "react-bootstrap";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import SoftBadge from "../../../../../../common/SoftBadge";
import numeral from "numeral";
import DataTableColumnSettings from "../../../../../../common/table/DataTableColumnSettings";
import CardDropdown from "../../../../../../common/CardDropdown";
import ViewTelegramOrderBotOrderDropdownItem from "../../ViewTelegramOrderBotOrderDropdownItem";
import UploadTelegramOrderToExcel from "../excel/UploadTelegramOrderToExcel";
import DeleteTelegramOrderBotOrderDropdownItem from "../../DeleteTelegramOrderBotOrderDropdownItem";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import TelegramOrderBotOrdersDatatableHeader from "./TelegramOrderBotOrdersDatatableHeader";
import DataTable from "../../../../../../common/table/DataTable";
import DataTablePagination from "../../../../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../../../../common/table/DataTableLimiter";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const TelegramOrderBotOrderDataTable = ({
                                            filters,
                                            pagination,
                                            columnVisibility,
                                            loading,
                                            orders,
                                            count,
                                            onFilterChange,
                                            onDebouncedFilterChange,
                                            onColumnVisibilityChange,
                                            onPaginationChange,
                                            onReloadData
                                        }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const numberFormat = useSelector(selectNumberFormat);
    const [rowSelection, setRowSelection] = useState({});
    const lastRowSelected = useRef(null);

    const columns = useMemo(() => {
        return [
            {
                id: 'selectColumn',
                accessorKey: t(lang, "items.common.column_switch"),
                enableSorting: false,
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                            />
                        </th>
                    )
                },
                cell: ({row, table}) => {
                    const updateRowSelection = (startRowId, endRowId) => {
                        const rows = table.getCoreRowModel().rows;
                        const startIndex = rows.findIndex(row => row.id === startRowId);
                        const endIndex = rows.findIndex(row => row.id === endRowId);
                        const fromIndex = Math.min(startIndex, endIndex);
                        const toIndex = Math.max(startIndex, endIndex);
                        const newRowSelection = {};

                        for (let i = fromIndex; i <= toIndex; i++) {
                            newRowSelection[rows[i].id] = true;
                        }

                        setRowSelection(prev => ({...prev, ...newRowSelection}));
                    };
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={row.getIsSelected()}
                                        disabled={!row.getCanSelect()}
                                        onChange={row.getToggleSelectedHandler()}
                                        onClick={(e) => {
                                            if (e.shiftKey && lastRowSelected.current)
                                                updateRowSelection(lastRowSelected.current.id, row.id);
                                            lastRowSelected.current = row
                                        }}
                            />
                        </td>
                    )
                },
                footer: ({column}) => {
                    return (
                        <td className="p-1 align-middle text-center"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            <Form.Check checked={table.getIsAllPageRowsSelected()}
                                        onChange={table.getToggleAllPageRowsSelectedHandler()}
                            />
                        </td>
                    )
                }
            },
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {pagination.pageIndex * pagination.pageSize + row.index + 1}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
            {
                id: 'user',
                accessorKey: t(lang, 'crm.common.datatable.username'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1">
                            {t(lang, 'crm.common.datatable.username')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-dark">
                            {original.user.first_name + ' ' + original.user.last_name}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-dark"></td>
                    )
                }
            },
            {
                id: 'contractorName',
                accessorKey: t(lang, 'crm.common.datatable.contractor'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1">
                            {t(lang, 'crm.common.datatable.contractor')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-dark">
                            {original.contractor &&
                                <Link to={`/crm/contractor-card/${original.contractor.id}`}
                                      className={'fs--1 fw-semi-bold'}>
                                    {original.contractor.name}
                                </Link>
                            }
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-dark"></td>
                    )
                }
            },
            {
                id: 'date',
                accessorKey: t(lang, 'crm.common.order.date'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1">
                            {t(lang, 'crm.common.order.date')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-dark">
                            {dayjs(original.date).format(dateFormat)}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-dark"></td>
                    )
                }
            },
            {
                id: 'contractorPhone',
                accessorKey: t(lang, 'crm.common.datatable.phone.number'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, 'crm.common.datatable.phone.number')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            {original.user.phone_number || ""}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
            {
                id: 'total',
                accessorKey: t(lang, 'crm.common.datatable.total_amount'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1">
                            {t(lang, 'crm.common.datatable.total_amount')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-end text-dark">
                            {original.net_price.map((total, index) => (
                                <div key={index} className={'text-break my-1'}>
                                    <SoftBadge bg="primary"
                                               className="fs--1"
                                    >
                                        {numeral['formats'][numberFormat].format(total.amount)} {total.currency.name}
                                    </SoftBadge>
                                </div>
                            ))}
                        </td>
                    );
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const totalInfo = rows.reduce((acc, {original}) => {
                        original.net_price.forEach((netPrice) => {
                            if (acc[netPrice.currency.name]) acc[netPrice.currency.name] += netPrice.amount;
                            else acc[netPrice.currency.name] = netPrice.amount;
                        });
                        return acc;
                    }, {});
                    return (
                        <td className="p-1 align-middle text-end text-dark">
                            {Object.keys(totalInfo).map((key) => (
                                <div key={key}>
                                    <OverlayTrigger trigger={['hover', 'focus']}
                                                    placement={'bottom'}
                                                    overlay={
                                                        <Popover id="popover-basic" className='mt-0'>
                                                            <Popover.Header>
                                                                <SoftBadge bg="info">
                                                                    {t(lang, "warehouse.operation.item.common.total_sum")}
                                                                </SoftBadge>
                                                            </Popover.Header>
                                                        </Popover>
                                                    }
                                    >
                                        <SoftBadge bg="info" className="cursor-pointer">
                                            {numeral.formats[numberFormat].format(totalInfo[key])}
                                            <span className="ms-1 fw-bold">{key}</span>
                                        </SoftBadge>
                                    </OverlayTrigger>
                                </div>
                            ))}
                        </td>
                    )
                }
            },
            {
                id: 'approved',
                accessorKey: t(lang, 'crm.common.datatable.status'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, 'crm.common.datatable.status')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            {original.is_approved ?
                                <SoftBadge bg="success" className="fs--1">
                                    {t(lang, 'common.status.approved')}
                                </SoftBadge>
                                :
                                <SoftBadge bg="danger" className="fs--1">
                                    {t(lang, 'common.status.not_approved')}
                                </SoftBadge>
                            }
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
            {
                id: 'approved_at',
                accessorKey: t(lang, 'crm.data.table.approved_at'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, 'crm.data.table.approved_at')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            {(original.is_approved && original.approved_at) ? dayjs(original.approved_at).format(`${dateFormat} HH:mm:ss`) : ""}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
            {
                id: 'sale_info',
                accessorKey: t(lang, 'crm.data.table.order_sale'),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            {t(lang, 'crm.data.table.order_sale')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            {(original.is_sale_created && original.sale_info) &&
                                <Link to={`/warehouse/operation/sales/view/${original.sale_info.id}`}>
                                    {original.sale_info.number}
                                </Link>
                            }
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
            {
                id: 'action',
                accessorKey: t(lang, 'notification.table.actions'),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1">
                            <DataTableColumnSettings table={table}/>
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark">
                            <CardDropdown btnRevealClass="btn-reveal-sm">
                                <div className="py-2">
                                    <ViewTelegramOrderBotOrderDropdownItem id={original.id}/>
                                    <UploadTelegramOrderToExcel id={original.id}/>
                                    <DeleteTelegramOrderBotOrderDropdownItem order={original}/>
                                </div>
                            </CardDropdown>
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"></td>
                    )
                }
            },
        ];
    }, [lang, pagination]);
    const data = useMemo(() => {
        return orders;
    }, [orders]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: onPaginationChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        autoResetPageIndex: false,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        rowCount: count,
        meta: {
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
        },
        state: {
            pagination: pagination,
            rowSelection: rowSelection,
            columnVisibility: columnVisibility
        }
    });

    return (
        <Card>
            <Card.Header>
                <TelegramOrderBotOrdersDatatableHeader table={table}
                                                       filters={filters}
                                                       onFilterChange={onFilterChange}
                                                       onDebouncedFilterChange={onDebouncedFilterChange}
                                                       onReloadData={onReloadData}
                                                       loading={loading}
                />
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={count}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    );
};

export default TelegramOrderBotOrderDataTable;