import React, {useState} from "react";
import IconButton from "../../../../common/IconButton";
import {faCancel} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {executorCancelAsync} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import PropTypes from "prop-types";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    ROAMING_INVOICE_BULK_ACTION_FINISHED,
    ROAMING_INVOICE_BULK_ACTION_STARTED
} from "../../../../../app/eventbus/roaming/roamingInvoiceEvents";
import {PermissionRoamingInvoiceReject} from "../../../../../enum/Permission/RoamingPermission";

const RoamingInvoiceExecutorCancel = ({id, invoices, handleShowProgressBar, updateActionResult, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    // cancel invoice
    const executorCancel = () => {
        if (invoices.length > 0)
            bulkCancel();
        else
            singleCancel();
    };

    // cancel single document
    const singleCancel = () => {
        setLoading(true);
        executorCancelAsync(activeCertificate, lang, id)
            .then(() => {
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    // bulk cancel documents
    const bulkCancel = async () => {
        handleShowProgressBar();
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_STARTED);
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];
            try {
                await executorCancelAsync(activeCertificate, lang, invoices[i].id);
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: invoice.info.number,
                    date: invoice.info.date,
                    contractor: {
                        name: invoice.customer.name,
                        inn: invoice.customer.inn
                    },
                    error: error?.message
                })
            }
        }
        EventBus.dispatch(ROAMING_INVOICE_BULK_ACTION_FINISHED);
    }

    return (
        <SelectCertificateWrapperComponent
            permission={PermissionRoamingInvoiceReject}
            openDialogButtonText={t(lang, "roaming.common.cancel")}
            submitButton={
                <IconButton
                    id="ExecutorCancel"
                    onClick={executorCancel}
                    variant="falcon-danger"
                    size="sm"
                    icon={faCancel}
                    className="m-1"
                    disabled={loading}
                    {...props}
                >
                    {t(lang, "roaming.common.cancel")}
                </IconButton>}
        />
    )
}

RoamingInvoiceExecutorCancel.propTypes = {
    id: PropTypes.string,
    invoices: PropTypes.array,
    updateActionResult: PropTypes.func,
    handleShowProgressBar: PropTypes.func
}
RoamingInvoiceExecutorCancel.defaultProps = {
    id: '',
    invoices: [],
    updateActionResult: () => {},
    handleShowProgressBar: () => {}
}

export default RoamingInvoiceExecutorCancel;
