import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {Button, Card, Col, Form, Row, Spinner, Table, ButtonGroup, Dropdown} from "react-bootstrap";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import IconButton from "../../../../../common/IconButton";

import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import SelectBranch from "../../../../../common/SelectBranch";
import CrmExecutorOrderItemRowAdd from "./CrmExecutorOrderItemRowAdd";
import {UZS} from "../../../../../../enum/CurrencyCode";
import {selectCurrency} from "../../../../../../app/store/reducers/currency/currencyReducer";
import numeral from "numeral";
import SelectContractor from "../../../contractor/SelectContractor";
import {
    getOrderStatusText,
    getOrderStatusVariant,
    CrmExecutorOrderStatuses
} from "../../../../../../enum/CrmOrderStatus";
import {deleteOrderItem} from "../../../../../../app/store/reducers/crm/order/crmExecutorOrderReducer";
import {toast} from "react-toastify";
import {useParams} from "react-router";

const itemsFieldName = 'items';

const CrmExecutorOrderForm = ({loading, formTitle, order, onChangeStatus, onSaveEditItem}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const currencies = useSelector(selectCurrency);
    const [editingItemId, setEditingItemId] = useState(null)
    const dispatch = useDispatch();
    const {id} = useParams()

    const {register, formState: {errors}, control} = useFormContext();
    const {append, fields, remove} = useFieldArray({name: itemsFieldName});

    const watchFieldArray = useWatch({name: itemsFieldName});
    const controlledFields = watchFieldArray ? fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    }) : [];

    const onDeleteItem = (item, index) => {
        if (item.item_id) {
            deleteOrderItem({order_id: id, order_item_id: item.item_id})
                .then(() => {
                    remove(index);
                    toast.success(t(lang, 'crm.common.toast.success'));
                })
                .catch(() => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
        } else {
            remove(index);
        }
    }

    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateFormat);

    const activeOrganization = useSelector(selectActiveOrganization)

    const TotalSum = () => {
        let filtered_arr = [];
        currencies.forEach(currency => {
            let filteredByCurrency = controlledFields.filter(item => item.price?.currency_id === currency.id);
            if (filteredByCurrency.length > 0) {
                filtered_arr.push(filteredByCurrency)
            }
        })
        return filtered_arr.map(arr => {
            let amount = arr.reduce((total, item) => {
                return total + item?.quantity * item.price?.amount;
            }, 0);
            return {
                amount: amount,
                currency: currencies.find(cur => cur.id === arr[0]['price']['currency_id'])
            }
        });
    }

    const addNewItem = () => {
        append({
            product: '',
            global_item_id: null,
            barcode: '',
            product_code: '',
            measurement: '',
            price: {
                amount: '',
                currency_id: currencies?.find(c => c?.code === UZS)?.id
            },
            quantity: ''
        });
    };

    return (
        <Fragment>
            <Card className="h-100 mb-2">
                <Card.Body>
                    <Row className={'mb-3'}>
                        <Col xs={12} lg={4}>
                            <h5>{formTitle}</h5>
                        </Col>
                        {id && <Col xs={12} lg={8} className={'text-end'}>
                            <Dropdown as={ButtonGroup}>
                                <Button
                                    variant={getOrderStatusVariant(order?.status)}>Статус: {t(lang, getOrderStatusText(order?.status))}</Button>

                                <Dropdown.Toggle split variant={getOrderStatusVariant(order?.status)}
                                                 id="order-status"/>
                                <Dropdown.Menu>
                                    {CrmExecutorOrderStatuses.map((status, index) => {
                                        return (
                                            status.value !== order?.status &&
                                            <Dropdown.Item key={index} onClick={() => {
                                                onChangeStatus(status)
                                            }}>{t(lang, status.label)}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>}
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, "crm.common.customer_order")}</Form.Label>
                                <div className={'d-flex justify-content-between'}>
                                    <div style={{width: '100%'}}>
                                        <Controller
                                            control={control}
                                            name={'customer'}
                                            rules={{required: t(lang, "crm.common.forms.validations.is_required"),}}
                                            render={({field}) => {
                                                return (
                                                    <SelectContractor
                                                        defaultValue={field?.value?.id}
                                                        className={classNames({
                                                            'is_invalid': errors?.customer,
                                                            'border': errors?.customer,
                                                            'border-danger': errors?.customer
                                                        }, 'rounded')}
                                                        placeholder={t(lang, "edi.common.button.select")}
                                                        classNamePrefix="react-select"
                                                        onChange={field.onChange}
                                                    />
                                                )
                                            }}/>
                                    </div>

                                </div>
                                <Form.Control.Feedback className={'d-block'}
                                                       type="invalid">{errors?.customer?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, "crm.common.order.number")}</Form.Label>
                                <Form.Control type="text"
                                              placeholder={t(lang, "crm.common.order.number.placeholder")}
                                              isInvalid={errors?.info?.number}
                                              isValid={Object.keys(errors).length > 0 && !errors?.info?.number}
                                              {...register('info.number', {
                                                  required: t(lang, "crm.common.forms.validations.is_required"),
                                              })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.info?.number?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, "crm.common.order.date")}</Form.Label>
                                <Controller
                                    control={control}
                                    name='info.date'
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            placeholderText={t(lang, "crm.common.order.date.placeholder")}
                                            wrapperClassName={classNames({
                                                'is-invalid': errors?.info?.date,
                                                'is-valid': Object.keys(errors).length > 0 && !errors?.info?.date
                                            })}
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            className={classNames('form-control', {
                                                'is-invalid': errors?.info?.date,
                                                'is-valid': Object.keys(errors).length > 0 && !errors?.info?.date
                                            })}
                                        />
                                    )}
                                    rules={{
                                        required: t(lang, "crm.common.forms.validations.is_required")
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.info?.date?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, "crm.common.contract.number")}</Form.Label>
                                <Form.Control type="text"
                                              placeholder={t(lang, "crm.common.contract.number.placeholder")}
                                              {...register('contract.number')}
                                />

                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, "crm.common.contract.date")}</Form.Label>
                                <Controller
                                    control={control}
                                    name='contract.date'

                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            placeholderText={t(lang, "crm.common.contract.date.placeholder")}
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            className={'form-control'}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, "crm.orders.add_order.order_details.final_shipment_date")}</Form.Label>
                                <Controller
                                    control={control}
                                    name='expires_on'

                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            placeholderText={t(lang, "crm.orders.add_order.order_details.final_shipment_date.placeholder")}
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            className={'form-control'}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, "crm.orders.add_order.order_branch")}</Form.Label>
                                <Controller name="branch"
                                            control={control}
                                            render={({field}) => (
                                                    <SelectBranch
                                                        defaultValue={field.value?.id || field.value}
                                                        className={'rounded'}
                                                        onChange={field.onChange}
                                                        errors={errors}
                                                    />
                                                )}/>
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>{t(lang, "crm.common.items.product.description")}</Form.Label>
                                <Form.Control type="text" as="textarea"
                                              placeholder={t(lang, "crm.common.items.product.description.placeholder")}
                                              {...register('description')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Row className="g-3 mb-3">
                <Col xs={12} className="pe-lg-2 mb-3">
                    <Card className="h-100">
                        <Card.Header>
                            <Row className="align-items-end g-2">
                                <Col>
                                    <h5 className="mb-0">{t(lang, "crm.common.items")}</h5>
                                </Col>
                                <Col xs="auto">
                                    <IconButton variant="falcon-default" size="sm" icon="plus" transform="shrink-3"
                                                className="me-2" onClick={addNewItem}>
                                        <span className="d-none d-sm-inline-block ms-1">
                                            {t(lang, "crm.common.add_button")}
                                        </span>
                                    </IconButton>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive striped hover size={"sm"}>
                                <thead>
                                <tr>
                                    <th scope="col">{t(lang, "crm.common.items.product")}</th>
                                    <th scope="col">{t(lang, "crm.common.items.product.barcode")}</th>
                                    <th scope="col">{t(lang, "crm.common.items.product.product_code")}</th>
                                    <th scope="col"
                                        style={{width: 150}}>{t(lang, "crm.common.items.product.measurement")}</th>
                                    <th scope="col"
                                        style={{width: 100}}>{t(lang, "crm.common.items.product.quantity")}</th>
                                    <th scope="col">{t(lang, "crm.common.items.product.price")}</th>
                                    <th scope="col">{t(lang, "crm.common.items.product.total")}</th>
                                    <th scope="col"/>
                                </tr>
                                </thead>
                                <tbody>
                                {controlledFields.map((item, index) => (
                                    <CrmExecutorOrderItemRowAdd item={item}
                                                                key={item.id}
                                                                editingItemId={editingItemId}
                                                                onEditItemId={(editItemId) => {
                                                                    setEditingItemId(editItemId)
                                                                }}
                                                                loading={loading}
                                                                onSaveEditItem={onSaveEditItem}
                                                                onDelete={(deleting_item) => {
                                                                    onDeleteItem(deleting_item, index)
                                                                }}
                                                                arrayFieldName={itemsFieldName}
                                                                index={index}
                                    />
                                ))}
                                </tbody>
                            </Table>

                            <div className={"d-flex justify-content-end"}>
                                <h5 className={'me-3'}>{t(lang, "edi.contract_formation.datatable.header.row.total_sum")}:</h5>
                                <h5>{TotalSum().map((total, index) => {
                                    return (<div key={index}>{<span>{numeral.formats[numberFormat].format(total.amount)}</span>} <small className={'text-info'}>{total.currency?.name}</small></div>)
                                })}</h5>
                            </div>

                            <div className="border-dashed-bottom my-3"/>

                            <Row className={'justify-content-end'}>
                                <Col md={'auto'} className="text-end">
                                    <Button type="submit" disabled={loading}>
                                        {loading && <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />}
                                        {t(lang, "crm.common.save_button")}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>)
};

export default CrmExecutorOrderForm;
