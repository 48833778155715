class ItemDictionaryWrapper {
    getName(itemsDictionary, itemId) {
        return itemsDictionary[itemId] ? itemsDictionary[itemId].item.name : null;
    }

    getWarehouseState(itemsDictionary, itemId, warehouseId) {
        if (itemsDictionary[itemId]) {
            const itemWarehouse = itemsDictionary[itemId].warehouse_states?.warehouse_items?.find(warehouse => warehouse.id === warehouseId);
            return itemWarehouse ? itemWarehouse.state : null;
        }
        return null;
    }

    getItemCommonPriceAmount(itemsDictionary, itemId) {
        if (itemsDictionary[itemId]) {
            return itemsDictionary[itemId].price.common_price.amount;
        }
        return null;
    }
}

const itemDictionaryWrapper = new ItemDictionaryWrapper();
export default itemDictionaryWrapper;