import ContractorNoteForm from "./ContractorNoteForm";
import React, {useState} from "react";
import {editContractorNoteAsync} from "../../../../../../app/store/reducers/contractor/contractorNoteReducer";

const ContractorEditNoteForm = ({editingNote, onCancel, onSuccess}) => {
    const [loading, setLoading] = useState(false)
    const handleOnSubmit = (formData) => {
        setLoading(true)
        editContractorNoteAsync(editingNote.id, {
            comment: formData.note
        }).then(res => {
            setLoading(false)
            onSuccess()
        }).catch(errors => {
            setLoading(false)
        })
    }
    return (<>
        <ContractorNoteForm onSubmit={handleOnSubmit} loading={loading} note={editingNote}
                            onCancel={onCancel}/>
    </>)
}
export default ContractorEditNoteForm