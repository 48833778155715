import {Card} from "react-bootstrap";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import ContractorAgentDatatableHeader from "./ContractorAgentDatatableHeader";
import {
    changeFilterOptionsAsync,
    loadContractorAgentsAsync, loadContractorAgentsCountAsync,
    selectContractorAgents, selectContractorAgentsCount,
    selectContractorAgentsFilterOptions
} from "../../../../../app/store/reducers/contractor/contractorAgentReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    DELETE_CONTRACTOR_AGENT_SUCCESS,
    REGISTER_CONTRACTOR_AGENT_SUCCESS
} from "../../../../../app/eventbus/contractor/contractorAgentEvents";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import ContractorAgentDelete from "./ContractorAgentDelete";


const ContractorAgentDatatable = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const filters = useSelector(selectContractorAgentsFilterOptions)
    const agents = useSelector(selectContractorAgents)
    const count = useSelector(selectContractorAgentsCount)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate


    const loadContractorAgents = () => {
        dispatch(loadContractorAgentsAsync({...filters, organization_id: activeOrganization.id}))
        dispatch(loadContractorAgentsCountAsync({...filters}))
    }

    useEffect(() => {
        const onContractorAgentRegisterHandler = EventBus.on(REGISTER_CONTRACTOR_AGENT_SUCCESS, () => {
            toast.success(t(lang, 'edi.toast.register_contract_succeed'));
            loadContractorAgents()
        })

        const onContractorAgentDeleteHandler = EventBus.on(DELETE_CONTRACTOR_AGENT_SUCCESS, () => {
            toast.success(t(lang, 'edi.toast.register_contract_succeed'));
            loadContractorAgents()
        })

        return () => {
            EventBus.remove(REGISTER_CONTRACTOR_AGENT_SUCCESS, onContractorAgentRegisterHandler)
            EventBus.remove(DELETE_CONTRACTOR_AGENT_SUCCESS, onContractorAgentDeleteHandler)
        }
    },[])


    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "crm.contractor.datatable.header.row.full_name"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.name}
                    </>
                );
            }
        },
        {
            accessor: 'contacts',
            Header: t(lang, "crm.common.datatable.phone.number"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.contacts.map((contact) => {
                            return(
                                <a href={`tel:+${contact}`} key={contact}>+{contact}</a>
                            )
                        })}
                    </>
                );
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "crm.common.datatable.created_at"),
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                )
            }
        },
        {
            accessor: 'updated_at',
            Header: t(lang, "crm.common.datatable.update_date"),
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{dayjs(original.updated_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                )
            }
        },
        {
            accessor: 'none',
            Header: "",
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <ContractorAgentDelete id={original?.id}/>
                    </>
                )
            }
        },
    ]


    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }

    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }


    useEffect(() => {
        loadContractorAgents()
    },[filters, activeOrganization])

    return(
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={agents}
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ContractorAgentDatatableHeader table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}


export default ContractorAgentDatatable;