import React, {Fragment, useEffect} from 'react';
import {Nav} from "react-bootstrap";
import {ReturnOrderStatuses} from "../enum/ReturnOrderStatus";
import {changeFilterOrdersAsync} from "../app/store/reducers/order/orderReducer";
import classNames from "classnames";
import {EDI_VIEWER_CUSTOMER, EDI_VIEWER_EXECUTOR, getEdiViewerText} from "../enum/edi/ediViewer";
import {Link} from "react-router-dom";
import {selectLang, Translate} from "../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useViewer} from "../pages/main/edi/ViewerProvider";
const EdiViewerComponent = ({to, typeViewer, title}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const { setViewer } = useViewer();

    useEffect(() => {
       typeViewer && setViewer(typeViewer)
    }, [typeViewer]);

    return (
        <Fragment>
            <Nav variant="tabs" className={'d-inline-flex py-1 border-bottom-0 my-0'}
                 activeKey={typeViewer}>
                <Nav.Item>
                    <Nav.Link as={'div'} className={classNames('rounded-0 bg-300')}>
                        {t(lang, title)}
                    </Nav.Link>
                </Nav.Item>
                <Fragment>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`${to}/${EDI_VIEWER_CUSTOMER}`} className={classNames({
                            'bg-primary text-white rounded-0': EDI_VIEWER_CUSTOMER === typeViewer,
                            'px-2': EDI_VIEWER_CUSTOMER !== typeViewer,
                        })}>
                            {t(lang, getEdiViewerText(EDI_VIEWER_CUSTOMER))}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`${to}/${EDI_VIEWER_EXECUTOR}`} className={classNames({
                            'bg-primary text-white rounded-0': EDI_VIEWER_EXECUTOR === typeViewer,
                            'px-2': EDI_VIEWER_EXECUTOR !== typeViewer,
                        })}>
                            {t(lang, getEdiViewerText(EDI_VIEWER_EXECUTOR))}
                        </Nav.Link>
                    </Nav.Item>
                </Fragment>
            </Nav>
        </Fragment>
    );
};

export default EdiViewerComponent;
