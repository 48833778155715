import React, {useMemo} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {selectEmployees} from "../../../app/store/reducers/employee/employeeReducer";

const MultiEmployeeSelect = ({employeeIds = [], onChange, ...props}) => {
    const employees = useSelector(selectEmployees);
    const lang = useSelector(selectLang);
    const t = Translate;

    const defaultValue = useMemo(() => {
        const value = [];
        for (let i = 0; i < employees.length; i++) {
            for (let j = 0; j < employeeIds.length; j++) {
                if (employeeIds[j] === employees[i].id) {
                    value.push(employees[i]);
                }
             }
        }
        return value
    }, [employees, employeeIds])

    const onSelectEmployee = (selectedEmployee) => {
        onChange(selectedEmployee);
    };

    return (
        <ReactSelect
            isMulti={true}
            options={employees}
            value={defaultValue}
            onChange={onSelectEmployee}
            classNamePrefix={'react-select'}
            placeholder={t(lang, 'Выберите')}
            getOptionValue={(option) => option?.id}
            getOptionLabel={(option) => option?.name}
            {...props}
        />
    );
};

export default MultiEmployeeSelect;