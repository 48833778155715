import React, {useEffect, useState} from 'react';
import {loadContractAsync} from "../../../../app/store/reducers/contract/contractReducer";
import EditContractForm from "../../../../components/hippo/contract/forms/edi/EditEdiContractForm";

const EditContract = ({id, show, onClose}) => {
    const [contract, setContract] = useState(null)

    useEffect(() => {
        if (id && show) {
            loadContractAsync(id).then((contract) => {
                setContract(contract)
            }).catch((error) => console.log(error))
        } else {
            setContract(null)
        }
    }, [id])

    return (
        <>
            {contract && <EditContractForm show={show} onClose={onClose} contract={contract} />}
        </>
    );
};

export default EditContract;