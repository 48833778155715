import React, {Fragment, useState} from 'react';
import IconButton from "../../../../common/IconButton";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {deleteOrder} from "../../../../../app/store/reducers/crm/order/crmCustomerOrderReducer";

const CrmCustomerOrderDeleteButton = ({id, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    // show/hide modal
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);

    const onDelete = () => {
        setLoading(true);
        deleteOrder(id)
            .then(() => {
                setShow(false)
                setLoading(false)
            })
            .catch(() => setLoading(false))
    };

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                variant="falcon-danger"
                icon="fa-trash"
                size="sm"
                {...props}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "items.common.delete_item")}
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "items.delete_item_modal.title")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "items.common.cancel")}
                    </Button>
                    <Button variant="danger"
                            onClick={onDelete} disabled={loading}
                    >
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                        }
                        {t(lang, "items.common.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

export default CrmCustomerOrderDeleteButton;