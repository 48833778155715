import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {FormProvider, useForm} from "react-hook-form";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../common/IconButton";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import SignActionWithSelectCertificatWrapper from "../../components/SignActionWithSelectCertificatWrapper";
import { selectCurrentCertificate } from "../../../../../app/store/reducers/eimzo/eimzoReducer";


const CancelContract = ({onCancel, disabled, ...props}) => {
    const methods = useForm();
    const { register, formState: {errors}, handleSubmit, reset } = methods
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const [show, setShow] = useState(false);
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        if(currentCertificate?.keyId) {
            onCancel(comment)
            reset();
            setShow(false);
        }
    };

    return (
        <>
            <IconButton
                variant="falcon-danger"
                size="sm"
                icon={faBan}
                disabled={disabled}
                iconClassName="me-1"
                onClick={() => setShow(true)}
                {...props}
            >{t(lang, "edi.common.cancel_decline")}</IconButton>
            <Modal size="md" show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(formSubmit)} id="CancelContractForm">
                        <Modal.Header>
                            <Modal.Title>Contract cancel</Modal.Title>
                            <FalconCloseButton onClick={() => {
                                handleClose()
                            }}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    name="Name"
                                    as="textarea" rows={3}
                                    placeholder="Please enter a comment to cancel"
                                    isInvalid={errors?.comment?.message}
                                    {...register("comment", {
                                        required: {
                                            value: true,
                                            message: "This field is required!"
                                        }
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.comment?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                handleClose()
                            }}>{t(lang, "edi.common.button.close")}</Button>
                            <SignActionWithSelectCertificatWrapper isForm>
                                <Button
                                    variant="danger"
                                    className="d-flex align-items-center"
                                    form="CancelContractForm"
                                    disabled={disabled}
                                    type="submit"
                                >{t(lang, "edi.common.button.yes")}
                                </Button>
                            </SignActionWithSelectCertificatWrapper>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    )
}

export default CancelContract;
