import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';
import {Button, FloatingLabel, Form, InputGroup, Spinner} from "react-bootstrap";
import {getOrganizationInfoAsync} from "../../../app/store/reducers/roaming/roamingReducer";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const innFormatRegEx = /^(\d{9}|\d{14})$/

const FindCompany = React.forwardRef(({onChange, inputGroupProps, inputProps, labelProps, defaultInn, isFloating, disabled}, ref) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const findCompany = async (inn) => {
        try {
            setLoading(true)
            onChange(await getOrganizationInfoAsync(inn))
        } catch (e) {
            console.log(e)
            toast.error(t(lang, 'edi.toast.find_company_failed'));
            onChange(null);
        } finally {
            setLoading(false)
        }
    };

    const onChangeHandler = (inn) => innFormatRegEx.test(inn) ? findCompany(inn) : onChange(null);

    useEffect(() => {
        defaultInn && onChangeHandler(defaultInn);
    }, [defaultInn]);


    return (
        <InputGroup {...inputGroupProps}>
            {!isFloating &&
                <Form.Control type="text"
                              className="input-spin-none"
                              ref={ref}
                              readOnly={loading}
                              disabled={disabled}
                              {...(disabled ? {value: defaultInn} : {defaultValue: defaultInn})}
                              onChange={e => onChangeHandler(e.target.value)}
                              {...inputProps}
                />
            }
            {isFloating &&
                <FloatingLabel label={inputProps.placeholder} {...labelProps}>
                    <Form.Control type="text"
                                  className="input-spin-none"
                                  ref={ref}
                                  readOnly={loading}
                                  onChange={e => onChangeHandler(e.target.value)}
                                  {...inputProps}
                    />
                </FloatingLabel>
            }

            {loading && <Button disabled={loading} variant="default">
                <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>
            </Button>}
        </InputGroup>
    )
});

FindCompany.propTypes = {
    onChange: PropTypes.func.isRequired,
    isFloating: PropTypes.bool
}

FindCompany.defaultProps = {
    onChange: () => {
    },
    isFloating: false
}

export default FindCompany;