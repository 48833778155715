import {createSlice} from "@reduxjs/toolkit";
import organizationBindingService from "../../../../services/organizationBinding/organizationBinding";
import referenceDataAggregatorService
    from "../../../../services/referenceDataAggregator/referenceDataAggregatorService";
import EventBus from "../../../eventbus/EventBus";
import {
    DELETE_PARTNER_FAILED,
    DELETE_PARTNER_SUCCEED
} from "../../../eventbus/organizationBinding/organizationBindingEvents";


const initialState = {
    ownerBindingsLoading: false,
    ownerBindings: [],
    ownerBindingsFilter: {},
    ownerBindingsPagination: {page: 1, limit: 10},
    supervisorBindingsLoading: false,
    supervisorBindings: [],
    supervisorBindingsFilter: {},
    supervisorBindingsPagination: {page: 1, limit: 10},
};

const organizationBindingSlice = createSlice({
    name: 'organizationBinding',
    initialState,
    reducers: {
        setOwnerBindingsLoading: (state, action) => {
            state.ownerBindingsLoading = action.payload;
        },
        setOwnerBindings: (state, action) => {
            state.ownerBindings = action.payload;
        },
        setOwnerBindingsFilter: (state, action) => {
            state.ownerBindingsFilter = action.payload;
        },
        setOwnerBindingsPagination: (state, action) => {
            state.ownerBindingsPagination = action.payload;
        },
        setSupervisorBindingsLoading: (state, action) => {
            state.supervisorBindingsLoading = action.payload;
        },
        setSupervisorBindings: (state, action) => {
            state.supervisorBindings = action.payload;
        },
        setSupervisorBindingsFilter: (state, action) => {
            state.supervisorBindingsFilter = action.payload;
        },
        setSupervisorBindingsPagination: (state, action) => {
            state.supervisorBindingsPagination = action.payload;
        },
    }
})
export default organizationBindingSlice.reducer;

export const selectOwnerBindingsLoading = state => state.organizationBinding.ownerBindingsLoading;
export const selectOwnerBindings = state => state.organizationBinding.ownerBindings;
export const selectOwnerBindingsFilter = state => state.organizationBinding.ownerBindingsFilter;
export const selectOwnerBindingsPagination = state => state.organizationBinding.ownerBindingsPagination;
export const selectSupervisorBindingsLoading = state => state.organizationBinding.supervisorBindingsLoading;
export const selectSupervisorBindings = state => state.organizationBinding.supervisorBindings;
export const selectSupervisorBindingsFilter = state => state.organizationBinding.supervisorBindingsFilter;
export const selectSupervisorBindingsPagination = state => state.organizationBinding.supervisorBindingsPagination;


export const setOwnerBindingsFilter = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setOwnerBindingsFilter(params))
};
export const setOwnerBindingsPagination = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setOwnerBindingsPagination(params))
};

export const setSupervisorBinding = (id) => (dispatch) => {
}
export const setSupervisorBindingsPagination = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorBindingsPagination(params))
};
export const setSupervisorBindingsFilter = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorBindingsFilter(params))
};

export const register = (payload) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.register(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const edit = (id, payload) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.edit(id, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};

export const deleteOwnerBindings = (id) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.delete(id)
            .then((response) => {
                EventBus.dispatch(DELETE_PARTNER_SUCCEED)
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_PARTNER_FAILED)
                reject(error);
            })
    })
};

export const loadOwnerBindings = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setOwnerBindingsLoading(true));
    organizationBindingService.getOwnerBindings(params)
        .then((response) => {
            dispatch(organizationBindingSlice.actions.setOwnerBindings(response.data));
            dispatch(organizationBindingSlice.actions.setOwnerBindingsLoading(false));
        })
        .catch((error) => {
            dispatch(organizationBindingSlice.actions.setOwnerBindings([]));
            dispatch(organizationBindingSlice.actions.setOwnerBindingsLoading(false));
        })
};
export const loadSupervisorBindings = (params) => (dispatch) => {
    dispatch(organizationBindingSlice.actions.setSupervisorBindingsLoading(true));
    organizationBindingService.getSupervisorBindings(params)
        .then((response) => {
            dispatch(organizationBindingSlice.actions.setSupervisorBindings(response.data));
            dispatch(organizationBindingSlice.actions.setSupervisorBindingsLoading(false));
        })
        .catch((error) => {
            dispatch(organizationBindingSlice.actions.setSupervisorBindings([]));
            dispatch(organizationBindingSlice.actions.setSupervisorBindingsLoading(false));
        })
};
export const bindItem = (payload) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.bindItem(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const bindWarehouse = (payload) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.bindWarehouse(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadOrganizationBinding = (id) => {
    return new Promise((resolve, reject) => {
        organizationBindingService.getOrganizationBinding(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadBindingSale = (bindingId, saleId) => {
    return new Promise((resolve, reject) => {
        referenceDataAggregatorService.getOrganizationBindingSale(bindingId, saleId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadOrderSaleDifferencesAsync = (orderId) => {
    return new Promise((resolve, reject) => {
        referenceDataAggregatorService.getOrderSaleDifferences(orderId)
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
};
export const loadSaleOrderDifferencesAsync = (saleId) => {
    return new Promise((resolve, reject) => {
        referenceDataAggregatorService.getSaleOrderDifferences(saleId)
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
};

