import ContractorPayoutView from "../../../../components/hippo/crm/contractor/payout-view/ContractorPayoutView";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {loadOneContractorPayoutsAsync} from "../../../../app/store/reducers/contractor/contractorPayoutReducer";


const ContractorPayout = ({viewId}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [payout, setPayout] = useState(null)
    const {id} = useParams()


    const loadContractorPayout = async () => {
        await loadOneContractorPayoutsAsync(viewId || id)
            .then((response) => {
                setPayout(response)
            })
            .catch((error) => {
                setPayout(null)
            })
    }


    useEffect(() => {
        loadContractorPayout()
    },[id, activeOrganization, viewId])




    if (!payout)
        return (
            <>
                loading...
            </>
        )

    return(
        <>
            <ContractorPayoutView payout={payout}/>
        </>
    )
}



export default ContractorPayout;
