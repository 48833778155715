import React from 'react';
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {changeFreeFormsFilterOptions, selectFreeFormDocumentsCount, selectFreeFormDocumentsFilterOptions} from "../../../../app/store/reducers/roaming/roamingFreeFormDocumentReducer";

const pageLimitOptions = [
    {text: "10", value: 10},
    {text: "25", value: 25},
    {text: "50", value: 50},
    {text: "75", value: 75},
    {text: "100", value: 100},
];

const FreeFormDocumentDataTableFooter = () => {
    const {limit, page} = useSelector(selectFreeFormDocumentsFilterOptions);
    const count = useSelector(selectFreeFormDocumentsCount);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const maxPage = Math.ceil(count / limit);
    const pages = [];
    const range = 2;

    for (let i = page - range; i <= maxPage && i <= page + range; i++) {
        if (i >= 1 && i <= maxPage) {
            pages.push(i)
        }
    }

    const onPageChange = selectedPage => dispatch(changeFreeFormsFilterOptions({limit: limit, page: selectedPage}));
    const onLimitChange = selectedOption => dispatch(changeFreeFormsFilterOptions({limit: selectedOption, page: 1}));

    return (
        <>
            <Row className="d-flex flex-sm-row justify-content-xxl-end">
                <Col sm={12} xxl={4} className="d-flex align-items-center justify-content-center justify-content-xxl-end col-12">
                    <Button
                        size="sm"
                        disabled={page === 1}
                        variant="falcon-default"
                        onClick={() => onPageChange(page - 1)}
                    >
                        <FontAwesomeIcon icon="chevron-left"/>
                    </Button>
                    <ul className="pagination mb-0 mx-1">
                        {pages.map(p => (
                            <li key={p} className={classNames({'active': p === page})}>
                                <Button
                                    size="sm"
                                    variant="falcon-default"
                                    className={classNames('page', 'me-1')}
                                    onClick={() => p !== page && onPageChange(p)}
                                >{p}</Button>
                            </li>
                        ))}
                    </ul>
                    <Button
                        size="sm"
                        variant="falcon-default"
                        disabled={page === maxPage}
                        onClick={() => onPageChange(page + 1)}
                    >
                        <FontAwesomeIcon icon="chevron-right"/>
                    </Button>
                </Col>

                <Col sm={6} xxl={2} className="d-flex ms-auto justify-content-end align-items-center justify-content-xxl-end col-6 flex-column flex-sm-row">
                    <p className="mx-2 mb-0">{t(lang, 'roaming.free_form_document.datatable.footer.show_by.title')}</p>
                    <Form.Select
                        size="sm"
                        selected
                        className="w-auto"
                        defaultValue={limit}
                        onChange={event => onLimitChange(event.target.value)}
                    >
                        {pageLimitOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.text}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
        </>
    );
};

export default FreeFormDocumentDataTableFooter;