import React, {useState} from 'react';
import {Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {signAttorneyAsync} from "../../../../app/store/reducers/attorney/attorneyReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import SignActionWithSelectCertificatWrapper from "../../roaming/components/SignActionWithSelectCertificatWrapper";
import {toast} from "react-toastify";
import {PermissionEdiAttorneySign} from "../../../../enum/Permission/EdiPermission";

const SignAttorneyComponent = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const signAttorneyHandler = () => {
        setLoading(true);

        signAttorneyAsync({id: id, certificate: currentCertificate, lang: lang})
            .then(() => {
                setLoading(false);
                toast.success(t(lang, 'edi.attorney.toast.sign.success'));
            })
            .catch(() => {
                setLoading(false);
                toast.error(t(lang, 'edi.attorney.toast.sign.error'));
            })
    }

    return (
        <>

            <SignActionWithSelectCertificatWrapper permission={PermissionEdiAttorneySign}>
                <IconButton
                    size="sm"
                    {...props}
                    icon={faSignature}
                    iconClassName="me-1"
                    variant="falcon-primary"
                    onClick={signAttorneyHandler}
                >
                    {t(lang, 'edi.common.button.sign')}
                    {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
                </IconButton>
            </SignActionWithSelectCertificatWrapper>
        </>
    );
};

export default SignAttorneyComponent;
