import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import LeadForm from "./LeadForm";
import {editLeadAsync} from "../../../../../app/store/reducers/contractor/contractorLeadReducer";

const EditLead = ({lead, onClose}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);

    const handleOnSubmit = (formData) => {
        let phones = formData.phones.filter(p => p.value)
        phones = phones.map(phone => {
            if (phone.value)
                return {
                    type: 1,
                    value: phone.value
                }
        });
        let emails = formData.emails.filter(e => e.value)
        emails = emails.map(email => {
            if (email.value)
                return {
                    type: 3,
                    value: email.value
                }
        });
        const contacts = phones.concat(emails)

        setLoading(true)
        dispatch(editLeadAsync(lead.id, {
            name: formData.name,
            inn: formData.inn,
            type: formData.type.map(t => +t),
            contacts: contacts
        })).then(res => {
            setLoading(false)
            onClose()
        }).catch(err => {
            console.log(err)
        });
    }
    return (<>
        <Modal show={true} onHide={onClose} size="xl">
            <Modal.Header>
                <Modal.Title>{t(lang, "crm.lead.edit")}</Modal.Title>
                <FalconCloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <LeadForm lead={lead} onClose={onClose} loading={loading} onSubmit={handleOnSubmit}/>
            </Modal.Body>
        </Modal>
    </>)
}
export default EditLead