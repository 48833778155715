export const UZS = "UZS";
export const RUB = "RUB";
export const KZT = "KZT";
export const GBP = "GBP";
export const USD = "USD";
export const EUR = "EUR";

class CurrencyWrapper {
    convertToNationalPrice(currencies, fromCurrencyName, fromCurrencyAmount) {
        const currency = currencies.find(c => c.name === fromCurrencyName);
        return fromCurrencyAmount * currency.rate;
    }
}

const currencyWrapper = new CurrencyWrapper();
export default currencyWrapper;