import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import EventBus from "../../../../app/eventbus/EventBus";
import SendWaybillForm from "../../../../components/hippo/roaming/waybill/forms/SendWaybillForm";
import {SAVE_DRAFT_WAYBILL_SUCCEED, SEND_WAYBILL_SUCCEED} from "../../../../app/eventbus/roaming/roamingWaybillEvents";
import {useDispatch, useSelector} from "react-redux";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingShipmentCreate} from "../../../../enum/Permission/RoamingPermission";

const SendWaybill = () => {
    const routerHistory = useHistory();
    const dispatch = useDispatch()

    const history = useHistory();
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingShipmentCreate))) {
            if (history.length > 1) {
                history.push('/roaming/waybills/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_SUCCEED, waybill => {
            routerHistory.push(`/roaming/waybill/${waybill.id}`)
            dispatch(getOrganizationBillingInfoAsync());
        });

        const onSaveDraftWaybillSucceedHandler = EventBus.on(SAVE_DRAFT_WAYBILL_SUCCEED, draftWaybill => {
            routerHistory.push(`/roaming/waybill/draft/${draftWaybill.id}`)
        })

        return () => {
            EventBus.remove(SEND_WAYBILL_SUCCEED, onSendWaybillSucceedHandler);
            EventBus.remove(SAVE_DRAFT_WAYBILL_SUCCEED, onSaveDraftWaybillSucceedHandler);
        }
    }, [])

    return (
        <>
            <SendWaybillForm/>
        </>
    );
};

export default SendWaybill;
