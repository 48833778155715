import Select from "react-select"
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {ContractorPaymentTypes} from "../../../../enum/ContractorWrapper";

const SelectContractorPaymentType = ({defaultValue, onChange, changeColor, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(ContractorPaymentTypes.find(b => b.value === defaultValue) || {label: 'Наличный', value: 1})
    }, [defaultValue])

    return (
        <div style={{width: '230px'}} {...props}>
            <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: changeColor && '0',
                        backgroundColor: changeColor && "#0d6efd",
                        border: changeColor && "none"
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: changeColor && '#fff',
                    }),
                }}
                value={value}
                options={ContractorPaymentTypes}
                hideSelectedOptions
                onChange={(val) => onChange(val)}
                classNamePrefix="react-select w-25"
                getOptionLabel={option => t(lang, option.label)}
                getOptionValue={option => option.value}
            />
        </div>
    )
}
export default SelectContractorPaymentType
