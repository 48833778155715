import axios from "axios";

class RoamingReferenceService {
    constructor() {
        this.baseURL = "/roaming-reference";
    }

    getCompany(innOrPinfl, params) {
        return axios.get(`${this.baseURL}/company/get/${innOrPinfl}`, {params})
    };

    getMyCurrentTurnOver(year) {
        return axios.get(`${this.baseURL}/get-current-turnover/${year}`)
    };

    getCompanies(params) {
        return axios.get(`${this.baseURL}/company/search`, {params})
    };

    getExecutorCompanyPrivileges(params) {
        return axios.get(`${this.baseURL}/get-seller-company-lgotas`, {params})
    };

    getCustomerCompanyPrivileges(params) {
        return axios.get(`${this.baseURL}/get-buyer-company-lgotas`, {params})
    };

    getTurnoverPrivileges(params) {
        return axios.get(`${this.baseURL}/get-turnover-company-lgotas`, {params})
    };

    getWarehouses(params) {
        return axios.get(`${this.baseURL}/get-warehouses`, {params});
    };

    getMeasurements() {
        return axios.get(`${this.baseURL}/get-measurements`);
    };

    getCountries() {
        return axios.get(`${this.baseURL}/get-countries`);
    };

    getRegions() {
        return axios.get(`${this.baseURL}/get-regions`);
    };

    getDistricts() {
        return axios.get(`${this.baseURL}/get-districts`);
    };

    getStations() {
        return axios.get(`${this.baseURL}/get-stations`);
    };

    getInvestmentObject(params) {
        return axios.get(`${this.baseURL}/get-investment-object`, {params});
    }

    getExchangeInfo(params) {
        return axios.get(`${this.baseURL}/get-exchange`, {params});
    }

    getExchangeTypeWithExchangeNumber(params) {
        return axios.get(`${this.baseURL}/get-exchange-type-with-lot-number`, {params});
    }

    getExchangeTypes(params) {
        return axios.get(`${this.baseURL}/get-exchange-types`, {params});
    }

    getCatalogsByCode(payload) {
        return axios.post(`${this.baseURL}/get-catalogs-by-code`, payload)
    }

    getCatalogByCode(code) {
        return axios.get(`${this.baseURL}/get-catalog-by-code/${code}`)
    }

    getCatalogsBySubPositionName(params) {
        return axios.get(`${this.baseURL}/get-catalogs-by-sub-position-name`, {params})
    }

    getMySoliqUzConract(id) {
        return axios.get(`${this.baseURL}/get-my-soliq-uz-contract/${id}`)
    }

    clearCache(inn) {
        return axios.get(`/roaming-reference/admin/company/invalidate/${inn}`)
    }
    getIkpuCode(ikpuCode) {
        return axios.get(`/roaming-reference/validate-catalog/${ikpuCode}`)
    }

    getRentContracts(params) {
        return axios.get(`${this.baseURL}/get-rent-contracts`, {params: params});
    };

    // company waybill address

    getWaybillRegions() {
        return axios.get(`${this.baseURL}/get-waybill-regions`)
    }

    getWaybillDistricts(regionCode) {
        return axios.get(`${this.baseURL}/get-waybill-districts/${regionCode}`)
    }

    getWaybillVillages(soatoCode) {
        return axios.get(`${this.baseURL}/get-waybill-villages/${soatoCode}`)
    }

    getCompanyAddresses(innOrPinfl) {
        return axios.get(`${this.baseURL}/get-company-addresses/${innOrPinfl}`)
    }

    registerCompanyAddress(payload) {
        return axios.post(`${this.baseURL}/register-company-address`, payload)
    }

    updateCompanyAddress(payload) {
        return axios.post(`${this.baseURL}/update-company-address`, payload)
    }

    deleteCompanyAddress(addressId) {
        return axios.post(`${this.baseURL}/delete-company-address`, {
            id: addressId,
        })
    }

    // system company address
    getSystemOrganizationAddresses() {
        return axios.get(`${this.baseURL}/get-system-company-addresses`)
    }

    registerSystemOrganizationAddresses(payload) {
        return axios.post(`${this.baseURL}/register-system-company-address`, payload)
    }

    deleteSystemOrganizationAddresses(addressId) {
        return axios.post(`${this.baseURL}/delete-system-company-address/${addressId}`)
    }

    // company binding permission
    getCompanyBindings() {
        return axios.get(`${this.baseURL}/get-company-bindings`)
    }

    getCompanyBindingPermissions() {
        return axios.get(`${this.baseURL}/get-company-binding-permissions`)
    }

    updateCompanyBindings(payload) {
        return axios.post(`${this.baseURL}/update-company-bindings`, payload)
    }
}

const roamingReferenceService = new RoamingReferenceService();
export default roamingReferenceService;
