import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../../../common/IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../../common/FalconCloseButton";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {addLocationAsync} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import {toast} from "react-toastify";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {LOCATION_ADD_SUCCESS} from "../../../../../../app/eventbus/contractor/contractorLocationEvents";
import YandexMap from "../../../../../common/map/YandexMap";

const AddContractorLocationForm = ({contractor}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const form = useForm({
        defaultValues: {
            location: {
                latitude: 41.311151,
                longitude: 69.279737,
                address: ''
            },
        }
    });

    const onSubmit = async (formData) => {
        try {
            const payload = {
                contractor_id: contractor.id,
                location: {
                    latitude: formData.location.latitude,
                    longitude: formData.location.longitude,
                    name: formData.location.address
                },
            };

            setLoading(true);
            await addLocationAsync(payload);
            setLoading(false);
            setShow(false);

            EventBus.dispatch(LOCATION_ADD_SUCCESS);
            toast.success(t(lang, 'common.toast.success'));
        } catch (error) {
            toast.error(t(lang, 'common.toast.error'))
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <>
            <IconButton icon={"plus"}
                        size={"sm"}
                        iconAlign={"left"}
                        variant="primary"
                        onClick={() => setShow(true)}
                        transform="shrink-3"
            >
                {t(lang, "crm.contractor.add_location")}
            </IconButton>
            {show &&
                <Modal show={true}
                       centered={true}
                       size="xl"
                       onHide={() => setShow(false)}
                >
                    <FormProvider {...form}>
                        <Form>
                            <Modal.Header>
                                <Modal.Title>{t(lang, 'crm.contractor.add_location')}</Modal.Title>
                                <FalconCloseButton onClick={() => setShow(false)}/>
                            </Modal.Header>
                            <Modal.Body>
                                <Controller name={'location'}
                                            render={({field}) => (
                                                <YandexMap defaultLocation={field.value}
                                                           onChange={field.onChange}
                                                           mapProps={{
                                                               width: '100%',
                                                               height: '500px'
                                                           }}
                                                />
                                            )}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={'secondary'}
                                        onClick={() => setShow(false)}>{t(lang, 'main.common.back')}</Button>
                                <Button variant={'primary'}
                                        disabled={loading}
                                        onClick={form.handleSubmit(onSubmit)}
                                >
                                    {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                    {t(lang, 'main.common.add')}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </FormProvider>
                </Modal>
            }
        </>
    );
};

export default AddContractorLocationForm;