import WriteOffDatatable from "../../../../components/hippo/warehouse-operation/write-off/datatable/WriteOffDatatable";


const WriteOffs = () => {



    return(
        <>
            <WriteOffDatatable />
        </>
    )
}

export default WriteOffs;