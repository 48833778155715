import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useState} from "react";
import classNames from "classnames";

function ContactSourceItem({ value, label, color, className }) {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={classNames(`d-flex flex-column align-items-center ${className}`, {
                'bg-soft-info': isHovered
            })} style={{ padding: '1rem', height: '100%' }}>
            <div className="d-flex align-items-center mb-1">
                <FontAwesomeIcon
                    icon={faSquare}
                    className={`fs-11 me-2 text-${color}`}
                    transform="up-2"
                />
                <span className="fs-9 text-body d-block text-wrap">{label}</span>
            </div>
            <h3 className="fw-semibold mb-0">{value}</h3>
        </div>
    );
}

export default ContactSourceItem;