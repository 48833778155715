import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import {useForm, FormProvider} from "react-hook-form";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {executorDeclineEmpowermentAsync} from "../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import SignActionWithSelectCertificatWrapper from "../components/SignActionWithSelectCertificatWrapper";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingEmpowermentReject} from "../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";


const ExecutorDeclineEmpowerment = ({id, ...props}) => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: {
            comment: '',
        }
    });
    const {register, handleSubmit, reset, formState: {errors}} = methods
    const currentCertificate = useSelector(selectCurrentCertificate);


    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingEmpowermentReject))) setShow(true)
        else dispatch(toastPermission())
    };

    const executorDeclineEmpowermentHandler = (formData) => {
        if(currentCertificate?.keyId) {
            setLoading(true);

            executorDeclineEmpowermentAsync(currentCertificate, {id, comment: formData.comment}, lang)
                .then(response => {
                    handleClose();
                    setLoading(false);
                    reset();
                })
                .catch(errors => {
                    setLoading(false)
                })
        }
    };

    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-danger"
                size="sm"
                iconClassName="me-1"
                icon={'ban'}
                {...props}
            >{t(lang, 'roaming.empowerment.decline_button')}</IconButton>

            <Modal show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={handleSubmit(executorDeclineEmpowermentHandler)} id="ExecutorDeclineEmpowermentForm">
                        <Modal.Header>
                            <Modal.Title>{t(lang, "roaming.empowerment.decline_modal.title")}</Modal.Title>
                            <FalconCloseButton onClick={() => handleClose()}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>{t(lang, "roaming.common.modal.comment")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t(lang, "roaming.common.modal.comment.placeholder")}
                                    isInvalid={errors?.comment}
                                    {...register('comment', {
                                        required: t(lang, 'roaming.validations.is_required'),
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.comment?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                {t(lang, "roaming.empowerment.cancel_button")}
                            </Button>
                            <SignActionWithSelectCertificatWrapper isForm>
                                <Button
                                    variant="danger"
                                    className="d-flex align-items-center"
                                    type="submit"
                                    disabled={loading}
                                    form="ExecutorDeclineEmpowermentForm"
                                >
                                    {loading && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                                    {t(lang, 'roaming.empowerment.decline_button')}
                                </Button>
                            </SignActionWithSelectCertificatWrapper>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    )
};

ExecutorDeclineEmpowerment.propTypes = {
    id: PropTypes.string,
};
ExecutorDeclineEmpowerment.defaultProps = {
    id: "",
};

export default ExecutorDeclineEmpowerment;
