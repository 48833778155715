import React, {useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import EventBus from "../../../../app/eventbus/EventBus";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import PropTypes from "prop-types";
import {deleteItemAsync} from "../../../../app/store/reducers/item/itemReducer";
import {BULK_DELETE_ITEMS_FINISHED, BULK_DELETE_ITEMS_STARTED} from "../../../../app/eventbus/itemEvents";
import ResultCounter from "../forms/bulk-item/ResultCounter";
import {Controller, useForm} from "react-hook-form";
import {getRandomNumber} from "../../../../helpers/utils";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import {ErrorMessage} from "@hookform/error-message";

const BulkDeleteItems = ({items}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useForm();
    const ref = useRef(null);

    const [show, setShow] = useState(false);
    const [code] = useState(getRandomNumber(100000, 999999))

    const onDeleteDraftItems = async (items) => {
        setShow(false);
        ref.current.updateLoadedCount(items.length);
        for (const {item} of items) {
            try {
                EventBus.dispatch(BULK_DELETE_ITEMS_STARTED, item.id);
                await deleteItemAsync(item.id);

                ref.current.incrementSucceedCount();
            } catch (e) {
                ref.current.incrementFailedCount();
            }
        }
        EventBus.dispatch(BULK_DELETE_ITEMS_FINISHED);
    }

    return (
        <React.Fragment>
            <ResultCounter ref={ref}/>
            <IconButton variant="falcon-danger"
                        icon={faTrash}
                        onClick={() => setShow(true)}
                        size="sm"
                        className="m-1"
            >
                {t(lang, 'items.common.delete')}
            </IconButton>
            {show &&
                <Modal show={true} onHide={() => setShow(false)} size="lg">
                    <Form>
                        <Modal.Header>
                            <Modal.Title>
                                {t(lang, "items.common.delete_item")}
                            </Modal.Title>
                            <FalconCloseButton onClick={() => setShow(false)}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>
                                    <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                    <span className="ms-1 text-primary">{code}</span>
                                </Form.Label>
                                <Controller name={'code'}
                                            control={form.control}
                                            rules={{
                                                required: t(lang, 'items.common.validations.is_required'),
                                                validate: (value) => {
                                                    if (+value === code)
                                                        return true;
                                                    return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                                }
                                            }}
                                            render={({field}) => (
                                                <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                        value={field.value}
                                                        htmlRef={field.ref}
                                                        placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                        className={classNames('form-control', {
                                                            'is-invalid': form.formState.errors?.code
                                                        })}
                                                        options={{
                                                            numeral: true,
                                                            delimiter: ' ',
                                                            numeralDecimalScale: 3
                                                        }}
                                                />
                                            )}
                                />
                                <ErrorMessage name={'code'}
                                              errors={form.formState.errors}
                                              as={<Form.Control.Feedback type="invalid"/>}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShow(false)}>
                                {t(lang, "items.common.cancel")}
                            </Button>
                            <Button variant="danger" onClick={form.handleSubmit(() => onDeleteDraftItems(items))}>
                                {t(lang, "items.common.delete")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
        </React.Fragment>
    );
};

export default BulkDeleteItems;

BulkDeleteItems.propTypes = {
    items: PropTypes.array.isRequired
}