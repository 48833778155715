import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {personAcceptEmpowermentAsync,} from "../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import SelectCertificateWrapperComponent from "../components/SelectCertificateWrapperComponent";
import SignActionWithSelectCertificatWrapper from "../components/SignActionWithSelectCertificatWrapper";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingEmpowermentAccept} from "../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";


const PersonAcceptEmpowerment = ({id, ...props}) => {
    const dispatch  = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);


    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingEmpowermentAccept))) setShow(true)
        else dispatch(toastPermission())
    };

    const personAcceptEmpowermentHandler = () => {
        setLoading(true);
        personAcceptEmpowermentAsync(currentCertificate, id, lang)
            .then(response => {
                handleClose();
                setLoading(false);
            })
            .catch(errors => {
                setLoading(false)
            })
    };

    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-primary"
                size="sm"
                iconClassName="me-1"
                icon={'check'}
                {...props}
            >{t(lang, 'roaming.empowerment.approve_button')}</IconButton>

            <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, "roaming.empowerment.accept_modal.title")}</Modal.Title>
                        <FalconCloseButton onClick={() => handleClose()}/>
                    </Modal.Header>
                    <Modal.Body>
                        {t(lang, "roaming.empowerment.accept_modal.body")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "roaming.empowerment.cancel_button")}
                        </Button>
                        <SignActionWithSelectCertificatWrapper>
                            <Button id="acceptButton" variant="primary" className="d-flex align-items-center" onClick={personAcceptEmpowermentHandler} disabled={loading}>
                                {loading && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                                {t(lang, 'roaming.empowerment.approve_button')}
                            </Button>
                        </SignActionWithSelectCertificatWrapper>
                    </Modal.Footer>
            </Modal>
        </>
    )
};

PersonAcceptEmpowerment.propTypes = {
    id: PropTypes.string,
};
PersonAcceptEmpowerment.defaultProps = {
    id: "",
};

export default PersonAcceptEmpowerment;
