import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useFormContext, Controller } from "react-hook-form"
// import SelectMeasurement from "../../components/SelectMeasurement"
import SelectCompanyCatalog from "../../IKPU/SelectCompanyCatalog"
import SelectNdsVatRate from "../../form/SelectNdsVatRate"
import Cleave from 'cleave.js/react';
import { Translate, selectLang } from "../../../../app/store/reducers/main/mainReducer"
import { useSelector } from "react-redux"
import IconButton from "../../../common/IconButton";
import SelectMeasurement from "../../roaming/SelectMeasurement";


const Products = ({ index, removeFieldProduct, onChangeAmounts }) => {
    const { register, watch, getValues, setValue, formState: { errors: productError }, trigger, control } = useFormContext()
    const product = watch(`products.${index}`)
    const errors = productError.products || []
    const { count, summa, vatRate } = product
    const customStyles = {
        measurement: {
            control: base => ({
                ...base,
                minHeight: 34,
                height: 34
            })
        }
    };
    const lang = useSelector(selectLang)
    const t = Translate

    useEffect(() => {
        if(count | summa | vatRate){
            let nCount = +count
            let nSumma = +summa
            let nVatRate = +vatRate
            let deliverySum = nCount * nSumma
            let vatSum = (deliverySum / 100) * nVatRate
            let deliverySumWithVat = deliverySum + vatSum
            deliverySum = deliverySum.toFixed(2)
            vatSum = vatSum.toFixed(2)
            deliverySumWithVat = deliverySumWithVat.toFixed(2)
            setValue(`products.${index}.deliverySum`, deliverySum)
            setValue(`products.${index}.vatSum`, vatSum)
            setValue(`products.${index}.deliverySumWithVat`, deliverySumWithVat)
            onChangeAmounts()
        }
    }, [count, summa, vatRate]);

    const onChangeCatalog = ({class_code, name}) => {
        setValue(`products.${index}.catalogCode`, class_code)
        setValue(`products.${index}.catalogName`, name)
        trigger(`products.${index}.catalogCode`)
    }
    const onChangeVatRate = (data) => {
        if(data === null){
            setValue(`products.${index}.vatRate`, 0)
            setValue(`products.${index}.withoutVat`, true)
        }
        else {
            setValue(`products.${index}.vatRate`, data)
            setValue(`products.${index}.withoutVat`, false)
        }
    }
    
    return (
        <tr>
            <td className="p-1 text-center">
                {index+1}
            </td>
            <td className="p-1">
                <Form.Control 
                    {...register(`products.${index}.name`, {
                        // required: "edi.common.forms.validations.is_required"
                    })}
                    className={`${errors[index]?.name && 'is-invalid'} p-1`}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.name?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller
                    name={`products.${index}.catalogCode`}
                    control={control}
                    render={() => (
                        <SelectCompanyCatalog 
                            variant={errors[index]?.catalogCode ? 'outline-danger' : 'outline-primary'}
                            textButton={product.catalogCode || 'Выбрать'} 
                            defaultCatalogCode={product.catalogCode}
                            onChange={onChangeCatalog} 
                            className={`${errors[index]?.catalogCode && 'is-invalid'} py-1`}
                        />
                    )}
                />                
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.catalogCode?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control 
                    {...register(`products.${index}.barCode`)}
                    className="p-1"
                />
            </td>
            <td className="p-1">
                <Controller 
                    name={`products.${index}.measureId`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <SelectMeasurement
                            onChange={(data) => field.onChange(data.measureId)}
                            placeholder=""
                            defaultMeasureId={field.value}
                            styles={customStyles.measurement}
                            className={`${errors[index]?.measureId && 'is-invalid'}`}
                            menuPortalTarget={document.body}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.measureId?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller 
                    name={`products.${index}.count`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            onChange={({target: {value}}) => field.onChange(value?.replace(/(?!-)[^0-9.]/g, ""))}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            value={field.value}
                            className={`${errors[index]?.count && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.count?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller 
                    name={`products.${index}.summa`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            onChange={({target: {value}}) => field.onChange(value?.replace(/(?!-)[^0-9.]/g, ""))}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            value={field.value}
                            className={`${errors[index]?.summa && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.summa?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller 
                    name={`products.${index}.deliverySum`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            className={`${errors[index]?.deliverySum && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.deliverySum?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller 
                    name={`products.${index}.vatRate`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={() => (
                        <SelectNdsVatRate
                            onChange={(data) => onChangeVatRate(data)}
                            defaultValue={vatRate}
                        />
                    )}
                />
            </td>
            <td className="p-1">
                <Controller 
                    name={`products.${index}.vatSum`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            className={`${errors[index]?.vatSum && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.vatSum?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Controller 
                    name={`products.${index}.deliverySumWithVat`}
                    control={control}
                    rules={{
                        // required: "edi.common.forms.validations.is_required"
                    }}
                    render={({field}) => (
                        <Cleave
                            {...field}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 2
                            }}
                            className={`${errors[index]?.deliverySumWithVat && 'is-invalid'} p-1 form-control`}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {t(lang, errors[index]?.deliverySumWithVat?.message)}
                </Form.Control.Feedback>
            </td>
            <td className="text-end p-1">
                <IconButton
                    onClick={() => removeFieldProduct(index)}
                    disabled={getValues("products").length <= 1}
                    variant="falcon-danger" 
                    icon="trash-alt"
                    size="sm"
                    className="px-2 py-1"
                />
            </td>
        </tr>
    )
}

export default Products