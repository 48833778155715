import React, {useState} from 'react';
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {loadPdfAsync} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import {downloadFile} from "../../../../helpers/excel-uploader";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import IconButton from "../../../common/IconButton";

const RoamingWaybillV2DownloadToPdf = ({waybill, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const [loading, setLoading] = useState(false);

    const onDownloadToPdf = () => {
        setLoading(true);
        loadPdfAsync(waybill.id)
            .then((response) => {
                setLoading(false);
                downloadFile({
                    content: response,
                    contentType: response.type,
                    fileName: `${waybill.info.number}-${dayjs(waybill.info.date).format(dateFormat)}`,
                });
            })
            .catch(error => {
                setLoading(false);
                toast.error(t(lang, "roaming.invoice.print_failed_error"))
            })

    }

    return (
        <IconButton {...props} icon={faFilePdf}
                    transform="shrink-3"
                    size="sm"
                    variant={'falcon-info'}
                    className="m-1"
                    onClick={onDownloadToPdf}
        >
            <span className="d-none d-sm-inline-block">{t(lang, 'edi.contract_formation.view.download_pdf')}</span>
            {loading && <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>}
        </IconButton>
    );
};

export default RoamingWaybillV2DownloadToPdf;
