import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    transferApproveAsync,
} from "../../../../app/store/reducers/warehouse-operation/transferReducer";
import {Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import {Link} from "react-router-dom";
import IconButton from "../../../common/IconButton";
import {
    isApprovedCheckedWarehouseOperation, OperationTotalSum,
} from "../enum/warehouseOperationWrapper";
import React, {useEffect, useState} from "react";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {toast} from "react-toastify";
import WarehouseOperationFooterCurrencyTotalSum from "../components/WarehouseOperationFooterCurrencyTotalSum";
import TransferDatatableTab from "./components/TransferDatatableTab";
import UploadTransferToExcel from "./excel/UploadTransferToExcel";
import TransferPrint from "./components/TransferPrint";
import TransferDownloadPdf from "./components/TransferDownloadPdf";


const Transfer = ({transfer}) => {
    const [activeTab, setActiveTab] = useState("all")
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate



    useEffect(() => {
        if (transfer?.items?.filter(f => !f?.is_deleted).length > 0) {
            setActiveTab("all")
        } else {
            setActiveTab("deleted")
        }
    },[currencies])


    const onApprovedHandler = async () => {
        await transferApproveAsync({transferId: transfer?.id})
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }





    return(
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}><h3 className="text-600">{t(lang, "warehouse.operation.item.common.time_transfer")} {transfer?.date}</h3></Col>
                        <Col xs={6} className="text-end">
                            <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                            <SoftBadge bg={transfer?.is_approved ? 'success' : 'warning'}>
                                {t(lang, isApprovedCheckedWarehouseOperation(transfer?.is_approved))}
                            </SoftBadge>
                        </Col>
                        <Col xs={12}>
                            {!transfer?.is_approved && transfer?.items?.filter(x => !x.is_deleted).length > 0 &&
                                <IconButton
                                    variant="falcon-primary"
                                    icon="layer-group"
                                    className="mx-2"
                                    size="md"
                                    onClick={() => onApprovedHandler()}
                                >{t(lang, "warehouse.operation.item.common.status.approve")}</IconButton>
                            }
                            <Link to={`../update/${transfer?.id}`}>
                                <IconButton
                                    variant="falcon-warning"
                                    icon="edit"
                                    title={t(lang,"warehouse.operation.item.common.edit")}
                                    className="ms-3"
                                    size="sm"
                                >{t(lang,"warehouse.operation.item.common.edit")}</IconButton>
                            </Link>
                            <Link to={`../update-excel/${transfer?.id}`}>
                                <IconButton
                                    variant="falcon-success"
                                    icon="edit"
                                    title={t(lang,"warehouse.operation.item.common.edit")}
                                    className="mx-2"
                                    size="sm"
                                >{t(lang,"Редактировать с Excel")}</IconButton>
                            </Link>
                            <UploadTransferToExcel className="me-2 d-inline-block" size="sm" id={transfer?.id} number={transfer?.number} />
                            <Link to={`../register`}>
                                <IconButton
                                    variant="falcon-primary"
                                    icon="plus"
                                    title={t(lang,"Новый трансфер")}
                                    className="ms-2"
                                    size="sm"
                                >{t(lang,"Новый трансфер")}</IconButton>
                            </Link>
                            <TransferPrint
                                transferId={transfer?.id}
                            />
                            <TransferDownloadPdf
                                transferId={transfer?.id}
                                number={transfer?.number}
                            />
                        </Col>
                    </Row>
                </Card.Header>
            </Card>

            <Row>
                <Col xs={12} className="mt-3">
                    <Card>
                        <Card.Body>
                            {transfer?.note &&
                                <Col xs={12}>
                                    <p className="fw-bold m-0">Описание: <span className="fw-medium">{transfer?.note}</span> </p>
                                </Col>
                            }
                            <Tabs activeKey={activeTab} id="controlled-tab-example" className="d-flex justify-content-center fs-1" onSelect={(tab) => {
                                setActiveTab(tab)
                            }}>
                                {transfer?.items?.filter(f => !f?.is_deleted).length > 0 &&
                                    <Tab eventKey="all" tabClassName="fs-1" title={t(lang, 'Списки')}>
                                        {activeTab === 'all' &&
                                            <TransferDatatableTab
                                                dataItems={transfer?.items?.filter(f => !f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                                {transfer?.items?.filter(f => f.is_deleted).length > 0 &&
                                    <Tab eventKey="deleted" tabClassName="fs-1" title={t(lang, 'Удалённые из списка')}>
                                        {activeTab === 'deleted' &&
                                            <TransferDatatableTab
                                                dataItems={transfer?.items?.filter(f => f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                            </Tabs>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                {activeTab === "all" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPriceType={"all"}
                                        netPrice={OperationTotalSum(transfer?.items?.filter(f => !f?.is_deleted))}
                                    />
                                }
                                {activeTab === "deleted" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPriceType={"deleted"}
                                        netPrice={OperationTotalSum(transfer?.items?.filter(f => f?.is_deleted))}
                                    />
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default Transfer;