export const REGISTER_RETURN_INVOICE_REQUESTED = "REGISTER_RETURN_INVOICE_REQUESTED";
export const REGISTER_RETURN_INVOICE_SUCCESS = "REGISTER_RETURN_INVOICE_SUCCESS";
export const REGISTER_RETURN_INVOICE_FAILED = "REGISTER_RETURN_INVOICE_FAILED";
export const DELETE_RETURN_INVOICE_REQUESTED = "DELETE_RETURN_INVOICE_REQUESTED";
export const DELETE_RETURN_INVOICE_SUCCESS = "DELETE_RETURN_INVOICE_SUCCESS";
export const DELETE_RETURN_INVOICE_FAILED = "DELETE_RETURN_INVOICE_FAILED";
export const CANCEL_RETURN_INVOICE_REQUESTED = 'CANCEL_RETURN_INVOICE_REQUESTED';
export const CANCEL_RETURN_INVOICE_SUCCESS = 'CANCEL_RETURN_INVOICE_SUCCESS';
export const CANCEL_RETURN_INVOICE_FAILED = 'CANCEL_RETURN_INVOICE_FAILED';
export const DECLINE_RETURN_INVOICE_REQUESTED = 'DECLINE_RETURN_INVOICE_REQUESTED';
export const DECLINE_RETURN_INVOICE_SUCCESS = 'DECLINE_RETURN_INVOICE_SUCCESS';
export const DECLINE_RETURN_INVOICE_FAILED = 'DECLINE_RETURN_INVOICE_FAILED';
export const SIGN_RETURN_INVOICE_REQUESTED = 'SIGN_RETURN_INVOICE_REQUESTED';
export const SIGN_RETURN_INVOICE_SUCCESS = 'SIGN_RETURN_INVOICE_SUCCESS';
export const SIGN_RETURN_INVOICE_FAILED = 'SIGN_RETURN_INVOICE_FAILED';