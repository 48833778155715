import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import FreeFormDocument from "../../../../components/roaming/freeform/view/FreeFormDocument";
import {loadFreeFormDocumentAsync} from "../../../../app/store/reducers/roaming/roamingFreeFormDocumentReducer";
import {
    ACCEPT_FREE_FORM_DOCUMENT_SUCCEED,
    CANCEL_FREE_FORM_DOCUMENT_SUCCEED, DECLINE_FREE_FORM_DOCUMENT_SUCCEED,
    SIGN_FREE_FORM_DOCUMENT_SUCCEED
} from "../../../../app/eventbus/roaming/roamingFreeFormDocumentEvents";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";

const ViewFreeFormDocument = ({modalId}) => {
    const {params: {id}} = useRouteMatch();
    const [freeFormDocument, setFreeFormDocument] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);

    useEffect(() => {
        loadFreeFormDocument();
    }, [modalId,id, activeOrganization])

    useEffect(() => {
        const onSignFreeFormDocumentSucceedHandler = EventBus.on(SIGN_FREE_FORM_DOCUMENT_SUCCEED, () => {
            toast.success("Success");
            loadFreeFormDocument();
        });

        const onCancelFreeFormDocumentSucceedHandler = EventBus.on(CANCEL_FREE_FORM_DOCUMENT_SUCCEED, () => {
            toast.success("Success");
            loadFreeFormDocument();
        });

        const onAcceptFreeFormDocumentSucceedHandler = EventBus.on(ACCEPT_FREE_FORM_DOCUMENT_SUCCEED, () => {
            toast.success("Success");
            loadFreeFormDocument();
        });

        const onDeclineFreeFormDocumentSucceedHandler = EventBus.on(DECLINE_FREE_FORM_DOCUMENT_SUCCEED, () => {
            toast.success("Success");
            loadFreeFormDocument();
        });

        return () => {
            EventBus.remove(SIGN_FREE_FORM_DOCUMENT_SUCCEED, onSignFreeFormDocumentSucceedHandler);
            EventBus.remove(CANCEL_FREE_FORM_DOCUMENT_SUCCEED, onCancelFreeFormDocumentSucceedHandler);
            EventBus.remove(ACCEPT_FREE_FORM_DOCUMENT_SUCCEED, onAcceptFreeFormDocumentSucceedHandler);
            EventBus.remove(DECLINE_FREE_FORM_DOCUMENT_SUCCEED, onDeclineFreeFormDocumentSucceedHandler);
        }
    }, [])

    const loadFreeFormDocument = () => {
        loadFreeFormDocumentAsync(modalId ? modalId : id)
            .then(loadedDocument => {
                setFreeFormDocument(loadedDocument)
            })
            .catch(error => console.log(error))
    }

    return (
        <>
            {!freeFormDocument && <SpinnerComponent />}
            {freeFormDocument && <FreeFormDocument document={freeFormDocument}/>}
        </>
    );
};

export default ViewFreeFormDocument;
