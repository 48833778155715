import React, {Fragment, useEffect} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {selectOrganization, updateOrganizationAsync} from "../../../app/store/reducers/draft-item/draftItemReducer";
import EventBus from "../../../app/eventbus/EventBus";
import {CHANGE_SETTINGS_SUCCESS} from "../../../app/eventbus/itemEvents";
import {Controller, useForm} from "react-hook-form";
import ReactSelect from "react-select";
import {taxRateOptions} from "../../../components/hippo/item/enum/itemWrapper";
import {toast} from "react-toastify";
import UpdateTaxItemsModal from "./UpdateTaxItemsModal";

const Settings = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const organization = useSelector(selectOrganization);
    const [taxRateModal, setTaxRateModal] = React.useState(false);

    const form = useForm({
        defaultValues: {
            trackEdiOrder: false,
            trackRoamingInvoice: false,
            defaultTaxRate: organization?.default_tax_rate?.toString() || null
        }
    });

    useEffect(() => {
        form.setValue('trackEdiOrder', organization?.track_edi_order || false);
        form.setValue('trackRoamingInvoice', organization?.track_roaming_invoice || false);
        form.setValue('defaultTaxRate', (organization && organization.default_tax_rate !== null) ? organization.default_tax_rate?.toString() : "");
    }, [organization])

    const onSubmit = (formData) => {
        updateOrganizationAsync({
            track_edi_order: formData.trackEdiOrder,
            track_roaming_invoice: formData.trackRoamingInvoice,
            default_tax_rate: (formData.defaultTaxRate !== null && formData.defaultTaxRate !== "") ? +formData.defaultTaxRate : null
        })
            .then(() => {
                toast.success(t(lang, 'common.toast.success'));
                EventBus.dispatch(CHANGE_SETTINGS_SUCCESS)
                let taxRate = organization.default_tax_rate !== null ? organization.default_tax_rate.toString() : "";
                if (formData.defaultTaxRate !== taxRate)
                    setTaxRateModal(true);
            })
            .catch(() => {
                toast.error(t(lang, 'common.toast.error'));
            })
    }

    if (organization == null)
        return null;

    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <Card.Title>{t(lang, 'items.common.settings')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Switch name="trackEdiOrder"
                                         label={t(lang, 'items.common.settings.track_edi_orders')}
                                         {...form.register('trackEdiOrder')}
                            />
                        </Col>
                        <Col xs={12}>
                            <Form.Switch name="trackRoamingInvoice"
                                         label={t(lang, 'items.common.settings.track_roaming_invoices')}
                                         {...form.register('trackRoamingInvoice')}
                            />
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col lg={2}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, 'items.common.tax_rate_link_selector_modal.title')}</Form.Label>
                                        <Controller name={'defaultTaxRate'}
                                                    control={form.control}
                                                    render={({field}) => (
                                                        <ReactSelect options={taxRateOptions}
                                                                     hideSelectedOptions
                                                                     classNamePrefix="react-select"
                                                                     placeholder={t(lang, "items.common.select")}
                                                                     getOptionLabel={option => t(lang, option.label)}
                                                                     getOptionValue={option => option.value}
                                                                     isSearchable={false}
                                                                     defaultMenuIsOpen={false}
                                                                     value={taxRateOptions.find(o => o.value === field.value)}
                                                                     onChange={(option) => field.onChange(option.value)}
                                                        />
                                                    )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} className="mt-3">
                            <Button variant={"primary"}
                                    onClick={form.handleSubmit(onSubmit)}>{t(lang, 'items.common.save')}</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <UpdateTaxItemsModal show={taxRateModal} setShow={setTaxRateModal} />

        </Fragment>
    );
};

export default Settings;
