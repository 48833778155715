import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useFieldArray, useFormContext} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import IconButton from "../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {ErrorMessage} from "@hookform/error-message";
import classNames from "classnames";

const PackageMeasurementRow = ({fieldName}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const {fields, append, remove} = useFieldArray({name: fieldName})

    const addNewPackageMeasurement = () => append({name: "", quantity: ""});
    const deletePackageMeasurement = (index) => remove(index);

    return (
        <React.Fragment>
            <h6 className="text-info cursor-pointer d-inline-block" onClick={addNewPackageMeasurement}>{t(lang, 'items.common.add_package_measurement')}</h6>
            <Row className="gy-3">
                {fields.map((field, index) => {
                    const {error} = form.getFieldState(`${fieldName}.${index}`);
                    return (
                        <React.Fragment key={field.id}>
                            <Col lg={5}>
                                <Form.Group>
                                    {index === 0 && <Form.Label>
                                        {t(lang, 'items.common.package_measurement.name')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>}
                                    <Form.Control  name={`${fieldName}.${index}.name`}
                                                   placeholder={t(lang, 'items.common.package_measurement.name.placeholder')}
                                                   isInvalid={error?.name}
                                                   {...form.register(`${fieldName}.${index}.name`, {
                                                       required: t(lang, 'items.common.validations.is_required')
                                                   })}
                                    />
                                    <ErrorMessage name='name'
                                                  errors={error}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group>
                                    {index === 0 && <Form.Label>
                                        {t(lang, 'items.common.package_measurement.quantity')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>}
                                    <Form.Control name={`${fieldName}.${index}.quantity`}
                                                  isInvalid={error?.quantity}
                                                  placeholder={t(lang, 'items.common.package_measurement.quantity.placeholder')}
                                                  {...form.register(`${fieldName}.${index}.quantity`, {
                                                      required: t(lang, 'items.common.validations.is_required')
                                                  })}
                                    />
                                    <ErrorMessage name='quantity'
                                                  errors={error}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group>
                                    <IconButton variant="falcon-default"
                                                icon={faTrash}
                                                iconClassName={"text-danger"}
                                                className={classNames({'mt-4': index === 0})}
                                                onClick={() => deletePackageMeasurement(index)}
                                    />
                                </Form.Group>
                            </Col>
                        </React.Fragment>
                    )
                })}
            </Row>
        </React.Fragment>
    );
};

export default PackageMeasurementRow;