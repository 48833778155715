import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {selectOrganizationActivePackages} from "../../app/store/reducers/billing/billingReducer";
import IconAlert from "./IconAlert";
import dayjs from "dayjs";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {Link} from "react-router-dom";
import {selectActiveOrganization} from "../../app/store/reducers/auth/authReducer";

let end_billing_package = localStorage.getItem('end_billing_package') || null

const EndBillingPackageAlert = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const activePackages = useSelector(selectOrganizationActivePackages);
    const activeOrganization = useSelector(selectActiveOrganization);
    const [show, setShow] = useState(false);

    const packages =  useMemo(() => {
        return  activePackages
            ?.filter(item => item.package.price > 0)
            .map(item => {
                return {...item, endDay: Math.floor((+dayjs(item?.expires_at) - dayjs()) / (1000 * 60 * 60 * 24))}
            })
    }, [activePackages, activeOrganization])

    const day = useMemo(() => {
        return  packages && packages?.map(item => item.endDay)
    }, [packages])

    useEffect(() => {
        let minDay = day?.length && !!(Math.min(...day) <= 7 && Math.min(...day) >= 0)
        if( minDay ) {
            if (end_billing_package) {
               if (+end_billing_package <= +dayjs()) {
                   setShow(true)
               }
            } else {
               setShow(true)
            }
        } else {
            setShow(false)
        }
    }, [day])

    const onHide = () => {
        localStorage.setItem('end_billing_package', JSON.stringify(+dayjs() + (60 * 60 * 24 * 1000)))
        setShow(false)
    }

    return (
        <Fragment>
            {!!show && <IconAlert variant={'warning'} dismissible onClose={onHide}>
                {packages.map((item => <div key={item.id}>
                    {
                        item.endDay === 0 ?
                            <p className={'mb-0'}>
                                {t(lang, "main.end.billing.package.today", {
                                    packageName: item?.package.name,
                                    day: dayjs(item.expires_at).format("HH:mm")
                                })}
                            </p> :
                            item.endDay <= 7 ?
                            <p className={'mb-0'}>
                                {t(lang, "main.end.billing.package", {
                                    packageName: item?.package.name,
                                    day: item.endDay + " " + t(lang, item.endDay > 1 ? "main.end.billing.package.days" : "main.end.billing.package.day")
                                })}
                            </p> : ""
                    }
                </div>))}
                <hr className="my-1" />
                <div className="d-flex justify-content-end">
                    <Link to="/user/billing" className="me-5" >{t(lang, 'main.billing.all_packages_card.title')}</Link>
                </div>
            </IconAlert>}
        </Fragment>
    );
};

export default EndBillingPackageAlert;
