import React, {Fragment} from 'react';
import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import EimzoSignerSelector from "./EimzoSignerSelector";
import classNames from "classnames";

const EImzoSignerRegisterForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, control, formState: {errors}, setValue} = useFormContext();
    const onChangeCompany = (company) => {
        setValue("inn_or_pinfl", company.inn || company.person_num || '')
        setValue("name", company.name)
    };
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>{t(lang,"admin.e-imzo.modal.host.title")}</Form.Label>
                <Form.Control
                    type={'text'}
                    placeholder={t(lang,"admin.e-imzo.modal.host.placeholder")}
                    {...register('host', {
                        required: t(lang, "edi.common.forms.validations.is_required")
                    })}
                />
                <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                    {errors?.host?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t(lang,"admin.e-imzo.modal.name.inn_or_pinfl.title")}</Form.Label>
                <Controller name="executor.inn"
                            control={control}
                            render={({field}) => (
                                <EimzoSignerSelector onChange={(company) => {
                                    onChangeCompany(company);
                                    field.onChange(company?.inn);
                                }}
                                                     inputProps={{
                                                         isInvalid: errors?.executor?.inn,
                                                         isValid: Object.keys(errors).length > 0 && !errors?.executor?.inn,
                                                         placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                                     }}
                                                     inputGroupProps={{
                                                         className: classNames({
                                                             'is-invalid': errors?.executor?.inn,
                                                             'is-valid': Object.keys(errors).length > 0 && !errors?.executor?.inn
                                                         }),
                                                     }}
                                                     ref={field.ref}
                                />
                            )}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required"),
                            }}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.executor?.inn?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </Fragment>
    );
};

export default EImzoSignerRegisterForm;
