import classNames from "classnames";
import {useSelector} from "react-redux";
import ResultCounter from "./ResultCounter";
import {Button, Form, Modal} from "react-bootstrap";
import IconButton from "../../../common/IconButton";
import {Controller, useForm} from "react-hook-form";
import React, {Fragment, useRef, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import ItemLegalTypeSelector from "./ItemLegalTypeSelector";
import {faListCheck} from "@fortawesome/free-solid-svg-icons";
import {editItemAsync} from "../../../../app/store/reducers/item/itemReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {BULK_EDIT_ITEMS_FINISHED, BULK_EDIT_ITEMS_STARTED} from "../../../../app/eventbus/itemEvents";

const BulkEditItemLegalType = ({items}) => {
    const [show, setShow] = useState(false);
    const {handleSubmit, control, reset, formState: {errors}} = useForm({
        defaultValues: {
            legalType: null,
        }
    })
    const lang = useSelector(selectLang);
    const ref = useRef(null);
    const t = Translate;

    const handleShow = () => setShow(true);
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const onSubmit = async ({legalType}) => {
        handleClose();

        ref.current.updateLoadedCount(items.length);
        EventBus.dispatch(BULK_EDIT_ITEMS_STARTED);
        for (let i = 0; i < items.length; i++) {
            try {
                const {item} = items[i];
                const itemJson = {
                    ...item,
                    measurement: item.measurement.toString(),
                    legal_type: legalType,
                }
                await editItemAsync(item.id, itemJson);
                ref.current.incrementSucceedCount();
            } catch (e) {
                console.log(e);
                ref.current.incrementFailedCount();
            }
        }
        EventBus.dispatch(BULK_EDIT_ITEMS_FINISHED);
    }

    return (
        <Fragment>
            <ResultCounter ref={ref}/>

            <IconButton variant={'falcon-primary'}
                        icon={faListCheck}
                        size="sm"
                        className="m-1"
                        onClick={handleShow}
            >
                {t(lang, 'items.common.bulk_edit_item_legal_type')}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'items.common.bulk_edit_item_legal_type')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Controller
                                control={control}
                                name={'legalType'}
                                rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                render={({field}) => (
                                    <ItemLegalTypeSelector
                                        legalType={field.value}
                                        onChange={field.onChange}
                                        selectorProps={{
                                            placeholder: t(lang, 'items.common.select'),
                                            className: classNames({'is-invalid': errors?.legalType}),
                                            wrapperClassName: classNames({'is-invalid': errors?.legalType}),
                                        }}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type={'invalid'}>
                                {errors?.legalType?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'main.common.back')}</Button>
                        <Button variant={'primary'} type={'submit'}>{t(lang, 'edi.common.button.save')}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default BulkEditItemLegalType;