import React, {useCallback, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Dropdown, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    DELETE_CATEGORY_FAILED,
    DELETE_CATEGORY_REQUESTED,
    DELETE_CATEGORY_SUCCESS
} from "../../../../app/eventbus/itemEvents";
import {deleteCategoryAsync} from "../../../../app/store/reducers/category/categoryReducer";

const DeleteCategory = ({category}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const onDeleteCategory = useCallback(() => {
        EventBus.dispatch(DELETE_CATEGORY_REQUESTED);
        setLoading(true);
        deleteCategoryAsync(category.id)
            .then((category) => {
                EventBus.dispatch(DELETE_CATEGORY_SUCCESS, category.id)
                setLoading(false);
                setShow(false);
            })
            .catch((error) => {
                setLoading(false);
                EventBus.dispatch(DELETE_CATEGORY_FAILED);
            })
    }, [category])

    return (
        <React.Fragment>
            <Dropdown.Item onClick={() => setShow(true)} className="text-danger">{t(lang, 'items.common.delete')}</Dropdown.Item>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.common.delete_category_modal.title', {name: category.name})}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, 'items.common.delete_category_modal.description')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        {t(lang, "items.common.cancel")}
                    </Button>
                    <Button variant="danger" onClick={onDeleteCategory} disabled={loading}>
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                        }
                        {t(lang, "items.common.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default DeleteCategory;