import {Card, Col, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useRouteMatch} from "react-router-dom";
import {
    loadLeadAsync,
    selectCardLead,
    updateCardLead
} from "../../../../app/store/reducers/contractor/contractorLeadReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import LeadNote from "../../../../components/hippo/crm/contractor/lead/LeadNote";

const LeadCard = () => {
    const lang = useSelector(selectLang);
    const lead = useSelector(selectCardLead)

    const t = Translate;
    const {params: {id}} = useRouteMatch();
    const dispatch = useDispatch()

    let phones = []
    let emails = []
    if (lead) {
        phones = lead.contacts.filter(contact => contact.type === 1)
        emails = lead.contacts.filter(contact => contact.type === 3)
    }
    const loadLead = () => {
        loadLeadAsync(id).then(res => {
            dispatch(updateCardLead(res))
        }).catch(err => console.log(err));
    }
    useEffect(() => {
        loadLead()
    }, [id]);

    return (<>
        <Row>
            <Col xs={12} lg={6}>
                <Card className={"mb-3"}>
                    <Card.Body className={"d-flex justify-content-between"}>
                        <h4>{lead?.name}</h4>
                    </Card.Body>
                </Card>
                <Card className={"my-2"}>
                    <Card.Body>
                        <div className={"d-flex align-items-center justify-content-between border-bottom pb-3"}>
                            <div className={"d-flex flex-column me-3"}>
                                <h6 className={"text-secondary"}>{t(lang, "edi.common.inn")}</h6>
                                <h6>{lead?.inn ? lead?.inn : t(lang, "edi.common.not_specified")}</h6>
                            </div>
                            <div className={"d-flex flex-column me-3"}>
                                <h6 className={"text-secondary"}>{t(lang, "edi.contractor_card.general.type")}</h6>
                                {
                                    lead?.type?.length > 0 ?
                                        lead?.type.map((item, index) => {
                                            if (item === 1) {
                                                return (
                                                    <h6 key={index}>{t(lang, "edi.common.buyer")}</h6>
                                                )
                                            }
                                            if (item === 2) {
                                                return (
                                                    <h6 key={index}>{t(lang, "edi.common.executor")}</h6>
                                                )
                                            }
                                        }) : <h6>{t(lang, "edi.common.not_specified")}</h6>
                                }
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <div className={"mb-3"}>
                            <div className={"text-secondary h6"}>{t(lang, "roaming.contract.company.info.phone")}</div>
                            {phones.length > 0 ? phones.map((ph, index) => {
                                return (<div className={"h6 text-info"} key={index}>{ph.value}</div>)
                            }) : <div className={"h6"}>{t(lang, "edi.common.not_specified")}</div>}
                        </div>
                        <hr/>
                        <div>
                            <div className={"text-secondary h6"}>{t(lang, "edi.contractor_card.general.email")}</div>
                            {emails.length > 0 ? emails.map((ph, index) => {
                                return (<div className={"h6 text-info"} key={index}>{ph.value}</div>)
                            }) : <div className={"h6"}>{t(lang, "edi.common.not_specified")}</div>}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} lg={6}>
                <Card>
                    <Card.Body>
                        <LeadNote/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>)
}
export default LeadCard;