import {Col, Row} from "react-bootstrap";
import React from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import DataTableSettings from "../DataTableSettings";
import {
    changeContractFormationDataTableColumn,
    selectContractFormationDataTable,
} from "../../../app/store/reducers/contract-formation/contractFormationReducer";


const ContactFormationDataTableHeader = () => {
    const allColumns = useSelector(selectContractFormationDataTable)
    const lang = useSelector(selectLang);
    const t = Translate;



    return(
        <>
            <Row className="d-flex justify-content-between">
                <Col xs={4} sm="auto" className="d-flex justify-content-between align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.navbar.routes.contract_formation")}</h5>
                </Col>
            </Row>
        </>
    )
}

export default ContactFormationDataTableHeader;
