import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import PurchaseDatatable from "../../../../components/hippo/warehouse-operation/purchase/datatable/PurchaseDatatable";
import {WAREHOUSE_OPERATION_PURCHASE_DELETE_SUCCESS} from "../../../../app/eventbus/warehouse-operation/purchaseEvents";
import {getPurchasesAsync, getPurchasesCountAsync, selectPurchaseFilterOptions} from "../../../../app/store/reducers/warehouse-operation/purchaseReducer";


const Purchases = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectPurchaseFilterOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        loadPurchases();

        const onPurchaseDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_SUCCESS, () => {
            loadPurchases();
        });

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_SUCCESS, onPurchaseDeletedHandler)
        };
    }, [activeOrganization, filters])

    const loadPurchases = () => {
        dispatch(getPurchasesAsync({...filters}));
        dispatch(getPurchasesCountAsync({...filters}));
    }

    return <PurchaseDatatable loadPurchases={loadPurchases}/>
}

export default Purchases;
