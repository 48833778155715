export const ADD_EMPLOYEE_REQUESTED = "ADD_EMPLOYEE_REQUESTED";
export const ADD_EMPLOYEE_SUCCEED = "ADD_EMPLOYEE_SUCCEED";
export const ADD_EMPLOYEE_FAILED = "ADD_EMPLOYEE_FAILED";

export const EDIT_EMPLOYEE_REQUESTED = "EDIT_EMPLOYEE_REQUESTED";
export const EDIT_EMPLOYEE_SUCCEED = "EDIT_EMPLOYEE_SUCCEED";
export const EDIT_EMPLOYEE_FAILED = "EDIT_EMPLOYEE_FAILED";

export const DELETE_EMPLOYEE_REQUESTED = "DELETE_EMPLOYEE_REQUESTED";
export const DELETE_EMPLOYEE_SUCCEED = "DELETE_EMPLOYEE_SUCCEED";
export const DELETE_EMPLOYEE_FAILED = "DELETE_EMPLOYEE_FAILED";