import {Card, Tab, Tabs} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import ContractorOrdersCard from "../../../../components/hippo/crm/contractor/card/order/ContractorOrdersCard";
import {useEffect, useState} from "react";
import {
    loadContractorAsync,
    selectCardContractor, updateCardContractor
} from "../../../../app/store/reducers/contractor/contractorReducer";
import ContractorGeneralDetails
    from "../../../../components/hippo/crm/contractor/card/general/ContractorGeneralDetails";
import ContractorInvoices from "../../../../components/hippo/crm/contractor/card/invoice/ContractorInvoices";

import ContractorShipmentsCard from "../../../../components/hippo/crm/contractor/card/shipment/ContractorShipmentsCard";
import ContractorContracts from "../../../../components/hippo/crm/contractor/card/contract/ContractorContracts";

import ContractorAttorneys from "../../../../components/hippo/crm/contractor/card/attorney/ContractorAttorneys";
import {
    clearFilterInvoice,
    selectContractorInvoiceFilterOptions,
} from "../../../../app/store/reducers/contractor/contractorInvoiceReducer";
import {useLocation} from "react-router";
import {useHistory, useRouteMatch} from "react-router-dom";
import ContractorRefunds from "../../../../components/hippo/crm/contractor/card/refund-operation/ContractorRefunds";
import ContractorReturnPurchases
    from "../../../../components/hippo/crm/contractor/card/return-purchase-operation/ContractorReturnPurchases";
import ContractorOperations from "./ContractorOperations";
import ContractorOtherInfo from "../../../../components/hippo/crm/contractor/card/other/ContractorOtherInfo";
import EventBus from "../../../../app/eventbus/EventBus";
import {CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED} from "../../../../app/eventbus/contractor/contractorEvents";
import ContractorActReports from "../../../../components/hippo/crm/contractor/card/act/ContractorActReports";
import {
    ADD_CONTRACTOR_PAYMENT_SUCCESS,
    ADD_CONTRACTOR_PAYOUT_SUCCESS
} from "../../../../app/eventbus/contractor/contractorPaymentEvents";
import ContractorSales from "../../../../components/hippo/crm/contractor/card/sale/ContractorSales";
import ContractorPurchases from "../../../../components/hippo/crm/contractor/card/purchase/ContractorPurchases";
import ContractorTelegramOrders
    from "../../../../components/hippo/crm/contractor/card/telegram-order/ContractorTelegramOrders";

const ContractorCard = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const contractor = useSelector(selectCardContractor);
    const [activeTab, setActiveTab] = useState('general')
    const invoiceFilter = useSelector(selectContractorInvoiceFilterOptions)
    const {params: {id}} = useRouteMatch();
    const routerHistory = useHistory();
    const dispatch = useDispatch()

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    let activeTabFromRoute = queryParameters.get("activeTab");

    useEffect(() => {
        loadCardContractor();

        const onAddContractorPaymentSucceed = EventBus.on(ADD_CONTRACTOR_PAYMENT_SUCCESS, () => {
            loadCardContractor();
        });

        const onAddContractorPayoutSucceed = EventBus.on(ADD_CONTRACTOR_PAYOUT_SUCCESS, () => {
            loadCardContractor();
        });

        return () => {
            EventBus.remove(ADD_CONTRACTOR_PAYMENT_SUCCESS, onAddContractorPaymentSucceed);
            EventBus.remove(ADD_CONTRACTOR_PAYOUT_SUCCESS, onAddContractorPayoutSucceed);
        };
    }, [id])

    useEffect(() => {
        if (activeTab !== 'invoices') {
            dispatch(clearFilterInvoice({
                page: invoiceFilter.page,
                limit: invoiceFilter.limit,
                status: invoiceFilter.status
            }))
        }
    }, [activeTab])

    useEffect(() => {
        if (activeTabFromRoute)
            setActiveTab(activeTabFromRoute)
    }, [activeTabFromRoute])

    useEffect(() => {
        if (invoiceFilter?.contract_number && activeTab !== 'invoices') {
            setActiveTab('invoices')
            routerHistory.push(`../contractor-card/${contractor?.id}?activeTab=invoices`)
        }
    }, [invoiceFilter?.contract_number]);

    useEffect(() => {
        const onContractorStartingDebtChanged = EventBus.on(CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED, (contractor) => {
            setActiveTab('general');
        })

        return () => {
            EventBus.remove(CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED, onContractorStartingDebtChanged);
        }
    }, [])

    const loadCardContractor = () => {
        loadContractorAsync(id)
            .then((contractor) => {
                dispatch(updateCardContractor(contractor));
            })
            .catch((error) => {
                console.log(error);
                dispatch(updateCardContractor(null));
            });
    }

    if (!contractor) return null;

    return (<>
        <Card className={"mb-3"}>
            <Card.Body className={"d-flex justify-content-between"}>
                <h4>{contractor?.name}</h4>
            </Card.Body>
        </Card>
        <Card>
            <Card.Body>
                <Tabs activeKey={activeTab} id="controlled-tab-example" onSelect={(tab) => {
                    routerHistory.push(`../contractor-card/${contractor?.id}?activeTab=${tab}`)
                    setActiveTab(tab)
                }}>
                    <Tab eventKey="general" title={t(lang, 'edi.contractor.card.general.tab.title')}>
                        {activeTab === 'general' && <ContractorGeneralDetails/>}
                    </Tab>
                    <Tab eventKey="telegram-orders" title={t(lang, 'edi.contractor.card.telegram_orders.tab.title')}>
                        <ContractorTelegramOrders contractorId={contractor?.id}/>
                    </Tab>

                    <Tab eventKey={'sales'} title={t(lang, 'edi.contractor.card.sales.tab.title')}>
                        <ContractorSales contractorId={contractor?.id}/>
                    </Tab>
                    <Tab eventKey={'purchases'} title={t(lang, 'edi.contractor.card.purchases.tab.title')}>
                        <ContractorPurchases contractorId={contractor?.id}/>
                    </Tab>

                    <Tab eventKey="refunds" title={t(lang, 'warehouse.operation.item.common.list_refund')}
                         onSelect={() => setActiveTab("refunds")}>
                        {activeTab === 'refunds' && <ContractorRefunds/>}
                    </Tab>
                    <Tab eventKey="return-purchase"
                         title={t(lang, 'warehouse.operation.item.common.list_return_purchase')}
                         onSelect={() => setActiveTab("return-purchase")}>
                        {activeTab === 'return-purchase' && <ContractorReturnPurchases/>}
                    </Tab>
                    <Tab eventKey="operations" title={t(lang, 'edi.contractor.card.operations.tab.title')}
                         onSelect={() => setActiveTab("operations")}>
                        {activeTab === 'operations' && <ContractorOperations/>}
                    </Tab>
                    <Tab eventKey={"act-report"} title={t(lang, 'crm.contractor.view.tab.act_report.title')}
                         onSelect={() => setActiveTab("act-report")}>
                        {activeTab === "act-report" && <ContractorActReports/>}
                    </Tab>
                    <Tab eventKey="contracts" title={t(lang, 'edi.dashboard.contracts.title')}
                         onSelect={() => {
                             setActiveTab("contracts")
                         }}>
                        {activeTab === 'contracts' && <ContractorContracts/>}
                    </Tab>
                    <Tab eventKey="orders" title={t(lang, 'edi.dashboard.orders.title')}
                         onSelect={() => setActiveTab("orders")}>
                        {activeTab === 'orders' && <ContractorOrdersCard/>}
                    </Tab>
                    <Tab eventKey="shipments" title={t(lang, 'edi.shipment.datatable.header.title')}
                         onSelect={() => setActiveTab("shipments")}>
                        {activeTab === 'shipments' && <ContractorShipmentsCard/>}
                    </Tab>
                    <Tab eventKey="invoices" title={t(lang, 'edi.dashboard.invoices.title')}
                         onSelect={() => setActiveTab("invoices")}>
                        {activeTab === 'invoices' && <ContractorInvoices/>}
                    </Tab>
                    <Tab eventKey="attorneys" title={t(lang, 'edi.attorney.datatable.header.title')}
                         onSelect={() => setActiveTab("attorneys")}>
                        {activeTab === 'attorneys' && <ContractorAttorneys/>}
                    </Tab>
                    <Tab eventKey={'other'} title={t(lang, 'crm.contractor.view.tab.other')}
                         onSelect={() => setActiveTab("other")}>
                        {activeTab === 'other' && <ContractorOtherInfo/>}
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    </>)
}
export default ContractorCard;
