import EventBus from "../eventbus/EventBus";
import {
    ACCOUNT_PERMISSION_UPDATE,
    PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_REGISTER, PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_UNREGISTER,
    PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_DISABLED, PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_ENABLED,
    PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED, SETTINGS_ORGANIZATION_ACCOUNT_UPDATE
} from "../eventbus/contractor/contractor-push-update-events";
import {PUSH_UPDATE_ITEM_STATE_UPDATED} from "../eventbus/itemEvents";
import axios from "axios";
import {
    ACCOUNT_CASH_BOX_BINDING_DISABLED,
    ACCOUNT_CASH_BOX_BINDING_ENABLED, ACCOUNT_CASH_BOX_BINDING_REGISTERED, ACCOUNT_CASH_BOX_BINDING_UNREGISTERED,
    CASH_BOX_AMOUNTS_UPDATED,
    CASH_BOX_DELETED,
    CASH_BOX_REGISTERED,
    CASH_BOX_UPDATED
} from "../eventbus/cashbox/cashboxEvents";

const EventNameMapper = {
    'warehouse-item-state-updated': PUSH_UPDATE_ITEM_STATE_UPDATED,
    'contractor-debt-state-updated': PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED,
    'account-contractor-binding-disabled': PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_DISABLED,
    'account-contractor-binding-enabled': PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_ENABLED,
    'account-contractor-bind-registered': PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_REGISTER,
    'account-contractor-bind-unregistered': PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_UNREGISTER,
    'cash-box-registered' : CASH_BOX_REGISTERED,
    'cash-box-updated' : CASH_BOX_UPDATED,
    'cash-box-deleted' : CASH_BOX_DELETED,
    'cash-box-amounts-updated' : CASH_BOX_AMOUNTS_UPDATED,
    'account-cash-box-binding-enabled' : ACCOUNT_CASH_BOX_BINDING_ENABLED,
    'account-cash-box-binding-disabled' : ACCOUNT_CASH_BOX_BINDING_DISABLED,
    'account-cash-box-bind-registered' : ACCOUNT_CASH_BOX_BINDING_REGISTERED,
    'account-cash-box-bind-unregistered' : ACCOUNT_CASH_BOX_BINDING_UNREGISTERED,
    'settings-organization-account-updated' : SETTINGS_ORGANIZATION_ACCOUNT_UPDATE,
    'account-permissions-updated' : ACCOUNT_PERMISSION_UPDATE,
};

class PushUpdate {
    _token = null;
    _resurrectConnection = false;
    _ws = null;

    _updatesDelay = 5 * 1000;
    _offset = null;
    _timeout = null;

    _setupHandlers() {
        const self = this;
        self._ws.onclose = (a, b, c, d, e, f, g, h) => {
            if (self._resurrectConnection) {
                setTimeout(() => {
                    self._connectWebSocket()
                }, 5000)
            }
        };
        self._ws.onmessage = (event) => {
            const eventData = JSON.parse(event.data);
            const {event_name: eventName, payload: payload} = eventData
            self._emitUpdate(eventName, payload)
        };
    }

    _emitUpdate(eventName, payload) {
        const busEventName = EventNameMapper[eventName];
        if (busEventName) {
            EventBus.dispatch(busEventName, payload)
        }
    }

    _connectWebSocket() {
        const self = this;
        if (!this._token) return;

        try {
            self._resurrectConnection = true;

            self._ws = new WebSocket(`${process.env.REACT_APP_PUSH_UPDATE_HOST}/api/ws?token=${this._token}`);
            self._setupHandlers();
        } catch (error) {
            console.log(error)
        }
    }

    _disconnectWebSocket() {
        try {
            const ws = this._ws;
            this._resurrectConnection = false;
            this._ws = null;

            if (ws) ws.close()
        } catch {

        }
    }

    // web api
    async _loadUpdates() {
        const token = pushUpdate._token
        try {
            let params = {
                token: token
            }
            if (pushUpdate._offset) {
                params["offset"] = pushUpdate._offset
            }
            const response = await axios.get(`${process.env.REACT_APP_PUSH_UPDATE_API_HOST}/api/updates/get`, {params: params})
            pushUpdate._offset = response.data.server_time

            for (let i = 0; i < response.data.events.length; i++) {
                const event = response.data.events[i]
                pushUpdate._emitUpdate(event.name, event.payload)
            }
        } catch (e) {

        } finally {
            pushUpdate._timeout = setTimeout(pushUpdate._loadUpdates, pushUpdate._updatesDelay)
        }
    }

    async _startWebApiUpdates() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PUSH_UPDATE_API_HOST}/server-time`)
            this._offset = response.data
        } catch (e) {
            console.log('Failed to get server time for push updates: ', e)
        }

        this._loadUpdates()
    }

    _stopWebApiUpdates() {
        if (this._timeout) {
            clearInterval(this._timeout)
        }

        this._timeout = null
        this._offset = null
    }

    // public methods
    start(token) {
        this._token = token
        this._startWebApiUpdates()
        this._connectWebSocket()
    }

    stop() {
        this._token = null
        this._stopWebApiUpdates()
        this._disconnectWebSocket()
    }
}

const pushUpdate = new PushUpdate()

export default pushUpdate;
