import {VIEW_INBOX, VIEW_OUTBOX} from "./WaybillStatus";

export const RoamingWaybillV2StatusAll = null
export const RoamingWaybillV2StatusExecutorSent = 110
export const RoamingWaybillV2StatusExecutorCanceled = 120
export const RoamingWaybillV2StatusResponsiblePersonRejected = 130
export const RoamingWaybillV2StatusResponsiblePersonAccepted = 140
export const RoamingWaybillV2StatusResponsiblePersonTillReturned = 150
export const RoamingWaybillV2StatusResponsiblePersonGiven = 160
export const RoamingWaybillV2StatusCustomerRejected = 170
export const RoamingWaybillV2StatusCustomerAccepted = 180
export const RoamingWaybillV2StatusResponsiblePersonReturned = 190
export const RoamingWaybillV2StatusConsignorReturnAccepted = 200
export const RoamingWaybillV2StatusTaxCommitteeRevoked = 999

const waybillStatus = (id, name) => {
    return {
        id,
        name
    }
}

export const VIEW_OUTBOX_STATUSES = [
    waybillStatus(RoamingWaybillV2StatusAll, 'roaming.waybill.v2.status.all'),
    waybillStatus(RoamingWaybillV2StatusExecutorSent, 'roaming.waybill.v2.status.consignor.sent'),
    waybillStatus(RoamingWaybillV2StatusExecutorCanceled, 'roaming.waybill.v2.status.consignor.canceled'),
    waybillStatus(RoamingWaybillV2StatusResponsiblePersonRejected, 'roaming.waybill.v2.status.responsible.person.rejected'),
    waybillStatus(RoamingWaybillV2StatusResponsiblePersonAccepted, 'roaming.waybill.v2.status.responsible.person.accepted'),
    waybillStatus(RoamingWaybillV2StatusResponsiblePersonTillReturned, 'roaming.waybill.v2.status.responsible.person.till.returned'),
    waybillStatus(RoamingWaybillV2StatusResponsiblePersonGiven, 'roaming.waybill.v2.status.responsible.person.given'),
    waybillStatus(RoamingWaybillV2StatusCustomerRejected, 'roaming.waybill.v2.status.consignee.rejected'),
    waybillStatus(RoamingWaybillV2StatusCustomerAccepted, 'roaming.waybill.v2.status.consignee.accepted'),
    waybillStatus(RoamingWaybillV2StatusResponsiblePersonReturned, 'roaming.waybill.v2.status.responsible.person.returned'),
    waybillStatus(RoamingWaybillV2StatusConsignorReturnAccepted, 'roaming.waybill.v2.status.consignor.return.accepted'),
    waybillStatus(RoamingWaybillV2StatusTaxCommitteeRevoked, 'roaming.waybill.v2.status.tax.committee.revoked'),
];

export const getWaybillV2Status = (status) => {
    switch (status) {
        case RoamingWaybillV2StatusAll :
            return {
                label: "all",
                variant: "primary"
            }
        case RoamingWaybillV2StatusExecutorSent :
            return {
                label: "roaming.waybill.v2.status.consignor.sent",
                variant: "info"
            }
        case RoamingWaybillV2StatusExecutorCanceled:
            return {
                label: "roaming.waybill.v2.status.consignor.canceled",
                variant: "danger"
            }
        case RoamingWaybillV2StatusResponsiblePersonRejected :
            return {
                label: "roaming.waybill.v2.status.responsible.person.rejected",
                variant: "danger"
            }
        case RoamingWaybillV2StatusResponsiblePersonAccepted :
            return {
                label: "roaming.waybill.v2.status.responsible.person.accepted",
                variant: "success"
            }
        case RoamingWaybillV2StatusResponsiblePersonTillReturned :
            return {
                label: "roaming.waybill.v2.status.responsible.person.till.returned",
                variant: "warning"
            }
        case RoamingWaybillV2StatusResponsiblePersonGiven :
            return {
                label: "roaming.waybill.v2.status.responsible.person.given",
                variant: "success"
            }
        case RoamingWaybillV2StatusCustomerRejected :
            return {
                label: "roaming.waybill.v2.status.consignee.rejected",
                variant: "danger"
            }
        case RoamingWaybillV2StatusCustomerAccepted :
            return {
                label: "roaming.waybill.v2.status.consignee.accepted",
                variant: "success"
            }
        case RoamingWaybillV2StatusResponsiblePersonReturned :
            return {
                label: "roaming.waybill.v2.status.responsible.person.returned",
                variant: "warning"
            }
        case RoamingWaybillV2StatusConsignorReturnAccepted :
            return {
                label: "roaming.waybill.v2.status.consignor.return.accepted",
                variant: "success"
            }
        case RoamingWaybillV2StatusTaxCommitteeRevoked :
            return {
                label: "roaming.waybill.v2.status.tax.committee.revoked",
                variant: "dark"
            }
        default :
            return  {
                label: "",
                variant: "light"
            }
    }
}


