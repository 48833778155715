import React, {useEffect, useState} from 'react';
import {read, utils} from 'xlsx'
import {Button, Col, Form, InputGroup, Modal, Row, Table} from "react-bootstrap";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {changeContractFormationExcelOptions, selectContractFormationExcelOptions, selectSaveContractFormationExcel,} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";

const NameColumn = 'NameColumn';
const UnimportantColumn = 'UnimportantColumn';
const CatalogCodeColumn = 'CatalogCodeColumn';
const MeasurementOrPackagesColumn = 'MeasurementOrPackagesColumn';
const BarcodeColumn = 'BarcodeColumn';
const QuantityColumn = 'QuantityColumn';
const PriceColumn = 'PriceColumn';
const NdsRateColumn = 'NdsRateColumn';


const columnDropdownOptions = [
    {
        text: "edi.contract_formation.excel.column_type_skip",
        value: UnimportantColumn
    },
    {
        text: "edi.contract_formation.excel.column_type_name",
        value: NameColumn
    },
    {
        text: "edi.contract_formation.excel.column_type_catalog_code",
        value: CatalogCodeColumn
    },
    {
        text: "edi.contract_formation.excel.column_type_measurement_or_packages",
        value: MeasurementOrPackagesColumn
    },
    {
        text: "edi.contract_formation.excel.column_type_barcode",
        value: BarcodeColumn
    },
    {
        text: "edi.contract_formation.excel.column_type_quantity",
        value: QuantityColumn
    },
    {
        text: "edi.contract_formation.excel.column_type_price",
        value: PriceColumn
    },
    {
        text: "edi.contract_formation.excel.column_type_nds_rate",
        value: NdsRateColumn
    },
]

const ContractFormationItemExcelImport = ({onSuccessFilled}) => {
    const [show, setShow] = useState(false);
    const [file, setFile] = useState(null);
    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState([]);
    const ExcelOptions = useSelector(selectContractFormationExcelOptions)
    const [skipRows, setSkipRows] = useState(0);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const disabled = items.length <= 0 || ExcelOptions.nameColumnIndex == null || ExcelOptions.catalogCodeColumnIndex == null || ExcelOptions.barcodeColumnIndex == null || ExcelOptions.quantityColumnIndex == null
    const isInvalidColumn = ExcelOptions.nameColumnIndex === null || ExcelOptions.catalogCodeColumnIndex === null || ExcelOptions.barcodeColumnIndex === null || ExcelOptions.quantityColumnIndex === null

    useEffect(() => {
        file && readFileAsync(file).then(sheets => {
            setItems(sheets);
        }).catch(() => setItems([]))
    }, [file]);

    useEffect(() => {
        items && setTableColumns();
    }, [items]);

    const handleShow = () => {
        setShow(true)
        setSkipRows(ExcelOptions.skipRows)
    };

    const handleClose = () => setShow(false);

    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsBinaryString(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = read(bufferArray, {type: 'binary'});
                const wsName = wb.SheetNames[0];
                const ws = wb.Sheets[wsName];
                const sheets = utils.sheet_to_json(ws, {header: 1, defval: null});

                resolve(sheets);
            }

            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const setTableColumns = () => {
        let fileColumns = [];
        let itemsRowLength = 0;

        for (let fileItemIndex = 0; fileItemIndex < items.length; fileItemIndex++) {
            const itemRow = items[fileItemIndex];

            if (itemRow.length > itemsRowLength)
                itemsRowLength = itemRow.length;
        }

        for (let i = 0; i < itemsRowLength; i++) {
            const nameColumnExist = fileColumns.some(fileColumn => fileColumn.type === NameColumn);
            const CatalogCodeColumnExist = fileColumns.some(fileColumn => fileColumn.type === CatalogCodeColumn);
            const MeasurementOrPackagesColumnExist = fileColumns.some(fileColumn => fileColumn.type === MeasurementOrPackagesColumn);
            const BarcodeColumnExist = fileColumns.some(fileColumn => fileColumn.type === BarcodeColumn);
            const quantityColumnExist = fileColumns.some(fileColumn => fileColumn.type === QuantityColumn);
            const PriceColumnExist = fileColumns.some(fileColumn => fileColumn.type === PriceColumn);
            const NdsRateColumnExist = fileColumns.some(fileColumn => fileColumn.type === NdsRateColumn)

            if (!nameColumnExist && ExcelOptions.nameColumnIndex === i) {
                fileColumns.push({index: i, type: NameColumn});
            } else if (!CatalogCodeColumnExist && ExcelOptions.catalogCodeColumnIndex === i) {
                fileColumns.push({index: i, type: CatalogCodeColumn});
            } else if (!MeasurementOrPackagesColumnExist && ExcelOptions.measurementOrPackagesColumnIndex === i) {
                fileColumns.push({index: i, type: MeasurementOrPackagesColumn});
            } else if (!BarcodeColumnExist && ExcelOptions.barcodeColumnIndex === i) {
                fileColumns.push({index: i, type: BarcodeColumn});
            } else if (!quantityColumnExist && ExcelOptions.quantityColumnIndex === i) {
                fileColumns.push({index: i, type: QuantityColumn});
            } else if (!PriceColumnExist && ExcelOptions.priceColumnIndex === i) {
                fileColumns.push({index: i, type: PriceColumn});
            } else if (!NdsRateColumnExist && ExcelOptions.ndsRateColumnIndex === i) {
                fileColumns.push({index: i, type: NdsRateColumn})
            } else {
                fileColumns.push({index: i, type: UnimportantColumn});
            }
        }

        return setColumns(fileColumns);
    }

    const onSelectColumnType = (selectedColumnType, selectedColumnIndex) => {
        const isSuchColumnExist = columns.find(column => column.type === selectedColumnType);

        if (isSuchColumnExist) {
            columns[isSuchColumnExist.index].type = UnimportantColumn;
            columns[selectedColumnIndex].type = selectedColumnType;
        } else {
            columns[selectedColumnIndex].type = selectedColumnType
        }

        const fieldNameColumn = columns.find(fieldColumn => fieldColumn.type === NameColumn);
        const fieldCatalogCodeColumn = columns.find(fieldColumn => fieldColumn.type === CatalogCodeColumn);
        const fieldBarcodeColumn = columns.find(fieldColumn => fieldColumn.type === BarcodeColumn);
        const fieldMeasurementOrPackagesColumn = columns.find(fieldColumn => fieldColumn.type === MeasurementOrPackagesColumn)
        const fieldQuantityColumn = columns.find(fieldColumn => fieldColumn.type === QuantityColumn);
        const fieldPriceColumn = columns.find(fieldColumn => fieldColumn.type === PriceColumn);
        const fieldNdsRateColumn = columns.find(fieldColumn => fieldColumn.type === NdsRateColumn)

        dispatch(changeContractFormationExcelOptions({
            skipRows: skipRows,
            nameColumnIndex: fieldNameColumn ? fieldNameColumn.index : null,
            catalogCodeColumnIndex: fieldCatalogCodeColumn ? fieldCatalogCodeColumn.index : null,
            measurementOrPackagesColumnIndex: fieldMeasurementOrPackagesColumn ? fieldMeasurementOrPackagesColumn.index : null,
            barcodeColumnIndex: fieldBarcodeColumn ? fieldBarcodeColumn.index : null,
            quantityColumnIndex: fieldQuantityColumn ? fieldQuantityColumn.index : null,
            priceColumnIndex: fieldPriceColumn ? fieldPriceColumn.index : null,
            ndsRateColumnIndex: fieldNdsRateColumn ? fieldNdsRateColumn.index : null
        }))

        return setColumns(columns)
    }

    const saveImportDetails = () => {
        const data = {
            skipRows: skipRows,
            nameColumnIndex: ExcelOptions.nameColumnIndex,
            catalogCodeColumnIndex: ExcelOptions.catalogCodeColumnIndex,
            measurementOrPackagesColumnIndex: ExcelOptions.measurementOrPackagesColumnIndex,
            barcodeColumnIndex: ExcelOptions.barcodeColumnIndex,
            quantityColumnIndex: ExcelOptions.quantityColumnIndex,
            priceColumnIndex: ExcelOptions.priceColumnIndex,
            ndsRateColumnIndex: ExcelOptions.ndsRateColumnIndex,
        };
        dispatch(selectSaveContractFormationExcel(data))
    }

    const onFill = () => {
        saveImportDetails();

        const products = [];
        for (let i = skipRows; i < items.length; i++) {
            const nameCheck = typeof items[i][ExcelOptions.nameColumnIndex] === 'object' ? '' : `${items[i][ExcelOptions.nameColumnIndex]}`
            const catalogCodeCheck = isNaN(items[i][ExcelOptions.catalogCodeColumnIndex]) ? null : `${items[i][ExcelOptions.catalogCodeColumnIndex]}`
            const measurementOrPackagesCheck = items[i][ExcelOptions.measurementOrPackagesColumnIndex] ? items[i][ExcelOptions.measurementOrPackagesColumnIndex] : null
            const barcodeCheck = typeof items[i][ExcelOptions.barcodeColumnIndex] === 'object' || isNaN(items[i][ExcelOptions.barcodeColumnIndex]) ? '' : `${items[i][ExcelOptions.barcodeColumnIndex]}`
            const quantityCheck = isNaN(items[i][ExcelOptions.quantityColumnIndex]) ? null : items[i][ExcelOptions.quantityColumnIndex]
            const priceCheck = isNaN(items[i][ExcelOptions.priceColumnIndex]) ? null : items[i][ExcelOptions.priceColumnIndex]
            const ndsRateCheck = isNaN(items[i][ExcelOptions.ndsRateColumnIndex]) ? '' : `${items[i][ExcelOptions.ndsRateColumnIndex]}`

            products.push({
                name: nameCheck,
                catalogCode: catalogCodeCheck,
                measurementOrPackages: measurementOrPackagesCheck,
                barcode: barcodeCheck,
                quantity: quantityCheck,
                price: priceCheck,
                ndsRate: ndsRateCheck,
            })
        }
        onSuccessFilled(products);

        setItems([]);
        setShow(false);
    }

    return (
        <>
            <Button size="sm" className="mx-1" variant="success" onClick={handleShow}>{t(lang, "edi.contract_formation.excel.fill_from_excel")}</Button>

            {show && <Modal show={true} onHide={handleClose} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "edi.contract_formation.excel.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs="12" md="6">
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>{t(lang, "edi.contract_formation.fill_from_excel.choose_file")}</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(event) => setFile(event?.target?.files?.[0])}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                            </Form.Group>
                        </Col>
                        <Col className="ms-auto" xs="12" sm="6" md="auto">
                            <Form.Group>
                                <Form.Label>{t(lang, "edi.contract_formation.fill_from_excel.ignore_top_lines")}</Form.Label>
                                <InputGroup>
                                    <Button variant="outline-secondary" disabled={skipRows === 0} onClick={() => setSkipRows(+skipRows - 1)}>-</Button>
                                    <Form.Control type="number" value={skipRows} onChange={(e) => setSkipRows(e.target.value)}/>
                                    <Button variant="outline-secondary" onClick={() => setSkipRows(+skipRows + 1)}>+</Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <Col xs="12">
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Table responsive>
                            <thead>
                            <tr>
                                {columns.map((column, index) => (
                                    <th className="px-1" key={column.index}>
                                        <Form.Group>
                                            <Form.Select isInvalid={isInvalidColumn} className="w-auto w-sm-100" value={column.type} onChange={(e) => onSelectColumnType(e.target.value, column.index)}>
                                                {columnDropdownOptions.map(option => (
                                                    <option key={option.value}
                                                            value={option.value}>{t(lang, option.text)}</option>
                                                ))}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">
                                                {t(lang, "edi.contract_formation.excel.validations.is_required")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {items.map((item, itemIndex) => (
                                <tr className={classNames('fs--1', {
                                    'bg-400 text-dark': (itemIndex + 1) <= skipRows
                                })} key={itemIndex}>
                                    {item.map((product, productIndex) => (
                                        <th key={productIndex}>{product}</th>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, "edi.common.button.close")}</Button>
                    <Button variant="primary" onClick={onFill} disabled={disabled}>{t(lang, "edi.contract_formation.fill_from_excel.fill_in")}</Button>
                </Modal.Footer>
            </Modal>}
        </>
    );
};

export default ContractFormationItemExcelImport;
