import React, {useEffect} from "react";
import {Form} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ForgotPasswordStepTwo = ({invalidConfirmCode, footer}) => {
    const {register, formState: {errors}, trigger} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (invalidConfirmCode)
            trigger();
    }, [invalidConfirmCode]);

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Control
                    type='number'
                    placeholder={t(lang, 'authentication.forgot-password.confirmation_code.placeholder')}
                    name='confirmCode'
                    isInvalid={errors.confirmCode}
                    className='input-spin-none'
                    {...register('confirmCode', {
                        required: t(lang, 'edi.common.forms.validations.is_required'),
                        pattern: {
                            value: /^\d{6}$/,
                            message: t(lang, 'authentication.forgot-password.confirmation_code.format')
                        },
                        validate: value => {
                            if (invalidConfirmCode && invalidConfirmCode === value)
                                return t(lang, 'authentication.forgot-password.confirmation_code.is_invalid')

                            return true;
                        }
                    })}
                />
                <Form.Control.Feedback type='invalid'>{errors && errors.confirmCode?.message}</Form.Control.Feedback>
            </Form.Group>

            {footer}
        </>
    );
};

ForgotPasswordStepTwo.propTypes = {
    confirmCodeError: PropTypes.bool,
    footer: PropTypes.element
}

export default ForgotPasswordStepTwo;