import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Form, FormControl, Row} from "react-bootstrap";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    RoamingWaybillV2DeliveryTypeFromWarehouseToWarehouse,
    selectTransports
} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import SelectTransport from "./SelectTransport";
import {Railway} from "../SendWaybillFormV2";



const DetailsFormV2 = ({ediShipment}) => {

    const {register, formState: {errors}, control, watch} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const delivery_type = useWatch({name: "delivery_type", exact: true})
    const transportType = useWatch({name: "transport_type", exact: true})
    const freight_forwarder = useWatch({name: 'freight_forwarder.inn_or_pinfl', exact: true})
    const carrier = useWatch({name: 'carrier.inn_or_pinfl', exact: true})

    return (
        <Fragment>
            <Card>
                <Card.Body className={'position-relative'}>
                    <Row>
                        {+delivery_type === 2 && <Fragment>
                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.contract_number')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.contract_number.placeholder')}
                                    {...register('contract_info.number', {
                                        required: delivery_type === 2 ? t(lang, "edi.common.forms.validations.is_required") : false
                                    })}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.contract_info?.number?.message}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.contract_date')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='contract_info.date'
                                    rules={{
                                        required: delivery_type === 2 ? t(lang, "edi.common.forms.validations.is_required") : false,
                                        max: {
                                            value: new Date(),
                                            message: t(lang, 'roaming.waybill.send.details_form.validations.shipment.date.more_than_current_date')
                                        }
                                    }}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            selected={field.value}
                                            dateFormat={dateFormat}
                                            onChange={(date) => field.onChange(date)}
                                            className={'form-control'}
                                            placeholderText={t(lang, 'roaming.waybill.send.details_form.contract_date.placeholder')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.contract_info?.date?.message}
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Fragment>}

                        {+transportType === Railway ? <Fragment>
                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.train_carriage_number')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.train_carriage_number:placeholder')}
                                    {...register('railway.train_carriage_number', {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.railway?.train_carriage_number?.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.train_direction')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.train_direction:placeholder')}
                                    {...register('railway.train_direction', {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.railway?.train_direction?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Fragment> : <Fragment>
                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>{t(lang, "Транспорт")}</Form.Label>
                                <Controller
                                    rules={{
                                        required: !ediShipment ? t(lang, "edi.common.forms.validations.is_required") : false
                                    }}
                                    name={'transport.registration_number'}
                                    render={({field}) => (
                                        <SelectTransport
                                            onChange={field.onChange}
                                            defaultTransportNumber={field.value}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.transport?.registration_number?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Fragment>}

                        {ediShipment && (carrier && (freight_forwarder !== carrier)) && <Fragment>
                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.payer_contract_number')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.contract_number.placeholder')}
                                    {...register('payer_contract_info.number', {
                                        required:t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.payer_contract_info?.number?.message}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.payer_contract_date')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='payer_contract_info.date'
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                        max: {
                                            value: new Date(),
                                            message: t(lang, 'roaming.waybill.send.details_form.validations.shipment.date.more_than_current_date')
                                        }
                                    }}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            selected={field.value}
                                            dateFormat={dateFormat}
                                            onChange={(date) => field.onChange(date)}
                                            className={'form-control'}
                                            placeholderText={t(lang, 'roaming.waybill.send.details_form.contract_date.placeholder')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.payer_contract_info?.date?.message}
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Fragment>}
                    </Row>

                    {ediShipment && <Fragment>
                        <Form.Group className={'position-absolute'} style={{top: '10px', right: '10px'}}>
                            <Form.Label form={'hide-additional-information'}>
                                <Form.Switch
                                    {...register(`is_hide_additional_information`, {
                                        shouldUnregister: true
                                    })}
                                    id={'hide-additional-information'}
                                    label={t(lang, 'roaming.waybill.send.hide.additional-information.title')}
                                />
                            </Form.Label>
                        </Form.Group>
                    </Fragment>}
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default DetailsFormV2;
