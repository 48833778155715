import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Controller, FormProvider, useForm} from "react-hook-form";
import telegramOrderBotService from "../../../../../services/telegram-order-bot/telegramOrderBotService";
import {toast} from "react-toastify";

const SendMessageToBotUsersModal = ({botId, botUserIds}) => {
        const t = Translate;
        const lang = useSelector(selectLang);
        const form = useForm({
            defaultValues: {
                message: ""
            }
        });
        const [show, setShow] = useState(false);
        const [loading, setLoading] = useState(false);
        const handleShow = () => setShow(true);
        const handleClose = () => setShow(false);

        const onSubmit = async (formData) => {
            try {
                let identifications = [];
                if (!!botUserIds.length) {
                    identifications = botUserIds;
                } else {
                    const {data: users} = await telegramOrderBotService.getTelegramOrderBotUsers(botId);
                    identifications = users.map((user) => user.id);
                }

                const payload = {
                    identifications: identifications,
                    message: formData.message
                };
                setLoading(true);
                await telegramOrderBotService.sendMessageToTelegramOrderBotUsers(botId, payload);
                setLoading(false);
                handleClose();
                toast.success(t(lang, "common.toast.success"));
                form.reset();
            } catch (error) {
                setLoading(false);
                toast.error(t(lang, "common.toast.error"));
            }
        };

        return (
            <React.Fragment>
                <Button variant="primary"
                        disabled={loading}
                        onClick={handleShow}
                >
                    {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                    {t(lang, "crm.telegram_order_bot.send_message")}
                </Button>
                {show &&
                    <Modal show={true}
                           onHide={handleClose}
                           centered={true}
                           size="lg"
                    >
                        <FormProvider {...form}>
                            <Form>
                                <Modal.Header>
                                    <Modal.Title>
                                        {!!botUserIds.length ? t(lang, "crm.telegram_order_bot.send_message_to_selected_bot_users") : t(lang, "crm.telegram_order_bot.send_message_to_all_bot_users")}
                                    </Modal.Title>
                                    <FalconCloseButton onClick={handleClose}/>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col xs={12}>
                                            <Controller name="message"
                                                        rules={{
                                                            required: t(lang, "items.common.validations.is_required")
                                                        }}
                                                        render={({field, fieldState: {error}}) => (
                                                            <Form.Group>
                                                                <Form.Label className="ps-0">Сообщение</Form.Label>
                                                                <Form.Control as="textarea"
                                                                              placeholder="Введите отправляемое сообщение"
                                                                              defaultValue={field.value}
                                                                              onChange={field.onChange}
                                                                              isInvalid={!!error}
                                                                />
                                                                {!!error && <Form.Control.Feedback
                                                                    type="invalid">{error?.message}</Form.Control.Feedback>}
                                                            </Form.Group>
                                                        )}
                                            />
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary"
                                            onClick={handleClose}
                                    >
                                        {t(lang, "main.common.back")}
                                    </Button>
                                    <Button variant="primary"
                                            disabled={loading}
                                            onClick={form.handleSubmit(onSubmit)}
                                    >
                                        {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                        {t(lang, 'crm.telegram_order_bot.send_message')}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </FormProvider>
                    </Modal>
                }
            </React.Fragment>
        );
    }
;

export default SendMessageToBotUsersModal;