import CurrenciesDataTable from "../../../components/currency/CurrenciesDataTable";
import {Col, Row} from "react-bootstrap";

const Currencies = () => {
    return (
        <Row>
            <Col xs={12} xxl={8}>
                <CurrenciesDataTable />
            </Col>
        </Row>
    )
};

export default Currencies;
