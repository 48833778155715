import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../app/store/reducers/main/mainReducer";
import {Card, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowDown, faTrash} from "@fortawesome/free-solid-svg-icons";
import {parseExcelFileAsync} from "../../../../app/store/reducers/excel/excelReducer";
import IconButton from "../../../common/IconButton";
import InProgress from "../../../common/InProgress";

const ExcelFileImportDropzone = ({onChangeFile}) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const [file, setFile] = useState(null);

    const onSelectFile = (files) => {
        const [file] = files;
        setFile(file);
        readFile(file);
    };
    const onClearFile = () => {
        setFile(null);
        onChangeFile([]);
    }
    const readFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            const base64Content = e.target.result;
            dispatch(updateShowInProgressAsync(true));
            parseExcelFileAsync(base64Content)
                .then((parsedContent) => {
                    onChangeFile(parsedContent);
                    dispatch(updateShowInProgressAsync(false));
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(updateShowInProgressAsync(false));
                })
        }
    };

    return (
        <Row>
            <InProgress />
            <Col xs="12">
                <div className="text-end">
                    <IconButton icon={faTrash}
                                variant={'falcon-danger'}
                                onClick={onClearFile}
                    >
                        {t(lang, 'items.common.clear_imported_file')}
                    </IconButton>
                </div>
                <Card className="my-3 shadow-none border-dashed bg-light">
                    <Form.Control
                        type="file"
                        id="fileUploader"
                        className="d-none"
                        value={''}
                        onChange={(event) => onSelectFile(event.target.files)}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <Card.Body as="label" htmlFor="fileUploader" className="text-center cursor-pointer py-4">
                        <h4>
                            <FontAwesomeIcon icon={faCloudArrowDown}/>
                            <span className="ms-2">{t(lang, 'items.common.mass_import.file_uploader.title')}</span>
                        </h4>
                        {!file && <span className="ms-4 text-warning">{t(lang, 'items.common.mass_import.file_uploader.subtitle')}</span>}
                        {file && <span className="ms-4 text-primary">{file.name}</span>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
};

export default ExcelFileImportDropzone;