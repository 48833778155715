import React from "react";
import {Form} from "react-bootstrap";
import PropTypes from 'prop-types';
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const RegistrationFromStepTwo = ({hasLabel, invalidConfirmCode}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}} = useFormContext();

    return <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirmation code</Form.Label>}
        <Form.Control
            placeholder={!hasLabel ? 'Введите код подтверждения' : ''}
            isInvalid={errors.account?.confirmCode}
            type="text"
            {...register('account.confirmCode', {
                required: t(lang, "edi.common.forms.validations.is_required"),
                pattern: {
                    value: /^\d{6}$/,
                    message: 'Введите 6-значный код подтверждения из SMS-сообщения.'
                },
                validate: {
                    invalid: value => {
                        if (invalidConfirmCode) {
                            if (invalidConfirmCode === value)
                                return 'Invalid value entered, please re-check code from sms message'
                        }
                        return true;
                    }
                }
            })}
        />
        <Form.Control.Feedback type="invalid">
            {errors.account?.confirmCode?.message}
        </Form.Control.Feedback>
    </Form.Group>;
};

RegistrationFromStepTwo.propTypes = {
    hasLabel: PropTypes.bool,
    invalidConfirmCode: PropTypes.string || null
}

RegistrationFromStepTwo.defaultValue = {
    hasLabel: false,
    invalidConfirmCode: null
}

export default RegistrationFromStepTwo;
