import React, {useEffect} from 'react';
import dayjs from 'dayjs';
import numeral from "numeral";
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
    changeFilterContractorInvoicesAsync,
    loadContractorInvoicesCountAsync,
    loadContractorInvoicesAsync,
    selectContractorInvoices,
    selectContractorCountInvoices,
    selectContractorInvoiceFilterOptions,
} from '../../../../../../app/store/reducers/contractor/contractorInvoiceReducer';

import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {selectActiveOrganization} from '../../../../../../app/store/reducers/auth/authReducer';
import AdvanceTableCustomPagination from '../../../../../common/advance-table/AdvanceTableCustomPagination';
import EventBus from "../../../../../../app/eventbus/EventBus";
import {DELETE_INVOICE_SUCCESS} from "../../../../../../app/eventbus/invoiceEvents";
import {
    selectLang,
    Translate,
    selectDateDayJSFormat,
    selectNumberFormat
} from "../../../../../../app/store/reducers/main/mainReducer";
import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../../../common/advance-table/AdvanceTableFooter";
import ContractorInvoiceDataTableHeader from "./ContractorInvoiceDataTableHeader";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import {RoamingInvoiceWrapper} from "../../../../../../enum/RoamingInvoiceWrapper";
import SoftBadge from "../../../../../common/SoftBadge";

const ContractorInvoiceDataTable = ({factura_type}) => {
    const contractor = useSelector(selectCardContractor)
    const dispatch = useDispatch()
    const invoices = useSelector(selectContractorInvoices)
    const count = useSelector(selectContractorCountInvoices)
    const filters = useSelector(selectContractorInvoiceFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        dispatch(loadContractorInvoicesAsync({
            ...filters,
            contractor_inn: contractor?.inn,
            factura_type: typeof factura_type !== 'undefined' ? factura_type : 0
        }));
        dispatch(loadContractorInvoicesCountAsync({
            ...filters,
            contractor_inn: contractor?.inn,
            factura_type: typeof factura_type !== 'undefined' ? factura_type : 0
        }));
    }, [contractor, filters])

    const totalSum = invoices.reduce((totSum, total) => {
        return totSum + total.total_info.total_with_nds
    }, 0)

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, "roaming.invoices.number"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                return (
                    <Link
                        to={`/roaming/invoice/${original.id}`}>
                        <strong>{original.info.number}</strong>
                    </Link>
                )
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, "roaming.invoices.date"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                return <b>{dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'status',
            Header: t(lang, "roaming.invoices.datatable.header.row.status"),
            Cell: ({row: {original}}) => {
                const invoiceWrapper = new RoamingInvoiceWrapper(original)
                return (
                    <h5>
                        <SoftBadge
                            bg={invoiceWrapper.getStatusVariant(activeOrganization.pinfl || activeOrganization.inn)}
                            className='me-2'>
                            {t(lang, invoiceWrapper.getStatusText(activeOrganization.pinfl || activeOrganization.inn))}
                        </SoftBadge>
                    </h5>
                )

            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.invoices.created_at"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                return <b>{dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "roaming.invoices.datatable.header.row.contractor"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                if (original.single_sided_type === 0) {
                    if (activeOrganization.inn === original.executor.inn || activeOrganization.pinfl === original.executor.inn)
                        return <>
                            <b>{original.customer.name}</b>
                            <br/>
                            <small><i>{original.customer.inn}</i></small>
                        </>
                    else
                        return <>
                            <b>{original.executor.name}</b>
                            <br/>
                            <small><i>{original.executor.inn}</i></small>
                        </>
                }
                return null;
            }
        },
        {
            accessor: 'total_sum',
            Header: t(lang, "roaming.invoices.datatable.header.row.total_sum"),
            headerProps: {className: 'text-end'},
            totalInfo: `${t(lang, "edi.common.datatable.total")}: ` + numeral.formats[numberFormat].format(totalSum),
            totalInfoClassName: "fw-bold text-nowrap text-end h6",
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <strong>
                        {numeral.formats[numberFormat].format((original.total_info.total_with_nds))}
                    </strong>
                )
            }
        }
    ];
    const onPageChange = (page) => dispatch(changeFilterContractorInvoicesAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterContractorInvoicesAsync({...filters, limit: limit, page: 1}));

    useEffect(() => {
        const onInvoiceDeletedHandler = EventBus.on(DELETE_INVOICE_SUCCESS, () => {
            dispatch(loadContractorInvoicesAsync({...filters, viewer_inn: activeOrganization.inn}))
        });

        return () => {
            EventBus.remove(DELETE_INVOICE_SUCCESS, onInvoiceDeletedHandler);
        }
    }, [])

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={invoices}
            width={10}
            sortable
            pagination
            perPage={1000}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ContractorInvoiceDataTableHeader/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default ContractorInvoiceDataTable;
