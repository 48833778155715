import React from 'react';
import AsyncSelect from "react-select/async";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {loadCatalogsBySubPositionName} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {debounce} from "underscore";

const SelectTasnifCatalog = ({onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const loadOptions = (query, callback) => {
        loadCatalogsBySubPositionName({sub_position_name: query})
            .then((catalogs) => {
                callback(catalogs);
            })
            .catch(() => {
                callback([]);
            })
    };
    const debouncedLoadOptions = debounce(loadOptions, 500);

    const formatOptionLabel = (option) => {
        return (
            <div className="fs--1">
                <span className="text-black fw-bold me-1">{option.class_code}</span>-
                <span className="ms-1 text-facebook">{option.name}</span>
            </div>
        )
    };

    return <AsyncSelect cacheOptions
                        loadOptions={debouncedLoadOptions}
                        formatOptionLabel={formatOptionLabel}
                        placeholder={t(lang, 'items.common.select')}
                        classNamePrefix="select"
                        value={null}
                        onChange={onChange}
    />
};

export default SelectTasnifCatalog;