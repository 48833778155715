import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectBoardName, selectBoards, selectWithTasks} from "../../../app/store/reducers/kanban/kanbanReducer";

import AddAnotherForm from "./AddAnotherForm";

import SelectDefaultCreatorEmployeeModal from "../modal/kanban/SelectDefaultCreatorEmployeeModal";
import {selectEmployees} from "../../../app/store/reducers/employee/employeeReducer";
import {useParams} from "react-router";
import {boardTypeName} from "../../../enum/KanbanWrapper";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const KanbanHeader = () => {
    const {id} = useParams();
    const lang = useSelector(selectLang);
    const t = Translate;
    const employees = useSelector(selectEmployees);
    const boardName = useSelector(selectBoardName)
    const [showForm, setShowForm] = useState(false);
    const [showDefaultCreator, setShowDefaultCreator] = useState(null);
    const withTasks = useSelector(selectWithTasks);


    useEffect(() => {
        if (!showDefaultCreator) {
            setShowDefaultCreator(employees.find(i => i.id === +localStorage.getItem('defaultCreator')))
        }
    }, [employees]);

    return (
        <Row className="gx-0 justify-content-between kanban-header rounded-2 px-x1 p-2 mt-0 mb-3">
            <Col>
                <h4 className={'m-0'}>{t(lang, boardTypeName(withTasks?.type)) || withTasks?.name}</h4>
            </Col>
            <Col className={'text-end'}>
                <div className="kanban-column">
                    <AddAnotherForm
                        type="list"
                        showForm={showForm}
                        setShowForm={setShowForm}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default KanbanHeader;
