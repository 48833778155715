import {Button, Form, Modal, Row, Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import React, {useState} from "react";
import PermissionsDatatable from "../PermissionsDatatable";
import RoleForm from "./RoleForm";
import {editRoleAsync} from "../../../../../app/store/reducers/role/roleReducer";
import {toast} from "react-toastify";

const EditRoleModal = ({show, role, onClose}) => {
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const t = Translate;
    const handleClose = () => {
        methods.reset();
        onClose();
    };
    const methods = useForm({
        defaultValues: {
            Name: role?.name,
            Permissions: {
                edi: role.permissions.filter(i => i >= 100 && i < 1000).map(i => {
                    return {value: i}
                }),
                roaming: role.permissions.filter(i => i >= 1000 && i < 2000).map(i => {
                    return {value: i}
                }),
                another: role.permissions.filter(i => (i >= 1 && i < 10) || (i <= 100 || i < 105)).map(i => {
                    return {value: i}
                })
            }
        },
    });

    const onSubmit = formData => {
        setLoading(true);
        let permissions = []
        for (const [key, value] of Object.entries(formData.Permissions)) {
            for (let i = 0; i < formData.Permissions[key].length; i++) {
                permissions.push(formData.Permissions[key][i].value)
            }
        }
        editRoleAsync(role.id, {
            name: formData.Name,
            permissions
        }).then((response) => {
        }).catch(error => {
            console.log(error)
            toast.error(t(lang, "hippo.user.settings.add_role.error.message"));
        }).finally(() => {
            setLoading(false);
            handleClose();
        });
    }
    return (
        <Modal show={show} size="xl">
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, "hippo.user.role.form.edit.title")}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <RoleForm/>
                        <PermissionsDatatable />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "edi.common.cancel_button")}
                        </Button>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />}
                            {t(lang, "edi.common.button.edit")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}
export default EditRoleModal;
