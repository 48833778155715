import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import {loadCompanyProductCatalogsAsync} from "../../../../../../app/store/reducers/roaming/roamingReducer";

import {registerOrder} from "../../../../../../app/store/reducers/crm/order/crmExecutorOrderReducer";

import {Form} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import {UZS} from "../../../../../../enum/CurrencyCode";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {selectCurrency} from "../../../../../../app/store/reducers/currency/currencyReducer";
import CrmExecutorOrderForm from "./CrmExecutorOrderForm";
import {selectDefaultSetting} from "../../../../../../app/store/reducers/settings/settingsReducer";

const CrmExecutorOrderAddForm = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const currencies = useSelector(selectCurrency);

    const history = useHistory()
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization)
    const [loading, setLoading] = useState(false);
    const settings = useSelector(selectDefaultSetting)

    const methods = useForm({
        defaultValues: {
            customer: null,
            branch:  settings?.branch?.default_id || null,
            info: {
                number: '',
                date: null
            },
            contract: {
                number: '',
                date: null
            },
            items: [
                {
                    product: '',
                    barcode: '',
                    product_code: '',
                    measurement: '',
                    price: {
                        amount: '',
                        currency_id: currencies?.find(c => c?.code === UZS)?.id
                    },
                    quantity: '',
                }
            ],
            expires_on: null,
            description: null
        }
    });

    const onSubmit = formData => {
        setLoading(true);
        registerOrder({
            items: formData.items.map(item => {
                return {
                    global_item_id: item.product?.id,
                    product: item.product?.name,
                    barcode: item.barcode || null,
                    product_code: item.product_code || null,
                    measurement: String(item.measurement),
                    price: item.price.amount === '' || item.price.amount === null ? null : {
                        amount: +item.price.amount,
                        currency_id: item.price.currency_id
                    },
                    quantity: +item.quantity,
                }
            }),
            customer: {
                inn: formData.customer.inn || null,
                name: formData.customer.name,
                global_contractor_id: formData.customer.id,
            },
            info: {
                number: formData.info?.number,
                date: formData?.info?.date ? dayjs(formData.info.date).format('YYYY-MM-DD') : null
            },
            contract: formData.contract?.number ? {
                number: formData.contract.number,
                date: formData?.contract.date ? dayjs(formData.contract.date).format('YYYY-MM-DD') : null
            } : null,
            branch: formData.branch?.id ? {
                id: formData.branch.id,
                code: formData.branch.code || null,
                name: formData.branch.name
            } : null,
            expires_on: formData?.expires_on ? dayjs(formData.expires_on).format('YYYY-MM-DD') : null,
            description: formData?.description
        }).then(() => {
            toast.success(t(lang, 'crm.common.toast.success'));
            history.goBack()
        }).catch(() => {
            toast.error(t(lang, 'crm.common.toast.error'));
        }).finally(() => {
            setLoading(false);
        })
    };

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
    }, [activeOrganization])

    useEffect(() => {
        methods.setValue('branch', settings?.branch?.default_id || null)
    }, [settings])

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <CrmExecutorOrderForm loading={loading}
                                      formTitle={'Новый заказ от клиента'}/>
            </Form>
        </FormProvider>
    );
};

export default CrmExecutorOrderAddForm;
