import React, {useEffect} from "react";
import EventBus from "../../app/eventbus/EventBus";
import {LOGIN_FAILED, LOGIN_SUCCESS} from "../../app/eventbus/authEvents";
import {toast} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";
import {selectIsAuthorized, selectIsLoginWithCertificate} from "../../app/store/reducers/auth/authReducer";
import {Link, Redirect} from "react-router-dom";
import Flex from "../../components/common/Flex";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import LoginWithUsername from "../../components/hippo/authentication/login/LoginWithUsername";
import {Tab, Tabs} from "react-bootstrap";
import LoginWithCertificate from "../../components/hippo/authentication/login/LoginWithCertificate";
import {changeDashboardPath} from "../../app/store/reducers/main/mainReducer";
const Login = () => {
    const isAuthorized = useSelector(selectIsAuthorized);
    const lang = useSelector(selectLang);
    const t = Translate;
    const isLoginWithCertificate = useSelector(selectIsLoginWithCertificate);
    const dispatch = useDispatch()

    const isWithUsername = 0;
    const isWithCertificate = 1;


    useEffect(() => {
        const onLoginSuccessHandler = EventBus.on(LOGIN_SUCCESS, () => {
            toast.success(t(lang, "edi.login.notification.login_success"));
        });

        const onLoginFailedHandler = EventBus.on(LOGIN_FAILED, () => {
            toast.error(t(lang, "edi.login.notification.login_failed"));
        });

        dispatch(changeDashboardPath())

        return () => {
            EventBus.remove(LOGIN_SUCCESS, onLoginSuccessHandler);
            EventBus.remove(LOGIN_FAILED, onLoginFailedHandler);
        };
    }, []);

    if (isAuthorized)
        return <Redirect to="/"/>

    return (
        <>
            <Flex justifyContent="between" alignItems="center" className="mb-2 border-bottom pb-1">
                <h5>{t(lang, "edi.login.title")}</h5>
                <p className="fs--1 text-600 mb-0">
                    {t(lang, "edi.login.or")} <Link to="./register">{t(lang, "edi.login.register")}</Link>
                </p>
            </Flex>
            <Tabs defaultActiveKey={isLoginWithCertificate} className='border-bottom p-3 mb-2 d-flex justify-content-center'>
                <Tab eventKey={isWithUsername} title={t(lang, "authentication.login.with_username")}>
                    <LoginWithUsername/>
                </Tab>
                <Tab className="bg-light" eventKey={isWithCertificate} title={t(lang, "authentication.login.with_certificate")}>
                    <LoginWithCertificate/>
                </Tab>
            </Tabs>
        </>
    )
};

export default Login;