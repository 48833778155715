import React, {Fragment, useEffect, useState} from 'react';
import {Button, Form, Spinner} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import numeral from "numeral";
import ActionButton from "../../../../../common/ActionButton";
import SelectProduct from "../../../order/components/selects/SelectProduct";
import SelectBarcode from "../../../order/components/selects/SelectBarcode";
import SelectMeasurement from "../../../order/components/selects/SelectMeasurement";
import InputGroupWithCurrency from "../../../../price/components/InputGroupWithCurrency";
import classNames from "classnames";
import {selectItems} from "../../../../../../app/store/reducers/item/itemReducer";
import {useParams} from "react-router";
import {orderMeasurementOptions} from "../../orderWrapper";
import CustomerOrderDeleteItemButton from "./CustomerOrderDeleteItemButton";
import {selectCurrency} from "../../../../../../app/store/reducers/currency/currencyReducer";

const CrmCustomerOrderItemRowAdd = ({
                                        onDelete,
                                        arrayFieldName,
                                        index,
                                        item,
                                        productLoading,
                                        editingItemId,
                                        onEditItemId,
                                        loading,
                                        onSaveEditItem
                                    }) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const items = useSelector(selectItems);

    const {id} = useParams()

    const numberFormat = useSelector(selectNumberFormat);
    const currencies = useSelector(selectCurrency);

    const {register, formState: {errors}, watch, setValue} = useFormContext();

    const [selectedItem, setSelectedItem] = useState(null)

    const [barcodes, setBarcodes] = useState([])

    const rootName = `${arrayFieldName}.${index}`;
    const product = watch(`${rootName}.product`);
    const product_code = watch(`${rootName}.product_code`);
    const measurement = watch(`${rootName}.measurement`);
    const quantity = +watch(`${rootName}.quantity`);
    const barcode = +watch(`${rootName}.barcode`);
    const amount = +watch(`${rootName}.price.amount`);
    const currency_id = watch(`${rootName}.price.currency_id`);

    const total = `${rootName}.total`;

    let itemErrors = {};

    if (errors && errors[arrayFieldName] && errors[arrayFieldName][index]) {
        itemErrors = errors[arrayFieldName][index];
    }

    useEffect(() => {
        if (selectedItem) {
            setBarcodes(selectedItem?.item?.barcodes);

            setValue(`${rootName}.product`, selectedItem?.item)
            setValue(`${rootName}.barcode`, selectedItem?.item?.barcodes[0])
            setValue(`${rootName}.product_code`, selectedItem?.item?.code)
            setValue(`${rootName}.price.amount`, selectedItem?.price?.common_price?.amount)
            setValue(`${rootName}.price.currency_id`, selectedItem?.price?.common_price?.currency?.global_currency_id)
            setValue(`${rootName}.measurement`, selectedItem?.item?.measurement);
        }
    }, [selectedItem])

    useEffect(() => {
        if (item && item.product && item.product.barcodes) {
            setBarcodes(item.product.barcodes);
        }
    }, [item])

    useEffect(() => {
        setValue(total, numeral.formats[numberFormat].format((amount) * quantity));
    }, [quantity, amount])

    const onDeleteClicked = () => {
        onDelete(item);
    };

    return (
        <Fragment>
            <tr>
                <td className="p-1" style={{minWidth: '300px', maxWidth: '100%'}}>
                    {!item?.item_id || editingItemId?.id === item?.id ? <div>
                        <Controller
                            name={`${rootName}.product`}
                            rules={{required: t(lang, "crm.common.forms.validations.is_required")}}
                            render={({field}) => {
                                return (
                                    <SelectProduct
                                        defaultValue={items.find(product => product?.item?.id === field.value?.id)}
                                        productLoading={productLoading}
                                        itemErrors={itemErrors}
                                        onChange={field.onChange}
                                        setSelectedItem={setSelectedItem}
                                    />
                                )
                            }
                            }/>
                        <div className={'text-danger'}>
                            <small>{itemErrors?.product?.message}</small>
                        </div>
                    </div> : <span>{product?.name}</span>}
                </td>
                <td className="p-1" style={{minWidth: '180px', maxWidth: '100%'}}>
                    {!item?.item_id || editingItemId?.id === item?.id ? <Controller
                        name={`${rootName}.barcode`}
                        render={({field}) => {
                            return (
                                <SelectBarcode
                                    defaultValue={field.value}
                                    barcodes={barcodes}
                                    onChange={field.onChange}
                                />
                            )
                        }}
                    /> : <span>{barcode}</span>}
                </td>
                <td className="p-1" style={{minWidth: '180px', maxWidth: '100%'}}>
                    {!item?.item_id || editingItemId?.id === item?.id ? <Form.Control style={{height: 38}}
                                                                        placeholder={t(lang, "crm.common.items.product.product_code.placeholder")}
                                                                        {...register(`${rootName}.product_code`)}
                    /> : <span>{product_code}</span>}
                </td>
                <td className="p-1" style={{minWidth: '100px', maxWidth: '100%'}}>
                    {!item?.item_id || editingItemId?.id === item?.id ? <div>
                        <Controller name={`${rootName}.measurement`} rules={{
                            required: t(lang, "crm.common.forms.validations.is_required"),
                        }} render={({field}) => (
                            <SelectMeasurement
                                onChange={field.onChange}
                                defaultMeasurement={String(field.value)}
                                itemErrors={itemErrors}
                            />
                        )}/>
                        <Form.Control.Feedback type="invalid">
                            {itemErrors?.measurement?.message}
                        </Form.Control.Feedback>
                    </div> : <span>{orderMeasurementOptions.find(m=>m.value === measurement) ? t(lang, orderMeasurementOptions.find(m=>m.value === measurement).label) : measurement}</span>}
                </td>
                <td className="p-1">
                    {!item?.item_id || editingItemId?.id === item?.id ? <div>
                        <Form.Control placeholder={t(lang, "crm.common.items.product.quantity.placeholder")}
                                      className="p-1"
                                      style={{height: 38}}
                                      isInvalid={itemErrors?.quantity}
                                      {...register(`${rootName}.quantity`, {
                                          required: t(lang, "crm.common.forms.validations.is_required"),
                                          pattern: {
                                              value: /^[1-9](\d*)((.)\d+)?$/,
                                              message: t(lang, "crm.common.forms.validations.invalid_format")
                                          },
                                      })}
                        />

                        <Form.Control.Feedback type="invalid">
                            {itemErrors?.quantity?.message}
                        </Form.Control.Feedback>
                    </div> : <span>{numeral.formats[numberFormat].format(quantity)}</span>}
                </td>

                <td className="p-1" style={{maxWidth: '200px'}}>
                    {!item?.item_id || editingItemId?.id === item?.id ? <div>
                        <Controller
                            name={`${rootName}.price.amount`}
                            render={({field}) => {
                                return (
                                    <InputGroupWithCurrency
                                        defaultCurrencyId={currency_id}
                                        defaultPrice={amount}
                                        onChangePrice={(val) => {
                                            setValue(`${rootName}.price.amount`, val);
                                        }}
                                        onChangeCurrency={(currency) => {
                                            setValue(`${rootName}.price.currency_id`, currency?.id)
                                        }}
                                        inputProps={{
                                            className: 'form-control',
                                            placeholder: "Цена",
                                            options: {
                                                numeral: true,
                                                delimiter: ' '
                                            }
                                        }}
                                    />
                                )
                            }}
                        />
                    </div> : <span>{numeral.formats[numberFormat].format(amount)}  <small className={'text-info'}>{currencies.find(curr=>curr.id === currency_id)?.name}</small></span>}
                </td>

                <td className="p-1" style={{minWidth: '180px', maxWidth: '100%'}}>
                    {!item?.item_id || editingItemId?.id === item?.id ?
                        <Form.Control style={{height: 38}} {...register(`${rootName}.total`)} readOnly={true}
                                      className="p-1"/> :
                        <span>{numeral.formats[numberFormat].format(quantity * amount)} <small className={'text-info'}>{currencies.find(curr=>curr.id === currency_id)?.name}</small></span>}
                </td>

                <td className="text-end p-1">
                    {id && <span>{
                            !item?.item_id || editingItemId?.id === item?.id ?
                                <Button type="submit" className={'me-2'} variant="success"
                                        onClick={() => {
                                            onSaveEditItem(item)
                                        }}
                                        size="sm" name={index} disabled={loading}>
                                    {loading && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />}
                                    {t(lang, 'crm.orders.item.row.button.save')}
                                </Button> :
                                <ActionButton icon="edit" title="Изменить" variant="action" className="p-0 me-2"
                                              onClick={() => {
                                                  onEditItemId(item)
                                              }}/>
                        }
                    </span>}
                    <CustomerOrderDeleteItemButton item={item} onDelete={onDeleteClicked}/>
                </td>
            </tr>
        </Fragment>
    );
}

export default CrmCustomerOrderItemRowAdd;
