import React, {useEffect} from 'react';
import dayjs from "dayjs";
import numeral from "numeral";
import {useDispatch, useSelector} from "react-redux";
import {Card, Col, OverlayTrigger, Popover, Row, Table} from "react-bootstrap";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../app/store/reducers/main/mainReducer";
import {
    selectOrderContractDefineMethodStrategy,
    selectOrderExecutorCreateInvoiceStrategy,
    selectOrderExecutorEditStrategy,
    selectOrderItemQuantityLimitStrategy,
    selectOrderProductNameDefineMethodStrategy,
    selectOrderShipmentDateMethodStrategy
} from "../../../app/order/GetStrategies";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import ReturnOrderCustomerApprove from "./actions/ReturnOrderCustomerApprove";
import ReturnOrderExecutorApprove from "./actions/ReturnOrderExecutorApprove";
import ReturnOrderExecutorDecline from "./actions/ReturnOrderExecutorDecline";
import ReturnOrderExecutorCancelDecline from "./actions/ReturnOrderExecutorCancelDecline";
import {
    AwaitCustomerApprove,
    ExecutorEdited,
    AwaitExecutorApprove,
    ExecutorDeclined,
    getStatusColorReturnOrder, GetStatusTextReturnOrder
} from '../../../enum/ReturnOrderStatus'
import ReturnOrderStop from "./actions/ReturnOrderStop";
import {ExecutorApproved, InProgress} from "../../../enum/ReturnOrderStatus";
import ReturnOrderResume from "./actions/ReturnOrderResume";
import {Stopped} from "../../../enum/ReturnOrderStatus";
import {Link} from "react-router-dom";
import IconButton from '../../common/IconButton';
import {faPlus, faSync} from '@fortawesome/free-solid-svg-icons';
import ReturnShipmentDataTable from '../return-shipment/ReturnShipmentDataTable';
import {
    loadCountReturnShipments, loadReturnShipmentDataTableColumn,
    loadReturnShipments,
    selectFilterOptions,
    selectReturnShipmentDataTable
} from '../../../app/store/reducers/return-shipment/returnShipmentReducer';
import ReturnOrderProtocolInfo from "./ReturnOrderProtocolInfo";
import UploadReturnOrderToExcel from "./excel/UploadReturnOrderToExcel";
import ReturnOrderIdentifyItems from "./actions/ReturnOrderIdentifyItems";
import OrderExecutorItem from "../order/OrderExecutorItem";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {
    PermissionEdiReturnOrderCreate,
    PermissionEdiReturnShipmentCreate
} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";

const ReturnOrder = ({returnOrder}) => {
    const dispatch = useDispatch()
    const dateFormat = useSelector(selectDateDayJSFormat)
    const numberFormat = useSelector(selectNumberFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectFilterOptions)
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeColumns = useSelector(selectReturnShipmentDataTable)

    const subtotal = returnOrder.items.reduce((acc, item) => acc + item.total, 0)
    const tax = returnOrder.items.reduce((acc, item) => acc + item.nds_value, 0);

    const isExecutor = activeOrganization.inn === returnOrder.executor.inn;

    const allowCustomerApprove = activeOrganization.inn === returnOrder.customer.inn && (returnOrder.status === AwaitCustomerApprove || returnOrder.status === ExecutorEdited);
    const allowExecutorApprove = activeOrganization.inn === returnOrder.executor.inn && (returnOrder.status === AwaitExecutorApprove);
    const allowExecutorDecline = activeOrganization.inn === returnOrder?.executor?.inn && returnOrder?.status === AwaitExecutorApprove;
    const allowExecutorCancelDecline = returnOrder?.executor?.inn === activeOrganization.inn
        && returnOrder?.status === ExecutorDeclined
        && dayjs(returnOrder?.executor_approve?.declined_on, 'YYYY-MM-DD').format(dateFormat) === dayjs(new Date()).format(dateFormat);
    const allowStopReturnOrder = activeOrganization.inn === returnOrder?.executor?.inn &&
        (returnOrder.status === ExecutorApproved || returnOrder.status === InProgress);
    const allowResumeReturnOrder = returnOrder?.executor?.inn === activeOrganization.inn && returnOrder?.status === Stopped;
    const allowRegisterReturnShipment = activeOrganization.inn === returnOrder.customer.inn && (
        returnOrder.status === ExecutorApproved || returnOrder.status === InProgress
    );
    useEffect(() => {
        dispatch(loadCountReturnShipments({
            ...filters,
            return_order_id: returnOrder.id,
            viewer_inn: activeOrganization.inn
        }))
        dispatch(loadReturnShipments({...filters, return_order_id: returnOrder.id, viewer_inn: activeOrganization.inn}))
        dispatch(loadReturnShipmentDataTableColumn())
    }, [filters, activeOrganization])

    return (
        <>
            <Card className="my-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang, "edi.common.created.time")}: {returnOrder.created_at}</h3>
                    <Col className="text-end">
                        <span className="me-2">{t(lang, 'edi.common.label.status')}: </span>
                        <span className={`p-1 rounded-pill text-white fs--2`}
                              style={{backgroundColor: getStatusColorReturnOrder(returnOrder.status)}}>{t(lang, GetStatusTextReturnOrder(returnOrder.status))}</span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {allowRegisterReturnShipment &&
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon={faPlus}
                                iconClassName="me-1"
                                className="ms-2"
                                onClick={() => !dispatch(checkPermission(PermissionEdiReturnShipmentCreate)) && dispatch(toastPermission())}
                                as={dispatch(checkPermission(PermissionEdiReturnShipmentCreate)) ? Link : 'div'}
                                to={`/edi/return-shipment/register/${returnOrder.id}`}
                            >{t(lang, 'edi.return_order.new_shipment')}</IconButton>}
                        {allowCustomerApprove &&
                            <ReturnOrderCustomerApprove id={returnOrder.id} className="ms-2"/>
                        }
                        {allowExecutorApprove &&
                            <ReturnOrderExecutorApprove id={returnOrder.id} className="ms-2"/>
                        }
                        {allowResumeReturnOrder &&
                            <ReturnOrderResume id={returnOrder.id} className="ms-2"/>
                        }
                        {allowExecutorCancelDecline &&
                            <ReturnOrderExecutorCancelDecline id={returnOrder.id} className="ms-2"/>
                        }
                        {allowStopReturnOrder &&
                            <ReturnOrderStop id={returnOrder.id} className="ms-2"/>
                        }
                        {allowExecutorDecline &&
                            <ReturnOrderExecutorDecline id={returnOrder.id} className="ms-2"/>
                        }
                        <ReturnOrderProtocolInfo id={returnOrder.id} className="me-1"/>
                        <UploadReturnOrderToExcel className="mx-2" id={returnOrder.id}
                                                  number={returnOrder.info.number}/>
                        {isExecutor && <ReturnOrderIdentifyItems className="me-2 d-inline-block" id={returnOrder.id}/>}
                    </Col>
                </Row>
            </Card>

            <Card className="p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.executor')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {returnOrder.executor.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {returnOrder.executor.inn}</span>
                    </Col>

                    <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.customer')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {returnOrder.customer.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {returnOrder.customer.inn}</span>
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card className="my-3">
                <Card.Header>
                    <Row className="mb-3">
                        <h5 className="p-0 ms-3"><b className="pe-1">{t(lang, "edi.common.id")}:</b>{returnOrder.id}
                        </h5>

                        <Col xs={12} className="d-flex flex-column align-items-center justify-content-center">
                            <h3>{t(lang, 'edi.return_order.title')}</h3>
                            <b>№ {returnOrder.info.number} от {dayjs(returnOrder.info.date, "YYYY-MM-DD").format(dateFormat)}</b>
                            <b>{t(lang, "edi.common.to.contract")} № {returnOrder.contract.number} от {dayjs(returnOrder.contract.date, "YYYY-MM-DD").format(dateFormat)}</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6} className="fs--1 mb-3 mb-lg-0">
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.contract_number')}:</span>
                                <span className="col mb-1 ps-0">{returnOrder.contract.number}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.contract_date')}:</span>
                                <span
                                    className="col mb-1 ps-0">{dayjs(returnOrder.contract.date, 'YYYY-MM-DD').format(dateFormat)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.delivery_deadline')}:</span>
                                <span
                                    className="col mb-1 ps-0">{dayjs(returnOrder.expires_on, 'YYYY-MM-DD').format(dateFormat)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.delivery_address')}:</span>
                                <span className="col mb-1 ps-0">{returnOrder.branch.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.customer_system_id')}:</span>
                                <span className="col mb-1 ps-0">{returnOrder.customer_system_id}
                                    <hr className={returnOrder.customer_system_id ? 'm-0' : 'mb-0'}
                                        style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.label.customer_shipment_id')}:</span>
                                <span className="col mb-1 ps-0">{returnOrder.customer_shipment_id}
                                    <hr className={returnOrder.customer_shipment_id ? 'm-0' : 'mb-0'}
                                        style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="fs--1 mb-3 mb-lg-0">
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.executor_edit_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderExecutorEditStrategy(returnOrder.executor_edit_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.executor_invoice_creation_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderExecutorCreateInvoiceStrategy(returnOrder.executor_create_invoice_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.shipment_date_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderShipmentDateMethodStrategy(returnOrder.shipment_date_method_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.goods_name_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderProductNameDefineMethodStrategy(returnOrder.product_name_define_method_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.contract_info_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderContractDefineMethodStrategy(returnOrder.contract_define_method_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col mb-1 pe-0 fw-bold">{t(lang, 'edi.order.strategies.label.goods_quantity_strategy')}:</span>
                                <span
                                    className="col mb-1 ps-0">{t(lang, selectOrderItemQuantityLimitStrategy(returnOrder.item_quantity_limit_strategy).name)}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Table bordered responsive className="border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th rowSpan={2}>№</th>
                            <th className="text-start">{t(lang, 'edi.common.product')}</th>
                            <th className="text-center">{t(lang, 'edi.common.customer_system_id')}</th>
                            <th className="text-center">{t(lang, 'edi.common.customer_shipment_id')}</th>
                            <th className="text-center">{t(lang, 'edi.common.barcode')}</th>
                            <th className="text-center">{t(lang, 'edi.common.catalog_class_code')}</th>
                            <th className="text-center">{t(lang, 'edi.common.measurement')}</th>
                            <th className="text-center">{t(lang, 'edi.common.quantity')}</th>
                            <th className="text-center">{t(lang, 'edi.common.price')}</th>
                            <th className="text-center">{t(lang, 'edi.common.total')}</th>
                            <th className="text-center">{t(lang, 'edi.common.nds_rate')}</th>
                            <th className="text-center">{t(lang, 'edi.common.nds_value')}</th>
                            <th className="text-end">{t(lang, 'edi.common.total_with_nds')}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                            <th className="p-0 text-center">5</th>
                            <th className="p-0 text-center">6</th>
                            <th className="p-0 text-center">7</th>
                            <th className="p-0 text-center">8</th>
                            <th className="p-0 text-center">9</th>
                            <th className="p-0 text-center">10</th>
                            <th className="p-0 text-center">11</th>
                            <th className="p-0 text-center">12</th>
                        </tr>
                        </thead>
                        <tbody>
                        {returnOrder.items.map((item, index) => {
                            const showExecutorItem = isExecutor && item.executor_item
                            return <tr key={item.id} className="border-500">
                                <td>{index + 1}</td>
                                <td className="text-start text-nowrap fw-semi-bold">
                                    {showExecutorItem && <OrderExecutorItem item={item}/>}
                                    {!showExecutorItem && <span>{item.product}</span>}
                                </td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.customer_system_id}</td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.customer_shipment_id}</td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.barcode}</td>
                                <td className="text-center text-nowrap fw-semi-bold">{item.catalog_class_code}</td>
                                <td className="text-center">{item.measurement}</td>
                                <td className="text-center">-{item.quantity_left} / -{item.quantity}</td>
                                <td className="text-center">{numeral.formats[numberFormat].format(item.price)}</td>
                                <td className="text-center">-{numeral.formats[numberFormat].format(item.total)}</td>
                                <td className="text-center">{item.nds_rate}</td>
                                <td className="text-center">
                                    {item.nds_value > 0 && '-'}
                                    {numeral.formats[numberFormat].format(item.nds_value)}
                                </td>
                                <td className="text-end">-{numeral.formats[numberFormat].format(item.total_with_nds)}</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, 'edi.common.total')}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(subtotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900">{t(lang, 'edi.common.nds_value')}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(tax)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, 'edi.common.total_with_nds')}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(subtotal + tax)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>


            <ReturnShipmentDataTable orderLinkDisabled activeColumns={activeColumns}/>
        </>
    );
};

export default ReturnOrder;
