import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import ModalForm from "./ModalForm";
import {
    addTaskInColumn,
    selectKanbanItems,
    selectKanbanStatusItems
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import PropTypes from "prop-types";
import {useParams} from "react-router";
import dayjs from "dayjs";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import useQuery from "../../../hippo/roaming/invoice/form/useQuery";
import {useHistory, useLocation} from "react-router-dom";
import {ContractorDebtBoardType, ContractorDebtColumnType} from "../../../../enum/KanbanWrapper";
import {
    loadContractorAsync,
    updateCardContractor,
    updateContractorDebtPromiseDate
} from "../../../../app/store/reducers/contractor/contractorReducer";
import {toast} from "react-toastify";


const defaultTitle = `task.board.default.title`;

const FormTask = ({columnId, boardId, setShow, contractorId, handlerClose, fastAddTask, boardType}) => {

    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);

    const defaultCreator = +localStorage.getItem('defaultCreator');

    const methods = useForm({
        defaultValues: {
            type: boardType,
            title: t(lang, defaultTitle, {
                date: new Date().toLocaleDateString()
            }),
            board_column_id: null,
            description: t(lang, defaultTitle, {
                date: new Date().toLocaleDateString()
            }),
            creator_id: defaultCreator || null,
            assignee_id: null,
            contractor: {
                id: contractorId || null,
                debt_promise_date: contractorId ? new Date() : null
            },
            comments: []
        }
    })

    const onSubmit = (formData) => {
        // if (!formData.creator_id) return methods.setError('creator_id', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})
        if (formData.type === ContractorDebtBoardType) {
            if (!formData.contractor?.debt_promise_date || !formData.contractor.id) {
                if (!formData.contractor.id) {
                    return methods.setError('contractor.id', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})
                }
                if (!formData.contractor.debt_promise_date) {
                    return methods.setError('contractor.debt_promise_date', {
                        type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')
                    })
                }
            }
        } else {
            if (formData.contractor.debt_promise_date && !formData.contractor?.id) return methods.setError('contractor.id', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})
            if (formData.contractor.id && !formData.contractor?.debt_promise_date) return methods.setError('contractor.debt_promise_date', {type: 'required', message: t(lang, 'crm.common.forms.validations.is_required')})
        }
        setLoading(true)
        const data = {
            board_id: fastAddTask ? null : id,
            board_type: formData.type || boardType,
            board_column_id: columnId || null,
            board_column_type: fastAddTask ? ContractorDebtColumnType : null,
            title: formData.title,
            description: formData.description,
            creator_id: formData.creator_id?.id || formData.creator_id,
            assignee_id: formData.assignee_id?.id || formData.assignee_id || null,
            contractor: formData.contractor?.id ? {
                id: formData.contractor?.id || null,
                debt_promise_date: dayjs(formData.contractor?.debt_promise_date).format('YYYY-MM-DD') || null
            } : null,
        }

        dispatch(addTaskInColumn(data))
            .then(() => {
                toast.success(t(lang, 'common.toast.success'))
                handlerClose && handlerClose()
                setShow(false)
            })
            .catch(e => {
                toast.error(t(lang, 'task.board.modal.error.label.name'))
            })
            .finally(() => setLoading(false))
    }

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <ModalForm loading={loading} columnId={columnId}/>
            </Form>
        </FormProvider>
    );
};

FormTask.propTypes = {
    // id: PropTypes.number | PropTypes.string,
    setShow: PropTypes.func
}
export default FormTask;
