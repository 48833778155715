import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Card, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../app/store/reducers/main/mainReducer";
import CashBoxDataTable from "../../pages/main/cashbox/CashBoxDataTable";
import {totalAmountInCashBox} from "../../enum/cash_box/cashBoxWrapper";
import {selectCurrency} from "../../app/store/reducers/currency/currencyReducer";
import numeral from "numeral";
import {selectedCurrencyForCashBox} from "../../app/store/reducers/cashbox/CashboxReducer";
import SpinnerComponent from "../hippo/spinner/SpinnerComponent";

export const cashBoxViewPage = (displayName) => {
    const CashBoxViewPage = ({request, title, id}) => {
        // const {id} = useParams()
        const lang = useSelector(selectLang)
        const t = Translate
        const currency = useSelector(selectCurrency)
        const selectedCurrency = useSelector(selectedCurrencyForCashBox)
        const numberFormat = useSelector(selectNumberFormat);

        const [data, setData] = useState(null)
        const [loading, setLoading] = useState(false)

        useEffect(() => {
            setLoading(true)
            request(id)
                .then(res => {
                    setData(res)
                })
                .catch(() => setData(null))
                .finally(() => setLoading(false))
        }, [])

        if (!data) return <SpinnerComponent />

        return <Fragment>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={12}>
                            <h4 className={'text-center'}>
                                {/*<span className={'text-600 fs-0'}>{t(lang, "cash_boxes.name.cash_box")}: </span>*/}
                                <span className={'text-primary fw-bold'}>{data?.name ? data?.name : data?.cash_box?.name}</span>
                            </h4>
                        </Col>
                        <Col xs={12} md={6}>
                            <h5 className="text-600 text-start">
                                {t(lang, "items.common.category.created_at")} {data?.created_at}
                            </h5>
                        </Col>
                        <Col xs={12} md={6}>
                            <h5 className="text-600 text-end">
                                {t(lang, "roaming.invoice.updated_at")} {data?.updated_at}
                            </h5>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>

            <Card className={'mt-3'}>
               <Card.Header>
                   <Row>
                       <Col xs={data?.account?.name ? 6 : 12}>
                           <h4 className={'text-info fw-bold'}>
                               {t(lang, title)}
                           </h4>
                       </Col>
                       {data?.account?.name && <Col xs={6}>
                           <h4 className={'text-600 text-end'}>
                               {t(lang, "task.board.member")}: {data?.account?.name}
                           </h4>
                       </Col>}
                   </Row>
               </Card.Header>

                <Card.Body className={'py-0'}>
                    <CashBoxDataTable data={data} />
                </Card.Body>

                <Card.Footer className={'pt-0'}>
                    {data?.notes &&  <div>
                        <span className={'fs--1'}>{t(lang, "edi.common.modal.description")}</span>
                        <p className={'text-600 fs-0 fw-bold ms-2'}>
                            {data.notes}
                        </p>
                    </div>}

                    <div className={'fw-bold fs-1 text-end'}>
                        <span className={'text-700'}>
                            {numeral['formats'][numberFormat].format(totalAmountInCashBox(data.amounts, currency, true) / selectedCurrency?.rate)}
                        </span>
                        <strong className={'text-info ms-2 fw-bold'}>{selectedCurrency?.name}</strong>

                        <span className={'d-block fs--1'}>{t(lang, "crm.data.table.total.sum")}</span>
                    </div>
                </Card.Footer>
            </Card>
        </Fragment>
    }

    CashBoxViewPage.displayName = displayName || 'CashBoxViewPage'
    return CashBoxViewPage
}
