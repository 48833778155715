import {useParams} from "react-router";
import ActDataTable from "../../../../components/hippo/roaming/act/ViewDataTable/ActDataTable";
import {useDispatch, useSelector} from "react-redux";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActView} from "../../../../enum/Permission/RoamingPermission";
import {Card} from "react-bootstrap";
import React from "react";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const Acts = () => {
    const { type_viewer } = useParams()
    const dispatch = useDispatch()
    const t = Translate
    const lang = useSelector(selectLang);
    //important
    useSelector(selectAccountRolePermissions)

    if(!dispatch(checkPermission(PermissionRoamingActView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.roaming.act.datatable.no_access.title')}</h4>
        </Card>
    }

    return  <ActDataTable typeViewer={type_viewer}/>

};

export default Acts;
