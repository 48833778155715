import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {REPORT} from "../payment/PaymentTab";
import {useSelector} from "react-redux";
import {loadReportData,} from "../../../../app/store/reducers/billing/billingReducer";
import dayjs from "dayjs";
import classNames from "classnames";
import ContactSourceItem from "../../../../components/common/ContactSourceItem";
import MostLeadsChart from "../../../../components/common/MostLeadsChart";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import DatePicker from "react-datepicker";
import AdminReportDataTable from "./AdminReportDataTable";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";


const today = new Date();
const startOfMonth = dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DD');
const endOfMonth = dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format('YYYY-MM-DD');

export const contactSourceColors = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
    'dark',
];

const AdminReport = ({tab}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat);

    const [loading, setLoading] = useState(false)
    const [reportData, setReportData] = useState({})
    const [reportFilter, setReportFilter] = useState({
        date_start: startOfMonth,
        date_end: endOfMonth,
    })

    const [viewer, setViewer] = useState(null)
    const viewerDataCount = reportData?.services?.find(item => item.name === viewer)
    const viewerTotalCount  =  viewerDataCount?.active_users_count + viewerDataCount?.demo_users_count || 0
    const reportTotalCount  =  reportData?.active_users_count + reportData?.demo_users_count || 0
    const viewerOrganizationsData = viewer
        ? [
            ...(reportData?.services?.find(item => item.name === viewer)?.packages || []).flatMap(item =>
                (item.organizations || []).map(org => ({
                    ...org,
                    package_name: item.name
                }))
            ),
            ...(reportData?.services?.find(item => item.name === viewer)?.demo_packages || []).flatMap(item =>
                (item.organizations || []).map(org => ({
                    ...org,
                    package_name: item.name
                }))
            )
        ]
        : [
            ...(reportData?.services || []).flatMap(service =>
                (service.packages || []).flatMap(item =>
                    (item.organizations || []).map(org => ({
                        ...org,
                        package_name: item.name
                    }))
                )
            ),
            ...(reportData?.services || []).flatMap(service =>
                (service.demo_packages || []).flatMap(item =>
                    (item.organizations || []).map(org => ({
                        ...org,
                        package_name: item.name
                    }))
                )
            )
        ];

    useEffect(() => {
        if (tab === REPORT) {
            setLoading(true)
            loadReportData({...reportFilter})
                .then(response => {
                    setReportData(response)
                })
                .catch(error => {
                    console.error(setReportData({}))
                })
                .finally(() => setLoading(false))
        }
    }, [tab, reportFilter]);

    const handleStartDateChange = (date) => {
        setReportFilter((prevState) => ({
            ...prevState,
            date_start: date ? dayjs(date).format('YYYY-MM-DD') : null,
        }));
    };

    const handleEndDateChange = (date) => {
        setReportFilter((prevState) => ({
            ...prevState,
            date_end: date ? dayjs(date).format('YYYY-MM-DD') : null,
        }));
    };

    if (loading) {
        return <SpinnerComponent />
    }

    return (
        <Fragment>
            <Card>
                <Card.Header className={'border-bottom bg-light'}>
                    {/*<h3>{t(lang, 'admin.navbar.routes.report')}</h3>*/}
                    <Row>
                        <Form.Group as={Col} md={4} lg={3}>
                            <Form.Label column={true}>{t(lang, 'admin.navbar.routes.report.filter.date_start')}</Form.Label>
                            <DatePicker
                                selected={new Date(reportFilter.date_start)}
                                onChange={handleStartDateChange}
                                dateFormat={dateFormat}
                                selectsStart
                                className="form-control"
                                id="start_date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={4} lg={3}>
                            <Form.Label column={true}>{t(lang, 'admin.navbar.routes.report.filter.date_end')}</Form.Label>
                            <DatePicker
                                selected={new Date(reportFilter.date_end)}
                                onChange={handleEndDateChange}
                                selectsEnd
                                dateFormat={dateFormat}
                                className="form-control"
                                id="end_date"
                            />
                        </Form.Group>
                    </Row>
                </Card.Header>
                <Card.Body className={'py-0'}>
                    <Row className={'align-items-center'}>
                        <Col xs={12} sm={8} className="mb-md-3 mb-lg-0" style={{ minHeight: '300px' }}>
                            <div className={'d-flex align-items-center justify-content-between gap-2 mt-3'}>
                                <h5>{t(lang, 'admin.navbar.routes.report.total_users_count')}: {viewer ? viewerDataCount?.total_users_count : reportData?.total_users_count}</h5>
                                <h5>{t(lang, 'admin.navbar.routes.report.active_users_count')}: {viewer ? viewerDataCount?.active_users_count : reportData?.active_users_count}</h5>
                                <h5>{t(lang, 'admin.navbar.routes.report.demo_users_count')}: {viewer ? viewerDataCount?.demo_users_count : reportData?.demo_users_count}</h5>
                            </div>
                            <hr className={'mb-0'} />
                            <Row className="g-0">
                                {reportData?.services?.map((item, index) => (
                                    <Col xs={6} xl={4} key={item.name} className={'cursor-pointer'}
                                         onClick={() => setViewer(viewer === item.name ? null : item.name)}>
                                        <ContactSourceItem
                                            value={item.total_users_count}
                                            label={item.name}
                                            color={contactSourceColors[index]}
                                            className={classNames('border-translucent border-bottom', {
                                                'bg-soft-info': viewer === item.name,
                                            })}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col sm={4} md={4} className="my-3 my-sm-0" style={{ height: '300px'}}>
                            <h5 className={'text-center text-facebook'}>
                                {viewer || t(lang, 'admin.navbar.routes.general')}:
                                <span className={'fw-bolder ms-2'}>{viewerDataCount ? viewerTotalCount : reportTotalCount}</span>
                            </h5>
                            <div className="position-relative d-flex flex-center mb-sm-4 mb-xl-0 echart-contact-by-source-container mt-sm-7 mt-lg-4 mt-xl-0">
                                <MostLeadsChart data={reportData?.services} viewer={viewer} style={{ height: '100%', width: '100%' }} />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Header className={'pt-0'}>
                    <AdminReportDataTable data={viewerOrganizationsData} filter={reportFilter}
                                          setFilter={setReportFilter}/>
                </Card.Header>
            </Card>
        </Fragment>
    );
};

export default AdminReport;