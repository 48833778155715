import ContractorAgentDatatable from "../../../../components/hippo/crm/contractor/agent/ContractorAgentDatatable";



const ContractorAgents = () => {

    return(
        <>
            <ContractorAgentDatatable/>
        </>
    )
}


export default ContractorAgents;