import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../app/store/reducers/main/mainReducer";
import {Table} from "react-bootstrap";
import dayjs from "dayjs";
import numeral from "numeral";
import {measurements} from "../../item/enum/itemWrapper";

const InternalActView = ({invoice}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const getMeasure = id => measurements.find(x => x.measureId === id) || null

    return (
        <Fragment>
            <h3 className={'text-center'}>{t(lang, 'roaming.invoice.internal.act')}</h3>
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-around">
                <div>
                    <p className="mb-1">
                        <span className="fs-0 fw-bold mb-1">{t(lang, "roaming.act_form.act_number")}: </span>
                        <span>{invoice?.info?.number}</span>
                    </p>
                    <p className="mb-1">
                        <span className="fs-0 fw-bold mb-1">{t(lang, "roaming.act_form.act_date")}: </span>
                        <span>{dayjs(invoice?.info?.date, 'YYYY-MM-DD').format(dateFormat)}</span>
                    </p>
                </div>
                <div>
                    <p className="mb-1">
                        <span className="fs-0 fw-bold">{t(lang, "roaming.act_form_contract_number")}: </span>
                        <span>{invoice?.contract?.number}</span>
                    </p>
                    <p className="mb-1">
                        <span className="fs-0 fw-bold">{t(lang, "roaming.act_form_contract_date")}: </span>
                        <span>{dayjs(invoice?.contract?.date, 'YYYY-MM-DD').format(dateFormat)}</span>
                    </p>
                </div>
            </div>
            <div className="d-flex flex-column mb-3">
                <span className="pe-0 my-2 fw-bold text-center">{t(lang, "roaming.act_form_act_comment")}:</span>
                <span className="text-center">{invoice?.internal_act?.description || invoice?.internal_act?.Description}</span>
                <hr/>
            </div>

            <Table bordered responsive className="border-bottom fs--1 border-500">
                <thead className="text-dark bg-200">
                <tr>
                    <th rowSpan={2}>№</th>
                    <th className="text-start">{t(lang, "edi.common.items.product.catalog_class_code")}</th>
                    <th className="text-center">{t(lang, "edi.common.items.product.catalog_class_name")}</th>
                    <th className="text-center">{t(lang, "edi.common.items.product")}</th>
                    <th className="text-center">{t(lang, "edi.common.measurement")}</th>
                    <th className="text-center">{t(lang, "edi.common.items.product.quantity")}</th>
                    <th className="text-center">{t(lang, "edi.common.items.product.price")}</th>
                    <th className="text-end">{t(lang, "edi.common.items.product.total")}</th>
                </tr>
                <tr>
                    <th className="p-0 text-center">1</th>
                    <th className="p-0 text-center">2</th>
                    <th className="p-0 text-center">3</th>
                    <th className="p-0 text-center">4</th>
                    <th className="p-0 text-center">5</th>
                    <th className="p-0 text-center">6</th>
                    <th className="p-0 text-center">7</th>
                </tr>
                </thead>
                <tbody>
                {invoice.items.map((item, index) => {
                    return (
                        <tr key={item.ordering_number}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-start">{item?.catalog?.code}</td>
                            <td className="text-center">{item?.catalog?.name}</td>
                            <td className="text-center">{item?.name}</td>
                            <td className="text-center">{item.measure_id ? getMeasure(item.measure_id)?.name : item.package?.name}</td>
                            <td className="text-center">{item?.quantity}</td>
                            <td className="text-center">{numeral.formats[numberFormat].format(+item?.price)}</td>
                            <td className="text-end">{numeral.formats[numberFormat].format(item?.total)}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </Fragment>
    );
};

export default InternalActView;