import numeral from "numeral";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import ItemRow from "./ItemRow";
import {useFieldArray, useFormContext} from "react-hook-form";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Button, ButtonToolbar, Card, Col, FormControl, Row, Table} from "react-bootstrap";
import {DefineByExecutor} from '../../../../app/order/OrderProductNameDefineMethodStrategyType'

const itemsFieldName = 'items';

const ReturnShipmentItemsForm = ({order}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const [query, setQuery] = useState('');
    const {control ,setValue, watch} = useFormContext();
    const {fields, update} = useFieldArray({control ,name: 'items', keyName: 'index'});
    const lang = useSelector(selectLang);
    const t = Translate;

    const itemsFieldsArray = watch('items');
    const allowEditProductName = DefineByExecutor.id === order.product_name_define_method_strategy;

    const subtotal = itemsFieldsArray.reduce((acc,item) => acc + item.total ,0);
    const tax = itemsFieldsArray.reduce((acc,item) => acc + item.nds_value ,0);
    const totalWithNds = subtotal + tax;

    const filteredItems = fields.filter((item) => item.product.toLowerCase().includes(query.toLowerCase()));

    useEffect(() => {
        setValue('totalWithNds', totalWithNds)
    }, [itemsFieldsArray])

    const resetQuantity = () => {
        fields.forEach((field, index) => {
            field.quantity = 0;
            update(index, field);
        })
    }
    const restoreQuantity = () =>   {
        fields.forEach((field, index) => {
            field.quantity = order?.items?.[index]?.quantity;
            update(index, field);
        })
    }

    return (
        <>
            <Card className="mt-3">
                <Card.Header>
                    <Card.Title>{t(lang, 'edi.return_shipment.shipment_items.title')}</Card.Title>
                </Card.Header>
                <Card.Body className="bg-light">
                    <Row>
                        <Col xs="12" sm="auto" className="order-1 order-sm-0">
                            <FormControl size="sm" type="text" placeholder={t(lang, 'edi.common.form.search_input.placeholder')} onChange={(e) => setQuery(e.target.value.trim())} />
                        </Col>
                        <Col xs="auto" sm="6" md="auto" className="ms-auto mb-3 mb-sm-0">
                            <ButtonToolbar className="d-grid d-md-block gap-2 float-end">
                                <Button size="sm" className="mx-1" onClick={() => restoreQuantity()}>{t(lang, 'edi.return_shipment.shipment_items.restore_all_quantities')}</Button>
                                <Button size="sm" className="mx-1" variant="danger" onClick={() => resetQuantity()}>{t(lang, 'edi.return_shipment.shipment_items.reset_all_quantities')}</Button>
                            </ButtonToolbar>
                        </Col>
                        <Col xs="12" className="order-2">
                            <hr/>
                        </Col>
                    </Row>

                    <Table className="fs--1" responsive striped>
                        <thead>
                        <tr>
                            <th className="px-1">#</th>
                            <th className="px-1">{t(lang, 'edi.common.product')}</th>
                            {/*<th className="px-1">{t(lang, 'edi.common.customer_shipment_id')}</th>*/}
                            <th className="px-1">{t(lang, 'edi.common.barcode')}</th>
                            <th className="px-1">{t(lang, 'edi.common.catalog_class_code')}</th>
                            <th className="px-1">{t(lang, 'edi.common.measurement')}</th>
                            <th className="px-1">{t(lang, 'edi.common.quantity')}</th>
                            <th className="px-1">{t(lang, 'edi.common.price')}</th>
                            <th className="px-1">{t(lang, 'edi.common.total')}</th>
                            <th className="px-1">{t(lang, 'edi.common.nds_rate')}</th>
                            <th className="px-1">{t(lang, 'edi.common.nds_value')}</th>
                            <th className="px-1 text-end">{t(lang, 'edi.common.total_with_nds')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredItems.map((item, index) => (
                            <ItemRow key={item.id} item={item} index={index} arrayFieldName={itemsFieldName} update={update} allowEditProductName={allowEditProductName} />
                        ))}
                        </tbody>
                    </Table>

                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900 px-1">{t(lang, 'edi.common.total')}:</th>
                                    <td className="fw-semi-bold px-1">
                                        {numeral.formats[numberFormat].format(subtotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900 px-1">{t(lang, 'edi.common.nds_rate')}:</th>
                                    <td className="fw-semi-bold px-1">
                                        {numeral.formats[numberFormat].format(tax)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900 px-1">{t(lang, 'edi.common.total_with_nds')}:</th>
                                    <td className="fw-semi-bold px-1">
                                        {numeral.formats[numberFormat].format(subtotal + tax)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Button tabIndex={itemsFieldsArray.length + 1} className="float-end" variant="primary" type="submit">{t(lang, 'edi.common.button.create')}</Button>
                </Card.Footer>
            </Card>
        </>
    );
};

export default ReturnShipmentItemsForm;