import React, {useRef} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import SoftBadge from "../../../../common/SoftBadge";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {isNdsPayerCertificateActive, isNdsPayerCertificatePending} from "../../../../../enum/RoamingInvoiceWrapper";


const pinflFormatRegEx = /^\d{14}$/
const RoamingInvoiceVatRegStatusBadge = ({vatRegStatus, vatRegCode, identifier}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);

    const getBadgeText = (identifier, vatRegCode, vatRegStatus) => {
        if (pinflFormatRegEx.test(identifier) && !vatRegCode && !vatRegStatus)
            return t(lang, 'roaming.invoice.nds_status.person');

        if (vatRegCode && vatRegStatus === isNdsPayerCertificateActive)
            return t(lang, 'roaming.invoice.nds_status.20');

        if (vatRegCode && vatRegStatus === isNdsPayerCertificatePending)
            return t(lang, 'roaming.invoice.nds_status.22');

        return t(lang, 'roaming.invoice.nds_status.default');
    };

    const badgeText = getBadgeText(identifier, vatRegCode, +vatRegStatus);

    return (
        <OverlayTrigger trigger={['hover', 'focus']}
                        placement={'right'}
                        overlay={
                            <Popover className='ms-0'>
                                <Popover.Body>{t(lang, 'roaming.common.nds_status')}</Popover.Body>
                            </Popover>
                        }
                        target={ref.current}
        >
            <span ref={ref}>{badgeText}</span>
        </OverlayTrigger>
    );
};

export default RoamingInvoiceVatRegStatusBadge;