import ReturnPurchaseDatatable from "../../../../components/hippo/warehouse-operation/return-purchase/datatable/ReturnPurchaseDatatable";


const ReturnPurchases = () => {



    return(
        <>
            <ReturnPurchaseDatatable />
        </>
    )
}

export default ReturnPurchases;