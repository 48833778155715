import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from "react-router-dom";
import { toast } from 'react-toastify';
import EventBus from '../../../../app/eventbus/EventBus';
import {loadInvoiceAsync} from "../../../../app/store/reducers/invoice/invoiceReducer";
import Invoice from "../../../../components/hippo/invoice/Invoice";
import {
    CANCEL_INVOICE_SUCCESS,
    DECLINE_INVOICE_SUCCESS,
    DELETE_INVOICE_SUCCESS,
    SIGN_INVOICE_SUCCESS
} from "../../../../app/eventbus/invoiceEvents";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {setEdiBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiInvoiceView} from "../../../../enum/Permission/EdiPermission";

const ViewInvoice = () => {
    const {params: {id}} = useRouteMatch();
    const [invoice, setInvoice] = useState(null);
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiInvoiceView))) {
            if (history.length > 1) {
                history.push('../invoices')
            } else {
                history.push('/')
            }
        }

    }, [permission])

    useEffect(() => {
        const signSuccessHandler = EventBus.on(SIGN_INVOICE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.sign.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            loadInvoice()
        });


        const cancelSuccessHandler = EventBus.on(CANCEL_INVOICE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.cancel.notification.success"))
            loadInvoice()
        })

        const declineSuccessHandler = EventBus.on(DECLINE_INVOICE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            loadInvoice()
        })

        const invoiceDeletedHandler = EventBus.on(DELETE_INVOICE_SUCCESS, () => {
            loadInvoice()
        })

        return () => {
            EventBus.remove(SIGN_INVOICE_SUCCESS, signSuccessHandler)
            EventBus.remove(CANCEL_INVOICE_SUCCESS, cancelSuccessHandler)
            EventBus.remove(DECLINE_INVOICE_SUCCESS, declineSuccessHandler)
            EventBus.remove(DELETE_INVOICE_SUCCESS, invoiceDeletedHandler)
        }
    }, [])

    useEffect(() => {
        loadInvoice();
    }, [id])

    const loadInvoice = () => {
        loadInvoiceAsync(id)
            .then(invoice => {
                dispatch(checkPermission(PermissionEdiInvoiceView)) && dispatch(setEdiBreadcrumbData(invoice))
                setInvoice(invoice)
            })
            .catch(error => console.log(error))
    }

    return (
        <>
            {invoice && <Invoice invoice={invoice} />}
            {!invoice && <span>loading....</span>}
        </>
    );
};

export default ViewInvoice;
