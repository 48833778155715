import {Col, Row} from "react-bootstrap";
import React from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const ContractorReturnOrderDataTableHeader = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    return (<Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.return_order.datatable.header.title")}</h5>
        </Col>
    </Row>)
}
export default ContractorReturnOrderDataTableHeader;