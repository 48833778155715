import classNames from "classnames";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import IconButton from "../../../common/IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import React, {cloneElement, Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SearchItem from "../../draft-item/components/SearchItem";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const BindDraftOperationItemModal = ({isProcessing, operationItemId, onItemBinded, children}) => {
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const form = useForm({
        defaultValues: {
            itemId: null
        },
    });

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = (formData) => {
        onItemBinded({itemId: formData.itemId, operationItemId: operationItemId});
        handleClose();
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton
                    icon={'layer-group'}
                    onClick={handleShow}
                    variant="falcon-primary"
                >
                    {t(lang, 'Существующий')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose} size="lg">
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'warehouse-operation.draft_operation.bind_draft_item_modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse-operation.draft_operation.bind_draft_item_modal.field.item')}</Form.Label>
                            <Controller
                                name="itemId"
                                control={form.control}
                                rules={{required: t(lang, 'items.common.validations.is_required')}}
                                render={({field}) => (
                                    <SearchItem
                                        defaultItemId={field.value}
                                        onChange={(option) => field.onChange(option ? option.item.id : null)}
                                        selectProps={{
                                            className: classNames({
                                                'is-invalid': form.formState.errors?.itemId
                                            })
                                        }}
                                    />
                                )}
                            />
                            <ErrorMessage
                                name='itemId'
                                errors={form.formState.errors}
                                as={<Form.Control.Feedback type="invalid"/>}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>{t(lang, "warehouse.operation.item.common.close")}</Button>
                        <Button variant="primary" type="submit" disabled={isProcessing}>
                            {isProcessing && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                            {t(lang, "warehouse.operation.item.common.button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default BindDraftOperationItemModal;