import React from "react";
import {Form} from "react-bootstrap";
import {changeDateFormat, DateFormatOptions, selectDateFormat} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";


const DateFormatChooser = () => {
    const dateFormat = useSelector(selectDateFormat);
    const dispatch = useDispatch();
    const now = new Date();

    const onChange = (dateFormat) => {
        dispatch(changeDateFormat(dateFormat));
    }

    return (
        <>
            <li className="me-2 d-md-block list-inline">
                <Form.Select size="sm"
                             value={dateFormat}
                             onChange={(event) => onChange(event.target.value)}
                >
                    {DateFormatOptions.map(dateFormat => {
                        return (
                            <option value={dateFormat} key={dateFormat}>
                                {dayjs(now).format(dateFormat.toUpperCase())}
                            </option>
                        )
                    })}
                </Form.Select>
            </li>
        </>
    )
}

export default DateFormatChooser


