import React from "react";
import {Spinner, ProgressBar, Col, Row, Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
const CountableProgressBar = ({show, results, length, signed, onClose}) => {
  const loading = results.length !== length;
  const lang = useSelector(selectLang);
  const t = Translate;

  const onCloseHandler = () => {
    onClose();
  }

  const styleCountableProgressBar = {
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    background: "rgb(255,255,255)",
    zIndex: 1030,
  };
  return (
    <>
      {show && (
        <div
          className="position-fixed d-flex flex-column justify-content-center align-items-center"
          style={styleCountableProgressBar}
        >
          {loading && (
              <Spinner
                  animation="border"
                  className="mb-3"
                  variant="primary"
                  style={{width: "65px", height: "65px"}}
              />
          )
          }
          <div className="mt-5 w-100 w-sm-75 w-xl-50 px-sm-0 px-3">
            <ProgressBar animated={loading} now={(100 / length) * results.length} style={{ height: 30 }} className="border border-400 shadow" variant="primary"/>
          </div>
          <Row className="g-3 mt-3 w-100 justify-content-center">
            <Col xl={1} xs={12} sm={4} className="text-center">
              <h4 className="fs-4 fw-medium text-700">
                <p className="text-primary">{(results.filter(res => res === true)).length}</p>
              </h4>
              <h6 className="text-primary">( Успешно )</h6>
            </Col>
            <Col xl={2} xs={12} sm={4} className="text-center">
              <h4 className="fs-4 fw-medium text-700">
                <p>{results.length} из {length}</p>
              </h4>
              {!!signed && <h6 className="text-warning">( {signed} из выбранных уже подписаны )</h6>}
              <h6>( Общий )</h6>
            </Col>
            <Col xl={1} xs={12} sm={4} className="text-center">
              <h4 className="fs-4 fw-medium text-700">
                <p className="text-danger">{(results.filter(res => res === false)).length}</p>
              </h4>
              <h6 className="text-danger">( Неудачно )</h6>
            </Col>
          </Row>
          <div className="modal-footer position-fixed bottom-0 w-100">
            <Button onClick={onCloseHandler}>{t(lang, 'edi.common.button.close')}</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CountableProgressBar;
