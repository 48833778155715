import React from 'react';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {
    selectContractorInvoiceFilterOptions,
    changeFilterContractorInvoicesAsync
} from "../../../../../../app/store/reducers/contractor/contractorInvoiceReducer";
import ContractorInvoiceDataTableFilter from "./ContractorInvoiceDataTableFilter";

const ContractorInvoiceDataTableHeader = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectContractorInvoiceFilterOptions);
    const methods = useForm({
        defaultValues: {
            date_start: filters.date_start ? dayjs(filters.date_start).toDate() : null,
            date_end: filters.date_end ? dayjs(filters.date_end).toDate() : null,
            contract_number: filters.contract_number || null,
            contract_date: filters.contract_date ? dayjs(filters.contract_date).toDate() : null,
        },
    });
    const onSubmitFilter = (data) => {
        dispatch(changeFilterContractorInvoicesAsync({
            ...filters,
            date_start: data.date_start ? dayjs(data.date_start).format("YYYY-MM-DD") : null,
            date_end: data.date_end ? dayjs(data.date_end).format("YYYY-MM-DD") : null,
            contract_number: data.contract_number || null,
            contract_date: data.contract_date ? dayjs(data.contract_date).format("YYYY-MM-DD") : null,
        }))
    }

    return (<>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                    <ContractorInvoiceDataTableFilter methods={methods}/>
                </Form>
            </FormProvider>
        </>
    );
};

export default ContractorInvoiceDataTableHeader;
