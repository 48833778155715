import React, {useState} from 'react';
import IconButton from "../../../common/IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {faCoins} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {applyCreditIntoOrganizationAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {toast} from "react-toastify";

const ApplyCredit = ({id}) => {
    const {handleSubmit, formState: {errors}, reset, control} = useForm({
        defaultValues: {
            amount: ""
        }
    });
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => {
        reset();
        setShow(false)
    };
    const handleShow = () => setShow(true);


    const onSubmit = ({amount}) => {
        setLoading(true);
        applyCreditIntoOrganizationAsync({id, amount})
            .then(() => {
                toast.success(t(lang, 'admin.organization.toast.apply_credit_success'));
                handleClose()
            })
            .catch((error) => {
                toast.error(t(lang, 'admin.organization.toast.apply_credit_failed'));
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <>
            <IconButton onClick={handleShow} size="sm" icon={faCoins} variant="warning" className="mt-2">
                {t(lang, 'admin.organization.apply_credit_button.title')}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t(lang, 'admin.organization.apply_credit_modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'admin.organization.apply_credit_modal.label')}</Form.Label>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required"),
                                }}
                                render={({field}) => (
                                    <Cleave
                                        value={field.value}
                                        onChange={(event) => field.onChange(event.target.rawValue)}
                                        options={{numeral: true, delimiter: ' ', numeralPositiveOnly: true}}
                                        className={classNames('form-control', {'is-invalid': errors?.amount})}
                                        placeholder={t(lang, 'admin.organization.apply_credit_modal.placeholder')}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.amount?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, 'admin.organization.add_organization_balance.modal.button.back')}
                        </Button>
                        <Button variant="warning" type="submit">
                            {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                            {t(lang, 'admin.organization.apply_credit')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>

    );
};

ApplyCredit.propTypes = {
    id: PropTypes.string
}

ApplyCredit.defaultProps = {
    id: ""
}

export default ApplyCredit;