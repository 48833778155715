import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {getOrder} from "../../../../app/store/reducers/crm/order/crmCustomerOrderReducer";
import ViewOrder from "../../../../components/hippo/crm/order/components/ViewOrder";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CRM_ORDER_IDENTIFY_ITEMS_SUCCESS,
} from "../../../../app/eventbus/crmOrderEvents";
import {
    acceptOrder, completeOrder, declineOrder, newOrder, progressOrder
} from "../../../../app/store/reducers/crm/order/crmExecutorOrderReducer";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const CrmViewOrder = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const {id} = useParams()
    const [order, setOrder] = useState(null);
    const loadOrder = () => {
        getOrder(id).then(res => {
            setOrder(res);
        }).catch(err => {
        })
    }

    useEffect(() => {
        loadOrder()

        const onCrmOrderIdentifyItemsSuccess = EventBus.on(CRM_ORDER_IDENTIFY_ITEMS_SUCCESS, crmOrder => {
            loadOrder()
        });
        return () => {
            EventBus.remove(CRM_ORDER_IDENTIFY_ITEMS_SUCCESS, onCrmOrderIdentifyItemsSuccess);
        }
    }, [])

    const onChangeStatus = (status) => {
        if (!status)
            return;

        switch (status.value) {
            case -1:
                declineOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
            case 0:
                newOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
            case 1:
                acceptOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
            case 2:
                progressOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
            case 3:
                completeOrder(id).then(r => {
                    loadOrder()
                    toast.success(t(lang, 'crm.common.toast.success'));
                }).catch(err => {
                    toast.error(t(lang, 'crm.common.toast.error'));
                });
                break;
        }
    }


    return (
        <ViewOrder
            order={order}
            onChangeStatus={onChangeStatus}
        />
    );
};

export default CrmViewOrder;
