import React, {useEffect} from 'react';
import KanbanDatatable from "../../../../components/kanban/datatable/KanbanDatatable";
import {
    getBoardOrganizationAsync,
    getBoardOrganizationCountAsync, selectBoardsFilter
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    CHANGE_BOARD_NAME_FAIL,
    CHANGE_BOARD_NAME_SUCCESS, DELETE_BOARD_SUCCESS,
    REGISTER_BOARD_FAIL,
    REGISTER_BOARD_SUCCESS
} from "../../../../app/eventbus/kanban/KanbanEvents";
import {toast} from "react-toastify";
import EventBus from "../../../../app/eventbus/EventBus";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const Kanban = () => {
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization)
    const filter = useSelector(selectBoardsFilter);

    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        getBoardsAsync()
    }, [activeOrganization, filter])

    useEffect(() => {
        const onBoardRegisterHandlerSuccess = EventBus.on(REGISTER_BOARD_SUCCESS, () => {
            toastSuccess()
            getBoardsAsync()
        })

        const onBoardEditHandlerSuccess = EventBus.on(CHANGE_BOARD_NAME_SUCCESS, () => {
            toastSuccess()
            getBoardsAsync()
        })

        const onBoardDeleteHandlerSuccess = EventBus.on(DELETE_BOARD_SUCCESS, () => {
            getBoardsAsync()
        })

        const onBoardRegisterHandlerFailed = EventBus.on(REGISTER_BOARD_FAIL, () => {
            toastError()
        })

        const onBoardChangeNameHandlerFailed = EventBus.on(CHANGE_BOARD_NAME_FAIL, () => {
            toastError()
        })

        return () => {
            EventBus.remove(REGISTER_BOARD_SUCCESS, onBoardRegisterHandlerSuccess)
            EventBus.remove(REGISTER_BOARD_FAIL, onBoardRegisterHandlerFailed)
            EventBus.remove(CHANGE_BOARD_NAME_SUCCESS, onBoardEditHandlerSuccess)
            EventBus.remove(DELETE_BOARD_SUCCESS, onBoardDeleteHandlerSuccess)
            EventBus.remove(CHANGE_BOARD_NAME_FAIL, onBoardChangeNameHandlerFailed)
        }
    }, [])

    const getBoardsAsync = () => {
        dispatch(getBoardOrganizationAsync({...filter}))
        dispatch(getBoardOrganizationCountAsync({...filter}))
    }

    function toastSuccess() {
        return toast.success(t(lang, "Success"))
    }

    function toastError() {
        return toast.error(t(lang, "Error"))
    }

    return (
        <KanbanDatatable />
    );
};

export default Kanban;
