export const ADD_NEW_DESKTOP_APP_VERSION_REQUESTED = "ADD_NEW_DESKTOP_APP_VERSION_REQUESTED";
export const ADD_NEW_DESKTOP_APP_VERSION_SUCCEED = "ADD_NEW_DESKTOP_APP_VERSION_SUCCEED";
export const ADD_NEW_DESKTOP_APP_VERSION_FAILED = "ADD_NEW_DESKTOP_APP_VERSION_FAILED";

export const DELETE_DESKTOP_APP_VERSION_REQUESTED = "DELETE_DESKTOP_APP_VERSION_REQUESTED";
export const DELETE_DESKTOP_APP_VERSION_SUCCEED = "DELETE_DESKTOP_APP_VERSION_SUCCEED";
export const DELETE_DESKTOP_APP_VERSION_FAILED = "DELETE_DESKTOP_APP_VERSION_FAILED";

export const ACTIVATE_DESKTOP_APP_VERSION_REQUESTED = "ACTIVATE_DESKTOP_APP_VERSION_REQUESTED";
export const ACTIVATE_DESKTOP_APP_VERSION_SUCCEED = "ACTIVATE_DESKTOP_APP_VERSION_SUCCEED";
export const ACTIVATE_DESKTOP_APP_VERSION_FAILED = "ACTIVATE_DESKTOP_APP_VERSION_FAILED";

export const DEACTIVATE_DESKTOP_APP_VERSION_REQUESTED = "DEACTIVATE_DESKTOP_APP_VERSION_REQUESTED";
export const DEACTIVATE_DESKTOP_APP_VERSION_SUCCEED = "DEACTIVATE_DESKTOP_APP_VERSION_SUCCEED";
export const DEACTIVATE_DESKTOP_APP_VERSION_FAILED = "DEACTIVATE_DESKTOP_APP_VERSION_FAILED";