import ContractorShipmentDataTable from "./ContractorShipmentDataTable";
import {Tabs, Tab} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useState} from "react";
import ContractorReturnShipmentDataTable from "../return-shipment/ContractorReturnShipmentDataTable";

const ContractorShipmentsCard = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [activeTab, setActiveTab] = useState('shipments')
    return (<div className={"mt-2"}>
        <Tabs variant={"pills"} activeKey={activeTab} id="controlled-tab-example" onSelect={(tab) => setActiveTab(tab)}>
            <Tab eventKey="shipments" title={t(lang, 'edi.shipment.datatable.header.title')}
                 onSelect={() => setActiveTab("shipments")}>
                {activeTab === 'shipments' && <ContractorShipmentDataTable/>}
            </Tab>
            <Tab eventKey="return_shipments" title={t(lang, 'edi.dashboard.return_shipments.title')}
                 onSelect={() => setActiveTab("return_shipments")}>
                {activeTab === 'return_shipments' && <ContractorReturnShipmentDataTable/>}
            </Tab>
        </Tabs>
    </div>)
}
export default ContractorShipmentsCard;