import axios from "axios";

class RoamingService {

    getCompanyBranches(inn) {
        return axios.get(`roaming/company/branches/get/${inn}`)
    }

    getRoamingBranches(inn) {
        return axios.get(`/roaming-reference/company/branch/get/${inn}`)
    }

    loadCountries(lang) {
        return axios.get(`/roaming/country?lang=${lang}`)
    }

    registerActFormSend({id, signature, executorInn, lang}) {
        return axios.post("/roaming/act/send", {
            id: id,
            signature: signature,
            executor_inn: executorInn,
            lang: lang,
        })
    }

    actFormSendDraft(data){
        return axios.post("/roaming/act/draft/save", data)
    }

    getAct(id){
        return axios.get(`/roaming/act/get/${id}`)
    }

    getActDraft(id) {
        return axios.get(`/roaming/act/draft/get/${id}`)
    }

    getExecutorAct(params){
        return axios.get(`/roaming/act/executor/get`,{
            params: params
        })
    }

    getExecutorActDraft(params){
        return axios.get(`/roaming/act/drafts/get`,{
            params: params
        })
    }

    getCustomerAct(params){
        return axios.get(`/roaming/act/customer/get`,{
            params: params
        })
    }

    getExecutorActCount(params){
        return axios.get(`/roaming/act/executor/count`,{
            params: params
        })
    }

    getExecutorActDraftCount(params){
        return axios.get(`/roaming/act/drafts/count`,{
            params: params
        })
    }

    getCustomerActCount(params){
        return axios.get(`/roaming/act/customer/count`,{
            params: params
        })
    }

    getActAcceptHashCode(id) {
        return axios.get(`/roaming/act/hashcode/accept/${id}`,{
            transformResponse: [data => data]
        })
    }

    getActCancelHashCode(id) {
        return axios.get(`/roaming/act/hashcode/cancel/${id}`,{
            transformResponse: [data => data]
        })
    }

    getActDeclineHashCode(id, comment) {
        return axios.get(`/roaming/act/hashcode/decline/${id}`,{
            transformResponse: [data => data],
            params: {comment: comment}
        })
    }

    ActCancel(id, signature) {
        return axios.post(`/roaming/act/cancel/${id}`,{
            signature: signature
        })
    }

    ActCustomerAccept(id, signature) {
        return axios.post(`/roaming/act/customer/accept/${id}`,{
            signature: signature
        })
    }

    ActCustomerDecline(id, signature, comment) {
        return axios.post(`/roaming/act/customer/decline/${id}`,{
            signature: signature,
            comment: comment
        })
    }

    syncAct(id) {
        return axios.get(`/roaming/act/sync/${id}`)
    }

    loadMeasurements(lang) {
        return axios.get(`/roaming/measure?lang=${lang}`)
    }

    loadDistricts(lang) {
        return axios.get(`/roaming/district?lang=${lang}`)
    }

    getBankName({mfo, lang}) {
        return axios.get(`/roaming/bank/${mfo}`, {
            params: {
                lang
            }
        })
    }

    getOrganizationNdsInfo({inn, date}) {
        return axios.get(`/roaming/company/get-nds-status/${inn}`, {
            params: {
                date
            }
        })
    }

    getPersonInfo(innOrPinfl, lang) {
        return axios.get(`/roaming/person/get/${innOrPinfl}`, {
            params: {
                lang
            }
        })
    }

    generateId(count) {
        return axios.get(`roaming/generate-id?count=${count}`)
    }

    getOrganizationInfo(inn) {
        return axios.get(`/roaming-reference/company/get/${inn}`)
    }

    getOrganizationInfoSearch(q) {
        return axios.get(`/roaming-reference/company/search`, {
            params: {
                q
            }
        })
    }

    getCompanyProductCatalog(inn, lang) {
        return axios.get(`/roaming/product-catalog-company/${inn}`, {
            params: {
                lang
            }
        })
    }

    invalidateCompanyProductCatalog(inn, lang) {
        return axios.post(`/roaming/invalidate-product-catalog-company/${inn}`, {
            params: {
                lang
            }
        })
    }

    exportCompanyCatalogs(params) {
        return axios.get(`/excel/roaming/catalogs/default`, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    getCommonProductCatalog({q, limit}) {
        return axios.get('/roaming/product-catalog-common', {
            params: {
                q: q,
                limit: limit,
            }
        })
    }

    attachTimestamp(signature) {
        return axios.post(`/eimzo/pkcs7/attach-timestamp`, {"signature": signature})
    }

    getTimestamp(signatureHex) {
        // return getTimestamp(signatureHex)
        return axios.get(`/roaming/timestamp/${signatureHex}`)
    }

    getProviderBindings() {
        return axios.get(`/roaming/provider-binding`)
    }

    getBankInfo(id, lang) {
        return axios.get(`/roaming/bank/${id}`, {
            params: { lang: lang }
        })
    }

    bindSystemProvider({signature}) {
        return axios.post(`/roaming/provider-binding`, {
            signature: signature
        })
    }

    deleteActDraft(id) {
        return axios.post(`/roaming/act/draft/delete/${id}`)
    }
}

const roamingService = new RoamingService();

export default roamingService;
