import React, {Fragment, useEffect} from 'react';
import {Card, Table} from "react-bootstrap";
import numeral from "numeral";
import SoftBadge from "../../../../common/SoftBadge";
import {methodPayment} from "../../../../../enum/BillingWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {
    selectLang, selectSystemMoneyFormat, Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {
    changeFilterOwnPaymentAsync,
    loadOwnPayments, loadOwnPaymentsCount,
    selectOwnPayments,
    selectOwnPaymentsCount,
    selectOwnPaymentsFilter
} from "../../../../../app/store/reducers/billing/billingReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {EXPENSES} from "./OtherTab";

const PaymentDatatable = ({tab}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);
    const dispatch = useDispatch()
    const payments = useSelector(selectOwnPayments)
    const count = useSelector(selectOwnPaymentsCount)
    const filter = useSelector(selectOwnPaymentsFilter)
    const activeOrganization = useSelector(selectActiveOrganization)

    useEffect(() => {
       if(tab === EXPENSES) {
           dispatch(loadOwnPayments({...filter}))
           dispatch(loadOwnPaymentsCount({...filter}))
       }
    }, [filter, activeOrganization, tab])


    const onPageChange = (page) => dispatch(changeFilterOwnPaymentAsync({...filter, page}))

    const onLimitChange = (limit) => dispatch(changeFilterOwnPaymentAsync({...filter, limit, page: 1}))


    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "main.billing.payment.datatable.created_at"),
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {dayjs(original.created_at).format("YYYY-MM-DD")}
                    </>
                );
            }
        },
        {
            accessor: 'method',
            Header: t(lang, "main.billing.payment.datatable.method.payment"),
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <SoftBadge bg={methodPayment(original.method).bg}>
                            <span>{methodPayment(original.method).name}</span>
                            <FontAwesomeIcon icon={methodPayment(original.method).icon} className={'ms-2'}/>
                        </SoftBadge>
                    </>
                );
            }
        },
        {
            accessor: 'amount',
            Header: t(lang, "main.billing.payment.datatable.amount"),
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {numeral.formats[systemMoneyFormat].format(original.amount)}
                    </>
                );
            }
        },
    ]


    return (
        <Fragment>

            <AdvanceTableWrapper
                columns={columns}
                data={payments}
                pagination
                perPage={10}
            >
                <Card>
                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}/>

                        <Card.Footer>
                            <AdvanceTableCustomPagination
                                count={count}
                                limit={filter.limit}
                                page={filter.page}
                                onLimitChange={onLimitChange}
                                onPageChange={onPageChange}
                            />
                        </Card.Footer>
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>

        </Fragment>
    );
};

export default PaymentDatatable;
