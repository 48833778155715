import {Col, Row} from "react-bootstrap";
import RegisterAndEditWarehouseButton from "./components/RegisterAndEditWarehouseButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import BindWarehousesToOrganizationsModal from "./components/BindWarehousesToOrganizationsModal";


const WarehousesDatatableHeader = ({warehouses, selectedRowIds}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const selectedWarehouses = Object.keys(selectedRowIds).reduce((result, selectedIndex) => {
        result.push(warehouses[selectedIndex]);
        return result;
    }, []);

    return (
        <Row>
            <Col xs={12}>
                <div className="d-flex justify-content-between flex-wrap">
                    <h4>{t(lang, "warehouse.common.item.title.warehouses")}</h4>
                    <div>
                        {selectedWarehouses.length > 0 &&
                            <BindWarehousesToOrganizationsModal warehouses={selectedWarehouses}/>
                        }
                        <RegisterAndEditWarehouseButton icon="fa-plus"
                                                        title={t(lang, "items.common.add")}
                                                        variant="falcon-primary"
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default WarehousesDatatableHeader;