import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form} from "react-bootstrap";
import RegistrationFromStepOne from "./register/RegistrationFromStepOne";
import RegistrationFromStepTwo from "./register/RegistrationFromStepTwo";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {
    getRegistrationVerifyConfirmationTicketAsync, registerAsync
} from "../../../app/store/reducers/auth/authReducer";
import IconAlert from "../../common/IconAlert";
import {Link} from "react-router-dom";

const RegistrationWithoutInnForm = ({hasLabel}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [step, setStep] = useState(1);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState(null);
    const [confirmationTicket, setConfirmationTicket] = useState(null);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);
    const [showAgentCode, setShowAgentCode] = useState(false);


    const methods = useForm({
        defaultValues: {
            company: {
                name: '',
                referral_agent_code: null
            },
            account: {
                name: '',
                username: '',
                password: '',
                confirmPassword: '',
                confirmCode: ''
            },
            terms: null
        }
    });

    const referralAgentCode = methods.watch('company.referral_agent_code')
    const inputRef = useRef(null)

    useEffect(() => {
        if (showAgentCode) {
            inputRef.current?.focus()
        }
    }, [showAgentCode])

    const onSubmit = (formData) => {

        let username = formData.account?.username?.replace(/ /g, '')
        username = '998' + username;

        switch (step) {
            case 1 :
                getRegistrationVerifyConfirmationTicketAsync(username)
                    .then(ticket => {
                        setConfirmationTicket(ticket);
                        setStep(step + 1);
                    }, () => {
                        setSomethingWentWrong(true);
                    })
                    break;
            case 2 :
                const data = methods.getValues()
                registerAsync({
                    name: data.company?.name,
                    username: username,
                    password: data.account?.password,
                    fullName: data.account?.name,
                    confirmCode: data.account?.confirmCode,
                    confirmTicket: confirmationTicket,
                    referral_agent_code: referralAgentCode
                })
                    .then(() => {
                        setSomethingWentWrong(false)
                    }, () => {
                        setSomethingWentWrong(true);
                    });
                    break;
        }
    }
    return (
        <FormProvider {...methods}>
            {somethingWentWrong && <IconAlert variant="danger" dismissible onClose={() => setSomethingWentWrong(false)}>
                <p className="mb-0">{t(lang, 'authorization.register.something_went_wrong')}</p>
            </IconAlert>}
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                {step === 1 && <RegistrationFromStepOne withoutInn hasLabel={hasLabel}/>}
                {step === 2 && <RegistrationFromStepTwo hasLabel={hasLabel} invalidConfirmCode={invalidConfirmCode}/>}
                <Form.Group className="mb-4">
                    <Button className="w-100" type="submit"> {t(lang, 'authorization.register.button.next')}</Button>
                </Form.Group>
            </Form>
            <p className="fs-0 text-center text-600 my-0">
                {t(lang, 'authorization.register.already_has_account')}? <Link
                to="../login">{t(lang, 'authorization.login.title')}</Link>
            </p>
            {step === 1 && <div className={'d-flex justify-content-center'}>
                {
                    !showAgentCode ? <p onClick={() => setShowAgentCode(true)}
                                        className={'d-inline-block cursor-pointer text-center text-primary mt-1 fs--2'}>
                            {referralAgentCode ?
                                <span className={'text-facebook'}>
                                    {t(lang, 'authentication.register.agent.code')}:
                                    <span className={'ms-2 text-primary'}>{referralAgentCode}</span>
                                </span> :
                                t(lang, 'authentication.register.add.agent.code')}
                        </p> :
                        <Form.Group as={Col} sm={12}>
                            <Form.Control
                                ref={inputRef}
                                className={'w-50 d-block m-auto mt-1'}
                                size={'sm'}
                                value={referralAgentCode}
                                onChange={(e) => methods.setValue('company.referral_agent_code', e.target.value)}
                                onBlur={() => setShowAgentCode(false)}
                                placeholder={!hasLabel ? t(lang, 'authentication.register.agent.code') : ''}
                                type="text"
                            />
                        </Form.Group>}
            </div>}
        </FormProvider>
    );
};

export default RegistrationWithoutInnForm;
