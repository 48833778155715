import React, {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {loadAttorneyAsync} from "../../../../app/store/reducers/attorney/attorneyReducer";
import EditAttorneyForm from "../../../../components/hippo/attorney/forms/EditAttorneyForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {EDIT_ATTORNEY_SUCCESS} from "../../../../app/eventbus/attorneyEvents";

const EditAttorney = () => {
    const {params: {id}} = useRouteMatch();
    const [attorney, setAttorney] = useState(null);
    const routerHistory = useHistory();

    useEffect(() => {
        loadAttorney();
    }, [id])

    useEffect(() => {
        const onAttorneyEditedHandler = EventBus.on(EDIT_ATTORNEY_SUCCESS, (attorney) => {
            routerHistory.push(`/edi/attorney/${attorney.id}`)
        })

        return () => {
            EventBus.remove(EDIT_ATTORNEY_SUCCESS, onAttorneyEditedHandler);
        }
    }, [])

    const loadAttorney = () => {
        loadAttorneyAsync(id)
            .then(response => {
                setAttorney(response)
            })
            .catch(error => console.log(error));
    }

    return (
        <>
            {!attorney && <span>loading...</span>}
            {attorney && <EditAttorneyForm attorney={attorney}/>}
        </>
    )
};

export default EditAttorney;