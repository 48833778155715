import React, {Fragment, useEffect} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import Cleave from "cleave.js/react";
import classNames from "classnames";

const DeliveryForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, control, watch, setValue} = useFormContext();

    const totalDistance = useWatch({name: "total_distance", exact: true})
    const deliveryCost = useWatch({name: "delivery_cost", exact: true})

    useEffect(() => {
        if (deliveryCost || totalDistance) {
            recalculate()
        }
    }, [deliveryCost, totalDistance]);

    const recalculate = () => {
        const distance = parseFloat(totalDistance?.toString()?.replace(/ /g, '')) || 0;
        const cost = parseFloat(deliveryCost?.toString()?.replace(/ /g, '')) || 0;

        if (!isNaN(distance) && !isNaN(cost)) {
            setValue('total_delivery_cost', distance * cost);
        }
    }

    return (
        <Card className={'mt-3'}>
            <Card.Body>
                <Row>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Form.Label>
                            {t(lang, "roaming.waybill.v2.send.total_distance")}
                        </Form.Label>
                        <Controller
                            name={'total_distance'}
                            control={control}
                            render={({field}) => (
                                <Cleave
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                    }}
                                    value={field.value}
                                    className={classNames('form-control', {
                                        'is-invalid': errors.total_distance,
                                    })}
                                    onChange={field.onChange}
                                    placeholder={t(lang, 'roaming.waybill.v2.send.total_distance:placeholder')}
                                />
                            )} />
                        <Form.Control.Feedback type={"invalid"} className={'d-block'}>
                            {errors.total_distance && errors.total_distance.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Form.Label>
                            {t(lang, "roaming.waybill.v2.send.delivery_cost")}
                        </Form.Label>
                        <Controller
                            name={'delivery_cost'}
                            control={control}
                            render={({field}) => (
                                <Cleave
                                    options={{
                                        numeral: true,
                                        delimiter: ' '
                                    }}
                                    placeholder={t(lang, 'roaming.waybill.v2.send.delivery_cost:placeholder')}
                                    value={field.value}
                                    className={classNames('form-control', {
                                        'is-invalid': errors.delivery_cost,
                                    })}
                                    onChange={field.onChange}
                                />
                            )} />
                        <Form.Control.Feedback type={"invalid"} className={'d-block'}>
                            {errors.delivery_cost && errors.delivery_cost.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Form.Label>
                            {t(lang, "roaming.waybill.v2.send.total_delivery_cost")}
                        </Form.Label>
                        <Controller
                            name={'total_delivery_cost'}
                            control={control}
                            render={({field}) => (
                            <Cleave
                                options={{
                                    numeral: true,
                                    delimiter: ' '
                                }}
                                value={field.value}
                                className={classNames('form-control', {
                                    'is-invalid': errors.total_delivery_cost,
                                })}
                                onChange={field.onChange}
                                placeholder={t(lang, 'roaming.waybill.v2.send.total_delivery_cost:placeholder')}
                            />
                        )} />
                        <Form.Control.Feedback type={"invalid"} className={'d-block'}>
                            {errors.total_delivery_cost && errors.total_delivery_cost.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default DeliveryForm;
