import React from 'react';
import {TypeDefault, waybillV2Types} from "../../../../../enum/roaming/WaybillV2Wrapper";
import ReactSelect from "react-select";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const WaybillV2TypeSelect = ({defaultType, onChange,...props}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    return (
        <ReactSelect
            options={waybillV2Types}
            defaultValue={TypeDefault}
            value={waybillV2Types.find(o => o.value === defaultType)}
            onChange={(option) => onChange(option.value)}
            getOptionValue={option => option.value}
            getOptionLabel={option => t(lang, option.label)}
            hideSelectedOptions
            {...props}
        />
    );
};

export default WaybillV2TypeSelect;
