import {createSlice} from "@reduxjs/toolkit";
import productMapService from "../../../../services/productMapService";

export const productMapSlice = createSlice({
    name: 'productMap',
    initialState: {},
    reducers: {}
})

export const loadProductMapNamesAsync = ({customer_inn, executor_inn}) => {
    return new Promise((resolve, reject) => {
        productMapService.getProductNames({customer_inn, executor_inn})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const deleteProductMapIdentityAsync = ({item_id, bind_attribute_id}) => {
    return new Promise((resolve, reject) => {
        productMapService.deleteProductIdentity({item_id, bind_attribute_id})
            .then(() => {
                resolve();
            })
            .catch((error) => {
                console.log(error);
                reject();
            })
    })
}

export default productMapSlice.reducer;
