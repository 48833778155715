exports.excelFileUploader = ({content, fileName}) => {
    const link = document.createElement("a");
    document.body.appendChild(link);

    const safeFileName = `${fileName.replace(/\//g, '_')}.xlsx`.replace(/\.xlsx\.xlsx$/, ".xlsx");

    const blobFile = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const blobUrl = window.URL.createObjectURL(blobFile);

    link.href = blobUrl;
    link.download = safeFileName;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
}

exports.downloadFile = ({ content, contentType, fileName }) => {
    const link = document.createElement("a");
    document.body.appendChild(link);

    const blobFile = new Blob([content], { type: contentType });
    const blobUrl = window.URL.createObjectURL(blobFile);

    link.href = blobUrl;
    link.download = fileName;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
};