import React from 'react';
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import ItemFormCardDetails from "./ItemFormCardDetails";
import ItemFormCardTracking from "./ItemFormCardTracking";
import ItemFormCardPrice from "./ItemFormCardPrice";
import ItemFormCardImages from "./ItemFormCardImages";
import ItemFormCardTaxIdentifications from "./ItemFormCardTaxIdentifications";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import PropTypes from "prop-types";
import ItemFormCardStateAndMeasurements from "./ItemFormCardStateAndMeasurements";
import {useFormContext} from 'react-hook-form';

const ItemForm = ({isEditing, onSubmit, loading}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    return (
        <Row>
            <Col xs={12} className="my-3">
                <Row className="g-4">
                    <Col md={6} lg={4} xl={4}>
                        <ItemFormCardDetails/>
                    </Col>
                    <Col md={6} lg={4} xl={4}>
                        <ItemFormCardPrice isEditing={isEditing}/>
                    </Col>
                    <Col md={6} lg={4} xl={4}>
                        <ItemFormCardStateAndMeasurements isEditing={isEditing}/>
                    </Col>
                    <Col md={6} lg={4} xl={3}>
                        <ItemFormCardTracking/>
                    </Col>
                    <Col md={6} lg={4} xl={3}>
                        <ItemFormCardTaxIdentifications/>
                    </Col>
                    <Col md={6} lg={4} xl={6}>
                        <ItemFormCardImages/>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Form.Switch
                                    type='checkbox'
                                    label={t(lang, 'items.common.skipNameUniqueValidation')}
                                    {...form.register('skipNameUniqeValidation')}
                                />
                            </Col>
                            <Col className='text-end'>
                                <IconButton
                                    icon={"fa-plus"}
                                    variant="falcon-primary"
                                    size="lg"
                                    disabled={loading}
                                    onClick={onSubmit}
                                >
                                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch'/>}
                                    {t(lang, "items.common.save")}
                                </IconButton>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
};

export default ItemForm;

ItemForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}
