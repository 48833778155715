import {useEffect, useMemo, useState} from "react";
import {loadDraftWaybillAsync, loadWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import FillWaybillForm from "../../../../components/roaming/waybill/forms/FillWaybillForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {SAVE_DRAFT_WAYBILL_SUCCEED, SEND_WAYBILL_SUCCEED} from "../../../../app/eventbus/roaming/roamingWaybillEvents";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";


const FillWaybill = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [waybill, setWaybill] = useState(null)
    const [draftWaybill, setDraftWaybill] = useState(null)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate
    const {id} = useParams()



    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_SUCCEED, waybill => {
            history.push(`/roaming/waybill/${waybill.id}`)
        });

        const onSaveDraftWaybillSucceedHandler = EventBus.on(SAVE_DRAFT_WAYBILL_SUCCEED, draftWaybill => {
            history.push(`/roaming/waybill/draft/${draftWaybill.id}`)
        })

        return () => {
            EventBus.remove(SEND_WAYBILL_SUCCEED, onSendWaybillSucceedHandler);
            EventBus.remove(SAVE_DRAFT_WAYBILL_SUCCEED, onSaveDraftWaybillSucceedHandler);
        }
    }, [])


    const query = useMemo(() => {
        return new URLSearchParams(history.location.search);
    }, [history.location.search])

    useEffect(() => {
        loadWaybill();
    }, [id, activeOrganization])

    const loadWaybill = () => {
        const status = JSON.parse(query.get("status"));
        if (status) {
            loadWaybillAsync(id)
                .then(loadedWaybill => {
                    setWaybill(loadedWaybill)
                })
                .catch(error => {
                    toast.error(t(lang,"roaming.common.error_not_found"))
                    console.log(error)
                    setWaybill(null)
                })
        } else {
            loadDraftWaybillAsync(id)
                .then(loadedDraftWaybill => {
                    const parsedContent = JSON.parse(loadedDraftWaybill.content);
                    const waybillFormat = {
                        buyer: {
                            inn: parsedContent.BuyerTin,
                            name: parsedContent.BuyerName
                        },
                        carrier: {
                            inn: parsedContent.CarrierTin,
                            name: parsedContent.CarrierName
                        },
                        contract_info: {
                            number: parsedContent.ContractDoc.ContractNo,
                            date: parsedContent.ContractDoc.ContractDate,
                        },
                        customer: {
                            branch: {
                                code: parsedContent.CustomerBranchCode,
                                name: parsedContent.CustomerBranchName
                            },
                            inn: parsedContent.CustomerTin,
                            name: parsedContent.CustomerName
                        },
                        delivery_info: {
                            number: parsedContent.TripTicketDoc.TripTicketNo,
                            date: parsedContent.TripTicketDoc.TripTicketDate,
                        },
                        delivery_type: parsedContent.DeliveryType,
                        distance_info: {
                            delivery_distance: parsedContent.DeliveryDistanceDoc.DeliveryDistance,
                            delivery_distance_in_city: parsedContent.DeliveryDistanceDoc.DeliveryDistanceInCity
                        },
                        driver_fio: parsedContent.DriverFio,
                        giver_driver_fio: parsedContent.GiverDriverFio,
                        giver_fio: parsedContent.GiverFio,
                        info: {
                            number: parsedContent.WaybillDoc.WaybillNo,
                            date: parsedContent.WaybillDoc.WaybillDate,
                        },
                        items: parsedContent.ProductList.Products.map(item => {
                            return {
                                catalog: {
                                    code: item.CatalogCode,
                                    name: item.CatalogName
                                },
                                follow_documents: item.FollowDocuments,
                                load_class: item.LoadClass,
                                measure_id: item.MeasureId,
                                packageCode: item.PackageCode,
                                packageName: item.PackageName,
                                weight_define_method: item.MethodDefineWeight,
                                name: item.Name,
                                price: item.Summa,
                                quantity: item.Count,
                                road_haulage_cost: item.RoadHaulageCost,
                                total: item.TotalSum,
                                weight_brutto: item.WeightBrutto,
                                weight_netto: item.WeightNetto
                            }
                        }),
                        point_infos: [
                            {
                                point_of_loading: parsedContent.PointDocs[0].PointOfLoading,
                                point_of_unloading: parsedContent.PointDocs[0].PointOfUnloading,
                            },
                            {
                                point_of_loading: parsedContent.PointDocs[1].PointOfLoading,
                                point_of_unloading: parsedContent.PointDocs[1].PointOfUnloading,
                            }
                        ],
                        point_of_redirect_address: parsedContent.PointOfRedirectAddress,
                        point_of_redirect_name: parsedContent.PointOfRedirectName,
                        seller: {
                            inn: parsedContent.SellerTin,
                            name: parsedContent.SellerName
                        },
                        special_notes: parsedContent.SpecialNotes,
                        taker_driver_fio: parsedContent.TakerDriverFio,
                        taker_fio: parsedContent.TakerFio,
                        trailer_info: {
                            trailer_type: parsedContent.TrailerDoc.TrailerType,
                            loader_info: {
                                number: parsedContent.TrailerDoc.TrailerRegNo,
                                model: parsedContent.TrailerDoc.TrailerModel
                            }
                        },
                        truck_info: {
                            number: parsedContent.TruckDoc.TruckRegNo,
                            model: parsedContent.TruckDoc.TruckModel
                        }
                    }
                    setDraftWaybill(waybillFormat);
                })
                .catch(error => {
                    toast.error(t(lang,"roaming.common.error_not_found"))
                    console.log(error)
                    setDraftWaybill(null)
                })
        }
    }



    if (!waybill && !draftWaybill) {
        return(
            <>
                loading...
            </>
        )
    } else {
        return(
            <>
                <FillWaybillForm waybill={waybill || draftWaybill}/>
            </>
        )
    }
}


export default FillWaybill;