import classNames from "classnames";
import {toast} from "react-toastify";
import Cleave from 'cleave.js/react';
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {useForm, Controller} from "react-hook-form";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {addOrganizationBalanceAsync} from "../../../../app/store/reducers/billing/billingReducer";

const TopUpOrganizationBalance = ({id}) => {
    const {control, handleSubmit, formState: {errors}, reset} = useForm();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => {
        reset();
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const onSubmit = (formData) => {
        setLoading(true);

        addOrganizationBalanceAsync({
            id: id,
            balance: +formData.balance,
        })
            .then(() => {
                handleClose();
                toast.success(t(lang, 'admin.organization.add_organization_balance.toast.success'));
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <IconButton onClick={handleShow} size="sm" icon="dollar-sign" variant="primary" className="mt-2">
                {t(lang, 'admin.organization.add_organization_balance.button.title')}
            </IconButton>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t(lang, 'admin.organization.add_organization_balance.modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'admin.organization.add_organization_balance.modal.sum.title')}</Form.Label>
                            <Controller
                                name="balance"
                                control={control}
                                rules={{required: t(lang, 'admin.organization.add_organization_balance.modal.sum.form.validation.is_required')}}
                                render={({field}) => (
                                    <Cleave
                                        value={field.value}
                                        onChange={event => field.onChange(event.target.rawValue)}
                                        options={{numeral: true, delimiter: ' ', numeralPositiveOnly: true}}
                                        className={classNames('form-control', {'is-invalid': errors?.balance})}
                                        placeholder={t(lang, 'admin.organization.add_organization_balance.modal.sum.placeholder')}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.balance?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, 'admin.organization.add_organization_balance.modal.button.back')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                            {t(lang, 'admin.organization.add_organization_balance.modal.button.top_up')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default TopUpOrganizationBalance;