import AttorneyDataTable from "../../../../components/hippo/attorney/AttorneyDataTable";
import {useDispatch, useSelector} from "react-redux";
import {
    loadAttorneyDataTableColumn,
    selectAttorneyDataTable
} from "../../../../app/store/reducers/attorney/attorneyReducer";
import React, {useEffect} from "react";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiAttorneyView} from "../../../../enum/Permission/EdiPermission";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const Attorneys = () => {
    const activeColumns = useSelector(selectAttorneyDataTable)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    // important
    useSelector(selectAccountRolePermissions)

    useEffect(() => {
        dispatch(loadAttorneyDataTableColumn())
    },[])

    if(!dispatch(checkPermission(PermissionEdiAttorneyView))) {
        return  <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.edi.attorney.datatable.no_access.title')}</h4>
        </Card>
    }

    return  <AttorneyDataTable activeColumns={activeColumns}/>

};

export default Attorneys;
