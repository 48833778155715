import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import {
    changeFilterOptionsAsync,
    selectContractorPaymentFilterOptions,
    selectLoading
} from "../../../../../app/store/reducers/contractor/contractorOperationReducer";
import {ContractorOperationTypes} from "../../../../../enum/ContractorOperationTypes";
import SelectContractor from "../SelectContractor";
import Divider from "../../../../common/Divider";
import {useRouteMatch} from "react-router";

const ContractorOperationDataTableHeader = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const contractorOperationFilters = useSelector(selectContractorPaymentFilterOptions);
    const dateFormat = useSelector(selectDateFormat);
    const dispatch = useDispatch();
    const isLoading = useSelector(selectLoading)
    const {params: {id}} = useRouteMatch();

    const {control, handleSubmit} = useForm({
        defaultValues: {
            contractor: contractorOperationFilters.contractor_id || null,
            type: [],
            date_start: contractorOperationFilters.date_start ? dayjs(contractorOperationFilters.date_start).toDate() : null,
            date_end: contractorOperationFilters.date_end ? dayjs(contractorOperationFilters.date_end).toDate() : null
        },
    });

    const {fields, append, replace} = useFieldArray({
        control,
        name: "type",
    });
    useEffect(() => {
        let foundTypes = contractorOperationFilters?.type?.map(type => {
            return ContractorOperationTypes.find(cot => {
                return cot.value === type
            })
        });
        replace(foundTypes);
    }, [])


    const isCheckedType = (val) => {
        let checked = fields.find(type => {
            return type.value === val;
        });
        return !!checked
    }
    const onChangeTypeHandler = (event, selectedType) => {
        if (event.currentTarget.checked && !fields.includes(selectedType)) {
            append({value: selectedType})
        } else {
            replace(fields.filter(field => field.value !== selectedType))
        }
    }

    const onSubmitFilter = ({type, contractorId, date_end, date_start, contractor, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD");
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD");
        type = type?.map(t => t?.value);
        const contractor_id = contractor?.id

        dispatch(changeFilterOptionsAsync({
            type,
            date_start,
            contractor_id,
            date_end,
            ...filters
        }))
    }

    return (
        <Form onSubmit={handleSubmit(onSubmitFilter)}>
            <Row className={'mb-1'}>
                <Col className="d-flex justify-content-between">
                    <h5 className="text-nowrap">{t(lang, "edi.contractor.datatable.header.row.contractor_operations")}</h5>
                </Col>
            </Row>
            <Row className={'mt-3'}>
                {ContractorOperationTypes.map((op, index) => {
                    return (
                        <Col xs={12} lg={4} key={index}>
                            <Form.Group>
                                <Form.Label className={'d-flex align-content-center pe-5'}>
                                    <Form.Switch checked={isCheckedType(op.value)} onChange={e => {
                                        onChangeTypeHandler(e, op.value);
                                    }}/>
                                    <div className={'m-0'}>{t(lang, op.label)}</div>
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    )
                })}
            </Row>
            <Divider/>
            <Row className="flex-between-center">
                {!id && <Form.Group as={Col} md={3}>
                    <Form.Label>Контрагент</Form.Label>
                    <Controller
                        control={control}
                        name="contractor"
                        render={({field}) => (
                            <SelectContractor
                                placeholder={t(lang, "edi.common.button.select")}
                                classNamePrefix="react-select"
                                onChange={field.onChange}
                                defaultValue={field?.value}
                            />
                        )}
                    />
                </Form.Group>}

                <Form.Group as={Col} md={3}>
                    <Form.Label>Дата (начало)</Form.Label>
                    <Controller
                        control={control}
                        name="date_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={"Дата (начало)"}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={3}>
                    <Form.Label>Дата (конец)</Form.Label>
                    <Controller
                        control={control}
                        name="date_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={"Дата (конец)"}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={3} className={"text-end mt-4"}>
                    <Button variant="primary" type='submit' disabled={isLoading}>
                        {isLoading &&
                        <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, "edi.invoice.datatable.header.filter.show")}
                    </Button>
                </Form.Group>
            </Row>
        </Form>
    );
};

ContractorOperationDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ContractorOperationDataTableHeader;