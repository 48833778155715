import React from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectWaybillVillages} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const WaybillVillageSelector = ({villageCode, onChange, villages, ...props}) => {
    // const villages = useSelector(selectWaybillVillages);
    const lang = useSelector(selectLang);

    const onSelectRegion = selectedVillage => {
        onChange(selectedVillage);
    };

    return (
        <ReactSelect
            options={villages}
            hideSelectedOptions={true}
            classNamePrefix={'react-select'}
            getOptionValue={option => option.code}
            getOptionLabel={option => option.name}
            onChange={newValue => onSelectRegion(newValue)}
            value={villages.find(d => d.code === villageCode) || null}
            placeholder={Translate(lang, "roaming.common.select.placeholder")}
            {...props}
        />
    );
};

export default WaybillVillageSelector;
