import PropTypes from "prop-types";
import classNames from "classnames";
import React, {useState} from "react";
import ReactSelect from "react-select";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../common/IconButton";
import {useForm, Controller} from "react-hook-form";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {
    ShipmentDeclineReasonStatuses
} from '../../../enum/reasons/ShipmentDeclineReasonType';
import {customerDeclineShipmentAsync} from "../../../app/store/reducers/shipment/shipmentReducer";
import checkPermission from "../../../enum/Permission/CheckPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import {PermissionEdiShipmentReject} from "../../../enum/Permission/EdiPermission";

const CustomerDeclineShipment = ({id, ...props}) => {
    const dispatch = useDispatch()
    const {register, handleSubmit, reset, formState: {errors}, control} = useForm();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => {
        if(dispatch(checkPermission(PermissionEdiShipmentReject))) setShow(true);
        else dispatch(toastPermission())
    }
    const handleClose = () => {
        setLoading(false);
        reset();
        setShow(false);
    };


    const customerDeclineShipment = (formData) => {
        setLoading(true);
        customerDeclineShipmentAsync({
            id: id,
            reason: formData.reason,
            comment: formData.comment,
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };


    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-danger"
                size="sm"
                icon="ban"
                transform="shrink-3"
                {...props}
            >
                <span className="d-none d-sm-inline-block">{t(lang, "edi.common.decline")}</span>
            </IconButton>
            <Modal size="md" show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(customerDeclineShipment)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t(lang, "edi.shipments.shipment.customer_decline_shipment_modal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, "edi.common.modal.reason")}</Form.Label>
                            <Controller
                                name="reason"
                                control={control}
                                rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                render={({field}) => (
                                    <ReactSelect
                                        isClearable
                                        classNamePrefix="react-select"
                                        options={ShipmentDeclineReasonStatuses}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => t(lang, option.name)}
                                        onChange={option => field.onChange(option?.id)}
                                        className={classNames({'is-invalid': !!errors?.reason?.message})}
                                        placeholder={t(lang, "edi.common.modal.decline_reason.placeholder")}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.reason?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                {...register('comment')}
                                placeholder={t(lang, 'edi.common.leave_comment')}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {handleClose()}}>
                            {t(lang, "edi.common.cancel_button")}
                        </Button>
                        <Button variant="danger" className="d-flex align-items-center" disabled={loading} type="submit">
                            {loading && <Spinner className="me-1" animation="border" role="status" size="sm" />}
                            {t(lang, "edi.common.decline")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
};

CustomerDeclineShipment.propTypes = {
    id: PropTypes.string,
};

CustomerDeclineShipment.defaultProps = {
    id: '',
};

export default CustomerDeclineShipment;
