import React, {useState} from 'react';
import CreatableSelect from "react-select/creatable";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {selectItems} from "../../../../../../app/store/reducers/item/itemReducer";

function SelectProduct({
                           defaultItemId,
                           onChange,
                           setSelectedItem,
                           ...props
                       }) {

    const items = useSelector(selectItems);
    const lang = useSelector(selectLang);
    const t = Translate;

    const [limit, setLimit] = useState(20)
    const [inputValue, setInputValue] = useState('')

    const fetchMoreData = async () => {
        setLimit(prev => prev + 20)
    }

    const getOptionProductValue = (option) => option?.item?.id

    const getOptionProductLabel = (option) => `${option?.item?.name}`

    const formatNoOptionsMessage = () => t(lang, "crm.common.select.product.no_options_message");

    const formatCreateLabel = (label) => `${t(lang, "crm.common.add")} ${label}`;

    function onChangeProduct(value) {
        setSelectedItem(value)
        return value?.item?.name
    }

    const formatCatalogLabel = (option) => {
        return (
            <div className="fs--1">
                <span
                    className={`ms-1 ${props?.isDisabled ? 'text-black' : 'text-facebook'}`}>{option?.product || option.item?.name}</span>
            </div>
        )
    }


    return (
        <div onKeyUp={(e) => setInputValue(e?.target?.value)}>
            <CreatableSelect
                onMenuScrollToBottom={fetchMoreData}
                placeholder={t(lang, "crm.common.select")}
                formatCreateLabel={formatCreateLabel}
                options={inputValue ? items : items.slice(0, limit)}
                onChange={(value) => {onChange(value.__isNew__ ? value?.label : onChangeProduct(value))}}
                formatOptionLabel={(option) => option?.__isNew__ ? option?.label : formatCatalogLabel(option)}
                getOptionValue={getOptionProductValue}
                getOptionLabel={getOptionProductLabel}
                noOptionsMessage={formatNoOptionsMessage}
                menuPortalTarget={document.body}
                {...props}
            />
        </div>
    );
}

export default SelectProduct;
