import React, {Fragment} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";

const RoamingInvoiceInvestmentObject = () => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const {register, formState: {errors}} = useFormContext()

    return (
        <Fragment>
            <Card className={'h-100'}>
                <Card.Header className={'pb-0'}>
                    <h5>{t(lang, 'roaming.invoice.investment.object')}</h5>
                </Card.Header>
                <Card.Body className={'pt-1'} as={Row}>
                    <Form.Group as={Col} xs={12} md={6}>
                        <Form.Label>
                            {t(lang, 'roaming.invoice.investment.id')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t(lang, 'roaming.invoice.investment.id.placeholder')}
                            isInvalid={errors?.investmentObject?.id}
                            {...register('investmentObject.id', {
                                required: t(lang, 'items.common.validations.is_required')
                            })}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.investmentObject?.id?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6}>
                        <Form.Label>
                            {t(lang, 'roaming.invoice.investment.name')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t(lang, 'roaming.invoice.investment.name.placeholder')}
                            isInvalid={errors?.investmentObject?.name}
                            {...register('investmentObject.name', {
                                required: t(lang, 'items.common.validations.is_required')
                            })}
                        />
                        <Form.Control.Feedback type="invalid">{errors?.investmentObject?.name?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default RoamingInvoiceInvestmentObject;
