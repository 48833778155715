import React, {useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {selectDistricts} from "../../../../app/store/reducers/roaming/roamingReducer";


const SelectDistrict = React.forwardRef(({onChange, defaultDistrictId, ...props}, ref) => {
    const districts = useSelector(selectDistricts);
    const [district, setDistrict] = useState(null);

    useEffect(() => {
        if(districts.length > 0 && defaultDistrictId)
            setDistrict(districts.find(d => d.districtId === defaultDistrictId))
        else
            setDistrict(null);
    }, [districts, defaultDistrictId])

    useEffect(() => {
        onChangeHandler(district)
    }, [district])


    const onChangeHandler = (district) => onChange(district);
    const getOptionCatalogLabel = (option) => option.name;
    const getOptionCatalogValue = (option) => option.districtId;


    return (
        <ReactSelect getOptionLabel={getOptionCatalogLabel}
                     getOptionValue={getOptionCatalogValue}
					 hideSelectedOptions={true}
                     options={districts}
                     value={district}
                     onChange={onChangeHandler}
                     ref={ref}
                     {...props}
        />    )
});

SelectDistrict.propTypes = {
    onChange: PropTypes.func
}

SelectDistrict.defaultProps = {
    onChange: () => {
    }
}

export default SelectDistrict;
