import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import {Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import WarehouseOperationDefaultSettingsForm from "../../components/WarehouseOperationDefaultSettingsForm";
import {
    selectPurchaseDefaultsSettings,
    setPurchaseDefaultsSettings
} from "../../../../../app/store/reducers/warehouse-operation/purchaseReducer";


const PurchaseSettings = () => {
    const purchaseDefaultsSettings = useSelector(selectPurchaseDefaultsSettings)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const form = useForm({
        defaultValues: {
            defaultEmployeeId: null,
            defaultClientId: null,
            defaultWarehouseId: null,
            isCheckedViewCategories: false,
            isCheckedViewWarehouses: false,
            isCheckedViewDateTime: false,
            isCheckedViewNumber: false,
            priceTypeOfInputOption: null,
            bulkPriceCommon: null,
            showItemSearchOptions: 20,
            numbersAfterDecimalPoint: 4,
            operationItemExtraInfo: null,
            showOperationItemPurchasePrice: false,
        }
    })


    const handleShow = () => {
        setShow(true)
        form.setValue("defaultEmployeeId", purchaseDefaultsSettings?.defaultEmployeeId)
        form.setValue("defaultClientId", purchaseDefaultsSettings?.defaultClientId)
        form.setValue("defaultWarehouseId", purchaseDefaultsSettings?.defaultWarehouseId)
        form.setValue("isCheckedViewCategories", purchaseDefaultsSettings?.isCheckedViewCategories)
        form.setValue("isCheckedViewWarehouses", purchaseDefaultsSettings?.isCheckedViewWarehouses)
        form.setValue("isCheckedViewDateTime", purchaseDefaultsSettings?.isCheckedViewDateTime)
        form.setValue("isCheckedViewNumber", purchaseDefaultsSettings?.isCheckedViewNumber)
        form.setValue("priceTypeOfInputOption", purchaseDefaultsSettings?.priceTypeOfInputOption)
        form.setValue("bulkPriceCommon", purchaseDefaultsSettings?.bulkPriceCommon)
        form.setValue("showItemSearchOptions", purchaseDefaultsSettings?.showItemSearchOptions)
        form.setValue("numbersAfterDecimalPoint", purchaseDefaultsSettings?.numbersAfterDecimalPoint)
        form.setValue("operationItemExtraInfo", purchaseDefaultsSettings?.operationItemExtraInfo)
        form.setValue("showOperationItemPurchasePrice", purchaseDefaultsSettings?.showOperationItemPurchasePrice)
    }


    const handleClose = () => {
        setShow(false)
    }


    const onSubmit = (formData) => {
        dispatch(setPurchaseDefaultsSettings(JSON.stringify(formData)))
        handleClose()
    }



    return(
        <>
            <IconButton
                icon="gear"
                onClick={handleShow}
                variant="falcon-primary"
                size="sm"
                className="mx-2"
            />
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        {/*Сотув созламалари*/}
                        Настройки прихода
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...form}>
                        <Form onSubmit={event => {
                            event.stopPropagation();
                            return form.handleSubmit(onSubmit)(event);
                        }}>
                            <WarehouseOperationDefaultSettingsForm />
                        </Form>
                    </FormProvider>
                </Modal.Body>
            </Modal>
        </>
    )
}



export default PurchaseSettings;
