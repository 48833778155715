import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../../../app/store/reducers/eimzo/eimzoReducer";
import {sendWaybillV2Async} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {
    WAYBILL_V2_BULK_ACTION_FINISHED,
    WAYBILL_V2_BULK_ACTION_STARTED
} from "../../../../../../app/eventbus/roaming/roamingWaybillV2Events";
import IconButton from "../../../../../common/IconButton";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import SelectCertificateWrapperComponent from "../../../components/SelectCertificateWrapperComponent";
import PropTypes from "prop-types";
import RoamingInvoiceDraftSend from "../../../invoice/actions/RoamingInvoiceDraftSend";

const WaybillV2DraftSend = ({waybill, waybills, handleShowProgressBar, updateActionResult, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const activeCertificate = useSelector(selectCurrentCertificate);

    // button loader
    const [loading, setLoading] = useState(false);

    // send
    const send = () => {
        if (waybills.length > 0)
            bulkSend();
        else
            singleSend();
    }

    // single send document
    const singleSend = () => {
        setLoading(true);
        sendWaybillV2Async(activeCertificate, lang, {hashCode: waybill.content})
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    // bulk send document
    const bulkSend = async () => {
        handleShowProgressBar();
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_STARTED);

        const taskSize = 10;
        const tasks = []

        const signer = async waybill => {
            try {
                await sendWaybillV2Async(activeCertificate, lang, {hashCode: waybill.content});
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn_or_pinfl || waybill.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn_or_pinfl || waybill.customer.inn
                    },
                    error: error?.message
                })
            }
        }

        for (let i = 0; i < waybills.length; i++) {
            tasks.push(signer(waybills[i]))

            if (tasks.length >= taskSize) {
                await Promise.all(tasks)
                tasks.splice(0, tasks.length)
            }
        }

        if (tasks.length > 0) {
            await Promise.all(tasks)
            tasks.splice(0, tasks.length)
        }
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_FINISHED);
    }

    return (
        <SelectCertificateWrapperComponent
            openDialogButtonText={t(lang, "roaming.invoice.send_button")}
            submitButton={ <IconButton icon={faSignature}
                        variant="falcon-primary"
                        id="Send"
                        disabled={loading}
                        onClick={send}
                        className={"m-0"}
                        {...props}
            >
                {loading && <Spinner size="sm" className='align-middle me-2' animation='border' role='switch'/>}
                {t(lang, "roaming.invoice.send_button")}
            </IconButton>
        } />
    );
};

export default WaybillV2DraftSend;

WaybillV2DraftSend.propTypes = {
    waybill: PropTypes.object,
    waybills: PropTypes.array,
    updateActionResult: PropTypes.func,
    handleShowProgressBar: PropTypes.func
}

WaybillV2DraftSend.defaultProps = {
    waybill: {},
    waybills: [],
    updateActionResult: () => {
    },
    handleShowProgressBar: () => {}
}
