import React, {useState} from "react"
import IconButton from "../../../../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {syncActAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import PropTypes from "prop-types";;


const SyncAct = ({id, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false)
    const syncAct = () => {
        setLoading(true)
        syncActAsync(id)
            .then()
            .catch()
            .finally(() => setLoading(false))
    }


    return (
        <IconButton
            icon={'fa-refresh'}
            variant="falcon-primary"
            onClick={syncAct}
            size="sm"
            iconClassName="me-1"
            disabled={loading}
            {...props}
        >
            {t(lang, "roaming.act.sync")}
        </IconButton>
    )
}

SyncAct.propTypes = {
    id: PropTypes.string
}

SyncAct.defaulProps = {
    id: ""
}

export default SyncAct;