import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card, Col, Row, Table} from "react-bootstrap";
import IconButton from "../../../common/IconButton";
import {Link} from "react-router-dom";
import {
    Created,
    CustomerEdited,
    ExecutorApproved,
    CustomerApproved,
    GetStatusTextReturnShipment,
    ExecutorDeclined, getStatusColorReturnShipment, InvoiceCreated
} from "../../../../enum/ReturnShipmentStatus";
import numeral from "numeral";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    selectDateDayJSFormat, selectLang,
    selectNumberFormat, Translate
} from "../../../../app/store/reducers/main/mainReducer";
import {
    AfterConfirmByCustomer,
    OnShipmentWaybillCreation
} from "../../../../app/order/OrderExecutorCreateInvoiceStrategyType";
import ReturnShipmentApproveCustomer from "../ReturnShipmentApproveCustomer";
import ReturnShipmentApproveExecutor from "../ReturnShipmentApproveExecutor";
import ReturnShipmentPrint from "../ReturnShipmentPrint";
import ReturnShipmentDelete from "../ReturnShipmentDelete";
import ReturnInvoicesOfReturnShipmentDataTable from "./ReturnInvoicesOfReturnShipmentDataTable";
import {loadCountReturnInvoiceAsync, loadReturnInvoicesAsync} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import UploadReturnShipmentToExcel from "../excel/UploadReturnShipmentToExcel";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionEdiReturnInvoiceCreate,
    PermissionEdiReturnOrderView
} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const ReturnShipment = ({returnShipment}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const subtotal = returnShipment.items.reduce((acc, item) => acc + item.total, 0);
    const tax = returnShipment.items.reduce((acc, item) => acc + item.nds_value, 0);

    const [filterOptions, setFilterOptions] = useState(
        {
            page: 1,
            limit: 10
        }
    );

    const activeOrganization = useSelector(selectActiveOrganization);
    const allowCreateInvoice = activeOrganization.inn === returnShipment.executor_info.inn &&
        (
            (
                returnShipment.return_order.executor_create_invoice_strategy === OnShipmentWaybillCreation.id && (
                    returnShipment.status === ExecutorApproved ||
                    returnShipment.status === CustomerApproved ||
                    returnShipment.status === CustomerEdited ||
                    returnShipment.status === Created ||
                    returnShipment.status === InvoiceCreated
                )
            ) || (
                returnShipment.return_order.executor_create_invoice_strategy === AfterConfirmByCustomer.id && (returnShipment.status === ExecutorApproved || returnShipment.status === InvoiceCreated)
            )
        )


    const allowCustomerApprove = activeOrganization.inn === returnShipment.customer_info.inn &&
        (
            returnShipment.status === Created || returnShipment.status === CustomerEdited
        )

    const allowExecutorApprove = activeOrganization.inn === returnShipment.executor_info.inn && returnShipment.status === CustomerApproved;

    const allowDelete = activeOrganization.inn === returnShipment.customer_info.inn && (
        returnShipment.status === Created ||
        returnShipment.status === CustomerEdited ||
        returnShipment.status === ExecutorDeclined
    )

    const onFilterOptionsChanged = (options) => setFilterOptions(options);

    const loadReturnInvoicesOfReturnShipment = () => {
        const filters = {
            page: filterOptions.page,
            limit: filterOptions.limit,
            viewer_inn: activeOrganization.inn,
            return_shipment_id: returnShipment.id,
        }

        dispatch(loadCountReturnInvoiceAsync(filters));
        dispatch(loadReturnInvoicesAsync(filters));
    }

    useEffect(() => {
        loadReturnInvoicesOfReturnShipment();
    }, [activeOrganization, filterOptions])

    return (
        <>
            <Card className="my-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang,"edi.common.created.time")}: {returnShipment.created_at}</h3>
                    <Col className="text-end">
                        <span className="me-2">{t(lang, "edi.common.status")}: </span>
                        <span className={`p-1 rounded-pill text-white fs--2`} style={{backgroundColor: getStatusColorReturnShipment(returnShipment.status)}}>{t(lang, GetStatusTextReturnShipment(returnShipment.status))}</span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {allowCreateInvoice &&
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                className="ms-2"
                                as={dispatch(checkPermission(PermissionEdiReturnInvoiceCreate)) ? Link : 'div'}
                                onClick={()=>!dispatch(checkPermission(PermissionEdiReturnInvoiceCreate)) && dispatch(toastPermission())}
                                to={`../return-invoice/register/${returnShipment.id}`}
                            >
                                <span className="d-none d-sm-inline-block">{t(lang, "edi.common.new_invoice_button")}</span>
                            </IconButton>
                        }
                        {allowCustomerApprove &&
                            <ReturnShipmentApproveCustomer className="ms-2" id={returnShipment.id}/>
                        }
                        {allowExecutorApprove &&
                            <ReturnShipmentApproveExecutor className="ms-2" id={returnShipment.id}/>
                        }

                        {allowDelete && <ReturnShipmentDelete id={returnShipment.id}/>}


                            <IconButton
                                to={`../return-order/${returnShipment.return_order.id}`}
                                as={dispatch(checkPermission(PermissionEdiReturnOrderView))  ? Link : 'div'}
                                onClick={()=>!dispatch(checkPermission(PermissionEdiReturnOrderView)) && dispatch(toastPermission())}
                                variant="falcon-default"
                                size="sm"
                                icon="eye"
                                transform="shrink-3"
                                className="mx-1"
                            >
                                    <span className="d-none d-sm-inline-block">
                                        {t(lang, "edi.common.return_order")}
                                    </span>
                            </IconButton>

                        <ReturnShipmentPrint size="sm" variant="falcon-default" className="mx-1" id={returnShipment.id}/>
                        <UploadReturnShipmentToExcel className="mx-2" id={returnShipment.id} number={returnShipment.return_shipment_info.number} />
                    </Col>
                </Row>
            </Card>

            <Card className="p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="fw-bold mb-1">{t(lang, "edi.common.executor")}</h5>
                        <span><b>{t(lang,"edi.common.company")}:</b> {returnShipment.executor_info.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {returnShipment.executor_info.inn}</span>
                    </Col>

                    <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="fw-bold mb-1">{t(lang, "edi.common.customer")}</h5>
                        <span><b>{t(lang,"edi.common.company")}:</b> {returnShipment.customer_info.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {returnShipment.customer_info.inn}</span>
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card className="my-3">
                <Card.Header>
                    <h5 className="p-0"><b className="pe-1">{t(lang,"edi.common.id")}:</b>{returnShipment.id}</h5>

                    <Row className="my-3">
                        <h3 className="text-center">{t(lang, "edi.common.return_shipment")}</h3>
                        <Col className="d-flex align-items-center justify-content-around">
                            <div>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold mb-1">{t(lang, "edi.common.shipment.number")}: </span>
                                    <span>{returnShipment.return_shipment_info.number}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.shipment.date")}: </span>
                                    <span>{dayjs(returnShipment.return_shipment_info.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                </p>
                            </div>
                            <div>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.contract.number")}: </span>
                                    <span>{returnShipment.contract_info.number}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.common.contract.date")}: </span>
                                    <span>{dayjs(returnShipment.contract_info.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                </p>
                                <p className="mb-1">
                                    <span className="fs-0 fw-bold">{t(lang, "edi.return_order.label.loading_address")}: </span>
                                    <span>{returnShipment.loading_address}</span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Footer>
                    <Table bordered responsive className="border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th rowSpan={2}>№</th>
                            <th className="text-start">{t(lang, "edi.common.items.product")}</th>
                            <th className="text-center">{t(lang, "edi.common.customer_system_id")}</th>
                            <th className="text-center">{t(lang, "edi.common.customer_shipment_id")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.catalog_class_code")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.product_code")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.barcode")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.measurement")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.quantity")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.price")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.total")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.nds")}</th>
                            <th className="text-center">{t(lang, "edi.common.items.product.nds_value")}</th>
                            <th className="text-end">{t(lang, "edi.common.items.product.total_with_nds")}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                            <th className="p-0 text-center">5</th>
                            <th className="p-0 text-center">6</th>
                            <th className="p-0 text-center">7</th>
                            <th className="p-0 text-center">8</th>
                            <th className="p-0 text-center">9</th>
                            <th className="p-0 text-center">10</th>
                            <th className="p-0 text-center">11</th>
                            <th className="p-0 text-center">12</th>
                            <th className="p-0 text-center">13</th>
                        </tr>
                        </thead>

                        <tbody>
                        {returnShipment.items.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-start">{item.product}</td>
                                    <td className="text-center">{item.return_order_item.customer_system_id}</td>
                                    <td className="text-center">{item.return_order_item.customer_shipment_id}</td>
                                    <td className="text-center">{item.catalog_class_code || item.return_order_item.catalog_class_code}</td>
                                    <td className="text-center">{item.product_code || item.return_order_item.product_code}</td>
                                    <td className="text-center">{item.barcode || item.return_order_item.barcode}</td>
                                    <td className="text-center">{item.measurement}</td>
                                    <td className="text-center">-{item.quantity}</td>
                                    <td className="text-center">{numeral.formats[numberFormat].format(item.price)}</td>
                                    <td className="text-center">-{numeral.formats[numberFormat].format(item.total)}</td>
                                    <td className="text-center">{item.nds_rate}</td>
                                    <td className="text-center">-{numeral.formats[numberFormat].format(item.nds_value)}</td>
                                    <td className="text-end">-{numeral.formats[numberFormat].format(item.total_with_nds)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, "edi.common.subtotal")}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(subtotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900">{t(lang, "edi.common.tax")}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(tax)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, "edi.common.total")}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(subtotal + tax)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
            <ReturnInvoicesOfReturnShipmentDataTable filters={filterOptions} onRefresh={loadReturnInvoicesOfReturnShipment} onFilterChanged={onFilterOptionsChanged} />
        </>
    )
};

ReturnShipment.propTypes = {
    returnShipment: PropTypes.object,
};

ReturnShipment.defaultProps = {
    returnShipment: {},
};
export default ReturnShipment;
