import React, {Fragment, useRef} from 'react';
import {Button, Modal} from "react-bootstrap";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {editItemAsync, selectItems} from "../../../app/store/reducers/item/itemReducer";
import EventBus from "../../../app/eventbus/EventBus";
import {BULK_EDIT_ITEMS_FINISHED} from "../../../app/eventbus/itemEvents";
import ResultCounter from "../../../components/hippo/item/components/ResultCounter";
import {selectOrganization} from "../../../app/store/reducers/draft-item/draftItemReducer";

const UpdateTaxItemsModal = ({show, setShow}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const items = useSelector(selectItems);
    const ref = useRef(null);
    const organization = useSelector(selectOrganization);

    const onApprove = async () => {
        onHide();
        ref.current.updateLoadedCount(items.length);
        for (let i = 0; i < items.length; i++) {
            try {
                const {item} = items[i];
                const itemJson = {
                    ...item,
                    measurement: item.measurement.toString(),
                    tax: {
                        ...item.tax,
                        tax_rate: organization.default_tax_rate || null
                    }
                }
                await editItemAsync(item.id, itemJson);
                ref.current.incrementSucceedCount();
            } catch (e) {
                console.log(e);
                ref.current.incrementFailedCount();
            }
        }
        EventBus.dispatch(BULK_EDIT_ITEMS_FINISHED);
    }

    const onHide = () => {
        setShow(false);
    }

    return (
        <Fragment>
            <ResultCounter ref={ref}/>

            <Modal show={show} size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered onHide={onHide}
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <h4>{t(lang, 'items.common.add_tax.setting')}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'dark'} onClick={onHide}>
                        {t(lang, 'items.common.no')}
                    </Button>
                    <Button onClick={onApprove}>
                        {t(lang, 'items.common.yes')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default UpdateTaxItemsModal;