import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {excelFileUploader} from "../../../../helpers/excel-uploader";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {TYPE_ORDER, TYPE_ORDERS, TYPE_ORDERS_WITH_ITEMS} from "../../../../enum/ExcelTemplateTypes";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectOrganizationTemplates} from "../../../../app/store/reducers/excel/excelReducer";
import {
    selectFilterOptions, uploadBeltonOrderToExcelAsync,
    uploadOrderListIntoExcelAsync,
    uploadOrdersWithItemsToExcelAsync, uploadSelectedOrdersToExcelAsync, uploadSelectedOrdersWithItemsToExcelAsync
} from "../../../../app/store/reducers/order/orderReducer";
import {sleepAsync} from "../../../../helpers/utils";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiOrderDownloadExcel} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const UploadOrderListToExcel = ({rowValues, selectable, ...props}) => {
    const dispatch = useDispatch()
    const [ordersWithItemsTypeTemplates, setOrdersWithItemsTypeTemplates] = useState([]);
    const [ordersTypeTemplates, setOrdersTypeTemplates] = useState([]);
    const [orderTypeTemplates, setOrderTypeTemplates] = useState([]);
    const organizationTemplates = useSelector(selectOrganizationTemplates);

    const activeOrganization = useSelector(selectActiveOrganization);
    const [loading, setLoading] = useState(false);
    const filter = useSelector(selectFilterOptions);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        const orderTemplates = organizationTemplates.filter(template => template.type === TYPE_ORDER) || [];
        const ordersTemplates = organizationTemplates.filter(template => template.type === TYPE_ORDERS) || [];
        const ordersWithItemsTemplates = organizationTemplates.filter(template => template.type === TYPE_ORDERS_WITH_ITEMS) || [];

        setOrderTypeTemplates(orderTemplates);
        setOrdersTypeTemplates(ordersTemplates);
        setOrdersWithItemsTypeTemplates(ordersWithItemsTemplates);
    }, [organizationTemplates])

    const uploadExcel = (uri) => {
        setLoading(true);

        if (rowValues.length > 0) {
            const selectedRowIDs = [];
            for (let i = 0; i < rowValues.length; i++) {
                const row = rowValues[i];

                if (row.isSelected) {
                    selectedRowIDs.push(row.original.id);
                }
            }

            uploadSelectedOrdersToExcelAsync(selectedRowIDs)
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'edi.orders.excel_upload.file_name'),
                    });
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else {
            uploadOrderListIntoExcelAsync({...filter, viewer_inn: activeOrganization.inn, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'edi.orders.excel_upload.file_name'),
                    });
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }

    const uploadExcelWithItems = (uri) => {
        setLoading(true);

        if (rowValues.length > 0) {
            const selectedRowIDs = [];
            for (let i = 0; i < rowValues.length; i++) {
                const row = rowValues[i];

                if (row.isSelected) {
                    selectedRowIDs.push(row.original.id);
                }
            }

            uploadSelectedOrdersWithItemsToExcelAsync(selectedRowIDs)
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'edi.orders_with_items.excel_upload.file_name'),
                    });
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        } else {
            uploadOrdersWithItemsToExcelAsync({...filter, viewer_inn: activeOrganization.inn, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'edi.orders_with_items.excel_upload.file_name'),
                    });
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }

    const uploadOrders = (uri) => {
        if (dispatch(checkPermission(PermissionEdiOrderDownloadExcel))) uploadExcel(uri)
        else dispatch(toastPermission())
    }
    const uploadOrdersWithItems = (uri) => {
        if (dispatch(checkPermission(PermissionEdiOrderDownloadExcel))) uploadExcelWithItems(uri)
        else dispatch(toastPermission())
    }


    const uploadSelectedBeltonOrders = async () => {
        setLoading(true);
        const selectedRowValues = rowValues.filter(value => value.isSelected);

        for (let i = 0; i < selectedRowValues.length; i++) {
            const order = selectedRowValues[i].original;
            try {
                const loadedBase64 = await uploadBeltonOrderToExcelAsync(order.id)
                excelFileUploader({
                    content: loadedBase64,
                    fileName: t(lang, 'edi.order.excel_upload.file_name', {number: order.info.number}),
                });

                await sleepAsync(200);
            }catch (e) {
                console.log(e)
            }
        }
        setLoading(false)
    }

    return (
        <>
            <Dropdown {...props} autoClose="outside">
                <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                    {loading
                        ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                        : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                    }
                    <span className="d-none d-sm-inline-block">
                        {t(lang, 'edi.order.datatable.header.navigation.dropdown.export_to_excel')}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-2'>
                    <>
                        <Dropdown.Header className="text-black">{t(lang, 'edi.order.datatable.header.navigation.dropdown.item.export_orders_to_excel')}</Dropdown.Header>
                        {ordersTypeTemplates.map((template, index) => (
                            <Dropdown.Item className="ps-4" key={index} onClick={() => uploadOrders(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                        ))}
                    </>
                    <>
                        <Dropdown.Header className="text-black">{t(lang, 'edi.order.datatable.header.navigation.dropdown.item.export_orders_with_items_to_excel')}</Dropdown.Header>
                        {ordersWithItemsTypeTemplates.map((template, index) => (
                            <Dropdown.Item className="ps-4" key={index} onClick={() => uploadOrdersWithItems(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                        ))}
                    </>

                    {selectable && (
                        <>
                            <Dropdown.Header className="text-black">{t(lang, 'edi.order.datatable.header.navigation.dropdown.item.export_selected_orders_to_excel')}</Dropdown.Header>
                            {orderTypeTemplates.map((template, index) => (
                                <Dropdown.Item className="ps-4" key={index} onClick={() => uploadSelectedBeltonOrders(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                            ))}
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

UploadOrderListToExcel.prototype = {
    rowValues: PropTypes.array,
    selectable: PropTypes.bool,
}

UploadOrderListToExcel.defaultProps = {
    rowValues: [],
    selectable: false
}

export default UploadOrderListToExcel;
