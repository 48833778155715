import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {
    editContractorPayoutNoteAsync,
    editContractorPayoutAsync,
    editContractorPayoutContractorAsync, editContractorPayoutDateAsync
} from "../../../../../app/store/reducers/contractor/contractorPayoutReducer";
import ContractorPayoutForm from "./ContractorPayoutForm";
import dayjs from "dayjs";
import {toast} from "react-toastify";

const EditContractorPayout = ({payout, onClose}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);
    const handleOnSubmit = (formData) => {
        const payload = {
            contractor_id: formData.contractor,
            date: dayjs(formData.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
            notes: formData.notes,
            cash_box_states: formData.cash_box_states.map((act) => {
                return {
                    type: act.type,
                    amount: typeof (act.currency.amount) === 'string' ? +act.currency.amount.replace(/ /g, '') : +act.currency.amount,
                    currency_id: act.currency.currency_id
                }
            }),
            debt_states: formData.debt_states.map((debt) => {
                return {
                    amount: typeof (debt.amount) === 'string' ? +debt.amount.replace(/ /g, '') : +debt.amount,
                    currency_id: debt.currency_id
                }
            }),
        }

        setLoading(true)
        dispatch(editContractorPayoutAsync({payout_id: payout.id, payload: payload}))
            .then(re => {
                onClose()
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }

    const handleEditNote = (note) => {
        const payload = {
            note: note
        }
        dispatch(editContractorPayoutNoteAsync({payout_id: payout.id, payload: payload})).then(re => {
        }).catch(err => console.log(err));
    }

    const handleEditContractor = (contractor_id) => {
        if (payout.contractor.id !== contractor_id) {
            const payload = {
                contractor_id: contractor_id
            }
            dispatch(editContractorPayoutContractorAsync({payout_id: payout.id, payload: payload}))
                .then(re => {
                })
                .catch(err => console.log(err));
        }
    }
    const handleEditPayoutDate = (date) => {
        const payload = {
            date: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
        }
        dispatch(editContractorPayoutDateAsync({payout_id: payout.id, payload: payload}))
            .then(re => {
                toast.success(t(lang, 'crm.common.toast.success'));
            })
            .catch(err => console.log(err));
    }
    return (<>
        <Modal show={true} onHide={onClose} size="xl">
            <Modal.Header>
                <Modal.Title>{t(lang, "crm.edit_contractor_payment_form.modal.title")}</Modal.Title>
                <FalconCloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <ContractorPayoutForm payout={payout}
                                      onClose={onClose}
                                      loading={loading}
                                      onSubmit={handleOnSubmit}
                                      onEditNote={handleEditNote}
                                      onEditDate={handleEditPayoutDate}
                                      onEditContractor={handleEditContractor}/>
            </Modal.Body>
        </Modal>
    </>)
}
export default EditContractorPayout
