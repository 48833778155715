import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {downloadFile} from "../../../../../../helpers/excel-uploader";
import {getExcelActReportAsync} from "../../../../../../app/store/reducers/contractor/contractorActReportReducer";
import dayjs from "dayjs";


const UploadActReportToExcel = ({id, dateStart, dateEnd, hasName, disabled, contractorName}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;


    const uploadExcel = () => {
        setLoading(true)
        getExcelActReportAsync({id, dateStart: dayjs(dateStart).format("YYYY-MM-DD"), dateEnd: dayjs(dateEnd).format("YYYY-MM-DD")}).then(({content, contentType}) => {
                downloadFile({
                    content: content,
                    contentType: contentType,
                    fileName: `${t(lang, "crm.contractor.view.tab.act_report.title")}_${contractorName}`,
                });
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false))
    }

    return (
        <Button variant="falcon-success" size="md" className="mx-2" disabled={disabled} onClick={() => uploadExcel()}>
            {loading
                ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
            }
            {hasName &&
                <span className="d-none d-sm-inline-block">{t(lang, 'crm.common.download_excel')}</span>
            }
        </Button>
    );
};

export default UploadActReportToExcel;
