import Packages from "./Packages";
import BalanceInfoCard from "./BalanceInfoCard";
import {Card, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import OrganizationActivePackages from "./OrganizationActivePackages";
import {ACTIVATE_PACKAGE_SUCCEED} from "../../../../app/eventbus/billingEvents";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import OtherTab from "./component/OtherTab";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";

const ACTIVE_PACKAGES = '1';
const ALL_PACKAGES = '2';
const OTHER = '3'

const Billing = () => {

    const routerHistory = useHistory();

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    let activeTabFromRoute = queryParameters.get("activeTab");

    const [key, setKey] = useState(ACTIVE_PACKAGES);
    const lang = useSelector(selectLang);

    const dispatch = useDispatch();
    const t = Translate;

    useEffect(() => {
        setKey(activeTabFromRoute || '1')
    }, [activeTabFromRoute])

    useEffect(() => {
        const onActivePackageSucceedHandler = EventBus.on(ACTIVATE_PACKAGE_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
            setKey(ACTIVE_PACKAGES);
        });

        return () => {
            EventBus.remove(ACTIVATE_PACKAGE_SUCCEED, onActivePackageSucceedHandler);
        }
    }, [])
    return (
        <>
            <BalanceInfoCard />

            <Card className="mt-3">
                <Tabs activeKey={key} fill onSelect={key => {
                    routerHistory.push(`../user/billing?activeTab=${key}`)
                    setKey(key)
                }} className='border-bottom pt-3'>
                    <Tab className="bg-light" eventKey={ACTIVE_PACKAGES} title={t(lang, 'main.billing.tabs.active_packages_tab.title')}>
                        <OrganizationActivePackages />
                    </Tab>
                    <Tab className="bg-light" eventKey={ALL_PACKAGES} title={t(lang, 'main.billing.tabs.all_packages_tab.title')}>
                        <Packages />
                    </Tab>
                    <Tab className="bg-light" eventKey={OTHER} title={t(lang, 'main.billing.tabs.other.title')}>
                        <OtherTab />
                    </Tab>
                </Tabs>
            </Card>
        </>
    );
};

export default Billing;
