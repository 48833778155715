// board
export const DefaultBoardType = 0
export const ContractorDebtBoardType = 1

// column
export const ContractorDebtColumnType = 1
export const ContractorDebtColumnOverdueType = 2
export const ContractorDebtColumnPartialPaymentType = 3
export const ContractorDebtColumnPaidType = 4


export const labelWrapper = [
    {color_type: 1, bg: 'success'},
    {color_type: 2, bg: 'primary'},
    {color_type: 3, bg: 'info'},
    {color_type: 4, bg: 'danger'},
    {color_type: 5, bg: 'secondary'},
    {color_type: 6, bg: 'warning'}
]

export const statusLabels = (status) => {
    switch (status)  {
        case 1 :
            return 'success'
        case 2 :
            return 'primary'
        case 3 :
            return 'info'
        case 4 :
            return 'danger'
        case 5 :
            return 'secondary'
        case 6 :
            return 'warning'
        default :
            return 'info'
    }
}

export const memberChangeType = (type) => {
    switch (type) {
        case 0 :
            return "task.board.history.datatable.change_type.register"
        case 1 :
            return "task.board.history.datatable.change_type.update"
        case 2 :
            return "task.board.history.datatable.change_type.delete"
    }
}

export const boardType = [
    {type: DefaultBoardType, name: 'По умолчанию'},
    {type: ContractorDebtBoardType, name: 'task.board.contractor.debt.type'}
]

export const boardTypeName = (type) => {
    switch (type) {
        case DefaultBoardType :
            return 'По умолчанию'
        case ContractorDebtBoardType :
            return 'task.board.contractor.debt.board.type'
    }
}

export const columnType = (boardType, columnType) => {
    if (boardType === ContractorDebtBoardType) {
        switch (columnType) {
            case ContractorDebtColumnType :
                return {
                    name: "task.board.contractor.debt.column.type.1",
                    variant: "primary"
                }
            case ContractorDebtColumnOverdueType :
                return {
                    name: "task.board.contractor.debt.column.type.2",
                    variant: "danger"
                }
            case ContractorDebtColumnPartialPaymentType :
                return {
                    name: "task.board.contractor.debt.column.type.3",
                    variant: "warning"
                }
            case ContractorDebtColumnPaidType :
                return {
                    name: "task.board.contractor.debt.column.type.4",
                    variant: "success"
                }
        }
    }

}

export const boardColumnsTypes = [
    {value: ContractorDebtColumnType, label: 'task.board.contractor.debt.column.type.1'},
    {value: ContractorDebtColumnOverdueType, label: 'task.board.contractor.debt.column.type.2'},
    {value: ContractorDebtColumnPartialPaymentType, label: 'task.board.contractor.debt.column.type.3'},
    // {value: ContractorDebtColumnPaidType, label: 'task.board.contractor.debt.column.type.4'}
]
