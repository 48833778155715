import React, {useState} from "react";
import {registerLeadNoteAsync} from "../../../../../app/store/reducers/contractor/leadNoteReducer";
import {useSelector} from "react-redux";
import {selectCardLead} from "../../../../../app/store/reducers/contractor/contractorLeadReducer";
import LeadNoteForm from "./LeadNoteForm";

const LeadAddNoteForm = () => {
    const lead = useSelector(selectCardLead)
    const [loading, setLoading] = useState(false)
    const handleOnSubmit = (formData) => {
        setLoading(true)
        registerLeadNoteAsync({
            lead_id: lead?.id,
            comment: formData.note
        }).then(res => {
            setLoading(false)
        }).catch(errors => {
            setLoading(false)
        })
    }
    return (<>
        <LeadNoteForm onSubmit={handleOnSubmit} loading={loading}/>
    </>)
}
export default LeadAddNoteForm