import axios from "axios";

class CrmOrderService {
    //customer
    getCustomerOrders(params) {
        return axios.get('/order-storage/order/customer/get', {
            params: params,
        })
    }
    getCustomerOrdersCount(params) {
        return axios.get('/order-storage/order/customer/count', {
            params
        })
    }
    registerCustomerOrder(payload) {
        return axios.post('/order-storage/order/customer/register', payload)
    }
    updateCustomerOrder(id, payload) {
        return axios.post(`/order-storage/order/customer/update/${id}`, payload)
    }

    //executor
    getExecutorOrders(params) {
        return axios.get('/order-storage/order/executor/get', {
            params: params,
        })
    }
    getExecutorOrdersCount(params) {
        return axios.get('/order-storage/order/executor/count', {
            params
        })
    }
    registerExecutorOrder(payload) {
        return axios.post('/order-storage/order/executor/register', payload)
    }
    updateExecutorOrder(id, payload) {
        return axios.post(`/order-storage/order/executor/update/${id}`, payload)
    }

    //order api
    getOrder(id) {
        return axios.get('/order-storage/order/get/' + id)
    }
    registerCrmOrder(payload) {
        return axios.post('/order-storage/order/customer/register', payload)
    }
    deleteOrder(id) {
        return axios.post('/order-storage/order/delete/' + id)
    }

    //order item api
    orderItemRegister(payload) {
        return axios.post('/order-storage/order/item/register', payload)
    }
    orderItemUpdate(payload) {
        return axios.post('/order-storage/order/item/update', payload)
    }
    itemDelete(payload) {
        return axios.post('/order-storage/order/item/delete', payload)
    }
    identifyItems({orderId}) {
        return axios.post(`/order-storage/order/set-executor-items/${orderId}`)
    }

    //update status
    cancelOrder(id, payload) {
        return axios.post(`/order-storage/order/cancel/${id}`, payload)
    }
    declineOrder(id, payload) {
        return axios.post(`/order-storage/order/decline/${id}`, payload)
    }
    newOrder(id, payload) {
        return axios.post(`/order-storage/order/new/${id}`, payload)
    }
    acceptOrder(id, payload) {
        return axios.post(`/order-storage/order/accept/${id}`, payload)
    }
    progressOrder(id, payload) {
        return axios.post(`/order-storage/order/start-processing/${id}`, payload)
    }
    completeOrder(id, payload) {
        return axios.post(`/order-storage/order/complete/${id}`, payload)
    }
}

export const crmOrderService = new CrmOrderService()
export default CrmOrderService
