import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadDraftRefundsAsync, loadDraftRefundsCountAsync, selectDraftRefundsFilterOptions} from "../../../../app/store/reducers/warehouse-operation/draftRefundReducer";
import DraftRefundDataTable from "../../../../components/hippo/warehouse-operation/draft-refund/database/DraftRefundDataTable";

const DraftRefunds = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectDraftRefundsFilterOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        loadDraftRefunds();
    }, [activeOrganization, filters])

    const loadDraftRefunds = () => {
        dispatch(loadDraftRefundsAsync({...filters}));
        dispatch(loadDraftRefundsCountAsync({...filters}));
    };

    return <DraftRefundDataTable/>;
};

export default DraftRefunds;