import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {edit, register} from "../../../../app/store/reducers/organization-binding/organizationBindingReducer";
import {toast} from "react-toastify";
import SelectItemsAccessType from "./SelectItemsAccessType";
import SelectSalesAccessType from "./SelectSalesAccessType";
import SelectWarehousesAccessType from "./SelectWarehousesAccessType";
import {
    AllCashBoxesDisabled,
    AllSalesDisabled,
    AllWarehousesDisabled,
    ItemPriceAccessTypeDisabled,
    SalesPaymentAccessTypeDisabled,
    SpecificItemsEnabled,
} from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ADD_PARTNER_FAILED,
    ADD_PARTNER_SUCCEED,
    EDIT_PARTNER_FAILED,
    EDIT_PARTNER_SUCCEED
} from "../../../../app/eventbus/organizationBinding/organizationBindingEvents";
import PropTypes from "prop-types";
import SelectSalesPaymentAccessType from "./SelectSalesPaymentAccessType";
import SelectItemsPriceAccessType from "./SelectItemsPriceAccessType";
import SelectCashBoxesAccessType from "./SelectCashBoxesAccessType";

const OrganizationBindingForm = ({organizationBinding, handleClose}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useForm({
        defaultValues: {
            code: organizationBinding?.supervisor_organization_code || '',
            itemsAccessType: organizationBinding?.item_access_type || SpecificItemsEnabled,
            itemsPriceAccessType: organizationBinding?.item_price_access_type || ItemPriceAccessTypeDisabled,
            salesAccessType: organizationBinding?.sales_access_type || AllSalesDisabled,
            salesPaymentAccessType: organizationBinding?.sales_payment_access_type || SalesPaymentAccessTypeDisabled,
            warehousesAccessType: organizationBinding?.warehouse_access_type || AllWarehousesDisabled,
            cashBoxesAccessType: organizationBinding?.cash_box_access_type || AllCashBoxesDisabled,
        }
    });

    const onSubmit = (formData) => {
        const payload = {
            supervisor_organization_code: formData.code,
            item_access_type: formData.itemsAccessType,
            item_price_access_type: formData.itemsPriceAccessType,
            sales_access_type: formData.salesAccessType,
            sales_payment_access_type: formData.salesPaymentAccessType,
            warehouse_access_type: formData.warehousesAccessType,
            cash_box_access_type: formData.cashBoxesAccessType,
        };

        if (organizationBinding) {
            edit(organizationBinding.id, payload)
                .then(() => {
                    EventBus.dispatch(EDIT_PARTNER_SUCCEED);
                    toast.success(t(lang, 'partners.common.toast.edit_partner_success'));
                    form.reset();
                    handleClose();
                })
                .catch((error) => {
                    EventBus.dispatch(EDIT_PARTNER_FAILED);
                    toast.error(t(lang, 'partners.common.toast.edit_partner_error'));
                    if (error.response.data) {
                        if (error.response.data?.organization_binding_exists) {
                            form.setError('serverError', {
                                message: t(lang, 'partners.common.edit_partner_error.organization_binding_exists', {code: payload.supervisor_organization_code})
                            })
                        } else {
                            form.setError('serverError', {
                                message: error.response.data?.error
                            })
                        }
                    }
                })
        } else {
            register(payload)
                .then(() => {
                    EventBus.dispatch(ADD_PARTNER_SUCCEED)
                    toast.success(t(lang, 'partners.common.toast.add_partner_success'));
                    form.reset();
                    handleClose();
                })
                .catch((error) => {
                    EventBus.dispatch(ADD_PARTNER_FAILED)
                    toast.error(t(lang, 'partners.common.toast.add_partner_error'));
                    if (error.response.data) {
                        if (error.response.data?.organization_binding_exists) {
                            form.setError('serverError', {
                                message: t(lang, 'partners.common.add_partner_error.organization_binding_exists', {code: payload.supervisor_organization_code})
                            })
                        } else {
                            form.setError('serverError', {
                                message: error.response.data?.error
                            })
                        }
                    }
                })
        }
    };

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <FormProvider {...form}>
                <Row className="gy-3">
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.organization_code')}</Form.Label>
                            <Controller name={`code`}
                                        rules={{
                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <>
                                                <Form.Control defaultValue={field.value}
                                                              onChange={field.onChange}
                                                              placeholder={t(lang, 'partners.common.organization_code.placeholder')}
                                                              isInvalid={!!error || !!form.formState.errors?.serverError}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{error?.message || form.formState.errors?.serverError?.message}</Form.Control.Feedback>
                                            </>
                                        )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.items_access')}</Form.Label>
                            <Controller name={'itemsAccessType'}
                                        rules={{
                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <>
                                                <SelectItemsAccessType onChange={field.onChange}
                                                                       defaultItemsAccessType={field.value}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{error?.message}</Form.Control.Feedback>
                                            </>
                                        )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.items_price_access')}</Form.Label>
                            <Controller name={'itemsPriceAccessType'}
                                        rules={{
                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <>
                                                <SelectItemsPriceAccessType onChange={field.onChange}
                                                                            defaultItemsPriceAccessType={field.value}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{error?.message}</Form.Control.Feedback>
                                            </>
                                        )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.sales_access')}</Form.Label>
                            <Controller name={'salesAccessType'}
                                        rules={{
                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <>
                                                <SelectSalesAccessType onChange={field.onChange}
                                                                       defaultSalesAccessType={field.value}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{error?.message}</Form.Control.Feedback>
                                            </>
                                        )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.sales_payment_access')}</Form.Label>
                            <Controller name={'salesPaymentAccessType'}
                                        rules={{
                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <>
                                                <SelectSalesPaymentAccessType onChange={field.onChange}
                                                                              defaultSalesPaymentAccessType={field.value}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{error?.message}</Form.Control.Feedback>
                                            </>
                                        )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.warehouses_access')}</Form.Label>
                            <Controller name={'warehousesAccessType'}
                                        rules={{
                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <>
                                                <SelectWarehousesAccessType onChange={field.onChange}
                                                                            defaultWarehousesAccessType={field.value}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{error?.message}</Form.Control.Feedback>
                                            </>
                                        )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.cash_boxes_access')}</Form.Label>
                            <Controller name={'cashBoxesAccessType'}
                                        rules={{
                                            required: t(lang, 'crm.common.forms.validations.is_required')
                                        }}
                                        render={({field, fieldState: {error}}) => (
                                            <>
                                                <SelectCashBoxesAccessType onChange={field.onChange}
                                                                           defaultCashBoxAccessType={field.value}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{error?.message}</Form.Control.Feedback>
                                            </>
                                        )}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="text-end">
                        <Button variant="secondary"
                                onClick={handleClose}
                                className="mx-2"
                        >
                            {t(lang, 'items.common.cancel')}
                        </Button>
                        <Button variant="primary"
                                disabled={form.formState.isSubmitting}
                                type="submit"
                        >
                            {form.formState.isSubmitting &&
                                <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {t(lang, 'items.common.save')}
                        </Button>
                    </Col>
                </Row>
            </FormProvider>
        </Form>
    );
};

export default OrganizationBindingForm;

OrganizationBindingForm.propTypes = {
    organizationBinding: PropTypes.object,
    handleClose: PropTypes.func
};

OrganizationBindingForm.defaultProps = {
    organizationBinding: null,
    handleClose: () => {
    }
};