import {Dropdown, InputGroup} from "react-bootstrap";
import {dropDownDiscountOptions, withoutDiscount} from "../enum/warehouseOperationWrapper";
import Cleave from "cleave.js/react";
import {useEffect, useState} from "react";


const InputGroupWithDiscount = ({onChangeAmount, onChangeDiscount, defaultAmount, defaultType, inputProps, ...props}) => {
    const [amount, setAmount] = useState(0);
    const [discount, setDiscount] = useState(withoutDiscount)

    useEffect(() => {
        if (amount !== defaultAmount) {
            setAmount(defaultAmount)
        }
        if (discount !== defaultType) {
            setDiscount(defaultType)
        }
    },[defaultAmount, defaultType])


    const onDiscountHandler = (option) => {
        if (option.value !== discount) {
            setDiscount(option.value)
            setAmount(0)
            onChangeAmount(0)
            onChangeDiscount(option?.value)
        }
    }

    return (
        <InputGroup {...props}>
            <Dropdown>
                <Dropdown.Toggle
                    id="dropdown-basic"
                    variant="primary"
                    style={{
                        zIndex: "0"
                    }}
                >
                    {!discount ? "🏷️" : "%"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {dropDownDiscountOptions.map((item) => {
                        return(
                            <Dropdown.Item active={discount === item.value} eventKey={item.value} key={item.value} onClick={() => onDiscountHandler(item)}>
                                {item.label}
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
            <Cleave value={amount}
                    onChange={(e) => {
                        setAmount(e.target.rawValue);
                        onChangeAmount(e.target.rawValue);
                    }}
                    {...inputProps}
            />
        </InputGroup>
    );
};

export default InputGroupWithDiscount;
