import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from 'react';
import {Modal} from "react-bootstrap";
import ViewSale from "../../../../../../pages/main/warehouse-operation/sale/ViewSale";
import ViewRefund from "../../../../../../pages/main/warehouse-operation/refund/ViewRefund";
import ViewReturnPurchase from "../../../../../../pages/main/warehouse-operation/return-purchase/ViewReturnPurchase";
import ViewPurchase from "../../../../../../pages/main/warehouse-operation/purchase/ViewPurchase";
import ContractorPayment from "../../../../../../pages/main/crm/contractor/ContractorPayment";
import ContractorPayout from "../../../../../../pages/main/crm/contractor/ContractorPayout";
import SpinnerComponent from "../../../../spinner/SpinnerComponent";

const PAYMENT = 'payment'
const PAYOUT = 'payout'
const PURCHASE = 'purchase'
const REFUND = 'refund'
const RETURN = 'return'
const SALE = 'sale'

const viewPages = [
    {key: PAYMENT, component: ContractorPayment},
    {key: PAYOUT, component: ContractorPayout},
    {key: SALE, component: ViewSale},
    {key: REFUND, component: ViewRefund},
    {key: RETURN, component: ViewReturnPurchase},
    {key: PURCHASE, component: ViewPurchase},
]

const ContractorActReportsModal = forwardRef(({viewReport, ...props}, ref) => {

    const [show, setShow] = useState(false)
    const [showPage, setShowPage] = useState(null)
    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
        setShowPage(null)
    }

    useImperativeHandle(ref, () => ({
        handleShow
    }))

    const handlePage = (viewReportKey) => {
        setShowPage({operation: viewReportKey, ...viewReport[viewReportKey]})
    }

    useEffect(() => {
        for (const viewReportKey in viewReport) {
            switch (viewReportKey) {
                case PAYMENT:
                case PAYOUT:
                case PURCHASE:
                case REFUND:
                case RETURN:
                case SALE:
                    if (viewReport[viewReportKey]) {
                        handlePage(viewReportKey);
                        return;
                    }
                    break;
                default:
                    break;
            }
        }
    }, [viewReport]);

    const currentViewPage = viewPages.find(page => page.key === showPage?.operation);

    return (
        <Fragment>
            <Modal fullscreen show={show} onHide={handleClose}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    {!viewReport && <SpinnerComponent />}
                    {viewReport && currentViewPage && (
                        <currentViewPage.component viewId={showPage.id} />
                    )}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
});

export default ContractorActReportsModal;
