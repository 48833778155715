import React, {useEffect} from "react";
import AddOrderForm from "../../../../components/hippo/order/forms/AddOrderForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_ORDER_SUCCESS} from "../../../../app/eventbus/orderEvents";
import {useHistory} from "react-router-dom";
import {PermissionEdiOrderCreate} from "../../../../enum/Permission/EdiPermission";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const AddOrder = () => {
    const routerHistory = useHistory();
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiOrderCreate))){
            if (routerHistory.length > 1) {
                routerHistory.push('../orders')
            } else {
                routerHistory.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        const onOrderAddedHandler = EventBus.on(ADD_ORDER_SUCCESS, (order) => {
            routerHistory.push(`/edi/order/${order.id}`)
        });

        return () => {
            EventBus.remove(ADD_ORDER_SUCCESS, onOrderAddedHandler);
        }
    }, []);

    return <AddOrderForm/>
};

export default AddOrder;
