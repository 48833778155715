import React from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import ReactSelect from "react-select";

const SelectCategory = ({onChange, defaultCategoryId, categories, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ReactSelect
            options={categories}
            isSearchable={!defaultCategoryId}
            isClearable
            hideSelectedOptions
            classNamePrefix="select"
            placeholder={t(lang, "items.common.select")}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onChange}
            value={categories.find(c => c.id === defaultCategoryId) || null}
            {...selectProps}
        />
    );
};

export default SelectCategory;
