import React from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";

const DataTableFilterForm = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    return (
        <Row>
            <Col sm={6}>
                <Row className="g-2">
                    <Col>
                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.datatable.filter.name')}</Form.Label>
                            <Form.Control name="name"
                                          placeholder={t(lang, 'items.common.datatable.filter.name.placeholder')}
                                          {...form.register('name')}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="d-flex align-items-end">
                        <Button type="submit">{t(lang, "items.common.datatable.filter.search")}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
};

export default DataTableFilterForm;