import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    loadRolesAsync,
    selectRoles
} from "../../app/store/reducers/role/roleReducer";
import Select from 'react-select';
import PropTypes from "prop-types";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const SelectRole = ({onChange, defaultRole}) => {
    const roles = useSelector(selectRoles);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [role, setRole] = useState(defaultRole || null)

    const onOptionChange = (option) => {
        onChange(option);
        setRole(option);
    }
    useEffect(() => {
        dispatch(loadRolesAsync())
    }, [])

    return (
        <Select
            isClearable
            defaultValue={role}
            options={roles}
            onChange={onOptionChange}
            hideSelectedOptions={true}
            getOptionLabel={option => t(lang, option.name)}
            classNamePrefix="react-select"
            getOptionValue={option => option.id}
            placeholder={t(lang, 'edi.common.form.select.placeholder')}
        />
    )
}
SelectRole.propTypes = {
    onChange: PropTypes.func
}
SelectRole.defaultProps = {
    onChange: () => {
    }
}
export default SelectRole;
