import dayjs from "dayjs";
import {createSlice} from "@reduxjs/toolkit";
import ContractorService from "../../../../services/contractorService";

const contractorActReportSlice = createSlice({
    name: 'contactorActReport',
    initialState: {
        actReport: null,
        filterOptions: {
            date_start: dayjs(new Date()).format('YYYY-MM-DD'),
            date_end: dayjs(new Date()).format('YYYY-MM-DD'),
        },
    },
    reducers: {
        updateActReports: (state, action) => {
            state.actReport = action.payload;
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload};
        },
    },
});

export const selectContractorActReport = state => state.contactorActReport.actReport;
export const selectContractorActReportFiltersOptions = state => state.contactorActReport.filterOptions;

export const loadContactorActReportsAsync = (id, params) => dispatch => {
    ContractorService.getActReports(id, params)
        .then(response => dispatch(contractorActReportSlice.actions.updateActReports(response.data)))
        .catch(() => dispatch(contractorActReportSlice.actions.updateActReports(null)))
}

export const getExcelActReportAsync = ({id, dateStart, dateEnd}) => {
    return new Promise((resolve, reject) => {
        ContractorService.getExcelActReport({id, dateStart, dateEnd})
            .then((response) => resolve({content: response.data, contentType: response.headers['content-type']}))
            .catch((e) => reject(e))
    })
}

export const changeContractorActReportFilterOptions = ({...params}) => dispatch => {
    dispatch(contractorActReportSlice.actions.updateFilterOptions({...params}));
}

export default contractorActReportSlice.reducer;
