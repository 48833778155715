import React, {useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Badge, Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import PropTypes from "prop-types";
import {
    bindContractorLabelAsync,
} from "../../../../../app/store/reducers/contractor/contractorLabelReducer";
import {toast} from "react-toastify";
import SelectContractorLabel from "./SelectContractorLabel";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    BIND_CONTRACTOR_LABEL_SUCCESS
} from "../../../../../app/eventbus/contractor/contractorLabelEvents";

const MassBindLabelsToContractorModal = ({show, setShow, contractorIds}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false)

    const methods = useForm({
        defaultValues: {
            labels: []
        }
    })

    const onHide = () => {
        methods.reset()
        setShow(false)
    }

    const onSubmit = async (formData) => {
        setLoading(true)
        for (const contractor_id of contractorIds) {
            for (const label of formData.labels) {
                await bindContractorLabelAsync({
                    contractor_id: contractor_id,
                    label: label.id
                }).then((contractor) => {
                    EventBus.dispatch(BIND_CONTRACTOR_LABEL_SUCCESS, contractor)
                }).catch(() => toast.error(t(lang, 'edi.common.toast.error'))).finally(() => setLoading(false))
            }
        }
        onHide()
    }

    return (
        <Modal show={show} onHide={onHide} size={'md'}>
            <Modal.Header>
                <Modal.Title>{t(lang, "crm.contractor.bind.label.button")}</Modal.Title>
                <FalconCloseButton onClick={onHide}/>
            </Modal.Header>
            <Modal.Body>
                <div className={'mb-3'}>
                    <span
                        className={'fs--5 h5 me-1'}>{t(lang, "crm.contractor.mass_bind.selected_contractors_text")}:</span>
                    <Badge bg="primary"><h5 className={'text-white'}>{contractorIds.length} шт</h5></Badge>
                </div>
                <FormProvider {...methods}>
                    <Form id={'bindContractorLabel'} onSubmit={methods.handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'crm.contractor.label')}</Form.Label>
                            <Controller control={methods.control}
                                        name={'labels'}
                                        rules={{required: t(lang, 'roaming.validations.is_required')}}
                                        render={({field}) => {
                                            return (
                                                <SelectContractorLabel
                                                    defaultContractorLabelId={field?.value?.map(val => val.id)}
                                                    isMulti
                                                    placeholder={t(lang, "crm.contractor.datatable.label")}
                                                    onChange={field.onChange}/>
                                            )
                                        }}/>
                        </Form.Group>
                    </Form>
                </FormProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button type='button' variant='falcon-primary'
                        onClick={onHide}>{t(lang, 'edi.common.button.close')}</Button>

                <Button type='submit' form={'bindContractorLabel'} variant='primary' disabled={loading}>
                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                    {t(lang, 'edi.common.button.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

MassBindLabelsToContractorModal.prototype = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    contractorIds: PropTypes.array
}
export default MassBindLabelsToContractorModal;
