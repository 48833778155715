import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {loadCompanyProductCatalogsAsync} from "../../../../../app/store/reducers/roaming/roamingReducer";

import {registerInternalOrderAsync,} from "../../../../../app/store/reducers/crm/internal/crmInternalOrderReducer";

import {Form} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import InternalOrderForm from "../../../../../components/hippo/crm/internal/order/forms/InternalOrderForm";
import {UZS} from "../../../../../enum/CurrencyCode";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";

const CrmInternalOrderAddForm = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const currencies = useSelector(selectCurrency);

    const history = useHistory()
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization)
    const [loading, setLoading] = useState(false);

    const methods = useForm({
        defaultValues: {
            branch_from: null,
            branch_to: null,
            info: {
                number: '',
                date: null
            },
            contract: {
                number: '',
                date: null
            },
            items: [
                {
                    product: '',
                    barcode: '',
                    product_code: '',
                    measurement: '',
                    price: {
                        amount: '',
                        currency_id: currencies?.find(c => c?.code === UZS)?.id
                    },
                    quantity: '',
                }
            ],
            expires_on: null,
        }
    });

    const onSubmit = formData => {
        setLoading(true);
        registerInternalOrderAsync({
            items: formData.items.map(item => {
                return {
                    id: item.product?.id,
                    product: item.product?.name,
                    barcode: item.barcode || null,
                    product_code: item.product_code || null,
                    measurement: String(item.measurement),
                    price: {
                        amount: +item.price.amount,
                        currency_id: item.price.currency_id
                    },
                    quantity: +item.quantity,
                }
            }),
            info: {
                number: formData.info.number,
                date: formData?.info?.date ? dayjs(formData.info.date).format('YYYY-MM-DD') : null
            },
            contract: {
                number: formData.contract.number,
                date: formData?.contract?.date ? dayjs(formData.contract.date).format('YYYY-MM-DD') : null
            },
            branch_from: formData.branch_from.id,
            branch_to: formData.branch_to.id,
            expires_on: formData?.expires_on ? dayjs(formData.expires_on).format('YYYY-MM-DD') : null,
        }).then(() => {
            toast.success(t(lang, 'crm.common.toast.success'));
            history.push('/crm/internal/order/all')
        }).catch(() => {
            toast.error(t(lang, 'crm.common.toast.error'));
        }).finally(() => {
            setLoading(false);
        })
    };

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
    }, [activeOrganization])

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <InternalOrderForm loading={loading}
                                   formTitle={t(lang, "crm.internal.order.create_new_order_form_title")}/>
            </Form>
        </FormProvider>
    );
};

export default CrmInternalOrderAddForm;
