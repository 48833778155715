export const version = '1.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'xl';
export const settings = {
    isFluid: true,
    isRTL: false,
    isDark: false,
    navbarPosition: 'top',
    showBurgerMenu: true, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'card'
};

const config = { version, navbarBreakPoint, topNavbarBreakpoint, settings };
export default config;
