import React from 'react'
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux';
import {Button, Col, Form, Row} from 'react-bootstrap'
import DatePicker from "react-datepicker";
import {selectDateFormat, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import SelectYesOrNo from "../SelectYesOrNo";
import SelectStatus from "../SelectStatus";
import {ReturnOrderStatuses} from "../../../enum/ReturnOrderStatus";
import SelectBranchUse from "../../common/SelectBranchUse";
import {selectSystemOrderCustomers} from "../../../app/store/reducers/order/orderReducer";
import Select from "../../common/Select";

const ReturnOrderDataTableFilter = () => {
    const {register} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const systemOrderCustomers = useSelector(selectSystemOrderCustomers);

    return (
        <>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.return_order.datatable.header.filter.title")}</h4>
            </Row>
            <Row className="mb-3 g-3">
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.return_order.datatable.header.filter.date_start")}</Form.Label>
                    <Controller
                        name="date_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "edi.return_order.datatable.header.filter.date_start:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.return_order.datatable.header.filter.date_end")}</Form.Label>
                    <Controller
                        name="date_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "edi.return_order.datatable.header.filter.date_end:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.return_order.datatable.header.filter.status")}</Form.Label>
                    <Controller
                        name="status"
                        render={({field}) => (
                            <SelectStatus statuses={ReturnOrderStatuses}
                                          isClearable
                                          defaultStatus={field.value}
                                          placeholder={t(lang, "edi.return_order.datatable.header.filter.status:placeholder")}
                                          onChange={statusId => field.onChange(typeof (statusId) !== 'undefined' ? statusId : null)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.return_order.datatable.header.filter.executer_is_approve")}</Form.Label>
                    <Controller
                        name="is_executor_approved"
                        render={({field}) => (
                            <SelectYesOrNo classNamePrefix="react-select" defaultValue={field.value}
                                           onChange={data => field.onChange(data.value)}/>
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.common.customer")}</Form.Label>
                    <Controller name={'customer_inn'}
                                render={({field}) => (
                                    <Select options={systemOrderCustomers} field={field} label={'name'} value={'inn'}
                                            placeholder={"edi.common.button.select"} isClearable hideSelectedOptions/>
                                )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.return_order.datatable.header.filter.branch")}</Form.Label>
                    <Controller
                        name="branch_id"
                        render={({field}) => (
                            <SelectBranchUse
                                isClearable
                                defaultValue={field.value}
                                placeholder={t(lang, "edi.return_order.datatable.header.filter.date_end:placeholder")}
                                onChange={data => field.onChange(data?.branch?.id)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.return_order.datatable.header.filter.number")}</Form.Label>
                    <Form.Control {...register("return_order_number")}
                                  placeholder={t(lang, "edi.return_order.datatable.header.filter.number:placeholder")}/>
                </Form.Group>
                <Form.Group as={Col} md={4} lg={3}>
                    <Form.Label>{t(lang, "roaming.common.executor")}</Form.Label>
                    <Form.Control name="executor_inn"
                                  type="text"
                                  placeholder={t(lang, "edi.common.executor.inn")}
                                  {...register('executor_inn')}/>
                </Form.Group>
                <Form.Group as={Col} className="d-flex align-items-end justify-content-end justify-content-sm-end">
                    <Button variant="primary"
                            type='submit'>{t(lang, "edi.return_order.datatable.header.filter.show")}</Button>
                </Form.Group>
            </Row>
        </>
    )
}

export default ReturnOrderDataTableFilter
