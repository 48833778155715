import React, {useCallback, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {loadBindingSale} from "../../../../app/store/reducers/organization-binding/organizationBindingReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import Sale from "./Sale";

const ViewSaleModal = ({binding, saleId}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const [sale, setSale] = useState(null);

    const load = useCallback(() => {
        setLoading(true);
        loadBindingSale(binding.id, saleId)
            .then((response) => {
                setSale(response);
                setLoading(false);
            })
            .catch((error) => {
                setSale(null);
                setLoading(false);
            })
    }, [binding, saleId]);

    return (
        <React.Fragment>
            <IconButton icon={faEye}
                        variant="falcon-primary"
                        disabled={loading}
                        onClick={load}
            />
            {sale && <Modal show={true} fullscreen>
                <Modal.Header>
                    <Modal.Title>{t(lang, 'partners.common.sales.client')}: {sale?.contractor?.name}</Modal.Title>
                    <FalconCloseButton onClick={() => setSale(null)}/>
                </Modal.Header>
                <Modal.Body>
                    <Sale binding={binding}
                          sale={sale}
                    />
                </Modal.Body>
            </Modal>}
        </React.Fragment>
    );
};

export default ViewSaleModal;