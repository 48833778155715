import React, {Fragment, useEffect, useState} from 'react';
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../common/advance-table/AdvanceTableFooter";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import LeadDatatableHeader from "./LeadDatatableHeader";
import {
    loadLeadsAsync,
    loadLeadsCountAsync,
    selectLeads,
    selectLeadsCount,
    selectLeadsFilterOptions,
    changeFilterOptionsAsync
} from "../../../../app/store/reducers/contractor/contractorLeadReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {toast} from "react-toastify";
import {
    CONVERT_LEAD_TO_CONTRACTOR_SUCCESS,
    DELETE_LEAD_SUCCESS,
    EDIT_LEAD_SUCCESS,
    REGISTER_LEAD_SUCCESS,
} from "../../../../app/eventbus/contractor/contractorLeadEvents";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import {FindLeadType, LeadTypeBuyer, LeadTypeProvider} from "../../../../enum/LeadTypes";
import SoftBadge from "../../../common/SoftBadge";
import IconButton from "../../../common/IconButton";
import EditLead from "../contractor/lead/EditLead";
import DeleteLeadModal from "../contractor/lead/DeleteLeadModal";
import ConvertLeadToContractorModal from "../contractor/lead/ConvertLeadToContractorModal";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContractorTypeBuyer, ContractorTypeProvider} from "../../../../enum/ContractorWrapper";

const LeadDatatable = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()

    const [editingLead, setEditingLead] = useState(null);
    const [deletingLeadId, setDeletingLeadId] = useState(null);
    const [convertingLeadId, setConvertingLeadId] = useState(null);

    const filters = useSelector(selectLeadsFilterOptions)
    const leads = useSelector(selectLeads)
    const count = useSelector(selectLeadsCount)

    const loadLeads = () => {
        dispatch(loadLeadsAsync({...filters, organization_id: activeOrganization.id}))
        dispatch(loadLeadsCountAsync({...filters}))
    }

    useEffect(() => {
        loadLeads()
    }, [filters, activeOrganization])

    useEffect(() => {
        const onLeadRegisterHandler = EventBus.on(REGISTER_LEAD_SUCCESS, () => {
            toast.success(t(lang, 'crm.common.toast.success'));
            loadLeads()
        })

        const onLeadEditHandler = EventBus.on(EDIT_LEAD_SUCCESS, () => {
            toast.success(t(lang, 'crm.common.toast.success'));
            loadLeads()
        })

        const onLeadDeleteHandler = EventBus.on(DELETE_LEAD_SUCCESS, () => {
            toast.success(t(lang, 'crm.common.toast.success'));
            loadLeads()
        })

        const onLeadConvertedToContractorHandler = EventBus.on(CONVERT_LEAD_TO_CONTRACTOR_SUCCESS, () => {
            toast.success(t(lang, 'crm.common.toast.success'));
            loadLeads()
        })

        return () => {
            EventBus.remove(REGISTER_LEAD_SUCCESS, onLeadRegisterHandler)
            EventBus.remove(EDIT_LEAD_SUCCESS, onLeadEditHandler)
            EventBus.remove(DELETE_LEAD_SUCCESS, onLeadDeleteHandler)
            EventBus.remove(CONVERT_LEAD_TO_CONTRACTOR_SUCCESS, onLeadConvertedToContractorHandler)
        }

    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            Header: t(lang, "edi.common.label.name"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`lead-card/${original.id}`}>
                        <strong>{original.name}</strong>
                    </Link>
                );
            }
        },
        {
            Header: t(lang, "edi.common.label.inn"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <strong>{original.inn ? original.inn : 'Не указано'}</strong>
                );
            }
        },
        {
            accessor: 'type',
            Header: 'Тип',
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                if (original.type?.length > 0) {
                    return original.type?.map((item, index) => {
                        if (item === LeadTypeBuyer) {
                            return (
                                <SoftBadge bg="success" key={index}>{t(lang, "edi.common.buyer")}</SoftBadge>
                            )
                        }
                        if (item === LeadTypeProvider) {
                            return (
                                <SoftBadge bg="warning" key={index}>{t(lang, "edi.common.executor")}</SoftBadge>
                            )
                        }
                    })
                } else {
                    return (<SoftBadge bg={'info'}>Не указано</SoftBadge>)
                }
            }
        },
        {
            accessor: 'contact',
            Header: 'Контакт',
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                if (original.contacts?.length > 0) {
                    return original.contacts?.map((item, index) => {
                        return (<span className={"text-info mx-1"} key={index}>{item.value}</span>)
                    })
                } else {
                    return (<SoftBadge bg={'info'}>Не указано</SoftBadge>)
                }
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <>
                        {!row.original.contractor && <IconButton
                            className={'py-0'}
                            variant="falcon-info"
                            size="sm"
                            icon="sync"
                            onClick={() => {
                                setConvertingLeadId(row.original.id);
                            }}
                            transform="shrink-3"
                        />}

                        <IconButton
                            className="mx-2 py-0"
                            variant="falcon-warning"
                            size="sm"
                            icon="edit"
                            onClick={() => {
                                setEditingLead(row.original)
                            }}
                            transform="shrink-3"
                        />
                        <IconButton
                            className={'py-0'}
                            variant="falcon-danger"
                            onClick={() => {
                                setDeletingLeadId(row.original.id);
                            }}
                            size="sm"
                            icon="trash"
                            transform="shrink-3"
                        />
                    </>
                );
            }
        }
    ].filter(i => i);

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }
    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={leads}
                pagination
                perPage={10}
            >
                <Card>
                    <Card.Header>
                        <LeadDatatableHeader table/>
                    </Card.Header>

                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}>
                        </AdvanceTable>
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            table
                        />
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            {editingLead &&
            <EditLead lead={editingLead} onClose={() => {
                setEditingLead(null)
            }}/>
            }

            {deletingLeadId &&
            <DeleteLeadModal id={deletingLeadId} show={!!deletingLeadId} onClose={() => {
                setDeletingLeadId(null)
            }}/>
            }

            {convertingLeadId &&
            <ConvertLeadToContractorModal id={convertingLeadId} show={!!convertingLeadId} onClose={() => {
                setConvertingLeadId(null)
            }}/>
            }

        </Fragment>
    );
};

export default LeadDatatable;