import React, {Fragment} from 'react';
import Operation from "../../../../components/hippo/cashbox/operation/Operation";
import {getOperationAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {Modal} from "react-bootstrap";

const ViewOperation = ({show, setShow, id}) => {
    return (
        <Fragment>
            <Modal show={show} onHide={() => setShow(false)} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Operation
                        request={getOperationAsync}
                        title={"edi.return_order.protocol_info.title"}
                        id={id}
                    />
                </Modal.Body>
            </Modal>

        </Fragment>

    );
};

export default ViewOperation;
