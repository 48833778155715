import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import WaybillForm from "../../../hippo/roaming/waybill/forms/WaybillForm";
import {useDispatch, useSelector} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import React, {useEffect, useRef, useState} from 'react';
import ErrorMessageAlert from "../../../common/ErrorMessageAlert";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {saveDraftWaybillAsync, sendWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {
    loadCompanyProductCatalogsAsync,
    loadMeasurementsAsync,
    selectCompanyCatalogs
} from "../../../../app/store/reducers/roaming/roamingReducer";

const EditWaybillForm = ({waybill}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const catalogs = useSelector(selectCompanyCatalogs)
    const alertRef = useRef(null);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            shipment: {
                number: waybill.WaybillDoc.WaybillNo,
                date: dayjs(waybill.WaybillDoc.WaybillDate).toDate(),
            },
            contract: {
                number: waybill.ContractDoc.ContractNo,
                date: dayjs(waybill.ContractDoc.ContractDate).toDate(),
            },
            waybill: {
                number: waybill.TripTicketDoc.TripTicketNo,
                date: dayjs(waybill.TripTicketDoc.TripTicketDate).toDate(),
            },

            truck: {
                register_number: waybill.TruckDoc.TruckRegNo,
                model: waybill.TruckDoc.TruckModel,
            },
            trailer: {
                type: waybill.TrailerDoc.TrailerType,
                register_number: waybill.TrailerDoc.TrailerRegNo,
                model: waybill.TrailerDoc.TrailerModel,
            },
            delivery_type: waybill.DeliveryType,
            driver: waybill.DriverFio,

            customer: {
                inn: waybill.CustomerTin,
                name: waybill.CustomerName,
                branch: {
                    code: waybill.CustomerBranchCode,
                    name: waybill.CustomerBranchName,
                },
                seller: {
                    inn: waybill.SellerTin,
                    name: waybill.SellerName,
                },
                first_point_of_loading: waybill.PointDocs[0].PointOfLoading,
                second_point_of_loading: waybill.PointDocs[1].PointOfLoading,
            },

            carrier: {
                inn: waybill.CarrierTin,
                name: waybill.CarrierName,
                branch: {
                    code: waybill.CarrierBranchCode,
                    name: waybill.CarrierBranchName,
                },
                buyer: {
                    inn: waybill.BuyerTin,
                    name: waybill.BuyerName
                },
                first_point_of_unloading: waybill.PointDocs[0].PointOfUnloading,
                second_point_of_unloading: waybill.PointDocs[1].PointOfUnloading,
            },

            special_notes: waybill.SpecialNotes,
            point_of_redirect_name: waybill.PointOfRedirectName,
            point_of_redirect_address: waybill.PointOfRedirectAddress,
            giver_fio: waybill.GiverFio,
            giver_driver_fio: waybill.GiverDriverFio,
            taker_fio: waybill.TakerFio,
            taker_driver_fio: waybill.TakerDriverFio,
            delivery_distance: {
                total: waybill.DeliveryDistanceDoc.DeliveryDistance,
                in_city: waybill.DeliveryDistanceDoc.DeliveryDistanceInCity,
            },

            products: waybill.ProductList.Products.map(item => {
                const catalog = catalogs?.find(c => c?.class_code === item?.CatalogCode)
                const packages = catalog?.package_names.find(p => p?.code === +item?.PackageCode)


                return {
                    product: item.Name,
                    catalog_code: item.CatalogCode,
                    catalog_name: item.CatalogName,
                    price: item.Summa,
                    measureId: item.MeasureId,
                    packageCode: +item.PackageCode,
                    packageName: item.PackageName,
                    catalogPackage: catalog,
                    packages: packages,
                    quantity: item.Count,
                    total: item.TotalSum,
                    road_haulage_cost: item.RoadHaulageCost,
                    follow_documents: item.FollowDocuments,
                    weight_define_method: item.MethodDefineWeight,
                    load_class: item.LoadClass,
                    weight_brutto: item.WeightBrutto,
                    weight_netto: item.WeightNetto,
                }
            })
        }
    });

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization?.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        return () => {
            setIsSaving(false);
            setIsSending(false);
        }
    }, []);

    const sendWaybill = ({id, hashCode}) => {
        setIsSending(true);

        sendWaybillAsync(currentCertificate, lang, {id, hashCode})
            .then(() => toast.success(t(lang, 'roaming.waybill.send.toast.register_success')))
            .catch(error => {
                setIsSending(false);
                setErrorMessage(error.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    const saveDraftWaybill = ({id, content}) => {
        setIsSaving(true);

        saveDraftWaybillAsync({id, content})
            .then(() => toast.success(t(lang, 'roaming.waybill.save.toast.saved_success')))
            .catch(error => {
                setIsSaving(false);
                setErrorMessage(error.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    const onSubmit = async (formData, event) => {
        const actionType = event.nativeEvent.submitter.name;
        const {WaybillId, ProductList: {WaybillProductId}} = waybill;

        const data = JSON.stringify({
            WaybillId: WaybillId,
            WaybillDoc: {
                WaybillNo: formData.shipment.number,
                WaybillDate: dayjs(formData.shipment.date).format('YYYY-MM-DD')
            },
            ContractDoc: {
                ContractNo: formData.contract.number,
                ContractDate: dayjs(formData.contract.date).format('YYYY-MM-DD')
            },
            TripTicketDoc: {
                TripTicketNo: formData.waybill.number,
                TripTicketDate: dayjs(formData.waybill.date).format('YYYY-MM-DD')
            },
            DriverFio: formData.driver,
            DeliveryType: formData.delivery_type,
            TrailerDoc: {
                TrailerType: formData.trailer.type,
                TrailerRegNo: formData.trailer.register_number,
                TrailerModel: formData.trailer.model
            },
            TruckDoc: {
                TruckRegNo: formData.truck.register_number,
                TruckModel: formData.truck.model
            },
            CustomerTin: formData.customer.inn,
            CustomerName: formData.customer.name,
            CustomerBranchCode: formData.customer.branch.code,
            CustomerBranchName: formData.customer.branch.name,
            SellerTin: formData.customer.seller.inn,
            SellerName: formData.customer.seller.name,

            CarrierTin: formData.carrier.inn,
            CarrierName: formData.carrier.name,
            CarrierBranchCode: formData.carrier.branch.code,
            CarrierBranchName: formData.carrier.branch.name,
            BuyerTin: formData.carrier.buyer.inn,
            BuyerName: formData.carrier.buyer.name,

            PointDocs: [
                {
                    PointOfLoading: formData.customer.first_point_of_loading,
                    PointOfUnloading: formData.carrier.first_point_of_unloading
                },
                {
                    PointOfLoading: formData.customer.second_point_of_loading,
                    PointOfUnloading: formData.carrier.second_point_of_unloading
                }
            ],

            SpecialNotes: formData.special_notes,
            PointOfRedirectName: formData.point_of_redirect_name,
            PointOfRedirectAddress: formData.point_of_redirect_address,
            GiverFio: formData.giver_fio,
            GiverDriverFio: formData.giver_driver_fio,
            TakerFio: formData.taker_fio,
            TakerDriverFio: formData.taker_driver_fio,
            DeliveryDistanceDoc: {
                DeliveryDistance: +formData.delivery_distance.total || 0,
                DeliveryDistanceInCity: +formData.delivery_distance.in_city || 0
            },

            ProductList: {
                WaybillProductId: WaybillProductId,
                Tin: formData.carrier.inn,
                Products: formData.products.map((product, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: product.product,
                        MeasureId: product.measureId,
                        PackageCode: product.packageCode ? `${product.packageCode}` : null,
                        PackageName: product.packageName,
                        Summa: product.price,
                        Count: product.quantity,
                        TotalSum: product.total,
                        RoadHaulageCost: product.road_haulage_cost || 0,
                        FollowDocuments: product.follow_documents,
                        MethodDefineWeight: product.weight_define_method,
                        LoadClass: product.load_class,
                        WeightBrutto: +product.weight_brutto || 0,
                        WeightNetto: +product.weight_netto || 0,
                        CatalogCode: product.catalog_code,
                        CatalogName: product.catalog_name,
                    }
                })
            }
        })

        if (actionType === 'send') {
            sendWaybill({
                id: WaybillId,
                hashCode: data,
            });
        } else if (actionType === 'save') {
            saveDraftWaybill({
                id: WaybillId,
                content: data,
            });
        }
    }

    return (
        <>
            <FormProvider {...methods}>
                {errorMessage && <ErrorMessageAlert ref={alertRef} errorMessage={errorMessage}/>}
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <WaybillForm isSending={isSending} isSaving={isSaving} />
                </Form>
            </FormProvider>
        </>
    );
};

export default EditWaybillForm;