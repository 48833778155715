export const SEND_FREE_FORM_DOCUMENT_REQUESTED = "SEND_FREE_FORM_DOCUMENT_REQUESTED";
export const SEND_FREE_FORM_DOCUMENT_SUCCEED = "SEND_FREE_FORM_DOCUMENT_SUCCEED";
export const SEND_FREE_FORM_DOCUMENT_FAILED = "SEND_FREE_FORM_DOCUMENT_FAILED";

export const SIGN_FREE_FORM_DOCUMENT_REQUESTED = "SIGN_FREE_FORM_DOCUMENT_REQUESTED";
export const SIGN_FREE_FORM_DOCUMENT_SUCCEED = "SIGN_FREE_FORM_DOCUMENT_SUCCEED";
export const SIGN_FREE_FORM_DOCUMENT_FAILED = "SIGN_FREE_FORM_DOCUMENT_FAILED";

export const CANCEL_FREE_FORM_DOCUMENT_REQUESTED = "CANCEL_FREE_FORM_DOCUMENT_REQUESTED";
export const CANCEL_FREE_FORM_DOCUMENT_SUCCEED = "CANCEL_FREE_FORM_DOCUMENT_SUCCEED";
export const CANCEL_FREE_FORM_DOCUMENT_FAILED = "CANCEL_FREE_FORM_DOCUMENT_FAILED";

export const ACCEPT_FREE_FORM_DOCUMENT_REQUESTED = "ACCEPT_FREE_FORM_DOCUMENT_REQUESTED";
export const ACCEPT_FREE_FORM_DOCUMENT_SUCCEED = "ACCEPT_FREE_FORM_DOCUMENT_SUCCEED";
export const ACCEPT_FREE_FORM_DOCUMENT_FAILED = "ACCEPT_FREE_FORM_DOCUMENT_FAILED";

export const DECLINE_FREE_FORM_DOCUMENT_REQUESTED = "DECLINE_FREE_FORM_DOCUMENT_REQUESTED";
export const DECLINE_FREE_FORM_DOCUMENT_SUCCEED = "DECLINE_FREE_FORM_DOCUMENT_SUCCEED";
export const DECLINE_FREE_FORM_DOCUMENT_FAILED = "DECLINE_FREE_FORM_DOCUMENT_FAILED";