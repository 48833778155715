import axios from "axios";

class ContractorService {

    getContractorCount() {
        return axios.get('/contractor/contractor/get/count')
    }

    getContractors(params) {
        return axios.get('/contractor/contractor/get', {params: params})
    }

    addContractor(payload) {
        return axios.post("/contractor/contractor/register", payload);
    }

    editContractor(id, payload) {
        return axios.post(`/contractor/contractor/update/${id}`, payload);
    }

    updateContractorStartingDebts(id, payload) {
        return axios.post(`/contractor/contractor/change-starting-debts/${id}`, payload);
    }

    setDefaultContractor(id) {
        return axios.post(`/contractor/contractor/default/set/${id}`);
    }

    unSetDefaultContractor() {
        return axios.post(`/contractor/contractor/default/unset`);
    }

    deleteContractor({id}) {
        return axios.post(`/contractor/contractor/delete/${id}`)
    }

    getContractor(id) {
        return axios.get(`/contractor/contractor/get/${id}`)
    }

    getContractorOrders(params) {
        return axios.get(`/contractor/document/edi/orders`, {params: params})
    }

    getContractorOrdersCount(params) {
        return axios.get(`/contractor/document/edi/orders/count`, {params: params})
    }

    getContractorOperations(params) {
        return axios.get(`/contractor/operation/get?${params}`)
    }

    getContractorOperationsCount(params) {
        return axios.get(`/contractor/operation/get/count?${params}`)
    }

    getContractorInvoices(params) {
        return axios.get(`/contractor/document/roaming/invoices`, {params: params})
    }

    getContractorInvoiceCount(params) {
        return axios.get(`/contractor/document/roaming/invoices/count`, {params: params})
    }

    getContractorReturnInvoices(params) {
        return axios.get(`/contractor/document/edi/return-invoices`, {params: params})
    }

    getContractorReturnInvoiceCount(params) {
        return axios.get(`/contractor/document/edi/return-invoices/count`, {params: params})
    }

    getContractorReturnOrders(params) {
        return axios.get(`/contractor/document/edi/return-orders`, {params: params})
    }

    getContractorReturnOrdersCount(params) {
        return axios.get(`/contractor/document/edi/return-orders/count`, {params: params})
    }

    getContractorReturnShipments(params) {
        return axios.get(`/contractor/document/edi/return-shipments`, {params: params})
    }

    getContractorReturnShipmentsCount(params) {
        return axios.get(`/contractor/document/edi/return-shipments/count`, {params: params})
    }

    getContractorBranches(params) {
        return axios.get(`/contractor/branch/get`, {params: params})
    }

    getContractorShipments(params) {
        return axios.get(`/contractor/document/edi/shipments`, {params: params})
    }

    getContractorShipmentsCount(params) {
        return axios.get(`/contractor/document/edi/shipments/count`, {params: params})
    }

    getContractorContracts(params) {
        return axios.get(`/contractor/document/edi/contracts`, {params: params})
    }

    getContractorContractsCount(params) {
        return axios.get(`/contractor/document/edi/contracts/count`, {params: params})
    }

    getContractorAttorneys(params) {
        return axios.get(`/contractor/document/edi/attorneys`, {params: params})
    }

    getContractorAttorneysCount(params) {
        return axios.get(`/contractor/document/edi/attorneys/count`, {params: params})
    }

    getContractorNotes(id, params) {
        return axios.get(`/contractor/note/get/${id}`, {params: params})
    }

    registerContractorNote(payload) {
        return axios.post(`/contractor/note/register`, payload)
    }

    editContractorNote(id, payload) {
        return axios.post(`/contractor/note/update/${id}`, payload);
    }

    deleteContractorNote(id) {
        return axios.post(`/contractor/note/delete/${id}`)
    }

    getContractorAgents(params) {
        return axios.get(`/contractor/agent/get`, params)
    }

    getContractorAgentsCount(params) {
        return axios.get(`/contractor/agent/get/count`, params)
    }

    getContractorAgent(id) {
        return axios.get(`/contractor/agent/get/${id}`)
    }

    registerContractorAgent(payload) {
        return axios.post(`/contractor/agent/register`, payload)
    }

    deleteContractorAgent(id) {
        return axios.post(`/contractor/agent/delete/${id}`)
    }

    getAgentBot() {
        return axios.get('/contractor/telegram-agent-bot/get')
    }

    registerAgentBot(payload) {
        return axios.post('/contractor/telegram-agent-bot/register', payload)
    }

    updateAgentBot({id, payload}) {
        return axios.post('/contractor/telegram-agent-bot/update/' + id, payload)
    }


    getOrderBot() {
        return axios.get('/contractor/telegram-order-bot/get')
    }

    registerOrderBot(payload) {
        return axios.post('/contractor/telegram-order-bot/register', payload)
    }

    updateOrderBot({id, payload}) {
        return axios.post('/contractor/telegram-order-bot/update/' + id, payload)
    }

    getContractorOrderBotsCount(params) {
        return axios.get(`/contractor/telegram-order-bot/get/count`, params)
    }

    getLeads(params) {
        return axios.get(`/contractor/lead/get`, params)
    }

    getLeadsCount() {
        return axios.get('/contractor/lead/get/count')
    }

    registerLead(payload) {
        return axios.post('/contractor/lead/register', payload)
    }

    editLead(id, payload) {
        return axios.post(`/contractor/lead/update/${id}`, payload);
    }

    deleteLead({id}) {
        return axios.post(`/contractor/lead/delete/${id}`)
    }

    getLead(id) {
        return axios.get(`/contractor/lead/get/${id}`)
    }

    bindOrderBotWithContractorLabel(payload) {
        return axios.post("/contractor/label/connect-to-telegram-order-bot", payload);
    }

    removeOrderBotWithContractorLabel(payload) {
        return axios.post("/contractor/label/remove-from-telegram-order-bot", payload);
    }

    convertLeadToContractor({id}) {
        return axios.post(`/contractor/lead/convert-to-contractor/${id}`)
    }

    getLeadNotes(id, params) {
        return axios.get(`/contractor/note/lead/get/${id}`, {params: params})
    }

    registerLeadNote(payload) {
        return axios.post(`/contractor/note/lead/register`, payload)
    }

    editLeadNote(id, payload) {
        return axios.post(`/contractor/note/lead/update/${id}`, payload);
    }

    deleteLeadNote(id) {
        return axios.post(`/contractor/note/lead/delete/${id}`)
    }

    getActReports(id, params) {
        return axios.post(`/contractor/report/act-report/${id}`, params)
    }

    getAccount() {
        return axios.get(`contractor/account/get`)
    }

    getAccountBinding(id) {
        return axios.get(`contractor/account/binding/get/${id}`)
    }
    accountBindingRegister(params) {
        return axios.post(`contractor/account/binding/register`,params)
    }
    accountBindingUnregister(params) {
        return axios.post(`contractor/account/binding/unregister`,params)
    }
    accountBindingEnable(params) {
        return axios.post(`/contractor/account/binding/enable`,params)
    }
    accountBindingDisable(params) {
        return axios.post(`/contractor/account/binding/disable`,params)
    }

    getExcelContractor(ids) {
        return axios.post(`/excel/contractor/report/list`,{
            ids
        }, {
            responseType: "arraybuffer",
        })
    }

    getExcelActReport({id, dateStart, dateEnd}) {
        return axios.get(`/excel/contractor/report/act-report/${id}`, {
            params: {
                date_start: dateStart,
                date_end: dateEnd,
            },
            responseType: "blob",
        })
    }

    addLocation(payload) {
        return axios.post(`/contractor/contractor/location/register`, payload);
    }

    editLocation(payload) {
        return axios.post(`/contractor/contractor/location/update`, payload)
    }

    deleteLocation(payload) {
        return axios.post(`/contractor/contractor/location/delete`, payload)
    }

    bindEmployeeToContractor(contractorId, {employeesIds}) {
        return axios.post(`/contractor/contractor/bind-employees/${contractorId}`, {
            employees_ids: employeesIds,
        })
    }

    unbindEmployeeToContractor(contractorId, {employeeId}) {
        return axios.post(`/contractor/contractor/unbind-employee/${contractorId}`, {
            employee_id: employeeId,
        })
    }
}

export default new ContractorService();
