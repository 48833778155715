export const DocumentReceived = 1
export const DocumentUpdated  = 2
export const DocumentDeleted  = 3
export const ContractCreated  = 4
export const ContractUpdated  = 5
export const ContractDeleted  = 6
export const Sent             = 7
export const InRework         = 8
export const InSigning        = 9
export const ExecutorSigned   = 10
export const CustomerAccepted = 11
export const ExecutorCanceled = 12
export const CustomerDeclined = 13




export const GetStatusText = status => {
    switch (status) {
        case DocumentReceived:
            return "Запрос принят"
        case ContractCreated:
            return "Создан"
        case ContractUpdated:
            return "Обновлен"
        case Sent:
            return "Отправлен"
        case InRework:
            return "На доработке"
        case InSigning:
            return "На подписании"
        case ExecutorSigned:
            return "Подписан поставщиком"
        case CustomerAccepted:
            return "Подписан заказчиком"
        default:
            return "Не подтверждён"
    }
}


export const GetStatusVariant = status => {
    switch (status) {
        case DocumentReceived:
            return "primary"
        case ContractCreated:
            return "success"
        case ContractUpdated:
            return "info"
        case Sent:
            return "success"
        case InRework:
            return "danger"
        case InSigning:
            return "warning"
        case ExecutorSigned:
            return "success"
        case CustomerAccepted:
            return "success"
        default:
            return "secondary"
    }
}



export const GetAllowedStatus = status => {
    switch (status) {
        case ContractCreated:
            return true
        case ContractUpdated:
            return true
        case InRework:
            return true
        default:
            return false
    }
}


export const GetAllowedSignStatus = status => {
    switch (status) {
        case InSigning:
            return true;
        default:
            return false;
    }
}


export const GetAllowedTaxLinkStatus = status => {
    switch (status) {
        case ExecutorSigned:
            return true;
        case CustomerAccepted:
            return true;
        case ExecutorCanceled:
            return true;
        case CustomerDeclined:
            return true;
        default:
            return false
    }
}

export const ShowEditButtonIKPU = status => {
    switch (status) {
        case ExecutorSigned :
            return false
        case CustomerAccepted :
            return false
        default :
            return true
    }
}