import React, {Fragment} from 'react';
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import AddLead from "../contractor/lead/AddLead";

const LeadDatatableHeader = () => {
    const lang = useSelector(selectLang)
    const t = Translate

    return (
        <Fragment>
            <Row className="d-flex justify-content-between">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0 me-2">{t(lang, "crm.lead")}</h5>
                </Col>
                <Col xs={8} className="d-flex justify-content-end">
                    <AddLead
                        icon="fa-plus"
                        title={t(lang, "crm.lead.add")}
                        variant="falcon-primary"
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default LeadDatatableHeader;