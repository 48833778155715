import React, {useState} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {useForm, FormProvider} from "react-hook-form";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import { selectLang, Translate } from "../../../../../app/store/reducers/main/mainReducer";
import SignActionWithSelectCertificatWrapper from "../../components/SignActionWithSelectCertificatWrapper";
import { selectCurrentCertificate } from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingVerificationActReject} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";


const DeclineVerificationAct = ({declineVerificationActHandler, disabled, ...props}) => {
    const dispatch = useDispatch()
    const methods = useForm();
    const { register, formState: {errors}, handleSubmit, reset } = methods
    const currentCertificate = useSelector(selectCurrentCertificate)
    const lang = useSelector(selectLang);
    const t = Translate;

    const [show, setShow] = useState(false);

    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingVerificationActReject))) setShow(true)
        else dispatch(toastPermission())
    }

    const handleClose = () => {
        reset();
        setShow(false);
    };

    const formSubmit = ({comment}) => {
        if(currentCertificate?.keyId) {
            declineVerificationActHandler({
                comment: comment
            })
            reset();
            setShow(false);
        }
    };

    return (
        <>
        <IconButton
            variant="falcon-danger"
            size="sm"
            icon={faXmarkCircle}
            disabled={disabled}
            iconClassName="me-1"
            onClick={handleShow}
            {...props}
        >{t(lang, "edi.common.button.decline")}</IconButton>
            <FormProvider {...methods}>
                <Modal size="md" show={show} onHide={handleClose}>
                    <Form onSubmit={handleSubmit(formSubmit)} id="DeclineVerificationActForm">
                        <Modal.Header>
                            <Modal.Title>VerificationAct decline</Modal.Title>
                            <FalconCloseButton onClick={() => {handleClose()}}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    name="Name"
                                    as="textarea" rows={3}
                                    placeholder="Please enter a comment to decline"
                                    isInvalid={errors?.comment?.message}
                                    {...register("comment", {
                                        required: {
                                            value: true,
                                            message: "This field is required!"
                                        }
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.comment?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {handleClose()}}>{t(lang, "edi.common.button.close")}</Button>
                            <SignActionWithSelectCertificatWrapper isForm>
                                <Button
                                    variant="danger"
                                    className="d-flex align-items-center"
                                    disabled={disabled}
                                    type="submit"
                                    form="DeclineVerificationActForm"
                                >{t(lang, "edi.common.button.yes")}
                                </Button>
                            </SignActionWithSelectCertificatWrapper>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </FormProvider>
        </>
    )
}

export default DeclineVerificationAct;
