import ContractorInvoiceDataTable from "./ContractorInvoiceDataTable";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {useSelector} from "react-redux";

const ContractorInvoices = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [activeTab, setActiveTab] = useState('invoices')
    return (<div className={"mt-2"}>
        <Tabs variant={"pills"} activeKey={activeTab} id="controlled-tab-example" onSelect={(tab) => setActiveTab(tab)}>
            <Tab eventKey="invoices" title={t(lang, 'edi.dashboard.invoices.title')}>
                {activeTab === 'invoices' && <ContractorInvoiceDataTable factura_type={0}/>}
            </Tab>
            <Tab eventKey="return_invoices" title={"Минусовая счет-фактура"}>
                {activeTab === 'return_invoices' && <ContractorInvoiceDataTable factura_type={1}/>}
            </Tab>
        </Tabs>
    </div>)
}
export default ContractorInvoices;