import React from 'react';
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import RoamingInvoiceVatRegStatusBadge from "./RoamingInvoiceVatRegStatusBadge";
import RoamingInvoiceFormBranchSelector from "./RoamingInvoiceFormBranchSelector";
import RoamingInvoiceFormDistrictSelector from "./RoamingInvoiceFormDistrictSelector";
import SelectBankAccount from "../../../../common/SelectBankAccount";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";


const RoamingInvoiceFormMember = ({
                                      fieldName,
                                      isSearchable,
                                      onChangeCompany,
                                      company,
                                  }) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    const onChangeBankAccount = (option) => {
        if (option) {
            form.setValue(`${fieldName}.account`, option.account);
            form.setValue(`${fieldName}.bankId`, option.bank_id);
        } else {
            form.setValue(`${fieldName}.account`, '');
            form.setValue(`${fieldName}.bankId`, '');
        }
    };

    const handleChange = (option) => {
        form.setValue(`${fieldName}.branch`, option)
        // set director
        if (option?.director_name) {
            form.setValue(`${fieldName}.director`, option.director_name);
        } else {
            form.setValue(`${fieldName}.director`, company.director);
        }
        // set accountant
        if (option?.accountant) {
            form.setValue(`${fieldName}.accountant`, option.accountant);
        } else {
            form.setValue(`${fieldName}.accountant`, company.accountant);
        }
        // set mfo and account
        if (option?.mfo && option?.account) {
            form.setValue(`${fieldName}.bankId`, option?.mfo);
            form.setValue(`${fieldName}.account`, option?.account);
        } else {
            form.setValue(`${fieldName}.bankId`, company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''));
            form.setValue(`${fieldName}.account`, company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''));
        }
    }

    return (
        <Row>
            {company &&
                <Col xs={12} className="mb-2">
                    <h5>
                        <span className="me-2">{t(lang, 'roaming.common.nds_status')}:</span>
                        <RoamingInvoiceVatRegStatusBadge vatRegStatus={company.vat_rate_status}
                                                         vatRegCode={company.vat_reg_code}
                                                         identifier={company.person_num || company.inn}
                        />
                    </h5>
                </Col>
            }
            <Col xs={12}>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">
                        {t(lang, 'roaming.common.inn_or_pinfl')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Controller name={`${fieldName}.innOrPinfl`}
                                    shouldUnregister={true}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                    }}
                                    render={({field, fieldState: {error}}) => (
                                        <FindCompanySearchable defaultInn={field.value}
                                                               onChange={(company) => {
                                                                   onChangeCompany(company);
                                                                   field.onChange(company ? (company.person_num || company.inn) : null)
                                                               }}
                                                               disabled={!isSearchable}
                                                               inputProps={{
                                                                   isSearchable: !field.value,
                                                                   menuPortalTarget: document.body,
                                                                   styles: {
                                                                       control: base => ({
                                                                           ...base,
                                                                           border: error?.message && '1px solid #e63757'
                                                                       }),
                                                                       container: (provided) => ({
                                                                           ...provided,
                                                                           margin: '-1px'
                                                                       }),
                                                                       menuPortal: (provided) => ({
                                                                           ...provided,
                                                                           zIndex: 9999
                                                                       })
                                                                   }
                                                               }}
                                        />
                                    )}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">
                        {t(lang, 'roaming.common.organization')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.name`}
                                      placeholder={t(lang, 'roaming.common.organization.placeholder')}
                                      isInvalid={form.formState.errors?.[fieldName]?.name}
                                      {...form.register(`${fieldName}.name`, {
                                          required: t(lang, 'items.common.validations.is_required'),
                                          shouldUnregister: true
                                      })}
                        />
                        <Form.Control.Feedback
                            type="invalid">{form.formState.errors?.[fieldName]?.name?.message}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.address')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.address`}
                                      placeholder={t(lang, 'roaming.common.address.placeholder')}
                                      {...form.register(`${fieldName}.address`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.district')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Controller name={`${fieldName}.districtId`}
                                    shouldUnregister={true}
                                    render={({field}) => (
                                        <RoamingInvoiceFormDistrictSelector defaultDistrictId={field.value}
                                                                            onChange={field.onChange}
                                                                            selectProps={{
                                                                                menuPortalTarget: document.body,
                                                                                styles: {
                                                                                    container: (provided) => ({
                                                                                        ...provided,
                                                                                        margin: '-1px'
                                                                                    }),
                                                                                    menuPortal: (provided) => ({
                                                                                        ...provided,
                                                                                        zIndex: 9999
                                                                                    })
                                                                                }
                                                                            }}
                                        />
                                    )}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12"
                                lg="4">{t(lang, 'roaming.common.bank_account')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <InputGroup>
                            <Form.Control name={`${fieldName}.account`}
                                          placeholder={t(lang, 'roaming.common.bank_account.placeholder')}
                                          {...form.register(`${fieldName}.account`, {
                                              shouldUnregister: true
                                          })}
                            />
                            <SelectBankAccount bankAccounts={company?.bank_accounts || []}
                                               onChange={onChangeBankAccount}
                            />
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.bank_mfo')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.bankId`}
                                      placeholder={t(lang, 'roaming.common.bank_mfo.placeholder')}
                                      {...form.register(`${fieldName}.bankId`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.oked')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.oked`}
                                      placeholder={t(lang, 'roaming.common.oked.placeholder')}
                                      {...form.register(`${fieldName}.oked`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.nds_code')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.vatRegCode`}
                                      placeholder={t(lang, 'roaming.common.nds_code.placeholder')}
                                      {...form.register(`${fieldName}.vatRegCode`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.nds_status')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.vatRegStatus`}
                                      placeholder={t(lang, 'roaming.common.nds_status.placeholder')}
                                      {...form.register(`${fieldName}.vatRegStatus`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.director')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.director`}
                                      placeholder={t(lang, 'roaming.common.director.placeholder')}
                                      {...form.register(`${fieldName}.director`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.accountant')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.accountant`}
                                      placeholder={t(lang, 'roaming.common.accountant.placeholder')}
                                      {...form.register(`${fieldName}.accountant`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                {company && company.branches.length > 0 &&
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column xs="12" sm="4" md="12"
                                    lg="4">{t(lang, 'roaming.invoice.branch')}</Form.Label>
                        <Col xs={12} sm={8} md={12} lg={8}>
                            <Controller name={`${fieldName}.branch`}
                                        shouldUnregister={true}
                                        render={({field}) => (
                                            <RoamingInvoiceFormBranchSelector branches={company.branches}
                                                                              defaultBranchCode={field.value ? field.value.code : null}
                                                                              onChange={handleChange}
                                            />
                                        )}
                            />
                        </Col>
                    </Form.Group>
                }
                <Form.Group as={Row} className="mb-2" hidden>
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.work_phone')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.workPhone`}
                                      placeholder={t(lang, 'roaming.common.work_phone.placeholder')}
                                      {...form.register(`${fieldName}.workPhone`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" hidden>
                    <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.common.mobile')}</Form.Label>
                    <Col xs={12} sm={8} md={12} lg={8}>
                        <Form.Control name={`${fieldName}.mobile`}
                                      placeholder={t(lang, 'roaming.common.mobile.placeholder')}
                                      {...form.register(`${fieldName}.mobile`, {
                                          shouldUnregister: true
                                      })}
                        />
                    </Col>
                </Form.Group>
            </Col>
        </Row>
    );
};

export default RoamingInvoiceFormMember;
