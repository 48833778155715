import React from 'react';
import Cleave from "cleave.js/react";
import {useSelector} from "react-redux";
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";

const AdditionalInformation = () => {
    const {register} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Card className="mt-3">
            <Card.Header  className="bg-light">
                <Card.Title>{t(lang, 'roaming.waybill.send.additional_information.title')}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col className="my-1" xs={12}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.special_notes')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("special_notes")}
                                placeholder={t(lang, 'roaming.waybill.send.additional_information.special_notes.placeholder')}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="my-1" md={6}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.giver_fio')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("giver_fio")}
                                placeholder={t(lang, 'roaming.waybill.send.additional_information.giver_fio.placeholder')}
                            />
                        </Form.Group>
                    </Col>
                    {/*<Col className="my-1" md={6}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.giver_driver_fio')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("giver_driver_fio")}
                                placeholder={t(lang, 'roaming.waybill.send.additional_information.giver_driver_fio.placeholder')}
                            />
                        </Form.Group>
                    </Col>*/}
                    <Col className="my-1" md={6}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.taker_fio')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("taker_fio")}
                                placeholder={t(lang, 'roaming.waybill.send.additional_information.taker_fio.placeholder')}
                            />
                        </Form.Group>
                    </Col>
                    {/*<Col className="my-1" md={6}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.taker_driver_fio')}</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("taker_driver_fio")}
                                placeholder={t(lang, 'roaming.waybill.send.additional_information.taker_driver_fio.placeholder')}
                            />
                        </Form.Group>
                    </Col>*/}
                    <Col className="my-1" md={6}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.delivery_distance')}</Form.Label>
                            <Controller
                                name="delivery_distance.total"
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        className="form-control"
                                        options={{numeral: true, delimiter: ''}}
                                        placeholder={t(lang, 'roaming.waybill.send.additional_information.delivery_distance.placeholder')}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="my-1" md={6}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.delivery_distance_in_city')}</Form.Label>
                            <Controller
                                name="delivery_distance.in_city"
                                render={({field}) => (
                                    <Cleave
                                        {...field}
                                        className="form-control"
                                        options={{numeral: true, delimiter: ''}}
                                        placeholder={t(lang, 'roaming.waybill.send.additional_information.delivery_distance_in_city.placeholder')}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default AdditionalInformation;