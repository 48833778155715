import React, {Fragment, useState} from 'react';
import IconButton from "../../../../common/IconButton";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

import {excelFileUploader} from "../../../../../helpers/excel-uploader";
import {getExcelContractor} from "../../../../../app/store/reducers/contractor/contractorReducer";

const UploadContractorsListToExcel = ({data}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);

    const ids = data.map(item => item.id)
    const getExcel = () => {
        setLoading(true)
        getExcelContractor(ids)
            .then((res) => {
                excelFileUploader({
                    content: res,
                    fileName: t(lang, "crm.bind.contractor.account.contractors")
                })
            })
            .catch(() => {})
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <IconButton
                variant="falcon-default"
                size="sm"
                transform="shrink-3"
                className={'me-2'}
                icon={loading ? faSpinner : faFileExcel}
                onClick={getExcel}
            >
                    <span className="d-none d-sm-inline-block">
                        {t(lang, 'edi.order.datatable.header.navigation.dropdown.export_to_excel')}
                    </span>
            </IconButton>
        </Fragment>
    );
};

export default UploadContractorsListToExcel;
