import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

const NameRow = ({itemFieldName, itemIndex, onRightClickHandler, checkIsExistingItem, id, errors}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const {fields} = useFieldArray({name: `${itemFieldName}.names`});
    const names = useWatch({name: `${itemFieldName}.names`});
    const itemName = names.map(obj => obj.name?.trim()).join(' ').trim();
    const [existingError, setExistingError] = useState(null);

    return fields.map((field, index) => {
        return (
            <td key={field.id}>
                <Controller name={`${itemFieldName}.names.${index}.name`}
                            rules={{
                                required: {
                                    value: index === 0,
                                    message: t(lang, 'items.common.validations.is_required')
                                }
                            }}
                            render={({field}) => {
                                const {error} = form.getFieldState(`${itemFieldName}.names.${index}`, form.formState);
                                const isExistingName = errors?.name_exists === field.value;
                                return (
                                    <Form.Group>
                                        <Form.Control name={`${itemFieldName}.names.${index}.name`}
                                                      placeholder={t(lang, 'items.common.name.placeholder')}
                                                      style={{minWidth: '300px'}}
                                                      isInvalid={error?.name || isExistingName}
                                                      ref={field.ref}
                                                      defaultValue={field.value}
                                                      onChange={(e) => {
                                                          const newValue = e.target.value;
                                                          field.onChange(e);

                                                          if (existingError && newValue !== errors?.name_exists) {
                                                              setExistingError(null);
                                                          }

                                                          if (newValue === errors?.name_exists) {
                                                              setExistingError(errors?.name_exists);
                                                          }
                                                      }}
                                                      onContextMenu={(e) => onRightClickHandler(e, itemIndex, `names.${index}.name`, field.value)}
                                        />
                                        <ErrorMessage name={'name'}
                                                      errors={error}
                                                      as={<Form.Control.Feedback type="invalid"/>}
                                        />
                                        {isExistingName && <div className="text-danger fs--1">{t(lang, "items.common.validations.name_exists", {name: errors?.name_exists})}</div>}
                                    </Form.Group>
                                )
                            }}
                />
            </td>
        )
    })
};

export default NameRow;
