import React, {useState} from 'react';
import {revisionDownloadPdfAsync} from "../../../../../app/store/reducers/warehouse-operation/revisionReducer";
import printJS from "print-js";
import IconButton from "../../../../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Spinner} from "react-bootstrap";
import {toast} from "react-toastify";



const RevisionPrint = ({revisionId, ...props}) => {
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate



    const onPrint = () => {
        setLoading(true)
        revisionDownloadPdfAsync({revisionId})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }




    return (
        <>
            <IconButton {...props} icon="print" disabled={loading} size="sm" className="mx-1" transform="shrink-3" variant="falcon-info" onClick={onPrint}>
                <span className="d-none d-sm-inline-block">{t(lang, "warehouse.operation.item.common.print")}</span>
                {loading && (
                    <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
                )}
            </IconButton>
        </>
    );
};

export default RevisionPrint;