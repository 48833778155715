import React, {useEffect, useState, Fragment} from "react"
import {Badge, Card} from "react-bootstrap";
import ContractorPayoutDataTableHeader from "./ContractorPayoutDataTableHeader";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {useDispatch, useSelector} from "react-redux";
import {
    loadContractorPayoutsAsync,
    selectContractorPayouts,
    changeFilterOptionsAsync,
    selectFilterOptionsContractorPayout,
    loadContractorPayoutCountAsync,
    selectPayoutContractorCount,
    selectLoading
} from "../../../../../app/store/reducers/contractor/contractorPayoutReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import numeral from "numeral";
import {Link, useHistory} from "react-router-dom";
import SoftBadge from "../../../../common/SoftBadge";
import IconButton from "../../../../common/IconButton";
import DeleteContractorPayoutModal from "./DeleteContractorPayoutModal";
import EditContractorPayout from "./EditContractorPayout";
import {
    ContractorSourcePurchase,
    ContractorSourceRefund, FindContractorPayoutSource
} from "../../../../../enum/ContractorPayoutSources";

const ContractorPayoutDataTable = () => {
    const routerHistory = useHistory();
    const contractorPayouts = useSelector(selectContractorPayouts);
    const count = useSelector(selectPayoutContractorCount)
    const lang = useSelector(selectLang);
    const filters = useSelector(selectFilterOptionsContractorPayout);
    const t = Translate;
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);
    const [editingContractorPayout, setEditingContractorPayout] = useState(null);
    const [deletingContractorPayoutId, setDeletingContractorPayoutId] = useState(null);
    const isLoading = useSelector(selectLoading);


    const separationCurrenciesAndTotalSum = (states) => {
        var groupByCurrencyId = {}

        states.forEach(state => {
            if (state?.currency?.id in groupByCurrencyId) {
                groupByCurrencyId[state?.currency?.id].totalSum += state?.amount
            } else {
                if (state?.currency?.id) {
                    groupByCurrencyId[state?.currency?.id] = {
                        id: state?.currency?.id,
                        name: state?.currency?.name,
                        totalSum: state?.amount
                    }
                }
            }
        })
        return Object.values(groupByCurrencyId)
    }

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "edi.contractor.datatable.header.row.contractor"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`contractor-card/${original.contractor.id}`}>
                        <strong>{original.contractor.name}</strong>
                    </Link>
                )
            }
        },
        {
            accessor: 'date',
            Header: t(lang, "crm.contractor_payout.datatable.header.date"),
            headerProps: {className: 'pe-2'},
        },
        {
            accessor: 'cash_box_states',
            Header: t(lang, "edi.contractor_payment.datatable.payment_amount"),
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            totalInfo: separationCurrenciesAndTotalSum(contractorPayouts.flatMap(contractorPayout => contractorPayout.cash_box_states)).map((payment, index) => (
                <div className={'fs-0'} key={index}>
                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                        {numeral.formats[numberFormat].format(payment.totalSum)}
                        &nbsp;
                        {payment.name}
                    </SoftBadge>
                </div>
            )),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {separationCurrenciesAndTotalSum(original.cash_box_states).map((payment, index) => {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                                        {numeral.formats[numberFormat].format(payment.totalSum)}
                                        &nbsp;
                                        {payment.name}
                                    </SoftBadge>
                                </div>
                            )
                        })}
                    </>
                )
            }
        },
        {
            accessor: 'debt_states',
            Header: t(lang, "warehouse.operation.item.common.credit"),
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            totalInfo: separationCurrenciesAndTotalSum(contractorPayouts.flatMap(contractorPayout => contractorPayout.debt_states)).map((payment, index) => (
                <div className={'fs-0'} key={index}>
                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                        {numeral.formats[numberFormat].format(payment.totalSum)}
                        &nbsp;
                        {payment.name}
                    </SoftBadge>
                </div>
            )),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original.debt_states.map((payment, index) => {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                                        {numeral.formats[numberFormat].format(payment.amount)}
                                        &nbsp;
                                        {payment.currency.name}
                                    </SoftBadge>
                                </div>
                            )
                        })}
                    </>
                )
            }
        },
        {
            accessor: 'notes',
            Header: 'Информация',
            headerProps: {
                style: {
                    maxWidth: '100px'
                },
            },
            cellProps: {
                style: {
                    maxWidth: '100px'
                },
            },
        },
        {
            accessor: 'source',
            id: "source",
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Header: t(lang, "crm.data.table.source"),
            Cell: ({row: {original}}) => {
                if (original.payout_source === ContractorSourcePurchase) {
                    return (
                        <Link to={`/warehouse/operation/purchases/view/${original.purchase.id}`}>
                            <Badge bg={FindContractorPayoutSource(original.payout_source)?.variant} className='me-2'>
                                {t(lang, FindContractorPayoutSource(original.payout_source)?.label)}</Badge>
                        </Link>
                    )
                } else if (original.payout_source === ContractorSourceRefund) {
                    return (
                        <Link to={`/warehouse/operation/refunds/view/${original.refund.id}`}>
                            <Badge bg={FindContractorPayoutSource(original.payout_source)?.variant}
                                   className='me-2'>{t(lang, FindContractorPayoutSource(original.payout_source)?.label)}</Badge>
                        </Link>
                    )
                } else {
                    return (
                        <Link to={`/crm/contractor-payout/view/${original.id}`}>
                            <Badge bg={FindContractorPayoutSource(original.payout_source)?.variant}
                                   className='me-2'>{t(lang, FindContractorPayoutSource(original.payout_source)?.label)}</Badge>
                        </Link>
                    )
                }
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.contracts.datatable.header.row.created_at"),
            headerProps: {className: 'pe-2'},
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <>
                        <IconButton
                            className="mx-2 py-0"
                            onClick={() => {
                                if (row.original.payout_source === ContractorSourcePurchase) {
                                    routerHistory.push(`/warehouse/operation/purchases/update/${row.original.purchase.id}?payment_id=${row.original.id}`)
                                } else if (row.original.payout_source === ContractorSourceRefund) {
                                    routerHistory.push(`/warehouse/operation/refunds/update/${row.original.refund.id}?payout_id=${row.original.id}`)
                                } else {
                                    setEditingContractorPayout(row.original)
                                }
                            }}
                            variant="falcon-warning"
                            size="sm"
                            icon="edit"
                            transform="shrink-3"
                        />
                        <IconButton
                            className={'py-0'}
                            onClick={() => {
                                if (row.original.purchase) {
                                    routerHistory.push(`/warehouse/operation/purchases/update/${row.original.purchase.id}?payment_id=${row.original.id}`)
                                } else if (row.original.refund) {
                                    routerHistory.push(`/warehouse/operation/refunds/update/${row.original.refund.id}?payout_id=${row.original.id}`)
                                } else {
                                    setDeletingContractorPayoutId(row.original.id);
                                }
                            }}
                            variant="falcon-danger"
                            size="sm"
                            icon="trash"
                            transform="shrink-3"
                        />
                    </>
                );
            }
        }
    ].filter(i => i);
    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }
    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }
    const activeOrganization = useSelector(selectActiveOrganization)

    useEffect(() => {
        dispatch(loadContractorPayoutsAsync({...filters}))
        dispatch(loadContractorPayoutCountAsync({...filters}))
    }, [activeOrganization, filters])
    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={contractorPayouts}
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ContractorPayoutDataTableHeader table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            isLoading={isLoading}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            {deletingContractorPayoutId &&
            <DeleteContractorPayoutModal id={deletingContractorPayoutId} show={!!deletingContractorPayoutId}
                                         onClose={() => {
                                             setDeletingContractorPayoutId(null)
                                         }}/>
            }

            {editingContractorPayout &&
            <EditContractorPayout payout={editingContractorPayout} onClose={() => {
                setEditingContractorPayout(null)
            }}/>
            }
        </Fragment>
    )
}
export default ContractorPayoutDataTable
