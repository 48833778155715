import {createSlice} from "@reduxjs/toolkit";
import contractorService from "../../../../services/contractorService";
import EventBus from "../../../eventbus/EventBus";
import {
    REGISTER_CONTRACTOR_AGENT_BOTS_FAILED,
    REGISTER_CONTRACTOR_AGENT_BOTS_REQUESTED,
    REGISTER_CONTRACTOR_AGENT_BOTS_SUCCESS,
    UPDATE_CONTRACTOR_AGENT_BOTS_FAILED,
    UPDATE_CONTRACTOR_AGENT_BOTS_REQUESTED,
    UPDATE_CONTRACTOR_AGENT_BOTS_SUCCESS
} from "../../../eventbus/contractor/contractorAgentBotsEvent";

export const contractorAgentBotReducer = createSlice({
    name: 'botReducer',
    initialState: {
        agentBots: [],
        count: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateAgentBots: (state, action) => {
            state.agentBots = action.payload || []
        },
        updateAgentBotsCount: (state, action) => {
            state.count = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
    },

})

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorAgentBotReducer.actions.updateFilterOptions({...params}))
}

export const loadContractorAgentBotsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getAgentBot(params)
        .then((response) => {
            dispatch(contractorAgentBotReducer.actions.updateAgentBots(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const loadContractorAgentBotsCountAsync = (filters) => dispatch => {
    return new Promise((resolve, reject) => {
        let {limit, page, ...other} = filters

        contractorService.getContractorAgentsCount(other)
            .then((response) => {
                dispatch(contractorAgentBotReducer.actions.updateAgentBotsCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const registerContractorAgentBotAsync = (payload) => {
    EventBus.dispatch(REGISTER_CONTRACTOR_AGENT_BOTS_REQUESTED)
    return new Promise((resolve, reject) => {
        contractorService.registerAgentBot(payload)
            .then((response) => {
                EventBus.dispatch(REGISTER_CONTRACTOR_AGENT_BOTS_SUCCESS)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(REGISTER_CONTRACTOR_AGENT_BOTS_FAILED)
                reject(error)
            })
    })
}

export const updateContractorAgentBotAsync = (id, addBot) => {
    EventBus.dispatch(UPDATE_CONTRACTOR_AGENT_BOTS_REQUESTED)
    return new Promise((resolve, reject) => {
        contractorService.updateAgentBot({id, payload: addBot})
            .then((response) => {
                EventBus.dispatch(UPDATE_CONTRACTOR_AGENT_BOTS_SUCCESS)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(UPDATE_CONTRACTOR_AGENT_BOTS_FAILED)
                reject(error)
            })
    })
}

export const selectContractorAgentBots = state => state.agentBots.agentBots

export const selectContractorAgentBotsCount = state => state.agentBots.count

export const selectContractorAgentBotsFilterOptions = state => state.agentBots.filterOptions


export default contractorAgentBotReducer.reducer