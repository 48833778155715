import {createSlice} from "@reduxjs/toolkit";
import itemService from "../../../../services/item/itemService";
import referenceDataAggregatorService
    from "../../../../services/referenceDataAggregator/referenceDataAggregatorService";
import {ALERT_ON_STATE_0} from "../../../../components/hippo/item/enum/itemWrapper";


export const ITEM_INITIALIZATION_STATE_NOT_STARTED = 0
export const ITEM_INITIALIZATION_STATE_LOADING = 1
export const ITEM_INITIALIZATION_STATE_COMPLETE = 2

export const itemSlice = createSlice({
    name: 'item',
    initialState: {
        itemInitializationState: ITEM_INITIALIZATION_STATE_NOT_STARTED,
        loading: false,
        isFilterCollapsed: false,
        filters: {
            searchTerm: '',
            sku: '',
            measurement: null,
            categoryId: null,
            warehouseId: null,
            branchId: null,
            alertOn: ALERT_ON_STATE_0,
            purchasePriceMoreThanSalePrice: null,
            hasCatalog: null,
            hasCatalogPackage: null,
            hasBarcode: null,
            hasCategory: null,
            hasSku: null
        },
        sorting: [
            {id: "name", desc: true},
        ],
        columnVisibility: {
            "selectColumn": true,
            "#": true,
            "name": true,
            "purchasePrice": true,
            "salePrice": true,
            "totalWarehouseState": true,
            "totalBranchState": false,
            "createdAt": true,
        },
        pagination: {
            pageIndex: 0,
            pageSize: 10
        },
        items: [],
        itemsDictionary: {},
        categories: [],
        categoriesTree: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsFilterCollapsed: (state, action) => {
            state.isFilterCollapsed = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        },
        setSorting: (state, action) => {
            state.sorting = action.payload;
        },
        setColumnVisibility: (state, action) => {
            state.columnVisibility = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setItemsDictionary: (state, {payload: rdaItems}) => {
            const newItemsDictionary = {};
            for (let i = 0; i < rdaItems.length; i++) {
                const rdaItem = rdaItems[i];
                newItemsDictionary[rdaItem.item.id] = rdaItem;
            }
            state.itemsDictionary = newItemsDictionary;
        },
        updateItem: (state, action) => {
            state.itemsDictionary[action.payload.item.id] = action.payload;
        },
        deleteItem: (state, action) => {
            delete state.itemsDictionary[action.payload];
        },
        pushUpdateItem: (state, action) => {
            const {item_id, warehouse_id, state: itemState} = action.payload;
            if (state.itemsDictionary[item_id]) {
                const editingWarehouseIndex = state.itemsDictionary[item_id].warehouse_states.warehouse_items.findIndex((i) => i.id === warehouse_id);
                if (editingWarehouseIndex > -1)
                    state.itemsDictionary[item_id].warehouse_states.warehouse_items[editingWarehouseIndex].state = itemState;
            }
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setCategoriesTree: (state, action) => {
            state.categoriesTree = action.payload;
        },
        setItemInitializationState: (state, action) => {
            state.itemInitializationState = action.payload
        }
    }
})

export const setLoading = (value) => (dispatch) => dispatch(itemSlice.actions.setLoading(value));
export const updateIsFilterCollapsed = (value) => (dispatch) => dispatch(itemSlice.actions.setIsFilterCollapsed(value));
export const updateFilters = (filters) => (dispatch) => dispatch(itemSlice.actions.setFilters(filters));
export const updatePagination = (pagination) => (dispatch) => dispatch(itemSlice.actions.setPagination(pagination));
export const updateItem = (item) => (dispatch) => dispatch(itemSlice.actions.updateItem(item));
export const deleteItem = (id) => (dispatch) => (dispatch(itemSlice.actions.deleteItem(id)));
export const updateSorting = (sorting) => (dispatch) => dispatch(itemSlice.actions.setSorting(sorting));
export const updateColumnVisibility = (columnVisibility) => (dispatch) => dispatch(itemSlice.actions.setColumnVisibility(columnVisibility));

export const updateItemsDictionary = (items) => (dispatch) => dispatch(itemSlice.actions.setItemsDictionary(items));
export const updateCategories = (categories) => (dispatch) => dispatch(itemSlice.actions.setCategories(categories));
export const updateCategoriesTree = (categoriesTree) => (dispatch) => dispatch(itemSlice.actions.setCategoriesTree(categoriesTree));

export const loadItemsAsync = (params) => {
    return new Promise((resolve, reject) => {
        referenceDataAggregatorService.getOrganizationItems(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            })
    })
}
export const loadItemAsync = (id) => {
    return new Promise((resolve, reject) => {
        referenceDataAggregatorService.getOrganizationItem(id)
            .then(response => resolve(response.data))
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    });
};
export const addItemAsync = (payload) => {
    return new Promise((resolve, reject) => {
        itemService.addItem(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                let itemError = {};

                if (error.response?.data?.name_exists) {
                    itemError.name = error.response.data.name_exists;
                }
                if (error.response?.data?.existing_barcodes?.length > 0) {
                    itemError.existing_barcodes = error.response.data.existing_barcodes;
                }
                if (error.response?.data?.code_exists) {
                    itemError.code_exists = error.response.data.code_exists;
                }
                reject(itemError);
            })
    });
};
export const validateItemCatalogsAsync = () => {
    return new Promise((resolve, reject) => {
        itemService.validateItemCatalogs()
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const validateItemCatalogAsync = (id) => {
    return new Promise((resolve, reject) => {
        itemService.validateItemCatalog(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const editItemAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        itemService.editItem(id, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    });
};
export const deleteItemAsync = (id) => {
    return new Promise((resolve, reject) => {
        itemService.deleteItem(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            })
    });
};
export const exportItemsAsync = (uri, params) => {
    return new Promise((resolve, reject) => {
        itemService.exportItems(uri, params)
            .then((response) => {
                resolve({content: response.data, contentType: response.headers['content-type']});
            })
            .catch((error) => {
                reject(error);
            })
    })
}

export const exportBulkItemsAsync = (uri, payload) => {
    return new Promise((resolve, reject) => {
        itemService.exportBulkItems(uri, payload)
            .then((response) => {
                resolve({content: response.data, contentType: response.headers['content-type']});
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const getProductGuideAsync = (barcode) => {
    return new Promise((resolve, reject) => {
        itemService.getProductGuide(barcode)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const pushUpdateItem = ({item_id, warehouse_id, state}) => (dispatch) => {
    dispatch(itemSlice.actions.pushUpdateItem({item_id, warehouse_id, state}));
};


export const selectIsFilterCollapsed = state => state.item.isFilterCollapsed;
export const selectFilterOptions = state => state.item.filters;
export const selectPaginationOptions = state => state.item.pagination;
export const selectSorting = state => state.item.sorting;
export const selectColumnVisibility = state => state.item.columnVisibility;
export const selectItems = state => Object.values(state.item.itemsDictionary);
export const selectItemsDictionary = state => state.item.itemsDictionary;
export const selectLoading = state => state.item.loading;
export const selectCategoriesTree = state => state.item.categoriesTree;
export const selectItemInitializationState = state => state.item.itemInitializationState

export default itemSlice.reducer;
