import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import ReactSelect from "react-select";
import classNames from "classnames";


const ContractFormationCertificateLinkSelector = ({certificateFile, onChange, controlledFieldsProduct, defaultId}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [modalShow, setModalShow] = useState(false);
    const [certificate, setCertificate] = useState(null);

    const onOptionChanged = value => {
        setCertificate(value)
        onChange(value)
        value && setModalShow(false)
    }

    useEffect(() => {
        if (certificate?.attachment_id !== defaultId) {
            setCertificate(controlledFieldsProduct.find(c => c?.attachment_id === defaultId))
        }
    }, [defaultId])

    const isSelected = !!certificate

    const link = <a href={"#"} className={classNames({"text-success": isSelected, "text-warning": !isSelected}, 'fs--1')} onClick={e => {
        e.preventDefault()
        setModalShow(true)
    }}>
        {isSelected ? `${certificate?.filename}` : t(lang, "edi.common.items.product.choose_certificate")}
    </a>



    return(
        <>
            {isSelected &&
                <OverlayTrigger overlay={<Tooltip>{certificate?.filename}</Tooltip>}>
                    {link}
                </OverlayTrigger>
            }

            {!isSelected && link}

            {modalShow &&
                <Modal
                    show={true}
                    onHide={() => setModalShow(false)}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="ContractFormationCertificateLinkSelector"
                >

                    <Modal.Body>
                        <ReactSelect options={certificateFile}
                                     value={certificate}
                                     onChange={value => onOptionChanged(value)}
                                     getOptionLabel={option => option?.filename}
                                     defaultMenuIsOpen={!certificate}
                                     closeMenuOnSelect
                                     autoFocus
                                     hideSelectedOptions
                                     isClearable
                                     classNamePrefix="react-select"
                                     placeholder={t(lang, "roaming.common.select.placeholder")}
                                     noOptionsMessage={() => t(lang, "edi.contract_formation.common_no_certificate")}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => {
                            setModalShow(false)
                        }}>{t(lang, 'edi.common.button.close')}</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}


export default ContractFormationCertificateLinkSelector;