import {Button, CloseButton, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";


const ShowNoteModal = ({notes, bg, title}) => {
    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate


    const handleClose = () => {
        setShow(false)
    }

    return(
        <>
            <Button
                onClick={() => setShow(true)}
                variant={bg}
                size="sm"
                transform="shrink-3"
            >
                {title}
            </Button>
            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{t(lang,"roaming.common.comment")}</Modal.Title>
                    <CloseButton onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <p>{notes}</p>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default ShowNoteModal;