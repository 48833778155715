import EventBus from "../../../../app/eventbus/EventBus";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {useHistory, useParams} from "react-router";
import {
    getTransferItemAsync,
    transferApproveAsync
} from "../../../../app/store/reducers/warehouse-operation/transferReducer";
import {
    WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/transferEvents";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import {Button, Card, Col, Row} from "react-bootstrap";
import SoftBadge from "../../../../components/common/SoftBadge";
import {
    isApprovedCheckedWarehouseOperation
} from "../../../../components/hippo/warehouse-operation/enum/warehouseOperationWrapper";
import TransferExcelForm from "../../../../components/hippo/warehouse-operation/transfer/forms/TransferExcelForm";
import IconButton from "../../../../components/common/IconButton";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";


const EditTransferExcel = () => {
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const [transfer, setTransfer] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const items = useSelector(selectItems);
    const lang = useSelector(selectLang)
    const t = Translate;
    const {id} = useParams()


    const loadTransfer = (id) => {
        getTransferItemAsync({transferId: id})
            .then(response => setTransfer(response.data))
            .catch(error => setTransfer(null))
    }

    useEffect(() => {
        loadTransfer(id)
    },[activeOrganization, id])


    useEffect(() => {
        const onItemAddedHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, (id) => {
            loadTransfer(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, (id) => {
            loadTransfer(id)
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, (id) => {
            loadTransfer(id)
        })

        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, onItemAddedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
        }
    },[])


    const onClickApproved = async () => {
        await transferApproveAsync({transferId: transfer?.id})
            .then((response) => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                history.push(`../view/${transfer?.id}`)
            })
            .catch((error) => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }


    if (items.length === 0)
        return <>items loading...</>

    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <>currency loading...</>

    if (!transfer)
        return <>loading...</>

    if (warehouses?.length < 2)
        return(
            <>
                <WarehouseNo/>
            </>
        )


    if (warehouses?.length > 1 && transfer)
        return(
            <Card>
                <Card.Body>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <h5>{t(lang, "warehouse.operation.item.common.new_transfer")}</h5>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            {transfer?.id && !transfer?.is_approved &&
                                <div>
                                    <IconButton
                                        variant="falcon-primary"
                                        icon="layer-group"
                                        className="mx-2"
                                        size="sm"
                                        onClick={() => onClickApproved()}
                                    >{t(lang,"warehouse.operation.item.common.status.approve")}</IconButton>
                                    <Button
                                        variant="falcon-info"
                                        title={t(lang, "warehouse.operation.item.common.button.save_draft")}
                                        size="sm"
                                        className="me-1"
                                        as={Link}
                                        to={`../view/${transfer?.id}`}
                                    >{t(lang, "warehouse.operation.item.common.button.save_draft")}</Button>
                                </div>
                            }
                            <div>
                                <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                <SoftBadge bg={transfer?.is_approved ? 'success' : 'warning'}>
                                    {t(lang, isApprovedCheckedWarehouseOperation(!!transfer?.is_approved))}
                                </SoftBadge>
                            </div>
                        </Col>
                    </Row>
                    <TransferExcelForm transfer={transfer} />
                </Card.Body>
            </Card>
        )
}

export default EditTransferExcel;