import { useSelector } from "react-redux";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Translate, selectLang } from "../../../../../app/store/reducers/main/mainReducer";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

const VerificationActDescription = () => {
  const { register, getFieldState, setValue, watch } = useFormContext();
  const errors = getFieldState('verificationActDoc').error || [];
  const lang = useSelector(selectLang);
  const {ownerName, ownerFizFio, partnerName, partnerFizFio} = watch()
  useEffect(() => {
    setValue('verificationActDoc.verificationActText', t(lang, "roaming.verification_act.add.verification_act.description.text.value", {
      owner: ownerName || "_________",
      fio_owner: ownerFizFio || "_________",
      partner: partnerName || "_________", 
      partner_fio: partnerFizFio || "_________"
    }))
  }, [ownerName, ownerFizFio, partnerName, partnerFizFio, lang]);

  const t = Translate;
  return (
    <Card>
      <Card.Header>
        <h5 className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.description.title")}</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm="12">
            <Form.Label>{t(lang, "roaming.verification_act.add.verification_act.description.text")}</Form.Label>
            <Form.Control 
              as="textarea"
              rows={10}
              {...register("verificationActDoc.verificationActText", {
                required: "edi.common.forms.validations.is_required"
              })}
              className={
                errors?.verificationActText?.message && "is-invalid"
              }
              placeholder={t(lang, "roaming.verification_act.add.verification_act.description.text")}
            />
            <Form.Control.Feedback type="invalid">
              {t(lang, errors?.verificationActText?.message)}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default VerificationActDescription;
