import React, {useState} from 'react'
import {deleteRoleAsync} from '../../../../app/store/reducers/role/roleReducer';
import ButtonWithConfirmModal from "../../../../components/common/ButtonWithConfirmModal";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {toast} from "react-toastify";

const DeleteRole = ({id, show, onClose}) => {
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const t = Translate;
    const onConfirm = () => {
        setLoading(true);
        deleteRoleAsync({
            id: id
        }).then(res => {
            onClose();
        }).catch(error => {
            console.log(error)
            toast.error(t(lang, "hippo.user.settings.delete_role.error.message"));
        }).finally(() => setLoading(false))
    }
    const onCloseModal = () => {
        onClose()
    }
    return (
        <ButtonWithConfirmModal
            title={t(lang, 'edi.delete.role.title')}
            button={{text: "Удалить", variant: "danger"}}
            onConfirm={onConfirm}
            showModal={show}
            loading={loading}
            onClose={onCloseModal}
        />
    )
}

export default DeleteRole
