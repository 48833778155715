import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from '../../../../app/store/reducers/auth/authReducer';
import {
    loadCountReturnShipments,
    loadReturnShipmentDataTableColumn,
    loadReturnShipments,
    selectFilterOptions, selectReturnShipmentDataTable
} from '../../../../app/store/reducers/return-shipment/returnShipmentReducer';
import ReturnShipmentDataTable from '../../../../components/hippo/return-shipment/ReturnShipmentDataTable.js';
import {setEdiReturnBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnShipmentView} from "../../../../enum/Permission/EdiPermission";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const ReturnShipments = () => {
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization)
    const filters = useSelector(selectFilterOptions)
    const activeColumns = useSelector(selectReturnShipmentDataTable)
    const t = Translate;
    const lang = useSelector(selectLang);
    // important
    useSelector(selectAccountRolePermissions)

    useEffect(() => {
        dispatch(loadCountReturnShipments({...filters, viewer_inn: activeOrganization.inn}))
        dispatch(loadReturnShipments({...filters, viewer_inn: activeOrganization.inn}))
        dispatch(loadReturnShipmentDataTableColumn())
    }, [filters, activeOrganization])

    useEffect(() => {
        dispatch(setEdiReturnBreadcrumbData(null))
    }, [])

    if(!dispatch(checkPermission(PermissionEdiReturnShipmentView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.edi.return-shipment.datatable.no_access.title')}</h4>
        </Card>
    }

    return   <ReturnShipmentDataTable activeColumns={activeColumns}/>

};

export default ReturnShipments;
