import React, {Fragment, useImperativeHandle, useState, forwardRef} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {getRandomNumber} from "../../../../helpers/utils";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {removeAdminOrganizationDemoUsesAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import {ErrorMessage} from "@hookform/error-message";

const DeleteOrganizationDemoUses = forwardRef(({serviceCode, organizationId, setDefaultValue}, ref) => {

    const lang = useSelector(selectLang)
    const t = Translate

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [code] = useState(getRandomNumber(100000, 999999))

    const methods = useForm({
        defaultValues: {
            code: null,
        }
    })

    const handlerShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
        setDefaultValue(null)
        methods.setValue('code', null)
        methods.clearErrors('code')
    }

    const onApprove = () => {
        setLoading(true)
        removeAdminOrganizationDemoUsesAsync({service_code: serviceCode, organization_id: organizationId})
            .then(() => {
                onHide()
                toast.success(t(lang, "crm.common.toast.success"))
            })
            .catch(({removeOrganizationDemoUseNotAllowed}) => {
                if (removeOrganizationDemoUseNotAllowed)
                    toast.error(t(lang, "admin.navbar.routes.demo_uses.error.remove_organization_demo_use_not_allowed"))
                else
                    toast.error(t(lang, "crm.common.toast.error"))
            })
            .finally(() => setLoading(false))
    }

    useImperativeHandle(ref, () => ({
        openModal: () => handlerShow(),
    }));


    return (
        <Fragment>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "common.button.delete")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form id={'delete-popover'} onSubmit={methods.handleSubmit(onApprove)}>
                            <Form.Group>
                                <Form.Label column={true}>
                                    <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                    <span className="ms-1 text-primary">{code}</span>
                                </Form.Label>
                                <Controller
                                    name={'code'}
                                    control={methods.control}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                        validate: (value) => {
                                            if (+value === code)
                                                return true;
                                            return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                        }
                                    }}
                                    render={({field}) => (
                                        <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                value={field.value}
                                                htmlRef={field.ref}
                                                placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                className={classNames('form-control', {
                                                    'is-invalid': methods.formState.errors?.code
                                                })}
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: 3
                                                }}
                                        />
                                    )}
                                />
                                <ErrorMessage name={'code'}
                                              errors={methods.formState.errors}
                                              as={<Form.Control.Feedback type="invalid"/>}
                                />
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer className={'d-flex justify-content-end'}>
                    <Button onClick={onHide} variant={'falcon-primary'}>{t(lang, "crm.common.back.button")}</Button>
                    <Button type={'submit'} form={'delete-popover'} variant={'primary'}>
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, "warehouse.operation.item.common.status.approve")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
});

export default DeleteOrganizationDemoUses;