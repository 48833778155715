import ReactSelect from "react-select";


const SelectMember = ({value, onChange, ...props}) => {

    return (
        <ReactSelect
            options={[]}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={[].find(i => i.id === value)}
            onChange={(option) => onChange(option?.id)}
            {...props}
        />
    );
};

export default SelectMember;
