import React, {useCallback, useState} from "react";
import IconButton from "../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {deleteExcelRegistry} from "../../../../app/store/reducers/excel-registry/excelRegistryReducer";
import {toast} from "react-toastify";

const DeleteExcelRegistry = ({id, buttonProps, buttonText}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const onDeleteExcelRegistry = useCallback(() => {
        setLoading(true);
        deleteExcelRegistry({id})
            .then(() => {
                setLoading(false);
                setShow(false);
                toast.success(t(lang, 'items.common.toast.success'))
            })
            .catch(() => {
                setLoading(false);
                toast.success(t(lang, 'main.common.error'))
            })
    }, [id]);

    return (
        <React.Fragment>
            <IconButton variant="falcon-danger"
                        icon={faTrash}
                        onClick={() => setShow(true)}
                        {...buttonProps}
            >
                {buttonText}
            </IconButton>

            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "items.common.item.product-identity.bind-attribute.delete.title")}
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "items.delete_item_modal.title")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        {t(lang, "items.common.cancel")}
                    </Button>
                    <Button variant="danger" onClick={onDeleteExcelRegistry} disabled={loading}>
                        {loading &&
                        <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                        }
                        {t(lang, "items.common.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
export default DeleteExcelRegistry