import React from "react";
import EmpowermentDataTable from "../../../../components/hippo/roaming/empowerement/table/EmpowermentDataTable";
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingEmpowermentView} from "../../../../enum/Permission/RoamingPermission";
import {Card} from "react-bootstrap";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const Empowerments = () => {
    const {params: {viewer}} = useRouteMatch();
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    //important
    useSelector(selectAccountRolePermissions)

    if(!dispatch(checkPermission(PermissionRoamingEmpowermentView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.roaming.empowerment.datatable.no_access.title')}</h4>
        </Card>
    }

    return  <EmpowermentDataTable viewer={viewer}/>

};

export default Empowerments;
