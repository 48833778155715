import {createSlice} from "@reduxjs/toolkit";
import contractorLabelService from "../../../../services/contractorLabelService";
import EventBus from "../../../eventbus/EventBus";
import {
    EDIT_CONTRACTOR_LABEL_REQUESTED,
    DELETE_CONTRACTOR_LABEL_FAILED,
    DELETE_CONTRACTOR_LABEL_SUCCESS,
    EDIT_CONTRACTOR_LABEL_SUCCESS,
    EDIT_CONTRACTOR_LABEL_FAILED,
    ADD_CONTRACTOR_LABEL_FAILED,
    DELETE_CONTRACTOR_LABEL_REQUESTED,
    ADD_CONTRACTOR_LABEL_REQUESTED,
    ADD_CONTRACTOR_LABEL_SUCCESS,
    BIND_CONTRACTOR_LABEL_SUCCESS,
    BIND_CONTRACTOR_LABEL_FAILED,
    BIND_CONTRACTOR_LABEL_REQUESTED,
    REMOVE_CONTRACTOR_LABEL_REQUESTED, REMOVE_CONTRACTOR_LABEL_SUCCESS, REMOVE_CONTRACTOR_LABEL_FAILED
} from "../../../eventbus/contractor/contractorLabelEvents";

export const contractorLabelSlice = createSlice({
    name: 'contractorLabel',
    initialState: {
        filterOptions: {page: 1, limit: 10},
        contractorLabels: [],
        allContractorLabels: [],
        count: 0,
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateContractorLabels: (state, action) => {
            state.contractorLabels = action.payload || []
        },
        updateContractorLabelsCount: (state, action) => {
            state.count = action.payload
        },
        updateAllContractorLabels: (state, action) => {
            state.allContractorLabels = action.payload
        }
    }
})


export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorLabelSlice.actions.updateFilterOptions({...params}))
}

export const loadAllContractorLabelsAsync = () => (dispatch) =>  {
    contractorLabelService.getContractorLabels()
        .then((response) => {
            dispatch(contractorLabelSlice.actions.updateAllContractorLabels(response.data))
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getAllContractorLabelsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorLabelService.getContractorLabels(params)
        .then((response) => {
            dispatch(contractorLabelSlice.actions.updateContractorLabels(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}
export const getCountContractorLabels = () => (dispatch) => {
    contractorLabelService.getContractorLabelCount()
        .then(res => {
            dispatch(contractorLabelSlice.actions.updateContractorLabelsCount(res.data))
        })
        .catch(e => {
            console.log(e)
        })
}
export const getContractorLabelAsync = id => {
    return new Promise((resolve, reject) => {
        contractorLabelService.getContractorLabel(id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    })
}
export const registerContractorLabelAsync = (payload) => {
    EventBus.dispatch(ADD_CONTRACTOR_LABEL_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorLabelService.addContractorLabel(payload)
            .then(response => {
                EventBus.dispatch(ADD_CONTRACTOR_LABEL_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_CONTRACTOR_LABEL_FAILED, error)
                reject(error);
            })
    });
}
export const editContractorLabelAsync = (id, payload) =>{
    EventBus.dispatch(EDIT_CONTRACTOR_LABEL_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorLabelService.editContractorLabel(id, payload)
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_LABEL_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_LABEL_FAILED, error)
                reject(error);
            })
    });
}
export const deleteContractorLabelAsync = ({id}) => {
    EventBus.dispatch(DELETE_CONTRACTOR_LABEL_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        contractorLabelService.deleteContractorLabel({id})
            .then((response) => {
                EventBus.dispatch(DELETE_CONTRACTOR_LABEL_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_CONTRACTOR_LABEL_FAILED, error.message);
                reject(error)
            })
    });
};

export const bindContractorLabelAsync = (data) => {
    EventBus.dispatch(BIND_CONTRACTOR_LABEL_REQUESTED, data)
    return new Promise((resolve, reject) => {
        contractorLabelService.bindContractorLabel(data)
            .then((response) => {
                EventBus.dispatch(BIND_CONTRACTOR_LABEL_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(BIND_CONTRACTOR_LABEL_FAILED, error.message)
                reject(error.message)
            })
    })
}

export const removeContractorLabelAsync = (data) => {
    EventBus.dispatch(REMOVE_CONTRACTOR_LABEL_REQUESTED, data)
    return new Promise((resolve, reject) => {
        contractorLabelService.removeContractorLabel(data)
            .then((response) => {
                EventBus.dispatch(REMOVE_CONTRACTOR_LABEL_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(REMOVE_CONTRACTOR_LABEL_FAILED, error.message)
                reject(error.message)
            })
    })
}

export const selectContractorLabels = (state) => state.contractorLabel.contractorLabels;
export const selectAllContractorLabels = (state) => state.contractorLabel.allContractorLabels;
export const selectContractorLabelFilterOptions = (state) => state.contractorLabel.filterOptions;
export const selectContractorLabelsCount = (state) => state.contractorLabel.count;

export default contractorLabelSlice.reducer;
