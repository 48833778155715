import {useDispatch, useSelector} from "react-redux";
import numeral from "numeral";
import {
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import React, {useEffect} from "react";
import {
    loadContractorOperationsAsync,
    selectLoading,
    selectContractorOperations,
    selectContractorPaymentFilterOptions
} from "../../../../../app/store/reducers/contractor/contractorOperationReducer";
import {Card, Spinner, Table} from "react-bootstrap";
import SoftBadge from "../../../../common/SoftBadge";
import {
    FindContractorOperationType,
    OperationTypePayment, OperationTypePayout, OperationTypePurchase, OperationTypeRefund, OperationTypeReturn,
    OperationTypeSale
} from "../../../../../enum/ContractorOperationTypes";
import ContractorOperationDataTableHeader from "./ContractorOperationDataTableHeader";
import Divider from "../../../../common/Divider";
import {Link} from "react-router-dom";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {useRouteMatch} from "react-router";

const ContractorOperationsDataTable = () => {
    const operations = useSelector(selectContractorOperations);
    const filters = useSelector(selectContractorPaymentFilterOptions);
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);
    const isLoading = useSelector(selectLoading)
    const currencies = useSelector(selectCurrency)

    const {params: {id}} = useRouteMatch();

    useEffect(() => {
        if (id) {
            dispatch(loadContractorOperationsAsync({...filters, contractor_id: id}))
        } else {
            dispatch(loadContractorOperationsAsync({...filters}))
        }
    }, [filters])

    const TotalDebitCredit = (is_debit) => {
        let debitOperations = [];
        if (is_debit) {
            debitOperations = operations.filter(op => {
                return op.type === OperationTypePayout
                    || op.type === OperationTypeSale
                    || op.type === OperationTypeReturn
            });

        } else {
            debitOperations = operations.filter(op => {
                return op.type === OperationTypePayment
                    || op.type === OperationTypePurchase
                    || op.type === OperationTypeRefund
            });
        }

        const debt_arrays = debitOperations.map(op => {
            return op.debt_states
        });
        let debts = debt_arrays.reduce((array, debt) => {
            return array.concat(debt)
        }, []);
        let filtered_arr = [];
        currencies.forEach(currency => {
            let filtered_debts = debts.filter(debt => debt.currency.id === currency.id);
            if (filtered_debts.length > 0) {
                filtered_arr.push(filtered_debts)
            }
        })

        return filtered_arr.map(arr => {
            let amount = arr.reduce((total, item) => {
                return total + item.amount;
            }, 0);
            return {
                amount: amount,
                currency: arr[0]['currency']
            }
        });
    }
    const TotalDifference = () => {
        let national_debit = TotalDebitCredit(true).find(debt_credit => {
            return debt_credit.currency.is_national
        });
        let national_credit = TotalDebitCredit(false).find(debt_credit => {
            return debt_credit.currency.is_national
        });
        return {
            amount: national_debit?.amount - national_credit?.amount,
            currency: national_debit?.currency || national_credit?.currency
        }
    }
    const TotalCurrencyDifference = () => {
        let currency_debits = TotalDebitCredit(true).filter(debt_credit => {
            return !debt_credit.currency.is_national
        });
        let currency_credits = TotalDebitCredit(false).filter(debt_credit => {
            return !debt_credit.currency.is_national
        });
        currency_debits = currency_debits.map(debt => {
            return {...debt, is_debit: true}
        });
        currency_credits = currency_credits.map(debt => {
            return {...debt, is_debit: false}
        });
        let concat_arr = currency_debits.concat(currency_credits);

        let filtered_arr_by_currency = [];
        currencies.forEach(currency => {
            let filtered_debts = concat_arr.filter(debt => debt.currency.id === currency.id);
            if (filtered_debts.length > 0) {
                filtered_arr_by_currency.push(filtered_debts)
            }
        })
        return filtered_arr_by_currency.map(fa => {
            let debit_total_amount = fa.reduce((total, debt) => {
                if (debt.is_debit)
                    return total + debt.amount
                return total
            }, 0);
            let credit_total_amount = fa.reduce((total, debt) => {
                if (!debt.is_debit)
                    return total + debt.amount
                return total
            }, 0);
            const total = debit_total_amount - credit_total_amount;
            return {
                amount: total,
                currency: fa[0]['currency']
            }
        })
    }

    return (
        <Card className="mb-3">
            <Card.Header>
                <ContractorOperationDataTableHeader/>
                <Divider/>
            </Card.Header>

            <Card.Body className="p-0">
                {isLoading && <div className="text-center">
                    <Spinner className="mx-auto" variant="primary" animation="border" role="switch"/>
                </div>}

                {!isLoading && <Table striped bordered size={'sm'}>
                    <thead>
                    <tr className={'bg-100'}>
                        <th/>
                        <th/>
                        <th/>
                        <th colSpan={'2'} className={'text-center'}>Без валюта</th>
                        <th colSpan={'2'} className={'text-center'}>Валюта</th>
                        <th/>
                        <th/>
                    </tr>
                    <tr className={'bg-100'}>
                        <th width={50}>#</th>
                        <th>Контрагент</th>
                        <th>Операция</th>
                        <th className={'text-center'}>Дебит</th>
                        <th className={'text-center'}>Кредит</th>
                        <th className={'text-center'}>Дебит</th>
                        <th className={'text-center'}>Кредит</th>
                        <th width={200}>Ползователь</th>
                        <th width={200}>Время</th>
                    </tr>
                    </thead>
                    <tbody>
                    {operations.map((op, index) => {
                        return (
                            <tr key={index}>
                                <th width={50}>{index + 1}</th>
                                <th>
                                    <Link to={`contractor-card/${op.contractor?.id}`}>
                                        <strong className={'fs--1'}>{op.contractor?.name}</strong>
                                    </Link>
                                </th>
                                <th>
                                    <SoftBadge
                                        bg={FindContractorOperationType(op.type)?.variant}>{t(lang, FindContractorOperationType(op.type)?.label)}</SoftBadge>
                                </th>
                                <th className={'text-center'}>
                                    {(op.type === OperationTypePayout || op.type === OperationTypeSale || op.type === OperationTypeReturn) && op.debt_states.map((ds, key) => {
                                        return (
                                            ds.currency.is_national &&
                                            <span key={key}>{numeral.formats[numberFormat].format(ds.amount)}</span>
                                        )
                                    })}
                                </th>
                                <th className={'text-center'}>{(op.type === OperationTypePayment || op.type === OperationTypePurchase || op.type === OperationTypeRefund) && op.debt_states.map((ds, key) => {
                                    return (
                                        ds.currency.is_national &&
                                        <span key={key}>{numeral.formats[numberFormat].format(ds.amount)}</span>
                                    )
                                })}</th>
                                <th className={'text-center'}>
                                    {(op.type === OperationTypePayout || op.type === OperationTypeSale || op.type === OperationTypeReturn) && op.debt_states.map((ds, key) => {
                                        return (
                                            !ds.currency.is_national && <div className={'ms-1'}
                                                                             key={key}>{numeral.formats[numberFormat].format(ds.amount)} {ds.currency.name}</div>
                                        )
                                    })}
                                </th>
                                <th className={'text-center'}>
                                    {(op.type === OperationTypePayment || op.type === OperationTypePurchase || op.type === OperationTypeRefund) && op.debt_states.map((ds, key) => {
                                        return (
                                            !ds.currency.is_national && <div className={'ms-1'}
                                                                             key={key}>{numeral.formats[numberFormat].format(ds.amount)} {ds.currency.name}</div>
                                        )
                                    })}
                                </th>
                                <th width={200}>{op.account.name ? op.account.name : op.account.username}</th>
                                <th width={200}>{op.date}</th>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr className="bg-300">
                        <td/>
                        <td/>
                        <td colSpan={"1"} className={'text-end'}>
                            <h5>{t(lang, "crm.contractor.operation.table.total")}</h5>
                        </td>
                        <td className="text-center"><h5>{TotalDebitCredit(true).map(total => {
                            return (<div>{total.currency.is_national &&
                            <span>{numeral.formats[numberFormat].format(total.amount)} {total.currency.name}</span>}</div>)
                        })}</h5>
                        </td>
                        <td className="text-center"><h5>{TotalDebitCredit(false).map(total => {
                            return (<div>{total.currency.is_national &&
                            <span>{numeral.formats[numberFormat].format(total.amount)} {total.currency.name}</span>}</div>)
                        })}</h5>
                        </td>
                        <td className="text-center"><h5>{TotalDebitCredit(true).map(total => {
                            return (<div>{!total.currency.is_national &&
                            <span>{numeral.formats[numberFormat].format(total.amount)} {total.currency.name}</span>}</div>)
                        })}</h5>
                        </td>
                        <td className="text-center"><h5>{TotalDebitCredit(false).map(total => {
                            return (<div>{!total.currency.is_national &&
                            <span>{numeral.formats[numberFormat].format(total.amount)} {total.currency.name}</span>}</div>)
                        })}</h5>
                        </td>
                        <td/>
                        <td/>
                    </tr>
                    <tr className="bg-300">
                        <td/>
                        <td/>
                        <td colSpan="1" className={'text-end'}>
                            <h5>{t(lang, "crm.contractor.operation.table.total_difference")}</h5>
                        </td>
                        <td className="text-center" colSpan="2">
                            <h5>{TotalDifference()?.amount ? (numeral.formats[numberFormat].format(TotalDifference().amount) + ' ' + TotalDifference().currency.name) : 0}</h5>
                        </td>
                        <td className="text-center" colSpan="2">
                            <h5>
                                {TotalCurrencyDifference().map(total => {
                                    return (<div>{
                                        <span>{numeral.formats[numberFormat].format(total.amount)} {total.currency.name}</span>}</div>)
                                })}
                            </h5></td>
                        <td/>
                        <td/>
                    </tr>
                    </tfoot>
                </Table>}
            </Card.Body>
        </Card>
    )
}
export default ContractorOperationsDataTable;