import React from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import BarcodeRow from "../components/BarcodeRow";
import {useFormContext} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

const ItemFormCardTracking = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();

    return (
        <Card className="h-100">
            <Card.Header className="border-bottom">
                <Card.Title>{t(lang, 'items.common.code')}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.sku')}</Form.Label>
                            <Form.Control name={'sku'}
                                          placeholder={t(lang, 'items.common.sku.placeholder')}
                                          {...form.register('sku')}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.code')}</Form.Label>
                            <Form.Control name={'code'}
                                          isInvalid={form.formState.errors?.code}
                                          placeholder={t(lang, 'items.common.code.placeholder')}
                                          {...form.register('code')}
                            />
                            <ErrorMessage name='code'
                                          errors={form.formState.errors}
                                          as={<Form.Control.Feedback type="invalid" />}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="mt-3">
                        <BarcodeRow />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
};

export default ItemFormCardTracking;