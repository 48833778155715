import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Form, Modal, Row} from "react-bootstrap";
import {dataTableTranslateColumns} from "../../../enum/CommonDatatableTranslateColumns";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {selectSettings, updateEdoSettings} from "../../../app/store/reducers/settings/edoSettingsReducer";
import {created_at, defaultRoamingInvoiceDatatableColumns, updated_at} from "../../../enum/RoamingInvoiceWrapper";
import {
    loadRoamingInvoicesAsync,
    loadRoamingInvoicesCountAsync, selectInvoiceFilterOptions
} from "../../../app/store/reducers/roaming/roamingInvoiceReducer";
import {useParams} from "react-router";

const DatatableSettingsControl = ({show, setShow}) => {
    const {viewer} = useParams();
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate
    const filters = useSelector(selectInvoiceFilterOptions);
    const settingServiceEdo = useSelector(selectSettings)

    const [columnsArray, setColumnsArray] = useState([])

    const datatableColumns = settingServiceEdo?.invoice_settings?.invoice_datatable_column?.length > 0 ? settingServiceEdo?.invoice_settings?.invoice_datatable_column : defaultRoamingInvoiceDatatableColumns
    const columns = defaultRoamingInvoiceDatatableColumns

    const handleClose = async () => {
        setShow(false);
        loadInvoices()
    }

    const handleShow = () => {
        setShow(true);
    }

    const loadInvoices = () => {
        dispatch(loadRoamingInvoicesAsync(viewer, {...filters, sort: settingServiceEdo.invoice_settings.sort, desc: settingServiceEdo.invoice_settings.desc}));
        dispatch(loadRoamingInvoicesCountAsync(viewer, {...filters}));
    }

    const onChangeSwitch = async (e, col) => {
        columnsArray.filter(column => column?.order === col?.order ? column.active = e.target.checked : column)

        const payload = {
            edo: {
                invoice_settings: {
                    invoice_datatable_column: columnsArray,
                    sort: col === 'sort' && e.target.value || settingServiceEdo.invoice_settings.sort,
                    desc: col === 'desc' ? (e.target.value === "true" && true) : settingServiceEdo.invoice_settings.desc
                },
            }
        }
        await updateEdoSettings({payload})
    }



    useEffect(() => {
        if ((datatableColumns?.length === 0 || columns?.length !== datatableColumns?.length) && columnsArray.length === 0) {
            for (let i = 0; i < columns?.length; i++) {
                columnsArray.push({
                    order: columns[i]?.order,
                    column: columns[i]?.column,
                    active: columns[i]?.active,
                })
            }
        } else if (datatableColumns?.length !== 0 && columnsArray.length === 0) {
            for (let i = 0; i < datatableColumns?.length; i++) {
                columnsArray.push({
                    order: datatableColumns[i]?.order,
                    column: datatableColumns[i]?.column,
                    active: datatableColumns[i]?.active,
                })
            }
        }
    },[datatableColumns, columns])



    return(
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t(lang,"edi.common.controller_datatable.modal.header_title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {t(lang,"edi.common.controller_datatable.modal.body.turn_on_off")}
                    </div>
                    {columnsArray?.map(col => {
                        return (
                            <div key={col?.order}>
                                <label className="d-flex">
                                    <Form.Check type="switch" defaultChecked={col?.active} onChange={(e) => onChangeSwitch(e, col)} />{" "}
                                    {t(lang, dataTableTranslateColumns(col?.column))}
                                </label>
                            </div>
                        )
                    })}
                    <div className="my-3">
                        {t(lang,"roaming.invoices.datatable.header.navigation.dropdown.sort")}
                    </div>
                    <Row>
                        <Col cols={6}>
                            <Form.Select size={'sm'} defaultValue={settingServiceEdo?.invoice_settings.sort} onChange={(e) => onChangeSwitch(e, 'sort')}>
                                <option value={created_at}> {t(lang, "roaming.invoices.datatable.header.navigation.dropdown.sort.by.created_at")}</option>
                                <option value={updated_at}> {t(lang, "roaming.invoices.datatable.header.navigation.dropdown.sort.by.updated_at")}</option>
                            </Form.Select>
                        </Col>
                        <Col cols={6}>
                            <Form.Select defaultValue={settingServiceEdo?.invoice_settings.desc+""} size={'sm'} onChange={(e) => onChangeSwitch(e, 'desc')}>
                                <option value={"false"}>{t(lang, "roaming.invoices.datatable.header.navigation.dropdown.desc.true")}</option>
                                <option value={"true"}>{t(lang, "roaming.invoices.datatable.header.navigation.dropdown.desc.false")}</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DatatableSettingsControl;
