import React, {Fragment, useState} from 'react';
import AddSystemOrganizationAddressModal from "./AddSystemOrganizationAddressModal";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    selectSystemOrganizationAddressesFilter,
    setSystemOrganizationAddressFilter
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {Col, Form, Row} from "react-bootstrap";
import {useAsyncDebounce} from "react-table";

const SystemOrganizationDatatableHeader = () => {
    const filter = useSelector(selectSystemOrganizationAddressesFilter)
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    const [contractorName, setContractorName] = useState(filter.name || '');

    const changedSearchFilter = useAsyncDebounce(value => {
        dispatch(setSystemOrganizationAddressFilter({
            name: contractorName,
        }));
    }, 1000);

    return (
        <Fragment>
            <h5>{t(lang, 'edi.navbar.routes.edo.system.company_address')}</h5>
            <Row className={'align-items-center mt-3'}>
                <Form.Group as={Col} lg={4} md={12}>
                    <Form.Control
                        type="search"
                        value={contractorName}
                        className="search-input"
                        onChange={event => {
                            setContractorName(event.target.value)
                            changedSearchFilter()
                        }}
                        placeholder={t(lang, "roaming.organization_address.datatable.header.filter.contractor")}
                    />
                </Form.Group>

                <Col className={'d-flex justify-content-end'}>
                    <AddSystemOrganizationAddressModal/>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SystemOrganizationDatatableHeader;
