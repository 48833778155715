import numeral from "numeral";
import classNames from "classnames";
import {Card, Dropdown} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, selectSystemMoneyFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    getOrganizationBillingInfoAsync,
    selectBillingIsLoading,
    selectOrganizationBillingInfo
} from "../../../../app/store/reducers/billing/billingReducer";
import PaymentInstruction from "./PaymentInstruction";
import {faCircleDollarToSlot} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const OrganizationBalanceInfo = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const billingInfo = useSelector(selectOrganizationBillingInfo);
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);
    const loading = useSelector(selectBillingIsLoading);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const loadBillingInfo = () => {
        dispatch(getOrganizationBillingInfoAsync())
    }

    useEffect(() => {
        loadBillingInfo();
    }, [activeOrganization])

    if (!billingInfo) return null;

    return (
        <React.Fragment>
            <Dropdown navbar={true} as="li" onToggle={handleToggle} className="d-lg-none">
                <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!" className="px-1 nav-link px-0">
                    <FontAwesomeIcon icon={faCircleDollarToSlot}
                                     transform="shrink-6"
                                     className="fs-5 text-facebook"
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-card" style={{left: '50%', transform: 'translateX(-50%)'}}>
                    <Card className={classNames({'opacity-75': loading})}>
                        <Card.Body className="py-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="my-0 text-primary me-2">
                                    {t(lang, 'main.navbar.nav_item.billing.balance')}:
                                    <span
                                        className="ms-2">{t(lang, 'main.top_nav.current_balance', {sum: numeral.formats[systemMoneyFormat].format(billingInfo?.balance)})}</span>
                                </p>
                                <FontAwesomeIcon onClick={loadBillingInfo} spin={loading} className="cursor-pointer"
                                                 icon="rotate"/>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="my-0 text-warning">
                                    {t(lang, "admin.organization.apply_credit_button.title")}:
                                    <span
                                        className="ms-2">{t(lang, 'main.top_nav.current_balance', {sum: numeral.formats[systemMoneyFormat].format(billingInfo.credit_amount)})}</span>
                                </p>
                                <PaymentInstruction className="cursor-pointer"/>
                            </div>
                        </Card.Body>
                    </Card>
                </Dropdown.Menu>
            </Dropdown>
            <Card className={classNames("mx-2 d-none d-lg-block", {'opacity-75': loading})}>
                <Card.Body className="py-1">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="my-0 text-primary me-2">
                            {t(lang, 'main.navbar.nav_item.billing.balance')}:
                            <span
                                className="ms-2">{t(lang, 'main.top_nav.current_balance', {sum: numeral.formats[systemMoneyFormat].format(billingInfo?.balance)})}</span>
                        </p>
                        <FontAwesomeIcon onClick={loadBillingInfo} spin={loading} className="cursor-pointer"
                                         icon="rotate"/>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="my-0 text-warning">
                            {t(lang, "admin.organization.apply_credit_button.title")}:
                            <span
                                className="ms-2">{t(lang, 'main.top_nav.current_balance', {sum: numeral.formats[systemMoneyFormat].format(billingInfo.credit_amount)})}</span>
                        </p>
                        <PaymentInstruction className="cursor-pointer"/>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
};

export default OrganizationBalanceInfo;
