import classNames from "classnames";
import {toast} from "react-toastify";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../../common/IconButton";
import React, {Fragment, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import TelegramOrderBotService from "../../../../../../services/telegram-order-bot/telegramOrderBotService";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {
    REGISTER_TELEGRAM_ORDER_BOT_FAILED,
    REGISTER_TELEGRAM_ORDER_BOT_REQUESTED,
    REGISTER_TELEGRAM_ORDER_BOT_SUCCEED
} from "../../../../../../app/eventbus/telegram-bot/telegramOrderBotEvents";
import SelectBotScript from "./SelectBotScript";

const AddTelegramOrderBotModal = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [scripts, setScripts] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const form = useForm({
        defaultValues: {
            botToken: "",
            script: null,
        }
    });

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const loadScripts = async () => {
        try {
            const {data: scripts} = await TelegramOrderBotService.getTelegramOrderBotScripts();
            setScripts(scripts);
        } catch (error) {
            setScripts([]);
            console.log(error);
        }
    };
    const onSubmit = (formData) => {
        const payload = {
            token: formData.botToken,
            script: formData.script,
        };

        setIsLoading(true);

        EventBus.dispatch(REGISTER_TELEGRAM_ORDER_BOT_REQUESTED, payload);
        TelegramOrderBotService.registerTelegramOrderBot(payload)
            .then(response => {
                setIsLoading(false);
                EventBus.dispatch(REGISTER_TELEGRAM_ORDER_BOT_SUCCEED, response.data);
                toast.success(t(lang, 'common.toast.success'));
                handleClose();
            })
            .catch(error => {
                setIsLoading(false);
                EventBus.dispatch(REGISTER_TELEGRAM_ORDER_BOT_FAILED, error);
                toast.error(t(lang, 'common.toast.error'));
            })
    };

    useEffect(() => {
        loadScripts();
    }, []);

    return (
        <Fragment>
            <IconButton size={'sm'} variant={'falcon-primary'} icon={faPlus} onClick={handleShow}>
                {t(lang, 'crm.common.add.button')}
            </IconButton>

            <Modal show={show} onHide={handleClose} size={'md'}>
                <FormProvider {...form}>
                    <Form onSubmit={form.handleSubmit(onSubmit)}>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{t(lang, 'crm.telegram_bots.order_bot.add_modal.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="gap-2">
                                <Col xs={12}>
                                    <Controller name="script"
                                                rules={{
                                                    required: t(lang, 'crm.common.forms.validations.is_required')
                                                }}
                                                render={({field, fieldState: {error}}) => (
                                                    <Form.Group>
                                                        <Form.Label>{t(lang, 'crm.telegram_bots.order_bot.add_modal.script')}</Form.Label>
                                                        <SelectBotScript botScripts={scripts}
                                                                         defaultValue={field.value}
                                                                         onChange={field.onChange}
                                                                         selectProps={{
                                                                             className: classNames({'is-invalid': !!error})
                                                                         }}
                                                        />
                                                        {!!error && <Form.Control.Feedback
                                                            type="invalid">{error.message}</Form.Control.Feedback>}
                                                    </Form.Group>
                                                )}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Controller name="botToken"
                                                rules={{
                                                    required: t(lang, 'crm.common.forms.validations.is_required')
                                                }}
                                                render={({field, fieldState: {error}}) => (
                                                    <Form.Group>
                                                        <Form.Label>{t(lang, 'crm.telegram_bots.order_bot.add_modal.bot_token')}</Form.Label>
                                                        <Form.Control rows={2}
                                                                      as="textarea"
                                                                      className={classNames({'is-invalid': !!error})}
                                                                      placeholder={t(lang, 'crm.telegram_bots.order_bot.add_modal.bot_token.placeholder')}
                                                                      defaultValue={field.value}
                                                                      onChange={field.onChange}
                                                        />
                                                        {!!error && <Form.Control.Feedback
                                                            type="invalid">{error.message}</Form.Control.Feedback>}
                                                    </Form.Group>
                                                )}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'secondary'} type={'button'} onClick={handleClose}>
                                {t(lang, 'crm.common.back.button')}
                            </Button>

                            <Button variant={'primary'} type={'submit'} disabled={loading}>
                                {loading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                                {t(lang, 'crm.common.add.button')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default AddTelegramOrderBotModal;