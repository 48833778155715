import {createSlice} from '@reduxjs/toolkit'
import dayjs from 'dayjs';
import returnInvoiceService from "../../../../services/returnInvoiceService.js";
import EventBus from "../../../eventbus/EventBus";
import {
    CANCEL_RETURN_INVOICE_FAILED,
    CANCEL_RETURN_INVOICE_REQUESTED,
    CANCEL_RETURN_INVOICE_SUCCESS,
    DECLINE_RETURN_INVOICE_FAILED,
    DECLINE_RETURN_INVOICE_REQUESTED,
    DECLINE_RETURN_INVOICE_SUCCESS,
    DELETE_RETURN_INVOICE_FAILED,
    DELETE_RETURN_INVOICE_REQUESTED,
    DELETE_RETURN_INVOICE_SUCCESS,
    SIGN_RETURN_INVOICE_FAILED,
    SIGN_RETURN_INVOICE_REQUESTED,
    SIGN_RETURN_INVOICE_SUCCESS,
    REGISTER_RETURN_INVOICE_FAILED,
    REGISTER_RETURN_INVOICE_REQUESTED,
    REGISTER_RETURN_INVOICE_SUCCESS
} from "../../../eventbus/returnInvoiceEvents";
import {
    roamingAttachAsync,
    roamingCancelAsync,
    roamingDeclineAsync,
    roamingSignAsync
} from '../eimzo/eimzoReducer.js';


export const STORAGE_RETURN_INVOICES = "ReturnInvoicesDataTableSettings"

export const returnInvoiceSlice = createSlice({
    name: "returnInvoice",
    initialState: {
        filterOptions: {page: 1, limit: 10},
        returnInvoices: [],
        count: 0,
        directors: [],
        accountants: [],
        releasers: [],
        returnInvoicesDataTableSettings: {}
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateReturnInvoices: (state, action) => {
            state.returnInvoices = action.payload
        },
        updateCount: (state, action) => {
            state.count = action.payload
        },

        createSigner: (state, action) => {
            const signer = action.payload;
            const signers = state[signer.arrayFieldName];
            signer.lastSelected = true;
            signers.map((s) => {
                s.lastSelected = false;
            });
            state[signer.arrayFieldName] = [...signers, signer];
            returnInvoiceSlice.caseReducers.saveSigners(state);
        },
        changeActiveSigner: (state, action) => {
            const signer = action.payload;
            const signers = state[signer.arrayFieldName];
            signers.map((s) => {
                s.lastSelected = s.label === signer.label;
            })
            state[signer.arrayFieldName] = [...signers];
            returnInvoiceSlice.caseReducers.saveSigners(state);
        },
        clearActiveSigner: (state, action) => {
            const signer = action.payload;
            const signers = state[signer.arrayFieldName];
            signers.map((s) => {
                if(s.lastSelected)
                    s.lastSelected = false;
            })
            state[signer.arrayFieldName] = [...signers];
            returnInvoiceSlice.caseReducers.saveSigners(state);
        },
        deleteSigner: (state, action) => {
            const signer = action.payload;
            const signers = state[signer.arrayFieldName];
            const index = signers.findIndex(s => {
                return s.label === signer.label;
            })
            state[signer.arrayFieldName] = [...signers.slice(0, index), ...signers.slice(index + 1)];
            returnInvoiceSlice.caseReducers.saveSigners(state);
        },
        saveSigners: (state) => {
            const json = {
                directors: [...state.directors],
                accountants: [...state.accountants],
                releasers: [...state.releasers],
            }
            localStorage.setItem('returnInvoiceSigners', JSON.stringify(json));
        },
        loadSigners: (state) => {
            const returnInvoiceSigners = JSON.parse(localStorage.getItem('returnInvoiceSigners'));
            if (returnInvoiceSigners) {
                state.directors = returnInvoiceSigners.directors;
                state.accountants = returnInvoiceSigners.accountants;
                state.releasers = returnInvoiceSigners.releasers;
            }
        },

        loadReturnInvoicesDataTableColumn: (state) => {
        let storageColumn = JSON.parse(localStorage.getItem(STORAGE_RETURN_INVOICES))
            let defaultColumn = {
                index: true,
                status: true,
                date: true,
                total_sum: true,
                branch: true,
                contractor: true,
                contract_num: true
            }
        if(!storageColumn) {
            localStorage.setItem(STORAGE_RETURN_INVOICES, JSON.stringify(defaultColumn))
        }
            state.returnInvoicesDataTableSettings = {...defaultColumn, ...storageColumn}
        },

        changeReturnInvoicesDataTableColumn: (state, action) => {
            state.returnInvoicesDataTableSettings = action.payload
            localStorage.setItem(STORAGE_RETURN_INVOICES, JSON.stringify(state.returnInvoicesDataTableSettings))
        },
    }
})

export const changeFilterOptionsAsync = ({number, date_start, date_end, updated_on_start, updated_on_end, ...params}) => dispatch => {
    dispatch(returnInvoiceSlice.actions.updateFilterOptions({
        number: number || null,
        date_start: date_start && dayjs(date_start).format("YYYY-MM-DD"),
        date_end: date_end && dayjs(date_end).format("YYYY-MM-DD"),
        updated_on_start: updated_on_start && dayjs(updated_on_start).format("YYYY-MM-DD 00:00:00"),
        updated_on_end: updated_on_end && dayjs(updated_on_end).format("YYYY-MM-DD 23:59:59"),
        ...params
    }))
};

export const loadReturnInvoicesAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    returnInvoiceService.getReturnInvoices(params)
        .then(response => {
            dispatch(returnInvoiceSlice.actions.updateReturnInvoices(response.data))
        })
        .catch(error => console.log(error))
};

export const loadCountReturnInvoiceAsync = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    returnInvoiceService.getCount(other)
        .then(response => {
            dispatch(returnInvoiceSlice.actions.updateCount(response.data))
        })
        .catch(error => console.log(error))
};

export const returnInvoiceRegisterAsync = (payload) => {
    EventBus.dispatch(REGISTER_RETURN_INVOICE_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        returnInvoiceService.returnInvoiceRegister(payload)
            .then((response) => {
                EventBus.dispatch(REGISTER_RETURN_INVOICE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(REGISTER_RETURN_INVOICE_FAILED, error)
                reject(error.response.data);
            });
    });
};

export const printReturnInvoiceAsync = (id) => {
    return new Promise((resolve, reject) => {
        returnInvoiceService.printReturnInvoice(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const loadReturnInvoiceAsync = id => {
    return new Promise((resolve, reject) => {
        returnInvoiceService.getReturnInvoice(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};

export const deleteReturnInvoiceAsync = ({id, comment}) => {
    EventBus.dispatch(DELETE_RETURN_INVOICE_REQUESTED, {id, comment});

    return new Promise((resolve, reject) => {
        returnInvoiceService.deleteReturnInvoice({id, comment})
            .then((response) => {
                EventBus.dispatch(DELETE_RETURN_INVOICE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_RETURN_INVOICE_FAILED, error.message);
                reject(error)
            })
    });
};

export const signReturnInvoiceAsync = (returnInvoice, certificate) => {
    EventBus.dispatch(SIGN_RETURN_INVOICE_REQUESTED, returnInvoice.id);

    return new Promise(async (resolve, reject) => {
        const signTicket = returnInvoice.sign_ticket || (await returnInvoiceService.getSignTicket(returnInvoice.id))?.data?.sign_ticket;

        if (!returnInvoice.executor.approve_info.is_approved) {
            roamingSignAsync(signTicket, certificate)
                .then((response) => {
                    EventBus.dispatch(SIGN_RETURN_INVOICE_SUCCESS, returnInvoice.id);
                    resolve(response.data);
                })
                .catch((error) => {
                    EventBus.dispatch(SIGN_RETURN_INVOICE_FAILED, error);
                    reject(error);
                })
        } else if (!returnInvoice.customer.approve_info.is_approved) {
            roamingAttachAsync(signTicket, certificate)
                .then((response) => {
                    EventBus.dispatch(SIGN_RETURN_INVOICE_SUCCESS, returnInvoice.id);
                    resolve(response.data);
                })
                .catch((error) => {
                    EventBus.dispatch(SIGN_RETURN_INVOICE_FAILED, error);
                    reject(error);
                })
        }
    })
}

export const cancelReturnInvoiceAsync = async (returnInvoice, comment, certificate) => {
    try {
        EventBus.dispatch(CANCEL_RETURN_INVOICE_REQUESTED, returnInvoice.id);
        const result = await roamingCancelAsync(returnInvoice.sign_ticket, comment, certificate)
        EventBus.dispatch(CANCEL_RETURN_INVOICE_SUCCESS, returnInvoice.id);
        return result
    } catch (error) {
        EventBus.dispatch(CANCEL_RETURN_INVOICE_FAILED, error);
        return false
    }
}

export const declineReturnInvoiceAsync = async (returnInvoice, comment, certificate) => {
    try {
        EventBus.dispatch(DECLINE_RETURN_INVOICE_REQUESTED, returnInvoice.id);
        const result = await roamingDeclineAsync(returnInvoice.sign_ticket, comment, certificate)
        EventBus.dispatch(DECLINE_RETURN_INVOICE_SUCCESS, returnInvoice.id);
        return result
    } catch (error) {
        EventBus.dispatch(DECLINE_RETURN_INVOICE_FAILED, error);
        return false
    }
}

export const uploadReturnInvoiceToExcelAsync = ({uri, id}) => {
    return new Promise((resolve, reject) => {
        returnInvoiceService.uploadReturnInvoiceToExcel({uri, id})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadReturnInvoicesToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        returnInvoiceService.uploadReturnInvoicesToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const uploadReturnInvoicesWithItemsToExcelAsync = ({uri, page, limit, ...filters}) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    return new Promise((resolve, reject) => {
        returnInvoiceService.uploadReturnInvoicesWithItemsToExcel({params: params, uri: uri})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const selectFilterOptions = (state) => state.returnInvoice.filterOptions;
export const selectReturnInvoices = (state) => state.returnInvoice.returnInvoices;
export const selectCount = (state) => state.returnInvoice.count;

export const selectReturnInvoicesDataTable = (state) => state.returnInvoice.returnInvoicesDataTableSettings

export const {loadReturnInvoicesDataTableColumn} = returnInvoiceSlice.actions
export const {changeReturnInvoicesDataTableColumn} = returnInvoiceSlice.actions

export const createSigner = returnInvoiceSlice.actions.createSigner;
export const changeActiveSigner = returnInvoiceSlice.actions.changeActiveSigner;
export const clearActiveSigner = returnInvoiceSlice.actions.clearActiveSigner;
export const deleteSigner = returnInvoiceSlice.actions.deleteSigner;
export const loadSigners = returnInvoiceSlice.actions.loadSigners;
export const selectReturnInvoiceDirectors = state => state.returnInvoice.directors;
export const selectReturnInvoiceAccountants = state => state.returnInvoice.accountants;
export const selectReturnInvoiceReleasers = state => state.returnInvoice.releasers;
export const selectReturnInvoiceActiveDirector = state => state.returnInvoice.directors.find(d => d.lastSelected);
export const selectReturnInvoiceActiveAccountant = state => state.returnInvoice.accountants.find(a => a.lastSelected);
export const selectReturnInvoiceActiveReleaser = state => state.returnInvoice.releasers.find(a => a.lastSelected);

export default returnInvoiceSlice.reducer;
