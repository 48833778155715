import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {bindDraftItemAsync} from "../../../../app/store/reducers/draft-item/draftItemReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    BIND_DRAFT_ITEM_FAILED,
    BIND_DRAFT_ITEM_REQUESTED,
    BIND_DRAFT_ITEM_SUCCESS
} from "../../../../app/eventbus/itemEvents";
import {Controller, useForm} from "react-hook-form";
import SearchItem from "./SearchItem";
import {ErrorMessage} from "@hookform/error-message";
import IconButton from "../../../common/IconButton";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router";
import {toast} from "react-toastify";
import classNames from "classnames";

const BindDraftItemModal = ({id}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const history = useHistory();
    const form = useForm({
        defaultValues: {
            itemId: null
        }
    })

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const onBindDraftItem = (formData) => {
        setLoading(true);
        EventBus.dispatch(BIND_DRAFT_ITEM_REQUESTED, formData.itemId);
        bindDraftItemAsync({
            id: id,
            item_id: formData.itemId
        })
            .then(() => {
                setLoading(false);
                EventBus.dispatch(BIND_DRAFT_ITEM_SUCCESS, formData.itemId);
                setShow(false);
                toast.success(t(lang, 'items.common.toast.success'));
                history.replace('../../../warehouse/items');
            })
            .catch(() => {
                setLoading(false);
                EventBus.dispatch(BIND_DRAFT_ITEM_FAILED, formData.itemId);
                toast.error(t(lang, 'items.common.toast.error'));
            })
    }

    return (
        <React.Fragment>
            <IconButton variant="falcon-primary"
                        icon={faLayerGroup}
                        title={t(lang, 'items.common.draft.expend_product_mapping')}
                        onClick={() => setShow(true)}
            >
                {t(lang, 'items.common.draft.existing_item')}
            </IconButton>
            {show && <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Form onSubmit={form.handleSubmit(onBindDraftItem)}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'items.common.draft.select_existing_item')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>
                                {t(lang, 'items.common.draft.select_item')}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Controller name="itemId"
                                        control={form.control}
                                        rules={{
                                            required: t(lang, 'items.common.validations.is_required')
                                        }}
                                        render={({field}) => (
                                            <SearchItem onChange={(option) => field.onChange(option ? option.item.id : null)}
                                                        selectProps={{
                                                            className: classNames({
                                                                'is-invalid': form.formState.errors?.itemId
                                                            })
                                                        }}
                                            />
                                        )}
                            />
                            <ErrorMessage name='itemId'
                                          errors={form.formState.errors}
                                          as={<Form.Control.Feedback type="invalid" />}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                            {t(lang, "items.common.cancel")}
                        </Button>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                            {t(lang, "items.common.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>}
        </React.Fragment>
    );
};

export default BindDraftItemModal;

BindDraftItemModal.propTypes = {
    id: PropTypes.string.isRequired
}