import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import EventBus from '../../../../app/eventbus/EventBus';
import { toast } from "react-toastify";
import { DOCUMENT_CANCEL_FAILED, DOCUMENT_CANCEL_STARTED, DOCUMENT_CANCEL_SUCCESS, DOCUMENT_DECLINE_FAILED, DOCUMENT_DECLINE_STARTED, DOCUMENT_DECLINE_SUCCESS, DOCUMENT_SIGN_FAILED, DOCUMENT_SIGN_STARTED, DOCUMENT_SIGN_SUCCESS } from '../../../../app/eventbus/signEvents';
import { selectActiveOrganization } from '../../../../app/store/reducers/auth/authReducer';
import { selectLang, Translate, updateShowInProgressAsync } from '../../../../app/store/reducers/main/mainReducer';
import { loadVerificationAct } from '../../../../app/store/reducers/roaming/roamingVerificationActReducer';
import VerificationAct from '../../../../components/hippo/roaming/verification-act/VerificationAct';
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingVerificationActView
} from "../../../../enum/Permission/RoamingPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {ROAMING_VERIFICATION_ACT_SYNC_SUCCESS} from "../../../../app/eventbus/roaming/roamingVerificationActEvents";

const ViewVerificationAct = ({modalId}) => {
    const [verificationAct, setVerificationAct] = useState({})
    const { id } = useParams();
    const history = useHistory();
    const permission = useSelector(selectAccountRolePermissions)
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate
    const dispatch = useDispatch()

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingVerificationActView))) {
            if (history.length > 1) {
                history.push('/roaming/verification-acts/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])
    const load = () => {
      loadVerificationAct(modalId ? modalId : id)
        .then(response => setVerificationAct(response.data))
        .catch(error => {
          console.log(error);
          history.push('/roaming/verification-acts/inbox')
        })
    }

    useEffect(() => {
      load()
    }, [modalId, id, activeOrganization.inn])

    useEffect(() => {
      // =========== DOCUMENT_SIGN
      const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => dispatch(updateShowInProgressAsync(true)))
      const signedHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
        toast.success(t(lang, "edi.document.decline.notification.success"))
        dispatch(getOrganizationBillingInfoAsync());
        dispatch(updateShowInProgressAsync(false))
        load()
      })
      const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (message) => {
        toast.error(t(lang, message))
        dispatch(updateShowInProgressAsync(false))
      });

      // =========== DOCUMENT_CANCEL
      const cancelStartedHandler = EventBus.on(DOCUMENT_CANCEL_STARTED, () => {
        dispatch(updateShowInProgressAsync(true))
      })
      const canceledHandler = EventBus.on(DOCUMENT_CANCEL_SUCCESS, () => {
        toast.success(t(lang, "edi.document.decline.notification.success"))
        dispatch(updateShowInProgressAsync(false))
        load()
      })
      const cancelFailedHandler = EventBus.on(DOCUMENT_CANCEL_FAILED, (message) => {
        toast.error(t(lang, message))
        dispatch(updateShowInProgressAsync(false))
      })

      // =========== DOCUMENT_DECLINE
      const declineStartedHandler = EventBus.on(DOCUMENT_DECLINE_STARTED, () => {
        dispatch(updateShowInProgressAsync(true))
      })
      const declinedHandler = EventBus.on(DOCUMENT_DECLINE_SUCCESS, () => {
        toast.success(t(lang, "edi.document.decline.notification.success"))
        dispatch(updateShowInProgressAsync(false))
        load()
      })
      const declineFailedHandler = EventBus.on(DOCUMENT_DECLINE_FAILED, (message) => {
        toast.error(t(lang, message))
        dispatch(updateShowInProgressAsync(false))
      })

        const verificationActSyncHandler = EventBus.on(ROAMING_VERIFICATION_ACT_SYNC_SUCCESS, () => {
            load()
        })

      return () => {
        EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
        EventBus.remove(DOCUMENT_SIGN_SUCCESS, signedHandler)
        EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)

        EventBus.remove(DOCUMENT_CANCEL_STARTED, cancelStartedHandler)
        EventBus.remove(DOCUMENT_CANCEL_SUCCESS, canceledHandler)
        EventBus.remove(DOCUMENT_CANCEL_FAILED, cancelFailedHandler)

        EventBus.remove(DOCUMENT_DECLINE_STARTED, declineStartedHandler)
        EventBus.remove(DOCUMENT_DECLINE_SUCCESS, declinedHandler)
        EventBus.remove(DOCUMENT_DECLINE_FAILED, declineFailedHandler)

          EventBus.remove(ROAMING_VERIFICATION_ACT_SYNC_SUCCESS,verificationActSyncHandler)
      }
    }, [])

    return (!!Object.keys(verificationAct).length &&
      <VerificationAct verificationAct={verificationAct}/>
    );
};

export default ViewVerificationAct;
