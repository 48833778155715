import React, {forwardRef, Fragment, useImperativeHandle, useState} from 'react';
import IconButton from "../../../common/IconButton";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import BoardForm from "../../form/board/BoardForm";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const BoardModal = forwardRef(({
                                   variant='falcon-primary',
                                   title,
                                   icon,
                                   data,
                                   id,
                                   hideButton,
                               }, ref) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)


    return (
        <Fragment>

            {!hideButton && <IconButton
                variant={variant}
                size="sm"
                icon={icon}
                transform="shrink-3"
                onClick={() => setShow(true)}
            />}

            <Modal show={show} onHide={()=>setShow(false)} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{t(lang, title)}</Modal.Title>
                    <FalconCloseButton onClick={()=>setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <BoardForm setLoading={setLoading} setShow={setShow} data={data} id={id}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button form={'board'} className={'float-end'} type="submit" variant={variant} disabled={loading}>
                        {loading &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                        {t(lang, title)}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
});

export default BoardModal;
