import IconAlert from "./IconAlert";
import {Alert} from "react-bootstrap";
import {useSelector} from "react-redux";
import React, {forwardRef} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const ErrorMessageAlert = forwardRef(({errorMessage}, ref) => {
    const lang = useSelector(selectLang);

    return (
        <div ref={ref}>
            <IconAlert variant="danger">
                <Alert.Heading className="mb-0">{Translate(lang, 'roaming.waybill.error_alert.title')}</Alert.Heading>
                <p className="mb-0 fw-medium mb-2">{errorMessage}</p>
            </IconAlert>
        </div>
    );
});

export default ErrorMessageAlert;