import React from 'react';
import OperationItemsReportDataTable
    from "../../../../components/hippo/warehouse-operation/items-report/OperationItemsReportDataTable";

const OperationItemsReport = () => {
    return (
        <OperationItemsReportDataTable/>
    );
};

export default OperationItemsReport;