import {
    loadDraftVerificationAct,
    loadVerificationAct
} from "../../../../app/store/reducers/roaming/roamingVerificationActReducer";
import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import VerificationActForm from "../../../../components/hippo/roaming/verification-act/VerificationActForm";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";


const FillVerificationAct = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [verificationAct, setVerificationAct] = useState(null)
    const [draftVerificationAct, setDraftVerificationAct] = useState(null)
    const history = useHistory()
    const {id} = useParams()
    const lang = useSelector(selectLang)
    const t = Translate





    const query = useMemo(() => {
        return new URLSearchParams(history.location.search);
    }, [history.location.search])


    const load = () => {
        const status = JSON.parse(query.get("status"));

        if (status) {
            loadVerificationAct(id)
                .then(response => {
                    let verificationAct = {
                        verificationActDoc: {
                            verificationActNo: response.data.info.number,
                            verificationActDate: dayjs(response.data.info.date).toDate(),
                            verificationActText: response.data.info.text
                        },
                        ownerTin: response.data.executor.inn,
                        ownerName: response.data.executor.name,
                        ownerFizTin: response.data.executor.director_inn,
                        ownerFizFio: response.data.executor.director_name,
                        partnerTin: response.data.customer.inn,
                        partnerName: response.data.customer.name,
                        partnerFizTin: response.data.customer.director_inn,
                        partnerFizFio: response.data.customer.director_name,
                        verificationActContracts: response.data.contracts.map(contract => ({
                            contractNo: contract.ContractNo,
                            contractDate: dayjs(contract.ContractDate).toDate(),
                            verificationActContractItems: contract.items.map(item => ({
                                ownerOperationDate: dayjs(item.executor_operation_date).toDate(),
                                ownerOperationName: item.executor_operation_name,
                                ownerDebit: +item.executor_debit,
                                ownerCredit: +item.executor_credit,
                                partnerOperationDate: dayjs(item.customer_operation_date).toDate(),
                                partnerOperationName: item.customer_operation_name,
                                partnerDebit: +item.customer_debit,
                                partnerCredit: +item.customer_credit
                            })),
                            openBalance: {
                                ownerDebit: +contract?.open_balance?.executor_debit || 0,
                                ownerCredit: +contract?.open_balance?.executor_credit || 0,
                                partnerDebit: +contract?.open_balance?.customer_debit || 0,
                                partnerCredit: +contract?.open_balance?.customer_credit || 0
                            },
                            closeBalance: {
                                ownerDebit: +contract?.close_balance?.executor_debit || 0,
                                ownerCredit: +contract?.close_balance?.executor_credit || 0,
                                partnerDebit: +contract?.close_balance?.customer_debit || 0,
                                partnerCredit: +contract?.close_balance?.customer_credit || 0
                            },
                            totalBalance: {
                                ownerDebit: +contract?.total_balance?.executor_debit || 0,
                                ownerCredit: +contract?.total_balance?.executor_credit || 0,
                                partnerDebit: +contract?.total_balance?.customer_debit || 0,
                                partnerCredit: +contract?.total_balance?.customer_credit || 0
                            }

                        })),
                        openBalance: {
                            ownerDebit: +response.data?.open_balance?.executor_debit || 0,
                            ownerCredit: +response.data?.open_balance?.executor_credit || 0,
                            partnerDebit: +response.data?.open_balance?.customer_debit || 0,
                            partnerCredit: +response.data?.open_balance?.customer_credit || 0,
                        },
                        turnoverBalance: {
                            ownerDebit: +response.data?.turnover_balance?.executor_debit || 0,
                            ownerCredit: +response.data?.turnover_balance?.executor_credit || 0,
                            partnerDebit: +response.data?.turnover_balance?.customer_debit || 0,
                            partnerCredit: +response.data?.turnover_balance?.customer_credit || 0,
                        },
                        closeBalance: {
                            ownerDebit: +response.data?.close_balance?.executor_debit || 0,
                            ownerCredit: +response.data?.close_balance?.executor_credit || 0,
                            partnerDebit: +response.data?.close_balance?.customer_debit || 0,
                            partnerCredit: +response.data?.close_balance?.customer_credit || 0,
                        }
                    }
                    setVerificationAct(verificationAct)
                })
                .catch(error => {
                    setVerificationAct(null)
                    console.log(error);
                    toast.error(t(lang,"roaming.common.error_not_found"))
                })
        } else {
            loadDraftVerificationAct(id)
                .then(response => {
                    let contentVerificationAct = JSON.parse(response.data.content)
                    let verificationAct = {
                        verificationActDoc: {
                            verificationActNo: contentVerificationAct.VerificationActDoc.VerificationActNo,
                            verificationActDate: dayjs(contentVerificationAct.VerificationActDoc.VerificationActDate).toDate(),
                            verificationActText: contentVerificationAct.VerificationActDoc.VerificationActText
                        },
                        ownerTin: contentVerificationAct.OwnerTin,
                        ownerName: contentVerificationAct.OwnerName,
                        ownerFizTin: contentVerificationAct.OwnerFizTin,
                        ownerFizFio: contentVerificationAct.OwnerFizFio,
                        partnerTin: contentVerificationAct.PartnerTin,
                        partnerName: contentVerificationAct.PartnerName,
                        partnerFizTin: contentVerificationAct.PartnerFizTin,
                        partnerFizFio: contentVerificationAct.PartnerFizFio,
                        verificationActContracts: contentVerificationAct.VerificationActContracts.map(verificationActContract => ({
                            contractNo: verificationActContract.ContractNo,
                            contractDate: dayjs(verificationActContract.ContractDate).toDate(),
                            verificationActContractItems: verificationActContract.VerificationActContractItems.map(verificationActContractItem => ({
                                ownerOperationDate: dayjs(verificationActContractItem.OwnerOperationDate).toDate(),
                                ownerOperationName: verificationActContractItem.OwnerOperationName,
                                ownerDebit: +verificationActContractItem.OwnerDebit,
                                ownerCredit: +verificationActContractItem.OwnerCredit,
                                partnerOperationDate: dayjs(verificationActContractItem.PartnerOperationDate).toDate(),
                                partnerOperationName: verificationActContractItem.PartnerOperationName,
                                partnerDebit: +verificationActContractItem.PartnerDebit,
                                partnerCredit: +verificationActContractItem.PartnerCredit
                            })),
                            openBalance: {
                                ownerDebit: +verificationActContract?.OpenBalance?.OwnerDebit || 0,
                                ownerCredit: +verificationActContract?.OpenBalance?.OwnerCredit || 0,
                                partnerDebit: +verificationActContract?.OpenBalance?.PartnerDebit || 0,
                                partnerCredit: +verificationActContract?.OpenBalance?.PartnerCredit || 0
                            },
                            closeBalance: {
                                ownerDebit: +verificationActContract?.CloseBalance?.OwnerDebit || 0,
                                ownerCredit: +verificationActContract?.CloseBalance?.OwnerCredit || 0,
                                partnerDebit: +verificationActContract?.CloseBalance?.PartnerDebit || 0,
                                partnerCredit: +verificationActContract?.CloseBalance?.PartnerCredit || 0
                            },
                            totalBalance: {
                                ownerDebit: +verificationActContract?.TotalBalance?.OwnerDebit || 0,
                                ownerCredit: +verificationActContract?.TotalBalance?.OwnerCredit || 0,
                                partnerDebit: +verificationActContract?.TotalBalance?.PartnerDebit || 0,
                                partnerCredit: +verificationActContract?.TotalBalance?.PartnerCredit || 0
                            }

                        })),
                        openBalance: {
                            ownerDebit: +contentVerificationAct?.OpenBalance?.OwnerDebit || 0,
                            ownerCredit: +contentVerificationAct?.OpenBalance?.OwnerCredit || 0,
                            partnerDebit: +contentVerificationAct?.OpenBalance?.PartnerDebit || 0,
                            partnerCredit: +contentVerificationAct?.OpenBalance?.PartnerCredit || 0,
                        },
                        turnoverBalance: {
                            ownerDebit: +contentVerificationAct?.TurnoverBalance?.OwnerDebit || 0,
                            ownerCredit: +contentVerificationAct?.TurnoverBalance?.OwnerCredit || 0,
                            partnerDebit: +contentVerificationAct?.TurnoverBalance?.PartnerDebit || 0,
                            partnerCredit: +contentVerificationAct?.TurnoverBalance?.PartnerCredit || 0,
                        },
                        closeBalance: {
                            ownerDebit: +contentVerificationAct?.CloseBalance?.OwnerDebit || 0,
                            ownerCredit: +contentVerificationAct?.CloseBalance?.OwnerCredit || 0,
                            partnerDebit: +contentVerificationAct?.CloseBalance?.PartnerDebit || 0,
                            partnerCredit: +contentVerificationAct?.CloseBalance?.PartnerCredit || 0,
                        }
                    }
                    setDraftVerificationAct(verificationAct)
                })
                .catch(error => {
                    setDraftVerificationAct(null)
                    console.log(error);
                    toast.error(t(lang,"roaming.common.error_not_found"))
                })
        }
    }

    useEffect(() => {
        load()
    }, [id, activeOrganization.inn])



    if (!verificationAct && !draftVerificationAct) {
        return(
            <>
                loading...
            </>
        )
    } else {
        return(
            <>
                <VerificationActForm verificationAct={verificationAct || draftVerificationAct}/>
            </>
        )
    }
}

export default FillVerificationAct;