import React from 'react';
import LogoutContent from "../../components/hippo/authentication/LogoutContent";

const Logout = () => (
  <div className="text-center">
    <LogoutContent />
  </div>
);

export default Logout;
