import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {
    loadBankPaymentsErrorAsync, loadBankPaymentsErrorCountAsync,
    selectSystemOrganizationsFilterOptionsBankPaymentsError
} from "../../../../app/store/reducers/billing/billingReducer";
import BankPaymentErrorDataTable from "../../../../components/admin/organization/datatable/BankPaymentErrorDataTable";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    SEEN_BANK_PAYMENTS_ERROR_FAILED,
    SEEN_BANK_PAYMENTS_ERROR_SUCCESS
} from "../../../../app/eventbus/billingEvents";
import {toast} from "react-toastify";


const BankPaymentError = () => {
    const filters = useSelector(selectSystemOrganizationsFilterOptionsBankPaymentsError)
    const dispatch = useDispatch()


    useEffect(() => {

        dispatch(loadBankPaymentsErrorAsync({...filters}))
        dispatch(loadBankPaymentsErrorCountAsync({...filters}))

        const onSeenBankPaymentsErrorHandlerSuccess = EventBus.on(SEEN_BANK_PAYMENTS_ERROR_SUCCESS, () => {
            dispatch(loadBankPaymentsErrorAsync(filters))
            dispatch(loadBankPaymentsErrorCountAsync(filters))
            toast.success('Success')
        })

        const onSeenBankPaymentsErrorHandlerFailed = EventBus.on(SEEN_BANK_PAYMENTS_ERROR_FAILED, () => {
            toast.error("Error")
        })

        return () => {
            EventBus.remove(SEEN_BANK_PAYMENTS_ERROR_SUCCESS, onSeenBankPaymentsErrorHandlerSuccess)
            EventBus.remove(SEEN_BANK_PAYMENTS_ERROR_FAILED, onSeenBankPaymentsErrorHandlerFailed)
        }

    }, [filters])



    return(
        <>
            <BankPaymentErrorDataTable/>
        </>
    )
}

export default BankPaymentError;
