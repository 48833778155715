import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import WarehouseOperationService from "../../../../services/warehouse-operation/warehouseOperationService";
import {
    APPROVE_DRAFT_REFUND_FAILED,
    APPROVE_DRAFT_REFUND_REQUESTED,
    APPROVE_DRAFT_REFUND_SUCCEED,
    BIND_DRAFT_REFUND_ITEM_FAILED,
    BIND_DRAFT_REFUND_ITEM_REQUESTED,
    BIND_DRAFT_REFUND_ITEM_SUCCEED, DELETE_DRAFT_REFUND_FAILED, DELETE_DRAFT_REFUND_REQUESTED, DELETE_DRAFT_REFUND_SUCCEED
} from "../../../eventbus/warehouse-operation/draftRefundEvents";
import dayjs from "dayjs";

const currentDate = new Date();
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

const draftRefundSlice = createSlice({
    name: 'draftRefund',
    initialState: {
        draftRefunds: [],
        draftRefundsCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            created_at_start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            created_at_end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
        },
    },
    reducers: {
        updateDraftRefunds: (state, action) => {
            state.draftRefunds = action.payload;
        },
        updateDraftRefundsCount: (state, action) => {
            state.draftRefundsCount = action.payload;
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
});

export const loadDraftRefundsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    WarehouseOperationService.getDraftRefunds(params)
        .then((response) => dispatch(draftRefundSlice.actions.updateDraftRefunds(response.data)))
        .catch(() => dispatch(draftRefundSlice.actions.updateDraftRefunds([])))
};

export const loadDraftRefundsCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params;

    WarehouseOperationService.getDraftRefundsCount(filters)
        .then((response) => dispatch(draftRefundSlice.actions.updateDraftRefundsCount(response.data)))
        .catch(() => dispatch(draftRefundSlice.actions.updateDraftRefundsCount([])))
};

export const loadDraftRefundAsync = (id) => {
    return new Promise((resolve, reject) => {
        WarehouseOperationService.getDraftRefund(id)
            .then(response => resolve(response.data))
            .then(error => reject(error))
    })
};

export const bindDraftRefundItemAsync = (draftRefundId, {draftRefundItemId, itemId}) => {
    EventBus.dispatch(BIND_DRAFT_REFUND_ITEM_REQUESTED, draftRefundId);

    return new Promise((resolve, reject) => {
        WarehouseOperationService.bindDraftRefundItem(draftRefundId, {draftRefundItemId: draftRefundItemId, itemId: itemId})
            .then(response => {
                EventBus.dispatch(BIND_DRAFT_REFUND_ITEM_SUCCEED, draftRefundId);
                resolve(response.data);
            })
            .then(error => {
                EventBus.dispatch(BIND_DRAFT_REFUND_ITEM_FAILED, draftRefundId);
                reject(error);
            })
    })
};

export const approveDraftRefundAsync = (draftRefundId, {warehouseId}) => {
    EventBus.dispatch(APPROVE_DRAFT_REFUND_REQUESTED, draftRefundId);

    return new Promise((resolve, reject) => {
        WarehouseOperationService.approveDraftRefund(draftRefundId, {warehouseId: warehouseId})
            .then(response => {
                EventBus.dispatch(APPROVE_DRAFT_REFUND_SUCCEED, response.data);
                resolve(response.data);
            })
            .then(error => {
                EventBus.dispatch(APPROVE_DRAFT_REFUND_FAILED, error);
                reject(error);
            })
    })
};

export const deleteDraftRefundAsync = (draftRefundId) => {
    EventBus.dispatch(DELETE_DRAFT_REFUND_REQUESTED, draftRefundId);

    return new Promise((resolve, reject) => {
        WarehouseOperationService.deleteDraftRefund(draftRefundId)
            .then(response => {
                EventBus.dispatch(DELETE_DRAFT_REFUND_SUCCEED, response.data);
                resolve(response.data);
            })
            .then(error => {
                EventBus.dispatch(DELETE_DRAFT_REFUND_FAILED, error);
                reject(error);
            })
    })
};

export const changeDraftRefundFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(draftRefundSlice.actions.updateFilterOptions({...params}))
};

export const selectDraftRefunds = state => state.draftRefund.draftRefunds;
export const selectDraftRefundsCount = state => state.draftRefund.draftRefundsCount;
export const selectDraftRefundsFilterOptions = state => state.draftRefund.filterOptions;

export default draftRefundSlice.reducer;