import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import DataTableFilterForm from "./DataTableFilterForm";
import {useDispatch, useSelector} from "react-redux";
import {selectFilters, updateFiltersAsync} from "../../../../../app/store/reducers/draft-item/draftItemReducer";

const DataTableFilter = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);
    const form = useForm({
        defaultValues: {
            name: filters?.name
        },
    });

    const onSubmit = (formData) => {
        const filters = {}

        if (formData?.name)
            filters["name"] = formData.name;

        dispatch(updateFiltersAsync(filters))
    }

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <DataTableFilterForm />
            </Form>
        </FormProvider>
    );
};

export default DataTableFilter;