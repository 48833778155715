import React, {useEffect} from "react";
import EventBus from "../../../../app/eventbus/EventBus";
import {useHistory} from "react-router-dom";
import EmpowermentSendForm from "../../../../components/hippo/roaming/empowerement/forms/EmpowermentSendForm";
import {
    EMPOWERMENT_DRAFT_FAILED,
    EMPOWERMENT_DRAFT_REQUESTED, EMPOWERMENT_DRAFT_SUCCESS,
    EMPOWERMENT_SEND_FAILED,
    EMPOWERMENT_SEND_REQUESTED,
    EMPOWERMENT_SEND_SUCCESS
} from "../../../../app/eventbus/roaming/roamingEmpowermentEvents";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../app/store/reducers/main/mainReducer";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingEmpowermentCreate,
    PermissionRoamingEmpowermentUpdate
} from "../../../../enum/Permission/RoamingPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const SendEmpowerment = () => {
    const routerHistory = useHistory();
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingEmpowermentCreate))) {
            if (routerHistory.length > 1) {
                routerHistory.push('/roaming/empowerments/inbox')
            } else {
                routerHistory.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        const onEmpowermentSentRequestedHandler = EventBus.on(EMPOWERMENT_SEND_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onEmpowermentSentSuccessHandler = EventBus.on(EMPOWERMENT_SEND_SUCCESS, empowerment => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.toast.empowerment_send_success'))
            routerHistory.push(`/roaming/empowerment/${empowerment.id}`)
        })

        const onEmpowermentSentFailedHandler = EventBus.on(EMPOWERMENT_SEND_FAILED, (error) => {
            dispatch(updateShowInProgressAsync(false))
            toast.error(error.message)
        })

        const onEmpowermentDraftRequestedHandler = EventBus.on(EMPOWERMENT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onEmpowermentDraftSuccessHandler = EventBus.on(EMPOWERMENT_DRAFT_SUCCESS, empowermentId => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.act_form_save_success'))
            routerHistory.push(`../draft-empowerment/${empowermentId.id}`)
        })

        const onEmpowermentDraftFailedHandler = EventBus.on(EMPOWERMENT_DRAFT_FAILED, () => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.act_form_save_error'))
        })

        return () => {
            EventBus.remove(EMPOWERMENT_SEND_REQUESTED, onEmpowermentSentRequestedHandler);
            EventBus.remove(EMPOWERMENT_SEND_SUCCESS, onEmpowermentSentSuccessHandler);
            EventBus.remove(EMPOWERMENT_SEND_FAILED, onEmpowermentSentFailedHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_REQUESTED, onEmpowermentDraftRequestedHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_SUCCESS, onEmpowermentDraftSuccessHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_FAILED, onEmpowermentDraftFailedHandler);
        }
    }, [])

    return (
        <>
            <EmpowermentSendForm />
        </>
    )
};

export default SendEmpowerment;
