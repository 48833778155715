import {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import {Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import Button from "react-bootstrap/Button";


const WarehouseOperationDeleteItem = ({onClick, itemId, id, ...props}) => {
    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate;

    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }


    const onDeleteItem = () => {
        onClick({id, itemId})
        setShow(false)
    }

    return(
        <>
            <IconButton
                icon="trash"
                variant="falcon-danger"
                className="ms-2"
                size="sm"
                onClick={() => handlerShow()}
                {...props}
            />

            <Modal show={show} onHide={handlerClose}>
                <Modal.Header>
                    <FalconCloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title className="text-center">{t(lang, "warehouse.operation.item.common.delete_item_modal.title")}</Modal.Title>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerClose}>
                        {t(lang, "warehouse.operation.item.common.close")}
                    </Button>
                    <Button variant="danger" onClick={() => onDeleteItem()}>
                        {t(lang, "warehouse.operation.item.common.delete_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default WarehouseOperationDeleteItem;