import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import Select from "react-select";


const WarehouseOperationSelectStatus = ({defaultValue, onChange, ...props}) => {
    const [value, setValue] = useState(null);
    const lang = useSelector(selectLang);
    const t = Translate;

    const options = [
        {value: null, label: t(lang, "warehouse.operation.item.common.filter.status.all")},
        {value: true, label: t(lang, "warehouse.operation.item.common.filter.status.confirmed")},
        {value: false, label: t(lang, "warehouse.operation.item.common.filter.status.no_confirmed")}
    ]

    useEffect(() => {
        if (defaultValue !== null) {
            const val = options.find(v => v.value === defaultValue);
            setValue(val);
        } else {
            setValue(options[0]);
        }
    }, [defaultValue])

    const onChangeState = option => {
        setValue(option);
        onChange(option);
    }

    const getOptionValue = option => option.value;
    const getOptionLabel = option => t(lang, option.label);

    return (
        <Select
            {...props}
            value={value}
            hideSelectedOptions
            isSearchable={false}
            onChange={onChangeState}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
        />
    )
}

export default WarehouseOperationSelectStatus;
