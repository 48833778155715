import IconButton from "../../../../common/IconButton";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import LeadForm from "./LeadForm";
import {registerLeadAsync} from "../../../../../app/store/reducers/contractor/contractorLeadReducer";

const AddContractor = ({sizeAndNameClose, disabled, changeNewContractorValue}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOnSubmit = (formData) => {
        let phones = formData.phones.filter(p => p.value)
        phones = phones.map(phone => {
            if (phone.value)
                return {
                    type: 1,
                    value: phone.value
                }
        });
        let emails = formData.emails.filter(e => e.value)
        emails = emails.map(email => {
            if (email.value)
                return {
                    type: 3,
                    value: email.value
                }
        });
        const contacts = phones.concat(emails)

        sizeAndNameClose && changeNewContractorValue({name: formData.name, inn: formData.inn})

        setLoading(true)

        registerLeadAsync({
            name: formData.name,
            inn: formData.inn,
            type: formData.type.map(t => +t),
            contacts: contacts
        }).then(res => {
            setLoading(false)
            setShow(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        });
    }
    return (<>
        <IconButton
            variant="falcon-default"
            disabled={disabled}
            size={!sizeAndNameClose && "sm"}
            icon="plus"
            onClick={() => setShow(true)}
            transform="shrink-3">
            {!sizeAndNameClose &&
            <span className="d-none d-sm-inline-block ms-1">
                    {t(lang, "edi.common.add_button")}
                </span>
            }
        </IconButton>
        <Modal show={show} onHide={() => {
            setShow(false)
        }} size="xl">
            <Modal.Header>
                <Modal.Title>{t(lang, "crm.lead.add")}</Modal.Title>
                <FalconCloseButton onClick={() => {
                    setShow(false)
                }}/>
            </Modal.Header>
            <Modal.Body>
                <LeadForm onClose={() => {
                    setShow(false)
                }} loading={loading} onSubmit={handleOnSubmit}/>
            </Modal.Body>
        </Modal>
    </>)
}
export default AddContractor