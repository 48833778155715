/* DELIVERY TYPES */
export const ExcelRegistryWaybillDeliveryTypeFromWarehouseToWarehouse = 1;
export const ExcelRegistryWaybillDeliveryTypeFromSellerToBuyer = 2;

export const ExcelRegistryWaybillDeliveryTypes = [
    {value: ExcelRegistryWaybillDeliveryTypeFromWarehouseToWarehouse, label: 'edo.excel_registry.waybill_delivery_type.from_warehouse_to_warehouse'},
    {value: ExcelRegistryWaybillDeliveryTypeFromSellerToBuyer, label: 'edo.excel_registry.waybill_delivery_type.from_seller_to_buyer'},
];
/* --- */

/* TRANSPORT TYPES */
export const ExcelRegistryWaybillTransportTypeRoad = 1;
export const ExcelRegistryWaybillTransportTypeAir = 2;
export const ExcelRegistryWaybillTransportTypeRailway = 3;
export const ExcelRegistryWaybillTransportTypeWater = 4;

export const ExcelRegistryWaybillTransportTypes = [
    {value: ExcelRegistryWaybillTransportTypeRoad, label: 'edo.excel_registry.waybill_transport_type.road'},
    {value: ExcelRegistryWaybillTransportTypeAir, label: 'edo.excel_registry.waybill_transport_type.air'},
    {value: ExcelRegistryWaybillTransportTypeRailway, label: 'edo.excel_registry.waybill_transport_type.railways'},
    {value: ExcelRegistryWaybillTransportTypeWater, label: 'edo.excel_registry.waybill_transport_type.water'},
];
/* --- */

/* COLUMNS */
export const ExcelRegistryWaybillColumnUnimportant = "unimportant";

export const ExcelRegistryWaybillColumnWaybillNumber = "waybill_number";
export const ExcelRegistryWaybillColumnWaybillDate = "waybill_date";
export const ExcelRegistryWaybillColumnContractNumber = "contract_number";
export const ExcelRegistryWaybillColumnContractDate = "contract_date";
export const ExcelRegistryWaybillColumnCustomerInn = "customer_inn";
export const ExcelRegistryWaybillColumnCustomerName = "customer_name";
export const ExcelRegistryWaybillColumnCustomerRoamingBranchCode = "customer_roaming_branch_code";
export const ExcelRegistryWaybillColumnFreightForwarderInn = "freight_forwarder_inn";
export const ExcelRegistryWaybillColumnFreightForwarderName = "freight_forwarder_name";
export const ExcelRegistryWaybillColumnCarrierInn = "carrier_inn";
export const ExcelRegistryWaybillColumnCarrierName = "carrier_name";
export const ExcelRegistryWaybillColumnClientContractNumber = "client_contract_number";
export const ExcelRegistryWaybillColumnClientContractDate = "client_contract_date";
export const ExcelRegistryWaybillColumnPayerContractNumber = "payer_contract_number";
export const ExcelRegistryWaybillColumnPayerContractDate = "payer_contract_date";
export const ExcelRegistryWaybillColumnResponsiblePersonInn = "responsible_person_inn";
export const ExcelRegistryWaybillColumnResponsiblePersonName = "responsible_person_name";
export const ExcelRegistryWaybillColumnDriverInn = "driver_inn";
export const ExcelRegistryWaybillColumnDriverName = "driver_name";
export const ExcelRegistryWaybillColumnTransportRegistrationNumber = "transport_registration_number";
export const ExcelRegistryWaybillColumnTotalDistance = "total_distance";
export const ExcelRegistryWaybillColumnDeliveryCost = "delivery_cost";
export const ExcelRegistryWaybillColumnTotalDeliveryCost = "total_delivery_cost";

export const ExcelRegistryWaybillColumnLoadingPointBranchCode = "loading_point_branch_code";
export const ExcelRegistryWaybillColumnLoadingPointRegionCode = "loading_point_region_code";
export const ExcelRegistryWaybillColumnLoadingPointDistrictCode = "loading_point_district_code";
export const ExcelRegistryWaybillColumnLoadingPointVillageCode = "loading_point_village_code";
export const ExcelRegistryWaybillColumnLoadingPointStreet = "loading_point_street";
export const ExcelRegistryWaybillColumnLoadingPointLongitude = "loading_point_longitude";
export const ExcelRegistryWaybillColumnLoadingPointLatitude = "loading_point_latitude";

export const ExcelRegistryWaybillColumnUnloadingPointBranchCode = "unloading_point_branch_code";
export const ExcelRegistryWaybillColumnUnloadingPointRegionCode = "unloading_point_region_code";
export const ExcelRegistryWaybillColumnUnloadingPointDistrictCode = "unloading_point_district_code";
export const ExcelRegistryWaybillColumnUnloadingPointVillageCode = "unloading_point_village_code";
export const ExcelRegistryWaybillColumnUnloadingPointStreet = "unloading_point_street";
export const ExcelRegistryWaybillColumnUnloadingPointLongitude = "unloading_point_longitude";
export const ExcelRegistryWaybillColumnUnloadingPointLatitude = "unloading_point_latitude";

export const ExcelRegistryWaybillColumnProductName = "product_name";
export const ExcelRegistryWaybillColumnProductCatalogCode = "product_catalog_code";
export const ExcelRegistryWaybillColumnProductCatalogName = "product_catalog_name"; // optional
export const ExcelRegistryWaybillColumnProductMeasurement = "product_measurement";
export const ExcelRegistryWaybillColumnProductQuantity = "product_quantity";
export const ExcelRegistryWaybillColumnProductPrice = "product_price";
export const ExcelRegistryWaybillColumnProductTotal = "product_total"; // optional

export const ExcelRegistryWaybillColumns = [
    {key: ExcelRegistryWaybillColumnUnimportant, label: 'edo.excel_registry.mass_register_waybill.column.unimportant'},

    {key: ExcelRegistryWaybillColumnWaybillNumber, label: 'edo.excel_registry.mass_register_waybill.column.waybill_number'},
    {key: ExcelRegistryWaybillColumnWaybillDate, label: 'edo.excel_registry.mass_register_waybill.column.waybill_date'},
    {key: ExcelRegistryWaybillColumnContractNumber, label: 'edo.excel_registry.mass_register_waybill.column.contract_number'},
    {key: ExcelRegistryWaybillColumnContractDate, label: 'edo.excel_registry.mass_register_waybill.column.contract_date'},
    {key: ExcelRegistryWaybillColumnCustomerInn, label: 'edo.excel_registry.mass_register_waybill.column.customer_inn'},
    {key: ExcelRegistryWaybillColumnCustomerName, label: 'edo.excel_registry.mass_register_waybill.column.customer_name'},
    {key: ExcelRegistryWaybillColumnCustomerRoamingBranchCode, label: 'edo.excel_registry.mass_register_waybill.column.customer_roaming_branch_code'},
    {key: ExcelRegistryWaybillColumnFreightForwarderInn, label: 'edo.excel_registry.mass_register_waybill.column.freight_forwarder_inn'},
    {key: ExcelRegistryWaybillColumnFreightForwarderName, label: 'edo.excel_registry.mass_register_waybill.column.freight_forwarder_name'},
    {key: ExcelRegistryWaybillColumnCarrierInn, label: 'edo.excel_registry.mass_register_waybill.column.carrier_inn'},
    {key: ExcelRegistryWaybillColumnCarrierName, label: 'edo.excel_registry.mass_register_waybill.column.carrier_name'},
    {key: ExcelRegistryWaybillColumnClientContractNumber, label: 'edo.excel_registry.mass_register_waybill.column.client_contract_number'},
    {key: ExcelRegistryWaybillColumnClientContractDate, label: 'edo.excel_registry.mass_register_waybill.column.client_contract_date'},
    {key: ExcelRegistryWaybillColumnPayerContractNumber, label: 'edo.excel_registry.mass_register_waybill.column.payer_contract_number'},
    {key: ExcelRegistryWaybillColumnPayerContractDate, label: 'edo.excel_registry.mass_register_waybill.column.payer_contract_date'},
    {key: ExcelRegistryWaybillColumnResponsiblePersonInn, label: 'edo.excel_registry.mass_register_waybill.column.responsible_person_inn'},
    {key: ExcelRegistryWaybillColumnResponsiblePersonName, label: 'edo.excel_registry.mass_register_waybill.column.responsible_person_name'},
    {key: ExcelRegistryWaybillColumnDriverInn, label: 'edo.excel_registry.mass_register_waybill.column.driver_inn'},
    {key: ExcelRegistryWaybillColumnDriverName, label: 'edo.excel_registry.mass_register_waybill.column.driver_name'},
    {key: ExcelRegistryWaybillColumnTransportRegistrationNumber, label: 'edo.excel_registry.mass_register_waybill.column.transport_registration_number'},
    {key: ExcelRegistryWaybillColumnTotalDistance, label: 'edo.excel_registry.mass_register_waybill.column.delivery_distance'},
    {key: ExcelRegistryWaybillColumnDeliveryCost, label: 'edo.excel_registry.mass_register_waybill.column.delivery_cost'},
    {key: ExcelRegistryWaybillColumnTotalDeliveryCost, label: 'edo.excel_registry.mass_register_waybill.column.total_delivery_cost'},

    {key: ExcelRegistryWaybillColumnLoadingPointBranchCode, label: 'edo.excel_registry.mass_register_waybill.column.loading_point_branch_code'},
    {key: ExcelRegistryWaybillColumnLoadingPointRegionCode, label: 'edo.excel_registry.mass_register_waybill.column.loading_point_region_code'},
    {key: ExcelRegistryWaybillColumnLoadingPointDistrictCode, label: 'edo.excel_registry.mass_register_waybill.column.loading_point_district_code'},
    {key: ExcelRegistryWaybillColumnLoadingPointVillageCode, label: 'edo.excel_registry.mass_register_waybill.column.loading_point_village_code'},
    {key: ExcelRegistryWaybillColumnLoadingPointStreet, label: 'edo.excel_registry.mass_register_waybill.column.loading_point_street'},
    {key: ExcelRegistryWaybillColumnLoadingPointLongitude, label: 'edo.excel_registry.mass_register_waybill.column.loading_point_longitude'},
    {key: ExcelRegistryWaybillColumnLoadingPointLatitude, label: 'edo.excel_registry.mass_register_waybill.column.loading_point_latitude'},

    {key: ExcelRegistryWaybillColumnUnloadingPointBranchCode, label: 'edo.excel_registry.mass_register_waybill.column.unloading_point_branch_code'},
    {key: ExcelRegistryWaybillColumnUnloadingPointRegionCode, label: 'edo.excel_registry.mass_register_waybill.column.unloading_point_region_code'},
    {key: ExcelRegistryWaybillColumnUnloadingPointDistrictCode, label: 'edo.excel_registry.mass_register_waybill.column.unloading_point_district_code'},
    {key: ExcelRegistryWaybillColumnUnloadingPointVillageCode, label: 'edo.excel_registry.mass_register_waybill.column.unloading_point_village_code'},
    {key: ExcelRegistryWaybillColumnUnloadingPointStreet, label: 'edo.excel_registry.mass_register_waybill.column.unloading_point_street'},
    {key: ExcelRegistryWaybillColumnUnloadingPointLongitude, label: 'edo.excel_registry.mass_register_waybill.column.unloading_point_longitude'},
    {key: ExcelRegistryWaybillColumnUnloadingPointLatitude, label: 'edo.excel_registry.mass_register_waybill.column.unloading_point_latitude'},

    {key: ExcelRegistryWaybillColumnProductName, label: 'edo.excel_registry.mass_register_waybill.column.product_name'},
    {key: ExcelRegistryWaybillColumnProductCatalogCode, label: 'edo.excel_registry.mass_register_waybill.column.product_catalog_code'},
    {key: ExcelRegistryWaybillColumnProductCatalogName, label: 'edo.excel_registry.mass_register_waybill.column.product_catalog_name'},
    {key: ExcelRegistryWaybillColumnProductMeasurement, label: 'edo.excel_registry.mass_register_waybill.column.product_measurement'},
    {key: ExcelRegistryWaybillColumnProductQuantity, label: 'edo.excel_registry.mass_register_waybill.column.product_quantity'},
    {key: ExcelRegistryWaybillColumnProductPrice, label: 'edo.excel_registry.mass_register_waybill.column.product_price'},
    {key: ExcelRegistryWaybillColumnProductTotal, label: 'edo.excel_registry.mass_register_waybill.column.product_total'},
];

/* --- */

class ExcelRegistryWaybillWrapper {
    constructor() {
        this.deliveryType = null;
        this.customerIsFreightForwarder = null;
        this.executorIsFreightForwarder = null;
        this.freightForwarderIsCarrier = null;
        this.responsiblePersonIsDriver = null;
        this.executorIsClientEmployer = null;
        this.customerIsClientEmployer = null;
    }

    setDeliveryType(deliveryType) {
        this.deliveryType = deliveryType;
    }

    setCustomerIsFreightForwarder(customerIsFreightForwarder) {
        this.customerIsFreightForwarder = customerIsFreightForwarder;
    }

    setExecutorIsFreightForwarder(executorIsFreightForwarder) {
        this.executorIsFreightForwarder = executorIsFreightForwarder;
    }

    setFreightForwarderIsCarrier(freightForwarderIsCarrier) {
        this.freightForwarderIsCarrier = freightForwarderIsCarrier;
    }

    setResponsiblePersonIsDriver(responsiblePersonIsDriver) {
        this.responsiblePersonIsDriver = responsiblePersonIsDriver;
    }

    setExecutorIsClientEmployer(executorIsClientEmployer) {
        this.executorIsClientEmployer = executorIsClientEmployer;
    }

    setCustomerIsClientEmployer(customerIsClientEmployer) {
        this.customerIsClientEmployer = customerIsClientEmployer;
    }

    isColumnRequired(columnKey) {
        switch (columnKey) {
            case ExcelRegistryWaybillColumnWaybillNumber:
                return true;
            case ExcelRegistryWaybillColumnWaybillDate:
                return true;
            case ExcelRegistryWaybillColumnContractNumber:
                return this.deliveryType === ExcelRegistryWaybillDeliveryTypeFromSellerToBuyer;
            case ExcelRegistryWaybillColumnContractDate:
                return this.deliveryType === ExcelRegistryWaybillDeliveryTypeFromSellerToBuyer;
            case ExcelRegistryWaybillColumnCustomerInn:
                return this.deliveryType === ExcelRegistryWaybillDeliveryTypeFromSellerToBuyer;
            case ExcelRegistryWaybillColumnCustomerName:
                return false;
            case ExcelRegistryWaybillColumnCustomerRoamingBranchCode:
                return false;
            case ExcelRegistryWaybillColumnFreightForwarderInn:
                if (this.customerIsFreightForwarder || this.executorIsFreightForwarder) {
                    return false;
                }
                return true;
            case ExcelRegistryWaybillColumnFreightForwarderName:
                return false;
            case ExcelRegistryWaybillColumnCarrierInn:
                return !this.freightForwarderIsCarrier;
            case ExcelRegistryWaybillColumnCarrierName:
                return false;
            case ExcelRegistryWaybillColumnClientContractNumber:
                return !!this.executorIsClientEmployer;
            case ExcelRegistryWaybillColumnClientContractDate:
                return !!this.executorIsClientEmployer;
            case ExcelRegistryWaybillColumnPayerContractNumber:
                return !!this.customerIsClientEmployer;
            case ExcelRegistryWaybillColumnPayerContractDate:
                return !!this.customerIsClientEmployer
            case ExcelRegistryWaybillColumnResponsiblePersonInn:
                return true;
            case ExcelRegistryWaybillColumnResponsiblePersonName:
                return false;
            case ExcelRegistryWaybillColumnDriverInn:
                return !this.responsiblePersonIsDriver;
            case ExcelRegistryWaybillColumnDriverName:
                return false;
            case ExcelRegistryWaybillColumnTransportRegistrationNumber:
                return false;
            case ExcelRegistryWaybillColumnTotalDistance:
                return false;
            case ExcelRegistryWaybillColumnDeliveryCost:
                return false;
            case ExcelRegistryWaybillColumnTotalDeliveryCost:
                return false;
            case ExcelRegistryWaybillColumnLoadingPointBranchCode:
                return false;
            case ExcelRegistryWaybillColumnLoadingPointRegionCode:
                return false;
            case ExcelRegistryWaybillColumnLoadingPointDistrictCode:
                return false;
            case ExcelRegistryWaybillColumnLoadingPointVillageCode:
                return false;
            case ExcelRegistryWaybillColumnLoadingPointStreet:
                return false;
            case ExcelRegistryWaybillColumnLoadingPointLongitude:
                return false;
            case ExcelRegistryWaybillColumnLoadingPointLatitude:
                return false;
            case ExcelRegistryWaybillColumnUnloadingPointBranchCode:
                return false;
            case ExcelRegistryWaybillColumnUnloadingPointRegionCode:
                return false;
            case ExcelRegistryWaybillColumnUnloadingPointDistrictCode:
                return false;
            case ExcelRegistryWaybillColumnUnloadingPointVillageCode:
                return false;
            case ExcelRegistryWaybillColumnUnloadingPointStreet:
                return false;
            case ExcelRegistryWaybillColumnUnloadingPointLongitude:
                return false;
            case ExcelRegistryWaybillColumnUnloadingPointLatitude:
                return false;
            case ExcelRegistryWaybillColumnProductName:
                return true;
            case ExcelRegistryWaybillColumnProductCatalogCode:
                return true;
            case ExcelRegistryWaybillColumnProductCatalogName:
                return false;
            case ExcelRegistryWaybillColumnProductMeasurement:
                return false;
            case ExcelRegistryWaybillColumnProductQuantity:
                return true;
            case ExcelRegistryWaybillColumnProductPrice:
                return false;
            case ExcelRegistryWaybillColumnProductTotal:
                return false;
            default:
                return false;
        }
    }
}

const excelRegistryWaybillWrapper = new ExcelRegistryWaybillWrapper();
export default excelRegistryWaybillWrapper;