import React, {Fragment} from 'react';
import CashBox from "../../../../components/hippo/cashbox/cash-boxes/CashBox";
import {getCashBoxAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";

const ViewCashBox = () => {
    return (
        <Fragment>
            <CashBox
                request={getCashBoxAsync}
                title={"cashbox.bind.account.title"}
            />
        </Fragment>
    );
};

export default ViewCashBox;
