import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const AdvanceTableCustomPagination2 = ({
                                           rowsPerPageOptions,
                                           setPageSize,
                                           pageSize,
                                           canPreviousPage,
                                           canNextPage,
                                           previousPage,
                                           nextPage,
                                           pageCount,
                                           pageIndex,
                                           gotoPage,
                                           onLimitChange,
                                           onPageChange,
                                       }) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const customPaginationArray = [];
    const range = 2
    for (let i = pageIndex - range; i <= pageCount && i <= pageIndex + range; i++) {
        if (i >= 0 && i < pageCount)
            customPaginationArray.push(i)
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-wrap align-items-center justify-content-center">
                <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={() => {
                        gotoPage(0);
                        onPageChange(1);
                    }}
                    disabled={!canPreviousPage}
                >
                    |<FontAwesomeIcon icon="chevron-left"/>
                </Button>

                <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={() => {
                        previousPage();
                        onPageChange(pageIndex);
                    }}
                    disabled={!canPreviousPage}
                    className="m-1"
                >
                    <FontAwesomeIcon icon="chevron-left"/>
                </Button>
                <ul className="pagination mb-0 d-flex flex-wrap">
                    {customPaginationArray.map((page, index) => {
                        return (
                            <li key={page} className={classNames('my-1',{ active: pageIndex === page })}>
                                <Button
                                    size="sm"
                                    variant="falcon-default"
                                    className={classNames('page me-1')}
                                    onClick={() => {
                                        gotoPage(page);
                                        onPageChange(page + 1)
                                    }}
                                >
                                    {page + 1}
                                </Button>
                            </li>
                        )
                    })}
                </ul>
                <Button
                    size="sm"
                    variant="falcon-default"
                    className="me-1"
                    onClick={() => {
                        nextPage();
                        onPageChange(pageIndex + 2);
                    }}
                    disabled={!canNextPage}
                >
                    <FontAwesomeIcon icon="chevron-right"/>
                </Button>

                <Button
                    size="sm"
                    variant="falcon-default"
                    onClick={() => {
                        gotoPage(pageCount - 1);
                        onPageChange(pageCount);
                    }}
                    disabled={!canNextPage}
                >
                    <FontAwesomeIcon icon="chevron-right"/>|
                </Button>
            </div>

            <div className="d-flex flex-column justify-content-center flex-sm-row justify-content-md-end">
                <p className="mb-0 me-sm-2">{t(lang, "edi.advance_table_custom_pagination.limit")}</p>
                <Form.Select
                    size="sm"
                    onChange={e => {
                        const pageSize = +e.target.value;
                        setPageSize(pageSize);
                        onLimitChange(pageSize);
                    }}
                    selected
                    className="w-auto"
                    defaultValue={pageSize}
                >
                    {rowsPerPageOptions.map(option => (
                        <option key={option}
                                value={option}>{option}</option>
                    ))}
                </Form.Select>
            </div>
        </div>
    );
};

export default AdvanceTableCustomPagination2;
