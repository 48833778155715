import {Card, Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";
import {
    getOrganizationInfoAsync,
    loadBankInfo,
} from "../../../../app/store/reducers/roaming/roamingReducer";
import {useEffect} from "react";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {GetAllowedStatus} from "../contract-formation-view/ContractFormationStatus";
import classNames from "classnames";
import FindPerson from "../../person/FindPerson";
import FindCompanySearchable from "../../organization/FindCompanySearchable";


const ContractFormationExecutorInfo = ({editDocumentStatus}) => {
    const { register, formState: {errors}, control, setValue } = useFormContext()
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang);
    const t = Translate

    const acceptEditStatus = GetAllowedStatus(editDocumentStatus)

    const onChangeHandler = async executor => {
        if(executor && !acceptEditStatus) {
            setValue('executor.name', executor.name)
            setValue('executor.tin', executor.inn)
            setValue('executor.pinflTin', executor.director_pinfl || executor.director_inn)
            setValue('executor.fio', executor.director)
            setValue('executor.address', executor.address)
            setValue('executor.bankId', executor.mfo)
            setValue('executor.account', executor.account)
        }
    }

    const onChangeDirectorHandler = async director => {
        if(director) {
            setValue('executor.pinflTin', director.pinfl || director.inn)
            setValue('executor.fio', director.name)
        } else {
            setValue(`executor.pinflTin`, '')
            setValue(`executor.fio`, '')
        }
    }

    useEffect(async () => {
        const mfo = await getOrganizationInfoAsync(activeOrganization.inn)
        if (mfo.mfo && !acceptEditStatus){
            const bankInfo = await loadBankInfo(mfo.mfo, lang)
            setValue('executor.bank', bankInfo?.name)
        }
    },[activeOrganization])

    return(
        <Card>
            <Card.Header>
                <h5 className="mb-2 pb-1">{t(lang, "edi.common.executor")}</h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.name")}</Form.Label>
                        <Form.Control
                            {...register("executor.name",{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            })}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.name")}
                            className={errors?.executor?.name?.message && "is-invalid"}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.executor?.name?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl")}</Form.Label>
                        <Controller
                            name="executor.tin"
                            control={control}
                            render={({field}) => (
                                <FindCompanySearchable
                                    defaultInn={field.value}
                                    onChange={onChangeHandler}
                                    disabled={true}
                                    inputProps={{
                                        placeholder: t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl")
                                    }}
                                />
                            )}
                        />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.full_name_person")}</Form.Label>
                        <Form.Control
                            {...register("executor.fio",{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            })}
                            className={errors?.executor?.fio?.message && "is-invalid"}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.full_name_person")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.executor?.fio?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl_person")}</Form.Label>
                        <Controller
                            name="executor.pinflTin"
                            control={control}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            }}
                            render={({field}) => (
                                <FindPerson
                                    defaultInnOrPinfl={field.value}
                                    onChange={onChangeDirectorHandler}
                                    inputProps={{
                                        isInvalid: errors?.executor?.pinflTin,
                                        isValid: Object.keys(errors).length > 0 && !errors?.executor?.pinflTin,
                                        placeholder: t(lang, "roaming.contracts.add.owner_info.inn_or_pinfl_person")
                                    }}
                                    inputGroupProps={{
                                        className: classNames({
                                            'is-invalid': errors?.executor?.pinflTin,
                                            'is-valid': Object.keys(errors).length > 0 && !errors?.executor?.pinflTin
                                        }),
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.executor?.pinflTin?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.work_phone_number")}</Form.Label>
                        <Form.Control
                            placeholder={t(lang, "roaming.contracts.add.owner_info.work_phone_number")}
                            {...register("executor.phoneWork")}
                        />
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.mobile_number")}</Form.Label>
                        <Form.Control
                            placeholder={t(lang, "roaming.contracts.add.owner_info.mobile_number")}
                            {...register("executor.mobile")}
                        />
                    </Col>
                    <Col sm="12" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.address")}</Form.Label>
                        <Form.Control
                            {...register("executor.address",{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            })}
                            className={errors?.executor?.address?.message && "is-invalid"}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.address")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.executor?.address?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="4" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.bank_account")}</Form.Label>
                        <Form.Control
                            {...register("executor.account",{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            })}
                            className={errors?.executor?.account?.message && "is-invalid"}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.bank_account")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.executor?.account?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="5" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.bank_address")}</Form.Label>
                        <Form.Control
                            {...register("executor.bank",{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            })}
                            className={errors?.executor?.bank?.message && "is-invalid"}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.bank_address")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.executor?.bank?.message}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="3" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.contracts.add.owner_info.mfo")}</Form.Label>
                        <Form.Control
                            {...register("executor.bankId",{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            })}
                            className={errors?.executor?.bankId?.message && "is-invalid"}
                            placeholder={t(lang, "roaming.contracts.add.owner_info.mfo")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.executor?.bankId?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ContractFormationExecutorInfo;
