import { useSelector } from "react-redux";
import { Card, Col, Row, Table } from "react-bootstrap";
import { Translate, selectLang } from "../../../../../app/store/reducers/main/mainReducer";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import IconButton from "../../../../common/IconButton";
import VerificationActContracts from "./VerificationActContracts";
import Cleave from 'cleave.js/react';
import { useEffect } from "react";

const Acts = () => {
  const { formState: { errors }, control, setValue, watch } = useFormContext();
  const {fields : fieldsContract, append : appendContract, remove : removeContract } = useFieldArray({ control: control, name: "verificationActContracts" })
  const watchFieldsContract = watch(`verificationActContracts`)
  const lang = useSelector(selectLang);
  const t = Translate;

  const addFieldContract = () => {
    appendContract({
      contractNo: "",
      contractDate: "",
      verificationActContractItems: [
          {
            ownerOperationDate: "",
            ownerOperationName: "",
            ownerDebit: 0.00,
            ownerCredit: 0.00,
            partnerOperationDate: "",
            partnerOperationName: "",
            partnerDebit: 0.00,
            partnerCredit: 0.00
          }
      ],
      openBalance: {
        ownerDebit: 0.00,
        ownerCredit: 0.00,
        partnerDebit: 0.00,
        partnerCredit: 0.00
      },
      closeBalance: {
        ownerDebit: 0.00,
        ownerCredit: 0.00,
        partnerDebit: 0.00,
        partnerCredit: 0.00
      },
      totalBalance: {
        ownerDebit: 0.00,
        ownerCredit: 0.00,
        partnerDebit: 0.00,
        partnerCredit: 0.00
      }
    })
  }
  const removeFieldContract = index => {
    removeContract(index)
  }
  const onChangeSumHandler = () => {
    let sumOpenDebit = watchFieldsContract.reduce((total, debit) => total + +debit.totalBalance.ownerDebit, 0)
    let sumOpenCredit = watchFieldsContract.reduce((total, credit) =>  total + +credit.totalBalance.ownerCredit, 0)
    let sumCloseDebit = 0
    let sumCloseCredit = 0
    if(sumOpenDebit > sumOpenCredit) {
      sumCloseDebit = sumOpenDebit - sumOpenCredit
    } else {
      sumCloseCredit = sumOpenCredit - sumOpenDebit
    }
    setValue(`turnoverBalance.ownerDebit`, sumOpenDebit)
    setValue(`turnoverBalance.ownerCredit`, sumOpenCredit)
    setValue(`turnoverBalance.partnerDebit`, sumOpenCredit)
    setValue(`turnoverBalance.partnerCredit`, sumOpenDebit)
    setValue(`closeBalance.ownerDebit`, sumCloseDebit)
    setValue(`closeBalance.ownerCredit`, sumCloseCredit)
    setValue(`closeBalance.partnerDebit`, sumCloseCredit)
    setValue(`closeBalance.partnerCredit`, sumCloseDebit)
  }
  useEffect(() => {
    onChangeSumHandler()
  }, [watchFieldsContract]);
  return (
    <>
      <Card className="position-sticky top-0" style={{zIndex: 1}}>
        <Card.Header>
          <h5 className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.contracts.title")}</h5>
        </Card.Header>
        <Card.Body className="pb-0">
          <Row>
            <Col sm="12">
              <Table bordered responsive className="mb-0">
                <thead>
                  <tr>
                    <th scope="col" colSpan="4" className="text-center table-primary">{t(lang, "roaming.verification_act.add.verification_act.contracts.owner_info", {owner: watch("ownerName") || t(lang, "roaming.verification_act.add.verification_act.contracts.owner_info.default")})}</th>
                    <th scope="col" colSpan="4" className="text-center table-primary">{t(lang, "roaming.verification_act.add.verification_act.contracts.partner_info", {partner: watch("partnerName") || t(lang, "roaming.verification_act.add.verification_act.contracts.partner_info.default")})}</th>
                    <th scope="col" colSpan="1" className="text-center table-secondary"></th>
                  </tr>
                  <tr>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.date")}</th>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.document")}</th>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.debit")}</th>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.owner.credit")}</th>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.partner.date")}</th>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.partner.document")}</th>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.partner.debit")}</th>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.head.partner.credit")}</th>
                    <th scope="col" style={{ width: "12.5%" }} className="text-center table-secondary px-0">
                      <div className="d-flex invisible">
                        <IconButton
                          variant="falcon-primary"
                          icon="plus"
                          size="sm"
                          className="mx-1 px-2"
                        />
                        <IconButton
                          variant="falcon-danger"
                          icon="trash"
                          size="sm"
                          className="mx-1 px-2"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {fieldsContract.map((contract, index) => (
        <VerificationActContracts key={contract.id} index={index} contract={contract} appendContract={addFieldContract} removeContract={removeFieldContract} onChange={onChangeSumHandler}/>
      ))}
      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col sm="12">
              <Table bordered responsive>
                <tfoot>
                  <tr>
                    <td style={{ width: "25%" }} className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.foot.turnover_balance.title")}</td>
                    <td style={{ width: "12.5%" }} className="text-center p-1">
                      <Controller 
                        name={`turnoverBalance.ownerDebit`}
                        control={control}
                        rules={{
                          required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                          <Cleave
                            {...field}
                            options={{
                              numeral: true,
                              delimiter: ' ',
                              numeralDecimalScale: 2
                            }}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                            className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                            readOnly
                          />
                        )}
                      />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1">
                      <Controller 
                        name={`turnoverBalance.ownerCredit`}
                        control={control}
                        rules={{
                          required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                          <Cleave
                            {...field}
                            options={{
                              numeral: true,
                              delimiter: ' ',
                              numeralDecimalScale: 2
                            }}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                            className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                            readOnly
                          />
                        )}
                      />
                    </td>
                    <td style={{ width: "25%" }} className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.foot.turnover_balance.title")}</td>
                    <td style={{ width: "12.5%" }} className="text-center p-1">
                      <Controller 
                        name={`turnoverBalance.partnerDebit`}
                        control={control}
                        rules={{
                          required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                          <Cleave
                            {...field}
                            options={{
                              numeral: true,
                              delimiter: ' ',
                              numeralDecimalScale: 2
                            }}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                            className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                            readOnly
                          />
                        )}
                      />
                    </td>
                    <td style={{ width: "12.5%" }} className="text-center p-1">
                      <Controller 
                        name={`turnoverBalance.partnerCredit`}
                        control={control}
                        rules={{
                          required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                          <Cleave
                            {...field}
                            options={{
                              numeral: true,
                              delimiter: ' ',
                              numeralDecimalScale: 2
                            }}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                            className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                            readOnly
                          />
                        )}
                      />
                    </td>
                    <td
                      style={{ width: "12.5%" }}
                      className="table-secondary px-0"
                    >
                      <div className="d-flex invisible">
                        <IconButton
                          variant="falcon-primary"
                          icon="plus"
                          size="sm"
                          className="mx-1 px-2"
                        />
                        <IconButton
                          variant="falcon-danger"
                          icon="trash"
                          size="sm"
                          className="mx-1 px-2"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.foot.close_balance.title")}</td>
                    <td className="text-center p-1">
                      <Controller 
                        name={`closeBalance.ownerDebit`}
                        control={control}
                        rules={{
                          required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                          <Cleave
                            {...field}
                            options={{
                              numeral: true,
                              delimiter: ' ',
                              numeralDecimalScale: 2
                            }}
                            placeholder="Кредит"
                            className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                            readOnly
                          />
                        )}
                      />
                    </td>
                    <td className="text-center p-1">
                      <Controller 
                        name={`closeBalance.ownerCredit`}
                        control={control}
                        rules={{
                          required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                          <Cleave
                            {...field}
                            options={{
                              numeral: true,
                              delimiter: ' ',
                              numeralDecimalScale: 2
                            }}
                            placeholder="Кредит"
                            className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                            readOnly
                          />
                        )}
                      />
                    </td>
                    <td className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.foot.close_balance.title")}</td>
                    <td className="text-center p-1">
                      <Controller 
                        name={`closeBalance.partnerDebit`}
                        control={control}
                        rules={{
                          required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                          <Cleave
                            {...field}
                            options={{
                              numeral: true,
                              delimiter: ' ',
                              numeralDecimalScale: 2
                            }}
                            placeholder="Кредит"
                            className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                            readOnly
                          />
                        )}
                      />
                    </td>
                    <td className="text-center p-1">
                      <Controller 
                        name={`closeBalance.partnerCredit`}
                        control={control}
                        rules={{
                          required: "edi.common.forms.validations.is_required"
                        }}
                        render={({field}) => (
                          <Cleave
                            {...field}
                            options={{
                              numeral: true,
                              delimiter: ' ',
                              numeralDecimalScale: 2
                            }}
                            placeholder="Кредит"
                            className={`${errors?.count && 'is-invalid'} p-2 form-control`}
                            readOnly
                          />
                        )}
                      />
                    </td>
                    <td className="table-secondary"></td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Acts;
