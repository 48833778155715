import React from 'react';
import LeadDatatable from "../../../../components/hippo/crm/lead/LeadDatatable";

const Lead = () => {
    return (
        <div>
            <LeadDatatable />
        </div>
    );
};

export default Lead;