import dayjs from "dayjs";
import {createSlice} from "@reduxjs/toolkit";
import WarehouseOperationService from "../../../../services/warehouse-operation/warehouseOperationService";
import EventBus from "../../../eventbus/EventBus";
import {
    APPROVE_DRAFT_PURCHASE_FAILED,
    APPROVE_DRAFT_PURCHASE_REQUESTED,
    APPROVE_DRAFT_PURCHASE_SUCCEED, BIND_DRAFT_PURCHASE_ITEM_FAILED,
    BIND_DRAFT_PURCHASE_ITEM_REQUESTED, BIND_DRAFT_PURCHASE_ITEM_SUCCEED, DELETE_DRAFT_PURCHASE_FAILED, DELETE_DRAFT_PURCHASE_REQUESTED, DELETE_DRAFT_PURCHASE_SUCCEED
} from "../../../eventbus/warehouse-operation/draftPurchaseEvents";

const currentDate = new Date();
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

const draftPurchaseSlice = createSlice({
    name: 'draftPurchase',
    initialState: {
        draftPurchases: [],
        draftPurchasesCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            created_at_start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            created_at_end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
        },
    },
    reducers: {
        updateDraftPurchases: (state, action) => {
            state.draftPurchases = action.payload;
        },
        updateDraftPurchasesCount: (state, action) => {
            state.draftPurchasesCount = action.payload;
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
});

export const selectDraftPurchases = state => state.draftPurchase.draftPurchases;
export const selectDraftPurchasesCount = state => state.draftPurchase.draftPurchasesCount;
export const selectDraftPurchasesFilterOptions = state => state.draftPurchase.filterOptions;

export const loadDraftPurchasesAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    WarehouseOperationService.getDraftPurchases(params)
        .then((response) => dispatch(draftPurchaseSlice.actions.updateDraftPurchases(response.data)))
        .catch(() => dispatch(draftPurchaseSlice.actions.updateDraftPurchases([])))
};

export const loadDraftPurchasesCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params;

    WarehouseOperationService.getDraftPurchasesCount(filters)
        .then((response) => dispatch(draftPurchaseSlice.actions.updateDraftPurchasesCount(response.data)))
        .catch(() => dispatch(draftPurchaseSlice.actions.updateDraftPurchasesCount([])))
};

export const loadDraftPurchaseAsync = (id) => {
    return new Promise((resolve, reject) => {
        WarehouseOperationService.getDraftPurchase(id)
            .then(response => resolve(response.data))
            .then(error => reject(error))
    })
};

export const bindDraftPurchaseItemAsync = (draftPurchaseId, {draftPurchaseItemId, itemId}) => {
    EventBus.dispatch(BIND_DRAFT_PURCHASE_ITEM_REQUESTED, draftPurchaseId);

    return new Promise((resolve, reject) => {
        WarehouseOperationService.bindDraftPurchaseItem(draftPurchaseId, {draftPurchaseItemId: draftPurchaseItemId, itemId: itemId})
            .then(response => {
                EventBus.dispatch(BIND_DRAFT_PURCHASE_ITEM_SUCCEED, draftPurchaseId);
                resolve(response.data);
            })
            .then(error => {
                EventBus.dispatch(BIND_DRAFT_PURCHASE_ITEM_FAILED, draftPurchaseId);
                reject(error);
            })
    })
};

export const approveDraftPurchaseAsync = (draftPurchaseId, {warehouseId}) => {
    EventBus.dispatch(APPROVE_DRAFT_PURCHASE_REQUESTED, draftPurchaseId);

    return new Promise((resolve, reject) => {
        WarehouseOperationService.approveDraftPurchase(draftPurchaseId, {warehouseId: warehouseId})
            .then(response => {
                EventBus.dispatch(APPROVE_DRAFT_PURCHASE_SUCCEED, response.data);
                resolve(response.data);
            })
            .then(error => {
                EventBus.dispatch(APPROVE_DRAFT_PURCHASE_FAILED, error);
                reject(error);
            })
    })
};

export const deleteDraftPurchaseAsync = (draftPurchaseId) => {
    EventBus.dispatch(DELETE_DRAFT_PURCHASE_REQUESTED, draftPurchaseId);

    return new Promise((resolve, reject) => {
        WarehouseOperationService.deleteDraftPurchase(draftPurchaseId)
            .then(response => {
                EventBus.dispatch(DELETE_DRAFT_PURCHASE_SUCCEED, response.data);
                resolve(response.data);
            })
            .then(error => {
                EventBus.dispatch(DELETE_DRAFT_PURCHASE_FAILED, error);
                reject(error);
            })
    })
};

export const changeDraftPurchaseFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(draftPurchaseSlice.actions.updateFilterOptions({...params}))
};

export default draftPurchaseSlice.reducer;