import React, {useEffect} from 'react';
import CashBoxTransferDataTable from "../../../../components/hippo/cashbox/transfer/CashBoxTransferDataTable";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    getAllTransferAsync, getAllTransferCount,
    selectCashBoxTransferFilter
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CASHBOX_TRANSFER_DELETE_SUCCESS,
    CASHBOX_TRANSFER_REGISTER_SUCCESS,
    CASHBOX_TRANSFER_UPDATE_SUCCESS
} from "../../../../app/eventbus/cashbox/cashboxEvents";

const CashBoxTransfer = () => {

    const dispatch = useDispatch()
    const organization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectCashBoxTransferFilter)

    const getTransferAsync = () => {
        dispatch(getAllTransferAsync({...filters}))
        dispatch(getAllTransferCount({...filters}))
    }

    useEffect(() => {
        getTransferAsync()
    }, [organization, filters])

    useEffect(() => {
        const onSuccessRegisterTransfer = EventBus.on(CASHBOX_TRANSFER_REGISTER_SUCCESS, () => {
            getTransferAsync()
        })

        const onSuccessUpdateTransfer = EventBus.on(CASHBOX_TRANSFER_UPDATE_SUCCESS, () => {
            getTransferAsync()
        })

        const onSuccessDeleteTransfer = EventBus.on(CASHBOX_TRANSFER_DELETE_SUCCESS, () => {
            getTransferAsync()
        })

        return () => {
            EventBus.remove(CASHBOX_TRANSFER_REGISTER_SUCCESS, onSuccessRegisterTransfer)
            EventBus.remove(CASHBOX_TRANSFER_UPDATE_SUCCESS, onSuccessUpdateTransfer)
            EventBus.remove(CASHBOX_TRANSFER_DELETE_SUCCESS, onSuccessDeleteTransfer)
        }
    }, [organization])

    return (
     <CashBoxTransferDataTable />
    );
};

export default CashBoxTransfer;
