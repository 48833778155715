import React from 'react';
import organizationBindingWrapper from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import Error403 from "../../../errors/Error403";
import OwnerOrganizationItemsDataTable from "../items/OwnerOrganizationItemsDataTable";

const ViewSupervisorItemsDataTable = ({binding}) => {
    const isViewItemsDisabled = organizationBindingWrapper.isAllItemsDisabled(binding.item_access_type);
    const isViewItemPricesEnabled = organizationBindingWrapper.isItemPriceAccessEnabled(binding.item_price_access_type);

    if (isViewItemsDisabled)
        return <Error403/>;

    return (
        <OwnerOrganizationItemsDataTable organizationBindingId={binding.id}
                                         isViewItemPricesEnabled={isViewItemPricesEnabled}
        />
    )
};

export default ViewSupervisorItemsDataTable;