import React, {useEffect, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import {loadReturnOrderAsync} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import RegisterReturnShipmentForm from "../../../../components/hippo/return-shipment/forms/RegisterReturnShipmentForm";
import {useHistory} from "react-router";
import EventBus from "../../../../app/eventbus/EventBus";
import {REGISTER_RETURN_SHIPMENT_SUCCEED} from "../../../../app/eventbus/returnShipmentEvents";
import {useDispatch, useSelector} from "react-redux";
import {returnShipmentSaveProviders} from "../../../../app/store/reducers/return-shipment/returnShipmentReducer";
import {loadCompanyProductCatalogsAsync} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionEdiReturnShipmentCreate,
} from "../../../../enum/Permission/EdiPermission";

const RegisterReturnShipment = () => {
    const {params: {id}} = useRouteMatch();
    const [order, setOrder] = useState(null);
    const lang = useSelector(selectLang);
    const routerHistory = useHistory();
    const dispatch = useDispatch();
    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiReturnShipmentCreate))) {
            if (history.length > 1) {
                history.push('/edi/return-orders')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        const onReturnShipmentSuccessRegisteredHandler = EventBus.on(REGISTER_RETURN_SHIPMENT_SUCCEED, (returnShipment) => {
            routerHistory.push(`/edi/return-shipment/${returnShipment.id}`);
            dispatch(returnShipmentSaveProviders())
        })

        return () => {
            EventBus.remove(REGISTER_RETURN_SHIPMENT_SUCCEED, onReturnShipmentSuccessRegisteredHandler);
        }
    }, [])

    useEffect(() => {
        loadReturnOrder()
    }, [id])

    useEffect(() => {
        if(order)
            dispatch(loadCompanyProductCatalogsAsync(order.customer.inn, lang))
    }, [order])

    const loadReturnOrder = () => {
        loadReturnOrderAsync(id)
            .then(returnOrder => setOrder(returnOrder))
            .catch(error => console.log(error))
    }

    return (
        <>
            {order && <RegisterReturnShipmentForm order={order} />}
            {!order && <span>loading....</span>}
        </>
    );
};

export default RegisterReturnShipment;
