import React from 'react';
import CashBoxesDatatable from "../../../../components/hippo/cashbox/cash-boxes/CashBoxesDatatable";

const CashBoxes = () => {
    return (
        <CashBoxesDatatable/>
    );
};

export default CashBoxes;
