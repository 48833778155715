import React, {useState} from 'react';
import IconButton from "../../../../common/IconButton";
import {faImages} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {Controller} from "react-hook-form";
import UploadImages from "../../components/UploadImages";


const ChooseImagesModal = ({fieldName, onRightClickHandler}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);

    return (
        <React.Fragment>
            <IconButton icon={faImages}
                        variant="falcon-primary"
                        title={t(lang, 'items.common.add')}
                        onClick={() => setShow(true)}
                        onContextMenu={onRightClickHandler}
            />
            {show && <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.common.add_images')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Controller name={fieldName}
                                render={({field}) => (
                                    <UploadImages files={field.value}
                                                  onChange={field.onChange}
                                                  preview
                                    />
                                )}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            onClick={() => setShow(false)}
                    >
                        {t(lang, 'items.common.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>}
        </React.Fragment>
    );
};

export default ChooseImagesModal;