import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import EventBus from "../../../../../app/eventbus/EventBus";
import {Form} from "react-bootstrap";
import {
    WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_DATE_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS, WAREHOUSE_OPERATION_REVISION_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS
} from "../../../../../app/eventbus/warehouse-operation/revisionEvents";
import {
    getRevisionItemAsync,
    revisionAddItemAsync,
    revisionUpdateNoteAsync,
    revisionRegisterAsync,
    revisionUpdateNumberAsync,
    selectRevisionDefaultsSettings,
    revisionChangeEmployeeAsync
} from "../../../../../app/store/reducers/warehouse-operation/revisionReducer";
import RevisionForm from "./RevisionForm";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import useQuery from "../../../roaming/invoice/form/useQuery";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";


const AddRevisionForm = () => {
    const revisionDefaultsSettings = useSelector(selectRevisionDefaultsSettings)
    const [revision, setRevision] = useState(null)
    const [loading, setLoading] = useState(false)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate;
    const {item_id} = useQuery('item_id')
    const settings = useSelector(selectDefaultSetting)



    const form = useForm({
        defaultValues: {
            employee_id: revisionDefaultsSettings?.defaultEmployeeId || null,
            date: dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            note: '',
            number: null,
            item_id: item_id || null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
        }
    })

    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee?.id || formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
            package_measurements: null,
            marks: [],
        })
    }



    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: +formData?.quantity,
            marks: formData?.marks || [],
        }

        if (buttonType === "addOrEditRevision" || event === "addOrEditRevision") {
            setLoading(true)
            if (!revision) {
                await revisionRegisterAsync({
                    date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
                    note: formData?.note,
                    number: formData?.number?.trim() ? formData?.number : null,
                    items: [item],
                    employee_id: formData?.employee_id,
                })
                    .then(({data}) => {
                        resetForm(data)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error?.response?.data?.error?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
            if (revision) {
                await revisionAddItemAsync({
                    revisionId: revision?.id,
                    operation: item
                })
                    .then(() => {
                        updateNote(formData)
                        // updateNumber(formData)
                        resetForm(formData)
                        updateEmployee(formData)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error?.response?.data?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || revision?.employee) {
            if (formData?.employee_id !== revision?.employee?.id) {
                revisionChangeEmployeeAsync({revisionId: revision?.id, employeeId: formData?.employee_id})
                    .then(({data}) => {
                        setRevision(data, data.items.reverse())
                    })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== revision?.note) {
            revisionUpdateNoteAsync({revisionId: revision?.id, note: formData?.note})
                .then(({data}) => {
                    setRevision(data, data.items.reverse())
                })
                .catch(() => {})
        }
    }

    // const updateNumber = (formData) => {
    //     if (formData?.number?.trim() && formData?.number !== revision?.number) {
    //         revisionUpdateNumberAsync({revisionId: revision?.id, number: formData?.number})
    //             .then(({data}) => {
    //                 setRevision(data, data.items.reverse())
    //             })
    //             .catch(() => {})
    //     }
    // }

    useEffect(() => {
        const onRegisterHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, (id) => {
            loadRevision(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS, (id) => {
            loadRevision(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS, (id) => {
            loadRevision(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS, (id) => {
            loadRevision(id)
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS, (id) => {
            history.push(`./view/${id}`)
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_DATE_SUCCESS, (id) => {
            loadRevision(id)
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_NUMBER_SUCCESS, (id) => {
            loadRevision(id)
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, onRegisterHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        form.setValue('warehouse_id', settings?.warehouse?.default_id || null)
    }, [settings])

    const loadRevision = async (id) => {
        await getRevisionItemAsync({revisionId: id})
            .then((response) => {
                setRevision(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setRevision(null)
            })
    }


    return(
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <RevisionForm revision={revision} loading={loading} addItem={form.handleSubmit(onSubmit)}/>
                </Form>
            </FormProvider>
        </>
    )
}

export default AddRevisionForm;
