import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectCashbox} from "../../../../app/store/reducers/cashbox/CashboxReducer";

const CashBoxSelect = ({defaultValue, onChange, isEditing, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const cashbox = useSelector(selectCashbox)

    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(defaultValue && cashbox.find(item => item.id === defaultValue))
    }, [defaultValue])

    return (
        <ReactSelect
            value={value}
            isClearable
            hideSelectedOptions
            options={cashbox}
            isDisabled={isEditing}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={(e) => {
                onChange(e)
                setValue(e)
            }}
            placeholder={t(lang, "edi.common.button.select")}
            {...props}
        />

    );
};

export default CashBoxSelect;
