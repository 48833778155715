import PropTypes from "prop-types";
import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {returnOrderExecutorApproveAsync} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnOrderApprove} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const ReturnOrderExecutorApprove = ({id, ...props}) => {
    const dispatch = useDispatch()

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState('');
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => setShow(true);
    const handleClose = () => {
        if(dispatch(checkPermission(PermissionEdiReturnOrderApprove))) setShow(true);
        else dispatch(toastPermission())
    };

    const onApproved = () => {
        setLoading(true);

        returnOrderExecutorApproveAsync({
            id: id,
            comment: comment
        })
            .then(() => handleClose())
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-primary"
                size="sm"
                iconClassName="me-1"
                icon={'check'}
                {...props}
            >{t(lang, 'edi.common.button.approve')}</IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'edi.return_order.approve_return_order.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder={t(lang, 'edi.common.leave_comment')}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'edi.common.button.close')}</Button>
                    <Button variant="primary" className="d-flex align-items-center" disabled={loading} onClick={onApproved}>
                        {loading && <Spinner className="me-2" animation="border" role="status" size="sm" />}
                        {t(lang, 'edi.common.button.approve')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

ReturnOrderExecutorApprove.propTypes = {
    id: PropTypes.string,
}

ReturnOrderExecutorApprove.defaultProps = {
    id: ''
}

export default ReturnOrderExecutorApprove;
