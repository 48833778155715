import React from 'react';
import {toast} from "react-toastify";
import {Translate} from "../../app/store/reducers/main/mainReducer";

const toastPermission = () => (_,getState) => {
    const lang = getState().main?.settings?.settings?.lang?.find(l => l?.is_active)?.code || 'ru'
    const t = Translate

    return toast.info(t(lang, 'system.permission.toast'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    })
};

export default toastPermission;
