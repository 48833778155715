import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from '../../../../app/store/reducers/auth/authReducer';
import {
    loadCountShipments, loadDataTableColumn,
    loadShipments as loadShipmentsAsync,
    selectFilterOptions,
    selectShipmentsDataTable
} from '../../../../app/store/reducers/shipment/shipmentReducer';
import ShipmentDataTable from '../../../../components/hippo/shipment/ShipmentDataTable';
import {setEdiBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiShipmentView} from "../../../../enum/Permission/EdiPermission";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {useParams} from "react-router";

const Shipments = () => {
    const {viewer} = useParams()
    const lang = useSelector(selectLang);
    const dispatch = useDispatch()
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization)
    const filters = useSelector(selectFilterOptions)
    const activeColumns = useSelector(selectShipmentsDataTable)
    // important
    useSelector(selectAccountRolePermissions)

    useEffect(() => {
       loadShipments()
        dispatch(loadDataTableColumn())
    }, [filters, activeOrganization, viewer])

    useEffect(() => {
        dispatch(setEdiBreadcrumbData(null))
    }, [])

    const loadShipments = () => {
        dispatch(loadCountShipments(viewer,{...filters, viewer_inn: activeOrganization.inn}))
        dispatch(loadShipmentsAsync(viewer,{...filters, viewer_inn: activeOrganization.inn}))
    }

    if(!dispatch(checkPermission(PermissionEdiShipmentView))) {
        return  <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.edi.shipment.datatable.no_access.title')}</h4>
        </Card>
    }

    return <ShipmentDataTable loadShipments={loadShipments} activeColumns={activeColumns}/>

};

export default Shipments;
