import React, {Fragment, useEffect, useState} from 'react';
import {
    getItemMemberChange,
    getItemStatusChange,
    selectModalContent, selectWithTasks
} from "../../app/store/reducers/kanban/kanbanReducer";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {columnType, ContractorDebtBoardType, memberChangeType} from "../../enum/KanbanWrapper";
import dayjs from "dayjs";
import {selectEmployees} from "../../app/store/reducers/employee/employeeReducer";
import {Button} from "react-bootstrap";

const ADD_COMMENT = 1
const UPDATE_COMMENT = 2
const DELETE_COMMENT = 3

const KanbanItem = ({id}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const modalContent = useSelector(selectModalContent)
    const employees = useSelector(selectEmployees);
    const withTasks = useSelector(selectWithTasks);
    const [showMoreLimit, setShowMoreLimit] = useState(3)
    const findColumnPositionHistory = (column_id) => {
        const columnId = withTasks.columns.find(column => column.id === column_id)?.type

        return columnType(ContractorDebtBoardType, columnId)?.name
    }

    const positionHistoryText = t(lang, 'task.board.history.datatable.status', {
        from_status: '{from_status}',
        to_status: '{to_status}'
    })

    const contractorHistoryText = t(lang, 'task.board.history.datatable.status.contractor.date', {
        from_debt_promise_date: '{from_debt_promise_date}',
        to_debt_promise_date: '{to_debt_promise_date}'
    })

    const assigneeHistoryText = t(lang, 'task.board.history.datatable.status.contractor.assignee', {
        from_assignee_id: '{from_assignee_id}',
        to_assignee_id: '{to_assignee_id}'
    })

    const addCommentHistoryText = t(lang, 'task.board.history.datatable.status.comment.add', {
        comment: '{comment}',
    })

    const updateCommentHistoryText = t(lang, 'task.board.history.datatable.status.comment.update', {
        from_content: '{from_content}',
        to_content: '{to_content}',
    })

    const deleteCommentHistoryText = t(lang, 'task.board.history.datatable.status.comment.delete', {
        comment: '{comment}',
    })

    const updatetitleHistoryText = t(lang, 'task.board.history.datatable.status.update.title', {
        from_title: '{from_title}',
        to_title: '{to_title}',
    })

    const updateDescriptionHistoryText = t(lang, 'task.board.history.datatable.status.update.description', {
        from_description: '{from_description}',
        to_description: '{to_description}',
    })

    return (
        <Fragment>
            {
                modalContent?.histories?.filter((item, index) => showMoreLimit >= index).map((item, index) => <p
                    key={index} className={'pb-1 fs--1 border-1 border-bottom'}>
                    <strong
                        className={'text-primary'}>{item.changer_id ? employees.find(employee => employee.id === item.changer_id)?.name : t(lang, 'main.top.nav.profile_dropdown.dropdown_item.system')}: </strong>
                    {
                        item.position_history && <div>
                            {
                                positionHistoryText.split(/({from_status}|{to_status})/g).map((part, index) =>
                                    part === '{from_status}' ? <span key={index}
                                                                     className={'text-italic fw-bold text-primary'}>{t(lang, findColumnPositionHistory(item?.position_history?.from_column_id))}</span> :
                                        part === '{to_status}' ? <span key={index}
                                                                       className={'text-italic fw-bold text-primary'}>{t(lang, findColumnPositionHistory(item?.position_history?.to_column_id))}</span> :
                                            part
                                )
                            }
                        </div>
                    }
                    {
                        item.contractor_history && <div>
                            {
                                contractorHistoryText.split(/({from_debt_promise_date}|{to_debt_promise_date})/g).map((part, index) =>
                                    part === '{from_debt_promise_date}' ? <span key={index}
                                                                                className={'text-italic fw-bold text-primary'}>{dayjs(new Date(item.contractor_history?.debt_promise_date.from_debt_promise_date)).format('DD-MM-YYYY')}</span> :
                                        part === '{to_debt_promise_date}' ? <span key={index}
                                                                                  className={'text-italic fw-bold text-primary'}>{dayjs(new Date(item.contractor_history?.debt_promise_date.to_debt_promise_date)).format('DD-MM-YYYY')}</span> :
                                            part
                                )
                            }
                        </div>
                    }
                    {
                        item.assignee_history && <div>
                            {
                                assigneeHistoryText.split(/({from_assignee_id}|{to_assignee_id})/g).map((part, index) =>
                                    part === '{from_assignee_id}' ? <span key={index}
                                                                          className={'text-italic fw-bold text-primary'}>{employees.find(employee => employee.id === item.assignee_history?.from_assignee_id)?.name || "_"}</span> :
                                        part === '{to_assignee_id}' ? <span key={index}
                                                                            className={'text-italic fw-bold text-primary'}>{employees.find(employee => employee.id === item.assignee_history?.to_assignee_id)?.name}</span> :
                                            part
                                )
                            }
                        </div>
                    }
                    {
                        item.comment_history && <div>
                            {item.comment_history.type === ADD_COMMENT &&
                                addCommentHistoryText.split(/({comment})/g).map((part, index) =>
                                    part === '{comment}' ? <span key={index}
                                                                 className={'text-italic fw-bold text-primary'}>{item.comment_history.to_content}</span> :
                                        part
                                )
                            }
                            {
                                item.comment_history.type === UPDATE_COMMENT &&
                                updateCommentHistoryText.split(/({from_content}|{to_content})/g).map((part, index) =>
                                    part === '{from_content}' ? <span key={index}
                                                                      className={'text-italic fw-bold text-primary'}>{item.comment_history.from_content}</span> :
                                        part === '{to_content}' ? <span key={index}
                                                                        className={'text-italic fw-bold text-primary'}>{item.comment_history.to_content}</span> :
                                            part
                                )
                            }
                            {
                                item.comment_history.type === DELETE_COMMENT &&
                                deleteCommentHistoryText.split(/({comment})/g).map((part, index) =>
                                    part === '{comment}' ? <span key={index}
                                                                 className={'text-italic fw-bold text-primary'}>{item.comment_history.to_content}</span> :
                                        part
                                )

                            }
                        </div>
                    }
                    {
                        item.title_history && <div>
                            {
                                updatetitleHistoryText.split(/({from_title}|{to_title})/g).map((part, index) =>
                                    part === '{from_title}' ? <span key={index}
                                                                    className={'text-italic fw-bold text-primary'}>{item.title_history.from_title}</span> :
                                        part === '{to_title}' ? <span key={index}
                                                                      className={'text-italic fw-bold text-primary'}>{item.title_history.to_title}</span> :
                                            part
                                )
                            }
                        </div>
                    }
                    {
                        item.description_history && <div>
                            {
                                updateDescriptionHistoryText.split(/({from_description}|{to_description})/g).map((part, index) =>
                                    part === '{from_description}' ? <span key={index}
                                                                          className={'text-italic fw-bold text-primary'}>{item.description_history.from_description}</span> :
                                        part === '{to_description}' ? <span key={index}
                                                                            className={'text-italic fw-bold text-primary'}>{item.description_history.to_description}</span> :
                                            part
                                )
                            }
                        </div>
                    }
                    {item.assigner && <span>
                        {t(lang, memberChangeType(item.change_type), {
                            assigner: item.assigner.name
                        })}
                    </span>}
                    <span className={' fs--2 d-flex justify-content-end gap-1'}>
                        <span className={'text-primary fw-bold'}>{dayjs(item.created_at).format('HH:mm')}</span>
                        <span>{dayjs(item.created_at).format('DD-MM-YYYY')}</span>
                    </span>
                </p>)
            }
            <div className="w-100 text-end mt-2">
                <Button disabled={showMoreLimit > modalContent?.histories?.length} onClick={() => setShowMoreLimit(showMoreLimit + 3)}>
                    {t(lang, 'roaming.invoice.mass_add.show_more_btn.title')}
                </Button>
            </div>
         </Fragment>
)};

export default KanbanItem;
