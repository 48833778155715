import React, {useRef, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import BulkItemsForm from "./BulkItemsForm";
import {addItemAsync} from "../../../../../app/store/reducers/item/itemReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {BULK_ADD_ITEMS_FINISHED, BULK_ADD_ITEMS_STARTED} from "../../../../../app/eventbus/itemEvents";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ResultCounter from "./ResultCounter";
import dayjs from "dayjs";
import {revisionRegisterAsync} from "../../../../../app/store/reducers/warehouse-operation/revisionReducer";
import {toast} from "react-toastify";
import {updateWarehouseItemAlertOn} from "../../../../../app/store/reducers/warehouse/warehouseReducer";

const AddBulkItemsForm = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);
    const [errors, setErrors] = useState({});
    const form = useForm({
        defaultValues: {
            items: [],
        }
    });

    const onSubmit = async (formData, replace) => {
        onReset();
        setErrors({})
        ref.current.updateLoadedCount(formData.items.length);
        const failed = [];
        const errors = {};
        try {
            EventBus.dispatch(BULK_ADD_ITEMS_STARTED);
            const revisionItems = [];

            for (let i = 0; i < formData.items.length; i++) {
                const item = formData.items[i];
                try {
                    const itemJson = {
                        name: item.names.map(obj => obj.name.trim()).join(' ').trim(),
                        description: item.description,
                        is_state_controlled: item.isStateControlled,
                        sku: item.sku || null,
                        code: item.code || null,
                        barcodes: item.barcodes.map(({barcode}) => barcode),
                        measurement: item.measurement,
                        category_id: item.categoryId,
                        description_attributes: item.descriptionAttributes.map((attr) => ({
                            key: attr.key,
                            value: attr.value
                        })),
                        package_measurements: item.packageMeasurements.map((pck) => ({
                            name: pck.name,
                            quantity: +pck.quantity
                        })),
                        legal_type: item.legalType,
                        tax: {
                            tax_rate: item.taxRate === '' ? null : +item.taxRate,
                            catalog: item.taxCatalog ? {
                                code: item.taxCatalog.class_code,
                                name: item.taxCatalog.name,
                                package: (item.taxCatalog.use_package && item.taxPackage) ? {
                                    code: item.taxPackage.code.toString(),
                                    name: item.taxPackage.name_ru,
                                } : null,
                            } : null,
                            benefit: item.taxBenefit ? {
                                id: +item.taxBenefit.id,
                                name: item.taxBenefit.name,
                                type: +item.taxBenefit.type,
                            } : null,
                            measurement: (item.taxMeasurement && !item.taxCatalog.use_package) ? item.taxMeasurement.measureId : null,
                        },
                        images: item.images.map((image) => ({
                            id: null,
                            name: image.name,
                            content: image.content.split(",")[1]
                        })),
                        common_price: {
                            currency_id: +item.commonPrice.currencyId,
                            amount: +item.commonPrice.price,
                        },
                        bulk_price: {
                            currency_id: +item.bulkPrice.currencyId,
                            amount: +item.bulkPrice.price
                        },
                        purchase_price: {
                            currency_id: +item.purchasePrice.currencyId,
                            amount: +item.purchasePrice.price
                        },
                    };
                    const createdItem = await addItemAsync(itemJson);

                    for (let i = 0; i < item.itemWarehouseStates.length; i++) {
                        let itemWarehouse = item.itemWarehouseStates[i];

                        if (itemWarehouse.alert_on) {
                            const alertOnItem = {
                                warehouse_id: itemWarehouse.id,
                                id: createdItem.id,
                                alert_on: +itemWarehouse.alert_on || 0
                            }
                            await updateWarehouseItemAlertOn(alertOnItem)
                        }

                        if (itemWarehouse.id && itemWarehouse.state) {
                            const revisionItem = {
                                item_id: createdItem.id,
                                warehouse_id: itemWarehouse.id,
                                quantity: +itemWarehouse.state
                            };
                            revisionItems.push(revisionItem);
                        }
                    }

                    ref.current.incrementSucceedCount();
                } catch (error) {
                    failed.push(item);
                    if (!errors[i]) {
                        errors[i] = {};
                    }

                    if (error.name) {
                        errors[i]['name_exists'] = item['names'][0].name;
                    }
                    if (error.existing_barcodes) {
                        errors[i]['existing_barcodes'] = item['barcodes'].map(({barcode}) => barcode);
                    }
                    if (error.code_exists) {
                        errors[i]['code_exists'] = item['code'];
                    }
                    ref.current.incrementFailedCount();
                }
            }
            setErrors(errors);
            if (revisionItems.length) {
                const revisionJson = {
                    approve: true,
                    date: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    items: revisionItems,
                };
                await revisionRegisterAsync(revisionJson);
            }

            if (failed.length > 0) {
                replace(failed);
                toast.error(t(lang, 'items.common.toast.error'));
            } else {
                toast.success(t(lang, 'items.common.toast.success'));
            }

            EventBus.dispatch(BULK_ADD_ITEMS_FINISHED);
        } catch (error) {
            console.log(error);
        }
    };
    const onReset = () => {
        form.reset();
    };

    return (
        <>
            <ResultCounter ref={ref}/>
            <Row className="gy-3">
                <Col xs={12}>
                    <FormProvider {...form}>
                        <Form>
                            <BulkItemsForm isEditing={false}
                                           allowDeleteItem={true}
                                           allowAddItem={true}
                                           onSubmit={form.handleSubmit(onSubmit)}
                                           onReset={onReset}
                                           errors={errors}
                            />
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </>
    );
};

export default AddBulkItemsForm;
