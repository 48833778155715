import { useSelector } from "react-redux";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { Translate, selectLang, selectDateFormat } from "../../../../../app/store/reducers/main/mainReducer";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import IconButton from "../../../../common/IconButton";
import VerificationActContractDocument from "./VerificationActContractDocument";
import Cleave from "cleave.js/react";
import { useEffect } from "react";

const VerificationActContracts = ({index, contract, appendContract, removeContract, onChange}) => {
  const { register, getFieldState, setValue, control, getValues, watch } = useFormContext();
  const { fields : fieldsDocument, append : appendDocument, remove : removeDocument } = useFieldArray({ control: control, name: `verificationActContracts.${index}.verificationActContractItems` })
  const { openBalance: watchOpenBalance, verificationActContractItems: watchFieldsDocument } = watch(`verificationActContracts.${index}`)
  const errors = getFieldState(`verificationActContracts.${index}`).error || []
  const dateFormat = useSelector(selectDateFormat);
  const lang = useSelector(selectLang);
  const t = Translate;

  const addFieldDocument = () => {
    appendDocument({
      ownerOperationDate: "",
      ownerOperationName: "",
      ownerDebit: 0.00,
      ownerCredit: 0.00,
      partnerOperationDate: "",
      partnerOperationName: "",
      partnerDebit: 0.00,
      partnerCredit: 0.00
    })
  }
  const removeFieldDocument = async index => {
    removeDocument(index)
  }
  const setPartnerOpenDebit = (sum) => {
    setValue(`verificationActContracts.${index}.openBalance.partnerDebit`, sum)
    onChangeSumHandler()
  }
  const setPartnerOpenCredit = (sum) => {
    setValue(`verificationActContracts.${index}.openBalance.partnerCredit`, sum)
    onChangeSumHandler()
  }
  const onChangeSumHandler = () => {
    let sumOpenDebit = watchFieldsDocument.reduce((total, debit) => total + +debit.ownerDebit, +watchOpenBalance.ownerDebit)
    let sumOpenCredit = watchFieldsDocument.reduce((total, credit) =>  total + +credit.ownerCredit, +watchOpenBalance.ownerCredit)
    let sumCloseDebit = 0
    let sumCloseCredit = 0
    if(sumOpenDebit > sumOpenCredit) {
      sumCloseDebit = sumOpenDebit - sumOpenCredit
    } else {
      sumCloseCredit = sumOpenCredit - sumOpenDebit
    }
    setValue(`verificationActContracts.${index}.totalBalance.ownerDebit`, sumOpenDebit)
    setValue(`verificationActContracts.${index}.totalBalance.ownerCredit`, sumOpenCredit)
    setValue(`verificationActContracts.${index}.totalBalance.partnerDebit`, sumOpenCredit)
    setValue(`verificationActContracts.${index}.totalBalance.partnerCredit`, sumOpenDebit)
    setValue(`verificationActContracts.${index}.closeBalance.ownerDebit`, sumCloseDebit)
    setValue(`verificationActContracts.${index}.closeBalance.ownerCredit`, sumCloseCredit)
    setValue(`verificationActContracts.${index}.closeBalance.partnerDebit`, sumCloseCredit)
    setValue(`verificationActContracts.${index}.closeBalance.partnerCredit`, sumCloseDebit)
    onChange()
  }

  useEffect(() => {
    onChangeSumHandler()
  }, [watchFieldsDocument]);

  return (
    <Card className="mt-3">
      <Card.Body>
        <Row>
          <Col sm="12">
            <Table bordered responsive>
              <tbody>
                <tr>
                  <td className="text-center p-1">
                    <Controller
                      control={control}
                      name={`verificationActContracts.${index}.contractDate`}
                      rules={{
                          required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <DatePicker
                          onChange={field.onChange}
                          dateFormat={dateFormat}
                          selected={field.value}
                          wrapperClassName={errors?.contractDate?.message && "is-invalid"}
                          className={`${errors?.contractDate?.message && "is-invalid"} form-control p-2`}
                          placeholderText={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.contract.date")}
                        />
                      )}
                    />
                  </td>
                  <td className="text-center p-1">
                    <Form.Control
                      {...register(`verificationActContracts.${index}.contractNo`)}
                      placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.contract.number")}
                      className="p-2"
                    />
                  </td>
                  <td colSpan="6" className="table-primary"></td>
                  <td colSpan="1" className="table-secondary d-flex px-0">
                    <IconButton
                      onClick={appendContract}
                      variant="falcon-primary"
                      icon="plus"
                      size="sm"
                      className="mx-1 px-2"
                    />
                    <IconButton
                      onClick={() => removeContract(index)}
                      disabled={getValues('verificationActContracts').length <= 1}
                      variant="falcon-danger"
                      icon="trash"
                      size="sm"
                      className="mx-1 px-2"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="9">
                    <hr className="w-100 my-0" />
                  </td>
                </tr>
                <tr>
                  <td className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.open_balance.title")}</td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.openBalance.ownerDebit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          onChange={({target: {rawValue}}) => {
                            let value = +(parseFloat(rawValue)?.toFixed(2)) || 0
                            field.onChange(value)
                            setPartnerOpenCredit(value)
                          }}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          value={0}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                        />
                      )}
                    />
                  </td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.openBalance.ownerCredit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          onChange={({target: {rawValue}}) => {
                            let value = +(parseFloat(rawValue)?.toFixed(2)) || 0
                            field.onChange(value)
                            setPartnerOpenDebit(value)
                          }}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          value={0}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                        />
                      )}
                    />
                  </td>
                  <td className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.open_balance.title")}</td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.openBalance.partnerDebit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.openBalance.partnerCredit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="table-secondary"></td>
                </tr>
                {fieldsDocument.map((document, indexDoc) => (
                  <tr key={document.id}>
                    <VerificationActContractDocument indexContract={index} index={indexDoc} appendDocument={addFieldDocument} removeDocument={removeFieldDocument} onChange={onChangeSumHandler} />
                  </tr>
                ))}
                <tr>
                  <td colSpan="9">
                    <hr className="w-100 my-0" />
                  </td>
                </tr>
                <tr>
                  <td className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.total_balance.title")}</td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.totalBalance.ownerDebit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.totalBalance.ownerCredit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.total_balance.title")}</td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.totalBalance.partnerDebit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.totalBalance.partnerCredit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="table-secondary"></td>
                </tr>
                <tr>
                  <td className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.close_balance_by_contract.title")}</td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.closeBalance.ownerDebit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.debit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.closeBalance.ownerCredit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.owner.credit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="text-center table-primary" colSpan="2">{t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.close_balance_by_contract.title")}</td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.closeBalance.partnerDebit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.debit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="text-center p-1">
                    <Controller 
                      name={`verificationActContracts.${index}.closeBalance.partnerCredit`}
                      control={control}
                      rules={{
                        required: "edi.common.forms.validations.is_required"
                      }}
                      render={({field}) => (
                        <Cleave
                          {...field}
                          options={{
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 2
                          }}
                          placeholder={t(lang, "roaming.verification_act.add.verification_act.contracts.table.body.partner.credit")}
                          className={`${errors[index]?.count && 'is-invalid'} p-2 form-control`}
                          readOnly
                        />
                      )}
                    />
                  </td>
                  <td className="table-secondary"></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default VerificationActContracts;
