import {createSlice} from "@reduxjs/toolkit";
import AttorneyService from "../../../../services/attorneyService";
import {createPkcs7WithTimestamp} from "../eimzo/eimzoReducer";
import {
    ACCEPT_ATTORNEY_FAILED,
    ACCEPT_ATTORNEY_REQUESTED,
    ACCEPT_ATTORNEY_SUCCESS,
    ADD_ATTORNEY_FAILED,
    ADD_ATTORNEY_REQUESTED,
    ADD_ATTORNEY_SUCCESS,
    CANCEL_ATTORNEY_FAILED,
    CANCEL_ATTORNEY_REQUESTED,
    CANCEL_ATTORNEY_SUCCESS,
    DECLINE_ATTORNEY_FAILED,
    DECLINE_ATTORNEY_REQUESTED,
    DECLINE_ATTORNEY_SUCCESS,
    EDIT_ATTORNEY_FAILED,
    EDIT_ATTORNEY_REQUESTED,
    EDIT_ATTORNEY_SUCCESS,
    SIGN_ATTORNEY_FAILED,
    SIGN_ATTORNEY_REQUESTED,
    SIGN_ATTORNEY_SUCCESS
} from "../../../eventbus/attorneyEvents";
import EventBus from "../../../eventbus/EventBus";
import {Translate} from "../main/mainReducer";

const t = Translate;

export const STORAGE_ATTORNEY = "AttorneyDataTableSettings"

export const attorneySlice = createSlice({
    name: 'attorney',
    initialState: {
        attorneys: [],
        filterOptions: {},
        paginationOptions: {page: 1, limit: 10},
        countAttorneys: 0,
        isLoading: false,
        attorneyDataTableSettings: {}
    },
    reducers: {
        updateAttorney: (state, action) => {
            state.attorneys = action.payload
        },
        updateCountAttorneys: (state, action) => {
            state.countAttorneys = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updatePaginationOptions: (state, action) => {
            state.paginationOptions = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        loadAttorneyDataTableColumn: (state) => {
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_ATTORNEY))
            if (!storageColumn) {
                storageColumn = {
                    index: true,
                    number: true,
                    full_name: true,
                    contractor: true,
                    position: true,
                    employees_inn: true,
                    button_action: true
                };
                localStorage.setItem(STORAGE_ATTORNEY, JSON.stringify(storageColumn))
            }
            state.attorneyDataTableSettings = storageColumn
        },

        changeAttorneyDataTableColumn: (state, action) => {
            state.attorneyDataTableSettings = action.payload
            localStorage.setItem(STORAGE_ATTORNEY, JSON.stringify(state.attorneyDataTableSettings))
        },
    },
})

export const loadAttorneysAsync = (filters, pagination) => (dispatch) => {
    const {page, limit} = pagination;
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    dispatch(attorneySlice.actions.setLoading(true));
    AttorneyService.getAttorneys(params)
        .then(response => {
            dispatch(attorneySlice.actions.updateAttorney(response.data))
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => dispatch(attorneySlice.actions.setLoading(false)))
}

export const loadCountAttorneysAsync = filters => (dispatch) => {
    const params = {...filters};

    AttorneyService.getCountAttorneys(params)
        .then(response => {
            dispatch(attorneySlice.actions.updateCountAttorneys(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadAttorneyAsync = id => {
    return new Promise((resolve, reject) => {
        AttorneyService.getAttorney(id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    })
}

export const changeFilterOptionsAsync = filters => dispatch => dispatch(attorneySlice.actions.updateFilterOptions(filters));
export const changePaginationOptionsAsync = pagination => dispatch => dispatch(attorneySlice.actions.updatePaginationOptions(pagination));

export const addAttorneyAsync = (payload) => {
    EventBus.dispatch(ADD_ATTORNEY_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        AttorneyService.addAttorney(payload)
            .then(response => {
                EventBus.dispatch(ADD_ATTORNEY_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_ATTORNEY_FAILED, error)
                reject(error);
            })
    });
}

export const editAttorneyAsync = (id, payload) => {
    EventBus.dispatch(EDIT_ATTORNEY_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        AttorneyService.editAttorney(id, payload)
            .then((response) => {
                EventBus.dispatch(EDIT_ATTORNEY_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(EDIT_ATTORNEY_FAILED, error.message);
                reject(error);
            });
    });
};

export const deleteAttorneyAsync = (id) => (dispatch) => {
    AttorneyService.deleteAttorney(id)
        .then(() => {
            dispatch(loadAttorneysAsync())
        })
        .catch(error => {
            console.log(error);
        })
}


export const signAttorneyAsync = ({id, certificate, lang}) => {
    let errorMessage;
    EventBus.dispatch(SIGN_ATTORNEY_REQUESTED);

    return new Promise((resolve, reject) => {
        AttorneyService.getSignHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        AttorneyService.signAttorney({id: id, signature: signature})
                            .then(response => {
                                EventBus.dispatch(SIGN_ATTORNEY_SUCCESS, response.data);
                                resolve(response.data);
                            })
                            .catch(error => {
                                errorMessage = error.response.data;
                                EventBus.dispatch(SIGN_ATTORNEY_FAILED, errorMessage);
                                reject(errorMessage);
                            })
                    })
                    .catch(() => {
                        errorMessage = t(lang, "roaming.attorney.alert.error.createPkcs7Async");
                        EventBus.dispatch(SIGN_ATTORNEY_FAILED, errorMessage);
                        reject(errorMessage);
                    })


            })
            .catch(error => {
                errorMessage = error.response.data;
                EventBus.dispatch(SIGN_ATTORNEY_FAILED, errorMessage);
                reject(errorMessage);
            })
    });
};
export const cancelAttorneyAsync = ({id, certificate, lang}) => {
    let errorMessage;
    EventBus.dispatch(CANCEL_ATTORNEY_REQUESTED);

    return new Promise((resolve, reject) => {
        AttorneyService.getCancelHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        AttorneyService.cancelAttorney({id: id, signature: signature})
                            .then(response => {
                                EventBus.dispatch(CANCEL_ATTORNEY_SUCCESS, response.data);
                                resolve(response.data);
                            })
                            .catch(error => {
                                errorMessage = error.response.data;
                                EventBus.dispatch(CANCEL_ATTORNEY_FAILED, errorMessage);
                                reject(errorMessage);
                            })
                    })
                    .catch(() => {
                        errorMessage = t(lang, "roaming.attorney.alert.error.createPkcs7Async");
                        EventBus.dispatch(CANCEL_ATTORNEY_FAILED, errorMessage);
                        reject(errorMessage);
                    })
            })
            .catch(error => {
                errorMessage = error.response.data;
                EventBus.dispatch(CANCEL_ATTORNEY_FAILED, errorMessage);
                reject(errorMessage);
            })
    });
};
export const acceptAttorneyAsync = ({id, certificate, lang}) => {
    let errorMessage;
    EventBus.dispatch(ACCEPT_ATTORNEY_REQUESTED);

    return new Promise((resolve, reject) => {
        AttorneyService.getAcceptHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        AttorneyService.acceptAttorney({id: id, signature: signature})
                            .then(response => {
                                EventBus.dispatch(ACCEPT_ATTORNEY_SUCCESS, response.data);
                                resolve(response.data);
                            })
                            .catch(error => {
                                errorMessage = error.response.data;
                                EventBus.dispatch(ACCEPT_ATTORNEY_FAILED, errorMessage);
                                reject(errorMessage);
                            })
                    })
                    .catch(() => {
                        errorMessage = t(lang, "roaming.attorney.alert.error.createPkcs7Async");
                        EventBus.dispatch(ACCEPT_ATTORNEY_FAILED, errorMessage);
                        reject(errorMessage);
                    })
            })
            .catch(error => {
                errorMessage = error.response.data;
                EventBus.dispatch(ACCEPT_ATTORNEY_FAILED, errorMessage);
                reject(errorMessage);
            })
    });
};
export const declineAttorneyAsync = ({id, certificate, lang}) => {
    let errorMessage;
    EventBus.dispatch(DECLINE_ATTORNEY_REQUESTED);

    return new Promise((resolve, reject) => {
        AttorneyService.getDeclineHashCode(id)
            .then(({data: hashCode}) => {
                createPkcs7WithTimestamp(hashCode, certificate.keyId)
                    .then(({pkcs7: signature}) => {
                        AttorneyService.declineAttorney({id: id, signature: signature})
                            .then(response => {
                                EventBus.dispatch(DECLINE_ATTORNEY_SUCCESS, response.data);
                                resolve(response.data);
                            })
                            .catch(error => {
                                errorMessage = error.response.data;
                                EventBus.dispatch(DECLINE_ATTORNEY_FAILED, errorMessage);
                                reject(errorMessage);
                            })
                    })
                    .catch(() => {
                        errorMessage = t(lang, "roaming.attorney.alert.error.createPkcs7Async");
                        EventBus.dispatch(DECLINE_ATTORNEY_FAILED, errorMessage);
                        reject(errorMessage);
                    })
            })
            .catch(error => {
                errorMessage = error.response.data;
                EventBus.dispatch(DECLINE_ATTORNEY_FAILED, errorMessage);
                reject(errorMessage);
            })
    });
};


export const printAttorneyAsync = (id) => {
    return new Promise((resolve, reject) => {
        AttorneyService.printAttorney(id)
            .then(response => resolve(response.data))
            .catch(error => {
                console.log(error);
                reject(error)
            })
    })
}

export const selectAttorneyDataTable = (state) => state.attorney.attorneyDataTableSettings

export const {loadAttorneyDataTableColumn} = attorneySlice.actions
export const {changeAttorneyDataTableColumn} = attorneySlice.actions

export const selectAttorneys = (state) => state.attorney.attorneys
export const selectCountAttorneys = (state) => state.attorney.countAttorneys
export const selectFilterOptions = (state) => state.attorney.filterOptions;
export const selectPaginationOptions = state => state.attorney.paginationOptions;
export const selectLoading = state => state.attorney.isLoading;
export default attorneySlice.reducer;
