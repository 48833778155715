export const REGISTER_RETURN_SHIPMENT_REQUESTED = 'REGISTER_RETURN_SHIPMENT_REQUESTED'
export const REGISTER_RETURN_SHIPMENT_SUCCEED = 'REGISTER_RETURN_SHIPMENT_SUCCEED'
export const REGISTER_RETURN_SHIPMENT_FAILED = 'REGISTER_RETURN_SHIPMENT_FAILED'
export const DELETE_RETURN_SHIPMENT_REQUESTED = 'DELETE_RETURN_SHIPMENT_REQUESTED'
export const DELETE_RETURN_SHIPMENT_SUCCESS = 'DELETE_RETURN_SHIPMENT_SUCCESS'
export const DELETE_RETURN_SHIPMENT_FAILED = 'DELETE_RETURN_SHIPMENT_FAILED'
export const CUSTOMER_APPROVE_RETURN_SHIPMENT_REQUESTED = "CUSTOMER_APPROVE_RETURN_SHIPMENT_REQUESTED";
export const CUSTOMER_APPROVE_RETURN_SHIPMENT_SUCCESS = "CUSTOMER_APPROVE_RETURN_SHIPMENT_SUCCESS";
export const CUSTOMER_APPROVE_RETURN_SHIPMENT_FAILED = "CUSTOMER_APPROVE_RETURN_SHIPMENT_FAILED";
export const EXECUTOR_APPROVE_RETURN_SHIPMENT_REQUESTED = "EXECUTOR_APPROVE_RETURN_SHIPMENT_REQUESTED";
export const EXECUTOR_APPROVE_RETURN_SHIPMENT_SUCCESS = "EXECUTOR_APPROVE_RETURN_SHIPMENT_SUCCESS";
export const EXECUTOR_APPROVE_RETURN_SHIPMENT_FAILED = "EXECUTOR_APPROVE_RETURN_SHIPMENT_FAILED";