export const WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_DELETE_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_DELETE_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_DELETE_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_DELETE_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_DELETE_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_DELETE_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_NOTE_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_NOTE_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_NOTE_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_NOTE_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_NOTE_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_NOTE_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_DATE_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_DATE_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_DATE_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_DATE_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_FAILED"

export const WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_REQUESTED = "WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_REQUESTED"
export const WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_SUCCESS = "WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_SUCCESS"
export const WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_FAILED = "WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_FAILED"