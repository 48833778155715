import React from 'react';
import CommonDeleteModal from "../../../../common/CommonDeleteModal";

const defaultTextColor = '#1c4f93';
const defaultBackgroundColor = '#d5e5fa';

const CommonLabelBadge = ({id, name, textColor, backgroundColor, cancellable = false, onCancel, ...props}) => {
    return (
        <div className={'d-inline-block px-2 py-0 rounded-2 mx-1'} style={{backgroundColor: backgroundColor ? backgroundColor : defaultBackgroundColor}} {...props}>
            <span className={'fs--1 fw-semi-bold'} style={{color: textColor ? textColor : defaultTextColor}}>{name}</span>
            {cancellable && (
                <CommonDeleteModal onDelete={() => onCancel(id)}>
                    <span className={'ms-1 cursor-pointer fw-semi-bold'}>x</span>
                </CommonDeleteModal>
            )}
        </div>
    );
};

export default CommonLabelBadge;