// board
export const REGISTER_BOARD_REQUEST = "REGISTER_BOARD_REQUEST"
export const REGISTER_BOARD_SUCCESS = "REGISTER_BOARD_SUCCESS"
export const REGISTER_BOARD_FAIL = "REGISTER_BOARD_FAIL"

export const CHANGE_BOARD_NAME_REQUEST = "CHANGE_BOARD_NAME_REQUEST"
export const CHANGE_BOARD_NAME_SUCCESS = "CHANGE_BOARD_NAME_SUCCESS"
export const CHANGE_BOARD_NAME_FAIL = "CHANGE_BOARD_NAME_FAIL"

export const DELETE_BOARD_REQUEST = "DELETE_BOARD_REQUEST"
export const DELETE_BOARD_SUCCESS = "DELETE_BOARD_SUCCESS"
export const DELETE_BOARD_FAIL = "DELETE_BOARD_FAIL"

export const STATUS_REGISTER_SUCCESS = "STATUS_REGISTER_SUCCESS"
export const STATUS_REGISTER_FAIL = "STATUS_REGISTER_FAIL"

export const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS"
export const STATUS_UPDATE_FAIL = "STATUS_UPDATE_FAIL"

export const STATUS_DELETE_REQUEST = "STATUS_DELETE_REQUEST"
export const STATUS_DELETE_SUCCESS = "STATUS_DELETE_SUCCESS"
export const STATUS_DELETE_FAIL = "STATUS_DELETE_FAIL"

export const DRAG_AND_DROP_REQUEST = "DRAG_AND_DROP_REQUEST"
export const DRAG_AND_DROP_SUCCESS = "DRAG_AND_DROP_SUCCESS"
export const DRAG_AND_DROP_FAIL = "DRAG_AND_DROP_FAIL"

export const DRAG_AND_DROP_TASK_REQUEST = "DRAG_AND_DROP_TASK_REQUEST"
export const DRAG_AND_DROP_TASK_SUCCESS = "DRAG_AND_DROP_TASK_SUCCESS"
export const DRAG_AND_DROP_TASK_FAIL = "DRAG_AND_DROP_TASK_FAIL"

export const EDIT_CONTRACOR_DATE_SUCCESS = "EDIT_CONTRACOR_DATE_SUCCESS"

export const MEMBER_REGISTER_REQUEST = "MEMBER_REGISTER_REQUEST"
export const MEMBER_REGISTER_SUCCESS = "MEMBER_REGISTER_SUCCESS"
export const MEMBER_REGISTER_FAIL = "MEMBER_REGISTER_FAIL"

export const MEMBER_UPDATE_REQUEST = "MEMBER_UPDATE_REQUEST"
export const MEMBER_UPDATE_SUCCESS = "MEMBER_UPDATE_SUCCESS"
export const MEMBER_UPDATE_FAIL = "MEMBER_UPDATE_FAIL"

export const MEMBER_DELETE_SUCCESS = "MEMBER_DELETE_SUCCESS"
export const MEMBER_DELETE_FAIL = "MEMBER_DELETE_FAIL"

export const ITEM_REGISTER_SUCCESS = "ITEM_REGISTER_SUCCESS"
export const ITEM_REGISTER_FAIL = "ITEM_REGISTER_FAIL"

export const ITEM_EDIT_SUCCESS = "ITEM_EDIT_SUCCESS"
export const ITEM_EDIT_FAIL = "ITEM_EDIT_FAIL"

export const ITEM_DELETE_SUCCESS = "ITEM_DELETE_SUCCESS"
export const ITEM_DELETE_FAIL = "ITEM_DELETE_FAIL"

export const ITEM_REGISTER_ASSIGNER_SUCCESS = "ITEM_REGISTER_ASSIGNER_SUCCESS"
export const ITEM_REGISTER_ASSIGNER_FAIL = "ITEM_REGISTER_ASSIGNER_FAIL"

export const ITEM_UPDATE_ASSIGNER_SUCCESS = "ITEM_UPDATE_ASSIGNER_SUCCESS"
export const ITEM_UPDATE_ASSIGNER_FAIL = "ITEM_UPDATE_ASSIGNER_FAIL"

export const ITEM_DELETE_ASSIGNER_SUCCESS = "ITEM_DELETE_ASSIGNER_SUCCESS"
export const ITEM_DELETE_ASSIGNER_FAIL = "ITEM_DELETE_ASSIGNER_FAIL"


export const ITEM_REGISTER_COMMENT_SUCCESS = "ITEM_REGISTER_COMMENT_SUCCESS"
export const ITEM_REGISTER_COMMENT_FAIL = "ITEM_REGISTER_COMMENT_FAIL"

export const ITEM_UPDATE_COMMENT_SUCCESS = "ITEM_UPDATE_COMMENT_SUCCESS"
export const ITEM_UPDATE_COMMENT_FAIL = "ITEM_UPDATE_COMMENT_FAIL"

export const ITEM_DELETE_COMMENT_SUCCESS = "ITEM_DELETE_COMMENT_SUCCESS"
export const ITEM_DELETE_COMMENT_FAIL = "ITEM_DELETE_COMMENT_FAIL"

export const ITEM_REGISTER_LABEL_SUCCESS = "ITEM_REGISTER_LABEL_SUCCESS"
export const ITEM_REGISTER_LABEL_FAIL = "ITEM_REGISTER_LABEL_FAIL"

export const ITEM_UPDATE_LABEL_SUCCESS = "ITEM_UPDATE_LABEL_SUCCESS"
export const ITEM_UPDATE_LABEL_FAIL = "ITEM_UPDATE_LABEL_FAIL"

export const ITEM_DELETE_LABEL_SUCCESS = "ITEM_DELETE_LABEL_SUCCESS"
export const ITEM_DELETE_LABEL_FAIL = "ITEM_DELETE_LABEL_FAIL"

export const ITEM_CHANGE_STATUS_SUCCESS = "ITEM_CHANGE_STATUS_SUCCESS"
export const ITEM_CHANGE_STATUS_FAIL = "ITEM_CHANGE_STATUS_FAIL"
