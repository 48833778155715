import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faRemove} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import EventBus from "../../../../app/eventbus/EventBus";
import {EDIT_ITEM_FAILED, EDIT_ITEM_REQUESTED, EDIT_ITEM_SUCCESS} from "../../../../app/eventbus/itemEvents";
import {editItemAsync} from "../../../../app/store/reducers/item/itemReducer";

const RemoveFromFavoriteButton = ({item}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);

    if (!item.is_favorite) return null;

    const updateItem = (item) => {
        const itemJson = {
            name: item.name,
            description: item.description,
            is_state_controlled: item.is_state_controlled,
            is_favorite: false,
            sku: item.sku || null,
            code: item.code || null,
            barcodes: item.barcodes,
            measurement: item.measurement.toString(),
            category_id: item.category ? item.category.id : null,
            description_attributes: item.description_attributes.map((attr) => ({
                key: attr.key,
                value: attr.value
            })),
            package_measurements: item.package_measurements.map((pck) => ({
                name: pck.name,
                quantity: +pck.quantity
            })),
            tax: item.tax ? {
                tax_rate: item.tax.tax_rate,
                catalog: item.tax.catalog ? {
                    code: item.tax.catalog.code,
                    name: item.tax.catalog.name,
                    package: item.tax.catalog.package ? {
                        code: item.tax.catalog.package.code.toString(),
                        name: item.tax.catalog.package.name,
                    } : null,
                } : null,
                benefit: item.tax.benefit ? {
                    id: +item.tax.benefit.id,
                    name: item.tax.benefit.name,
                    type: +item.tax.benefit.type,
                } : null,
                measurement: item.tax.measurement ? item.tax.measurement : null,
            } : null,
            images: item.images.map((image) => ({
                id: image.id,
                name: image.name,
                content: image.content
            }))
        }


        setLoading(true);
        EventBus.dispatch(EDIT_ITEM_REQUESTED);
        editItemAsync(item.id, itemJson)
            .then(() => {
                setLoading(false);
                EventBus.dispatch(EDIT_ITEM_SUCCESS, {itemId: item.id});
            })
            .catch(() => {
                setLoading(false);
                EventBus.dispatch(EDIT_ITEM_FAILED);
            })
    }


    return (
        <IconButton variant="falcon-danger"
                    size="sm"
                    icon={faRemove}
                    disabled={loading}
                    onClick={() => updateItem(item)}
        >
            {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
            {t(lang, 'items.common.remove_from_favorite')}
        </IconButton>
    );
};

export default RemoveFromFavoriteButton;