import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {Form} from "react-bootstrap";
import Cleave from "cleave.js/react";
import classNames from "classnames";

const NotificationForm = () => {
    const {formState: {errors}, control} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Fragment>
            <Form.Group className="text-start mb-3">
                <Form.Label>{t(lang, "notification.table.register.form_title")}:</Form.Label>
                <Controller
                    control={control}
                    name='phoneNumber'
                    render={({field}) => (
                        <Cleave
                            placeholder="998 XX XXX XX XX"
                            options={{
                                delimiters: [' ', ' ', ' ', ' ', ' '],
                                blocks: [3, 2, 3, 2, 2],
                                numericOnly: true
                            }}
                            value={field.value}
                            onChange={event => field.onChange(event.target.rawValue)}
                            className={classNames('form-control', {
                                'is-invalid': errors?.phoneNumber,
                                'is-valid': Object.keys(errors).length > 0 && !errors?.phoneNumber
                            })}
                        />
                    )}
                    rules={{
                        required: t(lang, "notification.common.forms.validations.is_required"),
                        minLength: {value: 12, message: t(lang, "notification.common.forms.validations.min_length")},
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.phoneNumber?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </Fragment>

    )
};

export default NotificationForm;