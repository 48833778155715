import React, {Fragment, useState} from 'react';
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {deleteBoardColumn} from "../../../app/store/reducers/kanban/kanbanReducer";
import KanbanModal from "../modal/kanban/KanbanModal";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import PropTypes from "prop-types";
import {useParams} from "react-router";
import AddAnotherForm from "./AddAnotherForm";

const KanbanColumnHeader = ({columnId, title, itemCount, setShowForm, columnType}) => {

    const {id} = useParams();
    const lang = useSelector(selectLang);
    const t = Translate;

    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleDeleteBoardColumn = () => {
        dispatch(deleteBoardColumn(id, {board_column_id: columnId}))
    };

    return (
        <>
            <div className="kanban-column-header">
                <h5 className="fs-0 mb-0">
                    {t(lang, title)} <span className="text-500">({itemCount})</span>
                </h5>
                <Dropdown align="end" className="font-sans-serif btn-reveal-trigger">
                    <Dropdown.Toggle variant="reveal" size="sm" className="py-0 px-2">
                        <FontAwesomeIcon icon="ellipsis-h" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-0">
                        <Dropdown.Item onClick={()=>setShow(true)}>
                            {t(lang, 'task.board.common.add')}
                        </Dropdown.Item>
                        {!columnType && <Fragment>
                            <Dropdown.Item className={'text-warning'}>
                                <AddAnotherForm defaultValue={title} columnId={columnId}>
                                    <div onClick={() => setShowForm(true)}>
                                        {t(lang, 'task.board.common.edit')}
                                    </div>
                                </AddAnotherForm>

                            </Dropdown.Item>
                            <Dropdown.Item className={'text-danger'} onClick={() => handleDeleteBoardColumn()}>
                                {t(lang, 'task.board.common.delete')}
                            </Dropdown.Item>
                        </Fragment>}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <KanbanModal show={show} setShow={setShow} columnId={columnId} />
        </>

    );
};



export default KanbanColumnHeader;
