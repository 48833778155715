import React, {useState} from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Col, Modal, Row, Table} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import IconButton from "../../../common/IconButton";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";
import {
    FindOperationType,
    Purchase,
    Refund,
    Return,
    Revision,
    Sale,
    TransferIn,
    TransferOut,
    WriteOff
} from "../../../../enum/OperationTypes";
import numeral from "numeral";
import SoftBadge from "../../../common/SoftBadge";

const OperationsItemsReportSummaryModal = ({report}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <React.Fragment>
            <IconButton variant="falcon-primary"
                        icon={faCalculator}
                        size="sm"
                        className="mx-1"
                        onClick={handleShow}
            >
                {t(lang, 'items.common.total_amount_information')}
            </IconButton>
            {show &&
                <Modal show={true}
                       centered={true}
                       onHide={handleClose}
                       size="lg"
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, "items.common.total_amount_information")}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Table responsive={true}
                                       bordered={true}
                                >
                                    <thead>
                                    <tr>
                                        <th className="p-1 text-center align-middle">{t(lang, "main.billing.charge.datatable.type.of.transaction")}</th>
                                        <th className="p-1 text-end align-middle">{t(lang, "crm.common.quantity")}</th>
                                        <th className="p-1 text-end align-middle">{t(lang, "crm.common.datatable.total_amount")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <SoftBadge bg={FindOperationType(Purchase)?.variant}
                                                       className="w-100"
                                            >
                                                {t(lang, FindOperationType(Purchase)?.label)}
                                            </SoftBadge>
                                        </td>
                                        <td className="p-1 text-end align-middle h6">{report.purchases_count}</td>
                                        <td>
                                            {!!report.purchases_net_price.length &&
                                                <SoftBadge bg={FindOperationType(Purchase)?.variant}
                                                           className="w-100 text-wrap d-flex flex-column gap-2"
                                                >
                                                    {report.purchases_net_price.map((price) => (
                                                        <div key={price.currency.id}
                                                             className="text-end"
                                                        >
                                                            {`${numeral.formats[numberFormat].format(price.amount)} ${price.currency.name}`}
                                                        </div>
                                                    ))}
                                                </SoftBadge>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SoftBadge bg={FindOperationType(Refund)?.variant}
                                                       className="w-100"
                                            >
                                                {t(lang, FindOperationType(Refund)?.label)}
                                            </SoftBadge>
                                        </td>
                                        <td className="p-1 text-end align-middle h6">{report.refunds_count}</td>
                                        <td>
                                            {!!report.refunds_net_price.length &&
                                                <SoftBadge bg={FindOperationType(Refund)?.variant}
                                                           className="w-100 text-wrap d-flex flex-column gap-2"
                                                >
                                                    {report.refunds_net_price.map((price) => (
                                                        <div key={price.currency.id}
                                                             className="text-end"
                                                        >
                                                            {`${numeral.formats[numberFormat].format(price.amount)} ${price.currency.name}`}
                                                        </div>
                                                    ))}
                                                </SoftBadge>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SoftBadge bg={FindOperationType(Return)?.variant}
                                                       className="w-100"
                                            >
                                                {t(lang, FindOperationType(Return)?.label)}
                                            </SoftBadge>
                                        </td>
                                        <td className="p-1 text-end align-middle h6">{report.returns_count}</td>
                                        <td>
                                            {!!report.returns_net_price.length &&
                                                <SoftBadge bg={FindOperationType(Return)?.variant}
                                                           className="w-100 text-wrap d-flex flex-column gap-2"
                                                >
                                                    {report.returns_net_price.map((price) => (
                                                        <div key={price.currency.id}
                                                             className="text-end"
                                                        >
                                                            {`${numeral.formats[numberFormat].format(price.amount)} ${price.currency.name}`}
                                                        </div>
                                                    ))}
                                                </SoftBadge>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SoftBadge bg={FindOperationType(Sale)?.variant}
                                                       className="w-100"
                                            >
                                                {t(lang, FindOperationType(Sale)?.label)}
                                            </SoftBadge>
                                        </td>
                                        <td className="p-1 text-end align-middle h6">{report.sales_count}</td>
                                        <td>
                                            {!!report.sales_net_price.length &&
                                                <SoftBadge bg={FindOperationType(Sale)?.variant}
                                                           className="w-100 text-wrap d-flex flex-column gap-2"
                                                >
                                                    {report.sales_net_price.map((price) => (
                                                        <div key={price.currency.id}
                                                             className="text-end"
                                                        >
                                                            {`${numeral.formats[numberFormat].format(price.amount)} ${price.currency.name}`}
                                                        </div>
                                                    ))}
                                                </SoftBadge>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SoftBadge bg={FindOperationType(Revision)?.variant}
                                                       className="w-100"
                                            >
                                                {t(lang, FindOperationType(Revision)?.label)}
                                            </SoftBadge>
                                        </td>
                                        <td className="p-1 text-end align-middle h6">{report.revisions_count}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SoftBadge bg={FindOperationType(TransferIn)?.variant}
                                                       className="w-100"
                                            >
                                                {t(lang, FindOperationType(TransferIn)?.label)}
                                            </SoftBadge>
                                        </td>
                                        <td className="p-1 text-end align-middle h6">{report.transfer_ins_count}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SoftBadge bg={FindOperationType(TransferOut)?.variant}
                                                       className="w-100"
                                            >
                                                {t(lang, FindOperationType(TransferOut)?.label)}
                                            </SoftBadge>
                                        </td>
                                        <td className="p-1 text-end align-middle h6">{report.transfer_outs_count}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SoftBadge bg={FindOperationType(WriteOff)?.variant}
                                                       className="w-100"
                                            >
                                                {t(lang, FindOperationType(WriteOff)?.label)}
                                            </SoftBadge>
                                        </td>
                                        <td className="p-1 text-end align-middle h6">{report.write_offs_count}</td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={handleClose}
                        >
                            {t(lang, "items.common.close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </React.Fragment>
    );
};

export default OperationsItemsReportSummaryModal;