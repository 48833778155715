import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {DELETE_ITEM_FAILED, DELETE_ITEM_REQUESTED, DELETE_ITEM_SUCCESS} from "../../../../app/eventbus/itemEvents";
import {deleteItemAsync} from "../../../../app/store/reducers/item/itemReducer";
import {Controller, FormProvider, useForm} from "react-hook-form";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import {ErrorMessage} from "@hookform/error-message";
import {getRandomNumber} from "../../../../helpers/utils";

const withDeleteItem = (WrappedComponent) => {
    return (props) => {
        const t = Translate;
        const lang = useSelector(selectLang);
        const form = useForm();

        const [item, setItem] = useState(null);
        const [show, setShow] = useState(false);
        const [loading, setLoading] = useState(false);
        const [code] = useState(getRandomNumber(100000, 999999))

        const handleShow = (item) => {
            setItem(item);
            setShow(true);
        }

        const handleClose = () => {
            setItem(null);
            setShow(false);
        }

        const onDeleteItem = () => {
            setLoading(true);

            EventBus.dispatch(DELETE_ITEM_REQUESTED, item);
            deleteItemAsync(item.id)
                .then(() => {
                    EventBus.dispatch(DELETE_ITEM_SUCCESS, {itemId: item.id});
                    setLoading(false);
                    handleClose();
                })
                .catch(() => {
                    EventBus.dispatch(DELETE_ITEM_FAILED, item);
                    setLoading(false);
                })
        }

        return (
            <React.Fragment>
                <WrappedComponent {...props} handleShow={handleShow}/>
                <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header>
                        <Modal.Title>
                            {t(lang, "items.common.delete_item")}
                        </Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <FormProvider {...form}>
                            <Form id={'with-delete-item'} onSubmit={form.handleSubmit(onDeleteItem)}>
                                <Form.Group>
                                    <Form.Label>
                                        <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                        <span className="ms-1 text-primary">{code}</span>
                                    </Form.Label>
                                    <Controller name={'code'}
                                                control={form.control}
                                                rules={{
                                                    required: t(lang, 'items.common.validations.is_required'),
                                                    validate: (value) => {
                                                        if (+value === code)
                                                            return true;
                                                        return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                                    }
                                                }}
                                                render={({field}) => (
                                                    <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                            value={field.value}
                                                            htmlRef={field.ref}
                                                            placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                            className={classNames('form-control', {
                                                                'is-invalid': form.formState.errors?.code
                                                            })}
                                                            options={{
                                                                numeral: true,
                                                                delimiter: ' ',
                                                                numeralDecimalScale: 3
                                                            }}
                                                    />
                                                )}
                                    />
                                    <ErrorMessage name={'code'}
                                                  errors={form.formState.errors}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </Form.Group>
                            </Form>
                        </FormProvider>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "items.common.cancel")}
                        </Button>
                        <Button type="submit" form={'with-delete-item'} variant="danger" disabled={loading}>
                            {loading &&
                                <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                            }
                            {t(lang, "items.common.delete")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    };
};

export default withDeleteItem;
