import {useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {seenBankPaymentsErrorBilling} from "../../../../app/store/reducers/billing/billingReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const SeenBankPaymentsErrorModal = ({show, setShow, id}) => {
    const [loading, setLoading] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const lang = useSelector(selectLang)
    const t = Translate

    const approve = () => {
        setLoading(true)
        seenBankPaymentsErrorBilling(id)
            .then(() => setShow(false))
            .catch(e => new Error(e))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "admin.organizations.datatable.item.inn.modal")}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "main.common.modal.close")}
                    </Button>
                    <Button onClick={approve} disabled={loading} variant="primary">
                        {loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        {t(lang, "main.common.modal.approve")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SeenBankPaymentsErrorModal;