import {createSlice} from "@reduxjs/toolkit";
import ContractorService from "../../../../../services/contractorService";
import EventBus from "../../../../eventbus/EventBus";
import {ACCOUNT_BINDING_SUCCESS} from "../../../../eventbus/bindContractorToAccountEvent";

const bindContractorToAccountSlice = createSlice({
    name: 'connectingContractor',
    initialState: {
        data: [],
    },
    reducers:{
        getAccount: (state,action) => {
            state.data = action.payload
        }
    }
})

export const selectBindContractor = (state) => state.bindContractorToAccount.data

export const getAccount = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ContractorService.getAccount()
            .then((res) => {
                dispatch(bindContractorToAccountSlice.actions.getAccount(res.data))
                resolve(res.data)
            })
            .catch((e) => reject(e))
    })
}

export const getAccountBinding = (id) => {
    return new Promise((resolve, reject) => {
        ContractorService.getAccountBinding(id)
            .then((res) => {
                resolve(res.data)
            })
            .catch((e) => reject(e))
    })
}

export const accountBindingRegister = (params) => {
    return new Promise((resolve, reject) => {
        ContractorService.accountBindingRegister(params)
            .then(res => {
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const accountBindingUnregister = (params) => {
    return new Promise((resolve, reject) => {
        ContractorService.accountBindingUnregister(params)
            .then(res => {
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const accountBindingEnable = (params) => {
    return new Promise((resolve, reject) => {
        ContractorService.accountBindingEnable(params)
            .then(res => {
                EventBus.dispatch(ACCOUNT_BINDING_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const accountBindingDisable = (params) => {
    return new Promise((resolve, reject) => {
        ContractorService.accountBindingDisable(params)
            .then(res => {
                EventBus.dispatch(ACCOUNT_BINDING_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export default bindContractorToAccountSlice.reducer
