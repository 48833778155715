import React, {useState} from "react";
import {Button, Modal, Form, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import PropTypes from "prop-types";
import {StopOrderAsync} from "../../../app/store/reducers/order/orderReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import IconButton from "../../common/IconButton";


const StopOrder = ({id, ...props}) => {
    const [show, setShow] = useState(false);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);

    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setLoading(false);
        setShow(false);
    };

    const stopOrderHandler = () => {
        setLoading(true);
        StopOrderAsync({
            id: id,
            comment: comment
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    };

    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-danger"
                size="sm"
                iconClassName="me-1"
                icon={'pause'}
                {...props}
            >{t(lang, 'edi.orders.order.stop_order_button')}</IconButton>

            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "edi.orders.order.stop_order_modal.title")}</Modal.Title>
                    <FalconCloseButton onClick={() => handleClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder={t(lang, 'edi.common.leave_comment')}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        {t(lang, "edi.common.cancel_button")}
                    </Button>
                    <Button variant="danger" className="d-flex align-items-center" disabled={loading} onClick={stopOrderHandler}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, "edi.orders.order.stop_order_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


StopOrder.propTypes = {
    id: PropTypes.string,
};

StopOrder.defaultProps = {
    id: "",
};

export default StopOrder;