import React, {useCallback, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {useFormContext} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import {debounce} from "underscore";
import {updateFilters} from "../../../../../app/store/reducers/category/categoryReducer";

const DataTableFilterForm = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const dispatch = useDispatch()

    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        const filters = {}

        if (filter)
            filters["name"] = filter.trim();

        dispatch(updateFilters(filters));
    },500), [])

    return (
        <Row>
            <Col sm={6}>
                <Row className="g-2">
                    <Col md={5}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'items.common.datatable.filter.name')}</Form.Label>
                            <Form.Control name="name"
                                          placeholder={t(lang, 'items.common.datatable.filter.name.placeholder')}
                                          {...form.register('name')}
                                          onChange={(e) => {
                                              onDebouncedFilterChange(e.target.value)
                                          }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DataTableFilterForm;
