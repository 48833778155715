import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";

import FalconCloseButton from "./FalconCloseButton";


function ButtonWithConfirmModal({title, description, button, onConfirm, showModal, onClose}) {
    const handleClose = () => {
        onClose()
    };
    const onClickConfirm = () => {
        onConfirm()
    }
    return (
        <>
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                {description && <Modal.Body>{description}</Modal.Body>}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button onClick={onClickConfirm} variant={button.variant}>{button.text}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


ButtonWithConfirmModal.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.shape({
        text: PropTypes.string,
        variant: PropTypes.string
    }),
    onConfirm: PropTypes.func,
    showModal: PropTypes.bool
}


ButtonWithConfirmModal.defaultProps = {
    title: "Вы уверены?",
    description: "",
    button: {
        text: "",
        variant: "primary",
    },
    onConfirm: () => {
    },
}


export default ButtonWithConfirmModal
