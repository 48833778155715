import React, {useEffect} from 'react';
import {useRouteMatch} from "react-router-dom";
import {Route, Switch} from "react-router";
import AddItem from "../AddItem";
import EditItem from "../EditItem";
import ViewItem from "../ViewItem";
import ViewDraftItem from "../ViewDraftItem";
import ApproveDraftItem from "../ApproveDraftItem";
import BulkAddItems from "../BulkAddItems";
import ImportItemsFromExcel from "../../../../components/hippo/item/excel/ImportItemsFromExcel";
import CategoriesDataTable from "../../../../components/hippo/category/components/datatable/CategoriesDataTable";
import Settings from "../Settings";
import DraftItemsDataTable from "../../../../components/hippo/draft-item/components/datatable/DraftItemsDataTable";
import {loadCategoriesAsync, updateAllCategories} from "../../../../app/store/reducers/category/categoryReducer";
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ADD_CATEGORY_SUCCESS,
    BULK_DELETE_CATEGORY_SUCCESS,
    CHANGE_SETTINGS_SUCCESS,
    DELETE_CATEGORY_SUCCESS,
    EDIT_CATEGORY_SUCCESS
} from "../../../../app/eventbus/itemEvents";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    loadCompanyProductCatalogsAsync,
    loadMeasurementsAsync
} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang} from "../../../../app/store/reducers/main/mainReducer";
import {loadOrganizationAsync} from "../../../../app/store/reducers/draft-item/draftItemReducer";
import ItemsHistoryDataTable from "../../../../components/hippo/item/history/ItemHistoryDataTable";
import ItemDataTable from "../../../../components/hippo/item/datatable/ItemDataTable";

const ItemLayout = () => {
    const { url } = useRouteMatch();
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);

    const loadCategories = () => {
        loadCategoriesAsync()
            .then((categories) => dispatch(updateAllCategories(categories)))
            .catch((error) => dispatch(updateAllCategories([])))
    }

    useEffect(() => {
        loadCategories();
        dispatch(loadOrganizationAsync());

        const onAddCategorySuccessHandler = EventBus.on(ADD_CATEGORY_SUCCESS, loadCategories);
        const onEditCategorySuccessHandler = EventBus.on(EDIT_CATEGORY_SUCCESS, loadCategories);
        const onDeleteCategorySuccessHandler = EventBus.on(DELETE_CATEGORY_SUCCESS, loadCategories);
        const onBulkDeleteCategoriesSuccessHandler = EventBus.on(BULK_DELETE_CATEGORY_SUCCESS, loadCategories);
        const onAnySettingsChangedHandler = EventBus.on(CHANGE_SETTINGS_SUCCESS, () => {
            dispatch(loadOrganizationAsync());
        })

        return () => {
            EventBus.remove(ADD_CATEGORY_SUCCESS, onAddCategorySuccessHandler);
            EventBus.remove(DELETE_CATEGORY_SUCCESS, onDeleteCategorySuccessHandler);
            EventBus.remove(EDIT_CATEGORY_SUCCESS, onEditCategorySuccessHandler);
            EventBus.remove(BULK_DELETE_CATEGORY_SUCCESS, onBulkDeleteCategoriesSuccessHandler);
            EventBus.remove(CHANGE_SETTINGS_SUCCESS, onAnySettingsChangedHandler);
        }
    }, [activeOrganization])

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
    }, [activeOrganization, lang])

    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang))
    }, [lang])

    return (
        <Switch>
            <Route path={`${url}`} exact component={ItemDataTable} />
            <Route path={`${url}/categories`} exact component={CategoriesDataTable} />

            <Route path={`${url}/history`} exact component={ItemsHistoryDataTable} />

            <Route path={`${url}/settings`} exact component={Settings} />
            <Route path={`${url}/drafts`} exact component={DraftItemsDataTable} />
            <Route path={`${url}/draft/approve/:id`} component={ApproveDraftItem} />
            <Route path={`${url}/bulk-add`} component={BulkAddItems} />
            <Route path={`${url}/registry-add`} component={ImportItemsFromExcel} />
            <Route path={`${url}/add`} component={AddItem} />
            <Route path={`${url}/edit/:id`} component={EditItem} />
            <Route path={`${url}/draft/:id`} component={ViewDraftItem} />
            <Route path={`${url}/:id`} component={ViewItem} />
        </Switch>
    );
};

export default ItemLayout;