import numeral from "numeral";
import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import Cleave from "cleave.js/react";
import {useFormContext, Controller} from "react-hook-form";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SelectCompanyCatalog from "../../IKPU/SelectCompanyCatalog";
import classNames from "classnames";

const itemQuantityFormatRegEx = /^[0-9]\d*(\.\d+)?$/

const ItemRow = ({item, index, arrayFieldName, update, allowEditProductName}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const {watch, formState: {errors}, register, control, setValue} = useFormContext();
    const rootName = `${arrayFieldName}.${index}`;
    const itemQuantity = +watch(`${rootName}.quantity`);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        updateRow();
    }, [itemQuantity])

    const updateRow = () => {
        item.quantity = +itemQuantity;
        item.total = item.quantity * item.price;
        item.nds_value = (item.total / 100) * item.nds_rate;
        item.total_with_nds = item.total + item.nds_value;

        update(index, item);
    }

    let itemErrors = {};
    if (errors && errors[arrayFieldName] && errors[arrayFieldName][index])
        itemErrors = errors[arrayFieldName][index];

    return (
        <>
            <tr>
                <th className="px-1">{index + 1}</th>
                <th className="px-1" style={{
                    minWidth: "250px",
                    width: "300px"
                }}>
                    {allowEditProductName ? (
                        <Form.Group>
                            <Controller
                                control={control}
                                name={`${rootName}.product`}
                                rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                render={({field}) => (
                                    <Form.Control
                                        type="text" size="sm"
                                        value={field.value}
                                        className="px-1 py-1"
                                        isInvalid={itemErrors.product}
                                        onChange={event => field.onChange(event.target.value)}
                                    />
                                )}
                            />

                            <Form.Control.Feedback type="invalid">
                                {itemErrors?.product?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    ) : item.product}
                </th>
                {/*<th className="px-1">{item.customer_shipment_id}</th>*/}
                <th className="px-1">{item.barcode}</th>
                <th className="px-1">
                    <Controller
                        name={`${rootName}.catalog_class_code`}
                        render={
                            ({field}) => (
                                <SelectCompanyCatalog size="sm"
                                                      textButton={field.value || t(lang, "edi.common.select_button")}
                                                      defaultCatalogCode={field.value}
                                                      onChange={(catalog) => {
                                                          field.onChange(catalog?.class_code || null)
                                                          setValue(`${rootName}.catalog_class_name`, catalog?.name || null);
                                                      }}
                                />
                            )
                        }
                    />
                </th>
                <th className="px-1">{item.measurement}</th>
                <th className="px-1">
                    <Controller
                        name={`${rootName}.quantity`}
                        rules={{
                            required: t(lang, 'edi.common.forms.validations.is_required'),
                            min: {value: 0, message: t(lang, 'edi.return_shipment.shipment_items.item.quantity.validation.less_than')},
                            max: {value: item.quantity_left, message: t(lang, 'edi.return_shipment.shipment_items.item.quantity.validation.more_than')}
                        }}
                        render={({field}) => (
                            <Cleave
                                tabIndex={index + 1}
                                value={+item.quantity}
                                style={{width: "80px"}}
                                className={classNames('form-control p-1', {
                                    'is-invalid': itemErrors.quantity
                                })}
                                options={{numeral: true, delimiter: '', numeralDecimalScale: 3}}
                                onChange={event => {
                                    if (itemQuantityFormatRegEx.test(event.target.value))
                                        field.onChange(event.target.value)
                                }}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.quantity?.message}
                    </Form.Control.Feedback>
                </th>
                <th className="px-1">{numeral.formats[numberFormat].format(item.price)}</th>
                <th className="px-1">{numeral.formats[numberFormat].format(item.total)}</th>
                <th className="px-1">{item.nds_rate}</th>
                <th className="px-1">{numeral.formats[numberFormat].format(item.nds_value)}</th>
                <th className="text-end px-1">{numeral.formats[numberFormat].format(item.total_with_nds)}</th>
            </tr>
        </>
    );
};

export default ItemRow;