import {createSlice} from '@reduxjs/toolkit'
import kanbanService from "../../../../services/kanban/KanbanService";
import EventBus from "../../../eventbus/EventBus";
import {
    REGISTER_BOARD_REQUEST,
    REGISTER_BOARD_SUCCESS,
    REGISTER_BOARD_FAIL,
    CHANGE_BOARD_NAME_REQUEST,
    CHANGE_BOARD_NAME_SUCCESS,
    CHANGE_BOARD_NAME_FAIL,
    STATUS_REGISTER_SUCCESS,
    STATUS_REGISTER_FAIL,
    STATUS_DELETE_REQUEST,
    STATUS_DELETE_SUCCESS,
    STATUS_DELETE_FAIL,
    STATUS_UPDATE_SUCCESS,
    STATUS_UPDATE_FAIL,
    MEMBER_REGISTER_SUCCESS,
    MEMBER_REGISTER_FAIL,
    MEMBER_UPDATE_REQUEST,
    MEMBER_UPDATE_SUCCESS,
    MEMBER_UPDATE_FAIL,
    MEMBER_DELETE_FAIL,
    MEMBER_DELETE_SUCCESS,
    ITEM_REGISTER_SUCCESS,
    ITEM_REGISTER_FAIL,
    ITEM_DELETE_ASSIGNER_SUCCESS,
    ITEM_DELETE_ASSIGNER_FAIL,
    ITEM_UPDATE_COMMENT_SUCCESS,
    ITEM_UPDATE_COMMENT_FAIL,
    ITEM_REGISTER_ASSIGNER_SUCCESS,
    ITEM_REGISTER_ASSIGNER_FAIL,
    ITEM_UPDATE_ASSIGNER_FAIL,
    ITEM_UPDATE_ASSIGNER_SUCCESS,
    ITEM_REGISTER_COMMENT_FAIL,
    ITEM_REGISTER_COMMENT_SUCCESS,
    ITEM_DELETE_COMMENT_FAIL,
    ITEM_DELETE_COMMENT_SUCCESS,
    ITEM_REGISTER_LABEL_SUCCESS,
    ITEM_REGISTER_LABEL_FAIL,
    ITEM_UPDATE_LABEL_SUCCESS,
    ITEM_UPDATE_LABEL_FAIL,
    ITEM_DELETE_LABEL_SUCCESS,
    ITEM_DELETE_LABEL_FAIL,
    ITEM_CHANGE_STATUS_SUCCESS,
    ITEM_CHANGE_STATUS_FAIL,
    DELETE_BOARD_REQUEST,
    DELETE_BOARD_SUCCESS,
    DELETE_BOARD_FAIL,
    DRAG_AND_DROP_SUCCESS,
    DRAG_AND_DROP_FAIL,
    DRAG_AND_DROP_REQUEST,
    ITEM_EDIT_SUCCESS,
    ITEM_EDIT_FAIL,
    ITEM_DELETE_SUCCESS,
    ITEM_DELETE_FAIL,
    DRAG_AND_DROP_TASK_REQUEST,
    DRAG_AND_DROP_TASK_SUCCESS,
    DRAG_AND_DROP_TASK_FAIL,
    ITEM_EDIT_TITLE_SUCCESS,
    ITEM_EDIT_TITLE_FAIL,
    EDIT_CONTRACOR_DATE_SUCCESS
} from "../../../eventbus/kanban/KanbanEvents";

const initialState = {
    boards: [],
    boardsCount: 0,
    boardFilters: {page: 1, limit: 10},
    filterWithTasks: {
        contractor_id: '',
        employee_id: '',
        debt_promise_date_start: null,
        debt_promise_date_end: null
    },
    withTasks: null,
    loading: false,

    boardName: "",
    members: [],
    labels: [
        {uuid: 1, name: 'task.board.modal.new.label.name', color_type: 1},
        {uuid: 2, name: 'task.board.modal.error.label.name', color_type: 4},
    ],
    kanbanItems: [],
    kanbanItemEdit: null,
    statusItems: [],
    itemChangeStatusLoader: false,
    modalContent: {}
}


const kanbanReducer = createSlice({
    name: 'kanbanReducer',
    initialState,
    reducers: {
        // boards
        getBoards: (state, action) => {
            state.boards = action.payload
        },
        setBoardsCount: (state, action) => {
            state.boardsCount = action.payload
        },
        setBoardFilters: (state, action) => {
            state.boardFilters = action.payload
        },
        getBoardItem:(state, action) => {
            state.kanbanItems = action.payload.statuses?.sort((a, b) => a.order - b.order)
            state.members = action.payload.members
            state.boardName = action.payload?.board?.name
            // state.labels = action.payload.labels
        },
        setWithTasks: (state, action) => {
            state.withTasks = action.payload
        },
        updateFilterWithTasks: (state, action) => {
            state.filterWithTasks = {
                ...state.filterWithTasks,
                ...action.payload
            }
        },
        getBoardStatusItems: (state, {payload}) => {
            state.statusItems = payload
        },

        // columns
        setDragAndDropColumn: (state, action) => {
            state.withTasks.columns = action.payload
        },
        addColumn:(state, action) => {
            state.withTasks.columns = [...state.withTasks.columns, {tasks: [], ...action.payload}]
        },
        editColumn:(state, action) => {
            state.withTasks.columns.map((column, index) => {
                if (column.id === action.payload.id) {
                    state.withTasks.columns.splice(index, 1, {...action.payload, tasks: column.tasks})
                }
            })
        },
        deleteColumn: (state, action) => {
            state.withTasks.columns = state.withTasks.columns.filter(column => column.id !== action.payload.id)
        },

        // tasks
        addTask:(state, action) => {
            state.withTasks.columns.map(column => {
                if (column.id === action.payload.board_column_id) {
                    column.tasks = [...column.tasks, action.payload]
                }
            })
        },
        editTask: (state, action) => {
            state.withTasks.columns.map(column => {
                if (column.id === action.payload.board_column_id) {
                    column.tasks.map((task, index) => {
                        if (task.id === action.payload.id) {
                            column.tasks.splice(index, 1, action.payload)
                        }
                    })
                }
            })
        },
        editTaskTitle: (state, action) => {
            state.withTasks.columns.map(column => {
                if (column.id === action.payload.board_column_id) {
                    column.tasks.map(task => {
                        if  (task.id === action.payload.id) {
                            task.title = action.payload.title
                        }
                    })
                }
            })
        },
        editTaskDescription: (state, action) => {
            state.withTasks.columns.map(column => {
                if (column.id === action.payload.board_column_id) {
                    column.tasks.map(task => {
                        if  (task.id === action.payload.id) {
                            task.description = action.payload.description
                        }
                    })
                }
            })
        },
        editTaskAssignee: (state, action) => {
            state.withTasks.columns.map(column => {
                if (column.id === action.payload.board_column_id) {
                    column.tasks.map(task => {
                        if  (task.id === action.payload.id) {
                            task.assignee_id = action.payload.assignee_id
                        }
                    })
                }
            })
        },
        editTaskContractorDate: (state, action) => {
            state.withTasks.columns.map(column => {
                if (column.id === action.payload.board_column_id) {
                    column.tasks.map(task => {
                        if  (task.id === action.payload.id) {
                            task.contractor.debt_promise_date = action.payload.contractor.debt_promise_date
                        }
                    })
                }
            })
        },
        deleteTask: (state, action) => {
            state.withTasks.columns.map(column => {
                if (column.id === action.payload.board_column_id) {
                    column.tasks = column.tasks.filter(task => task.id !== action.payload.id)
                }
            })
        },

        updateModalContent: (state, {payload}) => {
            state.modalContent = payload ?  payload : {}
        },
        updateLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})


export const selectBoards = (state) => state.kanban.boards;
export const selectBoardsCount = (state) => state.kanban.boardsCount;
export const selectBoardsFilter = (state) => state.kanban.boardFilters;
export const selectWithTasks = (state) => state.kanban.withTasks;
export const seletcFilterWithTasks = (state) => state.kanban.filterWithTasks;

export const selectBoardName = (state) => state.kanban.boardName
export const selectModalContent = (state) => state.kanban.modalContent
export const taskBoardLoading = (state) => state.kanban.loading

// boards
export const getBoardOrganizationAsync = ({page, limit, ...filters}) => (dispatch) => {

    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return kanbanService.getBoards(params)
        .then(res => {
            dispatch(kanbanReducer.actions.getBoards(res.data))
            return res.data
        })
        .catch(error => {
            console.log(error)
        })
}
export const getBoardOrganizationCountAsync = (params) => (dispatch) => {
    let {limit, page, ...others} = params

    kanbanService.getBoardsCount(others)
        .then(res => {
            dispatch(kanbanReducer.actions.setBoardsCount(res.data))
        })
        .catch(e => console.log(e))
}
export const getBoardItemAsync = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        kanbanService.getBoard(id)
            .then((res) => {
                dispatch(kanbanReducer.actions.getBoardItem(res.data))
                resolve(res)
            })
            .catch((e) => {
                new Error(e)
                reject(e)
            })
    })
}
export const changeBoardFilter = ({...params}) => (dispatch) => {
    dispatch(kanbanReducer.actions.setBoardFilters({...params}))
}
export const boardRegister = (data) => {
    EventBus.dispatch(REGISTER_BOARD_REQUEST)
    return new Promise((resolve, reject) => {
        kanbanService.addBoard(data)
            .then((res) => {
                EventBus.dispatch(REGISTER_BOARD_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(REGISTER_BOARD_FAIL)
                reject(e)
            })
    })
}
export const editBoard = ({id,...data}) => {
    EventBus.dispatch(CHANGE_BOARD_NAME_REQUEST)
    return new Promise((resolve, reject) => {
        kanbanService.editBoard(id,data)
            .then((res)=> {
                EventBus.dispatch(CHANGE_BOARD_NAME_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(CHANGE_BOARD_NAME_FAIL)
                reject(e)
            })
    })
}
export const deleteBoard = (id) => {
    EventBus.dispatch(DELETE_BOARD_REQUEST)
    return new Promise((resolve, reject) => {
        kanbanService.deleteBoard(id)
            .then((res)=> {
                EventBus.dispatch(DELETE_BOARD_SUCCESS)
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(DELETE_BOARD_FAIL)
                reject(e)
            })
    })
}
export const getWithTasksAsync = (params) => (dispatch) => {
    return kanbanService.getWithTasks(params)
        .then((res) => {
            dispatch(kanbanReducer.actions.setWithTasks(res.data))
            return res.data
        })
        .catch(error => console.log(error))
}
export const getBoardStatusItemsAsync = id => dispatch => {
    return new Promise((resolve, reject) => {
        kanbanService.boardItemsGet(id)
            .then(res => {
                dispatch(kanbanReducer.actions.getBoardStatusItems(res.data))
                resolve(res)
            })
            .catch((e) => {
                new Error(e)
                reject(e)
            })
    })

}



// columns
export const addBoardColumn = ({id, ...data}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        kanbanService.addColumn(id, data)
            .then((res) => {
                EventBus.dispatch(STATUS_REGISTER_SUCCESS)
                dispatch(kanbanReducer.actions.addColumn(res.data))
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(STATUS_REGISTER_FAIL)
                reject(e)
            })
    })
}
export const editBoardColumn = (id, data) => (dispatch) => {
    EventBus.dispatch(STATUS_DELETE_REQUEST)
    return new Promise((resolve, reject) => {
        kanbanService.editColumn(id, data)
            .then((res) => {
                EventBus.dispatch(STATUS_UPDATE_SUCCESS)
                dispatch(kanbanReducer.actions.editColumn(res.data))
                resolve(res)
            })
            .catch((e) => {
                EventBus.dispatch(STATUS_UPDATE_FAIL)
                reject(e)
            })
    })
}
export const deleteBoardColumn = (id, data) => (dispatch) => {
    EventBus.dispatch(STATUS_DELETE_REQUEST)
    return kanbanService.deleteColumn(id, data)
        .then((res) => {
            EventBus.dispatch(STATUS_DELETE_SUCCESS)
            dispatch(kanbanReducer.actions.deleteColumn(res.data))
        })
        .catch((e) => {
            EventBus.dispatch(STATUS_DELETE_FAIL)
            console.log(e)
        })
}
export const dragAndDropBoardColumn = (id, data) => {
    EventBus.dispatch(DRAG_AND_DROP_REQUEST)
    kanbanService.dragAndDropColumn(id, data)
        .then(() => {
            EventBus.dispatch(DRAG_AND_DROP_SUCCESS)
        })
        .catch((e) => {
            EventBus.dispatch(DRAG_AND_DROP_FAIL)
            console.log(e)
        })
}

// task
export const getTask = (id, data) => (dispatch) => {
    dispatch(kanbanReducer.actions.updateLoading(true))

    return new Promise((resolve, reject) => {
        kanbanService.getTask(id, data)
            .then(res => {
                resolve(res.data)
            })
            .catch(e => {
                reject(e)
            })
            .finally(() => {
                dispatch(kanbanReducer.actions.updateLoading(false))
            })
    })
}
export const addTaskInColumn = (data) => (dispatch) => {
    return kanbanService.addTask(data)
        .then((res) => {
            EventBus.dispatch(ITEM_REGISTER_SUCCESS, res.data.contractor?.id)
            dispatch(kanbanReducer.actions.addTask(res.data))
        })
        .catch((e) => {
            EventBus.dispatch(ITEM_REGISTER_FAIL)
            console.log(e)
        })
}
export const editTaskInColumn = (id, data) => (dispatch) =>{
    return kanbanService.editTask(id, data)
        .then((res) => {
            EventBus.dispatch(ITEM_EDIT_SUCCESS)
            dispatch(kanbanReducer.actions.editTask(res.data))
        })
        .catch((e) => {
            EventBus.dispatch(ITEM_EDIT_FAIL)
            console.log(e)
        })
}
export const deleteTaskInColumn = (id, data) => (dispatch) =>{
    return kanbanService.deleteTask(id, data)
        .then((res) => {
            EventBus.dispatch(ITEM_DELETE_SUCCESS)
            dispatch(kanbanReducer.actions.deleteTask(res.data))
        })
        .catch((e) => {
            EventBus.dispatch(ITEM_DELETE_FAIL)
            console.log(e)
        })
}
export const dragAndDropTaskInColumn = (data) => {
    EventBus.dispatch(DRAG_AND_DROP_TASK_REQUEST)
    return  kanbanService.dragAndDropTask(data)
        .then((res) => {
            EventBus.dispatch(DRAG_AND_DROP_TASK_SUCCESS, res.data.contractor?.id)
        })
        .catch((e) => {
            EventBus.dispatch(DRAG_AND_DROP_TASK_FAIL)
            console.log(e)
        })
}
export const editTaskInColumnTitle = (id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        kanbanService.editTaskTitle(id, data)
            .then((res) => {
                dispatch(kanbanReducer.actions.editTaskTitle(res.data))
                resolve(res.data)
            })
            .catch((e) => {
                reject(e)
            })
    })
}
export const editTaskInColumnDescription = (id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        kanbanService.editTaskDescription(id, data)
            .then((res) => {
                dispatch(kanbanReducer.actions.editTaskDescription(res.data))
                resolve(res.data)
            })
            .catch((e) => {
                reject(e)
            })
    })
}
export const editTaskInColumnAssignee = (id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        kanbanService.editTaskAssigne(id, data)
            .then((res) => {
                dispatch(kanbanReducer.actions.editTaskAssignee(res.data))
                resolve(res.data)
            })
            .catch((e) => {
                reject(e)
            })
    })
}
export const editTaskInColumnContractorDebtDate = (id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        kanbanService.editTaskContractorDate(id, data)
            .then((res) => {
                EventBus.dispatch(EDIT_CONTRACOR_DATE_SUCCESS, res.data.contractor?.id)
                dispatch(kanbanReducer.actions.editTaskContractorDate(res.data))
                resolve(res.data)
            })
            .catch((e) => {
                reject(e)
            })
    })
}

// comment
export const addTaskComment = (id, data) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemRegisterComment(id, data)
            .then((res) => {
                EventBus.dispatch(ITEM_REGISTER_COMMENT_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_REGISTER_COMMENT_FAIL)
                reject(e)
            })
    })
}
export const editTaskComment = (id, data) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemUpdateComment(id, data)
            .then((res) => {
                EventBus.dispatch(ITEM_UPDATE_COMMENT_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_UPDATE_COMMENT_FAIL)
                reject(e)
            })
    })
}
export const deleteTaskComment = (id, data) => {
    return new Promise((resolve, reject) => {
        kanbanService.itemDeleteComment(id, data)
            .then((res) => {
                EventBus.dispatch(ITEM_DELETE_COMMENT_SUCCESS)
                resolve(res.data)
            })
            .catch((e) => {
                EventBus.dispatch(ITEM_DELETE_COMMENT_FAIL)
                reject(e)
            })
    })
}



export const {
    updateModalContent,
    setDragAndDropColumn,
    updateFilterWithTasks

} = kanbanReducer.actions

export default kanbanReducer.reducer
