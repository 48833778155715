import React, {useRef, useState} from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {getRandomNumber} from "../../../../../../helpers/utils";
import TelegramOrderBotService from "../../../../../../services/telegram-order-bot/telegramOrderBotService";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {
    BULK_DELETE_TELEGRAM_ORDER_BOTS_FINISHED
} from "../../../../../../app/eventbus/telegram-bot/telegramOrderBotEvents";
import ResultCounter from "../../../../item/forms/bulk-item/ResultCounter";
import IconButton from "../../../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../../common/FalconCloseButton";
import Cleave from "cleave.js/react";
import classNames from "classnames";

const BulkDeleteTelegramOrderBots = ({bots}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);
    const form = useForm({
        code: ""
    });
    const [code] = useState(getRandomNumber(100000, 999999));
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onBulkDeleteTelegramOrderBots = async (bots) => {
        handleClose();
        ref.current.updateLoadedCount(bots.length);
        for (const bot of bots) {
            try {
                await TelegramOrderBotService.deleteTelegramOrderBot(bot.id);
                ref.current.incrementSucceedCount();
            } catch (error) {
                ref.current.incrementFailedCount();
                console.log(error);
            }
        }
        form.reset();
        EventBus.dispatch(BULK_DELETE_TELEGRAM_ORDER_BOTS_FINISHED);
    };

    return (
        <React.Fragment>
            <ResultCounter ref={ref}/>
            <IconButton variant="falcon-danger"
                        icon={faTrash}
                        onClick={handleShow}
                        size="sm"
            >
                {t(lang, 'items.common.delete')}
            </IconButton>
            {show &&
                <Modal show={true}
                       centered={true}
                       onHide={handleClose}
                       size="lg"
                >
                    <FormProvider {...form}>
                        <Form>
                            <Modal.Header>
                                <Modal.Title>
                                    {t(lang, "crm.telegram_bots.bulk_delete_bot_title")}
                                </Modal.Title>
                                <FalconCloseButton onClick={handleClose}/>
                            </Modal.Header>
                            <Modal.Body>
                                <Controller name={'code'}
                                            rules={{
                                                required: t(lang, 'items.common.validations.is_required'),
                                                validate: (value) => {
                                                    if (+value === code)
                                                        return true;
                                                    return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                                }
                                            }}
                                            render={({field, fieldState: {error}}) => (
                                                <Form.Group>
                                                    <Form.Label>
                                                        <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                                        <span className="ms-1 text-primary">{code}</span>
                                                    </Form.Label>
                                                    <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                            value={field.value}
                                                            htmlRef={field.ref}
                                                            placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                            className={classNames('form-control', {
                                                                'is-invalid': !!error
                                                            })}
                                                            options={{
                                                                numeral: true,
                                                                delimiter: ' ',
                                                                numeralDecimalScale: 3
                                                            }}
                                                    />
                                                    {!!error && <Form.Control.Feedback
                                                        type="invalid">{error.message}</Form.Control.Feedback>}
                                                </Form.Group>
                                            )}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    {t(lang, "items.common.cancel")}
                                </Button>
                                <Button variant="danger"
                                        onClick={form.handleSubmit(() => onBulkDeleteTelegramOrderBots(bots))}
                                >
                                    {t(lang, "items.common.delete")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </FormProvider>
                </Modal>
            }
        </React.Fragment>
    );
};

export default BulkDeleteTelegramOrderBots;