import React, {useState} from 'react';
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import FalconCloseButton from "../FalconCloseButton";

const DataTableColumnSettings = ({table}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <React.Fragment>
            <FontAwesomeIcon icon={faGear}
                             className="cursor-pointer"
                             onClick={handleShow}
            />
            {show &&
                <Modal show={true}
                       onHide={handleClose}
                       size="lg"
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, "edi.common.controller_datatable.modal.header_title")}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {table.getAllLeafColumns().map((column) => (
                                <Col xs={12} key={column.id}>
                                    <Form.Group>
                                        <Form.Switch defaultChecked={column.getIsVisible()}
                                                     disabled={!column.getCanHide()}
                                                     onChange={column.getToggleVisibilityHandler()}
                                                     label={column.columnDef.accessorKey}
                                        />
                                    </Form.Group>
                                </Col>
                            ))}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={handleClose}
                        >
                            {t(lang, "edi.common.button.close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </React.Fragment>
    );
};

export default DataTableColumnSettings;