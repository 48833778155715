import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {
    changeDesktopAppVersionsFilterOptionsAsync,
    selectDesktopAppVersions, selectDesktopAppVersionsCount,
    selectDesktopAppVersionsFilterOptions
} from "../../../../app/store/reducers/desktop-app/desktopAppReducer";
import {Badge, Card, Col, Row} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../common/advance-table/AdvanceTableFooter";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import DesktopAppVersionActivationSwitcher from "../actions/DesktopAppVersionActivationSwitcher";
import DeleteDesktopAppVersion from "../actions/DeleteDesktopAppVersion";
import AddDesktopAppNewVersion from "../AddDesktopAppNewVersion";
import {GetOsLabel} from "../../../../enum/DesktopApp";

const DesktopAppVersionsDatatable = () => {
    const filter = useSelector(selectDesktopAppVersionsFilterOptions);
    const desktopAppVersions = useSelector(selectDesktopAppVersions);
    const count = useSelector(selectDesktopAppVersionsCount);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'version',
            Header: t(lang, 'admin.desktop_app_versions.datatable.column.version'),
            Cell: ({row: {original}}) => {
                return original.version
            }
        },
        {
            accessor: 'os_type',
            Header: t(lang, 'admin.desktop_app_versions.datatable.column.os_type'),
            Cell: ({row: {original}}) => {
                return GetOsLabel(original.os_type)
            }
        },
        {
            accessor: 'is_active',
            Header: t(lang, 'admin.desktop_app_versions.datatable.column.is_active'),
            Cell: ({row: {original}}) => {
                return original.is_active
                    ? <Badge bg="success">{t(lang, 'admin.desktop_app_versions.datatable.row.is_active.active')}</Badge>
                    : <Badge bg="secondary">{t(lang, 'admin.desktop_app_versions.datatable.row.is_active.not_active')}</Badge>
            }
        },
        {
            accessor: 'none',
            cellProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <DesktopAppVersionActivationSwitcher version={original} className="cursor-pointer d-inline-block mx-2"/>
                        <DeleteDesktopAppVersion id={original.id} className="ms-2 text-danger cursor-pointer"/>
                    </>
                )
            }
        }
    ];

    const onPageChange = async (page) => {
        dispatch(changeDesktopAppVersionsFilterOptionsAsync({...filter, page: page}));
    }
    const onLimitChange = async (limit) => {
        dispatch(changeDesktopAppVersionsFilterOptionsAsync({...filter, limit: limit, page: 1}));
    }

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={desktopAppVersions}
            pagination
            perPage={10}
        >
            <Card>
                <Card.Header as={Row}>
                    <Col xs={6}>
                        <h4>{t(lang, 'admin.desktop_app_versions.title')}</h4>
                    </Col>
                    <Col xs="auto" className="ms-auto">
                        <AddDesktopAppNewVersion size="sm" />
                    </Col>
                </Card.Header>
                <Card.Body>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}>
                    </AdvanceTable>
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                    />
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filter.limit}
                        page={filter.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default DesktopAppVersionsDatatable;