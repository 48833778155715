import React, {useContext, useEffect, useState} from 'react';
import AppContext from "../../context/Context";
import NavbarTop from "../../components/hippo/navbar/top/NavbarTop";
import classNames from "classnames";
import Footer from "../../components/footer/Footer";
import Dashboard from "./dashboard/Dashboard";
import {Route, Switch} from "react-router-dom";
import Settings from "../../components/hippo/user/settings/Settings";
import EDILayout from "./edi/EDILayout";
import CrmLayout from "./crm/CrmLayout";
import RoamingLayout from "./roaming/RoamingLayout";
import Billing from "../../components/hippo/user/billing/Billing";
import AdminLayout from "./admin/AdminLayout";
import {useDispatch, useSelector} from "react-redux";
import {
    getOrganizationUsersAsync,
    logoutAsync,
    selectActiveOrganization,
    selectIsAdmin
} from "../../app/store/reducers/auth/authReducer";
import axios from "axios";
import {loadOrganizationTemplatesAsync} from "../../app/store/reducers/excel/excelReducer";
import WarehouseLayout from "./warehouse/WarehouseLayout";
import Currencies from "./user/Currencies";
import {loadDefaultSettings, loadSettings} from "../../app/store/reducers/settings/settingsReducer";
import EventBus from "../../app/eventbus/EventBus";
import {EDI_SETTINGS_SUCCESS} from "../../app/eventbus/ediSettingsEvent";
import {shipmentLoadProviders} from "../../app/store/reducers/shipment/shipmentReducer";
import {returnShipmentLoadProviders} from "../../app/store/reducers/return-shipment/returnShipmentReducer";
import {
    loadCompany,
    loadCountries,
    loadDistricts,
    loadMeasurements,
    loadRegions,
    loadStations,
    loadWarehouses
} from "../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {getAllWarehousesAsync} from "../../app/store/reducers/warehouse/warehouseReducer";
import {
    deleteItem,
    ITEM_INITIALIZATION_STATE_COMPLETE,
    ITEM_INITIALIZATION_STATE_LOADING,
    itemSlice,
    loadItemAsync,
    loadItemsAsync,
    pushUpdateItem,
    setLoading,
    updateCategoriesTree,
    updateItem,
    updateItemsDictionary
} from "../../app/store/reducers/item/itemReducer";
import {updateCategories} from "../../app/store/reducers/category/categoryReducer";
import {
    loadOwnerBindings,
    loadSupervisorBindings
} from "../../app/store/reducers/organization-binding/organizationBindingReducer";
import {
    ADD_PARTNER_SUCCEED,
    DELETE_PARTNER_SUCCEED,
    EDIT_PARTNER_SUCCEED
} from "../../app/eventbus/organizationBinding/organizationBindingEvents";
import KanbanLayout from "./kanban/KanbanLayout";
import {
    ADD_ITEM_SUCCESS,
    BULK_ADD_ITEMS_FINISHED,
    BULK_DELETE_ITEMS_FINISHED,
    BULK_EDIT_ITEMS_FINISHED,
    DELETE_ITEM_SUCCESS,
    EDIT_ITEM_SUCCESS,
    PUSH_UPDATE_ITEM_STATE_UPDATED,
    VALIDATE_ITEM_CATALOG_SUCCESS,
    VALIDATE_ITEM_CATALOGS_SUCCESS
} from "../../app/eventbus/itemEvents";

import PushUpdate from "../../app/push-update";
import {
    deleteContractor,
    loadAllContractorAsync,
    updateContractor,
    updateContractorDebt,
} from "../../app/store/reducers/contractor/contractorReducer";
import {
    ADD_CONTRACTOR_SUCCESS,
    CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED,
    DELETE_CONTRACTOR_SUCCESS,
    EDIT_CONTRACTOR_SUCCESS
} from "../../app/eventbus/contractor/contractorEvents";
import {BIND_CONTRACTOR_LABEL_SUCCESS,} from "../../app/eventbus/contractor/contractorLabelEvents";
import {
    ACCOUNT_PERMISSION_UPDATE,
    PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_REGISTER,
    PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_UNREGISTER,
    PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_DISABLED,
    PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_ENABLED,
    PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED,
    SETTINGS_ORGANIZATION_ACCOUNT_UPDATE
} from "../../app/eventbus/contractor/contractor-push-update-events";
import {selectLang} from "../../app/store/reducers/main/mainReducer";
import {loadCompanyProductCatalogsAsync} from "../../app/store/reducers/roaming/roamingReducer";
import {currencyGetAsync} from "../../app/store/reducers/currency/currencyReducer";
import Notification from "./notifications/Notification";
import {
    pushUpdateNotification,
    pushUpdateNotificationContractorDebt,
    pushUpdateNotificationDisabled
} from "../../app/store/reducers/push-update-notification/pushUpdateNotificationReducer";
import {
    AccountCashBoxBindingDisabled,
    AccountCashBoxBindingEnabled,
    AccountCashBoxBindRegistered,
    AccountCashBoxBindUnregistered,
    AccountContractorBindingDisable,
    AccountContractorBindingEnable,
    AccountContractorBindRegister,
    AccountContractorBindUnregister,
    CashBoxAmountsUpdated,
    CashBoxDeleted,
    CashBoxRegistered,
    CashBoxUpdated,
    ContractorDebtUpdated,
    pushUpdateFunc,
    WarehouseItemUpdated
} from "../../enum/PushUpdateNotification";
import Employees from "./employee/Employees";
import EndBillingPackageAlert from "../../components/common/EndBillingPackageAlert";
import CashBoxLayout from "./cashbox/CashBoxLayout";
import {
    ACCOUNT_CASH_BOX_BINDING_DISABLED,
    ACCOUNT_CASH_BOX_BINDING_ENABLED,
    ACCOUNT_CASH_BOX_BINDING_REGISTERED,
    ACCOUNT_CASH_BOX_BINDING_UNREGISTERED,
    CASH_BOX_AMOUNTS_UPDATED,
    CASH_BOX_DELETED,
    CASH_BOX_REGISTERED,
    CASH_BOX_UPDATED,
    CASHBOX_DELETE_SUCCESS,
    CASHBOX_REGISTER_SUCCESS,
    CASHBOX_UPDATE_SUCCESS
} from "../../app/eventbus/cashbox/cashboxEvents";
import {getAllCashboxAsync} from "../../app/store/reducers/cashbox/CashboxReducer";
import {loadBranchesAsync} from "../../app/store/reducers/branch/branchReducer";
import {loadRolesAsync} from "../../app/store/reducers/role/roleReducer";
import {ACCOUNT_REMOVE_FROM_ORGANIZATION_SUCCEED, ADD_USER_ORGANIZATION_SUCCESS} from "../../app/eventbus/authEvents";

const MainLayout = () => {
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);
    const isAdmin = useSelector(selectIsAdmin);
    const dispatch = useDispatch();

    const [mediaQueryList, setMediaQueryList] = useState(
        window.matchMedia("(min-width: 1200px)").matches
    )

    const pushUpdateSound = () => {
        let count = 0
        return () => {
            ++count
            if (count === 16) {
                dispatch(pushUpdateNotificationDisabled())
                count = 0
            }
        }
    }

    let pushUpdateClosure = pushUpdateSound()

    const loadItems = () => {
        dispatch(setLoading(true));
        dispatch(itemSlice.actions.setItemInitializationState(ITEM_INITIALIZATION_STATE_LOADING))
        loadItemsAsync()
            .then(({categories, categoriesTree, data}) => {
                const sortedItems = data.sort((a, b) => {
                    const nameA = a.item.name.trim().toLowerCase();
                    const nameB = b.item.name.trim().toLowerCase();

                    if (nameA < nameB)
                        return -1;

                    if (nameA > nameB)
                        return 1;

                    return 0;
                });
                dispatch(updateItemsDictionary(sortedItems));
                dispatch(updateCategories(categories));
                dispatch(updateCategoriesTree(categoriesTree));
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch(updateItemsDictionary([]));
                dispatch(updateCategories([]));
                dispatch(updateCategoriesTree([]));
                dispatch(setLoading(false));
            })
            .finally(() => {
                dispatch(itemSlice.actions.setItemInitializationState(ITEM_INITIALIZATION_STATE_COMPLETE))
            })
    }

    useEffect(() => {
        const reloadItem = async ({itemId}) => {
            try {
                const loadedItem = await loadItemAsync(itemId);
                dispatch(updateItem(loadedItem));
            } catch (error) {
                console.log(error);
            }
        };
        const removeItem = ({itemId}) => {
            dispatch(deleteItem(itemId))
        }

        const onAddItemSucceed = EventBus.on(ADD_ITEM_SUCCESS, reloadItem);
        const onEditItemSucceed = EventBus.on(EDIT_ITEM_SUCCESS, reloadItem);
        const onDeleteItemSucceed = EventBus.on(DELETE_ITEM_SUCCESS, removeItem);

        return () => {
            EventBus.remove(ADD_ITEM_SUCCESS, onAddItemSucceed);
            EventBus.remove(EDIT_ITEM_SUCCESS, onEditItemSucceed);
            EventBus.remove(DELETE_ITEM_SUCCESS, onDeleteItemSucceed);
        }
    }, [dispatch])

    useEffect(() => {
        window
            .matchMedia("(min-width: 1200px)")
            .addEventListener('change', e => setMediaQueryList(e.matches));
    }, [mediaQueryList]);

    useEffect(() => {
        PushUpdate.start(localStorage.getItem("token"))

        const unauthorizedInterceptor = axios.interceptors.response.use(response => {
            return response
        }, error => {
            console.log(error)
            if (error?.response?.status === 401) {
                dispatch(logoutAsync())
            }

            return Promise.reject(error);
        })
        // temporary
        const ediSettingsSuccessHandler = EventBus.on(EDI_SETTINGS_SUCCESS, () => {
            dispatch(loadSettings())
        })

        return () => {
            PushUpdate.stop()

            axios.interceptors.response.eject(unauthorizedInterceptor);
            // temporary
            EventBus.remove(EDI_SETTINGS_SUCCESS, ediSettingsSuccessHandler)
        }
    }, [])

    useEffect(() => {
        dispatch(loadMeasurements());
        dispatch(loadCountries());
        dispatch(loadRegions());
        dispatch(loadDistricts());
        dispatch(loadStations());
    }, []);

    useEffect(() => {
        dispatch(getOrganizationUsersAsync())
        dispatch(loadOwnerBindings());
        dispatch(loadSupervisorBindings());
        dispatch(loadAllContractorAsync());
        dispatch(currencyGetAsync());
        dispatch(getAllCashboxAsync());
        dispatch(loadBranchesAsync());

        loadItems();
        dispatch(loadOrganizationTemplatesAsync());
        dispatch(loadSettings())
        dispatch(loadDefaultSettings())
        dispatch(getAllWarehousesAsync({page: null, limit: null}))
        dispatch(shipmentLoadProviders()) // temporary
        dispatch(returnShipmentLoadProviders()) // temporary

        if (activeOrganization?.inn) {
            dispatch(loadCompany(activeOrganization.pinfl || activeOrganization.inn, {
                active_accounts: true,
                main_accounts: true,
                frozen_accounts: true
            }));
            dispatch(loadWarehouses({inn: activeOrganization.inn}));
        }

        const onUserAddedHandler = EventBus.on(ADD_USER_ORGANIZATION_SUCCESS, () => {
            dispatch(getOrganizationUsersAsync())
        })
        const onPartnerAddedHandler = EventBus.on(ADD_PARTNER_SUCCEED, () => {
            dispatch(loadOwnerBindings())
        });
        const onPartnerEditedHandler = EventBus.on(EDIT_PARTNER_SUCCEED, () => {
            dispatch(loadOwnerBindings());
        });
        const onPartnerDeletedHandler = EventBus.on(DELETE_PARTNER_SUCCEED, () => {
            dispatch(loadOwnerBindings());
        });

        const onCatalogsValidateHandler = EventBus.on(VALIDATE_ITEM_CATALOGS_SUCCESS, loadItems);
        const onBulkAddItemsFinishedHandler = EventBus.on(BULK_ADD_ITEMS_FINISHED, loadItems);
        const onBulkEditItemsFinishedHandler = EventBus.on(BULK_EDIT_ITEMS_FINISHED, loadItems);
        const onBulkItemDeleteHandler = EventBus.on(BULK_DELETE_ITEMS_FINISHED, loadItems);
        const onCatalogValidateHandler = EventBus.on(VALIDATE_ITEM_CATALOG_SUCCESS, loadItems);
        const onPushUpdateHandler = EventBus.on(PUSH_UPDATE_ITEM_STATE_UPDATED, ({
                                                                                     item_id,
                                                                                     warehouse_id,
                                                                                     state,
                                                                                     item_name,
                                                                                     organization_id
                                                                                 }) => {
            pushUpdateClosure()
            dispatch(pushUpdateItem({item_id, warehouse_id, state}));
            const pushUpdateWarehouseItem = pushUpdateFunc(WarehouseItemUpdated, {
                item_id,
                warehouse_id,
                state,
                item_name,
                organization_id
            })
            dispatch(pushUpdateNotification(pushUpdateWarehouseItem));
        });

        const onContractorAdd = EventBus.on(ADD_CONTRACTOR_SUCCESS, (contractor) => {
            dispatch(updateContractor(contractor));
        })
        const onContractorEditHandler = EventBus.on(EDIT_CONTRACTOR_SUCCESS, (contractor) => {
            dispatch(updateContractor(contractor));
        })
        const onContractorDeleteHandler = EventBus.on(DELETE_CONTRACTOR_SUCCESS, (contractor_id) => {
            dispatch(deleteContractor(contractor_id));
        })
        const onBindContractorLabelHandler = EventBus.on(BIND_CONTRACTOR_LABEL_SUCCESS, (contractor) => {
            dispatch(updateContractor(contractor));
        })
        const onContractorStartingDebtChanged = EventBus.on(CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED, (contractor) => {
            dispatch(updateContractor(contractor));
        })

        const onPushUpdateContractorDebtStateHandler = EventBus.on(PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED, (updated_contractor) => {
            const pushUpdateContractorDebt = pushUpdateFunc(ContractorDebtUpdated, updated_contractor)
            dispatch(pushUpdateNotificationContractorDebt(pushUpdateContractorDebt))
            dispatch(updateContractorDebt({contractorId: updated_contractor.id, debts: updated_contractor.debts}));
        });

        const onPushUpdateContractorBindingEnabled = EventBus.on(PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_ENABLED, (payload) => {
            const accountBinding = pushUpdateFunc(AccountContractorBindingEnable, payload)
            dispatch(pushUpdateNotification(accountBinding))
            pushUpdateClosure()
        })
        const onPushUpdateContractorBindingDisabled = EventBus.on(PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_DISABLED, (payload) => {
            const accountBinding = pushUpdateFunc(AccountContractorBindingDisable, payload)
            dispatch(pushUpdateNotification(accountBinding))
            pushUpdateClosure()
        })
        const onPushUpdateContractorBindRegister = EventBus.on(PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_REGISTER, (payload) => {
            const accountBinding = pushUpdateFunc(AccountContractorBindRegister, payload)
            dispatch(pushUpdateNotification(accountBinding))
            pushUpdateClosure()
            dispatch(loadAllContractorAsync());
        })

        const onPushUpdateContractorBindUnregister = EventBus.on(PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_UNREGISTER, (payload) => {
            const accountBinding = pushUpdateFunc(AccountContractorBindUnregister, payload)
            dispatch(pushUpdateNotification(accountBinding))
            pushUpdateClosure()
            dispatch(loadAllContractorAsync());
        })

        const onPushUpdateCashBoxRegistered = EventBus.on(CASH_BOX_REGISTERED, (payload) => {
            const cashBoxRegister = pushUpdateFunc(CashBoxRegistered, payload)
            dispatch(pushUpdateNotification(cashBoxRegister))
            pushUpdateClosure()
        })

        const onPushUpdateCashBoxUpdated = EventBus.on(CASH_BOX_UPDATED, (payload) => {
            const cashBoxRegister = pushUpdateFunc(CashBoxUpdated, payload)
            dispatch(pushUpdateNotification(cashBoxRegister))
            pushUpdateClosure()
        })

        const onPushUpdateCashBoxDeleted = EventBus.on(CASH_BOX_DELETED, (payload) => {
            const cashBoxRegister = pushUpdateFunc(CashBoxDeleted, payload)
            dispatch(pushUpdateNotification(cashBoxRegister))
            pushUpdateClosure()
        })

        const onPushUpdateCashBoxAmountsUpdated = EventBus.on(CASH_BOX_AMOUNTS_UPDATED, (payload) => {
            const cashBoxRegister = pushUpdateFunc(CashBoxAmountsUpdated, payload)
            dispatch(pushUpdateNotification(cashBoxRegister))
            pushUpdateClosure()
            dispatch(getAllCashboxAsync())
        })

        const onPushUpdateCashBoxAccountBindingRegistered = EventBus.on(ACCOUNT_CASH_BOX_BINDING_REGISTERED, (payload) => {
            const cashBoxRegister = pushUpdateFunc(AccountCashBoxBindRegistered, payload)
            dispatch(pushUpdateNotification(cashBoxRegister))
            pushUpdateClosure()
        })

        const onPushUpdateCashBoxAccountBindingUnregistered = EventBus.on(ACCOUNT_CASH_BOX_BINDING_UNREGISTERED, (payload) => {
            const cashBoxRegister = pushUpdateFunc(AccountCashBoxBindUnregistered, payload)
            dispatch(pushUpdateNotification(cashBoxRegister))
            pushUpdateClosure()
        })

        const onPushUpdateCashBoxAccountBindingEnabled = EventBus.on(ACCOUNT_CASH_BOX_BINDING_ENABLED, (payload) => {
            const cashBoxRegister = pushUpdateFunc(AccountCashBoxBindingEnabled, payload)
            dispatch(pushUpdateNotification(cashBoxRegister))
            pushUpdateClosure()
        })

        const onPushUpdateCashBoxAccountBindingDisabled = EventBus.on(ACCOUNT_CASH_BOX_BINDING_DISABLED, (payload) => {
            const cashBoxRegister = pushUpdateFunc(AccountCashBoxBindingDisabled, payload)
            dispatch(pushUpdateNotification(cashBoxRegister))
            pushUpdateClosure()
        })
        const onPushUpdateSettingsOrganizationAccountUpdate = EventBus.on(SETTINGS_ORGANIZATION_ACCOUNT_UPDATE, () => {
            dispatch(loadDefaultSettings())
        })
        const onAccountPermissionUpdate = EventBus.on(ACCOUNT_PERMISSION_UPDATE, () => {
            dispatch(loadRolesAsync())
        })
        const onCashBoxRegisterSucceed = EventBus.on(CASHBOX_REGISTER_SUCCESS, () => {
            dispatch(getAllCashboxAsync())
        })

        const onCashBoxUpdateSucceed = EventBus.on(CASHBOX_UPDATE_SUCCESS, () => {
            dispatch(getAllCashboxAsync())
        })

        const onCashBoxDeleteSucceed = EventBus.on(CASHBOX_DELETE_SUCCESS, () => {
            dispatch(getAllCashboxAsync())
        })
        const onRemoveAccountFromOrganizationSucceed = EventBus.on(ACCOUNT_REMOVE_FROM_ORGANIZATION_SUCCEED, () => {
            dispatch(getOrganizationUsersAsync())
        });

        return () => {
            EventBus.remove(ADD_USER_ORGANIZATION_SUCCESS, onUserAddedHandler);

            EventBus.remove(ADD_PARTNER_SUCCEED, onPartnerAddedHandler);
            EventBus.remove(EDIT_PARTNER_SUCCEED, onPartnerEditedHandler);
            EventBus.remove(DELETE_PARTNER_SUCCEED, onPartnerDeletedHandler);

            EventBus.remove(BULK_DELETE_ITEMS_FINISHED, onBulkItemDeleteHandler);
            EventBus.remove(VALIDATE_ITEM_CATALOGS_SUCCESS, onCatalogsValidateHandler);
            EventBus.remove(BULK_ADD_ITEMS_FINISHED, onBulkAddItemsFinishedHandler);
            EventBus.remove(BULK_EDIT_ITEMS_FINISHED, onBulkEditItemsFinishedHandler);
            EventBus.remove(VALIDATE_ITEM_CATALOG_SUCCESS, onCatalogValidateHandler);
            EventBus.remove(PUSH_UPDATE_ITEM_STATE_UPDATED, onPushUpdateHandler);

            EventBus.remove(ADD_CONTRACTOR_SUCCESS, onContractorAdd)
            EventBus.remove(EDIT_CONTRACTOR_SUCCESS, onContractorEditHandler);
            EventBus.remove(DELETE_CONTRACTOR_SUCCESS, onContractorDeleteHandler);
            EventBus.remove(BIND_CONTRACTOR_LABEL_SUCCESS, onBindContractorLabelHandler)
            EventBus.remove(CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED, onContractorStartingDebtChanged);

            EventBus.remove(PUSH_UPDATE_CONTRACTOR_DEBT_STATES_UPDATED, onPushUpdateContractorDebtStateHandler);
            EventBus.remove(PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_ENABLED, onPushUpdateContractorBindingEnabled);
            EventBus.remove(PUSH_UPDATE_ACCOUNT_CONTRACTOR_BINDING_DISABLED, onPushUpdateContractorBindingDisabled);
            EventBus.remove(PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_REGISTER, onPushUpdateContractorBindRegister);
            EventBus.remove(PUSH_UPDATE_ACCOUNT_CONTRACTOR_BIND_UNREGISTER, onPushUpdateContractorBindUnregister);

            EventBus.remove(CASH_BOX_REGISTERED, onPushUpdateCashBoxRegistered);
            EventBus.remove(CASH_BOX_UPDATED, onPushUpdateCashBoxUpdated);
            EventBus.remove(CASH_BOX_DELETED, onPushUpdateCashBoxDeleted);
            EventBus.remove(CASH_BOX_AMOUNTS_UPDATED, onPushUpdateCashBoxAmountsUpdated);
            EventBus.remove(ACCOUNT_CASH_BOX_BINDING_REGISTERED, onPushUpdateCashBoxAccountBindingRegistered);
            EventBus.remove(ACCOUNT_CASH_BOX_BINDING_UNREGISTERED, onPushUpdateCashBoxAccountBindingUnregistered);
            EventBus.remove(ACCOUNT_CASH_BOX_BINDING_ENABLED, onPushUpdateCashBoxAccountBindingEnabled);
            EventBus.remove(ACCOUNT_CASH_BOX_BINDING_DISABLED, onPushUpdateCashBoxAccountBindingDisabled);
            EventBus.remove(SETTINGS_ORGANIZATION_ACCOUNT_UPDATE, onPushUpdateSettingsOrganizationAccountUpdate);
            EventBus.remove(ACCOUNT_PERMISSION_UPDATE, onAccountPermissionUpdate);
            EventBus.remove(CASHBOX_REGISTER_SUCCESS, onCashBoxRegisterSucceed);
            EventBus.remove(CASHBOX_UPDATE_SUCCESS, onCashBoxUpdateSucceed);
            EventBus.remove(CASHBOX_DELETE_SUCCESS, onCashBoxDeleteSucceed);
            EventBus.remove(ACCOUNT_REMOVE_FROM_ORGANIZATION_SUCCEED, onRemoveAccountFromOrganizationSucceed);
        }
    }, [activeOrganization])

    useEffect(() => {
        if (activeOrganization.inn || activeOrganization.pinfl) {
            dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn || activeOrganization.pinfl, lang));
        }
    }, [activeOrganization, lang])

    const {
        config: {isFluid, navbarPosition, isNavbarVerticalCollapsed}
    } = useContext(AppContext);

    return (
        <div className={isFluid ? 'container-fluid' : 'container'}>
            <div className={classNames('content')}>
                <NavbarTop/>
                <EndBillingPackageAlert/>

                <Switch>
                    <Route path="/" exact component={Dashboard}/>
                    <Route path="/user/billing" exact component={Billing}/>
                    <Route path="/user/settings" exact component={Settings}/>
                    <Route path="/user/currency" exact component={Currencies}/>
                    <Route path="/user/notification" exact component={Notification}/>
                    <Route path="/user/employee" exact component={Employees}/>
                    <Route path="/edi" component={EDILayout}/>
                    <Route path="/roaming" component={RoamingLayout}/>
                    <Route path="/warehouse" component={WarehouseLayout}/>
                    <Route path="/crm" component={CrmLayout}/>
                    <Route path={"/kanban"} component={KanbanLayout}/>
                    <Route path={"/cash-box"} component={CashBoxLayout}/>
                    {/*<Route path="/marketplace" component={MarketPlaceLayout}/>*/}
                    {isAdmin && <Route path="/admin" component={AdminLayout}/>}
                </Switch>
                {<Footer/>}
            </div>
        </div>
    );
};

export default MainLayout;
