import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    selectSupervisorBindings,
    selectSupervisorBindingsFilter,
    selectSupervisorBindingsLoading,
    selectSupervisorBindingsPagination,
    setSupervisorBindingsPagination
} from "../../../../app/store/reducers/organization-binding/organizationBindingReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../common/advance-table/AdvanceTableFooter";
import AdvanceTableCustomPagination2 from "../../item/datatable/AdvanceTableCustomPagination2";
import DataTableHeader from "./DataTableHeader";
import {
    CashBoxAccessTypes,
    ItemsAccessTypes, ItemsPriceAccessTypes,
    SalesAccessTypes, SalesPaymentAccessTypes,
    WarehousesAccessTypes
} from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SupervisorBindingsDataTable = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const loading = useSelector(selectSupervisorBindingsLoading);
    const bindings = useSelector(selectSupervisorBindings);
    const filters = useSelector(selectSupervisorBindingsFilter);
    const pagination = useSelector(selectSupervisorBindingsPagination);
    const columns = useMemo(() => {
        return [
            {
                Header: "#",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: ({row}) => {
                    return `${row.index + 1}`;
                }
            },
            {
                accessor: 'partner',
                Header: t(lang, "crm.common.datatable.name"),
                Cell: ({row: {original}}) => {
                    return (
                        <Link to={`/warehouse/partners/supervisor/${original.id}`}>
                            {original.owner_organization_name}
                        </Link>
                    );
                }
            },
            {
                accessor: 'itemsAccessType',
                Header: t(lang, "partners.common.items_access"),
                Cell: ({row: {original}}) => {
                    const option = ItemsAccessTypes.find(o => o.value === original.item_access_type);
                    return (
                        <span>
                            <FontAwesomeIcon icon={option?.icon} className="me-1"/>
                            {t(lang, option?.label)}
                        </span>
                    )
                }
            },
            {
                accessor: 'itemsPriceAccessType',
                Header: t(lang, "partners.common.items_price_access"),
                Cell: ({row: {original}}) => {
                    const option = ItemsPriceAccessTypes.find(o => o.value === original.item_price_access_type);
                    return (
                        <span>
                            <FontAwesomeIcon icon={option?.icon} className="me-1"/>
                            {t(lang, option?.label)}
                        </span>
                    )
                }
            },
            {
                accessor: 'salesAccessType',
                Header: t(lang, "partners.common.sales_access"),
                Cell: ({row: {original}}) => {
                    const option = SalesAccessTypes.find(o => o.value === original.sales_access_type);
                    return (
                        <span>
                            <FontAwesomeIcon icon={option?.icon} className="me-1"/>
                            {t(lang, option?.label)}
                        </span>
                    )
                }
            },
            {
                accessor: 'salesPaymentAccessType',
                Header: t(lang, "partners.common.sales_payment_access"),
                Cell: ({row: {original}}) => {
                    const option = SalesPaymentAccessTypes.find(o => o.value === original.sales_payment_access_type);
                    return (
                        <span>
                            <FontAwesomeIcon icon={option?.icon} className="me-1"/>
                            {t(lang, option?.label)}
                        </span>
                    )
                }
            },
            {
                accessor: 'warehousesAccessType',
                Header: t(lang, "partners.common.warehouses_access"),
                Cell: ({row: {original}}) => {
                    const option = WarehousesAccessTypes.find(o => o.value === original.warehouse_access_type);
                    return (
                        <span>
                            <FontAwesomeIcon icon={option?.icon} className="me-1"/>
                            {t(lang, option?.label)}
                        </span>
                    )
                }
            },
            {
                accessor: 'cashBoxesAccessType',
                Header: t(lang, "partners.common.cash_boxes_access"),
                Cell: ({row: {original}}) => {
                    const option = CashBoxAccessTypes.find(o => o.value === original.cash_box_access_type);
                    return (
                        <span>
                            <FontAwesomeIcon icon={option?.icon} className="me-1"/>
                            {t(lang, option?.label)}
                        </span>
                    )
                }
            },
        ];
    }, [lang]);
    const data = useMemo(() => {
        //TODO: add filters if they given
        return bindings;
    }, [bindings, filters])

    const onLimitChange = (limit) => dispatch(setSupervisorBindingsPagination({limit: limit, page: pagination.page}));
    const onPageChange = (page) => dispatch(setSupervisorBindingsPagination({limit: pagination.limit, page: page}));

    return (
        <AdvanceTableWrapper columns={columns}
                             data={data}
                             width={'50px'}
                             pagination
                             perPage={pagination.limit}
        >
            <Card className="mb-3">
                <Card.Header>
                    <DataTableHeader table />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable table
                                  headerClassName="bg-200 text-900 text-wrap align-middle"
                                  rowClassName="align-middle white-space-nowrap"
                                  tableProps={{
                                      size: 'sm',
                                      striped: true,
                                      className: 'fs--1 mb-0 overflow-hidden',
                                  }}
                                  isLoading={loading}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter table/>
                    <AdvanceTableCustomPagination2 rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                                   onPageChange={onPageChange}
                                                   onLimitChange={onLimitChange}
                                                   table
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default SupervisorBindingsDataTable;