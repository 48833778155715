import React, {useState} from 'react';
import {Card, Dropdown} from "react-bootstrap";
import Flex from "../../../common/Flex";
import {useSelector} from "react-redux";
import {selectActiveOrganization, selectIsLoginWithCertificate} from "../../../../app/store/reducers/auth/authReducer";
import {Link} from "react-router-dom";
import FalconCardHeader from "../../../common/FalconCardHeader";
import OrganizationChooser from "../../organization/OrganizationChooser";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectCompany} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import RoamingInvoiceVatRegStatusBadge from "../../roaming/invoice/form/RoamingInvoiceVatRegStatusBadge";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ActiveOrganizationComponent = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const company = useSelector(selectCompany);
    const activeOrganization = useSelector(selectActiveOrganization)
    const isLoginWithCertificate = useSelector(selectIsLoginWithCertificate);
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Dropdown navbar={true} as="li" onToggle={handleToggle}>
            <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!" className="px-1 nav-link px-0">
                <FontAwesomeIcon icon={faUserCircle}
                                 transform="shrink-6"
                                 className="fs-5 text-facebook d-lg-none"
                />
                <Card className="d-none d-lg-block">
                    <Card.Body className="py-0">
                        <Flex justifyContent="between" alignItems="center">
                            <div className="text-center">
                                {activeOrganization?.inn && <div>
                                    <RoamingInvoiceVatRegStatusBadge identifier={company?.person_num || company?.inn}
                                                                     vatRegStatus={company?.vat_rate_status}
                                                                     vatRegCode={company?.vat_reg_code}
                                    />
                                </div>}

                                {!activeOrganization?.inn &&
                                    <p className="mb-0 text-dark fst-italic">{t(lang, 'common.organization.no.inn')}</p>}

                                <p className="mb-0 fw-bold text-dark">{activeOrganization?.name}</p>
                                <p className="mb-0 fs--2 fst-italic ">
                                    {company && <span className="float-start">
                                        Инн: {company?.person_num || company?.inn}
                                    </span>}
                                    <span className="float-end">Код: {activeOrganization?.organization_code}</span>
                                </p>
                            </div>
                        </Flex>
                    </Card.Body>
                </Card>
            </Dropdown.Toggle>
            {isLoginWithCertificate === 0 &&
                <Dropdown.Menu className="dropdown-menu-card" style={{left: '50%', transform: 'translateX(-50%)'}}>
                    <Card className="dropdown-menu-notification shadow-none" style={{maxWidth: '20rem'}}>
                        <FalconCardHeader className="card-header" title={t(lang, 'edi.choose_organization.title')}
                                          titleTag="h6" light={false}/>
                        <OrganizationChooser/>
                    </Card>
                </Dropdown.Menu>}
        </Dropdown>
    );
};

export default ActiveOrganizationComponent;


