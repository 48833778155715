import {Card, Spinner} from "react-bootstrap";
import AdvanceTableWrapper from "../../../../components/common/advance-table/AdvanceTableWrapper";
import EImzoSignerDataTableHeader from "./EImzoSignerDataTableHeader";
import AdvanceTable from "../../../../components/common/advance-table/AdvanceTable";
import {useEffect, useState} from "react";
import billingService from "../../../../services/billingService";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const EImzoSignerDataTable = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang);
    const t = Translate;
    useEffect(() => {
        setLoading(true)
        billingService.getEImzoSigners()
            .then(({data}) => setData(data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
    }, []);
    const setDataFunc = (data) => setData(data)
    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            Cell: ({row}) => {
                return `${row.index + 1}`;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, 'admin.e-imzo.table.header.name.title'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                return (
                    <strong>{original.name}</strong>
                );
            }
        },
        {
            accessor: 'host',
            Header: t(lang,"admin.e-imzo.table.header.host.title"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.host}</span>
                )
            }
        },
        {
            accessor: 'innOrPinfl',
            Header: t(lang,"admin.e-imzo.table.header.inn_or_pinfl.title"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.inn_or_pinfl}</span>
                )
            }
        }
    ]

    return (
        <>
            {loading ? <div className="py-4 d-flex align-items-center justify-content-center">
                <Spinner animation="border"/>
            </div> : <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={data}
            >
                <Card className="mb-3">
                    <Card.Header className="bg-light">
                        <EImzoSignerDataTableHeader setDataFunc={setDataFunc}/>
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>}

        </>
    );
};

export default EImzoSignerDataTable;
