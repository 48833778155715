export const BIND_DRAFT_REFUND_ITEM_REQUESTED = "BIND_DRAFT_REFUND_ITEM_REQUESTED";
export const BIND_DRAFT_REFUND_ITEM_SUCCEED = "BIND_DRAFT_REFUND_ITEM_SUCCEED";
export const BIND_DRAFT_REFUND_ITEM_FAILED = "BIND_DRAFT_REFUND_ITEM_FAILED";

export const APPROVE_DRAFT_REFUND_REQUESTED = "APPROVE_DRAFT_REFUND_REQUESTED";
export const APPROVE_DRAFT_REFUND_SUCCEED = "APPROVE_DRAFT_REFUND_SUCCEED";
export const APPROVE_DRAFT_REFUND_FAILED = "APPROVE_DRAFT_REFUND_FAILED";

export const DELETE_DRAFT_REFUND_REQUESTED = "DELETE_DRAFT_REFUND_REQUESTED";
export const DELETE_DRAFT_REFUND_SUCCEED = "DELETE_DRAFT_REFUND_SUCCEED";
export const DELETE_DRAFT_REFUND_FAILED = "DELETE_DRAFT_REFUND_FAILED";
