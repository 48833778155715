import React from "react";
import {ListGroup} from "react-bootstrap";
import {Link, useRouteMatch} from "react-router-dom";
import Flex from "../../../components/common/Flex";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const Dashboard = () => {
    const {url} = useRouteMatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <ListGroup>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/orders`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.orders.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.orders.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/shipments`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.shipments.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.shipments.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/invoices`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.invoices.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.invoices.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/return-orders`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.return_orders.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.return_orders.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/return-shipments`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.return_shipments.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.return_shipments.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/return-invoices`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.return_invoices.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.return_invoices.description')}
                </p>
            </ListGroup.Item>

            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/branches`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.branches.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.branches.description')}
                </p>
            </ListGroup.Item>

            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/attorneys`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.dashboard.attorneys.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.dashboard.attorneys.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/contracts-formation`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.navbar.routes.contract_formation')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'edi.navbar.routes.contract_formation.description')}
                </p>
            </ListGroup.Item>
        </ListGroup>
    )
};

export default Dashboard;