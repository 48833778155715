import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {UZS} from "../../../../../enum/CurrencyCode";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import WarehouseOperationItemSelect from "../../components/WarehouseOperationItemSelect";
import classNames from "classnames";
import InputGroupWithCurrency from "../../../price/components/InputGroupWithCurrency";
import numeral from "numeral";
import {ErrorMessage} from "@hookform/error-message";
import PackageTypeItem from "../../components/PackageTypeItem";
import WarehouseOperationWarehouseSelect from "../../components/WarehouseOperationWarehouseSelect";


const TransferUpdateItemModalForm = () => {
    const numberFormat = useSelector(selectNumberFormat)
    const currencies = useSelector(selectCurrency)
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate



    // state
    const [priceType, setPriceType] = useState(true)
    const {errors} = form.formState



    // watch
    const packageMeasurements = useWatch({name: 'package_measurements', exact: true})
    const constantPrice = useWatch({name: 'constantPrice', exact: true})
    const commonPriceUpdate = form.watch('commonPriceUpdate')
    const warehouse = form.watch('warehouse')
    const quantity = form.watch('quantity')
    const warehouseFrom = form.watch('warehouse_from_id')
    const warehouseTo = form.watch('warehouse_to_id')
    const itemId = useWatch({name: 'item_id', exact: true})


    const totalSum = +quantity * (+packageMeasurements?.quantity || 0) * +commonPriceUpdate?.amount
    const totalSumCurrencyName = currencies?.find(x => x.id === commonPriceUpdate?.currency_id)?.name



    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
        setPrice({priceType: priceType, item: option})
    }

    const onChangePriceType = (option) => {
        const product = items?.find(item => item?.item?.id === itemId)
        setPriceType(option)
        setPrice({priceType: option, item: product})
    }

    const setPrice = ({priceType, item}) => {
        if (priceType) {
            form.setValue('commonPriceUpdate.amount', item ? item?.price?.common_price?.amount : 0)
            form.setValue('commonPriceUpdate.currency_id', item ? item?.price?.common_price?.currency?.global_currency_id : currencies?.find(c => c?.code === UZS)?.id)
            form.setValue('constantPrice', item ? item?.price?.common_price?.amount : 0)
        } else {
            form.setValue('commonPriceUpdate.amount', item ? item?.price?.bulk_price?.amount : 0)
            form.setValue('commonPriceUpdate.currency_id', item ? item?.price?.bulk_price?.currency?.global_currency_id : currencies?.find(c => c?.code === UZS)?.id)
            form.setValue('constantPrice', item ? item?.price?.bulk_price?.amount : 0)
        }
    }




    return(
        <>
            <Row>
                <Col xs={12} >
                    <Form.Group>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.product_name")}</Form.Label>
                        <Controller
                            name="item_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationItemSelect
                                        options={items}
                                        allowImage={true}
                                        warehouseId={warehouseFrom}
                                        defaultValue={field?.value}
                                        isClearable
                                        onChange={option => {
                                            field?.onChange(option ? option?.item?.id : null)
                                            onChangeItem(option)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.item_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.item_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Label>{t(lang, "warehouse.operation.item.common.warehouse_from")}</Form.Label>
                    <Controller
                        name="warehouse_from_id"
                        rules={{
                            required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                            validate: value => warehouseTo !== value || t(lang, "warehouse.operation.item.common.same_warehouse.validation.is_required")
                        }}
                        render={({field}) => {
                            return (
                                <WarehouseOperationWarehouseSelect
                                    options={warehouse}
                                    defaultValue={field?.value}
                                    onChange={option => field?.onChange(option?.id)}
                                    className={classNames({
                                        'is-invalid': errors?.warehouse_from_id
                                    })}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.warehouse_from_id?.message}
                    </Form.Control.Feedback>
                </Col>
                <Col xs={12}>
                    <Form.Label>{t(lang, "warehouse.operation.item.common.warehouse_to")}</Form.Label>
                    <Controller
                        name="warehouse_to_id"
                        rules={{
                            required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                            validate: value => warehouseFrom !== value || t(lang, "warehouse.operation.item.common.same_warehouse.validation.is_required")
                        }}
                        render={({field}) => {
                            return (
                                <WarehouseOperationWarehouseSelect
                                    options={warehouse}
                                    defaultValue={field?.value}
                                    onChange={option => field?.onChange(option?.id)}
                                    className={classNames({
                                        'is-invalid': errors?.warehouse_to_id
                                    })}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.warehouse_to_id?.message}
                    </Form.Control.Feedback>
                </Col>
                <Col xs={12} >
                    <Form.Group>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.sum")}</Form.Label>
                        <Controller
                            name='commonPriceUpdate'
                            render={({field}) => (
                                <InputGroupWithCurrency
                                    defaultCurrencyId={commonPriceUpdate?.currency_id}
                                    defaultPrice={commonPriceUpdate?.amount}
                                    constantDefaultPrice={constantPrice}
                                    defaultBulkPrice={priceType}
                                    onChangeBulkPrice={(priceTypeOne) => {
                                        onChangePriceType(priceTypeOne)
                                    }}
                                    allowBulkPrice={true}
                                    onChangePrice={(price) => {
                                        form?.setValue('commonPriceUpdate.amount', price);
                                        form?.trigger('commonPriceUpdate');
                                    }}
                                    onChangeCurrency={(currency) => {
                                        form?.setValue('commonPriceUpdate.currency_id', currency ? currency?.id : null);
                                        form?.trigger('commonPriceUpdate');
                                    }}
                                    inputProps={{
                                        className: classNames({
                                            'is-invalid': errors?.commonPriceUpdate
                                        }, 'form-control'),
                                        placeholder: t(lang, "warehouse.operation.item.common.sum:placeholder"),
                                        options: {
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 4
                                        }
                                    }}
                                    className={classNames({
                                        'is-invalid': errors?.commonPriceUpdate
                                    })}
                                />
                            )}
                        />
                        <span className="text-info fs--1">
                                    {t(lang, "warehouse.operation.item.common.costPrice")}
                            {numeral.formats[numberFormat].format(13000)}
                        </span>
                        <ErrorMessage name='commonPriceUpdate'
                                      errors={errors}
                                      as={<Form.Control.Feedback type="invalid"/>}
                        />
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group className="d-flex flex-column">
                        <Form.Label>{t(lang, "warehouse.operation.item.common.quantity")}</Form.Label>
                        <InputGroup>
                            <div className="d-flex w-100">
                                <Form.Control
                                    type="text"
                                    id="quantity"
                                    {...form.register("quantity", {
                                        required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                        pattern: {
                                            value: /^[0-9]*[\.,]?[0-9]*$/,
                                            message: "100 | 1.2 | 1,5"
                                        }
                                    })}
                                    placeholder={t(lang, "warehouse.operation.item.common.quantity:placeholder")}
                                    // isInvalid={errors?.quantity}
                                    className={classNames("form-control w-100")}
                                />
                                <Controller
                                    name="package_measurements"
                                    render={({field}) => {
                                        return (
                                            <PackageTypeItem
                                                itemId={itemId}
                                                defaultValue={field?.value}
                                                onChange={option => {
                                                    field?.onChange(option)
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </div>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.quantity?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <span className="text-nowrap fw-bold my-auto fs-1">
                                {t(lang, "warehouse.operation.item.common.total_sum")}:
                        <span className="mx-1">{numeral.formats[numberFormat].format(totalSum)}</span>
                        <span className="text-info">{totalSumCurrencyName}</span>
                    </span>
                </Col>
            </Row>
        </>
    )
}


export default TransferUpdateItemModalForm;