import dayjs from "dayjs";
import numeral from "numeral";
import {Link} from "react-router-dom";
import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SoftBadge from "../../../../../common/SoftBadge";
import IconButton from "../../../../../common/IconButton";
import {Card, OverlayTrigger, Popover} from "react-bootstrap";
import DraftPaymentDataTableHeader from "./DraftPaymentDataTableHeader";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTableCustomPagination from "../../../../../common/advance-table/AdvanceTableCustomPagination";
import {selectLang, selectNumberFormat, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {isApprovedCheckedWarehouseOperation} from "../../../../warehouse-operation/enum/warehouseOperationWrapper";
import {
    changeDraftPaymentFilterOptionsAsync,
    selectDraftPayments,
    selectDraftPaymentsCount,
    selectDraftPaymentsFilterOptions
} from "../../../../../../app/store/reducers/crm/draft-payment/draftPaymentReducer";

const separationCurrenciesAndTotalSum = (states) => {
    let groupByCurrencyId = {};

    states.forEach(state => {
        if (state?.currency?.id in groupByCurrencyId) {
            groupByCurrencyId[state?.currency?.id].totalSum += state?.amount
        } else {
            if (state?.currency?.id) {
                groupByCurrencyId[state?.currency?.id] = {
                    id: state?.currency?.id,
                    name: state?.currency?.name,
                    totalSum: state?.amount
                }
            }
        }
    })
    return Object.values(groupByCurrencyId)
};

const DraftPaymentDataTable = () => {
    const filters = useSelector(selectDraftPaymentsFilterOptions);
    const draftPayments = useSelector(selectDraftPayments);
    const numberFormat = useSelector(selectNumberFormat);
    const count = useSelector(selectDraftPaymentsCount);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const columns = [
        {
            Header: "#",
            id: "row",
            cellProps: {width: '35px'},
            Cell: ({row}) => {
                return (
                    <OverlayTrigger
                        placement={'bottom'}
                        trigger={['hover', 'focus']}
                        overlay={
                            <Popover id="popover-basic" className='mt-0'>
                                <Popover.Header>
                                    <SoftBadge className={'d-block text-center fs--1'} bg={row?.original?.is_approved ? 'success' : 'warning'}>
                                        {t(lang, isApprovedCheckedWarehouseOperation(row?.original?.is_approved))}
                                    </SoftBadge>
                                </Popover.Header>
                            </Popover>
                        }
                    >
                        <SoftBadge className={'d-block text-center fs--1 cursor-pointer'} bg={row?.original?.is_approved ? 'success' : 'warning'}>
                            {(filters.page - 1) * filters.limit + row.index + 1}
                        </SoftBadge>
                    </OverlayTrigger>
                )
            },
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "crm.draft_payment.datatable.column.contractor"),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`/crm/contractor-card/${original?.contractor?.id}`} className="d-flex text-wrap">
                        {original?.contractor?.name}
                    </Link>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "crm.draft_payment.datatable.column.created_at"),
            cellProps: {className: 'pe-4'},
            Cell: ({row: {original}}) => {
                return (
                    <span>{dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                );
            }
        },
        {
            accessor: 'cash_box_states',
            Header: t(lang, "crm.draft_payment.datatable.column.cash_box_state"),
            headerProps: {className: 'text-end'},
            cellProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                return (
                    <Fragment>
                        {separationCurrenciesAndTotalSum(original.cash_box_states).map((payment, index) => {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                                        {numeral['formats'][numberFormat].format(payment.totalSum)}
                                        &nbsp;
                                        {payment.name}
                                    </SoftBadge>
                                </div>
                            )
                        })}
                    </Fragment>
                )
            }
        },
        {
            accessor: 'debt_states',
            Header: t(lang, "crm.draft_payment.datatable.column.debt_state"),
            headerProps: {className: 'text-end'},
            cellProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                return (
                    <Fragment>
                        {original.debt_states.map((payment, index) => {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                                        {numeral['formats'][numberFormat].format(payment.amount)}
                                        &nbsp;
                                        {payment.currency.name}
                                    </SoftBadge>
                                </div>
                            )
                        })}
                    </Fragment>
                )
            }
        },
        {
            accessor: 'none',
            headerProps: {className: 'text-end'},
            cellProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                return (
                    <IconButton as={Link} to={`/crm/contractor-draft-payment/view/${original.id}`} size={'sm'} variant={'falcon-info'} icon={'eye'}/>
                )
            },
        }
    ];

    const onPageChange = (page) => dispatch(changeDraftPaymentFilterOptionsAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeDraftPaymentFilterOptionsAsync({...filters, limit: limit, page: 1}));

    return (
        <AdvanceTableWrapper
            perPage={1000}
            columns={columns}
            pagination={true}
            data={draftPayments}
        >
            <Card className="mb-3">
                <Card.Header className={'bg-light'}>
                    <Card.Title>{t(lang, 'crm.draft_payment.datatable.title')}</Card.Title>
                </Card.Header>
                <Card.Header>
                    <DraftPaymentDataTableHeader/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName={'bg-200 text-900 text-wrap align-middle'}
                        rowClassName={'align-middle white-space-nowrap border-bottom border-2'}
                        tableProps={{
                            size: 'md',
                            striped: false,
                            className: 'fs--1 mb-2 overflow-hidden',
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableCustomPagination
                        count={count}
                        page={filters.page}
                        limit={filters.limit}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default DraftPaymentDataTable;