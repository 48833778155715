import {useSelector} from "react-redux";
import {
    selectWarehouseInitializationState,
    selectWarehouses, WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../app/store/reducers/item/itemReducer";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import ItemNo from "../../../../components/hippo/warehouse-operation/components/ItemNo";
import AddRevisionForm from "../../../../components/hippo/warehouse-operation/revision/forms/AddRevisionForm";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";


const AddRevision = () => {
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)





    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )


    return(
        <>
            <AddRevisionForm/>
        </>
    )
}

export default AddRevision;
