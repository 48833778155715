import React, {useState} from 'react';
import {Card, Tab, Tabs} from "react-bootstrap";
import AdminBought from "../component/AdminBought";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import Payments from "./Payments";
import AdminReport from "../component/AdminReport";

export const PAYMENTS = '1'
export const ACTIVE_PACKAGES = '2'
export const REPORT = '3'

const PaymentTab = () => {
    const [key, setKey] = useState(PAYMENTS)
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Card>
            <Tabs variant={'pills'}  activeKey={key} onSelect={key => {
                setKey(key)
            }} className='border-bottom p-3'>
                <Tab className="bg-light" eventKey={PAYMENTS} title={t(lang, 'admin.navbar.routes.payments')}>
                    <Payments tab={key}/>
                </Tab>
                <Tab className="bg-light" eventKey={ACTIVE_PACKAGES} title={t(lang, 'admin.navbar.routes.activated.packages')}>
                    <AdminBought tab={key} />
                </Tab>
                <Tab className="bg-light" eventKey={REPORT} title={t(lang, 'admin.navbar.routes.report')}>
                    <AdminReport tab={key} />
                </Tab>
            </Tabs>
        </Card>
    );
};

export default PaymentTab;
