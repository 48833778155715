import React from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {changeFiltersAsync, selectFilters} from "../../../../app/store/reducers/item-history/itemHistoryReducer";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {OperationTypes} from "../../../../enum/OperationTypes";
import Select from 'react-select';
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import IconButton from "../../../common/IconButton";
import {addDays} from "date-fns";
import SelectCategory from "../components/SelectCategory";
import {selectAllCategories} from "../../../../app/store/reducers/category/categoryReducer";
import SearchItem from "../../draft-item/components/SearchItem";
import {selectCategoriesTree, selectItems} from "../../../../app/store/reducers/item/itemReducer";
import itemWrapper from "../enum/itemWrapper";

const ItemHistoryDataTableHeader = () => {
    const historyFilters = useSelector(selectFilters)
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const warehouses = useSelector(selectWarehouses)
    const dateFormat = useSelector(selectDateFormat);
    const categories = useSelector(selectAllCategories);
    const categoriesTree = useSelector(selectCategoriesTree);
    const items = useSelector(selectItems);
    const form = useForm({
        defaultValues: {
            start: historyFilters.start ? dayjs(historyFilters.start).toDate() : new Date(),
            end: historyFilters.end ? dayjs(historyFilters.end).toDate() : new Date(),
            types: historyFilters.types || [],
            warehouses: historyFilters.warehouses || [],
            item_ids: historyFilters.item_ids || [],
            item_id: historyFilters.item_id || null,
            category_id: historyFilters.category_id || null
        },
    });
    const {fields, append, replace} = useFieldArray({
        control: form.control,
        name: "types",
    });

    const onChangeTypeHandler = (event, selectedType) => {
        if (event.currentTarget.checked && !fields.includes(selectedType)) {
            append({value: selectedType})
        } else {
            replace(fields.filter(field => field.value !== selectedType))
        }
    };
    const onSubmitFilter = ({start, end, types, warehouses, item_id, category_id}) => {
        const ts = types?.map(t => t.value);
        const ws = warehouses?.map(w => w.id);
        const params = {
            page: 1,
            limit: historyFilters.limit,
            start: start ? dayjs(start).format("YYYY-MM-DD HH:mm:ss") : null,
            end: end ? dayjs(end).format("YYYY-MM-DD HH:mm:ss") : null,
            types: ts,
            warehouses: ws,
            item_id: item_id || null,
            item_ids: category_id ? items.filter(i => itemWrapper.filterCategory(categoriesTree, category_id, i.item.category?.id)).map(i => i.item.id) : [],
            category_id: category_id || null,
        };
        dispatch(changeFiltersAsync(params));
    };
    const isChecked = (val) => {
        let checked = fields.find(type => {
            return type.value === val;
        });
        return !!checked
    };


    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmitFilter)}>
                <Row className="flex-between-center">
                    <Col xs={12} lg={3}>
                        <Form.Group>
                            <Form.Label>{t(lang, "crm.common.items.product")}</Form.Label>
                            <Controller name="item_id"
                                        render={({field}) => (
                                            <SearchItem defaultItemId={field.value}
                                                        onChange={(item) => field.onChange(item ? item.item.id : null)}
                                            />
                                        )}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={3}>
                        <Form.Group>
                            <Form.Label>{t(lang, "items.common.category")}</Form.Label>
                            <Controller name="category_id"
                                        render={({field}) => (
                                            <SelectCategory categories={categories}
                                                            defaultCategoryId={field.value}
                                                            onChange={(category) => field.onChange(category ? category.id : null)}
                                            />
                                        )}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={3}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'warehouse.common.item.title.warehouses')}</Form.Label>
                            <Controller name="warehouses"
                                        render={({field}) => {
                                            return (
                                                <Select
                                                    value={field.value}
                                                    options={warehouses}
                                                    getOptionLabel={(option) => option?.name}
                                                    getOptionValue={(option) => option?.id}
                                                    hideSelectedOptions
                                                    isMulti
                                                    isClearable
                                                    classNamePrefix="react-select"
                                                    placeholder="Филтер по склад"
                                                    size={'sm'}
                                                    onChange={warehouse => {
                                                        return field.onChange(warehouse || null)
                                                    }}
                                                />
                                            )
                                        }}
                            />
                        </Form.Group>
                    </Col>

                    <Form.Group as={Col} xs={12} lg={3}>
                        <Form.Label>{t(lang, "warehouse.items.history.filter.date_start")}</Form.Label>
                        <Controller name="start"
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            className='form-control'
                                            placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                            dateFormat={`${dateFormat} HH:mm:ss`}
                                            selected={field.value}
                                            onChange={field.onChange}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={60}
                                        />
                                    )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} lg={3}>
                        <Form.Label>{t(lang, "warehouse.items.history.filter.date_end")}</Form.Label>
                        <Controller name="end"
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            className='form-control'
                                            placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                            dateFormat={`${dateFormat} HH:mm:ss`}
                                            selected={field.value}
                                            onChange={field.onChange}
                                            maxDate={addDays(new Date(), 5)}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={60}
                                        />
                                    )}
                        />
                    </Form.Group>

                </Row>


                <hr/>

                <Row className={'mt-3'}>

                    <Col sm={6} xl={3}>
                        {OperationTypes[0].map((op, index) => {
                            return (
                                <Form.Group key={index}>
                                    <Form.Label className={'d-flex align-content-center pe-5'}>
                                        <Form.Switch checked={isChecked(op.value)} onChange={e => {
                                            onChangeTypeHandler(e, op.value);
                                        }}/>
                                        <div className={'m-0'}>{t(lang, op.label)}</div>
                                    </Form.Label>
                                </Form.Group>
                            )
                        })}
                    </Col>

                    <Col sm={6} xl={3}>
                        {OperationTypes[1].map((op, index) => {
                            return (
                                <Form.Group key={index}>
                                    <Form.Label className={'d-flex align-content-center pe-5'}>
                                        <Form.Switch checked={isChecked(op.value)} onChange={e => {
                                            onChangeTypeHandler(e, op.value);
                                        }}/>
                                        <div className={'m-0'}>{t(lang, op.label)}</div>
                                    </Form.Label>
                                </Form.Group>
                            )
                        })}
                    </Col>

                    <hr className={'d-xl-none'}/>

                    <Col sm={6} xl={3}>
                        {OperationTypes[2].map((op, index) => {
                            return (
                                <Form.Group key={index}>
                                    <Form.Label className={'d-flex align-content-center pe-5'}>
                                        <Form.Switch checked={isChecked(op.value)} onChange={e => {
                                            onChangeTypeHandler(e, op.value);
                                        }}/>
                                        <div className={'m-0'}>{t(lang, op.label)}</div>
                                    </Form.Label>
                                </Form.Group>
                            )
                        })}
                    </Col>

                    <Col sm={6} xl={2}>
                        {OperationTypes[3].map((op, index) => {
                            return (
                                <Form.Group key={index}>
                                    <Form.Label className={'d-flex align-content-center pe-5'}>
                                        <Form.Switch checked={isChecked(op.value)} onChange={e => {
                                            onChangeTypeHandler(e, op.value);
                                        }}/>
                                        <div className={'m-0'}>{t(lang, op.label)}</div>
                                    </Form.Label>
                                </Form.Group>
                            )
                        })}
                    </Col>

                    <Col xs={11} sm="auto" className="ms-auto d-flex align-items-end text-end pt-3">
                        <IconButton size="sm"
                                    transform="shrink-3"
                                    className="mx-2"
                                    icon="filter"
                                    onClick={form.handleSubmit(onSubmitFilter)}
                        >
                            <span>{t(lang, "crm.common.datatable.filter.show_button")}</span>
                        </IconButton>
                    </Col>
                </Row>

            </Form>
        </FormProvider>
    );
};

export default ItemHistoryDataTableHeader;
