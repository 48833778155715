import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import EditBulkItemsForm from "./bulk-item/EditBulkItemsForm";
import {faFilePen} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/IconButton";

const EditBulkItemsModal = ({items}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <IconButton size="sm"
                        variant="falcon-warning"
                        icon={faFilePen}
                        onClick={handleShow}
                        className="m-1"
            >
                {t(lang, "items.common.bulk_edit")}
            </IconButton>
            {show &&
                <Modal show={true}
                       onHide={handleClose}
                       fullscreen
                       className="p-0"
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, "items.common.bulk_edit")}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Body style={{position: 'initial'}}>
                            <EditBulkItemsForm editingItems={items}
                                               handleClose={handleClose}
                            />
                        </Modal.Body>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                                onClick={handleClose}
                                size="lg"
                        >
                            {t(lang, "items.common.cancel")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
};

export default React.memo(EditBulkItemsModal);