import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectOwnerBindings} from "../../../../app/store/reducers/organization-binding/organizationBindingReducer";

const SelectOwnerBindings = ({onChange, defaultBindingId, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ownerBindings = useSelector(selectOwnerBindings);

    return (
        <ReactSelect options={ownerBindings}
                     isSearchable={!defaultBindingId}
                     placeholder={t(lang, "items.common.select")}
                     getOptionLabel={(option) => option.supervisor_organization_name}
                     getOptionValue={(option) => option.id}
                     onChange={onChange}
                     value={ownerBindings.find(o => o.id === defaultBindingId) || null}
                     {...selectProps}
        />
    );
};

export default SelectOwnerBindings;