import React, {useState} from 'react';
import ContractorOrderDataTable from "./ContractorOrderDataTable";
import ContractorReturnOrderDataTable from "../return-order/ContractorReturnOrderDataTable";
import {Tab, Tabs} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const ContractorOrdersCard = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [activeTab, setActiveTab] = useState('orders')
    return (
        <div className={"mt-2"}>
            <Tabs variant={"pills"} activeKey={activeTab} id="controlled-tab-example" onSelect={(tab) => setActiveTab(tab)}>
                <Tab eventKey="orders" title={t(lang, 'edi.dashboard.orders.title')}
                     onSelect={() => setActiveTab("orders")}>
                    <ContractorOrderDataTable/>
                </Tab>
                <Tab eventKey="return_orders" title={t(lang, 'edi.dashboard.return_orders.title')}
                     onSelect={() => setActiveTab("return_orders")}>
                    <ContractorReturnOrderDataTable/>
                </Tab>
            </Tabs>
        </div>
    );
};

export default ContractorOrdersCard;