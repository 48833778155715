import React from 'react';
import IconButton from "../../../common/IconButton";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import withEditCategory from "./withEditCategory";

const EditCategoryButton = ({handleShow, buttonProps, children}) => {
    return (
        <IconButton icon={faPencil}
                    variant="falcon-warning"
                    onClick={handleShow}
                    {...buttonProps}
        >
            {children}
        </IconButton>
    );
};

export default withEditCategory(EditCategoryButton);