import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadDraftPaymentAsync} from "../../../../app/store/reducers/crm/draft-payment/draftPaymentReducer";
import DraftPaymentView from "../../../../components/hippo/crm/contractor/draft-payment/view/DraftPaymentView";
import {APPROVE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, DELETE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED} from "../../../../app/eventbus/contractor/contractorPaymentEvents";

const DraftPayment = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const [draftPayment, setDraftPayment] = useState(null);
    const routerHistory = useHistory();
    const {id} = useParams();

    useEffect(() => {
        loadDraftPayment()
    }, [id, activeOrganization]);

    useEffect(() => {
        const onDraftPaymentSuccessfullyApproved = EventBus.on(APPROVE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, (draftPayment) => {
            routerHistory.push(`/crm/contractor-payment/view/${draftPayment.payment.id}`);
        });

        const onDraftPaymentSuccessfullyDeleted = EventBus.on(DELETE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, () => {
            routerHistory.push('/crm/contractor-draft-payments');
        });

        return () => {
            EventBus.remove(APPROVE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, onDraftPaymentSuccessfullyApproved);
            EventBus.remove(DELETE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, onDraftPaymentSuccessfullyDeleted);
        };
    }, [])

    const loadDraftPayment = () => {
        loadDraftPaymentAsync(id)
            .then((loadedDraftPayment) => setDraftPayment(loadedDraftPayment))
            .catch(() => setDraftPayment(null))
    }

    if (!draftPayment)
        return <span>loading...</span>;

    return <DraftPaymentView draftPayment={draftPayment}/>;
};

export default DraftPayment;