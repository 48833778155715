import React from 'react';
import {Col, Row} from "react-bootstrap";
import OperationsItemsReportDataTableFilter from "./OperationsItemsReportDataTableFilter";
import Divider from "../../../common/Divider";
import OperationsItemsReportSummaryModal from "./OperationsItemsReportSummaryModal";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {
    FindOperationType,
    Purchase,
    Refund,
    Return,
    Revision,
    Sale,
    TransferIn,
    TransferOut, WriteOff
} from "../../../../enum/OperationTypes";
import SoftBadge from "../../../common/SoftBadge";
import DownloadOperationItemsReportToExcel from "./DownloadOperationItemsReportToExcel";

const OperationItemsReportDataTableHeader = ({report, loading, pagination, filters, onFilterChange, onDebouncedFilterChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <h5 className="mb-2">{t(lang, "items.common.datatable.filter")}</h5>
                <OperationsItemsReportDataTableFilter loading={loading}
                                                      filters={filters}
                                                      onFilterChange={onFilterChange}
                                                      onDebouncedFilterChange={onDebouncedFilterChange}
                />
            </Col>
            <Divider/>
            <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
                    <div className="d-flex align-items-center flex-wrap gap-2">
                        <SoftBadge bg={FindOperationType(Purchase)?.variant}
                        >
                            {t(lang, FindOperationType(Purchase)?.label)}
                        </SoftBadge>
                        <SoftBadge bg={FindOperationType(Refund)?.variant}
                        >
                            {t(lang, FindOperationType(Refund)?.label)}
                        </SoftBadge>
                        <SoftBadge bg={FindOperationType(Return)?.variant}
                        >
                            {t(lang, FindOperationType(Return)?.label)}
                        </SoftBadge>
                        <SoftBadge bg={FindOperationType(Sale)?.variant}
                        >
                            {t(lang, FindOperationType(Sale)?.label)}
                        </SoftBadge>
                        <SoftBadge bg={FindOperationType(Revision)?.variant}
                        >
                            {t(lang, FindOperationType(Revision)?.label)}
                        </SoftBadge>
                        <SoftBadge bg={FindOperationType(TransferIn)?.variant}
                        >
                            {t(lang, FindOperationType(TransferIn)?.label)}
                        </SoftBadge>
                        <SoftBadge bg={FindOperationType(TransferOut)?.variant}
                        >
                            {t(lang, FindOperationType(TransferOut)?.label)}
                        </SoftBadge>
                        <SoftBadge bg={FindOperationType(WriteOff)?.variant}
                        >
                            {t(lang, FindOperationType(WriteOff)?.label)}
                        </SoftBadge>
                    </div>
                    <div className="d-flex flex-wrap gap-1">
                        <DownloadOperationItemsReportToExcel filters={filters}
                                                             pagination={pagination}
                        />
                        <OperationsItemsReportSummaryModal report={report}/>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default OperationItemsReportDataTableHeader;