import {createSlice} from "@reduxjs/toolkit";
import EmployeeService from "../../../../services/employee/employeeService";
import EventBus from "../../../eventbus/EventBus";
import {
    ADD_EMPLOYEE_FAILED,
    ADD_EMPLOYEE_REQUESTED,
    ADD_EMPLOYEE_SUCCEED, DELETE_EMPLOYEE_FAILED,
    DELETE_EMPLOYEE_REQUESTED, DELETE_EMPLOYEE_SUCCEED,
    EDIT_EMPLOYEE_FAILED,
    EDIT_EMPLOYEE_REQUESTED,
    EDIT_EMPLOYEE_SUCCEED
} from "../../../eventbus/employeeEvents";

const employeeSlice = createSlice({
    name: 'employee',
    initialState: {
        employees: [],
        employeesCount: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateEmployees: (state, action) => {
            state.employees = action.payload;
        },
        updateEmployeesCount: (state, action) => {
            state.employeesCount = action.payload;
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
    },
});

export const selectEmployees = state => state.employee.employees;
export const selectEmployeesFilterOptions = state => state.employee.filterOptions;

export const addEmployeeAsync = (payload) => {
    EventBus.dispatch(ADD_EMPLOYEE_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        EmployeeService.addEmployee(payload)
            .then(addedEmployee => {
                EventBus.dispatch(ADD_EMPLOYEE_SUCCEED, addedEmployee);
                resolve(addedEmployee);
            })
            .catch(error => {
                EventBus.dispatch(ADD_EMPLOYEE_FAILED, error);
                reject(error);
            })
    });
};

export const editEmployeeAsync = (employeeId, payload) => {
    EventBus.dispatch(EDIT_EMPLOYEE_REQUESTED, employeeId);

    return new Promise((resolve, reject) => {
        EmployeeService.editEmployee(employeeId, payload)
            .then(editedEmployee => {
                EventBus.dispatch(EDIT_EMPLOYEE_SUCCEED, editedEmployee);
                resolve(editedEmployee);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_EMPLOYEE_FAILED, error);
                reject(error);
            })
    });
};

export const deleteEmployeeAsync = (employeeId) => {
    EventBus.dispatch(DELETE_EMPLOYEE_REQUESTED, employeeId);

    return new Promise((resolve, reject) => {
        EmployeeService.deleteEmployee(employeeId)
            .then(deletedEmployee => {
                EventBus.dispatch(DELETE_EMPLOYEE_SUCCEED, deletedEmployee);
                resolve(deletedEmployee);
            })
            .catch(error => {
                EventBus.dispatch(DELETE_EMPLOYEE_FAILED, error);
                reject(error);
            })
    });
};

export const getEmployeeAsync = (employeeId) => {
    return new Promise((resolve, reject) => {
        EmployeeService.getEmployee(employeeId)
            .then(loadedEmployee => resolve(loadedEmployee))
            .catch(error => reject(error))
    });
};

export const loadEmployeesAsync = () => dispatch => {
    EmployeeService.getEmployees()
        .then(response => dispatch(employeeSlice.actions.updateEmployees(response.data)))
        .catch(error => {
            dispatch(employeeSlice.actions.updateEmployees([]));
            console.log(error);
        })
};

export default employeeSlice.reducer;