import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingInvoiceCreate} from "../../../../enum/Permission/RoamingPermission";
import {useHistory} from "react-router-dom";
import SendRoamingInvoiceForm from "../../../../components/hippo/roaming/invoice/form/SendRoamingInvoiceForm";
import {
    loadRoamingInvoiceAsync,
    loadRoamingInvoiceDraftAsync
} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import useQuery from "../../../../components/hippo/roaming/invoice/form/useQuery";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {getSaleItemAsync} from "../../../../app/store/reducers/warehouse-operation/saleReducer";
import {loadWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {uniq} from "underscore";
import {
    loadCatalogsByCode,
    selectCompany,
    selectCurrentTurnOver
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {Button, Modal} from "react-bootstrap";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import numeral from "numeral";
import waybillV2Service from "../../../../services/roaming/roamingWaybillV2Service";
import roamingReferenceService from "../../../../services/roamingReference/roamingReferenceService";

const SendRoamingInvoice = () => {
    const {invoice_id, draft_invoice_id, sale_id, waybill_id, waybill_v2_id, type, is_edit} = useQuery();
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentTurnOver = useSelector(selectCurrentTurnOver);
    const permission = useSelector(selectAccountRolePermissions);
    const items = useSelector(selectItems);
    const history = useHistory();
    const [invoice, setInvoice] = useState(null);
    const [draftInvoice, setDraftInvoice] = useState(null);
    const [sale, setSale] = useState(null);
    const [waybill, setWaybill] = useState(null);
    const [v2Waybills, setV2Waybills] = useState([]);
    const [catalogs, setCatalogs] = useState([]);
    const [turnOverAlert, setTurnOverAlert] = useState(false)
    const [sellerCompanyPrivileges, setSellerCompanyPrivileges] = useState([]);
    const [turnoverCompanyPrivileges, setTurnoverCompanyPrivileges] = useState([]);
    const [buyerCompanyPrivileges, setBuyerCompanyPrivileges] = useState([]);
    const company = useSelector(selectCompany);
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);


    useEffect(() => {
        if (company && currentTurnOver) {
            if (!company.vat_reg_code) {
                if (currentTurnOver.currentTurnover > currentTurnOver.limit) {
                    setTurnOverAlert(true)
                }
            }
        }
    }, [currentTurnOver, company])

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingInvoiceCreate))) {
            if (history.length > 1) {
                history.push('/roaming/invoices/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        invoice_id && loadRoamingInvoiceAsync(invoice_id)
            .then((loadedInvoice) => {
                setInvoice(loadedInvoice);
            })
            .catch((error) => {
                setInvoice(null);
            });
    }, [invoice_id, activeOrganization]);

    useEffect(() => {
        draft_invoice_id && loadRoamingInvoiceDraftAsync(draft_invoice_id)
            .then((loadedDraftInvoice) => {
                setDraftInvoice(loadedDraftInvoice);
            })
            .catch((error) => {
                setDraftInvoice(null);
            })
    }, [draft_invoice_id, activeOrganization]);

    useEffect(() => {
        sale_id && getSaleItemAsync({saleId: sale_id})
            .then((response) => {
                const sale = response.data;
                setSale(sale);
            })
            .catch((error) => {
                setSale(null);
            })
    }, [sale_id, activeOrganization]);

    useEffect(() => {
        waybill_id && loadWaybillAsync(waybill_id)
            .then((response) => {
                setWaybill(response);
            })
            .catch((error) => {
                console.log(error);
                setWaybill(null);
            })
    }, [waybill_id, activeOrganization]);

    useEffect(() => {
        if (waybill_v2_id) {
            const v2WaybillIds = waybill_v2_id.split(",");
            const verifiedIds = v2WaybillIds.filter((id) => id.length === 24);
            const payload = {
                identifications: verifiedIds
            };

            waybillV2Service.getBulkWaybillsV2(payload)
                .then((response) => {
                    setV2Waybills(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setV2Waybills([]);
                })
        }
    }, [waybill_v2_id, activeOrganization]);

    useEffect(async () => {
        if (invoice) {
            if (!invoice.executor.nds_status && !invoice.executor.nds_registration_code) {
                const {data: turnoverPrivileges} = await roamingReferenceService.getTurnoverPrivileges({inn: invoice.executor.inn});
                setTurnoverCompanyPrivileges(turnoverPrivileges || []);
            }

            const {data: executorPrivileges} = await roamingReferenceService.getExecutorCompanyPrivileges({inn: invoice.executor.inn});
            setSellerCompanyPrivileges(executorPrivileges || []);

            const {data: customerPrivileges} = await roamingReferenceService.getCustomerCompanyPrivileges({inn: invoice.customer.inn});
            setBuyerCompanyPrivileges(customerPrivileges || []);

            const unknownCatalogCodes = uniq(invoice.items.map((item) => item.catalog?.code || ""));
            const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
            setCatalogs(catalogs || []);
        }
    }, [invoice]);

    useEffect(async () => {
        if (draftInvoice) {
            const roamingInvoiceJson = JSON.parse(draftInvoice.content);

            if (!roamingInvoiceJson.Seller.VatRegStatus && !roamingInvoiceJson.Seller.VatRegCode) {
                const {data: turnoverPrivileges} = await roamingReferenceService.getTurnoverPrivileges({inn: roamingInvoiceJson.SellerTin});
                setTurnoverCompanyPrivileges(turnoverPrivileges || []);
            }

            const {data: executorPrivileges} = await roamingReferenceService.getExecutorCompanyPrivileges({inn: roamingInvoiceJson.SellerTin});
            setSellerCompanyPrivileges(executorPrivileges || []);

            const {data: customerPrivileges} = await roamingReferenceService.getCustomerCompanyPrivileges({inn: roamingInvoiceJson.BuyerTin});
            setBuyerCompanyPrivileges(customerPrivileges || []);

            const unknownCatalogCodes = uniq(roamingInvoiceJson.ProductList.Products.map((item) => item.CatalogCode || ""));
            const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
            setCatalogs(catalogs || []);
        }
    }, [draftInvoice]);

    useEffect(async () => {
        if (sale) {
            try {
                const unknownCatalogCodes = sale.items.map((item) => {
                    const originalItem = items.find(i => i.item.name.trim().toLowerCase() === item.warehouse_item.name.trim().toLowerCase());
                    return (originalItem && originalItem.item.tax && originalItem.item.tax.catalog?.code) ? originalItem.item.tax.catalog.code : "";
                });
                const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
                setCatalogs(catalogs);
            } catch (e) {
                setCatalogs([]);
            }
        }
    }, [sale, items]);

    useEffect(async () => {
        if (waybill) {
            try {
                const unknownCatalogCodes = uniq(waybill.items.map((item) => item.catalog?.code || ""));
                const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
                setCatalogs(catalogs)
            } catch (e) {
                setCatalogs([]);
            }
        }
    }, [waybill]);

    useEffect(async () => {
        if (v2Waybills.length) {
            try {
                const unknownCatalogCodes = [];
                v2Waybills.forEach((v2Waybill) => {
                    if (v2Waybill.roadway) {
                        v2Waybill.roadway.product_groups.forEach((productGroup) => {
                            productGroup.products.map((product) => {
                                if (product.catalog?.code)
                                    unknownCatalogCodes.push(product.catalog.code);
                            })
                        })
                    }

                    if (v2Waybill.railway) {
                        v2Waybill.railway.product_groups.forEach((productGroup) => {
                            productGroup.products.map((product) => {
                                if (product.catalog?.code)
                                    unknownCatalogCodes.push(product.catalog.code);
                            })
                        })
                    }

                });
                const catalogs = await loadCatalogsByCode({codes: uniq(unknownCatalogCodes)});
                setCatalogs(catalogs);
            } catch (error) {
                console.log(error);
                setCatalogs([]);
            }
        }
    }, [v2Waybills])

    return (
        <>
            {turnOverAlert && <Modal
                show={true}
                onHide={() => setTurnOverAlert(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter">{t(lang, 'roaming.invoice.alert.current-turnover.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!currentTurnOver &&
                        <p className="fw-bold">
                            {t(lang, 'roaming.invoice.alert.current-turnover.body', {
                                sum: numeral.formats[numberFormat].format(currentTurnOver.currentTurnover),
                                limit: numeral.formats[numberFormat].format(currentTurnOver.limit)
                            })}
                        </p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setTurnOverAlert(false)}>{t(lang, 'roaming.invoice.alert.current-turnover.button.ok')}</Button>
                </Modal.Footer>
            </Modal>}

            <SendRoamingInvoiceForm invoice={invoice}
                                    draftInvoice={draftInvoice}
                                    sale={sale}
                                    waybill={waybill}
                                    v2Waybills={v2Waybills}
                                    catalogs={catalogs}
                                    sellerCompanyPrivileges={sellerCompanyPrivileges}
                                    turnoverCompanyPrivileges={turnoverCompanyPrivileges}
                                    buyerCompanyPrivileges={buyerCompanyPrivileges}
                                    type={type}
                                    isEdit={is_edit}
                                    isEditOrCopy={!!(invoice || draftInvoice)}
            />
        </>
    );
};

export default SendRoamingInvoice;
