import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Card, Col, Row, Table} from "react-bootstrap";
import {GetPaymentTypeText} from "../../../../../enum/ContractorWrapper";
import numeral from "numeral";


const ContractorPayoutView = ({payout}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang)
    const t = Translate


    const separationCurrenciesAndTotalSum = (state) => {
        var groupByCurrencyId = {}

        state.forEach(sum => {
            if (sum?.currency?.id in groupByCurrencyId) {
                groupByCurrencyId[sum?.currency?.id].totalSum += sum?.amount
            } else {
                if (sum?.currency?.id) {
                    groupByCurrencyId[sum?.currency?.id] = {
                        id: sum?.currency?.id,
                        name: sum?.currency?.name,
                        totalSum: sum?.amount,
                    }
                }
            }
        })
        return Object.values(groupByCurrencyId)
    }

    const separationCurrencyCalcCashBoxStates = separationCurrenciesAndTotalSum(payout?.cash_box_states)
    const separationCurrencyCalcDebtStates = separationCurrenciesAndTotalSum(payout?.debt_states)


    return(
        <>
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col className="d-flex justify-content-between align-items-center">
                            <h4 className="d-inline">Контрагент: {payout.contractor.name}</h4>
                            <span>Дата создания: {payout.created_at}</span>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>
            <Row className="d-flex justify-content-between mb-3">
                <Col xs={12} lg={6}>
                    <Card className="h-100">
                        {/*<h5 className="pt-3 ps-3">Касса</h5>*/}
                        <h5 className="pt-3 ps-3">Полученная сумма</h5>
                        <div className="table-responsive fs--1 mt-3">
                            <Table striped className="border-bottom">
                                <thead className="table-success">
                                <tr>
                                    <th className="align-middle">№</th>
                                    <th className="align-middle">{t(lang, "Виды оплаты")}</th>
                                    <th className="align-middle">{t(lang, "Сумма")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {payout.cash_box_states.map((cash, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="align-middle">{index + 1}</td>
                                            <td className="align-middle">{t(lang, GetPaymentTypeText(cash.payout_type))}</td>
                                            <td className="text-start">{numeral.formats[numberFormat].format(cash.amount || 0)} <span className="text-info ms-1">{cash.currency.name}</span></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                <Col xs="auto" className="d-flex">
                                    <p className="text-900 fw-bold text-nowrap m-0">{t(lang, "Общая сумма")}:</p>
                                    <Table borderless size="sm" className="fs--1 text-end">
                                        <tbody>
                                        {separationCurrencyCalcCashBoxStates.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td className="fw-semi-bold">{numeral.formats[numberFormat].format(item?.totalSum || 0)} <span className="text-info">{item?.name}</span></td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} lg={6}>
                    <Card className="h-100">
                        <h5 className="pt-3 ps-3">Долги</h5>
                        <div className="table-responsive fs--1 mt-3">
                            <Table striped className="border-bottom">
                                <thead className="table-danger">
                                <tr>
                                    <th className="align-middle">№</th>
                                    <th className="align-middle">{t(lang, "Сумма")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {payout.debt_states.map((debt, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="align-middle">{index + 1}</td>
                                            <td className="text-start">{numeral.formats[numberFormat].format(debt.amount || 0)}<span className="text-info ms-1">{debt.currency.name}</span></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                <Col xs="auto" className="d-flex">
                                    <p className="text-900 fw-bold text-nowrap m-0">{t(lang, "Общая сумма")}:</p>
                                    <Table borderless size="sm" className="fs--1 text-end">
                                        <tbody>
                                        {separationCurrencyCalcDebtStates.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td className="fw-semi-bold">{numeral.formats[numberFormat].format(item?.totalSum || 0)} <span className="text-info">{item?.name}</span></td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>

            {payout.notes &&
                <Card className="p-3">
                    <Card.Title className="ms-2 text-center">
                        Информация
                    </Card.Title>
                    <Card.Text>
                        {payout.notes}
                    </Card.Text>
                </Card>
            }
        </>
    )
}


export default ContractorPayoutView;
