import React, {Fragment, useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Button, Form, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import WarehouseOperationWarehouseSelect from "../../components/WarehouseOperationWarehouseSelect";
import SelectBranch from "../../../../common/SelectBranch";
import CashBoxSelect from "../../../cashbox/common/CashBoxSelect";
import IconButton from "../../../../common/IconButton";
import {
    loadDefaultSettings,
    selectDefaultSetting,
} from "../../../../../app/store/reducers/settings/settingsReducer";
import {selectCashbox, updateCashBoxDefaultSetting} from "../../../../../app/store/reducers/cashbox/CashboxReducer";
import {toast} from "react-toastify";
import {updateWarehouseDefaultSetting} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {updateBranchDefaultSetting} from "../../../../../app/store/reducers/branch/branchReducer";

const WarehouseAccountSettings = ({show, setShow}) => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate
    const settings = useSelector(selectDefaultSetting)
    const cashBoxes = useSelector(selectCashbox)

    const methods = useForm({
        defaultValues: {
            warehouse_id: null,
            branch_id: null,
            cash_box_id: null
        }
    })

    useEffect(() => {
        methods.setValue('warehouse_id', settings?.warehouse?.default_id || null)
        methods.setValue('branch_id', settings?.branch?.default_id || null)
    }, [settings])

    useEffect(() => {
        if (cashBoxes.length === 1 && !settings?.cash_box?.default_id) {
            methods.setValue('cash_box_id', cashBoxes[0].id)
            changeCashBox(cashBoxes[0].id, true)
        } else if (settings?.cash_box?.default_id) {
            methods.setValue('cash_box_id', settings?.cash_box?.default_id)
        } else {
            methods.setValue('cash_box_id', null)
        }
    }, [settings, cashBoxes])

    const changeCashBox = (id, defaultCashBox) => {
        const params = {
            cash_box: {
                default_id: id
            }
        }
        updateCashBoxDefaultSetting(params)
            .then(() => !defaultCashBox && toast.success(t(lang, "warehouse.operation.item.common.toast.success")))
            .catch(() => !defaultCashBox && toast.error(t(lang, "warehouse.operation.item.common.toast.error")))
    }


    const changeWarehouse = (id) => {
        const params = {
            warehouse: {
                default_id: id
            }
        }
        updateWarehouseDefaultSetting(params)
            .then(() => toast.success(t(lang, "warehouse.operation.item.common.toast.success")))
            .catch(() => toast.error(t(lang, "warehouse.operation.item.common.toast.error")))
    }

    const changeBranch = (id) => {
        const params = {
            branch: {
                default_id: id
            }
        }
        updateBranchDefaultSetting(params)
            .then(() => toast.success(t(lang, "warehouse.operation.item.common.toast.success")))
            .catch(() => toast.error(t(lang, "warehouse.operation.item.common.toast.error")))
    }

    const handleShow = () => {
        setShow(true)
    }
    const onHide = () => {
        setShow(false)
        dispatch(loadDefaultSettings())
    }

    return (
        <Fragment>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "warehouse.default.settings")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form>
                            <Form.Group>
                                <Form.Label>{t(lang, "warehouse.operation.item.common.warehouse")}</Form.Label>
                                <Controller name={'warehouse_id'} render={({field}) => (
                                    <WarehouseOperationWarehouseSelect
                                        defaultValue={field?.value}
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                            changeWarehouse(option ? option?.id : null)
                                        }}
                                        classNamePrefix="react-select"
                                        isClearable
                                    />
                                )}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t(lang, "edi.common.branch")}</Form.Label>
                                <Controller name={'branch_id'} render={({field}) => (
                                    <SelectBranch
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                            changeBranch(option ? option?.id : null)
                                        }}
                                        defaultValue={field.value}
                                        placeholder={t(lang, "edi.common.button.select")}
                                        classNamePrefix="react-select"
                                    />
                                )}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t(lang, "cashbox.bind.account.title")}</Form.Label>
                                <Controller name={'cash_box_id'} render={({field}) => (
                                    <CashBoxSelect
                                        defaultValue={field.value}
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                            changeCashBox(option ? option?.id : null)
                                        }}
                                        isClearable={cashBoxes.length > 1 && true}
                                    />
                                )}/>
                            </Form.Group>
                        </Form>
                    </FormProvider>
                </Modal.Body>
            </Modal>

        </Fragment>
    );
};

export default WarehouseAccountSettings;
