import React, {Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal} from "react-bootstrap";
import {deleteDesktopAppVersionAsync} from "../../../../app/store/reducers/desktop-app/desktopAppReducer";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const DeleteDesktopAppVersion = ({id, ...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleDelete = () => {
        setIsLoading(true);

        deleteDesktopAppVersionAsync(id)
            .then(() => {
                toast.success(t(lang, 'admin.desktop_app_versions.delete_desktop_app_version_modal.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'admin.desktop_app_versions.delete_desktop_app_version_modal.toast.failed')))
            .finally(() => setIsLoading(false))
    }

    return (
        <Fragment>
            <FontAwesomeIcon onClick={handleShow} icon={faTrash} {...props}/>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'admin.desktop_app_versions.delete_desktop_app_version_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, 'admin.desktop_app_versions.delete_desktop_app_version_modal.description')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'roaming.common.close')}</Button>
                    <Button variant="danger" onClick={handleDelete} disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                        {t(lang, 'main.common.delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default DeleteDesktopAppVersion;