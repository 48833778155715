import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import {getBranchAccountsAsync} from "../../../../../../app/store/reducers/branch/branchReducer";
import AccountBranchDataTable from "./AccountBranchDataTable";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {
    BIND_ACCOUNT_DISABLE,
    BIND_ACCOUNT_ENABLE,
    BIND_ACCOUNT_REGISTER, BIND_ACCOUNT_UNREGISTER
} from "../../../../../../app/eventbus/branchEvents";

const AccountBranch = () => {
    const dispatch = useDispatch()
    const organization = useSelector(selectActiveOrganization);

    useEffect(() => {
        dispatch(getBranchAccountsAsync())
    }, [organization])

    useEffect(() => {

        const accountBindingEnableSuccess = EventBus.on(BIND_ACCOUNT_ENABLE, () => {
            dispatch(getBranchAccountsAsync())
        })

        const accountBindingDisableSuccess = EventBus.on(BIND_ACCOUNT_DISABLE, () => {
            dispatch(getBranchAccountsAsync())
        })

        const accountBindingRegister = EventBus.on(BIND_ACCOUNT_REGISTER, () => {
            dispatch(getBranchAccountsAsync())
        })
        const accountBindingUnregister = EventBus.on(BIND_ACCOUNT_UNREGISTER, () => {
            dispatch(getBranchAccountsAsync())
        })

        return () => {
            EventBus.remove(BIND_ACCOUNT_ENABLE, accountBindingEnableSuccess)
            EventBus.remove(BIND_ACCOUNT_DISABLE, accountBindingDisableSuccess)
            EventBus.remove(BIND_ACCOUNT_REGISTER, accountBindingRegister)
            EventBus.remove(BIND_ACCOUNT_UNREGISTER, accountBindingUnregister)
        }

    }, [])

    return (
       <AccountBranchDataTable />
    );
};

export default AccountBranch;
