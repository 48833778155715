import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import ReactSelect from "react-select";

const AdminReportDataTableHeader = ({filter, setFilter, data}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const uniqueOrganizations = data.reduce((acc, org) => {
        if (!acc.some(existing => existing.package_name === org.package_name)) {
            acc.push(org);
        }
        return acc;
    }, []);

    const selectOptions = uniqueOrganizations.map(org => ({
        value: `${org.package_name}`,
        label: `${org.package_name}`
    }));

    return (
        <Row className={'pb-2 bg-light'}>
            <Col sm={12} md={4}>
                <Form.Group>
                    <Form.Label column={true}>
                        {t(lang, 'admin.common.payments.datatable.name')}
                    </Form.Label>
                    <Form.Control
                        type="search"
                        value={filter.name}
                        className="search-input"
                        placeholder={t(lang, 'edi.common.name.placeholder')}
                        onChange={event => {
                            setFilter({
                                ...filter,
                                name: event.target.value,
                            });
                        }}
                    />
                </Form.Group>
            </Col>

            <Col sm={12} md={4}>
                <Form.Group>
                    <Form.Label column={true}>
                        {t(lang, 'admin.common.payments.datatable.inn')}
                    </Form.Label>
                    <Cleave onChange={(e) => setFilter({...filter, inn: e.target.rawValue})}
                            value={filter.inn}
                            placeholder={t(lang, 'crm.common.inn.placeholder')}
                            className={classNames('form-control')}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3
                            }}
                    />
                </Form.Group>
            </Col>
            <Col sm={12} md={4}>
                <Form.Group>
                    <Form.Label column={true}>
                        {t(lang, 'main.billing.charge.datatable.package')}
                    </Form.Label>
                    <ReactSelect
                        isClearable
                        classNamePrefix={'react-select'}
                        options={selectOptions}
                        hideSelectedOptions
                        placeholder={t(lang, 'edi.common.button.select')}
                        value={filter.package_name ? {value: filter.package_name, label: filter.package_name} : null}
                        onChange={(option) => setFilter({...filter, package_name: option?.value || null})}
                    />
                </Form.Group>
            </Col>

        </Row>
    );
};

export default AdminReportDataTableHeader;