import React from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Card, Col, Row, Table} from "react-bootstrap";
import numeral from "numeral";
import dayjs from "dayjs";
import {GetPaymentTypeText} from "../../../../enum/ContractorWrapper";
import organizationBindingWrapper from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import {OperationTotalSumInfoWithoutPaymentType} from "../../warehouse-operation/enum/warehouseOperationWrapper";


const saleDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
const Sale = ({binding, sale}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const groupByItemIdAndPrice = sale.items.filter(i => !i.is_deleted).reduce((result, currentItem) => {
        const itemID = currentItem?.warehouse_item?.id;
        const currencyID = currentItem?.price?.currency?.id;
        const amount = currentItem?.price?.amount;

        const key = `${itemID}_${currencyID}_${amount}`;

        if (!result[key]) {
            result[key] = {
                item: {
                    id: currentItem?.warehouse_item?.id,
                    name: currentItem?.warehouse_item?.name
                },
                sub: []
            };
        }

        result[key].sub.push(currentItem);

        return result;
    }, {});
    const totalSum = OperationTotalSumInfoWithoutPaymentType(sale?.payment?.cash_box_states || []);
    const showPaymentInfo = organizationBindingWrapper.isSalePaymentAccessEnabled(binding.sales_payment_access_type) && sale.payment && !!sale.payment.cash_box_states.length;

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <h3>{t(lang, 'partners.common.sales.time')}: {dayjs(sale.date).format(saleDateTimeFormat)}</h3>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className="table-responsive fs--1" style={{maxHeight: "430px"}}>
                            <Table className="border-bottom">
                                <thead style={{position: "sticky", top: '-1px'}} className="table-primary">
                                <tr>
                                    <th className="text-center">№</th>
                                    <th className="align-middle">{t(lang, "items.common.name")}</th>
                                    <th className="text-center">{t(lang, "crm.common.quantity")}</th>
                                    <th className="text-center">{t(lang, "items.common.price")}</th>
                                    <th className="text-center">{t(lang, "partners.common.sales.total_sum")}</th>
                                </tr>
                                </thead>
                                <tbody>

                                {Object.values(groupByItemIdAndPrice)?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {item?.sub?.map((it, i) => {
                                                return (
                                                    <tr key={it.id}>
                                                        <td key={index}
                                                            className={i === 0 ? "text-start border-end border-2" : "d-none"}
                                                            rowSpan={item?.sub?.length}
                                                            style={{width: "50px"}}
                                                        >
                                                            {index + 1}
                                                        </td>
                                                        <td
                                                            className={i === 0 ? "text-start border-end border-2" : "d-none"}
                                                            rowSpan={item?.sub?.length}>
                                                            {it?.warehouse_item?.name}
                                                        </td>
                                                        <th className="text-end border-end border-2">
                                                            {it?.quantity}
                                                        </th>
                                                        <th className="text-end border-end border-2 text-nowrap">
                                                            {numeral.formats[numberFormat].format(it?.price?.amount)}
                                                            <span className="text-info"> {it?.price?.currency?.name}</span>
                                                        </th>
                                                        <th className="text-end border-end border-2 text-nowrap">
                                                            {numeral.formats[numberFormat].format(it?.quantity * it?.price?.amount)}
                                                            <span className="text-info"> {it?.price?.currency?.name}</span>
                                                        </th>
                                                    </tr>

                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {showPaymentInfo &&
                <Col xs={12}>
                    <Card className="h-100">
                        <h5 className="pt-3 fw-bold ps-3">{t(lang, "crm.contractor_payment.datatable.source.payment")}</h5>
                        <div className="table-responsive fs--1 mt-3">
                            <Table striped className="border-bottom">
                                <thead className="table-info">
                                <tr>
                                    <th className="align-middle">№</th>
                                    <th className="align-middle">{t(lang, "warehouse.operation.item.common.cash_box_state.type")}</th>
                                    <th className="align-middle">{t(lang, "warehouse.operation.item.common.total")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sale.payment.cash_box_states.map((cash, index) => {
                                    return(
                                        <tr key={index} className="fw-bold">
                                            <td className="align-middle">{index + 1}</td>
                                            <td className="align-middle">{t(lang, GetPaymentTypeText(cash?.payment_type))}</td>
                                            <td className="text-start">{numeral.formats[numberFormat].format(cash?.amount || 0)} <span className="text-info ms-1">{cash?.currency?.name}</span></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                <Col xs="auto" className="d-flex fs-1">
                                    <p className="text-900 fw-bold text-nowrap m-0">{t(lang, "warehouse.operation.item.common.total_sum")}:</p>
                                    <Table borderless size="sm" className="text-end">
                                        <tbody>
                                        {totalSum?.length > 0 &&
                                            totalSum?.map((item, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td className="fw-semi-bold">{numeral.formats[numberFormat].format(item?.totalSum || 0)} <span className="text-info">{item?.name}</span></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            }
        </Row>
    );
};

export default Sale;