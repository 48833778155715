import React, {useEffect} from 'react';
import AdminOrganizationRegisterModal from "../../components/AdminOrganizationRegisterModal";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterAdminOrganizationCredentialsAsync,
    getAdminOrganizationCredentialCountAsync,
    loadAdminOrganizationCredentialAsync,
    selectAdminOrganizationCredential,
    selectAdminOrganizationCredentialsCount,
    selectAdminOrganizationCredentialsFilterOptions,
} from "../../../../app/store/reducers/billing/billingReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {REGISTER_CREDENTIAL_FAIL, REGISTER_CREDENTIAL_SUCCESS} from "../../../../app/eventbus/billingEvents";
import {toast} from "react-toastify";

const OrganizationCredentials = ({organizationId}) => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    const data = useSelector(selectAdminOrganizationCredential)
    const filter = useSelector(selectAdminOrganizationCredentialsFilterOptions)
    const count = useSelector(selectAdminOrganizationCredentialsCount)

    useEffect(() => {
        const onRegisterHandlerSuccess = EventBus.on(REGISTER_CREDENTIAL_SUCCESS, () => {
            toast.success('Success')
            dispatch(loadAdminOrganizationCredentialAsync({...filter, organization_id: organizationId}))
            dispatch(getAdminOrganizationCredentialCountAsync({organization_id: organizationId}))
        })

        const onRegisterHandlerFailed = EventBus.on(REGISTER_CREDENTIAL_FAIL, () => {
            toast.error("Error")
        })

        return () => {
            EventBus.remove(REGISTER_CREDENTIAL_SUCCESS, onRegisterHandlerSuccess)
            EventBus.remove(REGISTER_CREDENTIAL_FAIL, onRegisterHandlerFailed)
        }
    }, []);

    const onPageChange = (page) => dispatch(changeFilterAdminOrganizationCredentialsAsync({...filter, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterAdminOrganizationCredentialsAsync({...filter, limit: limit, page: 1}));

    useEffect(() => {
        dispatch(loadAdminOrganizationCredentialAsync({...filter, organization_id: organizationId}))
        dispatch(getAdminOrganizationCredentialCountAsync({organization_id: organizationId}))
    }, [filter])


    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'client_id',
            Header: t(lang, "admin.organization.credentials.datatable.client_id"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <span>{original.client_id}</span>
                    </>
                );
            }
        },
        {
            accessor: 'client_secret',
            Header: t(lang, "admin.organization.credentials.datatable.client_secret"),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <span>{original.client_secret}</span>
                    </>
                )
            }
        }
    ]

    return (
        <>
            <AdvanceTableWrapper
                pagination
                perPage={1000}
                columns={columns}
                data={data}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <div className="d-flex justify-content-end">
                            <AdminOrganizationRegisterModal organizationId={organizationId}/>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filter.limit}
                            page={filter.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default OrganizationCredentials;