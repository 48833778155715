import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {selectWarehouses} from "../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {Button, Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import FalconCloseButton from "./FalconCloseButton";
import Divider from "./Divider";
import IconButton from "./IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const WarehouseLinkSelector = ({defaultWarehouseId, onChange, linkProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const warehouses = useSelector(selectWarehouses);
    const [show, setShow] = useState(false);
    const [warehouse, setWarehouse] = useState(null);

    const link = <span className="text-primary cursor-pointer fs--1"
                       onClick={() => setShow(true)}
                       {...linkProps}
    >
        {warehouse ? `${warehouse.warehouseName}` : t(lang, 'roaming.invoice.warehouse')}
    </span>

    const onChangeHandler = (option) => {
        setWarehouse(option);
        onChange(option);
        option && setShow(false);
    }

    useEffect(() => {
        if (warehouse?.id !== defaultWarehouseId)
            setWarehouse(warehouses.find(w => w.id === defaultWarehouseId) || null);
    }, [defaultWarehouseId, warehouses])

    return (
        <>
            {warehouse && <OverlayTrigger overlay={<Tooltip>{warehouse.warehouseName}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!warehouse && link}
            {show &&
                <Modal show={true}
                       onHide={() => setShow(false)}
                       size="xl"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'roaming.invoice.warehouse')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-2">
                            <Col xs={12}>
                                <ReactSelect options={warehouses}
                                             placeholder={t(lang, 'roaming.common.select.placeholder')}
                                             getOptionValue={option => option.id}
                                             getOptionLabel={option => option.warehouseName}
                                             isSearchable={!defaultWarehouseId}
                                             defaultMenuIsOpen={!warehouse}
                                             isClearable={true}
                                             value={null}
                                             onChange={onChangeHandler}
                                />
                            </Col>
                        </Row>
                        {warehouse &&
                            <>
                                <Divider/>
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="text-info">{t(lang, 'roaming.invoice.warehouse')}</h5>
                                        <div className="mt-3 fw-bold fs--1">
                                            <span className="text-primary">{warehouse.id} - </span>
                                            <span className="text-dark">{warehouse.warehouseName}</span>
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon={faTrash}
                                                iconClassName={"text-danger"}
                                                className="ms-2"
                                                title={t(lang, 'items.common.delete')}
                                                onClick={() => onChangeHandler(null)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShow(false)}>
                            {t(lang, 'items.common.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
};

export default WarehouseLinkSelector;