import PropTypes from 'prop-types';
import React, {useState, Fragment} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import AddContractor from "./AddContractor";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    selectContractorFilterOptions,
    changeFilterOptionsAsync
} from "../../../../app/store/reducers/contractor/contractorReducer";
import Select from "react-select";
import BindLabelsToContractorModal from "./label/BindLabelsToContractorModal";
import IconButton from "../../../common/IconButton";
import {useAsyncDebounce} from "react-table";
import MassBindLabelsToContractorModal from "./label/MassBindLabelsToContractorModal";
import SelectContractorLabel from "./label/SelectContractorLabel";
import UploadContractorsListToExcel from "./excel/UploadContractorsListToExcel";
import MassBindContractorsToEmployeeModal from "./bindings/MassBindContractorsToEmployeeModal";
import BindContractorToEmployeeModal from "./bindings/BindContractorToEmployeeModal";
import {
    debtPromiseDateDefault,
    debtPromiseDateShow,
    debtTypes,
    sortByDesc1,
    sortByDesc2,
    types
} from "../../../../enum/ContractorWrapper";
import ReactSelect from "react-select";
import {boardColumnsTypes} from "../../../../enum/KanbanWrapper";




const ContractorDataTableHeader = ({currency, setSelectedCurrency, selectedCurrency, selectedRowIds, setSort, sortByDesc, setSortByDesc, page, data, debtPromiseDate, setDebtPromiseDate}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const selectedContractors = Object.keys(selectedRowIds).reduce((result, selectedIndex) => {
        if (page && page[selectedIndex])
            result.push(page[selectedIndex]?.original);
        return result;
    }, []);

    const filterOptions = useSelector(selectContractorFilterOptions)

    const [contractorName, setContractorName] = useState(filterOptions.name || '');
    const [type, setType] = useState(filterOptions.type || null);
    const [contractorLabel, setContractorLabel] = useState(filterOptions.label || null);

    const [showBindContractorLabelModal, setShowBindContractorLabelModal] = useState(false)



    const dispatch = useDispatch();

    const changedSearchFilter = useAsyncDebounce(value => {
        dispatch(changeFilterOptionsAsync({
            name: contractorName,
        }));
    }, 1000);

    const onChangeTypeHandler = (option) => {
        dispatch(changeFilterOptionsAsync({
            type: option?.value,
            name: filterOptions.name,
        }));
        setType(option?.value)
    }
    // ! onChangeDebtHandler
    const onChangeDebtHandler = (option) => {
        dispatch(changeFilterOptionsAsync({
            debt: option?.value
        }));
        setType(option?.value)
    }
    const onChangeContractorLabelHandler = (option) => {
        dispatch(changeFilterOptionsAsync({
            name: filterOptions.name,
            label: option?.id
        }));
        setContractorLabel(option?.id)
    }

    const onChangeDebtPromiseDate = (option) => {
        if (option) {
            dispatch(changeFilterOptionsAsync({
                debtPromiseType: option.value
            }))
        } else {
            dispatch(changeFilterOptionsAsync({
                debtPromiseType: null
            }))
        }
    }

    return (
        <Fragment>
            <Row>
                <Col sm={12} md={3}>
                        <Form.Control
                            type="search"
                            value={contractorName}
                            className="search-input"
                            onChange={event => {
                                setContractorName(event.target.value)
                                changedSearchFilter()
                            }}
                            placeholder={t(lang, "roaming.organization_address.datatable.header.filter.contractor")}
                        />
                </Col>
                <Col sm={12} md={3}>
                        <Select
                            defaultValue={types.find(t => t.value === type)}
                            options={types}
                            getOptionLabel={(option)  => t(lang, option.label)}
                            hideSelectedOptions
                            onChange={onChangeTypeHandler}
                            classNamePrefix="react-select"
                            isClearable
                            placeholder="Поиск по тип"
                            size={'sm'}
                        />
                </Col>
                <Col sm={12} md={3}>
                        <SelectContractorLabel
                            classNamePrefix="react-select"
                            defaultContractorLabelId={contractorLabel}
                            onChange={onChangeContractorLabelHandler} placeholder="Поиск по ярлык"/>
                </Col>
                <Col sm={12} md={3}>
                        <Select
                            value={selectedCurrency}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            options={currency}
                            hideSelectedOptions
                            onChange={(option) => setSelectedCurrency(option)}
                            classNamePrefix="react-select"
                            placeholder={t(lang, 'edi.contractor.select.currency')}
                            size={'sm'}
                        />
                </Col>
                <Col sm={12} md={3}>
                    <Select
                        defaultValue={debtTypes.find(t => t.value === type)}
                        getOptionLabel={(option)  => t(lang, option.label)}
                        options={debtTypes}
                        hideSelectedOptions
                        onChange={onChangeDebtHandler}
                        classNamePrefix="react-select"
                        className={'mt-3'}
                        isClearable
                        placeholder={t(lang, 'edi.contractor.card.filter_by_debts.placeholder')}
                        size={'sm'}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <Form.Select className={'mt-3'} onChange={e => setSort(e.target.value)}>
                        <option value="name">{t(lang, 'Название')}</option>
                        <option value="debt">{t(lang, 'Задолжности')}</option>
                        <option value="debtDate">{t(lang, 'Задолжности по дате')}</option>
                    </Form.Select>
                </Col>
                <Col sm={12} md={3}>
                    <Form.Select className={'mt-3'} value={sortByDesc} onChange={e => setSortByDesc(e.target.value)}>
                        <option value={sortByDesc1}>{t(lang, 'По убыванию')}</option>
                        <option value={sortByDesc2}>{t(lang, 'По возрастанию')}</option>
                    </Form.Select>
                </Col>
                <Col sm={12} md={3}>
                    <Form.Select className={'mt-3'} value={debtPromiseDate} onChange={e => setDebtPromiseDate(+e.target.value)}>
                        <option value={debtPromiseDateDefault}>{t(lang, 'edi.contractor.card.filter_by_debts.promise.date.default')}</option>
                        <option value={debtPromiseDateShow}>{t(lang, 'edi.contractor.card.filter_by_debts.promise.date.show')}</option>
                    </Form.Select>
                </Col>
                <Col sm={12} md={3}>
                    <ReactSelect
                        options={boardColumnsTypes}
                        onChange={(option) => onChangeDebtPromiseDate(option)}
                        getOptionLabel={(option)  => t(lang, option.label)}
                        classNamePrefix="react-select"
                        isClearable
                        hideSelectedOptions
                        className={'mt-3'}
                        placeholder={t(lang, 'crm.contractor.datatable.debt_promise_date.type')}
                        size={'sm'}
                    />
                </Col>

                <Col xs={6} sm="auto" className="d-flex align-items-center ms-auto text-end ps-0 mt-3">
                    {selectedContractors.length > 0 ? (
                        <MassBindContractorsToEmployeeModal selectedContractors={selectedContractors}>
                            <IconButton icon={'right-left'} size={'sm'} variant={'falcon-primary'} className={'me-2'}>{t(lang, 'Привязать контрагенты к сотруднику')}</IconButton>
                        </MassBindContractorsToEmployeeModal>
                    ) : (
                        <BindContractorToEmployeeModal>
                            <IconButton icon={'right-left'} size={'sm'} variant={'falcon-primary'} className={'me-2'}>{t(lang, 'Привязать контрагент к сотруднику')}</IconButton>
                        </BindContractorToEmployeeModal>
                    )}

                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="right-left"
                        transform="shrink-3"
                        className={'me-2'}
                        onClick={() => setShowBindContractorLabelModal(true)}
                    >
                        <span
                            className="d-none d-sm-inline-block">{selectedContractors.length > 0 ? t(lang, "crm.contractor.mass_bind.label.button") : t(lang, "crm.contractor.bind.label.button")}</span>
                    </IconButton>
                    <UploadContractorsListToExcel data={selectedContractors.length > 0 ? selectedContractors : data}/>
                    <AddContractor/>
                </Col>
            </Row>
            {selectedContractors.length > 0 ? <MassBindLabelsToContractorModal show={showBindContractorLabelModal}
                                                                               contractorIds={selectedContractors?.map(contractor => contractor.id)}
                                                                               setShow={setShowBindContractorLabelModal}/> :
                <BindLabelsToContractorModal show={showBindContractorLabelModal}
                                             setShow={setShowBindContractorLabelModal}/>}
        </Fragment>
    );
};

ContractorDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ContractorDataTableHeader;
