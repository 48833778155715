import React, {useState} from 'react';
import {Form, InputGroup} from "react-bootstrap";
import classNames from "classnames";
import IconButton from "../../../../common/IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFieldArray} from "react-hook-form";
import itemWrapper from "../../enum/itemWrapper";


const barcodeRegExp = /^[0-9]{0,13}$/;
const BarcodeRow = ({fieldName, id, checkIsExistingItem, onRightClickHandler, errors}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const {
        fields: barcodes,
        append: appendBarcode,
        remove: removeBarcode
    } = useFieldArray({name: `${fieldName}`});
    const [existingBarcode, setExistingBarcode] = useState(null);

    return (
        <>
            <h6 className="text-info cursor-pointer d-inline-block"
                onClick={() => appendBarcode({barcode: itemWrapper.generateGtinBarcode(new Date().getTime().toString().slice(5, 13))})}>{t(lang, 'items.common.add_barcode')}</h6>
            {barcodes.map((barcode, index) => {
                return (
                    <Form.Group className="mb-2" key={barcode.id}>
                        <Controller name={`${fieldName}.${index}.barcode`}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                        pattern: {
                                            value: barcodeRegExp,
                                            message: t(lang, "items.common.validations.invalid_barcode")
                                        },
                                    }}
                                    render={({field, fieldState: {error}}) => {
                                        const isExistingBarcode = errors?.existing_barcodes?.[index] === field.value;
                                        return (
                                            <>
                                                <InputGroup
                                                    className={classNames({'is-invalid': !!error || isExistingBarcode})}>
                                                    <Form.Control
                                                        placeholder={t(lang, 'items.common.barcode.placeholder')}
                                                        name={field.name}
                                                        isInvalid={error?.barcode || isExistingBarcode}
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            field.onChange(e);

                                                            if (existingBarcode && newValue !== errors?.existing_barcodes) {
                                                                setExistingBarcode(null);
                                                            }
                                                            if (newValue === errors?.existing_barcodes) {
                                                                setExistingBarcode(errors.existing_barcodes);
                                                            }
                                                        }}
                                                        onContextMenu={onRightClickHandler}

                                                    />
                                                    <IconButton
                                                        variant="falcon-default"
                                                        size="sm"
                                                        icon={faTrash}
                                                        iconClassName={"text-danger"}
                                                        onClick={() => removeBarcode(index)}
                                                    />
                                                </InputGroup>
                                                {!!error && <Form.Control.Feedback
                                                    type="invalid">{error?.message}</Form.Control.Feedback>}
                                                {isExistingBarcode && <Form.Control.Feedback
                                                    type="invalid">{t(lang, "items.common.validations.existing_barcodes", {barcode: errors?.existing_barcodes?.[index]})}</Form.Control.Feedback>}
                                            </>
                                        )
                                    }}
                        />
                    </Form.Group>
                )
            })}
        </>
    );
};

export default BarcodeRow;