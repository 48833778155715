import React, {Fragment, useState} from 'react';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../../common/IconButton";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import EventBus from "../../../../../../app/eventbus/EventBus";

import {deleteWaybillV2DraftAsync} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import {
    DELETE_WAYBILL_V2_FAILED,
    DELETE_WAYBILL_V2_REQUESTED,
    DELETE_WAYBILL_V2_SUCCEED, WAYBILL_V2_BULK_ACTION_FINISHED, WAYBILL_V2_BULK_ACTION_STARTED
} from "../../../../../../app/eventbus/roaming/roamingWaybillV2Events";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../../common/FalconCloseButton";
import PropTypes from "prop-types";

const WaybillV2DraftDelete = ({id, waybills, hasTitle, handleShowProgressBar, updateActionResult, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    // show/hide confirmation modal
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const deleteDraft = () => {
        if (waybills?.length > 0)
            bulkDelete();
        else
            singleDelete();
    }

    // delete single document
    const singleDelete = () => {
        setLoading(true);
        EventBus.dispatch(DELETE_WAYBILL_V2_REQUESTED, id);
        deleteWaybillV2DraftAsync(id)
            .then(() => {
                setLoading(false);
                EventBus.dispatch(DELETE_WAYBILL_V2_SUCCEED, id);
                handleClose();
            })
            .catch(() => {
                EventBus.dispatch(DELETE_WAYBILL_V2_FAILED, id);
                setLoading(false);
            })
    }

    const bulkDelete = async () => {
        handleShowProgressBar();
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_STARTED);
        for (let i = 0; i < waybills.length; i++) {
            const waybill = waybills[i];
            try {
                await deleteWaybillV2DraftAsync(waybills[i].id);
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn
                    }
                });
            } catch (error) {
                updateActionResult({
                    number: waybill.info.number,
                    date: waybill.info.date,
                    contractor: {
                        name: waybill.customer.name,
                        inn: waybill.customer.inn
                    },
                    error: error?.message
                })
            }
        }
        EventBus.dispatch(WAYBILL_V2_BULK_ACTION_FINISHED);
        handleClose();
    }

    return (
        <Fragment>
            <IconButton onClick={handleShow}
                        variant="falcon-danger"
                        icon={faTrash}
                        {...props}
            >
                {hasTitle && t(lang, "roaming.invoice.delete")}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "roaming.invoice.delete_draft_modal_title")}
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "roaming.invoice.delete_draft_modal_body")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "roaming.common.cancel_button")}
                    </Button>
                    <Button variant="danger"
                            disabled={loading}
                            onClick={deleteDraft}
                    >
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, "roaming.invoice.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

WaybillV2DraftDelete.propTypes = {
    id: PropTypes.string,
    waybills: PropTypes.array,
    hasTitle: PropTypes.bool,
    updateActionResult: PropTypes.func,
    handleShowProgressBar: PropTypes.func
}

WaybillV2DraftDelete.defaultProps = {
    id: "",
    waybills: [],
    hasTitle: false,
    updateActionResult: () => {},
    handleShowProgressBar: () => {}
}

export default WaybillV2DraftDelete;
