import { useSelector } from "react-redux"
import { useFormContext } from "react-hook-form"
import { Translate, selectLang } from "../../../../../app/store/reducers/main/mainReducer"
import { Accordion, Card, Col, Form, Row } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from "../../../../common/IconButton"

const Parts = ({ fieldsPart, addFieldPart, removeFieldPart }) => {
    const { register, formState: {errors: partErrors} } = useFormContext()
    const errors = partErrors.parts || []
    const lang = useSelector(selectLang)
    const t = Translate
    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col><h5 className="mb-0">{t(lang, "roaming.contracts.add.parts.title")}</h5></Col>
                    <Col className="text-end">
                        <IconButton
                            onClick={addFieldPart}
                            variant="falcon-primary" 
                            icon="plus"
                            size="sm"
                            transform="shrink-3"
                        >{t(lang, "roaming.contracts.add.parts.add_part")}
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Accordion alwaysOpen={true} defaultActiveKey={fieldsPart.map(p => p.id)}>
                {fieldsPart.map((part, index) => (
                    <Accordion.Item className={`${errors[index] && 'border-danger'} border-bottom-2`} key={part.id}  eventKey={part.id}>
                        <Accordion.Header>
                            <div className="w-100">
                                <Form.Control 
                                    {...register(`parts.${index}.title`, {
                                        // required: "edi.common.forms.validations.is_required"
                                    })}
                                    className={errors[index]?.title && 'is-invalid'}
                                    placeholder={t(lang, "roaming.contracts.add.parts.name:placeholder")}
                                    type="text"
                                />
                            <Form.Control.Feedback type="invalid">
                                {t(lang, errors[index]?.title?.message)}
                            </Form.Control.Feedback>
                            </div>
                            <span className={`ms-3 me-2 ${fieldsPart.length <= 1 ? 'text-500 cursor-na' : 'text-danger'}`} onClick={() => fieldsPart.length > 1 && removeFieldPart(index)}><FontAwesomeIcon icon="trash-alt"/></span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Form.Control
                                {...register(`parts.${index}.body`, {
                                    // required: "edi.common.forms.validations.is_required"
                                })}
                                className={errors[index]?.body && 'is-invalid'}
                                placeholder={t(lang, "roaming.contracts.add.parts.body:placeholder")}
                                as="textarea" 
                                rows={10}

                            />
                            <Form.Control.Feedback type="invalid">
                                {t(lang, errors[index]?.body?.message)}
                            </Form.Control.Feedback>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
                </Accordion>
                <Row>
                    <Col className="text-end mt-4">
                        <IconButton
                            onClick={addFieldPart}
                            variant="falcon-primary" 
                            icon="plus"
                            size="sm"
                            transform="shrink-3"
                        >{t(lang, "roaming.contracts.add.parts.add_part")}
                        </IconButton>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default Parts