import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {useHistory} from "react-router";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {UZS} from "../../../../../enum/CurrencyCode";
import {toast} from "react-toastify";
import EventBus from "../../../../../app/eventbus/EventBus";
import {Form} from "react-bootstrap";
import {
    getReturnPurchaseItemAsync,
    returnPurchaseAddItemAsync,
    returnPurchaseChangeContractorAsync,
    returnPurchaseUpdateNoteAsync,
    returnPurchaseRegisterAsync,
    returnPurchaseUpdateNumberAsync,
    selectReturnPurchaseDefaultsSettings,
    returnPurchaseChangeEmployeeAsync
} from "../../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import {
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS
} from "../../../../../app/eventbus/warehouse-operation/returnPurchaseEvents";
import ReturnPurchaseForm from "./ReturnPurchaseForm";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";


const AddReturnPurchaseForm = () => {
    const [returnPurchase, setReturnPurchase] = useState(null)
    const returnPurchaseDefaultsSettings = useSelector(selectReturnPurchaseDefaultsSettings)
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const warehouses = useSelector(selectWarehouses)
    const currencies = useSelector(selectCurrency)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate;
    const settings = useSelector(selectDefaultSetting)



    const form = useForm({
        defaultValues: {
            contractor_id: null,
            employee_id: returnPurchaseDefaultsSettings?.defaultEmployeeId || null,
            cashbox_id: settings?.cash_box?.default_id || null,
            date: dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            note: '',
            number: null,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        }
    })

    const resetForm = (formData) => {
        form.reset({
            contractor_id: formData?.contractor?.id || formData?.contractor_id,
            employee_id: formData?.employee?.id || formData?.employee_id,
            cashbox_id: settings?.cash_box?.default_id || formData?.cashbox?.id || formData?.cashbox_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
            package_measurements: null,
            marks: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        })
    }



    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name
        const quantity = +formData?.quantity * +formData?.package_measurements?.quantity

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: quantity,
            marks: formData?.marks || [],
            price: {
                amount: +formData?.commonPrice?.amount,
                currency_id: formData?.commonPrice?.currency_id,
            },
        }

        if (buttonType === "addOrEditReturnPurchase" || event === "addOrEditReturnPurchase") {
            setLoading(true)
            if (!returnPurchase) {
                await returnPurchaseRegisterAsync({
                    contractor_id: formData?.contractor_id,
                    date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
                    note: formData?.note,
                    number: formData?.number?.trim() ? formData?.number : null,
                    items: [item],
                    employee_id: formData?.employee_id,
                    cashbox_id: formData?.cashbox_id
                })
                    .then(({data}) => {
                        setResponse(data)
                        resetForm(data)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error.response.data?.error?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
           /* if (returnPurchase && response?.contractor?.id !== formData?.contractor_id) {
                await returnPurchaseChangeContractorAsync({returnPurchaseId: returnPurchase.id, contractorId: formData?.contractor_id})
                    .then(({data}) => {
                        setResponse(data)
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }*/
            if (returnPurchase) {
                await returnPurchaseAddItemAsync({
                    returnPurchaseId: returnPurchase?.id,
                    operation: item
                })
                    .then(({data}) => {
                        updateNote(formData)
                        // updateNumber(formData)
                        resetForm(formData)
                        updateEmployee(formData)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error?.response?.data?.error?.includes("warehouse") || error?.response?.data?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || returnPurchase?.employee) {
            if (formData?.employee_id !== returnPurchase?.employee?.id) {
                returnPurchaseChangeEmployeeAsync({returnPurchaseId: returnPurchase?.id, employeeId: formData?.employee_id})
                    .then(({data}) => {
                        setReturnPurchase(data, data.items.reverse())
                    })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== returnPurchase?.note) {
            returnPurchaseUpdateNoteAsync({returnPurchaseId: returnPurchase?.id, note: formData?.note})
                .then(({data}) => {
                    setReturnPurchase(data, data.items.reverse())
                })
                .catch(() => {})
        }
    }

    // const updateNumber = (formData) => {
    //     if (formData?.number?.trim() && formData?.number !== returnPurchase?.number) {
    //         returnPurchaseUpdateNumberAsync({returnPurchaseId: returnPurchase?.id, number: formData?.number})
    //             .then(({data}) => {
    //                 setReturnPurchase(data, data.items.reverse())
    //             })
    //             .catch(() => {})
    //     }
    // }

    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, (id) => {
            history.push(`./view/${id}`)
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS, (id) => {
            loadReturnPurchase(id)
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        form.setValue('cashbox_id', settings?.cash_box?.default_id || null)
        form.setValue('warehouse_id', settings?.warehouse?.default_id || null)
    }, [settings])

    const loadReturnPurchase = async (id) => {
        await getReturnPurchaseItemAsync({returnPurchaseId: id})
            .then((response) => {
                setReturnPurchase(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setReturnPurchase(null)
            })
    }


    return(
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <ReturnPurchaseForm returnPurchase={returnPurchase} loading={loading} addItem={form.handleSubmit(onSubmit)} isEditing={false}/>
                </Form>
            </FormProvider>
        </>
    )
}

export default AddReturnPurchaseForm;
