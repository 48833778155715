import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {uploadOperationItemsReportAsync} from "../../../../app/store/reducers/excel/excelReducer";
import {downloadFile} from "../../../../helpers/excel-uploader";
import {toast} from "react-toastify";
import {Dropdown, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";

const DownloadOperationItemsReportToExcel = ({filters, pagination}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const [shouldLoadAll, setShouldLoadAll] = useState(false);

    const onSubmit = async (filter, pagination, shouldLoadAll) => {
        try {
            const payload = {};

            // build filters
            {
                if (filter.dateStart)
                    payload["date_start"] = dayjs(filter.dateStart).format("YYYY-MM-DD HH:mm:ss");

                if (filter.dateEnd)
                    payload["date_end"] = dayjs(filter.dateEnd).format("YYYY-MM-DD HH:mm:ss");

                if (!!filter.types.length)
                    payload["types"] = filter.types;

                if (filter.categoryId && !!filter.itemIds.length)
                    payload["item_ids"] = filter.itemIds;

                if (filter.itemId) {
                    if (payload["item_ids"]) {
                        const item = payload["item_ids"].find(id => id === filter.itemId) || null;
                        if (item)
                            payload["item_ids"] = [filter.itemId];
                        else
                            delete payload.item_ids;
                    } else {
                        payload["item_ids"] = [filter.itemId];
                    }
                }

                if (filter.contractorId)
                    payload["contractor_id"] = filter.contractorId;

                if (filter.warehouseId)
                    payload["warehouse_id"] = filter.warehouseId;
            }

            // build pagination
            {
                if (!shouldLoadAll) {
                    payload["limit"] = pagination.pageSize;
                    payload["skip"] = pagination.pageIndex * pagination.pageSize;
                }
            }

            setLoading(true);
            const {content, contentType} = await uploadOperationItemsReportAsync(payload);
            downloadFile({
                content: content,
                contentType: contentType,
                fileName: t(lang, 'warehouse.operation.period_report'),
            });
            toast.success(t(lang, "common.toast.success"));
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error(t(lang, "common.toast.error"));
            setLoading(false);
        }
    };

    return (
        <Dropdown>
            <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                {loading
                    ?
                    <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                    :
                    <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                }
                <span className="d-inline-block">
                        {t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.export_to_excel')}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className='py-2'>
                <Form.Switch label={t(lang, 'roaming.invoices.datatable.header.navigation.dropdown.download_all')}
                             onChange={(e) => setShouldLoadAll(e.target.checked)}
                             className="ps-5 text-black"
                />
                <Dropdown.Header className="text-black">{t(lang, 'crm.common.download_excel')}</Dropdown.Header>
                <Dropdown.Item className="ps-4" onClick={() => onSubmit(filters, pagination, shouldLoadAll)}>{t(lang, 'items.common.import_from_excel.download')}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DownloadOperationItemsReportToExcel;