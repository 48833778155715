import React from 'react';
import classNames from "classnames";
import {useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import ActivePackageCard from "./ActivePackageCard";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectOrganizationActivePackages} from "../../../../app/store/reducers/billing/billingReducer";

const OrganizationActivePackages = () => {
    const activePackages = useSelector(selectOrganizationActivePackages);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <Row className={classNames('py-5 px-3', {
                'd-flex justify-content-center': activePackages && activePackages.length < 3
            })}>
                <Col xs={12} className="text-center">
                    {activePackages && activePackages.length > 0
                        ?
                        <h1>{t(lang, 'main.billing.active_packages_card.title')}</h1>
                        :
                        <h2>{t(lang, 'main.billing.active_packages_card.no_active_packages')}</h2>
                    }
                </Col>

                {activePackages && activePackages.map((activePackage, index) => (
                    <ActivePackageCard key={index} activePackage={activePackage} />
                ))}
            </Row>
        </>
    );
};

export default OrganizationActivePackages;
