import {toast} from "react-toastify";
import React, {useState} from 'react';
import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {declineAttorneyAsync} from "../../../../app/store/reducers/attorney/attorneyReducer";
import SignActionWithSelectCertificatWrapper from "../../roaming/components/SignActionWithSelectCertificatWrapper";

const DeclineAttorneyComponent = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const declineAttorneyHandler = () => {
        setLoading(true);

        declineAttorneyAsync({id: id, certificate: currentCertificate, lang: lang})
            .then(() => {
                setLoading(false);
                toast.success(t(lang, 'edi.attorney.toast.decline.success'));
            })
            .catch(() => {
                setLoading(false);
                toast.error(t(lang, 'edi.attorney.toast.decline.error'));
            })
    }

    return (
        <>
            <SignActionWithSelectCertificatWrapper>
                <IconButton
                    size="sm"
                    {...props}
                    icon={faBan}
                    iconClassName="me-1"
                    variant="falcon-danger"
                    onClick={declineAttorneyHandler}
                >
                    {t(lang, 'edi.common.button.decline')}
                    {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
                </IconButton>
            </SignActionWithSelectCertificatWrapper>
        </>
    );
};

export default DeclineAttorneyComponent;