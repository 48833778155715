import React, {Fragment, useRef, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {editItemAsync} from "../../../../app/store/reducers/item/itemReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {BULK_EDIT_ITEMS_FINISHED} from "../../../../app/eventbus/itemEvents";
import ResultCounter from "./ResultCounter";
import IconButton from "../../../common/IconButton";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import ReactSelect from "react-select";
import {ndsOptions} from "../../../../enum/RoamingInvoiceWrapper";

const SelectBulkTaxRateButton = ({items}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);
    const form = useForm({
        defaultValues: {
            tax_rate: ""
        }
    });

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onSubmit = async (formData) => {
        handleClose();
        ref.current.updateLoadedCount(items.length);
        for (let i = 0; i < items.length; i++) {
            try {
                const {item} = items[i];
                const itemJson = {
                    ...item,
                    measurement: item.measurement.toString(),
                    tax: {
                        ...item.tax,
                        tax_rate: formData.tax_rate || null
                    }
                }
                await editItemAsync(item.id, itemJson);
                ref.current.incrementSucceedCount();
            } catch (e) {
                console.log(e);
                ref.current.incrementFailedCount();
            }
        }
        EventBus.dispatch(BULK_EDIT_ITEMS_FINISHED);
    }

    return (
        <Fragment>
            <ResultCounter ref={ref}/>
            <IconButton variant={'falcon-warning'}
                        icon={faKey}
                        size="sm"
                        className="m-1"
                        onClick={handleShow}
            >
                {t(lang, 'items.common.add_tax')}
            </IconButton>

            <Modal show={show}
                   onHide={handleClose}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.common.tax_rate_link_selector_modal.title')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label column={true}>{t(lang, 'items.common.tax_rate')}</Form.Label>
                                <Controller control={form.control}
                                            name={'tax_rate'}
                                            render={({field}) => (
                                                <ReactSelect options={ndsOptions}
                                                             hideSelectedOptions
                                                             isSearchable={false}
                                                             placeholder={t(lang, "items.common.select")}
                                                             getOptionLabel={option => t(lang, option.label)}
                                                             getOptionValue={option => option.value}
                                                             value={ndsOptions.find(o => o.value === field.value) || null}
                                                             onChange={(option) => field.onChange(option.value)}
                                                />
                                            )}/>

                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={form.handleSubmit(onSubmit)}>
                        {t(lang, 'items.common.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default SelectBulkTaxRateButton;