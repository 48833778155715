import React, {useEffect, useState} from 'react';
import TelegramOrderBotConfigsForm from "./TelegramOrderBotConfigsForm";
import telegramOrderBotService from "../../../../../services/telegram-order-bot/telegramOrderBotService";

const TelegramOrderBotConfig = ({bot}) => {
    const [botConfiguration, setBotConfiguration] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadBotConfiguration = async (bot) => {
        try {
            setLoading(true);
            const {data: botConfiguration} = await telegramOrderBotService.getTelegramOrderBotConfigs(bot.id);
            setBotConfiguration(botConfiguration);
            setLoading(false);
        } catch (error) {
            setBotConfiguration(null);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadBotConfiguration(bot);
    }, [bot]);

    if (loading) return null;

    if (!botConfiguration) return null;

    return <TelegramOrderBotConfigsForm botConfiguration={botConfiguration}/>;
};

export default TelegramOrderBotConfig;