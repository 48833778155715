import React, {useState} from "react";
import ContractorNoteList from "./ContractorNoteList";
import ContractorAddNoteForm from "./ContractorAddNoteForm";
import ContractorEditNoteForm from "./ContractorEditNoteForm";

const ContractorNote = () => {
    const [editingNote, setEditingNote] = useState(null);
    const onEditNote = (note) => {
        setEditingNote(note)
    }
    const onCancel = () => {
        setEditingNote(null)
    }
    const onSuccessEdited = () => {
        setEditingNote(null)
    }
    return (<>
        {editingNote && <ContractorEditNoteForm editingNote={editingNote} onCancel={onCancel} onSuccess={onSuccessEdited}/>}
        {!editingNote && <ContractorAddNoteForm/>}
        <ContractorNoteList onEdit={onEditNote}/>
    </>)
}
export default ContractorNote