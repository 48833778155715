import React, {useEffect, useState} from 'react';
import {Button, InputGroup} from "react-bootstrap";
import Cleave from "cleave.js/react";

const SkipRowsInputGroup = ({onChangeSkipCount, defaultSkipCount, maxSkipCount}) => {
    const [skipCount, setSkipCount] = useState(0);

    const incrementSkipCount = () => skipCount < maxSkipCount && setSkipCount(skipCount + 1);
    const decrementSkipCount = () => skipCount > 0 && setSkipCount(skipCount - 1);

    useEffect(() => {
        if (defaultSkipCount !== skipCount)
            setSkipCount(defaultSkipCount);
    }, [defaultSkipCount])

    useEffect(() => {
        onChangeSkipCount(skipCount);
    }, [skipCount])

    return (
        <InputGroup>
            <InputGroup.Text as={Button} variant="outline-secondary" onClick={decrementSkipCount}>-</InputGroup.Text>
            <Cleave className={'form-control'}
                    value={skipCount}
                    onChange={(e) => setSkipCount(+e.target.rawValue)}
                    options={{
                        numeral: true,
                        delimiter: ' ',
                        numeralPositiveOnly: true,
                    }}
            />
            <InputGroup.Text as={Button} variant="outline-secondary" onClick={incrementSkipCount}>+</InputGroup.Text>
        </InputGroup>
    );
};

export default SkipRowsInputGroup;