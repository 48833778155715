import {createSlice} from "@reduxjs/toolkit";
import contractorService from "../../../../services/contractorService";
import EventBus from "../../../eventbus/EventBus";
import {
    REGISTER_CONTRACTOR_ORDER_BOTS_REQUESTED,
    REGISTER_CONTRACTOR_ORDER_BOTS_SUCCESS,
    REGISTER_CONTRACTOR_ORDER_BOTS_FAILED,
    UPDATE_CONTRACTOR_ORDER_BOTS_REQUESTED,
    UPDATE_CONTRACTOR_ORDER_BOTS_SUCCESS,
    UPDATE_CONTRACTOR_ORDER_BOTS_FAILED,
    BIND_ORDER_BOT_CONTRACTOR_LABEL_REQUESTED,
    BIND_ORDER_BOT_CONTRACTOR_LABEL_SUCCESS,
    BIND_ORDER_BOT_CONTRACTOR_LABEL_FAILED,
    REMOVE_ORDER_BOT_CONTRACTOR_LABEL_REQUESTED,
    REMOVE_ORDER_BOT_CONTRACTOR_LABEL_SUCCESS,
    REMOVE_ORDER_BOT_CONTRACTOR_LABEL_FAILED
} from "../../../eventbus/contractor/contractorOrderBotEvent";

export const contractorOrderBotReducer = createSlice({
    name: 'orderBotReducer',
    initialState: {
        orderBots: [],
        count: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateOrderBots: (state, action) => {
            state.orderBots = action.payload || []
        },
        updateOrderBotsCount: (state, action) => {
            state.count = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
    },

})

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorOrderBotReducer.actions.updateFilterOptions({...params}))
}

export const loadContractorOrderBotsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getOrderBot(params)
        .then((response) => {
            dispatch(contractorOrderBotReducer.actions.updateOrderBots(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const loadContractorOrderBotsCountAsync = (filters) => dispatch => {
    return new Promise((resolve, reject) => {
        let {limit, page, ...other} = filters

        contractorService.getContractorOrderBotsCount(other)
            .then((response) => {
                dispatch(contractorOrderBotReducer.actions.updateOrderBotsCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const registerContractorOrderBotAsync = (payload) => {
    EventBus.dispatch(REGISTER_CONTRACTOR_ORDER_BOTS_REQUESTED)
    return new Promise((resolve, reject) => {
        contractorService.registerOrderBot(payload)
            .then((response) => {
                EventBus.dispatch(REGISTER_CONTRACTOR_ORDER_BOTS_SUCCESS)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(REGISTER_CONTRACTOR_ORDER_BOTS_FAILED)
                reject(error)
            })
    })
}

export const updateContractorOrderBotAsync = (id, addBot) => {
    EventBus.dispatch(UPDATE_CONTRACTOR_ORDER_BOTS_REQUESTED)
    return new Promise((resolve, reject) => {
        contractorService.updateOrderBot({id, payload: addBot})
            .then((response) => {
                EventBus.dispatch(UPDATE_CONTRACTOR_ORDER_BOTS_SUCCESS)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(UPDATE_CONTRACTOR_ORDER_BOTS_FAILED)
                reject(error)
            })
    })
}

export const bindOrderBotToContractorLabelAsync = (data) => {
    EventBus.dispatch(BIND_ORDER_BOT_CONTRACTOR_LABEL_REQUESTED, data)
    return new Promise((resolve, reject) => {
        contractorService.bindOrderBotWithContractorLabel(data)
            .then((response) => {
                EventBus.dispatch(BIND_ORDER_BOT_CONTRACTOR_LABEL_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(BIND_ORDER_BOT_CONTRACTOR_LABEL_FAILED, error.message)
                reject(error.message)
            })
    })
}
export const removeOrderBotToContractorLabelAsync = (data) => {
    EventBus.dispatch(REMOVE_ORDER_BOT_CONTRACTOR_LABEL_REQUESTED, data)
    return new Promise((resolve, reject) => {
        contractorService.removeOrderBotWithContractorLabel(data)
            .then((response) => {
                EventBus.dispatch(REMOVE_ORDER_BOT_CONTRACTOR_LABEL_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(REMOVE_ORDER_BOT_CONTRACTOR_LABEL_FAILED, error.message)
                reject(error.message)
            })
    })
}


export const selectContractorOrderBots = state => state.orderBots.orderBots

export const selectContractorOrderBotsCount = state => state.orderBots.count

export const selectContractorOrderBotsFilterOptions = state => state.orderBots.filterOptions


export default contractorOrderBotReducer.reducer