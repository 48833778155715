import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import ExcelRegistryService from "../../../../services/excelRegistryService";
import {
    ADD_MASS_ROAMING_INVOICE_FAILED,
    ADD_MASS_ROAMING_INVOICE_REQUESTED,
    ADD_MASS_ROAMING_INVOICE_SUCCEED, MASS_REGISTER_ROAMING_WAYBILL_FAILED, MASS_REGISTER_ROAMING_WAYBILL_REQUESTED, MASS_REGISTER_ROAMING_WAYBILL_SUCCEED,
    ROAMING_EXCEL_REGISTRY_FAILED,
    ROAMING_EXCEL_REGISTRY_REQUESTED,
    ROAMING_EXCEL_REGISTRY_SUCCESS
} from "../../../eventbus/excelRegistryEvents";

export const excelRegistrySlice = createSlice({
    name: 'excelRegistry',
    initialState: {
        isLoading: false,
        excelRegistries: [],
        excelRegistriesCount: 0,
        excelRegistriesFilterOptions: {
            page: 1,
            limit: 10,
        },
        viewPage: {
            id: null,
            showModal: false
        }
    },
    reducers: {
        setShowModal: (state, action) => {
            if (!action.payload) {
                state.viewPage = {id: null, showModal: false}
            }else {
                state.viewPage.showModal = action.payload
            }
        },
        setId:(state,action) => {
            state.viewPage.id = action.payload
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateExcelRegistries: (state, action) => {
            state.excelRegistries = action.payload;
        },
        updateExcelRegistriesCount: (state, action) => {
            state.excelRegistriesCount = action.payload;
        },
        updateExcelRegistriesFilterOptions: (state, action) => {
            state.excelRegistriesFilterOptions = {...state.excelRegistriesFilterOptions, ...action.payload};
        },
        clearExcelRegistriesFilterOptions: (state, action) => {
            state.excelRegistriesFilterOptions = action.payload
        }
    }
});

export const selectExcelRegistries = state => state.excelRegistry.excelRegistries;
export const selectExcelRegistriesCount = state => state.excelRegistry.excelRegistriesCount;
export const selectExcelRegistriesIsLoading = state => state.excelRegistry.isLoading;
export const selectExcelRegistriesFilterOptions = state => state.excelRegistry.excelRegistriesFilterOptions;
export const viewPage = state => state.excelRegistry.viewPage

export const registerMassInvoiceAsync = (payload) => {
    EventBus.dispatch(ADD_MASS_ROAMING_INVOICE_REQUESTED);

    return new Promise((resolve, reject) => {
        ExcelRegistryService.registerMassInvoice(payload)
            .then(response => {
                EventBus.dispatch(ADD_MASS_ROAMING_INVOICE_SUCCEED, response.data);
                resolve(response.data)
            })
            .catch(error => {
                EventBus.dispatch(ADD_MASS_ROAMING_INVOICE_FAILED, error);
                reject(error)
            })
    })
}
export const registerMassWaybillAsync = (payload) => {
    EventBus.dispatch(MASS_REGISTER_ROAMING_WAYBILL_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        ExcelRegistryService.registerMassWaybill(payload)
            .then(response => {
                EventBus.dispatch(MASS_REGISTER_ROAMING_WAYBILL_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(MASS_REGISTER_ROAMING_WAYBILL_FAILED, error);
                reject(error);
            })
    })
}
export const loadExcelRegistryAsync = id => {
    return new Promise((resolve, reject) => {
        ExcelRegistryService.getExcelRegistry(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}
export const loadExcelRegistriesAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    dispatch(excelRegistrySlice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        ExcelRegistryService.getExcelRegistries(params)
            .then(response => {
                dispatch(excelRegistrySlice.actions.updateExcelRegistries(response.data));
                dispatch(excelRegistrySlice.actions.updateIsLoading(false));
                resolve();
            })
            .catch(error => {
                dispatch(excelRegistrySlice.actions.updateIsLoading(false));
                reject(error);
            })
    })
}
export const loadExcelRegistriesCountAsync = params => dispatch => {
    const {limit, page, ...others} = params

    return new Promise((resolve, reject) => {
        ExcelRegistryService.getExcelRegistriesCount(others)
            .then(response => {
                dispatch(excelRegistrySlice.actions.updateExcelRegistriesCount(response.data));
                resolve();
            })
            .catch(error => reject(error))
    })
}
export const uploadFailedDocumentsToExcelAsync = id => {
    return new Promise((resolve, reject) => {
        ExcelRegistryService.uploadFailedDocumentsToExcel(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}
export const uploadSuccessDocumentsToExcelAsync = id => {
    return new Promise((resolve, reject) => {
        ExcelRegistryService.uploadSuccessDocumentsToExcel(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}
export const changeExcelRegistriesFilterOptions = params => dispatch => {
    return dispatch(excelRegistrySlice.actions.updateExcelRegistriesFilterOptions(params));
}

export const deleteExcelRegistry = ({id}) => {
    EventBus.dispatch(ROAMING_EXCEL_REGISTRY_REQUESTED, {id});
    return new Promise((resolve, reject) => {
        ExcelRegistryService.deleteExcelRegistry(id)
            .then(response => {
                EventBus.dispatch(ROAMING_EXCEL_REGISTRY_SUCCESS, response.data);
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
                EventBus.dispatch(ROAMING_EXCEL_REGISTRY_FAILED, error);
            })
    })
}

export default excelRegistrySlice.reducer;
export const {
    setId,
    setShowModal,
    clearExcelRegistriesFilterOptions
} = excelRegistrySlice.actions
