import {createSlice} from "@reduxjs/toolkit";
import roamingReferenceService from "../../../../services/roamingReference/roamingReferenceService";
import EventBus from "../../../eventbus/EventBus";
import {
    DELETE_COMPANY_ADDRESS_FAILED,
    DELETE_COMPANY_ADDRESS_REQUESTED,
    DELETE_COMPANY_ADDRESS_SUCCEED,
    DELETE_SYSTEM_COMPANY_ADDRESS_FAILED,
    DELETE_SYSTEM_COMPANY_ADDRESS_REQUESTED,
    DELETE_SYSTEM_COMPANY_ADDRESS_SUCCEED,
    REGISTER_COMPANY_ADDRESS_FAILED,
    REGISTER_COMPANY_ADDRESS_REQUESTED,
    REGISTER_COMPANY_ADDRESS_SUCCEED,
    REGISTER_SYSTEM_COMPANY_ADDRESS_FAILED,
    REGISTER_SYSTEM_COMPANY_ADDRESS_REQUESTED,
    REGISTER_SYSTEM_COMPANY_ADDRESS_SUCCEED,
    UPDATE_COMPANY_ADDRESS_FAILED,
    UPDATE_COMPANY_ADDRESS_REQUESTED,
    UPDATE_COMPANY_ADDRESS_SUCCEED,
    UPDATE_COMPANY_BINDINGS_FAILED,
    UPDATE_COMPANY_BINDINGS_REQUESTED,
    UPDATE_COMPANY_BINDINGS_SUCCEED
} from "../../../eventbus/roamingReferenceEvents";
import {createPkcs7WithTimestamp} from "../eimzo/eimzoReducer";

const initialState = {
    company: null,
    currentTurnOver: null,
    warehouses: [],
    measurements: [],
    countries: [],
    regions: [],
    districts: [],
    stations: [],
    exchangeTypes: [],
    waybillRegions: [],
    waybillDistricts: [],
    waybillVillages: [],
    companyAddresses: [],
    systemOrganizationAddresses: [],
    systemOrganizationAddressesPagination: {page: 1, limit: 100},
    systemOrganizationAddressesFilter: {name: ""},
};

export const roamingReferenceSlice = createSlice({
    name: 'roamingReference',
    initialState: initialState,
    reducers: {
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setCurrentTurnOver: (state, action) => {
            state.currentTurnOver = action.payload;
        },
        setWarehouses: (state, action) => {
            state.warehouses = action.payload;
        },
        setMeasurements: (state, action) => {
            state.measurements = action.payload;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setRegions: (state, action) => {
            state.regions = action.payload;
        },
        setDistricts: (state, action) => {
            state.districts = action.payload;
        },
        setStations: (state, action) => {
            state.stations = action.payload;
        },
        setExchangeTypes: (state, action) => {
            state.exchangeTypes = action.payload;
        },
        updateWaybillRegions: (state, action) => {
            state.waybillRegions = action.payload;
        },
        updateWaybillDistricts: (state, action) => {
            state.waybillDistricts = action.payload;
        },
        updateWaybillVillages: (state, action) => {
            state.waybillVillages = action.payload;
        },
        updateCompanyAddresses: (state, action) => {
            state.companyAddresses = action.payload;
        },
        updateSystemOrganizationAddresses: (state, action) => {
            state.systemOrganizationAddresses = action.payload;
        },
        setSystemOrganizationAddressesPagination: (state, action) => {
            state.systemOrganizationAddressesPagination = action.payload;
        },
        setSystemOrganizationAddressesFilter: (state, action) => {
            state.systemOrganizationAddressesFilter = {...state.systemOrganizationAddressesFilter, ...action.payload}
        },
    },
});

export const loadCompany = (innOrPinfl, params) => (dispatch) => {
    if (!innOrPinfl) {
        roamingReferenceSlice.actions.setCompany(null);
        return;
    }

    roamingReferenceService.getCompany(innOrPinfl, params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setCompany(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setCompany(null));
        })
};
export const loadCompanyCurrentTurnOver = (year) => (dispatch) => {
    roamingReferenceService.getMyCurrentTurnOver(year)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setCurrentTurnOver(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setCurrentTurnOver(null));
        })
};

export const getCompany = (innOrPinfl, params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCompany(innOrPinfl, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
};
export const getCompanies = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCompanies(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            })
    })
}

export const loadWarehouses = (params) => (dispatch) => {
    roamingReferenceService.getWarehouses(params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setWarehouses(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setWarehouses([]))
        })
};
export const loadMeasurements = () => (dispatch) => {
    roamingReferenceService.getMeasurements()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setMeasurements(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setMeasurements([]));
        })
};
export const loadCountries = () => (dispatch) => {
    roamingReferenceService.getCountries()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setCountries(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setCountries([]));
        })
};
export const loadRegions = () => (dispatch) => {
    roamingReferenceService.getRegions()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setRegions(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setRegions([]));
        })
};
export const loadDistricts = () => (dispatch) => {
    roamingReferenceService.getDistricts()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setDistricts(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setDistricts([]));
        })
};
export const loadStations = () => (dispatch) => {
    roamingReferenceService.getStations()
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setStations(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(roamingReferenceSlice.actions.setStations([]));
        })
};
export const loadCatalogsByCode = (payload) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCatalogsByCode(payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadCatalogByCode = (code) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCatalogByCode(code)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadCatalogsBySubPositionName = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCatalogsBySubPositionName(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}
export const loadInvestmentObjectInfo = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getInvestmentObject(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadExchangeInfo = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getExchangeInfo(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadExchangeTypeWithExchangeNumber = (params) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getExchangeTypeWithExchangeNumber(params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};
export const loadExchangeTypes = (params) => (dispatch) => {
    roamingReferenceService.getExchangeTypes(params)
        .then((response) => {
            dispatch(roamingReferenceSlice.actions.setExchangeTypes(response.data));
        })
        .catch((error) => {
            dispatch(roamingReferenceSlice.actions.setExchangeTypes([]));
            console.log(error);
        })
}
export const loadMySoliqUzContract = (id) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getMySoliqUzConract(id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
};

export const loadWaybillRegionsAsync = () => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getWaybillRegions()
            .then(response => {
                resolve(response.data)
            })
            .catch((e) => {
                reject(e)
            })
    })

};
export const loadWaybillDistrictsAsync = (regionCode) => (dispatch) => {
    roamingReferenceService.getWaybillDistricts(regionCode)
        .then(response => dispatch(roamingReferenceSlice.actions.updateWaybillDistricts(response.data)))
        .catch(() => dispatch(roamingReferenceSlice.actions.updateWaybillDistricts([])))
};
export const loadWaybillVillagesAsync = (soatoCode) => (dispatch) => {
    roamingReferenceService.getWaybillVillages(soatoCode)
        .then(response => dispatch(roamingReferenceSlice.actions.updateWaybillVillages(response.data)))
        .catch(() => dispatch(roamingReferenceSlice.actions.updateWaybillVillages([])))
};
export const loadCompanyAddressesAsync = (innOrPinfl) => (dispatch) => {
    roamingReferenceService.getCompanyAddresses(innOrPinfl)
        .then(response => dispatch(roamingReferenceSlice.actions.updateCompanyAddresses(response.data)))
        .catch(() => dispatch(roamingReferenceSlice.actions.updateCompanyAddresses([])))
}
export const registerCompanyAddressAsync = (payload) => {
    EventBus.dispatch(REGISTER_COMPANY_ADDRESS_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        roamingReferenceService.registerCompanyAddress(payload)
            .then(response => {
                EventBus.dispatch(REGISTER_COMPANY_ADDRESS_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(REGISTER_COMPANY_ADDRESS_FAILED, error);
                reject(error);
            })
    })
}
export const updateCompanyAddressAsync = (payload) => {
    EventBus.dispatch(UPDATE_COMPANY_ADDRESS_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        roamingReferenceService.updateCompanyAddress(payload)
            .then(response => {
                EventBus.dispatch(UPDATE_COMPANY_ADDRESS_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(UPDATE_COMPANY_ADDRESS_FAILED, error);
                reject(error);
            })
    })
}
export const deleteCompanyAddressAsync = (addressId) => {
    EventBus.dispatch(DELETE_COMPANY_ADDRESS_REQUESTED, addressId);

    return new Promise((resolve, reject) => {
        roamingReferenceService.deleteCompanyAddress(addressId)
            .then(response => {
                EventBus.dispatch(DELETE_COMPANY_ADDRESS_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(DELETE_COMPANY_ADDRESS_FAILED, error);
                reject(error);
            })
    })
}

// system organization
export const loadSystemOrganizationAddressesAsync = () => (dispatch) => {
    roamingReferenceService.getSystemOrganizationAddresses()
        .then(response => dispatch(roamingReferenceSlice.actions.updateSystemOrganizationAddresses(response.data)))
        .catch(() => dispatch(roamingReferenceSlice.actions.updateSystemOrganizationAddresses([])))
}
export const registerSystemOrganizationAddressesAsync = (payload) => {
    EventBus.dispatch(REGISTER_SYSTEM_COMPANY_ADDRESS_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        roamingReferenceService.registerSystemOrganizationAddresses(payload)
            .then(response => {
                EventBus.dispatch(REGISTER_SYSTEM_COMPANY_ADDRESS_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(REGISTER_SYSTEM_COMPANY_ADDRESS_FAILED, error);
                reject(error);
            })
    })
}

export const deleteSystemOrganizationAddressesAsync = (addressId) => {
    EventBus.dispatch(DELETE_SYSTEM_COMPANY_ADDRESS_REQUESTED, addressId);

    return new Promise((resolve, reject) => {
        roamingReferenceService.deleteSystemOrganizationAddresses(addressId)
            .then(response => {
                EventBus.dispatch(DELETE_SYSTEM_COMPANY_ADDRESS_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(DELETE_SYSTEM_COMPANY_ADDRESS_FAILED, error);
                reject(error);
            })
    })
}

export const setSystemOrganizationAddressPagination = (params) => (dispatch) => {
    dispatch(roamingReferenceSlice.actions.setSystemOrganizationAddressesPagination(params));
}
export const setSystemOrganizationAddressFilter = ({...params}) => dispatch => {
    dispatch(roamingReferenceSlice.actions.setSystemOrganizationAddressesFilter({...params}))
}

export const loadCompanyBindingsAsync = () => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCompanyBindings()
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};
export const loadCompanyBindingPermissionsAsync = () => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getCompanyBindingPermissions()
            .then(response => {
                const roamingWaybillV2ExecutorActionsCode = 101
                const roamingWaybillV2CustomerActionsCode = 102
                const data = response.data

                if (!data.find(x => x.code === roamingWaybillV2ExecutorActionsCode))
                    data.push({code: roamingWaybillV2ExecutorActionsCode, name: 'Отправка / отмена ТТН-2 (Новая)'})

                if (!data.find(x => x.code === roamingWaybillV2CustomerActionsCode))
                    data.push({code: roamingWaybillV2CustomerActionsCode, name: 'Подтверждение / отклонение (Новая)'})

                resolve(response.data)
            })
            .catch(error => reject(error))
    })
};
export const updateCompanyBindingsAsync = ({hashCode, certificate}) => {
    EventBus.dispatch(UPDATE_COMPANY_BINDINGS_REQUESTED);

    return new Promise((resolve, reject) => {
        createPkcs7WithTimestamp(hashCode, certificate.keyId)
            .then(({pkcs7: signature}) => {
                roamingReferenceService.updateCompanyBindings({sign: signature})
                    .then(response => {
                        EventBus.dispatch(UPDATE_COMPANY_BINDINGS_SUCCEED, response.data);
                        resolve(response.data);
                    })
                    .catch(error => {
                        EventBus.dispatch(UPDATE_COMPANY_BINDINGS_FAILED, error);
                        reject(error);
                    })
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export const clearCacheAsync = (inn) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.clearCache(inn)
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}
export const ikpuCodeAsync = (ikpuCode) => {
    return new Promise((resolve, reject) => {
        roamingReferenceService.getIkpuCode(ikpuCode)
            .then((response) => resolve(response.data))
            .catch((error) => reject(error))
    })
}

export const selectCompany = state => state.roamingReference.company;
export const selectCurrentTurnOver = state => state.roamingReference.currentTurnOver;
export const selectWarehouses = state => state.roamingReference.warehouses;
export const selectMeasurements = state => state.roamingReference.measurements;
export const selectCountries = state => state.roamingReference.countries;
export const selectRegions = state => state.roamingReference.regions;
export const selectDistricts = state => state.roamingReference.districts;
export const selectStations = state => state.roamingReference.stations;
export const selectExchangeTypes = state => state.roamingReference.exchangeTypes;
export const selectWaybillRegions = state => state.roamingReference.waybillRegions;
export const selectWaybillDistricts = state => state.roamingReference.waybillDistricts;
export const selectWaybillVillages = state => state.roamingReference.waybillVillages;
export const selectCompanyAddresses = state => state.roamingReference.companyAddresses;
export const selectSystemOrganizationAddresses = state => state.roamingReference.systemOrganizationAddresses;
export const selectSystemOrganizationAddressesPagination = state => state.roamingReference.systemOrganizationAddressesPagination;
export const selectSystemOrganizationAddressesFilter = state => state.roamingReference.systemOrganizationAddressesFilter;

export const {updateWaybillDistricts, updateWaybillVillages} = roamingReferenceSlice.actions;

export default roamingReferenceSlice.reducer;
