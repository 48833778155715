import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../../eventbus/EventBus";
import ContractorPaymentService from "../../../../../services/contractorPaymentService";
import {
    APPROVE_CONTRACTOR_DRAFT_PAYMENT_FAILED,
    APPROVE_CONTRACTOR_DRAFT_PAYMENT_REQUESTED,
    APPROVE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, DELETE_CONTRACTOR_DRAFT_PAYMENT_FAILED,
    DELETE_CONTRACTOR_DRAFT_PAYMENT_REQUESTED, DELETE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED
} from "../../../../eventbus/contractor/contractorPaymentEvents";
import dayjs from "dayjs";

const currentDate = new Date()
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

const draftPaymentSlice = createSlice({
    name: 'draftPayment',
    initialState: {
        draftPayments: [],
        draftPaymentsCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            created_at_start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            created_at_end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
        },
    },
    reducers: {
        updateDraftPayments: (state, action) => {
            state.draftPayments = action.payload;
        },
        updateDraftPaymentsCount: (state, action) => {
            state.draftPaymentsCount = action.payload;
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
});

export const loadDraftPaymentsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    ContractorPaymentService.getContractorDraftPayments(params)
        .then((response) => dispatch(draftPaymentSlice.actions.updateDraftPayments(response.data)))
        .catch(() => dispatch(draftPaymentSlice.actions.updateDraftPayments([])))
};

export const loadDraftPaymentsCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params;

    ContractorPaymentService.getContractorDraftPaymentsCount(filters)
        .then((response) => dispatch(draftPaymentSlice.actions.updateDraftPaymentsCount(response.data)))
        .catch(() => dispatch(draftPaymentSlice.actions.updateDraftPaymentsCount([])))
};

export const loadDraftPaymentAsync = (id) => {
    return new Promise((resolve, reject) => {
        ContractorPaymentService.getContractorDraftPayment(id)
            .then(response => resolve(response.data))
            .then(error => reject(error))
    })
};

export const approveDraftPaymentAsync = (draftPaymentId) => {
    EventBus.dispatch(APPROVE_CONTRACTOR_DRAFT_PAYMENT_REQUESTED, draftPaymentId);

    return new Promise((resolve, reject) => {
        ContractorPaymentService.approveContractorDraftPayment(draftPaymentId)
            .then(response => {
                EventBus.dispatch(APPROVE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, response.data);
                resolve(response.data);
            })
            .then(error => {
                EventBus.dispatch(APPROVE_CONTRACTOR_DRAFT_PAYMENT_FAILED, error);
                reject(error);
            })
    })
};

export const deleteDraftPaymentAsync = (draftPaymentId) => {
    EventBus.dispatch(DELETE_CONTRACTOR_DRAFT_PAYMENT_REQUESTED, draftPaymentId);

    return new Promise((resolve, reject) => {
        ContractorPaymentService.deleteContractorDraftPayment(draftPaymentId)
            .then(response => {
                EventBus.dispatch(DELETE_CONTRACTOR_DRAFT_PAYMENT_SUCCEED, response.data);
                resolve(response.data);
            })
            .then(error => {
                EventBus.dispatch(DELETE_CONTRACTOR_DRAFT_PAYMENT_FAILED, error);
                reject(error);
            })
    })
};

export const changeDraftPaymentFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(draftPaymentSlice.actions.updateFilterOptions({...params}))
};

export const selectDraftPayments = state => state.draftPayment.draftPayments;
export const selectDraftPaymentsCount = state => state.draftPayment.draftPaymentsCount;
export const selectDraftPaymentsFilterOptions = state => state.draftPayment.filterOptions;

export default draftPaymentSlice.reducer;