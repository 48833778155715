import React from 'react';
import {ListGroup} from "react-bootstrap";
import {Link, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import Flex from "../../../components/common/Flex";

const CashBoxDashboard = () => {

    const {url} = useRouteMatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <ListGroup>
            <ListGroup.Item  className='p-3 p-sm-4' as={Link} to={`${url}/cash-boxes`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'cashbox.bind.account.title')}</h5>
                </Flex>
            </ListGroup.Item>
            <ListGroup.Item  className='p-3 p-sm-4' as={Link} to={`${url}/branches`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.navbar.routes.branches')}</h5>
                </Flex>
            </ListGroup.Item>
            <ListGroup.Item  className='p-3 p-sm-4' as={Link} to={`${url}/cash-in`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'cashboxes.cash-in.nav.label')}</h5>
                </Flex>
            </ListGroup.Item>
            <ListGroup.Item  className='p-3 p-sm-4' as={Link} to={`${url}/cash-out`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'cashboxes.cash-out.nav.label')}</h5>
                </Flex>
            </ListGroup.Item>
            <ListGroup.Item  className='p-3 p-sm-4' as={Link} to={`${url}/expense`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'cashboxes.expense.nav.label')}</h5>
                </Flex>
            </ListGroup.Item>
            <ListGroup.Item  className='p-3 p-sm-4' as={Link} to={`${url}/transfer`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'warehouse.dashboard.items.transfer_title')}</h5>
                </Flex>
            </ListGroup.Item>
            <ListGroup.Item  className='p-3 p-sm-4' as={Link} to={`${url}/operation`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'edi.return_order.protocol_info.title')}</h5>
                </Flex>
            </ListGroup.Item>
        </ListGroup>
    );
};

export default CashBoxDashboard;
