import dayjs from "dayjs";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../app/eventbus/EventBus";
import {Card, Col, Nav, Row, Spinner, Tab} from "react-bootstrap";
import DeleteAccountSessionModal from "./DeleteAccountSessionModal";
import {REMOVE_ACCOUNT_SESSION_SUCCEED} from "../../../app/eventbus/authEvents";
import {loadAccountSessionsAsync} from "../../../app/store/reducers/auth/authReducer";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const AccountSessionsComponent = ({accounts, ...props}) => {
    const [key, setKey] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(false);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const hasAccountSessions = sessions.length > 0;

    useEffect(() => {
        const defaultAccount = accounts?.[0]?.id;
        setKey(defaultAccount);
    }, [accounts])

    useEffect(() => {
        if (key) {
            loadAccountSessions(key);
        }
    }, [key])

    const loadAccountSessions = (accountId) => {
        setLoading(true);

        loadAccountSessionsAsync(accountId)
            .then(loadedSessions => setSessions(loadedSessions.tokens || []))
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const onRemoveAccountSessionSucceed = EventBus.on(REMOVE_ACCOUNT_SESSION_SUCCEED, accountId => {
            loadAccountSessions(accountId);
        });

        return () => {
            EventBus.remove(REMOVE_ACCOUNT_SESSION_SUCCEED, onRemoveAccountSessionSucceed);
        }
    }, [])

    return (
        <Card {...props}>
            <Card.Header className="text-center">
                <h3>{t(lang, 'admin.organization.view.active_sessions.title')}</h3>
            </Card.Header>
            <Card.Body className="bg-light">
                <Tab.Container activeKey={key} onSelect={key => setKey(key)}>
                    <Row>
                        <Col sm={3}>
                            {accounts.map(account => (
                                <Nav key={account.id} variant="pills" className="flex-column cursor-pointer">
                                    <Nav.Link eventKey={account.id}>{account.username}</Nav.Link>
                                </Nav>
                            ))}
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {accounts.map(account => (
                                    <Tab.Pane key={account.id} eventKey={account.id}>
                                        {loading ? (
                                            <div className="text-center">
                                                <Spinner className='align-middle me-2' animation='border' role='switch'/>
                                            </div>
                                        ) : (
                                            <Row>
                                                {hasAccountSessions && sessions.map(session => (
                                                    <Col xs={12} lg={6} key={session.token}>
                                                        <div className="border border-secondary rounded-3 p-3 my-2">
                                                            <div>
                                                                <DeleteAccountSessionModal token={session.token} accountId={account.id}/>
                                                            </div>
                                                            <div>
                                                                <span className="mb-1 fw-black">{t(lang, 'admin.organization.view.active_sessions.session.created_at')}:</span>
                                                                <span className="ms-2 mb-1">{dayjs(session.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                            </div>
                                                            <div>
                                                                <span className="mb-1 fw-black">{t(lang, 'admin.organization.view.active_sessions.session.expires_on')}:</span>
                                                                <span className="ms-2 mb-1">{dayjs(session.expires_on).format(currentDateDayJSFormat)}</span>
                                                            </div>
                                                            <div>
                                                                <span className="mb-1 fw-black">{t(lang, 'admin.organization.view.active_sessions.session.ip_address')}:</span>
                                                                <span className="ms-2 mb-1 text-primary">{session.ip_address}</span>
                                                            </div>
                                                            <div className="mt-2">
                                                                <span className="mb-1 fw-black">{t(lang, 'admin.organization.view.active_sessions.session.user_agent')}:</span>
                                                                <span className="ms-2 mb-1">{session.user_agent}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}

                                                {!hasAccountSessions && <div className="text-center"><h4>{t(lang, 'admin.organization.view.active_sessions.no_active_session')}</h4></div>}
                                            </Row>
                                        )}
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Card.Body>
        </Card>
    );
};

export default AccountSessionsComponent;