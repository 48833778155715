import classNames from "classnames";
import {useSelector} from "react-redux";
import {Button, Table} from "react-bootstrap";
import {useFormContext, useWatch} from "react-hook-form";
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import MassRegisterRoamingWaybillImportDetails from "./MassRegisterRoamingWaybillImportDetails";
import ColumnSelector from "../../../../../components/hippo/roaming/invoice/excel/mass-add-invoice/ColumnSelector";
import excelRegistryWaybillWrapper, {ExcelRegistryWaybillColumns, ExcelRegistryWaybillColumnUnimportant} from "../../../../../enum/excel-registry/ExcelRegistryWaybillWrapper";

const MassRegisterRoamingWaybillSheetForm = ({sheet, sheetIndex, sheetsArrayName, sheetsSkipRowsArrayName, sheetsColumnKeysArrayName}) => {
    const {setValue, clearErrors, setError} = useFormContext();

    const specificSheetColumnArrayName = `${sheetsColumnKeysArrayName}.${sheetIndex}`;
    const specificSheetSkipRowsArrayName = `${sheetsSkipRowsArrayName}.${sheetIndex}`;
    const skipRowsFieldName = `${specificSheetSkipRowsArrayName}.countRow`;
    const specificSheetArrayName = `${sheetsArrayName}.${sheetIndex}`;

    const sheetColumnKeys = useWatch({name: specificSheetColumnArrayName});
    const sheetSkipRows = useWatch({name: specificSheetSkipRowsArrayName});
    const skipRows = useWatch({name: skipRowsFieldName});

    const [limit, setLimit] = useState(20);
    const tableContent = useRef(null);
    const lang = useSelector(selectLang);
    const count = sheet.data.length;
    const t = Translate;

    const deliveryType = useWatch({name: 'deliveryType', exact: true});
    const customerIsFreightForwarder = useWatch({name: 'customerIsFreightForwarder', exact: true});
    const executorIsFreightForwarder = useWatch({name: 'executorIsFreightForwarder', exact: true});
    const freightForwarderIsCarrier = useWatch({name: 'freightForwarderIsCarrier', exact: true});
    const responsiblePersonIsDriver = useWatch({name: 'responsiblePersonIsDriver', exact: true});
    const executorIsClientEmployer = useWatch({name: 'executorIsClientEmployer', exact: true});
    const customerIsClientEmployer = useWatch({name: 'customerIsClientEmployer', exact: true});

    useEffect(() => {
        validateSheetColumns()
    }, [deliveryType, customerIsFreightForwarder, executorIsFreightForwarder, freightForwarderIsCarrier, responsiblePersonIsDriver, executorIsClientEmployer, customerIsClientEmployer]);

    useEffect(() => {
        validateSheetColumns();
    }, [sheetColumnKeys])

    const resetSheetColumns = () => {
        let newSheetColumnKeys = [];

        for (let i = 0; i < sheetColumnKeys.length; i++) {
            newSheetColumnKeys.push(ExcelRegistryWaybillColumnUnimportant);
        }

        setValue(specificSheetColumnArrayName, newSheetColumnKeys);
    }

    const validateSheetColumns = () => {
        if (!!sheetColumnKeys && sheetColumnKeys.length > 0) {
            if (sheetIndex === 0) {
                validateMainSheetColumns();
            } else {
                validateSecondarySheetColumns();
            }
        }
    }

    const validateMainSheetColumns = () => {
        let noErrorsFound = true;

        for (let i = 0; i < ExcelRegistryWaybillColumns.length; i++) {
            const field = ExcelRegistryWaybillColumns[i];
            let found = false;

            if (excelRegistryWaybillWrapper.isColumnRequired(field.key)) {
                for (let j = 0; j < sheetColumnKeys.length; j++) {
                    if (field.key === sheetColumnKeys[j]) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    noErrorsFound = false;
                    setError(specificSheetArrayName, {
                        type: 'required',
                        message: t(lang, 'roaming.invoice.mass_add.columns.error_message', {column: t(lang, field.label)})
                    });
                    break;
                }
            }
        }

        if (noErrorsFound) {
            clearErrors(specificSheetArrayName);
        }
    }

    const validateSecondarySheetColumns = () => {
        // TODO implement
        return false;
    }

    if (!sheetColumnKeys || !sheetSkipRows || !sheet.data) {
        return false;
    }

    return (
        <Fragment>
            <MassRegisterRoamingWaybillImportDetails
                sheetIndex={sheetIndex}
                tableRef={tableContent}
                sheetArrayName={sheetsArrayName}
                onResetColumns={resetSheetColumns}
                skipRowsArrayName={sheetsSkipRowsArrayName}
            />

            <hr/>

            <div className="table-responsive mt-3" ref={tableContent}>
                <Table size="sm" bordered>
                    <thead>
                    <tr>
                        <th className="text-center px-3 m-0" width={20}>№</th>
                        {sheetColumnKeys.map((column, index) => (
                            <ColumnSelector
                                key={index}
                                index={index}
                                columnsOptions={ExcelRegistryWaybillColumns}
                                arrayFieldName={specificSheetColumnArrayName}
                                unimportantColumn={ExcelRegistryWaybillColumnUnimportant}
                            />
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {sheet.data.slice(0, limit).map((row, rowIndex) => (
                        <tr className={classNames('fs--1', {'bg-400 text-dark': (rowIndex + 1) <= skipRows})} key={rowIndex}>
                            <th className="text-center px-3 m-0">{rowIndex + 1}</th>
                            {row.map((cell, cellIndex) => (
                                <th key={cellIndex}>{cell}</th>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

            {count > limit && (
                <div className="w-100 text-center my-3">
                    <Button onClick={() => setLimit(limit + 20)}>{t(lang, 'Показать больше')}</Button>
                </div>
            )}
        </Fragment>
    );
};

export default MassRegisterRoamingWaybillSheetForm;