import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {selectMeasurements} from "../../../app/store/reducers/roaming/roamingReducer";


const SelectMeasurement = React.forwardRef(({onChange, defaultMeasureId, ...props}, ref) => {
    const [measure, setMeasure] = useState(null);
    const measurements = useSelector(selectMeasurements)

    useEffect(() => {
        const defaultValue = measurements.find(measurement => measurement.measureId === defaultMeasureId);
        setMeasure(defaultValue);
    }, [measurements])

    const onChangeHandler = (measurement) => {
        setMeasure(measurement);
        onChange(measurement);
    }

    const getOptionLabel = option => option.name;
    const getOptionValue = option => option.measureId;

    return (
        <ReactSelect
            ref={ref}
            {...props}
            value={measure}
            options={measurements}
            onChange={onChangeHandler}
            hideSelectedOptions={true}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
        />
    )
});

SelectMeasurement.propTypes = {
    onChange: PropTypes.func
}

SelectMeasurement.defaultProps = {
    onChange: () => {
    }
}

export default SelectMeasurement;
