import React from 'react';
import classNames from "classnames";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import {useFormContext, Controller} from "react-hook-form";
import {Card, Col, Form, FormControl, Row} from "react-bootstrap";
import {selectDateFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const DetailsForm = () => {
    const {register, formState: {errors}, control} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <Card>
                <Card.Header className="bg-light">
                    <Card.Title>{t(lang, 'roaming.waybill.send.details_form.title')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col className="my-1" md="6">
                            <Form.Group>
                                <Form.Label>{t(lang, 'roaming.waybill.send.details_form.shipment_number')}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={errors?.shipment?.number}
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.shipment_number.placeholder')}
                                    {...register('shipment.number', {
                                        required: t(lang, 'edi.common.forms.validations.is_required')
                                    })}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors?.shipment?.number?.message}
                                </FormControl.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="my-1" md="6">
                            <Form.Group>
                                <Form.Label>{t(lang, 'roaming.waybill.send.details_form.shipment_date')}<span className="text-danger">*</span>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='shipment.date'
                                    rules={{
                                        required: t(lang, 'edi.common.forms.validations.is_required'),
                                        max: {value: new Date(), message: t(lang, 'roaming.waybill.send.details_form.validations.shipment.date.more_than_current_date')}
                                    }}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            selected={field.value}
                                            dateFormat={dateFormat}
                                            onChange={(date) => field.onChange(date)}
                                            wrapperClassName={classNames({'is-invalid': errors?.shipment?.date})}
                                            className={classNames('form-control', {'is-invalid': errors?.shipment?.date})}
                                            placeholderText={t(lang, 'roaming.waybill.send.details_form.shipment_date.placeholder')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.shipment?.date?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col className="my-1" md="6">
                            <Form.Group>
                                <Form.Label>{t(lang, 'roaming.waybill.send.details_form.contract_number')}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={errors?.contract?.number}
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.contract_number.placeholder')}
                                    {...register('contract.number', {
                                        required: t(lang, 'edi.common.forms.validations.is_required')
                                    })}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors?.contract?.number?.message}
                                </FormControl.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="my-1" md="6">
                            <Form.Group>
                                <Form.Label>{t(lang, 'roaming.waybill.send.details_form.contract_date')}<span className="text-danger">*</span>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='contract.date'
                                    rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            selected={field.value}
                                            dateFormat={dateFormat}
                                            onChange={(date) => field.onChange(date)}
                                            wrapperClassName={classNames({'is-invalid': errors?.contract?.date})}
                                            className={classNames('form-control', {'is-invalid': errors?.contract?.date})}
                                            placeholderText={t(lang, 'roaming.waybill.send.details_form.contract_date.placeholder')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.contract?.date?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col className="my-1" md="6">
                            <Form.Group>
                                <Form.Label>{t(lang, 'roaming.waybill.send.details_form.waybill_number')}<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={errors?.waybill?.number}
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.waybill_number.placeholder')}
                                    {...register('waybill.number', {
                                        required: t(lang, 'edi.common.forms.validations.is_required')
                                    })}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors?.waybill?.number?.message}
                                </FormControl.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="my-1" md="6">
                            <Form.Group>
                                <Form.Label>{t(lang, 'roaming.waybill.send.details_form.waybill_date')}<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name='waybill.date'
                                    rules={{required: t(lang, 'edi.common.forms.validations.is_required')}}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            selected={field.value}
                                            dateFormat={dateFormat}
                                            onChange={field.onChange}
                                            wrapperClassName={classNames({'is-invalid': errors?.waybill?.date})}
                                            className={classNames('form-control', {'is-invalid': errors?.waybill?.date})}
                                            placeholderText={t(lang, 'roaming.waybill.send.details_form.waybill_date.placeholder')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.waybill?.date?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default DetailsForm;