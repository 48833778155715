import {toast} from "react-toastify";
import BranchForm from "./BranchForm";
import IconButton from "../../common/IconButton";
import {Button, Form, Modal} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {FormProvider, useForm} from "react-hook-form";
import React, {cloneElement, Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import checkPermission from "../../../enum/Permission/CheckPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import {PermissionBranchCreate} from "../../../enum/Permission/RolePermission";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {addBranchAsync, updateBranchRoamingAddressAsync} from "../../../app/store/reducers/branch/branchReducer";

const AddBranchModal = ({children, ...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [existName, setExistName] = useState('');
    const [existCode, setExistCode] = useState('');
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            name: null,
            code: null,
            inn: null,
            roaming_branch: {
                branch_name: null,
                branch_number: null,
            },
            location: {
                latitude: 41.311151,
                longitude: 69.279737,
                address: 'сквер Амира Темура'
            },
        },
    });

    const handleShow = () => {
        if (dispatch(checkPermission(PermissionBranchCreate))) {
            setShow(true);
        } else {
            dispatch(toastPermission());
        }
    };

    const handleClose = () => {
        methods.reset();
        setShow(false);
    };

    const onSubmit = async (formData) => {
        try {
            setIsLoading(true);

            // add branch
            const addPayload = {
                name: formData.name,
                code: formData.code,
                inn: formData.inn,
                roaming_branch: {
                    branch_name: formData.roaming_branch.branch_name,
                    branch_number: formData.roaming_branch.branch_number,
                }
            };
            const branch = await addBranchAsync(addPayload);

            // update roaming address
            const roamingAddressInfoExist = !!formData.regionCode && !!formData.districtCode && !!formData.villageCode;
            if (roamingAddressInfoExist) {
                const updatePayload = {
                    region_code: formData.regionCode,
                    region_name: formData.regionName,
                    district_code: formData.districtCode,
                    district_name: formData.districtName,
                    village_code: formData.villageCode,
                    village_name: formData.villageName,
                    street: formData.street,
                    other: formData.other,
                    longitude: formData.location.longitude,
                    latitude: formData.location.latitude,
                };
                await updateBranchRoamingAddressAsync(branch.id, updatePayload)
            }

            toast.success(t(lang, 'common.toast.success'));
            handleClose();
        } catch ({nameExists, codeExists}) {
            if (nameExists)
                return setExistName(formData.name);

            if (codeExists)
                return setExistCode(formData.code)

            return toast.error(t(lang, 'common.toast.error'))
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton variant={'falcon-primary'} size={'sm'} icon={'plus'} onClick={handleShow} {...props}>
                    {t(lang, 'edi.common.add_button')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose} size={'lg'}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{t(lang, 'edi.branches.add_branch_modal.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <BranchForm existingName={existName} existingCode={existCode} isEditing={false}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'secondary'} type={'button'} onClick={handleClose}>
                                {t(lang, 'edi.common.button.close')}
                            </Button>
                            <Button variant={'primary'} type={'submit'} disabled={isLoading}>
                                {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                                <span>{t(lang, 'edi.common.button.save')}</span>
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default AddBranchModal;
