import React, {useEffect} from 'react';
import CashBoxExpenseDataTable from "../../../../components/hippo/cashbox/expense/CashBoxExpenseDataTable";
import {useDispatch, useSelector} from "react-redux";
import {
    getAllExpenseAsync,
    getExpenseCountAsync,
    selectCashBoxExpenseFilter
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CASHBOX_EXPENSE_DELETE_SUCCESS,
    CASHBOX_EXPENSE_REGISTER_SUCCESS,
    CASHBOX_EXPENSE_UPDATE_SUCCESS
} from "../../../../app/eventbus/cashbox/cashboxEvents";

const CashBoxExpense = () => {

    const dispatch = useDispatch()
    const filters = useSelector(selectCashBoxExpenseFilter)
    const activeOrganization = useSelector(selectActiveOrganization)

    const getExpenseAsync = () => {
        dispatch(getAllExpenseAsync({...filters}))
        dispatch(getExpenseCountAsync({...filters}))
    }

    useEffect(() => {
        getExpenseAsync()
    }, [filters, activeOrganization])

    useEffect(() => {
        const onSuccessRegisterExpense = EventBus.on(CASHBOX_EXPENSE_REGISTER_SUCCESS, () => {
            getExpenseAsync()
        })
        const onSuccessUpdateExpense = EventBus.on(CASHBOX_EXPENSE_UPDATE_SUCCESS, () => {
            getExpenseAsync()
        })
        const onSuccessDeleteExpense = EventBus.on(CASHBOX_EXPENSE_DELETE_SUCCESS, () => {
            getExpenseAsync()
        })

        return () => {
            EventBus.remove(CASHBOX_EXPENSE_REGISTER_SUCCESS, onSuccessRegisterExpense)
            EventBus.remove(CASHBOX_EXPENSE_UPDATE_SUCCESS, onSuccessUpdateExpense)
            EventBus.remove(CASHBOX_EXPENSE_DELETE_SUCCESS, onSuccessDeleteExpense)
        }
    }, [activeOrganization])
    return (
        <CashBoxExpenseDataTable/>
    );
};

export default CashBoxExpense;
