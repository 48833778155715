import React from 'react';
import {useSelector} from "react-redux";
import {ListGroup} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Link, useRouteMatch} from "react-router-dom";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const Dashboard = () => {
    const lang = useSelector(selectLang);
    const {url} = useRouteMatch();
    const t = Translate;

    return (
        <ListGroup>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/organizations`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'admin.dashboard.organization.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'admin.dashboard.organization.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/payments`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'admin.dashboard.payment.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'admin.dashboard.payment.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/bank-payment-error`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'admin.dashboard.bank_payment_error.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'admin.dashboard.bank_payment_error.description')}
                </p>
            </ListGroup.Item>

            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/desktop-app/versions`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'admin.dashboard.desktop_app_versions.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'admin.dashboard.desktop_app_versions.description')}
                </p>
            </ListGroup.Item>

            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/control-company-roaming`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'admin.dashboard.control_company_roaming.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'admin.dashboard.control_company_roaming.description')}
                </p>
            </ListGroup.Item>
            <ListGroup.Item className='p-3 p-sm-4' as={Link} to={`${url}/e-imzo-signer`} action>
                <Flex justifyContent="between">
                    <h5 className="mb-1">{t(lang, 'admin.dashboard.e-imzo-signer.title')}</h5>
                </Flex>
                <p className="mb-1">
                    {t(lang, 'admin.dashboard.e-imzo-signer.description')}
                </p>
            </ListGroup.Item>
        </ListGroup>
    );
};

export default Dashboard;