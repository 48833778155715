import {
    BelowCustomsPrice,
    BelowMarketPrice,
    DocumentTypeWithInternalAct,
    ExciseSubmissionForOwnNeeds,
    ExciseUsageForOwnNeeds,
    ExitValueOfTransferredGoods,
    ForExportService,
    ForFinancialService,
    ForForeignExportService,
    ForGovernmentSecret,
    ForImportService,
    ForPerson,
    GoodAndServicesTransferAsCompensationToIndividuals,
    LossAndDamageOfGoods,
    OtherIncome,
    PriceAndPrimeCostDifference,
    RedemptionValueOfTransferredGoodsToShareholders,
    RevenuesFromTheSaleOfBusinessAsGoingConcern,
    TransferOfGoodsAndOtherAssetsOnBailmentBasis,
    TypeAdditional,
    TypeAdditionalAmendOfExpenses,
    TypeAdditionalWithoutPayment,
    TypeCorrective,
    TypeCorrectiveAmendOfExpenses,
    TypeCorrectiveWithoutPayment,
    ValueOfSoldFixedAssetsAndOtherProperty,
    VoucherValueForRedeemableGoodsAndServices
} from "../RoamingInvoiceWrapper";

export class RoamingInvoiceFormWrapper {
    isDocumentTypeInternalActInvoice(type) {
        return type === DocumentTypeWithInternalAct;
    }

    isAdditionalOrCorrective(type) {
        switch (type) {
            case TypeAdditional:
            case TypeCorrective:
            case TypeCorrectiveAmendOfExpenses:
            case TypeAdditionalAmendOfExpenses:
            case TypeCorrectiveWithoutPayment:
            case TypeAdditionalWithoutPayment:
                return true;
            default:
                return false;
        }
    }

    isSingleSided(type) {
        switch (type) {
            case ForPerson:
            case ForForeignExportService:
            case ForImportService:
            case ForGovernmentSecret:
            case ForFinancialService:
            case BelowMarketPrice:
            case BelowCustomsPrice:
            case ForExportService:
            case OtherIncome:
            case ExciseSubmissionForOwnNeeds:
            case ExciseUsageForOwnNeeds:
            case PriceAndPrimeCostDifference:
            case LossAndDamageOfGoods:
                return true;
            default:
                return false
        }
    }

    isForImportService(type) {
        return type === ForImportService;
    }

    isCorrective(type) {
        return type === TypeCorrective || type === TypeCorrectiveAmendOfExpenses || type === TypeCorrectiveWithoutPayment;
    }

    hasIncome(type) {
        switch (type) {
            case ExitValueOfTransferredGoods:
            case RedemptionValueOfTransferredGoodsToShareholders:
            case GoodAndServicesTransferAsCompensationToIndividuals:
            case TransferOfGoodsAndOtherAssetsOnBailmentBasis:
            case VoucherValueForRedeemableGoodsAndServices:
            case ValueOfSoldFixedAssetsAndOtherProperty:
            case RevenuesFromTheSaleOfBusinessAsGoingConcern:
                return true;
            default:
                return false
        }
    }
}

const roamingInvoiceFormWrapper = new RoamingInvoiceFormWrapper();

export default roamingInvoiceFormWrapper;