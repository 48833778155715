import React from 'react';
import organizationBindingWrapper from "../../../../enum/organizationBinding/OrganizationBindingWrapper";
import Error403 from "../../../errors/Error403";
import OwnerOrganizationCashBoxesDataTable from "../cash-boxes/OwnerOrganizationCashBoxesDataTable";

const ViewSupervisorCashBoxesDataTable = ({binding}) => {
    const isViewCashBoxesDisabled = organizationBindingWrapper.isCashBoxAccessDisabled(binding.cash_box_access_type);

    if (isViewCashBoxesDisabled)
        return <Error403/>;

    return (
        <OwnerOrganizationCashBoxesDataTable organizationBindingId={binding.id}/>
    );
};

export default ViewSupervisorCashBoxesDataTable;