import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import DatePicker from "react-datepicker";
import Select from "react-select";
import BillingService from "../../../../services/billingService";

const innRegex = /^(\d{9}|\d{14})$/
const SystemPaymentsDatatableFilter = ({onClear}) => {
    const dateFormat = useSelector(selectDateFormat)
    const lang = useSelector(selectLang)
    const t = Translate

    const [organizations, setOrganizations] = useState([])
    const [organizationNameOrInn, setOrganizationNameOrInn] = useState('')

    const loadOrganizationsOptions = () => {
        const baseParams = {
            skip: 0,
            limit: 10
        }
        if (innRegex.test(organizationNameOrInn)) {
            BillingService.getSystemOrganizations({...baseParams, inn: organizationNameOrInn})
                .then(response => {
                    setOrganizations(response.data)
                })
        } else {
            BillingService.getSystemOrganizations({...baseParams, name: organizationNameOrInn})
                .then(response => {
                    setOrganizations(response.data)
                })
        }
    }

    useEffect(() => {
        loadOrganizationsOptions()
    }, [organizationNameOrInn])

    return (
        <>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "admin.common.payments.datatable.navigation.filter.h1")}</h4>
            </Row>

            <Row className="mb-3 g-3">
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "admin.payments.datatable.header.organization.filter.title")}</Form.Label>
                    <Controller
                        name={'organization_id'}
                        render={({field}) => (
                            <Select
                                options={organizations}
                                noOptionsMessage={() => t(lang, "admin.payments.datatable.filter.option.not_found")}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => {
                                    return (
                                        `${option.name} / ${option.inn}`
                                    )
                                }}
                                onChange={(option) => field.onChange(option ? option.id : null)}
                                onInputChange={e => setOrganizationNameOrInn(e)}
                                inputValue={organizationNameOrInn}
                                placeholder={t(lang, "edi.common.button.select")}
                                hideSelectedOptions
                                isClearable
                                classNamePrefix="react-select"
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.date_start")}</Form.Label>
                    <Controller
                        name="created_on_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "admin.common.payments.datatable.navigation.filter.date_end")}</Form.Label>
                    <Controller
                        name="created_on_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "admin.common.payments.datatable.navigation.filter.date:placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>

                <Col md={12} lg={3} className="d-flex align-items-end justify-content-end gap-3">
                        <Button variant="falcon-primary" onClick={onClear} type='button'>{t(lang, "items.common.clear_imported_file")}</Button>
                        <Button variant="primary" type='submit'>{t(lang, "admin.common.payments.datatable.navigation.filter.show_button")}</Button>
                </Col>
            </Row>
        </>
    )
}


export default SystemPaymentsDatatableFilter;
