import ContractFormationForm from "../../../pages/main/edi/contract-formation/ContractFormationForm";
import React from "react";
import {registerContractFormationAsync} from "../../../app/store/reducers/contract-formation/contractFormationReducer";
import {useHistory} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import dayjs from "dayjs";

const RegisterContractFormationForm = ({document}) => {
    const history = useHistory()

    const form = useForm({
        defaultValues: {
            contractInfo: {
                number: "",
                date: dayjs(new Date(), 'YYYY-MM-DD').toDate(),
                name: document?.name,
                expireDate: null,
                place: "Ташкент",
                total_words: '',
                header: document?.header
            },
            form_fields: document.form_fields,
            executor: {
                name: '',
                tin: document?.executor?.inn,
                pinflTin: null,
                fio: '',
                address: '',
                bankId: null,
                account: null,
                phoneWork: null,
                mobile: null,
                bank: '',
            },
            customer: {
                name: '',
                tin: document?.customer?.inn,
                pinflTin: null,
                fio: '',
                address: '',
                bankId: null,
                account: null,
                phoneWork: null,
                mobile: null,
                bank: '',
            },
            products: [
                {
                    name: null,
                    catalogCode: null,
                    catalogName: null,
                    barCode: null,
                    measureId: '',
                    measureName: '',
                    packageCode: null,
                    packageName: null,
                    packages: null,
                    certificate: null,
                    count: null,
                    summa: null,
                    deliverySum: null,
                    exciseRate: '',
                    exciseSum: 0,
                    vatRate: '',
                    vatSum: 0,
                    deliverySumWithVat: 0,
                }
            ],
            parts: document?.parts.map((part, i) => ({
                order: i + 1,
                title: part.title,
                body: part.body,
            })),
            attachments: []
        }
    })


    const onSubmit = async (formData) => {
        const data = {
            id: document?.id,
            title: formData.contractInfo.name,
            number: formData.contractInfo.number,
            date: dayjs(formData.contractInfo.date).format('YYYY-MM-DD'),
            place: formData.contractInfo.place,
            expires_on: dayjs(formData.contractInfo.expireDate).format('YYYY-MM-DD'),
            total_in_words: formData.contractInfo.total_words,
            form_fields: formData.form_fields,
            contract_type: document?.contract_type,
            executor: {
                company_inn: formData.executor.tin,
                company_name: formData.executor.name,
                address: formData.executor.address,
                phone: formData.executor.phoneWork,
                mobile: formData.executor.mobile,
                bank_account: formData.executor.account,
                bank_mfo: formData.executor.bankId,
                physic_inn: formData.executor.pinflTin,
                physic_name: formData.executor.fio,
                bank_name: formData.executor.bank
            },
            customer: {
                company_inn: formData.customer.tin,
                company_name: formData.customer.name,
                address: formData.customer.address,
                phone: formData.customer.phoneWork,
                mobile: formData.customer.mobile,
                bank_account: formData.customer.account,
                bank_mfo: formData.customer.bankId,
                physic_inn: formData.customer.pinflTin,
                physic_name: formData.customer.fio,
                bank_name: formData.customer.bank,
            },
            items: formData.products.map((product, index) => ({
                id: product.id || "",
                OrdNo: index + 1,
                catalog_code: product.catalogCode,
                catalog_name: product.catalogName,
                barcode: product.barCode,
                name: product.name,
                measurement_id: product.measureId,
                measurement_name: product.measureName,
                package_code: product?.packageCode ? `${product?.packageCode}` : null,
                package_name: product?.packageName || null,
                quantity: +product.count,
                price: +product.summa,
                excise_rate: +product.exciseRate,
                excise_value: +product.exciseSum,
                total: +product.deliverySum,
                attachment_id: +product.certificate,
                nds_rate: +product.vatRate,
                without_vat: product.vatRate === '' || product.vatRate === null,
                nds_value: +product.vatSum,
                total_with_nds: +product.deliverySumWithVat,
            })),
            parts: document?.parts.map((part, index) => ({
                id: part.id || "",
                order: index + 1,
                title: part.title,
                body: part.body
            })),
            files: formData.attachments.map((attachment, index) => ({
                id: attachment.id || "",
                order: index + 1,
                filename: attachment.filename,
                base_64_content: attachment.file,
                expires_on: attachment.date ? dayjs(attachment.date).format('YYYY-MM-DD') : null,
                attachment_id: +attachment.attachment_id
            }))
        }

        await registerContractFormationAsync(data)
            .then(() => {
                history.push(`../view/${document?.id}`)
            })
            .catch(() => {})
    };

    return(
        <FormProvider {...form}>
            <Form>
                <ContractFormationForm document={document} onSubmit={form.handleSubmit(onSubmit)}/>
            </Form>
        </FormProvider>
    )
}

export default RegisterContractFormationForm
