import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {selectAllContractor} from '../../../../app/store/reducers/contractor/contractorReducer';
import Select from "react-select";

const SelectContractor = ({onChange, defaultValue, ...props}) => {
    const contractors = useSelector(selectAllContractor)
    const [contractor, setContractor] = useState(contractors.find(s => s.id === defaultValue) || null)

    useEffect(() => {
        onChange(contractor ? contractor : null);
    }, [contractor])

    return (
        <Select hideSelectedOptions
                {...props}
                style={{width: '100%'}}
                classNamePrefix="react-select"
                onChange={option => setContractor(option)}
                options={contractors}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={contractors.find(s => s.id === defaultValue)}
                isClearable/>
    );
};

SelectContractor.propTypes = {
    onChange: PropTypes.func
}

SelectContractor.defaultProps = {
    onChange: () => {
    }
}

export default SelectContractor;
