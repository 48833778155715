import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import numeral from "numeral";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../common/IconButton";
import {Badge, Card, Col, Row, Table} from "react-bootstrap";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate,
    updateShowInProgressAsync
} from "../../../app/store/reducers/main/mainReducer";
import PropTypes from "prop-types";
import returnInvoiceWrapper, {
    getStatusColorReturnInvoice,
    GetStatusTextReturnInvoice,
    InvoiceCreated,
    InvoiceSend
} from "../../../enum/ReturnInvoiceStatus";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";
import DeleteReturnInvoice from "./DeleteReturnInvoice";
import {faSignature} from '@fortawesome/free-solid-svg-icons';
import {
    cancelReturnInvoiceAsync,
    declineReturnInvoiceAsync,
    signReturnInvoiceAsync
} from '../../../app/store/reducers/return-invoice/returnInvoiceReducer';
import CancelReturnInvoice from './CancelReturnInvoice';
import DeclineReturnInvoice from './DeclineReturnInvoice';
import ReturnInvoicePrint from "./ReturnInvoicePrint";
import SelectCertificateWrapperComponent from "../roaming/components/SelectCertificateWrapperComponent";
import {AfterConfirmByCustomer} from "../../../app/order/OrderExecutorCreateInvoiceStrategyType";
import WarningAlert from "../roaming/components/WarningAlert";
import EventBus from "../../../app/eventbus/EventBus";
import {
    CANCEL_RETURN_INVOICE_FAILED,
    DECLINE_RETURN_INVOICE_FAILED,
    SIGN_RETURN_INVOICE_FAILED
} from "../../../app/eventbus/returnInvoiceEvents";
import UploadReturnInvoiceToExcel from "./excel/UploadReturnInvoiceToExcel";
import {
    PermissionEdiReturnInvoiceApprove,
    PermissionEdiReturnShipmentView
} from "../../../enum/Permission/EdiPermission";
import checkPermission from "../../../enum/Permission/CheckPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";

const ReturnInvoice = ({returnInvoice}) => {
    const dispatch = useDispatch();
    const tax = returnInvoice.items.reduce((acc, item) => acc + item.nds_value, 0);
    const subtotal = returnInvoice.items.reduce((acc, item) => acc + item.total, 0);
    const [allowDelete, setAllowDelete] = useState(false)
    const [showSignButton, setShowSignButton] = useState(false)
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [showDeclineButton, setShowDeclineButton] = useState(false)
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const numberFormat = useSelector(selectNumberFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onSignButtonClicked = () => {
        dispatch(updateShowInProgressAsync(true));
        signReturnInvoiceAsync(returnInvoice, currentCertificate)
            .finally(() => {
                dispatch(updateShowInProgressAsync(false));
            })
    }

    const onCancelButtonClicked = ({comment}) => {
        cancelReturnInvoiceAsync(returnInvoice, comment, currentCertificate)
            .finally(() => {
                dispatch(updateShowInProgressAsync(false));
            })
    }

    const onDeclineButtonClicked = ({comment}) => {
        declineReturnInvoiceAsync(returnInvoice, comment, currentCertificate)
            .finally(() => {
                dispatch(updateShowInProgressAsync(false));
            })
    }
    const [eventMessage, setEventMessage] = useState(null);

    useEffect(() => {
        const onSignFailedHandler = EventBus.on(SIGN_RETURN_INVOICE_FAILED, (error) => {
            setEventMessage({
                variant: "danger",
                title: t(lang, "edi.alerts.return_invoice.total_sum_error_title"),
                text: t(lang, error.message)
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        })

        const onCancelFailedHandler = EventBus.on(CANCEL_RETURN_INVOICE_FAILED, (error) => {
            setEventMessage({
                variant: "danger",
                title: t(lang, "edi.alerts.return_invoice.total_sum_error_title"),
                text: error.message
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        })

        const onDeclineFailedHandler = EventBus.on(DECLINE_RETURN_INVOICE_FAILED, (error) => {
            setEventMessage({
                variant: "danger",
                title: t(lang, "edi.alerts.return_invoice.total_sum_error_title"),
                text: error.message
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        })

        return () => {
            EventBus.remove(SIGN_RETURN_INVOICE_FAILED, onSignFailedHandler);
            EventBus.remove(CANCEL_RETURN_INVOICE_FAILED, onCancelFailedHandler);
            EventBus.remove(DECLINE_RETURN_INVOICE_FAILED, onDeclineFailedHandler);
        }
    }, [])

    useEffect(() => {
        if (activeOrganization.inn === returnInvoice.executor.inn && returnInvoice.status === InvoiceCreated)
            setShowSignButton(true)
        else if (activeOrganization.inn === returnInvoice.customer.inn && returnInvoice.status === InvoiceSend)
            setShowSignButton(true)
        else
            setShowSignButton(false)

        if (
            activeOrganization.inn === returnInvoice.executor.inn &&
            returnInvoice.status === InvoiceSend &&
            returnInvoice.return_shipment.return_order.executor_create_invoice_strategy !== AfterConfirmByCustomer.id
        )
            setShowCancelButton(true)
        else
            setShowCancelButton(false)

        if (
            activeOrganization.inn === returnInvoice.customer.inn &&
            returnInvoice.status === InvoiceSend &&
            returnInvoice.return_shipment.return_order.executor_create_invoice_strategy !== AfterConfirmByCustomer.id
        )
            setShowDeclineButton(true)
        else
            setShowDeclineButton(false)


        setAllowDelete(activeOrganization.inn === returnInvoice.executor.inn && returnInvoice.status === InvoiceCreated)
    }, [returnInvoice, activeOrganization])

    return (
        <>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

            <Card className="my-3 p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-8">{t(lang, "edi.common.created.time")}: {returnInvoice.created_at}</h3>
                    <Col className="text-end">
                        <span className="me-2">{t(lang, 'edi.common.label.status')}: </span>
                        <span className={`p-1 rounded-pill text-white fs--2`}
                              style={{backgroundColor: getStatusColorReturnInvoice(returnInvoice.status)}}>{t(lang, GetStatusTextReturnInvoice(returnInvoice.status))}</span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {showSignButton &&
                        <SelectCertificateWrapperComponent
                            submitButton={
                                <IconButton
                                    variant="falcon-primary"
                                    size="sm"
                                    icon={faSignature}
                                    iconClassName="me-1"
                                    className="mx-1 mb-2 mb-sm-0"
                                    id="signButton"
                                    onClick={onSignButtonClicked}
                                >{t(lang, 'edi.common.button.sign')}</IconButton>
                            }
                            openDialogButtonText={t(lang, 'edi.common.button.sign')}
                            permission={PermissionEdiReturnInvoiceApprove}
                        />
                        }

                        {showCancelButton &&
                        <SelectCertificateWrapperComponent
                            submitButton={
                                <CancelReturnInvoice cancel={onCancelButtonClicked}/>
                            }
                            openDialogButtonText={t(lang, 'edi.common.cancel_decline')}
                        />
                        }

                        {showDeclineButton &&
                        <SelectCertificateWrapperComponent
                            submitButton={
                                <DeclineReturnInvoice decline={onDeclineButtonClicked}/>
                            }
                            openDialogButtonText={t(lang, 'edi.common.button.decline')}
                        />
                        }

                        {allowDelete &&
                        <DeleteReturnInvoice id={returnInvoice.id}
                        />}

                            <IconButton
                                as={dispatch(checkPermission(PermissionEdiReturnShipmentView)) ? Link : 'div'}
                                onClick={()=>!dispatch(checkPermission(PermissionEdiReturnShipmentView)) && dispatch(toastPermission())}
                                to={`../return-shipment/${returnInvoice.return_shipment.id}`}
                                size="sm"
                                className="me-2"
                                transform="shrink-3"
                                variant="falcon-default"
                                icon="external-link-alt"
                            >
                                <span className="d-none d-sm-inline-block ms-1">{t(lang, 'edi.return_invoice.open_shipment')}</span>
                            </IconButton>

                        <ReturnInvoicePrint size="sm" variant="falcon-default" className="me-2" id={returnInvoice.id}/>
                        <UploadReturnInvoiceToExcel className="mx-2" id={returnInvoice.id} number={returnInvoice.return_invoice_info.number}/>
                    </Col>
                </Row>
            </Card>

            <Card className="p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.executor')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {returnInvoice.executor.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {returnInvoice.executor.inn}</span>
                    </Col>

                    <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="mb-1 fw-bold text-dark">{t(lang, 'edi.common.customer')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {returnInvoice.customer.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {returnInvoice.customer.inn}</span>
                    </Col>

                    <Col xs={12} lg={2} className={'d-flex justify-content-end'}>
                        {
                            returnInvoice?.is_customer_system_informed && <div>
                                <Badge pill bg={'info'} className={'fs--1 ms-2'}>
                                    {t(lang, 'edi.shipment.datatable.header.row.is_customer_system_informed')}
                                </Badge>
                            </div>
                        }
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card className="mt-3">
                <Card.Header>
                    <h5 className="p-0"><b className="pe-1">{t(lang, "edi.common.id")}:</b>{returnInvoice.id}</h5>

                    <Row className="my-5">
                        <h3 className="text-center">{t(lang, 'edi.common.invoice')}</h3>
                        <Col className="d-flex flex-column flex-md-row align-items-center justify-content-around">
                            <Col className="text-center mb-2 mb-md-0" xs="12" md="4">
                                <p className="mb-1 fw-bold">{t(lang, 'edi.common.return_invoice')}</p>
                                <p className="mb-1">
                                    {t(lang, 'edi.return_invoice.invoice_number.from.invoice_date', {
                                        invoice_number: returnInvoice.return_invoice_info.number,
                                        invoice_date: dayjs(returnInvoice.return_invoice_info.date, 'YYYY-MM-DD').format(dateFormat)
                                    })}
                                </p>
                            </Col>
                            <Col className="text-center mb-2 mb-md-0" xs="12" md="4">
                                <p className="mb-1 fw-bold">{t(lang, 'edi.common.contract')}</p>
                                <p className="mb-1">
                                    {t(lang, 'edi.return_invoice.contract_number.from.contract_date', {
                                        contract_number: returnInvoice.contract_info.number,
                                        contract_date: dayjs(returnInvoice.contract_info.date, 'YYYY-MM-DD').format(dateFormat)
                                    })}
                                </p>
                            </Col>
                            <Col className="text-center" xs="12" md="4">
                                <p className="mb-1 fw-bold">{t(lang, 'edi.common.shipment')}</p>
                                <p className="mb-1">
                                    {t(lang, 'edi.return_invoice.shipment_number.from.shipment_date', {
                                        shipment_number: returnInvoice.return_shipment.return_shipment_info.number,
                                        shipment_date: dayjs(returnInvoice.return_shipment.return_shipment_info.date, 'YYYY-MM-DD').format(dateFormat)
                                    })}
                                </p>
                            </Col>
                        </Col>
                    </Row>

                    <Row className="align-items-center mb-5 flex-column flex-lg-row">
                        <Col xs={12} lg={6} className="align-items-center">
                            <div className="d-flex justify-content-between">
                                <b className="col-6">{t(lang, "edi.common.executor")}:</b>
                                <span className="col-6">{returnInvoice.executor.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.executor.inn")}:</span>
                                <span className="col-6">{returnInvoice.executor.inn}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.address")}:</span>
                                <span className="col-6">{returnInvoice.executor.address}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.bank")}:</span>
                                <span className="col-6">{returnInvoice.executor.bank_info.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.account")}:</span>
                                <span className="col-6">{returnInvoice.executor.bank_info.account}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.mfo")}:</span>
                                <span className="col-6">{returnInvoice.executor.bank_info.mfo}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.nds_code")}:</span>
                                <span className="col-6">{returnInvoice.executor.nds_registration_code}
                                    <hr className={returnInvoice.executor.nds_registration_code ? 'm-0' : 'mb-0'} style={{height: "2px"}}/></span>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="align-items-center mt-3">
                            <div className="d-flex justify-content-between">
                                <b className="col-6">{t(lang, "edi.common.buyer")}:</b>
                                <span className="col-6">{returnInvoice.customer.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.address")}:</span>
                                <span className="col-6">{returnInvoice.customer.address}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.buyer.inn")}:</span>
                                <span className="col-6">{returnInvoice.customer.inn}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.bank")}:</span>
                                <span className="col-6">{returnInvoice.customer.bank_info.name}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.account")}:</span>
                                <span className="col-6">{returnInvoice.customer.bank_info.account}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.mfo")}:</span>
                                <span className="col-6">{returnInvoice.customer.bank_info.mfo}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="col-6">{t(lang, "edi.common.label.nds_code")}:</span>
                                <span className="col-6">{returnInvoice.customer.nds_registration_code}
                                    <hr className={returnInvoice.customer.nds_registration_code ? 'm-0' : 'mb-0'} style={{height: "2px"}}/></span>
                            </div>
                        </Col>
                    </Row>

                    <Table bordered responsive className="mt-3 border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th rowSpan={2}>№</th>
                            <th className="text-center">{t(lang, 'edi.common.product')}</th>
                            <th className="text-center">{t(lang, 'edi.common.barcode')}</th>
                            <th className="text-center">{t(lang, 'edi.common.measurement')}</th>
                            <th className="text-center">{t(lang, 'edi.common.quantity')}</th>
                            <th className="text-center">{t(lang, 'edi.common.price')}</th>
                            <th className="text-center">{t(lang, 'edi.common.total')}</th>
                            <th className="text-center">{t(lang, 'edi.common.nds_rate')}</th>
                            <th className="text-center">{t(lang, 'edi.common.nds_value')}</th>
                            <th className="text-center">{t(lang, 'edi.common.total_with_nds')}</th>
                            <th className="text-center">{t(lang, 'edi.common.origin')}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                            <th className="p-0 text-center">5</th>
                            <th className="p-0 text-center">6</th>
                            <th className="p-0 text-center">7</th>
                            <th className="p-0 text-center">8</th>
                            <th className="p-0 text-center">9</th>
                            <th className="p-0 text-center">10</th>
                        </tr>
                        </thead>

                        <tbody>
                        {returnInvoice.items.map((item, index) => (
                            <tr key={item.id} className="border-500">
                                <td>{index + 1}</td>
                                <td className="align-middle text-nowrap fw-semi-bold">{item.product}</td>
                                <td className="align-middle text-nowrap fw-semi-bold">{item.barcode}</td>
                                <td className="align-middle text-center">{item.measurement}</td>
                                <td className="align-middle text-center">-{item.quantity}</td>
                                <td className="align-middle text-center">{numeral.formats[numberFormat].format(item.price)}</td>
                                <td className="align-middle text-center">-{numeral.formats[numberFormat].format(item.total)}</td>
                                <td className="align-middle text-center">{item.nds_rate}</td>
                                <td className="align-middle text-center">-{numeral.formats[numberFormat].format(item.nds_value)}</td>
                                <td className="align-middle text-center">-{numeral.formats[numberFormat].format(item.total_with_nds)}</td>
                                <td className="align-middle text-center">{t(lang, returnInvoiceWrapper.getItemOriginText(item.origin))}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, 'edi.common.total')}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(subtotal)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900">{t(lang, 'edi.common.nds_value')}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(tax)}
                                    </td>
                                </tr>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, 'edi.common.total_with_nds')}:</th>
                                    <td className="fw-semi-bold">
                                        - {numeral.formats[numberFormat].format(subtotal + tax)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Footer>
                    <Row className="d-flex flex-lg-row flex-column">
                        <Col lg={6} xs={12}>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.common.company_director')}: </span>
                                <span className="col-8 text-center"> {returnInvoice.director}
                                    <hr className="d-block m-0 bg-dark" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.common.head_accountant')}: </span>
                                <span className="col-8 text-center"> {returnInvoice.accountant}
                                    <hr className="d-block m-0 bg-dark" style={{height: "2px"}}/> </span>
                            </div>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.common.good_releaser')}: </span>
                                <span className="col-8 text-center"> {returnInvoice.releaser}
                                    <hr className="d-block m-0 bg-dark" style={{height: "2px"}}/> </span>
                            </div>
                        </Col>

                        <Col className="mt-3" lg={6} xs={12}>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.invoice.proxy_number')}: </span>
                                <span className="col-8">{returnInvoice.proxy_info.number}
                                    <hr className="m-0 bg-dark" style={{height: "2px"}}/></span>
                            </div>
                            <div className="d-flex">
                                <span className="col-4 fs-0 fw-bold mb-1">{t(lang, 'edi.invoice.proxy_date')}: </span>
                                {returnInvoice.proxy_info.date && <span className="col-8 text-nowrap text-center">{dayjs(returnInvoice.proxy_info.date, 'YYYY-MM-DD').format(dateFormat)}
                                    <hr className="d-block m-0 bg-dark" style={{height: "2px"}}/></span>}
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <span>{returnInvoice.proxy_info.name}</span>
                                <hr className={`d-block col-12 bg-dark ${returnInvoice.proxy_info.name ? 'my-0' : 'mb-0'}`} style={{height: "2px"}}/>
                                <span className="fs-0 fw-bold mb-1">{t(lang, 'edi.invoice.proxy_name')}: </span>
                            </div>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </>
    );
};

ReturnInvoice.propTypes = {
    returnInvoice: PropTypes.object
};

ReturnInvoice.defaultProps = {
    returnInvoice: {}
};

export default ReturnInvoice;
