import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import Section from "../../components/common/Section";
import Logo from "../../components/hippo/Logo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import Login from "./Login";
import Registration from "./Registration";
import Logout from "./Logout";
import ForgetPassword from "./ForgetPassword";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import RegistrationWithoutInn from "./RegistrationWithoutInn";

const AuthSimpleLayout = () => {
    const lang = useSelector(selectLang);
    const {url} = useRouteMatch();
    const t = Translate;

    return (
        <Section className="py-0">
            <Row className="flex-center min-vh-100 py-6">
                <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
                    <Logo/>
                    <Card>
                        <Card.Body className="p-4 p-sm-5">
                            <Switch>
                                <Route path={`${url}/login`} exact component={Login}/>
                                <Route path={`${url}/register`} exact component={Registration}/>
                                <Route path={`${url}/register/without-inn`} exact component={RegistrationWithoutInn}/>
                                <Route path={`${url}/logout`} exact component={Logout}/>
                                <Route path={`${url}/forgot-password`} exact component={ForgetPassword}/>

                                <Redirect to="/errors/404"/>
                            </Switch>
                        </Card.Body>
                        <Card.Footer className="px-4 px-sm-5 border-top">
                            <div className="">
                                <div>
                                    <FontAwesomeIcon icon={faPhone} />
                                    <a className="mx-1" href="tel:+998712006363"> +998 71 200 63 63</a>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faTelegram} />
                                    <a className="text-decoration-none ms-2" href="https://t.me/HippoEDI" target="_blank">{t(lang, 'auth.layout.link.telegram_bot.title')}</a>
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Section>
    )
};

export default AuthSimpleLayout;
