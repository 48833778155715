import CreatableSelect from "react-select/creatable";
import React, {useEffect, useState} from "react";
import ActionButton from "../../common/ActionButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";


const VehiclesInfoSelect = ({options, isDisabled, defaultValue, noOptionsMessage, onChange, remove, append, ...props}) => {
    const [value, setValue] = useState(null)
    const [vehicles, setVehicles] = useState([])
    const lang = useSelector(selectLang)
    const t = Translate;


    useEffect(() => {
        if (vehicles.length === 0 && options) {
            setVehicles(options)
        } else {
            if (isDisabled) {
                setValue({label: defaultValue, is_active: true})
            } else {
                if (defaultValue !== value && !!defaultValue) {
                    setValue(vehicles.find(o => o.label === defaultValue))

                    vehicles?.filter(o => o?.label === defaultValue ? o.is_active = true : o.is_active = false)
                } else {
                    setValue(vehicles?.find(o => o.is_active))
                }
            }
        }

    },[defaultValue, options])



    const getOptionLabel = (option) => {
        return(
            <div className="d-flex justify-content-between align-items-center">
                <p className="mb-1 text-dark">{option?.label}</p>
                {!option?.is_active && <ActionButton icon="trash-alt" variant="action" className="p-1" title="Delete" onClick={(event) => {
                    event.stopPropagation();
                    remove(options.findIndex(f => f.id === option.id))
                }} />}
            </div>
        )
    }

    const onCreateOption = (inputValue) => {
        append({label: inputValue, is_active: false})
    }

    const onChangeOption = (option) => {
        if (option) {
            onChange(option)
            setValue(option)
            vehicles.filter(d => d.is_active ? d.is_active = true : d.is_active = false)
        } else {
            onChange(null)
            setValue(null)
            vehicles.filter(d => d.is_active ? d.is_active = false : d.is_active = false)
        }
    }



    return(
        <>
            <CreatableSelect
                {...props}
                isClearable
                value={value}
                options={options || []}
                isDisabled={isDisabled}
                defaultValue={defaultValue}
                hideSelectedOptions
                getOptionValue={(option) => option?.label}
                getOptionLabel={(option) => option?.label}
                onCreateOption={(option) => onCreateOption(option)}

                formatOptionLabel={(option) => option?.__isNew__ ? option?.label : getOptionLabel(option)}
                formatCreateLabel={(inputValue) => t(lang, 'edi.common.create_new', {value: inputValue})}
                onChange={(option) => onChangeOption(option)}
                noOptionsMessage={() => noOptionsMessage}
            />
        </>
    )
}


export default VehiclesInfoSelect;