import SendRoamingActForm from "../../../../components/hippo/roaming/act/form/SendRoamingActForm";
import useQuery from "../../../../components/hippo/roaming/invoice/form/useQuery";
import {useEffect, useState} from "react";
import {loadActAsync, loadActDraftAsync} from "../../../../app/store/reducers/roaming/roamingActReducer";
import {
    loadCatalogsByCode,
    selectCompany
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {uniq} from "underscore";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const SendAct = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const {act_id, draft_act_id, is_edit} = useQuery();
    const activeOrganization = useSelector(selectActiveOrganization);
    const company = useSelector(selectCompany);
    const [act, setAct] = useState(null);

    useEffect(() => {
        if (!act_id && !draft_act_id && company) {
            setAct({
                act_id: '',
                description: t(lang, "roaming.act.send_form.default_description", {executor_name: company.name, customer_name: "___________"}),
                notes: '',
                info: {
                    number: '',
                    date: null,
                },
                contract: {
                    number: '',
                    date: null,
                },
                executor: {
                    identifier: company?.person_num || company?.inn,
                    name: company?.short_name || company?.name,
                    branch: null,
                },
                customer: {
                    identifier: '',
                    name: '',
                    branch: null,
                },
                product_list: {
                    product_id: '',
                    items: [
                        {
                            catalog: null,
                            package: null,
                            measurement: null,
                            name: '',
                            quantity: '',
                            price: '',
                            total: '',
                            vat_rate: '',
                            vat_sum: '',
                            total_with_vat_sum: '',
                        }
                    ],
                },
            });
        }
    }, [act_id, draft_act_id, company, lang]);

    useEffect(async () => {
        if (act_id) {
            const {data: act} = await loadActAsync(act_id);
            const unknownCatalogCodes = uniq(act?.items?.map((item) => item?.catalog?.code));
            const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
            setAct({
                act_id: is_edit ? act.id : '',
                description: act.info.text,
                notes: act.notes,
                info: {
                    number: act.info.number,
                    date: act.info.date,
                },
                contract: {
                    number: act.contract.number,
                    date: act.contract.date,
                },
                executor: {
                    identifier: act.executor.inn,
                    name: act.executor.name,
                    branch: !!act.executor.branch?.branch_code ? {
                        code: act.executor.branch.branch_code,
                        name: act.executor.branch.branch_name
                    } : null,
                },
                customer: {
                    identifier: act.customer.inn,
                    name: act.customer.name,
                    branch: !!act.customer.branch?.branch_code ? {
                        code: act.customer.branch.branch_code,
                        name: act.customer.branch.branch_name
                    } : null,
                },
                product_list: {
                    product_id: is_edit ? act.product_id : '',
                    items: act.items.map((product) => {
                        let catalog = null;
                        let catalogPackage = null;
                        let measurement = null;

                        if (product.catalog?.code)
                            catalog = catalogs.find(c => c.class_code === product.catalog?.code);

                        if (product.package?.code && catalog && catalog.use_package)
                            catalogPackage = catalog.package_names.find(p => p.code === parseInt(product.package?.code));

                        return {
                            catalog: catalog,
                            package: catalogPackage,
                            measurement: measurement,
                            name: product.name,
                            quantity: product.quantity,
                            price: product.price,
                            total: product.total,
                            vat_rate: product.nds_rate,
                            vat_sum: product.nds_value,
                            total_with_vat_sum: product.total_with_nds,
                        };
                    }),
                },
            });
        }
    }, [act_id, is_edit, activeOrganization]);

    useEffect(async () => {
        if (draft_act_id) {
            const {data: draftAct} = await loadActDraftAsync(draft_act_id);
            const roamingActJSON = JSON.parse(draftAct.content);
            const unknownCatalogCodes = uniq(roamingActJSON.ProductList.Products.map((item) => item?.CatalogCode));
            const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});
            setAct({
                act_id: is_edit ? roamingActJSON.ActId : '',
                description: roamingActJSON.ActDoc.ActText,
                notes: roamingActJSON.Notes,
                info: {
                    number: roamingActJSON.ActDoc.ActNo,
                    date: roamingActJSON.ActDoc.ActDate,
                },
                contract: {
                    number: roamingActJSON.ContractDoc.ContractNo,
                    date: roamingActJSON.ContractDoc.ContractDate,
                },
                executor: {
                    identifier: roamingActJSON.SellerTin,
                    name: roamingActJSON.SellerName,
                    branch: (!!roamingActJSON.SellerBranchCode && !!roamingActJSON.SellerBranchName) ? {
                        code: roamingActJSON.SellerBranchCode,
                        name: roamingActJSON.SellerBranchName
                    } : null,
                },
                customer: {
                    identifier: roamingActJSON.BuyerTin,
                    name: roamingActJSON.BuyerName,
                    branch: (!!roamingActJSON.BuyerBranchCode && !!roamingActJSON.BuyerBranchName) ? {
                        code: roamingActJSON.BuyerBranchCode,
                        name: roamingActJSON.BuyerBranchName
                    } : null,
                },
                product_list: {
                    product_id: is_edit ? roamingActJSON.ProductList.ActProductId : '',
                    items: roamingActJSON.ProductList.Products.map((product) => {
                        let catalog = null;
                        let catalogPackage = null;
                        let measurement = null;

                        if (product.CatalogCode)
                            catalog = catalogs.find(c => c.class_code === product.CatalogCode);

                        if (product.PackageCode && catalog && catalog.use_package)
                            catalogPackage = catalog.package_names.find(p => p.code === parseInt(product.PackageCode));

                        return {
                            catalog: catalog,
                            package: catalogPackage,
                            measurement: measurement,
                            name: product.Name,
                            quantity: product.Count,
                            price: product.Summa,
                            total: product.TotalSum,
                            vat_rate: product.VatRate,
                            vat_sum: product.VatSum,
                            total_with_vat_sum: product.DeliverySum,
                        };
                    }),
                },
            });
        }
    }, [draft_act_id, is_edit, activeOrganization]);

    if (!act) return null;

    return (
        <SendRoamingActForm act={act}
                            isEdit={is_edit}
                            isCopy={!is_edit && (!!act_id || !!draft_act_id)}
        />
    )
}

export default SendAct;
