import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorBranchSlice = createSlice({
    name: 'contractorBranch',
    initialState: {
        branches: [],
        countBranches: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateBranches: (state, action) => {
            state.branches = action.payload
        },
        updateCountBranches: (state, action) => {
            state.countBranches = action.payload
        },
        updateFilterBranch: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorBranchesAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorBranches(params)
        .then(response => {
            dispatch(contractorBranchSlice.actions.updateBranches(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const changeFilterContractorBranchesAsync = (params) => (dispatch) => {
    return dispatch(contractorBranchSlice.actions.updateFilterBranch(params))
}
export const selectContractorBranches = (state) => state.contractorBranch.branches;
export const selectContractorBranchesCount = (state) => state.contractorBranch.countBranches;
export const selectContractorBranchFilterOptions = (state) => state.contractorBranch.filterOptions;
export default contractorBranchSlice.reducer