import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {roamingInvoiceItemOriginTypes} from "../../enum/RoamingInvoiceWrapper";
import ReactSelect from "react-select";
import {Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import FalconCloseButton from "./FalconCloseButton";
import Divider from "./Divider";
import IconButton from "./IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

const OriginLinkSelector = ({defaultOriginType, onChange, linkProps, linkAppendClassName, isTitle}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [origin, setOrigin] = useState(null);

    useEffect(() => {
        if (origin?.value !== defaultOriginType)
            setOrigin(roamingInvoiceItemOriginTypes.find(o => o.value === defaultOriginType));
    }, [defaultOriginType])

    const onChangeHandler = (option) => {
        setOrigin(option);
        onChange(option ? option.value : null);
        option && setShow(false);
    }

    const link = <span onClick={() => setShow(true)}
                       className={classNames('text-info cursor-pointer fs--1', linkAppendClassName)}
                       {...linkProps}
    >
        {origin && !isTitle ? t(lang, origin.label) : t(lang, 'roaming.common.items.origin')}
    </span>


    return (
        <>
            {origin && <OverlayTrigger overlay={<Tooltip>{t(lang, origin.label)}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!origin && link}
            {show &&
                <Modal show={true}
                       onHide={() => setShow(false)}
                       size="xl"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'roaming.common.items.origin')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <ReactSelect options={roamingInvoiceItemOriginTypes}
                                                 hideSelectedOptions
                                                 classNamePrefix="select"
                                                 placeholder={t(lang, "items.common.select")}
                                                 getOptionLabel={option => t(lang, option.label)}
                                                 getOptionValue={option => option.value}
                                                 isSearchable={false}
                                                 defaultMenuIsOpen={!origin}
                                                 value={null}
                                                 onChange={onChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {origin &&
                            <>
                                <Divider/>
                                <Row>
                                    <Col xs={12}>
                                        <h5 className="text-info">{t(lang, 'roaming.common.items.origin')}</h5>
                                        <div className="mt-3 fw-bold fs--1">
                                            <span className="text-primary">{origin.value} - </span>
                                            <span className="text-dark">{t(lang, origin.label)}</span>
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon={faTrash}
                                                iconClassName={"text-danger"}
                                                className="ms-2"
                                                title={t(lang, 'items.common.delete')}
                                                onClick={() => onChangeHandler(null)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default OriginLinkSelector;
