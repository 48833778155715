import {useHistory, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {loadContractAsync} from "../../../app/store/reducers/contract/contractReducer";
import ContractView from "./ContractView";

const ViewContract = () => {
    const {id} = useParams();
    const history = useHistory();
    const [contract, setContract] = useState(null)
    const activeOrganization = useSelector(selectActiveOrganization);

    const load = () => {
        loadContractAsync(id).then(data => {
            setContract(data)
        }).catch(error => {
            console.log(error);
            history.push('/crm/contracts')
        })
    }

    useEffect(() => {
        load()
    }, [id, activeOrganization])

    return (<>
        {contract && <ContractView contract={contract}/>}
        {!contract && <span>loading...</span>}
    </>)
}
export default ViewContract