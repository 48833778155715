import {useSelector} from "react-redux";
import {selectWarehouses} from "../../../app/store/reducers/warehouse/warehouseReducer";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import WarehousesDatatableHeader from "./WarehousesDatatableHeader";
import RegisterAndEditWarehouseButton from "./components/RegisterAndEditWarehouseButton";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";


const WarehousesDatatable = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const warehouses = useSelector(selectWarehouses)

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return (
                    <b>
                        {row.index + 1}
                    </b>
                )
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "warehouse.data.table.warehouse.name"),
            Cell: ({row: {original}}) => {
                return (
                    <span>{original.name}</span>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.data.table.created.date"),
            Cell: ({row: {original}}) => {
                return (
                    <span>{original?.created_at}</span>
                )
            }
        },
        {
            accessor: 'updated_at',
            Header: t(lang, "warehouse.data.table.updated.date"),
            Cell: ({row: {original}}) => {
                return (
                    <span>{original?.updated_at}</span>
                )
            }
        },
        {
            accessor: 'none',
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original: {id}}}) => {
                return (
                    <>
                        <RegisterAndEditWarehouseButton
                            id={id}
                            title={t(lang, "roaming.common.edit")}
                            icon="edit"
                            variant="falcon-warning"
                        />
                    </>
                )
            },
        },
    ];

    return (
        <AdvanceTableWrapper columns={columns}
                             data={warehouses}
                             pagination
                             selection
                             width={'50px'}
                             perPage={1000}
        >
            <Card className="mb-3">
                <Card.Header>
                    <WarehousesDatatableHeader warehouses={warehouses}
                                               table
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
            </Card>
        </AdvanceTableWrapper>
    );
}


export default WarehousesDatatable;