import axios from "axios";

class SignService {
    getSignHashCode(id) {
        return axios.get(`/roaming/sign/${id}`)
    }

    signDocument(id, {operatorUid, operatorSignature, roamingSignature, comment, certificateSerial, certificateOwner}) {
        return axios.post(`/roaming/sign/${id}`, {
            operator_uid: operatorUid,
            operator_signature: operatorSignature,
            roaming_signature: roamingSignature,
            comment: comment,
            certificate_serial: certificateSerial,
            certificate_owner: certificateOwner
        })
    }

    getCancelHashCode(id) {
        return axios.get(`/roaming/cancel/${id}`)
    }

    cancelDocument(id, {operatorUid, operatorSignature, roamingSignature, comment, certificateSerial, certificateOwner}) {
        return axios.post(`/roaming/cancel/${id}`, {
            operator_uid: operatorUid,
            operator_signature: operatorSignature,
            roaming_signature: roamingSignature,
            comment: comment,
            certificate_serial: certificateSerial,
            certificate_owner: certificateOwner
        })
    }
    getDeclineHashCode(id, comment) {
        return axios.get(`/roaming/decline/${id}`, {
            params: {comment: comment}
        })
    }

    declineDocument(id, {operatorUid, operatorSignature, roamingSignature, comment, certificateSerial, certificateOwner}) {
        return axios.post(`/roaming/decline/${id}`, {
            operator_uid: operatorUid,
            operator_signature: operatorSignature,
            roaming_signature: roamingSignature,
            comment: comment,
            certificate_serial: certificateSerial,
            certificate_owner: certificateOwner
        })
    }
}

const signService = new SignService()

export default signService;