import React, {Fragment, useState} from 'react';
import IconButton from "../../common/IconButton";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {PermissionEdiShipmentReject} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {executorCancelOldRoamingDocumentAsync} from "../../../app/store/reducers/shipment/shipmentReducer";
import {selectCurrentCertificate} from "../../../app/store/reducers/eimzo/eimzoReducer";
import SelectCertificateWrapperComponent from "../roaming/components/SelectCertificateWrapperComponent";
import {Button, Form, Modal, Spinner} from "react-bootstrap";

const ExecutorCancelRoamingDocument = ({id, ...props}) => {

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeCertificate = useSelector(selectCurrentCertificate);

    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setLoading(false);
        setShow(false);
    };

    const executorCancelOldRoamingDocument = () => {
        setLoading(true);
        executorCancelOldRoamingDocumentAsync(activeCertificate, lang, id)
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <SelectCertificateWrapperComponent
                variant={'falcon-danger'}
                submitButton={
                    <IconButton
                        id={'executor-cancel-shipment'}
                        onClick={handleShow}
                        variant="falcon-danger"
                        size="sm"
                        icon="cancel"
                        transform="shrink-3"
                    >
                        <span className="d-none d-sm-inline-block">{t(lang, "edi.shipments.shipment.executor_cancel_roaming_document.title")}</span>
                    </IconButton>
                }
                openDialogButtonText={t(lang, 'edi.shipments.shipment.executor_cancel_roaming_document.title')}
            />

            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header className={'border-0'} closeButton>
                    <Modal.Title>{t(lang, "roaming.free_form_document.view.action.cancel")}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        {t(lang, "edi.common.cancel_button")}
                    </Button>
                    <Button variant="primary" className="d-flex align-items-center" disabled={loading} onClick={executorCancelOldRoamingDocument}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, "edi.common.approve")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ExecutorCancelRoamingDocument;
