import React, {Fragment} from 'react';
import {useHistory} from "react-router";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {TypeAdditional, TypeCorrective} from "../../../../../enum/roaming/WaybillV2Wrapper";
import {Button, Col, Form, InputGroup} from "react-bootstrap";
import classNames from "classnames";
import DatePicker from "react-datepicker";
const WaybillV2OldDocInfo = () => {
    const history = useHistory();
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const form = useFormContext();
    const waybillType = useWatch({name: `waybill_type`, exact: true});

    const onSearchInvoice = () => {
        const id = form.getValues(`oldWaybillDoc.oldWaybillId`);
        history.push({search: `?waybill_id=${id}&type=${waybillType}`});
    };

    switch (waybillType) {
        case TypeAdditional:
        case TypeCorrective:
            return (
                <Fragment>
                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>
                            {t(lang, 'roaming.waybill.v2.send.id')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup className={classNames({'is-invalid': form.formState.errors?.oldWaybillDoc?.oldWaybillId} )}>
                            <Form.Control name={`oldWaybillDoc.oldWaybillId`}
                                          placeholder={t(lang, 'roaming.waybill.v2.send.id.placeholder')}
                                          isInvalid={form.formState.errors?.oldFacturaDoc?.oldFacturaId}
                                          {...form.register(`oldWaybillDoc.oldWaybillId`, {
                                              required: t(lang, 'items.common.validations.is_required'),
                                              shouldUnregister: true
                                          })}
                            />
                            <Button onClick={onSearchInvoice}>{t(lang, 'roaming.invoice.find')}</Button>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.oldWaybillDoc?.oldWaybillId?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>
                            {t(lang, 'roaming.waybill.v2.send.old.number')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control name={`oldWaybillDoc.oldWaybillNo`}
                                      placeholder={t(lang, 'roaming.waybill.v2.send.old.number:placeholder')}
                                      isInvalid={form.formState.errors?.oldWaybillDoc?.oldWaybillNo}
                                      disabled
                                      {...form.register(`oldWaybillDoc.oldWaybillNo`, {
                                          required: t(lang, 'items.common.validations.is_required'),
                                          shouldUnregister: true
                                      })}
                        />
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.oldWaybillDoc?.oldWaybillNo?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group  as={Col} sm={12} md={3}>
                        <Form.Label>
                            {t(lang, 'roaming.waybill.v2.send.old.date')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller name={`oldWaybillDoc.oldWaybillDate`}
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                        shouldUnregister: true
                                    }}
                                    render={({field}) => (
                                        <DatePicker selected={field.value}
                                                    onChange={field.onChange}
                                                    placeholderText={t(lang, 'roaming.waybill.v2.send.old.date:placeholder')}
                                                    dateFormat={dateFormat}
                                                    disabled
                                                    className={classNames('form-control', {
                                                        'is-invalid': form.formState.errors?.oldWaybillDoc?.oldWaybillDate,
                                                    })}
                                                    wrapperClassName={classNames({
                                                        'is-invalid': form.formState.errors?.oldWaybillDoc?.oldWaybillDate
                                                    })}
                                        />
                                    )}
                        />
                        <Form.Control.Feedback type="invalid">{form.formState.errors?.oldWaybillDoc?.oldWaybillDate?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Fragment>
            )
        default:
            return null;
    }
};

export default WaybillV2OldDocInfo;
