import React from 'react';
import {Button, Pagination} from "react-bootstrap";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis} from "@fortawesome/free-solid-svg-icons";

const DataTablePagination = ({table, count, pagination}) => {
    const pagesCount = Math.ceil(count / pagination.pageSize);

    let isPageNumberOutOfRange;
    const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
        const pageNumber = index;
        const isPageNumberFirst = pageNumber === 0;
        const isPageNumberLast = pageNumber === pagesCount - 1;
        const isCurrentPageWithinTwoPageNumbers =
            Math.abs(pageNumber - pagination.pageIndex) <= 2;

        if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinTwoPageNumbers) {
            isPageNumberOutOfRange = false;
            return (
                <li key={pageNumber} className={classNames({active: pageNumber === pagination.pageIndex})}>
                    <Button size="sm"
                            variant="falcon-default"
                            className={classNames('page')}
                            onClick={() => table.setPageIndex(pageNumber)}
                    >
                        {pageNumber + 1}
                    </Button>
                </li>
            );
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            const isEllipsisBefore = pageNumber < pagination.pageIndex;
            const targetPage = isEllipsisBefore
                ? Math.max(0, pagination.pageIndex - 3)
                : Math.min(pagesCount - 1, pagination.pageIndex + 3);
            return (
                <li key={`ellipsis-${pageNumber}`}>
                    <Button size="sm"
                            variant="falcon-default"
                            onClick={() => table.setPageIndex(targetPage)}
                    >
                        <FontAwesomeIcon icon={faEllipsis}/>
                    </Button>
                </li>
            )
        }

        return null;
    })

    return (
        <Pagination className="d-flex flex-wrap align-items-center justify-content-center gap-1">
            <li>
                <Button size="sm"
                        variant="falcon-default"
                        disabled={!table.getCanPreviousPage()}
                        onClick={() => table.setPageIndex(0)}
                >
                    |<FontAwesomeIcon icon="chevron-left"/>
                </Button>
            </li>
            <li>
                <Button size="sm"
                        variant="falcon-default"
                        disabled={!table.getCanPreviousPage()}
                        onClick={() => table.previousPage()}
                >
                    <FontAwesomeIcon icon="chevron-left"/>
                </Button>
            </li>
            {pageNumbers}
            <li>
                <Button size="sm"
                        variant="falcon-default"
                        disabled={!table.getCanNextPage()}
                        onClick={() => table.nextPage()}
                >
                    <FontAwesomeIcon icon="chevron-right"/>
                </Button>
            </li>
            <li>
                <Button size="sm"
                        variant="falcon-default"
                        disabled={!table.getCanNextPage()}
                        onClick={() => table.setPageIndex(pagesCount - 1)}
                >
                    <FontAwesomeIcon icon="chevron-right"/>|
                </Button>
            </li>
        </Pagination>
    );
};

export default DataTablePagination;