import {useHistory, useRouteMatch} from "react-router-dom";
import React, {useEffect, useState} from "react";
import EventBus from "../../../../app/eventbus/EventBus";
import Attorney from "../../../../components/hippo/attorney/Attorney";
import {loadAttorneyAsync} from "../../../../app/store/reducers/attorney/attorneyReducer";
import {
    ACCEPT_ATTORNEY_SUCCESS,
    CANCEL_ATTORNEY_SUCCESS, DECLINE_ATTORNEY_SUCCESS,
    SIGN_ATTORNEY_SUCCESS
} from "../../../../app/eventbus/attorneyEvents";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiAttorneyView} from "../../../../enum/Permission/EdiPermission";

const ViewAttorney = () => {
    const {params: {id}} = useRouteMatch();
    const [attorney, setAttorney] = useState(null);
    const dispatch = useDispatch()
    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiAttorneyView))) {
            if (history.length > 1) {
                history.push('../attorneys')
            } else {
                history.push('/')
            }
        }
    }, [permission])
    const loadAttorney = () => {
        loadAttorneyAsync(id)
        .then(loadedAttorney => {
            setAttorney(loadedAttorney)
        });
    }

    useEffect(() => {
        loadAttorney()
    }, [id]);

    useEffect(() => {
        const onSignAttorneySuccessHandler = EventBus.on(SIGN_ATTORNEY_SUCCESS, () => {
            dispatch(getOrganizationBillingInfoAsync());
            loadAttorney();
        });

        const onCancelAttorneySuccessHandler = EventBus.on(CANCEL_ATTORNEY_SUCCESS, () => {
            loadAttorney();
        });

        const onAcceptAttorneySuccessHandler = EventBus.on(ACCEPT_ATTORNEY_SUCCESS, () => {
            loadAttorney();
        });

        const onDeclineAttorneySuccessHandler = EventBus.on(DECLINE_ATTORNEY_SUCCESS, () => {
            loadAttorney();
        })

        return () => {
            EventBus.remove(SIGN_ATTORNEY_SUCCESS, onSignAttorneySuccessHandler);
            EventBus.remove(CANCEL_ATTORNEY_SUCCESS, onCancelAttorneySuccessHandler);
            EventBus.remove(ACCEPT_ATTORNEY_SUCCESS, onAcceptAttorneySuccessHandler);
            EventBus.remove(DECLINE_ATTORNEY_SUCCESS, onDeclineAttorneySuccessHandler);
        }
    }, [])

    return (
        <>
            {attorney && <Attorney attorney={attorney}/>}
            {!attorney && <span>loading....</span>}
        </>
    )
};

export default ViewAttorney;
