import React from 'react';
import ReactSelect from "react-select";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {incomeTypes} from "../../../../../enum/RoamingInvoiceWrapper";

const RoamingInvoiceFormIncomeTypeSelector = ({defaultIncomeType, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ReactSelect options={incomeTypes}
                     placeholder={t(lang, 'roaming.common.select.placeholder')}
                     getOptionValue={option => option.value}
                     getOptionLabel={option => t(lang, option.label)}
                     value={incomeTypes.find(o => o.value === defaultIncomeType) || null}
                     onChange={(option) => onChange(option ? option.value : null)}
                     isClearable={true}
        />
    );
};

export default RoamingInvoiceFormIncomeTypeSelector;