import {FormProvider, useForm} from 'react-hook-form';
import React, {useContext, useEffect, useState} from 'react';
import {Col, Form, Row,} from 'react-bootstrap'
import ForgotPasswordStepOne from "./ForgotPasswordStepOne";
import ForgotPasswordStepTwo from "./ForgotPasswordStepTwo";
import ForgotPasswordStepThree from "./ForgotPasswordStepThree";
import ForgotPasswordStepFour from "./ForgotPasswordStepFour";
import {resetPasswordAsync, sendForgotPasswordVerificationCodeAsync} from "../../../../app/store/reducers/auth/authReducer";
import {FORGOT_PASSWORD_RESTORE_FAILED} from "../../../../app/eventbus/authEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import {toast} from "react-toastify";
import IconButton from "../../../common/IconButton";
import PropTypes from 'prop-types';
import AppContext from "../../../../context/Context";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ForgotPasswordFooter = ({onGoBack}) => {
    const {isRTL} = useContext(AppContext);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Row className="g-2 mb-3">
            <Form.Group className="mb-3 text-start" as={Col} sm={6}>
                <IconButton variant="link" icon={isRTL ? 'chevron-right' : 'chevron-left'} iconAlign="left" transform="down-1 shrink-4" className="px-0 fw-semi-bold" onClick={onGoBack}>
                    {t(lang, 'authentication.forgot-password.prev_button')}
                </IconButton>
            </Form.Group>
            <Form.Group className="mb-3 text-end" as={Col} sm={6}>
                <IconButton variant="primary" className="ms-auto px-5" type="submit" icon={isRTL ? 'chevron-left' : 'chevron-right'} iconAlign="right" transform="down-1 shrink-4">
                    {t(lang, 'authentication.forgot-password.next_button')}
                </IconButton>
            </Form.Group>
        </Row>
    );
};

ForgotPasswordFooter.propTypes = {
    onGoBack: PropTypes.func.isRequired
}

const ForgotPasswordForm = props => {
    const methods = useForm();

    const [step, setStep] = useState(1);
    const [ticket, setTicket] = useState(null);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState('');

    useEffect(() => {
        const onForgetPasswordFailedHandler = EventBus.on(FORGOT_PASSWORD_RESTORE_FAILED, () => {
            toast.error(`Reset password failed`);
        });

        return () => {
            EventBus.remove(FORGOT_PASSWORD_RESTORE_FAILED, onForgetPasswordFailedHandler);
        }
    }, [])

    const onSubmit = ({username, confirmCode, password}) => {
        switch (step) {
            case 1:
                sendForgotPasswordVerificationCodeAsync(username)
                    .then(ticket => {
                        setTicket(ticket);
                        setStep(step + 1);
                    })
                break;

            case 2:
                setStep(step + 1);
                break;

            case 3:
                resetPasswordAsync({
                    username: username,
                    new_password: password,
                    confirm_ticket: ticket,
                    confirm_code: confirmCode,
                })
                    .then(() => {
                        setStep(step + 1);
                    })
                    .catch(({invalidConfirmationCode}) => {
                        if (invalidConfirmationCode) {
                            setInvalidConfirmCode(confirmCode);
                            setStep(2);
                        }
                    })
                break;
        }
    }

    const onGoBack = () => {
        setStep(step - 1);
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} {...props}>
                {step === 1 && <ForgotPasswordStepOne/>}
                {step === 2 && <ForgotPasswordStepTwo invalidConfirmCode={invalidConfirmCode} footer={<ForgotPasswordFooter onGoBack={onGoBack}/>}/>}
                {step === 3 && <ForgotPasswordStepThree footer={<ForgotPasswordFooter onGoBack={onGoBack}/>}/>}
                {step === 4 && <ForgotPasswordStepFour/>}
            </Form>
        </FormProvider>
    );
}

export default ForgotPasswordForm;