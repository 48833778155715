import React, {useEffect} from "react"
import ContractorsDataTable from "../../../../components/hippo/crm/contractor/ContractorDataTable"
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import Error403 from "../../../../components/errors/Error403";
import {PermissionCrmOperationContractorsView} from "../../../../enum/Permission/CrmPermission";
import {loadAllContractorAsync} from "../../../../app/store/reducers/contractor/contractorReducer";
import {BIND_EMPLOYEE_TO_CONTRACTOR_SUCCEED, UNBIND_EMPLOYEE_FROM_CONTRACTOR_SUCCEED} from "../../../../app/eventbus/contractor/contractorEvents";

const Contractors = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const onEmployeeSuccessfullyBindedToContractor = EventBus.on(BIND_EMPLOYEE_TO_CONTRACTOR_SUCCEED, () => {
            loadContractors();
        });

        const onEmployeeSuccessfullyUnbindedFromContractor = EventBus.on(UNBIND_EMPLOYEE_FROM_CONTRACTOR_SUCCEED, () => {
            loadContractors();
        });

        return () => {
            EventBus.remove(BIND_EMPLOYEE_TO_CONTRACTOR_SUCCEED, onEmployeeSuccessfullyBindedToContractor);
            EventBus.remove(UNBIND_EMPLOYEE_FROM_CONTRACTOR_SUCCEED, onEmployeeSuccessfullyUnbindedFromContractor);
        };
    }, []);

    const loadContractors = () => {
        dispatch(loadAllContractorAsync());
    };

    return (<ContractorsDataTable/>)
}

export default Contractors
