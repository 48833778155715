import React from 'react';
import {Col, Row} from "react-bootstrap";
import Bought from "./Bought";
import Payment from "./Payment";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const BillingCards = ({tab}) => {

    const lang = useSelector(selectLang);
    const t = Translate;


    return (
        <Row className={'mt-3'}>
            <Col cols={6} xl={6}>
                <h3 className={'text-center'}>{t(lang, 'main.billing.tabs.payment.title')}</h3>
                <Payment tab={tab} />
            </Col>
            <Col cols={6} xl={6}>
                <h3 className={'text-center'}>{t(lang, 'main.billing.tabs.bought.title')}</h3>
                <Bought tab={tab}/>
            </Col>
        </Row>
    );
};

export default BillingCards;
