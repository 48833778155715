export const CashBoxOperationTypePayment = 1
export const CashBoxOperationTypePayout = 2
export const CashBoxOperationTypeCashIn = 3
export const CashBoxOperationTypeCashOut = 4
export const CashBoxOperationTypeExpense = 5
export const CashBoxOperationTypeTransferIn = 6
export const CashBoxOperationTypeTransferOut = 7

export const CashBoxOperationLabelPayment = "cash_boxes.operation.type.payment"
export const CashBoxOperationLabelPayout = "cash_boxes.operation.type.payout"
export const CashBoxOperationLabelCashIn = "cash_boxes.operation.type.cash_in"
export const CashBoxOperationLabelCashOut = "cash_boxes.operation.type.cash_out"
export const CashBoxOperationLabelExpense = "cashboxes.expense.nav.label"

export const CashBoxOperationLabelTransferIn = "cash_boxes.operation.type.transfer_in"
export const CashBoxOperationLabelTransferOut = "cash_boxes.operation.type.transfer_out"


export const CASH_IN = 'cash_in'
export const CASH_OUT = 'cash_out'
export const CASH_EXPENSE = 'cash_expense'
export const TRANSFER = 'transfer'
export const PURCHASE = 'purchase'
export const REFUND = 'refund'
export const RETURN = 'return'
export const SALE = 'sale'
export const PAYMENT = 'payment'
export const PAYOUT = 'payout'

export const CashBoxOperationTypes = [
    {
        label: CashBoxOperationLabelPayment,
        value: CashBoxOperationTypePayment,
        variant: 'success'
    },
    {
        label: CashBoxOperationLabelPayout,
        value: CashBoxOperationTypePayout,
        variant: 'dark'
    },
    {
        label: CashBoxOperationLabelCashIn,
        value: CashBoxOperationTypeCashIn,
        variant: 'primary'
    },
    {
        label: CashBoxOperationLabelCashOut,
        value: CashBoxOperationTypeCashOut,
        variant: 'danger'
    },
    {
        label: CashBoxOperationLabelExpense,
        value: CashBoxOperationTypeExpense,
        variant: 'secondary'
    },
    {
        label: CashBoxOperationLabelTransferIn,
        value: CashBoxOperationTypeTransferIn,
        variant: 'info'
    },
    {
        label: CashBoxOperationLabelTransferOut,
        value: CashBoxOperationTypeTransferOut,
        variant: 'warning'
    },
]

export const currencyColor = (currencyName) => {
    switch (currencyName) {
        case 'UZS':
            return 'success'
        case 'USD':
            return 'primary'
        case 'RUB':
            return 'danger'
        default:
            return 'secondary'
    }
}

export const findOperationType = (operation) => {
    let isFind = false
    for (const operationKey in operation) {
        switch (operationKey) {
            case PURCHASE:
                if (operation[operationKey]) {
                    isFind = true
                    return {
                        label: 'crm.contractor.operation.type.purchase.label',
                        variant: 'warning'
                    }
                } else continue
            case REFUND:
                if (operation[operationKey]) {
                    isFind = true
                    return {
                        label: 'crm.contractor.operation.type.refund.label',
                        variant: 'danger'
                    }
                } else continue
            case RETURN:
                if (operation[operationKey]) {
                    isFind = true
                    return {
                        label: 'crm.contractor.operation.type.return.label',
                        variant: 'dark'
                    }
                } else continue
            case SALE:
                if (operation[operationKey]) {
                    isFind = true
                    return {
                        label: 'crm.contractor.operation.type.sale.label',
                        variant: 'success'
                    }
                }
        }
    }

    if (!isFind) {
        if (operation.type === CashBoxOperationTypePayment) {
            return {
                label: 'crm.contractor.operation.type.payment.label',
                variant: 'primary'
            }
        } else if (operation.type === CashBoxOperationTypePayout) {
            return {
                label: 'crm.contractor.operation.type.payout.label',
                variant: 'info'
            }
        } else {
            return {
                label: 'warehouse.operation.item.common.cash_box',
                variant: 'secondary'
            }

        }
    }
}