import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import React, {cloneElement, Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {approveDraftPaymentAsync} from "../../../../../app/store/reducers/crm/draft-payment/draftPaymentReducer";

const ApproveDraftPaymentModal = ({draftPaymentId, children, ...props}) => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const approveHandler = () => {
        setIsLoading(true);

        approveDraftPaymentAsync(draftPaymentId)
            .then(() => {
                toast.success(t(lang, 'common.toast.success'))
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')))
            .finally(() => setIsLoading(false))
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton variant={'primary'} onClick={handleShow} {...props}>{t(lang,'crm.common.approve.button')}</IconButton>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>{t(lang, 'crm.draft_payment.approve_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{t(lang, 'crm.draft_payment.approve_modal.description')}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'crm.common.back.button')}</Button>
                    <Button variant={'primary'} onClick={approveHandler} disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                        {t(lang, 'crm.common.approve.button')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ApproveDraftPaymentModal;