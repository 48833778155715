export const Created = 0
export const CustomerApproved = 1
export const CustomerEdited = 2
export const ExecutorDeclined = 3
export const ExecutorApproved = 4
export const InvoiceCreated = 5
export const CustomerDeleted = 6

export const GetStatusTextReturnShipment = status => {
    switch (status) {
        case CustomerApproved:
            return 'edi.return_shipment.status.customer_approved'

        case CustomerEdited:
            return 'edi.return_shipment.status.customer_edited'

        case ExecutorDeclined:
            return 'edi.return_shipment.status.executor_declined'

        case ExecutorApproved:
            return 'edi.return_shipment.status.executor_approved'

        case InvoiceCreated:
            return 'edi.return_shipment.status.invoice_created'
        case CustomerDeleted:
            return 'edi.return_shipment.status.customer_deleted'
        default:
            return 'edi.return_shipment.status.default'
    }
}

// export const GetStatusVariant = status => {
//         switch (status) {
//         case ExecutorApproved:
//                 return 'success'
//         case InvoiceCreated:
//                 return 'primary'
//         case CustomerApproved:
//                 return 'info'
//         case ExecutorDeclined:
//                 return 'danger'
//         case CustomerEdited:
//                 return 'warning'
//         default:
//             return 'secondary'
//     }
// }

export const getStatusColorReturnShipment = status => {
    switch (status) {
        case ExecutorApproved:
            return '#4caf50'
        case InvoiceCreated:
            return '#01579b'
        case CustomerApproved:
            return '#00796b'
        case ExecutorDeclined:
            return '#f44336'
        case CustomerEdited:
            return '#ef6c00'
        default:
            return '#607d8b'
    }
}


export const ReturnShipmentStatuses = [
    {id: Created, name: GetStatusTextReturnShipment(Created)},
    {id: CustomerApproved, name: GetStatusTextReturnShipment(CustomerApproved)},
    {id: CustomerEdited, name: GetStatusTextReturnShipment(CustomerEdited)},
    {id: ExecutorDeclined, name: GetStatusTextReturnShipment(ExecutorDeclined)},
    {id: ExecutorApproved, name: GetStatusTextReturnShipment(ExecutorApproved)},
    {id: InvoiceCreated, name: GetStatusTextReturnShipment(InvoiceCreated)},
    {id: CustomerDeleted, name: GetStatusTextReturnShipment(CustomerDeleted)},
]