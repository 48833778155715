import React, {useState} from 'react';
import Flex from "../../../common/Flex";
import {Button, Form, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    addTaskComment, deleteTaskComment,
    editTaskComment,
    selectModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import ConfirmationModal from "../ConfirmationModal";
import IconButton from "../../../common/IconButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import {useParams} from "react-router";
import SelectEmployeeModal from "./SelectEmployeeModal";
import {FormProvider, useForm} from "react-hook-form";
import {selectEmployees} from "../../../../app/store/reducers/employee/employeeReducer";
import classNames from "classnames";

const ModalCommentContent = ({comments, boardId, ...props}) => {
    const {id} = useParams();
    const lang = useSelector(selectLang);
    const t = Translate;
    const employees = useSelector(selectEmployees);

    const modalContent = useSelector(selectModalContent)
    const [edit, setEdit] = useState(null)
    const [loading, setLoading] = useState(false)
    const defaultCreator = JSON.parse(localStorage.getItem('defaultCreator'))

    const methods = useForm({
        defaultValues: {
            board_column_id: modalContent.board_column_id,
            board_column_task_id: modalContent.id,
            board_column_task_comment: {
                content: ''
            }
        }
    })

    const saveComment = (formData) => {
        setLoading(true)

        if (edit) {
            const params = {
                board_column_id: modalContent.board_column_id,
                board_column_task_id: modalContent.id,
                board_column_task_comment: {
                    id: edit.comment_id,
                    content: formData.board_column_task_comment?.content
                }
            }

            const index = comments.fields.findIndex(item => item.comment_id === edit.comment_id);

            editTaskComment(boardId || id, params)
                .then((res) => {
                    setEdit(null)
                    methods.reset({
                        board_column_task_comment: {
                            content: ''
                        }
                    });
                    if (index !== -1) {
                        comments.update(index, {
                            comment_id: edit.comment_id,
                            ...res
                        });
                    }
                })
                .finally(() => setLoading(false))

        }
        else {
            const params = {
                board_column_id: modalContent.board_column_id,
                board_column_task_id: modalContent.id,
                board_column_task_comment: {
                    content: formData.board_column_task_comment?.content
                }
            }

            addTaskComment(boardId || id, params)
                .then((res) => {
                    comments.append(
                        {comment_id: res.id, ...res}
                    )
                    methods.reset({
                        board_column_task_comment: {
                            content: ''
                        }
                    });
                })
                .catch((e) => {
                })
                .finally(() => setLoading(false))
        }
    }

    const deleteComment = (commentId, commenterId, index) => {

        const params = {
            board_column_id: modalContent.board_column_id,
            board_column_task_id: modalContent.id,
            board_column_task_comment_id: commentId,
        }
        return new Promise((resolve, reject) => {
            deleteTaskComment(boardId || id, params)
                .then((res) => {
                    comments.remove(index)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }


    return (
        <>
            <FormProvider {...methods}>
                <Form>
                    <Form.Group>
                        <div className="position-relative border rounded mb-3">
                            <Form.Control
                                {...methods.register('board_column_task_comment.content', {
                                    required: t(lang, 'task.board.common.forms.validations.is_required')
                                })}
                                as="textarea"
                                className={classNames("border-0 rounded-bottom-0 resize-none", {
                                    'is-invalid': methods.formState.errors['board_column_task_comment.content']
                                })}
                                placeholder={t(lang, 'task.board.modal.comments.placeholder')}
                                rows={3}
                            />
                            <Flex
                                justifyContent="end"
                                alignItems="center"
                                className="bg-light rounded-bottom p-2 mt-1"
                            >

                                    <IconButton
                                        size="sm"
                                        disabled={loading}
                                        iconClassName={'fs-0'}
                                        transform="shrink-3"
                                        onClick={methods.handleSubmit(saveComment)}
                                        {...props}
                                    >
                                        {loading && <Spinner animation="border" role="switch" size="sm"/>}
                                        <span className={'fs--1 font-weight-normal'}>
                                                {t(lang, edit ? 'task.board.common.edit' : 'task.board.common.add')}
                                            </span>
                                    </IconButton>
                            </Flex>
                        </div>
                    </Form.Group>
                </Form>
            </FormProvider>

            {comments.fields?.map((content, index) => (
                <Flex key={content?.id} className="mb-3">
                    <div className="flex-1 fs--1">
                        <p className="mb-1 bg-200 rounded-3 p-2">
                            <div className={'fs--2  d-flex align-items-center justify-content-between'}>
                                <i className="fw-bold text-primary">
                                    {employees.find(employee => employee.id === content?.commenter_id)?.name}
                                </i>
                                <i className={'fw-bold'}>
                                    {dayjs(content?.created_at).format('DD-MM-YYYY HH:mm')}
                                </i>
                            </div>

                            <div className={'ms-2 fs-1'}>{`- ${content?.content}`}</div>
                        </p>
                        <div className={'d-flex justify-content-end align-items-center gap-1'}>

                            <IconButton
                                icon={'edit'}
                                className={'p-0 me-0 border-none shadow-none'}
                                variant={'falcon-warning'}
                                onClick={() => {
                                    setEdit(content)
                                    methods.setFocus('board_column_task_comment.content')
                                    methods.setValue('board_column_task_comment.content', content?.content)
                                    methods.setValue('board_column_task_comment.commenter_id', content?.commenter_id)
                                }}
                            />

                            <ConfirmationModal
                                icon={'trash'}
                                className={'p-0 border-none shadow-none'}
                                variant={'falcon-danger'}
                                title={'Удалить'}
                                hideModalBody={true}
                                confirmationAction={() => deleteComment(
                                    content?.comment_id,
                                    content?.commenter_id ,
                                    index
                                )}
                            />
                        </div>

                    </div>
                </Flex>
            ))}

        </>
    );
};

export default ModalCommentContent;
