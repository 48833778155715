import React from 'react';
import {Col, Row} from 'react-bootstrap';
import { useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const ContractorAttorneyDataTableHeader = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.attorney.datatable.header.title")}</h5>
            </Col>
        </Row>
    );
};

export default ContractorAttorneyDataTableHeader;
