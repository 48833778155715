import React, {useEffect} from "react";
import RegisterReturnOrderForm from "../../../../components/hippo/return-order/forms/RegisterReturnOrderForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {useHistory} from "react-router-dom";
import {REGISTER_RETURN_ORDER_SUCCESS} from "../../../../app/eventbus/returnOrderEvents";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiReturnOrderCreate} from "../../../../enum/Permission/EdiPermission";

const RegisterReturnOrder = () => {
    const routerHistory = useHistory();
    const dispatch = useDispatch();

    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiReturnOrderCreate))) {
            if (routerHistory.length > 1) {
                routerHistory.push('../return-orders')
            } else {
                routerHistory.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        const onReturnOrderAddedHandler = EventBus.on(REGISTER_RETURN_ORDER_SUCCESS, (returnedOrder) => {
            routerHistory.push(`/edi/return-order/${returnedOrder.id}`)
        });

        return () => {
            EventBus.remove(REGISTER_RETURN_ORDER_SUCCESS, onReturnOrderAddedHandler);
        }
    }, []);

    return <RegisterReturnOrderForm />
};

export default RegisterReturnOrder;
