import React from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";

const RoamingConstructionInvoiceFormCardInfo = () => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);
    const {register, formState: {errors}} = useFormContext();

    return (
        <Card className="h-100">
            <Card.Body as={Row} className="g-2">

                <Form.Group as={Col} xs={12} md={3}>
                    <Form.Label>
                        {t(lang, 'roaming.invoice.number')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control name={`facturaDoc.facturaNo`}
                                  placeholder={t(lang, 'roaming.invoice.number.placeholder')}
                                  isInvalid={errors?.facturaDoc?.facturaNo}
                                  {...register(`facturaDoc.facturaNo`, {
                                      required: t(lang, 'items.common.validations.is_required'),
                                  })}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.facturaDoc?.facturaNo?.message}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={12} md={3}>
                    <Form.Label>
                        {t(lang, 'roaming.invoice.date')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller name={`facturaDoc.facturaDate`}
                                rules={{
                                    required: t(lang, 'items.common.validations.is_required'),
                                }}
                                render={({field, fieldState: {error}}) => (
                                    <DatePicker selected={field.value}
                                                onChange={field.onChange}
                                                placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                dateFormat={dateFormat}
                                                isClearable
                                                className={classNames('form-control', {
                                                    'is-invalid': errors?.facturaDoc?.facturaDate,
                                                })}
                                                wrapperClassName={classNames({
                                                    'is-invalid': errors?.facturaDoc?.facturaDate
                                                })}
                                    />
                                )}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.facturaDoc?.facturaDate?.message}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={12} md={3}>
                    <Form.Label>
                        {t(lang, 'roaming.contract.number')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control name={'contractDoc.contractNo'}
                                  placeholder={t(lang, 'roaming.contract.number.placeholder')}
                                  isInvalid={errors?.contractDoc?.contractNo}
                                  {...register('contractDoc.contractNo', {
                                      required: t(lang, 'items.common.validations.is_required'),
                                  })}

                    />
                    <Form.Control.Feedback type="invalid">{errors?.contractDoc?.contractNo?.message}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={12} md={3}>
                    <Form.Label>
                        {t(lang, 'roaming.contract.date')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller name={'contractDoc.contractDate'}
                                rules={{
                                    required: t(lang, 'items.common.validations.is_required'),
                                }}
                                render={({field}) => (
                                    <DatePicker selected={field.value}
                                                onChange={field.onChange}
                                                placeholderText={t(lang, "roaming.invoice.date.placeholder")}
                                                dateFormat={dateFormat}
                                                isClearable
                                                className={classNames('form-control', {
                                                    'is-invalid': errors?.contractDoc?.contractDate,
                                                })}
                                                wrapperClassName={classNames({
                                                    'is-invalid': errors?.contractDoc?.contractDate
                                                })}
                                    />
                                )}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.contractDoc?.contractDate?.message}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={12} md={3}>
                    <Form.Label>
                        {t(lang, 'roaming.invoice.exchange.id')}
                        {/*<span className="text-danger">*</span>*/}
                    </Form.Label>
                    <Form.Control
                        name={'lotId'}
                        placeholder={t(lang, 'roaming.invoice.exchange.id.placeholder')}
                        isInvalid={errors?.lotId}
                        {...register('lotId', {
                            // required: t(lang, 'items.common.validations.is_required'),
                        })}
                    />
                    {/*<Form.Control.Feedback type="invalid">{errors?.lotId?.message}</Form.Control.Feedback>*/}
                </Form.Group>

            </Card.Body>
        </Card>
    );
};

export default RoamingConstructionInvoiceFormCardInfo;
