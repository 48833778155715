import React, {Fragment, useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import numeral from "numeral";
import ActionButton from "../../../../../common/ActionButton";
import SelectProduct from "../../../order/components/selects/SelectProduct";
import SelectBarcode from "../../../order/components/selects/SelectBarcode";
import SelectMeasurement from "../../../order/components/selects/SelectMeasurement";
import InputGroupWithCurrency from "../../../../price/components/InputGroupWithCurrency";
import classNames from "classnames";
import {selectItems} from "../../../../../../app/store/reducers/item/itemReducer";

const ItemRowAdd = ({onDelete, arrayFieldName, index, item, productLoading}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const items = useSelector(selectItems);

    const numberFormat = useSelector(selectNumberFormat);

    const {register, formState: {errors}, watch, setValue} = useFormContext();

    const [selectedItem, setSelectedItem] = useState(null)

    const [barcodes, setBarcodes] = useState([])

    const rootName = `${arrayFieldName}.${index}`;
    const quantity = +watch(`${rootName}.quantity`);
    const amount = watch(`${rootName}.price.amount`);
    const currency_id = watch(`${rootName}.price.currency_id`);

    const total = `${rootName}.total`;

    let itemErrors = {};

    if (errors && errors[arrayFieldName] && errors[arrayFieldName][index]) {
        itemErrors = errors[arrayFieldName][index];
    }

    useEffect(() => {
        if (selectedItem) {
            setBarcodes(selectedItem?.item?.barcodes);

            setValue(`${rootName}.product`, selectedItem?.item)
            setValue(`${rootName}.barcode`, selectedItem?.item?.barcodes[0])
            setValue(`${rootName}.product_code`, selectedItem?.item?.code)
            setValue(`${rootName}.price.amount`, selectedItem?.price?.common_price?.amount)
            setValue(`${rootName}.price.currency_id`, selectedItem?.price?.common_price?.currency?.global_currency_id)
            setValue(`${rootName}.measurement`, selectedItem?.item?.measurement);
        }
    }, [selectedItem])

    useEffect(() => {
        if (item && item.product && item.product.barcodes) {
            setBarcodes(item.product.barcodes);
        }
    }, [item])

    useEffect(() => {
        setValue(total, numeral.formats[numberFormat].format((amount) * quantity));
    }, [quantity, amount])

    const onDeleteClicked = () => {
        onDelete(item);
    };

    return (
        <Fragment>
            <tr>
                <td className="p-1" style={{minWidth: '300px', maxWidth: '100%'}}>
                    <Controller
                        name={`${rootName}.product`}
                        rules={{required: t(lang, "crm.common.forms.validations.is_required")}}
                        render={({field}) => {
                            return (
                                <SelectProduct
                                    defaultValue={items.find(product => product?.item?.id === field.value?.id)}
                                    productLoading={productLoading}
                                    itemErrors={itemErrors}
                                    onChange={field.onChange}
                                    setSelectedItem={setSelectedItem}
                                />
                            )
                        }
                        }/>
                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.product?.message}
                    </Form.Control.Feedback>
                </td>
                <td className="p-1" style={{minWidth: '180px', maxWidth: '100%'}}>
                    <Controller
                        name={`${rootName}.barcode`}
                        render={({field}) => {
                            return (
                                <SelectBarcode
                                    defaultValue={field.value}
                                    barcodes={barcodes}
                                    onChange={field.onChange}
                                />
                            )
                        }}
                    />
                </td>
                <td className="p-1" style={{minWidth: '180px', maxWidth: '100%'}}>
                    <Form.Control style={{height: 38}}
                                  placeholder={t(lang, "crm.common.items.product.product_code.placeholder")}
                                  {...register(`${rootName}.product_code`)}
                    />
                </td>
                <td className="p-1" style={{minWidth: '100px', maxWidth: '100%'}}>
                    <Controller name={`${rootName}.measurement`} rules={{
                        required: t(lang, "crm.common.forms.validations.is_required"),
                    }} render={({field}) => (
                        <SelectMeasurement
                            onChange={field.onChange}
                            defaultMeasurement={String(field.value)}
                            itemErrors={itemErrors}
                        />
                    )}/>
                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.measurement?.message}
                    </Form.Control.Feedback>
                </td>
                <td className="p-1">
                    <Form.Control placeholder={t(lang, "crm.common.items.product.quantity.placeholder")}
                                  className="p-1"
                                  style={{height: 38}}
                                  isInvalid={itemErrors?.quantity}
                                  {...register(`${rootName}.quantity`, {
                                      required: t(lang, "crm.common.forms.validations.is_required"),
                                      pattern: {
                                          value: /^[1-9](\d*)((.)\d+)?$/,
                                          message: t(lang, "crm.common.forms.validations.invalid_format")
                                      },
                                  })}
                    />

                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.quantity?.message}
                    </Form.Control.Feedback>
                </td>


                <td className="p-1" style={{maxWidth: '200px'}}>
                    <Controller
                        name={`${rootName}.price.amount`}
                        rules={{
                            required: t(lang, "crm.common.forms.validations.is_required"),
                        }}
                        render={({field}) => {
                            return (
                                <InputGroupWithCurrency
                                    defaultCurrencyId={currency_id}
                                    defaultPrice={amount}
                                    onChangePrice={(val) => {
                                        setValue(`${rootName}.price.amount`, val);
                                    }}
                                    onChangeCurrency={(currency) => {
                                        setValue(`${rootName}.price.currency_id`, currency?.id)
                                    }}
                                    inputProps={{
                                        className: classNames({
                                            'is-invalid': itemErrors?.price?.amount
                                        }, 'form-control'),
                                        placeholder: "Цена",
                                        options: {
                                            numeral: true,
                                            delimiter: ' '
                                        }
                                    }}
                                    className={classNames({
                                        'is-invalid': itemErrors?.price?.amount
                                    })}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {itemErrors?.price?.amount?.message}
                    </Form.Control.Feedback>
                </td>

                <td className="p-1" style={{minWidth: '180px', maxWidth: '100%'}}>
                    <Form.Control style={{height: 38}} {...register(`${rootName}.total`)} readOnly={true}
                                  className="p-1"/>
                </td>

                <td className="text-end p-1">
                    <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-1"
                                  onClick={onDeleteClicked}/>
                </td>
            </tr>
        </Fragment>
    );
}

export default ItemRowAdd;
