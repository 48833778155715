import React, {useState} from 'react';
import {Col, Collapse, Form, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import ActDataTableFilter from "./ActDataTableFilter";
import {changeFilterOptionsAsync, selectCount} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {VIEW_DRAFT} from "../View/TypeView";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import SoftBadge from "../../../../common/SoftBadge";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingActCreate} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";


const ActDataTableHeader = ({selectedRowIds, onClickBulkSign, page, typeViewer}) => {
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false)
    const actCount = useSelector(selectCount)
    const currentCertificate = useSelector(selectCurrentCertificate)
    const dispatch = useDispatch();
    const methods = useForm();
    const lang = useSelector(selectLang);
    const t = Translate;


    const onSubmitFilter = ({order_number, ...filters}) => {
        dispatch(changeFilterOptionsAsync({
            order_number: order_number || null,
            page: 1,
            ...filters
        }))
    }

    const onBulkSign = async () => {
        let selectAct = Object.keys(selectedRowIds).map((key) => {
            let value = selectedRowIds[key]
            if (value) {
                return page[key].original
            }
            return false
        })
        onClickBulkSign(selectAct)
        return selectAct
    }


    return (
        <Row className="flex-between-center">
            <Collapse in={isOpenFilterCollapse}>
                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <ActDataTableFilter/>
                        </Form>
                    </FormProvider>
                    <hr/>
                </Col>
            </Collapse>
            <Col xs={4} sm="auto" className="d-flex flex-column pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.act_form.title")}</h5>
                <div className="mt-1">
                    {t(lang,"roaming.common.count")}:
                    <SoftBadge bg='success' className='ms-2'>
                        {actCount}
                    </SoftBadge>
                </div>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        {typeViewer === VIEW_DRAFT ? <IconButton
                            onClick={onBulkSign}
                            disabled={!currentCertificate}
                            variant="falcon-default"
                            size="sm"
                            type="button"
                            icon={faSignature}
                            iconClassName="me-1"
                            className="ms-2"
                        >{t(lang, "edi.attorney.datatable.header.navigation.sign")}
                        </IconButton>
                            :
                            ''
                        }
                    </div>
                ) : (
                    <div id="orders-actions">
                        <IconButton
                            className="mx-2"
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                            as={dispatch(checkPermission(PermissionRoamingActCreate)) ? Link : 'div'}
                            onClick={()=>!dispatch(checkPermission(PermissionRoamingActCreate)) && dispatch(toastPermission())}
                            to="../act/register"
                        >{t(lang, "edi.common.button.create")}</IconButton>

                        {   typeViewer !== VIEW_DRAFT
                            ?
                            <>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    transform="shrink-3"
                                    className="mx-2"
                                    icon="refresh"
                                    onClick={methods.handleSubmit(onSubmitFilter)}
                                >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "edi.orders.order.resume_order_button")}</span>
                                </IconButton>

                                <IconButton
                                    className="mx-2"
                                    onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="filter"
                                    transform="shrink-3"
                                >
                        <span
                            className="d-none d-sm-inline-block ms-1">{t(lang, "edi.order.datatable.header.navigation.filter")}</span>
                                </IconButton>
                            </>

                            : ''
                        }
                    </div>
                    )}
            </Col>
        </Row>
    );
};


export default ActDataTableHeader;
