export const VIEW_INBOX = "inbox";
export const VIEW_OUTBOX = "outbox";
export const VIEW_DRAFT = "draft";

export const GetViewerText = (viewer) => {
    switch (viewer) {
        case VIEW_INBOX:
            return "roaming.act.datatable.viewer.incoming";
        case VIEW_OUTBOX:
            return "roaming.act.datatable.viewer.outcoming";
        case VIEW_DRAFT:
            return "roaming.act.datatable.viewer.draft";
        default:
            return "Не определено";
    }
};

export const GetViewerIcon = (viewer) => {
    switch (viewer) {
        case VIEW_INBOX:
            return "arrow-down";
        case VIEW_OUTBOX:
            return "arrow-up";
        case VIEW_DRAFT:
            return "file-archive";
        default:
            return "";
    }
};

export const TypeViewers = [
    { id: VIEW_INBOX, name: GetViewerText(VIEW_INBOX) },
    { id: VIEW_OUTBOX, name: GetViewerText(VIEW_OUTBOX) },
    { id: VIEW_DRAFT, name: GetViewerText(VIEW_DRAFT) },
];