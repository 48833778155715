import {createSlice} from "@reduxjs/toolkit";
import DesktopAppManagerService from "../../../../services/desktopAppManagerService";
import EventBus from "../../../eventbus/EventBus";
import {
    ACTIVATE_DESKTOP_APP_VERSION_FAILED,
    ACTIVATE_DESKTOP_APP_VERSION_REQUESTED,
    ACTIVATE_DESKTOP_APP_VERSION_SUCCEED,
    ADD_NEW_DESKTOP_APP_VERSION_FAILED,
    ADD_NEW_DESKTOP_APP_VERSION_REQUESTED,
    ADD_NEW_DESKTOP_APP_VERSION_SUCCEED, DEACTIVATE_DESKTOP_APP_VERSION_FAILED,
    DEACTIVATE_DESKTOP_APP_VERSION_REQUESTED,
    DEACTIVATE_DESKTOP_APP_VERSION_SUCCEED,
    DELETE_DESKTOP_APP_VERSION_FAILED,
    DELETE_DESKTOP_APP_VERSION_REQUESTED,
    DELETE_DESKTOP_APP_VERSION_SUCCEED
} from "../../../eventbus/desktopAppEvents";

const desktopAppSlice = createSlice({
    name: 'desktopApp',
    initialState: {
        versions: [],
        versionsCount: [],
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateVersions: (state, action) => {
            state.versions = action.payload;
        },
        updateVersionsCount: (state, action) => {
            state.versionsCount = action.payload;
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    }
});

export const selectDesktopAppVersions = state => state.desktopApp.versions;
export const selectDesktopAppVersionsCount = state => state.desktopApp.versionsCount;
export const selectDesktopAppVersionsFilterOptions = state => state.desktopApp.filterOptions;

export const loadDesktopAppVersionsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};

    DesktopAppManagerService.getDesktopAppVersions(params)
        .then(response => dispatch(desktopAppSlice.actions.updateVersions(response.data)))
        .catch(() => dispatch(desktopAppSlice.actions.updateVersions([])))
}
export const loadDesktopAppVersionsCountAsync = params => dispatch => {
    const {limit, page, ...other} = params

    DesktopAppManagerService.getDesktopAppVersionsCount(other)
        .then(response => dispatch(desktopAppSlice.actions.updateVersionsCount(response.data)))
        .catch(() => dispatch(desktopAppSlice.actions.updateVersionsCount(0)))
}
export const addNewDesktopAppVersionAsync = (payload) => {
    EventBus.dispatch(ADD_NEW_DESKTOP_APP_VERSION_REQUESTED);

    return new Promise((resolve, reject) => {
        DesktopAppManagerService.addNewDesktopAppVersion(payload)
            .then(response => {
                EventBus.dispatch(ADD_NEW_DESKTOP_APP_VERSION_SUCCEED, response.data);
                resolve();
            })
            .catch(error => {
                EventBus.dispatch(ADD_NEW_DESKTOP_APP_VERSION_FAILED, error);
                reject(error);
            })
    })
}
export const deleteDesktopAppVersionAsync = id => {
    EventBus.dispatch(DELETE_DESKTOP_APP_VERSION_REQUESTED, id);

    return new Promise((resolve, reject) => {
        DesktopAppManagerService.deleteDesktopAppVersion(id)
            .then(() => {
                EventBus.dispatch(DELETE_DESKTOP_APP_VERSION_SUCCEED, id);
                resolve();
            })
            .catch(error => {
                EventBus.dispatch(DELETE_DESKTOP_APP_VERSION_FAILED, id);
                reject(error);
            })
    })
}
export const activateDesktopAppVersionAsync = id => {
    EventBus.dispatch(ACTIVATE_DESKTOP_APP_VERSION_REQUESTED, id);

    return new Promise((resolve, reject) => {
        DesktopAppManagerService.activateDesktopAppVersion(id)
            .then(() => {
                EventBus.dispatch(ACTIVATE_DESKTOP_APP_VERSION_SUCCEED, id);
                resolve();
            })
            .catch(error => {
                EventBus.dispatch(ACTIVATE_DESKTOP_APP_VERSION_FAILED, id);
                reject(error);
            })
    })
}
export const deactivateDesktopAppVersionAsync = id => {
    EventBus.dispatch(DEACTIVATE_DESKTOP_APP_VERSION_REQUESTED, id);

    return new Promise((resolve, reject) => {
        DesktopAppManagerService.deactivateDesktopAppVersion(id)
            .then(() => {
                EventBus.dispatch(DEACTIVATE_DESKTOP_APP_VERSION_SUCCEED, id);
                resolve();
            })
            .catch(error => {
                EventBus.dispatch(DEACTIVATE_DESKTOP_APP_VERSION_FAILED, id);
                reject(error);
            })
    })
}
export const changeDesktopAppVersionsFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(desktopAppSlice.actions.updateFilterOptions({...params}))
}

export default desktopAppSlice.reducer;