import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link, Redirect} from 'react-router-dom';
import RegistrationForm from "../../components/hippo/authentication/RegistrationForm";
import EventBus from "../../app/eventbus/EventBus";
import {REGISTRATION_SUCCESS} from "../../app/eventbus/authEvents";
import {toast} from "react-toastify";
import {loginAsync, selectIsAuthorized} from "../../app/store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const Registration = () => {
    const dispatch = useDispatch();
    const isAuthorized = useSelector(selectIsAuthorized);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        const onRegisterSuccessHandler = EventBus.on(REGISTRATION_SUCCESS, ({username, password}) => {
            toast.success(`You have success registered`);
            toast.success(`Welcome to Hippo`);

            dispatch(loginAsync(username, password));
        });

        return () => {
            EventBus.remove(REGISTRATION_SUCCESS, onRegisterSuccessHandler);
        };
    }, []);

    if (isAuthorized)
        return <Redirect to="/"/>

    return (
        <>
            <Row className="align-items-center mb-2">
                <Col>
                    <h5>{t(lang, 'authorization.register.title')}</h5>
                </Col>
                <Col xs={'auto'}>
                    <p className="fs--1 text-600 mb-0">
                        {t(lang, 'authorization.register.without.inn.title')} <Link className={'fw-bold'} to="./register/without-inn">{t(lang, 'authorization.register.transition')}</Link>
                    </p>
                </Col>
            </Row>
            <RegistrationForm/>

        </>
    );
};

export default Registration;
