
export const KZT = 398
export const RUB = 643
export const GBP = 826
export const USD = 840
export const UZS = 860
export const EUR = 978

export const GetCurrencyCodeText = (code) => {
    switch (code) {
        case KZT:
            return "KZT";
        case RUB:
            return "RUB";
        case GBP:
            return "GBP";
        case USD:
            return "USD"
        case UZS:
            return "UZS";
        case EUR:
            return "EUR";
        default:
            return "Не определено";
    }
};
