import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Divider from "../../../../common/Divider";
import Flex from "../../../../common/Flex";
import BulkDeleteCategories from "../BulkDeleteCategories";
import DataTableFilter from "./DataTableFilter";
import AddCategoryButton from "../AddCategoryButton";
import BulkEditCategories from "../BulkEditCategories";
import ActionProgressBar from "../../../../common/ActionProgressBar";

const DataTableHeader = ({selectedRowIds, page, load}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [customProgressBarCount, setCustomerProgressBarCount] = useState(null)
    const [bulkActionResult, setBulkActionResult] = useState([]);

    const selectedCategories = Object.keys(selectedRowIds).reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, [])

    // show progress modal
    const handleShowProgressBar = () => setShowProgressBar(true);
    // bulk action result
    const updateActionResult = (result) => setBulkActionResult(prev => [...prev, result]);
    const handleCloseProgressBar = () => {
        load();
        setBulkActionResult([]);
        setShowProgressBar(false);
        setCustomerProgressBarCount(null);
    };

    return (
        <Row>
            <ActionProgressBar show={showProgressBar}
                               result={bulkActionResult}
                               count={customProgressBarCount || selectedCategories.length}
                               onClose={handleCloseProgressBar}
            />

            <Col xs={12} className="mb-3">
                <h5 className="mb-2">{t(lang, "items.common.datatable.filter")}</h5>
                <DataTableFilter />
            </Col>
            <Divider />
            <Flex justifyContent="between">
                <h5>{t(lang, 'items.common.categories')}</h5>
                <div className={'d-flex gap-2'}>
                    <AddCategoryButton>{t(lang, "items.common.add")}</AddCategoryButton>
                    {selectedCategories.length > 0 &&
                        <BulkDeleteCategories categories={selectedCategories}/>
                    }
                    { selectedCategories.length > 0 &&
                        <BulkEditCategories
                            handleShowProgressBar={handleShowProgressBar}
                            updateActionResult={updateActionResult}
                            categories={selectedCategories}/>
                    }
                </div>
            </Flex>
        </Row>
    );
};

export default DataTableHeader;
