import React from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";

const RoamingInvoiceTechnicalSupervisor = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const {register,setValue, formState: {errors}} = useFormContext()

    const onChangeCompany = (company) => {
        if (company) {
            setValue(`technicalSupervisor`, {
                name: company.short_name || company.name,
                address: company.address,
                bankId: company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''),
                bankAccount: company.account || company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''),
            })
        } else {
            setValue(`technicalSupervisor`, {
                name: null,
                address: null,
                bankId: null,
                bankAccount: null,
            })
        }
    }

    return (
        <Card>
            <Card.Header className={'pb-0'}>
                <h5>{t(lang, 'roaming.invoice.technical_supervisor')}</h5>
            </Card.Header>
            <Card.Body className={'pt-1'} as={Row}>
                <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>
                        {t(lang, 'roaming.common.inn_or_pinfl')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                        name={`technicalSupervisor.inn`}
                        shouldUnregister={true}
                        rules={{
                            required: t(lang, 'items.common.validations.is_required'),
                        }}
                        render={({field, fieldState: {error}}) => (
                            <FindCompanySearchable
                                defaultInn={field.value}
                                onChange={(company) => {
                                    onChangeCompany(company);
                                    field.onChange(company ? (company.person_num || company.inn) : null)
                                }}
                                inputProps={{
                                    isSearchable: !field.value,
                                    menuPortalTarget: document.body,
                                    styles: {
                                        control: base => ({
                                            ...base,
                                            border: error?.message && '1px solid #e63757'
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            margin: '-1px'
                                        }),
                                        menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 9999
                                        })
                                    }
                            }}
                            />
                        )}
                    />
                    <Form.Control.Feedback className={'d-block'} type="invalid">{errors?.technicalSupervisor?.inn?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>
                        {t(lang, 'roaming.common.organization')}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control name={`technicalSupervisor.name`}
                                  disabled={true}
                                  placeholder={t(lang, 'roaming.common.organization.placeholder')}
                                  isInvalid={errors?.technicalSupervisor?.name}
                                  {...register(`technicalSupervisor.name`, {
                                      required: t(lang, 'items.common.validations.is_required'),
                                      shouldUnregister: true
                                  })}
                    />
                    <Form.Control.Feedback
                        type="invalid">{errors?.technicalSupervisor?.name?.message}</Form.Control.Feedback>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};

export default RoamingInvoiceTechnicalSupervisor;
