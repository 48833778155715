import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../../../common/IconButton";
import {Button, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../../../common/FalconCloseButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {deleteLocationAsync} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import {toast} from "react-toastify";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {LOCATION_DELETE_SUCCESS} from "../../../../../../app/eventbus/contractor/contractorLocationEvents";

const DeleteContractorLocationModal = ({contractor, location}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        try {
            const payload = {
                contractor_id: contractor.id,
                location_id: location.id,
            };

            setLoading(true);
            await deleteLocationAsync(payload);
            setLoading(false);
            setShow(false);

            EventBus.dispatch(LOCATION_DELETE_SUCCESS);
            toast.success(t(lang, 'common.toast.success'));
        } catch (error) {
            toast.error(t(lang, 'common.toast.error'))
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <>
            <IconButton icon={"trash-alt"}
                        size={"sm"}
                        iconAlign={"left"}
                        variant="falcon-default"
                        iconClassName="text-danger"
                        onClick={() => setShow(true)}
                        transform="shrink-3"
                        style={{width: '50px'}}
            />
            {show &&
                <Modal show={true}
                       centered={true}
                       size="xl"
                       onHide={() => setShow(false)}
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'crm.contractor.delete_location')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        {t(lang, 'common.common_delete_modal.description')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'}
                                onClick={() => setShow(false)}>{t(lang, 'main.common.back')}</Button>
                        <Button variant={'danger'}
                                disabled={loading}
                                onClick={onSubmit}
                        >
                            {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                            {t(lang, 'main.common.delete')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
};

export default DeleteContractorLocationModal;