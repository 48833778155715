import React, {useEffect} from "react";
import EmpowermentForm from "./EmpowermentForm";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {generateIdAsync, loadCompanyProductCatalogsAsync, loadDistrictsAsync, loadMeasurementsAsync,} from "../../../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectLang} from "../../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {
    draftEmpowermentAsync,
    sendEmpowermentAsync
} from "../../../../../app/store/reducers/roaming/roamingEmpowermentReducer";

const roamingDayJSDateFormat = 'YYYY-MM-DD'

const EmpowermentSendForm = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
    }, [activeOrganization])

    useEffect(() => {
        dispatch(loadDistrictsAsync(lang));
        dispatch(loadMeasurementsAsync(lang));
    },[])


    const methods = useForm({
        defaultValues: {
            empowermentInfo: {
                number: "",
                start: null,
                end: null
            },
            contractInfo: {
                number: "",
                date: null
            },
            personInfo: {
                inn: "",
                fullName: "",
            },
            customerInfo: {
                inn: "",
                name: "",
                pinflOrInn: "",
                address: "",
                cellPhone: "",
                housePhone: "",
                oked: "",
                director: "",
                accountant: "",
                districtId: "",
            },
            executorInfo: {
                inn: "",
                name: "",
                pinflOrInn: "",
                address: "",
                cellPhone: "",
                housePhone: "",
                oked: "",
                director: "",
                accountant: "",
                districtId: "",
            },
            items: [
                {
                    product: "",
                    measurementId: "",
                    quantity: "",
                    catalogClassCode: "",
                    catalogClassName: "",
                }
            ],
        }
    });

    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name
        const [EmpowermentId, AgentEmpowermentId, EmpowermentProductId] = await generateIdAsync(3)


        const EmpowermentForm = JSON.stringify({
            EmpowermentId,
            EmpowermentDoc: {
                EmpowermentNo: formData.empowermentInfo.number,
                EmpowermentDateOfIssue: formData.empowermentInfo.start && dayjs(formData.empowermentInfo.start).format(roamingDayJSDateFormat) || null,
                EmpowermentDateOfExpire: formData.empowermentInfo.end && dayjs(formData.empowermentInfo.end).format(roamingDayJSDateFormat) || null,
            },
            ContractDoc: {
                ContractNo: formData.contractInfo.number,
                ContractDate: formData.contractInfo.date && dayjs(formData.contractInfo.date).format(roamingDayJSDateFormat) || null
            },
            Agent: {
                AgentEmpowermentId,
                AgentTin: formData.personInfo.pinfl || formData.personInfo.inn,
                JobTitle: "",
                Fio: formData.personInfo.fullName || "",
                Passport: {
                    Number: "",
                    DateOfIssue: null,
                    IssuedBy: ""
                }
            },
            SellerTin: formData.executorInfo.pinflOrInn,
            BuyerTin: formData.customerInfo.pinflOrInn,
            Seller: {
                Name: formData.executorInfo.name || "",
                Address: formData.executorInfo.address || "",
                Mobile: formData.executorInfo.cellPhone || "",
                WorkPhone: formData.executorInfo.housePhone || "",
                Oked: formData.executorInfo.oked || "",
                DistrictId: formData.executorInfo.districtId,
                Director: formData.executorInfo.director || "",
                Accountant: formData.executorInfo.accountant || "",
            },
            Buyer: {
                Name: formData.customerInfo.name || "",
                Address: formData.customerInfo.address || "",
                Mobile: formData.customerInfo.cellPhone || "",
                WorkPhone: formData.customerInfo.housePhone || "",
                Oked: formData.customerInfo.oked || "",
                DistrictId: formData.customerInfo.districtId,
                Director: formData.customerInfo.director || "",
                Accountant: formData.customerInfo.accountant || "",
            },
            ProductList: {
                EmpowermentProductId,
                Tin: formData.customerInfo.pinflOrInn,
                Products: formData.items.map((item, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: item.product,
                        MeasureId: item.measurementId,
                        Count: +item.quantity,
                        CatalogCode: item.catalogClassCode,
                        CatalogName: item.catalogClassName
                    }
                })
            },
        })


        if(buttonType === "send") {
            if(activeCertificate?.keyId) {
                await sendEmpowermentAsync(activeCertificate, lang, EmpowermentForm)
            }
        } else if (buttonType === "draft") {
            await draftEmpowermentAsync(EmpowermentForm)
        }

    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} id="EmpowermentForm">
                <EmpowermentForm />
            </Form>
        </FormProvider>
    )
};

export default EmpowermentSendForm;
