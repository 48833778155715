import React, {useEffect} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectWaybillDistricts} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const WaybillDistrictSelector = ({districtCode, onChange, districts, ...props}) => {
    // const districts = useSelector(selectWaybillDistricts);
    const lang = useSelector(selectLang);

    const onSelectRegion = selectedDistrict => {
        onChange(selectedDistrict);
    };

    useEffect(() => {
        if (districts.length > 0) {
            onChange(districts.find(d => d.code === districtCode))
        }
    }, [districtCode, districts])

    return (
        <ReactSelect
            options={districts}
            hideSelectedOptions={true}
            classNamePrefix={'react-select'}
            getOptionValue={option => option.code}
            getOptionLabel={option => option.name}
            onChange={newValue => onSelectRegion(newValue)}
            value={districts.find(d => d.code === districtCode) || null}
            placeholder={Translate(lang, "roaming.common.select.placeholder")}
            {...props}
        />
    );
};

export default WaybillDistrictSelector;
