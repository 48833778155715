import React, {cloneElement, Fragment, useState} from 'react';
import IconButton from "../../../../common/IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import EmployeeSelect from "../../../common/EmployeeSelect";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import ContractorSelect from "../../../common/ContractorSelect";
import {bindEmployeeToContractorAsync} from "../../../../../app/store/reducers/contractor/contractorReducer";
import {toast} from "react-toastify";

const BindContractorToEmployeeModal = ({children, ...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = React.useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {handleSubmit, reset, control, formState: {errors}} = useForm({
        defaultValues: {
            contractorId: null,
            employeeId: null,
        },
    });

    const handleShow = () => setShow(true);
    const handleClose = () => {
        reset();
        setShow(false);
    };

    const onSubmit = (formData) => {
        const employeesIds = [formData.employeeId];
        bindEmployeeToContractorAsync(formData.contractorId, {employeesIds: employeesIds})
            .then(() => {
                handleClose();
                toast.success(t(lang, 'common.toast.success'));
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')));
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton variant={'falcon-primary'} size={'sm'} icon={'plus'} onClick={handleShow} {...props}>
                    {t(lang, 'Привязать сотрудника к контрагенту')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose} size={'lg'}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'Привязать контагента к сотруднику')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'Контрагент')}</Form.Label>
                            <Controller
                                control={control}
                                name={'contractorId'}
                                rules={{required: t(lang, "crm.common.forms.validations.is_required")}}
                                render={({field, fieldState}) => (
                                    <ContractorSelect
                                        isClearable={true}
                                        defaultValue={field.value}
                                        classNamePrefix={'react-select'}
                                        className={classNames({'is-invalid border border-danger rounded-2': fieldState.error})}
                                        onChange={selectedContractor => field.onChange(selectedContractor ? selectedContractor.id : null)}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                                {errors?.contractorId?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{t(lang, 'Сотрудник')}</Form.Label>
                            <Controller
                                control={control}
                                name={'employeeId'}
                                rules={{required: t(lang, "crm.common.forms.validations.is_required")}}
                                render={({field, fieldState}) => (
                                    <EmployeeSelect
                                        isClearable={true}
                                        defaultValue={field.value}
                                        classNamePrefix={'react-select'}
                                        className={classNames({'is-invalid border border-danger rounded-2': fieldState.error})}
                                        onChange={selectedEmployee => field.onChange(selectedEmployee ? selectedEmployee.id : null)}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                                {errors?.employeeId?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'Закрыть')}</Button>
                        <Button variant={'primary'} type={'submit'} disabled={isLoading}>
                            {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                            {t(lang, 'Привязать')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default BindContractorToEmployeeModal;