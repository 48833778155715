import React, {useEffect} from 'react';
import ChargeDatatable from "../../../../components/hippo/user/billing/component/ChargeDatatable";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterChargesAdminAsync,
    selectChargesAdmin,
    selectChargesAdminCount,
    selectChargesAdminFilter, loadChargesAdminAsync, loadChargesAdminCount
} from "../../../../app/store/reducers/billing/billingReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";

const AdminCharge = ({id}) => {

    const dispatch = useDispatch()

    const charges = useSelector(selectChargesAdmin)
    const count = useSelector(selectChargesAdminCount)
    const filters = useSelector(selectChargesAdminFilter)
    const activeOrganization = useSelector(selectActiveOrganization)


    useEffect(() => {
        dispatch(loadChargesAdminAsync({...filters, organization_id: id}))
        dispatch(loadChargesAdminCount({...filters, organization_id: id}))
    }, [filters, activeOrganization])


    return <ChargeDatatable isAdmin={true} charges={charges} filters={filters} count={count}
                            changeFilterChargesAsync={changeFilterChargesAdminAsync}/>
};

export default AdminCharge;
