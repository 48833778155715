import {Button, Form, Modal, Row, Spinner} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import React, {useState} from "react";
import PermissionsDatatable from "../PermissionsDatatable";
import RoleForm from "./RoleForm";
import {addRoleAsync} from "../../../../../app/store/reducers/role/roleReducer";
import {toast} from "react-toastify";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionSystemRoleCreate} from "../../../../../enum/Permission/RolePermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";

const AddRole = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const t = Translate;
    const [isShow, setIsShow] = useState(false)
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionSystemRoleCreate))) setIsShow(true)
        else dispatch(toastPermission())
    };
    const handleClose = () => {
        methods.reset();
        setIsShow(false);
    };
    const methods = useForm({
        defaultValues: {
            Name: '',
            Permissions: {
                edi: [],
                roaming: [],
                another: []
            },
        },
    });
    const onSubmit = formData => {
        if (formData.Permissions.length === 0) {
            toast.error(t(lang, "hippo.user.settings.add_role.permission.required.message"))
            return
        }
        setLoading(true);
        let permissions = []
        for (const [key, value] of Object.entries(formData.Permissions)) {
            for (let i = 0; i < formData.Permissions[key].length; i++) {
                permissions.push(formData.Permissions[key][i].value)
            }
        }

        dispatch(addRoleAsync({
            name: formData.Name,
            permissions
        })).then((response) => {
        }).catch(error => {
            console.log(error)
            toast.error(t(lang, "hippo.user.settings.add_role.error.message"));
        }).finally(() => {
            setLoading(false);
            handleClose();
        });
    }
    return (
        <Row className="flex-between-center mb-3">
            <div className="d-flex align-items-center justify-content-between">
                <h5>{t(lang, "hippo.user.settings.roles")}</h5>
                <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    className='me-2'
                    onClick={() => handleShow()}>
                    <span
                        className="d-none d-sm-inline-block ms-1">{t(lang, "edi.branch.datatable.header.row.navigation.create")}</span>
                </IconButton>
                <Modal show={isShow} size="xl">

                    <Modal.Header>
                        <Modal.Title>{t(lang, "hippo.user.role.form.title")}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmit)} id={'add-role'}>
                                <RoleForm/>
                                <PermissionsDatatable />
                            </Form>
                        </FormProvider>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t(lang, "edi.common.cancel_button")}
                        </Button>
                        <Button variant="primary" type="submit" disabled={loading} form={'add-role'}>
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />}
                            {t(lang, "edi.common.add_button")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Row>
    )
}
export default AddRole;
