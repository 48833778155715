import React from "react";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ActStatuses} from "../View/ActStatus";
import SelectActStatus from "./SelectActStatus";


const ActDataTableFilter = () => {
    const {control} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;



    return (
        <>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.order.datatable.header.filter.title")}</h4>
            </Row>
            <Row className="mb-3 g-3">
                <Form.Group as={Col} md={6} lg={4} controlId="status">
                    <Form.Label>{t(lang, "edi.order.datatable.header.filter.status")}</Form.Label>
                    <Controller
                        control={control}
                        name="status"
                        render={({field}) => (
                            <SelectActStatus
                                statuses={ActStatuses}
                                isClearable
                                placeholder={t(lang, "edi.order.datatable.header.filter.status:placeholder")}
                                onChange={statusId => field.onChange(statusId || null)}
                            />
                        )}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3 g-3 justify-content-end">
                <Form.Group as={Col} sm="auto" controlId="showChange">
                    <Button variant="primary" type='submit'>{t(lang, "edi.order.datatable.header.filter.show")}</Button>
                </Form.Group>
            </Row>
        </>
    )
}

export default ActDataTableFilter;