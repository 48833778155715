import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorInvoiceSlice = createSlice({
    name: 'contractorInvoice',
    initialState: {
        invoices: [],
        countInvoices: 0,
        filterOptions: {page: 1, limit: 10, status: 30},
    },
    reducers: {
        updateInvoices: (state, action) => {
            state.invoices = action.payload
        },
        updateCountInvoices: (state, action) => {
            state.countInvoices = action.payload
        },
        updateFilterInvoice: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        clearFilterInvoice: (state,action)=> {
            if(action.payload){
                state.filterOptions = action.payload
            }else {
                state.filterOptions =  {page: 1, limit: 10, status: 30}
            }
        }
    },
})
export const loadContractorInvoicesAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorInvoices(params)
        .then(response => {
            dispatch(contractorInvoiceSlice.actions.updateInvoices(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const loadContractorInvoicesCountAsync = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    contractorService.getContractorInvoiceCount(other)
        .then(response => {
            dispatch(contractorInvoiceSlice.actions.updateCountInvoices(response.data))
        })
        .catch(error => console.log(error))
}
export const changeFilterContractorInvoicesAsync = (params) => (dispatch) => {
    return dispatch(contractorInvoiceSlice.actions.updateFilterInvoice(params))
}
export const selectContractorInvoices = (state) => state.contractorInvoice.invoices;
export const selectContractorCountInvoices = (state) => state.contractorInvoice.countInvoices;
export const selectContractorInvoiceFilterOptions = (state) => state.contractorInvoice.filterOptions;

export const {clearFilterInvoice} = contractorInvoiceSlice.actions

export default contractorInvoiceSlice.reducer