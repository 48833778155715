import React from 'react';
import AsyncSelect from "react-select/async";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {loadCatalogByCode} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";



const SelectExactCatalog = ({onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const loadOptions = (inputValue) => {
        const catalogs = [];

        let code = inputValue.replace(/\D/g, '');
        if (code.length === 17) {
            return new Promise(async (resolve) => {
                try {
                    const catalog = await loadCatalogByCode(inputValue);
                    if (catalog)
                        catalogs.push(catalog);
                    resolve(catalogs);
                } catch (error) {
                    console.log(error);
                    resolve(catalogs);
                }
            })
        }

        return catalogs;
    };
    const formatOptionLabel = (option) => {
        return (
            <div className="fs--1">
                <span className="text-black fw-bold me-1">{option.class_code}</span>-
                <span className="ms-1 text-facebook">{option.name}</span>
            </div>
        )
    };

    return (
        <AsyncSelect cacheOptions
                     loadOptions={loadOptions}
                     formatOptionLabel={formatOptionLabel}
                     placeholder={t(lang, 'items.common.select')}
                     classNamePrefix="select"
                     value={null}
                     onChange={onChange}
        />
    );
};

export default SelectExactCatalog;