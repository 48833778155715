import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import PropTypes from "prop-types";
import {contractSyncAsync} from "../../../../../app/store/reducers/roaming/roamingContractReducer";

const RoamingContractSync = ({id, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false);
    const syncContract = () => {
        setLoading(true);
        contractSyncAsync(id)
            .then()
            .catch()
            .finally(() => setLoading(false))
    };

    return (
        <IconButton
            icon={'fa-refresh'}
            variant="falcon-primary"
            onClick={syncContract}
            size="sm"
            iconClassName="me-1"
            disabled={loading}
            {...props}>
            {t(lang, "roaming.contract.sync")}
        </IconButton>
    )
};

RoamingContractSync.propTypes = {
    id: PropTypes.string
}

RoamingContractSync.defaulProps = {
    id: ""
}

export default RoamingContractSync;