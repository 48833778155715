import React from 'react';
import classNames from "classnames";
import {useSelector} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const EmployeeForm = () => {
    const {register, formState: {errors}} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Row>
            <Form.Group as={Col} xs={12}>
                <Form.Label>{t(lang, 'employee.employee_form.field.name.title')}</Form.Label>
                <Form.Control
                    placeholder={t(lang, 'employee.employee_form.field.name.placeholder')}
                    className={classNames({'is-invalid': errors?.name})}
                    {...register('name', {required: t(lang, 'main.validation.is_required')})}
                />
                <Form.Control.Feedback type={'invalid'}>
                    {errors?.name?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </Row>
    );
};

export default EmployeeForm;