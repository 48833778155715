import classNames from "classnames";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import EventBus from "../../../../../app/eventbus/EventBus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import React, {cloneElement, Fragment, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Modal, Row, Table} from "react-bootstrap";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {UPDATE_COMPANY_BINDINGS_SUCCEED} from "../../../../../app/eventbus/roamingReferenceEvents";
import SelectCertificateWrapperComponent from "../../../roaming/components/SelectCertificateWrapperComponent";
import {loadCompanyBindingPermissionsAsync, loadCompanyBindingsAsync, updateCompanyBindingsAsync} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const CompanyBindings = () => {
    const [companyBindings, setCompanyBindings] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        loadCompanyBindings();
        loadCompanyBindingPermissions();

        const onUpdateCompanyBindingsSucceed = EventBus.on(UPDATE_COMPANY_BINDINGS_SUCCEED, () => {
            loadCompanyBindings();
        });

        return () => EventBus.remove(UPDATE_COMPANY_BINDINGS_SUCCEED, onUpdateCompanyBindingsSucceed);
    }, [])

    useEffect(() => {
        loadCompanyBindings();
    }, [activeOrganization])

    const loadCompanyBindings = () => {
        loadCompanyBindingsAsync()
            .then(loadedBindings => setCompanyBindings(loadedBindings?.tax_payers))
            .catch(() => setCompanyBindings([]))
    };

    const loadCompanyBindingPermissions = () => {
        loadCompanyBindingPermissionsAsync()
            .then(loadedPermissions => setPermissions(loadedPermissions))
            .catch(() => setPermissions([]))
    };

    return (
        <Card className={'shadow-none border'}>
            <Card.Header className={'bg-light d-flex flex-between-center'}>
                <Card.Title>{t(lang, 'main.settings.edi.company_bindings.datatable.title')}</Card.Title>

                <UpdateCompanyBindingPermissions companyBinding={null} permissionOptions={permissions}>
                    <IconButton icon={'plus'} variant={'primary'} size={'sm'}>{t(lang, 'main.common.button.add')}</IconButton>
                </UpdateCompanyBindingPermissions>
            </Card.Header>
            <Card.Body>
                <Table responsive={true} size={'sm'} striped={true}>
                    <thead>
                    <tr>
                        <th className={'text-black'}>#</th>
                        <th className={'text-black'}>{t(lang, 'main.settings.edi.company_bindings.datatable.column.pinfl')}</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {companyBindings?.map((taxpayer, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{taxpayer.tin}</td>
                            <td>
                                <UpdateCompanyBindingPermissions companyBinding={taxpayer} permissionOptions={permissions}>
                                    <IconButton icon={'pencil'} variant={'warning'} size={'sm'}/>
                                </UpdateCompanyBindingPermissions>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

const UpdateCompanyBindingPermissions = ({companyBinding, permissionOptions, children, ...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {setValue, control, register, handleSubmit, reset, formState: {errors}} = useForm({
        defaultValues: {
            personName: null,
            personInnOrPinfl: null,
            permissions: [],
        },
    });
    const {fields: permissions} = useFieldArray({name: 'permissions', control: control});

    const handleShow = () => {
        initDefaultValues();
        setShow(true);
    };

    const handleClose = () => {
        reset();
        setShow(false);
    };

    const initDefaultValues = () => {
        const personPermissions = [];
        for (let i = 0; i < permissionOptions.length; i++) {
            const permission = permissionOptions[i];

            const found = companyBinding?.roles?.find(r => Number(r) === Number(permission.code));
            personPermissions.push({
                value: !!found,
                code: permission.code,
                label: permission.name,
            });
        }

        setValue('personInnOrPinfl', companyBinding ? companyBinding.tin : null);
        setValue('permissions', personPermissions);
    };

    const onCompanyChanged = company => {
        setValue('personName', company ? company.name : null);
    };

    const onSubmit = formData => {
        const newPermissions = formData.permissions.filter(p => !!p.value).map(p => p.code.toString());
        const hashCode = JSON.stringify({
            Tin: activeOrganization.inn,
            FizTin: formData.personInnOrPinfl,
            Fio: formData.personName,
            Roles: newPermissions,
        });

        setIsLoading(true);
        updateCompanyBindingsAsync({hashCode: hashCode, certificate: currentCertificate})
            .then(() => {
                toast.success(t(lang, 'common.toast.success'));
                handleClose();
            })
            .catch(error => {
                console.log(error);
                toast.error(t(lang, 'common.toast.error'));
            })
            .finally(() => setIsLoading(false))
    };

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton variant={'falcon-warning'} size={'sm'} icon={'plus'} onClick={handleShow} {...props}>
                    {t(lang, 'main.common.button.edit')}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose} size={'xl'}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'main.settings.edi.company_bindings.update_modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as={Row}>
                        <Form.Group as={Col} xs={12} className={'mb-4'}>
                            <Form.Label>ИНН | ПИНФЛ</Form.Label>
                            <Controller
                                control={control}
                                name={'personInnOrPinfl'}
                                rules={{required: t(lang, 'main.validation.is_required')}}
                                render={({field}) => (
                                    <FindCompanySearchable
                                        defaultInn={field.value}
                                        onChange={(company) => {
                                            onCompanyChanged(company);
                                            field.onChange(company ? (company.person_num || company.inn) : null)
                                        }}
                                        inputGroupProps={{
                                            className: classNames('w-100', {'is-invalid': errors?.personInnOrPinfl}),
                                            wrapperClassName: classNames({'is-invalid': errors?.personInnOrPinfl}),
                                        }}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type={'invalid'}>
                                {errors?.personInnOrPinfl?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {permissions.map((permission, index) => (
                            <Form.Group as={Col} xs={4} key={permission.id}>
                                <Form.Check type={'checkbox'} className={'form-check'}>
                                    <Form.Check.Label className={'form-label text-dark cursor-pointer'}>
                                        <Form.Check.Input id={permission.id} {...register(`permissions.${index}.value`)}/>
                                        {permission?.label}
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} type={'button'} onClick={handleClose}>{t(lang, 'main.common.button.back')}</Button>

                        <SelectCertificateWrapperComponent
                            size={'md'}
                            openDialogButtonText={t(lang, 'main.common.button.save')}
                            submitButton={
                                <Button variant={'primary'} type={'submit'} disabled={isLoading}>
                                    {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                                    {t(lang, 'main.common.button.save')}
                                </Button>
                            }
                        />
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default CompanyBindings;