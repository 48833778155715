import React from 'react';
import ForgotPasswordForm from "../../components/hippo/authentication/forgot-password/ForgotPasswordForm";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const ForgetPassword = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <div className="text-center">
            <h5 className="mb-0">{t(lang, 'authentication.forgot-password.title')}</h5>
            <small>{t(lang, 'authentication.forgot-password.subtitle')}</small>
            <div className="text-start">
                <ForgotPasswordForm className="mt-4"/>
            </div>
        </div>
    );
};

export default ForgetPassword;
