import React, {Fragment} from 'react';
import {Card, Col, Form, FormControl, Row, Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import DetailsFormV2 from "./subformV2/DetailsFormV2";
import MembersInfoFormV2 from "./subformV2/MembersInfoFormV2";
import AdditionalInformationV2 from "./subformV2/AdditionalInformationV2";
import WaybillItemsV2Form from "./subformV2/WaybillItemsV2Form";
import IconButton from "../../../../common/IconButton";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import DetailsFormOne from "./subformV2/DetailsFormOne";
import DeliveryForm from "./subformV2/DeliveryForm";
import {useWatch} from "react-hook-form";
import {Railway, Roadway} from "./SendWaybillFormV2";
import AdditionalInformationRailwayV2 from "./subformV2/AdditionalInformationRailwayV2";

const WaybillFormV2 = ({isEditing, isSaving, isSending, content, shipmentId}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const transportType = useWatch({name: 'transport_type' , exact: true})

    return (
        <Fragment>
            <DetailsFormOne />
            <MembersInfoFormV2 isEditing={isEditing} shipmentId={shipmentId} />
            <DetailsFormV2 />
            {+transportType === Railway ?
                <AdditionalInformationRailwayV2 /> :
                <AdditionalInformationV2 isEditing={isEditing} content={content}/>
            }
            <WaybillItemsV2Form isEditing={isEditing} content={content} />
            <DeliveryForm />
            <Card className="mt-3">
                <Card.Body className="text-end">
                    <IconButton
                        disabled={isSaving}
                        icon={faBookmark}
                        name="save"
                        variant="falcon-warning"
                        size="lg"
                        className="w-100 w-md-auto me-md-3"
                        type={'submit'}
                    >
                        {isSaving && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                        {t(lang, 'roaming.common.save')}
                    </IconButton>

                    <SelectCertificateWrapperComponent
                        size="lg" className="w-100 mt-2 mt-md-0 w-md-auto"
                        name="send"
                        openDialogButtonText={t(lang, 'roaming.common.send')}
                        submitButton={
                        <IconButton
                            disabled={isSending}
                            icon={faSignature}
                            variant="falcon-primary"
                            size="lg"
                            className="w-100 mt-2 mt-md-0 w-md-auto"
                            id="sendButton"
                            name="send"
                            type="submit"
                        >
                            {isSending && <Spinner className="me-2" animation="border" role="status" size="sm"/>}
                            {t(lang, 'roaming.invoice.send_button')}
                        </IconButton>
                    }/>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default WaybillFormV2;
