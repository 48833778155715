import React, {useState} from 'react';
import printJS from "print-js";
import IconButton from "../../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {Spinner} from "react-bootstrap";
import {printShipmentAsync} from "../../../app/store/reducers/shipment/shipmentReducer";
import {TYPE_CREATE_BULK_APPROVE} from "../../../app/eventbus/shipmentEvents";
import EventBus from "../../../app/eventbus/EventBus";
import { PDFDocument } from 'pdf-lib';

const ShipmentPrint = ({id, shipments, selectable, ...props}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onPrint = async () => {
        setLoading(true);

        if (shipments?.length > 0) {
            await bulkPrint();
        } else {
            await printSingleShipment(id);
        }

        setLoading(false);
    };

    const printSingleShipment = async (shipmentId) => {
        try {
            const response = await printShipmentAsync(shipmentId);
            const arrayBuffer = await response.arrayBuffer();
            const pdfDoc = await PDFDocument.load(arrayBuffer);

            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const blobUrl = window.URL.createObjectURL(blob);

            printJS({
                printable: blobUrl,
                type: 'pdf',
                showModal: true,
                onPrintDialogClose: () => URL.revokeObjectURL(blobUrl)
            });
        } catch (error) {
            console.error("Ошибка при печати отправления:", error);
        }
    };

    const bulkPrint = async () => {
        EventBus.dispatch(TYPE_CREATE_BULK_APPROVE);

        let pdfDocs = [];

        const onPrintShipment = async (shipmentId) => {
            try {
                const response = await printShipmentAsync(shipmentId);
                const arrayBuffer = await response.arrayBuffer();
                const pdfDoc = await PDFDocument.load(arrayBuffer);
                pdfDocs.push(pdfDoc);
            } catch (error) {
                console.error("Ошибка при подготовке отправления к печати:", error);
            }
        };

        for (let i = 0; i < shipments.length; i++) {
            await onPrintShipment(shipments[i].id);
        }

        await combineAndPrint(pdfDocs);
    };

    const combineAndPrint = async (pdfDocs) => {
        if (pdfDocs.length === 0) return;

        const mergedPdf = await PDFDocument.create();
        for (const pdf of pdfDocs) {
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach(page => mergedPdf.addPage(page));
        }

        const mergedPdfFile = await mergedPdf.save();
        const blob = new Blob([mergedPdfFile], { type: "application/pdf" });
        const blobUrl = window.URL.createObjectURL(blob);

        printJS({
            printable: blobUrl,
            type: 'pdf',
            showModal: true,
        });
    };

    return (
        <>
            <IconButton{...props} icon="print" transform="shrink-3" onClick={onPrint}>
                <span className="d-none d-sm-inline-block">{t(lang, 'edi.common.print')}</span>
                {loading && (
                    <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
                )}
            </IconButton>
        </>
    );
};

export default ShipmentPrint;
