import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Tab} from "react-bootstrap";
import FalconCardHeader from "../../../common/FalconCardHeader";
import {
    exportCompanyCatalogsToExcel,
    getBankNameAsync,
    getOrganizationInfoAsync,
    invalidateCompanyClassCodesAsync,
    loadCompanyProductCatalogsAsync
} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import OrganizationChooser from "../../organization/OrganizationChooser";
import IconButton from "../../../common/IconButton";
import {toast} from "react-toastify";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import Flex from "../../../common/Flex";
import {excelFileUploader} from "../../../../helpers/excel-uploader";
import RegisterOrganizationInn from "./RegisterOrganizationInn";
import SyncCatalogCodeButton from "../../roaming/common/SyncCatalogCodeButton";


const ProfileSettings = () => {
    const [organization, setOrganization] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(async () => {
        try {
            let organizationInfo = await getOrganizationInfoAsync(activeOrganization.inn)
            let bankInfo = await getBankNameAsync({mfo: organizationInfo.mfo, lang})

            setOrganization({
                ...organizationInfo,
                ...bankInfo
            })
        } catch (e) {
            console.log(e);
        }
    }, [activeOrganization]);


    const exportCompanyCatalogs = () => {
        exportCompanyCatalogsToExcel({lang: lang})
            .then(loadedContent => {
                excelFileUploader({
                    content: loadedContent,
                    fileName: t(lang, 'items.common.catalog')
                })
            })
            .catch((error) => {})
    }

    return (
        <>
            <FalconCardHeader
                title={t(lang, 'hippo.user.settings.profile.title')}
                endEl={
                <Flex direction="column">
                    <SyncCatalogCodeButton />
                    <IconButton icon={faFileExport} size="sm" variant="success" onClick={exportCompanyCatalogs}>{t(lang, 'hippo.user.settings.organization_catalogs.export_to_excel')}</IconButton>
                </Flex>
                }
            />
            <Tab.Container id="left-tabs-example">
                <Row className="d-flex flex-lg-row flex-column align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
                    <Col lg={4} className='d-flex flex-lg-column col-10 m-auto m-lg-0'>
                        <OrganizationChooser/>
                    </Col>
                    <Col lg={8} className='d-flex flex-lg-column col-10 m-auto m-lg-0'>
                        <Tab.Content>
                            <p className="d-flex flex-md-row flex-column align-items-start align-items-md-center fs--1 fs-lg-0">
                                <b className="me-1">{t(lang, 'hippo.user.settings.profile.company')}: </b>{organization?.name || activeOrganization.name}</p>
                            <p className="d-flex flex-md-row flex-column align-items-start align-items-md-center fs--1 fs-lg-0">
                                <b className="me-1">{t(lang, 'hippo.user.settings.profile.inn')}: </b>{activeOrganization?.inn || <RegisterOrganizationInn />}</p>
                            <p className="d-flex flex-md-row flex-column align-items-start align-items-md-center fs--1 fs-lg-0">
                                <b className="me-1">{t(lang, 'hippo.user.settings.profile.name')}: </b>{organization?.director}</p>
                            <p className="d-flex flex-md-row flex-column align-items-start align-items-md-center fs--1 fs-lg-0">
                                <b className="me-1">{t(lang, 'hippo.user.settings.profile.address')}: </b>{organization?.address}</p>
                            <p className="d-flex flex-md-row flex-column align-items-start align-items-md-center fs--1 fs-lg-0">
                                <b className="me-1">{t(lang, 'hippo.user.settings.profile.bank_code')}: </b>{organization?.account}</p>
                            <p className="d-flex flex-md-row flex-column align-items-start align-items-md-center fs--1 fs-lg-0">
                                <b className="me-1">{t(lang, 'hippo.user.settings.profile.bank_name')}: </b>{organization?.name}</p>
                            <p className="d-flex flex-md-row flex-column align-items-start align-items-md-center fs--1 fs-lg-0">
                                <b className="me-1">{t(lang, 'hippo.user.settings.profile.bank_mfo')}: </b>{organization?.bankId}</p>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>


    );

};

export default ProfileSettings;

