import IconButton from "../../../../common/IconButton";
import {useState} from "react";
import {CloseButton, Form, Modal} from "react-bootstrap";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import ContractorAgentForm from "./ContractorAgentForm";
import {registerContractorAgentAsync} from "../../../../../app/store/reducers/contractor/contractorAgentReducer";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";


const ContractorAgentAdd = () => {

    const lang = useSelector(selectLang)
    const t = Translate

    const [show, setShow] = useState(false)
    const form = useForm({
        defaultValues: {
            name: "",
            phones: []
        }
    })


    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
        form.reset()
    }

    const {fields: fieldsPhone, append: appendPhone, remove: removePhone} = useFieldArray({
        control: form.control,
        name: "phones"
    });

    const onSubmit = async (formData) => {
        const agentAdd = {
            name: formData?.name,
            contacts: formData.phones.map((phone) => {
                return(
                    phone.value
                )
            }),
        }
        await registerContractorAgentAsync(agentAdd)
            .then(() => {
                handlerClose()
            })
            .catch((error) => {console.log(error)})
    }


    return(
        <>
            <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => handlerShow()}
            />

            <Modal show={show} onHide={handlerClose}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "crm.contractor.agent.add.agent")}</Modal.Title>
                    <CloseButton onClick={() => handlerClose()} />
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...form}>
                        <Form onSubmit={form.handleSubmit(onSubmit)}>
                            <ContractorAgentForm
                                fieldsPhone={fieldsPhone}
                                appendPhone={appendPhone}
                                removePhone={removePhone}
                            />
                        </Form>
                    </FormProvider>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ContractorAgentAdd;