import React, {Fragment, useEffect} from 'react';
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {Card, Col, Row} from "react-bootstrap";
import WaybillV2DataTableHeader from "./WaybillV2DataTableHeader";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {
    getWaybillStatusText,
    getWaybillStatusVariant, RESPONSIBLE_PERSON,
    VIEW_DRAFTS,
    VIEW_INBOX,
    VIEW_OUTBOX
} from "../../../../../enum/roaming/WaybillStatus";
import Flex from "../../../../common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faUser, faUserCheck, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";

import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {
    changeWaybillsV2FilterOptions,
    selectWaybillsV2,
    selectWaybillsV2Count,
    selectWaybillsV2FilterOptions,
    selectWaybillsV2Loading,
    setShowModal,
    setId,
    viewPage,
    loadWaybillV2ExecutorAsync,
    getWaybillV2ExecutorCount,
    loadWaybillV2CustomerAsync,
    getWaybillV2CustomerCount,
    loadWaybillV2DraftsAsync,
    getWaybillV2DraftsCount,
    loadWaybillV2ResponsiblePersonAsync, getWaybillV2ResponsiblePersonCount
} from "../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import dayjs from "dayjs";
import SoftBadge from "../../../../common/SoftBadge";
import numeral from "numeral";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {changeFilterOptionsAsync} from "../../../../../app/store/reducers/roaming/roamingVerificationActReducer";
import ViewModalPage from "../../components/ViewModalPage";
import ViewWaybill from "../../../../../pages/main/roaming/waybill/ViewWaybill";
import ViewDraftWaybill from "../../../../../pages/main/roaming/waybill/ViewDraftWaybill";
import ViewWaybillV2 from "../../../../../pages/main/roaming/waybill/ViewWaybillV2";
import ViewDraftWaybillV2 from "../../../../../pages/main/roaming/waybill/ViewDraftWaybillV2";
import {DRAFT} from "../../../../../enum/RoamingInvoiceWrapper";
import RoamingInvoiceDraftDelete from "../../invoice/actions/RoamingInvoiceDraftDelete";
import WaybillV2DraftDelete from "../forms/subformV2/WaybillV2DraftDelete";
import {getWaybillV2Status} from "../../../../../enum/roaming/WaybillV2Status";
import EventBus from "../../../../../app/eventbus/EventBus";
import {DELETE_WAYBILL_V2_SUCCEED} from "../../../../../app/eventbus/roaming/roamingWaybillV2Events";
import {toast} from "react-toastify";
import {useParams} from "react-router";

const WaybillV2DataTable = ({viewer, predefinedFilters, changeFilterOptionsAsync, onPageChange,onLimitChange, loadWaybills, excelRegistry, filter, waybills}) => {

    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const count = useSelector(selectWaybillsV2Count);
    const numberFormat = useSelector(selectNumberFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const {id, draftId, showModal} = useSelector(viewPage)

    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;

    useEffect(() => {
        loadWaybills()

        const onWaybillDeletedSuccess = EventBus.on(DELETE_WAYBILL_V2_SUCCEED, () => {
            loadWaybills()
            toast.success(t(lang, "edi.common.toast.success"))
        })

        return () => {
            EventBus.remove(DELETE_WAYBILL_V2_SUCCEED, onWaybillDeletedSuccess)
        }

    }, [filter, activeOrganization, viewer]);


    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open

        if (viewer === VIEW_DRAFTS) {
            if (ctrlKey) {
                history('/#/roaming/waybill-v2/draft/'+id, '_blank')
            } else {
                dispatch(setId({draftId: id}))
                dispatch(setShowModal(true))
            }
        } else {
            if (ctrlKey) {
                history('/#/roaming/waybill-v2/'+id, '_blank')
            } else {
                dispatch(setId({id}))
                dispatch(setShowModal(true))
            }
        }
    }

    const onHideModalPage = () => {
        dispatch(setShowModal(false))
    }

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, 'roaming.waybill.datatable.row.waybill_number'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                const routeTo = viewer === VIEW_DRAFTS ? `../waybill/draft/${original.id}` : `../waybill/${original.id}`;

                return (
                    <>
                        <strong
                            onClick={(e)=>openViewModalPage(e,original.id)}
                            className={'cursor-pointer text-primary'}>
                            {original.info.number}
                        </strong>
                    </>
                );
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, 'roaming.waybill.datatable.row.waybill_date'),
            headerProps: {className: 'pe-2'},
            Cell: rowData => {
                const {original} = rowData.row
                return dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'contract',
            Header: t(lang, 'roaming.waybill.datatable.row.contract'),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span>
                        {original.contract && t(lang, 'roaming.waybill.datatable.row.contract_number_from_contract_date', {
                            contract_number: original?.contract?.number,
                            contract_date: dayjs(original.contract?.date, "YYYY-MM-DD").format(currentDateDayJSFormat)
                        })}
                    </span>
                )
            }
        },
        ...(viewer !== VIEW_DRAFTS ? [
            {
                accessor: 'status',
                Header: t(lang, 'roaming.waybill.datatable.row.status'),
                Cell: ({row: {original}}) => {
                    return (
                        <SoftBadge className='me-2' bg={getWaybillV2Status(original.status).variant}>
                            {t(lang, getWaybillV2Status(original.status).label)}
                        </SoftBadge>
                    )
                }
            }
        ] : []),
        {
            accessor: 'contractor',
            Header: t(lang, 'roaming.waybill.datatable.row.contractor'),
            headerProps: {className: 'pe-2'},
            Cell: ({row: {original}}) => {
                if (viewer_inn_or_pinfl === original.executor.inn || viewer_inn_or_pinfl === original.executor.inn_or_pinfl)
                    return original.customer ? `${original.customer?.name} (${original.customer?.inn || original.customer?.inn_or_pinfl})` : ""
                else
                    return `${original.executor?.name} (${original.executor?.inn || original.executor?.inn_or_pinfl})`

            }
        },
        {
            accessor: 'total',
            Header: t(lang, 'roaming.waybill.datatable.row.total'),
            Cell: ({row: {original}}) => {
                const total = original.total ? original?.total : original.roadway?.product_groups[0]?.products?.reduce((acc, item) => acc + item.total, 0);
                return (
                    <span>{total ? numeral?.formats[numberFormat]?.format(total) : 0}</span>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, 'roaming.waybill.datatable.row.created_at'),
            Cell: rowData => {
                const {original} = rowData.row
                return dayjs(original.created_at, "DD.MM.YYYY HH:mm:ss").format("DD.MM.YYYY HH:mm:ss")
            }
        },
       /* ...(viewer === VIEW_DRAFTS ? [
            {
                accessor: 'status',
                Header: t(lang, 'roaming.waybill.datatable.row.status'),
                Cell: ({row: {original}}) => {
                    return (
                        <h5>
                            {/!*<SoftBadge className='me-2' bg={getWaybillStatusVariant(original, viewer_inn_or_pinfl)}>
                            {t(lang, getWaybillStatusText(original, viewer_inn_or_pinfl))}
                        </SoftBadge>*!/}
                        </h5>
                    )
                }
            }
        ] : [])*/
    ]

    if (viewer === VIEW_DRAFTS)
        columns.push({
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Cell: ({row: {original}}) => {
                return (
                    <WaybillV2DraftDelete id={original.id}
                                               size="sm"
                    />
                )
            }
        })


    return (
        <Fragment>
            {!excelRegistry && <Row className="mb-3">
                <Col>
                    <Link to={'/roaming/v2-waybills/inbox'} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEW_INBOX})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon="arrow-down"
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames('text-primary d-none d-lg-block', {
                                    'text-white': viewer === VIEW_INBOX,
                                    'text-primary': viewer !== VIEW_INBOX
                                })}>
                                    {t(lang, 'roaming.waybill.datatable.tabs.received')}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={'/roaming/v2-waybills/outbox'} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEW_OUTBOX})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon="arrow-up"
                                    className={classNames("fs-2 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title className={classNames('text-primary d-none d-lg-block', {
                                    'text-white': viewer === VIEW_OUTBOX,
                                    'text-primary': viewer !== VIEW_OUTBOX
                                })}>
                                    {t(lang, 'roaming.waybill.datatable.tabs.sent')}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={'/roaming/v2-waybills/responsible-person'} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === RESPONSIBLE_PERSON})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon={faUserCircle}
                                    className={classNames("fs-1 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title
                                    className={classNames('text-primary d-none d-lg-block', {'text-white': viewer === RESPONSIBLE_PERSON})}>
                                    {t(lang, 'roaming.waybill.v2.send.responsible_person')}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={'/roaming/v2-waybills/drafts'} className="text-decoration-none">
                        <Card className={classNames("p-3", {'bg-primary text-white': viewer === VIEW_DRAFTS})}>
                            <Flex justifyContent="center" alignItems="center">
                                <FontAwesomeIcon
                                    icon={faBookmark}
                                    className={classNames("fs-1 me-lg-2")}
                                    color="primary"
                                />
                                <Card.Title
                                    className={classNames('text-primary d-none d-lg-block', {'text-white': viewer === VIEW_DRAFTS})}>
                                    {t(lang, 'roaming.waybill.datatable.tabs.drafts')}
                                </Card.Title>
                            </Flex>
                        </Card>
                    </Link>
                </Col>
            </Row>}

            <AdvanceTableWrapper
                data={waybills}
                columns={columns}
                selection
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header className={'pb-0'}>
                        <WaybillV2DataTableHeader
                            loadWaybills={loadWaybills}
                            waybillCount={count}
                            viewer={viewer}
                            waybills={waybills}
                            predefinedFilters={predefinedFilters}
                            changeFilterOptionsAsync={changeFilterOptionsAsync}
                            excelRegistry={excelRegistry}
                            table
                        />
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            rowClassName="align-middle white-space-nowrap"
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filter.limit}
                            page={filter.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <ViewModalPage show={showModal} onHide={onHideModalPage}>
                {id && <ViewWaybillV2 modalId={id} />}
                {draftId && <ViewDraftWaybillV2 modalId={draftId} />}
            </ViewModalPage>

        </Fragment>
    );
};

export default WaybillV2DataTable;
