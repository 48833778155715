import React from "react";
import AuthSimpleLayout from "../../pages/authentication/AuthSimpleLayout";
import {Redirect, Route, Switch,} from "react-router-dom";
import ErrorLayout from "../../pages/error/ErrorLayout";
import SettingsToggle from "../../components/theme-settings-panel/SettingsToggle";
import SettingsPanel from "../../components/theme-settings-panel/SettingsPanel";
import {useSelector} from "react-redux";
import {selectIsAuthorized} from "../store/reducers/auth/authReducer";
import MainLayout from "../../pages/main/MainLayout";

const AppRoutes = () => {
    const isAuthorized = useSelector(selectIsAuthorized);

    return (
        <>
            <Switch>
                <Route path="/errors" component={ErrorLayout}/>
                <Route path="/authentication" component={AuthSimpleLayout}/>

                {!isAuthorized && <Redirect to="/authentication/login"/>}

                {isAuthorized && <Route component={MainLayout}/>}

                <Redirect to="/errors/404"/>
            </Switch>

            {/*<SettingsToggle/>*/}
            {/*<SettingsPanel/>*/}
        </>
    );
}

export default AppRoutes;