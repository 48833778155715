import React from 'react';
import {Alert} from "react-bootstrap";
import {useSelector} from "react-redux";
import IconAlert from "../common/IconAlert";
import StartTrialPeriodModal from "../common/StartTrialPeriodModal";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {EDI_SERVICE_CODE, POS_SERVICE_CODE, WAREHOUSE_MANAGEMENT_SERVICE_CODE} from "../../app/store/reducers/billing/billingReducer";

const TryEdiTrialAlert = ({serviceCode}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <IconAlert variant="info">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                    <div>
                        <Alert.Heading className="mb-2 mb-sm-0">{t(lang, 'main.demo_alert.edi.title')}</Alert.Heading>
                        {WAREHOUSE_MANAGEMENT_SERVICE_CODE === serviceCode &&
                            <p className="mb-0 fw-medium">{t(lang, 'main.demo_alert.warehouse.description')}</p>
                        }
                        {POS_SERVICE_CODE === serviceCode &&
                            <p className="mb-0 fw-medium">{t(lang, 'main.demo_alert.pos.description')}</p>
                        }
                        {EDI_SERVICE_CODE === serviceCode &&
                            <p className="mb-0 fw-medium">{t(lang, 'main.demo_alert.edi.description')}</p>
                        }
                    </div>
                    <StartTrialPeriodModal serviceCode={serviceCode} />
                </div>
            </IconAlert>
        </>
    );
};

export default TryEdiTrialAlert;
