import React from 'react';
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import SelectStatus from "../../SelectStatus";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {ExcelRegistryStatuses} from "../../../../enum/ExcelRegistryStatus";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ExcelRegistryDataTableFilter = ({onResetFilter}) => {
    const dateFormat = useSelector(selectDateFormat);
    const {register} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <Row className="mb-3 g-3">
                <h5 className="mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.excel_registry.datatable.header.filter.title")}</h5>
            </Row>

            <Row className="mb-3 g-3">
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, 'roaming.excel_registry.datatable.header.filter.file_name.title')}</Form.Label>
                    <Form.Control {...register("file_name")} placeholder={t(lang, "roaming.excel_registry.datatable.header.filter.file_name.placeholder")}/>
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "roaming.excel_registry.datatable.header.filter.status.title")}</Form.Label>
                    <Controller
                        name="status"
                        render={({field}) => (
                            <SelectStatus
                                isClearable
                                defaultStatus={field.value}
                                statuses={ExcelRegistryStatuses}
                                placeholder={t(lang, "roaming.excel_registry.datatable.header.filter.status.placeholder")}
                                onChange={statusId => field.onChange(typeof (statusId) !== 'undefined' ? statusId : null)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "roaming.excel_registry.datatable.header.filter.date_start.title")}</Form.Label>
                    <Controller
                        name="created_on_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                selected={field.value}
                                className='form-control'
                                dateFormat={dateFormat}
                                onChange={date => field.onChange(date)}
                                placeholderText={t(lang, "roaming.excel_registry.datatable.header.filter.date_start.placeholder")}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6} md={4} lg={3}>
                    <Form.Label>{t(lang, "roaming.excel_registry.datatable.header.filter.date_end.title")}</Form.Label>
                    <Controller
                        name="created_on_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                selected={field.value}
                                className='form-control'
                                dateFormat={dateFormat}
                                onChange={date => field.onChange(date)}
                                placeholderText={t(lang, "roaming.excel_registry.datatable.header.filter.date_end.placeholder")}
                            />
                        )}
                    />
                </Form.Group>

                <Col md={12} className="d-flex align-items-end justify-content-end gap-3">
                    <Button variant="falcon-primary" type='button' onClick={onResetFilter}>{t(lang, "items.common.clear_imported_file")}</Button>
                    <Button variant="primary" type='submit'>{t(lang, "roaming.excel_registry.datatable.header.filter.show")}</Button>
                </Col>
            </Row>
        </>
    );
};

export default ExcelRegistryDataTableFilter;
