import React, {useState} from 'react';
import {Card, Col, Form, Row, Table} from "react-bootstrap";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import RoamingInvoiceFormHideReportCommittentSwitcher from "./RoamingInvoiceFormHideReportCommittentSwitcher";
import RoamingInvoiceFormItemRow from "./RoamingInvoiceFormItemRow";
import IconButton from "../../../../common/IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import CatalogLinkSelector from "../../../../common/CatalogLinkSelector";
import OriginLinkSelector from "../../../../common/OriginLinkSelector";
import VatRateLinkSelector from "../../../../common/VatRateLinkSelector";
import BenefitLinkSelector from "../../../../common/BenefitLinkSelector";
import WarehouseLinkSelector from "../../../../common/WarehouseLinkSelector";
import numeral from 'numeral';
import {getNumberValue} from '../../../../../helpers/utils';

const RoamingInvoiceFormCardItems = ({executorPrivileges, customerPrivileges, turnoverPrivileges}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const form = useFormContext();
    const {fields, append, insert, remove,} = useFieldArray({name: 'productList.products'});

    const hasExcise = useWatch({name: `productList.hasExcise`, exact: true});
    const hasMarking = useWatch({name: `productList.hasMarking`, exact: true});
    const hasMedical = useWatch({name: `productList.hasMedical`, exact: true});
    const hasCommittent = useWatch({name: `productList.hasCommittent`, exact: true});
    const products = useWatch({name: `productList.products`});
    const [reversedCalculation, setReversedCalculation] = useState(false);
    const manualCalculation = useWatch({name: `manualCalculation`, exact: true});
    const numberFormat = useSelector(selectNumberFormat);

    const addNewItem = () => {
        append({
            committentName: '',
            committentTin: '',
            committentVatRegCode: '',
            committentVatRegStatus: '',
            catalog: null,
            package: null,
            measurement: null,
            lgota: null,
            origin: null,
            barcode: '',
            warehouseId: null,
            mark: {
                productType: null,
                kiz: [
                    {code: ''}
                ],
                nomUpak: [
                    {code: ''}
                ],
                identTransUpak: [
                    {code: ''}
                ],
            },
            exchangeInfo: {
                productCode: '',
                productProperties: '',
                planPositionId: 1,
            },
            name: '',
            serial: '',
            baseSumma: '',
            profitRate: '',
            count: '',
            countTon: '',
            countKm: '',
            summa: '',
            exciseRate: '',
            exciseSum: '',
            deliverySum: '',
            vatRate: '',
            vatSum: '',
            deliverySumWithVat: '',
        }, {shouldFocus: false});
    };
    const insertNewItem = (index) => {
        insert(index + 1, {
            committentName: '',
            committentTin: '',
            committentVatRegCode: '',
            committentVatRegStatus: '',
            catalog: null,
            package: null,
            measurement: null,
            lgota: null,
            origin: null,
            barcode: '',
            warehouseId: null,
            mark: {
                productType: null,
                kiz: [
                    {code: ''}
                ],
                nomUpak: [
                    {code: ''}
                ],
                identTransUpak: [
                    {code: ''}
                ],
            },
            exchangeInfo: {
                productCode: '',
                productProperties: '',
                planPositionId: 1,
            },
            name: '',
            serial: '',
            baseSumma: '',
            profitRate: '',
            count: '',
            countTon: '',
            countKm: '',
            summa: '',
            exciseRate: '',
            exciseSum: '',
            deliverySum: '',
            vatRate: '',
            vatSum: '',
            deliverySumWithVat: '',
        });
    };
    const deleteItem = (index) => remove(index);

    const onBulkSetCatalog = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.catalog`, value);
            form.setValue(`productList.products.${index}.package`, null);
            form.setValue(`productList.products.${index}.measurement`, null);
            form.setValue(`productList.products.${index}.lgota`, null);
            form.setValue(`productList.products.${index}.origin`, null);
        });
    };

    const onBulkSetOrigin = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.origin`, value);
        });
    };

    const onBulkSetVatRate = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.vatRate`, value);
        });
    };

    const onBulkSetLgota = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.lgota`, value);
        });
    };

    const onBulkSetWarehouse = (value) => {
        fields.map((field, index) => {
            form.setValue(`productList.products.${index}.warehouseId`, value ? value.id : null);
        });
    };

    return (
        <Card>
            <Card.Header className="d-flex flex-wrap justify-content-between pb-0">
                <Form.Switch label={t(lang, 'roaming.invoice.excise')}
                             {...form.register(`productList.hasExcise`)}
                />

                <Form.Switch label={t(lang, 'roaming.invoice.mark')}
                             {...form.register(`productList.hasMarking`)}
                />

                <Form.Switch label={t(lang, 'roaming.invoice.medicalExecutor')}
                             {...form.register(`productList.hasMedical`)}
                />

                <Form.Switch label={t(lang, 'roaming.invoice.comissionAgent')}
                             {...form.register(`productList.hasCommittent`)}
                />

                <RoamingInvoiceFormHideReportCommittentSwitcher/>

                <Form.Switch label={t(lang, 'roaming.invoice.reversed_calculation')}
                             defaultChecked={reversedCalculation}
                             onChange={(e) => setReversedCalculation(e.target.checked)}
                />

                <Form.Switch label={t(lang, 'roaming.invoice.manual_calculation')}
                             checked={manualCalculation}
                             onChange={e => {
                                 form.setValue('manualCalculation', e.target.checked)
                             }}
                />
            </Card.Header>
            <Card.Body className="pt-0">
                <Row className="gy-2">
                    <Col xs={12} className="text-end">
                        <IconButton icon={faPlus}
                                    variant={'falcon-primary'}
                                    onClick={addNewItem}
                        >
                            {t(lang, 'roaming.common.add')}
                        </IconButton>
                    </Col>
                    <Col xs={12}>
                        <Table responsive striped bordered className="border-300">
                            <thead className="bg-200 text-dark">
                            <tr className="position-relative">
                                <th style={{minWidth: '30px', width: '30px'}} className="p-0 text-center">#</th>
                                {hasCommittent &&
                                    <>
                                        <th className="p-0 align-middle text-center">
                                            {t(lang, 'roaming.invoice.comissionAgent.inn')}
                                            <span className="text-danger">*</span>
                                        </th>
                                        <th className="p-0 align-middle text-center">
                                            {t(lang, 'roaming.invoice.comissionAgent.name')}
                                            <span className="text-danger">*</span>
                                        </th>
                                        <th className="p-0 align-middle text-center">{t(lang, 'roaming.invoice.comissionAgent.nds_code')}</th>
                                        <th className="p-0 align-middle text-center">{t(lang, 'roaming.invoice.comissionAgent.nds_status')}</th>
                                    </>
                                }
                                <th className="p-0 align-middle text-center">
                                    {t(lang, 'roaming.common.items.product')}
                                    <span className="text-danger">*</span>
                                </th>
                                <th style={{minWidth: '150px', width: '150px'}}
                                    className="p-0 align-middle text-center">
                                    <div className="d-flex flex-column">
                                            <span>
                                                {t(lang, 'roaming.common.items.catalog_class_code')}
                                                <span className="text-danger">*</span>
                                            </span>
                                        <CatalogLinkSelector defaultCatalog={null}
                                                             onChange={onBulkSetCatalog}
                                                             linkProps={{
                                                                 className: classNames('text-primary cursor-pointer fs--1')
                                                             }}
                                        />
                                    </div>
                                </th>
                                <th style={{minWidth: '190px', width: '190px'}}
                                    className="p-0 align-middle text-center">
                                    <div className="d-flex flex-column">
                                            <span>
                                                {t(lang, 'roaming.common.items.origin')}
                                                <span className="text-danger">*</span>
                                            </span>
                                        <OriginLinkSelector defaultOriginType={null}
                                                            onChange={onBulkSetOrigin}
                                                            linkProps={{
                                                                className: classNames('text-primary cursor-pointer fs--1')
                                                            }}
                                        />
                                    </div>
                                </th>
                                {hasMarking &&
                                    <th style={{minWidth: '200px', width: '200px'}}
                                        className="p-0 align-middle text-center">
                                        {t(lang, 'roaming.invoice.mark')}
                                    </th>
                                }
                                <th style={{minWidth: '140px', width: '140px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, "roaming.common.items.barcode")}
                                </th>
                                <th style={{minWidth: '150px', width: '150px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, 'roaming.common.items.measurement')}
                                    <span className="text-danger">*</span>
                                </th>
                                <th style={{minWidth: '120px', width: '120px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, "roaming.common.items.quantity")}
                                    <span className="text-danger">*</span>
                                </th>
                                {hasMedical &&
                                    <>
                                        <th style={{minWidth: '140px', width: '140px'}}
                                            className="p-0 align-middle text-center">
                                            {t(lang, 'roaming.common.items.medical.serial')}
                                        </th>
                                        <th style={{minWidth: '140px', width: '140px'}}
                                            className="p-0 align-middle text-center">
                                            {t(lang, 'roaming.common.items.medical.value')}
                                            <span className="text-danger">*</span>
                                        </th>
                                        <th style={{minWidth: '140px', width: '140px'}}
                                            className="p-0 align-middle text-center">
                                            {t(lang, 'roaming.common.items.medical.rate')}
                                        </th>
                                    </>
                                }
                                <th style={{minWidth: '140px', width: '140px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, "roaming.common.items.price")}
                                    <span className="text-danger">*</span>
                                </th>
                                {hasExcise &&
                                    <>
                                        <th style={{minWidth: '140px', width: '140px'}}
                                            className="p-0 align-middle text-center">{t(lang, 'roaming.common.items.excise.rate', {icon: "%"})}</th>
                                        <th style={{minWidth: '140px', width: '140px'}}
                                            className="p-0 align-middle text-center">{t(lang, 'roaming.common.items.excise')}</th>
                                    </>
                                }
                                <th style={{minWidth: '150px', width: '150px'}}
                                    className="p-0 align-middle text-center">
                                    {t(lang, "roaming.invoice.items.total")}
                                </th>
                                <th style={{minWidth: '60px', width: '60px'}} className="p-0 align-middle text-center">
                                    <div className="d-flex flex-column">
                                        <span>{t(lang, "roaming.common.items.nds")}</span>
                                        <VatRateLinkSelector defaultVatRate={''}
                                                             onChange={onBulkSetVatRate}
                                                             linkProps={{
                                                                 className: classNames('text-primary cursor-pointer fs--1')
                                                             }}
                                        />
                                    </div>
                                </th>
                                <th style={{minWidth: '130px', width: '130px'}}
                                    className="p-0 align-middle text-center">{t(lang, "roaming.common.items.nds_value")}</th>
                                <th style={{minWidth: '160px', width: '160px'}}
                                    className="p-0 align-middle text-center">{t(lang, "roaming.common.items.total_with_nds")}</th>
                                <th style={{minWidth: '130px', width: '130px'}}
                                    className="p-0 align-middle text-center">
                                    <div className="d-flex flex-column">
                                        <span>{t(lang, 'roaming.common.items.privilege')}</span>
                                        <BenefitLinkSelector defaultBenefit={null}
                                                             benefits={[...executorPrivileges, ...turnoverPrivileges, ...customerPrivileges]}
                                                             onChange={onBulkSetLgota}
                                                             linkProps={{
                                                                 className: classNames('text-primary cursor-pointer fs--1')
                                                             }}
                                        />
                                    </div>
                                </th>
                                <th style={{minWidth: '100px', width: '100px'}}
                                    className="p-0 align-middle text-center">
                                    <div className="d-flex flex-column">
                                        <span>{t(lang, 'roaming.invoice.warehouse')}</span>
                                        <WarehouseLinkSelector defaultWarehouseId={null}
                                                               onChange={onBulkSetWarehouse}
                                                               linkProps={{
                                                                   className: classNames('text-primary cursor-pointer fs--1 text-truncate')
                                                               }}
                                        />
                                    </div>
                                </th>
                                <th style={{minWidth: '30px', width: '30px'}}
                                    className="p-0 align-middle text-center"></th>
                                <th style={{zIndex: '-10'}} className="p-0 invisible position-absolute"></th>
                                <th style={{zIndex: '-10'}} className="p-0 invisible position-absolute"></th>
                                <th style={{zIndex: '-10'}} className="p-0 invisible position-absolute"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {fields.map((field, index) => (
                                <RoamingInvoiceFormItemRow key={field.id}
                                                           hasExcise={hasExcise}
                                                           hasMarking={hasMarking}
                                                           hasMedical={hasMedical}
                                                           hasCommittent={hasCommittent}
                                                           reversedCalculation={reversedCalculation}
                                                           manualCalculation={manualCalculation}
                                                           fieldName={`productList.products.${index}`}
                                                           index={index}
                                                           insertNewItem={insertNewItem}
                                                           deleteItem={deleteItem}
                                                           allowDeleteItem={fields.length > 1}
                                                           sellerCompanyPrivileges={executorPrivileges}
                                                           turnoverCompanyPrivileges={turnoverPrivileges}
                                                           buyerCompanyPrivileges={customerPrivileges}
                                />
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row className="g-0 justify-content-end">
                    <Col xs="auto">
                        <Table borderless size="sm" className="fs--1 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(products.reduce((s, product) => {
                                        return s + getNumberValue(product.deliverySum);
                                    }, 0))}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(products.reduce((s, product) => {
                                        return s + getNumberValue(product.vatSum);
                                    }, 0))}
                                </td>
                            </tr>
                            {hasExcise &&
                                <tr>
                                    <th className="text-900">{t(lang, 'roaming.invoice.excise_total')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(products.reduce((s, product) => {
                                            return s + getNumberValue(product.exciseSum);
                                        }, 0))}
                                    </td>
                                </tr>
                            }
                            {/*
                                {hasMedical &&
                                    <tr>
                                        <th className="text-900">{t(lang, 'roaming.invoice.medical_total')}:</th>
                                        <td className="fw-semi-bold">
                                            {numeral.formats[numberFormat].format(medical)}
                                        </td>
                                    </tr>
                                } */}
                            <tr className="border-top">
                                <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(products.reduce((s, product) => {
                                        return s + getNumberValue(product.deliverySumWithVat);
                                    }, 0))}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default RoamingInvoiceFormCardItems;
