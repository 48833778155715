import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Table} from "react-bootstrap";
import React from "react";
import WarehouseOperationImageModal from "../../components/WarehouseOperationImageModal";
import dayjs from "dayjs";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";


const WriteOffDatatableTab = ({dataItems}) => {
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate





    const groupByItemIdAndPrice = (filterItems) => {
        var groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;


            if (itemID in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[itemID].sub.push(item)
            } else {
                if (itemID) {
                    groupByItemIdPriceIdCurrencyId[itemID] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(dataItems)



    return (
        <div className="table-responsive fs--1" style={{maxHeight: "430px"}}>

            <Table className="border-bottom">
                <thead style={{position: "sticky", top: '-1px'}} className="table-primary">
                <tr>
                    <th className="align-middle">№</th>
                    <th className="align-middle">{t(lang, "warehouse.operation.item.common.name")}</th>
                    <th className="text-center">{t(lang, "warehouse.operation.item.common.image")}</th>
                    <th className="text-end">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                    {dataItems?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                        <th className="text-center">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                    }
                    {warehouses?.length > 1 &&
                        <th className="text-end">{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                    }
                    {dataItems?.some(d => d?.is_deleted) &&
                        <th className="text-end text-danger">{t(lang, "Удаленное время")}</th>
                    }
                </tr>
                </thead>
                <tbody>

                {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                    return (
                        <React.Fragment key={rowIndex}>
                            {item?.sub?.map((subItem, subRowIndex) => {
                                return (
                                    <tr key={subItem.id}>
                                        <td key={rowIndex}
                                            className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                            rowSpan={item?.sub?.length}
                                            style={{width: "50px"}}
                                        >
                                            {groupByItemPriceCurrencyId?.length - rowIndex}
                                        </td>
                                        <td
                                            className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                            rowSpan={item?.sub?.length}>
                                            {subItem?.warehouse_item?.name}
                                        </td>
                                        <td
                                            className={subRowIndex === 0 ? "text-center border-end border-2 p-1" : "d-none"}
                                            style={{width: "50px"}} rowSpan={item?.sub?.length}>
                                            {items.find(x => x?.item?.id === subItem?.warehouse_item?.id)?.item?.images.length > 0 &&
                                                <WarehouseOperationImageModal
                                                    image={items.find(x => x?.item?.id === item?.item?.id)?.item?.images}/>
                                            }
                                        </td>
                                        <th className="text-end border-end border-2">
                                            {subItem?.quantity}
                                        </th>
                                        {groupByItemPriceCurrencyId[rowIndex]?.sub?.some(x => x?.warehouse_item.warehouse_item_use) &&
                                            <td className="text-center border-end border-2">
                                                {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                <span> => </span>
                                                {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                            </td>
                                        }
                                        {warehouses?.length > 1 &&
                                            <th className="text-end border-end border-2">
                                                {subItem?.warehouse_item?.warehouse?.name}
                                            </th>
                                        }
                                        {subItem?.is_deleted &&
                                            <>
                                                <th className="text-nowrap text-center border-end border-2 text-danger" style={{width: "150px"}}>
                                                    {dayjs(subItem?.deleted_at).format("YYYY-MM-DD HH:mm:ss")}
                                                </th>
                                            </>
                                        }
                                    </tr>
                                )
                            })}
                        </React.Fragment>
                    )
                })}
                </tbody>
            </Table>
        </div>
    );
};


export default WriteOffDatatableTab;