import {useLocation} from "react-router";

const useQuery = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryParams = {};
    for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
    }
    return queryParams;
};

export default useQuery;