export const SEND_WAYBILL_REQUESTED = 'SEND_WAYBILL_REQUESTED';
export const SEND_WAYBILL_SUCCEED = 'SEND_WAYBILL_SUCCEED';
export const SEND_WAYBILL_FAILED = 'SEND_WAYBILL_FAILED';

export const CARRIER_CANCEL_WAYBILL_REQUESTED = 'CARRIER_CANCEL_WAYBILL_REQUESTED';
export const CARRIER_CANCEL_WAYBILL_SUCCEED = 'CARRIER_CANCEL_WAYBILL_SUCCEED';
export const CARRIER_CANCEL_WAYBILL_FAILED = 'CARRIER_CANCEL_WAYBILL_FAILED';

export const CUSTOMER_DECLINE_WAYBILL_REQUESTED = 'CUSTOMER_DECLINE_WAYBILL_REQUESTED';
export const CUSTOMER_DECLINE_WAYBILL_SUCCEED = 'CUSTOMER_DECLINE_WAYBILL_SUCCEED';
export const CUSTOMER_DECLINE_WAYBILL_FAILED = 'CUSTOMER_DECLINE_WAYBILL_FAILED';

export const CUSTOMER_ACCEPT_WAYBILL_REQUESTED = 'CUSTOMER_ACCEPT_WAYBILL_REQUESTED';
export const CUSTOMER_ACCEPT_WAYBILL_SUCCEED = 'CUSTOMER_ACCEPT_WAYBILL_SUCCEED';
export const CUSTOMER_ACCEPT_WAYBILL_FAILED = 'CUSTOMER_ACCEPT_WAYBILL_FAILED';

export const SAVE_DRAFT_WAYBILL_REQUESTED = 'SAVE_DRAFT_WAYBILL_REQUESTED';
export const SAVE_DRAFT_WAYBILL_SUCCEED = 'SAVE_DRAFT_WAYBILL_SUCCEED';
export const SAVE_DRAFT_WAYBILL_FAILED = 'SAVE_DRAFT_WAYBILL_FAILED';

// waybill
export const ROAMING_WAYBILL_SYNC_REQUESTED = 'ROAMING_WAYBILL_SYNC_REQUESTED';
export const ROAMING_WAYBILL_SYNC_SUCCESS = 'ROAMING_WAYBILL_SYNC_SUCCESS';
export const ROAMING_WAYBILL_SYNC_FAILED = 'ROAMING_WAYBILL_SYNC_FAILED';