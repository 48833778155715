import RevisionDatatable from "../../../../components/hippo/warehouse-operation/revision/datatable/RevisionDatatable";


const Revisions = () => {



    return(
        <>
            <RevisionDatatable />
        </>
    )
}

export default Revisions;