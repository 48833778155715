import React from 'react';
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import ContractorSelect from "../../../../common/ContractorSelect";
import {selectDateFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import WarehouseOperationSelectStatus from "../../../../warehouse-operation/components/WarehouseOperationSelectStatus";
import {changeDraftPaymentFilterOptionsAsync, selectDraftPaymentsFilterOptions} from "../../../../../../app/store/reducers/crm/draft-payment/draftPaymentReducer";

const DraftPaymentDataTableHeader = () => {
    const filter = useSelector(selectDraftPaymentsFilterOptions);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            is_approved: typeof filter.is_approved !== 'undefined' ? filter.is_approved : null,
            contractor_id: filter.contractor_id || null,
            created_at_start: filter?.created_at_start && dayjs(filter?.created_at_start, "YYYY-MM-DD HH:mm:ss").toDate(),
            created_at_end: filter?.created_at_end && dayjs(filter?.created_at_end, "YYYY-MM-DD HH:mm:ss").toDate(),
        },
    });

    const onSubmitFilter = ({created_at_start, created_at_end, ...filters}) => {
        created_at_start = created_at_start && dayjs(created_at_start).format("YYYY-MM-DD HH:mm:ss");
        created_at_end = created_at_end && dayjs(created_at_end).format("YYYY-MM-DD HH:mm:ss");

        dispatch(changeDraftPaymentFilterOptionsAsync({created_at_start, created_at_end, ...filters}))
    };

    return (
        <Row as={Form} onSubmit={methods.handleSubmit(onSubmitFilter)}>
            <Form.Group as={Col} md={3} xs={12}>
                <Form.Label>{t(lang, 'crm.draft_payment.datatable.header.filter.is_approved')}</Form.Label>
                <Controller
                    name={'is_approved'}
                    control={methods.control}
                    render={({field}) => (
                        <WarehouseOperationSelectStatus
                            defaultValue={field.value}
                            classNamePrefix={'react-select'}
                            onChange={option => field.onChange(option.value)}
                        />
                    )}
                />
            </Form.Group>

            <Form.Group as={Col} md={3} xs={12}>
                <Form.Label>{t(lang, 'crm.draft_payment.datatable.header.filter.contractor')}</Form.Label>
                <Controller
                    name={'contractor_id'}
                    control={methods.control}
                    render={({field}) => (
                        <ContractorSelect
                            isClearable={true}
                            defaultValue={field.value}
                            classNamePrefix={'react-select'}
                            onChange={(option) => field.onChange(option ? option?.id : null)}
                        />
                    )}
                />
            </Form.Group>

            <Form.Group as={Col} md={3} xs={12}>
                <Form.Label>{t(lang, 'crm.draft_payment.datatable.header.filter.created_at_start')}</Form.Label>
                <Controller
                    name={'created_at_start'}
                    control={methods.control}
                    render={({field}) => (
                        <DatePicker
                            timeFormat={'p'}
                            timeIntervals={60}
                            showTimeSelect={true}
                            selected={field.value}
                            onChange={field.onChange}
                            className={'form-control'}
                            dateFormat={`${dateFormat} HH:mm`}
                            placeholderText={t(lang, 'crm.draft_payment.datatable.header.filter.created_at_start')}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group as={Col} md={3} xs={12}>
                <Form.Label>{t(lang, 'crm.draft_payment.datatable.header.filter.created_at_end')}</Form.Label>
                <Controller
                    control={methods.control}
                    name={'created_at_end'}
                    render={({field}) => (
                        <DatePicker
                            timeFormat={'p'}
                            timeIntervals={60}
                            showTimeSelect={true}
                            selected={field.value}
                            onChange={field.onChange}
                            className={'form-control'}
                            dateFormat={`${dateFormat} HH:mm`}
                            placeholderText={t(lang, 'crm.draft_payment.datatable.header.filter.created_at_end')}
                        />
                    )}
                />
            </Form.Group>

            <Form.Group as={Col} md={12} className={'text-end'}>
                <Button variant={'primary'} type={'submit'} size={'xs'} className={"ms-2 mt-4"}>
                    {t(lang, "crm.draft_payment.datatable.header.filter.submit")}
                </Button>
            </Form.Group>
        </Row>
    );
};

export default DraftPaymentDataTableHeader;