import React from 'react';
import {Col, Row} from "react-bootstrap";
import DataTableFilter from "./DataTableFilter";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import Flex from "../../../../common/Flex";
import Divider from "../../../../common/Divider";
import BulkDeleteDraftItem from "../BulkDeleteDraftItem";

const DataTableHeader = ({selectedRowIds, page}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const selectedDraftItems = Object.keys(selectedRowIds).reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, [])

    return (
        <Row>
            <Col xs={12} className="mb-3">
                <h5 className="mb-2">{t(lang, "items.common.datatable.filter")}</h5>
                <DataTableFilter/>
            </Col>
            <Divider />
            <Flex justifyContent="between">
                <h5>{t(lang, 'items.common.draft')}</h5>
                {selectedDraftItems.length > 0 &&
                    <BulkDeleteDraftItem drafts={selectedDraftItems}/>
                }
            </Flex>
        </Row>
    );
};

export default DataTableHeader;