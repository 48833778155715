import {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {Form} from "react-bootstrap";
import {
    selectWriteOffDefaultsSettings,
    writeOffAddItemAsync, writeOffChangeEmployeeAsync,
    writeOffUpdateNoteAsync, writeOffUpdateNumberAsync
} from "../../../../../app/store/reducers/warehouse-operation/writeOffReducer";
import WriteOffForm from "./WriteOffForm";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";


const EditWriteOffForm = ({writeOff}) => {
    const writeOffDefaultsSettings = useSelector(selectWriteOffDefaultsSettings)
    const warehouses = useSelector(selectWarehouses)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate
    const settings = useSelector(selectDefaultSetting)


    const form = useForm({
        defaultValues: {
            employee_id: writeOff?.employee?.id,
            date: dayjs(writeOff?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: writeOff?.note,
            number: writeOff?.number,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
        }
    })


    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
        })
    }




    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: +formData?.quantity,
        }


        if (buttonType === "addOrEditWriteOff") {
            setLoading(true)
            await writeOffAddItemAsync({
                writeOffId: writeOff?.id,
                operation: item
            })
                .then(({data}) => {
                    updateNote(formData)
                    // updateNumber(formData)
                    resetForm(formData)
                    updateEmployee(formData)
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch((error) => {
                    if (error?.response?.data?.includes("warehouse")) {
                        toast.error(t(lang, "Склад не выбран"))
                    } else {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || writeOff?.employee) {
            if (formData?.employee_id !== writeOff?.employee?.id) {
                writeOffChangeEmployeeAsync({writeOffId: writeOff?.id, employeeId: formData?.employee_id})
                    .then(() => { })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== writeOff?.note) {
            writeOffUpdateNoteAsync({writeOffId: writeOff?.id, note: formData?.note})
                .then(({data}) => {})
                .catch(() => {})
        }
    }

    // const updateNumber = (formData) => {
    //     if (formData?.number?.trim() && formData?.number !== writeOff?.number) {
    //         writeOffUpdateNumberAsync({writeOffId: writeOff?.id, number: formData?.number})
    //             .then(({data}) => {})
    //             .catch(() => {})
    //     }
    // }


    return(
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <WriteOffForm writeOff={writeOff} loading={loading} />
                </Form>
            </FormProvider>
        </>
    )
}

export default EditWriteOffForm;
