import React, {useCallback, useState} from 'react';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/IconButton";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {deleteDraftItemAsync} from "../../../../app/store/reducers/draft-item/draftItemReducer";
import PropTypes from "prop-types";
import {
    DELETE_DRAFT_ITEM_FAILED,
    DELETE_DRAFT_ITEM_REQUESTED,
    DELETE_DRAFT_ITEM_SUCCESS
} from "../../../../app/eventbus/itemEvents";
import EventBus from "../../../../app/eventbus/EventBus";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";

const DeleteDraftItem = ({id, buttonProps, buttonText}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const onDeleteDraftItem = useCallback(() => {
        setLoading(true);
        EventBus.dispatch(DELETE_DRAFT_ITEM_REQUESTED);
        deleteDraftItemAsync(id)
            .then(() => {
                setLoading(false);
                setShow(false);
                EventBus.dispatch(DELETE_DRAFT_ITEM_SUCCESS);
            })
            .catch(() => {
                setLoading(false);
                EventBus.dispatch(DELETE_DRAFT_ITEM_FAILED);
            })
    }, [id]);

    return (
        <React.Fragment>
            <IconButton variant="falcon-danger"
                        icon={faTrash}
                        onClick={() => setShow(true)}
                        {...buttonProps}
            >
                {buttonText}
            </IconButton>

            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "items.common.draft.delete.title")}
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "items.delete_item_modal.title")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        {t(lang, "items.common.cancel")}
                    </Button>
                    <Button variant="danger" onClick={onDeleteDraftItem} disabled={loading}>
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                        }
                        {t(lang, "items.common.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default DeleteDraftItem;

DeleteDraftItem.propTypes = {
    id: PropTypes.string.isRequired
}

