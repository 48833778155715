import React from 'react';
import IconButton from "../../../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Modal} from "react-bootstrap";

const CashBoxCommonRegisterModal = ({show, setShow, children, showBtn, defaultValue}) => {

    const lang = useSelector(selectLang)
    const t = Translate

    return (
        <div>
            {showBtn && <IconButton
                icon={'plus'}
                variant={'falcon-default'}
                onClick={() => setShow(true)}
                size={"sm"}
            >
                <span>{t(lang, "edi.branch.datatable.header.row.navigation.create")}</span>
            </IconButton>}

            <Modal show={show} size={'xl'} onHide={() => setShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, defaultValue ? "roaming.common.edit" : "main.common.add")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CashBoxCommonRegisterModal;
