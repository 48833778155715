import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorReturnOrderSlice = createSlice({
    name: 'contractorReturnOrder',
    initialState: {
        returnOrders: [],
        countReturnOrders: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateReturnOrders: (state, action) => {
            state.returnOrders = action.payload
        },
        updateCountReturnOrders: (state, action) => {
            state.countReturnOrders = action.payload
        },
        updateFilterReturnOrder: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorReturnOrdersAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorReturnOrders(params)
        .then(response => {
            dispatch(contractorReturnOrderSlice.actions.updateReturnOrders(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const loadContractorReturnOrdersCountAsync = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    contractorService.getContractorReturnOrdersCount(other)
        .then(response => {
            dispatch(contractorReturnOrderSlice.actions.updateCountReturnOrders(response.data))
        })
        .catch(error => console.log(error))
}
export const changeFilterContractorReturnOrdersAsync = (params) => (dispatch) => {
    return dispatch(contractorReturnOrderSlice.actions.updateFilterReturnOrder(params))
}
export const selectContractorReturnOrders = (state) => state.contractorReturnOrder.returnOrders;
export const selectContractorCountReturnOrders = (state) => state.contractorReturnOrder.countReturnOrders;
export const selectContractorReturnOrderFilterOptions = (state) => state.contractorReturnOrder.filterOptions;

export default contractorReturnOrderSlice.reducer