import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    changeDefaultRole,
    loadAccountRolesAsync,
    loadRolesAsync,
    selectRoles
} from "../../../../app/store/reducers/role/roleReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_ROLE_SUCCESS, DELETE_ROLE_SUCCESS, EDIT_ROLE_SUCCESS} from "../../../../app/eventbus/roleEvents";
import ActionButton from "../../../common/ActionButton";
import DeleteRole from "./DeleteRole";
import {toast} from "react-toastify";
import EditRoleModal from "./forms/EditRoleModal";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionSystemRoleDelete, PermissionSystemRoleUpdate} from "../../../../enum/Permission/RolePermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import SoftBadge from "../../../common/SoftBadge";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {original} from "@reduxjs/toolkit";
import ConfirmationDefaultModal from "./ConfirmationDefaultModal";


const RolesDatatable = () => {
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;
    const roles = useSelector(selectRoles);
    const [idRole, setIdRole] = useState(null)
    const [editingRole, setEditingRole] = useState(null)
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false)
    const [showEditRoleModal, setShowEditRoleModal] = useState(false)
    const [defaultId, setDefaultId] = useState(null)
    const [showDefaultRoleModal, setShowDefaultRoleModal] = useState(false)

    const activeOrganization = useSelector(selectActiveOrganization)

    const columns = [
        {
            Header: "#",
            id: "row",
            headerProps: {
                width: 80
            },
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "edi.common.label.name"),
            disableSortBy: true,
            cellProps: {
                className: 'fw-medium text-start',
                // width: '75%'
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <strong>{original.name}</strong>
                    </div>
                )
            }
        },
        {
            accessor: 'badge',
            Header: "",
            disableSortBy: true,
            cellProps: {
                className: 'fw-medium text-start',
                width: '50%'
            },
            Cell: ({row: {original}}) => {
                return (
                    <Fragment>
                        {original.is_admin && <SoftBadge bg="info" text="dark" className="fs--1">
                            <strong>{t(lang, 'system.permission.role.datatable.admin.role.title')}</strong>
                        </SoftBadge>}
                        {original.is_default && <SoftBadge bg="warning" text="dark" className="fs--1">
                            <strong>{t(lang, 'system.permission.role.datatable.default.role.title')}</strong>
                        </SoftBadge>}
                    </Fragment>
                )
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                width: '100px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <Fragment>
                        <div className={'d-flex justify-content-end align-items-center gap-3'}>
                            {
                                !(row?.original?.is_admin || row.original?.is_default) &&
                                <Button title={t(lang, "system.permission.role.datatable.default.role.change.title.on")}
                                        variant='falcon-primary'
                                        size={'sm'}
                                        onClick={() => {
                                            setShowDefaultRoleModal(true)
                                            setDefaultId(row?.original?.id)
                                        }}>
                                    {t(lang, "system.permission.role.datatable.default.role.change.title")}
                                </Button>
                            }
                            {!row?.original?.is_admin &&
                                <ActionButton icon="edit" title="Изменить" variant="action" className="p-0 text-warning"
                                              onClick={() => {
                                                  if (dispatch(checkPermission(PermissionSystemRoleUpdate))) {
                                                      setEditingRole(row.original);
                                                      setShowEditRoleModal(true)
                                                  } else {
                                                      dispatch(toastPermission())
                                                  }
                                              }}/>
                            }
                            {!(row?.original?.is_admin || row.original?.is_default) &&
                                <ActionButton icon="trash-alt" title="Удалить" variant="action" className="p-0 text-danger"
                                              onClick={() => {
                                                  if (dispatch(checkPermission(PermissionSystemRoleDelete))) {
                                                      setIdRole(row.original.id);
                                                      setShowDeleteRoleModal(true)
                                                  } else {
                                                      dispatch(toastPermission())
                                                  }
                                              }}/>}
                        </div>
                    </Fragment>
                );
            }
        }
    ];

    useEffect(() => {
        dispatch(loadRolesAsync())
    }, [activeOrganization])

    useEffect(() => {
        const onRoleAddedHandler = EventBus.on(ADD_ROLE_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(loadRolesAsync());
        });
        const onRoleDeletedHandler = EventBus.on(DELETE_ROLE_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(loadRolesAsync());
        });
        const onRoleEditHandler = EventBus.on(EDIT_ROLE_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(loadRolesAsync());
        });
        return () => {
            EventBus.remove(ADD_ROLE_SUCCESS, onRoleAddedHandler)
            EventBus.remove(DELETE_ROLE_SUCCESS, onRoleDeletedHandler)
            EventBus.remove(EDIT_ROLE_SUCCESS, onRoleEditHandler)
        }
    }, []);

    const onHideDefaultRoleModal = () => {
        setDefaultId(null)
        setShowDefaultRoleModal(false)
    }

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={roles}
                pagination
                perPage={1000}
            >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
            </AdvanceTableWrapper>
            <DeleteRole id={idRole} show={showDeleteRoleModal} onClose={() => {
                setIdRole(null);
                setShowDeleteRoleModal(false)
            }}/>
            {editingRole && <EditRoleModal role={editingRole} show={showEditRoleModal} onClose={() => {
                setEditingRole(null);
                setShowEditRoleModal(false)
            }}/>}
            <ConfirmationDefaultModal show={showDefaultRoleModal} onHide={onHideDefaultRoleModal} id={defaultId} />
        </Fragment>
    )
}
export default RolesDatatable
