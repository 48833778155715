import {toast} from "react-toastify";
import {Card} from "react-bootstrap";
import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import CommonDeleteModal from "../../../../common/CommonDeleteModal";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AddOrganizationAddressModal from "../form/AddOrganizationAddressModal";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {deleteCompanyAddressAsync, selectCompanyAddresses} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import EditOrganizationAddressModal from "../form/EditOrganizationAddressModal";

const OrganizationAddressDataTable = () => {
    const companyAddresses = useSelector(selectCompanyAddresses);
    const lang = useSelector(selectLang);
    const t = Translate;

    const columns = [
        {
            Header: "#",
            Cell: ({row}) => {
                return row.index + 1;
            },
        },
        {
            accessor: 'region_name',
            Header: t(lang, "roaming.organization_address.datatable.column.region"),
            Cell: ({row: {original}}) => {
                return original?.region_name;
            }
        },
        {
            accessor: 'district_name',
            Header: t(lang, "roaming.organization_address.datatable.column.district"),
            Cell: ({row: {original}}) => {
                return original?.district_name;
            }
        },
        {
            accessor: 'village_name',
            Header: t(lang, "roaming.organization_address.datatable.column.village"),
            Cell: ({row: {original}}) => {
                return original?.village_name;
            }
        },
        {
            accessor: 'street',
            Header: t(lang, "roaming.organization_address.datatable.column.street"),
            Cell: ({row: {original}}) => {
                return original?.street;
            }
        },
        {
            accessor: 'other',
            Header: t(lang, "roaming.organization_address.datatable.column.other"),
            Cell: ({row: {original}}) => {
                return original?.other;
            }
        },
        {
            accessor: 'none',
            Header: '',
            cellProps: {className: 'text-end'},
            Cell: ({row: {original}}) => {
                return (
                    <div>
                        <EditOrganizationAddressModal companyAddress={original}>
                            <IconButton icon={'pencil'} variant={'warning'} size={'sm'} className={'mx-1'}/>
                        </EditOrganizationAddressModal>
                        <CommonDeleteModal modalTitle={t(lang, 'Удаление адреса')} onDelete={() => deleteCompanyAddress(original.id)}>
                            <IconButton icon={'trash'} variant={'danger'} size={'sm'} className={'mx-1'}/>
                        </CommonDeleteModal>
                    </div>
                );
            },
        }
    ];

    const deleteCompanyAddress = (addressId) => {
        deleteCompanyAddressAsync(addressId)
            .then(() => toast.success(t(lang, 'common.toast.success')))
            .catch(() => toast.error(t(lang, 'common.toast.error')))
    }

    return (
        <Fragment>
            <AdvanceTableWrapper
                pagination
                perPage={10}
                columns={columns}
                data={companyAddresses}
            >
                <Card>
                    <Card.Header className={'d-flex flex-between-center'}>
                        <h5>{t(lang, 'roaming.organization_address.datatable.title')}</h5>
                        <AddOrganizationAddressModal/>
                    </Card.Header>
                    <Card.Body>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}>
                        </AdvanceTable>
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default OrganizationAddressDataTable;