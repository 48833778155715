import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ReactSelect from "react-select";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import classNames from "classnames";
import {WITHOUT_VAT_PRIVILEGE_TYPE, ZERO_VAT_PRIVILEGE_TYPE} from "../../enum/roaming/LgotaTypes";
import FalconCloseButton from "./FalconCloseButton";
import Divider from "./Divider";
import IconButton from "./IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const BenefitLinkSelector = ({onChange, benefits, defaultBenefit, linkProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [benefit, setBenefit] = useState(null);

    const link = <span onClick={() => setShow(true)}
                       className={classNames('fs--1 cursor-pointer', {
                           'text-success': benefit?.type === ZERO_VAT_PRIVILEGE_TYPE,
                           'text-primary': benefit?.type === WITHOUT_VAT_PRIVILEGE_TYPE
                       })}
                       {...linkProps}
    >
        {benefit ? `${benefit.new_id || benefit.id}` : t(lang, 'items.common.click_to_select_benefit')}
    </span>

    const onChangeHandler = (option) => {
        setBenefit(option);
        onChange(option);
        option && setShow(false);
    }

    useEffect(() => {
        if (defaultBenefit?.new_id !== benefit?.new_id || defaultBenefit?.id !== benefit?.id)
            setBenefit(defaultBenefit);
    }, [defaultBenefit, benefits])

    const formatOptionLabel = (option) => {
        return (
            <div className="fs--1">
                <h5 className="fs--1 fw-bold">
                    {option.type === 1 &&
                        <span className="text-success">{t(lang, "edi.common.privilege.type.1")}</span>}
                    {option.type === 2 &&
                        <span className="text-primary">{t(lang, "edi.common.privilege.type.2")}</span>}
                </h5>
                <p>
                    <span className="text-success fw-bold me-1">{option.new_id};</span>
                    <span className="text-warning fw-bold me-1">{option.id}</span>-
                    <span className="ms-1 text-facebook">{option.name}</span>
                </p>
            </div>
        )
    };

    return (
        <>
            {benefit && <OverlayTrigger overlay={<Tooltip>{benefit.name}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!benefit && link}
            <Modal show={show}
                   onHide={() => setShow(false)}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header>
                    <Modal.Title>{t(lang, 'items.common.benefit_link_selector_modal.title')}</Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'items.common.benefit')}</Form.Label>
                                <ReactSelect options={benefits}
                                             classNamePrefix="select"
                                             isClearable
                                             hideSelectedOptions
                                             defaultMenuIsOpen={!benefit}
                                             formatOptionLabel={formatOptionLabel}
                                             getOptionLabel={option =>  option.name}
                                             getOptionValue={option => option.new_id || option.id}
                                             placeholder={t(lang, "items.common.select")}
                                             value={null}
                                             onChange={onChangeHandler}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {benefit &&
                        <>
                            <Divider />
                            <Row>
                                <Col xs={12}>
                                    <h5 className="text-info">{t(lang, 'items.common.benefit_link_selector.chosen_benefit')}</h5>
                                    <div className="mt-3 fw-bold fs--1">
                                        <span className="text-primary">{benefit.new_id || benefit.id}</span> |
                                        <span className="text-dark">{benefit.name}</span>
                                        <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon={faTrash}
                                            iconClassName={"text-danger"}
                                            className="ms-2"
                                            title={t(lang, 'items.common.delete')}
                                            onClick={() => onChangeHandler(null)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BenefitLinkSelector
