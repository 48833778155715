import React, {useEffect, useState} from "react";
import {loadOrderAsync, setEdiBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import {useHistory, useRouteMatch} from "react-router-dom";
import Order from "../../../../components/hippo/order/Order";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CUSTOMER_APPROVED_ORDER,
    EXECUTOR_APPROVED_ORDER,
    EXECUTOR_CANCEL_DECLINE_SUCCESS,
    EXECUTOR_DECLINE_ORDER_SUCCESS,
    IDENTIFY_ORDER_ITEMS_SUCCESS,
    RESUME_ORDER_SUCCESS,
    STOP_ORDER_SUCCESS
} from "../../../../app/eventbus/orderEvents";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {getOrderProtocolInfoAsync} from "../../../../app/store/reducers/protocol/protocolReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiOrderView} from "../../../../enum/Permission/EdiPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const ViewOrder = () => {
    const {params: {id}} = useRouteMatch();
    const [order, setOrder] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const history = useHistory()
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const permission = useSelector(selectAccountRolePermissions)
    const t = Translate;

    useEffect(()  => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiOrderView))) {
            if (history.length > 1) {
                history.push('../orders')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    const loadOrder = () => {
        loadOrderAsync(id)
            .then(loadedOrder => {
                dispatch(checkPermission(PermissionEdiOrderView)) && dispatch(setEdiBreadcrumbData(loadedOrder))
                setOrder(loadedOrder)
            })
    };

    useEffect(() => {
        loadOrder();
    }, [id, activeOrganization]);


    useEffect(() => {
        const onExecutorApprovedOrderHandler = EventBus.on(EXECUTOR_APPROVED_ORDER, order => {
            toast.success(t(lang, 'edi.toast.order_executor_approve_succeed'))
            dispatch(getOrderProtocolInfoAsync(order.id))
            loadOrder();
        });

        const onCustomerApprovedOrderHandler = EventBus.on(CUSTOMER_APPROVED_ORDER, order => {
            toast.success(t(lang, 'edi.toast.order_customer_approve_succeed'))
            dispatch(getOrderProtocolInfoAsync(order.id))
            loadOrder();
        });

        const onDeclineOrderHandler = EventBus.on(EXECUTOR_DECLINE_ORDER_SUCCESS, order => {
            dispatch(getOrderProtocolInfoAsync(order.id))
            loadOrder();
        });

        const onCancelDeclineOrderHandler = EventBus.on(EXECUTOR_CANCEL_DECLINE_SUCCESS, order => {
            dispatch(getOrderProtocolInfoAsync(order.id))
            loadOrder();
        });

        const onStopOrderHandler = EventBus.on(STOP_ORDER_SUCCESS, order => {
            dispatch(getOrderProtocolInfoAsync(order.id))
            loadOrder();
        });

        const onResumeOrderHandler = EventBus.on(RESUME_ORDER_SUCCESS, order => {
            dispatch(getOrderProtocolInfoAsync(order.id))
            loadOrder();
        });

        const onIdentifyOrderItemHandler = EventBus.on(IDENTIFY_ORDER_ITEMS_SUCCESS, order => {
            dispatch(getOrderProtocolInfoAsync(order.id))
            loadOrder();
        });

        return () => {
            EventBus.remove(EXECUTOR_APPROVED_ORDER, onExecutorApprovedOrderHandler);
            EventBus.remove(CUSTOMER_APPROVED_ORDER, onCustomerApprovedOrderHandler);
            EventBus.remove(EXECUTOR_DECLINE_ORDER_SUCCESS, onDeclineOrderHandler);
            EventBus.remove(EXECUTOR_CANCEL_DECLINE_SUCCESS, onCancelDeclineOrderHandler);
            EventBus.remove(STOP_ORDER_SUCCESS, onStopOrderHandler);
            EventBus.remove(RESUME_ORDER_SUCCESS, onResumeOrderHandler);
            EventBus.remove(IDENTIFY_ORDER_ITEMS_SUCCESS, onIdentifyOrderItemHandler);
        }
    }, [])




    return (
        <>
            {order && <Order order={order}/>}
            {!order && <span>loading....</span>}
        </>
    )
};

export default ViewOrder;
