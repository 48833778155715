import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useState} from "react";
import IconButton from "../../../common/IconButton";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import {useForm} from "react-hook-form";
import {
    selectWarehouses, updateOneWarehouseAsync, registerWarehouseAsync
} from "../../../../app/store/reducers/warehouse/warehouseReducer";


const RegisterAndEditWarehouseButton = ({id, title, icon, variant, ...props}) => {

    const lang = useSelector(selectLang);
    const warehouses = useSelector(selectWarehouses)
    const t = Translate;

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const {formState: {errors}, register, handleSubmit, reset, setValue, watch} = useForm({
        defaultValues: {
            name: ""
        }
    });

    const handleShow = async () => {
        setShow(true)
        if (id) {
            warehouses.forEach(item => {
                if(item.id === id) {
                    setValue('name', item.name)
                }
            })
        }
    };

    const handleClose = () => {
        setShow(false)
        setLoading(false)
        reset()
    };

    const onRegisterAndEditWarehouse = (formData) => {
        setLoading(true)
        if (id) {
            updateOneWarehouseAsync({id, name: formData})
        } else {
            registerWarehouseAsync({name: formData})
        }
        setLoading(false)
        setShow(false)
    }


    return(
        <>
            <IconButton
                variant={variant}
                icon={icon}
                title={title}
                className="mx-2"
                size="sm"
                onClick={handleShow}
                {...props}
            />

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onRegisterAndEditWarehouse)}>
                    <Modal.Header>
                        <Modal.Title>
                            {title}
                        </Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Label>
                            {t(lang, "warehouse.modal.input.name")}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t(lang, "warehouse.modal.input.name")}
                            isInvalid={errors?.name}
                            {...register('name', {
                                required: t(lang, "items.common.validations.is_required")
                            })}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.name?.message}
                        </Form.Control.Feedback>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Назад
                        </Button>
                        <Button variant={variant} type="submit" disabled={loading}>
                            {loading &&
                                <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                            }
                            {title}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}


export default RegisterAndEditWarehouseButton;