import IconButton from "../../common/IconButton";
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Col, Form, Row, Collapse, Nav, Tabs, Tab} from 'react-bootstrap';
import {Link} from "react-router-dom";
import OrderDataTableFilter from "./forms/OrderDataTableFilter";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import {
    changeFilterOrdersAsync, changeOrderDataTableColumn,
    selectFilterOptions, selectOrdersDataTable,
} from "../../../app/store/reducers/order/orderReducer";
import {useForm, FormProvider} from "react-hook-form";
import UploadOrderListToExcel from "./excel/UploadOrderListToExcel";
import MassOrderAccept from "./MassOrderAccept";
import DataTableSettings from "../DataTableSettings";
import ExecutorDecline from "./ExecutorDecline";
import checkPermission from "../../../enum/Permission/CheckPermission";
import {
    PermissionEdiInvoiceView,
    PermissionEdiOrderCreate,
    PermissionEdiOrderDownloadExcel
} from "../../../enum/Permission/EdiPermission";
import toastPermission from "../../../enum/Permission/ToastPermission";
import orderWrapper from "../../../enum/OrderStatus";
import BulkStopOrder from "./BulkStopOrder";
import {ReturnOrderStatuses} from "../../../enum/ReturnOrderStatus";
import classNames from "classnames";
import EdiViewerComponent from "../../EdiViewerComponent";
import {useParams} from "react-router";

const OrderDataTableHeader = ({selectedRowIds, page, loadOrders, ...props}) => {
    const {viewer} = useParams()
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const filter = useSelector(selectFilterOptions);
    const allColumns = useSelector(selectOrdersDataTable)
    const methods = useForm({
        defaultValues: {
            branch_id: filter?.branch_id || null,
            date_end: filter?.date_end && dayjs(filter?.date_end).toDate() || null,
            date_start: filter?.date_start && dayjs(filter?.date_start).toDate() || null,
            is_executor_approved: typeof filter?.is_executor_approved === 'boolean' ? filter?.is_executor_approved : null,
            order_number: filter?.order_number || null,
            status: filter?.status || null,

            customer_inn: filter?.customer_inn || null,
            executor_inn: filter?.executor_inn || null,
            customer_employee_code: filter?.customer_employee_code || null
        }
    });
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true);


    const onSubmitFilter = ({date_start, date_end, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD")
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD")
        dispatch(changeFilterOrdersAsync({
            date_start: date_start,
            date_end: date_end,
            page: 1,
            ...filters
        }))
    };
    const selectedRowIndexArray = Object.keys(selectedRowIds);
    const selectedOrders = selectedRowIndexArray.reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, []);

    const canStopOrder = orderWrapper.isStopOrderAllowed(filter?.status);

    return (
        <Row className="flex-between-center">
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                    <Collapse in={isOpenFilterCollapse}>
                        <Col sm={12}>
                            <OrderDataTableFilter/>
                            <hr className={'my-0'}/>
                        </Col>
                    </Collapse>
                </Form>
            </FormProvider>

            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <EdiViewerComponent to={'/edi/orders'} typeViewer={viewer} title={"edi.order.datatable.header.title"} />
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div>
                        <ExecutorDecline orders={selectedOrders}
                                         loadOrders={loadOrders}
                        />
                        <MassOrderAccept rowValues={page}/>
                        {canStopOrder && <BulkStopOrder orders={selectedOrders}
                                                        loadOrders={loadOrders}/>
                        }
                        <UploadOrderListToExcel selectable
                                                rowValues={page}
                                                className="d-inline-block m-1"
                        />
                    </div>
                ) : (
                    <div id="orders-actions">
                        {
                            <IconButton
                                onClick={() => !dispatch(checkPermission(PermissionEdiOrderCreate)) && dispatch(toastPermission())}
                                as={dispatch(checkPermission(PermissionEdiOrderCreate)) ? Link : 'div'}
                                to="../order/add"
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-3">
                                <span
                                    className="d-none d-sm-inline-block ms-1">{t(lang, "edi.order.datatable.header.navigation.create")}</span>
                            </IconButton>
                        }

                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            transform="shrink-3"
                            className="mx-2"
                            icon="refresh"
                            onClick={methods.handleSubmit(onSubmitFilter)}
                        >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "edi.orders.order.resume_order_button")}</span>
                        </IconButton>

                        {<UploadOrderListToExcel className="d-inline-block me-2"/>}

                        <IconButton
                            onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                            variant="falcon-default"
                            size="sm"
                            icon="filter"
                            transform="shrink-3"
                        >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "edi.order.datatable.header.navigation.filter")}</span>
                        </IconButton>
                    </div>
                )}
            </Col>
        </Row>
    );
};

OrderDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default OrderDataTableHeader;
