import EImzoSignerDataTable from "./EImzoSignerDataTable";

const Organizations = () => {
    return (
        <>
            <EImzoSignerDataTable/>
        </>
    );
};

export default Organizations;
