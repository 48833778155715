import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import EventBus from "../../../../app/eventbus/EventBus";
import SendFreeFormDocumentForm from "../../../../components/roaming/freeform/form/SendFreeFormDocumentForm";
import {SEND_FREE_FORM_DOCUMENT_SUCCEED} from '../../../../app/eventbus/roaming/roamingFreeFormDocumentEvents';

const SendFreeFormDocument = () => {
    const routerHistory = useHistory();

    useEffect(() => {
        const onSendFreeFormDocumentSucceedHandler = EventBus.on(SEND_FREE_FORM_DOCUMENT_SUCCEED, freeFormDocument => {
            routerHistory.push(`/roaming/free-form/${freeFormDocument.id}`);
        });

        return () => {
            EventBus.remove(SEND_FREE_FORM_DOCUMENT_SUCCEED, onSendFreeFormDocumentSucceedHandler);
        }
    }, [])

    return (
        <>
            <SendFreeFormDocumentForm/>
        </>
    );
};

export default SendFreeFormDocument;