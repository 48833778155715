import React, {Fragment} from 'react';
import CrmExecutorOrderDataTableFilter from "./CrmExecutorOrderDataTableFilter";
import {FormProvider, useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {changeFilterOrdersAsync, selectFilterOptions} from "../../../../../app/store/reducers/crm/order/crmExecutorOrderReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectAllContractor} from "../../../../../app/store/reducers/contractor/contractorReducer";

const CrmExecutorOrderDataTableHeader = () => {
    const dispatch = useDispatch()
    const contractors = useSelector(selectAllContractor)
    const filterOptions = useSelector(selectFilterOptions)

    const methods = useForm({
        defaultValues: {
            customer: contractors.find(contractor=>contractor.id === filterOptions?.customer_contractor_id) || null,
            number: filterOptions?.number || '',
        }
    })

    const onSubmitFilter = ({number, customer}) => {
        dispatch(changeFilterOrdersAsync({
            number: number || null,
            customer_contractor_id: customer?.id
        }))
    }

    return (
        <Fragment>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                    <Row className={'mt-3'}>
                        <Col sm={12}>
                            <CrmExecutorOrderDataTableFilter/>
                            <hr/>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </Fragment>
    );
};

export default CrmExecutorOrderDataTableHeader;