import {
    WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/refundEvents";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getRefundItemAsync} from "../../../../app/store/reducers/warehouse-operation/refundReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import Refund from "../../../../components/hippo/warehouse-operation/refund/Refund";
import {loadContractorAsync, updateCardContractor} from "../../../../app/store/reducers/contractor/contractorReducer";


const ViewRefund = ({viewId}) => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [refund, setRefund] = useState(null)
    const dispatch = useDispatch()
    const {id} = useParams()


    const loadRefund = () => {
        getRefundItemAsync({refundId: viewId || id})
            .then(response => {
                setRefund(response.data, response?.data?.items?.reverse())
                loadContractorAsync(response?.data?.contractor?.id)
                    .then(cr => {
                        dispatch(updateCardContractor(cr))
                    }).catch(err => console.log(err));
            })
            .catch(error => setRefund(null))
    }

    useEffect(() => {
        loadRefund()
    },[activeOrganization, id, viewId])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, () => {
            loadRefund()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, () => {
            loadRefund()
        })

        const onRegisterPayoutHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS, () => {
            loadRefund()
        })

        const onUpdatePayoutHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS, () => {
            loadRefund()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS, onRegisterPayoutHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS, onUpdatePayoutHandler)
        }
    },[])


    if (!refund) return <> loading... </>



    return(
        <>
            <Refund refund={refund}/>
        </>
    )
}


export default ViewRefund;
