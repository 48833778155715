import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    ADD_CONTRACTOR_NOTE_SUCCESS,
    ADD_CONTRACTOR_NOTE_FAILED,
    ADD_CONTRACTOR_NOTE_REQUESTED,

    EDIT_CONTRACTOR_NOTE_SUCCESS,
    EDIT_CONTRACTOR_NOTE_FAILED,
    EDIT_CONTRACTOR_NOTE_REQUESTED,

    DELETE_CONTRACTOR_NOTE_SUCCESS,
    DELETE_CONTRACTOR_NOTE_FAILED,
    DELETE_CONTRACTOR_NOTE_REQUESTED,
} from "../../../eventbus/contractor/contractorEvents";

export const contractorNoteSlice = createSlice({
    name: 'contractorNote',
    initialState: {
        notes: [],
        countNotes: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateNotes: (state, action) => {
            state.notes = action.payload
        },
        updateCountNotes: (state, action) => {
            state.countNotes = action.payload
        },
        updateFilterNote: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorNotesAsync = ({id, page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorNotes(id, params)
        .then(response => {
            dispatch(contractorNoteSlice.actions.updateNotes(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const registerContractorNoteAsync = (payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(ADD_CONTRACTOR_NOTE_REQUESTED, payload);
        contractorService.registerContractorNote(payload)
            .then(response => {
                EventBus.dispatch(ADD_CONTRACTOR_NOTE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_CONTRACTOR_NOTE_FAILED, error)
                reject(error);
            })
    });
}
export const editContractorNoteAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(EDIT_CONTRACTOR_NOTE_REQUESTED, payload);
        contractorService.editContractorNote(id, payload)
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_NOTE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_NOTE_FAILED, error)
                reject(error);
            })
    });
}
export const deleteNoteAsync = ({id}) => {
    EventBus.dispatch(DELETE_CONTRACTOR_NOTE_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        contractorService.deleteContractorNote(id)
            .then((response) => {
                EventBus.dispatch(DELETE_CONTRACTOR_NOTE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_CONTRACTOR_NOTE_FAILED, error.message);
                reject(error)
            })
    });
};

export const changeFilterContractorNotesAsync = (params) => (dispatch) => {
    return dispatch(contractorNoteSlice.actions.updateFilterNote(params))
}

export const selectContractorNotes = (state) => state.contractorNote.notes;
export const selectContractorNotesCount = (state) => state.contractorNote.countNotes;
export const selectContractorNoteFilterOptions = (state) => state.contractorNote.filterOptions;
export default contractorNoteSlice.reducer
