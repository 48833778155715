import React, {Fragment, useEffect, useState} from 'react';
import {Col, Form, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectAllContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import AccountSelect from "../../../common/AccountSelect";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    accountBindingRegister,
    accountBindingUnregister, getAccountBinding
} from "../../../../app/store/reducers/crm/bind-contractor-to-account/bindContractorToAccountReducer";
import {toast} from "react-toastify";
import BindContractorFormBody from "./BindContractorFormBody";
import {
    cashboxAccountBindingRegister,
    cashboxAccountBindingUnregister, getCashboxAccountBinding,
    selectCashbox
} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {BRANCH, CASHBOX, CONTRACTOR} from "../../user/settings/Settings";
import {
    branchAccountBindingRegister,
    branchAccountBindingUnregister,
    selectAccountBranch, selectBranches
} from "../../../../app/store/reducers/branch/branchReducer";

const BindContractorToAccountForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [filterValue, setFilterValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [key, setKey] = useState(CONTRACTOR)
    const contractors = useSelector(selectAllContractor)
    const cashbox = useSelector(selectCashbox)
    const branches = useSelector(selectBranches)

    const {control, formState: {errors}, setError, clearErrors, setValue, watch} = useFormContext();
    const {fields: contractorFields, append: contractorAppend, remove: contractorRemove} = useFieldArray({
        control,
        name: "contractor_id"
    })

    const {fields: cashboxFields, append: cashboxAppend, remove: cashboxRemove} = useFieldArray({
        control,
        name: "cashbox_id"
    })

    const {fields: branchFields, append: branchAppend, remove: branchRemove} = useFieldArray({
        control,
        name: "branch_id"
    })

    const account = watch('account_id')

    useEffect(() => {
        if (account) {
            setLoading(true)
            getAccountBinding(account.account_id)
                .then(res => {
                    setValue('contractor_id', contractors.filter(contractor => res.some(item => item.contractor_id === contractor.id)).map(item => {
                        return {...item, item_id: item.id}
                    }))
                })
                .catch(() => setValue('contractor_id', []))
                .finally(() => setLoading(false))

            getCashboxAccountBinding(account.account_id)
                .then(res => {
                    setValue('cashbox_id', cashbox.filter(cashbox => res.cash_boxes.some(item => item.id === cashbox.id)).map(item => {
                        return {...item, item_id: item.id}
                    }))
                })
                .catch(() => setValue('cashbox_id', []))
                .finally(() => setLoading(false))
            clearErrors('account_id')
        } else {
            setValue('contractor_id', [])
        }
    }, [account])

    // Account
    const registerContractor = (item) => {
        if (!account) {
            return setError("account_id", {
                type: "required",
                message: t(lang, "crm.common.forms.validations.is_required")
            })
        }
        accountBindingRegister({
            account_id: account.account_id,
            contractor_id: item.id
        }).then(() => {
            contractorAppend({...item, item_id: item.id})
            toast.success(t(lang, "crm.common.toast.success"))
        }).catch(() => toast.error(t(lang, "crm.common.toast.error")))
    }

    const unregisterContractor = (item, index) => {
        accountBindingUnregister({
            account_id: account.account_id,
            contractor_id: item.item_id
        }).then(() => {
            contractorRemove(index)
            toast.success(t(lang, "crm.common.toast.success"))
        }).catch(() => toast.error(t(lang, "crm.common.toast.error")))
    }

    // Cashbox
    const registerCashbox = (item) => {
        if (!account) {
            return setError("account_id", {
                type: "required",
                message: t(lang, "crm.common.forms.validations.is_required")
            })
        }
        cashboxAccountBindingRegister({
            account_id: account.account_id,
            cashbox_id: item.id,
        }).then(() => {
            cashboxAppend({...item, item_id: item.id})
            toast.success(t(lang, "crm.common.toast.success"))
        }).catch(() => toast.error(t(lang, "crm.common.toast.error")))
    }

    const unregisterCashbox = (item, index) => {
        cashboxAccountBindingUnregister({
            account_id: account.account_id,
            cashbox_id: item.item_id,
        }).then(() => {
            cashboxRemove(index)
            toast.success(t(lang, "crm.common.toast.success"))
        }).catch(() => toast.error(t(lang, "crm.common.toast.error")))
    }

    //branch
    const registerBranch = (item) => {
        if (!account) {
            return setError("account_id", {
                type: "required",
                message: t(lang, "crm.common.forms.validations.is_required")
            })
        }
        const params = {
            account_id: account.account_id,
            branch_id: item.id,
        }
        branchAccountBindingRegister(params)
            .then(() => {
                branchAppend({...item, item_id: item.id})
                toast.success(t(lang, "crm.common.toast.success"))
            })
            .catch(() => toast.error(t(lang, "crm.common.toast.error")))
    }

    const unregisterBranch = (item, index) => {
        const params = {
            account_id: account.account_id,
            branch_id: item.id,
        }
        branchAccountBindingUnregister(params)
            .then(() => {
                branchRemove(index)
                toast.success(t(lang, "crm.common.toast.success"))
            })
            .catch(() => toast.error(t(lang, "crm.common.toast.error")))
    }

    return (
        <Fragment>
            <Row className={'mb-2 gap-2'}>
                <Form.Group as={Col} className={'pe-0'}>
                    <Form.Label>{t(lang, "crm.contractor.view.tab.act_report.account")}</Form.Label>
                    <Controller
                        name={'account_id'}
                        render={({field}) => (
                            <AccountSelect
                                defaultValue={field.value}
                                onChange={field.onChange}
                                account={account}
                            />
                        )}/>
                    <Form.Control.Feedback className={'d-block'} type="invalid">
                        {errors?.account_id?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className={'ps-0'}>
                    <Form.Label>{t(lang, 'warehouse.operation.item.common.filter')}</Form.Label>
                    <Form.Control onChange={(e) => setFilterValue(e.target.value)} type="text"
                                  placeholder={t(lang, 'items.common.datatable.filter.name.placeholder')}/>
                </Form.Group>
            </Row>
            {
                loading ? <div style={{height: 300}} className={'position-relative'}>
                        <Spinner className={'position-absolute'}
                                 variant={'primary'}
                                 style={{top: '50%', left: '50%'}}
                                 animation="border" role="status"/>
                    </div>
                    :
                    <Fragment>
                        <Tabs fill defaultActiveKey={key} onSelect={(key) => {
                            setKey(key)
                        }}>
                            <Tab title={t(lang, "crm.data.table.contractor")} eventKey={CONTRACTOR}>
                                <BindContractorFormBody data={contractors}
                                                        fields={contractorFields}
                                                        register={registerContractor}
                                                        unregister={unregisterContractor}
                                                        filterValue={filterValue}
                                                        label={"crm.bind.contractor.account.contractors"}
                                                        bindLabel={"crm.bind.contractor.account.bind.contractors"}
                                />
                            </Tab>
                            <Tab title={t(lang, "cashbox.bind.account.title")} eventKey={CASHBOX}>
                                <BindContractorFormBody data={cashbox}
                                                        fields={cashboxFields}
                                                        register={registerCashbox}
                                                        unregister={unregisterCashbox}
                                                        filterValue={filterValue}
                                                        label={"cashboxes.bind.account.label"}
                                                        bindLabel={"cashboxes.bind.account.bind.label"}
                                />
                            </Tab>
                            <Tab title={t(lang, "edi.dashboard.branches.title")} eventKey={BRANCH}>
                                <BindContractorFormBody data={branches}
                                                        fields={branchFields}
                                                        register={registerBranch}
                                                        unregister={unregisterBranch}
                                                        filterValue={filterValue}
                                                        label={"edi.dashboard.branches.title"}
                                                        bindLabel={"edi.dashboard.bind.branches.title"}
                                />
                            </Tab>
                        </Tabs>
                    </Fragment>
            }
        </Fragment>
    );
};

export default BindContractorToAccountForm;
