import {Controller, useFormContext, useWatch} from "react-hook-form";
import SelectCompanyCatalog from "../../IKPU/SelectCompanyCatalog";
import ReactSelect from "react-select";
import SelectMeasurement from "../../roaming/SelectMeasurement";
import numeral from "numeral";
import {Button, Form} from "react-bootstrap";
import {
    updateItemContractFormationAsync
} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";
import {useSelector} from "react-redux";
import {selectMeasurements} from "../../../../app/store/reducers/roaming/roamingReducer";
import {
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../app/store/reducers/main/mainReducer";
import  {useEffect, useRef, useState} from "react";


const ContractFormationViewItemUpdate = ({item, index, contractFormation, showTag}) => {
    const {setValue, control, getValues, formState: {errors: productsError}} = useFormContext()
    const numberFormat = useSelector(selectNumberFormat);
    const measurements = useSelector(selectMeasurements)
    const catalogs = useWatch({name: `products.${index}.catalogs`})
    const lang = useSelector(selectLang);
    const t = Translate;

    const errors = productsError.products || []
    const selectRef = useRef()

    const [error, setError] = useState({
        catalog_code: false,
        packages: false
    })

    useEffect(() => {
        setError({...error, packages: false, catalog_code: false})
    }, [showTag])

    const getMeasure = id => {
        return measurements.find(x => x.measureId === id) || null
    }
    const ContractFormationUpdateItemHandler = async (e, index) => {
        const product = getValues(`products.${index}`)
        setValue(`products.${index}.item_id`, item.id)

        await updateItemContractFormationAsync({id: contractFormation?.id, updateItem: {
                item_id: product?.item_id,
                catalog_code: product?.catalog_code,
                catalog_name: product?.catalog_name,
                measurement_id: product?.measurement_id,
                measurement_name: product?.measurement_name,
                package_code: product?.package_code ? `${product?.package_code}` : null,
                package_name: product?.package_name || null,
            }
        })
    }
    const onChangeCatalog = (catalog, index) => {
        if (!catalog) {
            error.catalog_code = true
            setValue(`products.${index}.packages`, null)
            setValue(`products.${index}.catalogs`, null)
        } else {
            error.catalog_code = false
            setValue(`products.${index}.catalog_code`, catalog?.class_code || null)
            setValue(`products.${index}.catalog_name`, catalog?.name || null)
            setValue(`products.${index}.catalogs`, catalog)
            setValue(`products.${index}.packages`, null)
        }
        setError({...error})
        selectRef.current?.clearValue()

    }


    const onChangeMeasurement = (option, index) => {
        if (!option) {
            setError({...error, packages: true})
            setValue(`products.${index}.packages`, option)
        } else if (option?.measureId) {
            setError({...error, packages: false})
            setValue(`products.${index}.measurement_id`, option?.measureId)
            setValue(`products.${index}.measurement_name`, option?.name)
            setValue(`products.${index}.package_code`, null)
            setValue(`products.${index}.package_name`, null)
        } else {
            setError({...error, packages: false})
            const name = lang === "ru" ? option?.name_ru : option?.name_uz
            setValue(`products.${index}.package_code`, option?.code)
            setValue(`products.${index}.package_name`, name)
            setValue(`products.${index}.measurement_id`, "")
            setValue(`products.${index}.measurement_name`, "")
            setValue(`products.${index}.packages`, option)
        }
    }


    return (
        <>
            <td className="align-middle">{index + 1}</td>
            <td className="align-middle">{item?.name}</td>
            {showTag ?
                <td className="align-middle">
                    {item?.catalog_code} - {item?.catalog_name}
                </td>
                :
                <td className="align-middle">
                    <Controller
                        rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                        name={`products.${index}.catalog_code`}
                        render={({field}) => (
                            <SelectCompanyCatalog
                                textButton={field.value || "Выбрать"}
                                defaultCatalogCode={field?.value}
                                onChange={(e) => onChangeCatalog(e, index)}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid" style={{display: "block"}}>
                        {error.catalog_code && t(lang, "edi.common.forms.validations.is_required") || errors[index]?.catalog_code?.message}
                    </Form.Control.Feedback>
                </td>
            }
            <td className="align-middle">{item?.barcode}</td>
            {showTag ?
                <td className="align-middle">
                    {item?.measurement ? getMeasure(item.measurement)?.name : item?.package_name}
                </td>
                :
                <td className="align-middle" style={{minWidth: "180px"}}>
                    {catalogs?.use_package ?
                        <>
                            <Controller
                                name={`products.${index}.packages`}
                                rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                control={control}
                                render={({field}) => (
                                    <ReactSelect
                                        options={catalogs?.package_names}
                                        placeholder={t(lang, "roaming.common.select.placeholder")}
                                        getOptionLabel={option => lang === "ru" ? option.name_ru : option.name_uz}
                                        getOptionValue={option => option.code}
                                        defaultValue={catalogs?.package_names.find(p => p.code === field.value?.code)}
                                        menuPortalTarget={document.body}
                                        hideSelectedOptions
                                        isClearable
                                        ref={selectRef}
                                        onChange={(e) => onChangeMeasurement(e, index)}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid" style={{display: "block"}}>
                                {error.packages && t(lang, "edi.common.forms.validations.is_required") || errors[index]?.packages?.message}
                            </Form.Control.Feedback>
                        </>
                        :
                        <>
                            <Controller
                                name={`products.${index}.measurement_id`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required")
                                }}
                                render={({field}) => (
                                    <SelectMeasurement
                                        onChange={(e) => onChangeMeasurement(e, index)}
                                        defaultMeasureId={field.value}
                                        classNamePrefix="react-select"
                                        isClearable
                                        menuPortalTarget={document.body}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid" style={{display: "block"}}>
                                {error.packages && t(lang, "edi.common.forms.validations.is_required") || errors[index]?.measurement_id?.message}
                            </Form.Control.Feedback>
                        </>
                    }
                </td>
            }
            <td className="align-middle text-nowrap">{item?.quantity}</td>
            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.price)}</td>
            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.total)}</td>
            <td className="align-middle text-nowrap">{item?.excise_rate}</td>
            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.excise_value || 0)}</td>
            <td className="align-middle text-nowrap">{item.without_vat ? 'Без НДС' : item?.nds_rate}</td>
            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.nds_value || 0)}</td>
            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.total_with_nds)}</td>
            {!showTag &&
                <td>
                    <Button variant="falcon-success" disabled={!catalogs || error.catalog_code || error.packages}
                            onClick={(e) => ContractFormationUpdateItemHandler(e, index)}>{t(lang, "edi.common.save_button")}</Button>
                </td>
            }
        </>
    )

}


export default ContractFormationViewItemUpdate;