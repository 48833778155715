export const REGISTER_TELEGRAM_ORDER_BOT_REQUESTED = "REGISTER_TELEGRAM_ORDER_BOT_REQUESTED";
export const REGISTER_TELEGRAM_ORDER_BOT_SUCCEED = "REGISTER_TELEGRAM_ORDER_BOT_SUCCEED";
export const REGISTER_TELEGRAM_ORDER_BOT_FAILED = "REGISTER_TELEGRAM_ORDER_BOT_FAILED";

export const CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_REQUESTED = "CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_REQUESTED";
export const CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_SUCCEED = "CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_SUCCEED";
export const CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_FAILED = "CONFIGURE_TELEGRAM_ORDER_BOT_CONFIG_FAILED";


export const BULK_DELETE_TELEGRAM_ORDER_BOTS_FINISHED = "BULK_DELETE_TELEGRAM_ORDER_BOTS_FINISHED";
export const DELETE_TELEGRAM_ORDER_BOT_SUCCEED = "DELETE_TELEGRAM_ORDER_BOT_SUCCEED";
export const BULK_DELETE_TELEGRAM_ORDER_BOT_ORDERS_FINISHED = "BULK_DELETE_TELEGRAM_ORDER_BOT_ORDERS_FINISHED";
export const DELETE_TELEGRAM_ORDER_BOT_ORDER_SUCCEED = "DELETE_TELEGRAM_ORDER_BOT_ORDER_SUCCEED";