import React from 'react';
import ReturnShipmentDetailsForm from "./ReturnShipmentDetailsForm";
import ReturnShipmentInfoForm from "./ReturnShipmentInfoForm";
import ReturnShipmentItemsForm from "./ReturnShipmentItemsForm";

const ReturnShipmentForm = ({order}) => {
    return (
        <>
            <ReturnShipmentInfoForm order={order} />
            <ReturnShipmentDetailsForm order={order} />
            <ReturnShipmentItemsForm order={order} />
        </>
    );
};

export default ReturnShipmentForm;