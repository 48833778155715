import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import AddContractorPayment from "./AddContractorPayment";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import {
    changeFilterOptionsAsync,
    selectFilterOptionsContractorPayments, selectLoading
} from "../../../../../app/store/reducers/contractor/contractorPaymentReducer";
import SelectContractor from "../SelectContractor";
import {ContractorPaymentSources} from "../../../../../enum/ContractorPaymentSources";
import Select from "react-select";

const ContractorPaymentDataTableHeader = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const paymentFilters = useSelector(selectFilterOptionsContractorPayments);
    const dispatch = useDispatch();
    const dateFormat = useSelector(selectDateFormat);
    const isLoading = useSelector(selectLoading)

    const methods = useForm({
        defaultValues: {
            contractor: paymentFilters.contractor_id || null,
            date_start: paymentFilters.date_start ? dayjs(paymentFilters.date_start).toDate() : null,
            date_end: paymentFilters.date_end ? dayjs(paymentFilters.date_end).toDate() : null,
            source: ContractorPaymentSources.find(source=>source.value === paymentFilters?.payment_source) || null
        },
    });
    const onSubmitFilter = ({date_end, date_start, contractor, source, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD");
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD");
        const contractor_id = contractor?.id
        const payment_source = source?.value

        dispatch(changeFilterOptionsAsync({
            page: 1,
            date_start,
            date_end,
            contractor_id,
            payment_source,
            ...filters
        }))
    }
    return (
        <>
            <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                <Row className={'mb-1'}>
                    <Col className="d-flex justify-content-between">
                        <h5 className="text-nowrap">{t(lang, "Платежи от контрагента")}</h5>
                        <div>
                            <AddContractorPayment/>
                        </div>
                    </Col>
                </Row>
                <Row className={'mb-1'}>
                    <Form.Group as={Col} md={3} xs={12}>
                        <Form.Label>Контрагент</Form.Label>
                        <Controller
                            control={methods.control}
                            name="contractor"
                            render={({field}) => (
                                <SelectContractor
                                    placeholder={t(lang, "edi.common.button.select")}
                                    classNamePrefix="react-select"
                                    onChange={field.onChange}
                                    defaultValue={field?.value}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={3} xs={12}>
                        <Form.Label>Тип</Form.Label>
                        <Controller name={'source'}
                                    className={''}
                                    control={methods.control}
                                    render={({field}) => {
                                        return (
                                            <Select
                                                options={ContractorPaymentSources}
                                                value={field.value}
                                                onChange={field.onChange}
                                                getOptionValue={option => option.value}
                                                getOptionLabel={option => t(lang, option.label)}
                                                placeholder={t(lang, "edi.common.button.select")}
                                                classNamePrefix={'react-select'}
                                                isClearable
                                                hideSelectedOptions/>
                                        )
                                    }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={3} xs={12}>
                        <Form.Label>Дата оплата (начало)</Form.Label>
                        <Controller
                            control={methods.control}
                            name="date_start"
                            render={({field}) => (
                                <DatePicker
                                    isClearable
                                    className='form-control'
                                    placeholderText={"Дата оплата (начало)"}
                                    dateFormat={dateFormat}
                                    selected={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={3} xs={12}>
                        <Form.Label>Дата оплата (конец)</Form.Label>
                        <Controller
                            control={methods.control}
                            name="date_end"
                            render={({field}) => (
                                <DatePicker
                                    isClearable
                                    className='form-control'
                                    placeholderText={"Дата оплата (конец)"}
                                    dateFormat={dateFormat}
                                    selected={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={12} className={'text-end'}>
                        <Button variant="primary"
                                type='submit'
                                size={'xs'}
                                disabled={isLoading}
                                className={"ms-2 mt-4"}>
                            {t(lang, "edi.invoice.datatable.header.filter.show")}
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
        </>
    );
};

ContractorPaymentDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ContractorPaymentDataTableHeader;
