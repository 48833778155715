import { useParams } from "react-router"
import ContractDataTable from "../../../../components/hippo/roaming/contract/ContractDataTable"
import {useDispatch, useSelector} from "react-redux";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingContractView} from "../../../../enum/Permission/RoamingPermission";
import {Card} from "react-bootstrap";
import React from "react";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";

const Contracts = () => {
  const { type_viewer } = useParams()
  const dispatch = useDispatch()
  const t = Translate;
  const lang = useSelector(selectLang);
  //important
  useSelector(selectAccountRolePermissions)

  if(!dispatch(checkPermission(PermissionRoamingContractView))) {
    return <Card className="text-center p-3">
      <h4>{t(lang, 'system.permission.roaming.contracts.datatable.no_access.title')}</h4>
  </Card>
  }

  return <ContractDataTable typeViewer={type_viewer}/>

}

export default Contracts
