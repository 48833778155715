import React, {useState} from 'react';
import {Button, Dropdown} from "react-bootstrap";
import {useSelector} from "react-redux";
import {transliterate} from "transliteration";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {loadItemsAsync} from "../../../../app/store/reducers/item/itemReducer";
import {faFileExcel, faScaleUnbalanced, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {exportDataToExcel} from "../../../../helpers/xlsx";
import {Kilogram} from "../enum/itemWrapper";

const UploadItemsToScales = () => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const uploadHandler = async () => {
        setLoading(true);

        loadItemsAsync()
            .then(({data: items}) => {
                const itemsWithSku = items.filter(i => i.item.sku);

                const scalesFormattedItems = itemsWithSku.map(i => {
                    return {
                        Sku: i.item.sku.replace(/ +/g, ""),
                        Name: transliterate(i.item.name),
                        Category: i.item.category ? transliterate(i.item.category.name) : '',
                        Price: i.price.common_price.amount,
                        x1: 0,
                        x2: 0,
                        x3: 0,
                        Sku2: i.item.sku.replace(/ +/g, ""),
                        x4: 0,
                        x5: 0,
                        x6: 0,
                        x7: '01.01.01',
                        x8: 0 + ';',
                    };
                });

                let str = '';
                for (let i = 0; i < scalesFormattedItems.length; i++) {
                    let line = '';
                    for (let index in scalesFormattedItems[i]) {
                        if (line !== '') line += ';'

                        line += scalesFormattedItems[i][index];
                    }
                    str += line + '\r\n';
                }

                const link = document.createElement("a");
                const file = new Blob([str], {type: 'text/plain'});
                link.href = URL.createObjectURL(file);
                link.download = 'Shtrix-M.txt';
                link.click();
                URL.revokeObjectURL(link.href);

                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    }

    const generateExcelForRongta = () => {
        setLoading(true)

        loadItemsAsync()
            .then(({data: items}) => {
                const kiloItems = items.filter(i => i.item.measurement === +Kilogram);
                let res = kiloItems.map(kItem => {
                    return {
                        'Name': kItem.item.name,
                        'Item Sku 1': kItem.item.sku,
                        'Item Sku 2': kItem.item.sku,
                        'Class Number 1': '27',
                        'Price': kItem.price.common_price.amount,
                        'Empty': '',
                        'Class Number 2': '27'
                    }
                })
                exportDataToExcel(res, 'Rongta')
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    }

    const generateExcelForTMA = () => {
        setLoading(true)

        loadItemsAsync()
            .then(({data: items}) => {
                const kiloItems = items.filter(i => i.item.measurement === +Kilogram);
                let res = kiloItems.map(kItem => {
                    return {
                        'Name': kItem.item.name,
                        'Item Code': kItem.item.sku,
                        'Class Number': '27',
                        'Price': kItem.price.common_price.amount
                    }
                })
                exportDataToExcel(res, 'T-Ma')
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    }


    return (
        <Dropdown className="d-grid w-md-50 d-lg-inline-block w-lg-auto p-1 p-lg-0">
            <Dropdown.Toggle bsPrefix="toggle"
                             variant={'falcon-warning'}
                             size="sm"
                             disabled={loading}>
                {loading
                    ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                    : <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                }
                {t(lang, 'items.common.datatable.upload_items_to_scales.title')}
            </Dropdown.Toggle>
            <Dropdown.Menu className='py-2'>
                <Dropdown.Item as="button" onClick={uploadHandler}>
                    {t(lang, "items.common.datatable.upload_items_to_scales_shtrixm.title")}
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={generateExcelForRongta}>
                    {t(lang, "items.common.datatable.upload_items_to_scales_rongta.title")}
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={generateExcelForTMA}>
                    {t(lang, "items.common.datatable.upload_items_to_scales_tma.title")}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UploadItemsToScales;