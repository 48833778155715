import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {Col, Row} from "react-bootstrap";
import {Link, Redirect} from "react-router-dom";
import RegistrationWithoutInnForm from "../../components/hippo/authentication/RegistrationWithoutInnForm";
import EventBus from "../../app/eventbus/EventBus";
import {REGISTRATION_SUCCESS} from "../../app/eventbus/authEvents";
import {toast} from "react-toastify";
import {loginAsync, selectIsAuthorized} from "../../app/store/reducers/auth/authReducer";
import {useHistory} from "react-router";

const RegistrationWithoutInn = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const lang = useSelector(selectLang);
    const isAuthorized = useSelector(selectIsAuthorized);
    const t = Translate;

    useEffect(() => {
        const onRegisterSuccessHandler = EventBus.on(REGISTRATION_SUCCESS, ({username, password}) => {
            toast.success(t(lang, "crm.common.toast.success"));
            // toast.success(`Welcome to Hippo`);

            // dispatch(loginAsync(username, password));
            history.push('/authentication/login')
        });

        return () => {
            EventBus.remove(REGISTRATION_SUCCESS, onRegisterSuccessHandler);
        };
    }, []);

    if (isAuthorized)
        return <Redirect to="/"/>

    return (
       <Fragment>
           <Row className="align-items-center mb-2">
               <Col>
                   <h5>{t(lang, 'authorization.register.title')}</h5>
               </Col>
               <Col xs="auto">
                   <p className="fs--1 text-600 mb-0">
                       {t(lang, 'authorization.register.with.inn.title')} <Link className={'fw-bold'} to="../register">{t(lang, 'authorization.register.transition')}</Link>
                   </p>
               </Col>
           </Row>
           <RegistrationWithoutInnForm/>

       </Fragment>
    );
};

export default RegistrationWithoutInn;
