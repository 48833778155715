import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import SignFreeFormDocument from "../actions/SignFreeFormDocument";
import CancelFreeFormDocument from "../actions/CancelFreeFormDocument";
import AcceptFreeFormDocument from "../actions/AcceptFreeFormDocument";
import DeclineFreeFormDocument from "../actions/DeclineFreeFormDocument";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import DownloadFreeFormDocumentPdfFile from "../download/DownloadFreeFormDocumentPdfFile";
import DownloadFreeFormDocumentZipFile from "../download/DownloadFreeFormDocumentZipFile";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    AwaitSenderSign, getStatusHistoryBg, getStatusHistory, getStatusHistoryVariant,
    getStatusShowCustomerHistory, getStatusShowExecutorHistory,
    GetStatusText,
    GetStatusVariant,
    ReceiverAccept,
    SenderSent
} from "../../../../enum/roaming/FreeFormDocumentStatus";
import HistoryActionSeal from "../../../hippo/roaming/components/HistoryActionSeal";
import {loadFreeFormDocumentPdfFileAsync} from "../../../../app/store/reducers/roaming/roamingFreeFormDocumentReducer";

const FreeFormDocument = ({document}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const [viewPdf, setViewPdf] = useState('');

    const allowDownloadZipFile = document.status === ReceiverAccept;
    const allowSenderSign = activeOrganization.inn === document.sender.inn && document.status === AwaitSenderSign;
    const allowSenderCancel = activeOrganization.inn === document.sender.inn && document.status === SenderSent;
    const allowReceiverDecline = activeOrganization.inn === document.receiver.inn && document.status === SenderSent;
    const allowReceiverApprove = activeOrganization.inn === document.receiver.inn && document.status === SenderSent;


    const loadPdf = () => {
        loadFreeFormDocumentPdfFileAsync(document.id)
            .then(response => {
                const reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onload = e => {
                    const base64 = e.target.result.split(',')[1];
                    setViewPdf(base64)
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        loadPdf()
    },[document])


    return (
        <>
            <Card className="mb-3">
                <Card.Header className="border-bottom">
                    <Row>
                        <Col>
                            <h4>{t(lang, 'roaming.free_form_document.view.title')}: № {document.info.number}</h4>
                            <div className="fs--1">
                                <p className="mb-1">
                                    {t(lang, 'roaming.free_form_document.view.free_form.date')}:
                                    <strong>{dayjs(document.info.date, 'YYYY-MM-DD').format(dateFormat)}</strong>
                                </p>
                                <span className="me-2">{t(lang, 'roaming.free_form_document.view.status.label')}: </span>
                                <SoftBadge pill className="fs--2" bg={GetStatusVariant(document.status)}>
                                    {t(lang, GetStatusText(document.status))}
                                </SoftBadge>
                            </div>
                        </Col>
                        <Col xs="auto" className="ms-auto">
                            {allowSenderSign && <SignFreeFormDocument id={document.id} className="mx-1" />}
                            {allowSenderCancel && <CancelFreeFormDocument id={document.id} className="mx-1" />}
                            {allowReceiverApprove && <AcceptFreeFormDocument id={document.id} className="mx-1" />}
                            {allowReceiverDecline && <DeclineFreeFormDocument id={document.id} className="mx-1" />}
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12} lg={6} className="fs--1 mb-3 mb-lg-0">
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold fs-0 text-dark">{t(lang, 'roaming.free_form_document.view.sender.title')}</span>
                            </div>
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.free_form_document.view.sender.name')}:</span>
                                <span className="col mb-1 ps-0">{document.sender.name}</span>
                            </div>
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.free_form_document.view.sender.inn')}:</span>
                                <span className="col mb-1 ps-0">{document.sender.inn}</span>
                            </div>
                        </Col>

                        <Col xs={12} lg={6} className="fs--1 mb-3 mb-lg-0">
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold fs-0 text-dark">{t(lang, 'roaming.free_form_document.view.receiver.title')}</span>
                            </div>
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.free_form_document.view.receiver.name')}:</span>
                                <span className="col mb-1 ps-0">{document.receiver.name}</span>
                            </div>
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.free_form_document.view.receiver.inn')}:</span>
                                <span className="col mb-1 ps-0">{document.receiver.inn}</span>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <hr/>
                        </Col>
                    </Row>

                    <Row className="d-flex flex-center">
                        <Col className="my-2" xs={12} lg={6}>
                            <DownloadFreeFormDocumentPdfFile id={document.id} />
                        </Col>
                        {allowDownloadZipFile && <Col className="my-2" xs={12} lg={6}>
                            <DownloadFreeFormDocumentZipFile id={document.id}/>
                        </Col>}
                    </Row>
                    <Row className="my-3">
                        {viewPdf &&
                            <Col xs={12}>
                                <iframe src={`data:application/pdf;base64,${viewPdf}`} className="vh-100" width="100%"/>
                            </Col>
                        }
                    </Row>
                    <Row className="flex-column flex-md-row">
                        <Col xs={12} md={6} className="d-flex justify-content-center flex-column align-items-start">
                            {document?.histories?.map((history, index) => {
                                const showExecutorHistory = getStatusShowExecutorHistory(history?.action)
                                if (showExecutorHistory)
                                    return(
                                        <div key={index}>
                                            <HistoryActionSeal
                                                serialNumber={history?.certificate?.serial_number}
                                                signingTime={history?.certificate?.signing_time}
                                                fullName={history?.certificate?.full_name}
                                                statusName={t(lang, getStatusHistory(history?.action))}
                                                variant={getStatusHistoryVariant(history?.action)}
                                                bg={getStatusHistoryBg(history?.action)}
                                            />
                                        </div>
                                    )
                            })}
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center flex-column align-items-end">
                            {document?.histories?.map((history, index) => {
                                const showCustomerHistory = getStatusShowCustomerHistory(history?.action)
                                if (showCustomerHistory)
                                    return(
                                        <div key={index}>
                                            <HistoryActionSeal
                                                serialNumber={history?.certificate?.serial_number}
                                                signingTime={history?.certificate?.signing_time}
                                                fullName={history?.certificate?.full_name}
                                                statusName={t(lang, getStatusHistory(history?.action))}
                                                variant={getStatusHistoryVariant(history?.action)}
                                                bg={getStatusHistoryBg(history?.action)}
                                            />
                                        </div>
                                    )
                            })}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default FreeFormDocument;