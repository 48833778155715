import React, {useEffect, useState} from 'react';
import SendWaybillFormV2 from "../../../../components/hippo/roaming/waybill/forms/SendWaybillFormV2";
import EventBus from "../../../../app/eventbus/EventBus";
import {useHistory} from "react-router-dom";
import {
    SAVE_DRAFT_WAYBILL_v2_SUCCEED,
    SEND_WAYBILL_V2_SUCCEED
} from "../../../../app/eventbus/roaming/roamingWaybillV2Events";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {useDispatch, useSelector} from "react-redux";
import useQuery from "../../../../components/hippo/roaming/invoice/form/useQuery";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {
    loadDraftWaybillV2Async,
    loadWaybillV2Async, setShowModal
} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import {loadShipmentAsync} from "../../../../app/store/reducers/shipment/shipmentReducer";
import {loadRoamingInvoiceAsync} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import {getOrganizationInfoAsync} from "../../../../app/store/reducers/roaming/roamingReducer";

const SendWaybillV2 = () => {
    const {draft_waybill_id, waybill_id, shipment_id, invoice_id} = useQuery();

    const history = useHistory();
    const dispatch = useDispatch();
    const activeOrganization = useSelector(selectActiveOrganization);

    const [draftWaybill, setDraftWaybill] = useState(null);
    const [waybill, setWaybill] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [organizationInfo, setOrganizationInfo] = useState(null)


    useEffect(() => {
        dispatch(setShowModal(false))
        draft_waybill_id && loadDraftWaybillV2Async(draft_waybill_id)
            .then((res) => setDraftWaybill(res))
            .catch(() => setDraftWaybill(null))
    }, [draft_waybill_id, activeOrganization])

    useEffect(() => {
        dispatch(setShowModal(false))
        waybill_id && loadWaybillV2Async(waybill_id)
            .then((res) => setWaybill(res))
            .catch(() => setWaybill(null))
    }, [waybill_id, activeOrganization])

    useEffect(() => {
        dispatch(setShowModal(false))
        shipment_id && loadShipmentAsync(shipment_id)
            .then((res) => setWaybill(res))
            .catch(() => setWaybill(null))
    }, [waybill_id, activeOrganization])

    useEffect(() => {
        dispatch(setShowModal(false))
        invoice_id && loadRoamingInvoiceAsync(invoice_id)
            .then((res) => setInvoice(res))
            .catch(() => setInvoice(null))
    }, [invoice_id, activeOrganization])

    useEffect(() => {
        if (invoice) {
            getOrganizationInfoAsync(activeOrganization.inn || activeOrganization.pinfl)
                .then((res) => setOrganizationInfo(res))
                .catch((e) => setOrganizationInfo(e))
        }

    }, [activeOrganization, invoice])

    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_V2_SUCCEED, waybill => {
            history.push(`/roaming/waybill-v2/${waybill.id}`)
            dispatch(getOrganizationBillingInfoAsync());
        });

        const onSaveDraftWaybillSucceedHandler = EventBus.on(SAVE_DRAFT_WAYBILL_v2_SUCCEED, draftWaybill => {
            history.push(`/roaming/waybill-v2/draft/${draftWaybill.id}`)
        })
        return () => {
            EventBus.remove(SAVE_DRAFT_WAYBILL_v2_SUCCEED, onSaveDraftWaybillSucceedHandler);
            EventBus.remove(SEND_WAYBILL_V2_SUCCEED, onSendWaybillSucceedHandler);
        }
    }, []);

    if (
        (draft_waybill_id && !draftWaybill) ||
        (invoice_id && !invoice) ||
        (waybill_id && !waybill || shipment_id && !waybill)
    ) {
        return <SpinnerComponent />
    }

    if  (invoice && !organizationInfo) {
        return <SpinnerComponent />
    }

    return (
        <>
            <SendWaybillFormV2
                draftWaybill={draftWaybill}
                waybill={waybill}
                invoice={invoice}
                organizationInfo={organizationInfo}
            />
        </>
    );
};

export default SendWaybillV2;
