import {useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../app/store/reducers/main/mainReducer";
import {getMeasurement, selectMeasurements} from "../../../app/store/reducers/roaming/roamingReducer";
import React from "react";
import {Card, Col, Row, Table} from "react-bootstrap";
import dayjs from "dayjs";
import numeral from "numeral";
import ConvertQuantityToWord from "../attorney/ConvertQuantityToWord";

const ContractView = ({contract}) => {
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const numberFormat = useSelector(selectNumberFormat);
    const measurements = useSelector(selectMeasurements);
    const t = Translate;
    const totalWithNds = contract?.items.reduce((total, item) => item?.total_with_nds + total, 0)

    return (<>
        <Card className="p-3">
            <Row>
                <h3 className="text-600 ms-2 col-8">{t(lang, "edi.common.created.time")}: {contract.created_at}</h3>
            </Row>
        </Card>
        <Card className="my-3 px-3">
            <Row className="d-flex flex-lg-row flex-column mt-3">
                <Col xs={12} lg={5} className="d-flex flex-column my-2">
                    <h5 className="mb-1 fw-bold text-dark">{t(lang, "roaming.contract.company.owner")}</h5>
                    <span><b>{t(lang, "edi.common.company")}:</b> {contract?.executor?.name}</span>
                    <span><b>{t(lang, 'edi.common.label.inn')}:</b> {contract?.executor?.inn}</span>
                </Col>

                <Col xs={12} lg={5} className="d-flex flex-column my-2">
                    <h5 className="mb-1 fw-bold text-dark">{t(lang, "edi.common.customer")}</h5>
                    <span><b>{t(lang, "edi.common.company")}:</b> {contract?.customer?.name}</span>
                    <span><b>{t(lang, 'edi.common.label.inn')}:</b> {contract?.customer?.inn}</span>
                </Col>
            </Row>
        </Card>
        <Card>
            <Card.Header>
                <Row>
                    <Row className="d-flex flex-column flex-lg-row align-items-md-center justify-content-lg-between">
                        <Col xs={12} md={8} lg={5} className="d-flex justify-content-center justify-content-lg-start">
                            <h5 className="p-0 ms-3"><b className="pe-1">{t(lang, "edi.common.id")}:</b>{contract.id}
                            </h5>
                        </Col>
{/*                        <Col xs={12} md={8} lg={4}>
                            <div className="alert-danger p-1 text-center">
                                <span className="fw-bold me-2">{t(lang, "roaming.common.comment")}:</span>
                                <strong>{contract.notes}</strong>
                            </div>
                        </Col>*/}
                    </Row>

                    <Col className="my-3 col-12 d-flex flex-column align-items-center">
                        <h3>{t(lang, "edi.common.contract")}</h3>
                        <div className="d-flex">
                            <b>Номер:</b><span>{contract?.info?.number}</span>
                        </div>
                        <div className="d-flex">
                            <b>Дата:</b><span>{dayjs(contract?.info?.date, "YYYY-MM-DD").format(dateFormat)}</span>
                        </div>
                        <div className="d-flex">
                            <b>Наименование:</b><span>{contract?.info?.name}</span>
                        </div>
                        <div className="d-flex">
                            <b>Действителен
                                до:</b><span>{dayjs(contract?.info?.expire_date, "YYYY-MM-DD").format(dateFormat)}</span>
                        </div>
                        <div className="d-flex">
                            <b>Место заключения:</b><span>{contract?.info?.place}</span>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={6} className="fs--1 my-2">
                        <div className="row">
                            <span
                                className="col-5 fs-1 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.owner")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.name}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.address")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.address?.trim()}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.phone")}</span>
                            <span
                                className="col-7 mb-1 ps-0">{contract?.executor?.work_phone}{contract?.executor?.mobile && `, ${contract?.executor?.mobile}`}
                                <hr className={contract?.executor?.work_phone || contract?.executor?.mobile ? 'm-0' : 'mb-0'}
                                    style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.fax")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.fax}
                                <hr className={contract?.executor?.fax ? 'm-0' : 'mb-0'}
                                    style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.tin")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.inn}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.oked")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.oked}
                                <hr className={contract?.executor?.oked ? 'm-0' : 'mb-0'}
                                    style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.account")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.account}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.bank")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.address?.trim()}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.mfo")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.executor?.mfo}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className="fs--1 my-2">
                        <div className="row">
                            <span
                                className="col-5 fs-1 mb-1 pe-0 fw-bold">{t(lang, "edi.common.customer")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.customer?.name}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.address")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.customer?.address?.trim()}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.phone")}</span>
                            <span
                                className="col-7 mb-1 ps-0">{contract?.customer?.work_phone}{contract?.customer?.mobile && `, ${contract?.customer?.mobile}`}
                                <hr className={contract?.customer?.work_phone || contract?.customer?.mobile ? 'm-0' : 'mb-0'}
                                    style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.fax")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.customer?.fax}
                                <hr className={contract?.customer?.fax ? 'm-0' : 'mb-0'}
                                    style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.tin")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.customer?.inn}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.oked")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.customer?.oked}
                                <hr className={contract?.customer?.oked ? 'm-0' : 'mb-0'}
                                    style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.account")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.customer?.account}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.bank")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.customer?.address?.trim()}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-5 mb-1 pe-0 fw-bold">{t(lang, "roaming.contract.company.info.mfo")}</span>
                            <span className="col-7 mb-1 ps-0">{contract?.customer?.mfo}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                <Table bordered responsive className="border-bottom fs--1 border-500">
                    <thead className="text-dark bg-200">
                    <tr>
                        <th className="align-middle" rowSpan="2">№</th>
                        <th className="align-middle" rowSpan="2">{t(lang, "roaming.contract.items_datatable.name")}</th>
                        <th className="align-middle"
                            rowSpan="2">{t(lang, "roaming.contract.items_datatable.catalog")}</th>
                        <th className="align-middle"
                            rowSpan="2">{t(lang, "roaming.contract.items_datatable.barcode")}</th>
                        <th className="align-middle"
                            rowSpan="2">{t(lang, "roaming.contract.items_datatable.measurement")}</th>
                        <th className="align-middle"
                            rowSpan="2">{t(lang, "roaming.contract.items_datatable.quantity")}</th>
                        <th className="align-middle"
                            rowSpan="2">{t(lang, "roaming.contract.items_datatable.price")}</th>
                        <th className="align-middle"
                            rowSpan="2">{t(lang, "roaming.contract.items_datatable.total")}</th>
                        <th className="align-middle text-center"
                            colSpan="2">{t(lang, "roaming.contract.items_datatable.vat")}</th>
                        <th className="align-middle"
                            rowSpan="2">{t(lang, "roaming.contract.items_datatable.total_with_vat")}</th>
                    </tr>
                    <tr>
                        <th className="align-middle">{t(lang, "roaming.contract.items_datatable.vat.rate")}</th>
                        <th className="align-middle">{t(lang, "roaming.contract.items_datatable.vat.sum")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {contract?.items?.map((item, index) => (
                        <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">{item?.name}</td>
                            <td className="align-middle">{item?.catalog_code} - {item?.catalog_name}</td>
                            <td className="align-middle">{item?.barcode}</td>
                            <td className="align-middle">{getMeasurement(measurements, item?.measurement_id)?.name}</td>
                            <td className="align-middle text-nowrap">{item?.quantity || 0}</td>
                            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.price || 0)}</td>
                            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.total || 0)}</td>
                            <td className="align-middle text-nowrap">{item?.nds_rate}</td>
                            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.nds_value || 0)}</td>
                            <td className="align-middle text-nowrap">{numeral.formats[numberFormat].format(item?.total_with_nds || 0)}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Row className="g-0 justify-content-end">
                    <Col xs="auto">
                        <Table borderless size="sm" className="fs--1 text-end">
                            <tbody>
                            <tr>
                                <th className="text-900">{t(lang, 'edi.common.total')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(contract?.items?.reduce((total, item) => item?.total + total, 0))}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-900">{t(lang, 'edi.common.nds_value')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(contract.items.reduce((total, item) => item.nds_value + total, 0))}
                                </td>
                            </tr>
                            <tr className="border-top">
                                <th className="text-900">{t(lang, 'edi.common.total_with_nds')}:</th>
                                <td className="fw-semi-bold">
                                    {numeral.formats[numberFormat].format(totalWithNds)}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <p className="fw-bold">
                    {t(lang, "roaming.contract.items_datatable.amount_in_words_1")} {ConvertQuantityToWord.getText(totalWithNds)} ({t(lang, "roaming.contract.items_datatable.amount_in_words_2")}),
                    ({numeral.formats[numberFormat].format(totalWithNds)})
                </p>
            </Card.Body>

            <Card.Footer>
                <Col className="border-top my-4 p-2">
                    {contract?.parts?.map((part, index) => (
                        <div key={index}>
                            <h5 className="ms-4">{index + 1}. {part?.title}</h5>
                            {part?.body?.split('\n').map((text, index) => (
                                <p className="text-justify" key={index}>{text}</p>
                            ))}
                        </div>
                    ))}
                </Col>
            </Card.Footer>
        </Card>
    </>)
}
export default ContractView