import React from 'react';
import ReactDOM from 'react-dom';
import Main from "./Main";
import App from "./app/App";
import './helpers/initFA';
import {compose} from "@reduxjs/toolkit";
import { registerAllModules } from 'handsontable/registry';
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n';
import 'handsontable/dist/handsontable.full.min.css';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// Load the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tashkent');

// register Handsontable's modules
registerAllModules()
registerLanguageDictionary(ruRU)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

ReactDOM.render(
    <React.StrictMode>
        <Main>
            <App/>
        </Main>
    </React.StrictMode>,
    document.getElementById('main')
);
