import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {toast} from "react-toastify";
import EventBus from '../../../../app/eventbus/EventBus';
import { DOCUMENT_SIGN_FAILED, DOCUMENT_SIGN_STARTED, DOCUMENT_SIGN_SUCCESS } from '../../../../app/eventbus/signEvents';
import { selectLang, Translate, updateShowInProgressAsync } from '../../../../app/store/reducers/main/mainReducer';
import ContractForm from '../../../../components/hippo/roaming/contract/ContractForm';
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingContractCreate} from "../../../../enum/Permission/RoamingPermission";
import {useHistory} from "react-router-dom";

const SendContract = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingContractCreate))) {
            if (history.length > 1) {
                history.push('/roaming/contracts/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const signSuccessHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
            toast.success(t(lang, "edi.document.sign.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
        });
        const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (data) => {
            toast.error(t(lang, data.message))
            dispatch(updateShowInProgressAsync(false))
        });

        return () => {
            EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
            EventBus.remove(DOCUMENT_SIGN_SUCCESS, signSuccessHandler)
            EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)
        }
    }, [])
    return (
        <ContractForm />
    );
};

export default SendContract;
