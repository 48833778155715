import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import React, {useEffect, useState} from 'react';
import ReturnInvoice from "../../../../components/hippo/return-invoice/ReturnInvoice";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadReturnInvoiceAsync} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CANCEL_RETURN_INVOICE_SUCCESS,
    DECLINE_RETURN_INVOICE_SUCCESS,
    DELETE_RETURN_INVOICE_SUCCESS,
    SIGN_RETURN_INVOICE_SUCCESS
} from "../../../../app/eventbus/returnInvoiceEvents";
import { toast } from "react-toastify";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {setEdiReturnBreadcrumbData} from "../../../../app/store/reducers/order/orderReducer";
import {useHistory} from "react-router-dom";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionEdiReturnInvoiceView,
} from "../../../../enum/Permission/EdiPermission";

const ViewReturnInvoice = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const {params: {id}} = useRouteMatch();
    const [invoice, setInvoice] = useState();
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)


    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiReturnInvoiceView))) {
            if (history.length > 1) {
                history.push('../return-invoices')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadReturnInvoice()
    }, [id, activeOrganization])

    const loadReturnInvoice = () => {
        loadReturnInvoiceAsync(id).then(response => {
            dispatch(checkPermission(PermissionEdiReturnInvoiceView)) && dispatch(setEdiReturnBreadcrumbData(response))
            setInvoice(response)
        }).catch(error => console.log(error))
    }

    useEffect(() => {
        const onDeletedHandler = EventBus.on(DELETE_RETURN_INVOICE_SUCCESS, () => {
            loadReturnInvoice()
        })


        const signSuccessHandler = EventBus.on(SIGN_RETURN_INVOICE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.sign.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            loadReturnInvoice()
        });



        const cancelSuccessHandler = EventBus.on(CANCEL_RETURN_INVOICE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.cancel.notification.success"))
            loadReturnInvoice()
        })


        const declineSuccessHandler = EventBus.on(DECLINE_RETURN_INVOICE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            loadReturnInvoice()
        })


        return () => {
            EventBus.remove(DELETE_RETURN_INVOICE_SUCCESS, onDeletedHandler)

            EventBus.remove(SIGN_RETURN_INVOICE_SUCCESS, signSuccessHandler)
            EventBus.remove(CANCEL_RETURN_INVOICE_SUCCESS, cancelSuccessHandler)
            EventBus.remove(DECLINE_RETURN_INVOICE_SUCCESS, declineSuccessHandler)
        }
    }, [])

    return (
        <>
            {invoice && <ReturnInvoice returnInvoice={invoice} />}
            {!invoice && <span>Loading ...</span>}
        </>
    );
};

export default ViewReturnInvoice;
