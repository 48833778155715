import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import {loadPDFAsync} from "../../../../../app/store/reducers/roaming/roamingInvoiceReducer";
import {downloadFile} from "../../../../../helpers/excel-uploader";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

const RoamingInvoiceDownloadToPdf = ({invoice, ...props}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const [loading, setLoading] = useState(false);

    const onDownloadToPdf = () => {
        setLoading(true);
        loadPDFAsync(invoice.id)
            .then((response) => {
                setLoading(false);
                downloadFile({
                    content: response,
                    contentType: response.type,
                    fileName: `${invoice.info.number}-${dayjs(invoice.info.date).format(dateFormat)}.pdf`,
                });
            })
            .catch(error => {
                setLoading(false);
                toast.error(t(lang, "roaming.invoice.print_failed_error"))
            })
    }

    return (
        <IconButton {...props} icon={faFilePdf}
                    transform="shrink-3"
                    size="sm"
                    variant={'falcon-info'}
                    onClick={onDownloadToPdf}
        >
            <span className="d-none d-sm-inline-block">{t(lang, 'edi.contract_formation.view.download_pdf')}</span>
            {loading && <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>}
        </IconButton>
    );
};

export default RoamingInvoiceDownloadToPdf;
