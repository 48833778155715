import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import {Controller} from "react-hook-form";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import RoamingInvoiceFormBranchSelector from "../../invoice/form/RoamingInvoiceFormBranchSelector";

const RoamingActFormMember = ({fieldName, isSearchable, company, onChangeCompany}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Row>
            <Col xs={12}>
                <Controller name={`${fieldName}.identifier`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required'),
                            }}
                            render={({field, fieldState: {error}}) => (
                                <Form.Group as={Row} className="mb-2">
                                    <Form.Label column xs="12" sm="4" md="12" lg="4">
                                        {t(lang, 'roaming.common.inn_or_pinfl')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col xs={12} sm={8} md={12} lg={8}>
                                        <FindCompanySearchable defaultInn={field.value}
                                                               disabled={!isSearchable}
                                                               onChange={(company) => {
                                                                   field.onChange(company ? (company.person_num || company.inn) : null);
                                                                   onChangeCompany(company);
                                                               }}
                                                               inputProps={{
                                                                   isSearchable: !field.value,
                                                                   menuPortalTarget: document.body,
                                                                   styles: {
                                                                       control: base => ({
                                                                           ...base,
                                                                           border: error?.message && '1px solid #e63757'
                                                                       }),
                                                                       container: (provided) => ({
                                                                           ...provided,
                                                                           margin: '-1px'
                                                                       }),
                                                                       menuPortal: (provided) => ({
                                                                           ...provided,
                                                                           zIndex: 9999
                                                                       })
                                                                   }
                                                               }}
                                        />
                                    </Col>
                                </Form.Group>
                            )}
                />
                <Controller name={`${fieldName}.name`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required'),
                            }}
                            render={({field, fieldState: {error}}) => (
                                <Form.Group as={Row} className="mb-2">
                                    <Form.Label column xs="12" sm="4" md="12" lg="4">
                                        {t(lang, 'roaming.common.organization')}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col xs={12} sm={8} md={12} lg={8}>
                                        <Form.Control name={`${fieldName}.name`}
                                                      placeholder={t(lang, 'roaming.common.organization.placeholder')}
                                                      value={field.value}
                                                      isInvalid={!!error}
                                                      onChange={field.onChange}
                                        />
                                        {!!error && <Form.Control.Feedback
                                            type="invalid">{error?.message}</Form.Control.Feedback>}
                                    </Col>
                                </Form.Group>
                            )}
                />
                {!!company?.branches?.length &&
                    <Controller name={`${fieldName}.branch`}
                                shouldUnregister={true}
                                render={({field}) => (
                                    <Form.Group as={Row} className="mb-2">
                                        <Form.Label column xs="12" sm="4" md="12"
                                                    lg="4">{t(lang, 'roaming.invoice.branch')}</Form.Label>
                                        <Col xs={12} sm={8} md={12} lg={8}>
                                            <RoamingInvoiceFormBranchSelector branches={company.branches}
                                                                              defaultBranchCode={field.value ? field.value.code : null}
                                                                              onChange={field.onChange}

                                            />
                                        </Col>
                                    </Form.Group>
                                )}
                    />
                }
            </Col>
        </Row>
    );
};

export default RoamingActFormMember;