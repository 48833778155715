import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Badge, Form} from "react-bootstrap";
import Cleave from "cleave.js/react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import React, {useEffect} from "react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContractFormationCertificateLinkSelector from "./ContractFormationCertificateLinkSelector";
import CatalogLinkSelector from "../../../common/CatalogLinkSelector";
import CatalogPackageLinkSelector from "../../../common/CatalogPackageLinkSelector";
import MeasurementLinkSelector from "../../../common/MeasurementLinkSelector";


const ContractFormationProduct = ({reversedCalculation, index, removeFieldProduct, controlledFieldsProduct, product}) => {
    const {register, getValues, setValue, formState: {errors: productsError}, trigger, control} = useFormContext()
    const errors = productsError.products || []
    const {count, summa, vatRate, exciseRate, deliverySumWithVat, measureId, measureName, packageCode, packageName} = product
    const catalog = useWatch({name: `products.${index}.catalog`, exact: true})
    const packages = useWatch({name: `products.${index}.packages`, exact: true})
    const measurement = useWatch({name: `products.${index}.measurement`, exact: true})
    const lang = useSelector(selectLang)
    const t = Translate;

    useEffect(() => {
        if (count || summa || vatRate || deliverySumWithVat) {
            if (reversedCalculation) {
                let deliverySum = +deliverySumWithVat * 100 / (100 + +vatRate)
                let vatSum = +deliverySumWithVat - +deliverySum
                let nSumma = +deliverySum / +count

                deliverySum = deliverySum.toFixed(2)
                vatSum = vatSum.toFixed(2)
                nSumma = nSumma.toFixed(2)

                setValue(`products.${index}.deliverySum`, deliverySum)
                setValue(`products.${index}.vatSum`, vatSum)
                setValue(`products.${index}.summa`, nSumma)
            } else {
                let nCount = +count
                let nSumma = +summa
                let nVatRate = +vatRate
                let nExciseRate = +exciseRate
                const total = nCount * nSumma
                let nExciseValue = (total / 100) * nExciseRate
                let deliverySum = total + nExciseValue
                let vatSum = (deliverySum / 100) * nVatRate
                let deliverySumWithVat = deliverySum + vatSum

                nExciseValue = nExciseValue.toFixed(2)
                deliverySum = deliverySum.toFixed(2)
                vatSum = vatSum.toFixed(2)
                deliverySumWithVat = deliverySumWithVat.toFixed(2)
                setValue(`products.${index}.deliverySum`, deliverySum)
                setValue(`products.${index}.exciseSum`, nExciseValue)
                setValue(`products.${index}.vatSum`, vatSum)
                setValue(`products.${index}.deliverySumWithVat`, deliverySumWithVat)
            }
        }

    }, [count, summa, vatRate, exciseRate, deliverySumWithVat]);

    const onChangeCatalog = (catalog) => {
        setValue(`products.${index}.catalog`, catalog)
        setValue(`products.${index}.catalogCode`, catalog?.class_code)
        setValue(`products.${index}.catalogName`, catalog?.name)
        trigger(`products.${index}.catalogCode`)
    }

    useEffect(() => {
        if (catalog && packages) {
            if (measureId !== "")
                setValue(`products.${index}.measureId`, "")
            if (measureName !== "")
                setValue(`products.${index}.measureName`, "")
            const name = lang === "ru" ? packages.name_ru : packages.name_uz
            if (packageName !== name)
                setValue(`products.${index}.packageName`, name)
            if (packageCode !== packages.code)
                setValue(`products.${index}.packageCode`, packages.code);
        } else {
            if (packageName !== null)
                setValue(`products.${index}.packageName`, null)
            if (packageCode !== null)
                setValue(`products.${index}.packageCode`, null)
            if (measurement !== null)
                setValue(`products.${index}.measurement`, null)
            if (catalog === null)
                setValue(`products.${index}.packages`, null)
        }
    }, [packages, catalog])

    const onChangeMeasurement = (option) => {
        setValue(`products.${index}.measurement`, option)
        setValue(`products.${index}.measureId`, option?.measureId, {shouldValidate: true})
        setValue(`products.${index}.measureName`, option?.name, {shouldValidate: true})
    }

    const onChangeCertificate = (certificateId) => {
        setValue(`products.${index}.certificate`, certificateId?.attachment_id)
    }

    const ndsOptions = [
        {value: '', label: 'Без НДС'},
        {value: '0', label: '0'},
        {value: '12', label: '12'},
        {value: '15', label: '15'}
    ];

    const certificateFile = [{
        filename: 'Не требуется',
        attachment_id: null
    }]

    useEffect(() => {
        for (let i = 0; i < controlledFieldsProduct.length; i++) {
            certificateFile.push(controlledFieldsProduct[i])
        }
    }, [controlledFieldsProduct])

    return <tr className="position-relative">
        <td style={{minWidth: '30px', width: '30px'}} className="p-0 align-middle text-center">{index + 1}</td>
        <td style={{minWidth: '480px'}} className="p-0">
            <Form.Control
                {...register(`products.${index}.name`, {
                    required: t(lang, "roaming.validations.is_required")
                })}
                name={`products.${index}.name`}
                isInvalid={errors[index]?.name}
                isValid={Object.keys(errors).length > 0 && !errors[index]?.name}
            />
        </td>
        <td style={{minWidth: '210px', width: '210px'}} className="p-0 align-middle text-center">
            <Controller name={`products.${index}.catalog`}
                        rules={{
                            required: t(lang, 'items.common.validations.is_required')
                        }}
                        render={({field}) => (
                            <CatalogLinkSelector defaultCatalog={field?.value}
                                                 onChange={(option) => {
                                                     field.onChange(option);
                                                     onChangeCatalog(option);
                                                 }}
                                                 linkProps={{
                                                     className: classNames({
                                                         'text-primary cursor-pointer fs--1': !errors[index]?.catalog,
                                                         'text-danger cursor-pointer fs--1': errors[index]?.catalog
                                                     })
                                                 }}
                            />
                        )}
            />
        </td>
        <td style={{minWidth: '360px', width: '360px'}} className="p-0 align-middle text-center">
            {catalog?.use_package &&
                <Controller name={`products.${index}.packages`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required')
                            }}
                            render={({field}) => (
                                <CatalogPackageLinkSelector catalogPackages={catalog?.package_names}
                                                            defaultCatalogPackage={packages}
                                                            onChange={field.onChange}
                                                            linkProps={{
                                                                className: classNames({
                                                                    'text-primary cursor-pointer fs--1': !errors[index]?.packages,
                                                                    'text-danger cursor-pointer fs--1': errors[index]?.packages
                                                                })
                                                            }}
                                />
                            )}
                />
            }
            {!catalog?.use_package &&
                <Controller name={`products.${index}.packages`}
                            rules={{
                                required: t(lang, 'items.common.validations.is_required')
                            }}
                            render={({field}) => (
                                <MeasurementLinkSelector onChange={field.onChange}
                                                         defaultMeasurement={measurement}
                                                         linkProps={{
                                                             className: classNames({
                                                                 'text-primary cursor-pointer fs--1': !errors[index]?.packages,
                                                                 'text-danger cursor-pointer fs--1': errors[index]?.packages
                                                             })
                                                         }}
                                />
                            )}
                />
            }
        </td>
        <td style={{minWidth: '180px', width: '180px'}} className="p-0">
            <Controller name={`products.${index}.barCode`} rules={{
                maxLength: {
                    value: 13,
                    message: "максимальная длина 13"
                }
            }} control={control} render={({field}) => (
                <Form.Control
                    type="text"
                    defaultValue={field.value}
                    onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                        field.onChange(e.target.value);
                    }}
                    className={classNames('', {
                        'is-invalid': errors[index]?.barCode,
                        'is-valid': Object.keys(errors).length > 0 && !errors[index]?.barCode
                    })}
                />
            )}
            />
        </td>
        <td style={{minWidth: '180px', width: '180px'}} className="p-0">
            <Controller
                name={`products.${index}.count`}
                control={control}
                rules={{required: t(lang, "roaming.validations.is_required")}}
                render={({field}) => (
                    <Cleave
                        options={{
                            numeral: true,
                            delimiter: ''
                        }}
                        value={field.value}
                        className={classNames('form-control', {
                            'is-invalid': errors[index]?.count,
                            'is-valid': Object.keys(errors).length > 0 && !errors[index]?.count
                        })}
                        onChange={field.onChange}
                    />
                )}
            />
        </td>
        <td style={{minWidth: '180px', width: '180px'}} className="p-0">
            <Controller
                name={`products.${index}.summa`}
                control={control}
                rules={{
                    required: t(lang, "roaming.validations.is_required")
                }}
                render={({field}) => (
                    <Cleave
                        options={{
                            numeral: true,
                            delimiter: ''
                        }}
                        disabled={reversedCalculation}
                        className={classNames('form-control', {
                            'is-invalid': errors[index]?.summa,
                            'is-valid': Object.keys(errors).length > 0 && !errors[index]?.summa
                        })}
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </td>
        <td style={{minWidth: '180px', width: '180px'}} className="p-0">
            <Form.Control
                name={`products.${index}.exciseRate`}
                type="text"
                {...register(`products.${index}.exciseRate`)}
            />
        </td>
        <td style={{minWidth: '180px', width: '180px'}} className="p-0">
            <Form.Control
                name={`products.${index}.exciseSum`}
                type="text"
                disabled
                {...register(`products.${index}.exciseSum`)}
            />
        </td>
        <td style={{minWidth: '210px', width: '210px'}} className="p-0">
            <Form.Control
                name={`products.${index}.deliverySum`}
                type="text"
                disabled
                {...register(`products.${index}.deliverySum`)}
            />
        </td>
        <td style={{minWidth: '180px', width: '180px'}} className="p-0">
            <Form.Select
                {...register(`products.${index}.vatRate`)}
            >
                {ndsOptions.map(option =>
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>)}
            </Form.Select>
        </td>
        <td style={{minWidth: '180px', width: '180px'}} className="p-0">
            <Form.Control
                name={`products.${index}.vatSum`}
                type="text"
                disabled
                {...register(`products.${index}.vatSum`)}
            />
        </td>
        <td style={{minWidth: '240px', width: '240px'}} className="p-0">
            <Form.Control
                name={`products.${index}.deliverySumWithVat`}
                type="text"
                disabled={!reversedCalculation}
                {...register(`products.${index}.deliverySumWithVat`)}
            />
        </td>
        <td style={{minWidth: '120px', width: '120px'}} className="p-0 text-center align-middle">
            <Controller
                name={`products.${index}.certificate`}
                render={({field}) => {
                    return (
                        <ContractFormationCertificateLinkSelector
                            certificateFile={certificateFile}
                            onChange={onChangeCertificate}
                            controlledFieldsProduct={controlledFieldsProduct}
                            defaultId={field.value}
                        />
                    )
                }}
            />
        </td>
        <td style={{minWidth: '30px', width: '30px'}} className="p-0 align-middle">
            {getValues("products").length > 1 &&
                <Badge pill bg="200" className="text-danger fs--2 ms-1 cursor-pointer" onClick={() => removeFieldProduct(index)}>
                    <FontAwesomeIcon icon="trash-alt" className="align-middle "/>
                </Badge>
            }
        </td>
    </tr>
}

export default ContractFormationProduct;
