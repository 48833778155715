import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import {loadShipmentAsync} from "../../../../app/store/reducers/shipment/shipmentReducer";
import ShipmentExecutorEditForm from "../../../../components/hippo/shipment/ShipmentExecutorEditForm";
import {loadOrderAsync} from "../../../../app/store/reducers/order/orderReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {EDIT_SHIPMENT_SUCCEED} from "../../../../app/eventbus/shipmentEvents";
import {loadCompanyProductCatalogsAsync, selectCompanyCatalogs} from "../../../../app/store/reducers/roaming/roamingReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiShipmentUpdate} from "../../../../enum/Permission/EdiPermission";

const ShipmentExecutorEdit = () => {
    const {params: {id}} = useRouteMatch();
    const catalogs = useSelector(selectCompanyCatalogs);
    const [shipment, setShipment] = useState(null);
    const [order, setOrder] = useState(null);
    const routerHistory = useHistory();

    const dispatch = useDispatch();
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiShipmentUpdate))) {
            if (routerHistory.length > 1) {
                routerHistory.push('/edi/shipments')
            } else {
                routerHistory.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        loadShipment();
    }, [id])

    useEffect(() => {
        if(order)
            dispatch(loadCompanyProductCatalogsAsync(order.executor.inn));
    }, [order])

    useEffect(() => {
        const onShipmentEditedListener = EventBus.on(EDIT_SHIPMENT_SUCCEED, shipment => {
            routerHistory.push(`/edi/shipment/${shipment.id}`)
        });

        return () => {
            EventBus.remove(EDIT_SHIPMENT_SUCCEED, onShipmentEditedListener)
        }
    }, []);

    const loadShipment = () => {
        loadShipmentAsync(id)
            .then(response => {
                setShipment(response);
                loadOrder(response?.order?.id)
            })
            .catch(error => console.log(error))
    }

    const loadOrder = (id) => {
        loadOrderAsync(id)
            .then(response => setOrder(response))
            .catch(error => console.log(error))
    }

    return (
        <>
            {!shipment && !order && <span>loading....</span>}
            {(shipment && order && catalogs.length > 0) && <ShipmentExecutorEditForm order={order} shipment={shipment}/>}
        </>
    );
};

export default ShipmentExecutorEdit;
