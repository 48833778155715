import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import InvoiceForm from "./InvoiceForm";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
    invoiceRegisterAsync,
    saveBankInfo,
    saveProductCatalogs
} from "../../../../app/store/reducers/invoice/invoiceReducer";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {saveContractId} from "../../../../app/store/reducers/contract/contractReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const InvoiceRegisterForm = ({shipment}) => {
    const dispatch = useDispatch();
    const routerHistory = useHistory();
    const lang = useSelector(selectLang);
    const t = Translate;

    // default values of form
    const methods = useForm({
        defaultValues: {
            invoiceInfo: {
                number: shipment.shipment_info.number,
                date: dayjs(shipment.shipment_info.date).toDate(),
            },
            contractInfo: {
                number: shipment.contract_info.number,
                date: dayjs(shipment.contract_info.date).toDate(),
            },
            shipmentInfo: {
                number: shipment.shipment_info.number,
                date: dayjs(shipment.shipment_info.date).toDate()
            },
            customer: {
                inn: shipment.customer_info.inn,
                name: shipment.customer_info.name,
                address: '',
                oked: '',
                ndsCode: '',
                bankName: '',
                account: '',
                mfo: ''
            },
            executor: {
                inn: shipment.executor_info.inn,
                name: shipment.executor_info.name,
                address: '',
                oked: '',
                ndsCode: '',
                bankName: '',
                account: '',
                mfo: '',
            },
            proxyInfo: {
                name: '',
                number: '',
                date: null,
            },
            director: '',
            accountant: '',
            releaser: '',
            deliveryAddress: shipment.delivery_address,
            items: shipment.items.map(item => {
                return {
                    id: item.id,
                    product: item.product,
                    barcode: item.barcode,
                    measurement: item.measurement,
                    quantity: item.quantity,
                    price: item.price,
                    total: item.total,
                    nds_rate: item.nds_rate,
                    nds_value: item.nds_value,
                    total_with_nds: item.total_with_nds,

                    catalogCode: null,
                    catalogName: null,
                    origin: null,
                    usePackage: false,
                    packageNames: [],
                    packageCode: null,
                    packageName: null,
                    benefitId: null,
                    benefitName: null,
                    benefitType: null,
                    benefitPrice: null,
                }
            }),
        },
    });

    // button loader
    const [loading, setLoading] = useState(false);

    // unique number validation
    const [existingNumber, setExistingNumber] = useState('');


    // submit
    const onSubmit = (formData) => {
        setLoading(true);

        const payload = {
            shipment_id: shipment.id,
            items: formData.items.map((item) => {
                return {
                    shipment_item_id: item.id,
                    measurement: item.measurement || null,
                    barcode: item.barcode || null,

                    catalog_class_code: item.catalogCode || null,
                    catalog_class_name: item.catalogName || null,
                    package_code: item?.packageCode?.code?.toString() || item?.packageCode?.toString(),
                    package_name: item?.packageCode?.name_ru || item.packageName,
                    benefit: item.benefitId || null,
                    benefit_name: item.benefitName || null,
                    benefit_type: +item.benefitType || null,
                    benefit_vat_sum: +item.benefitPrice || 0,
                    origin: +item.origin
                }
            }),
            invoice_info: {
                number: formData.invoiceInfo.number,
                date: dayjs(formData.invoiceInfo.date).format("YYYY-MM-DD")
            },
            customer: {
                inn: formData.customer.inn || null,
                name: formData.customer.name || null,
                address: formData.customer.address || null,
                oked: formData.customer.oked || null,
                nds_registration_code: formData.customer.ndsCode || null,
                bank_info: {
                    account: formData.customer.account || null,
                    mfo: formData.customer.mfo || null,
                    name: formData.customer.bankName || null
                }
            },
            executor: {
                inn: formData.executor.inn || null,
                name: formData.executor.name || null,
                address: formData.executor.address || null,
                oked: formData.executor.oked || null,
                nds_registration_code: formData.executor.ndsCode || null,
                bank_info: {
                    account: formData.executor.account || null,
                    mfo: formData.executor.mfo || null,
                    name: formData.executor.bankName || null
                },
            },
            proxy_info: {
                name: formData.proxyInfo.name,
                number: formData.proxyInfo.number,
                date: formData.proxyInfo.date ? dayjs(formData.proxyInfo.date).format("YYYY-MM-DD") : null,
            },
            director: formData.director,
            accountant: formData.accountant,
            releaser: formData.releaser,
        }

        invoiceRegisterAsync(payload)
            .then((invoice) => {
                setLoading(false);
                // save contract id
                dispatch(saveContractId());

                const customerBankInfo = {
                    identifier: invoice.customer.inn,
                    bankInfo: {
                        name: invoice.customer.bank_info.name,
                        mfo: invoice.customer.bank_info.mfo,
                        account: invoice.customer.bank_info.account
                    }
                };
                saveBankInfo(customerBankInfo);

                const executorBankInfo = {
                    identifier: invoice.executor.inn,
                    bankInfo: {
                        name: invoice.executor.bank_info.name,
                        mfo: invoice.executor.bank_info.mfo,
                        account: invoice.executor.bank_info.account
                    }                };
                saveBankInfo(executorBankInfo);

                // map items array and save catalog, package
                const productKeys = invoice.items.map((item) => {
                    return {
                        product: item.product,
                        catalogClassCode: item.catalog_class_code,
                        packageCode: item.package_code || "",
                        benefitId: item.benefit || "",
                        origin: item.origin
                    }
                });
                saveProductCatalogs(productKeys);

                // success event and route redirect
                toast.success(`Invoice ${invoice.invoice_info.number} successfully added !`);
                routerHistory.push(`/edi/invoice/${invoice.id}`);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(`Failed to add invoice`)

                if (error?.number_exists) {
                    setExistingNumber(formData.invoiceInfo.number)
                    methods.trigger()
                }
            })
    }


    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <InvoiceForm loading={loading}
                             existingNumber={existingNumber}
                />
            </Form>
        </FormProvider>
    )
};

InvoiceRegisterForm.propTypes = {
    shipment: PropTypes.object,
};

InvoiceRegisterForm.defaultProps = {
    shipment: {},
};

export default InvoiceRegisterForm;
