import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import IconButton from '../../common/IconButton';
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from 'react-redux';
import DataTableSettings from "../DataTableSettings";
import {
    changeContractDataTableColumn,
    loadContractDataTableColumn,
    STORAGE_CONTRACT
} from "../../../app/store/reducers/contract/contractReducer";
import {Link} from "react-router-dom";



const InvoiceDataTableHeader = () => {
    const [showAddForm, setShowAddForm] = useState(false)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    const allColumns = JSON.parse(localStorage.getItem(STORAGE_CONTRACT))
    if (!allColumns) {
        dispatch(loadContractDataTableColumn())
        return <></>
    }

    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.contract.datatable.header.row.navigation.create")}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <div id="invoice-actions">
                    <Link to="/crm/contractor-contract/send">
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            className="mx-2"
                            transform="shrink-3"
                        >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.contracts.datatable.register_contract")}</span>
                        </IconButton>
                    </Link>

                    <DataTableSettings allColumns={allColumns} changeDataTable={changeContractDataTableColumn} />
                </div>

            </Col>
        </Row>
    );
};

InvoiceDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default InvoiceDataTableHeader;
