import {createSlice} from "@reduxjs/toolkit";
import contractorOperationService from "../../../../services/contractorService";
import queryString from 'query-string';
import dayjs from "dayjs";

export const contractorOperationSlice = createSlice({
    name: 'contractorOperation',
    initialState: {
        filterOptions: {
            date_start: dayjs(new Date()).format('YYYY-MM-DD'),
            date_end: dayjs(new Date()).format('YYYY-MM-DD'),
        },
        cardFilterOptions: {
            date_start: dayjs(new Date()).format('YYYY-MM-DD'),
            date_end: dayjs(new Date()).format('YYYY-MM-DD'),
        },
        contractorOperations: [],
        cardContractorOperations: [],
        count: 0,
        isLoading: false,
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateCardFilterOptions: (state, action) => {
            state.cardFilterOptions = {...state.cardFilterOptions, ...action.payload}
        },
        updateContractorsCount: (state, action) => {
            state.count = action.payload
        },
        updateContractorOperations: (state, action) => {
            state.contractorOperations = action.payload
        },
        updateCardContractorOperations: (state, action) => {
            state.cardContractorOperations = action.payload
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
})
export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorOperationSlice.actions.updateFilterOptions({...params}))
}
export const changeCardFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorOperationSlice.actions.updateCardFilterOptions({...params}))
}
export const loadContractorOperationsAsync = ({...filters}) => dispatch => {
    const params = {...filters}
    dispatch(contractorOperationSlice.actions.setLoading(true));
    contractorOperationService.getContractorOperations(queryString.stringify(params))
        .then((response) => {
            dispatch(contractorOperationSlice.actions.updateContractorOperations(response.data));
        })
        .catch(error => {
            console.log(error);
        }).finally(() => {
        dispatch(contractorOperationSlice.actions.setLoading(false));
    });
}
export const loadCardContractorOperationsAsync = ({...filters}) => dispatch => {
    const params = {...filters}
    dispatch(contractorOperationSlice.actions.setLoading(true));
    contractorOperationService.getContractorOperations(queryString.stringify(params))
        .then((response) => {
            dispatch(contractorOperationSlice.actions.updateCardContractorOperations(response.data));
        })
        .catch(error => {
            console.log(error);
        }).finally(() => {
        dispatch(contractorOperationSlice.actions.setLoading(false));
    });
}
export const selectContractorOperations = (state) => state.contractorOperation.contractorOperations;
export const selectCardContractorOperations = (state) => state.contractorOperation.cardContractorOperations;

export const selectContractorPaymentFilterOptions = (state) => state.contractorOperation.filterOptions;
export const selectCardContractorPaymentFilterOptions = (state) => state.contractorOperation.cardFilterOptions;

export const selectCount = (state) => state.contractorOperation.count;

export const selectLoading = state => state.contractorOperation.isLoading;

export default contractorOperationSlice.reducer;