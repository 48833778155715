import {useSelector} from "react-redux";
import React, {useEffect, useRef} from "react";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import dayjs from "dayjs";
import {isNaN} from "underscore";
import {selectAccount} from "../../../../../app/store/reducers/auth/authReducer";
import {autocompleteValidator} from "handsontable/validators";
import Handsontable from "handsontable";
import {
    writeOffAddItemAsync, writeOffDeleteItemAsync, writeOffUpdateNoteAsync,
    writeOffRegisterAsync, writeOffUpdateDateAsync,
    writeOffUpdateItemAsync, writeOffUpdateNumberAsync
} from "../../../../../app/store/reducers/warehouse-operation/writeOffReducer";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import EventBus from "../../../../../app/eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../../app/eventbus/itemEvents";
import {CheckScanBarcodeOrMarking} from "../../enum/warehouseOperationWrapper";


const propPositionNumber = "positionNumber"
const propItemName = "itemName"
const propQuantity = 'quantity'
const propWarehouseName = "warehouseName"
const propWarehouseItemState = "warehouseItemState"
const propActions = "actions"

const colIndexPositionNumber = 0
const colIndexItemName = 1
const colIndexQuantity = 4
const colIndexWarehouseName = 5
const colIndexWarehouseItemState = 6
const colIndexActions = 7


const updateSourceTypeComputed = 'computed'

// const dateFormat = 'DD-MM-YYYY'
const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'

const numericValidator = (value, callback) => {
    callback(typeof (value) === 'number' && !isNaN(value));
}

const dateValidator = (value, callback) => {
    value = value || ''

    callback(dayjs(value, 'YYYY-MM-DD').isValid())
}

const timeValidator = (value, callback) => {
    const now = dayjs(new Date())

    value = `${now.format('YYYY-MM-DD')} ${value}`

    callback(dayjs(value, `${'YYYY-MM-DD'} ${timeFormat}`).isValid())
}

function dropdownValidator(value, callback) {
    if (this) {
        if (this.source.indexOf(value) > -1) {
            callback(true)
            return
        }
    }
    callback(false)
}

const createPositionObject = rowIndex => {
    return {
        id: null,
        item: null,
        quantity: 0,
        warehouseItem: null,
        marks: [],
        rowIndex: rowIndex
    }
}

const isOkWriteOff = writeOff => {
    return writeOff.date && writeOff.date.isValid()
}

const isOkPosition = position => {
    return typeof (position.quantity) === 'number' && !isNaN(position.quantity) // position.quantity
        && position.warehouseItem // position.warehouseItem
}

const WriteOffExcelForm = ({writeOff}) => {
    const items = useSelector(selectItems)
    const account = useSelector(selectAccount)
    const warehouses = useSelector(selectWarehouses)
    const lang = useSelector(selectLang)
    const t = Translate;

    const itemsRef = useRef(items)
    const hotContainerRef = useRef(null)
    const hotRef = useRef(null)
    const positionRowsStartRef = useRef(3)
    const positionRowsEndRef = useRef(3)

    const writeOffRef = useRef({
        id: null,
        date: dayjs(new Date()),
        number: null,
        note: null,
        items: [
            createPositionObject(positionRowsEndRef.current)
        ],
    })

    const upsert = () => {
        // register writeOff

        const positions = writeOffRef.current.items.filter(isOkPosition)

        writeOffRegisterAsync({
            date: writeOffRef.current.date.format("YYYY-MM-DD HH:mm:ss"),
            items: positions.map(position => {
                return {
                    item_id: position.item.item.id,
                    warehouse_id: position.warehouseItem.id,
                    quantity: position.quantity,
                    marks: position.marks || [],
                }
            }),
            note: writeOffRef.current.note,
            number: writeOffRef.current.number,
            approve: false,
        }).then(({data}) => {
            writeOffRef.current.id = data.id
            for (let i = 0; i < data.items.length; i++) {
                positions[i].id = data.items[i].id
            }
        })
    }

    const upsertPosition = position => {
        if (!isOkPosition(position)) return;

        if (writeOffRef.current.id) {
            if (position.id) {
                writeOffUpdateItemAsync({
                    writeOffId: writeOffRef.current.id,
                    operation: {
                        operation_item_id: position.id,
                        item_id: position.item.item.id,
                        warehouse_id: position.warehouseItem.id,
                        quantity: position.quantity,
                        marks: position.marks || [],
                    }
                })
            } else {
                // add
                writeOffAddItemAsync({
                    writeOffId: writeOffRef.current.id,
                    operation: {
                        item_id: position.item.item.id,
                        warehouse_id: position.warehouseItem.id,
                        quantity: position.quantity,
                        marks: position.marks || [],
                    }
                })
                    .then(({data}) => {
                        position.id = data.writeOff_item.id
                    })
                    .catch()
            }
        } else if (isOkWriteOff(writeOffRef.current)) {
            upsert()
        }
    }

    const deletePosition = position => {
        if (writeOffRef.current.id) {
            if (position.id) {
                writeOffDeleteItemAsync({writeOffId: writeOffRef.current.id, operationId: position.id})
                    .then()
                    .catch()
            }
        }
    }

    const enablePosition = position => {
        const hot = hotRef.current;

        hot.setCellMeta(position.rowIndex, colIndexItemName, "readOnly", false)
        hot.setCellMeta(position.rowIndex, colIndexQuantity, "readOnly", false)
        hot.setCellMeta(position.rowIndex, colIndexWarehouseName, "readOnly", false)

        hot.render()
    }

    const enablePositions = () => {
        for (let i = 0; i < writeOffRef.current.items.filter(isOkPosition).length; i++) {
            enablePosition(writeOffRef.current.items[i])
        }
    }

    const disablePosition = position => {
        const hot = hotRef.current;

        hot.setCellMeta(position.rowIndex, colIndexItemName, "readOnly", true)
        hot.setCellMeta(position.rowIndex, colIndexQuantity, "readOnly", true)
    }

    const disablePositions = () => {
        for (let i = 0; i < writeOffRef.current.items.filter(isOkPosition).length; i++) {
            disablePosition(writeOffRef.current.items[i])
        }
    }

    const createPositionRow = () => {
        const hot = hotRef.current;
        hot.alter('insert_row_below', positionRowsEndRef.current)

        positionRowsEndRef.current = positionRowsEndRef.current + 1;
        writeOffRef.current.items.push(createPositionObject(positionRowsEndRef.current))
        const position = writeOffRef.current.items.find(x => x.rowIndex === positionRowsEndRef.current)
        constructPositionRow(position)
    }

    const constructPositionRow = rowPosition => {
        const hot = hotRef.current;

        const positionNumber = rowPosition.rowIndex - positionRowsStartRef.current + 1
        hot.setDataAtCell(rowPosition.rowIndex, colIndexPositionNumber, positionNumber, updateSourceTypeComputed)

        hot.getPlugin('mergeCells').merge(rowPosition.rowIndex, colIndexItemName, rowPosition.rowIndex, colIndexItemName + 2)
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexPositionNumber, {
            data: propPositionNumber,
            className: 'fw-bold bg-soft-dark text-dark text-center'
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexItemName, {
            data: propItemName,
            source: (itemsRef?.current ?? items).map(x => x.item.name),
            type: "autocomplete",
            readOnly: false,
            validator: autocompleteValidator,
            allowInvalid: false,
            strict: true,
            allowEmpty: false,
            className: 'fw-semi-bold bg-soft-warning'
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexQuantity, {
            data: propQuantity,
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
            },
            allowEmpty: false,
            validator: numericValidator,
            className: 'fw-semi-bold bg-soft-success',
            readOnly: false,
            allowInvalid: false,
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexWarehouseName, {
            data: propWarehouseName,
            allowInvalid: false,
            strict: true,
            allowEmpty: false,
            type: 'dropdown',
            source: [],
            className: 'fw-semi-bold',
            validator: dropdownValidator,
            readOnly: true
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexWarehouseItemState, {
            data: propWarehouseItemState,
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
            },
            readOnly: true,
            className: 'fw-semi-bold',
        })
        hot.setCellMetaObject(rowPosition.rowIndex, colIndexActions, {
            data: propActions,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                td.innerHTML = null

                const deleteBtn = document.createElement('button')
                deleteBtn.innerText = "-"
                deleteBtn.classList.add('btn', 'btn-danger', 'btn-sm', 'float-end')
                deleteBtn.addEventListener('click', ev => {
                    ev.preventDefault();
                    if (window.confirm(t(lang, "warehouse.operation.item.common.delete_item_modal.title"))) {
                        onDeletePosition(row)
                    }
                })
                td.appendChild(deleteBtn)
            },
            readOnly: true,
        })

        if (!isOkWriteOff(writeOffRef.current)) {
            const position = writeOffRef.current.items.find(x => x.rowIndex === rowPosition.rowIndex)
            disablePosition(position)
        }

        hot.render()
    }


    const onDateChanged = (value, rowIndex, source) => {
        const now = dayjs(new Date())

        let time = now.format(timeFormat)
        if (writeOffRef.current.date)
            time = writeOffRef.current.date.format(timeFormat)

        writeOffRef.current.date = dayjs(`${value} ${time}`, `${dateFormat} ${timeFormat}`)

        // enable / disable positions
        if (isOkWriteOff(writeOffRef.current)) {
            enablePositions()
        } else {
            disablePositions()
        }

        if (writeOffRef.current.id && writeOffRef.current.date.isValid()) {
            writeOffUpdateDateAsync({writeOffId: writeOffRef.current.id, date: writeOffRef.current.date.format("YYYY-MM-DD HH:mm:ss")})
                .then(() => {
                })
                .catch()
        }
    }

    const onTimeChanged = (value, rowIndex, source) => {
        const now = dayjs(new Date())

        let date = now.format(dateFormat)
        if (writeOffRef.current.date)
            date = writeOffRef.current.date.format(dateFormat)

        writeOffRef.current.date = dayjs(`${date} ${value}`, `${dateFormat} ${timeFormat}`)

        // enable / disable positions
        if (isOkWriteOff(writeOffRef.current)) {
            enablePositions()
        } else {
            disablePositions()
        }

        if (writeOffRef.current.id && writeOffRef.current.date.isValid()) {
            writeOffUpdateDateAsync({writeOffId: writeOffRef.current.id, date: writeOffRef.current.date.format("YYYY-MM-DD HH:mm:ss")})
                .then(() => {
                })
                .catch()
        }
    }

    const onNumberChanged = (value, rowIndex, source) => {
        writeOffRef.current.number = value

        const hot = hotRef.current;
        hot.selectCell(positionRowsStartRef.current, colIndexItemName)

        if (writeOffRef.current.id) {
            writeOffUpdateNumberAsync({writeOffId: writeOffRef.current.id, number: writeOffRef.current.number})
                .then(() => {})
                .catch(() => {})
        }
    }

    const onNoteChanged = (value, rowIndex, source) => {
        writeOffRef.current.note = value

        if (writeOffRef.current.id) {
            writeOffUpdateNoteAsync({writeOffId: writeOffRef.current.id, note: writeOffRef.current.note})
                .then(() => {
                })
                .catch()
        }
    }

    const onItemChanged = (oldValue, value, rowIndex, source) => {
        if (oldValue === value) return;

        const hot = hotRef.current;

        const item = itemsRef.current.find(x => x.item.name?.trimStart() === value) || null
        const position = writeOffRef.current.items.find(x => x.rowIndex === rowIndex)

        // set item
        position.item = item

        // set warehouse
        // update warehouse name source
        const warehouseNames = position.item.warehouse_states.warehouse_items.map(x => x.name)
        hot.setCellMeta(position.rowIndex, colIndexWarehouseName, "source", warehouseNames)
        let warehouseName = position.warehouseItem?.name || null
        // select default warehouse name
        if (!warehouseName && warehouseNames.length > 0)
            warehouseName = warehouseNames[0]

        if (warehouseName) {
            const warehouseItem = position.item.warehouse_states.warehouse_items.find(x => x.name?.trimStart() === warehouseName)
            hot.setDataAtCell(position.rowIndex, colIndexWarehouseName, warehouseItem.name, updateSourceTypeComputed)
            position.warehouseItem = warehouseItem
        }

        // add new positions row
        if (position.rowIndex === positionRowsEndRef.current)
            createPositionRow()

        // save position
        upsertPosition(position)

        // enable position
        enablePosition(position)

        hot.selectCell(position.rowIndex, colIndexQuantity)
    }

    const onQuantityChanged = (value, rowIndex, source) => {
        const hot = hotRef.current;

        const position = writeOffRef.current.items.find(x => x.rowIndex === rowIndex)
        position.quantity = value || 0

        // save position
        upsertPosition(position)

        hot.selectCell(position.rowIndex + 1, colIndexItemName)
    }

    const onWarehouseChanged = (value, rowIndex, source) => {
        const hot = hotRef.current;

        const position = writeOffRef.current.items.find(x => x.rowIndex === rowIndex)

        if (source !== updateSourceTypeComputed) {
            position.warehouseItem = position.item.warehouse_states.warehouse_items.find(x => x.name?.trimStart() === value)

            // save position
            upsertPosition(position)
        }


        const state = position.warehouseItem?.state || 0;
        hot.setDataAtCell(position.rowIndex, colIndexWarehouseItemState, state, updateSourceTypeComputed)

        // update className
        if (state <= 0) {
            hot.setCellMeta(position.rowIndex, colIndexWarehouseItemState, "className", 'fw-semi-bold bg-warning bg-300 text-light')
        } else {
            hot.setCellMeta(position.rowIndex, colIndexWarehouseItemState, "className", 'fw-semi-bold bg-facebook text-light')
        }
    }

    const onDeletePosition = rowIndex => {
        const hot = hotRef.current;

        const position = writeOffRef.current.items.find(x => x.rowIndex === rowIndex)
        hot.alter('remove_row', position.rowIndex)

        writeOffRef.current.items = writeOffRef.current.items.filter(x => x.rowIndex !== position.rowIndex)

        for (let i = 0; i < writeOffRef.current.items.length; i++) {
            if (writeOffRef.current.items[i].rowIndex > position.rowIndex) {
                writeOffRef.current.items[i].rowIndex -= 1
            }
        }
        positionRowsEndRef.current = positionRowsEndRef.current - 1;
        if (positionRowsEndRef.current < positionRowsStartRef.current) {
            createPositionRow();
        }

        let counter = 1;
        for (let i = positionRowsStartRef.current; i <= positionRowsEndRef.current; i++) {
            hot.setDataAtCell(i, colIndexPositionNumber, counter, updateSourceTypeComputed)

            counter++
        }

        deletePosition(position)
    }

    const afterChange = (changes, source) => {
        const hot = hotRef.current;

        if (!changes) return;

        changes.forEach(([rowIndex, prop, oldValue, newValue]) => {
            hot.validateCell(newValue, {
                row: rowIndex, prop: prop
            }, valid => {
                if (!valid) return;

                if (rowIndex === 0) {
                    switch (prop) {
                        case propItemName:
                            onDateChanged(newValue, rowIndex, source)
                            break;

                        case `${propItemName}-4`:
                            onTimeChanged(newValue, rowIndex, source)
                            break;
                        case propWarehouseName:
                            onNumberChanged(newValue, rowIndex, source)
                            break;
                    }
                } else if (positionRowsEndRef.current + 2 === rowIndex) {
                    if (prop === `${propItemName}-3`) {
                        onNoteChanged(newValue, rowIndex, source)
                    }
                } else if (rowIndex >= positionRowsStartRef.current && rowIndex <= positionRowsEndRef.current) {
                    switch (prop) {
                        case propItemName:
                            onItemChanged(oldValue, newValue, rowIndex, source)
                            break;

                        case propQuantity:
                            onQuantityChanged(newValue, rowIndex, source)
                            break;

                        case propWarehouseName:
                            onWarehouseChanged(newValue, rowIndex, source)
                            break;
                    }
                }
            });
        });
    }

    const initialConstruct = hot => {
        // date / time / number
        hot.setCellMeta(0, 0, "className", 'fw-bold bg-soft-dark text-dark')
        hot.setCellMetaObject(0, 1, {
            className: 'fw-bold text-dark bg-soft-warning',
            readOnly: false,
            allowEmpty: false,
            allowInvalid: false,
            validator: dateValidator,
        })
        hot.setCellMeta(0, 2, "className", 'fw-bold bg-soft-dark text-dark')
        hot.setCellMetaObject(0, 3, {
            className: 'fw-bold text-dark bg-soft-warning',
            readOnly: false,
            allowEmpty: false,
            allowInvalid: false,
            validator: timeValidator,
        })
        hot.setCellMeta(0, 4, "className", 'fw-bold bg-soft-dark text-dark')
        hot.setCellMetaObject(0, 5, {
            className: 'fw-bold text-dark bg-soft-warning',
            readOnly: false
        })


        // empty row need
        hot.getPlugin('mergeCells').merge(1, 0, 1, 7)

        // positions header
        hot.setCellMeta(2, colIndexPositionNumber, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexItemName, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexQuantity, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexWarehouseName, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexWarehouseItemState, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.setCellMeta(2, colIndexActions, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.getPlugin('mergeCells').merge(2, colIndexItemName, 2, colIndexItemName + 2)

        // positions row need
        for (let i = positionRowsStartRef.current; i <= positionRowsEndRef.current; i++)
            constructPositionRow(writeOffRef.current.items.find(x => x.rowIndex === i))

        // empty row need
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 1, 0, positionRowsEndRef.current + 1, 7)


        // note
        hot.setCellMeta(positionRowsEndRef.current + 2, 0, "className", 'fw-bold bg-soft-dark text-dark align-middle text-center')
        hot.setCellMeta(positionRowsEndRef.current + 3, 0, "className", 'fw-bold bg-soft-dark text-dark')
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 2, 0, positionRowsEndRef.current + 3, 1)
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 2, 2, positionRowsEndRef.current + 3, 4)
        hot.setCellMetaObject(positionRowsEndRef.current + 2, 2, {
            type: "text",
            readOnly: false,
            className: 'bg-soft-warning',
        })


        // creator
        hot.setCellMeta(positionRowsEndRef.current + 4, 0, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 4, 0, positionRowsEndRef.current + 4, 1)
        hot.setCellMeta(positionRowsEndRef.current + 4, 2, "className", 'fw-bold bg-soft-dark text-dark text-center')
        hot.getPlugin('mergeCells').merge(positionRowsEndRef.current + 4, 3, positionRowsEndRef.current + 4, 7)



        hot.render()
    }

    const onScan = (value) => {
        var barcode
        if (value.length > 14){
            barcode = itemsRef?.current?.find(item => item?.item?.barcodes?.find(x => x === CheckScanBarcodeOrMarking(value)))
            if (barcode) {
                hotRef?.current.setDataAtCell(positionRowsEndRef?.current, colIndexItemName, barcode?.item?.name, updateSourceTypeComputed)
                hotRef?.current.setDataAtCell(positionRowsEndRef?.current, colIndexQuantity, 1, updateSourceTypeComputed)
                writeOffRef.current.items[writeOffRef.current.items.length - 1].marks = [value]
            }
        } else {
            barcode = itemsRef?.current?.find(item => item?.item?.barcodes?.find(x => x === CheckScanBarcodeOrMarking(value)))
            if (barcode) {
                hotRef?.current.setDataAtCell(positionRowsEndRef?.current, colIndexItemName, barcode?.item?.name, updateSourceTypeComputed)
                hotRef?.current.setDataAtCell(positionRowsEndRef?.current, colIndexQuantity, 1, updateSourceTypeComputed)
            }
        }
    }

    useEffect(() => {
        const data = []

        // 1-row
        const row1 = {}
        row1[propPositionNumber] = t(lang, "warehouse.operation.item.common.date")
        row1[`${propItemName}-3`] = t(lang, "warehouse.operation.item.common.time")
        row1[propQuantity] = t(lang, "warehouse.operation.item.common.number")
        if (writeOff) {
            writeOffRef.current.id = writeOff.id
            writeOffRef.current.date = dayjs(writeOff.date, `YYYY-MM-DD HH:mm:ss`)
            writeOffRef.current.number = writeOff.number || null

            row1[propItemName] = writeOffRef.current.date.format(dateFormat)
            row1[`${propItemName}-4`] = writeOffRef.current.date.format(timeFormat)
            row1[propWarehouseName] = writeOffRef.current.number
        }

        if (!writeOff) {
            writeOffRef.current.date = dayjs(new Date(), `YYYY-MM-DD HH:mm:ss`)
            row1[propItemName] = writeOffRef.current.date.format(dateFormat)
            row1[`${propItemName}-4`] = writeOffRef.current.date.format(timeFormat)
        }
        data.push(row1)

        // 3-row
        data.push({})

        // 4-row
        const row4 = {}
        row4[propPositionNumber] = '№'
        row4[propItemName] = t(lang, "warehouse.operation.item.common.product_name")
        row4[propQuantity] = t(lang, "warehouse.operation.item.common.quantity")
        row4[propWarehouseName] = t(lang, "warehouse.operation.item.common.warehouse")
        row4[propWarehouseItemState] = t(lang, "warehouse.operation.item.common.warehouse_state")
        row4[propActions] = t(lang, "warehouse.operation.item.common.action")
        data.push(row4)

        // positions rows
        const refPositions = []
        if (writeOff) {
            const positions = writeOff.items.filter(x => !x.is_deleted)

            for (let i = 0; i < positions.length; i++) {
                const position = positions[i]
                const item = itemsRef.current.find(x => x.item.id === position.warehouse_item.id)
                const warehouseItem = item?.warehouse_states.warehouse_items.find(x => x.id === position.warehouse_item.warehouse.id)

                data.push({
                    positionNumber: i + 1,
                    itemName: item?.item.name || null,
                    quantity: position.quantity,
                    warehouseName: warehouseItem?.name,
                    warehouseItemState: warehouseItem?.state,
                    marks: position.warehouse_item.marks || [],
                })

                refPositions.push({
                    id: position.id,
                    item: item,
                    quantity: position.quantity,
                    warehouseItem: warehouseItem,
                    marks: position.warehouse_item.marks || [],
                    rowIndex: positionRowsStartRef.current + i
                })

                positionRowsEndRef.current = positionRowsEndRef.current + 1
            }
        }
        data.push({positionNumber: positionRowsEndRef.current - positionRowsStartRef.current + 1})
        refPositions.push({rowIndex: positionRowsEndRef.current})
        writeOffRef.current.items = refPositions

        // positions end row + 1
        data.push({})

        // positions end row + 2
        let row5 = {}
        writeOffRef.current.note = writeOff?.note || null

        row5[propPositionNumber] = t(lang, "warehouse.operation.item.common.note")
        row5[`${propItemName}-3`] = writeOffRef.current.note
        data.push(row5)

        // note
        data.push({})

        // positions end row + 5
        let row8 = {}
        row8[propPositionNumber] = t(lang, "warehouse.operation.item.common.creator")
        row8[`${propItemName}-3`] = writeOff?.account?.name || account.name
        data.push(row8)


        const hot = new Handsontable(hotContainerRef.current, {
            licenseKey: "non-commercial-and-evaluation",
            mergeCells: true,
            filters: true,
            stretchH: "all",
            minSpareRows: 0,
            startCols: 8,
            columns: [
                {data: propPositionNumber, readOnly: true},
                {data: propItemName, readOnly: true},
                {data: `${propItemName}-3`, readOnly: true},
                {data: `${propItemName}-4`, readOnly: true},
                {data: propQuantity, readOnly: true},
                {data: propWarehouseName, readOnly: true},
                {data: propWarehouseItemState, readOnly: true},
                {data: propActions, readOnly: true},
            ],
            data: data,
            colWidths: ["5", "10", "20", "20", "20", "20", "20", "20", "15"],
        })
        hotRef.current = hot

        initialConstruct(hot)

        hot.addHook('afterChange', afterChange)

        // enable positions
        enablePositions()

        return () => {
            hot.removeHook('afterChange', afterChange)
            hot.destroy()
        }
    }, [])

    useEffect(() => {
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);
        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    },[])

    useEffect(() => {
        itemsRef.current = items
        const hot = hotRef.current;

        const itemNames = items.map(x => x.item.name?.trimStart())
        for (let rowIndex = positionRowsStartRef.current; rowIndex <= positionRowsEndRef.current; rowIndex++)
            hot.setCellMeta(rowIndex, colIndexItemName, "source", itemNames)
    }, [items])

    useEffect(() => {
        const hot = hotRef.current

        const warehouseNames = warehouses.map(x => x.name?.trimStart())
        for (let rowIndex = positionRowsStartRef.current; rowIndex <= positionRowsEndRef.current; rowIndex++)
            hot.setCellMeta(rowIndex, colIndexWarehouseName, "source", warehouseNames)
    },[warehouses])

    return (
        <div ref={hotContainerRef}></div>
    )
};

export default WriteOffExcelForm;