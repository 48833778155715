import {createSlice} from "@reduxjs/toolkit";

const telegramOrderBotSlice = createSlice({
    name: 'telegramOrderBot',
    initialState: {
        bots: [],
        filters: {
            name: ""
        },
        pagination: {
            pageIndex: 0,
            pageSize: 10
        },
    },
    reducers: {
        setBots: (state, action) => {
            state.bots = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const updateBots = (bots) => (dispatch) => dispatch(telegramOrderBotSlice.actions.setBots(bots));
export const updateFilters = (filters) => (dispatch) => dispatch(telegramOrderBotSlice.actions.setFilters(filters));
export const updatePagination = (pagination) => (dispatch) => dispatch(telegramOrderBotSlice.actions.setPagination(pagination));

export const selectTelegramOrderBots = state => state.telegramOrderBot.bots;
export const selectTelegramOrderBotFilters = state => state.telegramOrderBot.filters;
export const selectTelegramOrderBotPagination = state => state.telegramOrderBot.pagination;

export default telegramOrderBotSlice.reducer;