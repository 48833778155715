import React, {useEffect, useRef, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Button, Col, Collapse, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
    DRAFT,
    VIEWER_CUSTOMER,
    VIEWER_EXECUTOR,
    VIEWER_PERSON,
} from "../../../../../enum/RoamingInvoiceWrapper";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import FindCompany from "../../../organization/FindCompany";
import {
    getOrganizationInfoAsync,
    loadRoamingBranchesAsync
} from "../../../../../app/store/reducers/roaming/roamingReducer";
import ReactSelect from "react-select";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";

const RoamingInvoiceDataTableFilter = ({viewer, predefinedFilters, isOpenFilterCollapse, onResetFilter}) => {
    const {register, setValue} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();

    const showExecutorFilter = viewer === VIEWER_CUSTOMER || viewer === VIEWER_PERSON;
    const showCustomerFilter = viewer === VIEWER_EXECUTOR || viewer === VIEWER_PERSON || viewer === DRAFT;

    const [customer, setCustomer] = useState(null);
    const [executor, setExecutor] = useState(null);
    const [customerBranches, setCustomerBranches] = useState([]);
    const [executorBranches, setExecutorBranches] = useState([]);
    const [customerBranch, setCustomerBranch] = useState(null);
    const [executorBranch, setExecutorBranch] = useState(null);

    // set predefined filters
    useEffect(async () => {
        if (predefinedFilters && Object.keys(predefinedFilters).length > 0) {
            let params = {...predefinedFilters};

            params.date_end = params?.date_end ? dayjs(params.date_end).toDate() : null;
            params.date_start = params?.date_start ? dayjs(params.date_start).toDate() : null;
            params.contract_date = params?.contract_date ? dayjs(params.contract_date).toDate() : null;

            Object.keys(params).forEach(key => {
                setValue(key, params[key]);
            })
        }
    }, [predefinedFilters])


    // set default executor || customer
    useEffect(async () => {
        switch (viewer) {
            case VIEWER_CUSTOMER:
                setCustomer(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            case VIEWER_EXECUTOR:
                setExecutor(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            case DRAFT:
                setExecutor(await getOrganizationInfoAsync(activeOrganization.pinfl || activeOrganization.inn));
                break;
            default:
                break;
        }
    }, [viewer, activeOrganization])

    // save previous viewer for match in reducer
    const usePrevious = (value) => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value])

        return ref.current;
    };
    const previousViewer = usePrevious(viewer);

    // initialize values on viewer change
    useEffect(() => {
        if (previousViewer === VIEWER_CUSTOMER && (viewer === VIEWER_EXECUTOR || viewer === DRAFT)) {
            setCustomer(null);
            setExecutor(null);
        }

        if ((previousViewer === VIEWER_EXECUTOR || previousViewer === DRAFT) && viewer === VIEWER_CUSTOMER) {
            setCustomer(null);
            setExecutor(null);
        }

        if (previousViewer === VIEWER_CUSTOMER && viewer === VIEWER_PERSON) {
            setCustomer(null);
        }

        if ((previousViewer === VIEWER_EXECUTOR || previousViewer === DRAFT) && viewer === VIEWER_PERSON) {
            setExecutor(null);
        }

        if (previousViewer === VIEWER_PERSON && viewer === VIEWER_CUSTOMER) {
            setCustomer(null);
        }

        if (previousViewer === VIEWER_PERSON && (viewer === VIEWER_EXECUTOR || viewer === DRAFT)) {
            setExecutor(null);
        }
    }, [viewer])

    // set customer_inn and load branches
    useEffect(async () => {
        if (customer) {
            setValue('customer_inn', customer.person_num || customer.inn);
            setCustomerBranches(await dispatch(loadRoamingBranchesAsync(customer.person_num || customer.inn)));
        } else {
            setValue('customer_inn', null);
            setCustomerBranches([]);
            setCustomerBranch(null);
        }
    }, [customer])

    // set executor_inn and load branches
    useEffect(async () => {
        if (executor) {
            setValue('executor_inn', executor.person_num || executor.inn);
            setExecutorBranches(await dispatch(loadRoamingBranchesAsync(executor.person_num || executor.inn)));
        } else {
            setValue('executor_inn', null);
            setExecutorBranches([]);
            setExecutorBranch(null);
        }
    }, [executor])

    // set customer branch
    useEffect(() => {
        if (customerBranch)
            setValue('customer_branch_code', customerBranch.number);
        else
            setValue('customer_branch_code', null);
    }, [customerBranch])

    // set executor branch
    useEffect(() => {
        if (executorBranch)
            setValue('executor_branch_code', executorBranch.number);
        else
            setValue('executor_branch_code', null);
    }, [executorBranch])

    return (
        <>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.invoices.table_header.filter")}</h4>
            </Row>
            <Row className="mb-3 g-3">
                <Form.Group as={Col} md={6} lg={3}>
                    <Form.Label>{t(lang, "roaming.invoices.table_header.number")}</Form.Label>
                    <Form.Control name="number"
                                  type="text"
                                  placeholder={t(lang, "roaming.invoices.table_header.number.placeholder")}
                                  {...register("number")}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6} lg={3}>
                    <Form.Label>{t(lang, "roaming.invoices.table_header.date_start")}</Form.Label>
                    <Controller
                        name="date_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable
                                className='form-control'
                                placeholderText={t(lang, "roaming.invoices.table_header.date_start.placeholder")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6} lg={3}>
                    <Form.Label>{t(lang, "roaming.invoices.table_header.date_end")}</Form.Label>
                    <Controller
                        name="date_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable={!predefinedFilters.hasOwnProperty("date_end")}
                                className='form-control'
                                placeholderText={t(lang, "roaming.invoices.table_header.date_end.placeholder")}
                                disabled={predefinedFilters.hasOwnProperty("date_end")}
                                dateFormat={dateFormat}
                                selected={field.value}
                                defaultValue={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Form.Group>

                {showExecutorFilter &&
                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.common.executor")}</Form.Label>
                        <Controller name="executor_inn"
                                    render={({field}) => (
                                        <FindCompanySearchable inputProps={{
                                            placeholder: t(lang, "roaming.common.executor.placeholder")
                                        }}
                                                     onChange={setExecutor}
                                        />
                                    )}
                                    shouldUnregister
                        />
                    </Form.Group>
                }

                {showCustomerFilter &&
                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.common.customer")}</Form.Label>
                        <Controller name="customer_inn"
                                    render={({field}) => (
                                        <FindCompanySearchable inputProps={{
                                            placeholder: t(lang, "roaming.common.executor.placeholder"),
                                            disabled: predefinedFilters.hasOwnProperty("customer_inn")
                                        }}
                                                     defaultInn={predefinedFilters?.customer_inn}
                                                     onChange={setCustomer}
                                        />
                                    )}
                                    shouldUnregister
                        />
                    </Form.Group>
                }
            </Row>
            <Collapse in={isOpenFilterCollapse}>
                <Row className={'mb-3 g-3'}>
                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.invoices.table_header.created_at_start")}</Form.Label>
                        <Controller
                            name="created_at_start"
                            render={({field}) => (
                                <DatePicker
                                    isClearable={!predefinedFilters.hasOwnProperty("created_at_start")}
                                    className='form-control'
                                    placeholderText={t(lang, "roaming.invoices.table_header.created_at_start.placeholder")}
                                    disabled={predefinedFilters.hasOwnProperty("created_at_start")}
                                    dateFormat={dateFormat}
                                    selected={field.value}
                                    defaultValue={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.invoices.table_header.created_at_end")}</Form.Label>
                        <Controller
                            name="created_at_end"
                            render={({field}) => (
                                <DatePicker
                                    isClearable={!predefinedFilters.hasOwnProperty("created_at_end")}
                                    className='form-control'
                                    placeholderText={t(lang, "roaming.invoices.table_header.created_at_end.placeholder")}
                                    disabled={predefinedFilters.hasOwnProperty("created_at_end")}
                                    dateFormat={dateFormat}
                                    selected={field.value}
                                    defaultValue={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.invoices.table_header.contract_number")}</Form.Label>
                        <Form.Control name="contract_number"
                                      type="text"
                                      placeholder={t(lang, "roaming.invoices.table_header.contract_number.placeholder")}
                                      disabled={predefinedFilters.hasOwnProperty("contract_number")}
                                      {...register("contract_number")}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.invoices.table_header.contract_date")}</Form.Label>
                        <Controller
                            name="contract_date"
                            render={({field}) => (
                                <DatePicker
                                    isClearable={!predefinedFilters.hasOwnProperty("contract_date")}
                                    className='form-control'
                                    placeholderText={t(lang, "roaming.invoices.table_header.contract_date.placeholder")}
                                    disabled={predefinedFilters.hasOwnProperty("contract_date")}
                                    dateFormat={dateFormat}
                                    selected={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.invoices.datatable.filter.executor_branch_code")}</Form.Label>
                        <Controller name="executor_branch_code"
                                    render={({field}) => (
                                        <ReactSelect options={executorBranches}
                                                     classNamePrefix="react-select"
                                                     placeholder={t(lang, "roaming.common.select.placeholder")}
                                                     noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                                                     hideSelectedOptions
                                                     isClearable
                                                     value={executorBranch}
                                                     onChange={setExecutorBranch}
                                                     getOptionLabel={option => option?.system_code ? `${option.system_name} - (${option.system_code})` : option.name}
                                                     getOptionValue={option => option.number}
                                        />
                                    )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.invoices.datatable.filter.customer_branch_code")}</Form.Label>
                        <Controller name="customer_branch_code"
                                    render={({field}) => (
                                        <ReactSelect options={customerBranches}
                                                     classNamePrefix="react-select"
                                                     placeholder={t(lang, "roaming.common.select.placeholder")}
                                                     noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
                                                     hideSelectedOptions
                                                     value={customerBranch}
                                                     onChange={setCustomerBranch}
                                                     isClearable
                                                     getOptionLabel={option => option?.system_code ? `${option.system_name} - (${option.system_code})` : option.name}
                                                     getOptionValue={option => option.number}
                                        />
                                    )}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.invoices.datatable.filter.start_total_sum")}</Form.Label>
                        <Form.Control name="start_total_sum"
                                      type="text"
                                      placeholder={t(lang, "roaming.invoices.datatable.filter.start_total_sum.placeholder")}
                                      disabled={predefinedFilters.hasOwnProperty("start_total_sum")}
                                      {...register('start_total_sum')}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} lg={3}>
                        <Form.Label>{t(lang, "roaming.invoices.datatable.filter.end_total_sum")}</Form.Label>
                        <Form.Control name="end_total_sum"
                                      type="text"
                                      placeholder={t(lang, "roaming.invoices.datatable.filter.end_total_sum.placeholder")}
                                      {...register('end_total_sum')}
                        />
                    </Form.Group>
                </Row>

            </Collapse>
            <Row className="mb-3 g-3 justify-content-end">
                <Col md={12} lg={3} className="d-flex align-items-end justify-content-end gap-3">
                    <Button variant="falcon-primary" type='button' onClick={onResetFilter}>{t(lang, "items.common.clear_imported_file")}</Button>
                    <Button variant="primary" type='submit'>{t(lang, "roaming.invoices.table_header.show")}</Button>
                </Col>
            </Row>
        </>
    )
};

RoamingInvoiceDataTableFilter.propTypes = {
    predefinedFilters: PropTypes.object
}

RoamingInvoiceDataTableFilter.defaultProps = {
    predefinedFilters: {}
}

export default RoamingInvoiceDataTableFilter;
