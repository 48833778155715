import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SaleDatatable from "../../../../warehouse-operation/sale/datatable/SaleDatatable";
import {getSaleItemsAsync, getSaleItemsCountAsync, selectSaleFilterOptions} from "../../../../../../app/store/reducers/warehouse-operation/saleReducer";

const ContractorSales = ({contractorId}) => {
    const filters = useSelector(selectSaleFilterOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        loadContractorSales();
    }, [contractorId, filters])

    const predefinedFilters = useMemo(() => {
        return {
            contractor_id: contractorId,
            is_approved: null,
        };
    }, [contractorId])

    const loadContractorSales = () => {
        dispatch(getSaleItemsAsync({...filters, ...predefinedFilters}));
        dispatch(getSaleItemsCountAsync({...filters, ...predefinedFilters}));
    }

    return <SaleDatatable predefinedFilters={predefinedFilters}/>;
};

export default ContractorSales;