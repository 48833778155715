import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import {Col, Dropdown} from 'react-bootstrap';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleRight} from "@fortawesome/free-solid-svg-icons";
import Flex from "../../../common/Flex";

const ProfileDropdown = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    let dropdown = useRef()
    let dropdownMenu = useRef()

    function closeDropdown() {
        dropdown.current.classList.remove('show')
        dropdownMenu.current.classList.remove('show')
    }

    return (
        <Dropdown ref={dropdown} navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="p-1 nav-link text-center"
            >
                <FontAwesomeIcon icon={faCircleRight} className=" fs-4 text-facebook mb-1"/>
            </Dropdown.Toggle>

            <Dropdown.Menu ref={dropdownMenu} className="dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    <div className={'text-center fw-bold text-danger'}>
                        {t(lang, 'main.top.nav.profile_dropdown.dropdown_item.logout')}
                    </div>
                    <Flex>
                        <Col as={Link} to={'/authentication/logout'} className={'text-center text-decoration-none'}>
                            {t(lang, 'edi.common.select_yes_or_no.option.yes')}
                        </Col>
                        <Col onClick={closeDropdown} className={'text-center cursor-pointer text-secondary'}>
                            {t(lang, 'edi.common.select_yes_or_no.option.no')}
                        </Col>
                    </Flex>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
