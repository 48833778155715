import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import React, {Fragment, useState} from 'react';
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {GetOsExtension, OSTypeMacos, OsTypes} from "../../../enum/DesktopApp";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp, faPlus} from "@fortawesome/free-solid-svg-icons";
import {addNewDesktopAppVersionAsync} from "../../../app/store/reducers/desktop-app/desktopAppReducer";
import {toast} from "react-toastify";

const AddDesktopAppNewVersion = ({...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const {register, handleSubmit, formState: {errors}, control, watch, setValue, reset} = useForm({
        defaultValues: {
            version: "",
            os_type: null,
            is_active: false,
            file_content: null,
        }
    });
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const selectedOsType = watch('os_type');

    const handleShow = () => setShow(true);
    const handleClose = () => {
        reset();
        setSelectedFile(null);
        setShow(false);
    }

    const onSelectFile = event => {
        const [file] = event.target.files;
        const reader = new FileReader();
        setSelectedFile(file);

        reader.readAsDataURL(file)
        reader.onload = e => {
            setValue('file_content', e.target.result, {shouldValidate: true});
        }
        reader.onerror = err => {
            console.log(err)
            setValue('file_content', null, {shouldValidate: true});
            setSelectedFile(null);
        }
    }

    const onSubmit = formData => {
        setIsLoading(true);

        const payload = {
            version: formData.version,
            os_type: formData.os_type,
            is_active: formData.is_active,
            file_content: formData.file_content.split("base64,")[1],
        }

        addNewDesktopAppVersionAsync(payload)
            .then(() => {
                toast.success(t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.toast.failed')))
            .finally(() => setIsLoading(false))
    }

    return (
        <Fragment>
            <Button onClick={handleShow} {...props}>
                <FontAwesomeIcon className="me-2" icon={faPlus} />
                {t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.button')}
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as={Row}>
                        <Form.Group as={Col} xs={6} className="mt-3">
                            <Form.Label>{t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.field.version.title')}</Form.Label>
                            <Form.Control
                                className="input-spin-none"
                                isInvalid={errors.version}
                                {...register('version', {required: t(lang, "edi.common.forms.validations.is_required")})}
                                placeholder={t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.field.version.placeholder')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.version?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} xs={6} className="mt-3">
                            <Form.Label>{t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.field.os_type.title')}</Form.Label>
                            <Controller
                                name="os_type"
                                control={control}
                                rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                render={({field}) => (
                                    <ReactSelect
                                        isClearable
                                        options={OsTypes}
                                        hideSelectedOptions
                                        classNamePrefix="react-select"
                                        getOptionValue={option => option.type}
                                        getOptionLabel={option => option.label}
                                        value={OsTypes.find(os => os.type === field.value)}
                                        className={classNames({'is-invalid': errors.os_type})}
                                        onChange={option => field.onChange(option?.type || null)}
                                        placeholder={t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.field.os_type.placeholder')}
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.os_type?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} className="mt-4">
                            <Form.Switch {...register('is_active')} className="my-0" label={t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.field.is_active')}/>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Card className="my-3 shadow-none border-dashed bg-light">
                                <Controller
                                    control={control}
                                    name="file_content"
                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                    render={({}) => (
                                        <Form.Control
                                            type="file"
                                            id="fileUploader"
                                            className="d-none"
                                            onChange={onSelectFile}
                                            isInvalid={errors?.file_content}
                                            accept={GetOsExtension(selectedOsType)}
                                        />
                                    )}
                                />

                                <Card.Body as="label" htmlFor="fileUploader" className="text-center cursor-pointer py-4">
                                    <h4>
                                        <FontAwesomeIcon icon={faCloudArrowUp}/>
                                        <span className="ms-2">{t(lang, 'admin.desktop_app_versions.add_desktop_app_version_modal.choose_file')}</span>
                                        <span className="text-danger">*</span>
                                    </h4>
                                    <small className="text-danger my-0">{errors.file_content?.message}</small>
                                    {selectedFile && <span>{selectedFile?.name}</span>}
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>{t(lang, 'main.common.back')}</Button>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                            {t(lang, 'main.common.add')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default AddDesktopAppNewVersion;