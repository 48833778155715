export const EDI_VIEWER_CUSTOMER = "inbox";
export const EDI_VIEWER_EXECUTOR = "outbox";

export const getEdiViewerText = (viewer) => {
    switch (viewer) {
        case EDI_VIEWER_CUSTOMER:
            return "roaming.free_form_document.datatable.view_type.inbox"
        case EDI_VIEWER_EXECUTOR:
            return "roaming.free_form_document.datatable.view_type.outbox"
    }
}
