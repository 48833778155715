import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import React, {useEffect, useState} from "react";
import {
    getReturnPurchaseItemAsync, returnPurchaseApproveAsync, returnPurchasePaymentRegisterAsync,
    returnPurchasePaymentUpdateAsync
} from "../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {useHistory, useParams} from "react-router";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/returnPurchaseEvents";
import ReturnPurchaseExcelForm
    from "../../../../components/hippo/warehouse-operation/return-purchase/forms/ReturnPurchaseExcelForm";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Button, Card, Col, Row} from "react-bootstrap";
import WarehouseOperationPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationPayment";
import WarehouseOperationWithoutPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationWithoutPayment";
import SoftBadge from "../../../../components/common/SoftBadge";
import {
    isApprovedCheckedWarehouseOperation
} from "../../../../components/hippo/warehouse-operation/enum/warehouseOperationWrapper";
import {selectAllContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import {Link} from "react-router-dom";
import WOWithoutDiscountPayment
    from "../../../../components/hippo/warehouse-operation/components/WOWithoutDiscountPayment";


const EditReturnPurchaseExcel = () => {
    const [returnPurchase, setReturnPurchase] = useState(null)
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const contractors = useSelector(selectAllContractor)
    const history = useHistory()
    const items = useSelector(selectItems);
    const {id} = useParams()
    const lang = useSelector(selectLang)
    const t = Translate;


    const loadReturnPurchase = () => {
        getReturnPurchaseItemAsync({returnPurchaseId: id})
            .then(response => setReturnPurchase(response.data, response?.data?.items?.reverse()))
            .catch(error => setReturnPurchase(null))
    }

    useEffect(() => {
        loadReturnPurchase()
    }, [activeOrganization, id])


    useEffect(() => {
        const onChangeContractorHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onItemAddedHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, () => {
            loadReturnPurchase()
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS, onChangeContractorHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, onItemAddedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
        }
    }, [])


    const onSubmitPay = async (pay) => {
        if (returnPurchase?.payment) {
            await returnPurchasePaymentUpdateAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                    history.push(`../view/${pay?.id}`)
                })
                .catch(() => {})
        }

        if (!returnPurchase?.payment && returnPurchase?.is_approved) {
            await returnPurchasePaymentRegisterAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                    history.push(`../view/${pay?.id}`)
                })
                .catch(() => {})
        }

        if (!returnPurchase?.is_approved) {
            await returnPurchaseApproveAsync({returnPurchaseId: pay?.id, payment: pay?.payment || null})
                .then(() => {
                    history.push(`../view/${pay?.id}`)
                })
                .catch(() => {})
        }
    }


    if (!returnPurchase || contractors.length === 0 || items.length === 0)
        return <>loading...</>

    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <>currency loading...</>


    if (returnPurchase) {
        return(
            <>
                <Card>
                    <Card.Body>
                        <Row className="mb-2">
                            <Col xs={6}>
                                <h5>{t(lang, "warehouse.operation.item.common.new_return.purchase")}</h5>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end">
                                <div className="me-3">
                                    {/*<WarehouseOperationPayment onSubmitPay={onSubmitPay} data={returnPurchase} />*/}
                                    <WOWithoutDiscountPayment
                                        data={returnPurchase}
                                        onSubmitPay={onSubmitPay}
                                        isEditing={returnPurchase?.payment}
                                    />
                                    {!returnPurchase?.is_approved &&
                                        <WarehouseOperationWithoutPayment onClick={onSubmitPay} id={returnPurchase?.id}/>
                                    }
                                    <Button
                                        variant="falcon-info"
                                        title={t(lang, "warehouse.operation.item.common.button.save_draft")}
                                        size="sm"
                                        className="me-1"
                                        as={Link}
                                        to={`../view/${returnPurchase?.id}`}
                                    >{t(lang, "warehouse.operation.item.common.button.save_draft")}</Button>
                                </div>
                                <div>
                                    <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                    <SoftBadge bg={returnPurchase?.is_approved ? 'success' : 'warning'}>
                                        {t(lang, isApprovedCheckedWarehouseOperation(!!returnPurchase?.is_approved))}
                                    </SoftBadge>
                                </div>
                            </Col>
                        </Row>
                        <ReturnPurchaseExcelForm returnPurchase={returnPurchase} />
                    </Card.Body>
                </Card>
            </>
        )
    }
}

export default EditReturnPurchaseExcel;