import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadCompanyAddressesAsync} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import OrganizationAddressDataTable from "../../../../components/hippo/roaming/my-address/datatable/OrganizationAddressDataTable";
import {DELETE_COMPANY_ADDRESS_SUCCEED, REGISTER_COMPANY_ADDRESS_SUCCEED, UPDATE_COMPANY_ADDRESS_SUCCEED} from "../../../../app/eventbus/roamingReferenceEvents";

const OrganizationAddresses = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const dispatch = useDispatch();

    useEffect(() => {
        const onRegisterCompanyAddressSucceed = EventBus.on(REGISTER_COMPANY_ADDRESS_SUCCEED, () => {
            loadCompanyAddresses();
        });

        const onUpdateCompanyAddressSucceed = EventBus.on(UPDATE_COMPANY_ADDRESS_SUCCEED, () => {
            loadCompanyAddresses();
        });

        const onDeleteCompanyAddressSucceed = EventBus.on(DELETE_COMPANY_ADDRESS_SUCCEED, () => {
            loadCompanyAddresses();
        });

        return () => {
            EventBus.remove(REGISTER_COMPANY_ADDRESS_SUCCEED, onRegisterCompanyAddressSucceed);
            EventBus.remove(UPDATE_COMPANY_ADDRESS_SUCCEED, onUpdateCompanyAddressSucceed);
            EventBus.remove(DELETE_COMPANY_ADDRESS_SUCCEED, onDeleteCompanyAddressSucceed);
        }
    }, [])

    useEffect(() => {
        loadCompanyAddresses();
    }, [activeOrganization])

    const loadCompanyAddresses = () => {
        dispatch(loadCompanyAddressesAsync(activeOrganization.pinfl || activeOrganization.inn));
    };

    return <OrganizationAddressDataTable/>;
};

export default OrganizationAddresses;