import React, {useState} from 'react';
import {useSelector} from "react-redux";
import IconButton from '../../../common/IconButton';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {stopReturnOrderAsync} from "../../../../app/store/reducers/return-order/returnOrderReducer";

const ReturnOrderStop = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setLoading(false);
        setShow(false);
    };

    const stopReturnOrderHandler = () => {
        setLoading(true);

        stopReturnOrderAsync({
            id: id,
            comment: comment
        })
            .then(() => handleClose())
            .catch(error => {console.log(error)})
            .finally(() => setLoading(false))
    };

    return (
        <>
            <IconButton
                onClick={handleShow}
                variant="falcon-danger" 
                size="sm"
                iconClassName="me-1"
                icon={'pause'} 
                {...props}
            >{t(lang, 'edi.common.button.stop')}</IconButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'edi.return_order.stop.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t(lang, 'edi.common.label.comment')}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            placeholder={t(lang, 'edi.common.leave_comment')}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        {t(lang, 'edi.common.button.close')}
                    </Button>
                    <Button variant="danger" className="d-flex align-items-center" disabled={loading} onClick={stopReturnOrderHandler}>
                        {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                        {t(lang, 'edi.common.button.stop')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReturnOrderStop;