import React from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {Controller} from "react-hook-form";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const RoamingActFormCardDescription = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Card className="h-100">
            <Card.Body>
                <Row>
                    <Col xs={12}>
                        <Controller name="description"
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                    }}
                                    render={({field, fieldState: {error}}) => (
                                        <Form.Group>
                                            <Form.Label>
                                                {t(lang, "roaming.act_form_act_comment")}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control as="textarea"
                                                          name="description"
                                                          placeholder={t(lang, "roaming.act_form_act_comment")}
                                                          isInvalid={!!error}
                                                          value={field.value}
                                                          onChange={field.onChange}
                                            />
                                            {!!error && <Form.Control.Feedback
                                                type="invalid">{error?.message}</Form.Control.Feedback>}
                                        </Form.Group>
                                    )}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default RoamingActFormCardDescription;