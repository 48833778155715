import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import {useHistory, useRouteMatch} from "react-router-dom";
import Waybill from "../../../../components/roaming/waybill/view/Waybill";
import {SEND_WAYBILL_SUCCEED} from "../../../../app/eventbus/roaming/roamingWaybillEvents";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {loadDraftWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {selectCompanyCatalogs} from "../../../../app/store/reducers/roaming/roamingReducer";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingShipmentView,
} from "../../../../enum/Permission/RoamingPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";

const ViewDraftWaybill = ({modalId}) => {
    const routerHistory = useHistory();
    const {params: {id}} = useRouteMatch();
    const [content, setContent] = useState(null);
    const [draftWaybill, setDraftWaybill] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const catalogs = useSelector(selectCompanyCatalogs)
    const dispatch = useDispatch()
    const history = useHistory();
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingShipmentView))) {
            if (history.length > 1) {
                history.push('/roaming/waybills/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        const onSendWaybillSucceedHandler = EventBus.on(SEND_WAYBILL_SUCCEED, waybill => {
            routerHistory.push(`/roaming/waybill/${waybill.id}`)
            dispatch(getOrganizationBillingInfoAsync());
        });

        return () => {
            EventBus.remove(SEND_WAYBILL_SUCCEED, onSendWaybillSucceedHandler);
        }
    }, [])

    useEffect(() => {
        loadDraftWaybill();
    }, [modalId, id, activeOrganization])

    const loadDraftWaybill = () => {
        loadDraftWaybillAsync(modalId ? modalId : id)
            .then(loadedDraftWaybill => {
                const parsedContent = JSON.parse(loadedDraftWaybill.content);
                setContent(loadedDraftWaybill.content);
                customizeKeys(parsedContent)
            })
            .catch(error => console.log(error))
    }

    const customizeKeys = (waybill) => {
        return setDraftWaybill({
            id: modalId ? modalId : id,
            carrier: {
                inn: waybill.CarrierTin,
                name: waybill.CarrierName,
                branch: {
                    branch_code: waybill.CarrierBranchCode,
                    branch_name: waybill.CarrierBranchName,
                }
            },
            customer: {
                inn: waybill.CustomerTin,
                name: waybill.CustomerName,
                branch: {
                    branch_code: waybill.CustomerBranchCode,
                    branch_name: waybill.CustomerBranchName,
                }
            },
            seller: {
                inn: waybill.SellerTin,
                name: waybill.SellerName,
            },
            buyer: {
                inn: waybill.BuyerTin,
                name: waybill.BuyerName,
            },
            info: {
                number: waybill.WaybillDoc.WaybillNo,
                date: waybill.WaybillDoc.WaybillDate,
            },
            delivery_type: waybill.DeliveryType,
            contract_info: {
                number: waybill.ContractDoc.ContractNo,
                date: waybill.ContractDoc.ContractDate,
            },
            delivery_info: {
                number: waybill.TripTicketDoc.TripTicketNo,
                date: waybill.TripTicketDoc.TripTicketDate,
            },
            items: waybill.ProductList.Products.map(item => {
                const catalog = catalogs?.find(c => c?.class_code === item.CatalogCode)
                const packages = catalog?.package_names.find(p => p?.code === +item?.PackageCode)



                return {
                    name: item.Name,
                    measure_id: item.MeasureId,
                    packageCode: item.PackageCode,
                    packageName: item.PackageName,
                    catalogPackage: catalog,
                    packages: packages,
                    price: item.Summa,
                    quantity: item.Count,
                    total: item.TotalSum,
                    road_haulage_cost: item.RoadHaulageCost,
                    follow_documents: item.FollowDocuments,
                    method_define_weight: item.MethodDefineWeight,
                    load_class: item.LoadClass,
                    weight_brutto: item.WeightBrutto,
                    weight_netto: item.WeightNetto,
                    catalog: {
                        code: item.CatalogCode,
                        name: item.CatalogName
                    },
                }
            }),
            truck_info: {
                number: waybill.TruckDoc.TruckRegNo,
                model: waybill.TruckDoc.TruckModel,
            },
            trailer_info: {
                trailer_type: waybill.TrailerDoc.TrailerType,
                loader_info: {
                    number: waybill.TrailerDoc.TrailerRegNo,
                    model: waybill.TrailerDoc.TrailerModel,
                }
            },
            driver_fio: waybill.DriverFio,
            point_infos: waybill.PointDocs.map(point => {
                return {
                    point_of_loading: point.PointOfLoading,
                    point_of_unloading: point.PointOfUnloading,
                }
            }),
            point_of_redirect_name: waybill.PointOfRedirectName,
            point_of_redirect_address: waybill.PointOfRedirectAddress,
            special_notes: waybill.SpecialNotes,
            giver_fio: waybill.GiverFio,
            giver_driver_fio: waybill.GiverDriverFio,
            taker_driver_fio: waybill.TakerDriverFio,
            taker_fio: waybill.TakerFio,
            distance_info: {
                delivery_distance: waybill.DeliveryDistanceDoc.DeliveryDistance,
                delivery_distance_in_city: waybill.DeliveryDistanceDoc.DeliveryDistanceInCity,
            }
        });

    }

    return (
        <div>
            {!draftWaybill && <SpinnerComponent />}
            {draftWaybill && <Waybill waybill={draftWaybill} content={content} />}
        </div>
    );
};

export default ViewDraftWaybill;
