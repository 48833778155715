import {Image, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Slider from "react-slick";


const WarehouseOperationImageModal = ({image}) => {
    const [show, setShow] = useState(false)

    let slider1;
    let slider2;
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const handlerClose = () => {
        setShow(false)
    }
    const handlerShow = () => {
        setShow(true)
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true,
        autoplaySpeed: 2000,
    }

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);



    return(
        <div style={{maxWidth: "50px", maxHeight: "60px"}}>
            <img
                src={`${process.env.REACT_APP_HOST}/cdn/${image[0].path}`}
                alt={`${image[0].name}`}
                style={{minWidth: "35px", maxWidth: "50px", maxHeight: "38px", backgroundPosition: "center", objectFit: "contain"}}
                className="cursor-pointer"
                onClick={handlerShow}
            />

            <Modal show={show} size={"xl"} onHide={handlerClose} centered>
                <Modal.Body>
                    <div className="position-relative overflow-hidden">
                        <div className="d-flex align-items-center justify-content-center">
                            {image?.length === 1 && (
                                <Image
                                    fluid
                                    className="w-sm-100 h-sm-100 rounded"
                                    src={`${process.env.REACT_APP_HOST}/cdn/${image[0]?.path}`}
                                    alt={`${image[0]?.name}`}
                                    style={{
                                        maxWidth: "808px",
                                        maxHeight: "385px",
                                        backgroundPosition: "center",
                                        objectFit: "contain"
                                    }}
                                />
                            )}
                        </div>
                        {image.length > 1 && (
                            <>
                                <div className="product-slider">
                                    <Slider
                                        {...settings}
                                        asNavFor={nav2}
                                        ref={slider => (slider1 = slider)}
                                        className="slick-slider-arrow-inner h-100 full-height-slider"
                                    >
                                        {image?.map(img => (
                                            <div key={img?.id} className="d-flex align-items-center justify-content-center m-auto">
                                                <Image
                                                    fluid
                                                    rounded
                                                    className="w-sm-100 h-sm-100"
                                                    src={`${process.env.REACT_APP_HOST}/cdn/${img?.path}`}
                                                    alt={img?.name}
                                                    key={img?.id}
                                                    style={{
                                                        maxWidth: "808px",
                                                        maxHeight: "385px",
                                                        backgroundPosition: "center",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default WarehouseOperationImageModal;