import React, {cloneElement, Fragment, useState} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {
    ROAMING_ACT_DRAFT_DELETE_FAILED,
    ROAMING_ACT_DRAFT_DELETE_REQUESTED,
    ROAMING_ACT_DRAFT_DELETE_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingActEvents";
import EventBus from "../../../../../app/eventbus/EventBus";
import {deleteActDraftAsync} from "../../../../../app/store/reducers/roaming/roamingActReducer";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../common/IconButton";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import PropTypes from "prop-types";

const ActDraftDelete = ({id, children, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    // show/hide confirmation modal
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const deleteDraft = () => {
        setLoading(true);
        EventBus.dispatch(ROAMING_ACT_DRAFT_DELETE_REQUESTED, id);
        deleteActDraftAsync(id)
            .then(() => {
                setLoading(false);
                EventBus.dispatch(ROAMING_ACT_DRAFT_DELETE_SUCCESS, id);
                handleClose();
            })
            .catch(() => {
                EventBus.dispatch(ROAMING_ACT_DRAFT_DELETE_FAILED, id);
                setLoading(false);
            })

    }

    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: handleShow}) : (
                <IconButton onClick={handleShow} variant="falcon-danger" icon={faTrash}{...props}>
                    {t(lang, "roaming.invoice.delete")}
                </IconButton>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "roaming.invoice.delete_draft_modal_title")}
                    </Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "roaming.invoice.delete_draft_modal_body")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "roaming.common.cancel_button")}
                    </Button>
                    <Button variant="danger"
                            disabled={loading}
                            onClick={deleteDraft}
                    >
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                        {t(lang, "roaming.invoice.delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

ActDraftDelete.propTypes = {
    id: PropTypes.string,
}

ActDraftDelete.defaultProps = {
    id: "",
}

export default ActDraftDelete;