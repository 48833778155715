import React from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";

const SortSelector = ({defaultValue, onChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const options = [
        {label: t(lang, "items.common.sort.name"), id: "name", desc: true},
        {label: t(lang, "items.common.sort.name"), id: "name", desc: false},
        {label: t(lang, "items.common.sort.created_at"), id: "createdAt", desc: true},
        {label: t(lang, "items.common.sort.created_at"), id: "createdAt", desc: false},
        {label: t(lang, "items.common.sort.state"), id: "state", desc: true},
        {label: t(lang, "items.common.sort.state"), id: "state", desc: false},
        {label: t(lang, "items.common.purchase_price"), id: "purchasePrice", desc: true},
        {label: t(lang, "items.common.purchase_price"), id: "purchasePrice", desc: false},
        {label: t(lang, "items.common.price"), id: "commonPrice", desc: true},
        {label: t(lang, "items.common.price"), id: "commonPrice", desc: false},
        {label: t(lang, "items.common.bulk_price"), id: "bulkPrice", desc: true},
        {label: t(lang, "items.common.bulk_price"), id: "bulkPrice", desc: false},
        {label: t(lang, "items.common.sort.sku"), id: "sku", desc: true},
        {label: t(lang, "items.common.sort.sku"), id: "sku", desc: false},
    ];
    const uniqueOptions = options.filter((option) => !defaultValue?.find((v => v.id === option.id)));

    return (
        <ReactSelect isMulti
                     options={uniqueOptions}
                     isClearable={false}
                     hideSelectedOptions={true}
                     isSearchable={false}
                     placeholder={t(lang, "items.common.select")}
                     defaultValue={defaultValue?.map(dv => options?.find(option => option?.id === dv?.id && option?.desc === dv?.desc)) || []}
                     getOptionValue={option => `${option.id} ${option.desc}`}
                     getOptionLabel={option => `${option.label} ${option.desc ? `(${t(lang, "items.common.sort_by_desc.yes")})` : `(${t(lang, "items.common.sort_by_desc.no")})`}`}
                     onChange={onChange}
        />
    );
};

export default SortSelector;