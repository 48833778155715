import React from 'react';
import numeral from "numeral";
import Flex from "../../../common/Flex";
import {useSelector} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import Background from "../../../common/Background";
import cardImage from '../../../../assets/img/icons/spot-illustrations/corner-2.png';
import {selectOrganizationBillingInfo} from "../../../../app/store/reducers/billing/billingReducer";
import {selectLang, selectSystemMoneyFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";

const BalanceInfoCard = () => {
    const billingInfo = useSelector(selectOrganizationBillingInfo);
    const systemMoneyFormat = useSelector(selectSystemMoneyFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <Background image={cardImage} className="bg-card"/>
                    <Card.Body className="position-relative">
                        <Flex alignItems="center" justifyContent="between">
                            <div className="d-none d-sm-block">
                                <h4 className="text-black">{t(lang, 'main.billing.balance_info_card.organization')}</h4>
                                <div>
                                    <span className="mb-1">{t(lang, 'main.billing.balance_info_card.organization.name')}:</span>
                                    <span className="ms-1 text-black fw-bolder">{billingInfo?.name}</span>
                                </div>
                                <div>
                                    <span className="mb-1">{t(lang, 'main.billing.balance_info_card.organization.inn')}:</span>
                                    <span className="ms-1 text-black fw-bolder">{billingInfo?.inn}</span>
                                </div>
                            </div>
                            <div className="mx-auto text-center text-sm-start">
                                <h4>{t(lang, 'main.billing.balance_info_card.organization.total_balance')}</h4>
                                <div className="fw-medium">
                                    <span className="text-primary h3">
                                        {numeral.formats[systemMoneyFormat].format(billingInfo?.balance || 0)}
                                    </span>
                                    <span className="ms-1">{t(lang, 'main.billing.balance_info_card.organization.sum')}</span>
                                </div>
                            </div>
                        </Flex>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default BalanceInfoCard;