import React, {useState} from 'react'
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {deleteContractorPayoutAsync} from "../../../../../app/store/reducers/contractor/contractorPayoutReducer";

const DeleteContractorPayoutModal = ({id, onClose}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const onConfirm = () => {
        setLoading(true);
        deleteContractorPayoutAsync({id: id}).then(res => {
            onClose()
        }).catch(error => console.log(error)).finally(()=>{setLoading(false);})
    }
    return (
        <Modal
            show={true}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>{t(lang, 'edi.common.delete_button')}</Modal.Title>
                <FalconCloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>{t(lang, 'edi.common.delete_button')}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t(lang, 'edi.common.button.close')}
                </Button>
                <Button onClick={onConfirm} disabled={loading}>
                    {loading &&
                    <Spinner className="align-middle me-2" animation="border" role="switch"
                             size="sm"/>}
                    {t(lang, 'edi.common.delete_button')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteContractorPayoutModal