import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import EmpowermentForm from "../forms/EmpowermentForm";
import React, {useEffect} from "react";
import {
    loadCompanyProductCatalogsAsync, loadDistrictsAsync, loadMeasurementsAsync
} from "../../../../../app/store/reducers/roaming/roamingReducer";
import dayjs from "dayjs";
import {
    draftEmpowermentAsync,
    sendEmpowermentAsync
} from "../../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../../app/store/reducers/main/mainReducer";
import {useHistory} from "react-router-dom";
import EventBus from "../../../../../app/eventbus/EventBus";
import {
    EMPOWERMENT_DRAFT_FAILED,
    EMPOWERMENT_DRAFT_REQUESTED, EMPOWERMENT_DRAFT_SUCCESS,
    EMPOWERMENT_SEND_FAILED,
    EMPOWERMENT_SEND_REQUESTED,
    EMPOWERMENT_SEND_SUCCESS
} from "../../../../../app/eventbus/roaming/roamingEmpowermentEvents";
import {toast} from "react-toastify";




const roamingDayJSDateFormat = 'YYYY-MM-DD'

const DraftEmpowermentForm = ({draftEmpowerment}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const activeCertificate = useSelector(selectCurrentCertificate);
    const dispatch = useDispatch();
    const history = useHistory()
    const lang = useSelector(selectLang);
    const t = Translate;


    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang));
    }, [activeOrganization])

    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang));
        dispatch(loadDistrictsAsync(lang));
    },[])



    const methods = useForm({
        defaultValues: {
            empowermentInfo: {
                number: draftEmpowerment?.EmpowermentDoc?.EmpowermentNo,
                start: dayjs(draftEmpowerment?.EmpowermentDoc?.EmpowermentDateOfIssue, 'YYYY-MM-DD').toDate(),
                end: dayjs(draftEmpowerment?.EmpowermentDoc?.EmpowermentDateOfExpire, 'YYYY-MM-DD').toDate(),
            },
            contractInfo: {
                number: draftEmpowerment?.ContractDoc?.ContractNo,
                date: dayjs(draftEmpowerment?.ContractDoc?.ContractDate, 'YYYY-MM-DD').toDate(),
            },
            personInfo: {
                inn: draftEmpowerment?.Agent?.AgentTin,
                fullName: draftEmpowerment?.Agent?.Fio,
            },
            customerInfo: {
                inn: draftEmpowerment?.BuyerTin,
                name: draftEmpowerment?.Buyer?.Name,
                pinflOrInn: draftEmpowerment?.BuyerTin,
                address: draftEmpowerment?.Buyer?.Address,
                cellPhone: draftEmpowerment?.Buyer?.Mobile,
                housePhone: draftEmpowerment?.Buyer?.WorkPhone,
                oked: draftEmpowerment?.Buyer?.Oked,
                director: draftEmpowerment?.Buyer?.Director,
                accountant: draftEmpowerment?.Buyer?.Accountant,
                districtId: draftEmpowerment?.Buyer?.DistrictId,
            },
            executorInfo: {
                inn: draftEmpowerment?.SellerTin,
                name: draftEmpowerment?.Seller?.Name,
                pinflOrInn: draftEmpowerment?.SellerTin,
                address: draftEmpowerment?.Seller?.Address,
                cellPhone: draftEmpowerment?.Seller?.Mobile,
                housePhone: draftEmpowerment?.Seller?.WorkPhone,
                oked: draftEmpowerment?.Seller?.Oked,
                director: draftEmpowerment?.Seller?.Director,
                accountant: draftEmpowerment?.Seller?.Accountant,
                districtId: draftEmpowerment?.Seller?.DistrictId,
            },
            items: draftEmpowerment?.ProductList?.Products.map(item => {
                return  {
                    product: item.Name,
                    measurementId: item.MeasureId,
                    quantity: item.Count,
                    catalogClassCode: item.CatalogCode,
                    catalogClassName: item.CatalogName,
                }
            }),
        }
    });

    useEffect(() => {
        const onEmpowermentSentRequestedHandler = EventBus.on(EMPOWERMENT_SEND_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onEmpowermentSentSuccessHandler = EventBus.on(EMPOWERMENT_SEND_SUCCESS, empowerment => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.toast.empowerment_send_success'))
            history.push(`/roaming/empowerment/${empowerment.id}`)
        })

        const onEmpowermentSentFailedHandler = EventBus.on(EMPOWERMENT_SEND_FAILED, (error) => {
            dispatch(updateShowInProgressAsync(false))
            toast.error(error.message)
        })

        const onEmpowermentDraftRequestedHandler = EventBus.on(EMPOWERMENT_DRAFT_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onEmpowermentDraftSuccessHandler = EventBus.on(EMPOWERMENT_DRAFT_SUCCESS, () => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.act_form_save_success'))
            history.push(`/roaming/draft-empowerment/${draftEmpowerment?.EmpowermentId}`)
        })

        const onEmpowermentDraftFailedHandler = EventBus.on(EMPOWERMENT_DRAFT_FAILED, () => {
            dispatch(updateShowInProgressAsync(false))
            toast.success(t(lang, 'roaming.act_form_save_error'))
        })

        return () => {
            EventBus.remove(EMPOWERMENT_SEND_REQUESTED, onEmpowermentSentRequestedHandler);
            EventBus.remove(EMPOWERMENT_SEND_SUCCESS, onEmpowermentSentSuccessHandler);
            EventBus.remove(EMPOWERMENT_SEND_FAILED, onEmpowermentSentFailedHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_REQUESTED, onEmpowermentDraftRequestedHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_SUCCESS, onEmpowermentDraftSuccessHandler);
            EventBus.remove(EMPOWERMENT_DRAFT_FAILED, onEmpowermentDraftFailedHandler);
        }
    }, [])

    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name

        const EmpowermentForm = JSON.stringify({
            EmpowermentId: draftEmpowerment?.EmpowermentId,
            EmpowermentDoc: {
                EmpowermentNo: formData?.empowermentInfo?.number,
                EmpowermentDateOfIssue: formData?.empowermentInfo?.start && dayjs(formData?.empowermentInfo?.start).format(roamingDayJSDateFormat),
                EmpowermentDateOfExpire: formData?.empowermentInfo?.end && dayjs(formData?.empowermentInfo?.end).format(roamingDayJSDateFormat),
            },
            ContractDoc: {
                ContractNo: formData?.contractInfo?.number,
                ContractDate: formData?.contractInfo?.date && dayjs(formData?.contractInfo?.date).format(roamingDayJSDateFormat),
            },
            Agent: {
                AgentEmpowermentId: draftEmpowerment?.Agent?.AgentEmpowermentId,
                AgentTin: formData?.personInfo?.pinfl || formData?.personInfo?.inn,
                JobTitle: "",
                Fio: formData?.personInfo?.fullName || "",
                Passport: {
                    Number: "",
                    DateOfIssue: null,
                    IssuedBy: ""
                }
            },
            SellerTin: formData?.executorInfo?.pinflOrInn,
            BuyerTin: formData?.customerInfo?.pinflOrInn,
            Seller: {
                Name: formData?.executorInfo?.name || "",
                Address: formData?.executorInfo?.address || "",
                Mobile: formData?.executorInfo?.cellPhone || "",
                WorkPhone: formData?.executorInfo?.housePhone || "",
                Oked: formData?.executorInfo?.oked || "",
                DistrictId: formData?.executorInfo?.districtId,
                Director: formData?.executorInfo?.director || "",
                Accountant: formData?.executorInfo?.accountant || "",
            },
            Buyer: {
                Name: formData?.customerInfo?.name || "",
                Address: formData?.customerInfo?.address || "",
                Mobile: formData?.customerInfo?.cellPhone || "",
                WorkPhone: formData?.customerInfo?.housePhone || "",
                Oked: formData?.customerInfo?.oked || "",
                DistrictId: formData?.customerInfo?.districtId,
                Director: formData?.customerInfo?.director || "",
                Accountant: formData?.customerInfo?.accountant || "",
            },
            ProductList: {
                EmpowermentProductId: draftEmpowerment?.ProductList?.EmpowermentProductId,
                Tin: formData?.customerInfo?.pinflOrInn,
                Products: formData?.items.map((item, index) => {
                    return {
                        OrdNo: index + 1,
                        Name: item.product,
                        MeasureId: item.measurementId,
                        Count: +item.quantity,
                        CatalogCode: item.catalogClassCode,
                        CatalogName: item.catalogClassName
                    }
                })
            },
        })


        if(buttonType === "send") {
            if(activeCertificate?.keyId) {
                await sendEmpowermentAsync(activeCertificate, lang, EmpowermentForm)
            }
        } else if (buttonType === "draft") {
            await draftEmpowermentAsync(EmpowermentForm)
        }
    };



    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} id="EmpowermentForm">
                <EmpowermentForm draftEmpowerment={draftEmpowerment}/>
            </Form>
        </FormProvider>
    )
}


export default DraftEmpowermentForm;