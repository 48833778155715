import React from "react";
import {Redirect} from "react-router-dom";
import {selectIsAuthorized} from "../../../app/store/reducers/auth/authReducer";
import {useSelector} from "react-redux";
import ServiceChooser from "../../../components/hippo/dashboard/ServiceChooser";

const Dashboard = () => {
    const isAuthorized = useSelector(selectIsAuthorized);

    if (!isAuthorized)
        return <Redirect to="/authentication/login"/>

    return (
        <>
            <ServiceChooser/>
        </>
    );
}

export default Dashboard;