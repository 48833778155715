import React, {useEffect, useState} from 'react';
import {loadOrderAsync} from "../../../../app/store/reducers/order/orderReducer";
import AddShipmentForm from "../../../../components/hippo/shipment/AddShipmentForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {REGISTER_SHIPMENT_SUCCEED} from "../../../../app/eventbus/shipmentEvents";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {saveContractId} from "../../../../app/store/reducers/contract/contractReducer";
import {shipmentSaveProviders} from "../../../../app/store/reducers/shipment/shipmentReducer";
import {
    getOrganizationInfoAsync,
    loadCompanyProductCatalogsAsync,
    selectCompanyCatalogs
} from "../../../../app/store/reducers/roaming/roamingReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiShipmentCreate} from "../../../../enum/Permission/EdiPermission";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";

const AddShipment = () => {
    const catalogs = useSelector(selectCompanyCatalogs);
    const [order, setOrder] = useState(null)
    const [organizationInfo, setOrganizationInfo] = useState(null)
    const {params: {id}} = useRouteMatch();
    const routerHistory = useHistory();
    const dispatch = useDispatch();
    const history = useHistory()
    const permission = useSelector(selectAccountRolePermissions)
    const activeOrganization = useSelector(selectActiveOrganization)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionEdiShipmentCreate))) {
            if (history.length > 1) {
                history.push('/edi/orders')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        getOrganizationInfoAsync(activeOrganization.inn || activeOrganization.pinfl)
            .then((res) => setOrganizationInfo(res))
            .catch((e) => setOrganizationInfo(e))
    }, [activeOrganization])


    const loadOrder = () => {
        loadOrderAsync(id)
            .then(loadedOrder => {
                setOrder(loadedOrder)
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        loadOrder();
    }, [id])

    useEffect(() => {
        if (order)
            dispatch(loadCompanyProductCatalogsAsync(order.executor.inn));
    }, [order])

    useEffect(() => {
        const onShipmentAddedListener = EventBus.on(REGISTER_SHIPMENT_SUCCEED, shipment => {
            dispatch(saveContractId())
            dispatch(shipmentSaveProviders())
            routerHistory.push(`/edi/shipment/${shipment.id}`);
        });

        return () => {
            EventBus.remove(REGISTER_SHIPMENT_SUCCEED, onShipmentAddedListener)
        }
    }, []);

    return (
        <>
            {(order && catalogs.length > 0 && organizationInfo) && <AddShipmentForm order={order} organizationInfo={organizationInfo}/>}
            {(!order && !organizationInfo) && <span>loading....</span>}
        </>
    );
};

export default AddShipment;
