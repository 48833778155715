import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import {faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import OrganizationBindingForm from "./OrganizationBindingForm";

const AddOrganizationBindingModal = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <React.Fragment>
            <IconButton size="sm"
                        variant="falcon-primary"
                        icon={faPlus}
                        onClick={handleShow}
            >
                {t(lang, "partners.common.add_partner")}
            </IconButton>
            {show &&
                <Modal show={true} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'partners.common.add_partner')}</Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <OrganizationBindingForm handleClose={handleClose} />
                    </Modal.Body>
                </Modal>
            }
        </React.Fragment>
    );
};

export default AddOrganizationBindingModal;