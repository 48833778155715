import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import EventBus from "../../../../../app/eventbus/EventBus";
import MassRegisterRoamingWaybillForm from "./MassRegisterRoamingWaybillForm";
import {loadSettings} from "../../../../../app/store/reducers/settings/settingsReducer";
import {UPDATE_WAYBILL_REGISTRY_SETTINGS_SUCCEED} from "../../../../../app/eventbus/settingsEvents";
import {MASS_REGISTER_ROAMING_WAYBILL_SUCCEED} from "../../../../../app/eventbus/excelRegistryEvents";

const MassRegisterRoamingWaybill = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        loadWaybillRegistrySettings();

        const massRegisterRoamingWaybillSucceed = EventBus.on(MASS_REGISTER_ROAMING_WAYBILL_SUCCEED, registry => {
            history.push(`/roaming/excel-registry/${registry.id}`);
        });

        const updateWaybillRegistrySettingsSucceed = EventBus.on(UPDATE_WAYBILL_REGISTRY_SETTINGS_SUCCEED, () => {
            loadWaybillRegistrySettings();
        });

        return () => {
            EventBus.remove(MASS_REGISTER_ROAMING_WAYBILL_SUCCEED, massRegisterRoamingWaybillSucceed);
            EventBus.remove(UPDATE_WAYBILL_REGISTRY_SETTINGS_SUCCEED, updateWaybillRegistrySettingsSucceed);
        }
    }, [])

    const loadWaybillRegistrySettings = () => {
        dispatch(loadSettings());
    };

    return <MassRegisterRoamingWaybillForm/>;
};

export default MassRegisterRoamingWaybill;