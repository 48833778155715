import dayjs from "dayjs";
import {Link} from "react-router-dom";
import React, {useState} from 'react';
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {Col, Collapse, Form, Row} from "react-bootstrap";
import FreeFormDocumentDataTableFilter from "./FreeFormDocumentDataTableFilter";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    changeFreeFormsFilterOptions,
    selectFreeFormDocumentsCount, selectFreeFormDocumentsFilterOptions
} from "../../../../app/store/reducers/roaming/roamingFreeFormDocumentReducer";
import SoftBadge from "../../../common/SoftBadge";

const FreeFormDocumentDataTableHeader = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const freeFormDocumentCount = useSelector(selectFreeFormDocumentsCount)
    const filter = useSelector(selectFreeFormDocumentsFilterOptions);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            created_on_start: filter?.created_on_start && dayjs(filter?.created_on_start).toDate() || null,
            created_on_end: filter?.created_on_end && dayjs(filter?.created_on_end).toDate() || null,
            updated_on_start: filter?.updated_on_start && dayjs(filter?.updated_on_start).toDate() || null,
            updated_on_end: filter?.updated_on_end && dayjs(filter?.updated_on_end).toDate() || null,
            document_number: filter?.document_number || null,
            contractor_inn: filter?.contractor_inn || null,
            status: filter?.status || null,
        }
    });

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    const onSubmitFilter = ({document_number, created_on_start, created_on_end, updated_on_start, updated_on_end, contractor_inn, ...filters}) => {
        created_on_start = created_on_start && dayjs(created_on_start).format("YYYY-MM-DD");
        created_on_end = created_on_end && dayjs(created_on_end).format("YYYY-MM-DD");
        updated_on_start = updated_on_start && dayjs(updated_on_start).format("YYYY-MM-DD 00:00:00");
        updated_on_end = updated_on_end && dayjs(updated_on_end).format("YYYY-MM-DD 23:59:59");

        dispatch(changeFreeFormsFilterOptions({
            document_number: document_number || null,
            created_on_start: created_on_start,
            created_on_end: created_on_end,
            updated_on_start: updated_on_start,
            updated_on_end: updated_on_end,
            contractor_inn: contractor_inn || null,
            page: 1,
            ...filters,
        }));
    };

    return (
        <>
            <Row className="flex-between-center">
                <Collapse in={isCollapsed}>
                    <Col sm={12}>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                                <FreeFormDocumentDataTableFilter/>
                            </Form>
                        </FormProvider>
                        <hr/>
                    </Col>
                </Collapse>

                <Col xs={4} sm="auto" className="d-flex flex-column pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, 'roaming.free_form_document.datatable.title')}</h5>
                    <div className="mt-1">
                        {t(lang,"roaming.common.count")}:
                        <SoftBadge bg='success' className='ms-2'>
                            {freeFormDocumentCount}
                        </SoftBadge>
                    </div>
                </Col>

                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    <Link to="./send">
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, 'roaming.free_form_document.datatable.header.navigation.create')}
                                </span>
                        </IconButton>
                    </Link>

                    <IconButton
                        size="sm"
                        icon="refresh"
                        className="mx-2"
                        transform="shrink-3"
                        variant="falcon-default"
                        onClick={methods.handleSubmit(onSubmitFilter)}
                    >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, 'roaming.free_form_document.datatable.header.navigation.refresh')}
                            </span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        icon="filter"
                        transform="shrink-3"
                        variant="falcon-default"
                        onClick={toggleCollapse}
                    >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, 'roaming.free_form_document.datatable.header.navigation.filter')}
                            </span>
                    </IconButton>
                </Col>
            </Row>
        </>
    );
};

export default FreeFormDocumentDataTableHeader;
