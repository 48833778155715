import {Card} from "react-bootstrap";
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../app/eventbus/EventBus";
import AddEmployeeModal from "../../../components/employee/AddEmployeeModal";
import EditEmployeeModal from "../../../components/employee/EditEmployeeModal";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import DeleteEmployeeModal from "../../../components/employee/DeleteEmployeeModal";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {loadEmployeesAsync, selectEmployees} from "../../../app/store/reducers/employee/employeeReducer";
import {ADD_EMPLOYEE_SUCCEED, DELETE_EMPLOYEE_SUCCEED, EDIT_EMPLOYEE_SUCCEED} from "../../../app/eventbus/employeeEvents";

const Employees = () => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const employees = useSelector(selectEmployees);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    useEffect(() => {
        loadEmployees();
    }, [activeOrganization])

    useEffect(() => {
        const onEmployeeSuccessfullyAdded = EventBus.on(ADD_EMPLOYEE_SUCCEED, () => {
            loadEmployees();
        });

        const onEmployeeSuccessfullyEdited = EventBus.on(EDIT_EMPLOYEE_SUCCEED, () => {
           loadEmployees();
        });

        const onEmployeeSuccessfullyDeleted = EventBus.on(DELETE_EMPLOYEE_SUCCEED, () => {
            loadEmployees();
        });

        return () => {
            EventBus.remove(ADD_EMPLOYEE_SUCCEED, onEmployeeSuccessfullyAdded);
            EventBus.remove(EDIT_EMPLOYEE_SUCCEED, onEmployeeSuccessfullyEdited);
            EventBus.remove(DELETE_EMPLOYEE_SUCCEED, onEmployeeSuccessfullyDeleted);
        };
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 10,
            Cell: ({row}) => {
                return row.index + 1;
            },
        },
        {
            accessor: 'name',
            Header: t(lang, 'employee.datatable.column.name'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.name}</span>
                );
            }
        },
        {
            accessor: 'actions',
            Header: t(lang, 'employee.datatable.column.actions'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original: employee}}) => {
                const allowDeleteEmployee = !employee.account_id;
                return (
                    <div>
                        <EditEmployeeModal employee={employee}/>
                        {allowDeleteEmployee && <DeleteEmployeeModal employee={employee}/>}
                    </div>
                );
            }
        }
    ];

    const loadEmployees = () => {
        dispatch(loadEmployeesAsync())
    }

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={employees}
            width={'50px'}
            perPage={500}
            pagination
        >
            <Card className="mb-3">
                <Card.Header className={'d-flex flex-between-center'}>
                    <Card.Title>{t(lang, 'employee.datatable.header.title')}</Card.Title>
                    <AddEmployeeModal/>
                </Card.Header>
                <Card.Body>
                    <AdvanceTable
                        table
                        rowClassName="align-middle white-space-nowrap"
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden',
                        }}
                    />
                </Card.Body>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Employees;