import React, {useEffect, useState} from 'react';
import {Card, Tab, Tabs} from "react-bootstrap";
import CrmCustomerOrderDataTable
    from "../../../../components/hippo/crm/order/customer-orders/CrmCustomerOrderDataTable";
import CrmExecutorOrderDataTable
    from "../../../../components/hippo/crm/order/executor-orders/CrmExecutorOrderDataTable";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";

const CrmOrders = () => {
    const [activeTab, setActiveTab] = useState('executor_orders')
    const routerHistory = useHistory();

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    let activeTabFromRoute = queryParameters.get("activeTab");

    useEffect(() => {
        if (activeTabFromRoute)
            setActiveTab(activeTabFromRoute)
    }, [activeTabFromRoute])
    return <>
        <Card>
            <Card.Body>
                <Tabs variant={"pills"} activeKey={activeTab} id="crm_orders" onSelect={(tab) => {
                    routerHistory.push(`../crm/order?activeTab=${tab}`)
                    setActiveTab(tab)
                }}>
                    <Tab eventKey="executor_orders" title={'Заказы клиентов'}>
                        {activeTab === 'executor_orders' && <CrmExecutorOrderDataTable/>}
                    </Tab>
                    <Tab eventKey="customer_orders" title={"Заказы поставщику"}>
                        {activeTab === 'customer_orders' && <CrmCustomerOrderDataTable/>}
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    </>

};

export default CrmOrders;