import React, {useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import SelectDistrict from "../../../components/SelectDistrict";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import FindCompanySearchable from "../../../../organization/FindCompanySearchable";

const EmpowermentMember = ({title, root, inn, draftEmpowerment}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const {register, formState: {errors}, control, setValue} = useFormContext();
    const [districtId, setDistrictId] = useState(null);
    const [eventCount, setEventCount] = useState(0)


    const onChangeCompany = (event) => {
        setEventCount(eventCount + 1)

        setDistrictId(event ? event.district_id : null);
        setValue(`${root}.pinflOrInn`, event?.person_num || event?.inn);
        if (!draftEmpowerment || draftEmpowerment.status || eventCount > 1) {
            setValue(`${root}.name`, event?.name);
            setValue(`${root}.address`, event?.address);
            setValue(`${root}.director`, event?.director);
            setValue(`${root}.accountant`, event?.accountant);
        }
    };


    return (
        <Card>
            <Card.Header>
                <h4>{title}</h4>
            </Card.Header>
            <Card.Body className="bg-light">
                <Row>
                    <Col xs={12} xl={6} md={6} className="mb-2">
                        <Form.Label>{t(lang, "roaming.common.inn")}</Form.Label>
                        <FindCompanySearchable defaultInn={inn}
                                               disabled={root === "customerInfo" ? true : false}
                                               onChange={(company) => {
                                                   onChangeCompany(company);
                                               }}
                                               inputProps={{
                                                   placeholder: t(lang, "edi.toast.find_company_searchable:placeholder"),
                                               }}
                        />
                    </Col>
                    <Col xs={12} xl={6} md={6} className="mb-2">
                        <Form.Label>{t(lang, "roaming.common.inn_or_pinfl")}</Form.Label>
                        <Form.Control name={`${root}.pinflOrInn`}
                                      type="text"
                                      disabled
                                      isValid={Object.keys(errors).length > 0 && !errors[root]?.pinflOrInn}
                                      isInvalid={errors[root]?.pinflOrInn}
                                      {...register(`${root}.pinflOrInn`, {
                                          required: t(lang, 'roaming.validations.is_required'),
                                      })}
                        />
                        <Form.Control.Feedback type="invalid">{errors[root]?.pinflOrInn?.message}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12}>
                        <Form.Label>{title}</Form.Label>
                        <Form.Control name={`${root}.name`}
                                      type="text"
                                      placeholder={title}
                                      isValid={Object.keys(errors).length > 0 && !errors[root]?.name}
                                      {...register(`${root}.name`)}
                        />
                    </Col>
                    <Col xs={12} className="mb-2">
                        <Form.Label>{t(lang, "roaming.common.address")}</Form.Label>
                        <Form.Control name={`${root}.address`}
                                      type="text"
                                      placeholder={t(lang, "roaming.common.address.placeholder")}
                                      isValid={Object.keys(errors).length > 0 && !errors[root]?.address}
                                      {...register(`${root}.address`)}
                        />
                    </Col>
                    <Col xs={12} className="mb-2">
                        <Form.Label>{t(lang, "roaming.common.district")}</Form.Label>
                        <Controller control={control}
                                    name={`${root}.districtId`}
                                    rules={{
                                        required: t(lang, 'roaming.validations.is_required'),
                                    }}
                                    render={({field}) => (
                                        <SelectDistrict classNamePrefix="react-select"
                                                        className={classNames({
                                                            "is-invalid": errors[root]?.districtId,
                                                        })}
                                                        onChange={(district) => {
                                                            setDistrictId(district?.districtId || null);
                                                            field.onChange(district?.districtId);
                                                        }}
                                                        placeholder={t(lang, "roaming.common.select.placeholder")}
                                                        isClearable
                                                        defaultDistrictId={districtId}
                                        />
                                    )}
                        />
                        <Form.Control.Feedback
                            type="invalid">{errors[root]?.districtId?.message}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} sm={6} className="mb-2">
                        <Form.Label>{t(lang, "roaming.common.cellPhone")}</Form.Label>
                        <Form.Control name={`${root}.cellPhone`}
                                      type="text"
                                      placeholder={t(lang, "roaming.common.cellPhone.placeholder")}
                                      isValid={Object.keys(errors).length > 0 && !errors[root]?.cellPhone}
                                      {...register(`${root}.cellPhone`)}
                        />
                    </Col>
                    <Col xs={12} sm={6} className="mb-2">
                        <Form.Label>{t(lang, "roaming.common.housePhone")}</Form.Label>
                        <Form.Control name={`${root}.housePhone`}
                                      type="text"
                                      placeholder={t(lang, "roaming.common.housePhone.placeholder")}
                                      isValid={Object.keys(errors).length > 0 && !errors[root]?.housePhone}
                                      {...register(`${root}.housePhone`)}
                        />
                    </Col>
                    <Col xs={12} xl={6} md={6} className="mb-2">
                        <Form.Label>{t(lang, "roaming.common.director")}</Form.Label>
                        <Form.Control name={`${root}.director`}
                                      type="text"
                                      placeholder={t(lang, "roaming.common.director.placeholder")}
                                      isValid={Object.keys(errors).length > 0 && !errors[root]?.director}
                                      {...register(`${root}.director`)}
                        />
                    </Col>
                    <Col xs={12} xl={6} md={6}>
                        <Form.Label>{t(lang, "roaming.common.accountant")}</Form.Label>
                        <Form.Control name={`${root}.accountant`}
                                      type="text"
                                      placeholder={t(lang, "roaming.common.accountant.placeholder")}
                                      isValid={Object.keys(errors).length > 0 && !errors[root]?.accountant}
                                      {...register(`${root}.accountant`)}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
};

export default EmpowermentMember;
