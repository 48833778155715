import { useSelector } from "react-redux"
import { Card, Col, Form, Row } from "react-bootstrap"
import { Translate, selectLang, selectDateFormat } from "../../../../../app/store/reducers/main/mainReducer"
import { useFormContext, Controller } from "react-hook-form"
import DatePicker from "react-datepicker";

const ContractInfo = () => {
    const { register, formState: { errors }, control } = useFormContext()
    const dateFormat = useSelector(selectDateFormat)
    const lang = useSelector(selectLang)
    const t = Translate
    return (
        <Card>
            <Card.Header>
                <h5 className="mb-0">{t(lang, "roaming.contracts.add.contract_info.title")}</h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col sm="6">
                        <Row>
                            <Col sm="6" className="mb-3">
                                <Form.Label>{t(lang, "roaming.contracts.add.contract_info.name")}</Form.Label>
                                <Form.Control
                                    {...register("contractDoc.contractName", {
                                        // required: "edi.common.forms.validations.is_required"
                                    })} 
                                    className={errors?.contractDoc?.contractName?.message && "is-invalid"}
                                    placeholder={t(lang, "roaming.contracts.add.contract_info.name")} 
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors?.contractDoc?.contractName?.message)}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm="6">
                            </Col>
                            <Col sm="6" className="mb-3">
                                <Form.Label>{t(lang, "roaming.contracts.add.contract_info.contract_number")}</Form.Label>
                                <Form.Control
                                    {...register("contractDoc.contractNo", {
                                        // required: "edi.common.forms.validations.is_required"
                                    })} 
                                    className={errors?.contractDoc?.contractNo?.message && "is-invalid"}
                                    placeholder={t(lang, "roaming.contracts.add.contract_info.contract_number")}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors?.contractDoc?.contractNo?.message)}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm="6" className="mb-3">
                                <Form.Label>{t(lang, "roaming.contracts.add.contract_info.place_conclusion")}</Form.Label>
                                <Form.Control
                                    {...register("contractDoc.contractPlace", {
                                        // required: "edi.common.forms.validations.is_required"
                                    })} 
                                    className={errors?.contractDoc?.contractPlace?.message && "is-invalid"}
                                    placeholder={t(lang, "roaming.contracts.add.contract_info.place_conclusion")}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors?.contractDoc?.contractPlace?.message)}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm="6" className="mb-3">
                                <Form.Label>{t(lang, "roaming.contracts.add.contract_info.date_conclusion")}</Form.Label>
                                <Controller
                                    control={control}
                                    name="contractDoc.contractDate"
                                    rules={{
                                        required: "edi.common.forms.validations.is_required"
                                    }}
                                    render={({field}) => (
                                        <DatePicker
                                            onChange={field.onChange}
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                            wrapperClassName={errors?.contractDoc?.contractExpireDate?.message && "is-invalid"}
                                            className={`${errors?.contractDoc?.contractDate?.message && "is-invalid"} form-control`}
                                            placeholderText={t(lang, "edi.common.contract.date.placeholder")}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors?.contractDoc?.contractDate?.message)}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm="6" className="mb-3">
                                <Form.Label>{t(lang, "roaming.contracts.add.contract_info.expiring_on")}</Form.Label>
                                <Controller 
                                    control={control}
                                    name="contractDoc.contractExpireDate"
                                    rules={{
                                        // required: "edi.common.forms.validations.is_required"
                                    }}
                                    render={({field}) => (
                                        <DatePicker
                                            onChange={field.onChange}
                                            dateFormat={dateFormat}
                                            selected={field.value}
                                            wrapperClassName={errors?.contractDoc?.contractExpireDate?.message && "is-invalid"}
                                            className={`${errors?.contractDoc?.contractExpireDate?.message && "is-invalid"} form-control`}
                                            placeholderText={t(lang, "edi.common.contract.date.placeholder")}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t(lang, errors?.contractDoc?.contractExpireDate?.message)}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ContractInfo