import React, {useEffect, useState} from "react"
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {Button, Card, Col, Form, Row} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux"
import {Translate, selectLang} from "../../../../app/store/reducers/main/mainReducer"
import {generateIdAsync, loadCompanyProductCatalogsAsync} from "../../../../app/store/reducers/roaming/roamingReducer";
import {send, save} from "../../../../app/store/reducers/roaming/roamingContractReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer"
import ContractInfo from "./contract-form/ContractInfo";
import OwnerInfo from "./contract-form/OwnerInfo";
import Products from "./contract-form/Products";
import ClientInfo from "./contract-form/ClientInfo";
import Parts from "./contract-form/Parts";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import dayjs from "dayjs";
import {useHistory} from "react-router";
import SignActionWithSelectCertificatWrapper from "../components/SignActionWithSelectCertificatWrapper";


const ContractForm = ({contract}) => {
  const methods = useForm({
    defaultValues: {
      contractId: "",
      hasVat: false,
      owner: {
        tin: "",
        name: "",
        address: "",
        workPhone: "",
        mobile: "",
        fax: "",
        oked: "",
        account: "",
        bankId: "",
        fizTin: "",
        fio: "",
        branchCode: "",
        branchName: ""
      },
      clients: [
        {
          tin: "",
          name: "",
          address: "",
          workPhone: "",
          mobile: "",
          fax: "",
          oked: "",
          account: "",
          bankId: "",
          fizTin: "",
          fio: "",
          branchCode: "",
          branchName: ""
        }
      ],
      contractDoc: {
        contractName: "",
        contractNo: "",
        contractDate: "",
        contractExpireDate: "",
        contractPlace: ""
      },
      products: [
        {
          ordNo: 1,
          catalogCode: null,
          catalogName: null,
          barCode: "",
          name: "",
          measureId: "",
          packageCode: null,
          packageName: null,
          packages: null,
          count: null,
          summa: null,
          deliverySum: null,
          vatRate: 0,
          vatSum: null,
          deliverySumWithVat: null,
          withoutVat: false
        }
      ],
      parts: [
        {
          ordNo: 1,
          title: "",
          body: ""
        }
      ]
    }
  });
  const {fields : fieldsClient, append : appendClient, remove : removeClient } = useFieldArray({ control: methods.control, name: "clients", focusAppend: false })
  const {fields : fieldsProduct, append : appendProduct, remove : removeProduct } = useFieldArray({ control: methods.control, name: "products", focusAppend: false })
  const {fields : fieldsPart, append : appendPart, remove : removePart } = useFieldArray({ control: methods.control, name: "parts", focusAppend: false })
  const activeOrganization = useSelector(selectActiveOrganization)
  const currentCertificate = useSelector(selectCurrentCertificate);
  const lang = useSelector(selectLang)
  const history = useHistory()
  const dispatch = useDispatch()
  const t = Translate

    const [loading, setLoading] = useState(false);

  const addFieldClient = () => {
    appendClient({
      tin: "",
      name: "",
      address: "",
      workPhone: "",
      mobile: "",
      fax: "",
      oked: "",
      account: "",
      bankId: "",
      fizTin: "",
      fio: "",
      branchCode: "",
      branchName: ""
    })
  }
  const removeFieldClient = index => {
    removeClient(index)
  }

  const addFieldProduct = () => {
    appendProduct({
      ordNo: fieldsProduct.length,
      catalogCode: null,
      catalogName: null,
      barCode: "",
      name: "",
      measureId: "",
      packageCode: null,
      packageName: null,
      packages: null,
      count: null,
      summa: null,
      deliverySum: null,
      vatRate: 0,
      vatSum: null,
      deliverySumWithVat: null,
      withoutVat: false
    })
  }
  const removeFieldProduct = index => {
    removeProduct(index)
  }
  const addFieldPart = () => {
    appendPart({
      ordNo: fieldsPart.length,
      title: "",
      body: "",
    })
  }
  const removeFieldPart = index => {
    removePart(index)
  }

  const signAndSend = async (data) => {
    let contract = {
      ContractId: data?.contractId || (await generateIdAsync(1))[0],
      HasVat: data.products.some((product => !product.withoutVat)),
      ContractDoc: {
        ContractName: data.contractDoc.contractName,
        ContractNo: data.contractDoc.contractNo,
        ContractDate: dayjs(data.contractDoc.contractDate).format("YYYY-MM-DD") || null,
        ContractExpireDate: dayjs(data.contractDoc.contractExpireDate).format("YYYY-MM-DD") || null,
        ContractPlace: data.contractDoc.contractPlace
      },
      Owner: {
        Tin: data.owner.tin,
        Name: data.owner.name,
        Address: data.owner.address,
        WorkPhone: data.owner.workPhone,
        Mobile: data.owner.mobile,
        Fax: data.owner.fax,
        Oked: data.owner.oked,
        Account: data.owner.account,
        BankId: data.owner.bankId,
        FizTin: data.owner.fizTin,
        Fio: data.owner.fio,
        BranchCode: data.owner.branchCode,
        BranchName: data.owner.branchName
      },
      Clients: data.clients.map(client => ({
        Tin: client.tin,
        Name: client.name,
        Address: client.address,
        WorkPhone: client.workPhone,
        Mobile: client.mobile,
        Fax: client.fax,
        Oked: client.oked,
        Account: client.account,
        BankId: client.bankId,
        FizTin: client.fizTin,
        Fio: client.fio,
        BranchCode: client.branchCode,
        BranchName: client.branchName
      })),
      Products: data.products.map((product, index) => ({
        OrdNo: index+1,
        CatalogCode: product.catalogCode,
        CatalogName: product.catalogName,
        BarCode: product.barCode,
        Name: product.name,
        MeasureId: product.measureId,
        PackageCode: product.packageCode ? `${product.packageCode}` : null,
        PackageName: product.packageName || null,
        Count: +product.count,
        Summa: +product.summa,
        DeliverySum: +product.deliverySum,
        VatRate: +product.vatRate,
        VatSum: +product.vatSum,
        DeliverySumWithVat: +product.deliverySumWithVat,
        WithoutVat: product.withoutVat
      })),
      Parts: data.parts.map((part, index) => ({
        OrdNo: index+1,
        Title: part.title,
        Body: part.body
      }))
    }
    let {data: responseContract} = await send({
      contract: contract,
      certificate: currentCertificate,
      lang: lang
    })
    return responseContract
  }
  const saveDraft = async (data) => {
    let contract = {
      ContractId: data?.contractId || (await generateIdAsync(1))[0],
      HasVat: data.products.some((product => !product.withoutVat)),
      ContractDoc: {
        ContractName: data.contractDoc.contractName,
        ContractNo: data.contractDoc.contractNo,
        ContractDate: dayjs(data.contractDoc.contractDate).format("YYYY-MM-DD") || null,
        ContractExpireDate: dayjs(data.contractDoc.contractExpireDate).format("YYYY-MM-DD") || null,
        ContractPlace: data.contractDoc.contractPlace
      },
      Owner: {
        Tin: data.owner.tin,
        Name: data.owner.name,
        Address: data.owner.address,
        WorkPhone: data.owner.workPhone,
        Mobile: data.owner.mobile,
        Fax: data.owner.fax,
        Oked: data.owner.oked,
        Account: data.owner.account,
        BankId: data.owner.bankId,
        FizTin: data.owner.fizTin,
        Fio: data.owner.fio,
        BranchCode: data.owner.branchCode,
        BranchName: data.owner.branchName
      },
      Clients: data.clients.map(client => ({
        Tin: client.tin,
        Name: client.name,
        Address: client.address,
        WorkPhone: client.workPhone,
        Mobile: client.mobile,
        Fax: client.fax,
        Oked: client.oked,
        Account: client.account,
        BankId: client.bankId,
        FizTin: client.fizTin,
        Fio: client.fio,
        BranchCode: client.branchCode,
        BranchName: client.branchName
      })),
      Products: data.products.map((product, index) => ({
        OrdNo: index+1,
        CatalogCode: product.catalogCode,
        CatalogName: product.catalogName,
        BarCode: product.barCode,
        Name: product.name,
        MeasureId: product.measureId,
        PackageCode: product.packageCode ? `${product.packageCode}` : null,
        PackageName: product.packageName || null,
        Count: +product.count,
        Summa: +product.summa,
        DeliverySum: +product.deliverySum,
        VatRate: +product.vatRate,
        VatSum: +product.vatSum,
        DeliverySumWithVat: +product.deliverySumWithVat,
        WithoutVat: product.withoutVat
      })),
      Parts: data.parts.map((part, index) => ({
        OrdNo: index+1,
        Title: part.title,
        Body: part.body
      }))
    }
    let {data: responseContract} = await save({
      contract: contract,
      certificate: currentCertificate,
      lang: lang
    })
    return responseContract
  }
  const onSubmit = async (data, event) => {
    try {
      setLoading(true)
      let { name } = event.nativeEvent.submitter
      if(name === "draft") {
        let response = await saveDraft(data)
        if(response?.id) history.push(`/roaming/contract/draft/${response.id}`)
      } else if (name === "sign" && currentCertificate?.keyId) {
        let response = await signAndSend(data)
        if(response?.id) history.push(`/roaming/contract/${response.id}`)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

    useEffect(() => {
        let contractKeyProperty = contract && Object.keys(contract)
        if (contractKeyProperty?.length) {
            contractKeyProperty.forEach(key => {
                methods.setValue(key, contract[key])
            })
        }
    }, [contract]);

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
    }, [activeOrganization.inn]);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)} id="ContractForm">
                <Row>
                    <Col sm="12" className="mb-4">
                        <ContractInfo/>
                    </Col>
                    <Col sm="6" className="mb-4">
                        <OwnerInfo/>
                    </Col>
                    {fieldsClient.map((client, index) => (
                        <Col sm="6" className="mb-4" key={client.id}>
                            <ClientInfo index={index} addFieldClient={addFieldClient}
                                        removeFieldClient={removeFieldClient}/>
                        </Col>
                    ))}
                    <Col sm="12" className="mb-4">
                        <Products fieldsProduct={fieldsProduct} addFieldProduct={addFieldProduct}
                                  removeFieldProduct={removeFieldProduct}/>
                    </Col>
                    <Col sm="12" className="mb-4">
                        <Parts fieldsPart={fieldsPart} addFieldPart={addFieldPart} removeFieldPart={removeFieldPart}/>
                    </Col>
                    <Col sm="12">
                        <Card className="border-primary border-top-2">
                            <Card.Body className="text-end">
                                <Button name="draft" variant="falcon-warning" size="lg" type="submit">
                                    {t(lang, "Сохранить в черновик")}
                                </Button>
                                <SignActionWithSelectCertificatWrapper isForm>
                                    <Button
                                        name="sign"
                                        variant="falcon-primary"
                                        className="ms-3"
                                        size="lg"
                                        type="submit"
                                        form="ContractForm"
                                    >{t(lang, "roaming.contracts.add.sign_with_send")}
                                    </Button>
                                </SignActionWithSelectCertificatWrapper>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    )
}

export default ContractForm
