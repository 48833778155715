import React, {Fragment} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import Cleave from "cleave.js/react";
import classNames from "classnames";

const RoamingInvoiceFullPrice = () => {

    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Fragment>
            <Row className={'mt-1'}>
                <Col xs={12} md={4}>
                    <Card>
                        <Card.Header className={'pb-0'}>
                            <h5>{t(lang, 'roaming.invoice.full_price.price_total')}</h5>
                        </Card.Header>
                        <Card.Body className={'pt-1'}>
                            <RoamingInvoiceFullPriceForm fieldName={'priceTotal'} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4}>
                    <Card>
                        <Card.Header className={'pb-0'}>
                            <h5>{t(lang, 'roaming.invoice.full_price.vat')}</h5>
                        </Card.Header>
                        <Card.Body className={'pt-1'}>
                            <RoamingInvoiceFullPriceForm fieldName={'vat'} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4}>
                    <Card>
                        <Card.Header className={'pb-0'}>
                            <h5>{t(lang, 'roaming.invoice.full_price.price_with_vat')}</h5>
                        </Card.Header>
                        <Card.Body className={'pt-1'}>
                            <RoamingInvoiceFullPriceForm fieldName={'priceWithVat'} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RoamingInvoiceFullPrice;


const RoamingInvoiceFullPriceForm = ({fieldName}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const {formState: {errors}, control} = useFormContext()

    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    {t(lang, 'roaming.invoice.full_price.contract_price_totally')}
                    <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                    control={control}
                    name={`${fieldName}.contractPricesTotally`}
                    render={({field}) => (
                        <Cleave
                            // disabled={true}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3
                            }}
                            value={field.value}
                            placeholder={t(lang, 'roaming.invoice.full_price.contract_price_totally.placeholder')}
                            className={classNames('form-control', {
                                'is-invalid': errors?.[fieldName]?.contractPricesTotally,
                            })}
                            onChange={val => {
                                let v = val.target.value ? val.target.value.replace(/-/g, '') : val.target.value
                                field.onChange(v)
                            }}
                        />
                    )}
                />
                <Form.Control.Feedback type={'invalid'}>{errors?.[fieldName]?.contractPricesTotally?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(lang, 'roaming.invoice.full_price.contract_price_current_year')}
                    <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                    control={control}
                    name={`${fieldName}.contractPricesCurrentYear`}
                    render={({field}) => (
                        <Cleave
                            // disabled={true}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3
                            }}
                            value={field.value}
                            placeholder={t(lang, 'roaming.invoice.full_price.contract_price_totally.placeholder')}
                            className={classNames('form-control', {
                                'is-invalid': errors?.[fieldName]?.contractPricesCurrentYear,
                            })}
                            onChange={val => {
                                let v = val.target.value ? val.target.value.replace(/-/g, '') : val.target.value
                                field.onChange(v)
                            }}
                        />
                    )}
                />
                <Form.Control.Feedback type={'invalid'}>{errors?.[fieldName]?.contractPricesCurrentYear?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(lang, 'roaming.invoice.full_price.contract_works_form_start')}
                    <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                    control={control}
                    name={`${fieldName}.completedWorksFromStart`}
                    render={({field}) => (
                        <Cleave
                            // disabled={true}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3
                            }}
                            value={field.value}
                            placeholder={t(lang, 'roaming.invoice.full_price.contract_works_form_start.placeholder')}
                            className={classNames('form-control', {
                                'is-invalid': errors?.[fieldName]?.completedWorksFromStart,
                            })}
                            onChange={val => {
                                let v = val.target.value ? val.target.value.replace(/-/g, '') : val.target.value
                                field.onChange(v)
                            }}
                        />
                    )}
                />
                <Form.Control.Feedback type={'invalid'}>{errors?.[fieldName]?.completedWorksFromStart?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(lang, 'roaming.invoice.full_price.contract_works_current_year')}
                    <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                    control={control}
                    name={`${fieldName}.completedWorksCurrentYear`}
                    render={({field}) => (
                        <Cleave
                            // disabled={true}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3
                            }}
                            value={field.value}
                            placeholder={t(lang, 'roaming.invoice.full_price.contract_works_form_start.placeholder')}
                            className={classNames('form-control', {
                                'is-invalid': errors?.[fieldName]?.completedWorksCurrentYear,
                            })}
                            onChange={val => {
                                let v = val.target.value ? val.target.value.replace(/-/g, '') : val.target.value
                                field.onChange(v)
                            }}
                        />
                    )}
                />
                <Form.Control.Feedback type={'invalid'}>{errors?.[fieldName]?.completedWorksCurrentYear?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t(lang, 'roaming.invoice.full_price.contract_works_current_month')}
                    <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                    control={control}
                    name={`${fieldName}.completedWorksCurrentMonth`}
                    render={({field}) => (
                        <Cleave
                            // disabled={true}
                            options={{
                                numeral: true,
                                delimiter: ' ',
                                numeralDecimalScale: 3
                            }}
                            value={field.value}
                            placeholder={t(lang, 'roaming.invoice.full_price.contract_works_form_start.placeholder')}
                            className={classNames('form-control', {
                                'is-invalid': errors?.[fieldName]?.completedWorksCurrentMonth,
                            })}
                            onChange={val => {
                                let v = val.target.value ? val.target.value.replace(/-/g, '') : val.target.value
                                field.onChange(v)
                            }}
                        />
                    )}
                />
                <Form.Control.Feedback>{errors?.[fieldName]?.completedWorksCurrentMonth?.message}</Form.Control.Feedback>
            </Form.Group>
        </Fragment>
    )
}
