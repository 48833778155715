export const REGISTER_CRM_INTERNAL_ORDER_REQUESTED = 'REGISTER_CRM_INTERNAL_ORDER_REQUESTED';
export const REGISTER_CRM_INTERNAL_ORDER_SUCCESS = 'REGISTER_CRM_INTERNAL_ORDER_SUCCESS';
export const REGISTER_CRM_INTERNAL_ORDER_FAILED = 'REGISTER_CRM_INTERNAL_ORDER_FAILED';

export const EDIT_CRM_INTERNAL_ORDER_REQUESTED = 'EDIT_CRM_INTERNAL_ORDER_REQUESTED';
export const EDIT_CRM_INTERNAL_ORDER_SUCCESS = 'EDIT_CRM_INTERNAL_ORDER_SUCCESS';
export const EDIT_CRM_INTERNAL_ORDER_FAILED = 'EDIT_CRM_INTERNAL_ORDER_FAILED';

export const DELETE_CRM_INTERNAL_ORDER_REQUESTED = 'DELETE_CRM_INTERNAL_ORDER_REQUESTED'
export const DELETE_CRM_INTERNAL_ORDER_SUCCESS = 'DELETE_CRM_INTERNAL_ORDER_SUCCESS'
export const DELETE_CRM_INTERNAL_ORDER_FAILED = 'DELETE_CRM_INTERNAL_ORDER_FAILED'
