import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {excelFileUploader} from "../../../../helpers/excel-uploader";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectFilterOptions, uploadReturnOrdersToExcelAsync, uploadReturnOrdersWithItemsToExcelAsync} from "../../../../app/store/reducers/return-order/returnOrderReducer";
import {selectOrganizationTemplates} from "../../../../app/store/reducers/excel/excelReducer";
import {TYPE_RETURN_ORDERS, TYPE_RETURN_ORDERS_WITH_ITEMS, TYPE_SHIPMENTS, TYPE_SHIPMENTS_WITH_ITEMS} from "../../../../enum/ExcelTemplateTypes";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionEdiReturnOrderDownloadExcel,
    PermissionEdiReturnShipmentDownloadExcel
} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";

const UploadReturnOrderListToExcel = ({...props}) => {
    const dispatch = useDispatch()

    const [returnOrdersWithItemsTypeTemplates, setReturnOrdersWithItemsTypeTemplates] = useState([]);
    const [returnOrdersTypeTemplates, setReturnOrdersTypeTemplates] = useState([]);
    const organizationTemplates = useSelector(selectOrganizationTemplates);

    const [loading, setLoading] = useState(false);
    const filter = useSelector(selectFilterOptions);
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        const returnOrdersTemplates = organizationTemplates.filter(template => template.type === TYPE_RETURN_ORDERS) || [];
        const returnOrdersWithItemsTemplates = organizationTemplates.filter(template => template.type === TYPE_RETURN_ORDERS_WITH_ITEMS) || [];

        setReturnOrdersTypeTemplates(returnOrdersTemplates);
        setReturnOrdersWithItemsTypeTemplates(returnOrdersWithItemsTemplates);
    }, [organizationTemplates])
    const uploadExcel = (uri) => {
        setLoading(true);

        uploadReturnOrdersToExcelAsync({...filter, uri: uri})
            .then(loadedContent => {
                excelFileUploader({
                    content: loadedContent,
                    fileName: t(lang, 'edi.return_orders_list.excel.file.name')
                })
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    const uploadExcelWithItems = (uri) => {
        setLoading(true);

        uploadReturnOrdersWithItemsToExcelAsync({...filter, uri: uri})
            .then(loadedContent => {
                excelFileUploader({
                    content: loadedContent,
                    fileName: t(lang, 'edi.return_orders_with_items.excel.file.name')
                })
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }


    const uploadReturnOrders = (uri) => {
        if(dispatch(checkPermission(PermissionEdiReturnOrderDownloadExcel))) uploadExcel(uri)
        else dispatch(toastPermission())
    }

    const uploadReturnOrdersWithItems = (uri) => {
        if(dispatch(checkPermission(PermissionEdiReturnOrderDownloadExcel))) uploadExcelWithItems(uri)
        else dispatch(toastPermission())
    }

    return (
        <>
            <Dropdown {...props}>
                <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                    {loading
                        ?
                        <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                        :
                        <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                    }
                    <span className="d-none d-sm-inline-block">
                        {t(lang, 'edi.return_order.datatable.header.navigation.dropdown.export_to_excel')}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-2'>
                    <>
                        <Dropdown.Header className="text-black">{t(lang, 'edi.return_order.datatable.header.navigation.dropdown.item.export_return_orders_to_excel')}</Dropdown.Header>
                        {returnOrdersTypeTemplates.map((template, index) => (
                            <Dropdown.Item className="ps-4" key={index} onClick={() => uploadReturnOrders(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                        ))}
                    </>

                    <>
                        <Dropdown.Header className="text-black">{t(lang, 'edi.return_order.datatable.header.navigation.dropdown.item.export_return_orders_with_items_to_excel')}</Dropdown.Header>
                        {returnOrdersWithItemsTypeTemplates.map((template, index) => (
                            <Dropdown.Item className="ps-4" key={index} onClick={() => uploadReturnOrdersWithItems(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                        ))}
                    </>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default UploadReturnOrderListToExcel;
