import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {debounce} from "underscore";
import {Card, Col, Row} from "react-bootstrap";
import referenceDataAggregatorService
    from "../../../../services/referenceDataAggregator/referenceDataAggregatorService";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import numeral from "numeral";
import {
    ContractorPaymentTypes,
    GetPaymentTypeText,
    PaymentTypeBankTransferCode,
    PaymentTypeCashCode,
    PaymentTypeClickCode,
    PaymentTypeHumoCode,
    PaymentTypePaymeCode,
    PaymentTypeUzCardCode,
    PaymentTypeVisaCode
} from "../../../../enum/ContractorWrapper";
import cardImage from "../../../../assets/img/icons/spot-illustrations/corner-5.png";
import Background from "../../../common/Background";
import SoftBadge from "../../../common/SoftBadge";
import DataTable from "../../../common/table/DataTable";
import DataTablePagination from "../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../common/table/DataTableLimiter";

const OwnerOrganizationCashBoxesDataTable = ({organizationBindingId}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        name: ""
    });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [cashBoxes, setCashBoxes] = useState([]);

    const onFilterChange = (filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        setFilters(filterParams);
        setPagination(paginationParams);
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        setFilters(filterParams);
        setPagination(paginationParams);
    }, 500), [filters, pagination]);
    const onPaginationChange = (updater) => {
        setPagination(updater);
    };

    const load = async (filter, pagination) => {
        try {
            setLoading(true);
            const {data: cashBoxes} = await referenceDataAggregatorService.getOrganizationBindingCashBoxes(organizationBindingId);
            setCashBoxes(cashBoxes);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setCashBoxes([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        load(filters, pagination)
    }, [organizationBindingId]);

    const columns = useMemo(() => {
        return [
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {row.index + 1}
                        </td>
                    );
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle"></td>
                    )
                },
            },
            {
                id: 'name',
                accessorKey: 'cashbox.bind.account.title',
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1"
                            style={{
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, "cashbox.bind.account.title")}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                minWidth: "200px"
                            }}
                        >
                            <div className="d-flex flex-column">
                                <span className="fs--1 fw-semi-bold font-sans-serif text-dark">{original.name}</span>
                                {!!original.branch && <span
                                    className="fs--1 fw-semi-bold font-sans-serif text-secondary">{original.branch.name}</span>}
                            </div>
                        </td>
                    )
                },
                footer: ({table}) => {
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                minWidth: "200px"
                            }}
                        >

                        </td>
                    )
                }
            },
            {
                id: 'payments',
                accessorKey: t(lang, 'edi.common.datatable.total'),
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, 'edi.common.datatable.total')}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const totalPaymentGroupedByPaymentTypesAndCurrency = original.amounts.reduce((acc, cashBoxAmount) => {
                        if (acc[cashBoxAmount.money_type]) {
                            if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                            else
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        } else {
                            acc[cashBoxAmount.money_type] = {};
                            acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        }
                        return acc
                    }, {});
                    const totalPaymentsGroupedByCurrency = Object.values(totalPaymentGroupedByPaymentTypesAndCurrency).reduce((acc, payment) => {
                        for (const currency in payment) {
                            if (payment.hasOwnProperty(currency))
                                acc[currency] = (acc[currency] || 0) + payment[currency];
                        }
                        return acc;
                    }, {})

                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {totalPaymentsGroupedByCurrency ? Object.keys(totalPaymentsGroupedByCurrency).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(totalPaymentsGroupedByCurrency[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const totalPaymentGroupedByPaymentTypesAndCurrency = rows.reduce((acc, {original: cashBox}) => {
                        cashBox.amounts.forEach((cashBoxAmount) => {
                            if (acc[cashBoxAmount.money_type]) {
                                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.money_type] = {};
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                        })
                        return acc;
                    }, {});
                    const totalPaymentsGroupedByCurrency = Object.values(totalPaymentGroupedByPaymentTypesAndCurrency).reduce((acc, payment) => {
                        for (const currency in payment) {
                            if (payment.hasOwnProperty(currency))
                                acc[currency] = (acc[currency] || 0) + payment[currency];
                        }
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {totalPaymentsGroupedByCurrency ? Object.keys(totalPaymentsGroupedByCurrency).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(totalPaymentsGroupedByCurrency[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                }
            },
            {
                id: 'paymentTypeCash',
                accessorKey: t(lang, GetPaymentTypeText(PaymentTypeCashCode)),
                enableHiding: false,
                header: ({column}) => {
                    const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeCashCode);
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                       className="m-1 fs--2 text-dark"
                            >
                                {t(lang, paymentTypeOption.label)}
                            </SoftBadge>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const payments = original.amounts.reduce((acc, cashBoxAmount) => {
                        if (acc[cashBoxAmount.money_type]) {
                            if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                            else
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        } else {
                            acc[cashBoxAmount.money_type] = {};
                            acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        }
                        return acc
                    }, {});
                    const cashPayments = payments[`${PaymentTypeCashCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {cashPayments ? Object.keys(cashPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(cashPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const payments = rows.reduce((acc, {original: cashBox}) => {
                        cashBox.amounts.forEach((cashBoxAmount) => {
                            if (acc[cashBoxAmount.money_type]) {
                                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.money_type] = {};
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                        })
                        return acc;
                    }, {});
                    const cashPayments = payments[`${PaymentTypeCashCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {cashPayments ? Object.keys(cashPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(cashPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                }
            },
            {
                id: 'paymentTypeUzCard',
                accessorKey: t(lang, GetPaymentTypeText(PaymentTypeUzCardCode)),
                enableHiding: false,
                header: ({column}) => {
                    const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeUzCardCode);
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                       className="m-1 fs--2 text-dark"
                            >
                                {t(lang, paymentTypeOption.label)}
                            </SoftBadge>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const payments = original.amounts.reduce((acc, cashBoxAmount) => {
                        if (acc[cashBoxAmount.money_type]) {
                            if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                            else
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        } else {
                            acc[cashBoxAmount.money_type] = {};
                            acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        }
                        return acc
                    }, {});
                    const uzCardPayments = payments[`${PaymentTypeUzCardCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const payments = rows.reduce((acc, {original: cashBox}) => {
                        cashBox.amounts.forEach((cashBoxAmount) => {
                            if (acc[cashBoxAmount.money_type]) {
                                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.money_type] = {};
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                        })
                        return acc;
                    }, {});
                    const uzCardPayments = payments[`${PaymentTypeUzCardCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                }
            },
            {
                id: 'paymentTypeHumo',
                accessorKey: t(lang, GetPaymentTypeText(PaymentTypeHumoCode)),
                enableHiding: false,
                header: ({column}) => {
                    const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeHumoCode);
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                       className="m-1 fs--2 text-dark"
                            >
                                {t(lang, paymentTypeOption.label)}
                            </SoftBadge>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const payments = original.amounts.reduce((acc, cashBoxAmount) => {
                        if (acc[cashBoxAmount.money_type]) {
                            if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                            else
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        } else {
                            acc[cashBoxAmount.money_type] = {};
                            acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        }
                        return acc
                    }, {});
                    const uzCardPayments = payments[`${PaymentTypeHumoCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const payments = rows.reduce((acc, {original: cashBox}) => {
                        cashBox.amounts.forEach((cashBoxAmount) => {
                            if (acc[cashBoxAmount.money_type]) {
                                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.money_type] = {};
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                        })
                        return acc;
                    }, {});
                    const uzCardPayments = payments[`${PaymentTypeHumoCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                }
            },
            {
                id: 'paymentTypeBankTransfer',
                accessorKey: t(lang, GetPaymentTypeText(PaymentTypeBankTransferCode)),
                enableHiding: false,
                header: ({column}) => {
                    const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeBankTransferCode);
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                       className="m-1 fs--2 text-dark"
                            >
                                {t(lang, paymentTypeOption.label)}
                            </SoftBadge>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const payments = original.amounts.reduce((acc, cashBoxAmount) => {
                        if (acc[cashBoxAmount.money_type]) {
                            if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                            else
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        } else {
                            acc[cashBoxAmount.money_type] = {};
                            acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        }
                        return acc
                    }, {});
                    const humoPayments = payments[`${PaymentTypeBankTransferCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {humoPayments ? Object.keys(humoPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(humoPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const payments = rows.reduce((acc, {original: cashBox}) => {
                        cashBox.amounts.forEach((cashBoxAmount) => {
                            if (acc[cashBoxAmount.money_type]) {
                                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.money_type] = {};
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                        })
                        return acc;
                    }, {});
                    const bankTransferPayments = payments[`${PaymentTypeBankTransferCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {bankTransferPayments ? Object.keys(bankTransferPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(bankTransferPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                }
            },
            {
                id: 'paymentTypeClick',
                accessorKey: t(lang, GetPaymentTypeText(PaymentTypeClickCode)),
                enableHiding: false,
                header: ({column}) => {
                    const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeClickCode);
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                       className="m-1 fs--2 text-dark"
                            >
                                {t(lang, paymentTypeOption.label)}
                            </SoftBadge>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const payments = original.amounts.reduce((acc, cashBoxAmount) => {
                        if (acc[cashBoxAmount.money_type]) {
                            if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                            else
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        } else {
                            acc[cashBoxAmount.money_type] = {};
                            acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        }
                        return acc
                    }, {});
                    const uzCardPayments = payments[`${PaymentTypeClickCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const payments = rows.reduce((acc, {original: cashBox}) => {
                        cashBox.amounts.forEach((cashBoxAmount) => {
                            if (acc[cashBoxAmount.money_type]) {
                                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.money_type] = {};
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                        })
                        return acc;
                    }, {});
                    const clickPayments = payments[`${PaymentTypeClickCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {clickPayments ? Object.keys(clickPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(clickPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                }
            },
            {
                id: 'paymentTypePayMe',
                accessorKey: t(lang, GetPaymentTypeText(PaymentTypePaymeCode)),
                enableHiding: false,
                header: ({column}) => {
                    const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypePaymeCode);
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                       className="m-1 fs--2 text-dark"
                            >
                                {t(lang, paymentTypeOption.label)}
                            </SoftBadge>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const payments = original.amounts.reduce((acc, cashBoxAmount) => {
                        if (acc[cashBoxAmount.money_type]) {
                            if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                            else
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        } else {
                            acc[cashBoxAmount.money_type] = {};
                            acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        }
                        return acc
                    }, {});
                    const uzCardPayments = payments[`${PaymentTypePaymeCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const payments = rows.reduce((acc, {original: cashBox}) => {
                        cashBox.amounts.forEach((cashBoxAmount) => {
                            if (acc[cashBoxAmount.money_type]) {
                                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.money_type] = {};
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                        })
                        return acc;
                    }, {});
                    const payMePayments = payments[`${PaymentTypePaymeCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {payMePayments ? Object.keys(payMePayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(payMePayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                }
            },
            {
                id: 'paymentTypeVisa',
                accessorKey: t(lang, GetPaymentTypeText(PaymentTypeVisaCode)),
                enableHiding: false,
                header: ({column}) => {
                    const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeVisaCode);
                    return (
                        <th className="p-1 align-middle text-end text-dark fs--1"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                       className="m-1 fs--2 text-dark"
                            >
                                {t(lang, paymentTypeOption.label)}
                            </SoftBadge>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    const payments = original.amounts.reduce((acc, cashBoxAmount) => {
                        if (acc[cashBoxAmount.money_type]) {
                            if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                            else
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        } else {
                            acc[cashBoxAmount.money_type] = {};
                            acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                        }
                        return acc
                    }, {});
                    const uzCardPayments = payments[`${PaymentTypeVisaCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const payments = rows.reduce((acc, {original: cashBox}) => {
                        cashBox.amounts.forEach((cashBoxAmount) => {
                            if (acc[cashBoxAmount.money_type]) {
                                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.money_type] = {};
                                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                        })
                        return acc;
                    }, {});
                    const visaPayments = payments[`${PaymentTypeVisaCode}`];
                    return (
                        <td className="p-1 align-middle"
                            style={{
                                width: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {visaPayments ? Object.keys(visaPayments).map((currencyName) => (
                                    <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral['formats'][numberFormat].format(visaPayments[currencyName])}</span>
                                        <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                    </div>
                                )) :
                                <div className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                        </td>
                    )
                }
            },
        ];
    }, [lang]);
    const data = useMemo(() => {
        return cashBoxes;
    }, [cashBoxes]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getRowId: row => row.id,
        onPaginationChange: onPaginationChange,
        autoResetPageIndex: false,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        pageCount: Math.ceil(data.length / pagination.pageSize),
        state: {
            pagination: pagination,
        }
    });
    const totalPaymentGroupedByPaymentTypesAndCurrency = cashBoxes.reduce((acc, cashBox) => {
        cashBox.amounts.forEach((cashBoxAmount) => {
            if (acc[cashBoxAmount.money_type]) {
                if (acc[cashBoxAmount.money_type][cashBoxAmount.currency.name])
                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                else
                    acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
            } else {
                acc[cashBoxAmount.money_type] = {};
                acc[cashBoxAmount.money_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
            }
        })
        return acc;
    }, {});
    const totalPaymentsGroupedByCurrency = Object.values(totalPaymentGroupedByPaymentTypesAndCurrency).reduce((acc, payment) => {
        for (const currency in payment) {
            if (payment.hasOwnProperty(currency))
                acc[currency] = (acc[currency] || 0) + payment[currency];
        }
        return acc;
    }, {});

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <Row className="g-2">
                    <Col xs={12}>
                        <Card className="h-100">
                            <Card.Header>
                                <Card.Title
                                    className="text-center fs-3">{t(lang, 'cash_boxes.total.amount.title')}</Card.Title>
                                <Card.Subtitle className="text-center">
                                    {ContractorPaymentTypes.map((i) => (
                                        <SoftBadge style={{backgroundColor: i.bg}} key={i.value}
                                                   className="m-1 fs--2 text-dark">{t(lang, i.label)}</SoftBadge>
                                    ))}
                                </Card.Subtitle>
                            </Card.Header>
                            <Card.Body>
                                <Card.Subtitle>
                                    {Object.keys(totalPaymentsGroupedByCurrency).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs-0 fw-bold'>
                                            <span>{numeral['formats'][numberFormat].format(totalPaymentsGroupedByCurrency[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    ))}
                                </Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Col>
                    {ContractorPaymentTypes.map((option) => {
                        const payments = totalPaymentGroupedByPaymentTypesAndCurrency[`${option.value}`];
                        return (
                            <Col xs={12} md={4} xl={2} key={option.value}>
                                <Card className="h-100">
                                    <Background image={cardImage} className="bg-card"/>
                                    <Card.Header className="pb-0">
                                        <Card.Title className="text-center mb-0">
                                            <SoftBadge style={{backgroundColor: option.bg}}
                                                       className="m-1 text-dark">{t(lang, option.label)}</SoftBadge>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className="p-2 mt-1">
                                        <Card.Subtitle>
                                            {payments ? Object.keys(payments).map((currencyName) => (
                                                <div key={currencyName} className='d-block text-end fs-0 fw-bold'>
                                                    <span>{numeral['formats'][numberFormat].format(payments[currencyName])}</span>
                                                    <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                                </div>
                                            )) : <div
                                                className="text-center text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                                        </Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Col>
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <DataTable table={table}
                                   loading={loading}
                                   columns={columns}
                                   tableProps={{
                                       responsive: true
                                   }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <DataTablePagination table={table}
                                             pagination={pagination}
                                             count={data.length}
                        />
                        <DataTableLimiter table={table}
                                          pagination={pagination}
                                          limitOptions={[10, 25, 50, 75, 100]}
                        />
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
};

export default OwnerOrganizationCashBoxesDataTable;