import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {debounce} from "underscore";
import AsyncSelect from "react-select/async";
import {selectCategories} from "../../../../app/store/reducers/category/categoryReducer";


const WarehouseOperationCategorySelect = ({defaultValue, onChange, onFocus, ...props}) => {
    const [value, setValue] = useState(null)
    const filterOptions = {page: 0, limit: 20}
    const categories = useSelector(selectCategories)
    const ref = useRef(null)
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (defaultValue !== null) {
            const val = categories?.find(category => category?.id === defaultValue)
            setValue(val)
        } else {
            setValue(null)
        }
    },[categories, defaultValue])



    const onChangeCategory = option => {
        setValue(option)
        onChange(option)
    }

    const getOptionValue = option => option?.id
    const getOptionLabel = option => {
        return(
            `${option?.name}`
        )
    }

    useEffect(() => {
        onFocus && ref?.current?.focus()
    }, [onFocus])


    const loadOptions = (query, callback) => {
        if (query) {

            callback(categories?.filter(i => {
                const searchLower = query?.trim()?.toLowerCase();

                // search by name
                {
                    const filterNameParts = searchLower.replaceAll('  ', ' ').split(' ');
                    const categoryLowerName = i?.name?.toLowerCase();
                    if (filterNameParts?.length === filterNameParts?.filter(fnp => categoryLowerName?.indexOf(fnp) > -1)?.length)
                        return true;
                }
            }).slice(filterOptions?.page, filterOptions?.limit))

        } else {
            callback([]);
        }
    };
    const debouncedLoadOptions = debounce(loadOptions, 500);

    return (
        <AsyncSelect
            ref={ref}
            options={categories || []}
            value={value}
            onChange={onChangeCategory}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            placeholder={t(lang, 'items.common.select')}
            hideSelectedOptions={true}
            noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
            isClearable
            {...props}
            // menuPortalTarget={document.body}
            loadOptions={debouncedLoadOptions}
            defaultOptions={categories?.slice(filterOptions?.page, filterOptions?.limit)}
        />
    );
};


WarehouseOperationCategorySelect.propTypes = {
    onChange: PropTypes.func
}

WarehouseOperationCategorySelect.defaultProps = {
    onChange: () => {
    }
}

export default WarehouseOperationCategorySelect;