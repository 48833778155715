import React from 'react';
import classNames from "classnames";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import EmployeeSelect from "../../../common/EmployeeSelect";
import ContractorSelect from "../../../common/ContractorSelect";
import CashBoxSelect from "../../../cashbox/common/CashBoxSelect";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const CreateTelegramOrderSaleDetailsForm = () => {
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {formState: {errors}, register} = useFormContext();

    return (
        <Card>
            <Card.Body as={Row}>
                <Form.Group as={Col} xs={12} md={6} className={'my-1'}>
                    <Form.Label>{t(lang, 'warehouse.operation.item.common.date')}</Form.Label>
                    <Controller
                        name={`date`}
                        rules={{required: t(lang, 'warehouse.operation.item.common.validation.is_required')}}
                        render={({field, fieldState}) => (
                            <DatePicker
                                timeFormat={'p'}
                                timeIntervals={60}
                                showTimeSelect={true}
                                selected={field.value}
                                onChange={field.onChange}
                                dateFormat={`${dateFormat} HH:mm`}
                                placeholderText={t(lang, "date")}
                                wrapperClassName={classNames({'is-invalid': fieldState.error})}
                                className={classNames('form-control', {'is-invalid': fieldState.error})}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.date?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={12} md={6} className={'my-1'}>
                    <Form.Label>{t(lang, "warehouse.operation.item.common.client")}</Form.Label>
                    <Controller
                        name={'contractorId'}
                        rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                        render={({field, fieldState}) => (
                            <ContractorSelect
                                allowAdd={true}
                                allowEdit={true}
                                isClearable={true}
                                defaultValue={field.value}
                                classNamePrefix={'react-select'}
                                className={classNames({'is-invalid border border-danger rounded-2': fieldState.error})}
                                onChange={selectedContractor => field.onChange(selectedContractor ? selectedContractor.id : null)}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid" className={'d-block'}>
                        {errors?.contractorId?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} xs={12} md={6} className={'my-1'}>
                    <Form.Label>{t(lang, "warehouse.operation.item.common.employee")}</Form.Label>
                    <Controller
                        name={'employeeId'}
                        render={({field}) => (
                            <EmployeeSelect
                                allowAdd={true}
                                allowEdit={true}
                                isClearable={true}
                                defaultValue={field.value}
                                onChange={selectedEmployee => field.onChange(selectedEmployee ? selectedEmployee.id : null)}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} xs={12} md={6} className={'my-1'}>
                    <Form.Label>{t(lang, 'warehouse.operation.item.common.cash_box')}</Form.Label>
                    <Controller
                        name={'cashBoxId'}
                        render={({field}) => (
                            <CashBoxSelect defaultValue={field.value} onChange={selectedCashBox => field.onChange(selectedCashBox ? selectedCashBox.id : null)}/>
                        )}
                    />
                </Form.Group>

                <Form.Group as={Col} xs={12} className={'my-1'}>
                    <Form.Label>{t(lang, "warehouse.operation.item.common.note")}</Form.Label>
                    <Form.Control placeholder={t(lang, "warehouse.operation.item.common.note")} as="textarea" rows={3} name="note"{...register("note")}/>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};

export default CreateTelegramOrderSaleDetailsForm;