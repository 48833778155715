import React, {Fragment, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import IconButton from "../../../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import BindContractorToAccountModal from "./BindContractorToAccountModal";

const BindContractorToAccountDataTableHeader = () => {
    const lang = useSelector(selectLang)
    const t = Translate

    const [show, setShow] = useState(false)

    const onHide = () => {
        setShow(false)
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-between">
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="right-left"
                        transform="shrink-3"
                        onClick={() => setShow(true)}
                    >
                        <span className="d-none d-sm-inline-block">{t(lang, "crm.bind.contractor.account.navbar.title")}</span>
                    </IconButton>
            </div>
            <BindContractorToAccountModal show={show} onHide={onHide}/>
        </Fragment>
    );
};

export default BindContractorToAccountDataTableHeader;
