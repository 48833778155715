import axios from "axios";

const taskBoard = '/task-board'
class KanbanService {

    // board
    getBoards() {
        return axios.get(`${taskBoard}/board/get`)
    }

    getBoard(id) {
        return axios.get(`${taskBoard}/board/get/${id}`)
    }

    getBoardsCount() {
        return axios.get(`${taskBoard}/board/get-count`)
    }

    addBoard(data) {
        return axios.post(`${taskBoard}/board/add`, data)
    }

    editBoard(id, data) {
        return axios.post(`${taskBoard}/board/edit/${id}`, data)
    }

    deleteBoard(id) {
        return axios.post(`${taskBoard}/board/delete/${id}`)
    }

    getWithTasks(params) {
        return axios.post(`${taskBoard}/board/get-with-tasks`, params)
    }

    boardItemsGet(id) {
        return axios.get(`${taskBoard}/board/items/get/${id}`)
    }



    // member

    memberRegister(data) {
        return axios.post(`${taskBoard}/member/register`, data)
    }

    memberDelete(id) {
        return axios.post(`${taskBoard}/member/delete/${id}`)
    }

    memberChangeName(id,data) {
        return axios.post(`${taskBoard}/member/change-name/${id}`,data)
    }

    //status

    addColumn(id, data) {
        return axios.post(`${taskBoard}/board/column/add/${id}`,data)
    }

    editColumn(id, data) {
        return axios.post(`${taskBoard}/board/column/edit/${id}`, data)
    }

    deleteColumn(id, data) {
        return axios.post(`${taskBoard}/board/column/delete/${id}`, data)
    }

    dragAndDropColumn(id, data) {
        return axios.post(`${taskBoard}/board/column/drag-and-drop/${id}`, data)
    }

    // task/item

    getTask(id, data) {
        return axios.post(`${taskBoard}/board/task/get/${id}`, data)
    }

    addTask(data) {
        return axios.post(`${taskBoard}/board/task/add`, data)
    }

    editTask(id, data) {
        return axios.post(`${taskBoard}/board/task/edit/${id}`, data)
    }
    editTaskTitle(id, data) {
        return axios.post(`${taskBoard}/board/task/edit-title/${id}`, data)
    }
    editTaskDescription(id, data) {
        return axios.post(`${taskBoard}/board/task/edit-description/${id}`, data)
    }
    editTaskAssigne(id, data) {
        return axios.post(`${taskBoard}/board/task/edit-assignee/${id}`, data)
    }

    editTaskContractorDate(id, data) {
        return axios.post(`${taskBoard}/board/task/edit-contractor/${id}`, data)
    }

    deleteTask(id, data) {
        return axios.post(`${taskBoard}/board/task/delete/${id}`, data)
    }

    dragAndDropTask(data) {
        return axios.post(`${taskBoard}/board/task/drag-and-drop`, data)
    }

    // comment
    itemRegisterComment(id, data) {
        return axios.post(`${taskBoard}/board/task/add-comment/${id}`, data)
    }

    itemUpdateComment(id, data) {
        return axios.post(`${taskBoard}/board/task/edit-comment/${id}`, data)
    }

    itemDeleteComment(id, data) {
        return axios.post(`${taskBoard}/board/task/delete-comment/${id}`, data)
    }

    // label
    itemRegisterLabel(itemId, params) {
        return axios.post(`${taskBoard}/item/add/label/${itemId}`, {
            label: [params]
        })
    }

    itemUpdateLabel() {
        return axios.post(`${taskBoard}/item/update/label`)
    }

    itemDeleteLabel() {
        return axios.post(`${taskBoard}/item/delete/label`)
    }

    itemChangeStatus(id, params) {
        return axios.post(`${taskBoard}/item/change/status/${id}`, params)
    }

    getItemStatusChange(id) {
        return axios.get(`${taskBoard}/item/status-changes/get/${id}`)
    }
    getItemMemberChange(id) {
        return axios.get(`${taskBoard}/item/member-changes/get/${id}`)
    }



}

const kanbanService = new KanbanService()
export default kanbanService
