import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectMeasurements} from "../../../../app/store/reducers/roaming/roamingReducer";
import {
    RESPONSIBLE_PERSON,
    VIEW_DRAFTS, VIEW_INBOX, VIEW_OUTBOX
} from "../../../../enum/roaming/WaybillStatus";
import {DeliveryTypeOptions} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import IconButton from "../../../common/IconButton";
import {faClone, faPlus, faSignature} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory} from "react-router-dom";
import SelectCertificateWrapperComponent from "../../../hippo/roaming/components/SelectCertificateWrapperComponent";
import {toast} from "react-toastify";
import {
    getWaybillV2CustomerCount,
    getWaybillV2DraftsCount,
    getWaybillV2ExecutorCount, getWaybillV2ResponsiblePersonCount,
    loadWaybillV2CustomerAsync,
    loadWaybillV2DraftsAsync,
    loadWaybillV2ExecutorAsync, loadWaybillV2ResponsiblePersonAsync,
    selectWaybillsV2FilterOptions,
    sendWaybillV2Async,
    transportTypeOptions, waybillAcceptAsync, waybillCancelAsync, waybillDeclineAsync,
    waybillResponsiblePersonAccept, waybillResponsiblePersonGive,
    waybillResponsiblePersonReject, waybillResponsiblePersonReturn,
    waybillResponsiblePersonTillReturn, waybillReturnAcceptAsync
} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import WaybillV2DraftDelete from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2DraftDelete";
import ErrorMessageAlert from "../../../common/ErrorMessageAlert";
import {
    getWaybillV2Status
} from "../../../../enum/roaming/WaybillV2Status";
import WaybillV2ConsignorCancel from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2ConsignorCancel";
import {useParams} from "react-router";
import WaybillV2ReturnAccepted from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2ReturnAccepted";
import WaybillV2CustomerAccept from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2CustomerAccept";
import WaybillV2CustomerDecline from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2CustomerDecline";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    CONSIGNOR_CANCEL_FAILED,
    CONSIGNOR_CANCEL_SUCCEED,
    CUSTOMER_ACCEPT_FAILED,
    CUSTOMER_ACCEPT_SUCCEED,
    CUSTOMER_DECLINE_FAILED,
    CUSTOMER_DECLINE_SUCCEED, RESPONSIBLE_PERSON_ACCEPT_FAILED,
    RESPONSIBLE_PERSON_ACCEPT_SUCCEED, RESPONSIBLE_PERSON_GIVE_FAILED,
    RESPONSIBLE_PERSON_GIVE_SUCCEED,
    RESPONSIBLE_PERSON_REJECT_FAILED,
    RESPONSIBLE_PERSON_REJECT_SUCCEED, RESPONSIBLE_PERSON_RETURN_FAILED,
    RESPONSIBLE_PERSON_RETURN_SUCCEED, RESPONSIBLE_PERSON_TILL_RETURN_FAILED,
    RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED,
    RETURN_ACCEPT_FAILED,
    RETURN_ACCEPT_SUCCEED,
    WAYBILL_V2_SYNC_SUCCESS
} from "../../../../app/eventbus/roaming/roamingWaybillV2Events";
import WarningAlert from "../../../hippo/roaming/components/WarningAlert";
import WaybillV2Sync from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2Sync";
import WaybillV2ResponsiblePersonReject
    from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2ResponsiblePersonReject";
import WaybillV2ResponsiblePersonAccept
    from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2ResponsiblePersonAccept";
import WaybillV2ResponsiblePersonTillReturn
    from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2ResponsiblePersonTillReturn";
import WaybillV2ResponsiblePersonGive
    from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2ResponsiblePersonGive";
import WaybillV2ResponsiblePersonReturn
    from "../../../hippo/roaming/waybill/forms/subformV2/WaybillV2ResponsiblePersonReturn";
import {
    getHistoriesActions,
    singleSidedWaybillV2Types,
    waybillV2Types,
    WaybillV2Wrapper
} from "../../../../enum/roaming/WaybillV2Wrapper";
import numeral from "numeral";
import WaybillHistoryActions from "../../../hippo/roaming/waybill/WaybillHistoryActions";
import RoamingWaybillV2DownloadToPdf from "../../../hippo/roaming/components/RoamingWaybillV2DownloadToPdf";
import RoamingWaybillV2Print from "../../../hippo/roaming/components/RoamingWaybillV2Print";

const WaybillV2 = ({waybill, content = null, loadWaybill}) => {
    const {viewer} = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const alertRef = useRef(null);
    const numberFormat = useSelector(selectNumberFormat);
    const filter = useSelector(selectWaybillsV2FilterOptions);
    const measurements = useSelector(selectMeasurements);
    const lang = useSelector(selectLang);
    const t = Translate;

    const waybillWrapper = new WaybillV2Wrapper(waybill)

    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;
    const isDraftWaybill = !waybill.status && content;

    const allowCancel = !isDraftWaybill && waybillWrapper.allowCancel(viewer_inn_or_pinfl)
    const allowExecutorReturnAccept = !isDraftWaybill && waybillWrapper.allowExecutorReturnAccept(viewer_inn_or_pinfl)
    const allowCustomerAccept = !isDraftWaybill && waybillWrapper.allowCustomerAccept(viewer_inn_or_pinfl)
    const allowCustomerDecline = !isDraftWaybill && waybillWrapper.allowCustomerDecline(viewer_inn_or_pinfl)
    const allowCreateInvoice = !isDraftWaybill && waybillWrapper.allowCreateInvoice(viewer_inn_or_pinfl);

    const isResponsiblePersonRejectedOrAccepted = !isDraftWaybill && waybillWrapper.isResponsiblePersonRejectedOrAccepted(viewer_inn_or_pinfl)
    const isResponsiblePersonReturnOrGiven = !isDraftWaybill && waybillWrapper.isResponsiblePersonReturnOrGiven(viewer_inn_or_pinfl)
    const isResponsiblePersonReturn = !isDraftWaybill && waybillWrapper.isResponsiblePersonReturn(viewer_inn_or_pinfl)

    const [eventMessage, setEventMessage] = useState(null);

    const loadWaybills = () => {
        if (viewer === VIEW_INBOX) {
            dispatch(loadWaybillV2CustomerAsync({...filter}));
            dispatch(getWaybillV2CustomerCount({...filter}));
        } else if (viewer === VIEW_OUTBOX) {
            dispatch(loadWaybillV2ExecutorAsync({...filter}));
            dispatch(getWaybillV2ExecutorCount({...filter}));
        } else if (viewer === VIEW_DRAFTS) {
            dispatch(loadWaybillV2DraftsAsync({...filter}));
            dispatch(getWaybillV2DraftsCount({...filter}));
        } else if (viewer === RESPONSIBLE_PERSON) {
            dispatch(loadWaybillV2ResponsiblePersonAsync({...filter}));
            dispatch(getWaybillV2ResponsiblePersonCount({...filter}));
        }
    }

    useEffect(() => {

        // responsible person

        const onRejectResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_REJECT_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })

        const onAcceptResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_ACCEPT_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })

        const onTillReturnResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })

        const onGiveResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_GIVE_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })

        const onReturnResponsiblePerson = EventBus.on(RESPONSIBLE_PERSON_RETURN_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            });
        })


        const onSyncWaybillSuccessHandler = EventBus.on(WAYBILL_V2_SYNC_SUCCESS, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_sync_success')
            });
        })

        // success
        const onConsignorCancelSuccessHandler = EventBus.on(CONSIGNOR_CANCEL_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            })
        })

        const onConsignorReturnAcceptSuccessHandler = EventBus.on(RETURN_ACCEPT_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            })
        })

        const onCustomerAcceptSuccessHandler = EventBus.on(CUSTOMER_ACCEPT_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            })
        })

        const onCustomerDeclineSuccessHandler = EventBus.on(CUSTOMER_DECLINE_SUCCEED, () => {
            loadWaybill()
            loadWaybills()
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
            })
        })

        // responsible person fail

        const onRejectResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_REJECT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onAcceptResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onTillReturnResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_TILL_RETURN_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onGiveResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_GIVE_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onReturnResponsiblePersonFail = EventBus.on(RESPONSIBLE_PERSON_RETURN_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        // fail
        const onConsignorCancelFailHandler = EventBus.on(CONSIGNOR_CANCEL_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onConsignorReturnAcceptFailHandler = EventBus.on(RETURN_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onCustomerAcceptFailHandler = EventBus.on(CUSTOMER_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        const onCustomerDeclineFailHandler = EventBus.on(CUSTOMER_DECLINE_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error?.message
            })
        })

        return () => {
            EventBus.remove(WAYBILL_V2_SYNC_SUCCESS, onSyncWaybillSuccessHandler)

            EventBus.remove(RESPONSIBLE_PERSON_REJECT_SUCCEED, onRejectResponsiblePerson)
            EventBus.remove(RESPONSIBLE_PERSON_ACCEPT_SUCCEED, onAcceptResponsiblePerson)
            EventBus.remove(RESPONSIBLE_PERSON_TILL_RETURN_SUCCEED, onTillReturnResponsiblePerson)
            EventBus.remove(RESPONSIBLE_PERSON_GIVE_SUCCEED, onGiveResponsiblePerson)
            EventBus.remove(RESPONSIBLE_PERSON_RETURN_SUCCEED, onReturnResponsiblePerson)
            EventBus.remove(CONSIGNOR_CANCEL_SUCCEED, onConsignorCancelSuccessHandler)
            EventBus.remove(RETURN_ACCEPT_SUCCEED, onConsignorReturnAcceptSuccessHandler)
            EventBus.remove(CUSTOMER_ACCEPT_SUCCEED, onCustomerAcceptSuccessHandler)
            EventBus.remove(CUSTOMER_DECLINE_SUCCEED, onCustomerDeclineSuccessHandler)

            EventBus.remove(RESPONSIBLE_PERSON_REJECT_FAILED, onRejectResponsiblePersonFail)
            EventBus.remove(RESPONSIBLE_PERSON_ACCEPT_FAILED, onAcceptResponsiblePersonFail)
            EventBus.remove(RESPONSIBLE_PERSON_TILL_RETURN_FAILED, onTillReturnResponsiblePersonFail)
            EventBus.remove(RESPONSIBLE_PERSON_GIVE_FAILED, onGiveResponsiblePersonFail)
            EventBus.remove(RESPONSIBLE_PERSON_RETURN_FAILED, onReturnResponsiblePersonFail)
            EventBus.remove(CONSIGNOR_CANCEL_FAILED, onConsignorCancelFailHandler)
            EventBus.remove(RETURN_ACCEPT_FAILED, onConsignorReturnAcceptFailHandler)
            EventBus.remove(CUSTOMER_ACCEPT_FAILED, onCustomerAcceptFailHandler)
            EventBus.remove(CUSTOMER_DECLINE_FAILED, onCustomerDeclineFailHandler)
        }

    }, []);

    const getItemMeasurementText = (id) => measurements.find(measurement => measurement.measureId === id) || null
    const totalAmount = waybill.roadway.product_groups[0]?.products.reduce((accumulator, item) => item.total + accumulator, 0)

    const getDeliveryTypeText = (id) => {
        const deliveryType = DeliveryTypeOptions.find(type => type.value === id);
        return deliveryType?.label;
    }

    const getTransportTypeText = (id) => {
        const transportType = transportTypeOptions.find(type => type.value === id);
        return transportType?.text;
    }

    const signWaybill = () => {
        setLoading(true);

        sendWaybillV2Async(currentCertificate, lang, {
            id: waybill.id,
            hashCode: content
        })
            .then(() => {
                history.push(`/roaming/v2-waybills/outbox`)
                toast.success(t(lang, 'roaming.waybill.send.toast.register_success'))
            })
            .catch(error => {
                setIsSending(false);
                setErrorMessage(error?.message || null);
                alertRef.current.scrollIntoView();
            })
            .finally(() => setLoading(false))
    }

    const innOrPinfLabel = (innOrPinfl) => {
        if (innOrPinfl?.length === 9) {
            return {
                name: "edi.common.company",
                inn: "edi.common.label.inn"
            }
        } else {
            return {
                name: "task.board.member.name",
                inn: "roaming.common.pinfl"
            }
        }
    }

    return (
        <Fragment>
            {errorMessage && <ErrorMessageAlert ref={alertRef} errorMessage={errorMessage}/>}
            {eventMessage &&
            <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

            <Card className={'p-3'}>
                <Row>
                    <h3 className="text-600 ms-2 col-md-8">{t(lang, "edi.common.created.time")}: {waybill.created_at}</h3>
                    <Col className="text-end">
                        {waybill.status && (
                            <>
                                <span className="me-2">{t(lang, 'roaming.waybill.view.status')}: </span>
                                <SoftBadge pill className="fs-0"
                                           bg={getWaybillV2Status(waybill.status).variant}>
                                    {t(lang, getWaybillV2Status(waybill.status).label)}
                                </SoftBadge>
                            </>
                        )}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className={'d-flex gap-2 align-items-center'}>
                        {!isDraftWaybill && <Fragment>
                            <RoamingWaybillV2Print size="sm" variant="falcon-default" id={waybill.id}/>
                            <RoamingWaybillV2DownloadToPdf waybill={waybill}/>
                        </Fragment>}

                        {
                            isResponsiblePersonRejectedOrAccepted && <Fragment>
                                <WaybillV2ResponsiblePersonReject
                                    id={waybill.id}
                                    requestApi={waybillResponsiblePersonReject}
                                    btnText={"roaming.waybill.v2.title.responsible.person.rejected"}
                                    variant={"falcon-danger"}
                                    mTitle={"roaming.waybill.v2.responsible.person.reject"}
                                />

                                <WaybillV2ResponsiblePersonAccept
                                    id={waybill.id}
                                    requestApi={waybillResponsiblePersonAccept}
                                    btnText={"roaming.waybill.v2.title.responsible.person.accepted"}
                                    variant={'falcon-success'}
                                />
                            </Fragment>
                        }

                        {
                            isResponsiblePersonReturnOrGiven && <Fragment>
                                <WaybillV2ResponsiblePersonTillReturn
                                    id={waybill.id}
                                    requestApi={waybillResponsiblePersonTillReturn}
                                    btnText={"roaming.waybill.v2.title.responsible.person.returned"}
                                    variant={'falcon-danger'}
                                    mTitle={"roaming.waybill.v2.responsible.person.till.return"}
                                />

                                <WaybillV2ResponsiblePersonGive
                                    id={waybill.id}
                                    requestApi={waybillResponsiblePersonGive}
                                    btnText={"roaming.waybill.send.additional_information.giver_fio"}
                                />
                            </Fragment>
                        }

                        {
                            isResponsiblePersonReturn && <WaybillV2ResponsiblePersonReturn
                                id={waybill.id}
                                requestApi={waybillResponsiblePersonReturn}
                                btnText={"roaming.waybill.v2.title.responsible.person.returned"}
                                variant={'falcon-danger'}
                                mTitle={"roaming.waybill.v2.responsible.person.till.return"}
                            />
                        }

                        {allowCustomerAccept && <WaybillV2CustomerAccept
                            id={waybill.id}
                            requestApi={waybillAcceptAsync}
                            btnText={"roaming.waybill.v2.title.consignee.accepted"}
                        />}

                        {allowCustomerDecline && <WaybillV2CustomerDecline
                            id={waybill.id}
                            requestApi={waybillDeclineAsync}
                            btnText={"roaming.waybill.v2.title.consignee.rejected"}
                            variant={"falcon-danger"}
                        />}

                        {
                            allowCancel && <WaybillV2ConsignorCancel
                            id={waybill.id}
                            requestApi={waybillCancelAsync}
                            btnText={'roaming.free_form_document.view.action.cancel'}
                            variant={"falcon-default"}
                          />
                        }

                        {
                            allowExecutorReturnAccept && <WaybillV2ReturnAccepted
                                id={waybill.id}
                                requestApi={waybillReturnAcceptAsync}
                                btnText={"roaming.free_form_document.view.action.accept"}
                            />
                        }
                        {isDraftWaybill && (
                            <IconButton
                                to={`/roaming/waybill-v2/edit/${waybill.id}`}
                                as={Link}
                                size="sm"
                                transform="shrink-3"
                                variant="falcon-warning"
                                icon="pencil"
                            >
                                <span className="d-none d-sm-inline-block ms-1">{t(lang, 'roaming.common.edit')}</span>
                            </IconButton>
                        )}
                        {isDraftWaybill && (
                            <SelectCertificateWrapperComponent
                                className={'m-0'}
                                submitButton={
                                    <IconButton onClick={signWaybill} id="sendButton"
                                                size="sm" transform="shrink-3"
                                                name={'send'}
                                                variant="falcon-primary"
                                                icon={faSignature}>
                                        {loading &&
                                            <Spinner className='align-middle me-2'
                                                     animation='border' role='switch'
                                                     size='sm'/>}
                                        {t(lang, 'roaming.common.send')}
                                    </IconButton>
                                }
                                openDialogButtonText={t(lang, 'roaming.common.send')}
                            />
                        )}
                        {isDraftWaybill && <WaybillV2DraftDelete id={waybill.id} hasTitle={true} size="sm"/>}
                        {!isDraftWaybill && <WaybillV2Sync id={waybill.id}/>}

                        {isDraftWaybill && <Link to={`/roaming/waybill-v2/send?draft_waybill_id=${waybill.id}`}>
                            <IconButton size='sm'
                                        variant='falcon-primary'
                                        icon={faClone}
                                        className='me-2'
                            >
                                {t(lang, 'roaming.invoice.copy')}
                            </IconButton>
                        </Link>}

                        {!isDraftWaybill && <Link to={`/roaming/waybill-v2/send?waybill_id=${waybill.id}`}>
                            <IconButton size='sm'
                                        variant='falcon-primary'
                                        icon={faClone}
                            >
                                {t(lang, 'roaming.invoice.copy')}
                            </IconButton>
                        </Link>}
                        {allowCreateInvoice && <Link to={`/roaming/invoice/send?waybill_v2_id=${waybill.id}`}>
                            <IconButton size="sm"
                                        variant="falcon-primary"
                                        icon={faPlus}
                            >
                                {t(lang, 'edi.common.new_invoice_button')}
                            </IconButton>
                        </Link>}
                    </Col>
                </Row>
            </Card>

            <Card className="my-3 p-3">
                <Row>
                    <Col sm={12} md={6} className="d-flex flex-column mt-2">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.v2.send.executor')}</h5>
                        <div className={'d-flex gap-2'}>
                            <b>{t(lang, innOrPinfLabel(waybill.executor?.inn || waybill.executor?.inn_or_pinfl)?.name)}:</b>
                           <span>{waybill.executor?.name}</span>
                            {(waybill.executor?.branch?.branch_code && waybill.executor?.branch?.branch_name) &&
                                <span className="ms-2">({waybill.executor.branch.branch_code} - {waybill.executor.branch.branch_name})</span>
                            }
                        </div>
                        <span
                            className="d-block"><b>{t(lang, innOrPinfLabel(waybill.executor?.inn || waybill.executor?.inn_or_pinfl)?.inn)}:</b> {waybill.executor?.inn || waybill.executor?.inn_or_pinfl}</span>
                    </Col>

                    <Col sm={12} md={6} className="d-flex flex-column mt-2">
                        <h5 className="fw-bold mb-1">{ t(lang, waybill.customer ? 'roaming.waybill.v2.send.customer' : "roaming.waybill.send.single_sided_type")}</h5>
                        {waybill.customer ? <Fragment>
                             <div className={'d-flex gap-2'}>
                                 <b>{t(lang, innOrPinfLabel(waybill.customer?.inn || waybill.customer?.inn_or_pinfl)?.name)}:</b>
                                 <span>{waybill.customer?.name}</span>
                                 {(waybill.customer?.branch?.branch_code && waybill.customer?.branch?.branch_name) &&
                                     <SoftBadge bg={'primary'} className="m-0">({waybill.customer.branch.branch_code} - {waybill.customer.branch.branch_name})</SoftBadge>
                                 }
                             </div>
                            <span
                                className="d-block"><b>{t(lang, innOrPinfLabel(waybill.customer?.inn || waybill.customer?.inn_or_pinfl)?.inn)}:</b> {waybill.customer?.inn || waybill.customer?.inn_or_pinfl}</span>

                        </Fragment> : <Fragment>
                            <span>{t(lang, singleSidedWaybillV2Types.find(i => i.value === waybill.single_sided_type)?.label)}</span>
                        </Fragment>}
                    </Col>
                    <hr className={'my-2'}/>
                    <Col sm={12} md={6} className="d-flex flex-column mt-2">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.v2.send.freight_forwarder')}</h5>
                        <span
                            className={'d-block'}><b>{t(lang, innOrPinfLabel(waybill.freight_forwarder?.inn || waybill.freight_forwarder?.inn_or_pinfl)?.name)}:</b> {waybill.freight_forwarder?.name}</span>
                        <span
                            className="d-block"><b>{t(lang, innOrPinfLabel(waybill.freight_forwarder?.inn || waybill.freight_forwarder?.inn_or_pinfl)?.inn)}:</b> {waybill.freight_forwarder?.inn || waybill.freight_forwarder?.inn_or_pinfl}</span>
                    </Col>

                    <Col sm={12} md={6} className="d-flex flex-column mt-2">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.v2.send.carrier')}</h5>
                        <span
                            className={'d-block'}><b>{t(lang, innOrPinfLabel(waybill.carrier?.inn || waybill.carrier?.inn_or_pinfl)?.name)}:</b> {waybill.carrier?.name}</span>
                        <span
                            className="d-block"><b>{t(lang, innOrPinfLabel(waybill.carrier?.inn || waybill.carrier?.inn_or_pinfl)?.inn)}:</b> {waybill.carrier?.inn || waybill.carrier?.inn_or_pinfl}</span>
                    </Col>
                    <hr className={'my-2'}/>

                    <Col sm={12} md={6} className="d-flex flex-column mt-2">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.v2.send.responsible_person')}</h5>
                        <span
                            className={'d-block'}><b>{t(lang, innOrPinfLabel(waybill.responsible_person?.inn || waybill.responsible_person?.inn_or_pinfl)?.name)}:</b> {waybill.responsible_person?.name}</span>
                        <span
                            className="d-block"><b>{t(lang, innOrPinfLabel(waybill.responsible_person?.inn || waybill.responsible_person?.inn_or_pinfl)?.inn)}:</b> {waybill.responsible_person?.inn || waybill.responsible_person?.inn_or_pinfl}</span>
                    </Col>

                    {waybill.roadway.driver?.inn && <Col sm={12} md={6} className="d-flex flex-column mt-2">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.v2.send.driver')}</h5>
                        <span
                            className={'d-block'}><b>{t(lang, innOrPinfLabel(waybill.roadway.driver?.inn || waybill.roadway.driver?.inn_or_pinfl)?.name)}:</b> {waybill.roadway.driver?.name}</span>
                        <span
                            className="d-block"><b>{t(lang, innOrPinfLabel(waybill.roadway.driver?.inn || waybill.roadway.driver?.inn_or_pinfl)?.inn)}:</b> {waybill.roadway.driver?.inn || waybill.roadway.driver?.inn_or_pinfl}</span>
                    </Col>}

                    {waybill.roadway.driver?.inn && <hr className={'my-2'}/>}


                    {waybill.client_contract_info?.date && <Col sm={12} md={6} className="d-flex flex-column mt-2">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.v2.send.freight_forwarder.customer')}</h5>
                        <span
                            className={'d-block'}><b>{t(lang, innOrPinfLabel(waybill.client_contract_info?.inn || waybill.client_contract_info?.inn_or_pinfl)?.name)}:</b> {waybill.client_contract_info?.name}</span>
                        <span
                            className="d-block"><b>{t(lang, innOrPinfLabel(waybill.client_contract_info?.inn || waybill.client_contract_info?.inn_or_pinfl)?.inn)}:</b> {waybill.client_contract_info?.inn || waybill.client_contract_info?.inn_or_pinfl}</span>
                    </Col>}

                    {waybill.payer_contract_info?.date && <Col sm={12} md={6} className="d-flex flex-column mt-2">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.customer')}</h5>
                        <span
                            className={'d-block'}><b>{t(lang, innOrPinfLabel(waybill.payer_contract_info?.inn || waybill.payer_contract_info?.inn_or_pinfl)?.name)}:</b> {waybill.payer_contract_info?.name}</span>
                        <span
                            className="d-block"><b>{t(lang, innOrPinfLabel(waybill.payer_contract_info?.inn || waybill.payer_contract_info?.inn_or_pinfl)?.inn)}:</b> {waybill.payer_contract_info?.inn || waybill.payer_contract_info?.inn_or_pinfl}</span>
                    </Col>}
                </Row>
            </Card>

            <Card className="my-3 p-3 mt-2">
                <div className={'d-flex justify-content-between'}>
                    <h5 className="p-0 ms-3"><b className="pe-1">{t(lang, "edi.common.id")}:</b>{waybill.id}</h5>
                    <h5 className="p-0 ms-3"><b className="pe-1">{t(lang, "roaming.waybill.v2.send.type.select")}:</b>
                        {t(lang, waybillV2Types.find(type => type.value === waybill.type)?.label || "roaming.invoice.send_form.type.option.0")}
                    </h5>
                </div>
                <h3 className="text-center">{t(lang, 'edi.navbar.routes.edo.waybill.v2')}</h3>
                <Row className={'justify-content-center gap-2'}>
                    {waybill.contract?.number && <Col sm={12} md={"auto"} cols={"auto"}>
                        <div className="fw-bold mb-1 text-center">{t(lang, 'roaming.common.contract')}</div>
                        <div className={'d-flex justify-content-center gap-3'}>
                            <div>
                                <span className="fw-bold mb-1">{t(lang, '№')}: </span>
                                <span>{waybill.contract?.number}</span>
                            </div>
                            -
                            <div>
                                <span className="fw-bold mb-1">{t(lang, 'actions.progressbar.columns.date')}: </span>
                                <span>{waybill.contract?.date}</span>
                            </div>

                        </div>
                    </Col>}

                    <Col sm={12} md={"auto"} cols={"auto"}>
                        <div className="fw-bold mb-1 text-center">{t(lang, 'main.templates.type.shipment')}</div>
                        <div className={'d-flex justify-content-center gap-3'}>
                            <div>
                                <span className="fw-bold mb-1">{t(lang, '№')}: </span>
                                <span>{waybill.info?.number}</span>
                            </div>
                            -
                            <div>
                                <span className="fw-bold mb-1">{t(lang, 'actions.progressbar.columns.date')}: </span>
                                <span>{waybill.info?.date}</span>
                            </div>

                        </div>
                    </Col>

                    <Col sm={12} md={"auto"} cols={"auto"}>
                        <div
                            className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type')}</div>
                        <span
                            className={'d-block text-center'}>{t(lang, getDeliveryTypeText(waybill?.delivery_type))}</span>
                    </Col>

                    <Col sm={12} md={"auto"} cols={"auto"}>
                        <div
                            className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.send.delivery_details_transport_type')}</div>
                        <span
                            className={'d-block text-center'}>{t(lang, getTransportTypeText(waybill?.transport_type))}</span>
                    </Col>

                    {waybill.roadway?.truck?.registration_number ? <Col sm={12} md={"auto"} cols={"auto"}>
                        <div className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.view.transport')}</div>
                        <span className={'d-block text-center'}>
                            <span className={'me-1'}>{waybill.roadway?.truck?.model}</span>
                            /
                            <span className={'ms-1'}>{waybill.roadway?.truck?.registration_number}</span>
                        </span>
                    </Col> : <Fragment>
                        <Col sm={12} md={"auto"} cols={"auto"}>
                            <div className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.send.details_form.train_carriage_number')}</div>
                            <span className={'d-block text-center'}>{waybill.roadway?.trainCarriageNumber}</span>
                        </Col>
                        <Col sm={12} md={"auto"} cols={"auto"}>
                            <div className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.send.details_form.train_direction')}</div>
                            <span className={'d-block text-center'}>{waybill.roadway?.trainDirection}</span>
                        </Col>
                    </Fragment>}
                </Row>

                {(waybill.payer_contract_info?.date || waybill.client_contract_info?.date) && <Fragment>
                    <hr className={'my-2'}/>
                    <Row className={'justify-content-center gap-2 mt-2'}>
                        {waybill.client_contract_info?.date && <Col sm={12} md={"auto"} cols={"auto"}>
                            <div
                                className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.v2.view.client.contract')}</div>
                            <div className={'d-flex justify-content-center gap-5'}>
                                <div>
                                    <span className="fw-bold mb-1">{t(lang, '№')}: </span>
                                    <span>{waybill.client_contract_info?.number}</span>
                                </div>
                                -
                                <div>
                                    <span
                                        className="fw-bold mb-1">{t(lang, 'actions.progressbar.columns.date')}: </span>
                                    <span>{waybill.client_contract_info?.date}</span>
                                </div>
                            </div>
                        </Col>}
                        {waybill.payer_contract_info?.date && <Col sm={12} md={"auto"} cols={"auto"}>
                            <div
                                className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.v2.view.payer.contract')}</div>
                            <div className={'d-flex justify-content-center gap-3'}>
                                <div>
                                    <span className="fw-bold mb-1">{t(lang, '№')}: </span>
                                    <span>{waybill.payer_contract_info?.number}</span>
                                </div>
                                -
                                <div>
                                    <span
                                        className="fw-bold mb-1">{t(lang, 'actions.progressbar.columns.date')}: </span>
                                    <span>{waybill.payer_contract_info?.date}</span>
                                </div>
                            </div>
                        </Col>}
                    </Row>
                </Fragment>}
            </Card>

            <Row>
                <Col sm={12} md={6}>
                    <Card className={'my-3 p-3'}>
                        <div
                            className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.v2.send.loading_point.title')}</div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.region')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.loading_point?.region_name}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.district')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.loading_point?.district_name}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.village')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.loading_point?.village_name}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.street')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.loading_point?.address || waybill.roadway?.product_groups[0]?.loading_point?.street}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.lan')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.loading_point?.latitude || ""}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.lat')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.loading_point?.longitude || ""}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                    </Card>
                </Col>

                <Col sm={12} md={6}>
                    <Card className={'my-3 p-3'}>
                        <div
                            className="fw-bold mb-1 text-center">{t(lang, 'roaming.waybill.v2.send.unloading_point.title')}</div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.region')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.unloading_point?.region_name}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.district')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.unloading_point?.district_name}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.village')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.unloading_point?.village_name}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.street')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.unloading_point?.address || waybill.roadway?.product_groups[0]?.unloading_point?.street}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.lan')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.unloading_point?.latitude || ""}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                        <div className="row">
                            <span
                                className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.v2.send.lat')}</span>
                            <span
                                className="col-8 mb-1 ps-0">{waybill.roadway?.product_groups[0]?.unloading_point?.longitude || ""}
                                <hr className="m-0" style={{height: "2px"}}/></span>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Card className="my-3 p-3">
                <Table bordered responsive className="border-bottom fs--1 border-500">
                    <thead className="text-dark bg-200">
                    <tr>
                        <th rowSpan={3}>№</th>
                        <th rowSpan={2}
                            className="text-start">{t(lang, 'roaming.waybill.view.datatable.row.product')}</th>
                        <th rowSpan={2}
                            className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.catalog_class_code')}</th>
                        <th rowSpan={2}
                            className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.measurement')}</th>
                        <th rowSpan={2}
                            className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.quantity')}</th>
                        <th rowSpan={2}
                            className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.price')}</th>
                        <th rowSpan={2}
                            className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.total')}</th>
                    </tr>
                    <tr/>
                    <tr>
                        <th className="p-0 text-center">1</th>
                        <th className="p-0 text-center">2</th>
                        <th className="p-0 text-center">3</th>
                        <th className="p-0 text-center">4</th>
                        <th className="p-0 text-center">5</th>
                        <th className="p-0 text-center">6</th>
                    </tr>
                    </thead>
                    <tbody className={'bg-white'}>
                    {waybill?.roadway?.product_groups[0]?.products?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="text-start">{index + 1}</td>
                                <td className="text-center">{item.name}</td>
                                <td className="text-center">{item.catalog?.code || item.catalog_code}</td>
                                <td className="text-center">{item?.package?.code || item.measurement}</td>
                                <td className="text-center">{item.quantity}</td>
                                <td className="text-center">{numeral.formats[numberFormat].format(item.price)}</td>
                                <td className="text-center">{numeral.formats[numberFormat].format(item.total)}</td>
                            </tr>
                        )
                    })}

                    </tbody>
                </Table>
                <Row className="g-0 justify-content-end">
                    <Col xs="auto">
                        <Table borderless size="sm" className="fs--1 text-end">
                            <tbody>
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, 'roaming.common.items.total')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(totalAmount)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card>

            <Row className={'align-items-stretch'}>
                {waybill?.histories?.map((item, index) => <Col
                    className={'h-100'}
                    key={index}
                    xs={6} md={3}
                >
                    <WaybillHistoryActions
                        fullName={item.certificate.full_name}
                        serialNumber={item.certificate.serial_number}
                        signingTime={item.certificate.signing_time}
                        action={getHistoriesActions(item.action)}
                    />
                </Col>)}
            </Row>
        </Fragment>
    );
};
export default WaybillV2;
