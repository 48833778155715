import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import Orders from "./order/Orders";
import Dashboard from "./Dashboard";
import AddOrder from "./order/AddOrder";
import Branches from "./branch/Branches";
import ViewOrder from "./order/ViewOrder";
import ViewAttorney from "./attorney/ViewAttorney";
import Attorneys from "./attorney/Attorneys";
import AddAttorney from "./attorney/AddAttorney";
import Shipments from './shipment/Shipments';
import ViewShipment from "./shipment/ViewShipment";
import AddShipment from "./shipment/AddShipment";
import InvoiceRegister from "../../../components/hippo/invoice/forms/InvoiceRegister";
import Invoices from './invoice/Invoices';
import ReturnInvoices from './return-invoice/ReturnInvoices.js';
import ViewInvoice from "./invoice/ViewInvoice";
import ReturnOrders from './return-order/ReturnOrders';
import ReturnShipments from './return-shipment/ReturnShipments';
import ViewReturnShipment from "./return-shipment/ViewReturnShipment";
import ViewReturnOrder from "./return-order/ViewReturnOrder";
import RegisterReturnOrder from "./return-order/RegisterReturnOrder";
import ShipmentExecutorEdit from "./shipment/ShipmentExecutorEdit";
import ShipmentCustomerEdit from "./shipment/ShipmentCustomerEdit";
import RegisterReturnShipment from "./return-shipment/RegisterReturnShipment";
import ViewReturnInvoice from "./return-invoice/ViewReturnInvoice";
import ReturnInvoiceRegister from "../../../components/hippo/return-invoice/forms/ReturnInvoiceRegister";
import {useDispatch, useSelector} from 'react-redux';
import EditAttorney from "./attorney/EditAttorney";
import TryEdiTrialAlert from "../../../components/notification/TryEDITrialAlert";
import {
    EDI_SERVICE_CODE,
    getOrganizationBillingInfoAsync,
    selectIsEDIServiceActive,
    selectOrganizationServiceDemoUses
} from "../../../app/store/reducers/billing/billingReducer";
import EventBus from "../../../app/eventbus/EventBus";
import {ACTIVATE_DEMO_SUCCEED} from "../../../app/eventbus/billingEvents";
import ActivateServicePackageAlert from "../../../components/notification/ActivateServicePackageAlert";
import ContractFormation from "./contract-formation/ContractFormation";
import ViewContractFormation from "../../../components/hippo/contract-formation/contract-formation-view/ViewContractFormation";
import {loadBranchUsesAsync} from "../../../app/store/reducers/branch/branchReducer";
import {loadSystemOrderCustomerAsync} from "../../../app/store/reducers/order/orderReducer";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import MassImportAttorney from "./attorney/MassImportAttorney";
import Breadcrumb from "../../../components/hippo/breadcrumb/Breadcrumb";
import {selectEdiSettings, updateEdiSettings} from "../../../app/store/reducers/settings/ediSettingsReducer";
import {
    selectShipmentCarOptions, selectShipmentCarSerialOptions,
    selectShipmentDriverOptions
} from "../../../app/store/reducers/shipment/shipmentReducer";
import {
    selectReturnShipmentCarOptions, selectReturnShipmentCarSerialOptions,
    selectReturnShipmentDriverOptions
} from "../../../app/store/reducers/return-shipment/returnShipmentReducer";
import {setVehiclesInfoLocalStorage} from "../../../components/hippo/crm/order/orderWrapper";
import RegisterContractFormation from "./contract-formation/RegisterContractFormation";
import EditContractFormation from "./contract-formation/EditContractFormation";
import RoamingProviderBinding from "../../../components/hippo/roaming/RoamingProviderBinding";
import TasnifAlert from "../../../components/hippo/roaming/common/TasnifAlert";
import {EDI_VIEWER_CUSTOMER} from "../../../enum/edi/ediViewer";
import {ViewerProvider} from "./ViewerProvider";


const EDILayout = () => {
    const {url} = useRouteMatch();
    const dispatch = useDispatch();
    const isEDIServiceActive = useSelector(selectIsEDIServiceActive);
    const serviceDemoUses = useSelector(selectOrganizationServiceDemoUses);
    const activeOrganization = useSelector(selectActiveOrganization);
    const settings = useSelector(selectEdiSettings)

    const carOptions = useSelector(selectShipmentCarOptions);
    const driverOptions = useSelector(selectShipmentDriverOptions);
    const carSerialOptions = useSelector(selectShipmentCarSerialOptions);

    const carReturnShipmentOptions = useSelector(selectReturnShipmentCarOptions)
    const driverReturnShipmentOptions = useSelector(selectReturnShipmentDriverOptions)
    const carSerialReturnShipmentOptions = useSelector(selectReturnShipmentCarSerialOptions)



    const isAlreadyUsed = serviceDemoUses?.find(service => service.code === EDI_SERVICE_CODE);

    useEffect(() => {
        const activateDemoPeriodHandler = EventBus.on(ACTIVATE_DEMO_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
        });

        return () => {
            EventBus.remove(ACTIVATE_DEMO_SUCCEED, activateDemoPeriodHandler);
        }
    }, [])
    useEffect(() => {
        dispatch(loadSystemOrderCustomerAsync());
        dispatch(loadBranchUsesAsync());
        const shipmentLocalStorage = localStorage.getItem("shipmentProvidersInfo")
        const returnShipmentLocalStorage = localStorage.getItem("returnShipmentProvidersInfo")



        // temporary start
        if (settings) {
            const shipmentSettingsJson = {
                edi: {
                    should_validate_invoice_number: settings?.should_validate_invoice_number,
                    shipment: {
                        vehicles: {
                            drivers: settings?.shipment?.vehicles?.drivers?.map(driver => {
                                return {
                                    label: driver?.label,
                                    is_active: driver.is_active
                                }
                            }) || [],
                            numbers: settings?.shipment?.vehicles?.numbers?.map(number => {
                                return {
                                    label: number?.label,
                                    is_active: number.is_active
                                }
                            }) || [],
                            models: settings?.shipment?.vehicles?.models?.map(model => {
                                return {
                                    label: model?.label,
                                    is_active: model?.is_active
                                }
                            }) || [],
                        }
                    },
                    return_shipment: {
                        vehicles: {
                            drivers: settings?.return_shipment?.vehicles?.drivers?.map(driver => {
                                return {
                                    label: driver.label,
                                    is_active: driver.is_active
                                }
                            }) || [],
                            numbers: settings?.return_shipment?.vehicles?.numbers?.map(driver => {
                                return {
                                    label: driver.label,
                                    is_active: driver.is_active
                                }
                            }) || [],
                            models: settings?.return_shipment?.vehicles?.models?.map(model => {
                                return {
                                    label: model.label,
                                    is_active: model.is_active
                                }
                            }) || [],
                        }
                    }
                }
            }
            setVehiclesInfoLocalStorage(driverOptions, shipmentSettingsJson?.edi?.shipment?.vehicles?.drivers)
            setVehiclesInfoLocalStorage(carSerialOptions, shipmentSettingsJson?.edi?.shipment?.vehicles?.numbers)
            setVehiclesInfoLocalStorage(carOptions, shipmentSettingsJson?.edi?.shipment?.vehicles?.models)

            setVehiclesInfoLocalStorage(carReturnShipmentOptions, shipmentSettingsJson?.edi?.return_shipment?.vehicles?.drivers)
            setVehiclesInfoLocalStorage(driverReturnShipmentOptions, shipmentSettingsJson?.edi?.return_shipment?.vehicles?.numbers)
            setVehiclesInfoLocalStorage(carSerialReturnShipmentOptions, shipmentSettingsJson?.edi?.return_shipment?.vehicles?.models)


            if (shipmentLocalStorage && returnShipmentLocalStorage && shipmentSettingsJson.edi.shipment.vehicles.drivers.length !== settings.shipment.vehicles.drivers.length ||
                shipmentSettingsJson.edi.shipment.vehicles.numbers.length !== settings?.shipment?.vehicles?.numbers?.length ||
                shipmentSettingsJson.edi.shipment.vehicles.models.length !== settings?.shipment?.vehicles?.models.length ||
                shipmentSettingsJson.edi.return_shipment.vehicles.drivers.length !== settings?.return_shipment?.vehicles?.drivers?.length ||
                shipmentSettingsJson.edi.return_shipment.vehicles.numbers.length !== settings?.return_shipment?.vehicles?.numbers?.length ||
                shipmentSettingsJson.edi.return_shipment.vehicles.models.length !== settings?.return_shipment?.vehicles?.models?.length) {
                updateEdiSettings({payload: shipmentSettingsJson})
                    .then(() => {
                        localStorage.removeItem("shipmentProvidersInfo")
                        localStorage.removeItem("returnShipmentProvidersInfo")
                    })
                    .catch(() => {})
            }
        }
        // temporary end



    }, [activeOrganization, settings])


    if (!activeOrganization.inn) {
        return <Redirect to={'/'} />
    }

    return (
        <ViewerProvider>
            <RoamingProviderBinding/>
            <TasnifAlert/>

            {!isAlreadyUsed && activeOrganization.inn && <TryEdiTrialAlert serviceCode={EDI_SERVICE_CODE} />}
            {!isEDIServiceActive && activeOrganization.inn && <ActivateServicePackageAlert serviceCode={EDI_SERVICE_CODE} />}

            <Breadcrumb />

            <Switch>
                <Route path={`${url}`} exact component={Dashboard}/>

                <Route path={`${url}/order/add`} component={AddOrder}/>
                <Route path={`${url}/order/:id`} component={ViewOrder}/>
                <Route path={`${url}/orders/:viewer`} component={Orders}/>
                <Redirect from={`${url}/orders`} to={`./orders/${EDI_VIEWER_CUSTOMER}`} />

                <Route path={`${url}/contracts-formation`} component={ContractFormation}/>
                <Route path={`${url}/contract-formation/template/:id`} component={RegisterContractFormation}/>
                <Route path={`${url}/contract-formation/view/:id`} component={ViewContractFormation} />
                <Route path={`${url}/contract-formation/update/:id`} component={EditContractFormation} />

                <Route path={`${url}/attorney/add`} component={AddAttorney}/>
                <Route path={`${url}/attorney/mass-import`} component={MassImportAttorney}/>
                <Route path={`${url}/attorney/update/:id`} component={EditAttorney}/>
                <Route path={`${url}/attorney/:id`} component={ViewAttorney}/>
                <Route path={`${url}/attorneys`} component={Attorneys}/>

                <Route path={`${url}/shipment/add/:id`} component={AddShipment}/>
                <Route path={`${url}/shipment/executor-edit/:id`} component={ShipmentExecutorEdit}/>
                <Route path={`${url}/shipment/customer-edit/:id`} component={ShipmentCustomerEdit}/>
                <Route path={`${url}/shipment/:id`} component={ViewShipment}/>
                <Route path={`${url}/shipments/:viewer`} component={Shipments}/>
                <Redirect from={`${url}/shipments`} to={`./shipments/${EDI_VIEWER_CUSTOMER}`}/>

                <Route path={`${url}/invoice/register/:id`} component={InvoiceRegister}/>
                <Route path={`${url}/invoice/:id`} component={ViewInvoice}/>
                <Route path={`${url}/invoices`} component={Invoices}/>

                <Route path={`${url}/return-order/register`} component={RegisterReturnOrder}/>
                <Route path={`${url}/return-order/:id`} component={ViewReturnOrder}/>
                <Route path={`${url}/return-orders`} component={ReturnOrders}/>

                <Route path={`${url}/return-shipment/register/:id`} component={RegisterReturnShipment}/>
                <Route path={`${url}/return-shipment/:id`} component={ViewReturnShipment}/>
                <Route path={`${url}/return-shipments`} component={ReturnShipments}/>

                <Route path={`${url}/branches`} exact component={Branches}/>

                <Route path={`${url}/return-invoice/register/:id`} component={ReturnInvoiceRegister}/>
                <Route path={`${url}/return-invoice/:id`} component={ViewReturnInvoice}/>
                <Route path={`${url}/return-invoices`} component={ReturnInvoices}/>
            </Switch>
        </ViewerProvider>
    );
}

export default EDILayout;
