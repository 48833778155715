import React from 'react';
import PropTypes from "prop-types";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const DeclinedHistoryActionSeal = ({serialNumber, signingTime, fullName}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <div className='border-300 my-2 p-2 border-warning text-warning' style={{borderRadius: '10px', border: '10px solid', background: 'rgba(248, 172, 89, 0.1)'}}>
            <div className="d-flex justify-content-between">
                <span className="me-3 text-secondary">№ {serialNumber}</span>
                <span className="text-secondary">{signingTime}</span>
            </div>
            <p className={`fs-1 fw-semi-bold text-center text-uppercase my-3`}>{t(lang, 'roaming.action_seal.declined_text')}</p>
            <p className="m-0 fs--1 text-secondary">
                {fullName}
            </p>
        </div>
    );
};

DeclinedHistoryActionSeal.propTypes = {
    serialNumber: PropTypes.string.isRequired,
    signingTime: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired
}

export default DeclinedHistoryActionSeal;