import dayjs from "dayjs";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {Button, Card, Table} from "react-bootstrap";
import ColumnSelector from "./ColumnSelector";
import React, {useEffect, useRef, useState} from 'react';
import {useFormContext, useWatch} from "react-hook-form";
import ImportAttorneyDetailsForm from "./ImportAttorneyDetailsForm";
import ImportAttorneyFileUploader from "./ImportAttorneyFileUploader";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {UnimportantOption, AttorneyFieldOptions, MassAttorneyImportSelectorOptions} from "../../../../enum/AttorneyFieldOptions";

const itemsFieldName = 'items';
const columnKeysFieldName = 'columnKeys';

const ImportAttorneyForm = ({savedColumnPositions}) => {
    const {setValue, setError, clearErrors} = useFormContext();
    const [limit, setLimit] = useState(20);
    const tableContent = useRef(null);
    const lang = useSelector(selectLang);
    const t = Translate;

    const columnKeys = useWatch({name: columnKeysFieldName});
    const items = useWatch({name: itemsFieldName});
    const skipRows = useWatch({name: 'skipRows'});
    const itemsCount = items.length;

    useEffect(() => {
        validateColumns();
    }, [columnKeys])

    useEffect(() => {
        if (items.length > 0) {
            updateHeaderColumnKeys();
        }
    }, [items])

    const updateHeaderColumnKeys = () => {
        let itemsRowLength = 0;
        let selectorColumnKeys = [];

        for (let fileItemIndex = 0; fileItemIndex < items.length; fileItemIndex++) {
            const itemRow = items[fileItemIndex];

            if (itemRow.length > itemsRowLength) {
                itemsRowLength = itemRow.length;
            }
        }

        const savedColumns = JSON.parse(localStorage.getItem(savedColumnPositions));
        for (let i = 0; i < itemsRowLength; i++) {
            let column;

            if (savedColumns) {
                column = savedColumns?.[i] ? savedColumns?.[i] : UnimportantOption?.key;
            } else {
                column = AttorneyFieldOptions?.[i] ? AttorneyFieldOptions[i]?.key : UnimportantOption?.key;
            }

            selectorColumnKeys.push(column);
        }

        setValue(columnKeysFieldName, selectorColumnKeys);
    }

    const validateColumns = () => {
        if (columnKeys.length > 0) {
            let noErrorsFound = true;
            for (let i = 0; i < AttorneyFieldOptions.length; i++) {
                let found = false;
                const field = AttorneyFieldOptions[i];

                if (field.isRequired) {
                    for (let j = 0; j < columnKeys.length; j++) {
                        if (field.key === columnKeys[j]) {
                            found = true;
                            break;
                        }
                    }

                    if (!found) {
                        noErrorsFound = false;
                        setError("columns", {
                            type: 'required',
                            message: t(lang, 'edi.attorney.mass_import.columns.error_message', {column: t(lang, field.label)})
                        });
                        break;
                    }
                }
            }

            if (noErrorsFound) {
                clearErrors();
            }
        }
    }

    return (
        <>
            <Card>
                <Card.Header className="bg-light">
                    <Card.Title>{t(lang, 'edi.attorney.mass_import.title')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ImportAttorneyFileUploader/>

                    {itemsCount > 0 && (
                        <>
                            <ImportAttorneyDetailsForm tableRef={tableContent}/>

                            <div className="table-responsive mt-3" ref={tableContent}>
                                <Table size="sm" bordered>
                                    <thead>
                                    <tr>
                                        <th className="text-center px-3 m-0">№</th>
                                        {columnKeys.map((column, index) => (
                                            <ColumnSelector key={index} index={index} columnsOptions={MassAttorneyImportSelectorOptions} arrayFieldName={columnKeysFieldName} />
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items.slice(0, limit).map((item, itemIndex) => (
                                        <tr className={classNames('fs--1', {'bg-400 text-dark': (itemIndex + 1) <= skipRows})} key={itemIndex}>
                                            <th className="text-center px-3 m-0">{itemIndex + 1}</th>
                                            {item.map((product, productIndex) => (
                                                <th key={productIndex}>{product}</th>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>

                                {itemsCount > limit && (
                                    <div className="w-100 text-center my-3">
                                        <Button onClick={() => setLimit(limit + 20)}>{t(lang, 'edi.attorney.mass_import.show_more_btn.title')}</Button>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

export default ImportAttorneyForm;