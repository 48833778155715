import React, {useState} from 'react';
import {Link} from "react-router-dom";
import IconButton from "../../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import {Col, Collapse, Form, Row} from "react-bootstrap";
import WaybillDataTableFilter from "./WaybillDataTableFilter";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {
    changeWaybillsFilterOptions,
    selectWaybillsCount
} from "../../../../../app/store/reducers/roaming/roamingWaybillReducer";
import SoftBadge from "../../../../common/SoftBadge";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingShipmentCreate} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";
import {
    RoamingWaybillV2StatusExecutorSent,
    RoamingWaybillV2StatusResponsiblePersonGiven,
    RoamingWaybillV2StatusResponsiblePersonReturned,
    RoamingWaybillV2StatusResponsiblePersonTillReturned
} from "../../../../../enum/roaming/WaybillV2Status";
import {useParams} from "react-router";

const WaybillDataTableHeader = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const waybillCount = useSelector(selectWaybillsCount)
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const methods = useForm();
    const t = Translate;

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    const onSubmitFilter = (formData) => {
        dispatch(changeWaybillsFilterOptions({
            status: formData.status
        }))
    }

    return (
        <>
            <Row className="flex-between-center">
                <Collapse in={isCollapsed}>
                    <Col sm={12}>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                                <WaybillDataTableFilter/>
                            </Form>
                        </FormProvider>
                    </Col>
                </Collapse>

                <Col xs={4} sm="auto" className="d-flex flex-column pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.waybill.datatable.title")}</h5>
                    <div className="mt-1">
                        {t(lang, "roaming.common.count")}:
                        <SoftBadge bg='success' className='ms-2'>
                            {waybillCount}
                        </SoftBadge>
                    </div>
                </Col>

                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">



                    <IconButton
                        as={dispatch(checkPermission(PermissionRoamingShipmentCreate)) ? Link : 'div'}
                        onClick={()=>!dispatch(checkPermission(PermissionRoamingShipmentCreate)) && dispatch(toastPermission())}
                        to="../waybill/send"
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                    >
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, "roaming.waybill.datatable.header.navigation.new_waybill")}
                                </span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        icon="refresh"
                        className="mx-2"
                        transform="shrink-3"
                        variant="falcon-default"
                        onClick={methods.handleSubmit(onSubmitFilter)}
                    >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, "roaming.waybill.datatable.header.navigation.refresh")}
                            </span>
                    </IconButton>

                    <IconButton
                        size="sm"
                        icon="filter"
                        transform="shrink-3"
                        variant="falcon-default"
                        onClick={toggleCollapse}
                    >
                            <span className="d-none d-sm-inline-block ms-1">
                                {t(lang, "roaming.waybill.datatable.header.navigation.filter")}
                            </span>
                    </IconButton>
                </Col>
            </Row>
        </>
    );
};

export default WaybillDataTableHeader;
