import React from 'react';
import classNames from "classnames";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import Select from "react-select";
import {orderMeasurementOptions} from "../../orderWrapper";

const SelectMeasurement = ({onChange, itemErrors, defaultMeasurement, edit, disabledItem, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;


    const onChangePrice = (option) => {
        onChange(option ? t(lang, option.label) : null)
    }

    return (
        <Select
            placeholder={t(lang, "crm.common.select")}
            options={orderMeasurementOptions}
            onChange={option => onChangePrice(option)}
            formatOptionLabel={(option) => option.measurement || t(lang, option.label)}
            getOptionLabel={option => t(lang, option.label)}
            getOptionValue={option => option.label}
            value={orderMeasurementOptions.find(measurement => measurement.value === defaultMeasurement) || {label: defaultMeasurement} || null}
            hideSelectedOptions
            menuPortalTarget={document.body}
            {...props}
        />
    );
};

export default SelectMeasurement;
