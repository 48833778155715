import {Col, Row} from "react-bootstrap";
import React from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {Link} from "react-router-dom";
import IconButton from "../../../../../common/IconButton";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";

const ContractorContractTableHeader = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const cardContractor = useSelector(selectCardContractor)
    return (<Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.contract.datatable.header.row.navigation.create")}</h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            <div id="invoice-actions">
                <Link
                    to={{
                        pathname: "/crm/contractor-contract/send",
                        search: `?clientInn=${cardContractor?.inn}`,
                    }}>
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        className="mx-2"
                        transform="shrink-3"
                    >
                        <span
                            className="d-none d-sm-inline-block ms-1">{t(lang, "roaming.contracts.datatable.register_contract")}</span>
                    </IconButton>
                </Link>
            </div>
        </Col>
    </Row>)
}
export default ContractorContractTableHeader