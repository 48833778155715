import React from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { useFormContext, Controller } from "react-hook-form"
import { useSelector } from "react-redux"
import { Translate, selectLang } from "../../../../../app/store/reducers/main/mainReducer"
import FindCompany from "../../../organization/FindCompany"
import FindPerson from "../../../person/FindPerson";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import classNames from "classnames";


const PartnerInfo = () => {
    const { register, setValue, formState: { errors }, control } = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate
    const onChangeHandler = company => {
        setValue(`partnerName`, company?.name, {shouldValidate: !!company})
        setValue(`partnerFizTin`, (company?.director_pinfl || company?.director_inn) || company?.person_num, {shouldValidate: !!company})
        setValue(`partnerFizFio`, company?.director_pinfl ? company?.director : company?.name, {shouldValidate: !!company})
    }

    const onCompanyDirectorChange = (director) => {
        if (director) {
            setValue(`partnerFizTin`, director.pinfl || director.inn)
            setValue(`partnerFizFio`, director.name)
        } else {
            setValue(`partnerFizTin`, '')
            setValue(`partnerFizFio`, '')
        }
    }
    return (
        <Card>
            <Card.Header>
              <h5 className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.partner_info.title")}</h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.partner_info.inn_or_pinfl")}</Form.Label>
                        <Controller
                            name={`partnerTin`}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindCompanySearchable
                                    onChange={(company) => {
                                        field.onChange(company?.person_num || company?.inn)
                                        onChangeHandler(company)
                                    }}
                                    defaultInn={field.value}
                                    placeholder={t(lang, "edi.toast.find_company_searchable:placeholder")}
                                    inputProps={{
                                        isInvalid: errors?.partnerTin,
                                        placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                    }}
                                    inputGroupProps={{
                                        className: classNames({
                                            'is-invalid': errors?.partnerTin,
                                        })
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.partnerTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.partner_info.name")}</Form.Label>
                        <Form.Control
                            {...register(`partnerName`, {
                                required: "edi.common.forms.validations.is_required"
                            })}
                            className={errors?.partnerName && 'is-invalid'}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.partner_info.name")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.partnerName?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.partner_info.person_inn_or_pinfl")}</Form.Label>
                        <Controller
                            name={'partnerFizTin'}
                            control={control}
                            rules={{
                                required: "edi.common.forms.validations.is_required"
                            }}
                            render={({field}) => (
                                <FindPerson
                                    onChange={onCompanyDirectorChange}
                                    defaultInnOrPinfl={field.value}
                                    inputProps={{
                                        className: errors?.partnerFizTin && 'is-invalid',
                                        placeholder: t(lang, "roaming.verification_act.add.verification_act.partner_info.person_inn_or_pinfl")
                                    }}
                                    inputGroupProps={{
                                        className: errors?.partnerFizTin && 'is-invalid'
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.partnerFizTin?.message)}
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm="6" className="mb-3">
                        <Form.Label className="mb-0">{t(lang, "roaming.verification_act.add.verification_act.partner_info.person_name")}</Form.Label>
                        <Form.Control
                            {...register(`partnerFizFio`, {
                                required: "edi.common.forms.validations.is_required"
                            })}
                            className={errors?.partnerFizFio && 'is-invalid'}
                            placeholder={t(lang, "roaming.verification_act.add.verification_act.partner_info.person_name")}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t(lang, errors?.partnerFizFio?.message)}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default PartnerInfo
