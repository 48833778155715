import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {Badge, ListGroup, Offcanvas, Tab, Tabs} from 'react-bootstrap';
import {isIterableArray} from "../../../../helpers/utils";
import Notification from "../../../notification/Notification";
import {
    clearAllPushUpdateNotifications,
    selectPushUpdateNotification
} from "../../../../app/store/reducers/push-update-notification/pushUpdateNotificationReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SoftBadge from "../../../common/SoftBadge";
import {selectWithTasks} from "../../../../app/store/reducers/kanban/kanbanReducer";
import dayjs from "dayjs";
import {selectAllContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import {columnType, ContractorDebtBoardType, ContractorDebtColumnPaidType} from "../../../../enum/KanbanWrapper";

const GENERAL = 'GENERAL'
const DEBT = 'DEBT'

function formatPhoneNumber(phoneNumber) {
    return `${phoneNumber.slice(3, 5)}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 10)}-${phoneNumber.slice(10)}`;
}

const NotificationDropdown = () => {
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const notifications = useSelector(selectPushUpdateNotification);
    const withTasks = useSelector(selectWithTasks);
    const contractors = useSelector(selectAllContractor);

    const [allRead, setAllRead] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [key, setKey] = useState(GENERAL);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const clearAllNotification = () => {
        dispatch(clearAllPushUpdateNotifications())
        setIsOpen(false)
        setAllRead(false)
    }
    useEffect(() => {
        if (notifications.length) {
            setAllRead(true)
        } else {
            setAllRead(false)
        }
    }, [notifications]);

    const debtNotification = useMemo(() => {
        let data = []
        withTasks?.columns?.filter(column => column.type !== ContractorDebtColumnPaidType).forEach(column => {
            column.tasks?.forEach(task => {
                if (task?.contractor?.debt_promise_date) {
                    if (task?.contractor?.debt_promise_date <= dayjs(new Date()).format('YYYY-MM-DD')) {
                        data.push({
                            ...task,
                            type: column.type,
                            contractor: {
                                ...task.contractor,
                                contacts: contractors.find(contractor => contractor.id === task.contractor.id)?.contacts || []
                            }
                        });
                    }
                }
            })
        })
        return data
    }, [withTasks])

    useEffect(() => {
        setKey(isIterableArray(notifications) ? GENERAL : debtNotification.length > 0 ? DEBT : GENERAL)
    }, [debtNotification]);

    return (

        <Fragment>
            <div onClick={handleToggle} className={classNames('px-0 nav-link', {
                'notification-indicator notification-indicator-primary': allRead || !notifications.length,
            })}>
                <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-5 text-facebook"/>
            </div>

            <Offcanvas show={isOpen} onHide={handleToggle} placement={'end'}>
                <Offcanvas.Header closeButton className={'py-2'}>
                    <div className={'d-flex gap-2 align-items-center'}>
                        <Offcanvas.Title className={'m-0'}>{t(lang, "notification.name")}</Offcanvas.Title>
                        <SoftBadge bg={"info"}>{notifications.length + debtNotification.length}</SoftBadge>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body className={'pt-0'}>
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className={'flex-row gap-3'}
                        id="uncontrolled-tab-example"
                    >
                        <Tab eventKey={GENERAL} title={t(lang, 'edi.contractor.card.general.tab.title')}>
                            <ListGroup className="fw-normal fs--1 scrollbar">
                                {
                                    isIterableArray(notifications) ?
                                        notifications.map((notification, index) => (
                                            <ListGroup.Item key={index} className={"hover-bg-200"}>
                                                <Notification index={index} notification={notification}/>
                                            </ListGroup.Item>
                                        )) :
                                        <h6 className={"text-center m-0 py-2 text-dark fw-normal"}>
                                            {t(lang, "notification.no_data")}
                                        </h6>
                                }
                            </ListGroup>
                        </Tab>
                        <Tab eventKey={DEBT} title={t(lang, 'warehouse.operation.item.common.debt_state')}>
                            <ListGroup className="fw-normal fs--1 scrollbar">
                                {debtNotification.map((debt, index) => {
                                    const selectColumnType = columnType(ContractorDebtBoardType, debt.type)

                                    return (
                                        <ListGroup.Item key={index} className={"hover-bg-200"}>
                                            <strong className={'text-facebook'}>{debt.contractor?.name}</strong>
                                            <div className={'d-flex gap-2 justify-content-end align-items-center'}>
                                                {debt.contractor?.contacts.length ?
                                                    <ul className={'list-unstyled mb-0'}>
                                                        {debt.contractor?.contacts.map(contact => (
                                                            <li key={`${contact.value}`} className={'fw-bold'}><a
                                                                href={`tel:+${contact.value}`}>{formatPhoneNumber(contact.value)}</a>
                                                            </li>
                                                        ))}
                                                    </ul> : ''}
                                                <strong className={'text-info'}>{debt.contractor?.contact}</strong>
                                                <div className={'d-flex flex-column gap-1 justify-content-center'}>
                                                    <strong
                                                        className={'text-danger'}>{debt.contractor?.debt_promise_date}</strong>
                                                    <Badge bg={selectColumnType?.variant}>
                                                        {t(lang, selectColumnType?.name)}
                                                    </Badge>
                                                </div>
                                            </div>
                                            <p>{debt.title}</p>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
                {isIterableArray(notifications) &&
                    <div className="offcanvas-footer mt-auto text-center border-top py-3">
                        <Link className="card-link text-danger fw-semi-bold text-decoration-underline"
                              onClick={clearAllNotification} to="#!">
                            {t(lang, "notification.clear_all")}
                        </Link>
                    </div>}
            </Offcanvas>
        </Fragment>
    );
};

export default NotificationDropdown;
