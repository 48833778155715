import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import billingService from "../../../../services/billingService";
import {toast} from "react-toastify";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import EImzoSignerRegisterForm from "./EImzoSignerRegisterForm";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const EImzoSignerModal = ({setDataFunc}) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang);
    const t = Translate;
    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        methods.reset()
    }

    const methods = useForm({
        defaultValues: {
            name: "",
            host: "",
            inn_or_pinfl: ""
        }
    })
    const onSubmit = (data) => {
        const params = {
            name: data.name,
            host: data.host,
            inn_or_pinfl: data.inn_or_pinfl
        }
        setLoading(true)
        billingService.registerEImzoSigner(params)
            .then(async () => {
                handleClose()
                toast.success("success")
                await billingService.getEImzoSigners().then(({data}) => setDataFunc(data))
            })
            .catch(() => toast.error("Error"))
            .finally(() => setLoading(false))
    }
    return (
        <>
            <Button variant="primary" size={"sm"} onClick={handleShow}>{t(lang,"items.common.add")}</Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang,"admin.e-imzo.modal.header.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form id={'eimzo-signer-form'} onSubmit={methods.handleSubmit(onSubmit)}>
                            <EImzoSignerRegisterForm/>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button type={"button"} variant="secondary" onClick={handleClose}>
                        {t(lang, "items.common.close")}
                    </Button>
                    <Button type={"submit"} form={"eimzo-signer-form"} variant="primary" disabled={loading}>
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size="sm"/>}
                        {t(lang,"items.common.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EImzoSignerModal