import axios from "axios";

class BranchService {
    getBranches() {
        return axios.get('/branch/get')
    }
    getBranch(id) {
        return axios.get(`/branch/get/${id}`)
    }
    addBranch({name, code, inn, roaming_branch}) {
        return axios.post('/branch/register', {
            name: name,
            code: code,
            inn: inn,
            roaming_branch: roaming_branch,
        });
    }
    getBranchUse() {
        return axios.get('/branch-use/get')
    }
    edit(id, {
        name,
        code,
        roaming_branch,
    }) {
        return axios.post(`/branch/edit/${id}`, {
            name: name,
            code: code,
            roaming_branch: roaming_branch,
        })
    }

    deleteBranch({id}) {
        return axios.post(`/branch/delete/${id}`)
    }

    bindWarehouse(params) {
        return axios.post('/branch/warehouse/bind', params)
    }

    updateRoamingAddress(branchId, payload) {
        return axios.post(`/branch/branch/roaming-address/update/${branchId}`, payload)
    }

    deleteRoamingAddress(branchId) {
        return axios.post(`/branch/branch/roaming-address/delete/${branchId}`)
    }

    // bind account

    getAccounts() {
        return axios.get(`/branch/account/get`)
    }

    getAccount(id) {
        return axios.get(`/branch/account/binding/get/${id}`)
    }

    accountBindingEnable(params) {
        return axios.post(`/branch/account/binding/enable`, params)
    }

    accountBindingDisable(params) {
        return axios.post(`/branch/account/binding/disable`, params)
    }

    accountBindingRegister(params) {
        return axios.post(`/branch/account/binding/register`, params)
    }

    accountBindingUnregister(params) {
        return axios.post(`/branch/account/binding/unregister`, params)
    }

    getContractorBranches(innOrPinfl) {
        return axios.get(`/branch/branch/get-contractor-branches/${innOrPinfl}`)
    }
}

const branchService = new BranchService();

export default branchService;
