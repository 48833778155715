import React, {Fragment} from 'react';
import {Form} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SelectBranch from "../../../common/SelectBranch";

const CashBoxesRegisterForm = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, setValue, watch} = useFormContext();

    return (
        <Fragment>
            <Form.Group>
                <Form.Label>Касса</Form.Label>
                <Form.Control
                    type={'text'}
                    placeholder={t(lang, "edi.common.name.placeholder")}
                    {...register('name', {
                        required: t(lang, "edi.common.forms.validations.is_required")
                    })}
                />
                <Form.Control.Feedback type={'invalid'} className={'d-block'}>{errors?.name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>{t(lang, "edi.common.branch")}</Form.Label>
                <Controller
                    name={'branch_id'}
                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                    render={({field}) => (
                        <SelectBranch
                            onChange={field.onChange}
                            defaultValue={field.value}
                            placeholder={t(lang, "edi.common.button.select")}
                            classNamePrefix="react-select"
                        />
                    )}/>
                <Form.Control.Feedback type={'invalid'} className={'d-block'}>{errors?.branch_id?.message}</Form.Control.Feedback>
            </Form.Group>
        </Fragment>
    );
};

export default CashBoxesRegisterForm;
