import {getOrderDeclineReasonText} from './reasons/OrderDeclineReasonType';
import {getShipmentDeclineReasonText} from './reasons/ShipmentDeclineReasonType';
import {getShipmentDeleteReasonText} from "./reasons/ShipmentDeleteReasonType";

export const OrderCreated = 0
export const OrderCustomerApproved = 2
export const OrderExecutorApproved = 3
export const OrderExecutorDecline = 4
export const OrderCustomerEdited = 5
export const OrderExecutorEdited = 6
//Return order
export const ReturnOrderApproved = 7
export const ReturnOrderCancelApprove = 11

export const OrderStoppedByExecutor = 8
export const OrderResumedByExecutor = 9
export const OrderExecutorDeclineCancel = 10
//Shipment [500 - 1000]
export const ShipmentCreated = 500
export const ShipmentAccepted = 501
export const ShipmentEdited = 502
export const ShipmentDeclined = 503
export const ShipmentAcceptCanceled = 504
export const ShipmentDeleted = 505
export const ShipmentMatchAccepted = 506
export const ShipmentApproved = 507

//ViewRoamingInvoice [1000 - ..]
export const InvoiceCreated = 1000
export const InvoiceCustomerApproved = 1001
export const InvoiceExecutorApproved = 1002
export const InvoiceDeleted = 1003


export const getActionText = action => {
    switch (action) {
        case OrderCreated:
            return "edi.action.status.order_created"
        case OrderCustomerApproved:
            return "edi.action.status.order_customer_approved"
        case OrderExecutorApproved:
            return "edi.action.status.order_executor_approved"
        case OrderExecutorDecline:
            return "edi.action.status.order_executor_decline"
        case OrderCustomerEdited:
            return "edi.action.status.order_customer_edited"
        case OrderExecutorEdited:
            return "edi.action.status.order_executor_edited"
        case ReturnOrderApproved:
            return "edi.action.status.return_order_approved"
        case ReturnOrderCancelApprove:
            return "edi.action.status.return_order_cancel_approve"
        case OrderStoppedByExecutor:
            return "edi.action.status.order_stopped_by_executor"
        case OrderResumedByExecutor:
            return "edi.action.status.order_resumed_by_executor"
        case OrderExecutorDeclineCancel:
            return "edi.action.status.order_executor_decline_cancel"
        case ShipmentCreated:
            return "edi.action.status.shipment_created"
        case ShipmentAccepted:
            return "edi.action.status.shipment_accepted"
        case ShipmentEdited:
            return "edi.action.status.shipment_edited"
        case ShipmentDeclined:
            return "edi.action.status.shipment_declined"
        case ShipmentAcceptCanceled:
            return "edi.action.status.shipment_accept_canceled"
        case ShipmentDeleted:
            return "edi.action.status.shipment_deleted"
        case ShipmentMatchAccepted:
            return "edi.action.status.shipment_match_accepted"
        case ShipmentApproved:
            return "edi.action.status.shipment_approved"
        case InvoiceCreated:
            return "edi.action.status.invoice_created"
        case InvoiceCustomerApproved:
            return "edi.action.status.invoice_customer_approved"
        case InvoiceExecutorApproved:
            return "edi.action.status.invoice_executor_approved"
        case InvoiceDeleted:
            return "edi.action.status.invoice_deleted"
        default:
            return "edi.action.status.default"
    }
}


export const GetReasonText = (action, reason) => {
    switch (action) {
        case OrderExecutorDecline:
            return getOrderDeclineReasonText(reason);
        case ShipmentDeclined:
            return getShipmentDeclineReasonText(reason);
        case ShipmentDeleted:
            return getShipmentDeleteReasonText(reason);
        default:
            return '';
    }
}