import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";
import {Card, Col, Form, Row} from "react-bootstrap";
import FindCompany from "../../../../organization/FindCompany";
import FindCompanySearchable from "../../../../organization/FindCompanySearchable";
import SelectStations from "./SelectStations";
import SelectRegion from "./SelectRegion";

const AdditionalInformationRailwayV2Point = ({title, titleLoadingPoint, railwayName, trusteeName}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const [regionId, setRegionId] = useState(null)

    const {register, setValue, getValues, watch, formState: {errors}, control} = useFormContext();


    return (
        <Card>
            <Card.Body>
                <Card.Title className={'text-center fs-0 mb-2'}>{t(lang, title)}</Card.Title>
                <Row>
                    <Form.Group as={Col} sm={12} lg={6}>
                        <Form.Label>
                            {t(lang, 'roaming.contracts.add.clients_info.inn_or_pinfl')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                            name={`${trusteeName}.pinfl`}
                            render={({field}) => (
                                <FindCompanySearchable
                                    onChange={(railway_loading_point) => {
                                        field.onChange(railway_loading_point ? railway_loading_point?.person_num ? railway_loading_point?.person_num : railway_loading_point?.inn : null)
                                        setValue(`${trusteeName}.name`, railway_loading_point?.short_name || railway_loading_point?.name)
                                    }}
                                    defaultInn={field.value}
                                    inputProps={{
                                        className: `${errors?.executor?.inn_or_pinfl && 'is-invalid'} w-100`,
                                        placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")
                                    }}
                                />
                            )}
                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {errors?.[railwayName]?.pinfl?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                        <Form.Label>
                            {t(lang, 'roaming.waybill.send.members_info_form.customer.name')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            placeholder={t(lang, 'edi.common.name.placeholder')}
                            {...register(`${trusteeName}.name`)}
                        />
                    </Form.Group>
                </Row>

                <hr className={'my-1'}/>
                <Card.Title className={'text-center fs-0 my-2'}>{t(lang, titleLoadingPoint)}</Card.Title>
                <Row>
                    <Form.Group as={Col} sm={12} lg={6}>
                        <Form.Label>
                            {t(lang, "roaming.waybill.v2.send.region")}
                        </Form.Label>
                        <SelectRegion
                            isClearable
                            onChange={(region) => {
                                if  (region) {
                                    setRegionId(region.code)
                                } else {
                                    setRegionId(null)
                                    setValue(`${railwayName}.station_id`, null)
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} lg={6}>
                        <Form.Label>
                            {t(lang, 'roaming.waybill.v2.send.railway.loading_point.name')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                            name={`${railwayName}.station_id`}
                            render={({field}) => (
                                <SelectStations
                                    regionId={regionId}
                                    defaultId={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {errors?.[railwayName]?.station_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/*<Form.Group as={Col} sm={12} >
                        <Form.Label>
                            {t(lang, 'roaming.waybill.v2.send.railway.loading_point.id')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            placeholder={t(lang, 'edi.common.name.placeholder')}
                            {...register(`${railwayName}.station_name`)}
                        />
                    </Form.Group>*/}
                </Row>
            </Card.Body>
        </Card>
    );
};

export default AdditionalInformationRailwayV2Point;
