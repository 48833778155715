import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    ADD_LEAD_NOTE_SUCCESS,
    ADD_LEAD_NOTE_FAILED,
    ADD_LEAD_NOTE_REQUESTED,

    EDIT_LEAD_NOTE_SUCCESS,
    EDIT_LEAD_NOTE_FAILED,
    EDIT_LEAD_NOTE_REQUESTED,

    DELETE_LEAD_NOTE_SUCCESS,
    DELETE_LEAD_NOTE_FAILED,
    DELETE_LEAD_NOTE_REQUESTED,
} from "../../../eventbus/contractor/contractorLeadEvents";

export const leadNoteSlice = createSlice({
    name: 'leadNote',
    initialState: {
        notes: [],
        countNotes: 0,
        filterOptions: {page: 1, limit: 10},
    },
    reducers: {
        updateNotes: (state, action) => {
            state.notes = action.payload
        },
        updateCountNotes: (state, action) => {
            state.countNotes = action.payload
        },
        updateFilterNote: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadLeadNotesAsync = ({id, page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getLeadNotes(id, params)
        .then(response => {
            dispatch(leadNoteSlice.actions.updateNotes(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const registerLeadNoteAsync = (payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(ADD_LEAD_NOTE_REQUESTED, payload);
        contractorService.registerLeadNote(payload)
            .then(response => {
                EventBus.dispatch(ADD_LEAD_NOTE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_LEAD_NOTE_FAILED, error)
                reject(error);
            })
    });
}
export const editLeadNoteAsync = (id, payload) => {
    return new Promise((resolve, reject) => {
        EventBus.dispatch(EDIT_LEAD_NOTE_REQUESTED, payload);
        contractorService.editLeadNote(id, payload)
            .then(response => {
                EventBus.dispatch(EDIT_LEAD_NOTE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_LEAD_NOTE_FAILED, error)
                reject(error);
            })
    });
}
export const deleteNoteAsync = ({id}) => {
    EventBus.dispatch(DELETE_LEAD_NOTE_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        contractorService.deleteLeadNote(id)
            .then((response) => {
                EventBus.dispatch(DELETE_LEAD_NOTE_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_LEAD_NOTE_FAILED, error.message);
                reject(error)
            })
    });
};

export const changeFilterLeadNotesAsync = (params) => (dispatch) => {
    return dispatch(leadNoteSlice.actions.updateFilterNote(params))
}

export const selectLeadNotes = (state) => state.leadNote.notes;
export const selectLeadNotesCount = (state) => state.leadNote.countNotes;
export const selectLeadNoteFilterOptions = (state) => state.leadNote.filterOptions;
export default leadNoteSlice.reducer
