import { createSlice } from "@reduxjs/toolkit";
import warehouseService from "../../../../services/warehouse/warehouseService";
import EventBus from "../../../eventbus/EventBus";
import {
    WAREHOUSE_REGISTER_FAILED,
    WAREHOUSE_REGISTER_REQUEST, WAREHOUSE_REGISTER_SUCCESS,
    WAREHOUSE_UPDATE_FAILED,
    WAREHOUSE_UPDATE_REQUEST,
    WAREHOUSE_UPDATE_SUCCESS
} from "../../../eventbus/warehouseEvents";
import settingsService from "../../../../services/settingsService";


export const WAREHOUSE_INITIALIZATION_STATE_NOT_STARTED = 0
export const WAREHOUSE_INITIALIZATION_STATE_LOADING = 1
export const WAREHOUSE_INITIALIZATION_STATE_COMPLETE = 2

export const warehouseSlice = createSlice({
    name: "warehouses",
    initialState: {
        warehouses: [],
        filterOptions: { page: 1, limit: 10 },
        warehouseInitializationState: WAREHOUSE_INITIALIZATION_STATE_NOT_STARTED,
    },
    reducers: {
        updateWarehouses: (state, action) => {
            state.warehouses = action.payload
        },
        updateFilterOptions: (state, action) => {
            state.filterOptions = { ...state.filterOptions, ...action.payload }
        },
        setWarehouseInitializationState: (state, action) => {
            state.warehouseInitializationState = action.payload
        }
    }
})

export const changeFilterOptionsAsync = (params) => (dispatch) => {
    return dispatch(warehouseSlice.actions.updateFilterOptions(params))
}

export const selectFilterOptions = state => state.warehouses.filterOptions

export const selectWarehouses = state => state.warehouses.warehouses

export const getAllWarehousesAsync = ({ page, limit, ...filters }) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = { skip, limit, ...filters }

    dispatch(warehouseSlice.actions.setWarehouseInitializationState(WAREHOUSE_INITIALIZATION_STATE_LOADING))
    return new Promise((resolve, reject) => {
        warehouseService.getAllWarehouses(params)
            .then((response => {
                dispatch(warehouseSlice.actions.updateWarehouses(response.data))
                resolve(response)
            }))
            .catch((error) => {
                reject(error)
            })
            .finally(() => {
                dispatch(warehouseSlice.actions.setWarehouseInitializationState(WAREHOUSE_INITIALIZATION_STATE_COMPLETE))
            })
    })
}

export const getOneWarehouseAsync = ({ id }) => {

    return new Promise((resolve, reject) => {
        warehouseService.getOneWarehouse({ id })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const registerWarehouseAsync = (name) => {

    EventBus.dispatch(WAREHOUSE_REGISTER_REQUEST)
    return new Promise((resolve, reject) => {
        warehouseService.registerWarehouse(name)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_REGISTER_SUCCESS)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_REGISTER_FAILED)
                reject(error)
            })
    })
}

export const updateOneWarehouseAsync = ({ id, name }) => {

    EventBus.dispatch(WAREHOUSE_UPDATE_REQUEST)
    return new Promise((resolve, reject) => {
        warehouseService.updateOneWarehouse({ id, name })
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_UPDATE_SUCCESS)
                return resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_UPDATE_FAILED)
                reject(error)
            })
    })
}

export const updateWarehouseItemPurchasePriceAsync = (payload) => {
    return new Promise((resolve, reject) => {
        warehouseService.updateWarehouseItemPurchasePrice(payload)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export const updateWarehouseItemAlertOn = (payload) => {
    return new Promise((resolve, reject) => {
        warehouseService.updateAlertOn(payload)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

// settings
export const updateWarehouseDefaultSetting = (payload) => {
    return new Promise((resolve, reject) => {
        settingsService.updateWarehouseSettings(payload)
            .then((res) => resolve(res.data))
            .catch(() => reject())
    })
}

export const selectWarehouseInitializationState = state => state.warehouses.warehouseInitializationState

export default warehouseSlice.reducer
