import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ContractorOrderDataTableHeader from './ContractorOrderDataTableHeader';
import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";
import {getStatusColor, GetStatusText} from '../../../../../../enum/OrderStatus';
import AdvanceTableCustomPagination from '../../../../../common/advance-table/AdvanceTableCustomPagination';
import {selectActiveOrganization} from '../../../../../../app/store/reducers/auth/authReducer';
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    changeFilterContractorOrdersAsync,
    selectContractorCountOrders,
    selectContractorOrders,
    selectContractorOrderFilterOptions, loadContractorOrdersAsync, loadContractorOrdersCountAsync
} from "../../../../../../app/store/reducers/contractor/contractorOrderReducer";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";


const ContractorOrderDataTable = () => {
    const contractor = useSelector(selectCardContractor)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const orders = useSelector(selectContractorOrders)
    const count = useSelector(selectContractorCountOrders)
    const filters = useSelector(selectContractorOrderFilterOptions)
    const activeOrganization = useSelector(selectActiveOrganization)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        dispatch(loadContractorOrdersAsync({...filters, contractor_inn: contractor?.inn}))
        dispatch(loadContractorOrdersCountAsync({...filters, contractor_inn: contractor?.inn}))
    }, [contractor, filters])

    const columns = [
        {
            Header: "#",
            id: "row",
            cellProps: {
                width: '40px',
            },
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, "edi.order.datatable.header.row.status"),
            cellProps: {
                width: '130px',
                className: 'p-0'
            },
            Cell: rowData => {
                const {original} = rowData.row;

                return <div className="d-grid gap-2">
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {t(lang, GetStatusText(original.status))}
                            </Tooltip>
                        }
                        placement="right"
                    >
                        <Button variant="primary" style={{backgroundColor: getStatusColor(original.status)}} as={Link}
                                to={`/edi/order/${original.id}`}>
                            {original.info.number}
                        </Button>
                    </OverlayTrigger>
                </div>
            }
        },
        {
            accessor: 'info.date',
            Header: t(lang, "edi.order.datatable.header.row.date"),
            cellProps: {
                width: '80px',
            },
            Cell: rowData => {
                const {date} = rowData.row.original.info
                return <b>{dayjs(date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'branch.name',
            Header: t(lang, "edi.order.datatable.header.row.unloading_point"),
            cellProps: {
                className: 'fw-bold'
            },
        },
        {
            accessor: 'expires_on',
            Header: t(lang, "edi.order.datatable.header.row.expire_date"),
            cellProps: {
                width: '80px',
                className: 'fw-bold'
            },
            Cell: rowData => {
                const {expires_on} = rowData.row.original
                return dayjs(expires_on, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "edi.order.datatable.header.row.created_date"),
            cellProps: {
                width: '80px',
                className: 'fw-bold'
            },
            Cell: rowData => {
                const {created_at} = rowData.row.original
                return dayjs(created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "edi.order.datatable.header.row.contractor"),
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer.inn

                if (isCustomer) {
                    return <>
                        {original.executor.name}
                    </>
                }

                return <>
                    {original.customer.name}
                </>
            }
        },
    ];

    const onPageChange = (page) => dispatch(changeFilterContractorOrdersAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterContractorOrdersAsync({...filters, limit: limit, page: 1}));

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={orders}
            sortable
            pagination
            perPage={1000}
        ><Card>
            <Card.Header>
                <ContractorOrderDataTableHeader table/>
            </Card.Header>
            <Card.Body className="p-0">
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-wrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
            </Card.Body>
            <Card.Footer>
                <AdvanceTableCustomPagination
                    count={count}
                    limit={filters.limit}
                    page={filters.page}
                    onPageChange={onPageChange}
                    onLimitChange={onLimitChange}
                />
            </Card.Footer>
        </Card>
        </AdvanceTableWrapper>
    );
};

export default ContractorOrderDataTable;
