import React, {useEffect} from "react"
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux"
import {Translate, selectLang} from "../../../../app/store/reducers/main/mainReducer"
import {loadCompanyProductCatalogsAsync} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer"
import ContractInfo from "./ContractInfo";
import OwnerInfo from "./OwnerInfo";
import Products from "./Products";
import ClientInfo from "./ClientInfo";
import Parts from "./Parts";
import {useLocation} from "react-router";

const ContractFormPage = ({onSubmit, loading, contract}) => {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    let clientInnFromRoute = queryParameters.get("clientInn");

    const methods = useForm({
        defaultValues: {
            contractId: "",
            contractDoc: {
                contractNo: "",
                contractDate: "",
                contractName: "",
                contractExpireDate: "",
                contractPlace: ""
            },
            owner: {
                tin: "",
                name: "",
                address: "",
                workPhone: "",
                mobile: "",
                fax: "",
                oked: "",
                account: "",
                bankId: "",
                fizTin: "",
                fio: "",
                branchCode: "",
                branchName: "",
                role: ""
            },
            client: {
                tin: clientInnFromRoute || "",
                name: "",
                address: "",
                workPhone: "",
                mobile: "",
                fax: "",
                oked: "",
                account: "",
                bankId: "",
                fizTin: "",
                fio: "",
                branchCode: "",
                branchName: "",
                role: ""
            },
            products: [
                {
                    catalogCode: null,
                    catalogName: null,
                    barCode: "",
                    name: "",
                    measureId: null,
                    count: null,
                    summa: null,
                    deliverySum: null,
                    vatRate: 0,
                    vatSum: null,
                    deliverySumWithVat: null,
                    withoutVat: false
                }
            ],
            parts: [
                {
                    title: "",
                    body: ""
                }
            ],
            notes: ''
        }
    });

    const {
        fields: fieldsProduct,
        append: appendProduct,
        remove: removeProduct
    } = useFieldArray({control: methods.control, name: "products", focusAppend: false})

    const {fields: fieldsPart, append: appendPart, remove: removePart} = useFieldArray({
        control: methods.control,
        name: "parts",
        focusAppend: false
    })

    const activeOrganization = useSelector(selectActiveOrganization)
    const lang = useSelector(selectLang)
    const dispatch = useDispatch()
    const t = Translate;

    const addFieldProduct = () => {
        appendProduct({
            catalogCode: null,
            catalogName: null,
            barCode: "",
            name: "",
            measureId: null,
            count: null,
            summa: null,
            deliverySum: null,
            vatRate: 0,
            vatSum: null,
            deliverySumWithVat: null,
            withoutVat: false
        })
    }
    const removeFieldProduct = index => {
        removeProduct(index)
    }

    const addFieldPart = () => {
        appendPart({
            title: "",
            body: "",
        })
    }
    const removeFieldPart = index => {
        removePart(index)
    }

    const onSubmitForm = (data) => {
        onSubmit(data);
    };

    useEffect(() => {
        let contractKeyProperty = contract && Object.keys(contract)
        if (contractKeyProperty?.length) {
            contractKeyProperty.forEach(key => {
                methods.setValue(key, contract[key])
            })
        }
    }, [contract]);

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
    }, [activeOrganization.inn]);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmitForm)} id="ContractForm">
                <Row>
                    <Col sm="12" className="mb-4">
                        <ContractInfo/>
                    </Col>
                    <Col sm="6" className="mb-4">
                        <OwnerInfo/>
                    </Col>
                    <Col sm="6" className="mb-4">
                        <ClientInfo/>
                    </Col>
                    <Col sm="12" className="mb-4">
                        <Products fieldsProduct={fieldsProduct} addFieldProduct={addFieldProduct}
                                  removeFieldProduct={removeFieldProduct}/>
                    </Col>
                    <Col sm="12" className="mb-4">
                        <Parts fieldsPart={fieldsPart} addFieldPart={addFieldPart} removeFieldPart={removeFieldPart}/>
                    </Col>
                    <Col sm="12">
                        <Card className="border-primary border-top-2">
                            <Card.Body className="text-end">
                                <Button variant="falcon-primary" size="lg" type="submit" disabled={loading}>
                                    {loading && <Spinner animation="border" role="status" size="sm"/>}
                                    {t(lang, "Сохранить")}
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    )
}

export default ContractFormPage