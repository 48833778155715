import React, {Fragment, useEffect} from 'react';
import {Badge, Card, OverlayTrigger, Popover} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {
    OperationTotalSumInfo,
    sourceName,
    sourceVariant
} from "../../../../../enum/ContractorWrapper";
import SoftBadge from "../../../../common/SoftBadge";
import {Link} from "react-router-dom";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import numeral from "numeral";
import CrmCustomerOrderDataTableHeader from "./CrmCustomerOrderDataTableHeader";
import {
    getOrders,
    getOrdersCount,
    selectOrders,
    selectFilterOptions,
    changeFilterOrdersAsync,
    selectCount,
    selectLoading
} from "../../../../../app/store/reducers/crm/order/crmCustomerOrderReducer";
import {getOrderStatusText, getOrderStatusVariant} from "../../../../../enum/CrmOrderStatus";
import IconButton from "../../../../common/IconButton";
import EventBus from "../../../../../app/eventbus/EventBus";
import {DELETE_CRM_ORDER_SUCCESS} from "../../../../../app/eventbus/crmOrderEvents";
import {toast} from "react-toastify";
import CrmCustomerOrderDeleteButton from "./CrmCustomerOrderDeleteButton";
import {FindCrmOrderSource} from "../../../../../enum/CrmOrderSources";
import UploadOrderToExcel from "../excel/UploadOrderToExcel";

const CrmCustomerOrderDataTable = () => {
    const dispatch = useDispatch()
    const orders = useSelector(selectOrders)
    const count = useSelector(selectCount)
    const loading = useSelector(selectLoading)
    const filters = useSelector(selectFilterOptions);
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate;
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const currency = useSelector(selectCurrency)
    const numberFormat = useSelector(selectNumberFormat);

    const loadData = () => {
        dispatch(getOrders({...filters}))
        dispatch(getOrdersCount({...filters}))
    }
    useEffect(() => {
        loadData()
    }, [filters, activeOrganization])

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'info.number',
            Header: t(lang, "crm.data.table.number"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <OverlayTrigger trigger={['hover', 'focus']}
                                    placement={'bottom'}
                                    overlay={
                                        <Popover id="popover-basic" className='mt-0'>
                                            <Popover.Header>
                                                <SoftBadge className={'d-block text-center fs--1'}>{t(lang, "Видеть")}</SoftBadge>
                                            </Popover.Header>
                                        </Popover>
                                    }
                    >
                        <Link to={`/crm/order/${original.id}`}>
                            <strong>{original.info.number}</strong>
                        </Link>
                    </OverlayTrigger>
                );
            }
        },
        {
            accessor: 'contractor',
            id: "executor.name",
            Header: 'Поставщик',
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.executor?.global_contractor_id ?
                            <Link to={`/crm/contractor-card/${original?.executor?.global_contractor_id}`}>
                                {original?.executor?.name}
                            </Link>
                            :
                            <>
                                {original.executor.name}
                            </>
                        }
                    </>
                )
            }
        },
        {
            accessor: 'contractor',
            id: "executor.inn",
            Header: 'Инн',
            cellProps: {
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return <>
                    {original.executor.inn}
                </>
            }
        },
        {
            accessor: 'amount',
            id: "amount",
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            Header: t(lang, "edi.contractor_payment.datatable.payment_amount"),
            Cell: ({row: {original}}) => {
                return <>
                    <div className={'fs-0'}>
                        {OperationTotalSumInfo(original.items, currency).map(item => <SoftBadge key={item.id}
                                                                                                className={'d-block text-end mb-1 flex-fill me-1'}
                                                                                                bg={"primary"}>
                            {numeral.formats[numberFormat].format(item.totalSum)}
                            <span className={'ms-2 fw-bold'}>{item.name}</span>
                        </SoftBadge>)}
                    </div>
                </>
            }
        },
        {
            accessor: 'source',
            id: "source",
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Header: t(lang, "crm.data.table.source"),
            Cell: ({row: {original}}) => {
                return <Badge bg={FindCrmOrderSource((original.source))?.variant}
                              className='me-2'>{t(lang, FindCrmOrderSource((original.source))?.label)}</Badge>
            }
        },
        {
            accessor: 'expires_on',
            id: "expire_date",
            Header: t(lang, "crm.data.table.expires_on"),
            cellProps: {
                width: '200px',
                className: 'fw-bold',
            },
            Cell: rowData => {
                const {expires_on} = rowData.row.original
                return expires_on && dayjs(expires_on, "YYYY-MM-DD").format(currentDateDayJSFormat)
            }
        },
        {
            accessor: 'status',
            id: "status",
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Header: 'Статус',
            Cell: ({row: {original}}) => {
                return <Badge bg={getOrderStatusVariant(original.status)}
                              className='me-2'>{t(lang, getOrderStatusText(original.status))}</Badge>
            }
        },
        {
            accessor: 'created_at',
            id: "created_at",
            Header: t(lang, "crm.data.table.date"),
            Cell: ({row: {original}}) => {
                return <b>{original.created_at}</b>
            }
        },
        {
            accessor: 'none',
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <UploadOrderToExcel id={original?.id} number={original?.info?.number} />
                        <Link to={`/crm/order/customer/edit/${original?.id}`}>
                            <IconButton
                                variant="falcon-warning"
                                icon="edit"
                                className="mx-2"
                                size="sm"
                            />
                        </Link>
                        <CrmCustomerOrderDeleteButton id={original?.id}/>
                    </>
                )
            },

        }
    ].filter(i => i);

    const onPageChange = (page) => dispatch(changeFilterOrdersAsync({...filters, page: page}));
    const onLimitChange = (limit) => dispatch(changeFilterOrdersAsync({
        ...filters,
        limit: limit,
        page: 1
    }));

    useEffect(() => {
        const onCrmOrderDeletedHandlerSuccess = EventBus.on(DELETE_CRM_ORDER_SUCCESS, () => {
            toast.success('success')
            loadData()
        })
        return () => {
            EventBus.remove(DELETE_CRM_ORDER_SUCCESS, onCrmOrderDeletedHandlerSuccess)
        }
    }, [])

    return (
        <Fragment>
            <div className={'mx-3'}>
                <CrmCustomerOrderDataTableHeader/>
            </div>
            <AdvanceTableWrapper
                columns={columns}
                data={orders}
                pagination
                perPage={10}>
                <Card className="mb-3">
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            isLoading={loading}
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onPageChange={onPageChange}
                            onLimitChange={onLimitChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default CrmCustomerOrderDataTable;
