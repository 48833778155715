import React from 'react';
import Dashboard from "./Dashboard";
import {Route, Switch} from "react-router";
import {useRouteMatch} from "react-router-dom";
import Organizations from "./organization/Organizations";
import ViewOrganization from "./organization/ViewOrganization";
import BankPaymentError from "./payment/BankPaymentError";
import DesktopAppVersions from "./desktop-app-versions/DesktopAppVersions";
import PaymentTab from "./payment/PaymentTab";
import ControlCompanyRoaming from "./control-company-roaming/ControlCompanyRoaming";
import EImzoSigners from "./e-imzo-signer/EImzoSigners";

const AdminLayout = () => {
    const {url} = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${url}`} exact component={Dashboard}/>

                <Route path={`${url}/organizations`} component={Organizations}/>
                <Route path={`${url}/organization/:id`} component={ViewOrganization}/>
                <Route path={`${url}/payments`} component={PaymentTab}/>
                <Route path={`${url}/bank-payment-error`} component={BankPaymentError}/>
                <Route path={`${url}/desktop-app/versions`} component={DesktopAppVersions}/>
                <Route path={`${url}/control-company-roaming`} component={ControlCompanyRoaming}/>
                <Route path={`${url}/e-imzo-signer`} component={EImzoSigners}/>
            </Switch>
        </>
    );
};

export default AdminLayout;
