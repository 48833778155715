import PropTypes from 'prop-types';
import React, {Fragment, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import AddBranchModal from "./AddBranchModal";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from 'react-redux';
import IconButton from "../../common/IconButton";
import DataTableSettings from "../DataTableSettings";
import {
    changeBranchDataTableColumn,
    loadBranchDataTableColumn,
    STORAGE_BRANCH
} from "../../../app/store/reducers/branch/branchReducer";
import BindWarehouseModal from "./BindWarehouseModal";

const InvoiceDataTableHeader = () => {
    const [showBindWarehouseModal, setShowBindWarehouseModal] = useState(false)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang);
    const t = Translate;


    const allColumns = JSON.parse(localStorage.getItem(STORAGE_BRANCH))
    if (!allColumns) {
        dispatch(loadBranchDataTableColumn())
        return <></>
    }


    return (
        <Fragment>
            <Row className="flex-between-center">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.branch.datatable.header.title")}</h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    <div className="d-flex gap-1">
                        <AddBranchModal className={'ms-auto'}/>

                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="right-left"
                            transform="shrink-3"
                            onClick={()=>setShowBindWarehouseModal(true)}
                        >
                          <span className="d-none d-sm-inline-block">
                            {t(lang,   "edi.branch.bind.warehouses.button")}
                        </span>
                        </IconButton>

                        <DataTableSettings allColumns={allColumns} changeDataTable={changeBranchDataTableColumn} />
                    </div>
                </Col>
            </Row>

            <BindWarehouseModal show={showBindWarehouseModal} setShow={setShowBindWarehouseModal} />

        </Fragment>

    );
};

InvoiceDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default InvoiceDataTableHeader;
