import WarehousesDatatable from "./WarehousesDatatable";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../app/store/reducers/auth/authReducer";
import {useEffect} from "react";
import {
    getAllWarehousesAsync,
    selectFilterOptions,
} from "../../../app/store/reducers/warehouse/warehouseReducer";
import EventBus from "../../../app/eventbus/EventBus";
import {
    WAREHOUSE_REGISTER_FAILED,
    WAREHOUSE_REGISTER_SUCCESS, WAREHOUSE_UPDATE_FAILED,
    WAREHOUSE_UPDATE_SUCCESS
} from "../../../app/eventbus/warehouseEvents";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";


const Warehouses = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const activeOrganization = useSelector(selectActiveOrganization)
    const filter = useSelector(selectFilterOptions)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllWarehousesAsync({...filter, id: activeOrganization.id}))
    },[filter, activeOrganization])


    useEffect(() => {
        const onWarehouseUpdateHandler = EventBus.on(WAREHOUSE_UPDATE_SUCCESS, () => {
            dispatch(getAllWarehousesAsync({...filter, id: activeOrganization.id}))
            toast.success(t(lang, "edi.common.toast.success"))
        })
        const onWarehouseUpdateFailedHandler = EventBus.on(WAREHOUSE_UPDATE_FAILED, () => {
            toast.error(t(lang,"edi.common.toast.error"))
        })


        const onWarehouseRegisterHandler = EventBus.on(WAREHOUSE_REGISTER_SUCCESS, () => {
            dispatch(getAllWarehousesAsync({...filter, id: activeOrganization.id}))
            toast.success(t(lang, "edi.common.toast.success"))
        })
        const onWarehouseRegisterFailedHandler = EventBus.on(WAREHOUSE_UPDATE_FAILED, () => {
            toast.error(t(lang,"edi.common.toast.error"))
        })

        return () => {
            EventBus.remove(WAREHOUSE_UPDATE_SUCCESS, onWarehouseUpdateHandler)
            EventBus.remove(WAREHOUSE_UPDATE_FAILED, onWarehouseUpdateFailedHandler)

            EventBus.remove(WAREHOUSE_REGISTER_SUCCESS, onWarehouseRegisterHandler)
            EventBus.remove(WAREHOUSE_REGISTER_FAILED, onWarehouseRegisterFailedHandler)
        }
    },[])


    return(
        <WarehousesDatatable/>
    )
}


export default Warehouses;