import {useDispatch, useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {excelFileUploader} from "../../../../helpers/excel-uploader";
import {faFileExcel, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {selectOrganizationTemplates} from "../../../../app/store/reducers/excel/excelReducer";
import {TYPE_SHIPMENTS, TYPE_SHIPMENTS_WITH_ITEMS} from "../../../../enum/ExcelTemplateTypes";
import {
    selectFilterOptions,
    uploadSelectedShipmentsToExcelAsync, uploadSelectedShipmentsWithItemsToExcelAsync,
    uploadShipmentsToExcelAsync,
    uploadShipmentsWithItemsToExcelAsync
} from "../../../../app/store/reducers/shipment/shipmentReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionEdiShipmentDownloadExcel} from "../../../../enum/Permission/EdiPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import {toast} from "react-toastify";
import {useParams} from "react-router";

const UploadShipmentListToExcel = ({rowValues, selectable,...props}) => {
    const {viewer} = useParams();
    const dispatch = useDispatch()
    const [shipmentsWithItemsTypeTemplates, setShipmentsWithItemsTypeTemplates] = useState([]);
    const [shipmentsTypeTemplates, setShipmentsTypeTemplates] = useState([]);
    const organizationTemplates = useSelector(selectOrganizationTemplates);

    const [loading, setLoading] = useState(false);
    const filter = useSelector(selectFilterOptions);
    const lang = useSelector(selectLang);
    const t = Translate;
    useEffect(() => {
        const shipmentsTemplates = organizationTemplates.filter(template => template.type === TYPE_SHIPMENTS) || [];
        const shipmentsWithItemsTemplates = organizationTemplates.filter(template => template.type === TYPE_SHIPMENTS_WITH_ITEMS) || [];

        setShipmentsTypeTemplates(shipmentsTemplates);
        setShipmentsWithItemsTypeTemplates(shipmentsWithItemsTemplates);
    }, [organizationTemplates])

    const uploadExcel = (uri) => {
        setLoading(true);

        if (rowValues?.length > 0) {
            for (let i = 0; i < rowValues.length; i++) {
                const row = rowValues[i];

                if (row.isSelected) {
                    uploadSelectedShipmentsToExcelAsync(viewer, row.original.id)
                        .then(loadedContent => {
                            excelFileUploader({
                                content: loadedContent,
                                fileName: t(lang, 'edi.shipments_list.excel.file.name'),
                            });
                        })
                        .catch(error => toast.error(t(lang, "common.toast.error")))
                        .finally(() => setLoading(false))
                }
            }


        } else {
            uploadShipmentsToExcelAsync(viewer, {...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'edi.shipments_list.excel.file.name')
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
    }

    const uploadExcelWithItems = (uri) => {
        setLoading(true);

        if (rowValues?.length > 0) {
            for (let i = 0; i < rowValues.length; i++) {
                const row = rowValues[i];

                if (row.isSelected) {

                    uploadSelectedShipmentsWithItemsToExcelAsync(viewer,[row.original.id])
                        .then(loadedContent => {
                            excelFileUploader({
                                content: loadedContent,
                                fileName: t(lang, 'edi.shipments_with_items.excel.file.name'),
                            });
                        })
                        .catch(error => toast.error(t(lang, "common.toast.error")))
                        .finally(() => setLoading(false))
                }
            }


        } else {
            uploadShipmentsWithItemsToExcelAsync(viewer,{...filter, uri: uri})
                .then(loadedContent => {
                    excelFileUploader({
                        content: loadedContent,
                        fileName: t(lang, 'edi.shipments_with_items.excel.file.name')
                    })
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }


    }
    const uploadShipments = (uri) => {
        if(dispatch(checkPermission(PermissionEdiShipmentDownloadExcel))) uploadExcel(uri)
        else dispatch(toastPermission())
    };

    const uploadShipmentsWithItems = (uri) => {
        if(dispatch(checkPermission(PermissionEdiShipmentDownloadExcel))) uploadExcelWithItems(uri)
        else dispatch(toastPermission())
    };

    return (
        <>
            <Dropdown {...props}>
                <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                    {loading
                        ?
                        <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                        :
                        <FontAwesomeIcon className="me-1" icon={faFileExcel}/>
                    }
                    <span className="d-none d-sm-inline-block">
                        {t(lang, 'edi.shipment.datatable.header.navigation.dropdown.export_to_excel')}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-2'>
                    <>
                        <Dropdown.Header className="text-black">{t(lang, 'edi.shipment.datatable.header.navigation.dropdown.item.export_shipments_to_excel')}</Dropdown.Header>
                        {shipmentsTypeTemplates.map((template, index) => (
                            <Dropdown.Item className="ps-4" key={index} onClick={() => uploadShipments(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                        ))}
                    </>

                    <>
                        <Dropdown.Header className="text-black">{t(lang, 'edi.shipment.datatable.header.navigation.dropdown.item.export_shipments_with_items_to_excel')}</Dropdown.Header>
                        {shipmentsWithItemsTypeTemplates.map((template, index) => (
                            <Dropdown.Item className="ps-4" key={index} onClick={() => uploadShipmentsWithItems(template.uri)}>{t(lang, `common.excel.template.name.${template.name}`)}</Dropdown.Item>
                        ))}
                    </>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default UploadShipmentListToExcel;
