import React, {useEffect} from 'react';
import {Card, Col, Form, FormControl, Row} from "react-bootstrap";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    deliveryTypeOptions, RoamingWaybillV2DeliveryTypeFromWarehouseToWarehouse,
    selectTransports, transportTypeOptions
} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import WaybillV2TypeSelect from "../WaybillV2TypeSelect";
import WaybillV2OldDocInfo from "../WaybillV2OldDocInfo";

const DetailsFormOne = () => {

    const {register, formState: {errors}, control, setValue} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Card className={'mb-3'}>
            <Card.Body>
                <Row>
                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                            rules={{
                                required: t(lang, 'edi.common.forms.validations.is_required'),
                            }}
                            name="delivery_type"
                            render={({field}) => (
                                <ReactSelect
                                    hideSelectedOptions={true}
                                    options={deliveryTypeOptions}
                                    classNamePrefix="react-select"
                                    getOptionValue={option => option.value}
                                    getOptionLabel={option => t(lang, option.label)}
                                    onChange={option => field.onChange(option?.value)}
                                    defaultValue={deliveryTypeOptions.find(option => option.value === field.value)}
                                    placeholder={t(lang, 'roaming.waybill.send.delivery_details_form.delivery_type.placeholder')}
                                />
                            )}
                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {errors?.delivery_type?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>{t(lang, 'roaming.waybill.send.delivery_details_transport_type')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                            rules={{
                                required: t(lang, 'edi.common.forms.validations.is_required'),
                            }}
                            name="transport_type"
                            render={({field}) => (
                                <ReactSelect
                                    hideSelectedOptions={true}
                                    options={transportTypeOptions}
                                    classNamePrefix="react-select"
                                    getOptionValue={option => option.value}
                                    getOptionLabel={option => t(lang, option.text)}
                                    onChange={option => field.onChange(option?.value)}
                                    defaultValue={transportTypeOptions.find(option => option.value === field.value)}
                                    placeholder={t(lang, 'roaming.waybill.send.delivery_details_transport_type:placeholder')}
                                />
                            )}
                        />
                        <Form.Control.Feedback className={'d-block'} type="invalid">
                            {errors?.transport_type?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>{t(lang, 'roaming.waybill.send.details_form.shipment_number')}<span
                            className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t(lang, 'roaming.waybill.send.details_form.shipment_number.placeholder')}
                            {...register('waybill_info.number', {
                                required: t(lang, 'edi.common.forms.validations.is_required')
                            })}
                        />
                        <FormControl.Feedback type="invalid" className={'d-block'}>
                            {errors?.waybill_info?.number?.message}
                        </FormControl.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>
                            {t(lang, 'roaming.waybill.send.details_form.shipment_date')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                            control={control}
                            name='waybill_info.date'
                            rules={{
                                required: t(lang, 'edi.common.forms.validations.is_required'),
                            }}
                            render={({field}) => (
                                <DatePicker
                                    isClearable
                                    selected={field.value}
                                    dateFormat={dateFormat}
                                    onChange={(date) => field.onChange(date)}
                                    className={'form-control'}
                                    placeholderText={t(lang, 'roaming.waybill.send.details_form.shipment_date.placeholder')}
                                />
                            )}
                        />
                        <Form.Control.Feedback type="invalid" className={'d-block'}>
                            {errors?.waybill_info?.date?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>
                            {t(lang, 'roaming.waybill.v2.send.type.select')}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                            name={'waybill_type'}
                            rules={{required: t(lang, 'items.common.validations.is_required')}}
                            render={({field}) => (
                                <WaybillV2TypeSelect
                                    defaultType={field.value}
                                    onChange={field.onChange}
                                    classNamePrefix={'react-select'}
                                />
                            )}
                        />
                    </Form.Group>
                    <WaybillV2OldDocInfo />
                </Row>
            </Card.Body>
        </Card>
    );
};

export default DetailsFormOne;
