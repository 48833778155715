import React, {useEffect} from 'react';
import dayjs from "dayjs";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    changeExcelRegistriesFilterOptions,
    selectExcelRegistries,
    selectExcelRegistriesCount,
    selectExcelRegistriesFilterOptions,
    setId, setShowModal, viewPage
} from "../../../../app/store/reducers/excel-registry/excelRegistryReducer";
import {GetStatusColor, GetStatusText} from "../../../../enum/ExcelRegistryStatus";
import {formatBytes} from "../../../../helpers/byte-formatter";
import ExcelRegistryDataTableHeader from "./ExcelRegistryDataTableHeader";
import DeleteExcelRegistry from "./DeleteExcelRegistry";
import ViewModalPage from "../../roaming/components/ViewModalPage";
import ViewExcelRegistry from "../../../../pages/main/roaming/excel-registry/ViewExcelRegistry";
import {
    roamingHideModal,
    setRoamingCommonHideModal
} from "../../../../app/store/reducers/roaming/roamingInvoiceReducer";

const ExcelRegistryDataTable = () => {
    const t = Translate;
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const count = useSelector(selectExcelRegistriesCount);
    const excelRegistries = useSelector(selectExcelRegistries);
    const filter = useSelector(selectExcelRegistriesFilterOptions);
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);
    const {id, showModal} = useSelector(viewPage)
    const hideModal = useSelector(roamingHideModal)

    const openViewModalPage = (e,id) => {
        const ctrlKey = e.ctrlKey
        const history = window.open
        if (ctrlKey) {
            history('/#/roaming/excel-registry/'+id, '_blank')
        } else {
            dispatch(setId(id))
            dispatch(setShowModal(true))
        }

    }

    const onHideModalPage = () => {
        if (showModal) {
            dispatch(setShowModal(false))
        }
    }

    // to close the modal on page change
    useEffect(() => {
        if(hideModal) {
            onHideModalPage()
            dispatch(setRoamingCommonHideModal(null))
        }
    }, [])

    const columns = [
        {
            Header: "#",
            id: "row",
            cellProps: {
                width: '40px',
            },
            filterable: false,
            Cell: ({row}) => {
                return `${((filter.page - 1) * filter.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'file.name',
            Header: t(lang, 'roaming.excel_registry.datatable.header.row.file_name'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            Cell: ({row: {original}}) => {
                return (
                    <strong onClick={(e)=>openViewModalPage(e,original.id)} className={'cursor-pointer text-primary'}>{original.file.name}</strong>
                )
            }
        },
        {
            accessor: 'file.size',
            Header: t(lang, 'roaming.excel_registry.datatable.header.row.file_size'),
            headerProps: {className: 'pe-1'},
            cellProps: {className: 'py-2'},
            filterable: false,
            Cell: ({row: {original}}) => {
                return formatBytes(original.file.size)
            }
        },
        {
            accessor: 'status',
            Header: t(lang, 'roaming.excel_registry.datatable.header.row.status'),
            cellProps: {
                width: '130px',
                className: 'p-0'
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-grid gap-2 text-center">
                        <span style={{backgroundColor: GetStatusColor(original.status), color: 'white'}} className='rounded px-2 py-1'>
                            {t(lang, GetStatusText(original.status))}
                        </span>
                    </div>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, 'roaming.excel_registry.datatable.header.row.created_at'),
            cellProps: {
                width: '80px',
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return dayjs(original.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD | HH:mm:ss")
            }
        },
        {
            accessor: 'none',
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <DeleteExcelRegistry
                            id={original.id}
                            buttonProps={{
                                size: 'sm',
                                className: 'me-2',
                                title: t(lang, 'items.common.delete')
                            }}
                        />
                    </>
                )
            },
            cellProps: {
                className: 'ps-3 text-end'
            }
        }
    ];

    const onPageChange = (page) => dispatch(changeExcelRegistriesFilterOptions({...filter, page: page}));
    const onLimitChange = (limit) => dispatch(changeExcelRegistriesFilterOptions({...filter, limit: limit, page: 1}));

    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={excelRegistries}
                pagination
                perPage={100}
            >
                <Card>
                    <Card.Header>
                        <ExcelRegistryDataTableHeader/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            page={filter.page}
                            limit={filter.limit}
                            onPageChange={onPageChange}
                            onLimitChange={onLimitChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

             <ViewModalPage show={showModal} onHide={onHideModalPage}>
                 {id && <ViewExcelRegistry modalId={id} />}
             </ViewModalPage>

        </>
    );
};

export default ExcelRegistryDataTable;
