import React, {useEffect} from "react"
import SystemPaymentsDatatable from "../../../../components/admin/organization/datatable/SystemPaymentsDatatable";
import {useDispatch, useSelector} from "react-redux";
import {
    loadPaymentsAsync,
    loadPaymentsCountAsync,
    selectSystemOrganizationsFilterOptionsPayment
} from "../../../../app/store/reducers/billing/billingReducer";
import {PAYMENTS} from "./PaymentTab";

const Payments = ({tab}) => {
    const dispatch = useDispatch();

    const filter = useSelector(selectSystemOrganizationsFilterOptionsPayment)

    useEffect(() => {
        if(tab === PAYMENTS) {
            dispatch(loadPaymentsAsync({...filter}));
            dispatch(loadPaymentsCountAsync({...filter}));
        }
    }, [filter, tab])

    return (
        <SystemPaymentsDatatable/>
    )
};

export default Payments
