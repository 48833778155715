import classNames from "classnames";
import {toast} from "react-toastify";
import Cleave from "cleave.js/react";
import {useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import SelectCurrencyCode from "../SelectCurrencyCode";
import React, {Fragment, useEffect, useState} from 'react';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {getRandomNumber} from "../../../../../helpers/utils";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {selectNationalCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {changeContractorStartingDebtsAsync} from "../../../../../app/store/reducers/contractor/contractorReducer";

const startingDebtsFieldArrayName = 'startingDebts';

const ChangeContractorStartingDebtComponent = ({contractor}) => {
    const [confirmCode] = useState(getRandomNumber(100000000, 999999999));
    const nationalCurrency = useSelector(selectNationalCurrency);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [step, setStep] = useState(1);
    const lang = useSelector(selectLang);
    const t = Translate;

    const defaultStartingDebt = {
        amount: 0,
        currencyId: nationalCurrency?.id,
    };

    const methods = useForm({
        defaultValues: {
            startingDebts: [],
            code: null,
        }
    });

    useEffect(() => {
        if (!!contractor) {
            const startingDebts = contractor.starting_debts.map(debt => {
                return {
                    amount: debt.amount,
                    currencyId: debt.currency.id,
                }
            });

            methods.setValue(startingDebtsFieldArrayName, startingDebts);
        }
    }, [contractor])

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setStep(1);
        methods.reset({keepDefaultValues: true});
        setShow(false);
    };

    const onSubmit = ({code, startingDebts}) => {
        switch (step) {
            case 1:
                setStep(step + 1);
                break;
            case 2:
                if (confirmCode !== Number(code))
                    return false;

                const payload = {
                    starting_debts: startingDebts.map(debt => {
                        return {
                            amount: Number(debt.amount),
                            currency_id: debt.currencyId,
                        }
                    }),
                };

                setLoading(true);
                changeContractorStartingDebtsAsync({id: contractor.id, payload: payload})
                    .then(() => {
                        toast.success(t(lang, 'crm.common.toast.success'));
                        handleClose();
                    })
                    .catch(() => toast.error(t(lang, 'crm.common.toast.error')))
                    .finally(() => setLoading(false))
        }
    };

    return (
        <Fragment>
            <IconButton
                size={'sm'}
                variant={'danger'}
                onClick={handleShow}
                icon={'pen-to-square'}
            >
                {t(lang, 'crm.contractor.change_contractor_starting_debt.button.title')}
            </IconButton>

            <Modal show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{t(lang, 'crm.contractor.change_contractor_starting_debt.modal.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {step === 1 && <ChangeContractorStartingDebtForm arrayFieldName={startingDebtsFieldArrayName} defaultStartingDebt={defaultStartingDebt}/>}
                            {step === 2 && <ChangeContractorStartingDebtConfirmationForm confirmCode={confirmCode}/>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'crm.common.back.button')}</Button>
                            <Button variant={'primary'} type={'submit'} disabled={loading}>
                                {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                {step === 1 && t(lang, 'crm.common.next.button')}
                                {step === 2 && t(lang, 'crm.common.change.button')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

const ChangeContractorStartingDebtForm = ({arrayFieldName, defaultStartingDebt}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {fields: startingDebts, append: appendStartingDebt, remove: removeStartingDebt} = useFieldArray({name: arrayFieldName});
    const {formState: {errors}} = useFormContext();

    useEffect(() => {
        if (startingDebts.length === 0) {
            addNewStartingDebt();
        }
    },)

    const addNewStartingDebt = () => {
        appendStartingDebt(defaultStartingDebt);
    };

    const deleteStartingDebt = index => {
        removeStartingDebt(index);
    };

    return (
        <Row>
            <Form.Group as={Col} xs={12}>
                <div className={"d-flex justify-content-between mb-1"}>
                    <Form.Label>{t(lang, 'crm.contractor.change_contractor_starting_debt.form.starting_debt.title')}</Form.Label>
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        onClick={addNewStartingDebt}/>
                </div>
                {startingDebts.map((debt, index) => (
                    <Form.Group key={debt.id} className={'text-start mb-3 d-flex'}>
                        <Controller
                            rules={{required: t(lang, 'crm.common.forms.validations.is_required')}}
                            name={`${arrayFieldName}.${index}.amount`}
                            render={({field}) => (
                                <Cleave
                                    value={field.value}
                                    onFocus={event => event.target.select()}
                                    onChange={event => field.onChange(event.target.rawValue)}
                                    options={{numeral: true, delimiter: ' ', numeralDecimalScale: 3}}
                                    placeholder={t(lang, 'crm.contractor.change_contractor_starting_debt.form.starting_debt.placeholder')}
                                    className={classNames('form-control rounded-end-0', {'is-invalid': errors?.starting_debt_states?.[index]?.amount})}
                                />
                            )}
                        />
                        <Controller
                            name={`${arrayFieldName}.${index}.currencyId`}
                            render={({field}) => (
                                <SelectCurrencyCode
                                    defaultValue={field.value}
                                    onChange={(currency) => field.onChange(currency.id)}
                                />
                            )}
                        />

                        {startingDebts.length !== 1 && (
                            <IconButton
                                size="sm"
                                icon={"trash-alt"}
                                className={"ms-2"}
                                variant={'falcon-default'}
                                iconClassName={"text-secondary"}
                                onClick={() => deleteStartingDebt(index)}
                            />
                        )}
                    </Form.Group>
                ))}
            </Form.Group>
        </Row>
    );
};

const ChangeContractorStartingDebtConfirmationForm = ({confirmCode}) => {
    const {formState: {errors}} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Form.Group>
            <Form.Label>
                {t(lang, 'crm.contractor.change_contractor_starting_debt.form.confirm_code.title')}
                <span
                    className="ms-1 text-primary"
                    onCopy={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                >{confirmCode}</span>
            </Form.Label>
            <Controller
                name={'code'}
                rules={{
                    required: t(lang, 'items.common.validations.is_required'),
                    validate: (value) => {
                        if (+value === confirmCode)
                            return true;
                        return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: confirmCode});
                    }
                }}
                render={({field}) => (
                    <Cleave
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.rawValue)}
                        options={{numeral: true, delimiter: ' ', numeralDecimalScale: 3}}
                        className={classNames('form-control', {'is-invalid': errors?.code})}
                        placeholder={t(lang, 'crm.contractor.change_contractor_starting_debt.form.confirm_code.placeholder')}
                    />
                )}
            />
            <Form.Control.Feedback type={'invalid'}>
                {errors?.code?.message}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default ChangeContractorStartingDebtComponent;