import React, {useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {selectBranches} from '../../app/store/reducers/branch/branchReducer';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const SelectBranch = React.forwardRef(({defaultValue, onChange, roaming_address, options, ...props}, ref) => {
    const branches = useSelector(selectBranches);
    const [value, setValue] = useState(null)
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (defaultValue !== null) {
            let val
            if (options) {
                val = options.find(option => option?.code === defaultValue)
            } else {
                val = branches.find(branch => branch?.id === defaultValue)
            }
            setValue(val)
        } else {
            setValue(null)
        }
    },[defaultValue, options])

    const onChangeItem = option => {
        setValue(option)
        onChange(option)
    }

    const getOptionValue = option => option?.id
    const getOptionLabel = option => option?.name
    const formatOptionLabel = option => {
        return(
            <span>{option.name}</span>
        )
    }

    return (
        <ReactSelect
            options={options ? options.filter(i => i.roaming_address) : roaming_address ? branches.filter(i => i.roaming_address) : branches}
            formatOptionLabel={formatOptionLabel}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onChangeItem}
            isClearable
            hideSelectedOptions
            classNamePrefix="react-select"
            noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
            placeholder={t(lang, "roaming.common.select.placeholder")}
            {...props}
        />
    )
});

SelectBranch.propTypes = {
    onChange: PropTypes.func
}

SelectBranch.defaultProps = {
    onChange: () => {}
}

export default SelectBranch;
