import {Controller, FormProvider, useForm, useWatch} from "react-hook-form";
import {
    boardRegister,
    editBoard, selectBoards,
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {Form} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import SelectBoardType from "./SelectBoardType";
import {ContractorDebtBoardType, DefaultBoardType} from "../../../../enum/KanbanWrapper";

const BoardForm = ({setLoading, setShow, data, id}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const methods = useForm({
        defaultValues: {
            name: data?.name || null,
            type: data?.type || DefaultBoardType
        }
    })

    const boardType = methods.watch('type')

    const onSubmit = ({name, type}) => {
        setLoading(true)
        if (id) {
            editBoard({id, name, type})
                .then(() => setShow(false))
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        } else {
            boardRegister({name, type})
                .then(()=> setShow(false))
                .catch((e) => new Error(e))
                .finally(()=> setLoading(false))
        }

    }

    return (
        <FormProvider {...methods}>
            <Form id={'board'} onSubmit={methods.handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label>{t(lang, 'task.board.form.title')}</Form.Label>
                    <Form.Control
                        type={'text'}
                        disabled={boardType === ContractorDebtBoardType}
                        placeholder={t(lang, 'task.board.form.title.placeholder')}
                        isInvalid={methods?.formState?.errors?.name}
                        {...methods.register('name', {
                            required: boardType !== ContractorDebtBoardType ? t(lang, "task.board.common.forms.validations.is_required") : false
                        })}
                    />
                    <Form.Control.Feedback type="invalid">
                        {methods?.formState?.errors?.name?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t(lang, 'task.board.form.board.type')}</Form.Label>
                    <Controller
                        name={'type'}
                        rules={{required: t(lang, "task.board.common.forms.validations.is_required")}}
                        render={({field}) => (
                            <SelectBoardType
                                onChange={field.onChange}
                                defaultType={field.value} />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {methods?.formState?.errors?.type?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </FormProvider>
    );
};


export default BoardForm;
