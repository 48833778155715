import React from 'react';
import dayjs from "dayjs";
import numeral from "numeral";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import IconButton from "../../../common/IconButton";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import {Button, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import AdvanceTableFooter from "../../../common/advance-table/AdvanceTableFooter";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import AdvanceTableCustomPagination from "../../../common/advance-table/AdvanceTableCustomPagination";
import {getStatusColorReturnInvoice, GetStatusTextReturnInvoice} from "../../../../enum/ReturnInvoiceStatus";
import {selectCount, selectReturnInvoices} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import {selectDateDayJSFormat, selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";

const ReturnInvoicesOfReturnShipmentDataTable = ({onRefresh, filters, onFilterChanged}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const count = useSelector(selectCount);
    const numberFormat = useSelector(selectNumberFormat);
    const returnInvoices = useSelector(selectReturnInvoices);
    const activeOrganization = useSelector(selectActiveOrganization)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat);

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '40px',
            },
            Cell: ({row}) => {
                return `${((filters.page-1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'return_invoice_info',
            Header: t(lang, "edi.return_invoice.datatable.header.row.status"),
            cellProps: {
                width: '100px',
                className: 'p-0'
            },
            Cell: rowData => {
                const {original} = rowData.row

                return <div className="d-grid gap-2">
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {t(lang, GetStatusTextReturnInvoice(original.status))}
                            </Tooltip>
                        }
                        placement="right"
                    >
                        <div className="d-grid gap-2">
                            <Button variant="primary"  style={{backgroundColor: getStatusColorReturnInvoice(original.status)}} as={Link} to={`../return-invoice/${original.id}`}>
                                {original.return_invoice_info.number}
                            </Button>
                        </div>
                    </OverlayTrigger>
                </div>
            }
        },
        {
            accessor: 'return_invoice_info.date',
            Header: t(lang, "edi.return_invoice.datatable.header.row.date"),
            cellProps: {
                width: '80px',
            },
            Cell: ({row: {original}}) => {
                return <b>{dayjs(original.return_invoice_info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</b>
            }
        },
        {
            accessor: 'total_info.total',
            Header: t(lang, "edi.return_invoice.datatable.header.row.total_sum"),
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return numeral.formats[numberFormat].format(original.total_info.total)
            }
        },
        {
            accessor: 'return_shipment.return_order.branch.name',
            Header: t(lang, "edi.return_invoice.datatable.header.row.branch"),
            cellProps: {
                // width: '80px',
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                return <b>{original.return_shipment.return_order.branch.name}</b>
            }
        },
        {
            accessor: 'contractor',
            Header: t(lang, "edi.return_invoice.datatable.header.row.contractor"),
            cellProps: {
                // width: '250px',
                className: 'fw-bold'
            },
            Cell: ({row: {original}}) => {
                const isCustomer = activeOrganization.inn === original.customer.inn

                if(isCustomer){
                    return<>
                        {original.executor.name}
                    </>
                }

                return<>
                    {original.customer.name}
                </>
            }
        },
    ];

    const onLimitChange = (limit) => onFilterChanged({limit: limit, page: 1});
    const onPageChange = (page) => onFilterChanged({...filters, page: page});

    const refreshHandler = () => {
        onRefresh();
    }

    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={returnInvoices}
                selection
                width={10}
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header className="text-end">
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            transform="shrink-3"
                            icon="refresh"
                            className="mx-2"
                            onClick={refreshHandler}
                        >
                            <span className="d-none d-sm-inline-block ms-1">{t(lang, "edi.orders.order.resume_order_button")}</span>
                        </IconButton>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-wrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            table
                        />
                        <AdvanceTableCustomPagination
                            count={count}
                            page={filters.page}
                            limit={filters.limit}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default ReturnInvoicesOfReturnShipmentDataTable;