import {createSlice} from "@reduxjs/toolkit";
import itemHistoryService from "../../../../services/item/itemHistoryService";
import queryString from 'query-string';
import dayjs from "dayjs";

const currentDate = new Date()
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

export const itemHistorySlice = createSlice({
    name: 'itemHistory',
    initialState: {
        histories: [],
        count: 0,
        filters: {
            page: 1,
            limit: 10,
            start: dayjs(dateTimeStartFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            end: dayjs(dateTimeEndFormat)?.format("YYYY-MM-DD HH:mm:ss"),
            item_id: null,
            item_ids: [],
            category_id: null,
            types: [],
            warehouses: []
        },
    },
    reducers: {
        setHistories: (state, action) => {
            state.histories = action.payload;
        },
        updateCountHistories: (state, action) => {
            state.count = action.payload;
        },
        updateFilters: (state, action) => {
            state.filters = action.payload;
        },
        updateItemHistories: (state, action) => {
            state.histories = action.payload || []
        },
    }
})

export const loadCountHistoriesAsync = (payload) => (dispatch) => {
    itemHistoryService.getAllItemHistoriesCount(payload)
        .then(res => {
            dispatch(itemHistorySlice.actions.updateCountHistories(res.data))
        })
        .catch(e => {
            console.log(e)
        })
}

export const loadItemHistoriesAsync = (payload) => (dispatch) => {
    const limit = payload.limit;
    const skip = (payload.page - 1) * limit;
    const data = {...payload, skip, limit}

    itemHistoryService.getAllItemHistories(data)
        .then((response) => {
            dispatch(itemHistorySlice.actions.updateItemHistories(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const changeFiltersAsync = ({...filters}) => dispatch => {
    dispatch(itemHistorySlice.actions.updateFilters({...filters}))
}

export const selectHistories = (state) => state.itemHistory.histories;
export const selectCountHistories = (state) => state.itemHistory.count;
export const selectFilters = (state) => state.itemHistory.filters;

export default itemHistorySlice.reducer;