import React, {useState} from 'react';
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Card, Col, Row} from "react-bootstrap";
import {faBookmark, faSignature} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../common/IconButton";
import RoamingInvoiceFormCardInfo from "./RoamingInvoiceFormCardInfo";
import RoamingInvoiceFormCardExecutor from "./RoamingInvoiceFormCardExecutor";
import RoamingInvoiceFormCardCustomer from "./RoamingInvoiceFormCardCustomer";
import RoamingInvoiceFormCardReleaserInfo from "./RoamingInvoiceFormCardReleaserInfo";
import RoamingInvoiceFormCardEmpowermentInfo from "./RoamingInvoiceFormCardEmpowermentInfo";
import RoamingInvoiceFormCardItems from "./RoamingInvoiceFormCardItems";
import RoamingInvoiceFormCardOrigin from "./RoamingInvoiceFormCardOrigin";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";

const RoamingInvoiceForm = ({onSend, onSave, isEditOrCopy}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [customerPrivileges, setCustomerPrivileges] = useState([]);
    const [executorPrivileges, setExecutorPrivileges] = useState([]);
    const [turnoverPrivileges, setTurnoverPrivileges] = useState([]);

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <RoamingInvoiceFormCardOrigin />
            </Col>
            <Col xs={12}>
                <RoamingInvoiceFormCardInfo />
            </Col>
            <Col xs={12} md={6}>
                <RoamingInvoiceFormCardExecutor isEditOrCopy={isEditOrCopy}
                                                setExecutorPrivileges={setExecutorPrivileges}
                                                setTurnoverPrivileges={setTurnoverPrivileges}
                />
            </Col>
            <Col xs={12} md={6}>
                <RoamingInvoiceFormCardCustomer isEditOrCopy={isEditOrCopy}
                                                setCustomerPrivileges={setCustomerPrivileges}
                />
            </Col>
            <Col xs={12}>
                <RoamingInvoiceFormCardItems customerPrivileges={customerPrivileges}
                                             executorPrivileges={executorPrivileges}
                                             turnoverPrivileges={turnoverPrivileges}
                />
            </Col>
            <Col xs={12} md={6}>
                <RoamingInvoiceFormCardEmpowermentInfo isEditOrCopy={isEditOrCopy}/>
            </Col>
            <Col xs={12} md={6}>
                <RoamingInvoiceFormCardReleaserInfo isEditOrCopy={isEditOrCopy}/>
            </Col>
            <Col xs={12}>
                <Card>
                    <Card.Body className="text-end">
                        <IconButton icon={faBookmark}
                                    variant="falcon-warning"
                                    size="lg"
                                    className="w-100 w-md-auto me-md-3"
                                    onClick={onSave}
                        >
                            {t(lang, "roaming.invoice.save_button")}
                        </IconButton>
                        <SelectCertificateWrapperComponent size="lg" className="w-100 mt-2 mt-md-0 w-md-auto" submitButton={
                            <IconButton icon={faSignature}
                                        variant="falcon-primary"
                                        size="lg"
                                        className="w-100 mt-2 mt-md-0 w-md-auto"
                                        id="sendButton"
                                        onClick={onSend}
                            >
                                {t(lang, "roaming.invoice.send_button")}
                            </IconButton>
                        } openDialogButtonText={t(lang, "roaming.invoice.send_button")}/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default RoamingInvoiceForm;

RoamingInvoiceForm.propTypes = {
    onSend: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}
