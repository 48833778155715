import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import IconButton from "../common/IconButton";
import {Button, Modal} from "react-bootstrap";
import React, {Fragment, useState} from 'react';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {deleteEmployeeAsync} from "../../app/store/reducers/employee/employeeReducer";

const DeleteEmployeeModal = ({employee}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const deleteEmployee = (employeeId) => {
        deleteEmployeeAsync(employeeId)
            .then(() => {
                toast.success(t(lang, 'employee.delete_employee_modal.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'employee.delete_employee_modal.toast.error')))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                variant="falcon-danger"
                icon="trash" className="mx-1"
                size="sm" transform="shrink-3"
            />

            <Modal show={show} onHide={handleClose} size={'md'}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>{t(lang, 'employee.delete_employee_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(lang, 'employee.delete_employee_modal.description')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'secondary'} onClick={handleClose}>{t(lang, "main.common.back")}</Button>
                    <Button variant={'danger'} onClick={() => deleteEmployee(employee.id)} disabled={loading}>
                        {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                        {t(lang, 'main.common.delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default DeleteEmployeeModal;