import React, {useEffect, useState} from "react";
import ProductMapService from "../../../../services/productMapService";
import {Card, Row} from "react-bootstrap";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import DataTableFooter from "../../draft-item/components/datatable/DataTableFooter";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import Flex from "../../../common/Flex";
import DeleteItemProductIdentityBindAttribute from "./DeleteItemProductIdentityBindAttribute";
import EventBus from "../../../../app/eventbus/EventBus";
import {DELETE_PRODUCT_MAP_IDENTITY_SUCCESS} from "../../../../app/eventbus/itemEvents";

const ItemProductIdentities = ({itemId}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [bindItems, setBindItems] = useState([]);

    const columns = [
        {
            Header: '#',
            Cell: ({row}) => `${row.index + 1}`,
            cellProps: {
                width: '30px',
            }

        },
        {
            accessor: 'contractor',
            Header: t(lang, 'edi.common.label.inn'),
            Cell: ({row: {original}}) => {
                return (
                    <div style={{maxWidth: '200px'}}>
                        <h6 className="fw-semi-bold text-truncate fs-0">{original.contragent.inn}</h6>
                    </div>
                )
            },
            cellProps: {
                width: '400px',
            }
        },
        {
            accessor: 'name',
            Header: t(lang, 'items.common.name'),
            Cell: ({row: {original}}) => {
                return (
                    <h6 className="m-0 fs-0">{original.name}</h6>
                )
            },
        },
        {
            accessor: 'barcode',
            Header: t(lang, 'items.common.barcode'),
            Cell: ({row: {original}}) => {
                return (
                    <h6 className="m-0 fs-0">{original.barcode ?? t(lang, 'edi.common.not_specified')}</h6>
                )
            },
            cellProps: {
                width: '150px',
                className: 'fs-0'
            }
        },
        {
            accessor: 'code',
            Header: t(lang, 'items.common.sku'),
            Cell: ({row: {original}}) => {
                return (
                    <h6 className="m-0 fs-0">{original.code ?? t(lang, 'edi.common.not_specified')}</h6>
                )
            },
            cellProps: {
                width: '150px',
                className: 'fs-0'
            }
        },
        {
            accessor: 'none',
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <DeleteItemProductIdentityBindAttribute
                            itemId={itemId}
                            id={original.id}
                            buttonProps={{
                                size: 'sm',
                                className: 'me-2',
                                title: t(lang, 'items.common.delete')
                            }}
                        />
                    </>
                )
            },
            cellProps: {
                className: 'ps-3 text-end'
            }
        }
    ];

    const loadProductIdentity = () => {
        ProductMapService.getProductIdentity({itemId: itemId}).then(res => {
            setBindItems(res.data.bind_attributes);
        }).catch(err => {
        })
    }

    useEffect(() => {
        loadProductIdentity();
        const onDraftItemDeleteSuccessHandler = EventBus.on(DELETE_PRODUCT_MAP_IDENTITY_SUCCESS, () => {
            loadProductIdentity()
        });

        return () => {
            EventBus.remove(DELETE_PRODUCT_MAP_IDENTITY_SUCCESS, onDraftItemDeleteSuccessHandler);
        }
    }, [])

    return (<>
        <AdvanceTableWrapper
            columns={columns}
            data={bindItems}
            pagination
            selection
            width="50px"
        >
            <Card className={"mt-2"}>
                <Card.Header>
                    <Row>
                        <Flex justifyContent="between">
                            <h5>{t(lang, 'items.common.unidentified')}</h5>
                        </Flex>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <DataTableFooter/>
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    </>)
}

export default ItemProductIdentities;
