import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from 'react';
import ContractorPayment from "../../crm/contractor/ContractorPayment";
import ContractorPayout from "../../crm/contractor/ContractorPayout";
import ViewSale from "../../warehouse-operation/sale/ViewSale";
import ViewRefund from "../../warehouse-operation/refund/ViewRefund";
import ViewReturnPurchase from "../../warehouse-operation/return-purchase/ViewReturnPurchase";
import ViewPurchase from "../../warehouse-operation/purchase/ViewPurchase";
import {Modal} from "react-bootstrap";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";
import {
    CASH_EXPENSE,
    CASH_IN,
    CASH_OUT,
    CashBoxOperationTypeCashIn,
    CashBoxOperationTypeCashOut,
    CashBoxOperationTypeExpense,
    CashBoxOperationTypePayment,
    CashBoxOperationTypePayout,
    CashBoxOperationTypeTransferIn,
    CashBoxOperationTypeTransferOut,
    PAYMENT,
    PAYOUT,
    PURCHASE,
    REFUND,
    RETURN,
    SALE,
    TRANSFER
} from "../../../../enum/cash_box/cashBoxOperationType";
import ViewCashIn from "../cash-in/ViewCashIn";
import ViewTransfer from "../transfer/ViewTransfer";
import ViewCashOut from "../cash-out/ViewCashOut";
import ViewExpense from "../expense/ViewExpense";


const viewPages = [
    {key: CASH_IN, component: ViewCashIn},
    {key: CASH_OUT, component: ViewCashOut},
    {key: CASH_EXPENSE, component: ViewExpense},
    {key: TRANSFER, component: ViewTransfer},
    {key: PAYMENT, component: ContractorPayment},
    {key: PAYOUT, component: ContractorPayout},
    {key: SALE, component: ViewSale},
    {key: REFUND, component: ViewRefund},
    {key: RETURN, component: ViewReturnPurchase},
    {key: PURCHASE, component: ViewPurchase},
]

const CashBoxOperationModal = forwardRef(({viewType, setViewTpe}, ref) => {

    const [show, setShow] = useState(false)
    const [showPage, setShowPage] = useState(null)
    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
        setShowPage(null)
        setViewTpe(null)
    }

    useImperativeHandle(ref, () => ({
        handleShow
    }))

    const handlePage = (viewTypeKey, additionKey) => {
        if (additionKey) {
            setShowPage({operation: viewTypeKey, id: viewType[additionKey]})
        } else {
            setShowPage({operation: viewTypeKey, ...viewType[viewTypeKey]})
        }
    }

    useEffect(() => {
        if (viewType) {
            for (const viewTypeKey in viewType) {
                switch (viewTypeKey) {
                    case PURCHASE:
                    case REFUND:
                    case RETURN:
                    case SALE:
                        if (viewType[viewTypeKey]) {
                            handlePage(viewTypeKey);
                            return;
                        }
                        break;
                    default:
                        break;
                }
            }
            if (!showPage) {
                if (viewType?.type === CashBoxOperationTypePayment) {
                    handlePage(PAYMENT, 'ref_operation_id')
                } else if (viewType?.type === CashBoxOperationTypePayout) {
                    handlePage(PAYOUT, 'ref_operation_id')
                } else if (viewType?.type === CashBoxOperationTypeCashIn) {
                    handlePage(CASH_IN, 'ref_operation_id')
                } else if (viewType?.type === CashBoxOperationTypeCashOut) {
                    handlePage(CASH_OUT, 'ref_operation_id')
                } else if (viewType?.type === CashBoxOperationTypeExpense) {
                    handlePage(CASH_EXPENSE, 'ref_operation_id')
                } else if (viewType?.type === CashBoxOperationTypeTransferIn || viewType?.type === CashBoxOperationTypeTransferOut) {
                    handlePage(TRANSFER, 'ref_operation_id')
                }
            }
        }
    }, [viewType]);

    const currentViewPage = viewPages.find(page => page.key === showPage?.operation);

    return (
        <Fragment>
            <Modal fullscreen show={show} onHide={handleClose}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    {!viewType && <SpinnerComponent/>}
                    {viewType && currentViewPage && (
                        <currentViewPage.component viewId={showPage.id}/>
                    )}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
});

export default CashBoxOperationModal;