import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Card, Spinner} from "react-bootstrap";
import {faFileZipper} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {loadFreeFormDocumentZipFileAsync} from "../../../../app/store/reducers/roaming/roamingFreeFormDocumentReducer";

const DownloadFreeFormDocumentZipFile = ({id}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const downloadZip = () => {
        setLoading(true);

        loadFreeFormDocumentZipFileAsync(id)
            .then(response => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/zip'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${id}.zip`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);

            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Card className="bg-light cursor-pointer shadow-none border" onClick={downloadZip}>
                <Card.Body className="text-center">
                    <div>
                        <h4>
                            {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size="sm" />}
                            {t(lang, 'roaming.free_form_document.view.download_zip')}
                        </h4>
                        <FontAwesomeIcon className="fs-3" icon={faFileZipper}/>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default DownloadFreeFormDocumentZipFile;