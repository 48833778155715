import {Button, Form, Spinner, Col, Row, Card} from "react-bootstrap";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import classNames from "classnames";
import IconButton from "../../../../common/IconButton";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";

const LeadForm = ({onClose, loading, onSubmit, lead}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const {register, formState: {errors}, handleSubmit, control, setValue} = useForm({
        defaultValues: {
            name: lead?.name,
            inn: lead?.inn,
            type: lead?.type,

            phones: lead?.contacts.filter(contact => contact.type === 1),
            emails: lead?.contacts.filter(contact => contact.type === 3)
        },
    });


    useEffect(() => {
        let mm = lead?.type.map(t => {
            return String(t)
        })
        setValue('type', mm || [])
    }, [lead])

    const {fields: fieldsPhone, append: appendPhone, remove: removePhone} = useFieldArray({
        control,
        name: "phones"
    });
    const {fields: fieldsEmails, append: appendEmail, remove: removeEmail} = useFieldArray({
        control,
        name: "emails"
    });

    const onChangeCompany = company => {
        if (company) {
            setValue('inn', company.inn)
            setValue('name', company.name)
        }
    };

    return (<>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Card className="h-100">
                <Card.Header>
                    <div className={"d-flex"}>
                        <div className={"me-3"}>
                            <Form.Check type="checkbox">
                                <Form.Check.Input
                                    type="checkbox"
                                    value='1'
                                    {...register('type')}
                                />
                                <Form.Check.Label
                                    className="mb-0 h5">{t(lang, "edi.common.buyer")}</Form.Check.Label>
                            </Form.Check>
                        </div>
                        <div>
                            <Form.Check type="checkbox">
                                <Form.Check.Input
                                    type="checkbox"
                                    value='2'
                                    {...register('type')}
                                />
                                <Form.Check.Label
                                    className="mb-0 h5">{t(lang, "edi.common.executor")}</Form.Check.Label>
                            </Form.Check>
                        </div>
                    </div>
                </Card.Header>

                <Card.Body className="bg-light">
                    <Row className="g-3">
                        <Col xs={6}>
                            <Form.Group className="text-start">
                                <Form.Label>{t(lang, "edi.common.inn")}</Form.Label>
                                <Controller name="inn"
                                            control={control}
                                            render={({field}) => (
                                                <FindCompanySearchable defaultInn={field.value}
                                                                       onChange={(company) => {
                                                                           onChangeCompany(company);
                                                                           field.onChange(company?.inn);
                                                                       }}
                                                                       inputProps={{
                                                                           placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                                                       }}
                                                                       ref={field.ref}
                                                />
                                            )}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="text-start">
                                <Form.Label>{t(lang, "edi.common.name")}</Form.Label>
                                <Form.Control
                                    placeholder={t(lang, "edi.common.name")}
                                    name="name"
                                    type="text"
                                    isInvalid={errors?.name?.message}
                                    isValid={Object.keys(errors).length > 0 && !errors?.name?.message}
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: t(lang, "edi.common.forms.validations.is_required")
                                        }
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.name?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className={'mt-4'}>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>
                                    {t(lang, "edi.contract_formation.info.email")}
                                </Form.Label>
                                {fieldsEmails.map((item, index) => {
                                    return (
                                        <div key={index} className={"d-flex align-items-baseline mb-2"}>
                                            <div className={"w-100"}>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="example@gmail.com"
                                                    {...register(`emails.${index}.value`, {
                                                            pattern: {
                                                                value: /^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,3}$/,
                                                                message: t(lang, "edi.common.forms.validations.is_required")
                                                            }
                                                        }
                                                    )}
                                                    className={classNames('form-control', {
                                                        'is-invalid': errors?.emails?.[index]?.value.message,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.emails?.[index]?.value.message
                                                    })}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.emails?.[index]?.value.message}
                                                </Form.Control.Feedback>
                                            </div>

                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon={"trash-alt"}
                                                className={"ms-2"}
                                                iconClassName={"text-secondary"}
                                                onClick={() => {
                                                    removeEmail(index)
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </Form.Group>

                            <h6 className={"text-info cursor-pointer mt-2"} onClick={() => {
                                appendEmail({value: '', description: ''})
                            }}>Добавить e-mail</h6>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>
                                    {t(lang, "edi.common.cellPhone")}
                                </Form.Label>
                                {fieldsPhone.map((item, index) => {
                                    return (
                                        <div key={index} className={"mb-2 d-flex align-items-baseline"}>
                                            <div className={"w-100"}>
                                                <Form.Control
                                                    type='number'
                                                    placeholder="998ххххххххх"
                                                    isInvalid={errors?.phones?.[index]?.value.message}
                                                    isValid={Object.keys(errors).length > 0 && !errors?.phones?.[index]?.value.message}
                                                    className='input-spin-none'
                                                    {...register(`phones.${index}.value`, {
                                                        validate: {
                                                            format: value => !value || /^998(\d{9})$/.test(value) || t(lang, 'authentication.forgot-password.phoneNumber.validations.format'),
                                                        }
                                                    })}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.phones?.[index]?.value.message}
                                                </Form.Control.Feedback>
                                            </div>
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon={"trash-alt"}
                                                className={"ms-2"}
                                                iconClassName={"text-secondary"}
                                                onClick={() => {
                                                    removePhone(index)
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </Form.Group>
                            <h6 className={"text-info cursor-pointer mt-2"} onClick={() => {
                                appendPhone({value: '', description: ''})
                            }}>Добавить телефон</h6>
                        </Col>
                    </Row>
                    <div className={'text-end'}>
                        <Button variant="secondary" onClick={onClose} className={'me-2'}>
                            {t(lang, "edi.common.cancel_button")}
                        </Button>
                        <Button variant="primary" disabled={loading} type="submit">
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />}
                            {t(lang, "edi.common.button.save")}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </Form>
    </>)
}

LeadForm.defaultProps = {
    name: "",
    inn: "",
    type: "",
    phones: [{value: '', description: '', type: 1}],
    emails: [{value: '', description: '', type: 3}]
}

export default LeadForm

