import {createSlice} from '@reduxjs/toolkit'
import ru from '../../../../lang/ru.json'
import uz from '../../../../lang/uz.json'

const STORAGE_DATE_FORMAT = 'dateFormat';
const STORAGE_NUMBER_FORMAT = 'numberFormat';

export const LangOptions = [
    'ru',
    'uz',
]

const DASHBOARD_PATH = 'path'
export const NumberFormatOptions = [
    {type: 'comma', value: '100,000.00'},
    {type: 'decimal', value: '100 000.00'},
    {type: 'decimal_4', value: '100 000.0000'},
]

export const DateFormatOptions = [
    'dd-MM-yyyy',
    'dd.MM.yyyy',
    'dd/MM/yyyy',
    'yyyy-MM-dd',
    'yyyy.MM.dd',
    'yyyy/MM/dd'
]
const translations = {
    'ru': ru,
    'uz': uz
}

export const Translate = (lang, key, params = {}) => {
    const langTranslations = translations[lang] || {};
    let translation = langTranslations[key];

    if (typeof translation !== 'string') {
        return key;
    }

    Object.keys(params).forEach(param => {
        translation = translation.replace(`{${param}}`, params[param]);
    });

    return translation;
}

export const mainSlice = createSlice({
    name: 'main',
    initialState: {
        showInProgress: false,
        dateFormat: localStorage.getItem(STORAGE_DATE_FORMAT) || 'dd.MM.yyyy',
        numberFormat: localStorage.getItem(STORAGE_NUMBER_FORMAT) || 'decimal',
        systemMoneyFormat: 'money',
        dashboardPath: sessionStorage.getItem(DASHBOARD_PATH) || null
    },
    reducers: {
        updateShowInProgress: (state, action) => {
            state.showInProgress = action.payload
        },
        changeDateFormat: (state, action) => {
            state.dateFormat = action.payload;
            localStorage.setItem(STORAGE_DATE_FORMAT, state.dateFormat);
        },
        changeNumberFormat: (state, action) => {
            state.numberFormat = action.payload;
            localStorage.setItem(STORAGE_NUMBER_FORMAT, state.numberFormat);
        },
        changeDashboardPath: (state,action) => {
            if(action.payload){
                state.dashboardPath = action.payload
                sessionStorage.setItem(DASHBOARD_PATH, state.dashboardPath)
            }else {
                state.dashboardPath = null
                sessionStorage.removeItem(DASHBOARD_PATH)
            }
        }
    }
})

export const updateShowInProgressAsync = data => dispatch => {
    dispatch(mainSlice.actions.updateShowInProgress(data))
}

export const selectShowInProgress = (state) => state.main.showInProgress

export const selectNumberFormat = state => state.main.numberFormat;
export const selectSystemMoneyFormat = state => state.main.systemMoneyFormat;
export const selectLang = state => {
    const activeLang = state.settings.settings?.lang?.find(l => l?.is_active) || null
    return activeLang?.code || "ru"
}
export const selectDateFormat = state => state.main.dateFormat;
export const selectDateDayJSFormat = state => state.main.dateFormat.replace(/y/g, 'Y').replace(/d/g, 'D');
export const changePathDashboard = state => state.main.dashboardPath;

export const {changeDateFormat,changeDashboardPath} = mainSlice.actions;
export const {changeNumberFormat} = mainSlice.actions;

export default mainSlice.reducer
