import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import SoftBadge from "../../../common/SoftBadge";
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {faClone, faSignature} from "@fortawesome/free-solid-svg-icons";
import {Card, Col, Row, Spinner, Table} from "react-bootstrap";
import CarrierCancelWaybill from "../../../hippo/roaming/waybill/actions/CarrierCancelWaybill";
import CustomerAcceptWaybill from "../../../hippo/roaming/waybill/actions/CustomerAcceptWaybill";
import CustomerDeclineWaybill from "../../../hippo/roaming/waybill/actions/CustomerDeclineWaybill";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import SelectCertificateWrapperComponent from "../../../hippo/roaming/components/SelectCertificateWrapperComponent";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {loadMeasurementsAsync, selectMeasurements} from "../../../../app/store/reducers/roaming/roamingReducer";
import {
    CarrierSent,
    CustomerDecline,
    getWaybillStatusText,
    getWaybillStatusVariant
} from "../../../../enum/roaming/WaybillStatus";
import {DeliveryTypeOptions, sendWaybillAsync, TrailerTypeOptions} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {
    PermissionRoamingShipmentAccept,
    PermissionRoamingShipmentUpdate
} from "../../../../enum/Permission/RoamingPermission";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import WarningAlert from "../../../hippo/roaming/components/WarningAlert";
import RoamingWaybillSync from "../../../hippo/roaming/waybill/actions/RoamingWaybillSync";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ROAMING_WAYBILL_SYNC_FAILED,
    ROAMING_WAYBILL_SYNC_SUCCESS
} from "../../../../app/eventbus/roaming/roamingWaybillEvents";

const Waybill = ({waybill, content = null}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [loading, setLoading] = useState(false);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const measurements = useSelector(selectMeasurements);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;
    const isCarrier = viewer_inn_or_pinfl === waybill.carrier.inn;
    const isCustomer = viewer_inn_or_pinfl === waybill.customer.inn;

    const isDraftWaybill = !waybill.status && content;
    const allowCancel = isCarrier && waybill.status === CarrierSent;
    const allowDecline = isCustomer && waybill.status === CarrierSent;
    const allowAccept = isCustomer && waybill.status === CarrierSent;
    const showDeclineNotes = CustomerDecline === waybill.status
    const showCopyButton = waybill.carrier.inn === viewer_inn_or_pinfl
    const [eventMessage, setEventMessage] = useState(null);

    const getItemMeasurementText = (id) => measurements.find(measurement => measurement.measureId === id) || null

    const getDeliveryTypeText = (id) => {
        const deliveryType = DeliveryTypeOptions.find(type => type.value === id);
        return deliveryType?.label;
    }

    const getTrailerTypeText = (id) => {
        const deliveryType = TrailerTypeOptions.find(type => type.value === id);
        return deliveryType?.label;
    }

    const signWaybill = () => {
        setLoading(true);

        sendWaybillAsync(currentCertificate, lang, {
            id: waybill.id,
            hashCode: content
        })
            .then(() => toast.success(t(lang, 'roaming.waybill.send.toast.register_success')))
            .catch(error => toast.error(error))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang));

        // success
        const onWaybillSuccessHandler = EventBus.on(ROAMING_WAYBILL_SYNC_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.waybill.alert.success_event_title'),
                text: t(lang, 'roaming.waybill.alert.waybill_sync_success'),
            });
        })

        // fail
        const onWaybillFailHandler = EventBus.on(ROAMING_WAYBILL_SYNC_FAILED, (error) => {
            console.log("onWaybillFailHandler error", error)
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.waybill.alert.failed_event_title'),
                text: error.message
            })
        })

        return () => {
            EventBus.remove(ROAMING_WAYBILL_SYNC_SUCCESS, onWaybillSuccessHandler)
            EventBus.remove(ROAMING_WAYBILL_SYNC_FAILED, onWaybillFailHandler)
        }
    }, [])

    return (
        <>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

            {!eventMessage && waybill?.roaming_error && <WarningAlert text={waybill?.roaming_error.message} title={'Ошибка от роуминга'} variant={'danger'}/>}

            <Card className="p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-md-8">{t(lang,"edi.common.created.time")}: {waybill.created_at}</h3>
                    <Col className="text-end">
                        {waybill.status && (
                            <>
                                <span className="me-2">{t(lang, 'roaming.waybill.view.status')}: </span>
                                <SoftBadge pill className="fs--2" bg={getWaybillStatusVariant(waybill, viewer_inn_or_pinfl)}>
                                    {t(lang, getWaybillStatusText(waybill, viewer_inn_or_pinfl))}
                                </SoftBadge>
                            </>
                        )}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {showCopyButton &&
                            <IconButton
                                variant="falcon-primary"
                                size="sm"
                                icon={faClone}
                                as={Link}
                                to={waybill.status ? `/roaming/waybill/actions/${waybill.id}?status=${waybill?.status}` : `/roaming/waybill/actions/${waybill.id}?status=${null}`}
                                className="mx-2 d-inline"
                            >
                                {t(lang, "roaming.common.copy")}
                            </IconButton>
                        }
                        {allowCancel && <CarrierCancelWaybill className="ms-2" id={waybill.id} />}
                        {allowDecline && <CustomerDeclineWaybill className="ms-2" id={waybill.id} />}
                        {allowAccept && <CustomerAcceptWaybill className="ms-2" id={waybill.id} />}
                        {isDraftWaybill && (
                                <IconButton
                                    to={`/roaming/waybill/edit/${waybill.id}`}
                                    as={dispatch(checkPermission(PermissionRoamingShipmentUpdate)) ? Link : 'div'}
                                    onClick={()=>!dispatch(checkPermission(PermissionRoamingShipmentUpdate)) && dispatch(toastPermission())}
                                    size="sm"
                                    className="mx-2"
                                    transform="shrink-3"
                                    variant="falcon-warning"
                                    icon="pencil">
                                    <span className="d-none d-sm-inline-block ms-1">{t(lang, 'roaming.common.edit')}</span>
                                </IconButton>
                        )}
                        {isDraftWaybill && (
                            <SelectCertificateWrapperComponent permission={PermissionRoamingShipmentAccept}
                                submitButton={
                                    <IconButton onClick={signWaybill} id="sendButton" size="sm" className="mx-2" transform="shrink-3" variant="falcon-primary" icon={faSignature}>
                                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                                        {t(lang, 'roaming.common.send')}
                                    </IconButton>
                                }
                                openDialogButtonText={t(lang, 'roaming.common.send')}
                            />
                        )}
                        <RoamingWaybillSync id={waybill.id} className="me-2" />
                    </Col>
                </Row>
            </Card>

            <Card className="my-3 p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} xl={5} className="d-flex flex-column">
                        <div>
                            <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.carrier')}</h5>
                            <span><b>{t(lang,"edi.common.company")}:</b> {waybill.carrier.name}</span>
                            <span className="d-block"><b>{t(lang, 'edi.common.label.inn')}:</b> {waybill.carrier.inn}</span>
                        </div>
                        <div className="mt-3">
                            <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.buyer')}</h5>
                            <span><b>{t(lang,"edi.common.company")}:</b> {waybill.buyer.name}</span>
                            <span className="d-block"><b>{t(lang, 'edi.common.label.inn')}:</b> {waybill.buyer.inn}</span>
                        </div>
                    </Col>

                    <Col xs={12} xl={5} className="d-flex flex-column my-3 my-xl-0">
                        <div>
                            <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.customer')}</h5>
                            <span><b>{t(lang,"edi.common.company")}:</b> {waybill.customer.name}</span>
                            <span className="d-block"><b>{t(lang, 'edi.common.label.inn')}:</b> {waybill.customer.inn}</span>
                        </div>
                        <div className="mt-3">
                            <h5 className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.seller')}</h5>
                            <span><b>{t(lang,"edi.common.company")}:</b> {waybill.seller.name}</span>
                            <span className="d-block"><b>{t(lang, 'edi.common.label.inn')}:</b> {waybill.seller.inn}</span>
                        </div>
                    </Col>

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>


            <Card>
                <Card.Header>
                    <Row className="d-flex flex-column flex-lg-row align-items-md-center justify-content-lg-between">
                        <Col xs={12} md={8} lg={5} className="d-flex justify-content-center justify-content-lg-start">
                            <h5 className="p-0 ms-3"><b className="pe-1">{t(lang,"edi.common.id")}:</b>{waybill.id}</h5>
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            {showDeclineNotes &&
                                <div className="alert-danger p-1 text-center">
                                    <span className="fw-bold me-2">{t(lang, "roaming.common.comment")}:</span>
                                    <strong>{waybill.notes}</strong>
                                </div>
                            }
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <h3 className="text-center">{t(lang, 'roaming.waybill.view.title')}</h3>
                        <Col className="d-flex flex-md-row flex-column align-items-center align-items-md-start justify-content-around">
                            <div className="my-2">
                                <div>
                                    <span className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.contract_number')}: </span>
                                    <span>{waybill.contract_info.number}</span>
                                </div>
                                <div>
                                    <span className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.contract_date')}: </span>
                                    <span>{waybill.contract_info.date}</span>
                                </div>
                                <div>
                                    <span className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.delivery_number')}: </span>
                                    <span>{waybill.delivery_info.number}</span>
                                </div>
                                <div>
                                    <span className="fw-bold mb-1">{t(lang, 'roaming.waybill.view.contract_number')}: </span>
                                    <span>{waybill.contract_info.number}</span>
                                </div>
                            </div>
                            <div className="my-2">
                                <div>
                                    <span className="fw-bold">{t(lang, 'roaming.waybill.view.delivery_type')}: </span>
                                    <span>{t(lang, getDeliveryTypeText(waybill.delivery_type))}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t(lang, 'roaming.waybill.view.trailer_type')}: </span>
                                    <span>{t(lang, getTrailerTypeText(waybill.trailer_info.trailer_type))}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t(lang, 'roaming.waybill.view.driver_fio')}: </span>
                                    <span>{waybill.driver_fio}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t(lang, 'roaming.waybill.view.truck_info.number')}: </span>
                                    <span>{waybill.truck_info.number}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t(lang, 'roaming.waybill.view.truck_info.model')}: </span>
                                    <span>{waybill.truck_info.model}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t(lang, 'roaming.waybill.view.trailer_info.loader_info.number')}: </span>
                                    <span>{waybill.trailer_info.loader_info.number}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t(lang, 'roaming.waybill.view.trailer_info.loader_info.model')}: </span>
                                    <span>{waybill.trailer_info.loader_info.model}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} xl={6} className="fs--1">
                            <div className="row">
                                <span className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.carrier')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.carrier.name} <hr className="m-0" style={{height: "2px"}} /></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.label.inn')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.carrier.inn} <hr className="m-0" style={{height: "2px"}} /></span>
                            </div>
                            <div className="row mt-1">
                                <span className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.buyer')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.buyer.name} <hr className="m-0" style={{height: "2px"}} /></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.label.inn')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.buyer.inn} <hr className="m-0" style={{height: "2px"}} /></span>
                            </div>
                            {waybill.point_infos.map((info, index) => (
                                <div key={index} className="row">
                                    <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.point_of_unloading', {number: index + 1})}</span>
                                    <span className="col-8 mb-1 ps-0">{info.point_of_unloading} <hr className={info.point_of_unloading ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                                </div>
                            ))}
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.point_of_redirect_address')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.point_of_redirect_address} <hr className={waybill.point_of_redirect_address ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                            </div>
                        </Col>

                        <Col xs={12} xl={6} className="mt-5 fs--1 mt-xl-0">
                            <div className="row">
                                <span className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.customer')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill?.customer?.name} <hr className="m-0" style={{height: "2px"}} /></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.label.inn')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.customer.inn} <hr className="m-0" style={{height: "2px"}} /></span>
                            </div>
                            <div className="row mt-1">
                                <span className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.seller')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.seller.name} <hr className="m-0" style={{height: "2px"}} /></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.label.inn')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.seller.inn} <hr className="m-0" style={{height: "2px"}} /></span>
                            </div>
                            {waybill.point_infos.map((info, index) => (
                                <div key={index} className="row">
                                    <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.point_of_loading', {number: index + 1})}</span>
                                    <span className="col-8 mb-1 ps-0">{info.point_of_loading} <hr className={info.point_of_loading ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                                </div>
                            ))}
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.point_of_redirect_name')}</span>
                                <span className="col-8 mb-1 ps-0">{waybill.point_of_redirect_name} <hr className={waybill.point_of_redirect_name ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Table bordered responsive className="border-bottom fs--1 border-500">
                        <thead className="text-dark bg-200">
                        <tr>
                            <th rowSpan={3}>№</th>
                            <th rowSpan={2} className="text-start">{t(lang, 'roaming.waybill.view.datatable.row.product')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.catalog_class_code')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.measurement')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.quantity')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.price')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.total')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.road_haulage_cost')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.follow_documents')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.method_define_weight')}</th>
                            <th rowSpan={2} className="text-center">{t(lang, 'roaming.waybill.view.datatable.row.product.load_class')}</th>
                            <th colSpan={2} className="text-end">{t(lang, 'roaming.waybill.view.datatable.row.product.mass')}</th>
                        </tr>
                        <tr>
                            <th className="text-start p-2">{t(lang, 'roaming.waybill.view.datatable.row.product.brutto')}</th>
                            <th className="text-start p-2">{t(lang, 'roaming.waybill.view.datatable.row.product.netto')}</th>
                        </tr>
                        <tr>
                            <th className="p-0 text-center">1</th>
                            <th className="p-0 text-center">2</th>
                            <th className="p-0 text-center">3</th>
                            <th className="p-0 text-center">4</th>
                            <th className="p-0 text-center">5</th>
                            <th className="p-0 text-center">6</th>
                            <th className="p-0 text-center">7</th>
                            <th className="p-0 text-center">8</th>
                            <th className="p-0 text-center">9</th>
                            <th className="p-0 text-center">10</th>
                            <th className="p-0 text-center">11</th>
                            <th className="p-0 text-center">12</th>
                        </tr>
                        </thead>
                        <tbody>
                        {waybill.items.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="text-start">{index + 1}</td>
                                    <td className="text-center">{item.name}</td>
                                    <td className="text-center">{item.catalog.code}</td>
                                    <td className="text-center">{item?.measure_id ? getItemMeasurementText(item.measure_id)?.name : item.packageName}</td>
                                    <td className="text-center">{item.quantity}</td>
                                    <td className="text-center">{item.price}</td>
                                    <td className="text-center">{item.total}</td>
                                    <td className="text-center">{item.road_haulage_cost}</td>
                                    <td className="text-center">{item.follow_documents}</td>
                                    <td className="text-center">{item.method_define_weight}</td>
                                    <td className="text-center">{item.load_class}</td>
                                    <td className="text-center">{item.weight_brutto}</td>
                                    <td className="text-end">{item.weight_netto}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Card.Body>

                <Card.Footer className="border-top">
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.giver')}</span>
                                <span className="col mb-1 ps-0">{waybill.giver_fio} <hr className={waybill.giver_fio ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                            </div>
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.taker')}</span>
                                <span className="col mb-1 ps-0">{waybill.taker_fio} <hr className={waybill.taker_fio ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                            </div>
                            <div className="row">
                                <span className="col mb-1 pe-0 fw-bold">{t(lang, 'roaming.waybill.view.special_notes')}</span>
                                <span className="col mb-1 ps-0">{waybill.special_notes} <hr className={waybill.special_notes ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="row">
                                <span className="col fw-bold">{t(lang, 'roaming.waybill.view.delivery_distance_in_city')}</span>
                                <span className="col">{waybill?.distance_info?.delivery_distance_in_city} <hr className={waybill?.distance_info?.delivery_distance_in_city ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                            </div>
                            <div className="row">
                                <span className="col fw-bold">{t(lang, 'roaming.waybill.view.delivery_distance')}</span>
                                <span className="col">{waybill?.distance_info?.delivery_distance} <hr className={waybill?.distance_info?.delivery_distance ? "m-0" : "mb-0"} style={{height: "2px"}} /></span>
                            </div>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>

        </>
    );
};

export default Waybill;
