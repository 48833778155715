import ContactFormationDataTable from "../../../../components/hippo/contract-formation/ContactFormationDataTable";
import {useDispatch, useSelector} from "react-redux";
import {
    loadContractFormationDataTableColumn,
    selectContractFormationDataTable
} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";
import React, {useEffect} from "react";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionContractFormationView} from "../../../../enum/Permission/EdiPermission";
import {Card} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";


const ContractFormation = () => {
    const activeColumns = useSelector(selectContractFormationDataTable)
    const lang = useSelector(selectLang);
    const dispatch = useDispatch()
    const t = Translate;

    useEffect(() => {
        dispatch(loadContractFormationDataTableColumn())
    },[])


    if(!dispatch(checkPermission(PermissionContractFormationView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.edi.contract-formation.datatable.no_access.title')}</h4>
        </Card>
    }

    return  <ContactFormationDataTable activeColumns={activeColumns} />

}

export default ContractFormation;
