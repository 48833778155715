import React, {useState} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import FindPerson from "../../../../person/FindPerson";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";

const EmpowermentPerson = ({draftEmpowerment}) => {
    const {register, formState: {errors}, control, setValue} = useFormContext();
    const [eventCount, setEventCount] = useState(0)
    const lang = useSelector(selectLang);
    const t = Translate;

    const onChangePerson = (event) => {
        setEventCount(eventCount + 1)

        setValue("personInfo.pinfl", event?.pinfl);
        if (!draftEmpowerment || eventCount > 2) {
            setValue("personInfo.fullName", event?.name);
        }
    }


    return (
        <Card>
            <Card.Header>
                <h4>{t(lang, "roaming.empowerment.empowerment_person")}</h4>
            </Card.Header>
            <Card.Body className="bg-light">
                <Row>
                    <Col xs={12} sm={6} className="mb-2">
                            <Form.Label>{t(lang, "roaming.common.inn")}</Form.Label>
                            <Controller name="personInfo.inn"
                                        control={control}
                                        render={({field}) => (
                                            <FindPerson
                                                inputProps={{
                                                    isInvalid: errors?.personInfo?.inn,
                                                    isValid: Object.keys(errors).length > 0 && !errors?.personInfo?.inn,
                                                    placeholder: t(lang, "roaming.common.inn_or_pinfl.placeholder")
                                                }}
                                                onChange={(person) => {
                                                    onChangePerson(person);
                                                    field.onChange(person?.inn || '')
                                                }}
                                                inputGroupProps={{
                                                    className: classNames({
                                                        'is-invalid': errors?.personInfo?.inn,
                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.personInfo?.inn
                                                    }),
                                                }}
                                                defaultInnOrPinfl={field.value}
                                                ref={field.ref}
                                            />
                                        )}
                                        rules={{
                                            required: t(lang, 'roaming.validations.is_required'),
                                        }}
                            />
                            <Form.Control.Feedback type="invalid">{errors?.personInfo?.inn?.message}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} sm={6} className="mb-2">
                        <Form.Label>{t(lang, "roaming.common.inn_or_pinfl")}</Form.Label>
                        <Form.Control name="personInfo.pinfl"
                                      type="text"
                                      {...register("personInfo.pinfl")}
                                      disabled
                        />
                    </Col>
                    <Col xs={12} lg={6} className="mb-2">
                        <Form.Label>{t(lang, "roaming.empowerment.empowerment_person")}</Form.Label>
                        <Form.Control name="personInfo.fullName"
                                      type="text"
                                      placeholder={t(lang, "roaming.empowerment.empowerment_person")}
                                      isValid={Object.keys(errors).length > 0 && !errors?.personInfo?.name}
                                      {...register("personInfo.fullName")}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
};

export default EmpowermentPerson;