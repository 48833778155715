import React from 'react';
import FalconCardHeader from "../../../common/FalconCardHeader";
import {Card, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    LangOptions,
    selectLang,
    Translate,
} from "../../../../app/store/reducers/main/mainReducer";
import {updateLangSettingsAsync} from "../../../../app/store/reducers/settings/settingsReducer";

const ChangeLanguage = () => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();

    const onChangeLang = async lang => {
        const payload = {
            lang: LangOptions.map(l => {
                return {
                    code: l,
                    is_active: l === lang,
                }
            })
        }

        dispatch(updateLangSettingsAsync({payload}))
    }

    return (
        <Card className="mb-3 h-100">
            <FalconCardHeader title={t(lang, 'hippo.user.settings.profile.lang')}/>
            <Card.Body className="bg-light">
                <Form.Select size="sm"
                             option={LangOptions}
                             value={lang}
                             onChange={(e) => onChangeLang(e.target.value)}
                >
                    {LangOptions.map((option, index) => {
                        return <option value={option} key={index}>{option}</option>
                    })}
                </Form.Select>
            </Card.Body>
        </Card>
    );
};

export default ChangeLanguage;
