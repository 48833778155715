import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import PropTypes from "prop-types";
import {syncWaybillAsync} from "../../../../../app/store/reducers/roaming/roamingWaybillReducer";

const RoamingWaybillSync = ({id, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false);
    const syncWaybill = () => {
        setLoading(true);
        syncWaybillAsync(id)
            .then()
            .catch()
            .finally(() => setLoading(false))
    };

    return (
        <IconButton
            icon={'fa-refresh'}
            variant="falcon-primary"
            onClick={syncWaybill}
            size="sm"
            iconClassName="me-1"
            disabled={loading}
            {...props}>
            {t(lang, "roaming.waybill.sync")}
        </IconButton>
    )
};

RoamingWaybillSync.propTypes = {
    id: PropTypes.string
}

RoamingWaybillSync.defaulProps = {
    id: ""
}

export default RoamingWaybillSync;