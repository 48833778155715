import React, {useEffect} from 'react';
import {Card, Col, Form, InputGroup, Nav, Row, Tab} from "react-bootstrap";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import SelectCategory from "../components/SelectCategory";
import AddCategoryButton from "../../category/components/AddCategoryButton";
import {
    loadCategoriesAsync,
    selectAllCategories,
    updateAllCategories
} from "../../../../app/store/reducers/category/categoryReducer";
import DescriptionAttributesRow from "../components/DescriptionAttributesRow";
import EditCategoryButton from "../../category/components/EditCategoryButton";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_CATEGORY_SUCCESS} from "../../../../app/eventbus/itemEvents";


const ItemFormCardDetails = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const categories = useSelector(selectAllCategories);

    const loadCategories = () => {
        loadCategoriesAsync()
            .then((categories) => dispatch(updateAllCategories(categories)))
            .catch((error) => dispatch(updateAllCategories([])))
    }

    useEffect(() => {

        !categories.length && loadCategories()

        const onCategoryAddedHandler = EventBus.on(ADD_CATEGORY_SUCCESS, (category) => {
            form.setValue('categoryId', category.id);
        })

        return () => {
            EventBus.remove(ADD_CATEGORY_SUCCESS, onCategoryAddedHandler);
        };
    }, []);

    return (
        <Card className="h-100">
            <Tab.Container defaultActiveKey={'1'}>
                <Card.Header>
                    <Nav variant="tabs">
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'1'}>
                                <Card.Title>{t(lang, 'items.common.details')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'2'}>
                                <Card.Title>{t(lang, 'items.common.category')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'3'}>
                                <Card.Title>{t(lang, 'items.common.characteristics')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Tab.Content>
                    <Tab.Pane eventKey={'1'} transition={false}>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(lang, 'items.common.name')}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control name={'name'}
                                                      placeholder={t(lang, 'items.common.name.placeholder')}
                                                      isInvalid={form.formState.errors?.name}
                                                      {...form.register('name', {
                                                          required: t(lang, 'items.common.validations.is_required'),
                                                      })}
                                        />
                                        <ErrorMessage name='name'
                                                      errors={form.formState.errors}
                                                      as={<Form.Control.Feedback type="invalid"/>}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t(lang, 'items.common.description')}</Form.Label>
                                        <Form.Control as="textarea"
                                                      name={'description'}
                                                      placeholder={t(lang, 'items.common.description.placeholder')}
                                                      {...form.register('description')}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'2'} transition={false}>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>
                                    <Form.Label>{t(lang, 'items.common.category')}</Form.Label>
                                    <Controller name="categoryId"
                                                render={({field}) => (
                                                    <InputGroup>
                                                        <div className="react-select form-control p-0">
                                                            <SelectCategory categories={categories}
                                                                            onChange={option => field.onChange(option ? option.id : null)}
                                                                            defaultCategoryId={field.value}
                                                                            selectProps={{
                                                                                styles: {
                                                                                    container: (provided) => ({
                                                                                        ...provided,
                                                                                        margin: '-1px'
                                                                                    })
                                                                                }
                                                                            }}
                                                            />
                                                        </div>
                                                        {field.value ?
                                                            <EditCategoryButton categoryId={field.value}/>
                                                            :
                                                            <AddCategoryButton/>
                                                        }

                                                    </InputGroup>
                                                )}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'3'} transition={false}>
                        <Card.Body>
                            <Row className="g-2">
                                <Col xs={12}>
                                    <DescriptionAttributesRow fieldName={"descriptionAttributes"}/>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Card>
    );
};

export default ItemFormCardDetails;
