import React, {Fragment} from 'react';
import SendRoamingConstructionInvoiceForm from "./SendRoamingConstructionInvoiceForm";

const SendRoamingConstructionInvoice = () => {
    return (
        <Fragment>
            <SendRoamingConstructionInvoiceForm />
        </Fragment>
    );
};

export default SendRoamingConstructionInvoice;
