import axios from "axios";

class NotificationService {
    getNotifications() {
        return axios.get('/notification/contact/get')
    }

    registerNotification({phoneNumber, eventCodes}) {
        return axios.post('/notification/contact/add', {
            phone_number: phoneNumber,
            event_codes: eventCodes
        })
    }

    updateNotification({phoneNumber, eventCodes}, id) {
        return axios.post(`/notification/contact/update/${id}`, {
            phone_number: phoneNumber,
            event_codes: eventCodes
        })
    }

    deleteNotification(id) {
        return axios.post(`/notification/contact/delete/${id}`)
    }
}

const notificationService = new NotificationService()

export default notificationService