import React, {useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {changeDefaultRole, loadRolesAsync} from "../../../../app/store/reducers/role/roleReducer";
import {toast} from "react-toastify";

const ConfirmationDefaultModal = ({ show, onHide, id }) => {
    const dispatch = useDispatch()

    const lang = useSelector(selectLang);
    const t = Translate;

    const [loading, setLoading] = useState(false)

    const onChangeDefaultRole = () => {
        changeDefaultRole(id)
            .then(() => {
                dispatch(loadRolesAsync())
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                onHide()
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => setLoading(false))
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t(lang, 'system.permission.role.datatable.default.role.change.title.on')}</Modal.Title>
            </Modal.Header>
            <Modal.Footer className={'d-flex justify-content-end'}>
                <Button onClick={onHide} variant={'falcon-primary'}>{t(lang, "crm.common.back.button")}</Button>
                <Button onClick={onChangeDefaultRole} variant={'primary'}>
                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                    {t(lang, "warehouse.operation.item.common.status.approve")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationDefaultModal;
