import React, {useState} from 'react';
import printJS from "print-js";
import IconButton from "../../common/IconButton";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {Spinner} from "react-bootstrap";
import {printOrderAsync} from "../../../app/store/reducers/order/orderReducer";

const OrderPrint = ({id, ...props}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const onPrint = () => {
        setLoading(true);
        printOrderAsync(id)
            .then(response => {
                setLoading(false);
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
    }

    return (
        <>
            <IconButton {...props} icon="print" transform="shrink-3" onClick={onPrint}>
                <span className="d-none d-sm-inline-block">{t(lang, 'edi.common.print')}</span>
                {loading && (
                    <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
                )}
            </IconButton>
        </>
    );
};

export default OrderPrint;