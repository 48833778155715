import axios from "axios";
import {getDriverSalePdfAsync} from "../app/store/reducers/print/printReducer";

class PrintService {
    addOrganizationTemplate(organizationId, payload) {
        return axios.post(`/pdf/admin/organization-template/add/${organizationId}`, payload)
    }

    updateOrganizationTemplate(organizationId, payload) {
        return axios.post(`/pdf/admin/organization-template/update/${organizationId}`, payload)
    }

    deleteOrganizationTemplate(organizationId, templateId) {
        return axios.post(`/pdf/admin/organization-template/delete/${organizationId}`, {
            template_id: templateId,
        })
    }

    getOrganizationTemplates(organizationId) {
        return axios.get(`/pdf/admin/organization-template/get/${organizationId}`)
    }

    downloadOrganizationTemplate(organizationId, templateId) {
        return axios.get(`/pdf/admin/organization-template/download/${organizationId}`, {
            params: {
                template_id: templateId,
            },
            responseType: "blob",
        })
    }

    getDriverSalePdf({employeeId, dateStart, dateEnd}) {
        return axios.post(`/pdf/pdf/sale/driver`, {
            employee_id: employeeId,
            date_start: dateStart,
            date_end: dateEnd,
        }, {
            responseType: "blob",
        })
    }
}

const printService = new PrintService();
export default printService;