import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import EventBus from "../../../../app/eventbus/EventBus";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import DraftPurchase from "../../../../components/hippo/warehouse-operation/draft-purchase/DraftPurchase";
import {loadDraftPurchaseAsync} from "../../../../app/store/reducers/warehouse-operation/draftPurchaseReducer";
import {
    APPROVE_DRAFT_PURCHASE_SUCCEED,
    BIND_DRAFT_PURCHASE_ITEM_SUCCEED,
    DELETE_DRAFT_PURCHASE_SUCCEED
} from "../../../../app/eventbus/warehouse-operation/draftPurchaseEvents";

const ViewDraftPurchase = () => {
    const [draftPurchase, setDraftPurchase] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);
    const history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        const onBindDraftPurchaseItemSucceed = EventBus.on(BIND_DRAFT_PURCHASE_ITEM_SUCCEED, () => {
            loadDraftPurchase();
        });

        const onApproveDraftPurchaseSucceed = EventBus.on(APPROVE_DRAFT_PURCHASE_SUCCEED, (draftPurchase) => {
            history.replace(`/warehouse/operation/purchases/view/${draftPurchase.id}`);
        });

        const onDeleteDraftPurchaseSucceed = EventBus.on(DELETE_DRAFT_PURCHASE_SUCCEED, () => {
            history.replace('/warehouse/operation/draft-purchases');
        });

        return () => {
            EventBus.remove(APPROVE_DRAFT_PURCHASE_SUCCEED, onApproveDraftPurchaseSucceed);
            EventBus.remove(BIND_DRAFT_PURCHASE_ITEM_SUCCEED, onBindDraftPurchaseItemSucceed);
            EventBus.remove(DELETE_DRAFT_PURCHASE_SUCCEED, onDeleteDraftPurchaseSucceed);
        };
    }, [])

    useEffect(() => {
        loadDraftPurchase();
    }, [activeOrganization, id])

    const loadDraftPurchase = () => {
        loadDraftPurchaseAsync(id)
            .then(loadedDraftPurchase => setDraftPurchase(loadedDraftPurchase))
            .catch(() => setDraftPurchase(null))
    };

    if (!draftPurchase)
        return <span>loading...</span>

    return <DraftPurchase draftPurchase={draftPurchase}/>;
};

export default ViewDraftPurchase;