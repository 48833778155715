import IconButton from "../../common/IconButton";
import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import {Col, Collapse, Form, Row} from 'react-bootstrap';
import InvoiceDataTableFilter from "./InvoiceDataTableFilter";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {useForm, FormProvider} from "react-hook-form";
import {
    changeFilterOptionsAsync, changeInvoicesDataTableColumn,
    selectFilterOptions, selectInvoicesDataTable,
} from "../../../app/store/reducers/invoice/invoiceReducer";
import dayjs from "dayjs";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import SelectCertificateWrapperComponent from "../roaming/components/SelectCertificateWrapperComponent";
import UploadInvoiceListToExcel from "./excel/UploadInvoiceListToExcel";
import DataTableSettings from "../DataTableSettings";
import {PermissionEdiInvoiceApprove} from "../../../enum/Permission/EdiPermission";


const InvoiceDataTableHeader = ({
                                    selectedRowIds,
                                    isSelectable,
                                    filterDefaults,
                                    onClickBulkSign,
                                    page,
                                    filterDisableds
                                }) => {
    const allColumns = useSelector(selectInvoicesDataTable)
    const filter = useSelector(selectFilterOptions);
    const selectedRowIndexArray = Object.keys(selectedRowIds);
    const methods = useForm({
        defaultValues: {
            date_end: filter?.date_end && dayjs(filter?.date_end).toDate() || null,
            date_start: filter?.date_start && dayjs(filter?.date_start).toDate() || null,
            number: filter?.number || null,
            status: filter?.status || null,
            branch_id: filter?.branch_id || null,
            customer_inn: filter?.customer_inn || null,
        }
    });
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true);

    const onBulkSign = () => {
        const selectedRows = selectedRowIndexArray.map((selectedIndex) => page[selectedIndex].original);
        onClickBulkSign(selectedRows);
    }

    const onSubmitFilter = ({date_end, date_start, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD");
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD");
        dispatch(changeFilterOptionsAsync({
            page: 1,
            date_start,
            date_end,
            ...filters
        }))
    }

    return (
        <Row className="flex-between-center">
            <Collapse in={isOpenFilterCollapse}>
                <Col sm={12}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <InvoiceDataTableFilter filterDefaults={filterDefaults} filterDisableds={filterDisableds}/>
                        </Form>
                    </FormProvider>
                    <hr/>
                </Col>
            </Collapse>
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.invoice.datatable.header.title")}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <div id="invoice-actions">
                    {selectedRowIndexArray.length > 0 ?
                        <div className={'d-flex gap-2'}>
                            <SelectCertificateWrapperComponent
                                submitButton={<IconButton
                                    onClick={onBulkSign}
                                    variant="falcon-default"
                                    size="sm"
                                    id="massSignInvoice"
                                    type="button"
                                    icon={faSignature}
                                    iconClassName="me-1"
                                    className="ms-2"
                                >
                                    {t(lang, "edi.common.button.sign")}
                                </IconButton>}
                                openDialogButtonText={t(lang, "edi.common.button.sign")}
                                permission={PermissionEdiInvoiceApprove}>
                            </SelectCertificateWrapperComponent>
                            <UploadInvoiceListToExcel selectable rowValues={page} className="d-inline-block me-2"/>
                        </div> : <Fragment>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                transform="shrink-3"
                                icon="refresh"
                                onClick={methods.handleSubmit(onSubmitFilter)}
                                className="mx-2"
                            >
                        <span
                            className="d-none d-sm-inline-block ms-1">{t(lang, "edi.orders.order.resume_order_button")}</span>
                            </IconButton>

                            <UploadInvoiceListToExcel className="d-inline-block me-2"/>

                            <IconButton
                                onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                                variant="falcon-default"
                                size="sm"
                                icon="filter"
                                transform="shrink-3"
                            >
                        <span className="d-none d-sm-inline-block ms-1">{t(lang, "edi.invoice.datatable.header.navigation.filter")}</span>
                            </IconButton>
                        </Fragment>
                    }
                </div>
            </Col>
        </Row>
    );
};

InvoiceDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default InvoiceDataTableHeader;
