import React from 'react';
import Expense from "../../../../components/hippo/cashbox/expense/Expense";
import {getExpenseAsync} from "../../../../app/store/reducers/cashbox/CashboxReducer";

const ViewExpense = ({viewId}) => {
    return (
        <Expense
            request={getExpenseAsync}
            title={"cashboxes.expense.nav.label"}
            id={viewId}
        />
    );
};

export default ViewExpense;
