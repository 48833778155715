import IconButton from "../../../../common/IconButton";
import {Button, CloseButton, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {deleteContractorAgentAsync} from "../../../../../app/store/reducers/contractor/contractorAgentReducer";
import {toast} from "react-toastify";


const ContractorAgentDelete = ({id}) => {
    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate


    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }

    const onContractorAgentDelete = async () => {
        await deleteContractorAgentAsync(id)
            .then(() => {
                handlerClose()
            })
            .catch(() => {
                toast.error("Безуспешно!")
            })
    }


    return(
        <>
            <IconButton
                onClick={handlerShow}
                variant="falcon-danger"
                size="sm"
                icon="trash"
                transform="shrink-3"
            />
            <Modal show={show} onHide={handlerClose} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "Удалить")}
                    </Modal.Title>
                    <CloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    {t(lang, "Вы действительно хотите удалить?")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerClose}>
                        {t(lang, "Назад")}
                    </Button>
                    <Button variant="danger" onClick={() => onContractorAgentDelete()}>
                        {t(lang, "Удалить")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default ContractorAgentDelete;