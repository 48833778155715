import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../../../common/IconButton";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {customerDeclineWaybillAsync} from "../../../../../app/store/reducers/roaming/roamingWaybillReducer";
import SelectCertificateWrapperComponent from "../../components/SelectCertificateWrapperComponent";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingShipmentReject} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";

const CustomerDeclineWaybill = ({id, ...props}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {register, formState: {errors}, handleSubmit} = useForm()

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (dispatch(checkPermission(PermissionRoamingShipmentReject))) setShow(true)
        else dispatch(toastPermission())
    };

    const declineWaybillHandler = (formData) => {
        setLoading(true);

        customerDeclineWaybillAsync(currentCertificate, {
            id: id,
            comment: formData.comment,
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <SelectCertificateWrapperComponent permission={PermissionRoamingShipmentReject}
                submitButton={
                    <IconButton
                        size="sm"
                        {...props}
                        icon={faBan}
                        id="declineWaybill"
                        iconClassName="me-1"
                        onClick={handleShow}
                        variant="falcon-danger"
                    >{t(lang, 'roaming.common.decline')}</IconButton>
                }
                openDialogButtonText={t(lang, 'roaming.common.decline')}
            />

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(declineWaybillHandler)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t(lang, 'roaming.waybill.decline_waybill.modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{t(lang, 'roaming.common.label.comment')}</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                isInvalid={errors?.comment}
                                placeholder={t(lang, 'roaming.common.leave_comment')}
                                {...register('comment', {required: t(lang, 'roaming.common.forms.validations.is_required')})}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.comment?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>{t(lang, 'roaming.common.close')}</Button>

                        <Button variant="danger" type="submit">
                            {loading && <Spinner className="me-1" animation="border" role="status" size="sm"/>}
                            {t(lang, 'roaming.common.decline')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default CustomerDeclineWaybill;
