import React, {Fragment} from 'react';
import {Card, Col, Form, InputGroup, Nav, Row, Tab} from "react-bootstrap";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import SelectMeasurement from "../components/SelectMeasurement";
import PackageMeasurementRow from "../components/PackageMeasurementRow";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import SelectWarehouse from "../components/SelectWarehouse";
import Cleave from "cleave.js/react";
import ItemAlertOn from "../components/ItemAlertOnState";

const ItemFormCardStateAndMeasurements = ({isEditing}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const {control} = useFormContext();
    const {fields} = useFieldArray({
        control,
        name: "itemWarehouseStates"
    })

    return (
        <Card className="h-100">
            <Tab.Container defaultActiveKey={'1'}>
                <Card.Header>
                    <Nav variant="tabs">
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'1'}>
                                <Card.Title>{t(lang, 'items.common.measurement')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'2'}>
                                <Card.Title>{t(lang, 'items.common.state')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="cursor-pointer">
                            <Nav.Link eventKey={'3'}>
                                <Card.Title>{t(lang, 'items.common.package_measurement')}</Card.Title>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Tab.Content>
                    <Tab.Pane eventKey={'1'}>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(lang, 'items.common.measurement')}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Controller name={'measurement'}
                                                    render={({field}) => (
                                                        <SelectMeasurement defaultMeasurement={field.value}
                                                                           onChange={field.onChange}
                                                        />
                                                    )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'2'}>
                        <Card.Body>
                            <Row>
                                {!isEditing && <Form.Group as={Col} xs={12}>
                                    <table style={{overflow: 'scroll'}} className={'w-100'}>
                                        <thead>
                                        <tr>
                                            <th className={'fs--1'}>{t(lang, 'items.common.warehouse')}</th>
                                            <th className={'fs--1'}>{t(lang, 'items.common.state')}</th>
                                            <th className={'fs--1'}>{t(lang, 'items.common.alert_on.state')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {fields.map((itemWarehouseState, index) => (
                                            <tr key={itemWarehouseState.id}>
                                                    <ItemWarehouseStates
                                                        key={itemWarehouseState.id}
                                                        itemWarehouseState={itemWarehouseState}
                                                        index={index}
                                                    />
                                            </tr>
                                            )
                                        )}
                                        </tbody>
                                    </table>
                                </Form.Group>}

                                {isEditing && <Form.Group as={Col} xs={12}>
                                    <Form.Label>{t(lang, "items.common.alert_on.state")}</Form.Label>
                                    <ItemAlertOn />
                                </Form.Group>}

                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'3'}>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>
                                    <PackageMeasurementRow fieldName={"packageMeasurements"}/>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Card>
    );
};


const ItemWarehouseStates = ({itemWarehouseState, index}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const itemRootName = `itemWarehouseStates.${index}`;

    return (
        <Fragment>
                <td style={{minWidth: 100, maxWidth: 150}}>
                    <InputGroup.Text title={itemWarehouseState.name} className={'text-truncate w-100'}>{itemWarehouseState.name}</InputGroup.Text>
                </td>
                <td>
                    <Controller name={`${itemRootName}.state`}
                                render={({field}) => (
                                    <Cleave htmlRef={field.ref}
                                            className="form-control"
                                            placeholder={t(lang, 'items.common.state.placeholder')}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 188)
                                                    e.target.value += "."
                                            }}
                                            options={{
                                                numeral: true,
                                                delimiter: ' ',
                                                numeralDecimalScale: 3,
                                            }}
                                            value={field.value}
                                            onChange={e => field.onChange(e.target.rawValue)}

                                    />
                                )}
                    />
                </td>
                <td>
                    <Controller
                        name={`${itemRootName}.alert_on`}
                        render={({field}) => (
                            <Cleave htmlRef={field.ref}
                                    className="form-control"
                                    placeholder={t(lang, 'items.common.state.placeholder')}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 188)
                                            e.target.value += "."
                                    }}
                                    options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 3,
                                    }}
                                    value={field.value}
                                    onChange={e => field.onChange(e.target.rawValue)}
                            />
                        )}
                    />
                </td>
        </Fragment>
    )
}


export default ItemFormCardStateAndMeasurements;
