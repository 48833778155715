import React, {useEffect, useState} from "react";
import {ListGroup} from "react-bootstrap";
import {Link,useLocation} from "react-router-dom";
import Flex from "../../common/Flex";
import {useSelector, useDispatch} from "react-redux";
import {
    changeDashboardPath,
    changePathDashboard,
    selectLang,
    Translate
} from "../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization, selectIsAdmin} from "../../../app/store/reducers/auth/authReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ServiceItem = ({title, description, url, hidden, isLocked}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();
    const dashboardPath = useSelector(changePathDashboard)
    const {pathname} = useLocation()

    return (
        <ListGroup.Item title={isLocked ? t(lang, 'common.notification.without.inn') : null} hidden={hidden} className='p-3 p-sm-4' as={isLocked ? 'div' : Link}  onClick={()=>dispatch(changeDashboardPath(url))} to={dashboardPath !== url ? url : pathname} action>
            <Flex justifyContent="between">
                <h5 className="mb-1">{title}</h5>
                {isLocked && <FontAwesomeIcon icon={'lock'}/>}
            </Flex>
            <p className="mb-1">
                {description}
            </p>
        </ListGroup.Item>
    );
};

const ServiceChooser = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const isAdmin = useSelector(selectIsAdmin);
    const lang = useSelector(selectLang)
    const t = Translate

    const [services, setServices] = useState([])

    useEffect(() => {
        setServices([
            {
                title: t(lang, 'hippo.dashboard.admin.panel.title'),
                url: '/admin',
                description: t(lang, 'hippo.dashboard.admin.panel.description'),
                hidden: !isAdmin,
            },
            {
                title: 'Hippo EDI',
                url: '/edi',
                description: `${t(lang, "hippo.dashboard.edi_service_description")}`,
                hidden: false,
                isLocked: !activeOrganization.inn
            },
            {
                title: 'Hippo ЭДО',
                url: '/roaming',
                description: `${t(lang, "hippo.dashboard.roaming_service_description")}`,
                hidden: false,
                isLocked: !activeOrganization.inn
            },
            {
                title: 'Hippo Склад',
                url: '/warehouse',
                description: 'Управление скаладами и товарами Вашей Организации',
                hidden: false
            },
            {
                title: 'Hippo CRM',
                url: '/crm',
                description: 'Все необходимое в облачной CRM для бизнеса',
            },
            {
                title: 'Hippo Касса',
                url: '/cash-box',
                description: 'Сервис который позволяет ускорить передачу коммерческой информации, что позволит Вам сократить ошибки а также улучшить эффективность вашей бухгалтерии.',
            },
            // {
            //     title: 'Hippo Касса',
            //     url: '/pos',
            //     description: 'Сервис который позволяет ускорить передачу коммерческой информации, что позволит Вам сократить ошибки а также улучшить эффективность вашей бухгалтерии.',
            // },
            // {
            //     title: 'Hippo Производство',
            //     url: '/manufacture',
            //     description: 'Сервис который позволяет ускорить передачу коммерческой информации, что позволит Вам сократить ошибки а также улучшить эффективность вашей бухгалтерии.',
            // },
        ])
    }, [isAdmin, activeOrganization])

    return (
        <ListGroup>
            {services.map((service, index) => {
                return <ServiceItem {...service} key={index}/>
            })}
        </ListGroup>
    );
};

export default ServiceChooser;
