import {Form} from "react-bootstrap";
import ActionButton from "../../../common/ActionButton";
import React, {useEffect} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SelectCompanyCatalog from "../../IKPU/SelectCompanyCatalog";
import numeral from "numeral";


const ItemRow = ({allowDelete, onDelete, arrayFieldName, index, item}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const {register, formState: {errors}, watch, setValue} = useFormContext();
    const rootName = `${arrayFieldName}.${index}`;
    const numberFormat = useSelector(selectNumberFormat);


    const quantity = +watch(`${rootName}.quantity`);
    const price = +watch(`${rootName}.price`);
    let nds = +watch(`${rootName}.nds`);

    const total = `${rootName}.total`;
    const ndsValue = `${rootName}.ndsValue`;
    const totalWithNds = `${rootName}.totalWithNds`;


    useEffect(() => {
        setValue(total, numeral.formats[numberFormat].format(price * quantity));
        if (nds) {
            const calculatedNds = price * quantity * nds / 100;
            const calculatedTotalWithNds = price * quantity + calculatedNds;

            setValue(ndsValue, numeral.formats[numberFormat].format(calculatedNds));
            setValue(totalWithNds, numeral.formats[numberFormat].format(calculatedTotalWithNds));
        } else {
            const calculatedTotalWithNds = price * quantity;

            setValue(ndsValue, '0');
            setValue(totalWithNds, numeral.formats[numberFormat].format(calculatedTotalWithNds));
        }
    }, [quantity, price, nds])

    let itemErrors = {};
    if (errors && errors[arrayFieldName] && errors[arrayFieldName][index])
        itemErrors = errors[arrayFieldName][index];


    const ndsOptions = [
        {value: null, label: 'No'},
        {value: 0, label: '0'},
        {value: 12, label: '12'},
        {value: 15, label: '15'}
    ];

    const onDeleteClicked = () => {
        onDelete(item);
    };


    return (
        <tr>
            <td className="p-1">
                <Form.Control placeholder={t(lang, "edi.common.items.product.placeholder")} className="p-1" {...register(`${rootName}.name`, {
                    required: t(lang, "edi.common.forms.validations.is_required"),
                })} isInvalid={itemErrors?.name} isValid={Object.keys(errors).length > 0 && !itemErrors?.name}/>
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.name?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control placeholder={t(lang, "edi.common.items.product.customer_id.placeholder")}
                              isValid={Object.keys(errors).length > 0}
                              {...register(`${rootName}.customerSystemId`)}
                />
            </td>
            <td className="p-1">
                <Form.Control placeholder={t(lang, "edi.common.items.product.customer_id.placeholder")}
                              isValid={Object.keys(errors).length > 0}
                              {...register(`${rootName}.customerShipmentId`)}
                />
            </td>
            <td className="p-1">
                <Form.Control  placeholder={t(lang, "edi.common.items.product.barcode.placeholder")}
                               isValid={Object.keys(errors).length > 0}
                               {...register(`${rootName}.barcode`)}
                />
            </td>
            <td className="p-1">
                <Form.Control placeholder={t(lang, "edi.common.items.product.product_code.placeholder")}
                              isValid={Object.keys(errors).length > 0}
                              {...register(`${rootName}.productCode`)}
                />
            </td>
            <td className="p-1">
                <Controller name={`${rootName}.catalogClassCode`}
                            render={({field}) => (
                                <SelectCompanyCatalog size="sm"
                                                      textButton={field.value || t(lang, "edi.common.select_button")}
                                                      defaultCatalogCode={field.value}
                                                      onChange={(catalog) => {
                                                          field.onChange(catalog?.class_code || null)
                                                          setValue(`${rootName}.catalogClassName`, catalog?.name || null);
                                                      }}
                                />
                            )}
                />
            </td>
            <td className="p-1">
                <Form.Control placeholder={t(lang, "edi.common.items.product.measurement.placeholder")}
                              className="p-1"
                              isInvalid={itemErrors?.measurement}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.measurement}
                              {...register(`${rootName}.measurement`, {
                                  required: t(lang, "edi.common.forms.validations.is_required")
                              })}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.measurement?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control placeholder={t(lang, "edi.common.items.product.quantity.placeholder")}
                              className="p-1"
                              isInvalid={itemErrors?.quantity}
                              isValid={Object.keys(errors).length > 0 && !itemErrors?.quantity}
                              {...register(`${rootName}.quantity`, {
                                  required: t(lang, "edi.common.forms.validations.is_required"),
                                  pattern: {
                                      value: /^[1-9](\d*)((.)\d+)?$/,
                                      message: "Invalid format!"
                                  },
                              })}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.quantity?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control  placeholder={t(lang, "edi.common.items.product.price.placeholder")}
                               className='form-control p-1'
                               isInvalid={itemErrors?.price}
                               isValid={Object.keys(errors).length > 0 && !itemErrors?.price}
                               {...register(`${rootName}.price`, {
                                   required: t(lang, "edi.common.forms.validations.is_required"),
                                   pattern: {
                                       value: /^[1-9](\d*)((.)\d+)?$/,
                                       message: "Invalid format!"
                                   },
                               })}
                />
                <Form.Control.Feedback type="invalid">
                    {itemErrors?.price?.message}
                </Form.Control.Feedback>
            </td>
            <td className="p-1">
                <Form.Control {...register(`${rootName}.total`)} readOnly={true} className="p-1"/>
            </td>
            <td className="p-1">
                <Form.Select className="p-1"  {...register(`${rootName}.nds`)}>
                    {ndsOptions.map(ndsOption => {
                        return <option value={ndsOption.value} key={ndsOption.value}>{ndsOption.label}</option>
                    })}
                </Form.Select>
            </td>
            <td className="p-1">
                <Form.Control {...register(`${rootName}.ndsValue`)} readOnly={true} className="p-1"/>
            </td>
            <td className="p-1">
                <Form.Control {...register(`${rootName}.totalWithNds`)} readOnly={true} className="p-1"/>
            </td>
            <td className="text-end p-1">
                <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-1" onClick={onDeleteClicked}
                              disabled={!allowDelete}/>
            </td>
        </tr>
    );
};

export default ItemRow;