import React, {Fragment, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../common/IconButton";
import CashBoxesRegisterModal from "./CashBoxesRegisterModal";
import SelectCurrencyCode from "../../crm/contractor/SelectCurrencyCode";

const CashBoxesDatatableHeader = ({selectedCurrency ,setSelectedCurrency}) => {
    const lang = useSelector(selectLang)
    const t = Translate

    const [show, setShow] = useState(false)

    const onHideCashboxModal = () => {
        setShow(false)
    }
    return (
        <Fragment>


            <Row className="d-flex justify-content-between align-items-center py-2">
               {/*<Row>*/}
               {/*    <Col>*/}
               {/*        <label>{t(lang, "main.top.nav.profile_dropdown.dropdown_item.currency")}</label>*/}
               {/*        <SelectCurrencyCode*/}
               {/*            defaultValue={selectedCurrency}*/}
               {/*            onChange={(currency) => {*/}
               {/*                console.log(currency)*/}
               {/*                setSelectedCurrency(currency)*/}
               {/*            }}*/}
               {/*        />*/}
               {/*    </Col>*/}
               {/*</Row>*/}
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h4 className="mb-0 text-nowrap py-2 py-xl-0 me-2">{t(lang, "cashbox.bind.account.title")}</h4>
                </Col>
                <Col xs={8} className="d-flex justify-content-end gap-1">
                    <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className={'me-2'}
                        onClick={() => setShow(true)}
                    >
                        <span className="d-none d-sm-inline-block">{t(lang, "task.board.common.add")}</span>
                    </IconButton>
                </Col>
            </Row>

            <CashBoxesRegisterModal show={show} onHide={onHideCashboxModal} />
        </Fragment>
    );
};

export default CashBoxesDatatableHeader;
