import React, {useEffect} from 'react';
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    selectCountDraftItems,
    selectFilters,
    selectPagination,
    updatePaginationAsync
} from "../../../../../app/store/reducers/draft-item/draftItemReducer";

const DataTableFooter = () => {
    const dispatch = useDispatch();
    const count = useSelector(selectCountDraftItems);
    const pagination = useSelector(selectPagination, shallowEqual);
    const filters = useSelector(selectFilters, shallowEqual);

    useEffect(() => {
        dispatch(updatePaginationAsync({...pagination, page: 1}));
    }, [filters])

    const onPageChange = page => dispatch(updatePaginationAsync({...pagination, page: page}));
    const onLimitChange = limit => dispatch(updatePaginationAsync({...pagination, limit: limit, page: 1}));

    return (
        <AdvanceTableCustomPagination
            table
            count={count}
            limit={pagination.limit}
            page={pagination.page}
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
        />
    );
};

export default DataTableFooter;