import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import React, {cloneElement, Fragment, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {downloadFile} from "../../../../../helpers/excel-uploader";
import {faFilePdf, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {refundDownloadPdfAsync, refundDownloadReceipt58PdfAsync, refundDownloadReceipt80PdfAsync} from "../../../../../app/store/reducers/warehouse-operation/refundReducer";

const DownloadRefundDropdown = ({refundId, refundNumber, children, ...props}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const downloadDefaultPdf = id => {
        setLoading(true);

        refundDownloadPdfAsync({refundId: id})
            .then(pdfContent => {
                downloadFile({
                    content: pdfContent,
                    contentType: 'application/pdf',
                    fileName: `${t(lang, "warehouse.item.download_pdf.refund_name", {number: refundNumber})}.pdf`,
                });
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')))
            .finally(() => setLoading(false))
    }

    const downloadDefaultReceipt80Pdf = id => {
        setLoading(true);

        refundDownloadReceipt80PdfAsync({refundId: id})
            .then(pdfContent => {
                downloadFile({
                    content: pdfContent,
                    contentType: 'application/pdf',
                    fileName: `${t(lang, "warehouse.item.download_pdf.refund_name", {number: refundNumber})}.pdf`,
                });
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')))
            .finally(() => setLoading(false))
    }

    const downloadDefaultReceipt58Pdf = id => {
        setLoading(true);

        refundDownloadReceipt58PdfAsync({refundId: id})
            .then(pdfContent => {
                downloadFile({
                    content: pdfContent,
                    contentType: 'application/pdf',
                    fileName: `${t(lang, "warehouse.item.download_pdf.refund_name", {number: refundNumber})}.pdf`,
                });
            })
            .catch(() => toast.error(t(lang, 'common.toast.error')))
            .finally(() => setLoading(false))
    }

    return (
        <Dropdown {...props} autoClose="outside">
            <Dropdown.Toggle bsPrefix="toggle" variant="falcon-default" size="sm" disabled={loading}>
                {!!children
                    ?
                    <div>
                        {!!loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                        {!loading && cloneElement(children, {...children.props})}
                    </div>
                    :
                    <div>
                        {!!loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                        {!loading && <FontAwesomeIcon className="me-1" icon={faFilePdf}/>}

                        <span className="d-none d-sm-inline-block">
                            {t(lang, "warehouse.operation.item.common.download_pdf")}
                        </span>
                    </div>
                }
            </Dropdown.Toggle>
            <Dropdown.Menu className='py-2'>
                <Fragment>
                    <Dropdown.Header className="text-black">{t(lang, 'warehouse.operation.item.common.downloading_refund_invoice')}</Dropdown.Header>
                    <Dropdown.Item className="ps-4" disabled={loading} onClick={() => downloadDefaultPdf(refundId)}>
                        {t(lang, 'warehouse.operation.item.common.download_refund_invoice')}
                    </Dropdown.Item>
                </Fragment>
                <Fragment>
                    <Dropdown.Header className="text-black">{t(lang, 'warehouse.operation.item.common.downloading_receipt')}</Dropdown.Header>
                    <Dropdown.Item className="ps-4" disabled={loading} onClick={() => downloadDefaultReceipt80Pdf(refundId)}>
                        {t(lang, 'warehouse.operation.item.common.download_receipt_80')}
                    </Dropdown.Item>
                    <Dropdown.Item className="ps-4" disabled={loading} onClick={() => downloadDefaultReceipt58Pdf(refundId)}>
                        {t(lang, 'warehouse.operation.item.common.download_receipt_58')}
                    </Dropdown.Item>
                </Fragment>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DownloadRefundDropdown;