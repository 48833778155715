import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";

const TelegramOrderBotDataTableFilter = ({
                                             filter,
                                             onFilterChange,
                                             onDebouncedFilterChange,
                                         }) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Row className="g-2">
            <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                <Form.Group>
                    <Form.Label>{t(lang, 'items.common.datatable.filter.deep_search')}</Form.Label>
                    <Form.Control name="name"
                                  placeholder={t(lang, 'items.common.datatable.filter.deep_search.placeholder')}
                                  defaultValue={filter.name}
                                  onChange={(e) => onDebouncedFilterChange({...filter, name: e.target.value})}

                    />
                </Form.Group>
            </Col>
        </Row>
    );
};

export default TelegramOrderBotDataTableFilter;