import React, {useState} from 'react';
import {Col, Form, Row, InputGroup} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {changeInternalOrderFilterOptionsAsync} from "../../../../../app/store/reducers/crm/internal/crmInternalOrderReducer";
import {useAsyncDebounce} from "react-table";
import IconButton from "../../../../common/IconButton";
import {Link} from "react-router-dom";
import SelectBranch from "../../../../common/SelectBranch";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";

const InternalOrderDataTableHeader = () => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const dispatch = useDispatch();
    const [orderNumber, setOrderNumber] = useState('');
    const [branchFromId, setBranchFromId] = useState(null);
    const [branchToId, setBranchToId] = useState(null);

    const changedFilter = useAsyncDebounce(value => {
        dispatch(changeInternalOrderFilterOptionsAsync({
            branch_from_id: branchFromId,
            branch_to_id: branchToId,
            number: orderNumber,
            page: 1,
        }));
    }, 1000);

    return (
        <>
            <Row className="flex-between-center">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <InputGroup>
                        <Form.Control
                            type="search"
                            value={orderNumber}
                            className="search-input"
                            onChange={event => {
                                setOrderNumber(event.target.value)
                                changedFilter()
                            }}
                            placeholder={t(lang, "edi.common.form.search_input.placeholder")}
                        />
                    </InputGroup>
                </Col>
                <Col xs={2}>
                    <Form.Group>
                        <SelectBranch defaultBranchId={branchFromId}
                                      placeholder={t(lang, "crm.internal.order.from.branch")}
                                      onChange={branch => {
                                          setBranchFromId(branch?.id)
                                          changedFilter()
                                      }}
                        />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    <Form.Group>
                        <SelectBranch defaultBranchId={branchToId}
                                      placeholder={t(lang, "crm.internal.order.to.branch")}
                                      onChange={branch => {
                                          setBranchToId(branch?.id)
                                          changedFilter()
                                      }}
                        />
                    </Form.Group>
                </Col>
                <Col xs={6} sm="auto" className="ms-auto text-end ps-0">
                    <IconButton
                        className="mx-2"
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        as={Link}
                        transform="shrink-3"
                        to="../order/create"
                    >{t(lang, "edi.common.button.create")}</IconButton>
                </Col>
            </Row>
        </>
    );
};

export default InternalOrderDataTableHeader;
