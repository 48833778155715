import React, {Fragment, useState} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../common/FalconCloseButton";
import IconButton from "../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteNotificationAsync, loadNotificationsAsync,
} from "../../app/store/reducers/telegram-notification/telegramNotificationReducer";
import {toast} from "react-toastify";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";

const NotificationDelete = ({notification}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)

    const lang = useSelector(selectLang)
    const t = Translate

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const removeNotification = (id) => {
        setLoading(true)
        deleteNotificationAsync(id)
            .then(() => {
                dispatch(loadNotificationsAsync())
                toast.success('Success')
            })
            .catch(e => {
                new Error(e)
                toast.error("Failure")
            })
            .finally(() => setLoading(false))
    }
    return (
        <Fragment>
            <IconButton icon="trash" className="mx-1"
                        variant="falcon-danger"
                        size="sm" transform="shrink-3"
                        onClick={handleShow}
            />

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "notification.delete.number.modal")}</Modal.Title>
                    <FalconCloseButton onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(lang, "notification.confirmation.delete.number")} <b>+{notification?.phone_number}</b>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t(lang, "notification.close_button")}
                    </Button>
                    <Button variant="danger" disabled={loading} onClick={() => removeNotification(notification.id)}
                            className="d-flex align-items-center gap-1">
                        {loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />} {t(lang, "notification.delete_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default NotificationDelete;