import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {InputGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import AsyncSelect from "react-select/async";
import {debounce} from "underscore";
import {getCompanies, getCompany} from "../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const innFormatRegEx = /^(\d{9}|\d{14})$/

const FindCompanySearchable = React.forwardRef(({onChange, inputGroupProps, inputProps, defaultInn, disabled}, ref) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);
    const onChangeHandler = (OrganizationInfo) => {
        onChange(OrganizationInfo)
        setValue(OrganizationInfo)
    };
    const loadOptions = (query, callback) => {
        if (query) {
            getCompanies({
                q: query,
                active_accounts: true,
                frozen_accounts: true,
                main_accounts: true
            })
                .then((organizations) => {
                    callback(organizations);
                })
                .catch(() => {
                    callback([])
                })
        } else {
            callback([]);
        }
    };
    const debouncedLoadOptions = debounce(loadOptions, 500);

    const formatOptionLabel = (option) => {
        return (
            <div className="fs--1">
                <span className="text-black fw-bold me-1">{option?.inn}</span>-
                {option?.person_num &&
                    <>
                        <span className="text-black fw-bold mx-1">{option?.person_num}</span>-
                    </>
                }
                <span className="ms-1 text-facebook">{option?.short_name || option?.name}</span>
            </div>
        )
    };

    useEffect(async () => {
        if (!defaultInn) {
            setValue(null)
            return onChange(null)
        }
        if (innFormatRegEx.test(defaultInn)) {
            if (value?.director_pinfl === defaultInn || value?.inn === defaultInn || value?.person_num === defaultInn)
                return;
            let organization = await getCompany(defaultInn, {
                active_accounts: true,
                frozen_accounts: true,
                main_accounts: true
            });
            setValue(organization);
            onChange(organization);
        }
    }, [defaultInn]);

    return (
        <InputGroup {...inputGroupProps}>
            <AsyncSelect loadOptions={debouncedLoadOptions}
                         ref={ref}
                         isDisabled={disabled}
                         formatOptionLabel={formatOptionLabel}
                         getOptionValue={option => option?.person_num || option?.inn}
                         getOptionLabel={option => `${option?.person_num} ${option?.inn} / ${option?.short_name || option?.name}`}
                         placeholder={t(lang, 'items.common.select')}
                         className="w-100"
                         style={{width: "100%"}}
                         value={value}
                         defaultOptions
                         onChange={onChangeHandler}
                         isClearable
                         noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                         {...inputProps}
            />
        </InputGroup>
    )
});


export default FindCompanySearchable;
