import {Link} from "react-router-dom";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Form, Modal} from "react-bootstrap";
import InProgress from "../../../common/InProgress";
import {useForm, FormProvider} from "react-hook-form";
import ImportAttorneyForm from "./ImportAttorneyForm";
import ImportAttorneyProgressModal from "./ImportAttorneyProgressModal";
import {dynamicDateFormatter} from "../../../../helpers/dynamicDateFormatter";
import ConvertQuantityToWord from "../../../../app/filter/ConvertQuantityToWord";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {addAttorneyAsync} from "../../../../app/store/reducers/attorney/attorneyReducer";

const dateFormat = 'YYYY-MM-DD';
const STORAGE_ATTORNEYS_COLUMNS = 'attorneysColumns';

const MassImportAttorneyFrom = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [attorneysLength, setAttorneysLength] = useState(0);
    const [failedAttorneys, setFailedAttorneys] = useState([]);
    const [sendResults, setSendResults] = useState([]);
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            items: [],
            skipRows: 0,
            columnKeys: [],
        }
    })

    const onSubmit = async (formData) => {
        let attorneys = [];
        const {skipRows, items, columnKeys} = formData;

        let attorney = null;
        for (let i = skipRows; i < items.length; i++) {
            const item = items[i];

            // map attorney column
            let rowAttorneyInfo = {};
            for (let j = 0; j < item.length; j++) {
                rowAttorneyInfo[columnKeys[j]] = item[j] ? item[j].toString() : null;
            }

            if (attorney === null || attorney.attorney_info.number !== rowAttorneyInfo.attorney_number) {
                attorney = {
                    items: [],
                    owner_info: {
                        inn: rowAttorneyInfo.owner_inn,
                        name: rowAttorneyInfo.owner_name || null,
                        oked: rowAttorneyInfo.owner_oked || null,
                        address: rowAttorneyInfo.owner_address || null,
                    },
                    owner_bank_info: {
                        account: rowAttorneyInfo.owner_bank_account || null,
                        mfo: rowAttorneyInfo.owner_bank_mfo || null,
                        name: rowAttorneyInfo.owner_bank_name || null,
                    },
                    attorney_info: {
                        number: rowAttorneyInfo.attorney_number,
                        start: dynamicDateFormatter(rowAttorneyInfo.attorney_date_start, dateFormat),
                        end: dynamicDateFormatter(rowAttorneyInfo.attorney_date_end, dateFormat),
                    },
                    person_info: {
                        position: rowAttorneyInfo.person_position,
                        full_name: rowAttorneyInfo.person_full_name || null,
                        person_inn: rowAttorneyInfo.person_inn,
                    },
                    passport_info: {
                        number: rowAttorneyInfo.passport_number || null,
                        serial: rowAttorneyInfo.passport_serial || null,
                        issuer: rowAttorneyInfo.passport_issuer || null,
                        issued_on: dynamicDateFormatter(rowAttorneyInfo.passport_issued_on, dateFormat),
                    },
                    contract_info: {
                        number: rowAttorneyInfo.contract_number,
                        date: dynamicDateFormatter(rowAttorneyInfo.contract_date, dateFormat),
                    },
                    contractor_info: {
                        inn: rowAttorneyInfo.contractor_inn,
                        name: rowAttorneyInfo.contractor_name || null,
                        oked: rowAttorneyInfo.contractor_oked || null,
                        address: rowAttorneyInfo.contractor_address || null,
                    },
                    contractor_bank_info: {
                        account: rowAttorneyInfo.contractor_bank_account || null,
                        mfo: rowAttorneyInfo.contractor_bank_mfo || null,
                        name: rowAttorneyInfo.contractor_bank_name || null,
                    },
                    accountant: rowAttorneyInfo.accountant || null,
                    director: rowAttorneyInfo.director || null
                };
                attorneys.push(attorney);
            }

            attorney.items.push({
                product: rowAttorneyInfo.product_name,
                measurement: rowAttorneyInfo.product_measurement,
                quantity: +rowAttorneyInfo.product_quantity,
                quantity_in_words: ConvertQuantityToWord.getText(+rowAttorneyInfo.product_quantity)
            })
        }

        // save columns to localstorage
        localStorage.setItem(STORAGE_ATTORNEYS_COLUMNS, JSON.stringify(columnKeys));

        await massSendHandler(attorneys);
    }

    const massSendHandler = async (attorneys) => {
        setShow(true);

        setLoading(true);
        setAttorneysLength(attorneys.length);
        for (let i = 0; i < attorneys.length; i++) {
            const attorney = attorneys[i];

            await addAttorneyAsync(attorney)
                .then(() => {
                    setSendResults(prev => [...prev, true]);
                })
                .catch(() => {
                    setSendResults(prev => [...prev, false]);
                    setFailedAttorneys(prev => [...prev, attorney]);
                })
        }

        setLoading(false);
    }

    const handleClose = () => setShow(false);

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <InProgress/>
                    <ImportAttorneyForm savedColumnPositions={STORAGE_ATTORNEYS_COLUMNS} />
                </Form>
            </FormProvider>

            <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} fullscreen>
                <Modal.Header>
                    <Modal.Title>{t(lang, 'edi.attorney.mass_import.sending_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImportAttorneyProgressModal loading={loading} length={attorneysLength} failedAttorneys={failedAttorneys} results={sendResults} />
                </Modal.Body>
                <Modal.Footer>
                    <Button as={Link} to="../attorneys" onClick={handleClose}>{t(lang, 'edi.attorney.mass_import.sending_modal.close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MassImportAttorneyFrom;