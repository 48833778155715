import React from "react";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {selectWarehouses} from "../../app/store/reducers/warehouse/warehouseReducer";
import ReactSelect from "react-select";


const SelectWarehouse = ({onChange, defaultWarehouseId, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const warehouses = useSelector(selectWarehouses);

    return (
        <ReactSelect options={warehouses}
                     isSearchable={!defaultWarehouseId}
                     isClearable={true}
                     placeholder={t(lang, 'items.common.select')}
                     getOptionLabel={option => option.name}
                     getOptionValue={option => option.id}
                     onChange={onChange}
                     value={warehouses.find(w => w.id === defaultWarehouseId) || null}
                     {...selectProps}
        />
    );
};

export default SelectWarehouse;