import React, {cloneElement, forwardRef, Fragment, useImperativeHandle, useState} from 'react';
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import {
    dragAndDropTaskInColumn,
    selectModalContent
} from "../../../../app/store/reducers/kanban/kanbanReducer";
import {ContractorDebtBoardType, ContractorDebtColumnPaidType} from "../../../../enum/KanbanWrapper";
import {
    loadContractorAsync,
    updateCardContractor,
    updateContractorDebtPromiseDate
} from "../../../../app/store/reducers/contractor/contractorReducer";
import {toast} from "react-toastify";

const DebtRepayment = forwardRef(({ getTaskAsync, contractorId, hide, children }, ref) => {

    const dispatch = useDispatch();
    const modalContent = useSelector(selectModalContent);
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal: () => handlerShow(),
        closeModal: () => setShow(false),
    }));

    const onConfirmed = async () => {
        setLoading(true)
        await dragAndDropTaskInColumn({
            board_type: ContractorDebtBoardType,
            board_column_type: ContractorDebtColumnPaidType,
            board_column_task_id: modalContent?.id,
        })
            .then(res => {
                toast.success(t(lang, 'warehouse.operation.item.common.toast.success'))
                handlerClose()
            })
            .catch((e) => {
                toast.error(t(lang, 'warehouse.operation.item.common.toast.error'))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handlerClose = async () => {
        setShow(false)
        await loadContractorAsync(contractorId)
            .then(cr => {
                dispatch(updateCardContractor(cr))
                dispatch(updateContractorDebtPromiseDate(cr))
            })
    }

    const handlerShow = () => {
        setShow(true)
        getTaskAsync()
    }

    return (
        <Fragment>
            {
                !hide && (
                    <Fragment>
                        {!!children ? cloneElement(children, {...children.props, onClick: () => handlerShow()}) : (
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                onClick={() => setShow(true)}
                                transform="shrink-3">
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, "edi.common.add_button")}
                                </span>
                            </IconButton>)
                        }
                    </Fragment>
                )
            }

            <Modal show={show} onHide={handlerClose}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <div className={'d-flex justify-content-center'}>
                        <FontAwesomeIcon icon={'triangle-exclamation'} size={'4x'} className={'text-warning'} />
                    </div>
                    <Modal.Title className="text-center">{t(lang, "task.board.contractor.debt.will_you_pay_off_the_specified_debt")}</Modal.Title>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerClose}>
                        {t(lang, "warehouse.operation.item.common.without_payment_no")}
                    </Button>
                    <Button variant="primary" disabled={loading} onClick={() => onConfirmed()}>
                        {loading && <Spinner animation="border" size="sm" />}
                        {t(lang, "warehouse.operation.item.common.without_payment_yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
});

export default DebtRepayment;
