import {useSelector} from "react-redux";
import WarehouseNo from "../../../../components/hippo/warehouse-operation/components/WarehouseNo";
import {selectWarehouses} from "../../../../app/store/reducers/warehouse/warehouseReducer";
import {useHistory} from "react-router";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import React, {useEffect, useState} from "react";
import {
    getRefundItemAsync, refundApproveAsync, refundPayoutRegisterAsync,
    refundPayoutUpdateAsync
} from "../../../../app/store/reducers/warehouse-operation/refundReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS
} from "../../../../app/eventbus/warehouse-operation/refundEvents";
import {Button, Card, Col, Row} from "react-bootstrap";
import WarehouseOperationPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationPayment";
import WarehouseOperationWithoutPayment
    from "../../../../components/hippo/warehouse-operation/components/WarehouseOperationWithoutPayment";
import SoftBadge from "../../../../components/common/SoftBadge";
import {
    isApprovedCheckedWarehouseOperation
} from "../../../../components/hippo/warehouse-operation/enum/warehouseOperationWrapper";
import RefundExcelForm from "../../../../components/hippo/warehouse-operation/refund/forms/RefundExcelForm";
import {Link} from "react-router-dom";
import WOWithoutDiscountPayment
    from "../../../../components/hippo/warehouse-operation/components/WOWithoutDiscountPayment";


const AddRefundExcel = () => {
    const [refund, setRefund] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate

    const loadRefund = (id) => {
        getRefundItemAsync({refundId: id})
            .then((response) => setRefund(response.data))
            .catch((error) => setRefund(null))
    }


    useEffect(() => {
        const onChangeContractorHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, (id) => {
            loadRefund(id)
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_CHANGE_CONTRACTOR_SUCCESS, onChangeContractorHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
        }
    }, [])

    const onSubmitPay = async (pay) => {
        if (refund?.payout) {
            await refundPayoutUpdateAsync({refundId: pay?.id, payout: pay?.payment})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})
        }
        if (!refund?.payout && refund?.is_approved) {
            await refundPayoutRegisterAsync({refundId: pay?.id, payout: pay?.payment})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})
        }
        if (!refund?.is_approved) {
            await refundApproveAsync({refundId: pay?.id, payout: pay?.payment || null})
                .then(() => {
                    history.push(`./view/${pay?.id}`)
                })
                .catch(() => {})
        }
    }


    if (warehouses?.length > 0)
        return (
            <Card>
                <Card.Body>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <h5>{t(lang, "warehouse.operation.item.common.new_refunds")}</h5>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            {refund?.id &&
                                <div className="me-3">
                                    {/*<WarehouseOperationPayment onSubmitPay={onSubmitPay} data={refund} />*/}
                                    <WOWithoutDiscountPayment
                                        data={refund}
                                        isEditing={false}
                                        onSubmitPay={onSubmitPay}
                                    />
                                    {!refund?.is_approved &&
                                        <WarehouseOperationWithoutPayment onClick={onSubmitPay} id={refund?.id}/>
                                    }
                                    <Button
                                        variant="falcon-info"
                                        title={t(lang, "warehouse.operation.item.common.button.save_draft")}
                                        size="sm"
                                        className="me-1"
                                        as={Link}
                                        to={`./view/${refund?.id}`}
                                    >{t(lang, "warehouse.operation.item.common.button.save_draft")}</Button>
                                </div>
                            }
                            <div>
                                <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                <SoftBadge bg={refund?.is_approved ? 'success' : 'warning'}>
                                    {t(lang, isApprovedCheckedWarehouseOperation(!!refund?.is_approved))}
                                </SoftBadge>
                            </div>
                        </Col>
                    </Row>
                    <RefundExcelForm />
                </Card.Body>
            </Card>
        )

    if (warehouses?.length === 0)
        return(
            <>
                <WarehouseNo/>
            </>
        )
}


export default AddRefundExcel;