import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom'
import {useParams} from "react-router";
import {toast} from "react-toastify";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    DOCUMENT_CANCEL_FAILED,
    DOCUMENT_CANCEL_STARTED,
    DOCUMENT_CANCEL_SUCCESS,
    DOCUMENT_DECLINE_FAILED,
    DOCUMENT_DECLINE_STARTED,
    DOCUMENT_DECLINE_SUCCESS,
    DOCUMENT_SIGN_FAILED,
    DOCUMENT_SIGN_STARTED,
    DOCUMENT_SIGN_SUCCESS
} from "../../../../app/eventbus/signEvents";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../app/store/reducers/main/mainReducer";
import {loadDraftContract} from "../../../../app/store/reducers/roaming/roamingContractReducer";
import ContractView from "../../../../components/hippo/roaming/contract/ContractView";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingContractUpdate,
} from "../../../../enum/Permission/RoamingPermission";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";

const ViewDraftContract = ({modalId}) => {
    const {id} = useParams();
    const history = useHistory();
    const [contract, setContract] = useState(null)
    const activeOrganization = useSelector(selectActiveOrganization);
    const lang = useSelector(selectLang);
    const t = Translate
    const dispatch = useDispatch()
    const permission = useSelector(selectAccountRolePermissions)

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingContractUpdate))) {
            if (history.length > 1) {
                history.push('/roaming/contracts/draft')
            } else {
                history.push('/')
            }
        }
    }, [permission])
    const load = () => {
        loadDraftContract(modalId ? modalId : id)
            .then(response => {
                let contentContract = JSON.parse(response.data.content)
                let contract = {
                    id: contentContract.ContractId,
                    has_vat: contentContract.HasVat,
                    executor: {
                        inn: contentContract.Owner.Tin,
                        name: contentContract.Owner.Name,
                        address: contentContract.Owner.Address,
                        work_phone: contentContract.Owner.WorkPhone,
                        mobile: contentContract.Owner.Mobile,
                        fax: contentContract.Owner.Fax,
                        oked: contentContract.Owner.Oked,
                        account: contentContract.Owner.Account,
                        mfo: contentContract.Owner.BankId,
                        director_inn: contentContract.Owner.FizTin,
                        director_name: contentContract.Owner.Fio,
                        branch: {
                            branch_code: contentContract.Owner.BranchCode,
                            branch_name: contentContract.Owner.BranchName
                        }
                    },
                    contractors: contentContract.Clients.map((client) => ({
                        inn: client.Tin,
                        name: client.Name,
                        address: client.Address,
                        work_phone: client.WorkPhone,
                        mobile: client.Mobile,
                        fax: client.Fax,
                        oked: client.Oked,
                        account: client.Account,
                        mfo: client.BankId,
                        director_inn: client.FizTin,
                        director_name: client.Fio,
                        branch: {
                            branch_code: client.BranchCode,
                            branch_name: client.BranchName
                        }
                    })),
                    info: {
                        number: contentContract.ContractDoc.ContractNo,
                        date: contentContract.ContractDoc.ContractDate,
                        name: contentContract.ContractDoc.ContractName,
                        expire_date: contentContract.ContractDoc.ContractExpireDate,
                        place: contentContract.ContractDoc.ContractPlace
                    },
                    items: contentContract.Products.map((product) => ({
                        ordering_number: product.OrdNo,
                        barcode: product.BarCode,
                        name: product.Name,
                        measure_id: product.MeasureId,
                        packageCode: +product?.PackageCode,
                        packageName: product?.PackageName,
                        quantity: product.Count,
                        price: product.Summa,
                        total: product.DeliverySum,
                        vat_rate: product.VatRate,
                        vat_sum: product.VatSum,
                        total_with_nds: product.DeliverySumWithVat,
                        without_vat: product.WithoutVat,
                        catalog: {
                            code: product.CatalogCode,
                            name: product.CatalogName
                        }
                    })),
                    parts: contentContract.Parts.map((part) => ({
                        ordering_number: part.OrdNo,
                        title: part.Title,
                        body: part.Body
                    }))
                }
                setContract(contract)
            })
            .catch(error => {
                console.log(error);
                history.push('/roaming/contracts/draft')
            })
    }

    useEffect(() => {
        load()
    }, [modalId, id, activeOrganization])

    useEffect(() => {
        // =========== DOCUMENT_SIGN
        const signStartedHandler = EventBus.on(DOCUMENT_SIGN_STARTED, () => dispatch(updateShowInProgressAsync(true)))
        const signedHandler = EventBus.on(DOCUMENT_SIGN_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(getOrganizationBillingInfoAsync());
            dispatch(updateShowInProgressAsync(false))
            history.push(`/roaming/contract/${modalId ? modalId : id}`)
        })
        const signFailedHandler = EventBus.on(DOCUMENT_SIGN_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        });

        // =========== DOCUMENT_CANCEL
        const cancelStartedHandler = EventBus.on(DOCUMENT_CANCEL_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const canceledHandler = EventBus.on(DOCUMENT_CANCEL_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(updateShowInProgressAsync(false))
            load()
        })
        const cancelFailedHandler = EventBus.on(DOCUMENT_CANCEL_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        })

        // =========== DOCUMENT_DECLINE
        const declineStartedHandler = EventBus.on(DOCUMENT_DECLINE_STARTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })
        const declinedHandler = EventBus.on(DOCUMENT_DECLINE_SUCCESS, () => {
            toast.success(t(lang, "edi.document.decline.notification.success"))
            dispatch(updateShowInProgressAsync(false))
            load()
        })
        const declineFailedHandler = EventBus.on(DOCUMENT_DECLINE_FAILED, (message) => {
            toast.error(t(lang, message))
            dispatch(updateShowInProgressAsync(false))
        })

        return () => {
            EventBus.remove(DOCUMENT_SIGN_STARTED, signStartedHandler)
            EventBus.remove(DOCUMENT_SIGN_SUCCESS, signedHandler)
            EventBus.remove(DOCUMENT_SIGN_FAILED, signFailedHandler)

            EventBus.remove(DOCUMENT_CANCEL_STARTED, cancelStartedHandler)
            EventBus.remove(DOCUMENT_CANCEL_SUCCESS, canceledHandler)
            EventBus.remove(DOCUMENT_CANCEL_FAILED, cancelFailedHandler)

            EventBus.remove(DOCUMENT_DECLINE_STARTED, declineStartedHandler)
            EventBus.remove(DOCUMENT_DECLINE_SUCCESS, declinedHandler)
            EventBus.remove(DOCUMENT_DECLINE_FAILED, declineFailedHandler)
        }
    }, [])

    return <>
        {contract && <ContractView contract={contract}/>}
        {!contract && <SpinnerComponent />}
    </>
};

export default ViewDraftContract;
