import React from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {Controller, useFormContext} from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import EmpowermentMember from "./form-blocks/EmpowermentMember";
import EmpowermentPerson from "./form-blocks/EmpowermentPerson";
import EmpowermentItems from "./form-blocks/EmpowermentItems";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";

const EmpowermentForm = ({draftEmpowerment}) => {
    const {register, formState: {errors}, control, watch} = useFormContext();
    const activeOrganization = useSelector(selectActiveOrganization);
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;


    return (
        <>
            <Row>
                <Col xs={12} lg={6}>
                    <Card>
                        <Card.Header>
                            <h4>{t(lang, "roaming.common.empowerment")}</h4>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12} md={6} className="mb-2">
                                    <Form.Label>{t(lang, "roaming.common.empowerment.number")}</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder={t(lang, "roaming.common.empowerment.number.placeholder")}
                                            isInvalid={errors?.empowermentInfo?.number}
                                            isValid={Object.keys(errors).length > 0 && !errors?.empowermentInfo?.number}
                                            {...register('empowermentInfo.number', {
                                                required: t(lang, 'roaming.validations.is_required'),
                                            })}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors?.empowermentInfo?.number?.message}</Form.Control.Feedback>
                                </Col>
                                <Col xs={12} md={6} className="mb-2">
                                    <Form.Label>{t(lang, "roaming.common.empowerment.start_date")}</Form.Label>
                                    <Controller control={control}
                                                    name="empowermentInfo.start"
                                                    rules={{
                                                        required: t(lang, 'roaming.validations.is_required'),
                                                    }}
                                                    render={
                                                        ({field}) => (
                                                            <DatePicker
                                                                    selected={field.value}
                                                                    onChange={field.onChange}
                                                                    placeholderText={t(lang, "roaming.common.empowerment.start_date.placeholder")}
                                                                    dateFormat={dateFormat}
                                                                    wrapperClassName={classNames({
                                                                        'is-invalid': errors?.empowermentInfo?.start
                                                                    })}
                                                                    className={classNames('form-control', {
                                                                        'is-invalid': errors.empowermentInfo?.start,
                                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.empowermentInfo?.start
                                                                    })}
                                                                />
                                                        )
                                                    }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                            {errors?.empowermentInfo?.start?.message}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col xs={12}>
                                    <Form.Label>{t(lang, "roaming.common.empowerment.end_date")}</Form.Label>
                                    <Controller control={control}
                                                    name="empowermentInfo.end"
                                                    rules={{
                                                        required: t(lang, 'roaming.validations.is_required'),
                                                        validate: (value) => {
                                                            if(value < watch('empowermentInfo.start'))
                                                                return t(lang, "roaming.validations.empowerment.empowerment_end_date_more_than_empowerment_start_date");
                                                            return true;
                                                        }
                                                    }}
                                                    render={
                                                        ({field}) => (
                                                            <DatePicker
                                                                selected={field.value}
                                                                onChange={field.onChange}
                                                                placeholderText={t(lang, "roaming.common.empowerment.end_date.placeholder")}
                                                                dateFormat={dateFormat}
                                                                wrapperClassName={classNames({
                                                                    'is-invalid': errors?.empowermentInfo?.end
                                                                })}
                                                                className={classNames('form-control', {
                                                                    'is-invalid': errors.empowermentInfo?.end,
                                                                    'is-valid': Object.keys(errors).length > 0 && !errors?.empowermentInfo?.end
                                                                })}
                                                            />
                                                        )
                                                    }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                            {errors?.empowermentInfo?.end?.message}
                                        </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={6}>
                    <Card>
                        <Card.Header>
                            <h4>{t(lang, "roaming.common.contract")}</h4>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col xs={12} className="mb-2">
                                    <Form.Label>{t(lang, "roaming.common.contract.number")}</Form.Label>
                                    <Form.Control
                                            type="text"
                                            isInvalid={errors?.contractInfo?.number}
                                            isValid={Object.keys(errors).length > 0 && !errors?.contractInfo?.number}
                                            placeholder={t(lang, "roaming.common.contract.date.placeholder")}
                                            {...register('contractInfo.number', {
                                                required: t(lang, 'roaming.validations.is_required'),
                                            })}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                                </Col>
                                <Col xs={12}>
                                    <Form.Label>{t(lang, "roaming.common.contract.date")}</Form.Label>
                                    <Controller control={control}
                                                    name="contractInfo.date"
                                                    rules={{
                                                        required: t(lang, 'roaming.validations.is_required'),
                                                        validate: (value) => {
                                                            if(value > watch('empowermentInfo.start'))
                                                                return t(lang, "roaming.validations.empowerment.contract_date_more_than_empowerment_start_date")
                                                            return true;
                                                        }
                                                    }}
                                                    render={
                                                        ({field}) => (
                                                            <DatePicker
                                                                selected={field.value}
                                                                onChange={field.onChange}
                                                                placeholderText={t(lang, "roaming.common.contract.number.placeholder")}
                                                                dateFormat={dateFormat}
                                                                wrapperClassName={classNames({
                                                                    'is-invalid': errors?.contractInfo?.date
                                                                })}
                                                                className={classNames('form-control', {
                                                                    'is-invalid': errors?.contractInfo?.date,
                                                                    'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.date
                                                                })}
                                                            />
                                                        )
                                                    }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                            {errors?.contractInfo?.date?.message}
                                        </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={6} className="mt-3">
                    <EmpowermentMember root="customerInfo" inn={activeOrganization?.inn} draftEmpowerment={draftEmpowerment} title={t(lang, "roaming.common.customer")}/>
                </Col>
                <Col xs={12} lg={6} className="mt-3">
                    <EmpowermentMember root="executorInfo" inn={draftEmpowerment?.SellerTin || draftEmpowerment?.executor?.inn} draftEmpowerment={draftEmpowerment} title={t(lang, "roaming.common.executor")}/>
                </Col>
                <Col xs={12} className="mt-3">
                    <EmpowermentPerson draftEmpowerment={draftEmpowerment} />
                </Col>
                <Col xs={12} className="mt-3">
                    <EmpowermentItems root={'items'}/>
                </Col>
            </Row>
        </>
    )
};

export default EmpowermentForm;