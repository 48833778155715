import SoftBadge from "../../common/SoftBadge";
import {OverlayTrigger, Popover} from "react-bootstrap";
import React from "react";
import {useSelector} from "react-redux";
import {selectItems} from "../../../app/store/reducers/item/itemReducer";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";

const OrderExecutorItem = ({item}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const items = useSelector(selectItems);
    const getWarehouseItems = (itemId) => {
        if (items.length === 0)
            return []

        let foundItem = items.find(pr => pr.item.id === itemId)
        if (!foundItem)
            return []
        return foundItem.warehouse_states.warehouse_items
    }

    const getWarehouseItemsStateAmount = (itemId) => {
        if (items.length === 0)
            return 0;
        let foundItem = items.find(pr => pr.item.id === itemId)
        if (!foundItem)
            return 0
        return foundItem?.warehouse_states?.warehouse_items.reduce((total, item) => {
            return total + item.state
        }, 0);
    }
    return (<>
        <h6>{item.executor_item.name}</h6>
        <em className={'text-info'}>
            <SoftBadge className='me-1' bg={'secondary'}>{item.product}</SoftBadge>
            <OverlayTrigger trigger={['hover', 'focus']}
                            placement={'bottom'}
                            overlay={
                                <Popover id="popover-basic" className='mt-0'>
                                    <Popover.Header>
                                        {getWarehouseItems(item.executor_item.id).map((warehouseItem, index) => {
                                            return (
                                                <SoftBadge bg={'primary'}
                                                           className='mb-1 d-flex me-1 justify-content-center'
                                                           key={warehouseItem.id}>
                                                    {warehouseItem.state}
                                                    <br/>
                                                    {warehouseItem.name}
                                                </SoftBadge>
                                            )
                                        })}
                                    </Popover.Header>
                                </Popover>
                            }
            >
                <SoftBadge className='me-1'
                           bg={'primary'}>{t(lang, 'items.common.state')}: {getWarehouseItemsStateAmount(item.executor_item.id)}</SoftBadge>
            </OverlayTrigger>
        </em>
    </>)
}
export default OrderExecutorItem;