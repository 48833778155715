import contractorService from "../../../../services/contractorService";
import {createSlice} from "@reduxjs/toolkit";

export const contractorAttorneySlice = createSlice({
    name: 'contractorAttorney',
    initialState: {
        attorneys: [],
        countAttorneys: 0,
        filterOptions: {page: 1, limit: 10, status: 30},
    },
    reducers: {
        updateAttorneys: (state, action) => {
            state.attorneys = action.payload
        },
        updateCountAttorneys: (state, action) => {
            state.countAttorneys = action.payload
        },
        updateFilterAttorney: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
    },
})
export const loadContractorAttorneysAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    contractorService.getContractorAttorneys(params)
        .then(response => {
            dispatch(contractorAttorneySlice.actions.updateAttorneys(response.data))
        })
        .catch(error => {
            console.log(error);
        })
}
export const loadContractorAttorneysCountAsync = (params) => (dispatch) => {
    let {limit, page, ...other} = params
    contractorService.getContractorAttorneysCount(other)
        .then(response => {
            dispatch(contractorAttorneySlice.actions.updateCountAttorneys(response.data))
        })
        .catch(error => console.log(error))
}
export const changeFilterContractorAttorneysAsync = (params) => (dispatch) => {
    return dispatch(contractorAttorneySlice.actions.updateFilterAttorney(params))
}
export const selectContractorAttorneys = (state) => state.contractorAttorney.attorneys;
export const selectContractorCountAttorneys = (state) => state.contractorAttorney.countAttorneys;
export const selectContractorAttorneyFilterOptions = (state) => state.contractorAttorney.filterOptions;

export default contractorAttorneySlice.reducer