import React from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller} from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";

const RoamingActFormCardInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);

    return (
        <Card className="h-100">
            <Card.Body>
                <Row className="g-2">
                    <Col xs={12} md={4}>
                        <Controller name="info.number"
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                    }}
                                    render={({field, fieldState: {error}}) => (
                                        <Form.Group>
                                            <Form.Label>
                                                {t(lang, 'roaming.act_form.act_number')}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control name="info.number"
                                                          placeholder={t(lang, 'roaming.act_form.act_number')}
                                                          isInvalid={!!error}
                                                          value={field.value}
                                                          onChange={field.onChange}
                                            />
                                            {!!error && <Form.Control.Feedback
                                                type="invalid">{error?.message}</Form.Control.Feedback>}
                                        </Form.Group>
                                    )}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Controller name="info.date"
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                    }}
                                    render={({field, fieldState: {error}}) => (
                                        <Form.Group>
                                            <Form.Label>
                                                {t(lang, 'roaming.act_form.act_date')}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <DatePicker selected={field.value}
                                                        onChange={field.onChange}
                                                        placeholderText={t(lang, "roaming.act_form.act_date")}
                                                        dateFormat={dateFormat}
                                                        isClearable
                                                        className={classNames('form-control', {
                                                            'is-invalid': !!error,
                                                        })}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': !!error
                                                        })}
                                            />
                                            {!!error && <Form.Control.Feedback
                                                type="invalid">{error?.message}</Form.Control.Feedback>}
                                        </Form.Group>
                                    )}
                        />
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col xs={12} md={4}>
                        <Controller name="contract.number"
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                    }}
                                    render={({field, fieldState: {error}}) => (
                                        <Form.Group>
                                            <Form.Label>
                                                {t(lang, 'roaming.act_form_contract_number')}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control name="contract.number"
                                                          placeholder={t(lang, 'roaming.act_form_contract_number')}
                                                          isInvalid={!!error}
                                                          value={field.value}
                                                          onChange={field.onChange}
                                            />
                                            {!!error && <Form.Control.Feedback
                                                type="invalid">{error?.message}</Form.Control.Feedback>}
                                        </Form.Group>
                                    )}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Controller name="contract.date"
                                    rules={{
                                        required: t(lang, 'items.common.validations.is_required'),
                                    }}
                                    render={({field, fieldState: {error}}) => (
                                        <Form.Group>
                                            <Form.Label>
                                                {t(lang, 'roaming.act_form_contract_date')}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <DatePicker selected={field.value}
                                                        onChange={field.onChange}
                                                        placeholderText={t(lang, "roaming.act_form_contract_date")}
                                                        dateFormat={dateFormat}
                                                        isClearable
                                                        className={classNames('form-control', {
                                                            'is-invalid': !!error,
                                                        })}
                                                        wrapperClassName={classNames({
                                                            'is-invalid': !!error
                                                        })}
                                            />
                                            {!!error && <Form.Control.Feedback
                                                type="invalid">{error?.message}</Form.Control.Feedback>}
                                        </Form.Group>
                                    )}
                        />
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col xs={12}>
                        <Controller name="act_id"
                                    render={({field}) => (
                                        <Form.Group hidden>
                                            <Form.Control name="act_id"
                                                          value={field.value}
                                                          onChange={field.onChange}
                                            />
                                        </Form.Group>
                                    )}
                        />
                        <Controller name="product_list.product_id"
                                    render={({field}) => (
                                        <Form.Group hidden>
                                            <Form.Control name="product_list.product_id"
                                                          value={field.value}
                                                          onChange={field.onChange}
                                            />
                                        </Form.Group>
                                    )}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default RoamingActFormCardInfo;