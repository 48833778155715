import React, {Fragment} from 'react';
import {Col, Form, ListGroup, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const BindContractorFormBody = ({data, fields, filterValue, register, unregister, label, bindLabel}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <Form.Group as={Row} className={'px-3 gap-2'}>
                <Col className={'px-0'}>
                    <Form.Label>{t(lang, label)}</Form.Label>
                    <div style={{maxHeight: 500}} className={'overflow-auto scrollbar'}>
                        <ListGroup>
                            {data
                                .filter(i => !fields.some(j => j.item_id === i.id))
                                .filter(item => ((item?.name?.trim())?.toLowerCase())?.includes(filterValue?.toLowerCase()))
                                .map((item) => <ListGroup.Item
                                    key={item.id}
                                    onClick={() => register(item)}
                                    className={'d-flex align-items-center gap-2 fs--1 cursor-pointer'}
                                >
                                    <Form.Check onChange={() => {}} type={'checkbox'} checked={false} />
                                    {item.name}
                                </ListGroup.Item>)}
                        </ListGroup>
                    </div>
                </Col>
                <Col className={'px-0'}>
                    <Form.Label>{t(lang, bindLabel)}</Form.Label>
                    <div style={{maxHeight: 500}} className={'overflow-auto scrollbar'}>
                        <ListGroup>
                            {fields
                                .filter(item => ((item?.name?.trim())?.toLowerCase())?.includes(filterValue?.toLowerCase()))
                                .map((item, index) => <ListGroup.Item
                                    key={item.id}
                                    onClick={() => unregister(item, index)}
                                    className={'d-flex align-items-center gap-2  fs--1 cursor-pointer'}
                                >
                                    <Form.Check onChange={() => {}} type={'checkbox'} checked={true} />
                                    {item?.name}
                                </ListGroup.Item>)}
                        </ListGroup>
                    </div>
                </Col>
            </Form.Group>
        </Fragment>
    );
};

export default BindContractorFormBody;
