import React, {useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import CategoryForm from "./forms/CategoryForm";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_CATEGORY_FAILED, ADD_CATEGORY_REQUESTED, ADD_CATEGORY_SUCCESS} from "../../../../app/eventbus/itemEvents";
import {addCategoryAsync} from "../../../../app/store/reducers/category/categoryReducer";
import {toast} from "react-toastify";

const withAddCategory = (WrappedComponent) => {
    return (props) => {
        const t = Translate;
        const lang = useSelector(selectLang);
        const form = useForm({
            name: '',
            parentId: null,
            images: []
        });

        const [show, setShow] = useState(false);
        const handleShow = () => setShow(true);
        const handleClose = () => setShow(false);


        const [loading, setLoading] = useState(false);

        const onSubmit = (formData) => {
            EventBus.dispatch(ADD_CATEGORY_REQUESTED);

            const payload = {
                name: formData.name,
                parent_id: formData.parentId,
                image: formData?.images?.length > 0 ? {
                    id: null,
                    name: formData.images[0].name,
                    content: formData.images[0].content.split(",")[1]
                } : null
            };

            setLoading(true);
            addCategoryAsync(payload)
                .then((category) => {
                    EventBus.dispatch(ADD_CATEGORY_SUCCESS, category);
                    setLoading(false);
                    handleClose();
                    toast.success(t(lang, 'items.common.toast.success'));
                    form.reset();
                })
                .catch((error) => {
                    EventBus.dispatch(ADD_CATEGORY_FAILED);
                    setLoading(false);
                    toast.error(t(lang, 'items.common.toast.error'));
                    if (error.category_exists) {
                        form.setError('name', {
                            type: 'server',
                            message: t(lang, 'items.common.validations.category_name_exists', {name: payload.name})
                        })
                    }
                })
        }

        return (
            <React.Fragment>
                <WrappedComponent {...props} handleShow={handleShow}/>
                <Modal show={show} onHide={handleClose} size="lg">
                    <FormProvider {...form}>
                        <Form>
                            <Modal.Header>
                                <Modal.Title>{t(lang, 'items.common.add_category')}</Modal.Title>
                                <FalconCloseButton onClick={handleClose}/>
                            </Modal.Header>
                            <Modal.Body>
                                <CategoryForm />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary"
                                        onClick={handleClose}
                                >
                                    {t(lang, 'items.common.cancel')}
                                </Button>
                                <Button variant="primary"
                                        onClick={form.handleSubmit(onSubmit)}
                                        disabled={loading}
                                >
                                    {loading && <Spinner className='align-middle me-2' animation='border' role='switch'/>}
                                    {t(lang, 'items.common.save')}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </FormProvider>
                </Modal>
            </React.Fragment>
        )
    }
};

export default withAddCategory;
