import React, { useEffect } from 'react'
import {Controller, useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux';
import {Button, Col, Form, Row} from 'react-bootstrap'
import DatePicker from "react-datepicker";
import {selectDateFormat} from "../../../app/store/reducers/main/mainReducer";
import FindCompany from '../organization/FindCompany';
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import SelectStatus from "../SelectStatus";
import {InvoiceStatuses} from "../../../enum/InvoiceStatus";
import SelectBranchUse from "../../common/SelectBranchUse";


function InvoiceDataTableFilter({filterDefaults, filterDisableds}) {
    const {register, setValue} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;


    useEffect(() => {
        Object.keys(filterDefaults).forEach(key => {
            setValue(key, filterDefaults[key]);
        })
    }, [])



    return (
        <>
            <Row className="mb-3 g-3">
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.invoice.datatable.header.filter.title")}</h4>
            </Row>
            <Row className="mb-3 g-3">
                <Form.Group as={Col} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.invoice.datatable.header.filter.number")}</Form.Label>
                    <Form.Control 
                        readOnly={filterDisableds?.number}
                        {...register("number")}
                        placeholder={t(lang, "edi.invoice.datatable.header.filter.number:placeholder")}
                    />
                </Form.Group>
                <Form.Group as={Col} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.invoice.datatable.header.filter.date_start")}</Form.Label>
                    <Controller
                        name="date_start"
                        render={({field}) => (
                            <DatePicker
                                isClearable={!filterDisableds?.date_start}
                                className='form-control'
                                placeholderText={t(lang, "edi.invoice.datatable.header.filter.date_start:placeholder")}
                                disabled={filterDisableds?.date_start}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => field.onChange(date)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.invoice.datatable.header.filter.date_end")}</Form.Label>
                    <Controller
                        name="date_end"
                        render={({field}) => (
                            <DatePicker
                                isClearable={!filterDisableds?.date_end}
                                className='form-control'
                                placeholderText={t(lang, "edi.invoice.datatable.header.filter.date_end:placeholder")}
                                disabled={filterDisableds?.date_end}
                                dateFormat={dateFormat}
                                selected={field.value}
                                onChange={date => {
                                    field.onChange(date)
                                }}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.invoice.datatable.header.filter.contractor_inn")}</Form.Label>
                    <Form.Control name="customer_inn"
                                  type="text"
                                  {...register('customer_inn')}

                    />
                </Form.Group>
                <Form.Group as={Col} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.invoice.datatable.header.filter.status")}</Form.Label>
                    <Controller
                        name="status"
                        render={({field}) => (
                            <SelectStatus statuses={InvoiceStatuses}
                                          defaultStatus={field.value}
                                          isClearable
                                          isDisabled={filterDisableds?.status}
                                          placeholder={t(lang, "edi.invoice.datatable.header.filter.status:placeholder")}
                                          onChange={statusId => field.onChange(typeof (statusId) !== 'undefined' ? statusId : null)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} md={4} lg={3}>
                    <Form.Label>{t(lang, "edi.order.datatable.header.filter.branch")}</Form.Label>
                    <Controller
                        name="branch_id"
                        render={({field}) => (
                            <SelectBranchUse
                                isClearable
                                defaultValue={field.value}
                                placeholder={t(lang, "edi.order.datatable.header.filter.branch:placeholder")}
                                onChange={data => field.onChange(data?.branch?.id)}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} className="d-flex align-items-end justify-content-end justify-content-lg-start mb-1">
                    <Button variant="primary" type='submit'>{t(lang, "edi.invoice.datatable.header.filter.show")}</Button>
                </Form.Group>
            </Row>
        </>
    )
}

export default InvoiceDataTableFilter