import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {boardType} from "../../../../enum/KanbanWrapper";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";

const SelectBoardType = ({field, onChange, defaultType, ...props}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [value, setValue] = useState(null)
    useEffect(() => {
        setValue(boardType.find(board => board.type === defaultType) || null)
    }, [defaultType]);

    return (
        <ReactSelect
            options={boardType}
            getOptionLabel={option => t(lang, option.name)}
            getOptionValue={option => option.type}
            placeholder={t(lang, 'task.board.common.select.placeholder')}
            value={value}
            onChange={(option) =>onChange(option.type)}
            hideSelectedOptions
            classNamePrefix={'react-select'}
            {...props}
        />

    );
};

export default SelectBoardType;
