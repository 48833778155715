import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {ForImportService} from "../../../../../enum/RoamingInvoiceWrapper";
import RoamingInvoiceFormCountrySelector from "./RoamingInvoiceFormCountrySelector";

const RoamingInvoiceFormForeignCompanyInfo = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const singleSidedType = useWatch({name: `singleSidedType`, exact: true});

    if (singleSidedType !== ForImportService)
        return null

    return (
        <>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.invoice.country')}</Form.Label>
                <Col xs={12} sm={8} md={12} lg={8}>
                    <Controller name={`foreignCompany.countryId`}
                                render={({field}) => (
                                    <RoamingInvoiceFormCountrySelector defaultCountryId={field.value}
                                                                       onChange={field.onChange}
                                    />
                                )}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.invoice.foreign_organization')}</Form.Label>
                <Col xs={12} sm={8} md={12} lg={8}>
                    <Form.Control name={`foreignCompany.name`}
                                  placeholder={t(lang, 'roaming.invoice.foreign_organization.placeholder')}
                                  {...form.register(`foreignCompany.name`)}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.common.address')}</Form.Label>
                <Col xs={12} sm={8} md={12} lg={8}>
                    <Form.Control name={`foreignCompany.address`}
                                  placeholder={t(lang, 'roaming.common.address.placeholder')}
                                  {...form.register(`foreignCompany.address`)}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.common.bank_name')}</Form.Label>
                <Col xs={12} sm={8} md={12} lg={8}>
                    <Form.Control name={`foreignCompany.bank`}
                                  placeholder={t(lang, 'roaming.common.bank_name.placeholder')}
                                  {...form.register(`foreignCompany.bank`)}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm="4" md="12" lg="4">{t(lang, 'roaming.common.bank_account')}</Form.Label>
                <Col xs={12} sm={8} md={12} lg={8}>
                    <Form.Control name={`foreignCompany.account`}
                                  placeholder={t(lang, 'roaming.common.bank_account.placeholder')}
                                  {...form.register(`foreignCompany.account`)}
                    />
                </Col>
            </Form.Group>
        </>
    );
};

export default RoamingInvoiceFormForeignCompanyInfo;