import LeadNoteForm from "./LeadNoteForm";
import React, {useState} from "react";
import {editLeadNoteAsync} from "../../../../../app/store/reducers/contractor/leadNoteReducer";

const LeadEditNoteForm = ({editingNote, onCancel, onSuccess}) => {
    const [loading, setLoading] = useState(false)
    const handleOnSubmit = (formData) => {
        setLoading(true)
        editLeadNoteAsync(editingNote.id, {
            comment: formData.note
        }).then(res => {
            setLoading(false)
            onSuccess()
        }).catch(errors => {
            setLoading(false)
        })
    }
    return (<>
        <LeadNoteForm onSubmit={handleOnSubmit} loading={loading} note={editingNote}
                      onCancel={onCancel}/>
    </>)
}
export default LeadEditNoteForm