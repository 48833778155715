import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import ContractFormationForm from "../../../pages/main/edi/contract-formation/ContractFormationForm";
import {
    selectCompanyCatalogs, selectMeasurements
} from "../../../app/store/reducers/roaming/roamingReducer";
import {selectLang} from "../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import dayjs from "dayjs";
import {updateContractFormationAsync} from "../../../app/store/reducers/contract-formation/contractFormationReducer";
import {useHistory} from "react-router";


const EditContractFormationForm = ({document}) => {
    const measurements = useSelector(selectMeasurements)
    const catalogs = useSelector(selectCompanyCatalogs);
    const history = useHistory()
    const lang = useSelector(selectLang)


    const form = useForm({
        defaultValues: {
            contractInfo: {
                number: document?.contract?.number,
                date: dayjs(document?.contract?.date, 'YYYY-MM-DD').toDate(),
                name: document?.contract?.title,
                expireDate: dayjs(document?.contract?.expires_on, 'YYYY-MM-DD').toDate(),
                place: document?.contract?.place,
                total_words: document?.contract?.total_in_words,
                header: document?.header
            },
            form_fields: document.form_fields,

            executor: {
                name: document?.contract?.executor?.company_name,
                tin: document?.contract?.executor?.company_inn,
                pinflTin: document?.contract?.executor?.physic_inn,
                fio: document?.contract?.executor?.physic_name,
                address: document?.contract?.executor?.address,
                bankId: document?.contract?.executor?.bank_mfo,
                account: document?.contract?.executor?.bank_account,
                phoneWork: document?.contract?.executor?.phone,
                mobile: document?.contract?.executor?.mobile,
                bank: document?.contract?.executor?.bank_name,
            },
            customer: {
                name: document?.contract?.customer?.company_name,
                tin: document?.contract?.customer?.company_inn,
                pinflTin: document?.contract?.customer?.physic_inn,
                fio: document?.contract?.customer?.physic_name,
                address: document?.contract?.customer?.address,
                bankId: document?.contract?.customer?.bank_mfo,
                account: document?.contract?.customer?.bank_account,
                phoneWork: document?.contract?.customer?.phone,
                mobile: document?.contract?.customer?.mobile,
                bank: document?.contract?.customer?.bank_name,
            },
            products: document?.contract?.items.map((item, i) => {
                const catalog = catalogs?.find(c => c?.class_code === item?.catalog_code)
                const packages = catalog?.package_names?.find(p => p?.code === +item?.package_code)


                return {
                    id: item.id,
                    order: i + 1,
                    name: item?.name,
                    catalogCode: item?.catalog_code,
                    catalogName: item?.catalog_name,
                    barCode: item?.barcode,
                    measureId: item?.measurement,
                    measureName: item?.measurement_name,
                    packageCode: +item?.package_code,
                    packageName: item?.package_name,
                    catalog: catalog,
                    packages: packages,
                    certificate: item?.attachment_id,
                    count: item?.quantity,
                    summa: item?.price,
                    exciseRate: item?.excise_rate,
                    exciseSum:item?.excise_value,
                    deliverySum: item?.total,
                    vatRate: item.without_vat ? '' : item?.nds_rate,
                    vatSum: item?.nds_value,
                    deliverySumWithVat: item?.total_with_nds,
                }
            }),
            parts: document?.contract?.parts.map((part, i) => ({
                id: part?.id,
                order: i + 1,
                title: part?.title,
                body: part?.body
            })),
            attachments: document.contract.files.map((file, i) => {
                return {
                    order: i,
                    id: file?.id,
                    file: file?.content,
                    date: file.expires_on ? dayjs(file?.expires_on, 'YYYY-MM-DD').toDate() : null,
                    filename: file?.filename,
                    attachment_id: file?.attachment_id,
                }
            })
        }
    })



    useEffect(() => {
        document?.contract?.items.map((item, index) => {
            const catalog = catalogs?.find(c => c?.class_code === item?.catalog_code)
            const packages = catalog?.package_names?.find(p => p?.code === +item?.package_code)
            const packageName = lang === "ru" ? packages?.name_ru : packages?.name_uz

            const measurement = measurements?.find(m => m?.measureId === item?.measurement) || null;


            if (catalog) {
                form?.setValue(`products.${index}.catalogCode`, catalog?.class_code)
                form?.setValue(`products.${index}.catalogName`, catalog?.name)

                form?.setValue(`products.${index}.packageCode`, packages?.code)
                form?.setValue(`products.${index}.packageName`, packageName)
            }

            if (!catalog) {
                form?.setValue(`products.${index}.measurement`, measurement)
            }
        })
    },[document])



    const onSubmit = async (formData) => {
        const data = {
            id: document?.id,
            title: formData.contractInfo.name,
            number: formData.contractInfo.number,
            date: dayjs(formData.contractInfo.date).format('YYYY-MM-DD'),
            place: formData.contractInfo.place,
            expires_on: dayjs(formData.contractInfo.expireDate).format('YYYY-MM-DD'),
            total_in_words: formData.contractInfo.total_words,
            form_fields: formData.form_fields,
            contract_type: document?.contract_type,
            executor: {
                company_inn: formData.executor.tin,
                company_name: formData.executor.name,
                address: formData.executor.address,
                phone: formData.executor.phoneWork,
                mobile: formData.executor.mobile,
                bank_account: formData.executor.account,
                bank_mfo: formData.executor.bankId,
                physic_inn: formData.executor.pinflTin,
                physic_name: formData.executor.fio,
                bank_name: formData.executor.bank
            },
            customer: {
                company_inn: formData.customer.tin,
                company_name: formData.customer.name,
                address: formData.customer.address,
                phone: formData.customer.phoneWork,
                mobile: formData.customer.mobile,
                bank_account: formData.customer.account,
                bank_mfo: formData.customer.bankId,
                physic_inn: formData.customer.pinflTin,
                physic_name: formData.customer.fio,
                bank_name: formData.customer.bank,
            },
            items: formData.products.map((product, index) => ({
                id: product.id || "",
                OrdNo: index + 1,
                catalog_code: product.catalogCode,
                catalog_name: product.catalogName,
                barcode: product.barCode,
                name: product.name,
                measurement_id: product.measureId,
                measurement_name: product.measureName,
                package_code: product?.packageCode ? `${product?.packageCode}` : null,
                package_name: product?.packageName || null,
                quantity: +product.count,
                price: +product.summa,
                total: +product.deliverySum,
                excise_rate: +product.exciseRate,
                excise_value: +product.exciseSum,
                attachment_id: +product.certificate,
                nds_rate: +product.vatRate,
                without_vat: product.vatRate === '' || product.vatRate === null,
                nds_value: +product.vatSum,
                total_with_nds: +product.deliverySumWithVat,
            })),
            parts: document?.parts.map((part, index) => ({
                id: part.id || "",
                order: index + 1,
                title: part.title,
                body: part.body
            })),
            files: formData.attachments.map((attachment, index) => ({
                id: attachment.id || "",
                order: index + 1,
                filename: attachment.filename,
                base_64_content: attachment.file,
                expires_on: attachment.date ? dayjs(attachment.date).format('YYYY-MM-DD') : null,
                attachment_id: +attachment.attachment_id
            }))
        }
        await updateContractFormationAsync({id: document.id, editContract: data})
            .then(() => {
                history.push(`../view/${document?.id}`)
            })
            .catch(() => {})
    };

    return(
        <FormProvider {...form}>
            <Form>
                <ContractFormationForm document={document} onSubmit={form.handleSubmit(onSubmit)}/>
            </Form>
        </FormProvider>
    )
};

export default EditContractFormationForm;
