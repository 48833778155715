import axios from "axios";

class RoamingWaybillService {
    sendRoamingWaybill(params) {
        return axios.post('/roaming/waybill/send', params)
    }

    getCustomerWaybills(params) {
        return axios.get('/roaming/waybill/customer/get', {
            params: params
        });
    }

    getCustomerWaybillsCount(params) {
        return axios.get('/roaming/waybill/customer/count', {
            params: params
        })
    }

    getCarrierWaybills(params) {
        return axios.get('/roaming/waybill/carrier/get', {
            params: params
        });
    }

    getCarrierWaybillsCount(params) {
        return axios.get('/roaming/waybill/carrier/count', {
            params: params
        })
    }

    getWaybill(id) {
        return axios.get(`/roaming/waybill/get/${id}`)
    }

    syncWaybill(id){
        return axios.get(`/roaming/waybill/sync/${id}`)
    }

    getCancelHashCode(id) {
        return axios.get(`/roaming/waybill/hashcode/cancel/${id}`, {
            transformResponse: [data => data]
        })
    }

    carrierCancelWaybill({id, signature}) {
        return axios.post(`/roaming/waybill/cancel/${id}`, {
            signature: signature,
        })
    }

    getDeclineHashCode(id, params) {
        return axios.get(`/roaming/waybill/hashcode/decline/${id}`, {
            params: params,
            transformResponse: [data => data]
        })
    }

    customerDeclineWaybill({id, signature, comment}) {
        return axios.post(`/roaming/waybill/customer/decline/${id}`,{
            signature: signature,
            comment: comment,
        })
    }

    getAcceptHashCode(id) {
        return axios.get(`/roaming/waybill/hashcode/accept/${id}`, {
            transformResponse: [data => data]
        })
    }

    customerAcceptWaybill({id, signature}) {
        return axios.post(`/roaming/waybill/customer/accept/${id}`,{
            signature: signature,
        })
    }

    // draft
    saveDraftWaybill(payload) {
        return axios.post('/roaming/waybill/draft/save', payload)
    }

    getWaybillDrafts(params) {
        return axios.get(`/roaming/waybill/drafts/get`, {
            params: params
        })
    }

    getWaybillDraftsCount(params) {
        return axios.get('/roaming/waybill/drafts/count', {
            params: params
        })
    }

    getDraftWaybill(id) {
        return axios.get(`/roaming/waybill/draft/get/${id}`)
    }
}

const waybillService = new RoamingWaybillService();

export default waybillService;