export const SourceEDI = 1
export const SourceTelegramAgentBot = 2
export const SourceWebAPI = 3
export const SourceTelegramOrderBot = 4

export const CrmOrderSources = [
    {
        value: SourceEDI,
        label: "EDI",
        variant: 'warning'
    },
    {
        value: SourceTelegramAgentBot,
        label: "Телеграм агент бот",
        variant: 'info'
    },
    {
        value: SourceWebAPI,
        label: "Web",
        variant: 'secondary'
    },
    {
        value: SourceTelegramOrderBot,
        label: "Телеграм заказ бот",
        variant: 'success'
    },
]

export const FindCrmOrderSource = (Source) => {
    return CrmOrderSources.find(lt => lt.value === Source);
}
