import AdvanceTableWrapper from "../../../../../common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import ContractorContractTableHeader from "./ContractorContractTableHeader";
import AdvanceTable from "../../../../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../../../common/advance-table/AdvanceTableFooter";
import AdvanceTablePagination from "../../../../../common/advance-table/AdvanceTablePagination";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectContractorContracts,
    selectContractorContractFilterOptions,
    loadContractorContractsAsync, loadContractorContractsCountAsync
} from "../../../../../../app/store/reducers/contractor/contractorContractReducer";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import {selectCardContractor} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {EDIT_CONTRACT_SUCCEED, REGISTER_CONTRACT_SUCCEED} from "../../../../../../app/eventbus/contractEvents";
import {toast} from "react-toastify";
import {Link, useHistory} from "react-router-dom";
import numeral from "numeral";
import ActionButton from "../../../../../common/ActionButton";
import {changeFilterContractorInvoicesAsync} from "../../../../../../app/store/reducers/contractor/contractorInvoiceReducer";

const ContractorContractDataTable = () => {
    const dispatch = useDispatch()
    const contractor = useSelector(selectCardContractor)
    const contracts = useSelector(selectContractorContracts)
    const filters = useSelector(selectContractorContractFilterOptions)
    const currentDateDayJSFormat = useSelector(selectDateDayJSFormat)
    const lang = useSelector(selectLang);
    const t = Translate;
    const numberFormat = useSelector(selectNumberFormat);
    const totalSum = contracts.reduce((totSum, total) => totSum + total.contract_total_info.total_with_nds_value, 0)
    const routeHistory = useHistory();

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'info',
            Header: t(lang, "edi.contract.datatable.header.row.number"),
            headerProps: {className: 'pe-1'},
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`../contractor-contract/${original.id}`}>
                        <strong>{original.info.name} {original.info.number} от {dayjs(original.info.date, "YYYY-MM-DD").format(currentDateDayJSFormat)}</strong>
                    </Link>
                )
            }
        },
        {
            accessor: 'expire_date',
            Header: t(lang, "roaming.contracts.datatable.header.row.expire_date"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                if (original.info.expire_date) {
                    return `до ${dayjs(original.info.expire_date, "YYYY-MM-DD").format(currentDateDayJSFormat)}`
                }

                return ''
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.contracts.datatable.header.row.created_at"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => dayjs(original.created_at, "YYYY-MM-DD").format(currentDateDayJSFormat)
        },
        {
            accessor: 'contractor',
            Header: t(lang, "roaming.contracts.datatable.header.row.contractor"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return `${original.customer.name} (${original.customer.inn})`
            }
        },
        {
            accessor: 'total_sum',
            Header: t(lang, "roaming.contracts.datatable.header.row.total_sum"),
            totalInfo: `${t(lang, "edi.common.datatable.total")}: ` + numeral.formats[numberFormat].format(totalSum),
            totalInfoClassName: 'h6 fw-bold text-nowrap',
            Cell: ({row: {original}}) => {
                return (
                    <strong>
                        {numeral.formats[numberFormat].format(original.contract_total_info.total_with_nds_value)}
                    </strong>
                )
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <>
                        <ActionButton icon="link" title="Просмотр счета-фактуры" variant="action" className="p-0 me-2"
                                      onClick={() => {
                                          dispatch(changeFilterContractorInvoicesAsync({
                                              ...filters,
                                              contract_number: row?.original.info.number || null,
                                              contract_date: row?.original.created_at ? dayjs(row?.original.created_at).format("YYYY-MM-DD") : null,
                                          }))
                                      }}/>
                        <ActionButton icon="edit" title="Изменить" variant="action" className="p-0 me-2"
                                      onClick={() => {
                                          routeHistory.push(`../contractor-contract/edit/${row.original.id}`)
                                      }}/>
                    </>
                );
            }
        }
    ].filter(i => i);

    useEffect(() => {
        dispatch(loadContractorContractsAsync({...filters, contractor_inn: contractor?.inn}))
        dispatch(loadContractorContractsCountAsync({...filters, contractor_inn: contractor?.inn}))
    }, [contractor, filters])

    useEffect(() => {
        const onContactAddedHandler = EventBus.on(REGISTER_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.register_contract_succeed'));
            dispatch(loadContractorContractsAsync({...filters, contractor_inn: contractor?.inn}))
            dispatch(loadContractorContractsCountAsync({...filters, contractor_inn: contractor?.inn}))
        });

        const onContractEditedHandler = EventBus.on(EDIT_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.edit_contract_succeed'));
            dispatch(loadContractorContractsAsync({...filters, contractor_inn: contractor?.inn}))
            dispatch(loadContractorContractsCountAsync({...filters, contractor_inn: contractor?.inn}))
        });

        return () => {
            EventBus.remove(REGISTER_CONTRACT_SUCCEED, onContactAddedHandler);
            EventBus.remove(EDIT_CONTRACT_SUCCEED, onContractEditedHandler);
        }
    }, []);

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={contracts}
            sortable
            pagination
            perPage={10}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ContractorContractTableHeader table/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        table
                        rowsPerPageSelection
                        rowsPerPageOptions={[10, 25, 50]}
                    />
                    <AdvanceTablePagination table/>
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
}
export default ContractorContractDataTable
