import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    loadBoughtAdminPackages, loadBoughtAdminPackagesCount,
    selectBoughtAdminFilter,
    selectBoughtAdminPackages,
    selectBoughtAdminPackagesCount,
    changeFilterBoughtAdminAsync,
} from "../../../../app/store/reducers/billing/billingReducer";
import BoughtDatatable from "../../../../components/hippo/user/billing/component/BoughtDatatable";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {ACTIVE_PACKAGES} from "../payment/PaymentTab";

const AdminBought = ({tab}) => {
    const dispatch = useDispatch()
    const bought = useSelector(selectBoughtAdminPackages)
    const count = useSelector(selectBoughtAdminPackagesCount)
    const filter = useSelector(selectBoughtAdminFilter)
    const activeOrganization = useSelector(selectActiveOrganization)

    useEffect(() => {
       if(tab === ACTIVE_PACKAGES) {
           dispatch(loadBoughtAdminPackages({...filter}))
           dispatch(loadBoughtAdminPackagesCount({...filter}))
       }
    }, [filter, activeOrganization, tab])

    return <BoughtDatatable isAdmin={true} filter={filter} count={count} bought={bought}
                            changeFilterBoughtAsync={changeFilterBoughtAdminAsync}/>;
};

export default AdminBought;
