import React from 'react';
import classNames from "classnames";
import {useSelector} from "react-redux";
import {Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import FindCompany from "../../../../organization/FindCompany";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import FindCompanySearchable from "../../../../organization/FindCompanySearchable";

const MembersInfoForm = () => {
    const {register, formState: {errors}, control, setValue} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <>
            <Card className="mt-3">
                <Card.Header className="bg-light">
                    <Card.Title>{t(lang, 'roaming.waybill.send.members_info_form.title')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col className="" md={6}>
                            <h4>{t(lang, "roaming.waybill.send.members_info_form.customer")}</h4>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, "roaming.waybill.send.members_info_form.customer.inn")}<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name="customer.inn"
                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            ref={field.ref}
                                            defaultInn={field.value}
                                            inputProps={{
                                                isInvalid: errors?.customer?.inn,
                                                placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                            }}
                                            inputGroupProps={{className: classNames({'is-invalid': errors?.customer?.inn})}}
                                            onChange={(company) => {
                                                field.onChange(company?.person_num || company?.inn);
                                                setValue('customer.name', company?.name  || '')
                                            }}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.customer?.inn?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, "roaming.waybill.send.members_info_form.customer.name")}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    {...register('customer.name')}
                                    placeholder={t(lang, "roaming.waybill.send.members_info_form.customer.name.placeholder")}
                                />
                            </Form.Group>
                            {/*<Form.Group className="my-1">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.customer.branch_code')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register('customer.branch.code')}
                                    placeholder={t(lang, 'roaming.waybill.send.members_info_form.customer.branch_code.placeholder')}
                                />
                            </Form.Group>*/}
                            {/*<Form.Group className="my-1">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.customer.branch_name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register('customer.branch.name')}
                                    placeholder={t(lang, 'roaming.waybill.send.members_info_form.customer.branch_name.placeholder')}
                                />
                            </Form.Group>*/}

                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.seller.inn')}<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name="customer.seller.inn"
                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            ref={field.ref}
                                            defaultInn={field.value}
                                            onChange={company => {
                                                field.onChange(company?.person_num || company?.inn);
                                                setValue('customer.seller.name', company?.name  || '');
                                            }}
                                            inputProps={{
                                                isInvalid: errors?.customer?.seller?.inn,
                                                placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                        }}
                                            inputGroupProps={{className: classNames({'is-invalid': errors?.customer?.seller?.inn})}}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.customer?.seller?.inn?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.seller.name')}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    {...register('customer.seller.name')}
                                    placeholder={t(lang, "roaming.waybill.send.members_info_form.seller.name.placeholder")}
                                />
                            </Form.Group>

                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.point_of_loading', {number: 1})}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register('customer.first_point_of_loading')}
                                    placeholder={t(lang, 'roaming.waybill.send.members_info_form.point_of_loading.placeholder', {number: 1})}
                                />
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.point_of_loading', {number: 2})}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register('customer.second_point_of_loading')}
                                    placeholder={t(lang, 'roaming.waybill.send.members_info_form.point_of_loading.placeholder', {number: 2})}
                                />
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.point_of_redirect_name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("point_of_redirect_name")}
                                    placeholder={t(lang, 'roaming.waybill.send.additional_information.point_of_redirect_name.placeholder')}
                                />
                            </Form.Group>
                        </Col>

                        <Col className="mt-4 mt-md-0" md={6}>
                            <h4>{t(lang, 'roaming.waybill.send.members_info_form.carrier')}</h4>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, "roaming.waybill.send.members_info_form.carrier.inn")}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    {...register('carrier.inn')}
                                    placeholder={t(lang, "roaming.waybill.send.members_info_form.carrier.inn.placeholder")}
                                />
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, "roaming.waybill.send.members_info_form.carrier.name")}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    {...register('carrier.name')}
                                    placeholder={t(lang, "roaming.waybill.send.members_info_form.carrier.name.placeholder")}
                                />
                            </Form.Group>
                            {/*<Form.Group className="my-1">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.carrier.branch_code')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register('carrier.branch.code')}
                                    placeholder={t(lang, 'roaming.waybill.send.members_info_form.carrier.branch_code.placeholder')}
                                />
                            </Form.Group>*/}
                            {/*<Form.Group className="my-1">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.carrier.branch_name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register('carrier.branch.name')}
                                    placeholder={t(lang, 'roaming.waybill.send.members_info_form.carrier.branch_name.placeholder')}
                                />
                            </Form.Group>*/}

                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.buyer.inn')}<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name="carrier.buyer.inn"
                                    rules={{required: t(lang, "edi.common.forms.validations.is_required")}}
                                    render={({field}) => (
                                        <FindCompanySearchable
                                            ref={field.ref}
                                            defaultInn={field.value}
                                            onChange={(company) => {
                                                field.onChange(company?.person_num || company?.inn);
                                                setValue('carrier.buyer.name', company?.name  || '');
                                            }}
                                            inputProps={{
                                                isInvalid: errors?.carrier?.buyer?.inn,
                                                placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                        }}
                                            inputGroupProps={{className: classNames({'is-invalid': errors?.carrier?.buyer?.inn})}}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.carrier?.buyer?.inn?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.buyer.name')}<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly={true}
                                    {...register('carrier.buyer.name')}
                                    placeholder={t(lang, "roaming.waybill.send.members_info_form.buyer.name.placeholder")}
                                />
                            </Form.Group>

                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.point_of_unloading', {number: 1})}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register('carrier.first_point_of_unloading')}
                                    placeholder={t(lang, 'roaming.waybill.send.members_info_form.point_of_unloading.placeholder', {number: 1})}
                                />
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label>{t(lang, 'roaming.waybill.send.members_info_form.point_of_unloading', {number: 2})}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register('carrier.second_point_of_unloading')}
                                    placeholder={t(lang, 'roaming.waybill.send.members_info_form.point_of_unloading.placeholder', {number: 2})}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t(lang, 'roaming.waybill.send.additional_information.point_of_redirect_address')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("point_of_redirect_address")}
                                    placeholder={t(lang, 'roaming.waybill.send.additional_information.point_of_redirect_address.placeholder')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default MembersInfoForm;
