import React, {Fragment, memo, useEffect, useState} from 'react';
import {Col, Form, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {
    selectDateFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../app/store/reducers/main/mainReducer";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import CashBoxSelect from "./CashBoxSelect";
import DatePicker from "react-datepicker";
import SelectContractorPaymentType from "../../crm/contractor/SelectContractorPaymentType";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import classNames from "classnames";
import InputGroupWithCurrency from "../../price/components/InputGroupWithCurrency";
import {UZS} from "../../../../enum/CurrencyCode";
import ActionButton from "../../../common/ActionButton";
import {totalGroupAmounts, totalGroupCashAmount} from "../../../../enum/cash_box/cashBoxWrapper";
import numeral from "numeral";
import {ContractorPaymentTypes, PaymentTypeCashCode} from "../../../../enum/ContractorWrapper";
import {selectCashbox} from "../../../../app/store/reducers/cashbox/CashboxReducer";

const CashBoxCommonRegisterForm = ({transfer}) => {
    const t = Translate
    const lang = useSelector(selectLang)
    const dateFormat = useSelector(selectDateFormat);
    const currencies = useSelector(selectCurrency);
    const cashboxes = useSelector(selectCashbox);
    const numberFormat = useSelector(selectNumberFormat);

    const [allowDeleteItems, setAllowDeleteItems] = useState(false);
    const {formState: {errors}, register, getValues, setValue} = useFormContext()
    const {fields, append, remove} = useFieldArray({
        name: "amounts"
    });

    useEffect(() => {
        setAllowDeleteItems(fields.length > 1);
    }, [fields]);

    const addAmount = () => {
        append({
            amount: null,
            money_type: 1,
            currency_id: currencies?.find(c => c?.code === UZS)?.id
        })
    }

    const onDeleteItem = (index) => {
        remove(index)
    }

    return (
        <Row>
            {!transfer &&
                <Controller name={'cash_box_id'}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            }}
                            render={({field, fieldState: {error}}) => {
                                const cashBox = cashboxes.find(c => c.id === field.value) || null;
                                return (
                                    <Form.Group as={Col} md={6} xs={12}>
                                        <Form.Label>
                                            {t(lang, "cashbox.bind.account.title")}
                                        </Form.Label>
                                        <CashBoxSelect defaultValue={field.value}
                                                       onChange={(option) => field.onChange(option ? option.id : null)}
                                        />
                                        {!!error && <Form.Control.Feedback type="invalid"
                                                                           className="d-block"
                                        >
                                            {error.message}
                                        </Form.Control.Feedback>}
                                        {cashBox &&
                                            <div className="d-flex align-items-center gap-2 flex-wrap mt-2">
                                                {totalGroupCashAmount(cashBox.amounts.filter(i => i.money_type === PaymentTypeCashCode))
                                                    .filter(i => i.total !== 0)
                                                    .map((i, index) => (
                                                        <strong key={index}
                                                                className={classNames('text-success ms-2 py-2')}
                                                                style={{fontSize: 12}}>
                                                            <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                            <strong
                                                                className={'ms-2 fw-bold text-info'}>{i?.name}</strong>
                                                        </strong>
                                                    ))
                                                }
                                                {totalGroupAmounts(cashBox.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type)))
                                                    .filter(i => i.total !== 0)
                                                    .map((i, index) => (
                                                        <OverlayTrigger key={index}
                                                                        trigger={['hover', 'focus']}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Popover id="popover-basic"
                                                                                     className='mt-0'>
                                                                                <Popover.Header>
                                                                                    <div
                                                                                        className={'d-block text-center fs--1 px-2'}
                                                                                        style={{backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg}}>{t(lang, ContractorPaymentTypes.find(b => b.value === i?.money_type).label)}</div>
                                                                                </Popover.Header>
                                                                            </Popover>
                                                                        }
                                                        >
                                                            <div className="fs--1">
                                                                <strong className="fw-bold px-1"
                                                                        style={{
                                                                            fontSize: 12,
                                                                            backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg
                                                                        }}
                                                                >
                                                                    <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                                    <strong className="ms-2 fw-bold">{i?.name}</strong>
                                                                </strong>
                                                            </div>
                                                        </OverlayTrigger>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </Form.Group>
                                )
                            }}
                />
            }

            {transfer && <Fragment>
                <Controller name={'from_cashbox_id'}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            }}
                            render={({field, fieldState: {error}}) => {
                                const cashBox = cashboxes.find(c => c.id === field.value) || null;
                                return (
                                    <Form.Group as={Col} md={6} xs={12}>
                                        <Form.Label>
                                            {t(lang, "cashbox.bind.account.title")}
                                        </Form.Label>
                                        <CashBoxSelect defaultValue={field.value}
                                                       onChange={(option) => field.onChange(option ? option.id : null)}
                                        />
                                        {!!error && <Form.Control.Feedback type="invalid"
                                                                           className="d-block"
                                        >
                                            {error.message}
                                        </Form.Control.Feedback>}
                                        {cashBox &&
                                            <div className="d-flex align-items-center gap-2 flex-wrap mt-2">
                                                {totalGroupCashAmount(cashBox.amounts.filter(i => i.money_type === PaymentTypeCashCode))
                                                    .filter(i => i.total !== 0)
                                                    .map((i, index) => (
                                                        <strong key={index}
                                                                className={classNames('text-success ms-2 py-2')}
                                                                style={{fontSize: 12}}>
                                                            <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                            <strong
                                                                className={'ms-2 fw-bold text-info'}>{i?.name}</strong>
                                                        </strong>
                                                    ))
                                                }
                                                {totalGroupAmounts(cashBox.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type)))
                                                    .filter(i => i.total !== 0)
                                                    .map((i, index) => (
                                                        <OverlayTrigger key={index}
                                                                        trigger={['hover', 'focus']}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Popover id="popover-basic"
                                                                                     className='mt-0'>
                                                                                <Popover.Header>
                                                                                    <div
                                                                                        className={'d-block text-center fs--1 px-2'}
                                                                                        style={{backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg}}>{t(lang, ContractorPaymentTypes.find(b => b.value === i?.money_type).label)}</div>
                                                                                </Popover.Header>
                                                                            </Popover>
                                                                        }
                                                        >
                                                            <div className="fs--1">
                                                                <strong className="fw-bold px-1"
                                                                        style={{
                                                                            fontSize: 12,
                                                                            backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg
                                                                        }}
                                                                >
                                                                    <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                                    <strong className="ms-2 fw-bold">{i?.name}</strong>
                                                                </strong>
                                                            </div>
                                                        </OverlayTrigger>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </Form.Group>
                                )
                            }}
                />

                <Controller name={'to_cashbox_id'}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required")
                            }}
                            render={({field, fieldState: {error}}) => {
                                const cashBox = cashboxes.find(c => c.id === field.value) || null;
                                return (
                                    <Form.Group as={Col} md={6} xs={12}>
                                        <Form.Label>
                                            {t(lang, "cashbox.bind.account.title")}
                                        </Form.Label>
                                        <CashBoxSelect defaultValue={field.value}
                                                       onChange={(option) => field.onChange(option ? option.id : null)}
                                        />
                                        {!!error && <Form.Control.Feedback type="invalid"
                                                                           className="d-block"
                                        >
                                            {error.message}
                                        </Form.Control.Feedback>}
                                        {cashBox &&
                                            <div className="d-flex align-items-center gap-2 flex-wrap mt-2">
                                                {totalGroupCashAmount(cashBox.amounts.filter(i => i.money_type === PaymentTypeCashCode))
                                                    .filter(i => i.total !== 0)
                                                    .map((i, index) => (
                                                        <strong key={index}
                                                                className={classNames('text-success ms-2 py-2')}
                                                                style={{fontSize: 12}}>
                                                            <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                            <strong
                                                                className={'ms-2 fw-bold text-info'}>{i?.name}</strong>
                                                        </strong>
                                                    ))
                                                }
                                                {totalGroupAmounts(cashBox.amounts.filter(i => i.money_type !== PaymentTypeCashCode && (i.money_type !== null && i.money_type)))
                                                    .filter(i => i.total !== 0)
                                                    .map((i, index) => (
                                                        <OverlayTrigger key={index}
                                                                        trigger={['hover', 'focus']}
                                                                        placement={'bottom'}
                                                                        overlay={
                                                                            <Popover id="popover-basic"
                                                                                     className='mt-0'>
                                                                                <Popover.Header>
                                                                                    <div
                                                                                        className={'d-block text-center fs--1 px-2'}
                                                                                        style={{backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg}}>{t(lang, ContractorPaymentTypes.find(b => b.value === i?.money_type).label)}</div>
                                                                                </Popover.Header>
                                                                            </Popover>
                                                                        }
                                                        >
                                                            <div className="fs--1">
                                                                <strong className="fw-bold px-1"
                                                                        style={{
                                                                            fontSize: 12,
                                                                            backgroundColor: ContractorPaymentTypes.find(b => b.value === i?.money_type)?.bg
                                                                        }}
                                                                >
                                                                    <span>{numeral.formats[numberFormat].format(i?.total)}</span>
                                                                    <strong className="ms-2 fw-bold">{i?.name}</strong>
                                                                </strong>
                                                            </div>
                                                        </OverlayTrigger>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </Form.Group>
                                )
                            }}
                />
            </Fragment>}

            <Form.Group as={Col} md={6} xs={12}>
                <Form.Label>{t(lang, "edi.shipment.datatable.header.row.date")}</Form.Label>
                <Controller name={'date'}
                            render={({field}) => (
                                <DatePicker selected={field.value}
                                            dateFormat={`${dateFormat} HH:mm`}
                                            className='form-control'
                                            placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                            onChange={field.onChange}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={60}
                                />
                            )}
                />
            </Form.Group>

            {fields.map((item, index) => (
                <AmountsItem item={item}
                             index={index}
                             key={index}
                             onDeleteItem={onDeleteItem}
                             allowDelete={allowDeleteItems}
                />
            ))}
            <div className={'d-flex justify-content-end'}>
                 <span className={'fs-0 cursor-pointer text-info my-2'} onClick={addAmount}>
                     + {t(lang, "edi.common.button.add")}
                 </span>
            </div>

            <Col md={12}>
                <Form.Group>
                    <Form.Label>{t(lang, "edi.common.modal.description")}</Form.Label>
                    <Form.Control
                        {...register('notes')}
                        as={'textarea'}
                        rows={4}
                        placeholder={t(lang, "task.board.modal.description.task.placeholder")}
                    />
                </Form.Group>
            </Col>
        </Row>
    );
};

const AmountsItem = memo(({index, onDeleteItem, allowDelete}) => {
    const {formState: {errors}, control, getValues, setValue, watch, trigger, setError, clearErrors,} = useFormContext()

    const lang = useSelector(selectLang)
    const t = Translate
    const amounts = useWatch({
        control,
        name: `amounts.${index}`
    })

    return <Fragment>
        <Form.Group as={Col} md={6} xs={12}>
            <Form.Label>{t(lang, "edi.common.items.product.price")}</Form.Label>
            <div className={'d-flex align-items-center'}>
                <Controller name={`amounts.${index}.money_type`} render={({field}) => (
                    <SelectContractorPaymentType
                        onChange={field.onChange}
                        defaultValue={field.value?.value || field.value}
                        changeColor
                    />
                )}/>
                <InputGroupWithCurrency
                    defaultCurrencyId={amounts?.currency_id}
                    defaultPrice={amounts?.amount}
                    onChangePrice={(price) => {
                        setValue(`amounts.${index}.amount`, price);
                    }}
                    onChangeCurrency={(currency) => {
                        setValue(`amounts.${index}.currency_id`, currency ? currency?.id : null);
                    }}
                    inputProps={{
                        className: classNames('rounded-0', {
                            'is-invalid': errors?.amounts?.[index]?.[`amount${index}`]
                        }, 'form-control'),
                        placeholder: t(lang, "warehouse.operation.item.common.sum:placeholder"),
                        options: {
                            numeral: true,
                            delimiter: ' ',
                            numeralDecimalScale: 4
                        }
                    }}
                    className={classNames({
                        'is-invalid': errors?.amounts?.[index]?.[`amount${index}`]
                    })}
                />

                <ActionButton icon="trash-alt"
                              title="Delete"
                              variant="action"
                              className="p-1 mx-auto"
                              onClick={() => onDeleteItem(index)}
                              disabled={!allowDelete}
                />
            </div>
            <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                {errors?.amounts?.[index]?.[`amount${index}`]?.message}
            </Form.Control.Feedback>
        </Form.Group>
    </Fragment>
})

export default CashBoxCommonRegisterForm;
