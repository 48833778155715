import React from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {markOptions} from "../../../../../enum/RoamingInvoiceWrapper";

const MarkProductTypeSelector = ({defaultProductType, onChange, selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <ReactSelect options={markOptions}
                     classNamePrefix="select"
                     isSearchable={!defaultProductType}
                     isClearable={true}
                     getOptionLabel={option => t(lang, option.label)}
                     getOptionValue={option => option.value}
                     placeholder={t(lang, "items.common.select")}
                     onChange={(option) => onChange(option ? option.value : null)}
                     value={markOptions.find(m => m.value === defaultProductType) || null}
                     {...selectProps}
        />    );
};

export default MarkProductTypeSelector;